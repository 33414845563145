import firebase from 'firebase/app'
import { useObjectVal } from 'react-firebase-hooks/database'
import { emptyArray, emptyObject } from 'app/db/db-hooks/empty-hook-helpers'

export function useRecordsByUser<T>(path: string): UseWithObj<Record<string, T[]>> {
  const { data, ...rest } = useFire<Record<string, Record<string, T>>>(path)
  return {
    data: data
      ? Object.fromEntries(
          Object.entries(data).map(([uid, objectsById]) => [uid, Object.values(objectsById)] as const)
        )
      : emptyObject,
    ...rest,
  }
}

export function useRecords<T>(path: string) {
  const { data, ...rest } = useFire<Record<string, T>>(path)
  return { data: data ? Object.values(data) : emptyArray<T>(), ...rest }
}

export function combineFire2<T1 extends UseWithObj<any>, T2 extends UseWithObj<any>, TRet>(
  t1: T1,
  t2: T2,
  combine: (t1: T1['data'], t2: T2['data']) => TRet
) {
  return le({
    data: combine(t1.data, t2.data),
    loading: t1.loading || t2.loading,
    error: t1.error || t2.error,
  })
}

export function combineFire3<
  T1 extends UseWithObj<any>,
  T2 extends UseWithObj<any>,
  T3 extends UseWithObj<any>,
  TRet
>(t1: T1, t2: T2, t3: T3, combine: (t1: T1['data'], t2: T2['data'], t3: T3['data']) => TRet) {
  return le({
    data: combine(t1.data, t2.data, t3.data),
    loading: t1.loading || t2.loading || t3.loading,
    error: t1.error || t2.error || t3.error,
  })
}

export function combineFire4<
  T1 extends UseWithObj<any>,
  T2 extends UseWithObj<any>,
  T3 extends UseWithObj<any>,
  T4 extends UseWithObj<any>,
  TRet
>(
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4,
  combine: (t1: T1['data'], t2: T2['data'], t3: T3['data'], t4: T4['data']) => TRet
) {
  return le({
    data: combine(t1.data, t2.data, t3.data, t4.data),
    loading: t1.loading || t2.loading || t3.loading || t4.loading,
    error: t1.error || t2.error || t3.error || t4.error,
  })
}

export function combineFire5<
  T1 extends UseWithObj<any>,
  T2 extends UseWithObj<any>,
  T3 extends UseWithObj<any>,
  T4 extends UseWithObj<any>,
  T5 extends UseWithObj<any>,
  TRet
>(
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4,
  t5: T5,
  combine: (t1: T1['data'], t2: T2['data'], t3: T3['data'], t4: T4['data'], t5: T5['data']) => TRet
) {
  return le({
    data: combine(t1.data, t2.data, t3.data, t4.data, t5.data),
    loading: t1.loading || t2.loading || t3.loading || t4.loading || t5.loading,
    error: t1.error || t2.error || t3.error || t4.error || t5.error,
  })
}

export function combineFire6<
  T1 extends UseWithObj<any>,
  T2 extends UseWithObj<any>,
  T3 extends UseWithObj<any>,
  T4 extends UseWithObj<any>,
  T5 extends UseWithObj<any>,
  T6 extends UseWithObj<any>,
  TRet
>(
  t1: T1,
  t2: T2,
  t3: T3,
  t4: T4,
  t5: T5,
  t6: T6,
  combine: (
    t1: T1['data'],
    t2: T2['data'],
    t3: T3['data'],
    t4: T4['data'],
    t5: T5['data'],
    t6: T6['data']
  ) => TRet
) {
  return le({
    data: combine(t1.data, t2.data, t3.data, t4.data, t5.data, t6.data),
    loading: t1.loading || t2.loading || t3.loading || t4.loading || t5.loading || t6.loading,
    error: t1.error || t2.error || t3.error || t4.error || t5.error || t6.error,
  })
}

export function useFire<T>(path: string): UseWithObj<T | undefined> {
  const [data, loading, error] = useObjectVal<T>(firebase.database().ref(path))
  return le({ data, loading, error })
}

export function le<T>(obj: { data: T; loading: boolean; error: MaybeFBError }): UseWithObj<T> {
  return { ...obj, loadingOrError: obj.loading || !!obj.error }
}

export interface UseWithObj<T> extends WithLoadingAndError {
  data: T
}

export interface WithLoadingAndError {
  loading: boolean
  error: MaybeFBError | undefined
  loadingOrError: boolean
}

export type MaybeFBError = firebase.FirebaseError | undefined

export function combineLoadingAndError(all: WithLoadingAndError[]): WithLoadingAndError {
  return {
    loading: all.some((x) => x.loading),
    error: all.find((x) => x.error)?.error,
    loadingOrError: all.some((x) => x.loadingOrError),
  }
}
