import {
  useCachedEmailFromUserData,
  useUserNameAndSearchName,
} from 'app/pages/profile/cached-firebase-hooks'
import { CopyToClipboardAbbreviation } from 'utils/copy-to-clipboard'

export function UserName({ uid }: { uid: string }) {
  const { details, name } = useUserNameAndSearchName(uid)

  return (
    <div>
      {name && details ? (
        <CopyToClipboardAbbreviation details={details} text={name} />
      ) : (
        <UserNameFromUserData uid={uid} />
      )}
    </div>
  )
}

export function UserNameFromUserData({ uid }: { uid: string }) {
  const email = useCachedEmailFromUserData(uid)
  return <CopyToClipboardAbbreviation details={[email, uid].join(', ')} text={email} />
}
