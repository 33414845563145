import {
  combineFire2,
  combineFire4,
  combineFire5,
  useFire,
  useRecords,
  useRecordsByUser,
  UseWithObj,
} from 'app/db/db-hooks/db-hook-helpers'
import { emptyArray, emptyObject } from 'app/db/db-hooks/empty-hook-helpers'
import { useYearContext } from 'app/themes/year-context'
import { Year } from 'shared/data/license-year'
import {
  Bill,
  LicenseBooking,
  ManualBooking,
  PaymentWithoutUid,
  UserQuery as User,
  InscriptionBooking,
  InvalidPayment,
  Payment,
  BookingRelevantTransaction,
  WithTotal,
  AssociationPayment,
  isNotAssociationPayment,
  isManualPayment,
} from 'shared/db/db'
import { todoMigrateInscriptionBooking, todoMigrateLicenseBooking } from 'shared/db/migrate-bookings'
import { SportEventId } from 'shared/db/sport-event-id'
import {
  sortTransactions,
  sortedTransactionsWithTotal,
  transactionsTotal,
} from 'shared/db/transactions-service'
import { UserId } from 'shared/db/user-id'
import { AssociationID } from 'shared/models/associations'
import { decodeTags } from 'shared/tags/tags-service'
import { groupByString, sortByLowercase } from 'shared/utils/array'
import { filterValues, mapValues } from 'shared/utils/object'

export function useAllBalancesWithoutUsers() {
  const { data, ...rest } = useAllTransactionsWithoutUsers()
  const byUid = groupByString(data.filter(isNotAssociationPayment), (transaction) => transaction.uid)
  return {
    data: Object.entries(byUid).map(([uid, transactions]) => ({
      uid,
      transactions,
      balance: transactionsTotal(transactions || []),
    })),
    ...rest,
  }
}

export function useAllLicenseBookingsByCurrentYear() {
  const year = useYearContext()
  const { data, ...rest } = useAllLicenseBookings()
  return { data: data.filter((booking) => booking.year === year.year), ...rest }
}

export function useAllLicenseBookings(): UseWithObj<LicenseBooking[]> {
  const { data, ...rest } = useAllLicenseBookingsByUser()
  return {
    data: rest.loadingOrError ? emptyArray<LicenseBooking>() : Object.values(data).flat(),
    ...rest,
  }
}

export function useAllTransactionsWithoutUsers(): UseWithObj<BookingRelevantTransaction[]> {
  const { data, ...rest } = combineFire4(
    useAllPaymentsByRider(),
    useAllManualBookingsByRider(),
    useAllInscriptionBookingsByRider(),
    useAllLicenseBookingsByUser(),
    (payments, manualBookings, inscriptionBookings, licenseBookings) => [
      ...Object.values(payments).flat(),
      ...Object.values(manualBookings).flat(),
      ...Object.values(inscriptionBookings).flat(),
      ...Object.values(licenseBookings).flat(),
    ]
  )

  return {
    data: rest.loadingOrError ? emptyArray() : sortedTransactionsWithTotal(data).reverse(),
    ...rest,
  }
}

export function useAllPaymentsSorted() {
  const { data, ...rest } = useAllPaymentsByRider()
  const payments = Object.values(data || {}).flat()
  return {
    data: rest.loadingOrError
      ? emptyArray<WithTotal<Payment>>()
      : sortedTransactionsWithTotal(payments).reverse(),
    ...rest,
  }
}

export function useAllManualPayments() {
  const { data, ...rest } = useRecordsByUser<Payment>(`payments/byRider`)
  return {
    data: rest.loadingOrError
      ? []
      : Object.values(data).flatMap((payments) => payments.filter(isManualPayment)),
    ...rest,
  }
}

export function useAllPaymentsByRider() {
  const { data, ...rest } = useRecordsByUser<PaymentWithoutUid>(`payments/byRider`)
  return {
    data: rest.loadingOrError
      ? (emptyObject as Record<UserId, Payment[]>)
      : Object.fromEntries<Payment[]>(
          Object.entries(data).map(
            ([uid, payments]) => [uid, payments.map((payment) => ({ uid, ...payment }))] as const
          )
        ),
    ...rest,
  }
}

export function useRemainingInvalidPaymentsWithInfo() {
  const loadingPayments = useRemainingInvalidPayments()
  const loadingBills = useOpenBillsByReference()
  return combineFire2(loadingPayments, loadingBills, (payments, bills) => ({ payments, bills }))
}

export function useRemainingInvalidPaymentsCount() {
  return useRemainingInvalidPayments().data.length
}

function useRemainingInvalidPayments() {
  const { data, ...rest } = useRecords<InvalidPayment>(`payments/invalid`)
  return { data: data.filter((payment) => !payment.originalReference), ...rest }
}

export function useAllManualBookingsWithTotal() {
  const { data, ...rest } = useRecords<Record<string, ManualBooking>>('manualBookings/byRider')
  const allBookings = data.flatMap((bookings) => Object.values(bookings))
  return { data: sortedTransactionsWithTotal(allBookings).reverse(), ...rest }
}

export function useAllManualPaymentTags() {
  const { data, ...rest } =
    useFire<Record<AssociationID, Record<string, number>>>('caches/manualPaymentTags')
  return { data: mapTags(data), ...rest }
}

export function useAllManualBookingTags() {
  const { data, ...rest } =
    useFire<Record<AssociationID, Record<string, number>>>('caches/manualBookingTags')
  return { data: mapTags(data), ...rest }
}

export function useRawTags() {
  const { data: bookings } =
    useFire<Record<AssociationID, Record<string, number>>>('caches/manualBookingTags')
  const { data: payments } =
    useFire<Record<AssociationID, Record<string, number>>>('caches/manualPaymentTags')
  return { bookings, payments }
}

function mapTags(data: Record<AssociationID, Record<string, number>> | undefined) {
  return mapValues(
    decodeTags(data || (emptyObject as Record<AssociationID, Record<string, number>>)),
    (tags) =>
      sortByLowercase(
        Object.entries(tags)
          .filter(([, count]) => count > 0)
          .map(([tag]) => tag)
      )
  )
}

export function useAllManualBookingsByRider() {
  return useRecordsByUser<ManualBooking>('manualBookings/byRider')
}

export function useHasTransactions(user: User) {
  const { data, ...rest } = useTransactions(user)
  return { data: data.length > 0, ...rest }
}

export function useAllLicenseBookingsByUserOfYear(year: Year) {
  const { data, ...rest } = useAllLicenseBookingsByUser()
  return {
    data: mapValues(data, (entries) => entries.filter((booking) => booking.year === year)),
    ...rest,
  }
}

export function useAllLicenseBookingsByUser() {
  const { data, ...rest } = useRecordsByUser<LicenseBooking>(`licenseBookings/byRider`)

  return {
    data: data
      ? mapValues(data, (entries) => entries.map((booking) => todoMigrateLicenseBooking(booking)))
      : data,
    ...rest,
  }
}

export function useInscriptionBookingsBySportEvent(sportEvent: SportEventId) {
  const result = useAllInscriptionBookingsByRider()
  return {
    ...result,
    data: filterValues(
      mapValues(result.data, (bookings) =>
        bookings.filter((booking) => booking.item.sportEventId === sportEvent)
      ),
      (bookings) => bookings.length > 0
    ),
  }
}

export function useAllInscriptionBookingsByRider() {
  const { data, ...rest } = useRecordsByUser<InscriptionBooking>(`inscriptionBookings/byRider`)

  return {
    data: data
      ? mapValues(data, (entries) => entries.map((booking) => todoMigrateInscriptionBooking(booking)))
      : data,
    ...rest,
  }
}

export function useTransactions(user: User) {
  const { data, ...rest } = combineFire5(
    usePayments(user),
    useManualBookings(user),
    useInscriptionBookings(user),
    useLicenseBookings(user),
    useBillsByRider(user),
    (payments, manualBookings, inscriptionBookings, licenseBookings, bills) => [
      ...payments,
      ...manualBookings,
      ...inscriptionBookings,
      ...licenseBookings,
      ...bills,
    ]
  )

  return {
    data: rest.loadingOrError
      ? emptyArray<LicenseBooking | InscriptionBooking | Payment | ManualBooking | Bill>()
      : sortTransactions(data).reverse(),
    ...rest,
  }
}

function usePayments(user: User) {
  const { data, ...rest } = useRecords<PaymentWithoutUid>(`payments/byRider/${user.uid}`)
  return { data: data.map<Payment>((payment) => ({ ...payment, uid: user.uid })), ...rest }
}

function useManualBookings(user: User) {
  return useRecords<ManualBooking>(`manualBookings/byRider/${user.uid}`)
}

function useInscriptionBookings(user: User) {
  const { data, ...rest } = useRecords<InscriptionBooking>(`inscriptionBookings/byRider/${user.uid}`)
  return { data: data.map((booking) => todoMigrateInscriptionBooking(booking)), ...rest }
}

function useLicenseBookings(user: User) {
  const { data, ...rest } = useRecords<LicenseBooking>(`licenseBookings/byRider/${user.uid}`)
  return { data: data.map((booking) => todoMigrateLicenseBooking(booking)), ...rest }
}

function useBillsByRider(user: User) {
  const { data, ...rest } = useRecords<Bill>(`bills/byRider/${user.uid}`)
  return { data: data.filter((bill) => !bill.deleted), ...rest }
}

export function useOpenBillsByReference() {
  const { data, ...rest } = useRecords<Bill>('bills/byReference')
  return { data: data.filter((bill) => !bill.deleted && bill.status === 'open'), ...rest }
}

export function useAssociationPaymentsFlat(): UseWithObj<AssociationPayment[]> {
  type ByAssociationAndId = Record<AssociationID, Record<string, AssociationPayment>>
  const { data, ...rest } = useFire<ByAssociationAndId>('associationPayments')
  return { data: data ? Object.values(data).flatMap((byId) => Object.values(byId)) : [], ...rest }
}
