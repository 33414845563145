import { licenseCategoryLineItemsByLicenses, totalLineItem } from 'shared/category-pricing'
import {
  Documents,
  LicenseBooking,
  LicenseDraftWithDocuments,
  LicenseLineItem,
  TransponderLineItem,
  UserQuery,
} from 'shared/db/db'
import { AssociationID, todoMigrateAssociation } from 'shared/models/associations'
import { truthy } from 'shared/utils/array'

export function bookingContexts(
  data: {
    uid: string
    documents: Documents | undefined
    licenses: LicenseDraftWithDocuments[]
    licenseBookings: LicenseBooking[]
  }[],
  admin: UserQuery,
  association: AssociationID | undefined
): BookingContextWithDocuments[] {
  return data
    .map((licensesByRider) => {
      const { documents, licenseBookings, licenses } = licensesByRider
      if (!documents) return undefined

      const filteredLicenseBookings = licenseBookings.filter(
        (booking) => !association || todoMigrateAssociation(booking.item.association) === association
      )
      const filteredLicenses = licenses.filter(
        (license) => !association || license.association === association
      )

      const potentialItemsUnfiltered = licenseCategoryLineItemsByLicenses(
        filteredLicenses,
        documents,
        orderedTransponderItems(filteredLicenseBookings)
      )
      const potentialItems = potentialItemsUnfiltered.filter(
        (booking) => !association || booking.association === association
      )

      const bookedLicenseBookings = filteredLicenseBookings // TODO: later: .filter(({ year }) => year === licenseYear)
      const bookedItems = bookedLicenseBookings.map(({ item }) => item)
      const items = { potentialItems, bookedItems, bookedLicenseBookings }
      const bookingContext: BookingContext = {
        admin,
        rider: { uid: licensesByRider.uid },
        state: bookingContextState(items),
        ...items,
      }
      return { uid: bookingContext.rider.uid, documents, bookingContext }
    })
    .filter(truthy)
}

function orderedTransponderItems(licenseBookings: LicenseBooking[]): TransponderLineItem[] {
  return licenseBookings
    .map((booking) =>
      booking.type === 'licenseBooking' && booking.item.type === 'transponderLineItem'
        ? booking.item
        : undefined
    )
    .filter(truthy)
}

function bookingContextState(bookingItems: BookingItems): BookingContextState {
  return sameItems(bookingItems)
    ? 'same-items'
    : samePrice(bookingItems)
    ? 'same-price'
    : noBookedItems(bookingItems)
    ? 'no-booked-items'
    : 'conflicting-items'
}

function sameItems({ potentialItems, bookedItems }: BookingItems) {
  return (
    exactlySameItems(potentialItems, bookedItems) ||
    (samePrice({ potentialItems, bookedItems }) &&
      exactlySameItems(potentialItems, [...bookedItems].reverse().slice(0, potentialItems.length)))
  )
}

function exactlySameItems(itemsA: LicenseLineItem[], itemsB: LicenseLineItem[]) {
  return (
    itemsA.length === itemsB.length &&
    itemsA.every((a) => itemsB.find((b) => equalItem(a, b))) &&
    itemsB.every((b) => itemsA.find((a) => equalItem(a, b)))
  )
}

export function equalItem(a: LicenseLineItem, b: LicenseLineItem) {
  return a.name === b.name && a.price === b.price
}

function samePrice({ potentialItems, bookedItems }: Omit<BookingItems, 'bookedLicenseBookings'>) {
  return totalLineItem(potentialItems).price === totalLineItem(bookedItems).price
}

function noBookedItems({ bookedItems }: BookingItems) {
  return bookedItems.length === 0
}

export interface BookingContextWithDocuments {
  uid: string
  bookingContext: BookingContext
  documents: Documents
}

export interface BookingContext extends BookingItems {
  admin: UserQuery
  rider: UserQuery
  state: BookingContextState
}

export type BookingContextState = 'same-items' | 'same-price' | 'no-booked-items' | 'conflicting-items'

export interface BookingItems {
  bookedItems: LicenseLineItem[]
  bookedLicenseBookings: LicenseBooking[]
  potentialItems: LicenseLineItem[]
}
