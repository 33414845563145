import { useAllLicenseBookingsByCurrentYear } from 'app/db/db-hooks/financial-db-hooks'
import { TableBox } from 'app/layouts/table-box'
import { FinancialKPIs } from 'app/pages/admin/financials/financial-kpis'
import { filterTransactions } from 'app/pages/admin/financials/transactions-filter'
import { useUserContext } from 'app/themes/user-context'
import { UserQuery } from 'shared/db/db'
import {
  allLicenseBookingKPIs,
  allLicenseBookingKPIsByCategoryType,
} from 'shared/financials/financials-service'
import { t } from 'shared/i18n/current'
import { translateCategoryType } from 'shared/i18n/i18n-helpers'
import { toChf } from 'shared/utils/number'

interface AllLicenseBookingsProps {
  admin: UserQuery
}

export function AllLicenseBookings(_props: AllLicenseBookingsProps) {
  const { data: rawData, loading, error } = useAllLicenseBookingsByCurrentYear()
  const userContext = useUserContext()

  const data = filterTransactions(
    rawData.map((v) => ({ transaction: v })),
    userContext,
    undefined
  ).map((v) => v.transaction)
  const groupedKPIs = allLicenseBookingKPIsByCategoryType(data)

  return (
    <>
      <FinancialKPIs
        title={t().kpiTitles.licenseBookingKPIs}
        kpis={allLicenseBookingKPIs(data)}
        loading={loading}
        error={error}
      />
      <TableBox
        title={t().kpiTitles.licenceBookingsByCategoryType}
        loading={loading}
        error={error}
        data={
          !loading &&
          !error && {
            headers: [
              { value: t().position },
              { value: t().categoryType },
              { value: t().amountTableCHF, align: 'right' },
            ],
            contents: groupedKPIs.map((row) => () => [
              t().kpiTitles[row.kpi],
              translateCategoryType(row.categoryType),
              toChf(row.value),
            ]),
            ids: groupedKPIs.map((row) => [row.kpi, row.categoryType].join('-')),
            rawData: groupedKPIs.map((row) => JSON.stringify(row)),
          }
        }
      />
    </>
  )
}
