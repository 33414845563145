import { DB, ManualPayment } from 'shared/db/db'

export const id = '2022-11-09T19-16-49-028Z'

export const description = 'Convert manual payment.paymentMethod to tag'

export async function up(db: DB) {
  const payments = await db.loadAllManualPayments()
  await Promise.all(payments.map((p) => addPaymentTag(db, p)))
}

async function addPaymentTag(db: DB, payment: ManualPayment) {
  if (payment.tag) return
  const paymentMethod = (payment as unknown as { paymentMethod: string }).paymentMethod
  const tag = convertPaymentMethodToTag(paymentMethod)
  payment.tag = tag
  delete (payment as any).paymentMethod
  await db.replaceManualPaymentMethodWithTag(payment, tag)
}

export function convertPaymentMethodToTag(paymentMethod: string) {
  return paymentMethod
    ? {
        cash: 'Bar',
        other: 'Sonstige',
        bank: 'Bank',
        paypal: 'PayPal',
      }[paymentMethod] || paymentMethod
    : '?'
}
