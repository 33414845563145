import { List } from '@material-ui/core'
import { upperCase } from 'lodash'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { TermsAndDataProtectionText } from 'app/pages/legal/terms-and-data-protection-text'
import { t } from 'shared/i18n/current'
import { associations } from 'shared/models/associations'
import { ExternalLinkListItem } from 'utils/external-link'

export function TermsAndDataProtection() {
  return (
    <>
      <ElevatedBox title={t().routes.licenseTerms}>
        <List component="nav">
          {associations
            .filter(({ licenseTerms }) => licenseTerms)
            .flatMap((association) =>
              Object.entries(association.licenseTerms).map(([locale, url]) => (
                <ExternalLinkListItem key={[association.id, locale].join('-')} href={url}>
                  {t().routes.licenseTerms} {association.name}{' '}
                  {Object.entries(association.licenseTerms).length > 1 ? `(${upperCase(locale)})` : ''}
                </ExternalLinkListItem>
              ))
            )}
        </List>
      </ElevatedBox>

      <ElevatedBox title={t().routes.insuranceTerms}>
        <List component="nav">
          {associations
            .filter(({ insuranceTerms }) => insuranceTerms)
            .map((association) => (
              <ExternalLinkListItem key={association.id} href={association.insuranceTerms}>
                {t().routes.insuranceTerms} {association.name}
              </ExternalLinkListItem>
            ))}
        </List>
      </ElevatedBox>

      <ElevatedBox title={t().routes.homepages}>
        <List component="nav">
          {associations
            .filter(({ homepage }) => homepage)
            .map((association) => (
              <ExternalLinkListItem key={association.id} href={association.homepage}>
                {t().routes.homepage} {association.name}
              </ExternalLinkListItem>
            ))}
        </List>
      </ElevatedBox>

      <TermsAndDataProtectionText />
    </>
  )
}
