import type { History } from 'history'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export function useSearchQuery(parameter = 'q'): SearchQuery {
  const query = useQuery()
  const q = query.query.get(parameter) || ''

  return {
    q,
    set: (value: string) => set(value),
    toggle: (part: string, separator?: string) =>
      set(q.includes(part) ? removePart(q, part, separator) : joinPart(q, part, separator)),
  }

  function set(value: string): void {
    return query.set(parameter, value)
  }
}

function joinPart(q: string, part: string, separator = ','): string {
  return q ? [q, part].join(separator) : part
}

function removePart(q: string, part: string, separator = ','): string {
  return q
    .split(`${part}${separator}`)
    .join('')
    .split(`${separator}${part}`)
    .join('')
    .split(part)
    .join('')
}

export interface SearchQuery {
  q: string
  set: (value: string) => void
  toggle: (part: string, separator?: string) => void
}

export function useQuery() {
  const location = useLocation()
  const history = useHistory()

  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  return {
    query,
    pathname: location.pathname,
    push: useCallback((newQuery: URLSearchParams) => pushQuery(history, newQuery), [history]),
    set: useCallback(
      (name: string, value: string) => updateQuery(history, query, name, value),
      [history, query]
    ),
  }
}

function updateQuery(history: History<unknown>, query: URLSearchParams, name: string, value: string) {
  if (query.get(name) === value) return
  if (!query.get(name) && !value) return

  if (value) query.set(name, value)
  else query.delete(name)
  pushQuery(history, query)
}

function pushQuery(history: History<unknown>, newQuery: URLSearchParams) {
  return history.push({ search: newQuery.toString() })
}
