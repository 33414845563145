import { generateDayCategorySearchString } from 'shared/db/day-category'
import { DB } from 'shared/db/db'

export const id = '2024-04-11T11-49-24-387Z'

export const description = 'Add dayCategoryNamesForSearch to sport event'

export async function up(db: DB) {
  const sportEvents = await db.loadAllSportEvents()
  const allDayCategories = await db.loadAllDayCategoriesOnlyForMigration()
  await Promise.all(
    sportEvents.map(async (sportEvent) => {
      const dayCategories = Object.values(allDayCategories[sportEvent.id] || {})
      sportEvent.dayCategoryNamesForSearch = generateDayCategorySearchString(dayCategories)
      await db.updateSportEvent(sportEvent)
    })
  )
}
