import { t } from 'shared/i18n/current'
import { CategoryType } from 'shared/models/category'

export function translateLongCategoryType(type: CategoryType) {
  const types = t().categoryTypeNamesLong as Partial<Record<CategoryType, string>>
  return types[type] || translateCategoryType(type)
}

export function translateCategoryType(type: CategoryType | 'none') {
  if (type === 'none') return t().none

  return t().categoryTypeNames[type]
}
