import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { groupBy, sortBy, uniq } from 'lodash'
import { useState } from 'react'
import { MergeUsersButton } from '../../../users/merge-users-button'
import { useAllDocuments } from 'app/db/db-contexts/documents-context'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { SetPasswordButton } from 'app/pages/admin/users/set-password-button'
import { EditEmailButton, ImpersonateButton } from 'app/pages/admin/users/user-management'
import { FinancialBox } from 'app/pages/dashboard/financial-box'
import { useIsAssociationAdmin } from 'app/themes/user-context'
import { useAllUsersWithReload } from 'app/users/auth-admin-service'
import { DisabledForAssociationAdmin } from 'app/users/disabled-for-association-admin'
import { UserRecord } from 'shared/api/interfaces'
import { Documents, UserQuery } from 'shared/db/db'
import { queryParts, searchableDocumentsFoundByQueryParts } from 'shared/db/search'
import { t } from 'shared/i18n/current'
import { uFormatDate } from 'shared/utils/date'
import { toggleSetValue } from 'shared/utils/set'
import { sleep } from 'shared/utils/time'
import { useSearchQuery } from 'utils/router'
import { useBoolean } from 'utils/use-boolean'

const reloadDuration = { minutes: 2 }

export function DuplicatedUsers({ admin }: { admin: UserQuery }) {
  const search = useSearchQuery()
  const showOnlyDuplicates = useBoolean(true)
  const [selected, setSelected] = useState(new Set<string>())
  const { loading, error, rows } = useDuplicates(showOnlyDuplicates.value, search.q, reloadDuration)
  const associationAdmin = useIsAssociationAdmin()
  if (associationAdmin) return <DisabledForAssociationAdmin title={t().routes.duplicatedUsers} />

  return (
    <>
      <TableBox
        title={t().routes.duplicatedUsers}
        loading={loading}
        error={error}
        data={{
          rawData: rows.map(({ user, documents }) =>
            JSON.stringify({ user, documents, selected: [...selected.entries()] })
          ),
          headers: [
            { value: '' },
            { value: t().uid },
            { value: t().active },
            { value: t().email },
            { value: t().lastName },
            { value: t().firstName },
            { value: t().street, exportOnly: true },
            { value: t().zip },
            { value: t().place },
            { value: t().country },
            { value: t().birthdate },
            { value: t().phone },
            { value: t().creationTime },
            { value: t().lastSignInTime },
            { value: t().customClaims, exportOnly: true },
            actions(),
          ],
          // eslint-disable-next-line react/display-name
          contents: rows.map(({ user, documents }) => () => {
            const rowSelected = selected.has(user.uid)
            return [
              <Box key="duplicate" style={{ minWidth: '80px' }}>
                <Checkbox
                  disabled={!rowSelected && selected.size >= 2}
                  checked={rowSelected}
                  onChange={() => setSelected(toggleSetValue(selected, user.uid))}
                />
                {rowSelected && selected.size === 2 && (
                  <MergeUsersButton
                    users={rows.filter(({ user }) => selected.has(user.uid))}
                    admin={user}
                  />
                )}
              </Box>,
              user.uid,
              user.disabled ? '❌' : '✅',
              uniq([user.email, documents.personalData?.email || '']).join(' / '),
              documents.personalData?.lastName || '',
              documents.personalData?.firstName || '',
              documents.personalData?.street || '',
              documents.personalData?.zip || '',
              documents.personalData?.place || '',
              documents.personalData?.country || '',
              documents.personalData?.birthdate || '',
              documents.personalData?.phone || '',
              uFormatDate(user.metadata.creationTime),
              uFormatDate(user.metadata.lastSignInTime),
              JSON.stringify(user.customClaims || undefined) || '',
              <>
                <FinancialDialog user={user} admin={admin} />
                <EditEmailButton
                  user={user}
                  afterSuccessfulChange={async () => {
                    await sleep(2000)
                    globalThis.location.reload()
                  }}
                />{' '}
                <ImpersonateButton user={user} />
                <SetPasswordButton user={user} />
              </>,
            ]
          }),
          selected: rows.map(({ user }) =>
            selected.has(user.uid) ? 'success' : user.disabled ? 'deemphasized' : ''
          ),
        }}
      >
        <>
          <Box mr={1} mb={1}>
            <Alert severity="warning" variant="outlined">
              {t().users.duplicatedUsersNotice}
            </Alert>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <FormControlLabel
                control={
                  <Checkbox checked={showOnlyDuplicates.value} onChange={showOnlyDuplicates.toggle} />
                }
                label={t().showOnlyDuplicates}
              />
            </Box>
            <Box flexGrow={1} pr={1}>
              <TextField
                label={t().users.searchDuplicatedUsers}
                variant="outlined"
                size="small"
                fullWidth
                value={search.q}
                onChange={(event) => search.set(event.currentTarget.value)}
              />
            </Box>
          </Box>
        </>
      </TableBox>

      {rows.length === 1 && <FinancialBox user={{ uid: rows[0].user.uid }} admin={admin} />}
    </>
  )
}

function useDuplicates(showOnlyDuplicates: boolean, searchQuery: string, reloadDuration: Duration) {
  const allDocuments = useAllDocuments()
  const users = useAllUsersWithReload(reloadDuration)

  const parts = queryParts(searchQuery)
  const combined = users.users
    .map((user) => ({ user, documents: allDocuments.data[user.uid] }))
    .filter(({ documents }) => documents)
    .map((data) => ({ ...data, duplicateKey: duplicateKey(data) }))
    .filter(({ documents }) => searchableDocumentsFoundByQueryParts(documents, parts))

  const grouped = groupBy(combined, (row) => row.duplicateKey)
  const rows = combined
    .map((data) => ({ ...data, isDuplicate: grouped[data.duplicateKey].length > 1 }))
    .filter(({ isDuplicate }) => !showOnlyDuplicates || isDuplicate)

  return { rows: sortBy(rows, (row) => row.duplicateKey), loading: users.loading, error: users.error }
}

function duplicateKey({ user, documents }: { user: UserRecord; documents: Documents }) {
  if (user.disabled) return user.uid

  const personalData = documents.personalData
  if (!personalData) return user.uid

  return [personalData.lastName, personalData.firstName, personalData.zip].join(', ')
}
