import {
  FormHelperText,
  Grid,
  createStyles,
  makeStyles,
  Typography,
  Box,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import * as Yup from 'yup'
import { useNeededTransponders } from 'app/db/db-hooks/main-db-hooks'
import { samFields } from 'app/forms/fields'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import { useUserContext } from 'app/themes/user-context'
import { nextLicenseYear } from 'shared/data/license-config'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import {
  hasMyLapsTransponder,
  hasRfTransponder,
  myLapsTransponderMakes,
  myLapsTransponderNumbers,
  NewTransponder,
  rfTransponderNumbers,
  splitTransponderNumbers,
  Transponder,
  TransponderOptionId,
} from 'shared/models/transponder'
import { TransponderType } from 'shared/models/transponder-type'
import { truthy } from 'shared/utils/array'
import { useBoolean } from 'utils/use-boolean'

interface TransponderFormFieldsProps {
  values: TransponderFormContents
  prefix: '' | 'transponder.'
  user: UserQuery
  adminView?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function TransponderStepFormFields(props: TransponderFormFieldsProps) {
  const { user } = props
  const neededTransponders = useNeededTransponders(user, nextLicenseYear)
  const orderOptionalTransponder = useBoolean(false)

  if (neededTransponders.loadingOrError || !neededTransponders)
    return <LoadingOrErrorBox {...neededTransponders} />

  const needsTransponder = neededTransponders.data.length > 0
  const showTransponders = orderOptionalTransponder.value || needsTransponder

  return (
    <>
      {!needsTransponder && (
        <FormControlLabel
          control={
            <Switch
              checked={showTransponders}
              onChange={orderOptionalTransponder.toggle}
              name="orderOptionalTransponder"
            />
          }
          label={t().transponders.orderOptionalTransponder}
        />
      )}
      {showTransponders ? <TransponderFormFields {...props} /> : null}
    </>
  )
}

export function TransponderFormFields(props: TransponderFormFieldsProps) {
  const { values, prefix, user, adminView } = props
  const classes = useStyles()
  const fields = samFields().transponder
  const neededTransponders = useNeededTransponders(user, nextLicenseYear)
  const userState = useUserContext()
  const showOrderTransponderFields = userState.adminOrAssociationAdmin
  const hideOrderTransponderFields = adminView && !showOrderTransponderFields

  if (neededTransponders.loadingOrError || !neededTransponders)
    return <LoadingOrErrorBox {...neededTransponders} />

  return (
    <Grid container spacing={1} className={classes.gridParent}>
      <Grid item xs={12} hidden={adminView}>
        <Box mt={2} style={{ marginTop: 0 }}>
          <Typography variant="h6">{t().transponders.transpondersForm.yourTranspondersTitle}</Typography>
          <Typography>{t().transponders.transpondersForm.canBeEditedLater}</Typography>
        </Box>
      </Grid>

      <Grid item md={values.hasTransponder ? 4 : 12} xs={12}>
        {fields.hasTransponder.field(prefix)}
      </Grid>
      {values.hasTransponder && (
        <>
          <Grid item md={4} sm={6} xs={12}>
            {fields.transponderMake.field(prefix)}
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            {fields.transponderNumbers.field(prefix)}
          </Grid>
        </>
      )}

      <Grid item md={4} sm={values.hasTransponderRf ? 6 : 12} xs={12}>
        {fields.hasTransponderRf.field(prefix)}
        {values.hasTransponderRf && (
          <FormHelperText>{t().transponders.transpondersForm.batteryReminder}</FormHelperText>
        )}
      </Grid>
      {values.hasTransponderRf && (
        <>
          <Grid item md={8} sm={6} xs={12}>
            {fields.transponderNumbersRf.field(prefix)}
          </Grid>
        </>
      )}

      <Grid item xs={12} hidden={hideOrderTransponderFields}>
        <Box mt={2}>
          <Typography variant="h6">{t().transponders.transpondersForm.buyTransponderTitle}</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} hidden={hideOrderTransponderFields}>
        <Typography component="span">
          <p>{t().transponders.transpondersForm.usedTransponders}</p>
          <ul>
            {neededTransponders.data
              .filter((category) => category.year === nextLicenseYear)
              .map((category) => (
                <li key={category.id}>
                  {category.name}: {category.transponders.join(', ')}
                </li>
              ))}
          </ul>
        </Typography>
      </Grid>
      <Grid item xs={12} hidden={adminView}>
        <Typography component="span">
          <p>{t().transponders.transpondersForm.perCategory}</p>
          <ul>
            <li>{t().transponders.transpondersForm.needsToBeBought}</li>
            <li>{t().transponders.transpondersForm.needsToBeRentedPerEvent}</li>
            <li>{t().transponders.transpondersForm.isGivenForFreePerEvent}</li>
          </ul>
          <p>{t().transponders.transpondersForm.pleaseInformYourself}</p>
          <p>{t().transponders.transpondersForm.buyAdvertisment}</p>
        </Typography>
      </Grid>
      <Grid item md={8} xs={12} hidden={hideOrderTransponderFields}>
        {fields.orderedTransponders.field(
          prefix,
          neededTransponders.data,
          values.orderedTransponders || []
        )}
      </Grid>
    </Grid>
  )
}

export function ensureOrderedTransponderIsSet(
  transponder: TransponderFormContents
): TransponderFormContents {
  if (!transponder.transponders) transponder.transponders = {}
  const values = Object.values(transponder.transponders)
  if (values.length > 0) {
    const myLaps = values
      .filter((transponder) => transponder.type === 'MyLaps')
      .filter((t) => t.transponderNumber)
    if (myLaps.length > 0) {
      transponder.hasTransponder = true
      transponder.transponderMake = myLaps.map((t) => t.make).filter(truthy)[0] || ''
      transponder.transponderNumbers = myLaps.map((t) => t.transponderNumber).join(', ')
    }
    const rf = values
      .filter((transponder) => transponder.type === 'RF')
      .map((t) => t.transponderNumber || undefined)
      .filter(truthy)
    if (rf.length > 0) {
      transponder.hasTransponderRf = true
      transponder.transponderNumbersRf = rf.join(', ')
    }
  }
  if (!transponder.orderedTransponders) transponder.orderedTransponders = []
  if (!transponder.hasTransponderRf) {
    transponder.hasTransponderRf = false
    transponder.transponderNumbersRf = undefined
  }
  return transponder
}

export function transponderSchema(admin: boolean) {
  const validations = Object.fromEntries(
    Object.entries(samFields().transponder).map(([k, v]) => [k, v.validation(admin)])
  )
  return Yup.object().defined().shape(validations)
}

export function toTransponder(values: TransponderFormContents): Transponder {
  const transpondersArray = [
    ...myLapsTranspondersFromFormArray(values),
    ...rfTranspondersFromFormArray(values),
  ]

  const transponders: Transponder['transponders'] = Object.fromEntries(
    transpondersArray.map((t, i) => [`t${i}`, t])
  )
  return { orderedTransponders: values.orderedTransponders, transponders }
}

function myLapsTranspondersFromFormArray(values: TransponderFormContents): NewTransponder[] {
  return transpondersFromFormArrayCommon({
    type: 'MyLaps',
    hasTransponder: values.hasTransponder || false,
    transponderNumbers: values.transponderNumbers,
    make: values.transponderMake,
  })
}

function rfTranspondersFromFormArray(values: TransponderFormContents): NewTransponder[] {
  return transpondersFromFormArrayCommon({
    type: 'RF',
    hasTransponder: values.hasTransponderRf || false,
    transponderNumbers: values.transponderNumbersRf,
    make: undefined,
  })
}

function transpondersFromFormArrayCommon(props: {
  type: TransponderType
  hasTransponder: boolean
  transponderNumbers: string | undefined
  make: string | undefined
}): NewTransponder[] {
  const { hasTransponder, transponderNumbers, make, type } = props
  return hasTransponder && transponderNumbers
    ? splitTransponderNumbers(transponderNumbers).map((transponderNumber) => ({
        type,
        transponderNumber,
        ...(make ? { make } : {}),
      }))
    : []
}

export function toTransponderForm(values: Transponder | undefined): TransponderFormContents {
  if (!values) return loadInitialTransponderValues()
  return {
    hasTransponder: hasMyLapsTransponder(values),
    hasTransponderRf: hasRfTransponder(values),
    orderedTransponders: values.orderedTransponders,
    transponderMake: myLapsTransponderMakes(values),
    transponderNumbers: myLapsTransponderNumbers(values),
    transponderNumbersRf: rfTransponderNumbers(values),
    transponders: values.transponders,
  }
}

export function loadInitialTransponderValues(): TransponderFormContents {
  return Object.fromEntries(
    Object.entries(samFields().transponder).map(([k, v]) => [k, v.default])
  ) as unknown as TransponderFormContents
}

// should match: export type TransponderFormData = Yup.InferType<ReturnType<typeof schema>>
export interface TransponderFormContents {
  hasTransponder: boolean
  transponderMake: string
  transponderNumbers: string | undefined

  hasTransponderRf: boolean | undefined
  transponderNumbersRf: string | undefined

  orderedTransponders: TransponderOptionId[] | undefined

  transponders: Record<string, NewTransponder> | undefined
}
