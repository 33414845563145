import { useRemainingInvalidPaymentsCount } from 'app/db/db-hooks/financial-db-hooks'
import { routes } from 'shared/config/routes'
import { RoutedButton } from 'utils/buttons/routed-button'

export function InvalidPaymentsButton() {
  const invalidPaymentsCount = useRemainingInvalidPaymentsCount()

  return (
    <RoutedButton
      variant={invalidPaymentsCount > 0 ? 'contained' : 'outlined'}
      color={invalidPaymentsCount > 0 ? 'primary' : 'default'}
      to={routes.invalidPayments.to}
    >
      {routes.invalidPayments.text()} ({invalidPaymentsCount})
    </RoutedButton>
  )
}
