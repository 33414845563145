import {
  Button,
  Collapse,
  Divider,
  Grid,
  Link,
  LinearProgress,
  Typography,
  useTheme,
  withStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { validate } from 'email-validator'
import firebase from 'firebase/app'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as Yup from 'yup'
import { db } from 'app/db/frontend-db'
import { textField } from 'app/forms/fields'
import { I18nState, useI18nContext } from 'app/i18n/i18n-context'
import { handleUserI18nContext } from 'app/i18n/user-locale-service'
import { ChangeLocaleWideButtons } from 'app/layouts/i18n-change-locale'
import { SignInLogos } from 'app/layouts/sign-in-logos'
import { AssociationState, useAssociationContext } from 'app/themes/association-context'
import { useActiveAssociationID } from 'app/themes/use-assocation'
import {
  sendEmailSignInLink,
  signInWithEmailAndPassword,
  signInWithFacebook,
  signInWithGoogle,
} from 'app/users/auth'
import { User } from 'app/users/user'
import { newDomainWithoutHTTPS } from 'shared/config/domain'
import { routes } from 'shared/config/routes'
import { recordSignInEvent } from 'shared/db/user-events-service'
import { t } from 'shared/i18n/current'
import { associationPath } from 'shared/models/associations'
import { FriendlyError, FriendlyErrorForCollapse } from 'utils/errors'
import { ExternalLink } from 'utils/external-link'
import { useQuery } from 'utils/router'

export function SignInForm() {
  const theme = useTheme()
  const [popupError, setPopupError] = useState('')
  const [signInError, setSignInError] = useState('')
  const [popupOpened, setPopupOpened] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [, loading, error] = useAuthState(firebase.auth())
  const query = useQuery()
  const queryEmail = query.query.get('email')
  const association = useActiveAssociationID()
  const i18nContext = useI18nContext()
  const locale = i18nContext.locale
  const associationURL = `${host()}${associationPath({ association, locale })}`
  const associationContext = useAssociationContext()
  const passwordSignIn =
    query.query.get('passwordSignIn') === 'false'
      ? false
      : query.pathname.includes('/password') ||
        query.pathname.includes('/passwort') ||
        query.query.get('passwordSignIn') === 'true'

  if (loading)
    return (
      <div style={{ minWidth: '150px' }}>
        <div style={{ padding: '30px' }}>
          <LinearProgress />
        </div>
      </div>
    )

  return (
    <Formik
      initialValues={{ email: typeof queryEmail === 'string' ? queryEmail.trim() : '', password: '' }}
      enableReinitialize={true}
      validationSchema={schema()}
      onSubmit={async (values, { setSubmitting }) => {
        setSignInError('')
        if (passwordSignIn) {
          const user = await signInWithEmailAndPassword(values.email, values.password, setSignInError)
          await afterSignIn(user, associationContext, i18nContext, 'password')
        } else {
          await sendEmailSignInLink(values.email, setSignInError, setLinkSent)
        }
        setSubmitting(false)
      }}
    >
      {({ submitForm, isSubmitting, values }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SignInLogos />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 15 }}>
              <Typography component="h1" variant="h4" align="center">
                <Link onClick={(e) => e.preventDefault()} href={`https://${associationURL}`}>
                  {associationURL}
                </Link>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={!!popupOpened}>
                <Alert severity="success" variant="outlined">
                  {t().signIn.popupOpened}
                </Alert>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={!!popupError}>
                <FriendlyErrorForCollapse error={popupError} onClose={() => setPopupError('')} />
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <ChangeLocaleWideButtons />
            </Grid>

            <Grid item xs={12}>
              <GoogleRedBackgroundButton
                fullWidth
                color="primary"
                variant="contained"
                disabled={popupOpened || loading || isSubmitting || linkSent}
                onClick={async () => {
                  setPopupOpened(true)
                  const credentials = await signInWithGoogle(setPopupError)
                  await afterSignIn(credentials?.user, associationContext, i18nContext, 'google')
                  setPopupOpened(false)
                }}
              >
                {t().signIn.signInWithGoogle}
              </GoogleRedBackgroundButton>
            </Grid>

            <Grid item xs={12}>
              <FacebookBlueBackgroundButton
                fullWidth
                color="primary"
                variant="contained"
                disabled={popupOpened || loading || isSubmitting || linkSent}
                onClick={async () => {
                  setPopupOpened(true)
                  const credentials = await signInWithFacebook(setPopupError)
                  await afterSignIn(credentials?.user, associationContext, i18nContext, 'facebook')
                  setPopupOpened(false)
                }}
              >
                {t().signIn.signInWithFacebook}
              </FacebookBlueBackgroundButton>
            </Grid>

            <Grid item xs={12}>
              <div style={{ margin: '35px 0 30px 0' }}>
                <Divider />
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: -11,
                  }}
                >
                  <span style={{ background: theme.palette.background.default }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;oder&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              {textField('email', t().email, { disabled: linkSent })}
            </Grid>

            {passwordSignIn && (
              <Grid item xs={12}>
                {textField('password', t().password, { disabled: linkSent, type: 'password' })}
              </Grid>
            )}

            {isSubmitting && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}

            {!linkSent && error && (
              <Grid item xs={12}>
                <FriendlyError error={error} />
              </Grid>
            )}

            {signInError && (
              <Grid item xs={12}>
                <FriendlyError error={signInError} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Collapse in={!!linkSent}>
                <Alert severity="success" variant="outlined" onClose={() => setLinkSent(false)}>
                  {t().signIn.emailSentLong}
                </Alert>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={popupOpened || loading || isSubmitting || linkSent || !validate(values.email)}
                onClick={submitForm}
              >
                {linkSent
                  ? t().signIn.emailSentShort
                  : passwordSignIn
                  ? t().signIn.signInWithPassword
                  : t().signIn.signInWithEmail}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={popupOpened || loading || isSubmitting || linkSent}
                onClick={() => query.set('passwordSignIn', passwordSignIn ? 'false' : 'true')}
              >
                {passwordSignIn ? t().signIn.signInWithEmail : t().signIn.signInWithPassword}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                style={{ marginBottom: 14, marginTop: 14 }}
              >
                <ExternalLink color="inherit" href={routes.termsAndDataProtection.to}>
                  {routes.termsAndDataProtection.text()}
                </ExternalLink>
              </Typography>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

function host() {
  const host = window.location.host
  return isPullRequestPreviewHosting(host) ? newDomainWithoutHTTPS : host
}

function isPullRequestPreviewHosting(host: string) {
  return host.startsWith('sam-events--') && host.endsWith('.web.app')
}

function schema() {
  return Yup.object().shape({
    email: Yup.string().label(t().email).trim().required(),
  })
}

export const GoogleRedBackgroundButton = createButton('#fff', '#db4437', '#a13228')
export const FacebookBlueBackgroundButton = createButton('#fff', '#3b5998', '#23355B')

function createButton(color: string, backgroundColor: string, backgroundHoverColor: string) {
  return withStyles({
    root: { backgroundColor, color, '&:hover': { backgroundColor: backgroundHoverColor, color } },
  })(Button)
}

export async function afterSignIn(
  user: User | null | undefined,
  associationContext: AssociationState,
  i18nContext: I18nState,
  provider: 'email' | 'google' | 'facebook' | 'password'
) {
  if (!user) return

  await recordSignInEvent(db, user, provider)
  await updateAssociationBasedOnUser(user, associationContext)
  await handleUserI18nContext(db, user, i18nContext)
}

async function updateAssociationBasedOnUser(user: User, associationContext: AssociationState) {
  const token = await user.getIdTokenResult()
  const associationAdmin = token?.claims?.associationAdmin
  if (associationAdmin) associationContext.setAssociation(associationAdmin)
}
