import { DB, LicenseDrafts, UserQuery } from 'shared/db/db'
import { endUserEvent, UserEventLicenseSubmitted, UserEventSignIn } from 'shared/db/user-event'

export async function recordSignInEvent(db: DB, user: UserQuery, provider: UserEventSignIn['provider']) {
  const event: UserEventSignIn = { ...endUserEvent(user), type: 'signIn', provider }
  await db.pushUserEvent(event)
}

export async function recordLicenseSubmission(db: DB, user: UserQuery, details: LicenseDrafts) {
  const event: UserEventLicenseSubmitted = { ...endUserEvent(user), type: 'licenseSubmitted', details }
  await db.pushUserEvent(event)
}
