import {
  Box,
  Button,
  createStyles,
  FormHelperText,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { uuid4 } from '@sentry/utils'
import assertNever from 'assert-never'
import type { FormikErrors } from 'formik'
import { Formik, Form, FieldArray } from 'formik'
import { uniq, uniqBy } from 'lodash'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { DeleteButtonWithConfirmation } from 'app/layout/delete-button-with-confirmation'
import { InscriptionMovements } from 'app/sport-events/inscription-movements'
import { calculateCategoryMovements } from 'app/sport-events/inscription-movements-calculation'
import { useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { categoryByIdRequired, fullCategoryCommonName } from 'shared/data/categories-service'
import { Year } from 'shared/data/license-year'
import { DayCategory, DayCategoryID, generateDayCategorySearchString } from 'shared/db/day-category'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { AssociationID, todoMigrateAssociation } from 'shared/models/associations'
import { CategoryId } from 'shared/models/category'
import { TransponderType } from 'shared/models/transponder-type'
import { unknownDate } from 'shared/models/unknown-date'
import {
  DayCategoryFormData,
  SportEventFromForm,
} from 'shared/sport-events/sport-event-categories-service'
import { startingListNameAndDateLookup } from 'shared/sport-events/sport-event-groups'
import { SportEventStatus } from 'shared/sport-events/sport-event-status'
import { StartingListNameAndDateLookup, SportEvent } from 'shared/sport-events/sport-events'
import { sportEventLicenseCategoryIds, sportEventDates } from 'shared/sport-events/sport-events-service'
import { SportEventType } from 'shared/sport-events/sportEventType'
import { truthy } from 'shared/utils/array'
import { formatDate, parseDate } from 'shared/utils/date'
import { mapValues, strictEntries, strictKeys } from 'shared/utils/object'
import { Dig } from 'shared/utils/tsc'
import { DisplayFormErrors } from 'utils/display-form-errors'
import { useErrorSnackbarForError } from 'utils/snackbar'

interface SportEventFormProps {
  admin: UserQuery
  initialSportEvent: SportEvent | undefined
  initialDayCategories: DayCategory[]
  onSubmit: (data: {
    sportEvent: SportEventFromForm
    dayCategories: DayCategoryFormData[]
    licenseCategoryIds: CategoryId[]
    categoryDates: {
      categoryId: CategoryId | DayCategoryID
      date: string
    }[]
  }) => Promise<void>
  onCancel?: () => void
  submitLabel?: string
  autoHighlightSubmit?: true
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function SportEventForm(props: SportEventFormProps) {
  const { submitLabel, initialSportEvent, initialDayCategories, onSubmit, onCancel, admin } = props
  const showError = useErrorSnackbarForError()

  const currentYear = useLicenseYear()
  const userContext = useUserContext()
  const classes = useStyles()
  const association = userContext.associationAdmin || 'sam'
  const loadedInitialValues: SportEventFormData = initialSportEvent
    ? sportEventToInitialValues(initialSportEvent, initialDayCategories)
    : {
        ...loadInitialSportEventValues(),
        association,
        transponderType: association === 'fms' ? 'RF' : 'MyLaps',
      }
  const fields = samFields().sportEvent
  const dayCategoryFields = samFields().dayCategory

  return (
    <Formik
      validateOnMount
      initialValues={loadedInitialValues}
      enableReinitialize={true}
      validationSchema={sportEventSchema()}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit({
            sportEvent: {
              id: values.id,
              name: values.name,
              place: values.place,
              startsAt: formatDate(values.startsAt),
              endsAt: formatDate(values.endsAt),
              alternativeStartsAt: formatDate(values.alternativeStartsAt),
              alternativeEndsAt: formatDate(values.alternativeEndsAt),
              status: values.status,
              createdBy: admin.uid,
              createdAt: new Date().toISOString(),
              links: {},
              offersPower: values.offersPower,
              association: values.association,
              year: new Date().getFullYear() as Year,
              transponderType: values.transponderType,
              categoryGroupCounts: values.categoryGroupCounts,
              categoryGroupSizes: values.categoryGroupSizes,
              disabledInscriptions: values.disabledInscriptions,
              cancelled: !!values.cancelled,
              finalized: !!values.finalized,
              suggestedDonationAmount: values.suggestedDonationAmount || 0,
              dayCategoryNamesForSearch: generateDayCategorySearchString(values.dayCategories),
              ...(values.sportEventType === 'enduro' ? { sportEventType: 'enduro' } : {}),
            },
            dayCategories: values.dayCategories,
            licenseCategoryIds: values.categories,
            categoryDates: extractCategoryDates(values),
          })
        } catch (error) {
          showError(error)
          return false
        } finally {
          setSubmitting(false)
        }
      }}
    >
      {({ submitForm, values, isSubmitting, dirty, isValid, errors }) => (
        <Form>
          <Grid container spacing={1} className={classes.gridParent}>
            <Grid item sm={6} xs={12}>
              {fields.name.field()}
            </Grid>
            <Grid item sm={6} xs={12}>
              {fields.place.field()}
            </Grid>

            <Grid item sm={6} xs={12}>
              {fields.startsAt.field()}
            </Grid>
            <Grid item sm={6} xs={12}>
              {fields.endsAt.field()}
            </Grid>

            <Grid item sm={6} xs={12}>
              {fields.alternativeStartsAt.field()}
            </Grid>
            <Grid item sm={6} xs={12}>
              {fields.alternativeEndsAt.field()}
            </Grid>

            <Grid item xs={6}>
              {fields.status.field()}
            </Grid>
            <Grid item xs={6}>
              {fields.association.field({ disabled: !!userContext.associationAdmin })}
            </Grid>

            <Grid item xs={6}>
              {fields.offersPower.field()}
            </Grid>
            <Grid item xs={6}>
              {fields.transponderType.field()}
            </Grid>

            <Grid item xs={6}>
              {fields.cancelled.field()}
            </Grid>
            <Grid item xs={6}>
              {fields.suggestedDonationAmount.field()}
            </Grid>

            <Grid item xs={6}>
              {fields.sportEventType.field()}
              <FormHelperText>{fields.sportEventType.hint()}</FormHelperText>
            </Grid>
            <Grid item xs={6}>
              {fields.finalized.field()}
              <FormHelperText>{fields.finalized.hint()}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
              {fields.categories.field(currentYear)}
              {initialSportEvent &&
                sportEventLicenseCategoryIds(initialSportEvent).some(
                  (category) => !values.categories.includes(category)
                ) && (
                  <Alert severity="warning" variant="outlined">
                    {t().sportsEvent.removeCategoryWarning}
                    <br />
                    {!userContext.admin && t().sportsEvent.changeCategoryDateWarning}
                  </Alert>
                )}
            </Grid>

            <Grid item xs={12}>
              <FieldArray name="dayCategories">
                {({ push, remove }) => (
                  <>
                    {(values.dayCategories || []).map((dayCategory, index) => (
                      <Box mb={2} key={dayCategory.id}>
                        <Paper elevation={6}>
                          <Box p={2}>
                            <Grid item xs={12}>
                              <Typography variant="h5">
                                {dayCategory.name
                                  ? `${t().dayCategory.title} ${index + 1}: "${dayCategory.name}"`
                                  : `${t().dayCategory.title} ${index + 1}`}
                              </Typography>
                            </Grid>

                            <Grid container spacing={1} className={classes.gridParent}>
                              {strictKeys(dayCategoryFields).map((fieldName) => {
                                const field = dayCategoryFields[fieldName]
                                return (
                                  <Grid
                                    key={fieldName}
                                    item
                                    sm={'wide' in field && field.wide ? 12 : 6}
                                    xs={12}
                                  >
                                    {field.field(index)}
                                  </Grid>
                                )
                              })}
                            </Grid>

                            <Grid item xs={12}>
                              <Box mt={4} mb={1}>
                                <DeleteButtonWithConfirmation
                                  title={t().dayCategory.deleteDayCategory}
                                  confirmation={
                                    dayCategory.storedInDB
                                      ? t().dayCategory.deleteDayCategoryConfirmation
                                      : undefined
                                  }
                                  onConfirm={() => remove(index)}
                                />
                              </Box>
                            </Grid>
                          </Box>
                        </Paper>
                      </Box>
                    ))}

                    <Button
                      onClick={() =>
                        push({
                          ...mapValues(dayCategoryFields, (value) => value.default),
                          id: uuid4(),
                          storedInDB: false,
                        })
                      }
                    >
                      {t().dayCategory.createDayCategory}
                    </Button>
                  </>
                )}
              </FieldArray>
            </Grid>

            {(values.categories.length > 0 || values.dayCategories.length > 0) && (
              <Grid item xs={12}>
                <Typography variant="h5">{t().date}</Typography>
                <Box my={2}>
                  <Alert severity="info" variant="outlined">
                    {t().sportsEvent.inscriptionWithoutDateNotice}
                    <br />
                    {t().sportsEvent.changeEventDateNotice}
                    <br />
                    {t().sportsEvent.referToDeletedOrMovedInscriptionTable}
                  </Alert>
                </Box>

                <Grid container spacing={1} className={classes.gridParent}>
                  <Grid item xs />
                  {sportEventDatesOrNoDatesWithPrevious(initialSportEvent, values).map(
                    ({ date, type }) => (
                      <Grid item xs key={date}>
                        <Typography style={{ textDecoration: type === 'old' ? 'line-through' : 'none' }}>
                          {date}
                        </Typography>
                      </Grid>
                    )
                  )}
                  <Grid item xs>
                    <Typography>{t().categoryDateUnknown}</Typography>
                  </Grid>
                </Grid>

                {values.categories.map((categoryId) => (
                  <Grid key={categoryId} container spacing={1} className={classes.gridParent}>
                    <Grid item xs>
                      <Typography>{fullCategoryCommonName(categoryByIdRequired(categoryId))}</Typography>
                    </Grid>
                    {sportEventDatesOrNoDatesWithPrevious(initialSportEvent, values).map(({ date }) => (
                      <Grid item xs key={date}>
                        {fields.categoryDates.field(categoryId, date)}
                      </Grid>
                    ))}
                    <Grid item xs>
                      {fields.categoryDates.field(categoryId, unknownDate)}
                    </Grid>
                  </Grid>
                ))}

                {values.dayCategories.map((dayCategory, index) => (
                  <Grid key={index} container spacing={1} className={classes.gridParent}>
                    <Grid item xs>
                      <Typography>{dayCategory.name}</Typography>
                    </Grid>
                    {sportEventDatesOrNoDatesWithPrevious(initialSportEvent, values).map(({ date }) => (
                      <Grid item xs key={date}>
                        {fields.categoryDates.field(dayCategory.id, date)}
                      </Grid>
                    ))}
                    <Grid item xs>
                      {fields.categoryDates.field(dayCategory.id, unknownDate)}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {(values.categories.length > 0 || values.dayCategories.length > 0) && (
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t().sportsEvent.sportEventsAdminForm.categoryGroups}
                </Typography>

                <Grid container spacing={1} className={classes.gridParent}>
                  <Grid item xs />
                  {sportEventDatesOrNoDatesWithPrevious(initialSportEvent, values).map(
                    ({ date, type }) => (
                      <Grid item xs key={date}>
                        <Typography style={{ textDecoration: type === 'old' ? 'line-through' : 'none' }}>
                          {date}
                        </Typography>
                      </Grid>
                    )
                  )}
                  <Grid item xs>
                    <Typography>{t().categoryDateUnknown}</Typography>
                  </Grid>
                </Grid>

                {uniq(
                  values.categories.map((categoryId) => categoryByIdRequired(categoryId).startListName)
                ).map((startListName) => {
                  const lookup = startingListNameAndDateLookup(startListName, unknownDate)
                  const count = values.categoryGroupCounts[lookup] || 1
                  return (
                    <Grid key={startListName} container spacing={1} className={classes.gridParent}>
                      <Grid item xs>
                        <Typography>{startListName}</Typography>
                      </Grid>
                      {sportEventDatesOrNoDatesWithPrevious(initialSportEvent, values).map(
                        ({ date }) => {
                          const lookup = startingListNameAndDateLookup(startListName, date)
                          const count = values.categoryGroupCounts[lookup] || 1

                          return (
                            <Grid item xs key={date}>
                              {fields.categoryGroupCounts.field(lookup)}
                              {count > 1 && fields.categoryGroupSizes.field(lookup)}
                            </Grid>
                          )
                        }
                      )}
                      <Grid item xs>
                        {fields.categoryGroupCounts.field(lookup)}
                        {count > 1 && fields.categoryGroupSizes.field(lookup)}
                      </Grid>
                    </Grid>
                  )
                })}

                {uniq(values.dayCategories.map((dayCategory) => dayCategory.myLapsName)).map(
                  (startListName, index) => {
                    const lookup = startingListNameAndDateLookup(startListName, unknownDate)
                    const count = values.categoryGroupCounts[lookup] || 1
                    return (
                      <Grid key={index} container spacing={1} className={classes.gridParent}>
                        <Grid item xs>
                          <Typography>{startListName}</Typography>
                        </Grid>
                        {sportEventDatesOrNoDatesWithPrevious(initialSportEvent, values).map(
                          ({ date }) => {
                            const lookup = startingListNameAndDateLookup(startListName, date)
                            const count = values.categoryGroupCounts[lookup] || 1
                            return (
                              <Grid item xs key={date}>
                                {fields.categoryGroupCounts.field(lookup)}
                                {count > 1 && fields.categoryGroupSizes.field(lookup)}
                              </Grid>
                            )
                          }
                        )}
                        <Grid item xs>
                          {fields.categoryGroupCounts.field(lookup)}
                          {count > 1 && fields.categoryGroupSizes.field(lookup)}
                        </Grid>
                      </Grid>
                    )
                  }
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h5">{t().inscription.inscriptionDeactivatedFor}</Typography>

              {values.categories.map((categoryId) => (
                <Box key={categoryId}>
                  {fields.disabledInscriptions.field(
                    categoryId,
                    fullCategoryCommonName(categoryByIdRequired(categoryId))
                  )}
                </Box>
              ))}
              {values.dayCategories.map((dayCategory, index) => (
                <Box key={index}>
                  {fields.disabledInscriptions.field(dayCategory.id, dayCategory.name)}
                </Box>
              ))}
            </Grid>

            {initialSportEvent && (
              <Grid item xs={12}>
                <InscriptionMovements
                  movements={calculateCategoryMovements({
                    before: initialSportEvent,
                    initialDayCategories,
                    after: {
                      licenseCategoryIds: values.categories,
                      dayCategories: values.dayCategories,
                      dates: extractCategoryDates(values),
                    },
                  })}
                />
              </Grid>
            )}

            {isSubmitting && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}

            <SportEventFormErrors values={values} errors={errors} />

            {onCancel && (
              <Grid item xs={6}>
                <Button fullWidth disabled={isSubmitting} onClick={onCancel}>
                  {t().buttons.cancel}
                </Button>
              </Grid>
            )}
            <Grid item xs={onCancel ? 6 : 12}>
              <Button
                fullWidth
                variant={props.autoHighlightSubmit && !dirty ? 'outlined' : 'contained'}
                color={props.autoHighlightSubmit && !dirty ? 'default' : 'primary'}
                disabled={
                  (props.autoHighlightSubmit && !dirty) ||
                  isSubmitting ||
                  !isValid ||
                  !validSportEventDates(values) ||
                  !!sportEventCategoryDatesError(values)
                }
                onClick={submitForm}
              >
                {isSubmitting ? t().buttons.saving : submitLabel || t().buttons.save}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

function sportEventToInitialValues(
  initialValues: SportEvent,
  dayCategories: DayCategory[]
): SportEventFormData {
  const categoryDates = Object.fromEntries([
    ...Object.entries(initialValues.licenseCategoryDates || {}).map(
      ([categoryId, dates]) =>
        [categoryId, Object.fromEntries(dates.map((date) => [date, true] as const))] as const
    ),
    ...Object.entries(initialValues.dayCategoryDates || {}).map(
      ([categoryId, dates]) =>
        [categoryId, Object.fromEntries(dates.map((date) => [date, true] as const))] as const
    ),
  ])

  return {
    id: initialValues.id,
    name: initialValues.name,
    place: initialValues.place,
    startsAt: parseDate(initialValues.startsAt),
    endsAt: parseDate(initialValues.endsAt),
    status: initialValues.status || 'online',
    alternativeStartsAt: parseDate(initialValues.alternativeStartsAt),
    alternativeEndsAt: parseDate(initialValues.alternativeEndsAt),
    categories: sportEventLicenseCategoryIds(initialValues),
    offersPower: !!initialValues.offersPower,
    association: todoMigrateAssociation(initialValues.association),
    categoryDates,
    dayCategories: dayCategories.map<DayCategoryFormData>((dayCategory) => ({
      ...dayCategory,
      storedInDB: true,
    })),
    transponderType: initialValues.transponderType || 'MyLaps',
    categoryGroupCounts: initialValues.categoryGroupCounts || {},
    categoryGroupSizes: initialValues.categoryGroupSizes || {},
    disabledInscriptions: initialValues.disabledInscriptions || {},
    cancelled: !!initialValues.cancelled,
    finalized: !!initialValues.finalized,
    suggestedDonationAmount: initialValues.suggestedDonationAmount || 0,
    sportEventType: initialValues.sportEventType || '',
  }
}

export function loadInitialSportEventValues(): SportEventFormData {
  return Object.fromEntries(
    Object.entries(samFields().sportEvent).map(([k, v]) => [k, v.default])
  ) as unknown as SportEventFormData
}

export function sportEventSchema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().sportEvent).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['sportEvent']>
  return Yup.object().defined().shape(validations)
}

interface SportEventFormErrorsProps {
  values: SportEventFormData
  errors: FormikErrors<SportEventFormData>
}

function SportEventFormErrors({ values, errors }: SportEventFormErrorsProps) {
  return (
    <>
      <DisplayFormErrors errors={errors} />

      {!validSportEventDates(values) && (
        <Grid item xs={12}>
          <Alert severity="error" variant="outlined">
            {t().sportEventFormErrors.invalidStartOrEndDate}
          </Alert>
        </Grid>
      )}

      <SportEventCategoryDatesErrors values={values} />
    </>
  )
}

function SportEventCategoryDatesErrors({ values }: { values: SportEventFormData }) {
  if (!validSportEventDates(values)) return null

  const error = sportEventCategoryDatesError(values)

  return error ? (
    <Grid item xs={12}>
      <Alert severity="error" variant="outlined">
        {error === 'unknownWithDate'
          ? t().sportEventFormErrors.setDateOrAnnounceLater
          : error === 'noDates'
          ? t().sportEventFormErrors.noDateForCategory
          : error === 'invalidDateSelection'
          ? t().sportEventFormErrors.dateOutsideOfEventDates
          : assertNever(error)}
      </Alert>
    </Grid>
  ) : null
}

function sportEventCategoryDatesError(values: SportEventFormData) {
  return sportEventCategoryDatesHaveDiscrepancy(values)
    ? 'unknownWithDate'
    : sportEventCategoryDatesHasCategoryWithoutDates(values)
    ? 'noDates'
    : !sportEventCategoryDatesValidRange(values)
    ? 'invalidDateSelection'
    : undefined
}

function sportEventCategoryDatesHaveDiscrepancy(values: SportEventFormData) {
  const dates = extractCategoryDates(values)
  const categoriesWithUnknownDate = dates
    .filter(({ date }) => date === unknownDate)
    .map(({ categoryId }) => categoryId)
  return dates
    .filter(({ date }) => date !== unknownDate)
    .some(({ categoryId }) => categoriesWithUnknownDate.includes(categoryId))
}

function sportEventCategoryDatesHasCategoryWithoutDates(values: SportEventFormData) {
  const dates = extractCategoryDates(values)
  const selectedCats = selectedCategories(values)
  return selectedCats.some((cat) => !dates.some(({ categoryId }) => categoryId === cat))
}

function sportEventCategoryDatesValidRange(values: SportEventFormData) {
  const validDates = new Set(sportEventDatesOrNoDates(values))
  const selectedDates = Object.entries(values.categoryDates).flatMap(([, dates]) =>
    Object.entries(dates)
      .filter(([, selected]) => selected)
      .map(([date]) => date)
  )
  return selectedDates.every((date) => validDates.has(date) || date === 'unknown')
}

function validSportEventDates(values: SportEventFormData) {
  return sportEventDatesOrNoDates(values).length > 0
}

export function sportEventDatesOrNoDatesWithPrevious(
  before: { startsAt: string; endsAt: string } | undefined,
  after: { startsAt: Date; endsAt: Date }
) {
  return uniqBy(
    [
      ...sportEventDatesOrNoDates(after)
        .map((date) => ({ type: 'new', date }))
        .reverse(),
      ...(before
        ? sportEventDatesOrNoDates({
            startsAt: parseDate(before.startsAt),
            endsAt: parseDate(before.endsAt),
          })
        : []
      )
        .map((date) => ({ type: 'old', date }))
        .reverse(),
    ],
    (el) => el.date
  ).reverse()
}

export function sportEventDatesOrNoDates(event: { startsAt: Date; endsAt: Date }) {
  try {
    const dates = sportEventDates({
      startsAt: formatDate(event.startsAt),
      endsAt: formatDate(event.endsAt),
    })
    return dates.length <= 5 ? dates : []
  } catch (error) {
    return []
  }
}

function extractCategoryDates(values: SportEventFormData) {
  const selectedCats = selectedCategories(values)
  const eventDates = sportEventDatesOrNoDates(values)
  const dates = values.categoryDates
  return strictEntries(dates)
    .flatMap(([categoryId, dateFlags]) =>
      strictEntries(dateFlags).map(([date, flag]) => (flag ? { categoryId, date } : undefined))
    )
    .filter(truthy)
    .filter(({ date }) => eventDates.includes(date) || date === unknownDate)
    .filter(({ categoryId }) => selectedCats.includes(categoryId))
}

function selectedCategories(values: SportEventFormData) {
  return [...values.categories, ...values.dayCategories.map((c) => c.id)]
}

interface SportEventFormData {
  id: string
  startsAt: Date
  endsAt: Date
  alternativeStartsAt: Date
  alternativeEndsAt: Date
  name: string
  status: SportEventStatus
  place: string
  categories: CategoryId[]
  dayCategories: DayCategoryFormData[]
  categoryDates: Record<CategoryId | DayCategoryID, Record<string, boolean>>
  offersPower: boolean
  association: AssociationID
  transponderType: TransponderType
  categoryGroupCounts: Record<StartingListNameAndDateLookup, number>
  categoryGroupSizes: Record<StartingListNameAndDateLookup, number>
  disabledInscriptions: Record<CategoryId | DayCategoryID, boolean>
  cancelled: boolean
  finalized: boolean
  suggestedDonationAmount: number
  sportEventType: SportEventType
}
