import { nextLicenseYear } from 'shared/data/license-config'
import { DB } from 'shared/db/db'

export const id = '2024-01-11T02-45-46-464Z'

export const description = 'Show unprocessed license drafts (idempotent / changes nothing / readonly)'

export async function up(db: DB) {
  const drafts = await db.loadAllLicenseDrafts(nextLicenseYear)
  if (!drafts) throw new Error(`No drafts found for ${nextLicenseYear}`)

  const unprocessed = Object.entries(drafts)
    .filter(([_, draft]) => draft.summary?.processed === false)
    .map(([userId, draft]) => ({ userId, draft }))
  console.log(
    `User ids array`,
    unprocessed.map((u) => u.userId)
  )
  console.log(`User ids`, unprocessed.map((u) => u.userId).join(', '))
  console.log(unprocessed)
  if (unprocessed.length > 0) throw new Error(`Found unprocessed entries ${JSON.stringify(unprocessed)}`)
}
