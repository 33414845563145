import { groupBy, initial, isEqual, last, maxBy, minBy, sortBy } from 'lodash'

export function truthyTuples<T, U>(list: ([T, U] | undefined | false | 0 | '')[]) {
  return list.filter(truthy)
}

export function assertTuples<T, U>(list: ([T, U] | undefined)[]) {
  return list
}

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value
}

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

export function countStrings(strings: string[]) {
  return strings.reduce(
    (counts, current) => counts.set(current, (counts.get(current) || 0) + 1),
    new Map<string, number>()
  )
}

export function removeFirst<T>(array: T[], predicate: (value: T) => boolean) {
  const index = array.findIndex(predicate)
  if (index === -1) throw new Error(`Item not found in list: ${array}`)
  array.splice(index, 1)
}

export function tryRemoveFirst<T>(array: T[], predicate: (value: T) => boolean): T | undefined {
  const index = array.findIndex(predicate)
  const el = array[index]
  if (index === -1) return undefined
  array.splice(index, 1)
  return el
}

export function toSentence(words: string[]) {
  if (words.length === 0) throw new Error('Words cannot be empty')
  if (words.length === 1) return words[0]
  return [initial(words).join(', '), last(words)].join(' & ')
}

export function withPreviousAndNext<T>(array: T[]): {
  value: T
  previous: T | undefined
  next: T | undefined
  index: number
}[] {
  return array.map((value, index) => ({
    value,
    previous: array[index - 1],
    next: array[index + 1],
    index,
  }))
}

export function sortSetByLowercase(array: Set<string>) {
  return sortByLowercase([...array])
}

export function sortByLowercase(array: string[]) {
  return sortBy(array, (item) => item.toLocaleLowerCase())
}

export function toggleArrayElement<T extends string | number>(array: T[] | Readonly<T[]>, value: T) {
  return array.includes(value) ? array.filter((selected) => selected !== value) : [...array, value]
}

export function toggleArrayObject<T>(array: T[] | Readonly<T[]>, value: T) {
  return includesObject(array, value)
    ? array.filter((selected) => !isEqual(selected, value))
    : [...array, value]
}

export function includesObject<T>(array: T[] | Readonly<T[]>, value: T) {
  return array.some((selected) => isEqual(selected, value))
}

export function groupByLiteral<K extends string | number, T>(
  array: T[] | Readonly<T[]>,
  fn: (value: T) => K
): Partial<Record<K, [T, ...T[]]>> {
  return groupBy<T>(array, fn) as Partial<Record<K, [T, ...T[]]>>
}

export function groupByString<T>(
  array: T[] | Readonly<T[]>,
  fn: (value: T) => string
): Record<string, [T, ...T[]]> {
  return groupBy<T>(array, fn) as Record<string, [T, ...T[]]>
}

export function indexByString<T>(
  array: T[] | Readonly<T[]>,
  fn: (value: T) => string
): Record<string, T> {
  return Object.fromEntries(array.map((value) => [fn(value), value]))
}

export function tuple<T, U>(t: T, u: U): [T, U] {
  return [t, u]
}

export function isAtLeastLength<T>(array: T[], length: 0): array is T[]
export function isAtLeastLength<T>(array: T[], length: 1): array is [T, ...T[]]
export function isAtLeastLength<T>(array: T[], length: 2): array is [T, T, ...T[]]
export function isAtLeastLength<T>(array: T[], length: 3): array is [T, T, T, ...T[]]
export function isAtLeastLength<T>(array: T[], length: number): array is T[] {
  return array.length >= length
}

export function isLength<T>(array: ArrayLike<T>, length: 0): array is []
export function isLength<T>(array: ArrayLike<T>, length: 1): array is [T]
export function isLength<T>(array: ArrayLike<T>, length: 2): array is [T, T]
export function isLength<T>(array: ArrayLike<T>, length: 3): array is [T, T, T]
export function isLength<T>(array: ArrayLike<T>, length: number): array is T[]
export function isLength<T>(array: ArrayLike<T>, length: number): array is T[] {
  return array.length === length
}

export function strictMinBy<T>(array: [T, ...ArrayLike<T>], fn: StrictMinFn<T>): T
export function strictMinBy<T>(array: [], fn: StrictMinFn<T>): undefined
export function strictMinBy<T>(array: ArrayLike<T>, fn: StrictMinFn<T>): T | undefined | undefined
export function strictMinBy<T>(array: ArrayLike<T>, fn: StrictMinFn<T>) {
  return minBy(array, fn)
}

export function strictMaxBy<T>(array: [T, ...ArrayLike<T>], fn: StrictMinFn<T>): T
export function strictMaxBy<T>(array: [], fn: StrictMinFn<T>): undefined
export function strictMaxBy<T>(array: ArrayLike<T>, fn: StrictMinFn<T>): T | undefined | undefined
export function strictMaxBy<T>(array: ArrayLike<T>, fn: StrictMinFn<T>) {
  return maxBy(array, fn)
}

type StrictMinFn<T> = (value: T) => string | number | undefined

type ArrayLike<T> = T[] | ReadonlyArray<T>
