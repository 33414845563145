import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import type { FormikErrors } from 'formik'
import { ErrorBoundary } from 'utils/error-boundary'

export function DisplayFormErrors({ errors }: { errors: FormikErrors<any> }) {
  return (
    <ErrorBoundary>
      <DisplayFormErrorsInternal errors={errors} />
    </ErrorBoundary>
  )
}

function DisplayFormErrorsInternal({ errors }: { errors: FormikErrors<any> }) {
  return Object.keys(errors).length === 0 ? null : (
    <Grid item xs={12}>
      <Alert severity="error" variant="outlined">
        {Object.entries(errors).map(([key, value]) => (
          <div key={key}>
            {key}:
            {typeof value === 'string' ? (
              value
            ) : Array.isArray(value) ? (
              value.map((child, index) => (
                <div key={index}>
                  {key} ({index + 1}):{' '}
                  {typeof child === 'string' ? (
                    child
                  ) : child ? (
                    <DisplayFormErrorsInternal errors={child} />
                  ) : (
                    ''
                  )}
                </div>
              ))
            ) : value ? (
              <DisplayFormErrorsInternal errors={value} />
            ) : (
              ''
            )}
          </div>
        ))}
      </Alert>
    </Grid>
  )
}
