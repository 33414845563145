import { Box, Button, TextField } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { uniq } from 'lodash'
import { useState } from 'react'
import {
  useActiveSportEvent,
  useSearchApprovedLicensesWithContext,
  useSportEventInscriptions,
} from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { exportEmergencyPDFs } from 'app/emergency/export-emergency'
import { actions } from 'app/export/table'
import { InscribeButton } from 'app/inscription/inscribe-button'
import { Multiline } from 'app/layout/multiline'
import { useIsSmallerThanSm } from 'app/layout/use-small-screen'
import { useRacemanagerTitle } from 'app/layouts/route-with-error-boundary-and-title'
import { TableBox } from 'app/layouts/table-box'
import { EditLicenseButton } from 'app/license/edit-license-button'
import { ScanQRLicenseButton } from 'app/license/qr-code/scan-qr-license-button'
import {
  allTransponderNumbers,
  downloadEventOrganizerList,
  openLicenseTasksSummary,
} from 'app/mylaps/download-event-organizer-list'
import { downloadLicensesForMylaps } from 'app/mylaps/mylaps-download'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { EmergencyDetailsButton } from 'app/pages/admin/licenses/emergency-button'
import { LicenseAttachmentsButton } from 'app/pages/admin/licenses/license-attachments-button'
import { ImpersonateButton } from 'app/pages/admin/users/user-management'
import { FinancialBox } from 'app/pages/dashboard/financial-box'
import { DownloadLicenseButton } from 'app/pages/licenses/download-license'
import { AdminContext, useAdminContext } from 'app/themes/admin-context'
import { useAssociationContext } from 'app/themes/association-context'
import { useIsAdminOrAssociationAdmin } from 'app/themes/user-context'
import { useYearContext } from 'app/themes/year-context'
import { routes } from 'shared/config/routes'
import { categoryById, categoryByIdRequired, categoryCommonName } from 'shared/data/categories-service'
import {
  displayIssuedNumber,
  fullLicenseId,
  updateLicenseIdsAndCodes,
} from 'shared/data/licenses-service'
import { sectionNameWithIdById } from 'shared/data/sections'
import { ApprovedLicense, ApprovedLicenseWithContext, LicenseWithContext, UserQuery } from 'shared/db/db'
import { licenseTaskFilters } from 'shared/db/license-drafts'
import { licenseTypeName } from 'shared/db/license-type'
import { t } from 'shared/i18n/current'
import { associationNameWithDefault } from 'shared/models/associations'
import { nameWithPlace } from 'shared/models/personal-data'
import { hasMyLapsTransponder, myLapsTransponderMakes } from 'shared/models/transponder'
import { sportEventDatesForLicense } from 'shared/sport-events/sport-events-service'
import { formatDateDe, formatDateWithSecondsSpace, parseDate, parseISO } from 'shared/utils/date'
import { RoutedButton } from 'utils/buttons/routed-button'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { useSearchQuery } from 'utils/router'

interface ApprovedLicensesProps {
  admin: UserQuery
}

export function ApprovedLicenses({ admin }: ApprovedLicensesProps) {
  const adminContext = useAdminContext()
  const search = useSearchQuery()
  const isAdmin = useIsAdminOrAssociationAdmin()
  const { data, loading, error, loadingOrError } = useSearchApprovedLicensesWithContext(
    adminContext,
    search.q,
    true
  )
  const { data: sportEvent } = useActiveSportEvent(admin)
  const { data: inscriptions } = useSportEventInscriptions({ sportEventId: sportEvent?.id })
  const smallerThanSm = useIsSmallerThanSm()
  const { association } = useAssociationContext()

  const title = racemanagerLicensesTitle(t().routes.approvedLicenses, data)
  useRacemanagerTitle(title)

  return (
    <>
      <TableBox
        title={title}
        loading={loading}
        error={error}
        exports={
          <>
            {isAdmin && (
              <Button
                startIcon={<CloudDownload />}
                onClick={() =>
                  exportEmergencyPDFs(
                    data.map((row) => ({
                      associationEmail: association.email,
                      categoryName: categoryCommonName(
                        categoryByIdRequired(row.license.approved.categoryId)
                      ),
                      documents: row.license.documents,
                      license: row.license.approved,
                    }))
                  )
                }
              >
                {t().licensesBoxes.emergencyDetails}
              </Button>
            )}
            <Button startIcon={<CloudDownload />} onClick={() => downloadLicensesForMylaps(data)}>
              {t().licensesBoxes.myLaps}
            </Button>
            <Button startIcon={<CloudDownload />} onClick={() => downloadEventOrganizerList(data)}>
              {t().licensesBoxes.organizerList}
            </Button>
          </>
        }
        data={
          !loadingOrError && {
            headers: [
              { value: t().id, align: 'right' },
              { value: t().open, maxWidth: 100 },
              { value: t().issuedNumber.labelAlternative, align: 'right' },
              { value: t().lastName },
              { value: t().firstName },
              { value: t().association, exportOnly: smallerThanSm },
              { value: t().street, exportOnly: true },
              { value: t().zip, exportOnly: true },
              { value: t().place, exportOnly: true },
              { value: t().country, exportOnly: true },
              { value: t().email, exportOnly: true },
              { value: t().phone, exportOnly: true },
              { value: t().birthdate, exportOnly: true },
              { value: t().birthdateDe, exportOnly: true },
              { value: t().afmSection.label, exportOnly: true },
              { value: t().fmsSection.label, exportOnly: true },
              { value: t().newSamMember.labelAlternative, exportOnly: true },
              { value: t().samMemberNumber.label, exportOnly: true },
              { value: t().samSection.label, exportOnly: true },
              { value: t().parentsInfo, exportOnly: true },
              { value: t().lastYearCategories, exportOnly: true },
              { value: t().lastYearLicenses, exportOnly: true },
              { value: t().lastYearRanks, exportOnly: true },
              { value: t().lastYearStartNumbers, exportOnly: true },
              { value: t().hasTransponder.labelAlternative, exportOnly: true },
              { value: t().transponderMake.labelAlternative, exportOnly: true },
              { value: t().transponderNumber.label, exportOnly: true },
              { value: t().transponderNumbersRf.label, exportOnly: true },
              { value: t().category, maxWidth: 300 },
              { value: t().licenseType, exportOnly: true },
              { value: t().remarks, exportOnly: true },
              { value: t().processedAt, exportOnly: true },
              { value: t().teamName.labelShort, exportOnly: true },
              { value: t().bike, exportOnly: true },
              actions(),
            ],
            // eslint-disable-next-line react/display-name
            contents: data.map((licenseWithContext) => () => {
              const license = licenseWithContext.license
              const birthdate = license.documents.personalData?.birthdate
              const parentsInfo = license.documents.personalData?.parentsInfo
              const processedAt = license.draft.summary?.processedAt
              const category = categoryById(license.approved.categoryId)
              return [
                fullLicenseId(license.approved),
                openLicenseTasksSummary(licenseWithContext.tasks),
                displayIssuedNumber(license.approved),
                license.documents.personalData?.lastName || '',
                license.documents.personalData?.firstName || '',
                category?.year === 2021
                  ? license.approved.association || 'SAM'
                  : associationNameWithDefault(license.approved.licenseAssociation),
                license.documents.personalData?.street || '',
                license.documents.personalData?.zip || '',
                license.documents.personalData?.place || '',
                license.documents.personalData?.country || '',
                license.documents.personalData?.email || '',
                license.documents.personalData?.phone || '',
                birthdate || '',
                formatDateDe(parseDate(birthdate)),
                sectionNameWithIdById(license.documents.personalData?.afmSection),
                sectionNameWithIdById(license.documents.personalData?.fmsSection),
                license.documents.personalData?.newSamMember ? 'x' : '',
                license.documents.personalData?.samMemberNumber || '-',
                sectionNameWithIdById(license.documents.personalData?.samSection),
                typeof parentsInfo === 'string' ? parentsInfo : '',
                license.documents.lastYear?.lastYearCategories || '',
                license.documents.lastYear?.lastYearLicenses || '',
                license.documents.lastYear?.lastYearRanks || '',
                license.documents.lastYear?.lastYearStartNumbers || '',
                hasMyLapsTransponder(license.documents.transponder) ? 'x' : '',
                myLapsTransponderMakes(license.documents.transponder),
                allTransponderNumbers(license.documents.transponder?.transponders, 'MyLaps').join(', '),
                allTransponderNumbers(license.documents.transponder?.transponders, 'RF').join(', '),
                categoryCommonName(category),
                licenseTypeName(license.approved.licenseType),
                <Multiline key="remarks">{license.draft.summary?.remarks || ''}</Multiline>,
                processedAt ? formatDateWithSecondsSpace(parseISO(processedAt)) : '',
                license.draft.categoryDetails?.teamName || '',
                license.draft.categoryDetails?.bikeMake || '',
                <>
                  <FinancialDialog admin={admin} user={{ uid: license.userId }} />
                  {allowedToEditApprovedLicense(licenseWithContext, adminContext) && (
                    <EditLicenseButton licenseWithContext={licenseWithContext} admin={admin} />
                  )}
                  <LicenseAttachmentsButton
                    user={{ uid: license.userId }}
                    documents={license.documents}
                    admin={admin}
                  />
                  <EmergencyDetailsButton user={{ uid: license.userId }} admin={admin} />
                  <ImpersonateButton user={{ uid: license.userId }} />
                  {license.type === 'approved' && (
                    <>
                      <DownloadLicenseButton license={license.approved} type="license" />
                      <DownloadLicenseButton license={license.approved} type="pitLanePass" />
                    </>
                  )}
                  {sportEvent &&
                    sportEventDatesForLicense(sportEvent, license.approved).map((date) => (
                      <InscribeButton
                        key={date}
                        personalData={license.documents.personalData}
                        inscriptions={inscriptions}
                        sportEvent={sportEvent}
                        license={license.approved}
                        admin={admin}
                        date={date}
                      />
                    ))}
                </>,
              ]
            }),
            selected: data.map((licenseWithContext) =>
              licenseWithContext.license.approved.invalidated
                ? 'warning'
                : licenseWithContext.tasks.allVerified
                ? 'success'
                : licenseWithContext.tasks.allDone
                ? ''
                : 'error'
            ),
            ids: data.map(({ license: { id } }) => id),
            rawData: data.map((row) =>
              JSON.stringify({
                tasksDone: row.tasks.tasksDone,
                tasksTotal: row.tasks.tasksTotal,
                lastName: row.license.documents.personalData?.lastName,
                firstName: row.license.documents.personalData?.firstName,
                issuedNumber: row.license.approved.issuedNumber,
                licenseAssociation: row.license.approved.licenseAssociation,
                id: row.license.id,
                sportEvent: sportEvent?.id,
                inscriptions: inscriptions.length,
                smallerThanSm,
              })
            ),
          }
        }
      >
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <TextField
              label={`${t().licensesBoxes.search} ${[...licenseTaskFilters().keys()].join(
                '/'
              )}, AFM/FMS/SAM/...)`}
              variant="outlined"
              size="small"
              fullWidth
              value={search.q}
              onChange={(event) => search.set(event.currentTarget.value)}
            />
          </Box>
          <Box px={1}>
            <RoutedButton to={routes.licenseDrafts.generateTo(search.q)}>
              {routes.licenseDrafts.text()}
            </RoutedButton>
            <RoutedButton to={routes.assignLicense.generateTo(search.q)}>
              {routes.assignLicense.text()}
            </RoutedButton>
            <UpdateLicenseIdsButton setSearch={(query) => search.set(query)} currentSearch={search.q} />
            {sportEvent?.id && (
              <RoutedButton to={routes.inscriptions.generateTo(search.q, sportEvent.id)}>
                {routes.inscriptions.text()}
              </RoutedButton>
            )}
            <ScanQRLicenseButton
              onSuccess={(approvedLicense) => search.set(approvedLicenseQuery(approvedLicense))}
            />
          </Box>
        </Box>
      </TableBox>

      {data.length === 1 && <FinancialBox user={{ uid: data[0].license.userId }} admin={admin} />}
    </>
  )
}

function approvedLicenseQuery(approvedLicense: ApprovedLicense) {
  return `${approvedLicense.uid} ${approvedLicense.categoryId}`
}

interface UpdateLicenseIdsButtonProps {
  currentSearch: string
  setSearch: (query: string) => void
}

export function racemanagerLicensesTitle(
  base: string,
  data: ApprovedLicenseWithContext[] | LicenseWithContext[]
) {
  const uids = uniq(data.map((license) => license.license.userId))
  const title = `${
    uids.length === 1 ? `${base} ${nameWithPlace(data[0].license.documents.personalData)}` : base
  }`

  return title
}

function UpdateLicenseIdsButton({ currentSearch, setSearch }: UpdateLicenseIdsButtonProps) {
  const { error, setError } = useError()
  const [loading, setLoading] = useState(false)
  const { year } = useYearContext()

  return (
    <>
      <Button
        disabled={loading}
        onClick={() =>
          catchAndLog(
            setError,
            async () => {
              const previousSearch = `${currentSearch}`
              setSearch(t().licensesBoxes.setSearchInfo)
              await updateLicenseIdsAndCodes(db, year)
              setSearch(previousSearch)
            },
            setLoading
          )
        }
      >
        {loading ? t().licensesBoxes.assignmentInProgress : t().licensesBoxes.assignLicenseId}
      </Button>
      <Loading loading={loading} />
      <FriendlyError error={error} />
    </>
  )
}

function allowedToEditApprovedLicense(license: ApprovedLicenseWithContext, adminContext: AdminContext) {
  return adminContext.matchesAssociation(license.license.draft.categoryDetails?.licenseAssociation)
}
