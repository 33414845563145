import { DB } from 'shared/db/db'

export const id = '2023-02-08T20-46-31-329Z'

export const description = 'Move payment from deleted user'

export async function up(db: DB) {
  await runWithOrWithoutDryRun(db, true)
  await runWithOrWithoutDryRun(db, false)
}

async function runWithOrWithoutDryRun(db: DB, dryRun: boolean) {
  await verifyAndMoveRef(
    db,
    dryRun,
    'inscriptionBookings/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/-NMKQAzHhzHnQeIkln-P',
    'deleted/inscriptionBookings/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/-NMKQAzHhzHnQeIkln-P'
  )
  await verifyAndMoveRef(
    db,
    dryRun,
    'sportEventInscriptionsV2/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/-NLtrkRHX7IZMjrrRVk-/2023-03-26/-NLttwPUJalKEtJLEVG7',
    'deleted/sportEventInscriptionsV2/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/-NLtrkRHX7IZMjrrRVk-/2023-03-26/-NLttwPUJalKEtJLEVG7'
  )
  await verifyAndMoveRef(
    db,
    dryRun,
    'sportEventInscriptionsV2/inscriptions/-NLtrkRHX7IZMjrrRVk-/2023-03-26/-NLttwPUJalKEtJLEVG7/vGUr6WpowuQ5QKRuWTMgLjcgVTN2',
    'deleted/sportEventInscriptionsV2/inscriptions/-NLtrkRHX7IZMjrrRVk-/2023-03-26/-NLttwPUJalKEtJLEVG7/vGUr6WpowuQ5QKRuWTMgLjcgVTN2'
  )

  await verifyAndSet(
    db,
    dryRun,
    'bills/byReference/230121174233979841061500795/byUid',
    'dQKzR1PIdIUqxjkkeiTPmLBCDsy2'
  )
  await verifyAndSet(
    db,
    dryRun,
    'bills/byReference/230121174233979841061500795/uid',
    'dQKzR1PIdIUqxjkkeiTPmLBCDsy2'
  )

  await verifyAndSet(
    db,
    dryRun,
    'bills/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/230121174233979841061500795/byUid',
    'dQKzR1PIdIUqxjkkeiTPmLBCDsy2'
  )

  await verifyAndSet(
    db,
    dryRun,
    'bills/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/230121174233979841061500795/uid',
    'dQKzR1PIdIUqxjkkeiTPmLBCDsy2'
  )

  await verifyAndMoveRef(
    db,
    dryRun,
    'bills/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/230121174233979841061500795',
    'bills/byRider/dQKzR1PIdIUqxjkkeiTPmLBCDsy2/230121174233979841061500795'
  )

  await verifyAndSet(
    db,
    dryRun,
    'payments/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/WlYyMDIzMDEyMy8zNDg2MzUvMQ==-230121174233979841061500795/uid',
    'dQKzR1PIdIUqxjkkeiTPmLBCDsy2'
  )

  await verifyAndMoveRef(
    db,
    dryRun,
    'payments/byRider/vGUr6WpowuQ5QKRuWTMgLjcgVTN2/WlYyMDIzMDEyMy8zNDg2MzUvMQ==-230121174233979841061500795',
    'payments/byRider/dQKzR1PIdIUqxjkkeiTPmLBCDsy2/WlYyMDIzMDEyMy8zNDg2MzUvMQ==-230121174233979841061500795'
  )
}

async function verifyAndMoveRef(db: DB, dryRun: boolean, from: string, to: string) {
  await ensurePathExists(db, from)
  if (await db.exists(to)) throw new Error(`${to} already exists`)
  if (!dryRun) await db.moveRef(from, to)
}

async function verifyAndSet(db: DB, dryRun: boolean, path: string, data: string) {
  await ensurePathExists(db, path)
  if (!dryRun) await db.set(path, data)
}

async function ensurePathExists(db: DB, from: string) {
  if (!(await db.exists(from))) throw new Error(`${from} does not exist`)
}
