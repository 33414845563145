import { makeStyles } from '@material-ui/core'
import { PaletteColor } from '@material-ui/core/styles/createPalette'
import { SnackbarProvider } from 'notistack'
import { ReactNode } from 'react'
import { samLightGreen } from 'shared/utils/colors'

export function NotistackProvider({ children }: { children?: ReactNode }) {
  const classes = useStyles()
  return <SnackbarProvider classes={classes}>{children}</SnackbarProvider>
}

const useStyles = makeStyles(({ palette }) => ({
  variantSuccess: variant({ ...palette.success, main: samLightGreen, contrastText: '#000' }),
  variantError: variant(palette.error),
  variantInfo: variant(palette.info),
  variantWarning: variant(palette.warning),
}))

function variant(color: PaletteColor) {
  return {
    backgroundColor: important(color.main),
    color: important(color.contrastText),
  }
}

function important(cssAttribute: string) {
  return `${cssAttribute} !important`
}
