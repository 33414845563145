import firebase from 'firebase/app'
import type { GenericApiEndpoint, RacemanagerApiQuery } from 'shared/api/interfaces'

export function initializeFirebaseApp() {
  if (firebase.apps.length === 0) {
    firebase.initializeApp({
      apiKey: 'AIzaSyCIzzxZWLi91WEWd5Vt42c2qcUHh0kAhFo',
      authDomain: 'sam-events.firebaseapp.com',
      databaseURL: 'https://sam-events.firebaseio.com',
      projectId: 'sam-events',
      storageBucket: 'sam-events.appspot.com',
      messagingSenderId: '398984511118',
      appId: '1:398984511118:web:bc5bdab31b3380862353df',
      measurementId: 'G-4SWQ313V17',
    })
    firebase.auth().useDeviceLanguage()
    firebase.analytics()
    firebase.performance()
  }
}

export async function callRacemanagerApi<TEndpoint extends GenericApiEndpoint<any, any, any>>(
  name: TEndpoint['name'],
  query: TEndpoint['query']
): Promise<TEndpoint['result']> {
  const fullQuery: RacemanagerApiQuery<TEndpoint> = { name, query }
  return (await firebaseFunctions().httpsCallable('racemanagerApi')(fullQuery)).data
}

export function firebaseFunctions() {
  return firebase.app().functions('europe-west6')
}
