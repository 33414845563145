import { Typography } from '@material-ui/core'
import { t } from 'shared/i18n/current'
import { DriversLicense } from 'shared/models/DriversLicense'

interface ShowDriversLicenseProps {
  driversLicense: DriversLicense | undefined
}

export function ShowDriversLicense({ driversLicense }: ShowDriversLicenseProps) {
  if (!driversLicense) return null

  return (
    <Typography>
      {t().documentBox.driversLicense}: {driversLicense.id}
    </Typography>
  )
}
