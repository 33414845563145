import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { DownloadableTable } from 'app/layouts/downloadable-table'
import { QRScanResult } from 'shared/api/interfaces'
import { categoryOfAssociationRequired, fullCategoryName } from 'shared/data/categories-service'
import { t } from 'shared/i18n/current'
import { fullName } from 'shared/models/personal-data'

export function QRScannedTable(props: { result: QRScanResult; type: 'license' | 'pit-lane-pass' }) {
  const { result, type } = props
  return (
    <>
      <Box mb={1} mt={1}>
        {result.valid ? (
          <Alert severity="success" variant="filled">
            {t().licensePdf.valid} {type === 'license' && t().licensePdf.togetherWithIdOrPassport}
          </Alert>
        ) : (
          <Alert severity="error">{t().licensePdf.invalid}</Alert>
        )}
      </Box>

      <DownloadableTable
        disableDownloadButtons
        disableCountButton
        data={{
          contents: [
            [
              t().category,
              fullCategoryName(categoryOfAssociationRequired(result.categoryId, result.association)),
            ],
            [t().issuedNumber.labelAlternative, result.issuedNumber],
            [t().name, fullName(result)],
            [t().birthdate, result.birthdate],
            [t().licenseNumber, result.shortId],
            ...(result.association === 'fms'
              ? [[t().licenseType, t().licenseTypes[result.licenseType]]]
              : []),
          ],
          rawData: [],
        }}
      />
    </>
  )
}
