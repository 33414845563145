export function filterValues<K extends string, T>(
  object: Record<K, T>,
  fn: (value: T) => boolean
): Record<K, T> {
  const entries = strictEntries<K, T>(object)
  const mappedEntries = entries.filter(([_key, value]) => fn(value))
  return Object.fromEntries(mappedEntries) as Record<K, T>
}

export function mapValues<K extends string, T, U>(
  object: Record<K, T>,
  fn: (value: T) => U
): Record<K, U>
export function mapValues<K extends string, T, U>(
  object: Partial<Record<K, T>>,
  fn: (value: T) => U
): Partial<Record<K, U>>
export function mapValues<K extends string, T, U>(
  object: Partial<Record<K, T>>,
  fn: (value: T) => U
): Partial<Record<K, U>> {
  const entries = strictEntries<K, T>(object)
  const mappedEntries = entries.map(([key, value]) => [key, fn(value)] as const)
  return Object.fromEntries(mappedEntries) as Record<K, U>
}

export function strictFromEntries<K extends string, V>(
  array: [K, V][] | Readonly<[K, V]>[] | Readonly<[K, V][]>
): Partial<Record<K, V>> {
  return Object.fromEntries(array) as Partial<Record<K, V>>
}

export function strictEntries<K extends string, V>(object: Partial<Record<K, V>>): [K, V][] {
  return Object.entries(object) as [K, V][]
}

export function strictKeys<K extends string>(object: Partial<Record<K, any>>): K[] {
  return Object.keys(object) as K[]
}

export const nullAsUndefined = null as any as undefined
