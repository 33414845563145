import { lighten, darken } from '@material-ui/core'

export const samLightGreenContrast = '#000'

export const samLightGreenLight = '#efff4f'
export const samLightGreen = '#b9d300'
export const samLightGreenDark = '#85a200'

export const samGreenContrast = '#fff'
export const samGreen = '#008542'
export const samGreenLight = '#4ab66e'
export const samGreenDark = '#005718'

export const samYellowLight = '#ffff54'
export const samYellow = '#fedf00'
export const samYellowDark = '#c6ae00'

export const samRedLight = '#ff6349'
export const samRed = '#d62b1e'
export const samRedDark = '#9c0000'

export const samBlueLight = '#5570e0'
export const samBlue = '#0045ad'
export const samBlueDark = '#001f7d'

export const fmsLightBlueContrast = '#fff'
export const fmsLightBlue = '#14affc'
export const fmsLightBlueLight = lighten(fmsLightBlue, 0.42)
export const fmsLightBlueDark = darken(fmsLightBlue, 0.42)

export const fmsBlueContrast = '#fff'
export const fmsBlue = '#2a3b57' // before: '#009bdf'
export const fmsBlueLight = lighten(fmsBlue, 0.62)
export const fmsBlueDark = darken(fmsBlue, 0.62)

export const fmsRedContrast = '#000'
export const fmsRed = '#ed1c23'
export const fmsRedLight = lighten(fmsRed, 0.62)
export const fmsRedDark = darken(fmsRed, 0.62)

export const mxrsOrange = '#f18231'
export const mxrsOrangeLight = lighten(mxrsOrange, 0.62)
export const mxrsOrangeDark = darken(mxrsOrange, 0.62)
export const mxrsOrangeContrast = '#fff'

export const mxrsLightOrange = lighten(mxrsOrange, 0.1)
export const mxrsLightOrangeLight = lighten(mxrsLightOrange, 0.62)
export const mxrsLightOrangeDark = darken(mxrsLightOrange, 0.62)
export const mxrsLightOrangeContrast = '#000'

export const mxrsRedContrast = '#000'
export const mxrsRed = '#ed1c23'
export const mxrsRedLight = lighten(mxrsRed, 0.62)
export const mxrsRedDark = darken(mxrsRed, 0.62)

export const sjmccBlack = '#000'
export const sjmccBlackLight = lighten(sjmccBlack, 0.62)
export const sjmccBlackDark = darken(sjmccBlack, 0.62)
export const sjmccBlackContrast = '#fff'

export const sjmccWhite = '#fff'
export const sjmccWhiteLight = lighten(sjmccWhite, 0.62)
export const sjmccWhiteDark = darken(sjmccWhite, 0.62)
export const sjmccWhiteContrast = '#000'

export const sjmccLightBlack = '#222'
export const sjmccLightBlackLight = lighten(sjmccLightBlack, 0.62)
export const sjmccLightBlackDark = darken(sjmccLightBlack, 0.62)
export const sjmccLightBlackContrast = '#fff'

export const sjmccRedContrast = '#000'
export const sjmccRed = '#ba000d'
export const sjmccRedLight = lighten(sjmccRed, 0.22)
export const sjmccRedDark = darken(sjmccRed, 0.62)

export const afmRed = '#dc1d08'
export const afmRedLight = lighten(afmRed, 0.22)
export const afmRedDark = darken(afmRed, 0.62)
export const afmRedContrast = '#fff'

export const afmLightRed = '#dc1d08'
export const afmLightRedLight = lighten(afmRed, 0.22)
export const afmLightRedDark = darken(afmRed, 0.62)
export const afmLightRedContrast = '#fff'

export const afmErrorOrange = '#ffb900'
export const afmErrorOrangeDark = darken(afmErrorOrange, 0.62)
export const afmErrorOrangeLight = lighten(afmErrorOrange, 0.22)
