import { externalLinks } from 'shared/config/externalLinks'
import { I18nLocales } from 'shared/i18n/i18n-types'
import { ArrayElement } from 'shared/utils/tsc'

export function associationName({ association }: { association: AssociationID }) {
  return associationByID(association).name
}

export function associationNameWithDefault(association: AssociationID | undefined) {
  return associationByID(todoMigrateAssociation(association)).name
}

// TODO: later: migrate data
export function todoMigrateAssociation(association: AssociationID | undefined) {
  return association || 'sam'
}

export function isAssociationByID(id: string): id is AssociationID {
  return associations.some((association) => association.id === id)
}

export function associationByID(id: AssociationID) {
  const association = associations.find((association) => association.id === id)
  if (!association) throw new Error(`Association with ID ${id} not found`)
  return association
}

export function associationIndex(association: AssociationID) {
  return associations.findIndex((a) => a.id === association) + 1
}

export const associations = [
  {
    id: 'afm',
    name: 'AFM',
    homepage: externalLinks.homepages.afm,
    licenseTerms: externalLinks.afmLicenseTerms,
    insuranceTerms: '',
    place: 'Châtel-St-Denis',
    address:
      'Association Fribourgeoise Motocycliste AFM, Route de la Frasse 497, 1618 Châtel-St-Denis, licence@afm-moto.ch',
    email: 'licence@afm-moto.ch',
    paymentInfo: {
      name: 'Association Fribourgeoise Motocycliste',
      iban: 'CH2300768300136373307',
      streetName: 'Route de la Frasse',
      streetNumber: '497',
      zip: '1618',
      place: 'Chatel-St-Denis',
      country: 'CH',
    },
  } as const,
  {
    id: 'fms',
    name: 'Swiss Moto',
    homepage: externalLinks.homepages.fms,
    licenseTerms: externalLinks.fmsLicenseTerms,
    insuranceTerms: externalLinks.fmsInsuranceTerms,
    place: 'Frauenfeld',
    address:
      'Swiss Moto Generalsekretariat, Allmendstrasse 26, 4658 Däniken, sport@swissmoto.org, +41 32 628 20 20',
    email: 'sport@swissmoto.org',
    paymentInfo: {
      name: 'Swiss Moto Generalsekretariat',
      iban: 'CH9609000000120034560',
      streetName: 'Allmendstrasse',
      streetNumber: '26',
      zip: '4658',
      place: 'Däniken',
      country: 'CH',
    },
  } as const,
  {
    id: 'mxrs',
    name: 'MXRS',
    homepage: externalLinks.homepages.mxrs,
    licenseTerms: { de: externalLinks.mxrsLicenseTerms },
    insuranceTerms: '',
    place: 'Luterbach',
    address:
      'MXRS GmbH, Chanton Christian, Unterführungsstr.26, 4542 Luterbach, info@mxrs.ch, +41 32 682 13 64',
    email: 'info@mxrs.ch',
    paymentInfo: {
      name: 'MXRS GmbH, Chanton Christian',
      iban: 'CH1704835099048851000',
      streetName: 'Unterführungsstrasse',
      streetNumber: '26',
      zip: '4542',
      place: 'Luterbach',
      country: 'CH',
    },
  } as const,
  {
    id: 'sam',
    name: 'SAM',
    homepage: externalLinks.homepages.sam,
    licenseTerms: { de: externalLinks.samLicenseTerms },
    insuranceTerms: externalLinks.samInsuranceTerms,
    place: 'Feusisberg',
    address: 'SAM Zentralverwaltung, Firststrasse 15, 8835 Feusisberg, info@s-a-m.ch, +41 44 787 61 30',
    email: 'info@s-a-m.ch',
    paymentInfo: {
      name: 'SAM-Sport',
      iban: 'CH8006935016252140004', // QR-IBAN: 'CH9709000000460013722'
      streetName: 'Firststrasse',
      streetNumber: '15',
      zip: '8835',
      place: 'Feusisberg',
      country: 'CH',
    },
  } as const,
  {
    id: 'sjmcc',
    name: 'SJMCC',
    homepage: externalLinks.homepages.sjmcc,
    licenseTerms: { de: externalLinks.sjmccLicenseTerms },
    insuranceTerms: '',
    place: 'Laupersdorf',
    address: 'SJMCC, Wiblen 8, 9245 Oberbüren, info@sjmcc.ch, +41 79 647 73 12',
    email: 'info@sjmcc.ch',
    paymentInfo: {
      name: 'Schweiz. Jugend-Moto-Cross Club',
      iban: 'CH1980808005013713355',
      streetName: 'Wiblen',
      streetNumber: '8',
      zip: '9245',
      place: 'Oberbüren',
      country: 'CH',
    },
  } as const,
]

export const racemanagerAddress =
  'Racemanager, SAM Zentralverwaltung, Firststrasse 15, 8835 Feusisberg, info@s-a-m.ch, +41 44 787 61 30'

export const racemanagerPaymentInfo = {
  name: 'Racemanager',
  iban: 'CH6206935305807805090', // QR-IBAN: 'CH6730058305807805090'
  bic: 'RBABCH22935',
  email: 'info@s-a-m.ch',
}

export const associationIDs = associations.map(({ id }) => id)

export function associationPath(props: { association: AssociationID; locale: I18nLocales }): string {
  const { association, locale } = props
  return `/${association}/${locale}`
}

export function associationPathWithoutLocale(props: { association: AssociationID }): string {
  const { association } = props
  return `/${association}`
}

export const independentAssociationID = 'independent'

export type FullAssociation = ArrayElement<typeof associations>
export type AssociationID = ArrayElement<typeof associationIDs>
export type IndependentAssociationID = typeof independentAssociationID
