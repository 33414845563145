import { useAssociationContext } from 'app/themes/association-context'
import { associationsWithLogos } from 'app/themes/associationsWithLogos'
import { AssociationID } from 'shared/models/associations'

export function useAssociations() {
  const activeAssociation = useActiveAssociationID()

  return associationsWithLogos().map((association) => ({
    ...association,
    active: association.id === activeAssociation,
  }))
}

export function useActiveAssociation() {
  return useAssociationContext().association
}

export function useActiveAssociationID(): AssociationID {
  return useAssociationContext().associationID
}
