import { DB } from 'shared/db/db'
import { recalculateTagCaches } from 'shared/tags/tags-service'

export const id = '2022-11-10T00-20-22-751Z'

export const description = 'Init tag caches'

export async function up(db: DB) {
  await recalculateTagCaches(db)
}
