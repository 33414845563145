import { Button, Tooltip } from '@material-ui/core'
import type { ButtonProps, ButtonTypeMap } from '@material-ui/core'

type ButtonWithTooltipProps<
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
> = ButtonProps<D, P> & {
  tooltip: string
}

export function ButtonWithTooltip({ tooltip, ...rest }: ButtonWithTooltipProps) {
  return (
    <Tooltip title={tooltip}>
      <span>
        <Button {...rest} />
      </span>
    </Tooltip>
  )
}
