import { UserId } from 'shared/db/user-id'

export function uploadStatusOk(status: UploadStatus | undefined) {
  return status === 'verified' || status === 'uploaded'
}

export function migrateUploadStatus(
  object: { status: UploadStatus; statusDetails?: UploadStatusDetails } | undefined
): UploadStatusDetails | undefined {
  if (object?.statusDetails) return object.statusDetails

  const status = object?.statusDetails?.status || object?.status
  if (!status) return undefined

  return { date: '2020-12-31T23:00:00.000Z', status, noteInternal: '', noteUser: '', byUid: '' }
}

export interface UploadStatusDetails {
  date: string
  noteUser: string
  noteInternal: string
  status: UploadStatus
  byUid: UserId
}

export type UploadStatus = 'notUploaded' | 'uploaded' | 'verified' | 'invalid'

export type UploadMetadata = UploadMetadataContaining | undefined

export interface UploadMetadataContaining {
  fullPath: string
  name: string
  contentType: string
  contentDisposition: string
  size: number
  createdAt: string
  updatedAt: string
  md5Hash: string
}

export interface CommonUploadMetadata {
  status: UploadStatus
  statusDetails?: UploadStatusDetails
  page1?: UploadMetadata
}
