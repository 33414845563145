import { createStyles, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import { LastYear } from 'shared/models/last-year'
import { Dig } from 'shared/utils/tsc'

interface LastYearProps {
  initialValues?: LastYear
  onSubmit: (data: LastYear) => Promise<void>
  onBack: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function LastYearForm(props: LastYearProps) {
  const classes = useStyles()
  const { initialValues = loadInitialValues(), onBack, onSubmit } = props
  const fields = samFields().lastYear

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema()}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values)
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <Grid container spacing={1} className={classes.gridParent}>
              <Grid item xs={12} style={{ marginTop: 0 }}>
                {fields.noPreviousLicense.field()}
              </Grid>

              {!values.noPreviousLicense && (
                <>
                  <Grid item xs={12}>
                    {fields.lastYearLicenses.field()}
                  </Grid>

                  <Grid item xs={12}>
                    {fields.lastYearCategories.field()}
                  </Grid>

                  <Grid item xs={12}>
                    {fields.lastYearStartNumbers.field()}
                  </Grid>

                  <Grid item xs={12}>
                    {fields.lastYearRanks.field()}
                  </Grid>
                </>
              )}
              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <AccordionNavigationButtons
                isSubmitting={isSubmitting}
                onBack={onBack}
                submitForm={submitForm}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

function loadInitialValues(): LastYear {
  return Object.fromEntries(
    Object.entries(samFields().lastYear).map(([k, v]) => [k, v.default])
  ) as unknown as LastYear
}

function schema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().lastYear).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['lastYear']>
  return Yup.object().defined().shape(validations)
}
