import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { categoryById, fullCategoryCommonName } from 'shared/data/categories-service'
import { ApprovedLicense } from 'shared/db/db'
import { t } from 'shared/i18n/current'

export function ListApprovedLicenses({ approved }: { approved: ApprovedLicense[] }) {
  return approved.length <= 0 ? null : (
    <Box mb={2}>
      <Typography variant="subtitle1" gutterBottom>
        {t().confirmedLicenseForRider}
      </Typography>
      <List disablePadding dense>
        {approved.map((draft) => (
          <ListItem key={draft.categoryId}>
            <ListItemText
              primary={`${fullCategoryCommonName(categoryById(draft.categoryId))} (${
                draft.issuedNumber
              })`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
