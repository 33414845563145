import { Button, Link, ListItem } from '@material-ui/core'
import type { ButtonProps } from '@material-ui/core'
import { ReactNode } from 'react'

interface ExternalLinkProps {
  href: string
  children: ReactNode
  color?: 'inherit'
}

export function ExternalLink({ children, ...rest }: ExternalLinkProps) {
  return (
    <Link {...rest} rel="noreferrer" target="_blank">
      {children}
    </Link>
  )
}

interface ExternalLinkButtonProps extends ButtonProps {
  href: string
  children: ReactNode
}

export function ExternalLinkButton({ children, ...rest }: ExternalLinkButtonProps) {
  const options = { ...rest, rel: 'noreferrer', target: '_blank' }
  return <Button {...options}>{children}</Button>
}

export function ExternalLinkListItem({ children, ...rest }: ExternalLinkProps) {
  return (
    <ListItem {...rest} button component="a" rel="noreferrer" target="_blank">
      {children}
    </ListItem>
  )
}
