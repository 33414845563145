import { useDocuments } from 'app/db/db-hooks/main-db-hooks'
import { ListTransactions } from 'app/pages/billing/list-transactions'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'

export function FinancialBox({ user, admin }: { user: UserQuery; admin: UserQuery }) {
  const { data: documents } = useDocuments(user)
  return (
    <ElevatedBox title={t().financials.financials}>
      <ListTransactions documents={documents} admin={admin} user={user} />
    </ElevatedBox>
  )
}
