import { Box, LinearProgress, Paper, Typography } from '@material-ui/core'
import { Center } from 'app/layout/center'
import { useIsSmallerThanLg } from 'app/layout/use-small-screen'

export function CenterLoading({ message, loading = true }: { message?: string; loading?: boolean }) {
  const md = useIsSmallerThanLg()
  return loading ? (
    <Center minHeight="100vh">
      <Paper elevation={3} style={{ minWidth: md ? '90vw' : '50vw' }}>
        <Box p={2}>
          <Loading loading={true} />
          {message && (
            <Box pt={2}>
              <Typography align="center">{message}</Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Center>
  ) : null
}

export function Loading({ loading = true }: { loading?: boolean }) {
  return loading ? <LinearProgress /> : null
}
