import { isInscriptionPaid, isLicensePaid } from 'shared/billing/payment-status'
import { parsePaymentFiles, PaymentFileContents } from 'shared/billing/payments-parser'
import { Year, years } from 'shared/data/license-year'
import { DB, InscriptionBooking, PaymentFile } from 'shared/db/db'
import { sortedTransactionsWithTotal, transactionTotal } from 'shared/db/transactions-service'
import { updateDayInscriptionPublicStatistics } from 'shared/inscription/inscription-payment-status'
import {
  updateAllInscriptionLists,
  updateInscriptionListByInscription,
} from 'shared/inscription/public-inscription-list'
import { Inscription } from 'shared/sport-events/sport-events'

export async function processPaymentFiles(db: DB, fileContents: PaymentFileContents[]) {
  const paymentFiles = await parsePaymentFiles(fileContents)
  await Promise.all(paymentFiles.map((paymentFile) => registerPaymentFile(db, paymentFile)))
}

async function registerPaymentFile(db: DB, paymentFile: PaymentFile) {
  const uids = await db.registerPaymentFile(paymentFile)
  await Promise.all(uids.map((uid) => updateTransactionAndLicenseStates(db, uid)))
}

export async function updateAllTransactionStates(db: DB) {
  const uids = await db.loadAllUids()
  await Promise.all(uids.map((uid) => updateTransactionAndLicenseStates(db, uid)))
}

export async function updateTransactionAndLicenseStates(db: DB, uid: string) {
  await updateTransactionStates(db, uid)
  await Promise.all([
    ...years.flatMap((year) => [updateApprovedLicenses(db, uid, year)]),
    updateInscriptions(db, uid),
  ])
}

export async function updateTransactionStates(db: DB, uid: string) {
  const transactions = sortedTransactionsWithTotal(await db.loadTransactions({ uid }))
  const transactionsWithTotal = transactions.map((transaction) => ({
    ...transaction,
    _transactionTotal: transactionTotal(transaction),
    _remainingBalance: 0,
    _currentTotal: transaction.currentTotal,
  }))
  const lastTransaction = transactionsWithTotal[0]
  if (!lastTransaction) return

  // the balance is swapped so we only have to handle the positive balance case
  const swapped = lastTransaction.currentTotal > 0
  if (swapped) swapBalanceAndTotal(transactionsWithTotal)
  updateRemainingBalance(lastTransaction, transactionsWithTotal)
  if (swapped) swapBalanceAndTotal(transactionsWithTotal)

  const transactionsToUpdate = transactionsWithTotal.filter(
    (transaction) => transaction.remainingBalance !== transaction._remainingBalance
  )
  transactionsToUpdate.forEach(
    (transaction) => (transaction.remainingBalance = transaction._remainingBalance)
  )

  await Promise.all(
    transactionsToUpdate.map((transaction) => db.updateTransactionRemainingBalance(transaction))
  )
}

// TODO: later: day licenses / paid at: to calculate paidAt, this function needs to be extended. For this, this function needs automated tests

function updateRemainingBalance<
  T extends {
    _remainingBalance: number
    _transactionTotal: number
    _currentTotal: number
  }
>(lastTransaction: T, transactionsWithTotal: T[]) {
  let overallTotal = -lastTransaction._currentTotal
  if (overallTotal === 0) return

  const relevant = transactionsWithTotal.filter((transaction) => transaction._transactionTotal > 0)

  relevant.forEach((transaction) => {
    if (overallTotal === 0) return

    const transactionTotal = transaction._transactionTotal
    if (overallTotal <= transactionTotal) {
      transaction._remainingBalance = overallTotal
      overallTotal = 0
    } else {
      transaction._remainingBalance = transactionTotal
      overallTotal -= transactionTotal
    }
  })
}

function swapBalanceAndTotal(
  transactionsWithTotal: {
    _remainingBalance: number
    _transactionTotal: number
    _currentTotal: number
  }[]
) {
  transactionsWithTotal.forEach((transaction) => {
    transaction._remainingBalance = -transaction._remainingBalance
    transaction._transactionTotal = -transaction._transactionTotal
    transaction._currentTotal = -transaction._currentTotal
  })
}

export async function updateApprovedLicenses(db: DB, uid: string, year: Year) {
  const licenses = await db.loadApprovedLicenses({ uid }, year)
  const bookings = await db.loadLicenseBookings({ uid })

  await Promise.all(
    licenses.map(async (license) => {
      const paid = isLicensePaid(license, bookings)
      // TODO: later: if a license was paid once, it should stay paid, no matter what
      // don't just say `if (paid && !license.paid) => otherwise: fix this issue: if the booking does not exist, the license is set to paid. afterwards, the booking is never set to "unpaid".
      if (paid !== license.paid) await db.setApprovedLicensePaid(license, paid, year)
    })
  )
}

export async function updateAllInscriptions(db: DB, year: Year) {
  const inscriptions = await db.loadAllInscriptions()
  const bookings = await db.loadInscriptionBookingsByRider()

  await Promise.all(
    inscriptions.map((inscription) =>
      updateInscriptionWithBookings(db, inscription, bookings[inscription.uid] || [])
    )
  )
  await updateAllInscriptionLists(db, year)
}

export async function updateInscriptions(db: DB, uid: string) {
  const inscriptions = await db.loadInscriptionsByUser({ uid })
  const bookings = await db.loadInscriptionBookings({ uid })

  await Promise.all(
    inscriptions.map((inscription) => updateInscriptionWithBookings(db, inscription, bookings))
  )
}

export async function forceUpdateInscriptionPaymentStatus(db: DB, inscription: Inscription) {
  const bookings = await db.loadInscriptionBookings({ uid: inscription.uid })
  await forceUpdateInscriptionWithBookings(db, inscription, bookings)
}

export async function updateInscriptionPaymentStatus(db: DB, inscription: Inscription) {
  const bookings = await db.loadInscriptionBookings({ uid: inscription.uid })
  await updateInscriptionWithBookings(db, inscription, bookings)
}

async function updateInscriptionWithBookings(
  db: DB,
  inscription: Inscription,
  bookings: InscriptionBooking[]
) {
  const paid = isInscriptionPaid(inscription, bookings)
  // TODO: later: once paid, should it stay paid? if (paid && !inscription.paid) {
  if (paid !== inscription.paid) await forceUpdateInscriptionWithBookings(db, inscription, bookings)
}

export async function forceUpdateInscriptionWithBookings(
  db: DB,
  inscription: Inscription,
  bookings: InscriptionBooking[]
) {
  const paid = isInscriptionPaid(inscription, bookings)
  await db.setInscriptionPaid(inscription, paid)
  await updateInscriptionListByInscription(db, { ...inscription, paid })
  await updateDayInscriptionPublicStatistics(db, { ...inscription, paid })
}
