import { Box, Paper, Typography, useTheme } from '@material-ui/core'
import { FindInPage } from '@material-ui/icons'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { UploadHealthCheckPage } from 'app/pages/health-check/edit-health-check'
import { UploadInsurancePage } from 'app/pages/insurance/edit-insurance'
import { UploadPhoto } from 'app/pages/photo/edit-photo'
import { Documents, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'

interface LicenseAttachmentsButtonProps {
  admin: UserQuery
  user: UserQuery
  documents: Documents
}

export function LicenseAttachmentsButton(props: LicenseAttachmentsButtonProps) {
  const dialog = useDialog()
  return (
    <>
      <IconButtonWithTooltip tooltip={t().documents} onClick={() => dialog.open()}>
        <FindInPage />
      </IconButtonWithTooltip>
      {dialog.isOpen && (
        <ConfirmDialog maxWidth="xl" fullWidth dialog={dialog} title={t().documents}>
          <LicenseAttachments {...props} />
        </ConfirmDialog>
      )}
    </>
  )
}

function LicenseAttachments({ admin, user, documents }: LicenseAttachmentsButtonProps) {
  const theme = useTheme()
  const photo = documents.photo
  const insurance = documents.insurance
  const healthCheck = documents.healthCheck

  return (
    <>
      <Paper elevation={3} style={{ backgroundColor: theme.palette.background.default }}>
        <Box p={2} mb={2}>
          <Typography component="h3" variant="h5">
            {t().insurance.shortTitle}
          </Typography>
          <UploadInsurancePage
            insurance={insurance}
            metadata={insurance?.page1}
            page={1}
            user={user}
            admin={admin}
          />
        </Box>
      </Paper>

      <Paper elevation={3} style={{ backgroundColor: theme.palette.background.default }}>
        <Box p={2} mb={2}>
          <Typography component="h3" variant="h5">
            Foto
          </Typography>
          <UploadPhoto
            key="photo"
            photo={photo}
            metadata={photo?.upload}
            page={1}
            user={user}
            admin={admin}
          />
        </Box>
      </Paper>

      <Paper elevation={3} style={{ backgroundColor: theme.palette.background.default }}>
        <Box p={2} mb={2}>
          <Typography component="h3" variant="h5">
            {t().healthCheck.title}
          </Typography>
          <UploadHealthCheckPage
            healthCheck={healthCheck}
            metadata={healthCheck?.page1}
            page={1}
            user={user}
            admin={admin}
          />
          <UploadHealthCheckPage
            healthCheck={healthCheck}
            metadata={healthCheck?.page2}
            page={2}
            user={user}
            admin={admin}
          />
          <UploadHealthCheckPage
            healthCheck={healthCheck}
            metadata={healthCheck?.page3}
            page={3}
            user={user}
            admin={admin}
          />
        </Box>
      </Paper>
    </>
  )
}
