import { Box, Paper, Typography } from '@material-ui/core'
import { ReactNode } from 'react'

interface ElevatedBoxProps {
  title?: string
  children?: ReactNode
}

export function ElevatedBox({ title, children }: ElevatedBoxProps) {
  return (
    <ElevatedBoxInner title={title} variant="h3">
      {children}
    </ElevatedBoxInner>
  )
}

export function ElevatedBoxSm({ title, children }: ElevatedBoxProps) {
  return (
    <ElevatedBoxInner title={title} variant="h6">
      {children}
    </ElevatedBoxInner>
  )
}

function ElevatedBoxInner({ title, children, variant }: ElevatedBoxProps & { variant: 'h3' | 'h6' }) {
  return (
    <Paper elevation={3}>
      <Box my={2} p={2} pb={1}>
        {title && (
          <Box mb={2}>
            <Typography component={variant} variant={variant}>
              {title}
            </Typography>
          </Box>
        )}

        {children && <Box pb={1}>{children}</Box>}
      </Box>
    </Paper>
  )
}
