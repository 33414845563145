import {
  categoryOfAssociationByLicense,
  fixedLicenseType,
  ShortApprovedLicense,
} from 'shared/data/categories-service'
import { SingleLicenseDraft } from 'shared/db/db'
import { LicenseType } from 'shared/db/license-type'
import { BooleanByAge, CategoryOfAssociation } from 'shared/models/category'
import { isSameOrAfter, isSameOrBefore } from 'shared/utils/date'

export function draftLicenseNeedsHealthCheck(
  draftLicense: SingleLicenseDraft,
  birthdate: string
): boolean {
  const license: ShortApprovedLicense = fixedLicenseType({
    categoryId: draftLicense.categoryId,
    licenseAssociation:
      draftLicense.categoryDetails?.licenseAssociation || draftLicense.category.associations[0],
    licenseType: draftLicense.categoryDetails?.licenseType || 'national',
  })
  return approvedLicenseNeedsHealthCheck(license, birthdate)
}

export function approvedLicenseNeedsHealthCheck(
  approvedLicense: ShortApprovedLicense,
  birthdate: string
): boolean {
  const category = categoryOfAssociationByLicense(approvedLicense)
  return needsHealthCheck({
    category,
    licenseType: approvedLicense.licenseType || 'national',
    birthdate,
  })
}

export function needsHealthCheck(props: {
  category: CategoryOfAssociation
  licenseType: LicenseType
  birthdate: string
}): boolean {
  const { category, licenseType, birthdate } = props
  return booleanByAge(
    category.needsHealthCheck.filter((healthCheck) => healthCheck.licenseType === licenseType),
    { birthdate }
  )
}

export function booleanByAge(
  booleans: BooleanByAge[] | Readonly<BooleanByAge[]>,
  { birthdate }: { birthdate: string }
) {
  const boolean = booleans.find(
    (boolean) =>
      (!boolean.from || isSameOrBefore(birthdate, boolean.from)) &&
      (!boolean.to || isSameOrAfter(birthdate, boolean.to))
  )

  if (!boolean) throw new Error(`Invalid boolean by age ${JSON.stringify(booleans)}`)

  return boolean.value
}
