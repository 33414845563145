import { FormHelperText, Grid, Typography } from '@material-ui/core'
import type { FormikErrors } from 'formik'
import { samFields } from 'app/forms/fields'
import { externalLinks } from 'shared/config/externalLinks'
import { t } from 'shared/i18n/current'
import { PersonalDataFormData } from 'shared/models/personal-data'
import { ExternalLink } from 'utils/external-link'

export function isSamMembershipField(fieldName: string) {
  return ['newSamMember', 'samMemberNumber', 'samSection'].some((el) => fieldName === el)
}

interface PersonalDataSamFieldsProps {
  lockMemberFields: boolean
  values: PersonalDataFormData
  prefix: '' | 'personalData.'
  errors: FormikErrors<PersonalDataFormData> | undefined
  withTitle?: boolean
}

export function PersonalDataFieldsSam(props: PersonalDataSamFieldsProps) {
  const { lockMemberFields, values, prefix, errors, withTitle } = props
  const fields = samFields().personalData

  return (
    <>
      {withTitle && (
        <Grid item xs={12}>
          <Typography variant="h6">SAM</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        {fields.newSamMember.field({ prefix })}
        {values.newSamMember && (
          <>
            <FormHelperText error>{fields.newSamMember.hintForFine}</FormHelperText>
            <FormHelperText>{fields.newSamMember.hintForNewMembers}</FormHelperText>
          </>
        )}
      </Grid>

      {!values.newSamMember && (
        <Grid item xs={12}>
          {fields.samMemberNumber.field({
            prefix,
            disabled: lockMemberFields && !!values.samMemberNumber && !errors?.samMemberNumber,
          })}
        </Grid>
      )}

      {lockMemberFields && !!values.samMemberNumber && errors && (
        <Grid item xs={12}>
          <FormHelperText>{fields.samMemberNumber.hintForSubmittedLicense}</FormHelperText>
        </Grid>
      )}

      <Grid item xs={12}>
        {fields.samSection.field({
          prefix,
          values: values as unknown as Record<string, unknown>,
          disabled: lockMemberFields && !!values.samSection,
        })}
      </Grid>

      {lockMemberFields && !!values.samSection && (
        <Grid item xs={12}>
          <FormHelperText>
            {fields.samSection.hintForSubmittedLicense}{' '}
            <ExternalLink href={externalLinks.changeSection}>
              {t().licenseForm.steps.personalData.samSectionChange}
            </ExternalLink>
          </FormHelperText>
        </Grid>
      )}
    </>
  )
}
