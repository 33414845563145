import { useState } from 'react'

const enableUserLocation = false
const lastPosition: LatLon = [47.378597, 8.548422]

export type LatLon = [number, number]

export function usePosition() {
  const [position, setPosition] = useState<LatLon>(lastPosition)
  loadPosition().then(setPosition).catch(console.error)
  return position
}

async function loadPosition() {
  if (enableUserLocation) {
    try {
      const currentPosition = await loadCurrentPosition()
      lastPosition[0] = currentPosition.coords.latitude
      lastPosition[1] = currentPosition.coords.longitude
    } catch (error) {
      console.error('Unable to load position:', error)
    }
  }
  return lastPosition
}

function loadCurrentPosition() {
  return new Promise<GeolocationPosition>((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject)
  )
}
