import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { uniqBy } from 'lodash'
import { useState } from 'react'
import * as Yup from 'yup'
import { db } from 'app/db/frontend-db'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { DownloadableTable } from 'app/layouts/downloadable-table'
import {
  LicenseDraftConfirmValues,
  LicenseDraftConfirmValuesWithoutCategoryDetails,
} from 'app/license/forms/admin-form-types'
import { AdministrateApprovedCategories } from 'app/license/forms/administrate-approved-categories'
import {
  AdministrateDraftCategories,
  categoryDetailsSchema,
  loadInitialValuesCategoryDetails,
} from 'app/license/forms/administrate-draft-categories'
import { AdministratePersonalData } from 'app/license/forms/administrate-personal-data'
import { AdministrateTransponder } from 'app/license/forms/administrate-transponder'
import { ListApprovedLicenses } from 'app/license/list-approved-licenses'
import { ListConflictingLicenses } from 'app/license/list-conflicting-licenses'
import { ListOtherLicenses } from 'app/license/list-other-licenses'
import {
  ensureOrderedTransponderIsSet,
  loadInitialTransponderValues,
  toTransponder,
  toTransponderForm,
  transponderSchema,
} from 'app/license/steps/transponder-form-fields'
import {
  loadInitialPersonalDataValues,
  personalDataSchema,
} from 'app/personal-data/personal-data-form-fields'
import { useAdminContext } from 'app/themes/admin-context'
import { useLicenseYear } from 'app/themes/year-context'
import { routes } from 'shared/config/routes'
import {
  categoryById,
  categoryByIdRequired,
  categoryCommonName,
  categoryOfAssociation,
  fixedLicenseType,
} from 'shared/data/categories-service'
import { Year } from 'shared/data/license-year'
import {
  ApprovedLicense,
  Documents,
  LicenseDraftWithDocuments,
  LicenseWithContext,
  UserQuery,
} from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { todoMigrateAssociation } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { CategoryDetails } from 'shared/models/category-details'
import { fullName, PersonalData } from 'shared/models/personal-data'
import { SelectedCategory } from 'shared/models/selected-category'
import { Transponder } from 'shared/models/transponder'
import { truthy } from 'shared/utils/array'
import { formatDate, parseDate } from 'shared/utils/date'
import { validNumber } from 'shared/utils/number'
import {
  mapCategoryDetails,
  mapLastYear,
  mapPersonalData,
  mapSummary,
  mapTransponder,
} from 'shared/views'
import { FriendlyError } from 'utils/errors'
import { ExternalLink } from 'utils/external-link'
import { FormErrors } from 'utils/form-errors'
import { Loading } from 'utils/loading'
import { useErrorSnackbar, useErrorSnackbarForError, useSuccessSnackbar } from 'utils/snackbar'

interface EditLicenseButtonProps {
  licenseWithContext: LicenseWithContext
  admin: UserQuery
}

export function EditLicenseButton(props: EditLicenseButtonProps) {
  const { licenseWithContext, admin } = props
  return (
    <EditOrNewLicenseButton
      documents={licenseWithContext.license.documents}
      licenseWithContext={licenseWithContext}
      admin={admin}
      actionType="edit"
      user={{ uid: licenseWithContext.license.userId }}
    />
  )
}

interface NewLicenseButtonProps {
  documents: Documents
  admin: UserQuery
  user: UserQuery
  licenses: LicenseWithContext[]
}

export function NewLicenseButton({ user, documents, admin, licenses }: NewLicenseButtonProps) {
  return (
    <EditOrNewLicenseButton
      documents={documents}
      licenseWithContext={licenses[0]}
      admin={admin}
      actionType="new"
      user={user}
    />
  )
}

interface EditOrNewLicenseButtonProps {
  documents: Documents
  licenseWithContext: LicenseWithContext | undefined
  admin: UserQuery
  actionType: 'edit' | 'new'
  user: UserQuery
}

function EditOrNewLicenseButton(props: EditOrNewLicenseButtonProps) {
  const { documents, licenseWithContext, admin, actionType, user } = props
  const [onSubmitError, setError] = useState<Error>()
  const confirmLicenseDialog = useDialog()
  const summaryDialog = useDialog()
  const showSuccessMessage = useSuccessSnackbar()
  const showErrorMessage = useErrorSnackbar()
  const showErrorMessageForError = useErrorSnackbarForError()
  const [summaryRemarks, setSummaryRemarks] = useState('')
  const license = licenseWithContext?.license
  const category = categoryOfLicenseWithContext(licenseWithContext)
  const categoryNameWithNumber =
    actionType === 'new' || !license
      ? '-'
      : license.type === 'draft'
      ? `${categoryCommonName(category)} (${license.draft.categoryDetails?.preferredNumber})`
      : `${categoryCommonName(category)} (${license.approved.issuedNumber})`
  const year = useLicenseYear()

  return (
    <>
      <IconButtonWithTooltip
        tooltip={
          actionType === 'new' || !license
            ? t().addNewLicense
            : license.type === 'draft'
            ? t().editLicenseRequest(categoryNameWithNumber)
            : t().editLicense(categoryNameWithNumber)
        }
        onClick={() => confirmLicenseDialog.open()}
      >
        {actionType === 'new' ? <Add /> : <Edit />}
      </IconButtonWithTooltip>
      {confirmLicenseDialog.isOpen && (
        <Formik
          initialValues={loadInitialValues(documents, licenseWithContext)}
          enableReinitialize
          validateOnMount
          validationSchema={schema()}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setError(undefined)
              await updateDatabaseFromFormFields(values, user, year)
              showSuccessMessage(t().alerts.dataSaved)
            } catch (error: any) {
              setError(error)
              showErrorMessage(t().alerts.errorSaving)
              throw error
            }
            setSubmitting(false)
          }}
        >
          {({ submitForm, isSubmitting, dirty, values, errors, touched, isValid }) => (
            <Form>
              <ConfirmDialog
                title={t().acceptLicenseConditions}
                dialog={summaryDialog}
                onConfirm={() =>
                  db.setSummary(
                    user,
                    {
                      remarks: summaryRemarks,
                      acceptTerms: true,
                      processed: true,
                      processedAt: new Date().toISOString(),
                    },
                    year
                  )
                }
                buttonText={t().conditionsAcceptedByRider}
                disabled={!summaryRemarks}
              >
                <Box>
                  <Alert severity="error" variant="outlined">
                    {t().noLicenseRequestAlert}
                  </Alert>

                  <Box mt={2}>
                    {t().licenseRequestAlternative}
                    <ul>
                      <li>{t().licenseRequestAdminConfirmation}</li>
                      <li>{t().licenseRequestRiderConfirmation}</li>
                    </ul>
                    {t().or}
                    <ul>
                      <li>{t().licenseRequestOtherConfirmation}</li>
                    </ul>
                  </Box>
                  <Box py={2} style={{ overflow: 'hidden' }}>
                    <TextField
                      label={t().licenseRequestInternalNote}
                      helperText={t().editLicenseHelperText}
                      variant="outlined"
                      fullWidth
                      multiline
                      value={summaryRemarks}
                      onChange={(e) => setSummaryRemarks(e.target.value)}
                    />
                  </Box>
                  <Box>
                    <ExternalLink href={routes.termsAndDataProtection.to}>
                      {t().acceptTerms.validation}
                    </ExternalLink>
                  </Box>
                </Box>
              </ConfirmDialog>
              <ConfirmDialog
                maxWidth="xl"
                title={
                  actionType === 'edit' && license
                    ? `${t().license} ${actionText(license)}: ${fullName(
                        license.documents.personalData
                      )}, ${categoryCommonName(license.draft.category)} (${
                        license.draft.categoryDetails?.preferredNumber
                      }), UID: ${user.uid}`
                    : `${t().addLicense}, UID: ${user.uid}`
                }
                buttonText={
                  actionType === 'new' || !license
                    ? t().saveLicenseRequest
                    : license.type === 'draft'
                    ? t().confirmLicenseRequest(categoryNameWithNumber)
                    : t().editLicense(categoryNameWithNumber)
                }
                dialog={confirmLicenseDialog}
                onConfirm={async () => {
                  try {
                    if (actionType === 'new') {
                      const summary = await db.loadSummary(user, year)
                      if (!summary) {
                        summaryDialog.open()
                        showErrorMessage(t().noLicenseRequestAlert)
                        return false
                      }
                    }

                    await submitForm()

                    if (actionType === 'edit' && license?.type === 'draft') {
                      await db.approveLicense({ draft: license, admin }, year)
                      showSuccessMessage(t().licenseConfirmed)
                    }
                  } catch (error: any) {
                    showErrorMessageForError(error)
                    return false
                  }
                }}
                disabled={
                  !isValid ||
                  (license &&
                    license.type === 'draft' &&
                    !validPreferredNumber(category, license.draft.categoryDetails?.preferredNumber))
                }
              >
                <LicensesLists licenseWithContext={licenseWithContext} />

                <AdministrateDraftCategories />
                <AdministrateApprovedCategories />
                <AdministrateTransponder user={user} values={values.transponder} />
                <AdministratePersonalData
                  errors={errors.personalData}
                  values={values.personalData}
                  parentsAgree={touched.personalData?.parentsAgree && errors.personalData?.parentsAgree}
                />

                <Loading loading={isSubmitting} />

                {license && license.type === 'draft' && (
                  <Box>
                    <Button
                      fullWidth
                      variant={dirty ? 'contained' : 'outlined'}
                      color={dirty ? 'primary' : 'default'}
                      disabled={!dirty || isSubmitting || !isValid}
                      onClick={submitForm}
                    >
                      {isSubmitting ? t().buttons.saving : t().buttons.save}
                    </Button>
                  </Box>
                )}

                {license && actionType === 'edit' && (
                  <>
                    <Typography variant="h4" component="h3" gutterBottom>
                      {t().licenseRequestSummary}
                    </Typography>

                    <DownloadableTable
                      data={{
                        contents: license
                          ? [
                              [t().category, categoryCommonName(license.draft.category)],
                              ...mapCategoryDetails(fixedLicenseType(license.draft.categoryDetails)),
                              ...mapPersonalData(license.documents.personalData, {
                                uid: license.userId,
                                view: 'admin',
                                associationOfAdmin: license.association,
                              }),
                              ...mapLastYear(license.documents.lastYear),
                              ...mapTransponder(license.documents.transponder),
                              ...mapSummary(license.draft.summary),
                            ]
                          : [],
                        rawData: undefined,
                      }}
                    />
                  </>
                )}

                {!isValid && <FormErrors errors={errors} />}
                <FriendlyError error={onSubmitError} />
              </ConfirmDialog>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

function actionText(license: LicenseDraftWithDocuments) {
  return license.type === 'draft' ? t().licenses.confirm : t().licenses.edit
}

async function updateDatabaseFromFormFields(
  values: LicenseDraftConfirmValues,
  user: { uid: string },
  year: Year
) {
  const approvedLicenses = values.categoryDetails.approved
  const approvedCategoryIds = approvedLicenses.filter(truthy).map((it) => it.categoryId)
  await Promise.all([
    updateDraftCategories(
      user,
      values.categoryDetails.drafts
        .map((it) => fixedLicenseType(it))
        .filter((it) => !approvedCategoryIds.includes(it.categoryId)),
      year
    ),
    updateApprovedCategories(
      user,
      approvedLicenses.map((it) => fixedLicenseType(it)),
      year
    ),
    updateTransponder(user, toTransponder(values.transponder)),
    updatePersonalData(user, {
      ...values.personalData,
      birthdate: formatDate(values.personalData.birthdate),
    }),
  ])
}

async function updateDraftCategories(
  user: { uid: string },
  categoryDetailsWithDuplicates: CategoryDetails[],
  year: Year
) {
  const categoryDetails = uniqBy(categoryDetailsWithDuplicates, ({ categoryId }) => categoryId)
  const selectedCategories: SelectedCategory[] = categoryDetails
    .filter(({ categoryId }) => categoryById(categoryId))
    .map(({ categoryId, licenseAssociation }) => ({
      association: todoMigrateAssociation(licenseAssociation),
      category: categoryId,
      categoryType: categoryByIdRequired(categoryId)?.type,
    }))
  await db.setLicenseDraftCategoryIds(user, selectedCategories, year)
  await Promise.all([
    ...categoryDetails
      .map((it) => fixedLicenseType(it))
      .map((details) => db.setDraftCategoryDetails(user, fixedLicenseType(details), year)),
  ])
}

async function updateApprovedCategories(
  user: { uid: string },
  approvedLicenses: ApprovedLicense[],
  year: Year
) {
  await Promise.all([
    ...approvedLicenses.map((details) =>
      db.setApprovedCategoryDetails(user, fixedLicenseType(details), year)
    ),
  ])
}

async function updateTransponder(user: { uid: string }, transponder: Transponder) {
  await db.setTransponder(user, transponder)
}

async function updatePersonalData(user: { uid: string }, personalData: PersonalData) {
  await db.setPersonalData(user, personalData)
}

function loadInitialValues(
  documents: Documents,
  licenseWithContext: LicenseWithContext | undefined
): LicenseDraftConfirmValues {
  return {
    categoryDetails: loadInitialValuesCategoryDetails(licenseWithContext),
    ...loadInitialValuesFromDocuments(documents),
  }
}

function loadInitialValuesFromDocuments(
  documents: Documents
): LicenseDraftConfirmValuesWithoutCategoryDetails {
  const personalDataOrUndefined = documents.personalData
  const personalData = personalDataOrUndefined
    ? { ...personalDataOrUndefined, birthdate: parseDate(personalDataOrUndefined.birthdate) }
    : loadInitialPersonalDataValues()
  personalData.email = documents.personalData?.email || ''
  const transponder = ensureOrderedTransponderIsSet(
    toTransponderForm(documents.transponder) || loadInitialTransponderValues()
  )
  return { transponder, personalData }
}

function schema() {
  return Yup.object()
    .defined()
    .shape({
      categoryDetails: categoryDetailsSchema(),
      transponder: transponderSchema(true),
      personalData: personalDataSchema(true),
    })
}

function validPreferredNumber(
  category: CategoryOfAssociation | undefined,
  preferredNumber: string | undefined
) {
  return category && (!category.numberChoice || validNumber(preferredNumber))
}

function LicensesLists({ licenseWithContext }: { licenseWithContext: LicenseWithContext | undefined }) {
  const adminContext = useAdminContext()
  const conflicts = licenseWithContext?.conflicts || []
  const approved = licenseWithContext?.approved || []
  const others = licenseWithContext?.others || []
  const category = categoryOfLicenseWithContext(licenseWithContext)

  const filteredConflicts = conflicts.filter((x) =>
    adminContext.matchesAssociation(x.draft.categoryDetails?.licenseAssociation)
  )
  return (
    <>
      <Typography variant="h4" component="h3" gutterBottom>
        {t().licensesAndLicenseRequests}
      </Typography>
      <Grid container spacing={1}>
        <Grid item sm={4} xs={12}>
          {category?.numberChoice && <ListConflictingLicenses conflicts={filteredConflicts} />}
        </Grid>
        <Grid item sm={4} xs={12}>
          <ListApprovedLicenses approved={approved} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ListOtherLicenses others={others} />
        </Grid>
      </Grid>
    </>
  )
}

function categoryOfLicenseWithContext(licenseWithContext: LicenseWithContext | undefined) {
  if (!licenseWithContext) return undefined

  const categoryId =
    licenseWithContext.license.type === 'approved'
      ? licenseWithContext.license.approved.categoryId
      : licenseWithContext.license.draft.categoryId
  return categoryOfAssociation(categoryId, licenseWithContext.license.association)
}
