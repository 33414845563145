import { TableBox } from 'app/layouts/table-box'
import { KPIs } from 'shared/financials/financials-service'
import { t } from 'shared/i18n/current'
import { toChf } from 'shared/utils/number'
import { strictEntries } from 'shared/utils/object'

interface FinancialKPIsProps {
  title: string
  kpis: KPIs
  loading: boolean
  error: unknown
}

export function FinancialKPIs({ title, kpis, loading, error }: FinancialKPIsProps) {
  return (
    <TableBox
      title={title}
      loading={loading}
      error={error}
      data={
        !loading &&
        !error && {
          headers: [{ value: t().position }, { value: t().amountTableCHF, align: 'right' }],
          contents: strictEntries(kpis).map(([key, value]) => [t().kpiTitles[key], toChf(value)]),
          ids: Object.keys(kpis),
          rawData: Object.entries(kpis).map((row) => JSON.stringify(row)),
        }
      }
    />
  )
}
