import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { sortBy } from 'lodash'
import { useState } from 'react'
import { useAsync, useAsyncCallback } from 'react-async-hook'
import { useAllTransactions } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { actions, headerChf, tableHeaders } from 'app/export/table'
import { ButtonWithTooltip } from 'app/layout/button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { TableBox } from 'app/layouts/table-box'
import {
  associationAccountBalance,
  AssociationBalance,
} from 'app/pages/admin/financials/association-balance'
import {
  AddAssociationPaymentButton,
  EditAssociationPaymentButton,
} from 'app/pages/admin/financials/association-payment-form'
import {
  AssociationAndOptionalDateFilter,
  useOptionalFilterState,
} from 'app/pages/admin/financials/date-filter'
import {
  generateAssociationPaymentPain001,
  prettyFormat,
} from 'app/pages/admin/financials/pain-001/generate-association-payment-pain-001'
import { filterTransactions } from 'app/pages/admin/financials/transactions-filter'
import { UserState, useUserContext } from 'app/themes/user-context'
import { generateRandomReferenceNumber } from 'shared/billing/billing-service'
import {
  AssociationPayment,
  AssociationPaymentPaid,
  AssociationPaymentRequested,
  isAssociationPayment,
  isAssociationPaymentPaid,
  isAssociationPaymentRequested,
  UserQuery,
} from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { AssociationID, associationIndex, associationName } from 'shared/models/associations'
import { truthy } from 'shared/utils/array'
import { formatDate, pFormatDate } from 'shared/utils/date'
import { roundChf, toChf } from 'shared/utils/number'
import { toggleSetValue } from 'shared/utils/set'
import { TableData } from 'shared/utils/table-data'
import { DeleteButtonIcon } from 'utils/buttons/delete-button-icon'
import { DebugString } from 'utils/debug'
import { FriendlyError } from 'utils/errors'
import { FlexButtons } from 'utils/flex-buttons'
import { Loading } from 'utils/loading'
import { Disp } from 'utils/react'
import { downloadXml } from 'utils/xml/download-xml'

interface UserFinancialsProps {
  admin: UserQuery
}

export function AssociationFinancials(_props: UserFinancialsProps) {
  const userContext = useUserContext()
  const { filter, setFilter } = useOptionalFilterState()

  const { data: rawData, loading, error, loadingOrError } = useAllTransactions()
  const data = filterTransactions(rawData, userContext, filter)
  const associationPayments: AssociationPayment[] = sortBy(
    data.map(({ transaction }) => transaction).filter(isAssociationPayment),
    (row) => row.date
  ).reverse()
  const [selectedPaymentIds, setSelectedPaymentIds] = useState(new Set<string>())

  const { paid: unroundedAccountBalance } = associationAccountBalance(data)
  const accountBalance = roundChf(unroundedAccountBalance)

  return (
    <>
      <AssociationAndOptionalDateFilter filter={filter} onChange={(filter) => setFilter(filter)} />
      <AssociationBalance
        title={filter.startsAt ? t().accountBalanceChangeAssociation : t().accountBalanceAssociation}
        data={data}
        loading={loading}
        error={error}
      />
      <TableBox
        title={t().associationPayments.title}
        loading={loading}
        error={error}
        data={
          !loadingOrError &&
          tableContents({
            associationPayments,
            userContext,
            accountBalance,
            selectedPaymentIds,
            setSelectedPaymentIds,
          })
        }
      >
        <FlexButtons justifyContent="flex-end">
          {userContext.normalAdmin && (
            <DownloadSelectedPaymentsButton
              selectedPaymentIds={selectedPaymentIds}
              associationPayments={associationPayments}
            />
          )}
          <AddAssociationPaymentButtonWrapper
            accountBalance={accountBalance}
            association={filter.association}
          />
        </FlexButtons>
      </TableBox>
    </>
  )
}

function tableContents(props: {
  accountBalance: number
  associationPayments: AssociationPayment[]
  userContext: UserState
  selectedPaymentIds: Set<string>
  setSelectedPaymentIds?: Disp<Set<string>>
}): TableData {
  const { accountBalance, associationPayments, userContext, selectedPaymentIds, setSelectedPaymentIds } =
    props
  const admin = userContext.normalAdmin
  return {
    headers: tableHeaders(
      (
        [
          userContext.normalAdmin ? ({ value: '', export: false } as const) : undefined,
          { value: t().id, exportOnly: true },
          t().date,
          t().association,
          t().status,
          t().internalRemarks,
          { value: t().uid, exportOnly: true },
          { value: t().adminID, exportOnly: true },
          { value: t().createdAt, exportOnly: true },
          { value: t().updatedAt, exportOnly: true },
          headerChf(),
          actions(),
        ] as const
      ).filter(truthy)
    ),
    contents: associationPayments.map((payment) => [
      ...(userContext.normalAdmin
        ? [
            <FormControlLabel
              key={payment.id}
              control={
                <Checkbox
                  name={payment.id}
                  checked={selectedPaymentIds.has(payment.id)}
                  onChange={() =>
                    setSelectedPaymentIds?.(toggleSetValue(selectedPaymentIds, payment.id))
                  }
                />
              }
              label={''}
            />,
          ]
        : []),
      payment.id,
      pFormatDate(payment.date),
      associationName(payment),
      t().associationPayments.states[payment.status],
      payment.internalRemarks,
      payment.requestedByUid,
      payment.paidByUid || '-',
      payment.createdAt,
      payment.updatedAt,
      toChf(payment.amount),
      <>
        <EditAssociationPaymentButton
          buttonText={t().buttons.edit}
          associationPayment={payment}
          maxAmount={Math.max(payment.amount, maxAmount(userContext, accountBalance))}
          disabled={payment.status === 'paid' && !admin}
        />
        <DeleteButtonIcon
          title={t().delete}
          onConfirm={() => db.deleteAssociationPayment(payment)}
          disabled={payment.status === 'paid' && !admin}
        />
      </>,
    ]),
    selected: associationPayments.map((row) =>
      selectedPaymentIds.has(row.id)
        ? row.status === 'paid'
          ? 'error'
          : 'warning'
        : row.status === 'paid'
        ? 'success'
        : ''
    ),
    rawData: associationPayments.map((row) =>
      JSON.stringify({
        row,
        selected: selectedPaymentIds.has(row.id),
        size: selectedPaymentIds.size,
        forceRerender: Math.random(),
      })
    ),
    ids: associationPayments.map(({ id }) => id),
  }
}

function AddAssociationPaymentButtonWrapper(props: {
  accountBalance: number
  association?: AssociationID
}) {
  const { accountBalance, association } = props
  const userContext = useUserContext()
  const buttonText = userContext.normalAdmin
    ? t().associationPayments.add
    : t().associationPayments.requestPayment
  return (
    <AddAssociationPaymentButton
      buttonText={buttonText}
      amount={accountBalance}
      maxAmount={maxAmount(userContext, accountBalance)}
      association={association}
    />
  )
}

function maxAmount(userContext: UserState, accountBalance: number) {
  return userContext.normalAdmin ? Number.MAX_VALUE : accountBalance
}

function DownloadSelectedPaymentsButton(props: {
  selectedPaymentIds: Set<string>
  associationPayments: AssociationPayment[]
}) {
  const { selectedPaymentIds, associationPayments } = props
  const selectedPayments = associationPayments.filter((payment) => selectedPaymentIds.has(payment.id))
  const userContext = useUserContext()
  const downloadPaymentsCallback = useAsyncCallback(() =>
    downloadSelectedPayments(selectedPayments, userContext, dateOverride)
  )
  const [dateOverride, setDateOverride] = useState<string>('')
  const dialog = useDialog()

  const buttonText = t().associationPayments.downloadSelectedPayments(selectedPaymentIds.size)

  return (
    <>
      <ButtonWithTooltip
        disabled={selectedPaymentIds.size === 0}
        color="primary"
        variant="contained"
        startIcon={<CloudDownload />}
        tooltip={buttonText}
        onClick={dialog.open}
      >
        {buttonText}
      </ButtonWithTooltip>
      <ConfirmDialog
        maxWidth={prettyFormat ? 'xl' : 'sm'}
        fullWidth
        title={buttonText}
        buttonText={downloadPaymentsCallback.loading ? t().buttons.saving : buttonText}
        dialog={dialog}
        onConfirm={() => downloadPaymentsCallback.execute()}
        disabled={downloadPaymentsCallback.loading}
      >
        <Box mb={2}>
          <KeyboardDatePicker
            value={dateOverride || null}
            onChange={(newValue) => {
              const validDate = newValue && !Number.isNaN(newValue?.getTime())
              setDateOverride((validDate && formatDate(newValue)) || '')
            }}
          />
        </Box>
        <Typography>
          {dateOverride
            ? t().financials.overwritePaymentDate(dateOverride)
            : t().financials.paymentDateAlert}
        </Typography>
        {prettyFormat && <Preview selectedPayments={selectedPayments} dateOverride={dateOverride} />}
        <FriendlyError error={downloadPaymentsCallback.error} />
        <Loading loading={downloadPaymentsCallback.loading} />
      </ConfirmDialog>
    </>
  )
}

export function Preview(props: { selectedPayments: AssociationPayment[]; dateOverride: string }) {
  const { selectedPayments, dateOverride } = props
  const userContext = useUserContext()
  const xml = useAsync(
    () =>
      generateAssociationPaymentPain001({
        payments: convertRequestsToPaid(
          selectedPayments.filter(isAssociationPaymentRequested),
          dateOverride,
          userContext
        ),
        executionDate: dateOverride,
      }),
    [selectedPayments, dateOverride, userContext]
  )
  return <DebugString>{xml.status === 'success' ? xml.result : <Loading loading />}</DebugString>
}

async function downloadSelectedPayments(
  selectedPayments: AssociationPayment[],
  userContext: UserState,
  dateOverride: string
) {
  const requestedPayments = selectedPayments.filter(isAssociationPaymentRequested)
  const alreadyPaidPayments = selectedPayments.filter(isAssociationPaymentPaid)
  const converted = convertRequestsToPaid(requestedPayments, dateOverride, userContext)
  await Promise.all(converted.map((payment) => db.updateAssociationPayment(payment)))
  await downloadPain001File([...converted, ...alreadyPaidPayments], dateOverride)
}

function convertRequestsToPaid(
  requestedPayments: AssociationPaymentRequested[],
  dateOverride: string,
  userContext: UserState
) {
  return requestedPayments.map<AssociationPaymentPaid>((requested) => ({
    ...requested,
    desiredDate: requested.date,
    date: dateOverride || requested.date,
    status: 'paid',
    paidByUid: userContext.uid || '',
    paymentReference: generateRandomReferenceNumber(new Date(), associationIndex(requested.association)),
  }))
}

async function downloadPain001File(payments: AssociationPaymentPaid[], executionDate: string) {
  downloadXml(await generateAssociationPaymentPain001({ payments, executionDate }), {
    name: `racemanager-association-payments-${new Date().toISOString()}.xml`,
  })
}
