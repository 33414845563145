import { DayCategory, DayCategoryID } from 'shared/db/day-category'
import { DB } from 'shared/db/db'
import { SportEventId } from 'shared/db/sport-event-id'
import { CategoryId } from 'shared/models/category'
import { TransponderTypeIncludingEmpty } from 'shared/models/transponder-type'
import { SportEvent } from 'shared/sport-events/sport-events'
import { strictFromEntries } from 'shared/utils/object'

export function extractLicenseCategoryDates(props: {
  categoryDates: CategoryDate[]
  licenseCategoryIds: CategoryId[]
}) {
  const { licenseCategoryIds, categoryDates } = props
  return strictFromEntries(
    licenseCategoryIds.map(
      (categoryId) =>
        [
          categoryId,
          categoryDates.filter((entry) => categoryId === entry.categoryId).map(({ date }) => date),
        ] as const
    )
  )
}

export async function createOrUpdateDayCategories(props: {
  db: DB
  sportEvent: SportEventFromForm
  dayCategories: DayCategoryFormData[]
  categoryDates: CategoryDate[]
}): Promise<{ dates: Record<DayCategoryID, string[]>; categories: DayCategory[] }> {
  const { db, dayCategories, sportEvent, categoryDates } = props
  await db.deleteSportEventDayCategories(sportEvent)
  const dayCategoriesWithDates = await Promise.all(
    dayCategories.map((dayCategoryForm) =>
      createOrUpdateDayCategory(db, sportEvent, dayCategoryForm, categoryDates)
    )
  )
  return {
    dates: Object.fromEntries(
      dayCategoriesWithDates.map(({ dayCategory, dates }) => [dayCategory.id, dates] as const)
    ),
    categories: dayCategoriesWithDates.map(({ dayCategory }) => dayCategory),
  }
}

async function createOrUpdateDayCategory(
  db: DB,
  sportEvent: SportEventFromForm,
  dayCategoryForm: DayCategoryFormData,
  categoryDates: CategoryDate[]
) {
  const dayCategory = newDayCategory(sportEvent, dayCategoryForm)
  const dates = datesOfDayCategory(categoryDates, dayCategoryForm)
  return {
    dates,
    dayCategory: dayCategoryForm.storedInDB
      ? await updateSportEventDayCategory(db, dayCategory)
      : await pushSportEventDayCategory(db, dayCategory),
  }
}

async function updateSportEventDayCategory(db: DB, dayCategory: DayCategory): Promise<DayCategory> {
  await db.updateSportEventDayCategory(dayCategory)
  return dayCategory
}

function pushSportEventDayCategory(db: DB, dayCategory: DayCategory): Promise<DayCategory> {
  return db.pushSportEventDayCategory({ ...dayCategory, id: '' })
}

function datesOfDayCategory(categoryDates: CategoryDate[], dayCategoryForm: DayCategoryFormData) {
  return categoryDates
    .filter(({ categoryId }) => categoryId === dayCategoryForm.id)
    .map(({ date }) => date)
}

function newDayCategory(
  sportEvent: { id: SportEventId },
  dayCategoryForm: DayCategoryFormData
): DayCategory {
  if (!sportEvent.id) throw new Error(`Invalid sport event id: ${sportEvent.id}`)

  return {
    sportEvent: sportEvent.id,

    id: dayCategoryForm.id,
    transponder: dayCategoryForm.transponder,
    name: dayCategoryForm.name,
    hint: dayCategoryForm.hint,
    additionalTermsText: dayCategoryForm.additionalTermsText,
    additionalTermsURL: dayCategoryForm.additionalTermsURL,
    price: dayCategoryForm.price,
    needsInsurance: dayCategoryForm.needsInsurance,
    needsEmergency: dayCategoryForm.needsEmergency,
    needsHealthCheck: dayCategoryForm.needsHealthCheck,
    needsBikeInfo: dayCategoryForm.needsBikeInfo,
    advertiseSamInsurance: dayCategoryForm.advertiseSamInsurance,
    myLapsName: dayCategoryForm.myLapsName,
    startListName: dayCategoryForm.myLapsName,
    sidecar: dayCategoryForm.sidecar,
    sidecarPassenger: dayCategoryForm.sidecarPassenger,
    surcharge: dayCategoryForm.surcharge,
    surchargeAfter: dayCategoryForm.surchargeAfter,
    discount: dayCategoryForm.discount,
    discountUntil: dayCategoryForm.discountUntil,
    inscriptionsLimit: dayCategoryForm.inscriptionsLimit,
    waitlistLimit: dayCategoryForm.waitlistLimit,
    categoryType: 'dayCategory',
    pricePower: dayCategoryForm.pricePower,
    priceTransponderRental: dayCategoryForm.priceTransponderRental,
    sortPrimaryBy: dayCategoryForm.sortPrimaryBy,
  }
}

export interface CategoryDate {
  categoryId: string
  date: string
}

export type SportEventFromForm = Omit<
  SportEvent,
  'licenseCategoryIds' | 'dayCategoryIds' | 'licenseCategoryDates' | 'dayCategoryDates'
>

export interface DayCategoryFormData {
  id: DayCategoryID
  // sportEvent: SportEventId
  transponder: TransponderTypeIncludingEmpty

  name: string
  hint: string
  additionalTermsText: string
  additionalTermsURL: string

  price: number

  needsInsurance: boolean
  needsEmergency: boolean
  needsHealthCheck: boolean
  needsBikeInfo: boolean

  advertiseSamInsurance: boolean

  myLapsName: string

  sidecar: boolean
  sidecarPassenger: boolean

  surcharge: number
  surchargeAfter: string

  discount: number
  discountUntil: string

  inscriptionsLimit: number
  waitlistLimit: number

  pricePower: number
  priceTransponderRental: number
  sortPrimaryBy: 'paymentDate' | 'enlistingDate'

  storedInDB: boolean
}
