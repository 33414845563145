import { rawSections } from 'shared/data/rawSections'
import { Section } from 'shared/data/section-interface'
import { AssociationID } from 'shared/models/associations'

export function sectionNameWithIdById(id: number | undefined) {
  const section = findSection(id)
  return section ? sectionNameWithId(section) : ''
}

export function sectionName(id: number | undefined) {
  return findSection(id)?.name || ''
}

function findSection(id: number | undefined) {
  return rawSections.find((section) => section.id === id)
}

function sectionNameWithId(section: Section) {
  return `${section.name} (${section.id})`
}

export function allSectionsFiltered(association: AssociationID, onlyAcceptsNewMember: boolean) {
  return onlyAcceptsNewMember ? allSectionsAcceptingNewMembers(association) : allSections(association)
}

function allSectionsAcceptingNewMembers(association: AssociationID) {
  return allSections(association).filter((section) => section.acceptsNewMembers)
}

function allSections(association: AssociationID) {
  return rawSections.filter((section) => section.association === association)
}
