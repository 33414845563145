import { isCategoryId } from 'shared/data/categories-service'
import type { DayCategoryID } from 'shared/db/day-category'
import type { CategoryId } from 'shared/models/category'
import { truthy } from 'shared/utils/array'

export function serializeCategoryIDs(categories: (CategoryId | DayCategoryID)[]) {
  return categories.join(' ')
}

export function deserializeLicenseCategoryIDs(categories: string | undefined): CategoryId[] {
  return innerDeserializeCategoryIDs(categories || '')
    .map((id) => (isCategoryId(id) ? id : undefined))
    .filter(truthy)
}

export function deserializeDayCategoryIDs(dayCategories: string | undefined): DayCategoryID[] {
  return innerDeserializeCategoryIDs(dayCategories || '')
}

function innerDeserializeCategoryIDs(categories: string | undefined): (CategoryId | DayCategoryID)[] {
  return (categories || '').split(' ').filter(truthy)
}
