import { Box, Button, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { ExpandMore, Language } from '@material-ui/icons'
import { Fragment } from 'react'
import { useI18nContext } from 'app/i18n/i18n-context'
import { useUserContext } from 'app/themes/user-context'
import { availableLocales, changeLocaleText } from 'shared/i18n/i18n-types'
import { PreventDefaultLink } from 'utils/prevent-default-link'
import { useMenuWithAnchor } from 'utils/use-menu'

export function ChangeLocaleFooterLinks() {
  const i18n = useI18nContext()
  const user = useUserContext()

  return (
    <>
      {availableLocales.map((locale) => (
        <Fragment key={locale}>
          {i18n.locale === locale ? (
            changeLocaleText[locale]?.buttonText
          ) : (
            <PreventDefaultLink
              key={locale}
              onClick={() => i18n.setLocaleAndUserLocale(user.user, locale)}
            >
              {changeLocaleText[locale]?.buttonText}
            </PreventDefaultLink>
          )}{' '}
        </Fragment>
      ))}
    </>
  )
}

export function ChangeLocaleDropdownMenu() {
  const i18n = useI18nContext()
  const menu = useMenuWithAnchor()
  const user = useUserContext()

  return (
    <Box mr={2} display="flex">
      <Tooltip title="Sprache wechseln / change language / changer la langue" enterDelay={300}>
        <Button color="inherit" onClick={menu.open}>
          <Language />
          <span>{changeLocaleText[i18n.locale]?.buttonText}</span>
          <ExpandMore fontSize="small" />
        </Button>
      </Tooltip>
      <Menu anchorEl={menu.anchorEl} open={menu.isOpen} onClose={menu.close}>
        {availableLocales.map((locale) => (
          <MenuItem
            component="a"
            data-no-link="true"
            key={locale}
            selected={i18n.locale === locale}
            onClick={() => {
              i18n.setLocaleAndUserLocale(user.user, locale)
              menu.close()
            }}
          >
            {changeLocaleText[locale]?.buttonText}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export function ChangeLocaleIconButtons() {
  const i18n = useI18nContext()
  const user = useUserContext()

  return (
    <Box display="flex" flexGrow={1}>
      {availableLocales.map((locale) => (
        <Box key={locale} p={1}>
          <Tooltip title={changeLocaleText[locale]?.changeToLocale} enterDelay={300}>
            <span>
              <IconButton
                disabled={i18n.locale === locale}
                size="small"
                onClick={() => i18n.setLocaleAndUserLocale(user.user, locale)}
              >
                {changeLocaleText[locale]?.shortText}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ))}
    </Box>
  )
}

export function ChangeLocaleWideButtons() {
  const i18n = useI18nContext()
  const user = useUserContext()

  return (
    <Box mb={3} display="flex" justifyContent="center">
      {availableLocales.map((locale) => (
        <Box key={locale} p={1}>
          <Tooltip title={changeLocaleText[locale]?.changeToLocale} enterDelay={300}>
            <span>
              <Button
                disabled={i18n.locale === locale}
                onClick={() => i18n.setLocaleAndUserLocale(user.user, locale)}
              >
                {changeLocaleText[locale]?.buttonText}
              </Button>
            </span>
          </Tooltip>
        </Box>
      ))}
    </Box>
  )
}
