import { sortBy } from 'lodash'
import { InscriptionBooking } from 'shared/db/db'
import { AssociationID } from 'shared/models/associations'
import { SportEvent } from 'shared/sport-events/sport-events'
import { groupByString } from 'shared/utils/array'

export function sportEventTotals(
  data: SportEventFinancialRowByInscription[]
): SportEventFinancialRowSummary {
  return data.reduce<SportEventFinancialRowSummary>(
    (totals, row) => ({ amount: sumAmounts(totals, row) }),
    {
      amount: {
        count: 0,
        total: 0,
        inscription: 0,
        power: 0,
        open: 0,
        dayLicense: 0,
        donation: 0,
        inscriptionDiscount: 0,
      },
    }
  )
}

export function groupByCategoryType(
  data: SportEventFinancialRowByInscription[]
): SportEventFinancialRowByCategoryType[] {
  const grouped = groupByString(data, (data) => data.category.typeName)
  const unsorted = Object.values(grouped).map((rows) =>
    rows.reduce<SportEventFinancialRowByCategoryType>(
      (totals, row) => ({
        categoryTypeName: row.category.typeName,
        amount: sumAmounts(totals, row),
      }),
      {
        categoryTypeName: rows[0].category.typeName,
        amount: {
          count: 0,
          total: 0,
          inscription: 0,
          power: 0,
          open: 0,
          dayLicense: 0,
          donation: 0,
          inscriptionDiscount: 0,
        },
      }
    )
  )
  return sortBy(unsorted, (row) => row.categoryTypeName)
}

export function groupByCategory(
  data: SportEventFinancialRowByInscription[]
): SportEventFinancialRowByCategory[] {
  const grouped = groupByString(data, (data) => data.category.id)
  return Object.values(grouped).map((rows) =>
    rows.reduce<SportEventFinancialRowByCategory>(
      (totals, row) => ({
        category: row.category,
        amount: sumAmounts(totals, row),
      }),
      {
        category: rows[0].category,
        amount: {
          count: 0,
          total: 0,
          inscription: 0,
          power: 0,
          open: 0,
          dayLicense: 0,
          donation: 0,
          inscriptionDiscount: 0,
        },
      }
    )
  )
}

function sumAmounts(
  totals: SportEventFinancialRowTotals,
  row: SportEventFinancialRowTotals
): AmountBySource {
  return {
    count: totals.amount.count + row.amount.count,
    total: totals.amount.total + row.amount.total,
    inscription: totals.amount.inscription + row.amount.inscription,
    power: totals.amount.power + row.amount.power,
    dayLicense: totals.amount.dayLicense + row.amount.dayLicense,
    donation: totals.amount.donation + row.amount.donation,
    inscriptionDiscount: totals.amount.inscriptionDiscount + row.amount.inscriptionDiscount,
    open: totals.amount.open + row.amount.open,
  }
}

export interface SportEventFinancialRowByInscription {
  uid: string
  riderName: string
  category: FinancialCategory
  amount: AmountBySource
  sportEvent: SportEvent | 'unknown'
  association: AssociationID | 'multiple' | 'none'
  bookings: InscriptionBooking[]
}

export interface SportEventFinancialRowByCategory {
  category: FinancialCategory
  amount: AmountBySource
}

export interface SportEventFinancialRowByCategoryType {
  categoryTypeName: string
  amount: AmountBySource
}

export interface SportEventFinancialRowSummary {
  amount: AmountBySource
}

export interface SportEventFinancialRowTotals {
  amount: AmountBySource
}

export interface AmountBySource {
  count: number
  total: number
  inscription: number
  power: number
  dayLicense: number
  donation: number
  inscriptionDiscount: number
  open: number
}

export interface FinancialCategory {
  id: string
  name: string
  typeName: string
}
