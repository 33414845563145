import type firebase from 'firebase/app'
import { createContext, useContext } from 'react'
import {
  useIsAdmin,
  useIsAssociationAdminFirebase,
  useIsNormalAdmin,
  useIsReadonlyAdmin,
} from 'app/db/db-hooks/use-is-admin'
import { User } from 'app/users/user'
import { categoryById } from 'shared/data/categories-service'
import { ApprovedLicense, LicenseDraftWithDocuments } from 'shared/db/db'
import { UserId } from 'shared/db/user-id'
import { todoMigrateAssociation, AssociationID } from 'shared/models/associations'
import { CategoryId } from 'shared/models/category'

export function useUserState(data: UseUserStateProps): UserState {
  const user = data.user || undefined
  const admin = useIsAdmin(user)
  const normalAdmin = useIsNormalAdmin(user)
  const readOnlyAdmin = useIsReadonlyAdmin(user)
  const associationAdmin = useIsAssociationAdminFirebase(user)

  return {
    admin,
    normalAdmin,
    readOnlyAdmin,
    preloadDocuments: admin || normalAdmin || readOnlyAdmin || !!associationAdmin,
    associationAdmin: admin ? undefined : associationAdmin,
    user,
    uid: user?.uid,
    canViewApprovedLicense: (license: ApprovedLicense) =>
      canViewCategory(admin, associationAdmin, license.categoryId),
    canEditApprovedLicense: (license: ApprovedLicense) =>
      admin || license.licenseAssociation === associationAdmin,
    canViewLicense: (license: LicenseDraftWithDocuments) =>
      canViewCategory(
        admin,
        associationAdmin,
        license.type === 'approved' ? license.approved.categoryId : license.draft.categoryId
      ),
    canEditLicense: (license: LicenseDraftWithDocuments) =>
      admin || license.association === associationAdmin,
    canViewCategory: (categoryId: CategoryId) => canViewCategory(admin, associationAdmin, categoryId),
    canViewAssociation: (association: AssociationID) =>
      admin || associationAdmin === todoMigrateAssociation(association),
    canEditAssociation: (association: AssociationID) =>
      normalAdmin || associationAdmin === todoMigrateAssociation(association),
    adminOrAssociationAdmin: admin || !!associationAdmin,
    userLoading: data.loading,
    userError: data.error,
  }
}

function canViewCategory(
  admin: boolean,
  associationAdmin: false | AssociationID,
  categoryId: CategoryId
) {
  return (
    admin ||
    !!categoryById(categoryId)?.associations.some((association) => association === associationAdmin)
  )
}

interface UseUserStateProps {
  user: User | null | undefined
  loading: boolean
  error: firebase.auth.Error | undefined
}

export function useIsAdminOrAssociationAdmin() {
  const userContext = useUserContext()
  return userContext.adminOrAssociationAdmin
}

export function useIsAssociationAdmin() {
  const userContext = useUserContext()
  return userContext.associationAdmin === false || !!userContext.associationAdmin
}

export function useAdmin() {
  return { uid: useUserContext().user?.uid || '' }
}

export function useUserContext() {
  return useContext(UserContext)
}

export function useUserDefinedContext() {
  const context = useContext(UserContext)
  if (!context.user) throw new Error('User not defined')
  return context
}

export const UserContext = createContext(initialState())

function initialState(): UserState {
  return {
    admin: false,
    normalAdmin: false,
    readOnlyAdmin: false,
    associationAdmin: false,
    preloadDocuments: false,
    adminOrAssociationAdmin: false,
    user: undefined,
    uid: undefined,
    userLoading: true,
    userError: undefined,
    canViewApprovedLicense: () => false,
    canEditApprovedLicense: () => false,
    canViewLicense: () => false,
    canEditLicense: () => false,
    canViewCategory: () => false,
    canViewAssociation: () => false,
    canEditAssociation: () => false,
  }
}

export interface UserState {
  admin: boolean
  normalAdmin: boolean
  readOnlyAdmin: boolean
  associationAdmin: AssociationID | false | undefined
  preloadDocuments: boolean
  adminOrAssociationAdmin: boolean
  user: User | undefined
  uid: UserId | undefined
  userLoading: boolean
  userError: firebase.auth.Error | undefined
  canViewApprovedLicense: (license: ApprovedLicense) => boolean
  canEditApprovedLicense: (license: ApprovedLicense) => boolean
  canViewLicense: (license: LicenseDraftWithDocuments) => boolean
  canEditLicense: (license: LicenseDraftWithDocuments) => boolean
  canViewCategory: (association: CategoryId) => boolean
  canViewAssociation: (association: AssociationID) => boolean
  canEditAssociation: (association: AssociationID) => boolean
}
