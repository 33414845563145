import {
  Box,
  createStyles,
  FormHelperText,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import { ShowPersonalData } from 'app/pages/profile/show-personal-data'
import { User } from 'app/users/user'
import { itemsTotal } from 'shared/billing/billing-service'
import { licenseCategoryLineItemsForLicenseForm } from 'shared/category-pricing'
import { routes } from 'shared/config/routes'
import { categoryOfAssociation } from 'shared/data/categories-service'
import { LicenseFormData } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { Summary } from 'shared/models/summary'
import { Dig } from 'shared/utils/tsc'
import { ExternalLink } from 'utils/external-link'

interface SummaryProps {
  user: User
  licenseData: LicenseFormData
  initialValues?: Summary
  onSubmit: (data: Summary) => Promise<void>
  onBack: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function SummaryForm(props: SummaryProps) {
  const classes = useStyles()
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const { initialValues = loadInitialValues(), onBack, onSubmit, user, licenseData } = props
  const fields = samFields().summary
  const prices = licenseCategoryLineItemsForLicenseForm(licenseData)

  if (!prices) return <Box>{t().licenseForm.steps.summary.noBirthdateFound}</Box>

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema()}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit({ ...values, processed: false })
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, touched, errors, values }) => (
          <Form>
            <Grid container spacing={1} className={classes.gridParent}>
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Box m={2}>
                    <Typography component="h4" variant="h6">
                      {t().personalDataBox.title}
                    </Typography>
                  </Box>
                  <Box m={2}>
                    <ShowPersonalData user={user} personalData={licenseData.documents?.personalData} />
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Box m={2}>
                    <Typography component="h4" variant="h6">
                      {t().licenseForm.steps.summary.priceChf}
                    </Typography>
                  </Box>

                  <Box m={2}>
                    <Typography>{t().licenseForm.steps.summary.openCreditsAndDebits}</Typography>
                  </Box>

                  <Box mt={2}>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t().licenseForm.steps.summary.category}</TableCell>
                            {!isXs && (
                              <TableCell>{t().licenseForm.steps.summary.preferredNumber}</TableCell>
                            )}
                            <TableCell style={{ wordBreak: 'keep-all' }} align="right">
                              {t().licenseForm.steps.summary.priceChf}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {prices.map((licenseItem) => (
                            <TableRow key={licenseItem.name}>
                              <TableCell>
                                {licenseItem.name}
                                {licenseItem.type === 'categoryLineItem' &&
                                  categoryOfAssociation(licenseItem.categoryId, licenseItem.association)
                                    ?.numberChoice && (
                                    <Box display={{ xs: 'block', sm: 'none' }}>
                                      {t().licenseForm.steps.summary.preferredNumber}:
                                      {
                                        licenseData.licenseDrafts?.categoryDetails?.[
                                          licenseItem.categoryId
                                        ]?.preferredNumber
                                      }
                                    </Box>
                                  )}
                              </TableCell>
                              {!isXs && (
                                <TableCell>
                                  {licenseItem.type === 'categoryLineItem' &&
                                    licenseData.licenseDrafts?.categoryDetails?.[licenseItem.categoryId]
                                      ?.preferredNumber}
                                </TableCell>
                              )}
                              <TableCell style={{ wordBreak: 'keep-all' }} align="right">
                                {licenseItem.price}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell style={{ fontWeight: 500 }}>{t().licenseForm.total}</TableCell>
                            {!isXs && <TableCell />}
                            <TableCell style={{ fontWeight: 500 }} align="right">
                              {itemsTotal(prices)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Box m={2}>
                    <Typography component="h4" variant="h6">
                      {t().licenseForm.steps.summary.conclusion}
                    </Typography>
                  </Box>
                  <Box m={2}>
                    {fields.acceptTerms.field()}
                    <FormHelperText>
                      {fields.acceptTerms.hintForTerms}{' '}
                      <ExternalLink href={routes.termsAndDataProtection.to}>
                        {t().acceptTerms.validation}
                      </ExternalLink>
                    </FormHelperText>
                    {touched.acceptTerms && errors.acceptTerms && (
                      <FormHelperText error>{errors.acceptTerms}</FormHelperText>
                    )}
                  </Box>
                  <Box m={2}>{fields.remarks.field()}</Box>
                </Paper>
              </Grid>

              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <AccordionNavigationButtons
                isSubmitting={isSubmitting}
                onBack={onBack}
                submitForm={submitForm}
                nextLabel={
                  prices.every((item) => item.subtype === 'transponder')
                    ? t().licenseForm.requestTransponders
                    : multipleLicenses(licenseData)
                    ? t().licenseForm.requestLicenses
                    : t().licenseForm.requestLicense
                }
                disabled={prices.length === 0 || !values.acceptTerms}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

function multipleLicenses(licenseData: LicenseFormData) {
  return (licenseData.licenseDrafts?.categories?.length || 0) > 1
}

function loadInitialValues(): Summary {
  return Object.fromEntries(
    Object.entries(samFields().summary).map(([k, v]) => [k, v.default])
  ) as unknown as Summary
}

function schema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().summary).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['summary']>
  return Yup.object().defined().shape(validations)
}
