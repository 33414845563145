import { convertData } from 'app/export/table'
import { formatDateWithSeconds } from 'shared/utils/date'
import { toFilename } from 'shared/utils/string'
import { TableData } from 'shared/utils/table-data'

export async function downloadXlsx(data: TableData, fileName: string) {
  const xlsx = await import('xlsx')
  const workBook = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(
    workBook,
    xlsx.utils.aoa_to_sheet(convertData(data)),
    `Export ${formatDateWithSeconds(new Date())}`
  )
  xlsx.writeFile(workBook, `${toFilename(fileName)}.xlsx`)
}

export async function parseXlsx<T>(buffer: ArrayBuffer) {
  const xlsx = await import('xlsx')
  const book = xlsx.read(buffer, { type: 'array', cellDates: true })
  return book.SheetNames.flatMap((sheetName) =>
    xlsx.utils.sheet_to_json<T>(book.Sheets[sheetName], { dateNF: 'yyyy-mm-dd' })
  )
}
