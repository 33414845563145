import { PreventDefaultLink } from 'utils/prevent-default-link'
import { SearchQuery } from 'utils/router'

interface SearchForLinkProps {
  children: string
  search: SearchQuery
}

export function SearchLink({ children, search }: SearchForLinkProps) {
  return <PreventDefaultLink onClick={() => search.set(children)}>{children}</PreventDefaultLink>
}
