import { Grid } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { db } from 'app/db/frontend-db'
import { samFields } from 'app/forms/fields'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, UseDialog, useDialog } from 'app/layouts/confirm-dialog'
import { useUserContext } from 'app/themes/user-context'
import { renameManualPaymentTag } from 'shared/billing/payment-service'
import { t } from 'shared/i18n/current'
import { AssociationID, associationName } from 'shared/models/associations'
import { Loading } from 'utils/loading'
import { useSuccessSnackbar } from 'utils/snackbar'

export function RenameManualPaymentTag(props: { tag: string; association: AssociationID }) {
  const { tag, association } = props
  const dialog = useDialog()
  return (
    <>
      <IconButtonWithTooltip tooltip={t().payments.renameTag(tag)} onClick={() => dialog.open()}>
        <Edit />
      </IconButtonWithTooltip>
      {dialog.isOpen && (
        <RenameManualPaymentTagForm dialog={dialog} tag={tag} association={association} />
      )}
    </>
  )
}

type RenameManualPaymentTagFormProps = {
  tag: string
  association: AssociationID
  dialog: UseDialog
}

function RenameManualPaymentTagForm({ dialog, tag, association }: RenameManualPaymentTagFormProps) {
  const userContext = useUserContext()
  const showSuccessMessage = useSuccessSnackbar()

  const fields = samFields().editTag

  return (
    <>
      <Formik
        initialValues={{ tag }}
        validationSchema={Yup.object().defined().shape({ tag: fields.tag.validation })}
        validateOnMount
        onSubmit={async (values, { setSubmitting }) => {
          await renameManualPaymentTag({
            db,
            uid: userContext.uid || '',
            oldTag: tag,
            newTag: values.tag,
            association,
          })
          showSuccessMessage(t().alerts.dataSaved)
          setSubmitting(false)
        }}
      >
        {(form) => {
          return (
            <ConfirmDialog
              maxWidth="xl"
              fullWidth
              title={t().payments.renameTag(tag)}
              buttonText={
                form.isSubmitting ? t().buttons.saving : t().payments.renameTagTo(tag, form.values.tag)
              }
              dialog={dialog}
              onConfirm={() => form.submitForm()}
              disabled={form.isSubmitting || !form.isValid}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity="warning" variant="outlined" closeText={t().financials.riderChanged}>
                      {t().payments.renameTagWarning(tag, associationName({ association }))}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    {fields.tag.field()}
                  </Grid>
                </Grid>
              </Form>
              <Loading loading={form.isSubmitting} />
            </ConfirmDialog>
          )
        }}
      </Formik>
    </>
  )
}
