import { Box, Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { tryToChangeEmail } from 'app/pages/profile/change-email'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { base64Decode } from 'shared/utils/base64'
import { FriendlyError } from 'utils/errors'
import { useQuery } from 'utils/router'

export function ChangeEmailAfterLogin({ user }: { user: User }) {
  const history = useHistory()
  const { query } = useQuery()
  const [reauthenticateEmail, setReauthenticateEmail] = useState('')
  const [error, setError] = useState<any>(null)
  const [confirmationLinkSent, setConfirmationLinkSent] = useState(false)

  const changeEmailTo = query.get('change-email-to')
  if (user.email && user.emailVerified && changeEmailTo && !confirmationLinkSent) {
    setConfirmationLinkSent(true)
    const newEmail = base64Decode(changeEmailTo)
    tryToChangeEmail({ newEmail, user, setError, setReauthenticateEmail, history }).catch(console.error)
  }

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().personalDataBox.changeEmail.title}
        </Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <FriendlyError pb={2} error={error} />

          {(error || reauthenticateEmail) && (
            <Link to={routes.changeEmail.to}>{t().personalDataBox.changeEmail.tryAgain}</Link>
          )}
        </Box>
      </Paper>
    </>
  )
}
