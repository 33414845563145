import { db } from 'app/db/frontend-db'
import {
  UploadPage,
  useDownloadFilePath,
  NewUpload,
  uploadFile,
  toUploadMetadata,
  ensureOldDataIsDeleted,
  extension,
} from 'app/storage/storage'
import { UserQuery } from 'shared/db/db'
import { updateInsuranceUploadStatus } from 'shared/upload/upload-service'

export function useInsuranceFilePath(page: UploadPage) {
  return useDownloadFilePath(insuranceNameRef(page), page.metadata)
}

export async function uploadInsurance(page: NewUpload) {
  const metadata = await uploadFile(page, insurancePageRef(page))
  await db.setInsuranceUpload(page.user, page.page, toUploadMetadata(metadata))
  await updateInsuranceUploadStatus(db, page)
}

export async function deleteInsurance({ user, admin, page, metadata }: UploadPage) {
  await ensureOldDataIsDeleted(metadata?.fullPath)
  await db.deleteInsuranceUpload(user, page)
  await updateInsuranceUploadStatus(db, { user, admin })
}

function insurancePageRef({ user, page, file }: NewUpload) {
  return rawInsurancePageRef({ user, page, ext: extension(file) })
}

function rawInsurancePageRef({ user, ext, page }: { user: UserQuery; page: number; ext: string }) {
  return `insuranceConfirmations/${user.uid}/page-${page}${ext ? '.' : ''}${ext}`
}

function insuranceNameRef({ user, metadata }: UploadPage) {
  return `insuranceConfirmations/${user.uid}/${metadata?.name}`
}
