import { Box, TextField } from '@material-ui/core'
import { History, ExpandMore, ExpandLess } from '@material-ui/icons'
import assertNever from 'assert-never'
import { useEffect } from 'react'
import { UseWithObj } from 'app/db/db-hooks/db-hook-helpers'
import { useAllUserEvents, useUserEventDetails, useUserEvents } from 'app/db/db-hooks/user-events'
import { actions, tableHeaders } from 'app/export/table'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { SimpleDialog, useDialog } from 'app/layouts/confirm-dialog'
import { TableBox } from 'app/layouts/table-box'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { FinancialBox } from 'app/pages/dashboard/financial-box'
import { UserName } from 'app/pages/profile/user-name'
import { useUserContext } from 'app/themes/user-context'
import { UserQuery } from 'shared/db/db'
import { queryParts } from 'shared/db/search'
import { UserEvent } from 'shared/db/user-event'
import { pFormatDateWithSecondsSpaceDe } from 'shared/utils/date'
import { PreWithScroll } from 'utils/debug'
import { useSearchQuery } from 'utils/router'

export function AllUserEvents() {
  const userEvents = useAllUserEvents()
  return <UserEventsTable title={'Benutzeraktivität'} data={userEvents} />
}

export function UserEventsButton({ user, q }: { user: UserQuery; q?: string }) {
  const dialog = useDialog()
  const userContext = useUserContext()

  if (!userContext.normalAdmin) return null

  return (
    <>
      <IconButtonWithTooltip tooltip={`Historie zum Benutzer ${user.uid}`} onClick={dialog.open}>
        <History />
      </IconButtonWithTooltip>
      {dialog.isOpen && (
        <SimpleDialog dialog={dialog} fullWidth>
          <UserEvents user={user} q={q} />
        </SimpleDialog>
      )}
    </>
  )
}

export function UserEvents(props: { user: UserQuery; noDetails?: boolean; q?: string }) {
  const { user, noDetails, q } = props
  const userEvents = useUserEvents(user)
  const title = `Historie zum Benutzer ${user.uid}`
  return <UserEventsTable title={title} data={userEvents} noDetails={noDetails} q={q} />
}

interface UserEventsTableProps {
  title: string
  data: UseWithObj<UserEvent[]>
  noDetails?: boolean
  q?: string
}

export function UserEventsTable({ title, data, noDetails, q }: UserEventsTableProps) {
  const search = useSearchQuery('q-user-event')
  const parts = queryParts(search.q)

  useEffect(() => {
    if (q) search.set(q)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q])

  const rows = noDetails
    ? data.data
    : data.data?.filter((event) =>
        parts.every(
          (part) =>
            event.byUid.toLocaleLowerCase() === part.lower ||
            event.uid.toLocaleLowerCase() === part.lower ||
            event.type.toLocaleLowerCase().includes(part.lower) ||
            event.date.toLocaleLowerCase().includes(part.lower) ||
            event.id?.toLocaleLowerCase() === part.lower
        )
      )

  return (
    <TableBox
      title={title}
      error={data.error}
      loading={data.loading}
      data={{
        headers: tableHeaders(['Benutzer', 'Aktor', 'Datum', 'Aktion', actions()]),
        // eslint-disable-next-line react/display-name
        contents: rows?.map((row) => () => {
          return [
            <UserName key="uid" uid={row.uid} />,
            <UserName key="byUid" uid={row.byUid} />,
            pFormatDateWithSecondsSpaceDe(row.date),
            row.type,
            <>
              {!noDetails && (
                <IconButtonWithTooltip
                  tooltip="Details"
                  onClick={() => search.toggle(row.id || '', ' ')}
                >
                  {parts.map((x) => x.original).includes(row.id || '') ? <ExpandLess /> : <ExpandMore />}
                </IconButtonWithTooltip>
              )}
            </>,
          ]
        }),
        rawData: rows?.map((row) => JSON.stringify({ row, parts })),
      }}
      more={!noDetails && rows.length === 1 ? <ShowUserEvent userEvent={rows[0]} /> : undefined}
    >
      {!noDetails && (
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <TextField
              label={`Suchen (Datum, Typ, UID)`}
              variant="outlined"
              size="small"
              fullWidth
              value={search.q}
              onChange={(event) => search.set(event.currentTarget.value)}
            />
          </Box>
        </Box>
      )}
    </TableBox>
  )
}

function ShowUserEvent({ userEvent }: { userEvent: UserEvent }) {
  const details = useUserEventDetails(userEvent)
  const userContext = useUserContext()
  const event: UserEvent = { ...userEvent, details: details.data }

  if (!userContext.user) return null

  return (
    <>
      <ElevatedBox title="Details">
        <UserEventDetails userEvent={event} />
        {event.details && <PreWithScroll values={event.details} />}
      </ElevatedBox>
      <FinancialBox user={event} admin={userContext.user} />
      <UserEvents user={event} noDetails />
    </>
  )
}

function UserEventDetails({ userEvent }: { userEvent: UserEvent }) {
  if (userEvent.type === 'licenseSubmitted') return <>Keine weiteren Details ersichtlich</>
  else if (userEvent.type === 'signIn') return <>Provider: {userEvent.provider}</>
  else if (userEvent.type === 'upload') return <>Dokument: {userEvent.model}</>
  else if (userEvent.type === 'inscription') return <>Einschreiben: {userEvent.inscriptionType} </>
  else if (userEvent.type === 'editSportEvent')
    return <>Sportveranstaltung bearbeitet: {userEvent.editType} </>
  else if (userEvent.type === 'editManualBooking')
    return <>Manuelle Buchung bearbeitet: {userEvent.editType} </>
  else if (userEvent.type === 'editInscriptionBooking')
    return <>Einschreibebuchung bearbeitet: {userEvent.editType} </>
  else if (userEvent.type === 'editLicenseBooking')
    return <>Lizenzbuchung bearbeitet: {userEvent.editType} </>
  else if (userEvent.type === 'editManualPayment') return <>Zahlung bearbeitet: {userEvent.editType} </>
  else if (userEvent.type === 'renameTag')
    return (
      <>
        Tag umenannt: {userEvent.editType}, {userEvent?.details?.oldTag} zu {userEvent.details?.newTag}{' '}
      </>
    )
  else assertNever(userEvent)
}
