import { Money } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { db } from 'app/db/frontend-db'
import { ButtonWithTooltip } from 'app/layout/button-with-tooltip'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import {
  ManualPaymentFields,
  manualPaymentSchema,
} from 'app/pages/admin/manual-payments/manual-payment-form'
import { useAssociationContext } from 'app/themes/association-context'
import { useAdmin, useUserContext } from 'app/themes/user-context'
import { pushManualPayment } from 'shared/billing/payment-service'
import { Bill, NewManualPayment } from 'shared/db/db'
import { UserId } from 'shared/db/user-id'
import { currentLocale, t } from 'shared/i18n/current'
import { Loading } from 'utils/loading'
import { useSuccessSnackbar } from 'utils/snackbar'

export function AddManualPaymentForBillButton({ bill }: { bill: Bill }) {
  return (
    <AddManualPaymentButton
      bill={bill}
      reference={bill.reference}
      uid={bill.uid}
      amount={bill.paymentInfo.amount}
      internalRemarks=""
    />
  )
}

interface AddManualPaymentButtonProps {
  bill?: Bill
  amount: number
  reference: string
  uid: UserId
  internalRemarks: string
  buttonText?: string
}

export function AddManualPaymentButton(props: AddManualPaymentButtonProps) {
  const { bill, amount, reference, uid, buttonText, internalRemarks } = props
  const admin = useAdmin()
  const dialog = useDialog()
  const associationContext = useAssociationContext()
  const initialValues = {
    amount,
    internalRemarks,
    date: new Date(),
    tag: currentLocale() === 'fr' ? 'Espèces' : 'Bar',
    association: associationContext.association.id,
  }
  const showSuccessMessage = useSuccessSnackbar()
  const userContext = useUserContext()

  return (
    <>
      {buttonText ? (
        <ButtonWithTooltip
          color="primary"
          variant="contained"
          startIcon={<Money />}
          tooltip={t().payments.addManualPaymentTitle}
          onClick={dialog.open}
        >
          {buttonText}
        </ButtonWithTooltip>
      ) : (
        <IconButtonWithTooltip tooltip={t().payments.addManualPaymentTitle} onClick={dialog.open}>
          <Money />
        </IconButtonWithTooltip>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={manualPaymentSchema()}
        validateOnMount
        onSubmit={async (values, { setSubmitting }) => {
          const newManualPayment: NewManualPayment = {
            bill,
            date: values.date.toISOString(),
            amount: values.amount,
            byUid: admin.uid,
            tag: values.tag,
            association: values.association,
            internalRemarks: values.internalRemarks || null,
            reference,
            uid,
          }
          await pushManualPayment(db, newManualPayment, userContext.uid || '')
          showSuccessMessage(t().alerts.dataSaved)
          setSubmitting(false)
        }}
      >
        {(form) => {
          const paymentName =
            (form.values.amount || 0) < 0 ? t().payments.refund : t().financials.payment
          return (
            <ConfirmDialog
              maxWidth="xl"
              fullWidth
              title={t().payments.addNewManualPayment(paymentName)}
              buttonText={
                form.isSubmitting ? t().buttons.saving : t().payments.addManualPayment(paymentName)
              }
              dialog={dialog}
              onConfirm={() => form.submitForm()}
              disabled={form.isSubmitting || !form.isValid}
            >
              <Form>
                <ManualPaymentFields form={form} />
              </Form>
              <Loading loading={form.isSubmitting} />
            </ConfirmDialog>
          )
        }}
      </Formik>
    </>
  )
}
