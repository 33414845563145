import { db } from 'app/db/frontend-db'
import { TextUploadButton } from 'app/upload/upload-button'
import { processPaymentFiles } from 'shared/billing/payment-processor'
import { t } from 'shared/i18n/current'

export function UploadBankStatementButton() {
  return (
    <>
      <TextUploadButton onUpload={async (fileContents) => await processPaymentFiles(db, fileContents)}>
        {t().payments.bankPaymentUpload}
      </TextUploadButton>
    </>
  )
}
