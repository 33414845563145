import { RiderPdfContext, withGeneratedPdfHeader } from 'app/pages/insurance/withGeneratedPdfHeader'
import { t } from 'shared/i18n/current'
import { PersonalData } from 'shared/models/personal-data'
import { downloadPdf, PDFDoc } from 'utils/pdf/pdf'

export function generateHealthCheckForm(personalData: PersonalData) {
  return downloadPdf({ name: 'gesundheitscheck' }, (pdf) => generateHealthCheckPdf(personalData, pdf))
}

async function generateHealthCheckPdf(personalData: PersonalData, pdf: PDFDoc) {
  await withGeneratedPdfHeader(pdf, personalData, (context) => selfDeclaration(context))
}

function selfDeclaration({ pdf, riderName, birthdate }: RiderPdfContext) {
  pdf.text(`${t().pdfInsurance.selfDeclaration.title}`, { align: 'center' })
  pdf.moveDown(3)
  pdf.text(t().pdfInsurance.selfDeclaration.confirmTitle(riderName, birthdate), justify())
  pdf.moveDown(1)
  pdf.list(
    [
      t().pdfInsurance.selfDeclaration.bulletPoint1,
      t().pdfInsurance.selfDeclaration.bulletPoint2,
      t().pdfInsurance.selfDeclaration.bulletPoint3,
      t().pdfInsurance.selfDeclaration.bulletPoint4,
      t().pdfInsurance.selfDeclaration.bulletPoint5,
    ],
    justify()
  )
  pdf.moveDown(2)
  pdf.text(`${t().pdfInsurance.selfDeclaration.endParagraph}`, justify())
  pdf.moveDown(3)
  pdf.text(`${t().pdfInsurance.selfDeclaration.datePlaceRiderSignature}`, justify())
}

function justify() {
  return { align: 'justify' }
}
