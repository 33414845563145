import assertNever from 'assert-never'
import { startCase, uniq } from 'lodash'
import {
  categoryOfAssociationByLicense,
  categoryOfAssociationRequired,
} from 'shared/data/categories-service'
import { sectionNameWithIdById } from 'shared/data/sections'
import { DayCategory } from 'shared/db/day-category'
import {
  ApprovedLicense,
  ApprovedLicenseWithContext,
  InscriptionWithContextAndSportEvent,
  Documents,
} from 'shared/db/db'
import {
  inscriptionGroupName,
  inscriptionGroupNameWithDash,
} from 'shared/inscription/public-inscriptions-names'
import { AssociationID, associationName, todoMigrateAssociation } from 'shared/models/associations'
import { MotocrossBike, PublicBike } from 'shared/models/bike'
import { CategoryOfAssociation } from 'shared/models/category'
import {
  fullName,
  PersonalData,
  reverseFullName,
  reverseFullNameUppercase,
} from 'shared/models/personal-data'
import { NewTransponder } from 'shared/models/transponder'
import { ExportOptions, FormatType } from 'shared/models/transponder-type'
import {
  EnlistedDayInscriptionDayCategory,
  EnlistedDayInscriptionDayCategoryDraft,
  EnlistedDayInscriptionYearCategory,
  EnlistedDayInscriptionYearCategoryDraft,
  EnlistedInscription,
  EnlistedLicenseInscription,
  InscribedInscription,
  Inscription,
  InscriptionGroup,
  isInscribedInscription,
  isUnlistedInscription,
  PublicInscription,
  SportEvent,
} from 'shared/sport-events/sport-events'
import { sportEventCategories } from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'
import { parseInt10, toNumberIfPossible, validNumber } from 'shared/utils/number'
import { TableData } from 'shared/utils/table-data'

export function exportInscriptionsToMylaps(
  inscriptions: InscriptionWithContextAndSportEvent[],
  exportOptions: ExportOptions
) {
  const data = inscriptions
    .map<Record<string, string | number> | undefined>((inscription) =>
      isInscribedInscription(inscription.inscription)
        ? inscription.licenseWithContext
          ? exportApprovedLicenseWithContextToMylaps(
              inscription.licenseWithContext,
              inscription.inscription,
              exportOptions
            )
          : exportDayLicenseToMylaps(inscription, exportOptions)
        : undefined
    )
    .filter(truthy)
  return exportListToMylaps(data)
}

export function exportLicensesToMylaps(licenses: ApprovedLicenseWithContext[]) {
  const data = licenses.map<Record<string, string | number>>((context) => {
    const category = categoryOfAssociationByLicense(context.license.approved)
    return exportApprovedLicenseWithContextToMylaps(context, undefined, {
      transponderType: category.myLapsSpecialExportFormatting ? 'RF' : 'MyLaps',
      format: category.myLapsSpecialExportFormatting ? 'special' : 'normal',
    })
  })
  return exportListToMylaps(data)
}

export function exportListToMylaps(rawData: Record<string, string | number>[]): TableData {
  const data = convertMylapsExportData(rawData)
  if (data.length === 0) return { headers: [], contents: [], rawData: undefined }

  const headers = uniq(data.flatMap((row) => Object.keys(row)))
  return {
    headers: headers.map((header) => ({ value: header })),
    contents: data.map((row) => headers.map((header) => row[header])),
    rawData: undefined,
  }
}

export function exportEnlistedInscriptionToPublicInscription(
  props:
    | ExportEnlistedLicenseInscriptionProps
    | ExportEnlistedDayInscriptionYearCategoryProps
    | ExportEnlistedDayInscriptionDayCategoryProps
) {
  return props.inscription.type === 'enlistedDayInscriptionDayCategory' ||
    props.inscription.type === 'enlistedDayInscriptionDayCategoryDraft'
    ? exportEnlistedDayInscriptionDayCategoryToPublicInscription(props as any)
    : props.inscription.type === 'enlistedDayInscriptionYearCategory' ||
      props.inscription.type === 'enlistedDayInscriptionYearCategoryDraft'
    ? exportEnlistedDayInscriptionYearCategoryToPublicInscription(props as any)
    : props.inscription.type === 'enlistedLicenseInscription'
    ? exportEnlistedLicenseInscriptionToPublicInscription(props as any)
    : assertNever(props.inscription)
}

interface ExportEnlistedLicenseInscriptionProps {
  inscription: EnlistedLicenseInscription
  license: ApprovedLicense
  documents: Documents
  exportOptions: ExportOptions
  inscribed: boolean
}

function exportEnlistedLicenseInscriptionToPublicInscription(
  props: ExportEnlistedLicenseInscriptionProps
): PublicInscription {
  const { inscription, license, documents, exportOptions, inscribed } = props
  const data = exportEntryToStartingListCategoryWithLicense(
    license,
    documents,
    inscription,
    exportOptions
  )
  return {
    ...inscriptionPropsToPublicInscriptionPart(inscription),
    ...dataPropsToPublicInscriptionPart(data),
    inscribed,
  }
}

interface ExportEnlistedDayInscriptionYearCategoryProps {
  inscription: EnlistedDayInscriptionYearCategory | EnlistedDayInscriptionYearCategoryDraft
  documents: Documents
  exportOptions: ExportOptions
  inscribed: boolean
}

export function exportEnlistedDayInscriptionYearCategoryToPublicInscription(
  props: ExportEnlistedDayInscriptionYearCategoryProps
): PublicInscription {
  const { inscription, documents, exportOptions, inscribed } = props
  const category = categoryOfAssociationRequired(inscription.category, inscription.association)
  const isDayLicense = true
  const data = exportEntryToStartingListGeneral(
    category,
    documents,
    publicBikesForInscription(inscription),
    inscription.sidecarPartner,
    inscription.association,
    inscription.type === 'enlistedDayInscriptionYearCategory'
      ? inscription.issuedNumber
      : parsePreferredNumber(inscription),
    inscription.borrowedTransponder?.toString() || '',
    exportOptions,
    isDayLicense,
    inscription.group
  )

  return {
    ...inscriptionPropsToPublicInscriptionPart(inscription),
    ...dataPropsToPublicInscriptionPart(data),
    inscribed,
  }
}

interface ExportEnlistedDayInscriptionDayCategoryProps {
  dayCategory: DayCategory | undefined
  inscription: EnlistedDayInscriptionDayCategory | EnlistedDayInscriptionDayCategoryDraft
  documents: Documents
  exportOptions: ExportOptions
  inscribed: boolean
}

export function exportEnlistedDayInscriptionDayCategoryToPublicInscription(
  props: ExportEnlistedDayInscriptionDayCategoryProps
): PublicInscription {
  const { dayCategory, inscription, documents, exportOptions, inscribed } = props
  if (!dayCategory) throw new Error('dayCategory not found required')

  const isDayLicense = false
  const data = exportEntryToStartingListGeneral(
    dayCategory,
    documents,
    publicBikesForInscription(inscription),
    inscription.sidecarPartner,
    inscription.association,
    inscription.type === 'enlistedDayInscriptionDayCategory'
      ? inscription.issuedNumber
      : parsePreferredNumber(inscription),
    inscription.borrowedTransponder?.toString() || '',
    exportOptions,
    isDayLicense,
    inscription.group
  )
  return {
    ...inscriptionPropsToPublicInscriptionPart(inscription),
    ...dataPropsToPublicInscriptionPart(data),
    inscribed,
  }
}

function parsePreferredNumber({ preferredNumber }: { preferredNumber: string }) {
  return validNumber(preferredNumber) ? parseInt10(preferredNumber) : '?'
}

function inscriptionPropsToPublicInscriptionPart(inscription: EnlistedInscription) {
  return {
    uid: inscription.uid,
    category: inscription.category,
    date: inscription.date,
    year: inscription.year,
    sportEvent: inscription.sportEvent,
    group: inscription.group || null,
  }
}

function dataPropsToPublicInscriptionPart(data: ReturnType<typeof exportEntryToStartingListGeneral>) {
  return {
    categoryName: data.startListCategoryName,
    name: data.publicName,
    bike: data.bike,
    transponder1: data.transponder1,
    transponder2: data.transponder2,
    sponsor: data.sponsor,
    section: data.section,
    association: data.association,
    domicile: data.domicile,
    startingNumber: data.startingNumber,
    nationality: data.nationality || '',
    tires: data.tires,
  }
}

export function bikesForInscription(inscription: Inscription, documents: Documents) {
  return inscription.type === 'unlistedLicenseInscription'
    ? []
    : Object.values(documents.bikes || {}).filter((bike) => inscription.bikeIds?.includes(bike.id))
}

export function publicBikesForInscription(inscription: Inscription): PublicBike {
  return isUnlistedInscription(inscription)
    ? { bikeMake: '', teamName: '' }
    : { bikeMake: inscription.bikeMake || '', teamName: inscription.teamName || '' }
}

function convertMylapsExportData(data: Record<string, string | number>[]) {
  return data.map((row) =>
    Object.fromEntries(
      Object.entries(row).map(([key, value]) => [
        key,
        typeof value === 'string' && value.includes(',') ? value.split(',').join('/') : value,
      ])
    )
  )
}

function exportDayLicenseToMylaps(
  context: InscriptionWithContextAndSportEvent,
  exportOptions: ExportOptions
) {
  const inscription = context.inscription
  const documents = context.documents
  const bike = publicBikesForInscription(inscription)

  if (inscription.type === 'enlistedDayInscriptionYearCategory') {
    const category = categoryOfAssociationRequired(inscription.category, inscription.association)
    const isDayLicense = true
    const data = exportEntryToStartingListGeneral(
      category,
      documents,
      bike,
      inscription.sidecarPartner,
      inscription.association,
      inscription.issuedNumber,
      inscription.borrowedTransponder?.toString() || '',
      exportOptions,
      isDayLicense,
      inscription.group
    )
    return exportDataToMylaps(data)
  }

  if (inscription.type === 'enlistedDayInscriptionDayCategory') {
    const dayCategory = context.dayCategory
    if (!dayCategory) throw new Error('dayCategory not found required')

    const isDayLicense = false
    const data = exportEntryToStartingListGeneral(
      dayCategory,
      documents,
      publicBikesForInscription(inscription),
      inscription.sidecarPartner,
      inscription.association,
      inscription.issuedNumber,
      inscription.borrowedTransponder?.toString() || '',
      exportOptions,
      isDayLicense,
      inscription.group
    )
    return exportDataToMylaps(data)
  }

  return undefined
}

function exportApprovedLicenseWithContextToMylaps(
  context: ApprovedLicenseWithContext,
  inscription: InscribedInscription | undefined,
  exportOptions: ExportOptions
) {
  const approved = context.license.approved
  const documents = context.license.documents
  return exportEntryToMylaps(approved, documents, inscription, exportOptions)
}

function exportEntryToMylaps(
  license: ApprovedLicense,
  documents: Documents,
  inscription: InscribedInscription | undefined,
  exportOptions: ExportOptions
) {
  const data = exportEntryToStartingListCategoryWithLicense(
    license,
    documents,
    inscription,
    exportOptions
  )
  return exportDataToMylaps(data)
}

function exportDataToMylaps(data: ReturnType<typeof exportEntryToStartingListGeneral>) {
  return {
    'St.Nr.': data.startingNumber,
    Fahrernummer: data.startingNumber,
    Wagennummer: '',
    Sektion: data.section,
    Club: associationName({ association: data.association }),
    Klasse: data.categoryName,
    Vorname: data.firstName,
    Nachname: data.lastName,
    Name: data.nameSpecial,
    Transponder1: data.transponder1,
    Transponder2: data.transponder2,
    Bike: data.bike,
    Domicile: data.domicile,
    Nat: data.nationality,
    Email: data.email,
    Sponsor: data.sponsor,
    BikeAndSponsor: [data.bike, data.sponsor].filter(truthy).join(' - ').toUpperCase(),
    Tires: data.tires,
    Gruppe: data.group,
    KlasseMitGruppe: data.startListCategoryNameWithGroup,
  }
}

export function exportEntryToStartingListCategoryWithLicense(
  license: ApprovedLicense,
  documents: Documents,
  inscription: EnlistedInscription | undefined,
  exportOptions: ExportOptions
) {
  const category = categoryOfAssociationRequired(license.categoryId, license.licenseAssociation)
  const sidecarPartner =
    inscription?.sidecarPartner || license.currentSidecarPartner || license.sidecarPartner
  const isDayLicense = false

  return exportEntryToStartingListGeneral(
    category,
    documents,
    { bikeMake: license.bikeMake || '', teamName: license.teamName || '' },
    sidecarPartner,
    todoMigrateAssociation(license.licenseAssociation),
    license.issuedNumber,
    inscription?.borrowedTransponder?.toString() || '',
    exportOptions,
    isDayLicense,
    inscription?.group
  )
}

function exportEntryToStartingListGeneral(
  category: InscriptionCategory,
  documents: Documents,
  bike: PublicBike,
  sidecarPartner: string | undefined,
  association: AssociationID, // TODO: later: | IndependentAssociationID,
  startingNumber: number | string,
  borrowedTransponder: string | undefined,
  exportOptions: ExportOptions,
  isDayLicense: boolean,
  group: InscriptionGroup
) {
  const personalData = documents.personalData
  if (!personalData) throw new Error(`Invalid personal data for documents ${JSON.stringify(documents)}`)

  const privateTransponders = Object.values(documents.transponder?.transponders || {}).filter(
    (transponder) => transponder.type === exportOptions.transponderType
  )

  const hasGroups = !!group
  const startListName = startListCategoryName(category, isDayLicense)
  return {
    startingNumber,
    section: exportSectionName(category, personalData, association),
    association,
    categoryName: myLapsCategoryName(exportOptions.format, category, isDayLicense),
    startListCategoryName: startListName,
    firstName: prefixedFirstName(exportOptions.format, category, personalData),
    lastName: prefixedLastName(exportOptions.format, category, personalData, sidecarPartner),
    nameSpecial: prefixedNameSpecial(exportOptions.format, category, personalData, sidecarPartner),
    bike: bikeMake(exportOptions.format, bike),
    domicile: domicile(exportOptions.format, personalData),
    nationality: personalData.country,
    group: inscriptionGroupName({ group, hasGroups }),
    sponsor: teamName(bike.teamName),
    tires: '',
    publicName: publicName(exportOptions.format, category, personalData, sidecarPartner),
    ...transponders(privateTransponders, borrowedTransponder),
    email: personalData.email,
    startListCategoryNameWithGroup: `${startListName}${inscriptionGroupNameWithDash({
      group,
      hasGroups,
    })}`,
  }
}

export function exportSectionName(
  category: InscriptionCategory,
  personalData: PersonalData,
  eventAssociation: AssociationID
) {
  return sectionNameWithIdById(sectionId(category, personalData, eventAssociation))
}

function sectionId(
  category: InscriptionCategory,
  personalData: PersonalData,
  eventAssociation: AssociationID
) {
  const categoryAssociation = category.categoryType === 'licenseCategory' && category.association
  if (categoryAssociation === 'fms' && personalData.fmsSection) return personalData.fmsSection
  if (categoryAssociation === 'afm' && personalData.afmSection) return personalData.afmSection
  if (categoryAssociation === 'sam' && personalData.samSection) return personalData.samSection

  if (eventAssociation === 'fms' && personalData.fmsSection) return personalData.fmsSection
  if (eventAssociation === 'afm' && personalData.afmSection) return personalData.afmSection
  if (eventAssociation === 'sam' && personalData.samSection) return personalData.samSection

  return personalData.samSection
}

function transponders(privateTransponders: NewTransponder[], borrowedTransponder: string | undefined) {
  const transponders = [
    borrowedTransponder,
    privateTransponders[0]?.transponderNumber,
    privateTransponders[1]?.transponderNumber,
  ]
  const [transponder1 = '', transponder2 = ''] = transponders.filter(truthy).map(toNumberIfPossible)
  return { transponder1, transponder2 }
}

function myLapsCategoryName(
  format: FormatType,
  originalCategory: InscriptionCategory,
  isDayLicense: boolean
) {
  const category = extractMyLapsCategory(format, originalCategory)
  const suffix = category.categoryType === 'licenseCategory' && isDayLicense ? ' / TL' : ''
  return `${category.myLapsName}${suffix}`
}

function extractMyLapsCategory(format: FormatType, category: InscriptionCategory): InscriptionCategory {
  if (category.categoryType === 'dayCategory') return category
  if (category.associations.length === 1) return category
  const requireMyLapsSpecialExportFormatting = format === 'special'
  const candidate = category.associationSpecificDetails.find(
    (detail) => detail.myLapsSpecialExportFormatting === requireMyLapsSpecialExportFormatting
  )
  if (candidate) return { ...category, ...candidate }
  return category
}

function startListCategoryName(category: InscriptionCategory, isDayLicense: boolean) {
  const suffix = category.categoryType === 'licenseCategory' && isDayLicense ? ' / TL' : ''
  return `${category.startListName}${suffix}`
}

function publicName(
  format: FormatType,
  category: InscriptionCategory,
  personalData: PersonalData,
  sidecarPartner: string | undefined
) {
  const partner = sidecarPartnerPublicName(category, sidecarPartner)
  return `${
    format === 'special' ? reverseFullNameUppercase(personalData) : reverseFullName(personalData)
  }${partner}`
}

function sidecarPartnerPublicName(category: InscriptionCategory, sidecarPartner: string | undefined) {
  return category.sidecar ? ` / ${sidecarPartner}` : ''
}

function prefixedFirstName(
  format: FormatType,
  category: InscriptionCategory,
  personalData: PersonalData
): string | number {
  return `${namePrefix(format, category)}${firstName(category, personalData)}`
}

function firstName(category: InscriptionCategory, personalData: PersonalData) {
  return category.sidecar ? `${fullName(personalData)} /` : `${personalData.firstName}`
}

function prefixedLastName(
  format: FormatType,
  category: InscriptionCategory,
  personalData: PersonalData,
  sidecarPartner: string | undefined
): string | number {
  return `${namePrefix(format, category)}${lastName(category, personalData, sidecarPartner)}`
}

function lastName(
  category: InscriptionCategory,
  personalData: PersonalData,
  sidecarPartner: string | undefined
) {
  return category.sidecar
    ? formatSidecarPartnerLowerCase(sidecarPartner || '')
    : `${personalData.lastName}`
}

function namePrefix(format: FormatType, category: InscriptionCategory) {
  return format === 'special'
    ? 'Falsches MyLaps Template => VornameSpecial wählen für Vorname | '
    : category.sidecarPassenger
    ? 'SEITENWAGEN PASSAGIER => Nur seitenwagen fahrer importieren - die Passagier-Liste ist nur zur Kontrolle | '
    : ''
}

function prefixedNameSpecial(
  format: FormatType,
  category: InscriptionCategory,
  personalData: PersonalData,
  sidecarPartner: string | undefined
): string | number {
  const prefix =
    format === 'special'
      ? ''
      : 'Falsches MyLaps Template => Vorname und Nachname wählen für Vor- und Nachname | '
  const postfix = category.sidecar ? ` / ${formatSidecarPartnerSpecialCase(sidecarPartner || '')}` : ''
  return `${prefix}${reverseFullNameUppercase(personalData)}${postfix}`
}

function formatSidecarPartnerLowerCase(sidecarPartner: string) {
  const arr = sidecarPartner.split(' ')
  if (arr.length !== 2) return sidecarPartner

  const [last, first] = arr
  return `${startCase(first.toLowerCase())} ${startCase(last.toLowerCase())}`
}

function formatSidecarPartnerSpecialCase(sidecarPartner: string) {
  const arr = sidecarPartner.split(' ')
  if (arr.length !== 2) return sidecarPartner

  const [last, first] = arr
  return `${last.toUpperCase()} ${startCase(first.toLowerCase())}`
}

function domicile(format: FormatType, personalData: PersonalData): string {
  return format === 'special' ? personalData.place : `${personalData.zip} ${personalData.place}`
}

function bikeMake(format: FormatType, bike: MotocrossBike | PublicBike): string {
  const make = bike.bikeMake.trim().split(',').join('|')
  const fixedMake =
    make.toLowerCase() === 'ktm'
      ? 'KTM'
      : make.toLowerCase() === 'gasgas' ||
        make.toLowerCase() === 'gas-gas' ||
        make.toLowerCase() === 'gas gas'
      ? 'GASGAS'
      : make
  return format === 'special' ? fixedMake.toUpperCase() : startCase(fixedMake)
}

function teamName(name: string): string {
  return name.trim().split(',').join('|')
}

type InscriptionCategory = CategoryOfAssociation | DayCategory

export function sportEventExportOptions(sportEvent: SportEvent): ExportOptions {
  return {
    transponderType: sportEvent.transponderType || 'MyLaps',
    format: sportEventExportFormatting(sportEvent),
  }
}

function sportEventExportFormatting(sportEvent: SportEvent): ExportOptions['format'] {
  if (sportEvent.transponderType === 'RF') return 'special'

  const categories = sportEventCategories(sportEvent).filter(
    (category) => category.categoryType === 'licenseCategory'
  )
  if (categories.length === 0) return 'normal'

  const allCategoriesRequireSpecialFormatting = categories.every((category) =>
    category.associationSpecificDetails.every((specific) => specific.myLapsSpecialExportFormatting)
  )
  return allCategoriesRequireSpecialFormatting ? 'special' : 'normal'
}
