import { tableHeaders } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { CategoryMovement } from 'app/sport-events/inscription-movements-calculation'
import { t } from 'shared/i18n/current'

export function InscriptionMovements({ movements }: { movements: CategoryMovement[] }) {
  if (movements.length === 0) return null

  return (
    <TableBox
      title={t().inscription.moveOrDeleteInscriptionNotice}
      data={{
        contents: movements.map((movement) => [
          movement.categoryId,
          movement.categoryName,
          movement.from,
          movement.to,
        ]),
        rawData: movements.map((movement) => JSON.stringify(movement)),
        headers: tableHeaders([
          { value: t().inscription.categoryId, exportOnly: true },
          t().inscription.categoryId,
          t().inscription.from,
          t().inscription.to,
        ]),
      }}
      error={false}
      loading={false}
      disableDownloadButtons
    />
  )
}
