import { Box, TextField } from '@material-ui/core'
import { useSearchLicensesWithPaidInsurance } from 'app/db/db-hooks/main-db-hooks'
import { TableBox } from 'app/layouts/table-box'
import { useAdminContext } from 'app/themes/admin-context'
import { routes } from 'shared/config/routes'
import { categoryById, categoryCommonName } from 'shared/data/categories-service'
import { UserQuery } from 'shared/db/db'
import { licenseTaskFilters } from 'shared/db/license-drafts'
import { t } from 'shared/i18n/current'
import { formatDateDe, parseDate } from 'shared/utils/date'
import { toChf } from 'shared/utils/number'
import { useSearchQuery } from 'utils/router'

export function ListInsurances(_props: { admin: UserQuery }) {
  const adminContext = useAdminContext()
  const search = useSearchQuery()
  const { data, loading, error, loadingOrError } = useSearchLicensesWithPaidInsurance(
    adminContext,
    search.q
  )

  return (
    <>
      <TableBox
        title={routes.insurances.text()}
        loading={loading}
        error={error}
        data={
          !loadingOrError && {
            headers: [
              { value: t().firstName },
              { value: t().lastName },
              { value: t().street, exportOnly: true },
              { value: t().zip },
              { value: t().place },
              { value: t().country, exportOnly: true },
              { value: t().birthdate, exportOnly: true },
              { value: t().birthdateDe, exportOnly: true },
              { value: t().samMemberNumber.label, exportOnly: true },
              { value: t().category },
              { value: t().amountTableCHF, align: 'right' },
            ],
            contents: data.map(({ licenseWithContext, paidInsurance }) => () => {
              const license = licenseWithContext.license
              const birthdate = license.documents.personalData?.birthdate
              const category = categoryById(license.approved.categoryId)
              return [
                license.documents.personalData?.firstName || '',
                license.documents.personalData?.lastName || '',
                license.documents.personalData?.street || '',
                license.documents.personalData?.zip || '',
                license.documents.personalData?.place || '',
                license.documents.personalData?.country || '',
                birthdate || '',
                formatDateDe(parseDate(birthdate)),
                license.documents.personalData?.samMemberNumber || '-',
                categoryCommonName(category),
                toChf(paidInsurance),
              ]
            }),
            ids: data.map((row) => row.licenseWithContext.license.id),
            rawData: data.map((row) =>
              JSON.stringify({
                personalData: row.licenseWithContext.license.documents.personalData,
                id: row.licenseWithContext.license.id,
                paidInsurance: row.paidInsurance,
              })
            ),
          }
        }
      >
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <TextField
              label={`${t().licensesBoxes.search} ${[...licenseTaskFilters().keys()].join(
                '/'
              )}, AFM/FMS/SAM/...)`}
              variant="outlined"
              size="small"
              fullWidth
              value={search.q}
              onChange={(event) => search.set(event.currentTarget.value)}
            />
          </Box>
        </Box>
      </TableBox>
    </>
  )
}
