import { sumBy } from 'lodash'
import { combineLoadingAndError } from 'app/db/db-hooks/db-hook-helpers'
import { useAllManualPaymentsWithName, useSportEvents } from 'app/db/db-hooks/main-db-hooks'
import { actions } from 'app/export/table'
import { createHierarchy, HierarchyTableBox } from 'app/layouts/hierarchy-table-box'
import { TableBox } from 'app/layouts/table-box'
import { AssociationAndDateFilter, useFilterState } from 'app/pages/admin/financials/date-filter'
import { transactionTableContents } from 'app/pages/admin/financials/flat-ransactions'
import { filterTransactions } from 'app/pages/admin/financials/transactions-filter'
import { RenameManualPaymentTag } from 'app/pages/admin/manual-payments/edit-manual-payment-tag'
import { useUserContext } from 'app/themes/user-context'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { associationByID } from 'shared/models/associations'
import { toChf } from 'shared/utils/number'

interface CashTransactionsProps {
  admin: User
}

export function ManualPayments({ admin }: CashTransactionsProps) {
  const transactions = useAllManualPaymentsWithName()

  const sportEvents = useSportEvents()
  const sportEventsData = sportEvents.data
  const userContext = useUserContext()
  const { filter, setFilter } = useFilterState()

  const { data: rawData } = transactions
  const data = filterTransactions(rawData, userContext, filter)

  const { loading, error, loadingOrError } = combineLoadingAndError([sportEvents, transactions])

  return (
    <>
      <AssociationAndDateFilter filter={filter} onChange={(filter) => setFilter(filter)} />

      <HierarchyTableBox
        title={routes.manualPayments.text()}
        loading={loading}
        error={error}
        data={{
          headers: [
            { value: t().name },
            actions(),
            { value: 'UID', align: 'right' },
            { value: t().payments.sum, align: 'right' },
          ],
          hierarchy: createHierarchy(
            'all',
            routes.manualPayments.text(),
            data,
            [
              {
                group: (t) => t.transaction.association,
                name: (t) => associationByID(t.transaction.association).name,
              },
              {
                group: (t) => t.transaction.tag || '-',
                name: (t) => t.transaction.tag || '-',
                action: (t) => (
                  <RenameManualPaymentTag
                    tag={t.transaction.tag}
                    association={t.transaction.association}
                  />
                ),
              },
              {
                group: (t) => t.transaction.byUid,
                name: (t) => t.byUidName,
              },
            ],
            (transactions, depth) => [
              depth === 3 ? transactions[0].transaction.byUid : '',
              toChf(sumBy(transactions, (t) => t.transaction.amount)),
            ],
            0,
            undefined
          ),
        }}
      />

      <TableBox
        title={routes.manualPayments.text()}
        loading={loading}
        error={error}
        data={!loadingOrError && transactionTableContents(data, admin, userContext, sportEventsData)}
      />
    </>
  )
}
