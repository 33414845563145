import { ArrayElement } from 'shared/utils/tsc'

export type InscriptionStatus = ArrayElement<ReturnType<typeof inscriptionStates>>

export function inscriptionStates() {
  return ['not-inscribed', 'unlisted', 'inscribed', 'inscription-pending'] as const
}

export function migrateInscribed(inscribed: boolean | undefined) {
  return inscribed === undefined ? true : inscribed
}
