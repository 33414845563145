import { Fragment } from 'react'
import { SearchLink } from 'app/pages/admin/payments/search-link'
import { SearchQuery } from 'utils/router'

interface SearchableReferenceDetailsProps {
  reference: string
  search: SearchQuery
}

export function SearchableReferenceDetails({ reference, search }: SearchableReferenceDetailsProps) {
  return (
    <>
      {referenceParts(reference).map((part, index) => (
        <Fragment key={index}>
          {index === 0 ? '' : '-'}
          <SearchLink search={search}>{part}</SearchLink>
        </Fragment>
      ))}
    </>
  )
}

export function ReferenceDetails({ reference }: { reference: string }) {
  const parts = referenceParts(reference)
  return (
    <>
      {parts.map((part, index) => (
        <Fragment key={index}>{part}</Fragment>
      ))}
    </>
  )
}

function referenceParts(reference: string) {
  const slices = [6, 12, 18, 26, 27]
  return slices.map((toSlice, index) => reference.slice(slices[index - 1] || 0, toSlice))
}
