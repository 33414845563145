import { Year, years } from 'shared/data/license-year'
import { updateLicenseIdsAndCodes } from 'shared/data/licenses-service'
import { DB } from 'shared/db/db'

export const id = '2023-10-30T20-43-12-050Z'

export const description = 'Create short id mapping, update short ids (idempotent)'

export async function up(db: DB) {
  await Promise.all(years.map((year) => migrateShortIds(db, year)))
}

async function migrateShortIds(db: DB, year: Year) {
  const licenses = await db.loadAllApprovedLicenses(year)
  await Promise.all(
    licenses.map((license) =>
      license.shortId ? db.setApprovedLicenseShortID(license, license.shortId, year) : license.shortId
    )
  )
  await updateLicenseIdsAndCodes(db, year)
}
