import { Box, LinearProgress, Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDriversLicense } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { DriversLicenseForm } from 'app/driversLicense/DriversLicenseForm'
import { MayBeOptionalMessage } from 'app/pages/dashboard/may-be-optional-message'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { FriendlyError } from 'utils/errors'

interface EditDriversLicenseProps {
  user: UserQuery
  adminView?: boolean
  needed?: boolean
  disableBack?: boolean
}

export function EditDriversLicense({ user, adminView, needed, disableBack }: EditDriversLicenseProps) {
  const history = useHistory()
  const { data, loading, error } = useDriversLicense(user)
  const [onSubmitError, setError] = useState<Error | undefined>()

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().documentBox.driversLicense}
        </Typography>
      </Box>

      <MayBeOptionalMessage needed={!!needed} />

      <Paper elevation={3}>
        <Box p={2}>
          <FriendlyError error={error} />
          <FriendlyError error={onSubmitError} />
          {loading && <LinearProgress />}
          {!loading && (
            <>
              <DriversLicenseForm
                adminView
                onSubmit={async (data) => {
                  try {
                    setError(undefined)
                    await db.setDriversLicense(user, { ...data })
                    if (!adminView && !disableBack) history.goBack()
                  } catch (error: any) {
                    setError(error)
                  }
                }}
                onBack={() => history.goBack()}
                onCancel={() => history.goBack()}
                initialValues={data || undefined}
              />
            </>
          )}
        </Box>
      </Paper>
    </>
  )
}
