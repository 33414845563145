import QRCode from 'qrcode'
import { db } from 'app/db/frontend-db'
import { addLogo } from 'app/pages/billing/add-association-logo'
import { cropImageSize, downloadFileOrUndefined } from 'app/storage/storage'
import { domain } from 'shared/config/domain'
import { routes } from 'shared/config/routes'
import {
  categoryByIdRequired,
  categoryOfAssociationRequired,
  fullCategoryShortName,
} from 'shared/data/categories-service'
import { InscriptionTask, LicenseTask } from 'shared/data/license-tasks-overview'
import { ApprovedLicense, Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { openLicenseTasks } from 'shared/license/license-tasks'
import { associationByID, todoMigrateAssociation } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { nameWithPlace } from 'shared/models/personal-data'
import { uploadStatusOk } from 'shared/models/upload-status'
import { truthy } from 'shared/utils/array'
import { downloadPdf, drawSVG, PDFDoc, PDFOptions } from 'utils/pdf/pdf'

export async function generateLicense2021(license: ApprovedLicense) {
  const documents = await db.loadDocuments(license)
  if (!documents) throw new Error('No documents provided')
  await downloadPdf(licensePdfOptions(filename(documents)), (pdf) =>
    generateLicensePdf(license, documents, pdf)
  )
}

function filename(documents: Documents) {
  const { samMemberNumber, firstName, lastName } = documents.personalData || {}
  return ['lizenz', samMemberNumber, firstName, lastName].filter(truthy).join('-')
}

function licensePdfOptions(filename: string): PDFOptions {
  return {
    name: filename,
    layout: 'portrait',
    /*
     The ratio of the internal value of A4 as described in
     https://github.com/foliojs/pdfkit/blob/master/lib/page.js#L20 and A4 as described in https://en.wikipedia.org/wiki/ISO_216#A_series
     and https://en.wikipedia.org/wiki/ISO/IEC_7810
     */
    size: [(595.28 / 210) * 53.98, (841.89 / 297) * 85.6],
    margins: { top: 75.1, left: marginLeftRight, right: marginLeftRight, bottom: 0 },
  }
}

async function generateLicensePdf(license: ApprovedLicense, documents: Documents, pdf: PDFDoc) {
  const association = todoMigrateAssociation(license.licenseAssociation)
  const category = categoryOfAssociationRequired(license.categoryId, association)

  pdf.font('Helvetica')
  pdf.fontSize(fontSize)

  drawTopBackground(pdf, category)
  await drawImages(pdf, documents, license)
  drawHeader(pdf, category)
  drawNumberPlate(pdf, category, license)
  if (association === 'sam') drawMemberNumber(pdf, documents)
  drawTransponderNumber(pdf, category, documents)
  drawRiderDetails(pdf, license, category, documents)
  drawValidity(pdf, category, openLicenseTasks({ documents, license }))
  drawAddress(pdf, category)
}

function drawTopBackground(pdf: PDFKit.PDFDocument, category: CategoryOfAssociation) {
  pdf.rect(0, 0, pdf.page.width, topSectionHeight).fill(category.colorLicense).fill('black')
}

async function drawImages(pdf: PDFKit.PDFDocument, documents: Documents, license: ApprovedLicense) {
  await drawLogo(license, pdf)
  await drawPhoto(documents, pdf)
  await drawQRCode(license, pdf, 0, 43)
}

async function drawLogo(license: ApprovedLicense, pdf: PDFKit.PDFDocument) {
  if (categoryByIdRequired(license.categoryId).type === 'supermoto') {
    await drawSamLogoSmall(pdf)
    await drawFmsLogoSmall(pdf)
  } else {
    await drawLogoInner(pdf, license)
  }
}

async function drawLogoInner(pdf: PDFKit.PDFDocument, license: ApprovedLicense) {
  pdf.save().scale(0.5)
  await addLogo(todoMigrateAssociation(license.licenseAssociation), pdf, 10, 10, 'dark')
  pdf.restore()
}

async function drawSamLogoSmall(pdf: PDFKit.PDFDocument) {
  pdf.save().scale(0.28)
  await addLogo('sam', pdf, 10, 82)
  pdf.restore()
}

async function drawFmsLogoSmall(pdf: PDFKit.PDFDocument) {
  pdf.save().scale(0.28)
  await addLogo('fms', pdf, 10, 7, 'dark')
  pdf.restore()
}

async function drawPhoto(documents: Documents, pdf: PDFKit.PDFDocument) {
  if (!uploadStatusOk(documents.photo?.status)) return

  const photo = await downloadFileOrUndefined(documents.photo?.upload?.fullPath || '')
  if (!photo) return

  const photoScale = 0.065
  const photoWidth = cropImageSize.width * photoScale
  const photoHeight = cropImageSize.height * photoScale
  const circleRadius = photoWidth / 2
  const left = 110
  const top = -2.2
  pdf
    .save()
    .circle(left + photoWidth / 2, top + photoHeight / 2, circleRadius)
    .clip()
    .image(photo, left, top, scaleImage(cropImageSize, photoScale))
    .restore()
}

async function drawQRCode(license: ApprovedLicense, pdf: PDFKit.PDFDocument, x: number, y: number) {
  const url = `${domain}${await routes.qrLicenseV1.generateTo(db, license)}`
  const data = await QRCode.toString(url, { errorCorrectionLevel: 'H', type: 'svg' })
  await drawSVG(pdf, data, x, y)
}

function drawHeader(pdf: PDFKit.PDFDocument, category: CategoryOfAssociation) {
  const categoryType = category.type === 'other' ? 'FUN' : category.typeName.toUpperCase()
  pdf
    .save()
    .font('Helvetica-Bold')
    .fontSize(9)
    .lineWidth(2.7)
    .strokeColor('white')
    .fill('white')
    .text(categoryType, marginLeftRight, 10, { align: 'center', fill: true, stroke: true })
    .fontSize(8)
    .text(`${t().licensePdf.license} ${category.year}`, { align: 'center', fill: true, stroke: true })
    .stroke()
    .fill('black')
    .font('Helvetica-Bold')
    .fontSize(9)
    .text(categoryType, marginLeftRight, 10, { align: 'center' })
    .fontSize(8)
    .text(`${t().licensePdf.license} ${category.year}`, { align: 'center', fill: true })
    .rect(40, 0, pdf.page.width - 80, 8.54)
    .fill(category.colorLicense)
    .restore()
}

function drawNumberPlate(
  pdf: PDFKit.PDFDocument,
  category: CategoryOfAssociation,
  license: ApprovedLicense
) {
  if (!displayIssuedNumber(category, license)) return

  const plateSize = 18
  pdf
    .save()
    .rect(pdf.page.width / 2 - plateSize / 2, topSectionHeight - plateSize / 2, plateSize, plateSize)
    .lineJoin('bevel')
    .fillAndStroke(category.colorBackground || 'white', 'black')
    .fill(category.colorForeground || 'black')
    .fontSize(8)
    .font('Helvetica-Bold')
    .text(`${license.issuedNumber}`, marginLeftRight, topSectionHeight - 3, { align: 'center' })
    .restore()
}

function drawMemberNumber(pdf: PDFKit.PDFDocument, documents: Documents) {
  const memberNumber = (documents.personalData?.samMemberNumber || '').toString()
  pdf
    .font('Helvetica')
    .fontSize(6)
    .text(`MNR: ${memberNumber}`, marginLeftRight, topSectionHeight + 5)
}

function drawTransponderNumber(
  pdf: PDFKit.PDFDocument,
  category: CategoryOfAssociation,
  documents: Documents
) {
  const transponder = category.transponders
    .map(
      (type) =>
        Object.values(documents.transponder?.transponders || {}).find((t) => t.type === type)
          ?.transponderNumber
    )
    .filter(truthy)
    .join('/')
  if (transponder)
    pdf.fontSize(6).text(`TP: ${transponder}`, marginLeftRight, topSectionHeight + 5, { align: 'right' })
}

function drawRiderDetails(
  pdf: PDFKit.PDFDocument,
  license: ApprovedLicense,
  category: CategoryOfAssociation,
  documents: Documents
) {
  if (category.type !== 'other') {
    const issuedNumber = displayIssuedNumber(category, license) ? `#${license.issuedNumber} - ` : ''
    pdf
      .font('Helvetica-Bold')
      .fontSize(6)
      .text(`${issuedNumber}${fullCategoryShortName(category)}`, undefined, topSectionHeight + 15)
  }
  pdf.font('Helvetica').text(nameWithPlace(documents.personalData))
}

function displayIssuedNumber(category: CategoryOfAssociation, license: ApprovedLicense) {
  return category.numberChoice && license.issuedNumber && license.issuedNumber > 0
}

function drawValidity(
  pdf: PDFKit.PDFDocument,
  category: CategoryOfAssociation,
  tasks: {
    tasks: (LicenseTask | InscriptionTask)[]
    allDone: boolean
    tasksDone: number
    tasksTotal: number
  }
) {
  if (tasks.allDone) pdf.text(`${t().licensePdf.validUntil} 31.12.${category.year}`)
  else
    pdf
      .fillColor('red')
      .fontSize(5)
      .text(
        `${t().licensePdf.notValidYet}: ${tasks.tasks
          .filter((task) => !task.done)
          .map((task) => shortTaskDescription(task))
          .join(', ')}`,
        { align: 'justify' }
      )
      .fillColor('black')
}

function shortTaskDescription(task: LicenseTask | InscriptionTask) {
  return t().licenseTasks.pdfTasks[task.type]
}

function drawAddress(pdf: PDFKit.PDFDocument, category: CategoryOfAssociation) {
  category.associations.forEach((association, index) => {
    pdf
      .fontSize(3)
      .text(
        `${associationByID(association).address} `,
        marginLeftRight,
        topSectionHeight + 188 + index * 4
      )
  })
}

function scaleImage({ width, height }: { width: number; height: number }, ratio: number) {
  return { width: width * ratio, height: height * ratio }
}

const topSectionHeight = 45
const fontSize = 8
const marginLeftRight = 7
