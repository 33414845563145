import { ParsedPayment, ParsedPaymentTransaction } from 'shared/billing/payment-interfaces'
import {
  CAMT054RelatedParty,
  CAMT054PaymentFile,
  CAMT054Txdtl,
  CAMT054Rltdpty,
} from 'shared/billing/payment-xml-interface'
import { PaymentFile } from 'shared/db/db'
import { base64Encode } from 'shared/utils/base64'
import { ErrorWithDetails, serializeError } from 'shared/utils/errors'
import { replaceAll } from 'shared/utils/string'

export async function parsePaymentFiles(fileContents: PaymentFileContents[]): Promise<PaymentFile[]> {
  const parsed: PaymentFile[] = await Promise.all(
    fileContents.map(async ({ text, filename }) => ({
      id: '',
      filename,
      parsed: await parsePaymentText(text),
    }))
  )
  const paymentFiles = JSON.parse(JSON.stringify(parsed)) as typeof parsed
  paymentFiles.forEach(validatePaymentFile)
  return paymentFiles
}

export interface PaymentFileContents {
  text: string
  filename: string
}

function validatePaymentFile(paymentFile: PaymentFile) {
  paymentFile.parsed.transactions.forEach(validateTransaction)
}

function validateTransaction(transaction: ParsedPaymentTransaction) {
  if (transaction.hasError)
    throw new ErrorWithDetails(
      `Invalid transaction: ${transaction.raw}, ${JSON.stringify(transaction.serializedError)}`,
      { cause: transaction.error, details: JSON.parse(transaction.raw) }
    )
}

export async function parsePaymentText(rawText: string): Promise<ParsedPayment> {
  const xml2js = await import('xml2js')
  const parseStringPromise = xml2js.default.parseStringPromise

  const json: CAMT054PaymentFile = await parseStringPromise(rawText, {
    normalize: true,
    normalizeTags: true,
    trim: true,
  })

  return {
    transactions: json.document.bktocstmrdbtcdtntfctn.flatMap((v) =>
      v.ntfctn.flatMap((v) =>
        v.ntry.flatMap((v) => {
          const date = firstEO(firstEO(v.valdt).dt)
          return v.ntrydtls.flatMap((v) => v.txdtls.map((rawDetail) => mapDetails(rawDetail, date)))
        })
      )
    ),
    rawText,
  }
}

function mapDetails(rawDetail: CAMT054Txdtl, date: string): ParsedPaymentTransaction {
  try {
    const detail = mapLowLevelDetails(rawDetail)
    const reference = validReference(detail.reference)
      ? detail.reference
      : base64Encode(detail.reference)
    return {
      hasError: false,
      paymentFileId: '',
      date,
      rawReference: detail.reference,
      referenceValid: reference === detail.reference,
      id: `${detail.transactionId}-${reference}`,
      ...detail,
      reference,
    }
  } catch (error) {
    if (!(error instanceof Error)) throw error
    return {
      hasError: true,
      paymentFileId: '',
      date,
      error,
      serializedError: serializeError(error),
      raw: JSON.stringify(rawDetail),
    }
  }
}

function validReference(reference: string | undefined) {
  if (!reference) return false
  const exampleReferenceNumber = '123456789012345678901234567'
  const matchOnlyNumbers = /^\d+$/
  return reference.length === exampleReferenceNumber.length && matchOnlyNumbers.test(reference)
}

function mapLowLevelDetails(detail: CAMT054Txdtl) {
  assertEquals(firstEO(detail.amt).$.Ccy, 'CHF')
  const relatedParties = firstEO(detail.rltdpties)
  return {
    transactionId: base64Encode(firstEO(firstEO(detail.refs).acctsvcrref)),
    amount: parseFloat(firstEO(detail.amt)._),
    debitorIban: parseIBAN(relatedParties),
    debitor: mapDebitor(relatedParties.dbtr?.[0]),
    ultimateDebitor: mapDebitor(relatedParties.ultmtdbtr?.[0]),
    creditor: mapDebitor(relatedParties.cdtr?.[0]),
    reference: detail?.rmtinf?.[0]?.strd?.[0]?.cdtrrefinf?.[0]?.ref?.[0],
    referenceInfo: detail.addtltxinf?.[0],
    raw: JSON.stringify(detail),
  }
}

function parseIBAN(relatedParties: CAMT054Rltdpty) {
  const ibanWithSlash = relatedParties.dbtracct
    ? firstMaybeOne(firstEO(firstEO(relatedParties.dbtracct).id).iban) ||
      firstMaybeOne(firstMaybeOne(firstEO(firstEO(relatedParties.dbtracct).id).othr)?.id)
    : ''
  return replaceAll(ibanWithSlash || '', '/', '')
}

function mapDebitor(debitor: CAMT054RelatedParty | undefined) {
  const address = firstMaybeOne(debitor?.pstladr)
  return {
    name: firstMaybeOne(debitor?.nm) || '',
    address: {
      street: firstMaybeOne(address?.strtnm) || '',
      streetNumber: firstMaybeOne(address?.bldgnb) || '',
      zip: firstMaybeOne(address?.pstcd) || '',
      place: firstMaybeOne(address?.twnnm) || '',
      country: firstMaybeOne(address?.ctry) || '',
      addressLines: address?.adrline || [],
    },
  }
}

function firstMaybeOne<T>(arr: T[] | undefined): T | undefined {
  return arr && (arr.length === 0 ? undefined : firstEO(arr))
}

/**
 * Verifies that there is exactly one (EO) element in the array, then returns it
 */
function firstEO<T>(arr: T[]): T {
  if (arr.length !== 1) throw new Error(`Expected ${arr}.lenght === 1`)
  return arr[0]
}

function assertEquals<T>(actual: T, expected: T) {
  if (actual !== expected) throw new Error(`Expected ${expected}, was ${actual}`)
}
