import { Button } from '@material-ui/core'
import { callRacemanagerApi } from 'app/config/firebase'
import { useExecutedMigrations } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { actions, tableHeaders } from 'app/export/table'
import { ButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { TableBox } from 'app/layouts/table-box'
import { useUserContext } from 'app/themes/user-context'
import { RunDailyCronjobEndpoint } from 'shared/api/interfaces'
import { deleteMigrationExecution, executeMigration } from 'shared/db/db-migrations'
import { migrations } from 'shared/db/migrations'
import { useCatchAndDisplayErrorOrSuccess } from 'utils/snackbar'

export function DBMigrations() {
  const userContext = useUserContext()
  if (!userContext.normalAdmin) return null

  return <InnerDBMigrations />
}

function InnerDBMigrations() {
  const handlePromise = useCatchAndDisplayErrorOrSuccess()
  const executedMigrations = useExecutedMigrations()
  const allMigrations = migrations.map((migration) => ({
    ...migration,
    executed: executedMigrations.data.find((executed) => executed.id === migration.id),
  }))

  return (
    <>
      <TableBox
        title="DB Migrations"
        error={executedMigrations.error}
        loading={executedMigrations.loading}
        data={{
          headers: tableHeaders(['ID', 'Description', 'Executed at', actions()]),
          contents: allMigrations.map((migration) => [
            migration.id,
            migration.description,
            migration.executed?.runAt || '-',
            <>
              <ButtonWithConfirmation
                disabled={!!migration.executed?.runAt}
                onConfirm={() => handlePromise(executeMigration(db, migration))}
                title="Execute migration"
                confirmation={`Execute migration "${migration.description}"`}
                fullWidth={false}
                variant="text"
              >
                Execute
              </ButtonWithConfirmation>
              <ButtonWithConfirmation
                disabled={!migration.executed?.runAt}
                onConfirm={() => handlePromise(deleteMigrationExecution(db, migration))}
                title="Delete migration"
                confirmation={`Delete migration "${migration.description}"`}
                fullWidth={false}
                variant="text"
              >
                Delete
              </ButtonWithConfirmation>
            </>,
          ]),
          selected: [],
          rawData: undefined,
        }}
      />
      <Button onClick={() => callRacemanagerApi<RunDailyCronjobEndpoint>('runDailyCronjob', null)}>
        Run daily cronjobs
      </Button>
    </>
  )
}
