import { Box, Button, Paper, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import assertNever from 'assert-never'
import { useParams } from 'react-router-dom'
import { useNewInscriptionFormData } from 'app/db/db-hooks/inscription-db-hooks'
import { useDocuments, useDriversLicense, useSportEventDayCategory } from 'app/db/db-hooks/main-db-hooks'
import { FinancialBox } from 'app/pages/dashboard/financial-box'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import { VisualLicenseTasks } from 'app/pages/dashboard/overview-box'
import { EditDriversLicense } from 'app/pages/driversLicense/EditDriversLicense'
import {
  SelectedBikes,
  EnlistLicenseInscriptionButton,
  EnlistDayInscriptionYearCategoryButton,
  EnlistDayInscriptionDayCategoryButton,
} from 'app/pages/inscription/enlist-inscription-buttons'
import { ListPublicInscriptionsAllDates } from 'app/pages/inscription/public-inscriptions-page'
import {
  UnlistLicenseInscription,
  UnlistDayLicenseInscriptionButton,
} from 'app/pages/inscription/unlist-inscription-buttons'
import {
  associationsNames,
  categoryByIdRequired,
  categoryOfAssociationRequired,
  commonCategoryName,
  isCategoryId,
} from 'shared/data/categories-service'
import { nextLicenseYear } from 'shared/data/license-config'
import { DayCategory, DayCategoryID } from 'shared/db/day-category'
import { Documents, UserQuery } from 'shared/db/db'
import { SportEventDate, SportEventId } from 'shared/db/sport-event-id'
import { t } from 'shared/i18n/current'
import { inscriptionStatusLong } from 'shared/inscription/inscription-status-service'
import {
  dayCategoryInscriptionTasks,
  dayLicenseYearCategoryInscriptionTasks,
  licenseInscriptionTasks,
} from 'shared/license/license-tasks'
import { Category, CategoryId } from 'shared/models/category'
import {
  isDayInscriptionDayCategoryInscriptionOrDraft,
  isDayInscriptionYearCategoryInscriptionOrDraft,
} from 'shared/sport-events/sport-events'
import { pFormatDateWithSecondsSpaceDe } from 'shared/utils/date'
import { Loading } from 'utils/loading'
import { useBoolean } from 'utils/use-boolean'

// pending i18n

interface NewInscriptionPageProps {
  user: UserQuery
}

export function NewInscriptionPage({ user }: NewInscriptionPageProps) {
  const { sportEventId, categoryId, date } = useParams<{
    sportEventId: string
    categoryId: DayCategoryID | CategoryId
    date: string
  }>()
  const documents = useDocuments(user)

  return (
    <>
      <LoadingOrErrorBox loading={documents.loading} error={documents.error} />
      {documents.data && (
        <NewInscriptionForm
          user={user}
          documents={documents.data}
          categoryId={categoryId}
          sportEventId={sportEventId}
          date={date}
          additionalInfo
        />
      )}
    </>
  )
}

export interface NewInscriptionFormProps {
  user: UserQuery
  documents: Documents
  categoryId: DayCategoryID | CategoryId
  sportEventId: SportEventId
  date: SportEventDate
  additionalInfo?: boolean
  admin?: boolean
}

export function NewInscriptionForm(props: NewInscriptionFormProps) {
  const { user, documents, categoryId, sportEventId, date, additionalInfo, admin } = props
  return (
    <>
      {isCategoryId(categoryId) ? (
        <NewInscriptionFormInner
          user={user}
          sportEventId={sportEventId}
          category={categoryByIdRequired(categoryId)}
          date={date}
          documents={documents}
          additionalInfo={!!additionalInfo}
          admin={!!admin}
        />
      ) : (
        <DayCategoryNewInscription
          user={user}
          sportEventID={sportEventId}
          dayCategoryID={categoryId}
          date={date}
          documents={documents}
          additionalInfo={!!additionalInfo}
          admin={!!admin}
        />
      )}
    </>
  )
}

interface DayCategoryNewInscriptionProps {
  user: UserQuery
  sportEventID: string
  dayCategoryID: DayCategoryID
  date: string
  documents: Documents
  additionalInfo: boolean
  admin: boolean
}

export function DayCategoryNewInscription(props: DayCategoryNewInscriptionProps) {
  const { user, sportEventID, dayCategoryID, date, documents, additionalInfo, admin } = props
  const dayCategory = useSportEventDayCategory({ sportEventID, dayCategoryID })

  return dayCategory.loadingOrError ? (
    <LoadingOrErrorBox loading={dayCategory.loading} error={dayCategory.error} />
  ) : dayCategory.data ? (
    <NewInscriptionFormInner
      user={user}
      sportEventId={sportEventID}
      category={dayCategory.data}
      date={date}
      documents={documents}
      additionalInfo={additionalInfo}
      admin={admin}
    />
  ) : (
    <>{t().sportsEvent.eventOrCategoryNotFound}</>
  )
}

interface NewInscriptionFormInnerProps {
  user: UserQuery
  sportEventId: SportEventId
  category: Category | DayCategory
  date: string
  documents: Documents
  additionalInfo: boolean
  admin: boolean
}

function NewInscriptionFormInner(props: NewInscriptionFormInnerProps) {
  const { user, category, date, documents, additionalInfo, admin } = props
  const { data, ...rest } = useNewInscriptionFormData(props)
  const submitting = useBoolean(false)
  const driversLicense = useDriversLicense(user)

  const { sportEvent, inscription, bikes, licenseTasks, license } = data

  if (!rest.loadingOrError && !sportEvent) return <>Veranstaltung nicht gefunden</>
  if (!sportEvent) return <Loading loading />
  if (driversLicense.loadingOrError)
    return <LoadingOrErrorBox loading={false} error={driversLicense.error} />
  if (sportEvent.year !== nextLicenseYear)
    return <>Einschreiben für alle Jahre ausser {nextLicenseYear} deaktiviert</>

  if (sportEvent.sportEventType === 'enduro' && !driversLicense.data?.id)
    return <EditDriversLicense user={user} needed disableBack />

  const inscriptionBikeIds = new Set<string>(inscription?.bikeIds || [])
  const availableBikes = bikes.filter((bike) => bike.status !== 'deleted')
  const enlisted =
    inscription?.type === 'enlistedLicenseInscription' ||
    inscription?.type === 'enlistedDayInscriptionYearCategory' ||
    inscription?.type === 'enlistedDayInscriptionYearCategoryDraft' ||
    inscription?.type === 'enlistedDayInscriptionDayCategory' ||
    inscription?.type === 'enlistedDayInscriptionDayCategoryDraft'

  const potentialEnlistingType = enlisted
    ? undefined
    : license
    ? 'enlistedLicenseInscription'
    : category.categoryType === 'dayCategory'
    ? 'enlistedDayInscriptionDayCategoryDraft'
    : 'enlistedDayInscriptionYearCategoryDraft'

  const potentialUnlistingType =
    enlisted &&
    inscription.type === 'enlistedLicenseInscription' &&
    license &&
    category.categoryType === 'licenseCategory'
      ? 'unlistedLicenseInscription'
      : enlisted &&
        (inscription.type === 'enlistedDayInscriptionYearCategory' ||
          inscription.type === 'enlistedDayInscriptionYearCategoryDraft' ||
          inscription.type === 'enlistedDayInscriptionDayCategory' ||
          inscription.type === 'enlistedDayInscriptionDayCategoryDraft') &&
        !license
      ? 'unlistedDayLicenseInscription'
      : license && !inscription && category.categoryType === 'licenseCategory'
      ? 'unlistedLicenseInscription'
      : undefined

  const tasks =
    enlisted &&
    category.categoryType === 'dayCategory' &&
    isDayInscriptionDayCategoryInscriptionOrDraft(inscription)
      ? dayCategoryInscriptionTasks({ category, documents, inscription })
      : isDayInscriptionYearCategoryInscriptionOrDraft(inscription)
      ? dayLicenseYearCategoryInscriptionTasks({
          category: categoryOfAssociationRequired(inscription.category, inscription.association),
          documents,
          inscription,
        })
      : inscription?.type === 'enlistedLicenseInscription'
      ? licenseInscriptionTasks({
          category: categoryOfAssociationRequired(inscription.category, inscription.association),
          documents,
          inscription,
        })
      : undefined

  return (
    <>
      <Box py={3}>
        <Typography variant="h2">
          {t().inscribeIn} {associationsNames(category)} {commonCategoryName(category)},{' '}
          {sportEvent?.name} ({date})
        </Typography>
      </Box>
      <LoadingOrErrorBox loading={rest.loading} error={rest.error} />
      {!rest.loadingOrError && licenseTasks && (
        <Paper elevation={3}>
          <Box p={2} pb={1}>
            <Box mb={2}>
              <Typography component="h3" variant="h4">
                {t().category}: {associationsNames(category)} {commonCategoryName(category)}
              </Typography>
              <Typography component="h3" variant="h5">
                {t().status}: <em>{inscriptionStatusLong(inscription, tasks || licenseTasks)}</em>
                <br />
                {inscription && (
                  <>
                    {t().lastChange}: {pFormatDateWithSecondsSpaceDe(inscription.createdAt)}
                    <br />
                  </>
                )}
              </Typography>
            </Box>

            {enlisted && (
              <>
                {((category.categoryType === 'dayCategory' && category.needsBikeInfo) ||
                  (category.categoryType === 'licenseCategory' &&
                    category.associationSpecificDetails[0].needsBikeInfo)) && (
                  <Box mt={2}>
                    <SelectedBikes
                      label={t().bikes}
                      bikes={bikes.filter((bike) => inscriptionBikeIds.has(bike.id))}
                      selectedBikeIds={inscriptionBikeIds}
                      sportsEventType={sportEvent.sportEventType}
                    />
                  </Box>
                )}
                {(category.sidecar || category.sidecarPassenger) && inscription.sidecarPartner && (
                  <Box mt={2}>
                    {t().sidecarPartner.label}: {inscription.sidecarPartner}
                  </Box>
                )}
                {(inscription.type === 'enlistedDayInscriptionDayCategoryDraft' ||
                  inscription.type === 'enlistedDayInscriptionYearCategoryDraft') && (
                  <Box mt={2}>
                    {t().licensesBoxes.preferredNumber}: {inscription.preferredNumber}
                  </Box>
                )}
                {(inscription.type === 'enlistedDayInscriptionDayCategory' ||
                  inscription.type === 'enlistedDayInscriptionYearCategory') && (
                  <Box mt={2}>
                    {t().licensesBoxes.definitiveIssuedNumber}: {inscription.issuedNumber}
                  </Box>
                )}
                {inscription.remarksAdmin ||
                  (inscription.remarksRider && (
                    <Box mt={2}>
                      <Box>{t().remarks}:</Box>
                      <Box mt={1}>{inscription.remarksRider}</Box>
                      <Box mt={1}>{inscription.remarksAdmin}</Box>
                    </Box>
                  ))}

                {tasks && (
                  <Box mt={2}>
                    <VisualLicenseTasks tasks={tasks} size="sm" />
                  </Box>
                )}
              </>
            )}

            <Box mt={2} mb={1}>
              {!admin && (sportEvent.disabledInscriptions || {})[category.id] ? (
                <>
                  <Alert severity="error" variant="outlined">
                    <AlertTitle>{t().inscriptionsDisabled.title}</AlertTitle>
                    {t().inscriptionsDisabled.hintEnlist}
                  </Alert>
                  <Box mt={1}>
                    <Button disabled variant="outlined">
                      {t().inscription.payAndInscribe}
                    </Button>
                  </Box>
                </>
              ) : potentialEnlistingType === 'enlistedLicenseInscription' ? (
                category.categoryType === 'licenseCategory' && (
                  <EnlistLicenseInscriptionButton
                    user={user}
                    date={date}
                    submitting={submitting}
                    category={category}
                    sportEvent={sportEvent}
                    availableBikes={availableBikes}
                    admin={admin}
                  />
                )
              ) : potentialEnlistingType === 'enlistedDayInscriptionYearCategoryDraft' ? (
                category.categoryType === 'licenseCategory' && (
                  <EnlistDayInscriptionYearCategoryButton
                    user={user}
                    date={date}
                    submitting={submitting}
                    category={category}
                    sportEvent={sportEvent}
                    availableBikes={availableBikes}
                    documents={documents}
                    additionalInfo={additionalInfo}
                    admin={admin}
                  />
                )
              ) : potentialEnlistingType === 'enlistedDayInscriptionDayCategoryDraft' ? (
                category.categoryType === 'dayCategory' && (
                  <EnlistDayInscriptionDayCategoryButton
                    user={user}
                    date={date}
                    category={category}
                    sportEvent={sportEvent}
                    availableBikes={availableBikes}
                    documents={documents}
                    additionalInfo={additionalInfo}
                    admin={admin}
                  />
                )
              ) : !potentialEnlistingType ? null : (
                assertNever(potentialEnlistingType)
              )}

              {potentialUnlistingType === 'unlistedLicenseInscription' ? (
                !admin && (sportEvent.disabledInscriptions || {})[category.id] ? (
                  <>
                    <Alert severity="error" variant="outlined">
                      <AlertTitle>{t().inscriptionsDisabled.title}</AlertTitle>
                      {t().inscriptionsDisabled.hintUnlist}
                    </Alert>
                    <Box mt={1}>
                      <Button disabled variant="outlined">
                        {t().unlist.buttonText}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <UnlistLicenseInscription
                    category={category.categoryType === 'licenseCategory' ? category : undefined}
                    user={user}
                    sportEvent={sportEvent}
                    date={date}
                    submitting={submitting}
                  />
                )
              ) : potentialUnlistingType === 'unlistedDayLicenseInscription' ? (
                <UnlistDayLicenseInscriptionButton
                  category={category}
                  user={user}
                  sportEvent={sportEvent}
                  date={date}
                  submitting={submitting}
                />
              ) : !potentialUnlistingType ? null : (
                assertNever(potentialUnlistingType)
              )}
            </Box>

            <Box>
              <Loading loading={submitting.value} />
            </Box>
          </Box>
        </Paper>
      )}
      {additionalInfo && (
        <>
          <ListPublicInscriptionsAllDates user={user} category={category} sportEvent={sportEvent} />
          <FinancialBox user={user} admin={user} />
        </>
      )}
    </>
  )
}
