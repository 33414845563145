import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useInsurance, usePersonalData } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { DeleteButtonWithConfirmation } from 'app/layout/delete-button-with-confirmation'
import { OrderedList } from 'app/layout/ordered-list'
import { MayBeOptionalMessage } from 'app/pages/dashboard/may-be-optional-message'
import { ConfirmUploadButton } from 'app/pages/insurance/confirm-upload-button'
import { generateInsuranceForm } from 'app/pages/insurance/pdf-insurance-form'
import { DisplayUploadedFile } from 'app/storage/display-uploaded-file'
import { uploadInsurance, useInsuranceFilePath, deleteInsurance } from 'app/storage/insurance'
import { UploadPage } from 'app/storage/storage'
import { InvalidUploadMessage } from 'app/upload/invalid-upload-message'
import { UploadButton } from 'app/upload/upload-button'
import { routes } from 'shared/config/routes'
import { nextLicenseYear } from 'shared/data/license-config'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { Insurance } from 'shared/models/insurance'
import { migrateUploadStatus, UploadMetadata } from 'shared/models/upload-status'
import { setInsuranceStatus } from 'shared/upload/upload-service'
import { RoutedButton } from 'utils/buttons/routed-button'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { PreventDefaultLink } from 'utils/prevent-default-link'

export function EditInsurance({ user }: { user: UserQuery }) {
  const history = useHistory()
  const { data, loading, error: insuranceError } = useInsurance(user, nextLicenseYear)
  const { error, setError } = useError()
  const { data: personalData, loading: pdLoading, error: pdError } = usePersonalData(user)
  const { needed, insurance } = data

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().insurance.title}
        </Typography>
      </Box>

      <MayBeOptionalMessage needed={needed} />

      <Paper elevation={3}>
        <Box p={2}>
          <FriendlyError error={insuranceError} />
          <FriendlyError error={pdError} />
          <FriendlyError error={error} />
          <Loading loading={loading || pdLoading} />
          {!personalData && !personalData && (
            <RoutedButton to={routes.editProfile.to} fullWidth>
              {personalData ? t().buttons.edit : t().personalDataBox.createPersonalData}
            </RoutedButton>
          )}
          {!loading && personalData && (
            <>
              <Box my={2}>
                <Typography component="h3" variant="h3">
                  {t().provideDocuments.status}{' '}
                  {t().insurance.status[insurance?.status || 'notUploaded']}
                </Typography>
              </Box>
              <Box my={2}>
                <OrderedList
                  items={[
                    { name: 1, text: t().insurance.chooseInsuranceConfirmationType },
                    {
                      name: '1a',
                      text: (
                        <PreventDefaultLink
                          onClick={() =>
                            catchAndLog(setError, () => generateInsuranceForm(personalData, 'employee'))
                          }
                        >
                          {t().insurance.optionAForEmployees}
                        </PreventDefaultLink>
                      ),
                    },
                    {
                      name: '1b',
                      text: (
                        <PreventDefaultLink
                          onClick={() =>
                            catchAndLog(setError, () =>
                              generateInsuranceForm(personalData, 'independent')
                            )
                          }
                        >
                          {t().insurance.optionBForSelfEmployed}
                        </PreventDefaultLink>
                      ),
                    },
                    {
                      name: '1c',
                      text: (
                        <PreventDefaultLink
                          onClick={() =>
                            catchAndLog(setError, () => generateInsuranceForm(personalData, 'other'))
                          }
                        >
                          {t().insurance.optionCForOthers}
                        </PreventDefaultLink>
                      ),
                    },
                    {
                      name: 2,
                      text: t().insurance.downloadInsuranceConfirmation,
                    },
                    {
                      name: 3,
                      text: t().insurance.fillInByEmployer,
                    },
                    { name: '4a', text: t().provideDocuments.optionATakePictureWithPhone },
                    { name: '4b', text: t().insurance.optionBForSelfEmployed },
                    { name: 5, text: t().provideDocuments.checkReadabilityAndSignature },
                  ]}
                />
              </Box>
              <Box mb={4}>
                <Typography>{t().provideDocuments.qualityHint}</Typography>
              </Box>
              <UploadInsurancePage
                insurance={insurance}
                metadata={insurance?.page1}
                page={1}
                user={user}
                admin={user}
              />
            </>
          )}

          <Grid item xs={12}>
            <Box mt={1}>
              <Button fullWidth onClick={() => history.goBack()}>
                {t().buttons.back}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

interface UploadInsurancePageProps {
  insurance: Insurance | undefined
  metadata: UploadMetadata
  page: number
  user: UserQuery
  admin: UserQuery
}

export function UploadInsurancePage(props: UploadInsurancePageProps) {
  const { insurance, metadata, user, admin, page } = props
  const path = useInsuranceFilePath({ user, admin, page, metadata })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InvalidUploadMessage upload={migrateUploadStatus(insurance)} />

        <UploadButton
          fullWidth
          onUpload={async (files) => {
            if (files.length >= 1)
              await Promise.all([
                uploadInsurance({
                  oldMetadata: insurance?.page1,
                  file: files[0],
                  page: 1,
                  user,
                  admin,
                }),
              ])
            else if (files.length !== 0)
              throw new Error(`${t().provideDocuments.errorTooManyFilesSelected} ${page}`)
          }}
        >
          {t().insurance.uploadInsuranceConfirmation}
        </UploadButton>

        <Box py={1}>
          <DeleteInsuranceUploadButton {...props} />
        </Box>

        <ConfirmUploadButton
          upload={migrateUploadStatus(props.insurance)}
          admin={props.admin}
          metadata={props.metadata}
          onUpdateStatus={(statusDetails) => setInsuranceStatus({ db, user, admin, statusDetails })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayUploadedFile path={path} metadata={metadata} />
      </Grid>
    </Grid>
  )
}

function DeleteInsuranceUploadButton(props: UploadPage) {
  const { metadata } = props
  return (
    <DeleteButtonWithConfirmation
      title={t().insurance.deleteInsuranceConfirmation}
      onConfirm={() => deleteInsurance(props)}
      disabled={!metadata}
    />
  )
}
