import { categoryOfAssociationRequired, fixedLicenseType } from 'shared/data/categories-service'
import { nextLicenseYear } from 'shared/data/license-config'
import {
  LicenseTasksOverview,
  LicenseTask,
  LicenseTaskType,
  InscriptionTaskType,
  InscriptionTask,
} from 'shared/data/license-tasks-overview'
import { Year } from 'shared/data/license-year'
import { registeredViaTrustedAssociation } from 'shared/db/association'
import { DayCategory } from 'shared/db/day-category'
import { ApprovedLicense, Documents, LicenseDraftWithDocuments } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import {
  approvedLicenseNeedsHealthCheck,
  draftLicenseNeedsHealthCheck,
  needsHealthCheck,
} from 'shared/license/needs-health-check'
import { todoMigrateAssociation } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { CategoryDetails } from 'shared/models/category-details'
import {
  CommonUploadMetadata,
  UploadStatus,
  UploadStatusDetails,
  uploadStatusOk,
} from 'shared/models/upload-status'
import {
  DayInscription,
  EnlistedDayInscriptionDayCategory,
  EnlistedDayInscriptionDayCategoryDraft,
  EnlistedDayInscriptionYearCategory,
  EnlistedDayInscriptionYearCategoryDraft,
  EnlistedLicenseInscription,
  Inscription,
  isDayInscriptionDayCategoryInscriptionOrDraft,
  isDayInscriptionYearCategoryInscriptionOrDraft,
} from 'shared/sport-events/sport-events'
import { truthy } from 'shared/utils/array'
import { parseInt10 } from 'shared/utils/number'

interface OpenLicenseTasksProps {
  documents: Documents
  license: ApprovedLicense
}

export function openLicenseTasks(props: OpenLicenseTasksProps) {
  const { documents, license } = props
  const category = categoryOfAssociationRequired(license.categoryId, license.licenseAssociation)
  return openLicenseTasksByCategory({
    documents,
    license: fixedLicenseType(license),
    category,
    type: 'year',
  })
}

type OpenLicenseTasksByCategoryProps =
  | OpenYearLicenseTasksByCategoryProps
  | OpenDayLicenseInscriptionTasksByCategoryProps

export function openLicenseTasksByCategory(props: OpenLicenseTasksByCategoryProps) {
  return props.type === 'year' ? openYearLicenseTasks(props) : openDayLicenseTasks(props)
}

interface OpenYearLicenseTasksByCategoryProps {
  type: 'year'
  documents: Documents
  license: ApprovedLicense
  category: CategoryOfAssociation
}

function openYearLicenseTasks(props: OpenYearLicenseTasksByCategoryProps) {
  const { documents, license, category } = props
  return licenseTasks({
    documents,
    submittedLicense: true,
    licensePaid: !!license.paid,
    needsHealthCheck: approvedLicenseNeedsHealthCheck(license, documents.personalData?.birthdate || ''),
    needsInsurance: !!category.needsInsurance,
    needsEmergency: !!category.needsEmergency,
    registeredViaTrustedAssociation: registeredViaTrustedAssociation(license),
    year: category.year,
    isSam: todoMigrateAssociation(license.licenseAssociation) === 'sam',
    needsMembership: category.needsMembership,
  })
}

interface OpenDayLicenseInscriptionTasksByCategoryProps {
  type: 'day'
  documents: Documents
  category: DayCategory
  inscription: DayInscription
}

export function openDayLicenseTasks(props: OpenDayLicenseInscriptionTasksByCategoryProps) {
  const { documents, category, inscription } = props
  return licenseTasks({
    documents,
    submittedLicense: true,
    licensePaid: true,
    needsHealthCheck: !!category.needsHealthCheck,
    needsInsurance: !!category.needsInsurance,
    needsEmergency: !!category.needsEmergency,
    registeredViaTrustedAssociation: false,
    year: inscription.year,
    isSam: inscription.association === 'sam',
    needsMembership: false,
  })
}

interface GeneralInscriptionTasksProps {
  documents: Documents
  category: CategoryOfAssociation | DayCategory
  inscription?: Inscription | undefined
}

export function generalInscriptionTasks(props: GeneralInscriptionTasksProps) {
  const { documents, category, inscription } = props
  if (
    category.categoryType === 'dayCategory' &&
    isDayInscriptionDayCategoryInscriptionOrDraft(inscription)
  )
    return dayCategoryInscriptionTasks({ documents, category, inscription })

  if (
    category.categoryType === 'licenseCategory' &&
    isDayInscriptionYearCategoryInscriptionOrDraft(inscription)
  )
    return dayLicenseYearCategoryInscriptionTasks({ documents, category, inscription })

  if (category.categoryType === 'licenseCategory' && inscription?.type === 'enlistedLicenseInscription')
    return licenseInscriptionTasks({ documents, category, inscription })

  return licenseTasksOverview([])
}

interface OpenDayLicenseYearCategoryTasksProps {
  documents: Documents
  category: CategoryOfAssociation
  inscription?: EnlistedDayInscriptionYearCategoryDraft | EnlistedDayInscriptionYearCategory
}

export function dayLicenseYearCategoryInscriptionTasks(props: OpenDayLicenseYearCategoryTasksProps) {
  const { documents, category, inscription } = props
  return inscriptionTasks({
    inscription,
    documents,
    needsHealthCheck: false,
    needsInsurance: !!category.needsInsurance,
    needsEmergency: !!category.needsEmergency,
    needsBikes: !!category.needsBikeInfo,
    needsMembership: false,
    registeredViaTrustedAssociation: false,
    year: category.year,
    isSam: false,
  })
}

interface OpenLicenseInscriptionTasksProps {
  documents: Documents
  category: CategoryOfAssociation
  inscription?: EnlistedLicenseInscription
}

export function licenseInscriptionTasks(props: OpenLicenseInscriptionTasksProps) {
  const { documents, category, inscription } = props

  return inscriptionTasks({
    inscription,
    documents,
    needsHealthCheck: false,
    needsInsurance: !!category.needsInsurance,
    needsEmergency: !!category.needsEmergency,
    needsBikes: !!category.needsBikeInfo,
    needsMembership: !!category.needsMembership,
    registeredViaTrustedAssociation: false,
    year: category.year,
    isSam: false,
  })
}

export function dayCategoryInscriptionTasks(props: {
  documents: Documents
  category: DayCategory
  inscription?: EnlistedDayInscriptionDayCategory | EnlistedDayInscriptionDayCategoryDraft
}): LicenseTasksOverview {
  const { documents, category } = props

  return inscriptionTasks({
    inscription: props.inscription,
    needsBikes: category.needsBikeInfo,
    needsEmergency: category.needsEmergency,
    needsHealthCheck: category.needsHealthCheck,
    needsInsurance: category.needsInsurance,
    needsMembership: false,
    year: nextLicenseYear,
    isSam: false,
    documents,
    registeredViaTrustedAssociation: false,
  })
}

interface OpenDraftLicenseInscriptionTasksProps {
  documents: Documents
  category: CategoryOfAssociation
  categoryDetails: CategoryDetails
}

export function openDraftLicenseInscriptionTasks(props: OpenDraftLicenseInscriptionTasksProps) {
  const { documents, category, categoryDetails } = props
  return licenseTasks({
    documents,
    submittedLicense: true,
    licensePaid: undefined,
    needsHealthCheck: needsHealthCheck({
      category,
      licenseType: categoryDetails.licenseType,
      birthdate: documents.personalData?.birthdate || '',
    }),
    needsInsurance: !!category.needsInsurance,
    needsEmergency: !!category.needsEmergency,
    registeredViaTrustedAssociation: false,
    year: category.year,
    isSam: category.association === 'sam',
    needsMembership: category.needsMembership,
  })
}

interface LicenseTasksProps {
  submittedLicense: boolean
  documents: Documents | undefined
  licensePaid: boolean | undefined
  needsHealthCheck: boolean
  needsInsurance: boolean
  needsEmergency: boolean
  registeredViaTrustedAssociation: boolean
  year: Year
  isSam: boolean
  needsMembership: boolean
}

export function licenseTasksForLicense(license: LicenseDraftWithDocuments, birthdate: string) {
  const category = categoryOfAssociationRequired(
    license.type === 'approved' ? license.approved.categoryId : license.draft.category.id,
    license.type === 'approved'
      ? license.approved.licenseAssociation || license.draft.category.associations[0]
      : license.draft.categoryDetails?.licenseAssociation || license.draft.category.associations[0]
  )
  return licenseTasks({
    submittedLicense: true,
    documents: license.documents,
    licensePaid: license.type === 'approved' ? !!license.approved.paid : false,
    needsHealthCheck:
      license.type === 'approved'
        ? approvedLicenseNeedsHealthCheck(license.approved, birthdate)
        : draftLicenseNeedsHealthCheck(license.draft, birthdate),
    needsInsurance: category.needsInsurance,
    needsEmergency: category.needsEmergency,
    year: category.year,
    isSam: category.association === 'sam',
    registeredViaTrustedAssociation:
      license.type === 'approved' && category.year === 2021
        ? registeredViaTrustedAssociation(license.approved)
        : false,
    needsMembership: category.needsMembership,
  })
}

export function licenseTasksForApprovedLicense(
  license: ApprovedLicense,
  documents: Documents | undefined
) {
  const category = categoryOfAssociationRequired(license.categoryId, license.licenseAssociation)
  return licenseTasks({
    submittedLicense: true,
    documents,
    licensePaid: !!license.paid,
    needsHealthCheck: approvedLicenseNeedsHealthCheck(license, documents?.personalData?.birthdate || ''),
    needsInsurance: category.needsInsurance,
    needsEmergency: category.needsEmergency,
    year: category.year,
    isSam: category.association === 'sam',
    registeredViaTrustedAssociation:
      category.year === 2021 ? registeredViaTrustedAssociation(license) : false,
    needsMembership: category.needsMembership,
  })
}

export function licenseTasks(props: LicenseTasksProps): LicenseTasksOverview {
  const { submittedLicense, licensePaid, registeredViaTrustedAssociation } = props
  const allTasks: (LicenseTask | undefined)[] = [
    submittedLicense || registeredViaTrustedAssociation
      ? {
          type: 'licenseSubmitted' as const,
          done: true,
          verified: true,
        }
      : advertiseLicence()
      ? {
          type: 'licenseSubmitted' as const,
          done: false,
          verified: true,
        }
      : undefined,

    licensePaid === undefined
      ? undefined
      : {
          type: 'licenseFees' as const,
          done: licensePaid || registeredViaTrustedAssociation,
          verified: true,
        },

    ...innerProfileTasks({ needsBikes: false, ...props }),
  ]

  return licenseTasksOverview(allTasks)
}

function advertiseLicence() {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  return nextLicenseYear >= currentYear || (nextLicenseYear === currentYear && currentMonth <= 2)
}

interface InscriptionTasksProps {
  documents: Documents | undefined
  needsHealthCheck: boolean
  needsInsurance: boolean
  needsEmergency: boolean
  needsBikes: boolean
  needsMembership: boolean
  registeredViaTrustedAssociation: boolean
  year: Year
  isSam: boolean
  inscription:
    | EnlistedDayInscriptionDayCategory
    | EnlistedDayInscriptionDayCategoryDraft
    | EnlistedDayInscriptionYearCategoryDraft
    | EnlistedDayInscriptionYearCategory
    | EnlistedLicenseInscription
    | undefined
}

export function inscriptionTasks(props: InscriptionTasksProps): LicenseTasksOverview {
  const { inscription } = props
  const allTasks: (LicenseTask | InscriptionTask | undefined)[] = [
    inscription ? { type: 'inscriptionSubmitted', done: true, verified: true } : undefined,
    inscription ? { type: 'inscriptionPayment', done: inscription.paid, verified: true } : undefined,
    inscription?.type === 'enlistedDayInscriptionDayCategoryDraft' ||
    inscription?.type === 'enlistedDayInscriptionYearCategoryDraft'
      ? { type: 'adminConfirmationPending', done: false, verified: true }
      : undefined,
    ...innerProfileTasks(props),
  ]
  return licenseTasksOverview(allTasks)
}

interface ProfileTasksProps {
  documents: Documents | undefined
  needsHealthCheck: boolean
  needsInsurance: boolean
  needsEmergency: boolean
  needsMembership: boolean
  needsBikes: boolean
  registeredViaTrustedAssociation: boolean
  year: Year
  isSam: boolean
}

export function profileTasks(props: ProfileTasksProps): LicenseTasksOverview {
  return licenseTasksOverview(innerProfileTasks(props))
}

function innerProfileTasks(props: ProfileTasksProps): (LicenseTask | undefined)[] {
  const {
    documents,
    needsBikes,
    needsHealthCheck,
    needsInsurance,
    needsEmergency,
    needsMembership,
    registeredViaTrustedAssociation,
    year,
    isSam,
  } = props

  return [
    {
      type: 'personalData' as const,
      done: !!documents?.personalData,
      verified: true,
    },
    isSam && needsMembership
      ? {
          type: 'memberFees' as const,
          done:
            !!documents?.memberFeesPaidAt ||
            !!documents?.memberFeesPaymentHistory?.[year] ||
            registeredViaTrustedAssociation,
          verified: true,
        }
      : undefined,
    needsEmergency
      ? documents?.emergency
        ? {
            type: 'checkEmergency' as const,
            done: newEnough(documents.emergency.lastChecked, year),
            verified: true,
          }
        : { type: 'emergency' as const, done: false, verified: true }
      : undefined,
    needsHealthCheck
      ? {
          type: 'healthCheck' as const,
          ...uploadStatusPrechecked(year, documents?.healthCheck, { registeredViaTrustedAssociation }),
        }
      : undefined,
    needsInsurance
      ? {
          type: 'insurance',
          ...uploadStatusPrechecked(year, documents?.insurance, { registeredViaTrustedAssociation }),
        }
      : undefined,
    needsBikes
      ? {
          type: 'bike',
          done:
            Object.values(documents?.bikes || {}).filter((bike) => bike.status !== 'deleted').length > 0,
          verified: true,
        }
      : undefined,
    { type: 'photo', ...uploadStatus(documents?.photo) },
  ]
}

function licenseTasksOverview(
  allTasksWithUndefined: (LicenseTask | InscriptionTask | undefined)[]
): LicenseTasksOverview {
  const allTasks = allTasksWithUndefined.filter(truthy)
  const tasks = [...allTasks.filter((task) => task.done), ...allTasks.filter((task) => !task.done)]
  const tasksDone = tasks.filter((task) => task.done).length
  const allDone = tasksDone === tasks.length
  const allVerified = allDone && tasks.every((task) => task.verified)
  const tasksTotal = tasks.length
  return { tasks, allDone, allVerified, tasksDone, tasksTotal }
}

export function uploadStatusPrechecked(
  year: number,
  commonUploadMetadata: CommonUploadMetadata | undefined,
  { registeredViaTrustedAssociation }: { registeredViaTrustedAssociation: boolean }
) {
  const page1 = commonUploadMetadata?.page1
  const uploadStats = uploadStatus(commonUploadMetadata)
  const updatedAt = page1?.updatedAt
  return {
    done: registeredViaTrustedAssociation || (newEnough(updatedAt, year) && uploadStats.done),
    verified: registeredViaTrustedAssociation || uploadStats.verified,
  }
}

/**
 * This is the performance optimized version of:
 *
 * `return updatedAt ? isBefore(addMonths(parseDate(`${year}-01-01`), -3), parseISO(updatedAt)) : true`
 */
function newEnough(updatedAt: string | undefined, currentYear: number) {
  if (!updatedAt) return true

  const currentYearTens = currentYear - 2000

  const year = parseInt10(updatedAt.substring(2, 4))
  if (year >= currentYearTens) return true

  const monthTen = updatedAt[5]
  if (year === currentYearTens - 1 && monthTen === '1') return true

  return false
}

function uploadStatus(info: { status?: UploadStatus; statusDetails?: UploadStatusDetails } | undefined) {
  const status = info?.statusDetails?.status || info?.status || 'notUploaded'
  return { done: uploadStatusOk(status), verified: status === 'verified' }
}

export function taskName(type: LicenseTaskType | InscriptionTaskType) {
  return t().licenseTasks.names[type]
}

export function taskShortName(type: LicenseTaskType | InscriptionTaskType) {
  return t().licenseTasks.shortNames[type]
}
