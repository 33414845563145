import { Box, Button, TextField } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useState } from 'react'
import { submitSportEventInscriptionToServer } from 'app/inscription/inscription-frontend-service'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { DayCategory } from 'shared/db/day-category'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { unlistEnabled } from 'shared/inscription/inscription-service'
import { Category } from 'shared/models/category'
import { SportEvent } from 'shared/sport-events/sport-events'
import { parseInt10 } from 'shared/utils/number'
import { useErrorSnackbarForError } from 'utils/snackbar'
import { useBoolean, UseBoolean } from 'utils/use-boolean'

interface UnlistLicenseInscriptionProps {
  category: Category | undefined
  user: UserQuery
  sportEvent: SportEvent
  date: string
  submitting: UseBoolean
}

export function UnlistLicenseInscription(props: UnlistLicenseInscriptionProps) {
  const { submitting, category, user, sportEvent, date } = props

  if (!category) return null

  if (!unlistEnabled(sportEvent)) return <UnsubscribeDisabled sportEvent={sportEvent} />

  return (
    <SubmitSportEventInscriptionButton
      sportEvent={sportEvent}
      submitting={submitting}
      confirmation={t().unlist.dialogConfirmation}
      normalButtonText={t().unlist.buttonText}
      submittingButtonText={t().unlist.inProgress}
      disabled={!unlistEnabled(sportEvent)}
      onSubmit={async (donationAmount) => {
        // TODO: later: only allow admin after a certain time before the event (usually Tuesday before the event)
        await submitSportEventInscriptionToServer({
          categoryId: category.id,
          uid: user.uid,
          sportEventId: sportEvent.id,
          type: 'unlistedLicenseInscription',
          donationAmount,
          date,
        })
      }}
    />
  )
}

interface UnlistDayLicenseInscriptionButtonProps {
  category: Category | DayCategory
  user: UserQuery
  sportEvent: SportEvent
  date: string
  submitting: UseBoolean
}

export function UnlistDayLicenseInscriptionButton(props: UnlistDayLicenseInscriptionButtonProps) {
  const { submitting, category, user, sportEvent, date } = props

  if (!unlistEnabled(sportEvent)) return <UnsubscribeDisabled sportEvent={sportEvent} />

  return (
    <SubmitSportEventInscriptionButton
      disabled={!unlistEnabled(sportEvent)}
      sportEvent={sportEvent}
      submitting={submitting}
      confirmation={t().unlist.dialogConfirmation}
      normalButtonText={t().unlist.buttonText}
      submittingButtonText={t().unlist.inProgress}
      onSubmit={async (donationAmount) => {
        // TODO: later: only allow admin after a certain time before the event (usually Tuesday before the event)
        await submitSportEventInscriptionToServer({
          categoryId: category.id,
          uid: user.uid,
          sportEventId: sportEvent.id,
          type: 'unlistedDayLicenseInscription',
          donationAmount,
          date,
        })
      }}
    />
  )
}

function UnsubscribeDisabled({ sportEvent }: { sportEvent: SportEvent }) {
  return (
    <Alert severity="info" variant="outlined">
      <AlertTitle>{t().inscription.unsubscribeNotPossibleAnymoreTitle(sportEvent.name)}</AlertTitle>
      {t().inscription.unsubscribeNotPossibleAnymoreText(sportEvent.name)}
    </Alert>
  )
}

interface SubmitSportEventInscriptionButtonProps {
  onSubmit: (donationAmount: number) => Promise<void>
  submitting: UseBoolean
  normalButtonText: string
  submittingButtonText: string
  confirmation: string
  sportEvent: SportEvent
  disabled: boolean
}

function SubmitSportEventInscriptionButton(props: SubmitSportEventInscriptionButtonProps) {
  const {
    submitting,
    onSubmit,
    normalButtonText,
    submittingButtonText,
    confirmation,
    sportEvent,
    disabled,
  } = props

  const buttonSubmitting = useBoolean(false)
  const showError = useErrorSnackbarForError()
  const dialog = useDialog()
  const [donationAmount, setDonationAmount] = useState(
    sportEvent.cancelled ? sportEvent.suggestedDonationAmount || 0 : 0
  )

  return (
    <>
      <Button variant="text" fullWidth={false} disabled={submitting.value} onClick={() => dialog.open()}>
        {buttonSubmitting.value ? submittingButtonText : normalButtonText}
      </Button>
      <ConfirmDialog
        title={t().unlist.dialogTitle}
        dialog={dialog}
        disabled={disabled}
        onConfirm={async () => {
          // TODO: later: only allow admin after a certain time before the event (usually Tuesday before the event)

          try {
            submitting.setTrue()
            buttonSubmitting.setTrue()
            await onSubmit(donationAmount)
          } catch (error) {
            showError(error)
          }
          buttonSubmitting.setFalse()
          submitting.setFalse()
        }}
        buttonText={
          donationAmount > 0
            ? t().unlist.dialogConfirmDonation(donationAmount.toFixed(2))
            : t().unlist.dialogConfirmWithoutDonation
        }
      >
        <Box mb={2}>{confirmation}</Box>
        {sportEvent.cancelled && (sportEvent.suggestedDonationAmount || 0) !== 0 && (
          <Box>
            {t().unlist.donation(sportEvent.suggestedDonationAmount.toFixed(2))}
            <Box mt={2}>
              <TextField
                type="number"
                fullWidth
                variant="outlined"
                id="donationAmount"
                label={t().unlist.donationAmount}
                value={donationAmount}
                onChange={(e) => setDonationAmount(parseInt10(e.target.value))}
              />
            </Box>
          </Box>
        )}
      </ConfirmDialog>
    </>
  )
}
