import { Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { Inscription, SportEvent } from 'shared/sport-events/sport-events'
import { truthy } from 'shared/utils/array'

export function transponderDescription(
  sportEvent: SportEvent,
  documents: Documents,
  inscription: Inscription
) {
  const borrowed = inscription.borrowedTransponder
  const own = ownTransponderDescription(sportEvent, documents, !!borrowed)
  const transponders = [
    borrowed && `${t().transponders.borrowedTransponder}: ${borrowed}`,
    own && `${t().transponders.privateTransponder}: ${own}`,
  ]

  return transponders.filter(truthy).join('; ')
}

function ownTransponderDescription(sportEvent: SportEvent, documents: Documents, borrowed: boolean) {
  return Object.values(documents.transponder?.transponders || {})
    .filter((transponder) => transponder.type === sportEvent.transponderType)
    .map((transponder) => transponder.transponderNumber)
    .filter(truthy)
    .slice(0, borrowed ? 1 : 2)
    .join(', ')
}
