import { Box } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { t } from 'shared/i18n/current'

export function MayBeOptionalMessage({ needed }: { needed: boolean }) {
  return needed ? null : (
    <Box pb={3}>
      <Alert severity="info" variant="outlined">
        <AlertTitle>{t().optionalMessage.title}</AlertTitle>
        {t().optionalMessage.messagePart1} <strong>{t().optionalMessage.messagePart2Highlighted}</strong>
        {t().optionalMessage.messagePart3}
      </Alert>
    </Box>
  )
}
