import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  Box,
  Typography,
} from '@material-ui/core'
import { last } from 'lodash'
import { useState } from 'react'
import { useTransactions } from 'app/db/db-hooks/financial-db-hooks'
import { db } from 'app/db/frontend-db'
import { actions, tableHeaders } from 'app/export/table'
import { useIsSmallerThanSm, useIsSmallerThanXs } from 'app/layout/use-small-screen'
import { renderHeader, renderCell } from 'app/layouts/downloadable-table'
import { NewManualBookingButton } from 'app/pages/admin/bookings/new-manual-booking'
import { EditPersonalDataButton } from 'app/pages/admin/users/EditPersonalDataButton'
import {
  MapTransactionParams,
  mapTransactionToView,
  TransactionView,
} from 'app/pages/billing/map-transactions'
import { useUserContext } from 'app/themes/user-context'
import { storeNewBill } from 'shared/billing/billing-service'
import { routes } from 'shared/config/routes'
import { Documents, UserQuery } from 'shared/db/db'
import { sortedTransactionsWithTotal } from 'shared/db/transactions-service'
import { t } from 'shared/i18n/current'
import { associationName } from 'shared/models/associations'
import { groupByLiteral } from 'shared/utils/array'
import { olderThan } from 'shared/utils/date'
import { toChf, toChfOrDash } from 'shared/utils/number'
import { RoutedButton } from 'utils/buttons/routed-button'
import {
  CopyToClipboardAbbreviation,
  CopyToClipboardAbbreviationAutoShort,
} from 'utils/copy-to-clipboard'
import { FriendlyError } from 'utils/errors'
import { FlexButtons } from 'utils/flex-buttons'
import { useErrorSnackbarForError } from 'utils/snackbar'
import { useBoolean } from 'utils/use-boolean'

interface ListTransactionsProps {
  admin: UserQuery
  user: UserQuery
  documents: Documents | undefined
}

export function ListTransactions({ documents, admin, user }: ListTransactionsProps) {
  const userContext = useUserContext()
  const { data, error, loadingOrError } = useTransactions(user)
  const [callbackError, setError] = useState<Error | null>(null)
  const smallScreen = useIsSmallerThanSm()
  const xsScreen = useIsSmallerThanXs()
  const showAll = useBoolean(false)

  if (loadingOrError) return null

  const headers = tableHeaders([
    ...(smallScreen
      ? [{ value: t().position }]
      : [
          t().date,
          ...(userContext.adminOrAssociationAdmin ? [{ value: t().association }] : []),
          t().reference,
          t().status,
        ]),

    { align: 'right', value: xsScreen ? t().amountTable : t().amountTableCHF },
    { align: 'right', value: xsScreen ? t().accountBalance : t().accountBalanceCHF },
    { ...actions(), maxWidth: smallScreen ? 50 : undefined },
  ])

  const rawTransactions = sortedTransactionsWithTotal(data)
  const transactionRows = rawTransactions.map((transaction) => {
    const params = {
      user,
      smallScreen,
      admin,
      userContext,
      setError,
      transaction,
      transactions: rawTransactions,
    }
    return { params, result: mapTransactionToView(params) }
  })
  const contents = toTransactionsToViews(
    userContext.adminOrAssociationAdmin,
    transactionRows,
    smallScreen
  )

  return (
    <>
      {userContext.adminOrAssociationAdmin && (
        <Grid container justifyContent="flex-end">
          <Box mb={2}>
            <FlexButtons justifyContent="flex-end">
              <EditPersonalDataButton
                type="button"
                user={{ uid: admin.uid }}
                documents={documents}
                email={documents?.personalData?.email || ''}
              />
              <RoutedButton to={routes.approvedLicenses.generateTo(user.uid)} variant="contained">
                {routes.approvedLicenses.text()}
              </RoutedButton>
              <RoutedButton to={routes.licenseDrafts.generateTo(user.uid)} variant="contained">
                {routes.licenseDrafts.text()}
              </RoutedButton>
              <RoutedButton to={routes.assignLicense.generateTo(user.uid)} variant="contained">
                {routes.assignLicense.text()}
              </RoutedButton>
              <RoutedButton to={routes.licenseBookingsByRider.generateTo(user.uid)} variant="contained">
                {routes.licenseBookingsByRider.text()}
              </RoutedButton>
              <RoutedButton to={routes.payments.generateTo(user.uid)} variant="contained">
                {routes.payments.text()}
              </RoutedButton>
              <RoutedButton to={routes.userEvents.generateTo(user.uid)} variant="contained">
                {routes.userEvents.text()}
              </RoutedButton>
              <NewManualBookingButton color="default" admin={admin} defaultValues={{ uid: user.uid }} />
              <GenerateBillButton admin={admin} rider={user} />
            </FlexButtons>
          </Box>
        </Grid>
      )}
      <FriendlyError error={error} />
      <FriendlyError error={callbackError} />
      <Box mb={2}>
        <Typography variant="h5">
          {t().accountBalanceCHF}: {toChf(transactionRows[0]?.result?.total || 0)}
        </Typography>
      </Box>
      {transactionRows.length > 0 && (
        <>
          <TableContainer>
            <Table size="small" padding={xsScreen ? 'none' : 'normal'}>
              <TableHead>
                <TableRow>{headers.map((header, index) => renderHeader(header, index))}</TableRow>
              </TableHead>
              <TableBody>
                {contents.current.map((row, index) => (
                  <TableRow hover key={index}>
                    {row.map((value, index) => renderCell(value, index, headers?.[index]))}
                  </TableRow>
                ))}
                {showAll.value &&
                  contents.old.map((row, index) => (
                    <TableRow hover key={index}>
                      {row.map((value, index) => renderCell(value, index, headers?.[index]))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!showAll.value && contents.old[0] && (
            <Button fullWidth onClick={showAll.toggle}>
              {t().showOldTransactions}
            </Button>
          )}
        </>
      )}
    </>
  )
}

export function GenerateBillButton({ admin, rider }: { admin: UserQuery; rider: UserQuery }) {
  const association = useUserContext().associationAdmin
  const showError = useErrorSnackbarForError()

  return association === false ? null : (
    <Button
      variant="contained"
      onClick={async () => {
        try {
          await storeNewBill(db, { admin, rider }, association)
        } catch (error) {
          showError(error)
        }
      }}
    >
      {t().financials.generateNewBill}
    </Button>
  )
}

function toTransactionsToViews(
  admin: boolean,
  props: TransactionViewWithParams[],
  smallScreen: boolean
) {
  const data = props.reduce((transactions, current) => {
    const lastTransaction = last(transactions)
    const currentCopy = current.result.censored ? { ...current, result: { ...current.result } } : current
    if (currentCopy.result.censored && lastTransaction) {
      currentCopy.result.date = lastTransaction.result.date
      currentCopy.result.datetime = lastTransaction.result.datetime
      currentCopy.result.datetimeISO = lastTransaction.result.datetimeISO
    }
    if (currentCopy.result.censored && lastTransaction?.result.censored) {
      lastTransaction.result.amount += currentCopy.result.amount
    }
    if (currentCopy.result.censored && last(transactions)?.result.censored) return transactions
    return [...transactions, currentCopy]
  }, [] as TransactionViewWithParams[])
  const groups = groupByLiteral(data, (row) =>
    olderThan(row.params.transaction.date, { months: 3 }) ? 'old' : 'current'
  )
  return {
    current: (groups.current || []).map((value) => toTransactionView(admin, smallScreen, value)),
    old: (groups.old || []).map((value) => toTransactionView(admin, smallScreen, value)),
  }
}

function toTransactionView(admin: boolean, smallScreen: boolean, props: TransactionViewProps) {
  const { result } = props
  const association = extractAssociation(admin, result)
  const internalRemarks = admin && result.internalRemarks ? result.internalRemarks : ''
  return [
    ...(smallScreen
      ? [
          result.censored ? (
            <>
              <p>{result.date}</p>
              <p>{t().financials.bookingsOtherAssociations}</p>
            </>
          ) : (
            <>
              <p>
                <em>{renderStatus(result, internalRemarks)}</em>
              </p>
              {association && (
                <p>
                  {t().association}: {association}
                </p>
              )}
              <p>{result.date}</p>
              <p>{result.reference}</p>
            </>
          ),
        ]
      : result.censored
      ? [result.date, `${t().financials.bookingsOtherAssociations}`, '', '']
      : [
          result.date,
          ...(association ? [association] : []),
          result.reference,
          renderStatus(result, internalRemarks),
        ]),
    <div key="amount" style={{ padding: smallScreen ? 4 : 0, whiteSpace: 'nowrap' }}>
      {toChfOrDash(-result.amount)}
    </div>,
    <div key="total" style={{ padding: smallScreen ? 4 : 0, whiteSpace: 'nowrap' }}>
      {toChfOrDash(result.total)}
    </div>,
    result.censored ? '' : result.actions,
  ]
}

function renderStatus(result: TransactionView, internalRemarks: string) {
  return (
    <>
      <CopyToClipboardAbbreviationAutoShort key="status" text={result.status} maxTextLength={22} />
      {internalRemarks && (
        <>
          {' '}
          <CopyToClipboardAbbreviation
            key="remarks"
            text={`*${t().remarks}`}
            details={internalRemarks}
          />
        </>
      )}
    </>
  )
}

function extractAssociation(admin: boolean, result: TransactionView) {
  if (!admin) return ''
  if (result.association === '') return '-'
  return associationName({ association: result.association })
}

type TransactionViewProps = {
  params: MapTransactionParams
  result: TransactionView
}

interface TransactionViewWithParams {
  params: MapTransactionParams
  result: TransactionView
}
