import {
  InscriptionBooking,
  LicenseBooking,
  LicenseDrafts,
  ManualBooking,
  ManualPayment,
} from 'shared/db/db'
import type { UploadStatusDetails } from 'shared/models/upload-status'
import { Inscription, SportEvent } from 'shared/sport-events/sport-events'

export type UserEvent =
  | UserEventLicenseSubmitted
  | UserEventSignIn
  | UserEventUpload
  | UserEventInscription
  | SportEventEdit
  | EditManualBooking
  | EditManualPayment
  | EditInscriptionBooking
  | EditLicenseBooking
  | RenameTag

export interface UserEventLicenseSubmitted extends UserEventBase {
  type: 'licenseSubmitted'
  details?: LicenseDrafts
}

export interface UserEventSignIn extends UserEventBase {
  type: 'signIn'
  provider: 'google' | 'facebook' | 'email' | 'password'
}

export interface UserEventUpload extends UserEventBase {
  type: 'upload'
  model: 'healthCheck' | 'photo' | 'insurance'
  details?: UploadStatusDetails
}

export interface UserEventInscription extends UserEventBase {
  type: 'inscription'
  inscriptionType: Inscription['type']
  action: 'approve' | 'delete' | 'create'
  details?: Inscription
}

export interface SportEventEdit extends UserEventBase {
  type: 'editSportEvent'
  editType: 'add' | 'update' | 'delete'
  details?: SportEvent
}

export interface EditManualBooking extends UserEventBase {
  type: 'editManualBooking'
  editType: 'add' | 'update' | 'delete'
  details?: ManualBooking
}

export interface EditManualPayment extends UserEventBase {
  type: 'editManualPayment'
  editType: 'add' | 'update' | 'delete'
  details?: ManualPayment
}

export interface RenameTag extends UserEventBase {
  type: 'renameTag'
  editType: 'manualPayment' | 'manualBooking'
  details?: { ids: { uid: string; elementId: string }[]; oldTag: string; newTag: string }
}

export interface EditInscriptionBooking extends UserEventBase {
  type: 'editInscriptionBooking'
  editType: 'add' | 'update' | 'delete'
  details?: InscriptionBooking
}

export interface EditLicenseBooking extends UserEventBase {
  type: 'editLicenseBooking'
  editType: 'add' | 'update' | 'delete'
  details?: LicenseBooking
}

export interface UserEventBase {
  id: string
  uid: string
  byUid: string
  date: string
  details?: any
}

export function endUserEvent(user: { uid: string }): UserEventBase {
  return { id: '', uid: user.uid, byUid: user.uid, date: new Date().toISOString() }
}

export function adminUserEvent({ admin, user }: AdminUserEventProps): UserEventBase {
  return { id: '', uid: user.uid, byUid: admin.uid, date: new Date().toISOString() }
}

interface AdminUserEventProps {
  user: { uid: string }
  admin: { uid: string }
}
