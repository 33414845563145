import assertNever from 'assert-never'
import { AssociationID } from 'shared/models/associations'
import { drawSVG, PDFDoc } from 'utils/pdf/pdf'

export function addLogo(
  association: AssociationID | 'racemanager',
  pdf: PDFDoc,
  x = 25,
  y = 25,
  type: 'light' | 'dark' = 'light'
) {
  return association === 'fms'
    ? type === 'light'
      ? addFmsLogo(pdf, x, y)
      : addFmsLogoForDarkBackground(pdf, x, y)
    : association === 'mxrs'
    ? addMxrsLogo(pdf, x, y)
    : association === 'sam'
    ? addSamLogo(pdf, x, y)
    : association === 'sjmcc'
    ? addSjmccLogo(pdf, x, y)
    : association === 'afm'
    ? addAfmLogo(pdf, x, y)
    : association === 'racemanager'
    ? racemanagerLogo(pdf, x, y)
    : assertNever(association)
}

async function addFmsLogo(pdf: PDFDoc, x: number, y: number) {
  const { fmsLogoLightBackground: fmsLogo } = await import(
    'app/pages/billing/logos/fms-logo-for-light-background'
  )
  await drawSVG(pdf, fmsLogo, x, y)
}

async function addFmsLogoForDarkBackground(pdf: PDFDoc, x: number, y: number) {
  const { fmsLogoDarkBackground: fmsLogoForDarkBackground } = await import(
    'app/pages/billing/logos/fms-logo-for-dark-background'
  )
  await drawSVG(pdf, fmsLogoForDarkBackground, x, y)
}

async function addMxrsLogo(pdf: PDFDoc, x: number, y: number) {
  const { mxrsLogo } = await import('app/pages/billing/logos/mxrs-logo')
  await drawSVG(pdf, mxrsLogo, x, y)
}

async function addAfmLogo(pdf: PDFDoc, x: number, y: number) {
  const { afmLogo } = await import('app/pages/billing/logos/afm-logo')
  await drawSVG(pdf, afmLogo, x, y)
}

async function addSamLogo(pdf: PDFDoc, x: number, y: number) {
  const { samLogo } = await import('app/pages/billing/logos/sam-logo')
  await drawSVG(pdf, samLogo, x, y)
}

async function addSjmccLogo(pdf: PDFDoc, x: number, y: number) {
  const { sjmccLogo } = await import('app/pages/billing/logos/sjmcc-logo')
  await drawSVG(pdf, sjmccLogo, x, y)
}

async function racemanagerLogo(pdf: PDFDoc, x: number, y: number) {
  const { racemanagerLogo } = await import('app/pages/billing/logos/racemanager-logo')
  await drawSVG(pdf, racemanagerLogo, x, y)
}
