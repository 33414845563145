import { isInscriptionPaid } from 'shared/billing/payment-status'
import { DB, InscriptionBooking } from 'shared/db/db'
import { updateDayInscriptionPublicStatistics } from 'shared/inscription/inscription-payment-status'
import { Inscription, SportEvent } from 'shared/sport-events/sport-events'

export async function recalculateInscriptionStatistics(db: DB, sportEvent: SportEvent) {
  const inscriptionsWithBookings = await loadInscriptionsWithBookings(db, sportEvent)
  await deleteInscriptionStatistics(db, sportEvent)
  await createInscriptionStatistics(inscriptionsWithBookings, db)
}

async function loadInscriptionsWithBookings(
  db: DB,
  sportEvent: SportEvent
): Promise<InscriptionWithBookings[]> {
  const inscriptions = await db.loadInscriptionsBySportEvent(sportEvent)
  return Promise.all(
    inscriptions.map(async (inscription) => ({
      inscription,
      bookings: await db.loadInscriptionBookingsAndSportEvent(inscription, sportEvent),
    }))
  )
}

async function deleteInscriptionStatistics(db: DB, sportEvent: SportEvent) {
  await db.clearInscriptionPublicStatistics(sportEvent)
}

async function createInscriptionStatistics(inscriptions: InscriptionWithBookings[], db: DB) {
  await Promise.all(inscriptions.map((inscription) => createInscriptionStatistic(db, inscription)))
}

async function createInscriptionStatistic(db: DB, inscriptionWithBookings: InscriptionWithBookings) {
  const { inscription, bookings } = inscriptionWithBookings
  const paid = isInscriptionPaid(inscription, bookings)
  await updateDayInscriptionPublicStatistics(db, { ...inscription, paid })
}

interface InscriptionWithBookings {
  inscription: Inscription
  bookings: InscriptionBooking[]
}
