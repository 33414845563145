import { DB } from 'shared/db/db'
import { SportEvent } from 'shared/sport-events/sport-events'

export const id = '2022-01-18T22-39-40-305Z'

export const description = 'Migrates the `SportEvent` `categoryIds` to `licenseCategoryIds`'

export async function up(db: DB) {
  const sportEvents: Record<string, SportEvent> | undefined = await db.load('sportEvents')
  if (!sportEvents) throw new Error('No sport events found')

  const migratedSportEvents = Object.fromEntries(
    Object.entries(sportEvents).map(
      ([id, sportEvent]) =>
        [
          id,
          {
            ...sportEvent,
            licenseCategoryIds: sportEvent.licenseCategoryIds || (sportEvent as any).categoryIds || '',
          },
        ] as const
    )
  )
  await db.set('sportEvents', migratedSportEvents)
}
