import { db } from 'app/db/frontend-db'
import { parseXlsx } from 'app/export/xlsx'
import { ArrayBufferUploadButton } from 'app/upload/upload-button'
import { formatDate, parseDateDe } from 'shared/utils/date'
import { parseInt10, validNumber } from 'shared/utils/number'

export function UploadPaidMemberFees() {
  return (
    <>
      <ArrayBufferUploadButton
        onUpload={(fileContents) => handleMemberFeeFiles(fileContents.map(({ buffer }) => buffer))}
      >
        Mitgliederbeiträge Upload
      </ArrayBufferUploadButton>
    </>
  )
}

async function handleMemberFeeFiles(fileContents: ArrayBuffer[]) {
  const paymentsByMember = new Map((await Promise.all(fileContents.map(parsePaidMemberFees))).flat())
  if (paymentsByMember.size === 0) throw new Error(`Invalid member numbers ${paymentsByMember}`)

  const documents = await db.loadAllDocuments()
  await Promise.all(
    Object.entries(documents).map(async ([uid, documents]) => {
      const memberNumber = documents.personalData?.samMemberNumber
      const paidAt = memberNumber && paymentsByMember.get(memberNumber)
      if (!documents.memberFeesPaidAt && paidAt)
        await db.setMemberFeesPaidAt({ uid }, formatDate(paidAt))
    })
  )
}

async function parsePaidMemberFees(buffer: ArrayBuffer) {
  const parsed = await parseXlsx<RawMemberFeeRow>(buffer)
  const data = parsed.filter(isNormalRow).map(parseRow).filter(isNonEmptyRow).map(addDate)
  if (data.some(isInvalidRow)) throw new Error(`Invalid member fees file ${data}`)
  return data.map(({ id, date }) => [id, parseDateDe(date)] as const)
}

function isNormalRow(el: RawMemberFeeRow) {
  return el['Mitglied-Nr'] && el['Rechnungsversand'] && el['Zahlungsdatum']
}

function parseRow(el: RawMemberFeeRow): MemberFeeRow {
  return {
    id: parseID(el['Mitglied-Nr']),
    amount: parseAmount(el['Rechnungsbetrag']),
    billedAt: el['Rechnungsversand'],
    paidAt: el['Zahlungsdatum'],
  }
}

function parseID(rawID: string | number) {
  if (typeof rawID === 'number') return rawID
  if (!validNumber(rawID)) throw new Error(`Invalid member id: ${rawID}`)
  return parseInt10(rawID)
}

function parseAmount(amount: string | number): number {
  return typeof amount === 'number' ? amount : parseFloat(amount)
}

function isNonEmptyRow({ id, billedAt, paidAt }: MemberFeeRow) {
  return id && billedAt && paidAt
}

function addDate(row: MemberFeeRow) {
  return { ...row, date: row.amount === 0 && row.paidAt === '-' ? row.billedAt : row.paidAt }
}

function isInvalidRow({ id, date, amount }: MemberFeeRow) {
  return typeof id !== 'number' || typeof date !== 'string' || typeof amount !== 'number'
}

interface RawMemberFeeRow {
  'Mitglied-Nr': number | string
  Zahlungsdatum: string
  Rechnungsbetrag: number | string
  Rechnungsversand: string
}

interface MemberFeeRow {
  id: number
  billedAt: string
  paidAt: string
  amount: number
  date?: string
}
