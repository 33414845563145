import { Button, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Fragment, useState } from 'react'
import { EditPersonalDataButton } from './EditPersonalDataButton'
import { useAllDocuments } from 'app/db/db-contexts/documents-context'
import { useAssociationAdmins } from 'app/db/db-hooks/use-is-admin'
import { db } from 'app/db/frontend-db'
import { actions, tableHeaders } from 'app/export/table'
import { useDialog, ConfirmDialog } from 'app/layouts/confirm-dialog'
import { TableBox } from 'app/layouts/table-box'
import { useUserContext } from 'app/themes/user-context'
import { User } from 'app/users/user'
import { t } from 'shared/i18n/current'
import {
  associationByID,
  AssociationID,
  associations,
  FullAssociation,
} from 'shared/models/associations'
import { fullName } from 'shared/models/personal-data'
import { StoredUser } from 'shared/models/stored-user'
import { useAsyncError } from 'utils/errors'
import { Disp } from 'utils/react'

interface UserManagementProps {
  user: User
}

export function AssociationAdminManagement({ user }: UserManagementProps) {
  const { data, loading, error, loadingOrError } = useAssociationAdmins()
  const [selectedUser, setSelectedUser] = useState<StoredUser | null>(null)
  const userContext = useUserContext()
  const allDocuments = useAllDocuments()

  if (!userContext.adminOrAssociationAdmin) return null

  const admins = data.admins.filter((admin) => userContext.canViewAssociation(admin.association))

  return (
    <TableBox
      title={t().admins.associationAdmins}
      loading={loading || allDocuments.loading}
      error={error || allDocuments.error}
      data={
        !loadingOrError && {
          headers: tableHeaders(['Verband', 'UID', 'E-Mail', 'Name', actions()]),
          contents: admins.map((admin) => {
            const documents = allDocuments.data[admin.uid]
            return [
              associationByID(admin.association).name,
              admin.uid,
              admin.user.email,
              fullName(documents?.personalData),
              <>
                <RemoveAssociationAdminButton admin={admin} user={user} />
                <EditPersonalDataButton
                  user={{ uid: admin.uid }}
                  documents={documents}
                  email={admin.user.email}
                />
              </>,
            ]
          }),
          ids: admins.map(({ uid }) => uid),
          rawData: admins.map((row) =>
            JSON.stringify({ row, docs: fullName(allDocuments.data[row.uid]?.personalData) })
          ),
        }
      }
    >
      {!loadingOrError && (
        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
          <Grid item>
            <Autocomplete
              size="small"
              options={data.nonAdmins}
              getOptionLabel={(option) => option.email}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label={t().admins.adminEmail} variant="outlined" />
              )}
              value={selectedUser}
              onChange={(_el, value) => setSelectedUser(value)}
            />
          </Grid>
          <Grid item>
            {associations.map(
              (association) =>
                userContext.canEditAssociation(association.id) && (
                  <AddAssociationAdminButton
                    key={association.id}
                    association={association}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                  />
                )
            )}
          </Grid>
        </Grid>
      )}
    </TableBox>
  )
}

interface AddAssociationAdminButtonProps {
  association: FullAssociation
  selectedUser: StoredUser | null
  setSelectedUser: Disp<StoredUser | null>
}

function AddAssociationAdminButton(props: AddAssociationAdminButtonProps) {
  const { association, selectedUser, setSelectedUser } = props

  const addAdminDialog = useDialog()
  const throwError = useAsyncError()

  return (
    <Fragment key={association.id}>
      <Button onClick={() => addAdminDialog.open()} disabled={!selectedUser}>
        {t().admins.addAssociationAdmin(association.name)}
      </Button>
      <ConfirmDialog
        title={`${association.name} ${t().admins.addAdmin}`}
        buttonText={t().admins.addAsAssociationAdmin(association.name)}
        dialog={addAdminDialog}
        onClose={() => setSelectedUser(null)}
        onConfirm={() =>
          selectedUser
            ? db.setAssociationAdmin(selectedUser, association.id).catch(throwError)
            : Promise.resolve()
        }
      >
        {t().admins.confirmAddAssociationAdmin(selectedUser?.email || '', association.name)}
      </ConfirmDialog>
    </Fragment>
  )
}

interface RemoveAssociationAdminButtonProps {
  admin: { user: StoredUser; association: AssociationID }
  user: User
}

function RemoveAssociationAdminButton({ admin, user }: RemoveAssociationAdminButtonProps) {
  const removeAdminDialog = useDialog()
  const throwError = useAsyncError()

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        disabled={admin.user.uid === user.uid}
        onClick={() => removeAdminDialog.open()}
      >
        Entfernen
      </Button>
      <ConfirmDialog
        title={t().admins.removeAssociationAdmin}
        buttonText={t().admins.removeAsAssociationAdmin}
        dialog={removeAdminDialog}
        onConfirm={() => db.removeAssociationAdmin(admin.user, admin.association).catch(throwError)}
      >
        {t().admins.confirmRemoveAsAssociationAdmin(admin.user.email)}
      </ConfirmDialog>
    </>
  )
}
