import type { InscriptionBooking, LicenseBooking } from 'shared/db/db'
import { todoMigrateAssociation } from 'shared/models/associations'
import { originalTransponderOption } from 'shared/models/transponder'

export function todoMigrateInscriptionBooking(booking: InscriptionBooking): InscriptionBooking {
  return {
    ...booking,
    item: {
      ...booking.item,
      association: todoMigrateAssociation(booking.item.association),
    },
  }
}

export function todoMigrateLicenseBooking(booking: LicenseBooking): LicenseBooking {
  const subtype = migrateLicenseBookingSubtype(booking)
  const reverse = migrateLicenseBookingReverse(booking)
  return {
    ...booking,
    year: booking.year || 2021,
    item: {
      ...booking.item,
      association: todoMigrateAssociation(booking.item.association),
      subtype: subtype as any,
      reverse,
      ...(booking.item.type === 'transponderLineItem'
        ? {
            transponderId: booking.item.transponderId || originalTransponderOption().id,
            transponderType: booking.item.transponderType || originalTransponderOption().type,
          }
        : {}),
    },
  }
}

function migrateLicenseBookingSubtype(booking: LicenseBooking): LicenseBooking['item']['subtype'] {
  if (booking.item.subtype as any) return booking.item.subtype
  const name = booking.item.name

  if (booking.item.type === 'transponderLineItem') return 'transponder'
  if (booking.item.type === 'categoryLineItem') {
    if (name.startsWith('Zusatzlizenz') || name.startsWith('Storniert: Zusatzlizenz'))
      return 'additionalLicense'
    if (name.startsWith('Zuschlag') || name.startsWith('Storniert: Zuschlag')) return 'licenseSurcharge'
    if (name.startsWith('Rabatt') || name.startsWith('Storniert: Rabatt')) return 'licenseDiscount'
    if (name.startsWith('Lizenz') || name.startsWith('Storniert: Lizenz')) return 'mainLicense'
  }
  throw new Error(`unknown categoryLineItem subtype: ${JSON.stringify(booking)}`)
}

function migrateLicenseBookingReverse(booking: LicenseBooking): LicenseBooking['item']['reverse'] {
  if (typeof booking.item.reverse === 'boolean') return booking.item.reverse
  return booking.item.name.startsWith('Storniert:')
}
