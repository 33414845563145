import { Button } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { clamp } from 'lodash'
import { useState } from 'react'
import { captureMessage } from 'app/config/sentry'
import { db } from 'app/db/frontend-db'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { InscriptionMovements } from 'app/sport-events/inscription-movements'
import { calculateCategoryMovements } from 'app/sport-events/inscription-movements-calculation'
import {
  CategoryMovementProgress,
  processMovements,
} from 'app/sport-events/inscription-movements-update'
import { sportEventDatesOrNoDates, SportEventForm } from 'app/sport-events/sport-event-form'
import { categoryByIdFromStringRequired } from 'shared/data/categories-service'
import { DayCategory, generateDayCategorySearchString } from 'shared/db/day-category'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { recalculateInscriptionStatistics } from 'shared/inscription/inscription-statistics-service'
import { unknownDate } from 'shared/models/unknown-date'
import { serializeCategoryIDs } from 'shared/sport-events/sport-event-categories-serialization'
import {
  createOrUpdateDayCategories,
  extractLicenseCategoryDates,
  SportEventFromForm,
} from 'shared/sport-events/sport-event-categories-service'
import { startingListNameAndDateLookup } from 'shared/sport-events/sport-event-groups'
import { SportEvent } from 'shared/sport-events/sport-events'
import {
  ensureSportEventObjectIsNotFinalized,
  sportEventDescription,
} from 'shared/sport-events/sport-events-service'
import { parseDate } from 'shared/utils/date'
import { prettyJSON } from 'utils/debug'
import { Disp } from 'utils/react'
import { useErrorSnackbarForError } from 'utils/snackbar'

interface EditSportEventButtonProps {
  admin: UserQuery
  sportEvent: SportEvent
  dayCategories: DayCategory[]
}

export function EditSportEventButton(props: EditSportEventButtonProps) {
  const { admin, sportEvent, dayCategories } = props
  const dialog = useDialog()
  const [progress, setProgress] = useState<CategoryMovementProgress>()
  const showError = useErrorSnackbarForError()
  const title = t().sportsEvent.editSportEvent(sportEventDescription(sportEvent))

  return (
    <>
      <IconButtonWithTooltip tooltip={title} onClick={() => dialog.open()}>
        <Edit />
      </IconButtonWithTooltip>
      {dialog.isOpen && (
        <ConfirmDialog maxWidth="xl" fullWidth title={title} dialog={dialog} hideCloseButton>
          {progress ? (
            <UpdatingInscriptions progress={progress} setProgress={setProgress} />
          ) : (
            <SportEventForm
              admin={admin}
              initialSportEvent={sportEvent}
              initialDayCategories={dayCategories}
              onCancel={() => dialog.close()}
              onSubmit={async (props) => {
                const { sportEvent: sportEventForm } = props
                const newMovements = calculateCategoryMovements({
                  after: {
                    dates: props.categoryDates,
                    dayCategories: props.dayCategories,
                    licenseCategoryIds: props.licenseCategoryIds,
                  },
                  before: sportEvent,
                  initialDayCategories: dayCategories,
                })
                if (newMovements.length > 0) ensureSportEventObjectIsNotFinalized(sportEvent)

                try {
                  const dayCategories = await createOrUpdateDayCategories({ db, ...props })
                  const dayCategoryDates = dayCategories.dates
                  const licenseCategoryDates = extractLicenseCategoryDates(props)
                  const licenseCategoryIds = Object.keys(licenseCategoryDates)
                  const dayCategoryIds = Object.keys(dayCategoryDates)
                  const categoryIds = [...licenseCategoryIds, ...dayCategoryIds]

                  const { categoryGroupCounts, categoryGroupSizes } = calculateCategoryGroups(
                    dayCategories,
                    sportEventForm,
                    licenseCategoryIds
                  )

                  const disabledInscriptions = Object.fromEntries(
                    Object.entries(sportEventForm.disabledInscriptions || {}).filter(
                      ([k, v]) => categoryIds.includes(k) && v
                    )
                  )

                  const sportEventWithCategories = {
                    ...sportEventForm,
                    licenseCategoryIds: serializeCategoryIDs(licenseCategoryIds),
                    dayCategoryIds: serializeCategoryIDs(dayCategoryIds),
                    dayCategoryNamesForSearch: generateDayCategorySearchString(dayCategories.categories),
                    licenseCategoryDates,
                    dayCategoryDates,
                    disabledInscriptions,
                    categoryGroupCounts,
                    categoryGroupSizes,
                  }
                  await db.updateSportEvent(sportEventWithCategories)
                  await db.pushUserEvent({
                    id: '',
                    type: 'editSportEvent',
                    uid: admin.uid,
                    byUid: admin.uid,
                    date: new Date().toISOString(),
                    editType: 'update',
                    details: sportEventWithCategories,
                  })

                  await processMovements(sportEventWithCategories, newMovements, setProgress)
                  await recalculateInscriptionStatistics(db, sportEvent)

                  dialog.close()
                } catch (error: any) {
                  captureMessage(error?.message)
                  showError(error)
                }
              }}
            />
          )}
        </ConfirmDialog>
      )}
    </>
  )
}

interface UpdatingInscriptionsProps {
  progress: CategoryMovementProgress
  setProgress: Disp<CategoryMovementProgress | undefined>
}

export function calculateCategoryGroups(
  dayCategories: { dates: Record<string, string[]>; categories: DayCategory[] },
  sportEventForm: SportEventFromForm,
  licenseCategoryIds: string[]
) {
  const dayCategoryStartListNames = dayCategories.categories.map((category) => category.startListName)
  const dates = sportEventDatesOrNoDates({
    startsAt: parseDate(sportEventForm.startsAt),
    endsAt: parseDate(sportEventForm.endsAt),
  })
  const startListNames = licenseCategoryIds
    .map(categoryByIdFromStringRequired)
    .map((c) => c.startListName)
  const potentialLookups = [...startListNames, ...dayCategoryStartListNames].flatMap((startListName) =>
    [...dates, unknownDate].map((date) => startingListNameAndDateLookup(startListName, date))
  )
  const categoryGroupCounts = Object.fromEntries(
    Object.entries(sportEventForm.categoryGroupCounts || {})
      .map(([k, v]) => [k, clamp(v, 1, 1000)] as const)
      .filter(([k, v]) => v > 1 && potentialLookups.includes(k))
  )
  const categoryGroupSizes = Object.fromEntries(
    Object.entries(sportEventForm.categoryGroupSizes || {})
      .map(([k, v]) => [k, clamp(v, 1, 1000)] as const)
      .filter(([k, v]) => v && v > 1 && potentialLookups.includes(k))
  )
  return { categoryGroupCounts, categoryGroupSizes }
}

function UpdatingInscriptions({ progress, setProgress }: UpdatingInscriptionsProps) {
  return (
    <>
      <InscriptionMovements movements={progress.categories} />
      <ElevatedBox title={t().inscription.updatingInscriptions}>
        <div>
          {Object.entries(progress.progress).map(([id, categoryProgress]) => (
            <div key={`${id}-${categoryProgress.categoryId}`}>
              {categoryProgress.categoryName}:{' '}
              {categoryProgress.status === 'loading'
                ? '?'
                : `${categoryProgress.done}/${categoryProgress.total}`}
            </div>
          ))}
        </div>

        {progress.errors.length > 0 && (
          <ElevatedBox title={t().sportsEvent.errorsOccured}>
            <pre>{prettyJSON(progress.errors)}</pre>
          </ElevatedBox>
        )}

        <Button onClick={() => setProgress(undefined)}>{t().buttons.cancel}</Button>
      </ElevatedBox>
    </>
  )
}
