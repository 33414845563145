import { Edit } from '@material-ui/icons'
import { parseISO } from 'date-fns'
import { Form, Formik } from 'formik'
import { db } from 'app/db/frontend-db'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, UseDialog, useDialog } from 'app/layouts/confirm-dialog'
import {
  bookingKindText,
  ManualBookingFields,
  ManualBookingFormData,
  manualBookingSchema,
  useManualBookingAutocompleteOptions,
} from 'app/pages/admin/bookings/manual-booking-form'
import { updateManualBooking } from 'shared/billing/bookings-service'
import { categoryByIdRequired, categoryOfAssociation } from 'shared/data/categories-service'
import { ManualBooking, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { FriendlyError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { useSuccessSnackbar } from 'utils/snackbar'

interface EditManualBookingButtonProps {
  booking: ManualBooking
  admin: UserQuery
}

export function EditManualBookingButton({ admin, booking }: EditManualBookingButtonProps) {
  const dialog = useDialog()
  return (
    <>
      <IconButtonWithTooltip
        tooltip={t().financials.editManualBookingButton}
        onClick={() => dialog.open()}
      >
        <Edit />
      </IconButtonWithTooltip>
      {dialog.isOpen && <EditManualBookingForm dialog={dialog} admin={admin} booking={booking} />}
    </>
  )
}

type EditManualBookingFormProps = EditManualBookingButtonProps & {
  dialog: UseDialog
}

function EditManualBookingForm({ dialog, admin, booking }: EditManualBookingFormProps) {
  const showSuccessMessage = useSuccessSnackbar()

  const autocompleteOptions = useManualBookingAutocompleteOptions()
  const category = booking.categoryId && categoryByIdRequired(booking.categoryId)
  const association = booking.item.association

  const initialValues: ManualBookingFormData = {
    amount: booking.item.price,
    bookingText: booking.item.name,
    category: category
      ? categoryOfAssociation(category.id, association || category.associations[0])
      : undefined,
    date: parseISO(booking.date),
    tag: booking.tag,
    uid: { id: booking.uid, name: booking.uid },
    internalRemarks: booking.internalRemarks || '',
    sportEvent: booking.sportEventId
      ? { id: booking.sportEventId, name: booking.sportEventId }
      : undefined,
    association,
  }

  return (
    <>
      <Loading loading={autocompleteOptions.loading} />
      <FriendlyError error={autocompleteOptions.error} />
      {!autocompleteOptions.loadingOrError && (
        <Formik
          initialValues={initialValues}
          validationSchema={manualBookingSchema()}
          validateOnMount
          onSubmit={async (values, { setSubmitting }) => {
            const manualBooking = {
              type: booking.type,
              id: booking.id,
              sportEventId: values.sportEvent?.id || null,
              internalRemarks: values.internalRemarks || null,
              categoryId: values.category?.id || null,
              uid: values.uid?.id || '',
              byUid: admin.uid,
              date: values.date.toISOString(),
              item: {
                name: values.bookingText,
                price: values.amount,
                type: 'billLineItem' as const,
                association: values.association,
              },
              createdAt: booking.createdAt,
              updatedAt: new Date().toISOString(),
              tag: values.tag || '',
            }
            await updateManualBooking(db, manualBooking)
            showSuccessMessage(t().alerts.dataSaved)
            setSubmitting(false)
          }}
        >
          {(form) => (
            <ConfirmDialog
              maxWidth="xl"
              fullWidth
              title={t().financials.editManualBooking(bookingKindText(form.values.amount))}
              buttonText={
                form.isSubmitting
                  ? t().buttons.saving
                  : t().financials.saveManualBooking(bookingKindText(form.values.amount))
              }
              dialog={dialog}
              onConfirm={() => form.submitForm()}
              disabled={form.isSubmitting || !form.isValid}
            >
              <Form>
                <ManualBookingFields
                  formik={form}
                  action={'edit'}
                  autocompleteOptions={autocompleteOptions.data}
                />
              </Form>
              <Loading loading={form.isSubmitting} />
            </ConfirmDialog>
          )}
        </Formik>
      )}
    </>
  )
}
