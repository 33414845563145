import { Grid, Typography } from '@material-ui/core'
import { samFields } from 'app/forms/fields'

export function isAfmMembershipField(fieldName: string) {
  return fieldName === 'afmSection'
}

interface PersonalDataAfmFieldsProps {
  prefix: '' | 'personalData.'
  withTitle?: boolean
  required?: boolean
}

export function PersonalDataFieldsAfm(props: PersonalDataAfmFieldsProps) {
  const { prefix, withTitle, required } = props
  const fields = samFields().personalData

  return (
    <>
      {withTitle && (
        <Grid item xs={12}>
          <Typography variant="h6">AFM</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        {fields.afmSection.field({ prefix, required: !!required })}
      </Grid>
    </>
  )
}
