import { addBusinessDays } from 'date-fns'
import { sum } from 'lodash'
import { createElement } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { AssociationPaymentPaid } from 'shared/db/db'
import { associationByID, racemanagerPaymentInfo } from 'shared/models/associations'
import { formatDate } from 'shared/utils/date'
import { randomString } from 'shared/utils/string'
import { isLocalDevelopment } from 'utils/debug'

interface PainProps {
  payments: AssociationPaymentPaid[]
  executionDate: string
}

export const prettyFormat = false

export async function generateAssociationPaymentPain001(props: PainProps) {
  const dynamicXmlPart = renderToStaticMarkup(<Pain001File {...props} />)
  const xmlText = `<?xml version='1.0' encoding='UTF-8'?><Document xmlns="http://www.six-interbank-clearing.com/de/pain.001.001.03.ch.02.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.six-interbank-clearing.com/de/pain.001.001.03.ch.02.xsd pain.001.001.03.ch.02.xsd">${dynamicXmlPart}</Document>`

  if (!prettyFormat || !isLocalDevelopment()) return xmlText

  const { format } = await import('prettier')
  const xmlParser = await import('@prettier/plugin-xml')

  return format(xmlText, {
    parser: 'xml',
    printWidth: 105,
    plugins: [xmlParser],
    // @ts-ignore
    xmlWhitespaceSensitivity: 'ignore',
  })
}

function Pain001File(props: PainProps) {
  const { payments, executionDate } = props
  const total = sum(payments.map(({ amount }) => amount)).toFixed(2)
  return (
    <CstmrCdtTrfInitn>
      <GrpHdr>
        <MsgId>{randomString(35)}</MsgId>
        <CreDtTm>{new Date().toISOString()}</CreDtTm>
        <NbOfTxs>{payments.length}</NbOfTxs>
        <CtrlSum>{total}</CtrlSum>
        <InitgPty>
          <Nm>{racemanagerPaymentInfo.name}</Nm>
          {/* <CtctDtls>
            <Nm>{racemanagerPaymentInfo.name}</Nm>
            <EmailAdr>{racemanagerPaymentInfo.email}</EmailAdr>
            <Othr>
              <ChanlTp>NAME</ChanlTp>
              <Id>Racemanager</Id>
            </Othr>
            <Othr>
              <ChanlTp>PRVD</ChanlTp>
              <Id>Elmer Software Engineering GmbH</Id>
            </Othr>
          </CtctDtls> */}
        </InitgPty>
      </GrpHdr>
      <PmtInf>
        <PmtInfId>{randomString(35)}</PmtInfId>
        <PmtMtd>TRF</PmtMtd>
        <BtchBookg>true</BtchBookg>
        <NbOfTxs>{payments.length}</NbOfTxs>
        <ReqdExctnDt>{executionDate || formatDate(addBusinessDays(new Date(), 1))}</ReqdExctnDt>
        <Dbtr>
          <Nm>{racemanagerPaymentInfo.name}</Nm>
        </Dbtr>
        <DbtrAcct>
          <Id>
            <IBAN>{racemanagerPaymentInfo.iban}</IBAN>
          </Id>
        </DbtrAcct>
        <DbtrAgt>
          <FinInstnId>
            <BIC>{racemanagerPaymentInfo.bic}</BIC>
          </FinInstnId>
        </DbtrAgt>
        {payments.map((payment) => (
          <Pain001Payment key={payment.id} payment={payment} />
        ))}
      </PmtInf>
    </CstmrCdtTrfInitn>
  )
}

function Pain001Payment({ payment }: { payment: AssociationPaymentPaid }) {
  const { paymentInfo } = associationByID(payment.association)

  return (
    <CdtTrfTxInf>
      <PmtId>
        <InstrId>{randomString(35)}</InstrId>
        <EndToEndId>{randomString(35)}</EndToEndId>
      </PmtId>
      <Amt>
        <InstdAmt Ccy="CHF">{payment.amount.toFixed(2)}</InstdAmt>
      </Amt>
      <ChrgBr>SHAR</ChrgBr>
      <Cdtr>
        <Nm>{paymentInfo.name}</Nm>
        <PstlAdr>
          {paymentInfo.streetName && <StrtNm>{paymentInfo.streetName}</StrtNm>}
          {paymentInfo.streetNumber && <BldgNb>{paymentInfo.streetNumber}</BldgNb>}
          <PstCd>{paymentInfo.zip}</PstCd>
          <TwnNm>{paymentInfo.place}</TwnNm>
          <Ctry>{paymentInfo.country}</Ctry>
        </PstlAdr>
      </Cdtr>
      <CdtrAcct>
        <Id>
          <IBAN>{paymentInfo.iban}</IBAN>
        </Id>
      </CdtrAcct>
      {/* <RmtInf>
        <Strd>
          <CdtrRefInf>
            <Ref>
              {payment.paymentReference ||
                generateRandomReferenceNumber(new Date(), associationIndex(payment.association))}
            </Ref>
            <Tp>
              <CdOrPrtry>
                <Cd>SCOR</Cd>
              </CdOrPrtry>
            </Tp>
          </CdtrRefInf>
        </Strd>
      </RmtInf> */}
    </CdtTrfTxInf>
  )
}

const CstmrCdtTrfInitn = (props: any) => createElement('CstmrCdtTrfInitn', props)
const GrpHdr = (props: any) => createElement('GrpHdr', props)
const MsgId = (props: any) => createElement('MsgId', props)
const NbOfTxs = (props: any) => createElement('NbOfTxs', props)
const CtrlSum = (props: any) => createElement('CtrlSum', props)
const InitgPty = (props: any) => createElement('InitgPty', props)
const Nm = (props: any) => createElement('Nm', props)
const CreDtTm = (props: any) => createElement('CreDtTm', props)
const PmtInf = (props: any) => createElement('PmtInf', props)
const PmtInfId = (props: any) => createElement('PmtInfId', props)
const PmtMtd = (props: any) => createElement('PmtMtd', props)
const BtchBookg = (props: any) => createElement('BtchBookg', props)
const ReqdExctnDt = (props: any) => createElement('ReqdExctnDt', props)
const Dbtr = (props: any) => createElement('Dbtr', props)
const DbtrAcct = (props: any) => createElement('DbtrAcct', props)
const Id = (props: any) => createElement('Id', props)
const IBAN = (props: any) => createElement('IBAN', props)
const CdtTrfTxInf = (props: any) => createElement('CdtTrfTxInf', props)
const InstrId = (props: any) => createElement('InstrId', props)
const EndToEndId = (props: any) => createElement('EndToEndId', props)
const PmtId = (props: any) => createElement('PmtId', props)
const InstdAmt = (props: any) => createElement('InstdAmt', props)
const Amt = (props: any) => createElement('Amt', props)
const ChrgBr = (props: any) => createElement('ChrgBr', props)
const Ctry = (props: any) => createElement('Ctry', props)
const PstlAdr = (props: any) => createElement('PstlAdr', props)
const FinInstnId = (props: any) => createElement('FinInstnId', props)
const CdtrAcct = (props: any) => createElement('CdtrAcct', props)
const Cdtr = (props: any) => createElement('Cdtr', props)
const DbtrAgt = (props: any) => createElement('DbtrAgt', props)
const BIC = (props: any) => createElement('BIC', props)
const StrtNm = (props: any) => createElement('StrtNm', props)
const BldgNb = (props: any) => createElement('BldgNb', props)
const PstCd = (props: any) => createElement('PstCd', props)
const TwnNm = (props: any) => createElement('TwnNm', props)
// const CtctDtls = (props: any) => createElement('CtctDtls', props)
// const Othr = (props: any) => createElement('Othr', props)
// const ChanlTp = (props: any) => createElement('ChanlTp', props)
// const EmailAdr = (props: any) => createElement('EmailAdr', props)
// const Strd = (props: any) => createElement('Strd', props)
// const RmtInf = (props: any) => createElement('RmtInf', props)
// const CdtrRefInf = (props: any) => createElement('CdtrRefInf', props)
// const Ref = (props: any) => createElement('Ref', props)
// const Tp = (props: any) => createElement('Tp', props)
// const CdOrPrtry = (props: any) => createElement('CdOrPrtry', props)
// const Cd = (props: any) => createElement('Cd', props)
