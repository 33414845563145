import { Box, Paper, Typography } from '@material-ui/core'
import { ReactNode } from 'react'
import { useIsSmallerThanSm, useIsSmallerThanXs } from 'app/layout/use-small-screen'
import { DownloadableTable } from 'app/layouts/downloadable-table'
import { TableData } from 'shared/utils/table-data'
import { FriendlyError } from 'utils/errors'
import { Loading } from 'utils/loading'

interface TableBoxProps {
  title: string
  children?: ReactNode
  more?: ReactNode
  exports?: ReactNode
  loading: boolean
  error: unknown
  data: TableData | false | undefined
  density?: 'small' | 'medium' | 'high'
  disableDownloadButtons?: boolean
  maxEntries?: number
}

export function TableBox(props: TableBoxProps) {
  const { title, children, loading, error, data, ...rest } = props
  const xsScreen = useIsSmallerThanXs()
  const smScreen = useIsSmallerThanSm()

  return (
    <Paper elevation={3}>
      <Box mb={2} px={xsScreen ? 0.5 : 2} pt={2} pb={1}>
        <Box mb={2}>
          <Typography component="h3" variant={xsScreen ? 'h5' : smScreen ? 'h4' : 'h3'}>
            {title}
          </Typography>
          <FriendlyError error={error} />
          <Loading loading={loading} />
        </Box>

        <Box pb={2}>
          {children && <Box pb={2}>{children}</Box>}
          {!loading && !error && data && <DownloadableTable data={data} {...rest} />}
        </Box>
      </Box>
    </Paper>
  )
}
