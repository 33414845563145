import { produce } from 'immer'
import { db } from 'app/db/frontend-db'
import {
  CategoryMovement,
  deleteInscriptionMovement,
} from 'app/sport-events/inscription-movements-calculation'
import { DayCategoryID } from 'shared/db/day-category'
import { t } from 'shared/i18n/current'
import { handleTwoDayDiscount } from 'shared/inscription/inscription-discount-service'
import { updateInscriptionListByInscription } from 'shared/inscription/public-inscription-list'
import { CategoryId } from 'shared/models/category'
import { Inscription, SportEvent } from 'shared/sport-events/sport-events'
import { serializeError } from 'shared/utils/errors'
import { Disp } from 'utils/react'

export async function processMovements(
  sportEvent: SportEvent,
  movements: CategoryMovement[],
  setProgress: Disp<CategoryMovementProgress | undefined>
) {
  const loadingProgress: CategoryMovementProgress = {
    categories: movements,
    progress: Object.fromEntries(
      movements.map((movement) => [
        movement.categoryId,
        { status: 'loading', categoryName: movement.categoryName, categoryId: movement.categoryId },
      ])
    ),
    errors: [],
  }
  setProgress(loadingProgress)

  const inscriptions = await loadInscriptions(sportEvent, movements)
  let progress: CategoryMovementProgressStarted = {
    categories: movements,
    progress: Object.fromEntries(
      movements.map((movement) => [
        movement.categoryId,
        {
          status: 'started',
          categoryName: movement.categoryName,
          categoryId: movement.categoryId,
          total: inscriptions.filter((inscription) => inscription.category === movement.categoryId)
            .length,
          done: 0,
        },
      ])
    ),
    errors: [],
  }
  setProgress(progress)

  await doProcessMovements(sportEvent, movements, inscriptions, (categoryId, error) => {
    progress = produce(progress, (newProgress) => {
      newProgress.progress[categoryId].done += 1
      if (error) {
        newProgress.errors.push(error)
      }
    })
    setProgress(progress)
  })

  if (progress.errors.length > 0)
    throw new Error(`${t().inscription.moveInscriptionsError}: ${JSON.stringify(progress.errors)}`)

  setProgress(undefined)
}

async function doProcessMovements(
  sportEvent: SportEvent,
  movements: CategoryMovement[],
  inscriptions: Inscription[],
  onProgress: (categoryId: string, error?: Record<string, any> | undefined) => void
) {
  await Promise.all(
    inscriptions.map(async (inscription) => {
      try {
        const movement = movements.find(
          (movement) =>
            movement.categoryId === inscription.category && movement.from === inscription.date
        )
        if (!movement) {
          onProgress(inscription.category)
          return
        }

        const bookings = (
          await db.loadInscriptionBookingsAndSportEvent(
            { uid: inscription.uid },
            { id: inscription.sportEvent }
          )
        ).filter(
          (booking) =>
            booking.item.sportEventDate === inscription.date &&
            booking.item.categoryId === inscription.category
        )

        if (movement.to === deleteInscriptionMovement) {
          await db.deleteInscription(inscription)
          await Promise.all(bookings.map((booking) => db.deleteInscriptionBooking(booking)))
          await updateInscriptionListByInscription(db, inscription)
          await handleTwoDayDiscount({ db, inscription, sportEvent })
        } else {
          await db.deleteInscription(inscription)
          inscription.date = movement.to
          await db.setInscription(inscription)
          await Promise.all(
            bookings.map(async (booking) => {
              booking.item.sportEventDate = movement.to
              await db.updateInscriptionBooking(booking)
            })
          )
          await updateInscriptionListByInscription(db, inscription)
        }

        onProgress(inscription.category)
      } catch (error) {
        console.error(error)
        const message =
          error instanceof Error
            ? { inscription, error: serializeError(error) }
            : { error: t().alerts.unknownError }
        onProgress(inscription.category, message)
      }
    })
  )
}

async function loadInscriptions(
  sportEvent: SportEvent,
  movements: CategoryMovement[]
): Promise<Inscription[]> {
  const inscriptions = await db.loadInscriptionsBySportEvent(sportEvent)
  return inscriptions.filter((inscription) =>
    movements.some(
      (movement) => inscription.category === movement.categoryId && inscription.date === movement.from
    )
  )
}

export interface CategoryMovementProgress {
  categories: CategoryMovement[]
  progress: Record<DayCategoryID | CategoryId, InscriptionProgress>
  errors: Record<string, any>[]
}

type InscriptionProgress = InscriptionProgressLoading | InscriptionProgressStarted

interface CategoryMovementProgressStarted {
  categories: CategoryMovement[]
  progress: Record<DayCategoryID | CategoryId, InscriptionProgressStarted>
  errors: Record<string, any>[]
}

interface InscriptionProgressLoading {
  status: 'loading'
  categoryName: string
  categoryId: string
}

interface InscriptionProgressStarted {
  status: 'started'
  categoryName: string
  categoryId: string
  total: number
  done: number
}
