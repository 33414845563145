import { UseWithObj } from 'app/db/db-hooks/db-hook-helpers'
import { emptyArray } from 'app/db/db-hooks/empty-hook-helpers'
import { useAllManualBookingsWithTotal } from 'app/db/db-hooks/financial-db-hooks'
import { AdminContext, useAdminContext } from 'app/themes/admin-context'
import { AssociationID } from 'shared/models/associations'
import { parseISO } from 'shared/utils/date'

export function useAllManualBookingsWithTotalByAssociation() {
  const adminContext = useAdminContext()
  const manualBookings = useAllManualBookingsWithTotal()
  return filterByAssociationAndYear(manualBookings, adminContext, (row) => ({
    association: row.item.association,
    year: parseISO(row.date).getFullYear(),
  }))
}

function filterByAssociationAndYear<U>(
  useWithObj: UseWithObj<U[]>,
  adminContext: AdminContext,
  adminContextMapping: (row: U) => {
    association: AssociationID
    year: number
  }
): UseWithObj<U[]> {
  const { data, ...rest } = useWithObj
  return {
    data: filterRowsByAssociationAndYear<U>({ adminContext, data, adminContextMapping }),
    ...rest,
  }
}

function filterRowsByAssociationAndYear<U>(props: {
  adminContext: AdminContext
  data: U[]
  adminContextMapping: (row: U) => {
    association: AssociationID | false | undefined
    year: number | undefined
  }
}): U[] {
  const { adminContext, data, adminContextMapping: associationFilter } = props
  return adminContext.association === false
    ? emptyArray()
    : !adminContext.association
    ? data
    : data.filter(
        (row) =>
          (adminContext.association === undefined ||
            associationFilter(row).association === adminContext.association) &&
          (!adminContext.year || associationFilter(row).year === adminContext.year)
      )
}
