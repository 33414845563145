import {
  Box,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
} from '@material-ui/core'
import { Business, ExpandMore } from '@material-ui/icons'
import { useAssociationContext } from 'app/themes/association-context'
import { associationsWithLogosPreview } from 'app/themes/associationsWithLogos'
import { useUserContext } from 'app/themes/user-context'
import { t } from 'shared/i18n/current'
import { useMenu, useMenuWithAnchor } from 'utils/use-menu'

export function ChangeAssociationMenuItem({ closeMenu }: { closeMenu: () => void }) {
  const context = useAssociationContext()
  const innerMenu = useMenu()

  return (
    <>
      <ListItem button onClick={innerMenu.toggle}>
        <ListItemIcon>
          <Business />
        </ListItemIcon>
        <ListItemText primary={t().changeAssociation} />
      </ListItem>
      <Collapse in={innerMenu.isOpen}>
        {associationsWithLogosPreview(context.previewing).map((association) => (
          <ListItem
            disabled={association.id === context.associationID}
            key={association.id}
            button
            onClick={() => {
              context.setAssociation(association.id)
              innerMenu.close()
              closeMenu()
            }}
          >
            <ListItemIcon>
              <img
                src={
                  association.id === 'afm'
                    ? association.whiteBackgroundLogo
                    : association.darkBackgroundLogo
                }
                alt={association.name}
                style={{ width: 48, height: 48 }}
              />
            </ListItemIcon>
            <ListItemText
              primary={association.name}
              secondary={association.id === context.associationID ? t().active : undefined}
            />
          </ListItem>
        ))}
      </Collapse>
    </>
  )
}

export function ChangeAssociationDropdownMenu() {
  const context = useAssociationContext()
  const menu = useMenuWithAnchor()
  const userContext = useUserContext()

  if (userContext.associationAdmin) return null

  return (
    <Box mr={2} display="flex">
      <Tooltip title={t().changeAssociation} enterDelay={300}>
        <Button color="inherit" onClick={menu.open}>
          <Business />
          <span>{context.associationID}</span>
          <ExpandMore fontSize="small" />
        </Button>
      </Tooltip>
      <Menu anchorEl={menu.anchorEl} open={menu.isOpen} onClose={menu.close}>
        {associationsWithLogosPreview(context.previewing).map((association) => (
          <MenuItem
            component="a"
            data-no-link="true"
            key={association.id}
            selected={context.associationID === association.id}
            onClick={() => {
              context.setAssociation(association.id)
              menu.close()
            }}
          >
            {association.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
