import { ImportContacts } from '@material-ui/icons'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { db } from 'app/db/frontend-db'
import { DeleteInscriptionButton } from 'app/inscription/delete-inscription-button'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { SelectBikes } from 'app/pages/inscription/enlist-inscription-buttons'
import { routes } from 'shared/config/routes'
import { ApprovedLicense, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { processSubmitSportsEventInscription } from 'shared/inscription/inscription-service'
import { PersonalData } from 'shared/models/personal-data'
import { deserializeLicenseCategoryIDs } from 'shared/sport-events/sport-event-categories-serialization'
import { Inscription, SportEvent } from 'shared/sport-events/sport-events'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'

interface InscribeButtonProps {
  license: ApprovedLicense
  sportEvent: SportEvent
  inscriptions: Inscription[]
  personalData: PersonalData | undefined
  admin: UserQuery
  date: string
}

export function InscribeButton(props: InscribeButtonProps) {
  // TODO: later: inscription admin: rewrite this component!

  const { sportEvent, license, inscriptions, personalData, admin, date } = props
  const uid = license.uid

  const { error, setError } = useError()
  const dialog = useDialog()
  const [selectedBikeIds, setSelectedBikeIds] = useState(new Set<string>())
  const history = useHistory()

  const inscription = inscriptions.find(
    (inscription) =>
      inscription.category === license.categoryId &&
      inscription.uid === uid &&
      inscription.sportEvent === sportEvent.id
  )

  const sportEventLicenseCategoryIDs = deserializeLicenseCategoryIDs(sportEvent?.licenseCategoryIds)
  const hasLicense = sportEventLicenseCategoryIDs.includes(license.categoryId)

  if (Math.random()) return null // inscription for admin is currently disabled

  return hasLicense ? (
    <>
      <FriendlyError error={error} />
      {inscription ? (
        <DeleteInscriptionButton
          personalData={personalData}
          sportEvent={sportEvent}
          inscription={inscription}
        />
      ) : (
        <>
          <ConfirmDialog
            title={t().inscribe}
            dialog={dialog}
            buttonText={t().inscribe}
            onConfirm={async () => {
              await processInscription(license, sportEvent, uid, admin, [...selectedBikeIds], date)
              history.push(routes.inscriptions.generateTo(`${uid} ${license.categoryId}`, sportEvent.id))
            }}
          >
            <SelectBikes
              label={t().chooseBikes}
              user={{ uid }}
              selectedBikeIds={selectedBikeIds}
              setSelectedBikeIds={setSelectedBikeIds}
              sportsEventType={sportEvent.sportEventType}
            />
          </ConfirmDialog>
          <IconButtonWithTooltip
            tooltip={`${t().inscription.inscribeFor} ${sportEvent.name} (${date})`}
            onClick={() =>
              catchAndLog(setError, async () => {
                const availableBikes = await db.loadBikes({ uid })
                if (availableBikes.length === 1) {
                  await processInscription(
                    license,
                    sportEvent,
                    uid,
                    admin,
                    availableBikes.map((bike) => bike.id),
                    date
                  )
                  history.push(
                    routes.inscriptions.generateTo(`${uid} ${license.categoryId}`, sportEvent.id)
                  )
                } else {
                  dialog.open()
                }
              })
            }
          >
            <ImportContacts />
          </IconButtonWithTooltip>
        </>
      )}
    </>
  ) : (
    <IconButtonWithTooltip
      tooltip={t().inscription.categoryNotStartingIn(license.categoryId, sportEvent.name)}
      onClick={() => alert(t().inscription.categoryNotStartingIn(license.categoryId, sportEvent.name))}
      style={{ color: 'red' }}
    >
      <ImportContacts />
    </IconButtonWithTooltip>
  )
}

async function processInscription(
  license: ApprovedLicense,
  sportEvent: SportEvent,
  uid: string,
  admin: UserQuery,
  bikeIds: string[],
  date: string
) {
  await processSubmitSportsEventInscription(
    db,
    // TODO: later: inscription admin: add other cases
    {
      categoryId: license.categoryId,
      bikeIds,
      sportEventId: sportEvent.id,
      type: 'enlistedLicenseInscription',
      uid,
      date,
      remarksRider: '',
    },
    // TODO: later: inscription admin: add association admin
    {
      isAdmin: true,
      isAssociationAdmin: false,
      association: undefined,
      manuallyVerified: true,
      byUid: admin.uid,
    }
  )
}
