import { db } from 'app/db/frontend-db'
import { ButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { Year } from 'shared/data/license-year'
import { parseISO } from 'shared/utils/date'

export function ResetPaidMemberFees() {
  return (
    <ButtonWithConfirmation
      fullWidth={false}
      onConfirm={resetPaidMemberFees}
      title="Wirklich zurücksetzen?"
      confirmation="Alle Mitgliederbeiträge werden für das aktuelle Jahr auf 'unbezahlt' zurückgesetzt"
    >
      Mitgliederbeiträge zurücksetzen
    </ButtonWithConfirmation>
  )
}

async function resetPaidMemberFees() {
  const documents = await db.loadAllDocuments()
  await Promise.all(
    Object.entries(documents).map(async ([uid, documents]) => {
      const memberFeesPaymentHistory = documents.memberFeesPaymentHistory || {}

      if (documents.memberFeesPaidAt) {
        const date = parseISO(documents.memberFeesPaidAt)
        memberFeesPaymentHistory[date.getFullYear() as Year] = documents.memberFeesPaidAt

        await db.setMemberFeesPaymentHistory({ uid }, memberFeesPaymentHistory)
        await db.deleteMemberFeesPaidAt({ uid })
      }
    })
  )
}
