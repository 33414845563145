import { Typography } from '@material-ui/core'
import { t } from 'shared/i18n/current'
import { Emergency } from 'shared/models/emergency'
import { truthy } from 'shared/utils/array'

interface ShowEmergencyProps {
  needed: boolean
  emergency: Emergency | undefined
}

export function ShowEmergency({ emergency, needed }: ShowEmergencyProps) {
  if (!needed && !emergency) return null

  return emergency ? (
    <Typography>
      {t().documentBox.contactOnAccident}:{' '}
      {[emergency.contactOnAccident1Name, emergency.contactOnAccident2Name].filter(truthy).join(', ') ||
        '-'}
    </Typography>
  ) : (
    <Typography>{t().documentBox.emergencyDataMissing}</Typography>
  )
}
