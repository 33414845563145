import firebase from 'firebase/app'
import { last } from 'lodash'
import { useEffect, useState } from 'react'
import { UserQuery } from 'shared/db/db'
import { UploadMetadata } from 'shared/models/upload-status'

export function extension(file: File) {
  return (last(file.name.split('.')) || '').toLowerCase()
}

export async function uploadFile(page: NewUpload, refPath: string) {
  await ensureOldDataIsDeleted(page.oldMetadata?.fullPath)
  const ref = firebase.storage().ref(refPath)
  const snapshot = await ref.put(page.file)
  return snapshot.metadata
}

export function useDownloadFilePath(refPath: string, metadata: UploadMetadata | undefined) {
  const [path, setPath] = useState('')
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => setPath(await downloadFilePath(refPath)))()
  }, [refPath, metadata])
  return path
}

export async function downloadFile(path: string) {
  const file = await downloadFileOrUndefined(path)
  if (!file) throw new Error(`Unable to download file ${path}`)
  return file
}

export async function downloadFileOrUndefined(path: string) {
  const url = await downloadFilePath(path)
  if (!url) return undefined
  return await (await fetch(url)).arrayBuffer()
}

export async function downloadFilePath(path: string) {
  try {
    const ref = firebase.storage().ref(path)
    return (await ref.getDownloadURL()) as string
  } catch {
    return ''
  }
}

export function toUploadMetadata(metadata: firebase.storage.FullMetadata): UploadMetadata {
  return {
    fullPath: metadata.fullPath || '',
    name: metadata.name || '',
    contentType: metadata.contentType || '',
    contentDisposition: metadata.contentDisposition || '',
    size: metadata.size || 0,
    createdAt: metadata.timeCreated || '',
    updatedAt: metadata.updated || '',
    md5Hash: metadata.md5Hash || '',
  }
}

export async function ensureOldDataIsDeleted(fullPath: string | undefined) {
  try {
    if (fullPath) await firebase.storage().ref(fullPath).delete()
  } catch (error: any) {
    if (error.code !== 'storage/object-not-found') throw error
  }
}

export interface NewUpload {
  user: UserQuery
  admin: UserQuery
  page: number
  file: File
  oldMetadata: UploadMetadata
}

export interface UploadPage {
  user: UserQuery
  admin: UserQuery
  page: number
  metadata: UploadMetadata
}

export const cropImageSize = { width: 600, height: 800 }
