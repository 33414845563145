import { makeStyles, Slider, Button, createStyles, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { getOrientation, Orientation } from 'get-orientation/browser'
import { useState, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { CropOptions, cropImageToSize, rotateImage } from 'app/pages/photo/canvas-utils'
import { cropImageSize } from 'app/storage/storage'
import { t } from 'shared/i18n/current'
import { FriendlyError, useError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { Disp } from 'utils/react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cropContainer: {
      position: 'relative',
      width: '100%',
      height: 400,
      background: '#333',
      [theme.breakpoints.up('sm')]: { height: 400 },
    },
  } as const)
)

interface CropPhotoProps {
  file: File | undefined
  onSave: (blob: Blob) => Promise<void>
}

const previewOnly = false

export function CropPhoto({ file, onSave }: CropPhotoProps) {
  const classes = useStyles()
  const [imageSrc, setImageSrc] = useState('')
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropOptions | null>(null)
  const [croppedImage, setCroppedImage] = useState('')
  const [cropping, setCropping] = useState(false)
  const { error, setError } = useError()

  useEffect(() => {
    setRotededImage(file, setImageSrc).catch(console.error)
  }, [file])

  if (!imageSrc) return null

  return (
    <div>
      <FriendlyError error={error} />
      <div className={classes.cropContainer}>
        <Cropper
          image={imageSrc}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={cropImageSize.width / cropImageSize.height}
          maxZoom={6}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={(_, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)}
          onZoomChange={setZoom}
        />
      </div>
      <div>
        <Button
          fullWidth
          onClick={async () => {
            try {
              setCropping(true)
              const blob = await cropImageToSize(imageSrc, croppedAreaPixels, rotation, cropImageSize)
              if (previewOnly) setCroppedImage(URL.createObjectURL(blob))
              else await onSave(blob)
            } catch (error: any) {
              setError(error)
            } finally {
              setCropping(false)
            }
          }}
          variant="contained"
          color="primary"
          disabled={cropping}
        >
          {t().licensePhoto.saveLicensePhoto}
        </Button>
        <Loading loading={cropping} />

        <Typography variant="overline">Zoom</Typography>
        <Slider
          value={zoom}
          min={1}
          max={6}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(_e, zoom) => setZoom(zoom as number)}
        />
        <Typography variant="overline">Rotation</Typography>
        <Slider
          value={rotation}
          min={0}
          max={360}
          step={90}
          aria-labelledby="Rotation"
          onChange={(_e, rotation) => setRotation(rotation as number)}
        />
      </div>
      {previewOnly && croppedImage && (
        <img src={croppedImage} alt="Cropped" style={{ maxWidth: '100%', maxHeight: '100%' }} />
      )}
    </div>
  )
}

function readFile(file: File) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result as string), false)
    reader.readAsDataURL(file)
  })
}

async function setRotededImage(file: File | undefined, setImageSrc: Disp<string>) {
  if (!file) return setImageSrc('')
  const imageData = await readFile(file)
  const rotatedImageData = await rotateImageUsingOrientation(file, imageData)
  setImageSrc(rotatedImageData)
}

async function rotateImageUsingOrientation(file: File, imageData: string) {
  const orientation = await evaluateOrientation(file)
  const rotation = orientationAngle(orientation)
  return rotation ? await rotateImage(imageData, rotation) : imageData
}

async function evaluateOrientation(file: File) {
  try {
    return await getOrientation(file)
  } catch (error) {
    return 'unknown'
  }
}

function orientationAngle(orientation: Orientation | 'unknown') {
  if (orientation === Orientation.BOTTOM_RIGHT) return 180
  if (orientation === Orientation.RIGHT_TOP) return 90
  if (orientation === Orientation.LEFT_BOTTOM) return -90
  return 0
}
