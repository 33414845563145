export const constCategories2023 = [
  {
    id: 'common-2023-motocross-u-20',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'U20',
    commonNameEn: 'U20',
    commonNameFr: 'U20',
    importName: 'MX U20',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'U20',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 545,
    associations: ['sam', 'sjmcc'],
    associationSpecificDetails: [
      {
        nameDe: 'U20',
        nameEn: 'U20',
        nameFr: 'U20',
        shortNameDe: 'U20',
        shortNameEn: 'U20',
        shortNameFr: 'U20',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'U20',
        startListName: 'U20',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 250, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'bis 150 ccm, 2-Takt und bis 250 ccm 4-Takt, bis 20 Jahre (Jahrgang 2003)',
        hintEn: 'up to 150 cc, 2-stroke and up to 250 cc 4-stroke, up to 20 years (born 2003)',
        hintFr: "Jusqu'à 150 cc, 2 coups et jusqu'à 250 cc à 4 coups, jusqu'à 20 ans (né en 2003)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'SJMCC/SAM U20 Cup',
        nameEn: 'SJMCC/SAM U20 Cup',
        nameFr: 'SJMCC/SAM U20 Cup',
        shortNameDe: 'U20',
        shortNameEn: 'U20',
        shortNameFr: 'U20',
        needsHealthCheck: [{ association: 'sjmcc', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'U20',
        startListName: 'U20',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#ffffff',
        numberFixed: true,
        association: 'sjmcc',
        prices: [{ association: 'sjmcc', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'bis 150 ccm, 2-Takt und bis 250 ccm 4-Takt, bis 20 Jahre (Jahrgang 2003)',
        hintEn: 'up to 150 cc, 2-stroke and up to 250 cc 4-stroke, up to 20 years (born 2003)',
        hintFr: "Jusqu'à 150 cc, 2 coups et jusqu'à 250 cc à 4 coups, jusqu'à 20 ans (né en 2003)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-mx-2-u-25',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 U25',
    commonNameEn: 'MX2 U25',
    commonNameFr: 'MX2 U25',
    importName: 'MX MX2 U25',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 546,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 U25',
        nameEn: 'MX2 U25',
        nameFr: 'MX2 U25',
        shortNameDe: 'MX2 U25',
        shortNameEn: 'MX2 U25',
        shortNameFr: 'MX2 U25',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 U25',
        startListName: 'MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 250 ccm 2/4-Takt, bis 25 Jahre (Jahrgang 1998 und jünger), nicht mit einer FMS-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 250 ccm 2/4 clock, up to 25 years (born in 1998 and younger), not with an FMS MX license (exception: Yamaha-Cup)',
        hintFr:
          "85 à 250 ccm 2/4 horloge, jusqu'à 25 ans (né en 1998 et moins), pas avec une licence FMS MX (exception: Yamaha-Cup)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-mx-2-u-30',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 U30',
    commonNameEn: 'MX2 U30',
    commonNameFr: 'MX2 U30',
    importName: 'MX MX2 U30',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 547,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 U30',
        nameEn: 'MX2 U30',
        nameFr: 'MX2 U30',
        shortNameDe: 'MX2 U30',
        shortNameEn: 'MX2 U30',
        shortNameFr: 'MX2 U30',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 U30',
        startListName: 'MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 250 ccm 2/4-Takt, 26 bis 30 Jahre (Jahrgänge 1993-1997), nicht mit einer FMS-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 250 ccm 2/4 clock, 26 to 30 years (born in 1993-1997), not with an FMS MX license (exception: Yamaha-Cup)',
        hintFr:
          '85 à 250 ccm 2/4 horloge, 26 à 30 ans (né en 1993-1997), pas avec une licence FMS MX (exception: Yamaha-Cup)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-mx-2-u-35',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 U35',
    commonNameEn: 'MX2 U35',
    commonNameFr: 'MX2 U35',
    importName: 'MX MX2 U35',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 548,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 U35',
        nameEn: 'MX2 U35',
        nameFr: 'MX2 U35',
        shortNameDe: 'MX2 U35',
        shortNameEn: 'MX2 U35',
        shortNameFr: 'MX2 U35',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 U35',
        startListName: 'MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 250 ccm 2/4-Takt, 31 bis 35 Jahre (Jahrgänge 1988-1992), nicht mit einer FMS-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 250 ccm 2/4-stroke, 31 to 35 years (born 1988-1992), not with an FMS MX license (exception: Yamaha-Cup)',
        hintFr:
          '85 à 250 CCM 2/4-Stroke, 31 à 35 ans (né en 1988-1992), pas avec une licence FMS MX (exception: Yamaha-Cup)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-mx-2-u-40',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 U40',
    commonNameEn: 'MX2 U40',
    commonNameFr: 'MX2 U40',
    importName: 'MX MX2 U40',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 549,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 U40',
        nameEn: 'MX2 U40',
        nameFr: 'MX2 U40',
        shortNameDe: 'MX2 U40',
        shortNameEn: 'MX2 U40',
        shortNameFr: 'MX2 U40',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 U40',
        startListName: 'MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 250 ccm 2/4-Takt, ab 36 Jahren (Jahrgänge 1987 und älter), nicht mit einer FMS-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 250 ccm 2/4 clock, from 36 years (born in 1987 and older), not with an FMS MX license (exception: Yamaha-Cup)',
        hintFr:
          '85 à 250 ccm 2/4 horloge, à partir de 36 ans (né en 1987 et plus), pas avec une licence FMS MX (exception: yamaha-cup)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-mx-open-u-30',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX Open U30',
    commonNameEn: 'MX Open U30',
    commonNameFr: 'MX Open U30',
    importName: 'MX MX Open U30',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'MX Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 590,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Open U30',
        nameEn: 'MX Open U30',
        nameFr: 'MX Open U30',
        shortNameDe: 'MX Open U30',
        shortNameEn: 'MX Open U30',
        shortNameFr: 'MX Open U30',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Open U30',
        startListName: 'MX Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 650 ccm 2/4-Takt, bis 30 Jahre (Jahrgang 1993 und jünger), nicht mit einer FMS-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 650 ccm 2/4 stroke, up to 30 years (born in 1993 and younger), not with an FMS MX license (exception: Yamaha-Cup)',
        hintFr:
          "85 à 650 ccm 2/4 d'AVC, jusqu'à 30 ans (né en 1993 et ​​moins), pas avec une licence FMS MX (exception: Yamaha-Cup)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-mx-open-u-40',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX Open U40',
    commonNameEn: 'MX Open U40',
    commonNameFr: 'MX Open U40',
    importName: 'MX MX Open U40',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'MX Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 591,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Open U40',
        nameEn: 'MX Open U40',
        nameFr: 'MX Open U40',
        shortNameDe: 'MX Open U40',
        shortNameEn: 'MX Open U40',
        shortNameFr: 'MX Open U40',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Open U40',
        startListName: 'MX Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          '85 bis 650 ccm 2/4-Takt, ab 31 Jahren (Jahrgänge 1992 und älter), nicht mit einer FMS-MX-Lizenz (Ausnahme: Yamaha-Cup)',
        hintEn:
          '85 to 650 ccm 2/4 clock, from 31 years (born in 1992 and older), not with an FMS MX license (exception: Yamaha-Cup)',
        hintFr:
          '85 à 650 ccm 2/4 horloge, à partir de 31 ans (né en 1992 et plus), pas avec une licence FMS MX (exception: yamaha-cup)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-senioren-open-u-40',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren Open Ü40',
    commonNameEn: 'Senioren Open Ü40',
    commonNameFr: 'Senioren Open Ü40',
    importName: 'MX Senioren Open Ü40',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Senioren Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 592,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren Open Ü40',
        nameEn: 'Senioren Open Ü40',
        nameFr: 'Senioren Open Ü40',
        shortNameDe: 'Senioren Open Ü40',
        shortNameEn: 'Senioren Open Ü40',
        shortNameFr: 'Senioren Open Ü40',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren Open Ü40',
        startListName: 'Senioren Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Ab 40 Jahre (Jahrgänge 1974-1983)',
        hintEn: 'From 40 years (born 1974-1983)',
        hintFr: 'À partir de 40 ans (né de 1974 à 1983)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-senioren-open-u-50',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren Open Ü50',
    commonNameEn: 'Senioren Open Ü50',
    commonNameFr: 'Senioren Open Ü50',
    importName: 'MX Senioren Open Ü50',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Senioren Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 553,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren Open Ü50',
        nameEn: 'Senioren Open Ü50',
        nameFr: 'Senioren Open Ü50',
        shortNameDe: 'Senioren Open Ü50',
        shortNameEn: 'Senioren Open Ü50',
        shortNameFr: 'Senioren Open Ü50',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren Open Ü50',
        startListName: 'Senioren Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Ab 50 Jahre (Jahrgänge 1964-1973)',
        hintEn: 'From 50 years (born in 1964-1973)',
        hintFr: 'À partir de 50 ans (né en 1964 à 1973)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-senioren-open-u-60',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren Open Ü60',
    commonNameEn: 'Senioren Open Ü60',
    commonNameFr: 'Senioren Open Ü60',
    importName: 'MX Senioren Open Ü60',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Senioren Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 554,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren Open Ü60',
        nameEn: 'Senioren Open Ü60',
        nameFr: 'Senioren Open Ü60',
        shortNameDe: 'Senioren Open Ü60',
        shortNameEn: 'Senioren Open Ü60',
        shortNameFr: 'Senioren Open Ü60',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren Open Ü60',
        startListName: 'Senioren Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Ab 60 Jahre (Jahrgang 1963 und älter)',
        hintEn: 'From 60 years (born in 1963 and older)',
        hintFr: 'À partir de 60 ans (né en 1963 et plus)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-masters-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Masters Open',
    commonNameEn: 'Masters Open',
    commonNameFr: 'Masters Open',
    importName: 'MX Masters Open',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Masters Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 555,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Masters Open',
        nameEn: 'Masters Open',
        nameFr: 'Masters Open',
        shortNameDe: 'Masters Open',
        shortNameEn: 'Masters Open',
        shortNameFr: 'Masters Open',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Masters Open',
        startListName: 'Masters Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 400, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Nicht mit Inter-Lizenz eines anderen Verbandes',
        hintEn: 'Not with an inter-license of another association',
        hintFr: "Pas avec une intervention d'interdiction d'une autre association",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-pro-race-serie-einzellizenz',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'ProRace Serie Einzellizenz',
    commonNameEn: 'ProRace Serie Einzellizenz',
    commonNameFr: 'ProRace Serie Einzellizenz',
    importName: 'MX ProRace Serie Einzellizenz',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'ProRace Serie',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 556,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'ProRace Serie Einzellizenz',
        nameEn: 'ProRace Serie Einzellizenz',
        nameFr: 'ProRace Serie Einzellizenz',
        shortNameDe: 'ProRace Serie',
        shortNameEn: 'ProRace Serie',
        shortNameFr: 'ProRace Serie',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ProRace Serie',
        startListName: 'ProRace Serie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 400, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-pro-race-serie-zusatz',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'ProRace Serie Zusatz',
    commonNameEn: 'ProRace Serie Zusatz',
    commonNameFr: 'ProRace Serie Zusatz',
    importName: 'MX ProRace Serie Zusatz',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'ProRace Serie',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 557,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'ProRace Serie Zusatz',
        nameEn: 'ProRace Serie Zusatz',
        nameFr: 'ProRace Serie Zusatz',
        shortNameDe: 'ProRace Serie Zusatz',
        shortNameEn: 'ProRace Serie Zusatz',
        shortNameFr: 'ProRace Serie Zusatz',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ProRace Serie',
        startListName: 'ProRace Serie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'national' }],
        priceHintDe: 'ohne erweiterte Versicherungsdeckung',
        priceHintEn: 'Without extended insurance coverage',
        priceHintFr: "Sans couverture d'assurance prolongée",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Für Besitzer einer Lizenz FMS Inter, FMS Lites, FMS Junioren, SAM Masters',
        hintEn: 'For owners of a license FMS Inter, FMS Lites, FMS Juniors, Sam Masters',
        hintFr: "Pour les propriétaires d'une licence FMS Inter, FMS Lites, FMS Juniors, Sam Masters",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-imba-mx-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA MX2',
    commonNameEn: 'IMBA MX2',
    commonNameFr: 'IMBA MX2',
    importName: 'MX IMBA MX2',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'IMBA MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 558,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA MX2',
        nameEn: 'IMBA MX2',
        nameFr: 'IMBA MX2',
        shortNameDe: 'IMBA MX2',
        shortNameEn: 'IMBA MX2',
        shortNameFr: 'IMBA MX2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA MX2',
        startListName: 'IMBA MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Masters Open',
        hintEn: 'In addition to Masters Open',
        hintFr: 'En plus des maîtres ouverts',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-imba-open-class',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Open Class',
    commonNameEn: 'IMBA Open Class',
    commonNameFr: 'IMBA Open Class',
    importName: 'MX IMBA Open Class',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'IMBA Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 559,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Open Class',
        nameEn: 'IMBA Open Class',
        nameFr: 'IMBA Open Class',
        shortNameDe: 'IMBA Open Class',
        shortNameEn: 'IMBA Open Class',
        shortNameFr: 'IMBA Open Class',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Open',
        startListName: 'IMBA Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Masters Open',
        hintEn: 'In addition to Masters Open',
        hintFr: 'En plus des maîtres ouverts',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-motocross-swiss-mx-women-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Swiss MX Women Cup',
    commonNameEn: 'Swiss MX Women Cup',
    commonNameFr: 'Swiss MX Women Cup',
    importName: 'MX Women',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Swiss MX Women Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 560,
    associations: ['afm', 'fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Women Cup',
        nameEn: 'Swiss MX Women Cup',
        nameFr: 'Swiss MX Women Cup',
        shortNameDe: 'Swiss MX Women Cup',
        shortNameEn: 'Swiss MX Women Cup',
        shortNameFr: 'Swiss MX Women Cup',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Women Cup',
        startListName: 'Swiss MX Women Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Swiss MX Women Cup',
        nameEn: 'Swiss MX Women Cup',
        nameFr: 'Swiss MX Women Cup',
        shortNameDe: 'Swiss MX Women Cup',
        shortNameEn: 'Swiss MX Women Cup',
        shortNameFr: 'Swiss MX Women Cup',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Women Cup',
        startListName: 'Swiss MX Women Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, to: '2005-01-01', licenseType: 'national' },
          { association: 'fms', value: 350, from: '2004-12-31', licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
      {
        nameDe: 'Swiss MX Women Cup (als Zusatz zu MX Girls)',
        nameEn: 'Swiss MX Women Cup (additionally to MX Girls)',
        nameFr: 'Swiss MX Women Cup (en complément pour MX Girls)',
        shortNameDe: 'Swiss MX Women Cup',
        shortNameEn: 'Swiss MX Women Cup',
        shortNameFr: 'Swiss MX Women Cup',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Women Cup',
        startListName: 'Swiss MX Women Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 0, licenseType: 'national' }],
        priceHintDe: 'Für Frauen, die am Swiss MX Women Cup teilnehmen',
        priceHintEn: 'For women participating in the Swiss MX Woman Cup',
        priceHintFr: 'Pour les femmes participant à la Swiss MX Woman Cup',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-imba-ladies',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Ladies',
    commonNameEn: 'IMBA Ladies',
    commonNameFr: 'IMBA Ladies',
    importName: 'MX IMBA Ladies',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'IMBA Ladies',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 561,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Ladies',
        nameEn: 'IMBA Ladies',
        nameFr: 'IMBA Ladies',
        shortNameDe: 'IMBA Ladies',
        shortNameEn: 'IMBA Ladies',
        shortNameFr: 'IMBA Ladies',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Ladies',
        startListName: 'IMBA Ladies',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu MX Women Cup',
        hintEn: 'In addition to MX Women Cup',
        hintFr: 'En plus de la MX Women Cup',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-motocross-sidecar-pilot',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Sidecar Pilot',
    commonNameEn: 'Sidecar Pilot',
    commonNameFr: 'Sidecar Pilot',
    importName: 'MX Sidecar Pilot',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Sidecar',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 562,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Seitenwagen Fahrer',
        nameEn: 'Seitenwagen Fahrer',
        nameFr: 'Seitenwagen Fahrer',
        shortNameDe: 'Seitenwagen Fahrer',
        shortNameEn: 'Seitenwagen Fahrer',
        shortNameFr: 'Seitenwagen Fahrer',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Sidecar',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Sidecar Pilot',
        nameEn: 'Sidecar Pilot',
        nameFr: 'Sidecar Pilot',
        shortNameDe: 'Sidecar Pilot',
        shortNameEn: 'Sidecar Pilot',
        shortNameFr: 'Sidecar Pilot',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Sidecar',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-motocross-sidecar-passenger',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Sidecar Passenger',
    commonNameEn: 'Sidecar Passenger',
    commonNameFr: 'Sidecar Passenger',
    importName: 'MX Sidecar Passager',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Sidecar',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 563,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Seitenwagen Passagier',
        nameEn: 'Seitenwagen Passagier',
        nameFr: 'Seitenwagen Passagier',
        shortNameDe: 'Seitenwagen Passagier',
        shortNameEn: 'Seitenwagen Passagier',
        shortNameFr: 'Seitenwagen Passagier',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Nur zur Kontrolle, NICHT importieren - Seitenwagen Passagier',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 250, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Sidecar Passager',
        nameEn: 'Sidecar Passager',
        nameFr: 'Sidecar Passager',
        shortNameDe: 'Sidecar Passenger',
        shortNameEn: 'Sidecar Passenger',
        shortNameFr: 'Sidecar Passenger',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Nur zur Kontrolle, NICHT importieren - Seitenwagen Passagier',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-imba-side-car',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Side Car',
    commonNameEn: 'IMBA Side Car',
    commonNameFr: 'IMBA Side Car',
    importName: 'MX IMBA Side Car',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'IMBA Sidecar',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 564,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Side Car',
        nameEn: 'IMBA Side Car',
        nameFr: 'IMBA Side Car',
        shortNameDe: 'IMBA Side Car',
        shortNameEn: 'IMBA Side Car',
        shortNameFr: 'IMBA Side Car',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Sidecar',
        startListName: 'IMBA Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Seitenwagen',
        hintEn: 'In addition to sidecar',
        hintFr: 'En plus de Sidecar',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-e-klasse',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'E-Klasse (Elektro)',
    commonNameEn: 'E-Klasse (Elektro)',
    commonNameFr: 'E-Klasse (Elektro)',
    importName: 'MX E-Klasse',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'E-Klasse',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 593,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'E-Klasse (Elektro)',
        nameEn: 'E-Klasse (Elektro)',
        nameFr: 'E-Klasse (Elektro)',
        shortNameDe: 'E-Klasse',
        shortNameEn: 'E-Klasse',
        shortNameFr: 'E-Klasse',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'E-Klasse',
        startListName: 'E-Klasse',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#000000',
        colorForeground: '#ffff00',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 250, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Meisterschaft ab 5 Lizenzen',
        hintEn: 'Championship from 5 licenses',
        hintFr: 'Championnat de 5 licences',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-motocross-quad',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Quad',
    commonNameEn: 'Quad',
    commonNameFr: 'Quad',
    importName: 'MX Quad',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Quad',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 565,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Quad',
        nameEn: 'Quad',
        nameFr: 'Quad',
        shortNameDe: 'Quad',
        shortNameEn: 'Quad',
        shortNameFr: 'Quad',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Quad',
        startListName: 'Quad',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#aac811',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacross-m-0',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M0 (bis 50 ccm)',
    commonNameEn: 'Mofacross M0 (bis 50 ccm)',
    commonNameFr: 'Mofacross M0 (bis 50 ccm)',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M0',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 566,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M0 (bis 50 ccm)',
        nameEn: 'Mofacross M0 (bis 50 ccm)',
        nameFr: 'Mofacross M0 (bis 50 ccm)',
        shortNameDe: 'Mofacross M0',
        shortNameEn: 'Mofacross M0',
        shortNameFr: 'Mofacross M0',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M0',
        startListName: 'Mofacross M0',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacross-m-1',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M1 (bis 75 ccm)',
    commonNameEn: 'Mofacross M1 (bis 75 ccm)',
    commonNameFr: 'Mofacross M1 (bis 75 ccm)',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M1',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 567,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M1 (bis 75 ccm)',
        nameEn: 'Mofacross M1 (bis 75 ccm)',
        nameFr: 'Mofacross M1 (bis 75 ccm)',
        shortNameDe: 'Mofacross M1',
        shortNameEn: 'Mofacross M1',
        shortNameFr: 'Mofacross M1',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M1',
        startListName: 'Mofacross M1',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacross-m-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M2 (bis 80 ccm)',
    commonNameEn: 'Mofacross M2 (bis 80 ccm)',
    commonNameFr: 'Mofacross M2 (bis 80 ccm)',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 568,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M2 (bis 80 ccm)',
        nameEn: 'Mofacross M2 (bis 80 ccm)',
        nameFr: 'Mofacross M2 (bis 80 ccm)',
        shortNameDe: 'Mofacross M2',
        shortNameEn: 'Mofacross M2',
        shortNameFr: 'Mofacross M2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M2',
        startListName: 'Mofacross M2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ff9900',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacross-m-3',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M3 (bis 100 ccm)',
    commonNameEn: 'Mofacross M3 (bis 100 ccm)',
    commonNameFr: 'Mofacross M3 (bis 100 ccm)',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M3',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 569,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M3 (bis 100 ccm)',
        nameEn: 'Mofacross M3 (bis 100 ccm)',
        nameFr: 'Mofacross M3 (bis 100 ccm)',
        shortNameDe: 'Mofacross M3',
        shortNameEn: 'Mofacross M3',
        shortNameFr: 'Mofacross M3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M3',
        startListName: 'Mofacross M3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ff9900',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-pitbikecross-pitbike-light-125',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'pitbikecross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Light 125',
    commonNameEn: 'Pitbike Light 125',
    commonNameFr: 'Pitbike Light 125',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Pitbike Light 125',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 570,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Light 125',
        nameEn: 'Pitbike Light 125',
        nameFr: 'Pitbike Light 125',
        shortNameDe: 'Pitbike Light 125',
        shortNameEn: 'Pitbike Light 125',
        shortNameFr: 'Pitbike Light 125',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Pitbike Light 125',
        startListName: 'Pitbike Light 125',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#801434',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-pitbikecross-pitbike-open-200',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'pitbikecross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Open 200',
    commonNameEn: 'Pitbike Open 200',
    commonNameFr: 'Pitbike Open 200',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Pitbike Open 200',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 571,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Open 200',
        nameEn: 'Pitbike Open 200',
        nameFr: 'Pitbike Open 200',
        shortNameDe: 'Pitbike Open 200',
        shortNameEn: 'Pitbike Open 200',
        shortNameFr: 'Pitbike Open 200',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Pitbike Open 200',
        startListName: 'Pitbike Open 200',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#66ff00',
        colorForeground: '#000000',
        colorLicense: '#801434',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-e-moto-e-moto-1',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'eMoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'eMoto 1',
    commonNameEn: 'eMoto 1',
    commonNameFr: 'eMoto 1',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'eMoto 1',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 772,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'eMoto 1 Standard',
        nameEn: 'eMoto 1 Standard',
        nameFr: 'eMoto 1 Standard',
        shortNameDe: 'eMoto 1',
        shortNameEn: 'eMoto 1',
        shortNameFr: 'eMoto 1',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'eMoto 1',
        startListName: 'eMoto 1',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#ffeb43',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-04-30T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-04-30T22:59:59.999Z',
        hintDe: 'z.B. Sur-Run Lightbee (ca. 45 km/h)',
        hintEn: 'z.B. Sur-Run Lightbee (ca. 45 km/h)',
        hintFr: 'z.B. Sur-Run Lightbee (ca. 45 km/h)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-e-moto-e-moto-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'eMoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'eMoto 2',
    commonNameEn: 'eMoto 2',
    commonNameFr: 'eMoto 2',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'eMoto 2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 773,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'eMoto 2 Open',
        nameEn: 'eMoto 2 Open',
        nameFr: 'eMoto 2 Open',
        shortNameDe: 'eMoto 2',
        shortNameEn: 'eMoto 2',
        shortNameFr: 'eMoto 2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'eMoto 2',
        startListName: 'eMoto 2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#000000',
        colorForeground: '#ffffff',
        colorLicense: '#ffeb43',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-04-30T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-04-30T22:59:59.999Z',
        hintDe: 'z.B. Sur-Run Lightbee Open (ca. 65 km/h)',
        hintEn: 'z.B. Sur-Run Lightbee Open (ca. 65 km/h)',
        hintFr: 'z.B. Sur-Run Lightbee Open (ca. 65 km/h)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-kid-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Kid 65',
    commonNameEn: 'Kid 65',
    commonNameFr: 'Kid 65',
    importName: 'SM Kid 65',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'KID 65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 572,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kid 65',
        nameEn: 'Kid 65',
        nameFr: 'Kid 65',
        shortNameDe: 'Kid 65',
        shortNameEn: 'Kid 65',
        shortNameFr: 'Kid 65',
        needsHealthCheck: [{ association: 'sam', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'KID 65',
        startListName: 'KID 65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und jünger',
        hintEn: 'Born in 2009 and younger',
        hintFr: 'Année de naissance 2009 et moins vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 20,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 40,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Kid 65',
        nameEn: 'Kid 65',
        nameFr: 'Kid 65',
        shortNameDe: 'Kid 65',
        shortNameEn: 'Kid 65',
        shortNameFr: 'Kid 65',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'KID 65',
        startListName: 'KID 65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 90, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und jünger',
        hintEn: 'Born in 2008 and younger',
        hintFr: 'Année de naissance 2008 et moins vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 20,
        priceDayLicenseForMemberTwoDays: 20,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-youngster-85',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Youngster 85',
    commonNameEn: 'Youngster 85',
    commonNameFr: 'Youngster 85',
    importName: 'SM Youngster 85',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'YOUNGSTER 85',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 573,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Youngster 85',
        nameEn: 'Youngster 85',
        nameFr: 'Youngster 85',
        shortNameDe: 'Youngster 85',
        shortNameEn: 'Youngster 85',
        shortNameFr: 'Youngster 85',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'YOUNGSTER 85',
        startListName: 'YOUNGSTER 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 210, licenseType: 'national' },
          { association: 'sam', value: 500, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2011 bis 2008',
        hintEn: 'Born between 2011 and 2008',
        hintFr: 'Né entre 2011 et 2008',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Youngster 85',
        nameEn: 'Youngster 85',
        nameFr: 'Youngster 85',
        shortNameDe: 'Youngster 85',
        shortNameEn: 'Youngster 85',
        shortNameFr: 'Youngster 85',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'YOUNGSTER 85',
        startListName: 'YOUNGSTER 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 110, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 bis 2007',
        hintEn: 'Born between 2010 and 2007',
        hintFr: 'Né entre 2010 et 2007',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-a-1-125-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'A1 125 Cup',
    commonNameEn: 'A1 125 Cup',
    commonNameFr: 'A1 125 Cup',
    importName: 'SM A1 125 Cup',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'A1 125 CUP',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 574,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'A1 125 Cup',
        nameEn: 'A1 125 Cup',
        nameFr: 'A1 125 Cup',
        shortNameDe: 'A1 125 Cup',
        shortNameEn: 'A1 125 Cup',
        shortNameFr: 'A1 125 Cup',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'A1 125 CUP',
        startListName: 'A1 125 CUP',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 210, licenseType: 'national' },
          { association: 'sam', value: 500, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2011 bis 2005',
        hintEn: 'Born between 2011 and 2005',
        hintFr: 'Né entre 2011 et 2005',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'A1 125 Cup',
        nameEn: 'A1 125 Cup',
        nameFr: 'A1 125 Cup',
        shortNameDe: 'A1 125 Cup',
        shortNameEn: 'A1 125 Cup',
        shortNameFr: 'A1 125 Cup',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'A1 125 CUP',
        startListName: 'A1 125 CUP',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 110, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 bis 2004',
        hintEn: 'Born between 2010 and 2004',
        hintFr: 'Né entre 2010 et 2004',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-women',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Women',
    commonNameEn: 'Women',
    commonNameFr: 'Women',
    importName: 'SM Women',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'WOMEN',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 575,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Women',
        nameEn: 'Women',
        nameFr: 'Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'WOMEN',
        startListName: 'WOMEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ff66ff',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 320, licenseType: 'national' },
          { association: 'sam', value: 650, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2008 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
        hintEn:
          'Born in 2008 and older. If there are not at least 8 licenses for the electro and the women, these categories start with the beginners and are evaluated separately.',
        hintFr:
          "Année de naissance 2008 et plus vieux. S'il n'y a pas d'au moins 8 licences pour l'électro et les femmes, ces catégories commencent avec les débutants et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Women',
        nameEn: 'Women',
        nameFr: 'Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'WOMEN',
        startListName: 'WOMEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ff66ff',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-elektro',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Elektro',
    commonNameEn: 'Elektro',
    commonNameFr: 'Elektro',
    importName: 'SM Elektro',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'ELEKTRO',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 576,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Elektro',
        nameEn: 'Elektro',
        nameFr: 'Elektro',
        shortNameDe: 'Elektro',
        shortNameEn: 'Elektro',
        shortNameFr: 'Elektro',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELEKTRO',
        startListName: 'ELEKTRO',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 320, licenseType: 'national' },
          { association: 'sam', value: 650, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2008 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
        hintEn:
          'Born in 2008 and older. If there are not at least 8 licenses for the electro and the women, these categories start with the beginners and are evaluated separately.',
        hintFr:
          "Année de naissance 2008 et plus vieux. S'il n'y a pas d'au moins 8 licences pour l'électro et les femmes, ces catégories commencent avec les débutants et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Elektro',
        nameEn: 'Elektro',
        nameFr: 'Elektro',
        shortNameDe: 'Elektro',
        shortNameEn: 'Elektro',
        shortNameFr: 'Elektro',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELEKTRO',
        startListName: 'ELEKTRO',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2007 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
        hintEn:
          'Born in 2006 and older. If there are not at least 8 licenses for the electro and the women, these categories start with the beginners and are ranked separately.',
        hintFr:
          "Année de naissance 2006 et plus vieux. S'il n'y a pas d'au moins 8 licences pour l'électro et les femmes, ces catégories commencent avec les débutants et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-s-5',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'S5',
    commonNameEn: 'S5',
    commonNameFr: 'S5',
    importName: 'SM S5',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'S5',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 577,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'S5 (ex. Einsteiger)',
        nameEn: 'S5 (ex. Einsteiger)',
        nameFr: 'Fun S5 (anct. Débutants)',
        shortNameDe: 'S5 (ex. Einsteiger)',
        shortNameEn: 'S5 (ex. Einsteiger)',
        shortNameFr: 'S5 (anct. Débutants)',
        needsHealthCheck: [{ association: 'sam', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S5',
        startListName: 'S5',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'S5 (ex. Einsteiger)',
        nameEn: 'S5 (ex. Einsteiger)',
        nameFr: 'Fun S5 (anct. Débutants)',
        shortNameDe: 'S5 (ex. Einsteiger)',
        shortNameEn: 'S5 (ex. Einsteiger)',
        shortNameFr: 'S5 (anct. Débutants)',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S5',
        startListName: 'S5',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 220, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-senior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senior',
    commonNameEn: 'Senior',
    commonNameFr: 'Senior',
    importName: 'SM Senior',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'SENIOR',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 578,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senior',
        nameEn: 'Senior',
        nameFr: 'Senior',
        shortNameDe: 'Senior',
        shortNameEn: 'Senior',
        shortNameFr: 'Senior',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'SENIOR',
        startListName: 'SENIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1988 und älter',
        hintEn: 'Born in 1988 and older',
        hintFr: 'Année de naissance 1988 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Senior',
        nameEn: 'Senior',
        nameFr: 'Senior',
        shortNameDe: 'Senior',
        shortNameEn: 'Senior',
        shortNameFr: 'Senior',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'SENIOR',
        startListName: 'SENIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1988 und älter',
        hintEn: 'Born in 1988 and older',
        hintFr: 'Année de naissance 1988 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-s-4',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'S4',
    commonNameEn: 'S4',
    commonNameFr: 'S4',
    importName: 'SM S4',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'S4',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 579,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'S4',
        nameEn: 'S4',
        nameFr: 'S4',
        shortNameDe: 'S4',
        shortNameEn: 'S4',
        shortNameFr: 'S4',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S4',
        startListName: 'S4',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'S4',
        nameEn: 'S4',
        nameFr: 'S4',
        shortNameDe: 'S4',
        shortNameEn: 'S4',
        shortNameFr: 'S4',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S4',
        startListName: 'S4',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-s-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'S2',
    commonNameEn: 'S2',
    commonNameFr: 'S2',
    importName: 'SM S2',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'S2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 769,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'S2',
        nameEn: 'S2',
        nameFr: 'S2',
        shortNameDe: 'S2',
        shortNameEn: 'S2',
        shortNameFr: 'S2',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S2',
        startListName: 'S2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#000000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'S2',
        nameEn: 'S2',
        nameFr: 'S2',
        shortNameDe: 'S2',
        shortNameEn: 'S2',
        shortNameFr: 'S2',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S2',
        startListName: 'S2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#000000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-s-1',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'S1',
    commonNameEn: 'S1',
    commonNameFr: 'S1',
    importName: 'SM S1',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'S1',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 580,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'S1',
        nameEn: 'S1',
        nameFr: 'S1',
        shortNameDe: 'S1',
        shortNameEn: 'S1',
        shortNameFr: 'S1',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S1',
        startListName: 'S1',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'S1',
        nameEn: 'S1',
        nameFr: 'S1',
        shortNameDe: 'S1',
        shortNameEn: 'S1',
        shortNameFr: 'S1',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'S1',
        startListName: 'S1',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-supermoto-quad',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Quad',
    commonNameEn: 'Quad',
    commonNameFr: 'Quad',
    importName: 'SM Quad',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'QUAD',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 581,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Quad',
        nameEn: 'Quad',
        nameFr: 'Quad',
        shortNameDe: 'Quad',
        shortNameEn: 'Quad',
        shortNameFr: 'Quad',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'QUAD',
        startListName: 'QUAD',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Quad',
        nameEn: 'Quad',
        nameFr: 'Quad',
        shortNameDe: 'Quad',
        shortNameEn: 'Quad',
        shortNameFr: 'Quad',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'QUAD',
        startListName: 'QUAD',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-minibike-pitbike-kids-cup-90-ccm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Kids-Cup 90 ccm',
    commonNameEn: 'Pitbike Kids-Cup 90 ccm',
    commonNameFr: 'Pitbike Kids-Cup 90 ccm',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE KIDS-CUP',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 763,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Kids-Cup 90 ccm',
        nameEn: 'Pitbike Kids-Cup 90 ccm',
        nameFr: 'Pitbike Kids-Cup 90 ccm',
        shortNameDe: 'Pitbike Kids-Cup 90 ccm',
        shortNameEn: 'Pitbike Kids-Cup 90 ccm',
        shortNameFr: 'Pitbike Kids-Cup 90 ccm',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE KIDS-CUP',
        startListName: 'PITBIKE KIDS-CUP',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-minibike-pitbike-mini-gp-160-ccm-series',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike / MiniGP 160 ccm Series',
    commonNameEn: 'Pitbike / MiniGP 160 ccm Series',
    commonNameFr: 'Pitbike / MiniGP 160 ccm Series',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP 160',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 764,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike / MiniGP 160 ccm Series',
        nameEn: 'Pitbike / MiniGP 160 ccm Series',
        nameFr: 'Pitbike / MiniGP 160 ccm Series',
        shortNameDe: 'Pitbike / MiniGP 160 ccm Series',
        shortNameEn: 'Pitbike / MiniGP 160 ccm Series',
        shortNameFr: 'Pitbike / MiniGP 160 ccm Series',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP 160',
        startListName: 'PITBIKE / MINIGP 160',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-minibike-pitbike-mini-gp-190-ccm-series',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike / MiniGP 190 ccm Series',
    commonNameEn: 'Pitbike / MiniGP 190 ccm Series',
    commonNameFr: 'Pitbike / MiniGP 190 ccm Series',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP 190',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 765,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike / MiniGP 190 ccm Series',
        nameEn: 'Pitbike / MiniGP 190 ccm Series',
        nameFr: 'Pitbike / MiniGP 190 ccm Series',
        shortNameDe: 'Pitbike / MiniGP 190 ccm Series',
        shortNameEn: 'Pitbike / MiniGP 190 ccm Series',
        shortNameFr: 'Pitbike / MiniGP 190 ccm Series',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP 190',
        startListName: 'PITBIKE / MINIGP 190',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ff0000',
        colorForeground: '#ffffff',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-minibike-pitbike-mini-gp-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike / MiniGP Open',
    commonNameEn: 'Pitbike / MiniGP Open',
    commonNameFr: 'Pitbike / MiniGP Open',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP OPEN',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 766,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike / MiniGP Open',
        nameEn: 'Pitbike / MiniGP Open',
        nameFr: 'Pitbike / MiniGP Open',
        shortNameDe: 'Pitbike / MiniGP Open',
        shortNameEn: 'Pitbike / MiniGP Open',
        shortNameFr: 'Pitbike / MiniGP Open',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP OPEN',
        startListName: 'PITBIKE / MINIGP OPEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-minibike-pitbike-mini-gp-ladies',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike / MiniGP Ladies',
    commonNameEn: 'Pitbike / MiniGP Ladies',
    commonNameFr: 'Pitbike / MiniGP Ladies',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP LADIES',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 767,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike / MiniGP Ladies',
        nameEn: 'Pitbike / MiniGP Ladies',
        nameFr: 'Pitbike / MiniGP Ladies',
        shortNameDe: 'Pitbike / MiniGP Ladies',
        shortNameEn: 'Pitbike / MiniGP Ladies',
        shortNameFr: 'Pitbike / MiniGP Ladies',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP LADIES',
        startListName: 'PITBIKE / MINIGP LADIES',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffc0cb',
        colorForeground: '#000000',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 130,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacup-rookies-e-50-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Rookies E-50 Cup',
    commonNameEn: 'Rookies E-50 Cup',
    commonNameFr: 'Rookies E-50 Cup',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Rookies E-50 Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 582,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Rookies E-50 Cup',
        nameEn: 'Rookies E-50 Cup',
        nameFr: 'Rookies E-50 Cup',
        shortNameDe: 'Rookies E-50 Cup',
        shortNameEn: 'Rookies E-50 Cup',
        shortNameFr: 'Rookies E-50 Cup',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Rookies E-50 Cup',
        startListName: 'Rookies E-50 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 110,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacup-superstock-70',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Superstock 70',
    commonNameEn: 'Superstock 70',
    commonNameFr: 'Superstock 70',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Superstock 70',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 583,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Superstock 70',
        nameEn: 'Superstock 70',
        nameFr: 'Superstock 70',
        shortNameDe: 'Superstock 70',
        shortNameEn: 'Superstock 70',
        shortNameFr: 'Superstock 70',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Superstock 70',
        startListName: 'Superstock 70',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 130,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacup-superstock-80',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Superstock 80',
    commonNameEn: 'Superstock 80',
    commonNameFr: 'Superstock 80',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Superstock 80',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 584,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Superstock 80',
        nameEn: 'Superstock 80',
        nameFr: 'Superstock 80',
        shortNameDe: 'Superstock 80',
        shortNameEn: 'Superstock 80',
        shortNameFr: 'Superstock 80',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Superstock 80',
        startListName: 'Superstock 80',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 130,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacup-open-100',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Open 100',
    commonNameEn: 'Open 100',
    commonNameFr: 'Open 100',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Open 100',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 585,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Open 100',
        nameEn: 'Open 100',
        nameFr: 'Open 100',
        shortNameDe: 'Open 100',
        shortNameEn: 'Open 100',
        shortNameFr: 'Open 100',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Open 100',
        startListName: 'Open 100',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 130,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-mofacup-puch-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Puch Cup',
    commonNameEn: 'Puch Cup',
    commonNameFr: 'Puch Cup',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Puch Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 586,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Puch Cup',
        nameEn: 'Puch Cup',
        nameFr: 'Puch Cup',
        shortNameDe: 'Puch Cup',
        shortNameEn: 'Puch Cup',
        shortNameFr: 'Puch Cup',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Puch Cup',
        startListName: 'Puch Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 130, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 130,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-3',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    commonNameEn: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    commonNameFr: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 3',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 594,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        nameEn: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        nameFr: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        shortNameDe: 'Klasse 3',
        shortNameEn: 'Klasse 3',
        shortNameFr: 'Klasse 3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 3',
        startListName: 'Klasse 3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-4',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 4, Fortgeschrittene, Jg. 1993 und jünger, blaue Spur',
    commonNameEn: 'Klasse 4, Fortgeschrittene, Jg. 1993 und jünger, blaue Spur',
    commonNameFr: 'Klasse 4, Fortgeschrittene, Jg. 1993 und jünger, blaue Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 4',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 595,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 4, Fortgeschrittene, Jg. 1993 und jünger, blaue Spur',
        nameEn: 'Klasse 4, Fortgeschrittene, Jg. 1993 und jünger, blaue Spur',
        nameFr: 'Klasse 4, Fortgeschrittene, Jg. 1993 und jünger, blaue Spur',
        shortNameDe: 'Klasse 4',
        shortNameEn: 'Klasse 4',
        shortNameFr: 'Klasse 4',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 4',
        startListName: 'Klasse 4',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-5',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 5, Einsteiger, Jg. 1978 und jünger, rote Spur',
    commonNameEn: 'Klasse 5, Einsteiger, Jg. 1978 und jünger, rote Spur',
    commonNameFr: 'Klasse 5, Einsteiger, Jg. 1978 und jünger, rote Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 5',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 596,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 5, Einsteiger, Jg. 1978 und jünger, rote Spur',
        nameEn: 'Klasse 5, Einsteiger, Jg. 1978 und jünger, rote Spur',
        nameFr: 'Klasse 5, Einsteiger, Jg. 1978 und jünger, rote Spur',
        shortNameDe: 'Klasse 5',
        shortNameEn: 'Klasse 5',
        shortNameFr: 'Klasse 5',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 5',
        startListName: 'Klasse 5',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-6',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 6, Nachwuchs, Jg. 2005 und jünger, grüne Spur',
    commonNameEn: 'Klasse 6, Nachwuchs, Jg. 2005 und jünger, grüne Spur',
    commonNameFr: 'Klasse 6, Nachwuchs, Jg. 2005 und jünger, grüne Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 6',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 597,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 6, Nachwuchs, Jg. 2005 und jünger, grüne Spur',
        nameEn: 'Klasse 6, Nachwuchs, Jg. 2005 und jünger, grüne Spur',
        nameFr: 'Klasse 6, Nachwuchs, Jg. 2005 und jünger, grüne Spur',
        shortNameDe: 'Klasse 6',
        shortNameEn: 'Klasse 6',
        shortNameFr: 'Klasse 6',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 6',
        startListName: 'Klasse 6',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-7',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 7, Senioren, Jg. 1992 und älter, blaue Spur',
    commonNameEn: 'Klasse 7, Senioren, Jg. 1992 und älter, blaue Spur',
    commonNameFr: 'Klasse 7, Senioren, Jg. 1992 und älter, blaue Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 7',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 598,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 7, Senioren, Jg. 1992 und älter, blaue Spur',
        nameEn: 'Klasse 7, Senioren, Jg. 1992 und älter, blaue Spur',
        nameFr: 'Klasse 7, Senioren, Jg. 1992 und älter, blaue Spur',
        shortNameDe: 'Klasse 7',
        shortNameEn: 'Klasse 7',
        shortNameFr: 'Klasse 7',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 7',
        startListName: 'Klasse 7',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-8',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 8, Veteranen, Jg. 1977 und älter, rote Spur',
    commonNameEn: 'Klasse 8, Veteranen, Jg. 1977 und älter, rote Spur',
    commonNameFr: 'Klasse 8, Veteranen, Jg. 1977 und älter, rote Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 8',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 599,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 8, Veteranen, Jg. 1977 und älter, rote Spur',
        nameEn: 'Klasse 8, Veteranen, Jg. 1977 und älter, rote Spur',
        nameFr: 'Klasse 8, Veteranen, Jg. 1977 und älter, rote Spur',
        shortNameDe: 'Klasse 8',
        shortNameEn: 'Klasse 8',
        shortNameFr: 'Klasse 8',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 8',
        startListName: 'Klasse 8',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-8-t',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    commonNameEn: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    commonNameFr: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 8T',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 600,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        nameEn: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        nameFr: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        shortNameDe: 'Klasse 8T',
        shortNameEn: 'Klasse 8T',
        shortNameFr: 'Klasse 8T',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 8T',
        startListName: 'Klasse 8T',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-9',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 9, Hobby, Jg. 2004 und älter, grüne Spur',
    commonNameEn: 'Klasse 9, Hobby, Jg. 2004 und älter, grüne Spur',
    commonNameFr: 'Klasse 9, Hobby, Jg. 2004 und älter, grüne Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 9',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 601,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 9, Hobby, Jg. 2004 und älter, grüne Spur',
        nameEn: 'Klasse 9, Hobby, Jg. 2004 und älter, grüne Spur',
        nameFr: 'Klasse 9, Hobby, Jg. 2004 und älter, grüne Spur',
        shortNameDe: 'Klasse 9',
        shortNameEn: 'Klasse 9',
        shortNameFr: 'Klasse 9',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 9',
        startListName: 'Klasse 9',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-klasse-9-t',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    commonNameEn: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    commonNameFr: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Klasse 9T',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 602,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        nameEn: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        nameFr: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        shortNameDe: 'Klasse 9T',
        shortNameEn: 'Klasse 9T',
        shortNameFr: 'Klasse 9T',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 9T',
        startListName: 'Klasse 9T',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-elektro-trial',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    commonNameEn: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    commonNameFr: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Elektro Trial',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 603,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        nameEn: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        nameFr: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        shortNameDe: 'Elektro Trial',
        shortNameEn: 'Elektro Trial',
        shortNameFr: 'Elektro Trial',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Elektro Trial',
        startListName: 'Elektro Trial',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2005-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2004-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-kids-klasse-1',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2016 und jünger, weisse Spur leicht',
    commonNameEn: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2016 und jünger, weisse Spur leicht',
    commonNameFr: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2016 und jünger, weisse Spur leicht',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 1',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 604,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2016 und jünger, weisse Spur leicht',
        nameEn: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2016 und jünger, weisse Spur leicht',
        nameFr: 'Kids Klasse 1, E Kids Cup 12.5" und 16", Jg. 2016 und jünger, weisse Spur leicht',
        shortNameDe: 'Kids Klasse 1',
        shortNameEn: 'Kids Klasse 1',
        shortNameFr: 'Kids Klasse 1',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 1',
        startListName: 'Kids Klasse 1',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-kids-klasse-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2014 und 2015, weisse Spur leicht',
    commonNameEn: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2014 und 2015, weisse Spur leicht',
    commonNameFr: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2014 und 2015, weisse Spur leicht',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 605,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2014 und 2015, weisse Spur leicht',
        nameEn: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2014 und 2015, weisse Spur leicht',
        nameFr: 'Kids Klasse 2, E Kids Cup 12.5" und 16", Jg. 2014 und 2015, weisse Spur leicht',
        shortNameDe: 'Kids Klasse 2',
        shortNameEn: 'Kids Klasse 2',
        shortNameFr: 'Kids Klasse 2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 2',
        startListName: 'Kids Klasse 2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-kids-klasse-3',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2013 und jünger, grüne Spur mittel',
    commonNameEn: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2013 und jünger, grüne Spur mittel',
    commonNameFr: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2013 und jünger, grüne Spur mittel',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 3',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 606,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2013 und jünger, grüne Spur mittel',
        nameEn: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2013 und jünger, grüne Spur mittel',
        nameFr: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2013 und jünger, grüne Spur mittel',
        shortNameDe: 'Kids Klasse 3',
        shortNameEn: 'Kids Klasse 3',
        shortNameFr: 'Kids Klasse 3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 3',
        startListName: 'Kids Klasse 3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-kids-klasse-4',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2007 bis 2012, grüne Spur mittel',
    commonNameEn: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2007 bis 2012, grüne Spur mittel',
    commonNameFr: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2007 bis 2012, grüne Spur mittel',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 4',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 607,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2007 bis 2012, grüne Spur mittel',
        nameEn: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2007 bis 2012, grüne Spur mittel',
        nameFr: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2007 bis 2012, grüne Spur mittel',
        shortNameDe: 'Kids Klasse 4',
        shortNameEn: 'Kids Klasse 4',
        shortNameFr: 'Kids Klasse 4',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 4',
        startListName: 'Kids Klasse 4',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-kids-klasse-5',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 5, E Kids Cup 20", Jg. 2007 und jünger, gelbe Spur schwer',
    commonNameEn: 'Kids Klasse 5, E Kids Cup 20", Jg. 2007 und jünger, gelbe Spur schwer',
    commonNameFr: 'Kids Klasse 5, E Kids Cup 20", Jg. 2007 und jünger, gelbe Spur schwer',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 5',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 608,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 5, E Kids Cup 20", Jg. 2007 und jünger, gelbe Spur schwer',
        nameEn: 'Kids Klasse 5, E Kids Cup 20", Jg. 2007 und jünger, gelbe Spur schwer',
        nameFr: 'Kids Klasse 5, E Kids Cup 20", Jg. 2007 und jünger, gelbe Spur schwer',
        shortNameDe: 'Kids Klasse 5',
        shortNameEn: 'Kids Klasse 5',
        shortNameFr: 'Kids Klasse 5',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 5',
        startListName: 'Kids Klasse 5',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-trial-kids-klasse-6',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 6, E Kids Cup 24", Jg. 2007 und jünger, gelbe Spur schwer',
    commonNameEn: 'Kids Klasse 6, E Kids Cup 24", Jg. 2007 und jünger, gelbe Spur schwer',
    commonNameFr: 'Kids Klasse 6, E Kids Cup 24", Jg. 2007 und jünger, gelbe Spur schwer',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 6',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 609,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 6, E Kids Cup 24", Jg. 2007 und jünger, gelbe Spur schwer',
        nameEn: 'Kids Klasse 6, E Kids Cup 24", Jg. 2007 und jünger, gelbe Spur schwer',
        nameFr: 'Kids Klasse 6, E Kids Cup 24", Jg. 2007 und jünger, gelbe Spur schwer',
        shortNameDe: 'Kids Klasse 6',
        shortNameEn: 'Kids Klasse 6',
        shortNameFr: 'Kids Klasse 6',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 6',
        startListName: 'Kids Klasse 6',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2023-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-other-trainingslizenz-funlizenz',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'other',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Trainingslizenz / Funlizenz',
    commonNameEn: 'Trainingslizenz / Funlizenz',
    commonNameFr: 'Trainingslizenz / Funlizenz',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName:
      'Hier kommt die Kategorie für ein Hobbyrennen / Tageslizenz rein - Trainingslizenz / Funlizenz',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 587,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Trainingslizenz / Funlizenz',
        nameEn: 'Trainingslizenz / Funlizenz',
        nameFr: 'Trainingslizenz / Funlizenz',
        shortNameDe: 'Fun',
        shortNameEn: 'Fun',
        shortNameFr: 'Fun',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName:
          'Hier kommt die Kategorie für ein Hobbyrennen / Tageslizenz rein - Trainingslizenz / Funlizenz',
        startListName:
          'Hier kommt die Kategorie für ein Hobbyrennen / Tageslizenz rein - Trainingslizenz / Funlizenz',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#afa88b',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 250, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 80,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2023-other-transponder-dummy',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'other',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Transponder-Dummy',
    commonNameEn: 'Transponder-Dummy',
    commonNameFr: 'Transponder-Dummy',
    importName: '',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Transponder-Dummy',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 770,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Transponder-Dummy',
        nameEn: 'Transponder-Dummy',
        nameFr: 'Transponder-Dummy',
        shortNameDe: 'Transponder-Dummy',
        shortNameEn: 'Transponder-Dummy',
        shortNameFr: 'Transponder-Dummy',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Transponder-Dummy',
        startListName: 'Transponder-Dummy',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#afa88b',
        numberFixed: false,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'national' }],
        priceHintDe:
          'wird benötigt, wenn ein Transponder gekauft werden soll, ohne eine Lizenz zu lösen',
        priceHintEn: 'is needed if a transponder is to be bought without loosening a license',
        priceHintFr: 'est nécessaire si un transpondeur doit être acheté sans relâcher une licence',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-swiss-mx-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss MX Open',
    commonNameEn: 'Swiss MX Open',
    commonNameFr: 'Swiss MX Open',
    importName: 'MX Swiss MX Open',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Swiss MX Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 610,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Open',
        nameEn: 'Swiss MX Open',
        nameFr: 'Swiss MX Open',
        shortNameDe: 'Swiss MX Open',
        shortNameEn: 'Swiss MX Open',
        shortNameFr: 'Swiss MX Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Open',
        startListName: 'Swiss MX Open',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-swiss-mx-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss MX2',
    commonNameEn: 'Swiss MX2',
    commonNameFr: 'Swiss MX2',
    importName: 'MX Swiss MX2',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Swiss MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 611,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX2',
        nameEn: 'Swiss MX2',
        nameFr: 'Swiss MX2',
        shortNameDe: 'Swiss MX2',
        shortNameEn: 'Swiss MX2',
        shortNameFr: 'Swiss MX2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX2',
        startListName: 'Swiss MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-inter-erweiterung-swiss-mx-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Inter Erweiterung Swiss MX Open',
    commonNameEn: 'Extension Inter Swiss MX Open',
    commonNameFr: 'Inter Extension Swiss MX Open',
    importName: 'MX Erweiterung / extension Inter Swiss MX Open',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 612,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Erweiterung Swiss MX Open',
        nameEn: 'Inter Extension Swiss MX Open',
        nameFr: 'Extension Inter Swiss MX Open',
        shortNameDe: 'Erweiterung Inter Swiss MX Open',
        shortNameEn: 'Inter Extension Swiss MX Open',
        shortNameFr: 'Extension Inter Swiss MX Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Open',
        startListName: 'Swiss MX Open',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'national' }],
        priceHintDe: 'Zusätzlich zu Lites 250, Junior 125 2T, oder Women Cup',
        priceHintEn: 'In addition to Lites 250, Junior 125 2T, or Women Cup',
        priceHintFr: 'En plus des lites 250, Junior 125 2T ou Women Cup',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-inter-erweiterung-swiss-mx-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Inter Erweiterung Swiss MX2',
    commonNameEn: 'Extension Inter Swiss MX2',
    commonNameFr: 'Inter Extension Swiss MX2',
    importName: 'MX Erweiterung / extension Inter Swiss MX2',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 613,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Erweiterung Swiss MX2',
        nameEn: 'Inter Extension Swiss MX2',
        nameFr: 'Extension Inter Swiss MX2',
        shortNameDe: 'Erweiterung Inter Swiss MX2',
        shortNameEn: 'Inter Extension Swiss MX2',
        shortNameFr: 'Extension Inter Swiss MX2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX2',
        startListName: 'Swiss MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'national' }],
        priceHintDe: 'Zusätzlich zu Lites 250, Junior 125 2T, oder Women Cup',
        priceHintEn: 'In addition to Lites 250, Junior 125 2T, or Women Cup',
        priceHintFr: 'En plus des lites 250, Junior 125 2T ou Women Cup',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-swiss-mx-open-auslandische-lizenz-licence-etrangere',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss MX Open ausländische Lizenz',
    commonNameEn: 'Swiss MX Open licence étrangère',
    commonNameFr: 'Swiss MX Open foreign license',
    importName: 'Swiss MX Open ausländische Lizenz / licence étrangère',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX Open',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 614,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Open ausländische Lizenz',
        nameEn: 'Swiss MX Open foreign license',
        nameFr: 'Swiss MX Open licence étrangère',
        shortNameDe: 'Swiss MX Open ausländische Lizenz',
        shortNameEn: 'Swiss MX Open foreign license',
        shortNameFr: 'Swiss MX Open licence étrangère',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Open',
        startListName: 'Swiss MX Open',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-swiss-mx-2-auslandische-lizenz-licence-etrangere',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss MX2 ausländische Lizenz',
    commonNameEn: 'Swiss MX2 licence étrangère',
    commonNameFr: 'Swiss MX2 foreign license',
    importName: 'Swiss MX2 ausländische Lizenz / licence étrangère',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX2',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 615,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX2 ausländische Lizenz',
        nameEn: 'Swiss MX2 foreign license',
        nameFr: 'Swiss MX2 licence étrangère',
        shortNameDe: 'Swiss MX2 ausländische Lizenz',
        shortNameEn: 'Swiss MX2 foreign license',
        shortNameFr: 'Swiss MX2 licence étrangère',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX2',
        startListName: 'Swiss MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-lites-250',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Lites 250',
    commonNameEn: 'Lites 250',
    commonNameFr: 'Lites 250',
    importName: 'MX Lites 250',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Lites 250',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 616,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Lites 250',
        nameEn: 'Lites 250',
        nameFr: 'Lites 250',
        shortNameDe: 'Lites 250',
        shortNameEn: 'Lites 250',
        shortNameFr: 'Lites 250',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Lites 250',
        startListName: 'Lites 250',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, to: '2005-01-01', licenseType: 'national' },
          { association: 'fms', value: 400, from: '2004-12-31', licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-junior-125-2-t',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Junior 125 2T',
    commonNameEn: 'Junior 125 2T',
    commonNameFr: 'Junior 125 2T',
    importName: 'MX Junior 125 2T',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Junior 125 2T',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 617,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Junior 125 2T',
        nameEn: 'Junior 125 2T',
        nameFr: 'Junior 125 2T',
        shortNameDe: 'Junior 125 2T',
        shortNameEn: 'Junior 125 2T',
        shortNameFr: 'Junior 125 2T',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Junior 125 2T',
        startListName: 'Junior 125 2T',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2002 bis 2010',
        hintEn: 'Born between 2002 and 2010',
        hintFr: 'Né entre 2002 et 2010',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-mini-85',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Mini 85',
    commonNameEn: 'Mini 85',
    commonNameFr: 'Mini 85',
    importName: 'MX Mini 85',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Mini 85',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 618,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Mini 85',
        nameEn: 'Mini 85',
        nameFr: 'Mini 85',
        shortNameDe: 'Mini 85',
        shortNameEn: 'Mini 85',
        shortNameFr: 'Mini 85',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mini 85',
        startListName: 'Mini 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 bis 2012',
        hintEn: 'Born between 2008 and 2012',
        hintFr: 'Né entre 2008 et 2012',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 30,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 40,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-scott-kids-cup-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Scott Kids Cup 65',
    commonNameEn: 'Scott Kids Cup 65',
    commonNameFr: 'Scott Kids Cup 65',
    importName: 'MX Scott Kids Cup 65',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Scott Kids Cup 65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 619,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Scott Kids Cup 65',
        nameEn: 'Scott Kids Cup 65',
        nameFr: 'Scott Kids Cup 65',
        shortNameDe: 'Scott Kids Cup 65',
        shortNameEn: 'Scott Kids Cup 65',
        shortNameFr: 'Scott Kids Cup 65',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Scott Kids Cup 65',
        startListName: 'Scott Kids Cup 65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 50, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2011 bis 2015',
        hintEn: 'Born between 2011 and 2015',
        hintFr: 'Né entre 2011 et 2015',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 30,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 40,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-yamaha-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Yamaha Cup',
    commonNameEn: 'Yamaha Cup',
    commonNameFr: 'Yamaha Cup',
    importName: 'MX Yamaha Cup',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Yamaha Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 620,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Yamaha Cup',
        nameEn: 'Yamaha Cup',
        nameFr: 'Yamaha Cup',
        shortNameDe: 'Yamaha Cup',
        shortNameEn: 'Yamaha Cup',
        shortNameFr: 'Yamaha Cup',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Yamaha Cup',
        startListName: 'Yamaha Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, to: '2005-01-01', licenseType: 'national' },
          { association: 'fms', value: 400, from: '2004-12-31', licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-swiss-mx-women-cup-angora-afm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Swiss MX Women Cup Angora/AFM',
    commonNameEn: 'Swiss MX Women Cup Angora/AFM',
    commonNameFr: 'Swiss MX Women Cup Angora/AFM',
    importName: 'MX Swiss MX Women Cup Angora/AFM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX Women Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 621,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Women Cup Angora/AFM',
        nameEn: 'Swiss MX Women Cup Angora/AFM',
        nameFr: 'Swiss MX Women Cup Angora/AFM',
        shortNameDe: 'Swiss MX Women Cup Angora/AFM',
        shortNameEn: 'Swiss MX Women Cup Angora/AFM',
        shortNameFr: 'Swiss MX Women Cup Angora/AFM',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Women Cup',
        startListName: 'Swiss MX Women Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 und älter',
        hintEn: 'Born in 2010 and older',
        hintFr: 'Année de naissance 2010 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-mx-quad',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'MX Quad',
    commonNameEn: 'MX Quad',
    commonNameFr: 'MX Quad',
    importName: 'MX Quad',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 762,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Quad',
        nameEn: 'MX Quad',
        nameFr: 'MX Quad',
        shortNameDe: 'MX Quad',
        shortNameEn: 'MX Quad',
        shortNameFr: 'MX Quad',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-mxgp-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM MXGP WM',
    commonNameEn: 'FIM MXGP WM',
    commonNameFr: 'FIM MXGP WM',
    importName: 'MX FIM MXGP WM',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 622,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MXGP WM',
        nameEn: 'FIM MXGP WM',
        nameFr: 'FIM MXGP WM',
        shortNameDe: 'FIM MXGP WM',
        shortNameEn: 'FIM MXGP WM',
        shortNameFr: 'FIM MXGP WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-mx-2-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM MX2 WM',
    commonNameEn: 'FIM MX2 WM',
    commonNameFr: 'FIM MX2 WM',
    importName: 'MX FIM MX2 WM',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 623,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX2 WM',
        nameEn: 'FIM MX2 WM',
        nameFr: 'FIM MX2 WM',
        shortNameDe: 'FIM MX2 WM',
        shortNameEn: 'FIM MX2 WM',
        shortNameFr: 'FIM MX2 WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-sidecar-pilot-wm',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Sidecar Pilot WM',
    commonNameEn: 'FIM Sidecar Pilot WM',
    commonNameFr: 'FIM Sidecar Pilot WM',
    importName: 'MX FIM Sidecar Pilot WM',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 624,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Pilot WM',
        nameEn: 'FIM Sidecar Pilot WM',
        nameFr: 'FIM Sidecar Pilot WM',
        shortNameDe: 'FIM Sidecar Pilot WM',
        shortNameEn: 'FIM Sidecar Pilot WM',
        shortNameFr: 'FIM Sidecar Pilot WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 850, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 500,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-sidecar-passenger-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Sidecar Passager WM',
    commonNameEn: 'FIM Sidecar Passager WM',
    commonNameFr: 'FIM Sidecar Passager WM',
    importName: 'MX FIM Sidecar Passenger WM',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 625,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Passager WM',
        nameEn: 'FIM Sidecar Passager WM',
        nameFr: 'FIM Sidecar Passager WM',
        shortNameDe: 'FIM Sidecar Passenger WM',
        shortNameEn: 'FIM Sidecar Passenger WM',
        shortNameFr: 'FIM Sidecar Passenger WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-wmx',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM WMX',
    commonNameEn: 'FIM WMX',
    commonNameFr: 'FIM WMX',
    importName: 'MX FIM WMX',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 626,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM WMX',
        nameEn: 'FIM WMX',
        nameFr: 'FIM WMX',
        shortNameDe: 'FIM WMX',
        shortNameEn: 'FIM WMX',
        shortNameFr: 'FIM WMX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 750, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 250,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fime-emx-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX65',
    commonNameEn: 'FIME EMX65',
    commonNameFr: 'FIME EMX65',
    importName: 'MX FIME EMX65',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 627,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX65',
        nameEn: 'FIME EMX65',
        nameFr: 'FIME EMX65',
        shortNameDe: 'FIME EMX65',
        shortNameEn: 'FIME EMX65',
        shortNameFr: 'FIME EMX65',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fime-emx-85',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX85',
    commonNameEn: 'FIME EMX85',
    commonNameFr: 'FIME EMX85',
    importName: 'MX FIME EMX85',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 628,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX85',
        nameEn: 'FIME EMX85',
        nameFr: 'FIME EMX85',
        shortNameDe: 'FIME EMX85',
        shortNameEn: 'FIME EMX85',
        shortNameFr: 'FIME EMX85',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fime-emx-125',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX125',
    commonNameEn: 'FIME EMX125',
    commonNameFr: 'FIME EMX125',
    importName: 'MX FIME EMX125',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 629,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX125',
        nameEn: 'FIME EMX125',
        nameFr: 'FIME EMX125',
        shortNameDe: 'FIME EMX125',
        shortNameEn: 'FIME EMX125',
        shortNameFr: 'FIME EMX125',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fime-emx-250',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX250',
    commonNameEn: 'FIME EMX250',
    commonNameFr: 'FIME EMX250',
    importName: 'MX FIME EMX250',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 630,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX250',
        nameEn: 'FIME EMX250',
        nameFr: 'FIME EMX250',
        shortNameDe: 'FIME EMX250',
        shortNameEn: 'FIME EMX250',
        shortNameFr: 'FIME EMX250',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-emx-2-t',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX2T',
    commonNameEn: 'FIME EMX2T',
    commonNameFr: 'FIME EMX2T',
    importName: 'MX FIME EMX2T',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 631,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX2T',
        nameEn: 'FIME EMX2T',
        nameFr: 'FIME EMX2T',
        shortNameDe: 'FIME EMX2T',
        shortNameEn: 'FIME EMX2T',
        shortNameFr: 'FIME EMX2T',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fime-emx-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX Open',
    commonNameEn: 'FIME EMX Open',
    commonNameFr: 'FIME EMX Open',
    importName: 'MX FIME EMX Open',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 632,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX Open',
        nameEn: 'FIME EMX Open',
        nameFr: 'FIME EMX Open',
        shortNameDe: 'FIME EMX Open',
        shortNameEn: 'FIME EMX Open',
        shortNameFr: 'FIME EMX Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fime-wemx',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME WEMX',
    commonNameEn: 'FIME WEMX',
    commonNameFr: 'FIME WEMX',
    importName: 'MX FIME WEMX',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 633,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME WEMX',
        nameEn: 'FIME WEMX',
        nameFr: 'FIME WEMX',
        shortNameDe: 'FIME WEMX',
        shortNameEn: 'FIME WEMX',
        shortNameFr: 'FIME WEMX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 250, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-supermoto-fim-supermoto-s-1-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Supermoto S1 WM',
    commonNameEn: 'FIM Supermoto S1 WM',
    commonNameFr: 'FIM Supermoto S1 WM',
    importName: 'SM FIM Supermoto S1 WM',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 634,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supermoto S1 WM',
        nameEn: 'FIM Supermoto S1 WM',
        nameFr: 'FIM Supermoto S1 WM',
        shortNameDe: 'FIM Supermoto S1 WM',
        shortNameEn: 'FIM Supermoto S1 WM',
        shortNameFr: 'FIM Supermoto S1 WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 600,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-supermoto-fime-supermoto-s-2-em',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: '[Deaktiviert] FIME Supermoto S2 EM',
    commonNameEn: '[Deaktiviert] FIME Supermoto S2 EM',
    commonNameFr: '[Deaktiviert] FIME Supermoto S2 EM',
    importName: '[Deaktiviert] SM FIME Supermoto S2 EM',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 635,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: '[Deaktiviert] FIME Supermoto S2 EM',
        nameEn: '[Deaktiviert] FIME Supermoto S2 EM',
        nameFr: '[Deaktiviert] FIME Supermoto S2 EM',
        shortNameDe: '[Deaktiviert] FIME Supermoto S2 EM',
        shortNameEn: '[Deaktiviert] FIME Supermoto S2 EM',
        shortNameFr: '[Deaktiviert] FIME Supermoto S2 EM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-supermoto-fime-supermoto-s-4-em',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME Supermoto S4 EM',
    commonNameEn: 'FIME Supermoto S4 EM',
    commonNameFr: 'FIME Supermoto S4 EM',
    importName: 'SM FIME Supermoto S4 EM',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 768,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Supermoto S4 EM',
        nameEn: 'FIME Supermoto S4 EM',
        nameFr: 'FIME Supermoto S4 EM',
        shortNameDe: 'FIME Supermoto S4 EM',
        shortNameEn: 'FIME Supermoto S4 EM',
        shortNameFr: 'FIME Supermoto S4 EM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-elite',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Elite',
    commonNameEn: 'Elite',
    commonNameFr: 'Elite',
    importName: 'Trial Elite',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'ELITE',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 636,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Elite',
        nameEn: 'Elite',
        nameFr: 'Elite',
        shortNameDe: 'Elite',
        shortNameEn: 'Elite',
        shortNameFr: 'Elite',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELITE',
        startListName: 'ELITE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-expert',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Expert',
    commonNameEn: 'Expert',
    commonNameFr: 'Expert',
    importName: 'Trial Expert',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'EXPERT',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 637,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Expert',
        nameEn: 'Expert',
        nameFr: 'Expert',
        shortNameDe: 'Expert',
        shortNameEn: 'Expert',
        shortNameFr: 'Expert',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'EXPERT',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 500, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Open',
    commonNameEn: 'Open',
    commonNameFr: 'Open',
    importName: 'Trial Open',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'OPEN',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 638,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Open',
        nameEn: 'Open',
        nameFr: 'Open',
        shortNameDe: 'Open',
        shortNameEn: 'Open',
        shortNameFr: 'Open',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'OPEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 100, to: '2005-01-01', licenseType: 'national' },
          {
            association: 'fms',
            value: 200,
            from: '2004-12-31',
            to: '2002-01-01',
            licenseType: 'national',
          },
          { association: 'fms', value: 350, from: '2001-12-31', licenseType: 'national' },
          { association: 'fms', value: 350, to: '2002-01-01', licenseType: 'international' },
          { association: 'fms', value: 500, from: '2001-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-challenge',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Challenge',
    commonNameEn: 'Challenge',
    commonNameFr: 'Challenge',
    importName: 'Trial Challenge',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'CHALLENGE',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 639,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Challenge',
        nameEn: 'Challenge',
        nameFr: 'Challenge',
        shortNameDe: 'Challenge',
        shortNameEn: 'Challenge',
        shortNameFr: 'Challenge',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'CHALLENGE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 100, to: '2005-01-01', licenseType: 'national' },
          {
            association: 'fms',
            value: 200,
            from: '2004-12-31',
            to: '2002-01-01',
            licenseType: 'national',
          },
          { association: 'fms', value: 350, from: '2001-12-31', licenseType: 'national' },
          { association: 'fms', value: 350, to: '2002-01-01', licenseType: 'international' },
          { association: 'fms', value: 500, from: '2001-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-junior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Junior',
    commonNameEn: 'Junior',
    commonNameFr: 'Junior',
    importName: 'Trial Junior',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'JUNIOR',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 640,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Junior',
        nameEn: 'Junior',
        nameFr: 'Junior',
        shortNameDe: 'Junior',
        shortNameEn: 'Junior',
        shortNameFr: 'Junior',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'JUNIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 100, to: '2005-01-01', licenseType: 'national' },
          { association: 'fms', value: 200, from: '2004-12-31', licenseType: 'national' },
          { association: 'fms', value: 350, to: '2002-01-01', licenseType: 'international' },
          { association: 'fms', value: 500, from: '2001-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2003 und älter',
        hintEn: 'Year 2003 and older',
        hintFr: 'Année 2003 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fun',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Fun',
    commonNameEn: 'Fun',
    commonNameFr: 'Fun',
    importName: 'Trial Fun',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'FUN',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 641,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Fun',
        nameEn: 'Fun',
        nameFr: 'Fun',
        shortNameDe: 'Fun',
        shortNameEn: 'Fun',
        shortNameFr: 'Fun',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'FUN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-assistent',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Assistent',
    commonNameEn: 'Assistent',
    commonNameFr: 'Assistent',
    importName: 'Trial Assistent',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'ASSISTENT',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 642,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Assistent',
        nameEn: 'Assistent',
        nameFr: 'Assistent',
        shortNameDe: 'Assistent',
        shortNameEn: 'Assistent',
        shortNameFr: 'Assistent',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'ASSISTENT',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 50, licenseType: 'national' },
          { association: 'fms', value: 100, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fime-trial',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial EM / CE',
    commonNameEn: 'FIME Trial EM / CE',
    commonNameFr: 'FIME Trial EM / CE',
    importName: 'Trial FIME Trial',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 643,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial EM / CE',
        nameEn: 'FIME Trial EM / CE',
        nameFr: 'FIME Trial EM / CE',
        shortNameDe: 'FIME Trial EM / CE',
        shortNameEn: 'FIME Trial EM / CE',
        shortNameFr: 'FIME Trial EM / CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fim-trial-x-trial',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial / X Trial',
    commonNameEn: 'FIM Trial / X Trial',
    commonNameFr: 'FIM Trial / X Trial',
    importName: 'Trial FIM Trial / X Trial',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 644,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial / X Trial',
        nameEn: 'FIM Trial / X Trial',
        nameFr: 'FIM Trial / X Trial',
        shortNameDe: 'FIM Trial / X Trial',
        shortNameEn: 'FIM Trial / X Trial',
        shortNameFr: 'FIM Trial / X Trial',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1050, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 600,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fim-trial-women',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Women',
    commonNameEn: 'FIM Trial Women',
    commonNameFr: 'FIM Trial Women',
    importName: 'Trial FIM Trial Women',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 645,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Women',
        nameEn: 'FIM Trial Women',
        nameFr: 'FIM Trial Women',
        shortNameDe: 'FIM Trial Women',
        shortNameEn: 'FIM Trial Women',
        shortNameFr: 'FIM Trial Women',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 750, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fim-trial-assistant',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Assistant',
    commonNameEn: 'FIM Trial Assistant',
    commonNameFr: 'FIM Trial Assistant',
    importName: 'Trial FIM Trial Assistant',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 646,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Assistant',
        nameEn: 'FIM Trial Assistant',
        nameFr: 'FIM Trial Assistant',
        shortNameDe: 'FIM Trial Assistant',
        shortNameEn: 'FIM Trial Assistant',
        shortNameFr: 'FIM Trial Assistant',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 250, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fime-trial-assistant',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial Assistant',
    commonNameEn: 'FIME Trial Assistant',
    commonNameFr: 'FIME Trial Assistant',
    importName: 'Trial FIME Trial Assistant',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 647,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial Assistant',
        nameEn: 'FIME Trial Assistant',
        nameFr: 'FIME Trial Assistant',
        shortNameDe: 'FIME Trial Assistant',
        shortNameEn: 'FIME Trial Assistant',
        shortNameFr: 'FIME Trial Assistant',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 150, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-inter-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter Open',
    commonNameEn: 'Inter Open',
    commonNameFr: 'Inter Open',
    importName: 'Enduro Inter Open',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 648,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Open',
        nameEn: 'Inter Open',
        nameFr: 'Inter Open',
        shortNameDe: 'Inter Open',
        shortNameEn: 'Inter Open',
        shortNameFr: 'Inter Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, to: '1997-01-01', licenseType: 'international' },
          { association: 'fms', value: 550, from: '1996-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-inter-junior-25',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter Junior -25',
    commonNameEn: 'Inter Junior -25',
    commonNameFr: 'Inter Junior -25',
    importName: 'Enduro Inter Junior -25',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 649,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Junior -25',
        nameEn: 'Inter Junior -25',
        nameFr: 'Inter Junior -25',
        shortNameDe: 'Inter Junior -25',
        shortNameEn: 'Inter Junior -25',
        shortNameFr: 'Inter Junior -25',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1998 und älter',
        hintEn: 'Born in 1998 and older',
        hintFr: 'Né en 1998 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-national-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'National Open',
    commonNameEn: 'National Open',
    commonNameFr: 'National Open',
    importName: 'Enduro National Open',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 650,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Open',
        nameEn: 'National Open',
        nameFr: 'National Open',
        shortNameDe: 'National Open',
        shortNameEn: 'National Open',
        shortNameFr: 'National Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 250, to: '1997-01-01', licenseType: 'national' },
          { association: 'fms', value: 400, from: '1996-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-national-junior-open-25',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'National Junior Open -25',
    commonNameEn: 'National Junior Open -25',
    commonNameFr: 'National Junior Open -25',
    importName: 'Enduro National Junior Open -25',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 651,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Junior Open -25',
        nameEn: 'National Junior Open -25',
        nameFr: 'National Junior Open -25',
        shortNameDe: 'National Junior Open -25',
        shortNameEn: 'National Junior Open -25',
        shortNameFr: 'National Junior Open -25',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 250, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1998 und älter',
        hintEn: 'Born in 1998 and older',
        hintFr: 'Né en 1998 et plus',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-national-veteran',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'National Veteran',
    commonNameEn: 'National Veteran',
    commonNameFr: 'National Veteran',
    importName: 'Enduro National Veteran',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 652,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Veteran',
        nameEn: 'National Veteran',
        nameFr: 'National Veteran',
        shortNameDe: 'National Veteran',
        shortNameEn: 'National Veteran',
        shortNameFr: 'National Veteran',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-national-senior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'National Senior',
    commonNameEn: 'National Senior',
    commonNameFr: 'National Senior',
    importName: 'Enduro National Senior',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 653,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Senior',
        nameEn: 'National Senior',
        nameFr: 'National Senior',
        shortNameDe: 'National Senior',
        shortNameEn: 'National Senior',
        shortNameFr: 'National Senior',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-kadetten-cadets',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Kadetten / Cadets',
    commonNameEn: 'Kadetten / Cadets',
    commonNameFr: 'Kadetten / Cadets',
    importName: 'Enduro Kadetten / Cadets',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 654,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Kadetten / Cadets',
        nameEn: 'Kadetten / Cadets',
        nameFr: 'Kadetten / Cadets',
        shortNameDe: 'Kadetten / Cadets',
        shortNameEn: 'Kadetten / Cadets',
        shortNameFr: 'Kadetten / Cadets',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-enduro-rally',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Enduro Rally',
    commonNameEn: 'Enduro Rally',
    commonNameFr: 'Enduro Rally',
    importName: 'Enduro Rally',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 655,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Enduro Rally',
        nameEn: 'Enduro Rally',
        nameFr: 'Enduro Rally',
        shortNameDe: 'Enduro Rally',
        shortNameEn: 'Enduro Rally',
        shortNameFr: 'Enduro Rally',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-vintage-enduro-european-championship',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Vintage Enduro European Championship',
    commonNameEn: 'Vintage Enduro European Championship',
    commonNameFr: 'Vintage Enduro European Championship',
    importName: 'Enduro Vintage Enduro European Championship',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 656,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Vintage Enduro European Championship',
        nameEn: 'Vintage Enduro European Championship',
        nameFr: 'Vintage Enduro European Championship',
        shortNameDe: 'Vintage Enduro European Championship',
        shortNameEn: 'Vintage Enduro European Championship',
        shortNameFr: 'Vintage Enduro European Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fime-enduro',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Enduro',
    commonNameEn: 'FIME Enduro',
    commonNameFr: 'FIME Enduro',
    importName: 'Enduro FIME Enduro',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 657,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Enduro',
        nameEn: 'FIME Enduro',
        nameFr: 'FIME Enduro',
        shortNameDe: 'FIME Enduro',
        shortNameEn: 'FIME Enduro',
        shortNameFr: 'FIME Enduro',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fime-enduro-junior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Enduro Junior',
    commonNameEn: 'FIME Enduro Junior',
    commonNameFr: 'FIME Enduro Junior',
    importName: 'Enduro FIME Enduro Junior',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 658,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Enduro Junior',
        nameEn: 'FIME Enduro Junior',
        nameFr: 'FIME Enduro Junior',
        shortNameDe: 'FIME Enduro Junior',
        shortNameEn: 'FIME Enduro Junior',
        shortNameFr: 'FIME Enduro Junior',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-enduro-isde',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro / ISDE',
    commonNameEn: 'FIM Enduro / ISDE',
    commonNameFr: 'FIM Enduro / ISDE',
    importName: 'Enduro FIM Enduro / ISDE',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 659,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro / ISDE',
        nameEn: 'FIM Enduro / ISDE',
        nameFr: 'FIM Enduro / ISDE',
        shortNameDe: 'FIM Enduro / ISDE',
        shortNameEn: 'FIM Enduro / ISDE',
        shortNameFr: 'FIM Enduro / ISDE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1050, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 550,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-enduro-junior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro Junior',
    commonNameEn: 'FIM Enduro Junior',
    commonNameFr: 'FIM Enduro Junior',
    importName: 'Enduro FIM Enduro Junior',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 660,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro Junior',
        nameEn: 'FIM Enduro Junior',
        nameFr: 'FIM Enduro Junior',
        shortNameDe: 'FIM Enduro Junior',
        shortNameEn: 'FIM Enduro Junior',
        shortNameFr: 'FIM Enduro Junior',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-womens-world-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: "FIM Women's World Cup",
    commonNameEn: "FIM Women's World Cup",
    commonNameFr: "FIM Women's World Cup",
    importName: "Enduro FIM Women's World Cup",
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 661,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: "FIM Women's World Cup",
        nameEn: "FIM Women's World Cup",
        nameFr: "FIM Women's World Cup",
        shortNameDe: "FIM Women's World Cup",
        shortNameEn: "FIM Women's World Cup",
        shortNameFr: "FIM Women's World Cup",
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-super-enduro',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SuperEnduro',
    commonNameEn: 'FIM SuperEnduro',
    commonNameFr: 'FIM SuperEnduro',
    importName: 'Enduro FIM SuperEnduro',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 662,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SuperEnduro',
        nameEn: 'FIM SuperEnduro',
        nameFr: 'FIM SuperEnduro',
        shortNameDe: 'FIM SuperEnduro',
        shortNameEn: 'FIM SuperEnduro',
        shortNameFr: 'FIM SuperEnduro',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-vintage-solo',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Solo',
    commonNameEn: 'Solo',
    commonNameFr: 'Solo',
    importName: 'Vintage Solo',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 663,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Solo',
        nameEn: 'Solo',
        nameFr: 'Solo',
        shortNameDe: 'Solo',
        shortNameEn: 'Solo',
        shortNameFr: 'Solo',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1973-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1972-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-vintage-sidecar-pilot',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Pilot',
    commonNameEn: 'Sidecar Pilot',
    commonNameFr: 'Sidecar Pilot',
    importName: 'Vintage Sidecar Pilot',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 664,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Pilot',
        nameEn: 'Sidecar Pilot',
        nameFr: 'Sidecar Pilot',
        shortNameDe: 'Sidecar Pilot',
        shortNameEn: 'Sidecar Pilot',
        shortNameFr: 'Sidecar Pilot',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1973-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1972-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-vintage-sidecar-passager',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Passager',
    commonNameEn: 'Sidecar Passager',
    commonNameFr: 'Sidecar Passager',
    importName: 'Vintage Sidecar Passager',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 665,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Passager',
        nameEn: 'Sidecar Passager',
        nameFr: 'Sidecar Passager',
        shortNameDe: 'Sidecar Passager',
        shortNameEn: 'Sidecar Passager',
        shortNameFr: 'Sidecar Passager',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1973-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1972-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 300, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-vintage-uem-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'UEM Cup',
    commonNameEn: 'UEM Cup',
    commonNameFr: 'UEM Cup',
    importName: 'Vintage UEM Cup',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 666,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'UEM Cup',
        nameEn: 'UEM Cup',
        nameFr: 'UEM Cup',
        shortNameDe: 'UEM Cup',
        shortNameEn: 'UEM Cup',
        shortNameFr: 'UEM Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-bergrennen-fime-bergrennen-course-de-cote',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Bergrennen / Course de Côte',
    commonNameEn: 'FIME Bergrennen / Course de Côte',
    commonNameFr: 'FIME Bergrennen / Course de Côte',
    importName: 'Bergrennen FIME Bergrennen / Course de Côte',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 667,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Bergrennen / Course de Côte',
        nameEn: 'FIME Bergrennen / Course de Côte',
        nameFr: 'FIME Bergrennen / Course de Côte',
        shortNameDe: 'FIME Bergrennen / Course de Côte',
        shortNameEn: 'FIME Bergrennen / Course de Côte',
        shortNameFr: 'FIME Bergrennen / Course de Côte',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 700, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 200,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-bergrennen-swiss-moto-legend-trophy',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Swiss Moto Legend Trophy',
    commonNameEn: 'Swiss Moto Legend Trophy',
    commonNameFr: 'Swiss Moto Legend Trophy',
    importName: 'Bergrennen Swiss Moto Legend Trophy',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 668,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto Legend Trophy',
        nameEn: 'Swiss Moto Legend Trophy',
        nameFr: 'Swiss Moto Legend Trophy',
        shortNameDe: 'Swiss Moto Legend Trophy',
        shortNameEn: 'Swiss Moto Legend Trophy',
        shortNameFr: 'Swiss Moto Legend Trophy',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1973-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1972-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-bergrennen-swiss-sidecar-legend-trophy',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Swiss Sidecar Legend Trophy',
    commonNameEn: 'Swiss Sidecar Legend Trophy',
    commonNameFr: 'Swiss Sidecar Legend Trophy',
    importName: 'Bergrennen Swiss Sidecar Legend Trophy',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 669,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Sidecar Legend Trophy',
        nameEn: 'Swiss Sidecar Legend Trophy',
        nameFr: 'Swiss Sidecar Legend Trophy',
        shortNameDe: 'Swiss Sidecar Legend Trophy',
        shortNameEn: 'Swiss Sidecar Legend Trophy',
        shortNameFr: 'Swiss Sidecar Legend Trophy',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1973-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1972-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-bergrennen-solo',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Solo',
    commonNameEn: 'Solo',
    commonNameFr: 'Solo',
    importName: 'Bergrennen Solo',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 670,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Solo',
        nameEn: 'Solo',
        nameFr: 'Solo',
        shortNameDe: 'Solo',
        shortNameEn: 'Solo',
        shortNameFr: 'Solo',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-bergrennen-sidecar-pilot',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Pilot',
    commonNameEn: 'Sidecar Pilot',
    commonNameFr: 'Sidecar Pilot',
    importName: 'Bergrennen Sidecar Pilot',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 671,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Pilot',
        nameEn: 'Sidecar Pilot',
        nameFr: 'Sidecar Pilot',
        shortNameDe: 'Sidecar Pilot',
        shortNameEn: 'Sidecar Pilot',
        shortNameFr: 'Sidecar Pilot',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-bergrennen-sidecar-passager',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Passager',
    commonNameEn: 'Sidecar Passager',
    commonNameFr: 'Sidecar Passager',
    importName: 'Bergrennen Sidecar Passager',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 672,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Passager',
        nameEn: 'Sidecar Passager',
        nameFr: 'Sidecar Passager',
        shortNameDe: 'Sidecar Passager',
        shortNameEn: 'Sidecar Passager',
        shortNameFr: 'Sidecar Passager',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 300, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-moto-gp',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto GP',
    commonNameEn: 'FIM Moto GP',
    commonNameFr: 'FIM Moto GP',
    importName: 'Road Racing FIM Moto GP',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 673,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto GP',
        nameEn: 'FIM Moto GP',
        nameFr: 'FIM Moto GP',
        shortNameDe: 'FIM Moto GP',
        shortNameEn: 'FIM Moto GP',
        shortNameFr: 'FIM Moto GP',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Outrage by IRTA',
        hintFr: 'Indignation par IRTA',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-moto-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto2',
    commonNameEn: 'FIM Moto2',
    commonNameFr: 'FIM Moto2',
    importName: 'Road Racing FIM Moto2',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 674,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto2',
        nameEn: 'FIM Moto2',
        nameFr: 'FIM Moto2',
        shortNameDe: 'FIM Moto2',
        shortNameEn: 'FIM Moto2',
        shortNameFr: 'FIM Moto2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Outrage by IRTA',
        hintFr: 'Indignation par IRTA',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-moto-3',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto3',
    commonNameEn: 'FIM Moto3',
    commonNameFr: 'FIM Moto3',
    importName: 'Road Racing FIM Moto3',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 675,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto3',
        nameEn: 'FIM Moto3',
        nameFr: 'FIM Moto3',
        shortNameDe: 'FIM Moto3',
        shortNameEn: 'FIM Moto3',
        shortNameFr: 'FIM Moto3',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Outrage by IRTA',
        hintFr: 'Indignation par IRTA',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-moto-e',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MotoE',
    commonNameEn: 'FIM MotoE',
    commonNameFr: 'FIM MotoE',
    importName: 'Road Racing FIM MotoE',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 676,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MotoE',
        nameEn: 'FIM MotoE',
        nameFr: 'FIM MotoE',
        shortNameDe: 'FIM MotoE',
        shortNameEn: 'FIM MotoE',
        shortNameFr: 'FIM MotoE',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Outrage by IRTA',
        hintFr: 'Indignation par IRTA',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-superbike-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Superbike WM/CM',
    commonNameEn: 'FIM Superbike WM/CM',
    commonNameFr: 'FIM Superbike WM/CM',
    importName: 'Road Racing FIM Superbike WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 677,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Superbike WM/CM',
        nameEn: 'FIM Superbike WM/CM',
        nameFr: 'FIM Superbike WM/CM',
        shortNameDe: 'FIM Superbike WM/CM',
        shortNameEn: 'FIM Superbike WM/CM',
        shortNameFr: 'FIM Superbike WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 2400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 900,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-supersport-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Supersport  WM/CM',
    commonNameEn: 'FIM Supersport  WM/CM',
    commonNameFr: 'FIM Supersport  WM/CM',
    importName: 'Road Racing FIM Supersport  WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 678,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supersport  WM/CM',
        nameEn: 'FIM Supersport  WM/CM',
        nameFr: 'FIM Supersport  WM/CM',
        shortNameDe: 'FIM Supersport  WM/CM',
        shortNameEn: 'FIM Supersport  WM/CM',
        shortNameFr: 'FIM Supersport  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 2400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 900,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-endurance-team-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Team  WM/CM',
    commonNameEn: 'FIM Endurance Team  WM/CM',
    commonNameFr: 'FIM Endurance Team  WM/CM',
    importName: 'Road Racing FIM Endurance Team  WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 679,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Team  WM/CM',
        nameEn: 'FIM Endurance Team  WM/CM',
        nameFr: 'FIM Endurance Team  WM/CM',
        shortNameDe: 'FIM Endurance Team  WM/CM',
        shortNameEn: 'FIM Endurance Team  WM/CM',
        shortNameFr: 'FIM Endurance Team  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-endurance-rider-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Rider  WM/CM',
    commonNameEn: 'FIM Endurance Rider  WM/CM',
    commonNameFr: 'FIM Endurance Rider  WM/CM',
    importName: 'Road Racing FIM Endurance Rider  WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 680,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Rider  WM/CM',
        nameEn: 'FIM Endurance Rider  WM/CM',
        nameFr: 'FIM Endurance Rider  WM/CM',
        shortNameDe: 'FIM Endurance Rider  WM/CM',
        shortNameEn: 'FIM Endurance Rider  WM/CM',
        shortNameFr: 'FIM Endurance Rider  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1050, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 550,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-sidecar-pilote-wm-cm',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Sidecar Pilote WM/CM',
    commonNameEn: 'FIM Sidecar Pilote WM/CM',
    commonNameFr: 'FIM Sidecar Pilote WM/CM',
    importName: 'Road Racing FIM Sidecar Pilote WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 681,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Pilote WM/CM',
        nameEn: 'FIM Sidecar Pilote WM/CM',
        nameFr: 'FIM Sidecar Pilote WM/CM',
        shortNameDe: 'FIM Sidecar Pilote WM/CM',
        shortNameEn: 'FIM Sidecar Pilote WM/CM',
        shortNameFr: 'FIM Sidecar Pilote WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 850, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 350,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-sidecar-passager-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Sidecar Passager WM/CM',
    commonNameEn: 'FIM Sidecar Passager WM/CM',
    commonNameFr: 'FIM Sidecar Passager WM/CM',
    importName: 'Road Racing FIM Sidecar Passager WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 682,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Passager WM/CM',
        nameEn: 'FIM Sidecar Passager WM/CM',
        nameFr: 'FIM Sidecar Passager WM/CM',
        shortNameDe: 'FIM Sidecar Passager WM/CM',
        shortNameEn: 'FIM Sidecar Passager WM/CM',
        shortNameFr: 'FIM Sidecar Passager WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-moto-gp-rookies-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MotoGP Rookies Cup',
    commonNameEn: 'FIM MotoGP Rookies Cup',
    commonNameFr: 'FIM MotoGP Rookies Cup',
    importName: 'Road Racing FIM MotoGP Rookies Cup',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 683,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MotoGP Rookies Cup',
        nameEn: 'FIM MotoGP Rookies Cup',
        nameFr: 'FIM MotoGP Rookies Cup',
        shortNameDe: 'FIM MotoGP Rookies Cup',
        shortNameEn: 'FIM MotoGP Rookies Cup',
        shortNameFr: 'FIM MotoGP Rookies Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-cev-moto-3-junior-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM CEV Moto3 Junior WM / CM',
    commonNameEn: 'FIM CEV Moto3 Junior WM / CM',
    commonNameFr: 'FIM CEV Moto3 Junior WM / CM',
    importName: 'Road Racing FIM CEV Moto3 Junior WM / CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 684,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM CEV Moto3 Junior WM / CM',
        nameEn: 'FIM CEV Moto3 Junior WM / CM',
        nameFr: 'FIM CEV Moto3 Junior WM / CM',
        shortNameDe: 'FIM CEV Moto3 Junior WM / CM',
        shortNameEn: 'FIM CEV Moto3 Junior WM / CM',
        shortNameFr: 'FIM CEV Moto3 Junior WM / CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-junior-gp-moto-2-european-championship',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM JuniorGP Moto2 European Championship',
    commonNameEn: 'FIM JuniorGP Moto2 European Championship',
    commonNameFr: 'FIM JuniorGP Moto2 European Championship',
    importName: 'Road Racing FIM JuniorGP Moto2 European Championship',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 685,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM JuniorGP Moto2 European Championship',
        nameEn: 'FIM JuniorGP Moto2 European Championship',
        nameFr: 'FIM JuniorGP Moto2 European Championship',
        shortNameDe: 'FIM JuniorGP Moto2 European Championship',
        shortNameEn: 'FIM JuniorGP Moto2 European Championship',
        shortNameFr: 'FIM JuniorGP Moto2 European Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fime-road-racing',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Road Racing EM / CE',
    commonNameEn: 'FIME Road Racing EM / CE',
    commonNameFr: 'FIME Road Racing EM / CE',
    importName: 'Road Racing FIME Road Racing',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 686,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Road Racing EM / CE',
        nameEn: 'FIME Road Racing EM / CE',
        nameFr: 'FIME Road Racing EM / CE',
        shortNameDe: 'FIME Road Racing EM / CE',
        shortNameEn: 'FIME Road Racing EM / CE',
        shortNameFr: 'FIME Road Racing EM / CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Genaue Kategorie bekanntgeben',
        hintEn: 'Appearable category',
        hintFr: "Catégorie d'apparence",
        advertiseSamInsurance: false,
        needsCommentDe: 'genaue Kategorie bekanntgeben',
        needsCommentFr: 'State exact category',
        needsCommentEn: "Catégorie d'apparence",
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fime-promosport-mini-rr-pocketbike',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Promosport (Mini RR, Pocketbike)',
    commonNameEn: 'FIME Promosport (Mini RR, Pocketbike)',
    commonNameFr: 'FIME Promosport (Mini RR, Pocketbike)',
    importName: 'Road Racing FIME Promosport (Mini RR, Pocketbike)',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 687,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Promosport (Mini RR, Pocketbike)',
        nameEn: 'FIME Promosport (Mini RR, Pocketbike)',
        nameFr: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameDe: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameEn: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameFr: 'FIME Promosport (Mini RR, Pocketbike)',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fime-northern-talent-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Northern Talent Cup',
    commonNameEn: 'FIME Northern Talent Cup',
    commonNameFr: 'FIME Northern Talent Cup',
    importName: 'Road Racing FIME Northern Talent Cup',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 688,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Northern Talent Cup',
        nameEn: 'FIME Northern Talent Cup',
        nameFr: 'FIME Northern Talent Cup',
        shortNameDe: 'FIME Northern Talent Cup',
        shortNameEn: 'FIME Northern Talent Cup',
        shortNameFr: 'FIME Northern Talent Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-supersport-600',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Supersport 600',
    commonNameEn: 'Inter Supersport 600',
    commonNameFr: 'Inter Supersport 600',
    importName: 'Road Racing Inter Supersport 600',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 689,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Supersport 600',
        nameEn: 'Inter Supersport 600',
        nameFr: 'Inter Supersport 600',
        shortNameDe: 'Inter Supersport 600',
        shortNameEn: 'Inter Supersport 600',
        shortNameFr: 'Inter Supersport 600',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-supersport-300',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Supersport 300',
    commonNameEn: 'Inter Supersport 300',
    commonNameFr: 'Inter Supersport 300',
    importName: 'Road Racing Inter Supersport 300',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 690,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Supersport 300',
        nameEn: 'Inter Supersport 300',
        nameFr: 'Inter Supersport 300',
        shortNameDe: 'Inter Supersport 300',
        shortNameEn: 'Inter Supersport 300',
        shortNameFr: 'Inter Supersport 300',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-superstock-600',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Superstock 600',
    commonNameEn: 'Inter Superstock 600',
    commonNameFr: 'Inter Superstock 600',
    importName: 'Road Racing Inter Superstock 600',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 588,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Superstock 600',
        nameEn: 'Inter Superstock 600',
        nameFr: 'Inter Superstock 600',
        shortNameDe: 'Inter Superstock 600',
        shortNameEn: 'Inter Superstock 600',
        shortNameFr: 'Inter Superstock 600',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-superstock-1000',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Superstock 1000',
    commonNameEn: 'Inter Superstock 1000',
    commonNameFr: 'Inter Superstock 1000',
    importName: 'Road Racing Inter Superstock 1000',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 589,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Superstock 1000',
        nameEn: 'Inter Superstock 1000',
        nameFr: 'Inter Superstock 1000',
        shortNameDe: 'Inter Superstock 1000',
        shortNameEn: 'Inter Superstock 1000',
        shortNameFr: 'Inter Superstock 1000',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-superbike',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Superbike',
    commonNameEn: 'Inter Superbike',
    commonNameFr: 'Inter Superbike',
    importName: 'Road Racing Inter Superbike',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 691,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Superbike',
        nameEn: 'Inter Superbike',
        nameFr: 'Inter Superbike',
        shortNameDe: 'Inter Superbike',
        shortNameEn: 'Inter Superbike',
        shortNameFr: 'Inter Superbike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-yamaha-r-3-b-lu-c-ru-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Yamaha R3 bLU cRU Cup',
    commonNameEn: 'Inter Yamaha R3 bLU cRU Cup',
    commonNameFr: 'Inter Yamaha R3 bLU cRU Cup',
    importName: 'Road Racing Inter Yamaha R3 bLU cRU Cup',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 692,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Yamaha R3 bLU cRU Cup',
        nameEn: 'Inter Yamaha R3 bLU cRU Cup',
        nameFr: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameDe: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameEn: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameFr: 'Inter Yamaha R3 bLU cRU Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-250-cc',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter 250cc',
    commonNameEn: 'Inter 250cc',
    commonNameFr: 'Inter 250cc',
    importName: 'Road Racing Inter 250cc',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 693,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter 250cc',
        nameEn: 'Inter 250cc',
        nameFr: 'Inter 250cc',
        shortNameDe: 'Inter 250cc',
        shortNameEn: 'Inter 250cc',
        shortNameFr: 'Inter 250cc',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-125-cc',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter 125cc',
    commonNameEn: 'Inter 125cc',
    commonNameFr: 'Inter 125cc',
    importName: 'Road Racing Inter 125cc',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 694,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter 125cc',
        nameEn: 'Inter 125cc',
        nameFr: 'Inter 125cc',
        shortNameDe: 'Inter 125cc',
        shortNameEn: 'Inter 125cc',
        shortNameFr: 'Inter 125cc',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, to: '2005-01-01', licenseType: 'international' },
          { association: 'fms', value: 550, from: '2004-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-sidecar-pilot',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Sidecar Pilot',
    commonNameEn: 'Inter Sidecar Pilot',
    commonNameFr: 'Inter Sidecar Pilot',
    importName: 'Road Racing Inter Sidecar Pilot',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 695,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Sidecar Pilot',
        nameEn: 'Inter Sidecar Pilot',
        nameFr: 'Inter Sidecar Pilot',
        shortNameDe: 'Inter Sidecar Pilot',
        shortNameEn: 'Inter Sidecar Pilot',
        shortNameFr: 'Inter Sidecar Pilot',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-sidecar-passager',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Sidecar Passager',
    commonNameEn: 'Inter Sidecar Passager',
    commonNameFr: 'Inter Sidecar Passager',
    importName: 'Road Racing Inter Sidecar Passager',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 696,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Sidecar Passager',
        nameEn: 'Inter Sidecar Passager',
        nameFr: 'Inter Sidecar Passager',
        shortNameDe: 'Inter Sidecar Passager',
        shortNameEn: 'Inter Sidecar Passager',
        shortNameFr: 'Inter Sidecar Passager',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 450, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-mini-road-racing',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Mini Road Racing',
    commonNameEn: 'Inter Mini Road Racing',
    commonNameFr: 'Inter Mini Road Racing',
    importName: 'Road Racing Inter Mini Road Racing',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 697,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Mini Road Racing',
        nameEn: 'Inter Mini Road Racing',
        nameFr: 'Inter Mini Road Racing',
        shortNameDe: 'Inter Mini Road Racing',
        shortNameEn: 'Inter Mini Road Racing',
        shortNameFr: 'Inter Mini Road Racing',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-endurance',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Endurance',
    commonNameEn: 'Inter Endurance',
    commonNameFr: 'Inter Endurance',
    importName: 'Road Racing Inter Endurance',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 698,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Endurance',
        nameEn: 'Inter Endurance',
        nameFr: 'Inter Endurance',
        shortNameDe: 'Inter Endurance',
        shortNameEn: 'Inter Endurance',
        shortNameFr: 'Inter Endurance',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-european-talent-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'European Talent Cup',
    commonNameEn: 'European Talent Cup',
    commonNameFr: 'European Talent Cup',
    importName: 'Road Racing European Talent Cup',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 699,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'European Talent Cup',
        nameEn: 'European Talent Cup',
        nameFr: 'European Talent Cup',
        shortNameDe: 'European Talent Cup',
        shortNameEn: 'European Talent Cup',
        shortNameFr: 'European Talent Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-scooter',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Scooter',
    commonNameEn: 'Inter Scooter',
    commonNameFr: 'Inter Scooter',
    importName: 'Road Racing Inter Scooter',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 700,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Scooter',
        nameEn: 'Inter Scooter',
        nameFr: 'Inter Scooter',
        shortNameDe: 'Inter Scooter',
        shortNameEn: 'Inter Scooter',
        shortNameFr: 'Inter Scooter',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-inter-pitbike',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Pitbike',
    commonNameEn: 'Inter Pitbike',
    commonNameFr: 'Inter Pitbike',
    importName: 'Road Racing Inter Pitbike',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 701,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Pitbike',
        nameEn: 'Inter Pitbike',
        nameFr: 'Inter Pitbike',
        shortNameDe: 'Inter Pitbike',
        shortNameEn: 'Inter Pitbike',
        shortNameFr: 'Inter Pitbike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-dragster-fim-drag-bike-wold-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'dragster',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Drag Bike Wold Cup',
    commonNameEn: 'FIM Drag Bike Wold Cup',
    commonNameFr: 'FIM Drag Bike Wold Cup',
    importName: 'Dragster/Sprinter FIM Drag Bike Wold Cup',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 702,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Drag Bike Wold Cup',
        nameEn: 'FIM Drag Bike Wold Cup',
        nameFr: 'FIM Drag Bike Wold Cup',
        shortNameDe: 'FIM Drag Bike Wold Cup',
        shortNameEn: 'FIM Drag Bike Wold Cup',
        shortNameFr: 'FIM Drag Bike Wold Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 2400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 550,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-dragster-inter-drag-bike',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'dragster',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Drag Bike',
    commonNameEn: 'Inter Drag Bike',
    commonNameFr: 'Inter Drag Bike',
    importName: 'Dragster/Sprinter Inter Drag Bike',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 703,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Drag Bike',
        nameEn: 'Inter Drag Bike',
        nameFr: 'Inter Drag Bike',
        shortNameDe: 'Inter Drag Bike',
        shortNameEn: 'Inter Drag Bike',
        shortNameFr: 'Inter Drag Bike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-ice-race-fim-ice-race-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Ice-Race WM/CM',
    commonNameEn: 'FIM Ice-Race WM/CM',
    commonNameFr: 'FIM Ice-Race WM/CM',
    importName: 'Ice-Race FIM Ice-Race WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 704,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Ice-Race WM/CM',
        nameEn: 'FIM Ice-Race WM/CM',
        nameFr: 'FIM Ice-Race WM/CM',
        shortNameDe: 'FIM Ice-Race WM/CM',
        shortNameEn: 'FIM Ice-Race WM/CM',
        shortNameFr: 'FIM Ice-Race WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 450,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-ice-race-fim-eu-ice-race-em-ce',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM EU Ice-Race EM/CE',
    commonNameEn: 'FIM EU Ice-Race EM/CE',
    commonNameFr: 'FIM EU Ice-Race EM/CE',
    importName: 'Ice-Race FIM EU Ice-Race EM/CE',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 705,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM EU Ice-Race EM/CE',
        nameEn: 'FIM EU Ice-Race EM/CE',
        nameFr: 'FIM EU Ice-Race EM/CE',
        shortNameDe: 'FIM EU Ice-Race EM/CE',
        shortNameEn: 'FIM EU Ice-Race EM/CE',
        shortNameFr: 'FIM EU Ice-Race EM/CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1050, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 200,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-ice-race-inter-ice-race',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Ice-Race',
    commonNameEn: 'Inter Ice-Race',
    commonNameFr: 'Inter Ice-Race',
    importName: 'Ice-Race Inter Ice-Race',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 706,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Ice-Race',
        nameEn: 'Inter Ice-Race',
        nameFr: 'Inter Ice-Race',
        shortNameDe: 'Inter Ice-Race',
        shortNameEn: 'Inter Ice-Race',
        shortNameFr: 'Inter Ice-Race',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-fim-snx-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM SNX WM/CM',
    commonNameEn: 'FIM SNX WM/CM',
    commonNameFr: 'FIM SNX WM/CM',
    importName: 'Ice-Race FIM SNX WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 707,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX WM/CM',
        nameEn: 'FIM SNX WM/CM',
        nameFr: 'FIM SNX WM/CM',
        shortNameDe: 'FIM SNX WM/CM',
        shortNameEn: 'FIM SNX WM/CM',
        shortNameFr: 'FIM SNX WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 450,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-fim-snx-wm-cm-women',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX WM/CM Women',
    commonNameEn: 'FIM SNX WM/CM Women',
    commonNameFr: 'FIM SNX WM/CM Women',
    importName: 'Ice-Race FIM SNX WM/CM Women',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 708,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX WM/CM Women',
        nameEn: 'FIM SNX WM/CM Women',
        nameFr: 'FIM SNX WM/CM Women',
        shortNameDe: 'FIM SNX WM/CM Women',
        shortNameEn: 'FIM SNX WM/CM Women',
        shortNameFr: 'FIM SNX WM/CM Women',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: false,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-fime-snx-em-ce',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME SNX EM/CE',
    commonNameEn: 'FIME SNX EM/CE',
    commonNameFr: 'FIME SNX EM/CE',
    importName: 'SNX FIME SNX EM/CE',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 709,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME SNX EM/CE',
        nameEn: 'FIME SNX EM/CE',
        nameFr: 'FIME SNX EM/CE',
        shortNameDe: 'FIME SNX EM/CE',
        shortNameEn: 'FIME SNX EM/CE',
        shortNameFr: 'FIME SNX EM/CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-inter-snx',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter SNX',
    commonNameEn: 'Inter SNX',
    commonNameFr: 'Inter SNX',
    importName: 'SNX Inter SNX',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 710,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter SNX',
        nameEn: 'Inter SNX',
        nameFr: 'Inter SNX',
        shortNameDe: 'Inter SNX',
        shortNameEn: 'Inter SNX',
        shortNameFr: 'Inter SNX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 350, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-elite-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Elite Open',
    commonNameEn: 'Elite Open',
    commonNameFr: 'Elite Open',
    importName: 'SNX Elite Open',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 711,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Elite Open',
        nameEn: 'Elite Open',
        nameFr: 'Elite Open',
        shortNameDe: 'Elite Open',
        shortNameEn: 'Elite Open',
        shortNameFr: 'Elite Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-amateur-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Amateur Open',
    commonNameEn: 'Amateur Open',
    commonNameFr: 'Amateur Open',
    importName: 'SNX Amateur Open',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 712,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Amateur Open',
        nameEn: 'Amateur Open',
        nameFr: 'Amateur Open',
        shortNameDe: 'Amateur Open',
        shortNameEn: 'Amateur Open',
        shortNameFr: 'Amateur Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-senior-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Senior Open',
    commonNameEn: 'Senior Open',
    commonNameFr: 'Senior Open',
    importName: 'SNX Senior Open',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 713,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Senior Open',
        nameEn: 'Senior Open',
        nameFr: 'Senior Open',
        shortNameDe: 'Senior Open',
        shortNameEn: 'Senior Open',
        shortNameFr: 'Senior Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-damen-open',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Damen Open',
    commonNameEn: 'Damen Open',
    commonNameFr: 'Damen Open',
    importName: 'SNX Damen Open',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 714,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Damen Open',
        nameEn: 'Damen Open',
        nameFr: 'Damen Open',
        shortNameDe: 'Damen Open',
        shortNameEn: 'Damen Open',
        shortNameFr: 'Damen Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-track-fim-track-racing-world-championship',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Track Racing World Championship',
    commonNameEn: 'FIM Track Racing World Championship',
    commonNameFr: 'FIM Track Racing World Championship',
    importName: 'Track FIM Track Racing World Championship',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 715,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Track Racing World Championship',
        nameEn: 'FIM Track Racing World Championship',
        nameFr: 'FIM Track Racing World Championship',
        shortNameDe: 'FIM Track Racing World Championship',
        shortNameEn: 'FIM Track Racing World Championship',
        shortNameFr: 'FIM Track Racing World Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 800,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-track-fim-flat-track-world-championship',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Flat Track World Championship',
    commonNameEn: 'FIM Flat Track World Championship',
    commonNameFr: 'FIM Flat Track World Championship',
    importName: 'Track FIM Flat Track World Championship',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 716,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Flat Track World Championship',
        nameEn: 'FIM Flat Track World Championship',
        nameFr: 'FIM Flat Track World Championship',
        shortNameDe: 'FIM Flat Track World Championship',
        shortNameEn: 'FIM Flat Track World Championship',
        shortNameFr: 'FIM Flat Track World Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 700, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 200,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-track-fime-track-racing',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Track Racing',
    commonNameEn: 'FIME Track Racing',
    commonNameFr: 'FIME Track Racing',
    importName: 'Track FIME Track Racing',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 717,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Track Racing',
        nameEn: 'FIME Track Racing',
        nameFr: 'FIME Track Racing',
        shortNameDe: 'FIME Track Racing',
        shortNameEn: 'FIME Track Racing',
        shortNameFr: 'FIME Track Racing',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-track-fime-speedway',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Speedway',
    commonNameEn: 'FIME Speedway',
    commonNameFr: 'FIME Speedway',
    importName: 'Track FIME Speedway',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 718,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Speedway',
        nameEn: 'FIME Speedway',
        nameFr: 'FIME Speedway',
        shortNameDe: 'FIME Speedway',
        shortNameEn: 'FIME Speedway',
        shortNameFr: 'FIME Speedway',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-track-inter-speedway-track',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Speedway/Track',
    commonNameEn: 'Inter Speedway/Track',
    commonNameFr: 'Inter Speedway/Track',
    importName: 'Track Inter Speedway/Track',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 719,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Speedway/Track',
        nameEn: 'Inter Speedway/Track',
        nameFr: 'Inter Speedway/Track',
        shortNameDe: 'Inter Speedway/Track',
        shortNameEn: 'Inter Speedway/Track',
        shortNameFr: 'Inter Speedway/Track',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-other-fms-fun-sport',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Fun Sport',
    commonNameEn: 'Fun Sport',
    commonNameFr: 'Fun Sport',
    importName: 'Fun Sport',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 720,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Fun Sport',
        nameEn: 'Fun Sport',
        nameFr: 'Fun Sport',
        shortNameDe: 'Fun Sport',
        shortNameEn: 'Fun Sport',
        shortNameFr: 'Fun Sport',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-other-fms-inter-one-event-all-disciplines',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter One Event all disciplines',
    commonNameEn: 'Inter One Event all disciplines',
    commonNameFr: 'Inter One Event all disciplines',
    importName: 'Inter One Event all disciplines',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 721,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter One Event all disciplines',
        nameEn: 'Inter One Event all disciplines',
        nameFr: 'Inter One Event all disciplines',
        shortNameDe: 'Inter One Event all disciplines',
        shortNameEn: 'Inter One Event all disciplines',
        shortNameFr: 'Inter One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-other-fms-fime-one-event-all-disciplines',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME One Event all disciplines',
    commonNameEn: 'FIME One Event all disciplines',
    commonNameFr: 'FIME One Event all disciplines',
    importName: 'FIME One Event all disciplines',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 722,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME One Event all disciplines',
        nameEn: 'FIME One Event all disciplines',
        nameFr: 'FIME One Event all disciplines',
        shortNameDe: 'FIME One Event all disciplines',
        shortNameEn: 'FIME One Event all disciplines',
        shortNameFr: 'FIME One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 185, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 95,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-other-fms-fime-promosport-one-event-all-disciplines',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Promosport One Event all disciplines',
    commonNameEn: 'FIME Promosport One Event all disciplines',
    commonNameFr: 'FIME Promosport One Event all disciplines',
    importName: 'FIME Promosport One Event all disciplines',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 723,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Promosport One Event all disciplines',
        nameEn: 'FIME Promosport One Event all disciplines',
        nameFr: 'FIME Promosport One Event all disciplines',
        shortNameDe: 'FIME Promosport One Event all disciplines',
        shortNameEn: 'FIME Promosport One Event all disciplines',
        shortNameFr: 'FIME Promosport One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 150, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 60,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-superbike-supersport-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Superbike/Supersport One Event WM/CM',
    commonNameEn: 'FIM Superbike/Supersport One Event WM/CM',
    commonNameFr: 'FIM Superbike/Supersport One Event WM/CM',
    importName: 'FIM Superbike/Supersport One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 724,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Superbike/Supersport One Event WM/CM',
        nameEn: 'FIM Superbike/Supersport One Event WM/CM',
        nameFr: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameDe: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameEn: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameFr: 'FIM Superbike/Supersport One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 720, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 630,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Kategorie, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, category, date and location announced',
        hintFr: 'Nom de course, catégorie, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Kategorie, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, catégorie, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-endurance-rider-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Rider One Event WM/CM',
    commonNameEn: 'FIM Endurance Rider One Event WM/CM',
    commonNameFr: 'FIM Endurance Rider One Event WM/CM',
    importName: 'FIM Endurance Rider One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 725,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Rider One Event WM/CM',
        nameEn: 'FIM Endurance Rider One Event WM/CM',
        nameFr: 'FIM Endurance Rider One Event WM/CM',
        shortNameDe: 'FIM Endurance Rider One Event WM/CM',
        shortNameEn: 'FIM Endurance Rider One Event WM/CM',
        shortNameFr: 'FIM Endurance Rider One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 210,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-mxgp-mx-2-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MXGP/ MX2 One Event WM/CM',
    commonNameEn: 'FIM MXGP/ MX2 One Event WM/CM',
    commonNameFr: 'FIM MXGP/ MX2 One Event WM/CM',
    importName: 'FIM MXGP/ MX2 One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 726,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MXGP/ MX2 One Event WM/CM',
        nameEn: 'FIM MXGP/ MX2 One Event WM/CM',
        nameFr: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameDe: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameEn: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameFr: 'FIM MXGP/ MX2 One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 410,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-mx-junior-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Junior One Event WM/CM',
    commonNameEn: 'FIM MX Junior One Event WM/CM',
    commonNameFr: 'FIM MX Junior One Event WM/CM',
    importName: 'FIM MX Junior One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 727,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Junior One Event WM/CM',
        nameEn: 'FIM MX Junior One Event WM/CM',
        nameFr: 'FIM MX Junior One Event WM/CM',
        shortNameDe: 'FIM MX Junior One Event WM/CM',
        shortNameEn: 'FIM MX Junior One Event WM/CM',
        shortNameFr: 'FIM MX Junior One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 210, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 120,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-wmx-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM WMX One Event WM/CM',
    commonNameEn: 'FIM WMX One Event WM/CM',
    commonNameFr: 'FIM WMX One Event WM/CM',
    importName: 'FIM WMX One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 728,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM WMX One Event WM/CM',
        nameEn: 'FIM WMX One Event WM/CM',
        nameFr: 'FIM WMX One Event WM/CM',
        shortNameDe: 'FIM WMX One Event WM/CM',
        shortNameEn: 'FIM WMX One Event WM/CM',
        shortNameFr: 'FIM WMX One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 140, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-mx-sidecar-pilot-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
    commonNameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
    commonNameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
    importName: 'FIM MX Sidecar Pilot One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 729,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
        nameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
        nameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-motocross-fim-mx-sidecar-passenger-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
    commonNameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
    commonNameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
    importName: 'FIM MX Sidecar Passenger One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 730,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
        nameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
        nameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 50, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-supermoto-fim-supermoto-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Supermoto One Event WM/CM',
    commonNameEn: 'FIM Supermoto One Event WM/CM',
    commonNameFr: 'FIM Supermoto One Event WM/CM',
    importName: 'FIM Supermoto One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 731,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supermoto One Event WM/CM',
        nameEn: 'FIM Supermoto One Event WM/CM',
        nameFr: 'FIM Supermoto One Event WM/CM',
        shortNameDe: 'FIM Supermoto One Event WM/CM',
        shortNameEn: 'FIM Supermoto One Event WM/CM',
        shortNameFr: 'FIM Supermoto One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 210,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-fim-snx-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX One Event WM/CM',
    commonNameEn: 'FIM SNX One Event WM/CM',
    commonNameFr: 'FIM SNX One Event WM/CM',
    importName: 'FIM SNX One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 732,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX One Event WM/CM',
        nameEn: 'FIM SNX One Event WM/CM',
        nameFr: 'FIM SNX One Event WM/CM',
        shortNameDe: 'FIM SNX One Event WM/CM',
        shortNameEn: 'FIM SNX One Event WM/CM',
        shortNameFr: 'FIM SNX One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 310,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-snx-fim-snx-women-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX Women One Event WM/CM',
    commonNameEn: 'FIM SNX Women One Event WM/CM',
    commonNameFr: 'FIM SNX Women One Event WM/CM',
    importName: 'FIM SNX Women One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 733,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX Women One Event WM/CM',
        nameEn: 'FIM SNX Women One Event WM/CM',
        nameFr: 'FIM SNX Women One Event WM/CM',
        shortNameDe: 'FIM SNX Women One Event WM/CM',
        shortNameEn: 'FIM SNX Women One Event WM/CM',
        shortNameFr: 'FIM SNX Women One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 190, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fim-trial-trial-de-nation-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
    commonNameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
    commonNameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
    importName: 'FIM Trial, Trial de Nation One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 734,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
        nameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
        nameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 210,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fime-trial-assistant-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial Assistant One Event',
    commonNameEn: 'FIME Trial Assistant One Event',
    commonNameFr: 'FIME Trial Assistant One Event',
    importName: 'Trial FIME Trial Assistant One Event',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 735,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial Assistant One Event',
        nameEn: 'FIME Trial Assistant One Event',
        nameFr: 'FIME Trial Assistant One Event',
        shortNameDe: 'FIME Trial Assistant One Event',
        shortNameEn: 'FIME Trial Assistant One Event',
        shortNameFr: 'FIME Trial Assistant One Event',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 50, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fim-trial-assistent-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Assistent One Event WM/CM',
    commonNameEn: 'FIM Trial Assistent One Event WM/CM',
    commonNameFr: 'FIM Trial Assistent One Event WM/CM',
    importName: 'FIM Trial Assistent One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 736,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Assistent One Event WM/CM',
        nameEn: 'FIM Trial Assistent One Event WM/CM',
        nameFr: 'FIM Trial Assistent One Event WM/CM',
        shortNameDe: 'FIM Trial Assistent One Event WM/CM',
        shortNameEn: 'FIM Trial Assistent One Event WM/CM',
        shortNameFr: 'FIM Trial Assistent One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 140, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-enduro-isde-world-trophy-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    commonNameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    commonNameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    importName: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 737,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        nameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        nameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 210,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-isde-womens-clubs-team-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM ISDE Womens, Clubs Team One Event',
    commonNameEn: 'FIM ISDE Womens, Clubs Team One Event',
    commonNameFr: 'FIM ISDE Womens, Clubs Team One Event',
    importName: 'FIM ISDE Womens, Clubs Team One Event',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 738,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM ISDE Womens, Clubs Team One Event',
        nameEn: 'FIM ISDE Womens, Clubs Team One Event',
        nameFr: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameDe: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameEn: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameFr: 'FIM ISDE Womens, Clubs Team One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 250, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 160,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-enduro-vintage-world-cup-fim-enduro-vintage-trophy-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
    commonNameEn: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
    commonNameFr: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
    importName: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 780,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        nameEn: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        nameFr: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        shortNameDe: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        shortNameEn: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        shortNameFr: 'FIM Enduro Vintage World Cup / FIM Enduro Vintage Trophy One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 200, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-cross-country-rally-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Cross Country RallyGP One Event',
    commonNameEn: 'FIM Cross Country RallyGP One Event',
    commonNameFr: 'FIM Cross Country RallyGP One Event',
    importName: 'FIM Cross Country RallyGP One Event',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 739,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Cross Country RallyGP One Event',
        nameEn: 'FIM Cross Country RallyGP One Event',
        nameFr: 'FIM Cross Country RallyGP One Event',
        shortNameDe: 'FIM Cross Country RallyGP One Event',
        shortNameEn: 'FIM Cross Country RallyGP One Event',
        shortNameFr: 'FIM Cross Country RallyGP One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 740, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 650,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-cross-country-rally-raid-rally-2-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Cross Country Rally-Raid & Rally2 One Event',
    commonNameEn: 'FIM Cross Country Rally-Raid & Rally2 One Event',
    commonNameFr: 'FIM Cross Country Rally-Raid & Rally2 One Event',
    importName: 'FIM Cross Country Rally-Raid & Rally2 One Event',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 774,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        nameEn: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        nameFr: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        shortNameDe: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        shortNameEn: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        shortNameFr: 'FIM Cross Country Rally-Raid & Rally2 One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 560, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 470,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-enduro-fim-cross-country-rally-raid-rally-3-one-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Cross Country Rally-Raid & Rally3 One Event',
    commonNameEn: 'FIM Cross Country Rally-Raid & Rally3 One Event',
    commonNameFr: 'FIM Cross Country Rally-Raid & Rally3 One Event',
    importName: 'FIM Cross Country Rally-Raid & Rally3 One Event',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 775,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        nameEn: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        nameFr: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        shortNameDe: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        shortNameEn: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        shortNameFr: 'FIM Cross Country Rally-Raid & Rally3 One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 210, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 120,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-other-fms-fim-e-xplorer-world-cup-pne-event',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM E-Xplorer World Cup One Event',
    commonNameEn: 'FIM E-Xplorer World Cup One Event',
    commonNameFr: 'FIM E-Xplorer World Cup One Event',
    importName: 'FIM E-Xplorer World Cup One Event',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 776,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM E-Xplorer World Cup One Event',
        nameEn: 'FIM E-Xplorer World Cup One Event',
        nameFr: 'FIM E-Xplorer World Cup One Event',
        shortNameDe: 'FIM E-Xplorer World Cup One Event',
        shortNameEn: 'FIM E-Xplorer World Cup One Event',
        shortNameFr: 'FIM E-Xplorer World Cup One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 340, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 250,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-other-fms-fim-land-speed-world-record',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Land Speed World Record',
    commonNameEn: 'FIM Land Speed World Record',
    commonNameFr: 'FIM Land Speed World Record',
    importName: 'FIM Land Speed World Record',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 777,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Land Speed World Record',
        nameEn: 'FIM Land Speed World Record',
        nameFr: 'FIM Land Speed World Record',
        shortNameDe: 'FIM Land Speed World Record',
        shortNameEn: 'FIM Land Speed World Record',
        shortNameFr: 'FIM Land Speed World Record',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 340, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 250,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-road-racing-fim-junior-gp-one-event-wm-cm',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM JuniorGP One Event WM/CM',
    commonNameEn: 'FIM JuniorGP One Event WM/CM',
    commonNameFr: 'FIM JuniorGP One Event WM/CM',
    importName: 'FIM JuniorGP One Event WM/CM',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 778,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM JuniorGP One Event WM/CM',
        nameEn: 'FIM JuniorGP One Event WM/CM',
        nameFr: 'FIM JuniorGP One Event WM/CM',
        shortNameDe: 'FIM JuniorGP One Event WM/CM',
        shortNameEn: 'FIM JuniorGP One Event WM/CM',
        shortNameFr: 'FIM JuniorGP One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 210, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 120,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fim-trial-one-event-vintage-trophy',
    active: true,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial, One Event Vintage Trophy',
    commonNameEn: 'FIM Trial, One Event Vintage Trophy',
    commonNameFr: 'FIM Trial, One Event Vintage Trophy',
    importName: 'FIM Trial, One Event Vintage Trophy',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 779,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial, One Event Vintage Trophy',
        nameEn: 'FIM Trial, One Event Vintage Trophy',
        nameFr: 'FIM Trial, One Event Vintage Trophy',
        shortNameDe: 'FIM Trial, One Event Vintage Trophy',
        shortNameEn: 'FIM Trial, One Event Vintage Trophy',
        shortNameFr: 'FIM Trial, One Event Vintage Trophy',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 140, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2023-trial-fim-trial-women-wm-cm-one-event',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Women WM/CM One Event',
    commonNameEn: 'FIM Trial Women WM/CM One Event',
    commonNameFr: 'FIM Trial Women WM/CM One Event',
    importName: 'FIM Trial Women WM/CM One Event',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: '',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 781,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Women WM/CM One Event',
        nameEn: 'FIM Trial Women WM/CM One Event',
        nameFr: 'FIM Trial Women WM/CM One Event',
        shortNameDe: 'FIM Trial Women WM/CM One Event',
        shortNameEn: 'FIM Trial Women WM/CM One Event',
        shortNameFr: 'FIM Trial Women WM/CM One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 200, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 110,
        preferAsAdditionalLicense: true,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-automatique-50',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Automatique50',
    commonNameEn: 'Automatique50',
    commonNameFr: 'Automatique50',
    importName: 'Automatique50',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Automatique50',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 743,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Automatique50',
        nameEn: 'Automatique50',
        nameFr: 'Automatique50',
        shortNameDe: 'Auto50',
        shortNameEn: 'Auto50',
        shortNameFr: 'Auto50',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Automatique50',
        startListName: 'Automatique50',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-automatique-50-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Automatique50 (für Fahrer eines AFM-Klubs)',
    commonNameEn: 'Automatique50 (pilote inscrit dans un club AFM)',
    commonNameFr: 'Automatique50 (for members of an AFM club)',
    importName: 'Automatique50',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Automatique50',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 744,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Automatique50 (für Fahrer eines AFM-Klubs)',
        nameEn: 'Automatique50 (for members of an AFM club)',
        nameFr: 'Automatique50 (pilote inscrit dans un club AFM)',
        shortNameDe: 'Auto50 (AFM)',
        shortNameEn: 'Auto50 (AFM)',
        shortNameFr: 'Auto50 (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Automatique50',
        startListName: 'Automatique50',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs',
        priceHintEn: 'For members of an AFM club',
        priceHintFr: 'Pilote inscrit dans un Club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-kids-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Kids65',
    commonNameEn: 'Kids65',
    commonNameFr: 'Kids65',
    importName: 'Kids65',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Kids65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 745,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids65',
        nameEn: 'Kids65',
        nameFr: 'Kids65',
        shortNameDe: 'Kids65',
        shortNameEn: 'Kids65',
        shortNameFr: 'Kids65',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids65',
        startListName: 'Kids65',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '65 ccm, bis 12 Jahre Alt (Jahrgang 2010)',
        hintEn: '65 ccm, up to 12 years (born 2010)',
        hintFr: "65 ccm, jusqu'à 12 ans (né en 2010)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-kids-65-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Kids65 (für Fahrer eines AFM-Klubs)',
    commonNameEn: 'Kids65 (pilote inscrit dans un club AFM)',
    commonNameFr: 'Kids65 (for members of an AFM club)',
    importName: 'Kids65',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Kids65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 746,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids65 (für Fahrer eines AFM-Klubs)',
        nameEn: 'Kids65 (for members of an AFM club)',
        nameFr: 'Kids65 (pilote inscrit dans un club AFM)',
        shortNameDe: 'Kids65 (AFM)',
        shortNameEn: 'Kids65 (AFM)',
        shortNameFr: 'Kids65 (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids65',
        startListName: 'Kids65',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs',
        priceHintEn: 'For members of an AFM club',
        priceHintFr: 'Pilote inscrit dans un Club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '65 ccm, bis 12 Jahre Alt (Jahrgang 2010)',
        hintEn: '65 ccm, up to 12 years (born 2010)',
        hintFr: "65 ccm, jusqu'à 12 ans (né en 2010)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-kids-65-fms',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Kids65 (mit FMS Lizenz)',
    commonNameEn: 'Kids65 (pilote avec licence FMS)',
    commonNameFr: 'Kids65 (for FMS Rider)',
    importName: 'Kids65',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Kids65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 761,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids65 (mit FMS Lizenz)',
        nameEn: 'Kids65 (for FMS Rider)',
        nameFr: 'Kids65 (pilote avec licence FMS)',
        shortNameDe: 'Kids65 (AFM)',
        shortNameEn: 'Kids65 (AFM)',
        shortNameFr: 'Kids65 (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids65',
        startListName: 'Kids65',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 0, licenseType: 'national' }],
        priceHintDe: 'Für FMS Fahrer',
        priceHintEn: 'For FMS Rider',
        priceHintFr: 'Pilote avec licence FMS',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '65 ccm, bis 12 Jahre Alt (Jahrgang 2010)',
        hintEn: '65 ccm, up to 12 years (born 2010)',
        hintFr: "65 ccm, jusqu'à 12 ans (né en 2010)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mini-85',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Mini85',
    commonNameEn: 'Mini85',
    commonNameFr: 'Mini85',
    importName: 'Mini85',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Mini85',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 747,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Mini85',
        nameEn: 'Mini85',
        nameFr: 'Mini85',
        shortNameDe: 'Mini85',
        shortNameEn: 'Mini85',
        shortNameFr: 'Mini85',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mini85',
        startListName: 'Mini85',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '85 ccm 2t oder 150 ccm 4t, 15 Jahre Alt (Jahrgang 2007)',
        hintEn: '85 ccm 2t or 150 ccm 4t up to 15 years (born 2007)',
        hintFr: "85 ccm 2t ou 150 ccm 4t, jusqu'à 15 ans (né en 2007)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mini-85-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Mini85 (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
    commonNameEn: 'Mini85 (pilote inscrit dans un club AFM ou avec une licence FMS)',
    commonNameFr: 'Mini85 (for members of an AFM club or with a FMS license)',
    importName: 'Mini85',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Mini85',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 748,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Mini85 (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
        nameEn: 'Mini85 (for members of an AFM club or with a FMS license)',
        nameFr: 'Mini85 (pilote inscrit dans un club AFM ou avec une licence FMS)',
        shortNameDe: 'Mini85 (AFM)',
        shortNameEn: 'Mini85 (AFM)',
        shortNameFr: 'Mini85 (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mini85',
        startListName: 'Mini85',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz',
        priceHintEn: 'For members of an AFM club or with a FMS license',
        priceHintFr: 'Pilote inscrit dans un club AFM ou avec une licence FMS',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '85 ccm 2t oder 150 ccm 4t, 15 Jahre Alt (Jahrgang 2007)',
        hintEn: '85 ccm 2t or 150 ccm 4t up to 15 years (born 2007)',
        hintFr: "85 ccm 2t ou 150 ccm 4t, jusqu'à 15 ans (né en 2007)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-rookie',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Rookie',
    commonNameEn: 'Rookie',
    commonNameFr: 'Rookie',
    importName: 'Rookie',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Rookie',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 749,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Rookie',
        nameEn: 'Rookie',
        nameFr: 'Rookie',
        shortNameDe: 'Rookie',
        shortNameEn: 'Rookie',
        shortNameFr: 'Rookie',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Rookie',
        startListName: 'Rookie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab 250 4t',
        hintEn: 'Up to 250 ccm 4t',
        hintFr: 'Dès 250 4t',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-rookie-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Rookie (für Fahrer eines AFM-Klubs)',
    commonNameEn: 'Rookie (pilote inscrit dans un club AFM)',
    commonNameFr: 'Rookie (for members of an AFM club)',
    importName: 'Rookie',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Rookie',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 750,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Rookie (für Fahrer eines AFM-Klubs)',
        nameEn: 'Rookie (for members of an AFM club)',
        nameFr: 'Rookie (pilote inscrit dans un club AFM)',
        shortNameDe: 'Rookie (AFM)',
        shortNameEn: 'Rookie (AFM)',
        shortNameFr: 'Rookie (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Rookie',
        startListName: 'Rookie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs',
        priceHintEn: 'For members of an AFM club',
        priceHintFr: 'Pilote inscrit dans un Club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab 250 4t',
        hintEn: 'Up to 250 ccm 4t',
        hintFr: 'Dès 250 4t',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-girls',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX Girls',
    commonNameEn: 'MX Girls',
    commonNameFr: 'MX Girls',
    importName: 'MX Girls',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MX Girls',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 751,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Girls',
        nameEn: 'MX Girls',
        nameFr: 'MX Girls',
        shortNameDe: 'MX Girls',
        shortNameEn: 'MX Girls',
        shortNameFr: 'MX Girls',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Girls',
        startListName: 'MX Girls',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '125-150 2t oder 250 4t',
        hintEn: '125-150 2t or 250 4t',
        hintFr: '125-150 2t ou 250 4t',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-girls-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX Girls (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
    commonNameEn: 'MX Girls (pilote inscrit dans un club AFM ou avec une licence FMS)',
    commonNameFr: 'MX Girls (for members of an AFM club or with a FMS license)',
    importName: 'MX Girls',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MX Girls',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 752,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX Girls (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
        nameEn: 'MX Girls (for members of an AFM club or with a FMS license)',
        nameFr: 'MX Girls (pilote inscrit dans un club AFM ou avec une licence FMS)',
        shortNameDe: 'MX Girls (AFM)',
        shortNameEn: 'MX Girls (AFM)',
        shortNameFr: 'MX Girls (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX Girls',
        startListName: 'MX Girls',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz',
        priceHintEn: 'For members of an AFM club or with a FMS license',
        priceHintFr: 'Pilote inscrit dans un club AFM ou avec une licence FMS',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '125-150 2t oder 250 4t',
        hintEn: '125-150 2t or 250 4t',
        hintFr: '125-150 2t ou 250 4t',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-3-senior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX3 Senior',
    commonNameEn: 'MX3 Senior',
    commonNameFr: 'MX3 Senior',
    importName: 'MX3 Senior',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MX3 Senior',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 753,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX3 Senior',
        nameEn: 'MX3 Senior',
        nameFr: 'MX3 Senior',
        shortNameDe: 'MX3',
        shortNameEn: 'MX3',
        shortNameFr: 'MX3',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX3 Senior',
        startListName: 'MX3 Senior',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-3-senior-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX3 Senior (für Fahrer eines AFM-Klubs)',
    commonNameEn: 'MX3 Senior (pilote inscrit dans un club AFM)',
    commonNameFr: 'MX3 Senior (for members of an AFM club)',
    importName: 'MX3 Senior',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MX3 Senior',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 754,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX3 Senior (für Fahrer eines AFM-Klubs)',
        nameEn: 'MX3 Senior (for members of an AFM club)',
        nameFr: 'MX3 Senior (pilote inscrit dans un club AFM)',
        shortNameDe: 'MX3 (AFM)',
        shortNameEn: 'MX3 (AFM)',
        shortNameFr: 'MX3 (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX3 Senior',
        startListName: 'MX3 Senior',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs',
        priceHintEn: 'For members of an AFM club',
        priceHintFr: 'Pilote inscrit dans un Club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-125',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX125',
    commonNameEn: 'MX125',
    commonNameFr: 'MX125',
    importName: 'MX125',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MX125',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 755,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX125',
        nameEn: 'MX125',
        nameFr: 'MX125',
        shortNameDe: 'MX125',
        shortNameEn: 'MX125',
        shortNameFr: 'MX125',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX125',
        startListName: 'MX125',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '125-150 2t',
        hintEn: '125-150 2t',
        hintFr: '125-150 2t',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-125-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX125 (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
    commonNameEn: 'MX125 (pilote inscrit dans un club AFM ou avec une licence FMS)',
    commonNameFr: 'MX125 (for members of an AFM club or with a FMS license)',
    importName: 'MX125',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MX125',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 756,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX125 (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
        nameEn: 'MX125 (for members of an AFM club or with a FMS license)',
        nameFr: 'MX125 (pilote inscrit dans un club AFM ou avec une licence FMS)',
        shortNameDe: 'MX125 (AFM)',
        shortNameEn: 'MX125 (AFM)',
        shortNameFr: 'MX125 (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX125',
        startListName: 'MX125',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz',
        priceHintEn: 'For members of an AFM club or with a FMS license',
        priceHintFr: 'Pilote inscrit dans un club AFM ou avec une licence FMS',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '125-150 2t',
        hintEn: '125-150 2t',
        hintFr: '125-150 2t',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-250',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX250',
    commonNameEn: 'MX250',
    commonNameFr: 'MX250',
    importName: 'MX250',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MX250',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 757,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX250',
        nameEn: 'MX250',
        nameFr: 'MX250',
        shortNameDe: 'MX250',
        shortNameEn: 'MX250',
        shortNameFr: 'MX250',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX250',
        startListName: 'MX250',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '250 4t',
        hintEn: '250 4t',
        hintFr: '250 4t',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-250-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MX250 (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
    commonNameEn: 'MX250 (pilote inscrit dans un club AFM ou avec une licence FMS)',
    commonNameFr: 'MX250 (for members of an AFM club or with a FMS license)',
    importName: 'MX250',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MX250',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 758,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MX250 (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
        nameEn: 'MX250 (for members of an AFM club or with a FMS license)',
        nameFr: 'MX250 (pilote inscrit dans un club AFM ou avec une licence FMS)',
        shortNameDe: 'MX250 (AFM)',
        shortNameEn: 'MX250 (AFM)',
        shortNameFr: 'MX250 (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX250',
        startListName: 'MX250',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz',
        priceHintEn: 'For members of an AFM club or with a FMS license',
        priceHintFr: 'Pilote inscrit dans un club AFM ou avec une licence FMS',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '250 4t',
        hintEn: '250 4t',
        hintFr: '250 4t',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MXOpen',
    commonNameEn: 'MXOpen',
    commonNameFr: 'MXOpen',
    importName: 'MXOpen',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MXOpen',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 759,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MXOpen',
        nameEn: 'MXOpen',
        nameFr: 'MXOpen',
        shortNameDe: 'MXOpen',
        shortNameEn: 'MXOpen',
        shortNameFr: 'MXOpen',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MXOpen',
        startListName: 'MXOpen',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 140, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer die nicht AFM-Klub Mitglied sind',
        priceHintEn: 'For not members of an AFM club',
        priceHintFr: 'Pilote non inscrit dans un club AFM',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 140,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab 250 ccm',
        hintEn: 'From 250 ccm',
        hintFr: 'Dès 250 ccm',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'afm-2023-motocross-mx-open-c',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'MXOpen (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
    commonNameEn: 'MXOpen (pilote inscrit dans un club AFM ou avec une licence FMS)',
    commonNameFr: 'MXOpen (for members of an AFM club or with a FMS license)',
    importName: 'MXOpen',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'MXOpen',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 760,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'MXOpen (für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz )',
        nameEn: 'MXOpen (for members of an AFM club or with a FMS license)',
        nameFr: 'MXOpen (pilote inscrit dans un club AFM ou avec une licence FMS)',
        shortNameDe: 'MXOpen (AFM)',
        shortNameEn: 'MXOpen (AFM)',
        shortNameFr: 'MXOpen (AFM)',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MXOpen',
        startListName: 'MXOpen',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#dc1d08',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 90, licenseType: 'national' }],
        priceHintDe: 'Für Fahrer eines AFM-Klubs oder mit einer FMS Lizenz',
        priceHintEn: 'For members of an AFM club or with a FMS license',
        priceHintFr: 'Pilote inscrit dans un club AFM ou avec une licence FMS',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 90,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 10,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: 'Ab 250 ccm',
        hintEn: 'From 250 ccm',
        hintFr: 'Dès 250 ccm',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2023-motocross-scott-kids-cup-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Scott Kids Cup 65',
    commonNameEn: 'Scott Kids Cup 65',
    commonNameFr: 'Scott Kids Cup 65',
    importName: '',
    year: 2023,
    enlistWithoutLicense: false,
    startListName: 'Scott Kids Cup 65',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 771,
    associations: ['afm'],
    associationSpecificDetails: [
      {
        nameDe: 'Scott Kids Cup 65 (als Zusatz zu Kids 65)',
        nameEn: 'Scott Kids Cup 65 ( additionally to Kids 65)',
        nameFr: 'Scott Kids Cup 65 (en complément pour Kids 65)',
        shortNameDe: 'Scott Kids Cup 65',
        shortNameEn: 'Scott Kids Cup 65',
        shortNameFr: 'Scott Kids Cup 65',
        needsHealthCheck: [{ association: 'afm', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Scott Kids Cup 65',
        startListName: 'Scott Kids Cup 65',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'afm',
        prices: [{ association: 'afm', value: 0, licenseType: 'national' }],
        priceHintDe: 'Für Kids 65ccm, die am Scott Kids Cup teilnehmen',
        priceHintEn: 'For Kids 65 participating in the Scott Kids Cup 65',
        priceHintFr: 'Pour les Kids 65 participant à la Scott Kids Cup65',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2023-02-28T23:59:59.999Z',
        discount: 0,
        discountUntil: '2023-02-28T23:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sjmcc-2023-motocross-u-9',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'U9',
    commonNameEn: 'U9',
    commonNameFr: 'U9',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'U9 Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 740,
    associations: ['sjmcc'],
    associationSpecificDetails: [
      {
        nameDe: 'U9 Cup',
        nameEn: 'U9 Cup',
        nameFr: 'U9 Cup',
        shortNameDe: 'U9',
        shortNameEn: 'U9',
        shortNameFr: 'U9',
        needsHealthCheck: [{ association: 'sjmcc', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'U9 Cup',
        startListName: 'U9 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#ffffff',
        numberFixed: true,
        association: 'sjmcc',
        prices: [{ association: 'sjmcc', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '50ccm 2-Takt, Automaten / 110ccm 4-Takt, Elektro, 10/12 Zoll',
        hintEn: '50cc 2-stroke, machine / 110cc 4-stroke, electrical, 10/12 inches',
        hintFr: '50cc 2 temps, machine / 110cc à 4 coups, électrique, 10/12 pouces',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 40,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sjmcc-2023-motocross-u-12',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'U12',
    commonNameEn: 'U12',
    commonNameFr: 'U12',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'U12 Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 741,
    associations: ['sjmcc'],
    associationSpecificDetails: [
      {
        nameDe: 'U12 Cup',
        nameEn: 'U12 Cup',
        nameFr: 'U12 Cup',
        shortNameDe: 'U12',
        shortNameEn: 'U12',
        shortNameFr: 'U12',
        needsHealthCheck: [{ association: 'sjmcc', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'U12 Cup',
        startListName: 'U12 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#ffffff',
        numberFixed: true,
        association: 'sjmcc',
        prices: [{ association: 'sjmcc', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '65ccm, Getriebe, Elektromotoren, 12/14 Zoll Räde',
        hintEn: '65ccm, gearbox, electric motors, 12/14 inches',
        hintFr: '65ccm, boîte de vitesses, moteurs électriques, 12/14 pouces',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 40,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sjmcc-2023-motocross-u-16',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'U16',
    commonNameEn: 'U16',
    commonNameFr: 'U16',
    importName: '',
    year: 2023,
    enlistWithoutLicense: true,
    startListName: 'Backyard Racing U16 Cup',
    groups: { enabled: false, defaultMaxGroups: 1 },
    shortId: 742,
    associations: ['sjmcc'],
    associationSpecificDetails: [
      {
        nameDe: 'Backyard Racing U16 Cup',
        nameEn: 'Backyard Racing U16 Cup',
        nameFr: 'Backyard Racing U16 Cup',
        shortNameDe: 'U16',
        shortNameEn: 'U16',
        shortNameFr: 'U16',
        needsHealthCheck: [{ association: 'sjmcc', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Backyard Racing U16 Cup',
        startListName: 'Backyard Racing U16 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#ffffff',
        numberFixed: true,
        association: 'sjmcc',
        prices: [{ association: 'sjmcc', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        preferAsAdditionalLicense: false,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2023-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2023-01-31T22:59:59.999Z',
        hintDe: '85ccm 2-Takt / 150ccm 4-Takt, Getriebe',
        hintEn: '85cc 2-stroke / 150cc 4-stroke, gearbox',
        hintFr: '85cc 2-temps / 150cc 4-temps, boîte de vitesses',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        needsMembership: true,
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
] as const
