import { Grid, LinearProgress } from '@material-ui/core'
import { Formik, Form } from 'formik'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import {
  transponderSchema,
  TransponderStepFormFields,
  ensureOrderedTransponderIsSet,
  TransponderFormContents,
  toTransponder,
} from 'app/license/steps/transponder-form-fields'
import { UserQuery } from 'shared/db/db'
import { Transponder } from 'shared/models/transponder'

interface TransponderProps {
  initialValues: TransponderFormContents
  onSubmit: (data: Transponder) => Promise<void>
  submitLabel?: string
  onBack?: () => void
  autoHighlightSubmit?: true
  user: UserQuery
}

export function TransponderForm(props: TransponderProps) {
  const { autoHighlightSubmit, submitLabel, initialValues, onBack, onSubmit, user } = props

  ensureOrderedTransponderIsSet(initialValues)

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={transponderSchema(false)}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit(toTransponder(values))
        setSubmitting(false)
      }}
    >
      {({ submitForm, isSubmitting, values, dirty }) => (
        <Form>
          <TransponderStepFormFields values={values} prefix="" user={user} />
          <Grid container spacing={1}>
            {isSubmitting && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            <AccordionNavigationButtons
              isSubmitting={isSubmitting}
              onBack={onBack}
              submitForm={submitForm}
              nextLabel={submitLabel}
              autoHighlightSubmit={autoHighlightSubmit}
              dirty={dirty}
              spacingTop
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
