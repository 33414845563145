import { ReactNode } from 'react'
import { Route } from 'react-router-dom'
import { useTitle } from 'react-use'
import { UserQuery } from 'shared/db/db'
import { ErrorBoundary } from 'utils/error-boundary'

interface RouteWithErrorBoundaryAndTitleProps {
  user: UserQuery | undefined
  text: () => string
  path: string
  children: ReactNode
  exact?: boolean
}

export function RouteWithErrorBoundaryAndTitle(props: RouteWithErrorBoundaryAndTitleProps) {
  const { user, path, text, children, exact } = props

  return (
    <RouteWithErrorBoundary user={user} path={path} text={text} exact={exact}>
      {children}
    </RouteWithErrorBoundary>
  )
}

interface RouteWithErrorBoundaryProps {
  user: UserQuery | undefined
  text: () => string
  path: string
  children: ReactNode
  exact?: boolean
}

export function RouteWithErrorBoundary(props: RouteWithErrorBoundaryProps) {
  const { user, path, children, exact, text } = props

  return (
    <Route path={path} exact={exact}>
      <DocumentTitle text={text} />
      <ErrorBoundary user={user || anonymousUser}>{children}</ErrorBoundary>
    </Route>
  )
}

const anonymousUser = { uid: 'anonymous' }

function DocumentTitle(props: { text: () => string }) {
  const { text } = props
  useRacemanagerTitle(text())
  return null
}

export function useRacemanagerTitle(title: string) {
  const text = title ? `${title.trim()} - Racemanager` : 'Racemanager'
  useTitle(text)
}
