import { db } from 'app/db/frontend-db'
import {
  DownloadLicenseProps,
  drawAddress,
  drawAssociationAndCategory,
  drawHeader,
  drawImages,
  drawNumberPlate,
  drawQRCodeOnPDF,
  drawTopBackground,
  ensureFontsAreLoaded,
  filename,
  fontCache,
  fontSizeFooter,
  fontSizeNormal,
  licensePdfOptions,
  PDFProps,
  regularFontName,
  shouldDrawIssuedNumber,
  titleBoldFontName,
  titleRegularFontName,
  topSectionHeight,
  urlQRCodeWithDomain,
} from 'app/pages/licenses/pdf-license-service-common'
import { categoryOfAssociationRequired } from 'shared/data/categories-service'
import { ApprovedLicense, Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { openLicenseTasks } from 'shared/license/license-tasks'
import { todoMigrateAssociation } from 'shared/models/associations'
import { fullName } from 'shared/models/personal-data'
import { truthy } from 'shared/utils/array'
import { downloadPdf, PDFDoc } from 'utils/pdf/pdf'
import { usePdfPreview } from 'utils/pdf/pdf-preview'
import { Disp } from 'utils/react'

export function usePitLane2022Preview(setError: Disp<Error | null>, { license }: DownloadLicenseProps) {
  usePdfPreview(setError, licensePdfOptions('preview'), async (pdf) => {
    const documents = await db.loadDocuments(license)
    if (!documents) throw new Error('No documents provided')
    await ensureFontsAreLoaded()
    Object.entries(fontCache).forEach(([key, value]) => pdf.registerFont(key, value))
    await generatePitLanePdf(license, documents, pdf)
  })
}

export async function generatePitLane2022(license: ApprovedLicense) {
  await ensureFontsAreLoaded()

  const documents = await db.loadDocuments(license)
  if (!documents) throw new Error('No documents provided')
  await downloadPdf(licensePdfOptions(filename(license, documents, 'pitLanePass')), (pdf) => {
    Object.entries(fontCache).forEach(([key, value]) => pdf.registerFont(key, value))
    return generatePitLanePdf(license, documents, pdf)
  })
}

async function generatePitLanePdf(license: ApprovedLicense, documents: Documents, pdf: PDFDoc) {
  const association = todoMigrateAssociation(license.licenseAssociation)
  const category = categoryOfAssociationRequired(license.categoryId, association)

  pdf.font(titleRegularFontName)

  const tasks = openLicenseTasks({ license, documents })
  const pdfProps = { pdf, type: 'pitLanePass' as const, category, license, documents, tasks }
  drawTopBackground(pdf, category)
  await drawImages(pdfProps)
  drawHeader(pdfProps)
  drawNumberPlate(pdfProps)

  await drawQRCodeOnPDF(pdfProps, 0, 17)
  await drawQRUrl(pdfProps)

  pdf.fontSize(fontSizeNormal)
  pdf.font(titleBoldFontName).text('', undefined, topSectionHeight + 10)
  drawAssociationAndCategory(pdfProps)
  drawRiderNameAndNumber(pdfProps)

  pdf.fontSize(3).text('\n').fontSize(fontSizeNormal)
  drawNotValidForEntry(pdfProps)

  pdf.font(regularFontName)
  drawAddress(pdfProps)
}

async function drawQRUrl(props: PDFProps) {
  const { pdf } = props
  const url = await urlQRCodeWithDomain(props)
  pdf.font(regularFontName).fontSize(fontSizeFooter).text(url, 10, 254, { align: 'center' })
}

function drawRiderNameAndNumber(props: PDFProps) {
  const { pdf, documents, license } = props

  pdf
    .font(titleRegularFontName)
    .text(
      [fullName(documents.personalData), shouldDrawIssuedNumber(props) ? `#${license.issuedNumber}` : '']
        .filter(truthy)
        .join(', '),
      undefined,
      undefined
    )
}

function drawNotValidForEntry({ pdf }: PDFProps) {
  pdf.font(titleBoldFontName).text(t().licensePdf.notValidForEntry, 10, 256, { align: 'center' })
}
