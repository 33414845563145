import { downloadCsv } from 'app/export/csv'
import { downloadXlsx } from 'app/export/xlsx'
import { ApprovedLicenseWithContext, InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { ExportOptions } from 'shared/models/transponder-type'
import { exportInscriptionsToMylaps, exportLicensesToMylaps } from 'shared/my-laps/mylaps-export'

export async function downloadInscriptinsForMylapsXlsx(
  inscriptions: InscriptionWithContextAndSportEvent[],
  exportOptions: ExportOptions
) {
  const result = exportInscriptionsToMylaps(inscriptions, exportOptions)
  await downloadXlsx(result, 'mylaps')
}

export function downloadInscriptinsForMylapsCsv(
  inscriptions: InscriptionWithContextAndSportEvent[],
  exportOptions: ExportOptions
) {
  const result = exportInscriptionsToMylaps(inscriptions, exportOptions)
  downloadCsv(result, 'mylaps')
}

export async function downloadLicensesForMylaps(licenses: ApprovedLicenseWithContext[]) {
  const result = exportLicensesToMylaps(licenses)
  await downloadXlsx(result, 'mylaps')
}
