import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useRemainingInvalidPaymentsWithInfo } from 'app/db/db-hooks/financial-db-hooks'
import { db } from 'app/db/frontend-db'
import { samFields } from 'app/forms/fields'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { SearchOpenBills } from 'app/pages/admin/payments/bills'
import { DebitorDetails } from 'app/pages/admin/payments/debitor-details'
import { SearchableReferenceDetails } from 'app/pages/admin/payments/reference-details'
import { SearchLink } from 'app/pages/admin/payments/search-link'
import { Bill, InvalidPayment, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { pFormatDate } from 'shared/utils/date'
import { PreWithScroll } from 'utils/debug'
import { FriendlyError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { SearchQuery, useSearchQuery } from 'utils/router'
import { SectionList } from 'utils/section-list'

export function InvalidPayments({ admin }: { admin: UserQuery }) {
  const search = useSearchQuery()
  const { data, loading, error, loadingOrError } = useRemainingInvalidPaymentsWithInfo()

  return (
    <>
      <Loading loading={loading} />
      <FriendlyError error={error} />
      <Grid container spacing={2}>
        <Grid item xs={4} style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
          <Box px={1}>
            <Paper elevation={3}>
              <Box m={2} p={2}>
                <Typography component="h2" variant="h3">
                  {t().payments.invalidPayments}
                </Typography>
                {!loadingOrError && data.payments.length === 0 && (
                  <Typography>{t().payments.noInvalidPaymentsFound}</Typography>
                )}
              </Box>
            </Paper>
          </Box>
          {!loadingOrError &&
            data.payments.map((payment) => (
              <Box key={payment.id} px={1}>
                <Paper elevation={3}>
                  <Box m={2} p={2} pb={1}>
                    <AssignReferenceButton payment={payment} bills={data.bills} />
                    <Button fullWidth onClick={() => db.deleteInvalidPayment(payment)}>
                      {t().payments.deleteQrPayment}
                    </Button>
                    <Typography component="h3" variant="h4">
                      {t().payments.invalidPayment}
                    </Typography>
                    <InvalidPaymentDetails search={search} payment={payment} />
                  </Box>
                </Paper>
              </Box>
            ))}
        </Grid>
        <Grid item xs={8}>
          <Box p={2} style={{ overflowY: 'scroll', maxHeight: '80vh' }}>
            {!loadingOrError && <SearchOpenBills admin={admin} search={search} bills={data.bills} />}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

function InvalidPaymentDetails({ payment, search }: { payment: InvalidPayment; search: SearchQuery }) {
  return (
    <SectionList
      contents={[
        [t().reference, payment.reference],
        [
          t().referenceParts,
          <SearchableReferenceDetails key="" search={search} reference={payment.reference} />,
        ],
        [
          t().debitor,
          <>
            <DebitorDetails search={search} debitor={payment.debitor} />
            <DebitorDetails search={search} debitor={payment.ultimateDebitor} />
          </>,
        ],
        [t().datetime, payment.date],
        [
          t().amount.label,
          <SearchLink key="" search={search}>
            {payment.amount.toString()}
          </SearchLink>,
        ],
        [t().iban, payment.debitorIban || '-'],
        ['Details', <PreWithScroll key="d" values={payment} />],
        ['Raw', <PreWithScroll key="d" values={JSON.parse(payment.raw)} />],
      ]}
    />
  )
}

function AssignReferenceButton({ payment, bills }: { payment: InvalidPayment; bills: Bill[] }) {
  const dialog = useDialog()
  const fields = samFields().invalidPayment

  const billOptions = bills.map((bill) => ({
    id: bill.reference,
    name: `${bill.reference}, ${bill.paymentInfo.debitor.name}, ${bill.paymentInfo.debitor.zip}, ${
      bill.paymentInfo.debitor.city
    }, CHF ${bill.paymentInfo.amount}, ${pFormatDate(bill.date)}`,
  }))
  return (
    <>
      <Button fullWidth variant="contained" color="primary" onClick={() => dialog.open()}>
        Referenznummer zuweisen
      </Button>

      <Formik
        initialValues={{ bill: null } as { bill: { id: string; name: string } | null }}
        enableReinitialize={true}
        validationSchema={schema()}
        validateOnMount
        onSubmit={async (values, { setSubmitting }) => {
          const bill = bills.find((bill) => bill.reference === values.bill?.id)
          if (!bill) throw new Error('Bill must not be empty')
          await db.assignInvalidPayment({ payment, bill })
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, isValid, errors, touched }) => (
          <ConfirmDialog
            fullWidth
            title="Referenznummer zuweisen"
            dialog={dialog}
            buttonText="Referenznummer zuweisen"
            onConfirm={() => submitForm()}
            disabled={!isValid || isSubmitting}
          >
            <Form>{fields.bill.field({ billOptions, errors, touched })}</Form>
          </ConfirmDialog>
        )}
      </Formik>
    </>
  )
}

export function schema() {
  return Yup.object().defined().shape({ bill: samFields().invalidPayment.bill.validation })
}
