import { ResetPaidMemberFees } from 'app/pages/admin/payments/reset-paid-member-fees'
import { UploadPaidMemberFees } from 'app/pages/admin/payments/upload-paid-member-fees'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { routes } from 'shared/config/routes'
import { UserQuery } from 'shared/db/db'
import { RoutedButton } from 'utils/buttons/routed-button'

export function MemberFees(_props: { admin: UserQuery }) {
  return (
    <ElevatedBox title="SAM Mitgliederbeiträge">
      <UploadPaidMemberFees /> <ResetPaidMemberFees />{' '}
      <RoutedButton variant="outlined" to={routes.payments.to}>
        {routes.payments.text()}
      </RoutedButton>
    </ElevatedBox>
  )
}
