import { Box, Button, Collapse, Dialog, Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useAsync } from 'react-async-hook'
import { ButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { useDialog } from 'app/layouts/confirm-dialog'
import { CircularProgressWithLabel, LicenseTaskList } from 'app/pages/dashboard/overview-box'
import { DownloadLicenseButtons } from 'app/pages/licenses/download-license'
import { DownloadLicenseConfirmation } from 'app/pages/licenses/download-license-confirmation'
import { qrCodeDataURL } from 'app/pages/licenses/pdf-license-service-common'
import { routes } from 'shared/config/routes'
import {
  categoryById,
  categoryOfAssociationByLicense,
  fullCategoryShortName,
} from 'shared/data/categories-service'
import { nextLicenseYear, oldLicenseYear } from 'shared/data/license-config'
import { ApprovedLicense, Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { openLicenseTasks } from 'shared/license/license-tasks'
import { RoutedButton } from 'utils/buttons/routed-button'
import { useBoolean } from 'utils/use-boolean'

interface ApprovedLicensesBoxProps {
  approvedLicenses: ApprovedLicense[]
  documents: Documents
}

export function ApprovedLicensesBox({ approvedLicenses, documents }: ApprovedLicensesBoxProps) {
  const showPreviousYears = useBoolean(false)

  return (
    <Paper elevation={3}>
      <Box p={2} mb={2}>
        <Typography component="h3" variant="h3">
          {t().licensesBoxes.approvedLicensesTitle}
        </Typography>

        {approvedLicenses
          .map((license) => ({
            license,
            shown: showPreviousYears.value || categoryById(license.categoryId)?.year === nextLicenseYear,
          }))
          .map(({ license, shown }) => (
            <Collapse key={license.categoryId} in={shown}>
              <ApprovedLicenseBox license={license} documents={documents} />
            </Collapse>
          ))}

        <Box mt={2}>
          <RoutedButton to={routes.newLicense.generateTo(nextLicenseYear)}>
            {t().licensesBoxes.requestAdditionalLicense(nextLicenseYear)}
          </RoutedButton>{' '}
          {new Date().getFullYear() === oldLicenseYear && (
            <RoutedButton to={routes.newLicense.generateTo(oldLicenseYear)}>
              {t().licensesBoxes.requestAdditionalLicense(oldLicenseYear)}
            </RoutedButton>
          )}
          <Button onClick={() => showPreviousYears.toggle()}>
            {showPreviousYears.value
              ? t().licensesBoxes.hideLicensesBefore(oldLicenseYear)
              : t().licensesBoxes.showLicensesBefore(oldLicenseYear)}
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

interface ApprovedLicenseBoxProps {
  license: ApprovedLicense
  documents: Documents
}

function ApprovedLicenseBox({ license, documents }: ApprovedLicenseBoxProps) {
  const category = categoryById(license.categoryId)

  if (!category)
    return (
      <>
        {t().licensesBoxes.unknownCategory} {license.categoryId}
      </>
    )

  const categoryOfAssociation = categoryOfAssociationByLicense(license)
  const licenseTasks = openLicenseTasks({ license, documents })

  return (
    <Box key={license.categoryId} my={2}>
      <Paper variant="outlined" elevation={3}>
        <Box p={2}>
          <Box display="flex">
            <Box mr={4}>
              <CircularProgressWithLabel
                size="sm"
                invalidated={!!license.invalidated}
                done={licenseTasks.tasksDone}
                total={licenseTasks.tasksTotal}
              />
            </Box>
            <Box flexGrow={1} alignSelf="center">
              <Typography component="h4" variant="h6">
                {license.invalidated ? `${t().licensesBoxes.invalidatedLicense.title}: ` : ''}{' '}
                {fullCategoryShortName(categoryOfAssociation)}
              </Typography>
              {categoryOfAssociation.numberChoice && (
                <Typography>
                  {categoryOfAssociation.numberFixed
                    ? t().licensesBoxes.definitiveIssuedNumber
                    : t().licensesBoxes.provisionallyIssuedNumber}
                  : {license.issuedNumber}
                </Typography>
              )}
              {license.sidecarPartner && (
                <Typography>
                  {t().licensesBoxes.sidecarPartner}: {license.sidecarPartner}
                </Typography>
              )}
              {license.remarksAdmin && <Typography>{license.remarksAdmin}</Typography>}

              <Box mt={1}>
                {!license.invalidated && <ShowQRCodeToScanButton license={license} />}

                {license.invalidated ? (
                  <ButtonWithConfirmation
                    fullWidth={false}
                    disableConfirmButton
                    title={t().licensesBoxes.invalidatedLicense.title}
                    variant="text"
                    confirmation={t().licensesBoxes.invalidatedLicense.explanation}
                    onConfirm={() => undefined}
                  />
                ) : licenseTasks.allDone ? (
                  <DownloadLicenseButtons license={license} />
                ) : (
                  <DownloadLicenseConfirmation category={categoryOfAssociation} uid={license.uid} />
                )}
              </Box>
            </Box>
          </Box>
          {!licenseTasks.allDone && <LicenseTaskList size="sm" tasks={licenseTasks} />}
        </Box>
      </Paper>
    </Box>
  )
}

function ShowQRCodeToScanButton({ license }: { license: ApprovedLicense }) {
  const dialog = useDialog()
  return (
    <>
      <Button onClick={dialog.open}>{t().licensePdf.scanLicense}</Button>
      <Dialog fullWidth open={dialog.isOpen} onClose={dialog.close}>
        <QRCodeToScan license={license} />
      </Dialog>
    </>
  )
}

function QRCodeToScan({ license }: { license: ApprovedLicense }) {
  const [url, setUrl] = useState('')
  useAsync(async () => {
    setUrl(await qrCodeDataURL({ license, type: 'license' }))
  }, [license])

  return url ? <img src={url} alt="License QR Code" /> : null
}
