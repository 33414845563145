import { sortBy } from 'lodash'
import { useFire, useRecords } from 'app/db/db-hooks/db-hook-helpers'
import { UserQuery } from 'shared/db/db'
import { UserEvent } from 'shared/db/user-event'

export function useAllUserEvents() {
  const { data, ...rest } = useRecords<UserEvent>('userEvents2/all')
  return { data: data.reverse(), ...rest }
}

export function useUserEvents(user: UserQuery) {
  const { data, ...rest } = useFire<Record<string, Record<string, UserEvent>>>(
    `userEvents2/byUser/${user.uid}`
  )
  return {
    data: sortBy(
      Object.values(data || {}).flatMap((obj) => Object.values(obj)),
      (el) => el.date
    ).reverse(),
    ...rest,
  }
}

export function useUserEventDetails(userEvent: UserEvent) {
  return useFire<UserEvent['details']>(
    `userEvents2/details/${userEvent.uid}/${userEvent.type}/${userEvent.id}`
  )
}
