import { Box, Typography } from '@material-ui/core'
import { differenceInMonths } from 'date-fns'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { nextLicenseYearIsOnline } from './config'
import { useLicenseData } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { AccordionStep } from 'app/license/accordion-step'
import { allSteps, firstStep, LicenseStep, nextStep, previousStep } from 'app/license/step'
import { AdditionalInfoForm } from 'app/license/steps/additional-info'
import { CategoriesForm } from 'app/license/steps/categories'
import { CategoryDetailsForm } from 'app/license/steps/category-details'
import { LastYearForm } from 'app/license/steps/last-year'
import { SummaryForm } from 'app/license/steps/summary'
import { TransponderForm } from 'app/license/steps/transponder'
import { toTransponderForm } from 'app/license/steps/transponder-form-fields'
import { TooEarly } from 'app/license/too-early'
import { PersonalDataForm } from 'app/personal-data/personal-data-form'
import { useAssociationState } from 'app/themes/association-context'
import { userEmail } from 'app/users/auth'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { categoryById, fixedLicenseType } from 'shared/data/categories-service'
import { nextLicenseYear } from 'shared/data/license-config'
import { Year } from 'shared/data/license-year'
import { LicenseFormData } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { SelectedCategory } from 'shared/models/selected-category'
import { truthy } from 'shared/utils/array'
import { parseISO } from 'shared/utils/date'
import { FriendlyError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { Disp } from 'utils/react'

interface LicenseFormProps {
  user: User
  year: Year
}

export function LicenseForm({ user, year }: LicenseFormProps) {
  const {
    data: licenseData,
    loading: licenseDataLoading,
    error: licenseDataError,
  } = useLicenseData(user, year)
  const [step, setStep] = useState<LicenseStep>(firstStep())
  const [onSubmitError, setError] = useState<Error | undefined>()
  const history = useHistory()
  const associationState = useAssociationState()

  const state: LicenseFormState = { user, step, setStep, year }
  const processedAtStr = licenseData.licenseDrafts?.summary?.processedAt
  const processedAt = processedAtStr ? parseISO(processedAtStr) : undefined
  const lockMemberFields = processedAt && differenceInMonths(new Date(), processedAt) < 6
  const tooEarly = !nextLicenseYearIsOnline && year === nextLicenseYear && !associationState.previewing

  return (
    <>
      <Box my={3}>
        <Typography component="h2" variant="h3">
          {t().licenseForm.title} {year}
        </Typography>
      </Box>

      <FriendlyError error={licenseDataError} />
      <FriendlyError error={onSubmitError} />
      <Loading loading={licenseDataLoading} />

      {tooEarly ? (
        <TooEarly />
      ) : licenseDataLoading ? null : (
        <>
          {allSteps(licenseData).map((additionalStep) => (
            <AccordionStep
              licenseData={licenseData}
              key={additionalStep.id}
              title={additionalStep.displayName()}
              accordionStep={additionalStep.id}
              selectedStep={step.id}
              setStepActive={() => setStep(additionalStep)}
            >
              {additionalStep.type === 'personalData' ? (
                <PersonalDataForm
                  lockMemberFields={!!lockMemberFields}
                  email={userEmail(user)}
                  disableSamFields
                  onSubmit={(part) =>
                    onSubmit({
                      part,
                      state,
                      setError,
                      storePart: (user, data) => db.setPersonalData(user, data),
                    })
                  }
                  onCancel={() => history.goBack()}
                  initialValues={licenseData.documents?.personalData}
                />
              ) : additionalStep.type === 'lastSeason' ? (
                <LastYearForm
                  onSubmit={(part) =>
                    onSubmit({
                      part,
                      state,
                      setError,
                      storePart: (user, data) => db.setLastYear(user, data),
                    })
                  }
                  onBack={() => setStep(previousStep(step, licenseData))}
                  initialValues={licenseData.documents?.lastYear}
                />
              ) : additionalStep.type === 'categories' ? (
                <CategoriesForm
                  onSubmit={(part) =>
                    onSubmit({
                      part,
                      state,
                      setError,
                      storePart: (user, data) => db.setLicenseDraftCategoryIds(user, data, state.year),
                    })
                  }
                  onBack={() => setStep(previousStep(step, licenseData))}
                  initialValues={buildInitialValues(licenseData)}
                  approvedLicenses={licenseData.approvedLicenses}
                  year={state.year}
                />
              ) : additionalStep.type === 'transponder' ? (
                <TransponderForm
                  onSubmit={(part) =>
                    onSubmit({
                      part,
                      state,
                      setError,
                      storePart: (user, data) => db.setTransponder(user, data),
                    })
                  }
                  onBack={() => setStep(previousStep(step, licenseData))}
                  initialValues={toTransponderForm(licenseData.documents?.transponder)}
                  user={user}
                />
              ) : additionalStep.type === 'additionalInfo' ? (
                <AdditionalInfoForm
                  lockMemberFields={!!lockMemberFields}
                  onSubmit={(part) =>
                    onSubmit({
                      part,
                      state,
                      setError,
                      storePart: (user, data) => db.setPersonalData(user, data),
                    })
                  }
                  onBack={() => setStep(previousStep(step, licenseData))}
                  initialValues={licenseData.documents?.personalData}
                  licenseData={licenseData}
                />
              ) : additionalStep.type === 'summary' ? (
                <SummaryForm
                  user={user}
                  licenseData={licenseData}
                  onSubmit={async (part) => {
                    await onSubmit({
                      part,
                      state,
                      setError,
                      storePart: (user, data) => db.setSummary(user, data, state.year),
                    })
                    history.push(`${routes.userDashboard.to}?license-request-received`)
                  }}
                  onBack={() => setStep(previousStep(step, licenseData))}
                  initialValues={
                    licenseData.licenseDrafts?.summary
                      ? { ...licenseData.licenseDrafts.summary, acceptTerms: false }
                      : undefined
                  }
                />
              ) : additionalStep.type === 'category' ? (
                <>
                  <CategoryDetailsForm
                    category={additionalStep.category}
                    licenseCategoryContext={additionalStep.licenseCategoryContext}
                    onSubmit={(part) =>
                      onSubmit({
                        part,
                        state,
                        setError,
                        storePart: (user, data) => db.setDraftCategoryDetails(user, data, state.year),
                      })
                    }
                    onBack={() => setStep(previousStep(step, licenseData))}
                    initialValues={fixedLicenseType(
                      licenseData.licenseDrafts?.categoryDetails?.[additionalStep.category.id]
                    )}
                  />
                </>
              ) : null}
            </AccordionStep>
          ))}
        </>
      )}
    </>
  )
}

function buildInitialValues(licenseData: LicenseFormData): SelectedCategory[] {
  const licenseDrafts = licenseData.licenseDrafts
  if (!licenseDrafts) return []
  const categoryIds = licenseDrafts.categories || []
  return categoryIds
    .map<SelectedCategory | undefined>((categoryId) => {
      const category = categoryById(categoryId)
      const details = fixedLicenseType(licenseDrafts.categoryDetails?.[categoryId])
      return details && category
        ? {
            association: details.licenseAssociation,
            category: category.id,
            categoryType: category.type,
          }
        : undefined
    })
    .filter(truthy)
}

async function onSubmit<T>({
  state,
  part,
  storePart,
  setError,
}: {
  state: LicenseFormState
  part: T
  storePart: (user: User, data: T) => Promise<void>
  setError: Disp<Error | undefined>
}) {
  try {
    setError(undefined)
    await storePart(state.user, part)
    const newLicenseData = await db.loadLicenseData(state.user, state.year)
    state.setStep(nextStep(state.step, newLicenseData))
  } catch (error: any) {
    setError(error)
  }
}

interface LicenseFormState {
  user: User
  step: LicenseStep
  setStep: Disp<LicenseStep>
  year: Year
}
