import { useAllDocuments } from 'app/db/db-contexts/documents-context'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { EditPersonalDataButton } from 'app/pages/admin/users/EditPersonalDataButton'
import { SetPasswordButton } from 'app/pages/admin/users/set-password-button'
import { EditEmailButton, ImpersonateButton } from 'app/pages/admin/users/user-management'
import { useIsAssociationAdmin } from 'app/themes/user-context'
import { useAllUsers } from 'app/users/auth-admin-service'
import { DisabledForAssociationAdmin } from 'app/users/disabled-for-association-admin'
import { routes } from 'shared/config/routes'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { fullName } from 'shared/models/personal-data'
import { uFormatDate } from 'shared/utils/date'
import { sleep } from 'shared/utils/time'

export function EmailConflicts({ admin }: { admin: UserQuery }) {
  const users = useAllUsers()
  const { data: allDocuments, error, loading } = useAllDocuments()

  const associationAdmin = useIsAssociationAdmin()
  if (associationAdmin) return <DisabledForAssociationAdmin title={routes.emailConflicts.text()} />

  const conflicts =
    users.users &&
    users.users
      ?.map((firebaseUser) => ({ firebaseUser, documents: allDocuments[firebaseUser.uid] }))
      ?.map((row) => ({
        ...row,
        firebaseEmail: row.firebaseUser.email || '',
        racemanagerEmail: row.documents?.personalData?.email || '',
      }))
      .filter(
        ({ firebaseEmail, racemanagerEmail }) =>
          racemanagerEmail && firebaseEmail && racemanagerEmail !== firebaseEmail
      )

  return (
    <TableBox
      title={routes.emailConflicts.text()}
      loading={users.loading || loading}
      error={users.error || error}
      data={
        conflicts && {
          rawData: conflicts.map((user) => JSON.stringify(user)),
          headers: [
            { value: t().uid },
            { value: t().emailFirebase },
            { value: t().emailRacemanager },
            { value: t().displayName },
            { value: t().name },
            { value: t().creationTime },
            { value: t().lastSignInTime },
            { value: t().customClaims, exportOnly: true },
            actions(),
          ],
          contents: conflicts.map(({ firebaseUser, firebaseEmail, racemanagerEmail, documents }) =>
            // eslint-disable-next-line react/display-name
            () => {
              return [
                firebaseUser.uid,
                firebaseEmail,
                racemanagerEmail,
                firebaseUser.displayName,
                fullName(documents?.personalData),
                uFormatDate(firebaseUser.metadata.creationTime),
                uFormatDate(firebaseUser.metadata.lastSignInTime),
                JSON.stringify(firebaseUser.customClaims || undefined) || '',
                <>
                  <FinancialDialog user={firebaseUser} admin={admin} />
                  <EditPersonalDataButton
                    user={firebaseUser}
                    documents={documents}
                    email={firebaseEmail}
                  />
                  <EditEmailButton
                    user={firebaseUser}
                    afterSuccessfulChange={async () => {
                      await sleep(2000)
                      globalThis.location.reload()
                    }}
                  />{' '}
                  <ImpersonateButton user={firebaseUser} />
                  <SetPasswordButton user={firebaseUser} />
                </>,
              ]
            }
          ),
        }
      }
    />
  )
}
