import { downloadXlsx } from 'app/export/xlsx'
import { exportBikesToCsv } from 'shared/bikes/bikes-export'
import { DayCategory, DayCategoryID } from 'shared/db/day-category'
import { InscriptionWithContextAndSportEvent } from 'shared/db/db'

export async function downloadBikes(
  inscriptions: InscriptionWithContextAndSportEvent[],
  dayCategories: Record<DayCategoryID, DayCategory>
) {
  const result = exportBikesToCsv(inscriptions, dayCategories)
  await downloadXlsx(result, 'bikes')
}
