import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { ReactNode } from 'react'
import { isPreviousStep } from 'app/license/step'
import { LicenseFormData } from 'shared/db/db'

interface P {
  licenseData: LicenseFormData
  title: string
  accordionStep: string
  selectedStep: string
  children: ReactNode
  setStepActive: () => void
}

export function AccordionStep(props: P) {
  const { licenseData, title, accordionStep, selectedStep, setStepActive, children } = props

  const isPrevious = isPreviousStep(licenseData, selectedStep, accordionStep)
  return (
    <Accordion expanded={selectedStep === accordionStep}>
      <AccordionSummary
        expandIcon={isPrevious ? <ExpandMore /> : undefined}
        onClick={isPrevious ? setStepActive : undefined}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>{children}</div>
      </AccordionDetails>
    </Accordion>
  )
}
