import { ArrayElement } from 'shared/utils/tsc'

export interface LicenseTasksOverview {
  tasksTotal: number
  tasksDone: number
  allDone: boolean
  allVerified: boolean
  tasks: (LicenseTask | InscriptionTask)[]
}

export interface LicenseTask {
  type: LicenseTaskType
  done: boolean
  verified: boolean
}

export interface InscriptionTask {
  type: InscriptionTaskType
  done: boolean
  verified: boolean
}

export type LicenseTaskType = ArrayElement<ReturnType<typeof licenseTaskTypes>>

export function licenseTaskTypes() {
  return [
    'personalData',
    'licenseSubmitted',
    'memberFees',
    'licenseFees',
    'emergency',
    'checkEmergency',
    'healthCheck',
    'insurance',
    'photo',
    'bike',
  ] as const
}

export type InscriptionTaskType = ArrayElement<ReturnType<typeof inscriptionTaskTypes>>

export function inscriptionTaskTypes() {
  return ['inscriptionPayment', 'adminConfirmationPending', 'inscriptionSubmitted'] as const
}
