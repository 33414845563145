import { ReactNode, useState } from 'react'
import { DocumentsContext } from 'app/db/db-contexts/documents-context'
import { useFire, UseWithObj } from 'app/db/db-hooks/db-hook-helpers'
import { DocumentsWithUid } from 'shared/db/db'
import { UserId } from 'shared/db/user-id'
import { useDebouncedValue } from 'utils/useDebouncedValue'

export function AdminDBProvider({ children }: { children: ReactNode }) {
  return <DBDocumentsProvider>{children}</DBDocumentsProvider>
}

function DBDocumentsProvider({ children }: { children: ReactNode }) {
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const rawDocuments = useAllDocumentsFromDB()
  const documents = useDebouncedValue(rawDocuments)

  if (!initiallyLoaded && Object.keys(documents).length > 0) setInitiallyLoaded(true)

  return (
    <DocumentsContext.Provider value={{ documents, initiallyLoaded }}>
      {children}
    </DocumentsContext.Provider>
  )
}

function useAllDocumentsFromDB(): UseWithObj<Record<UserId, DocumentsWithUid>> {
  const result = useFire<Record<string, DocumentsWithUid>>('documents')
  return { ...result, data: result.loadingOrError || !result.data ? {} : usersWithUid(result.data) }
}

function usersWithUid(documents: Record<UserId, DocumentsWithUid>) {
  Object.keys(documents).forEach((userId) => (documents[userId].uid = userId))
  return documents
}
