import { ViewList } from '@material-ui/icons'
import { useDocuments } from 'app/db/db-hooks/main-db-hooks'
import { useIsAdmin } from 'app/db/db-hooks/use-is-admin'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { ListTransactions } from 'app/pages/billing/list-transactions'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'

export function FinancialDialog({ admin, user }: { admin: UserQuery; user: UserQuery }) {
  const isAdmin = useIsAdmin(admin)
  const dialog = useDialog()
  const { data: documents } = useDocuments(user)

  return (
    <>
      <IconButtonWithTooltip tooltip={t().financials.financials} onClick={() => dialog.open()}>
        <ViewList />
      </IconButtonWithTooltip>
      {dialog.isOpen && (
        <ConfirmDialog
          maxWidth="xl"
          fullWidth
          dialog={dialog}
          title={isAdmin ? `${t().financials.financials}, UID: ${user?.uid}` : t().financials.financials}
        >
          <ListTransactions documents={documents} admin={admin} user={user} />
        </ConfirmDialog>
      )}
    </>
  )
}
