import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import { AssignmentLate, ThumbUp } from '@material-ui/icons'
import assertNever from 'assert-never'
import { useState } from 'react'
import { useBikes } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { AccountBalance, AccountBalancePaymentButton } from 'app/financials/account-balance'
import { DeleteInscriptionButton } from 'app/inscription/delete-inscription-button'
import {
  updateBorrowedTransponder,
  updateIssuedNumber,
  updateSidecarPartner,
} from 'app/inscription/update-inscription-service'
import { IconButtonWithDetails } from 'app/layout/button-with-details'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { AddFineFormForInscription } from 'app/pages/admin/inscriptions/AddFineButton'
import { openInscriptionTasks } from 'app/pages/admin/inscriptions/openInscriptionTasks'
import { ElevatedBoxSm } from 'app/pages/dashboard/elevated-box'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import { bikeName } from 'app/pages/inscription/bike-name'
import { useAdmin } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { categoryByIdRequired } from 'shared/data/categories-service'
import { ApprovedLicense, InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { inscriptionCategory } from 'shared/inscription/inscription-categories-service'
import {
  approveDayInscriptionDayCategoryDraft,
  approveDayInscriptionYearCategoryDraft,
} from 'shared/inscription/inscription-service'
import { InscriptionStatus } from 'shared/inscription/inscription-status'
import {
  updateInscriptionListByInscription,
  updateInscriptionListByUser,
} from 'shared/inscription/public-inscription-list'
import { Bike } from 'shared/models/bike'
import {
  EnlistedDayInscriptionDayCategory,
  EnlistedDayInscriptionDayCategoryDraft,
  EnlistedDayInscriptionYearCategory,
  EnlistedDayInscriptionYearCategoryDraft,
  EnlistedInscription,
  isDayInscription,
  isInscribedInscription,
  isUnlistedInscription,
  SportEvent,
} from 'shared/sport-events/sport-events'
import { sportEventDescription } from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'
import { pFormatDateWithSecondsSpaceDe } from 'shared/utils/date'
import { parseInt10 } from 'shared/utils/number'
import { nullAsUndefined } from 'shared/utils/object'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { useCatchAndDisplayErrorOrSuccess } from 'utils/snackbar'

interface InscriptionDetailsButtonProps {
  sportEvent: SportEvent
  inscription: InscriptionWithContextAndSportEvent
  status: InscriptionStatus
  disabled: boolean
}

export function InscriptionDetailsButton(data: InscriptionDetailsButtonProps) {
  const { sportEvent, inscription, status, disabled } = data

  return (
    <IconButtonWithDetails
      fullWidth
      disabled={disabled}
      icon={<ThumbUp />}
      title={`${t().details}: ${t().inscription.inscriptionTypes[inscription.inscription.type]}`}
      tooltip={t().inscription.modifySportsEventInscription(
        t().inscription.inscriptionTypes[inscription.inscription.type],
        sportEventDescription(sportEvent)
      )}
    >
      <InscriptionDetails sportEvent={sportEvent} inscription={inscription} status={status} />
    </IconButtonWithDetails>
  )
}

export function InscriptionDetails(props: {
  sportEvent: SportEvent
  inscription: InscriptionWithContextAndSportEvent
  status: InscriptionStatus
}) {
  const { inscription, status } = props
  const bikeIds = inscription.inscription.bikeIds || []
  const { data: allBikes, ...rest } = useBikes({ uid: inscription.inscription.uid })
  const bikes = allBikes.filter((bike) => bikeIds.includes(bike.id))
  const category = inscriptionCategory(inscription.inscription, inscription.dayCategory)
  const openTasks = openInscriptionTasks(inscription)
  const admin = useAdmin()

  return (
    <>
      <LoadingOrErrorBox {...rest} />

      <ElevatedBoxSm title={t().inscription.collect}>
        <Box display="flex">
          <Typography>{t().accountBalance}: </Typography>
          <AccountBalance user={inscription.inscription} />
        </Box>
        <Box display="flex" style={{ alignItems: 'center' }}>
          <Typography>{t().details}: </Typography>
          <FinancialDialog admin={admin} user={inscription.inscription} />
        </Box>
        <Box>
          <AccountBalancePaymentButton user={inscription.inscription} />
        </Box>
      </ElevatedBoxSm>

      <ElevatedBoxSm title={t().details}>
        <Box mt={1}>
          <Typography>
            {t().inscription.raceDate}: {inscription.inscription.date}
          </Typography>
        </Box>

        <Box mt={1}>
          <Typography>
            {t().financials.payment}: {inscription.inscription.paid ? t().paid : t().open}
          </Typography>
        </Box>

        {inscription.inscription.remarksRider && (
          <Box mt={1}>
            <Typography>
              {t().inscription.riderRemarks}: {inscription.inscription.remarksRider}
            </Typography>
          </Box>
        )}

        {inscription.inscription.remarksAdmin && (
          <Box mt={1}>
            <Typography>
              {t().inscription.adminRemarks}: {inscription.inscription.remarksAdmin}
            </Typography>
          </Box>
        )}

        <Box mt={1}>
          <Typography>
            {isUnlistedInscription(inscription.inscription)
              ? t().inscription.unlistedInscriptionCreatedAt
              : t().inscription.enlistedInscriptionCreatedAt}
            : {pFormatDateWithSecondsSpaceDe(inscription.inscription.createdAt)}
          </Typography>
        </Box>

        <Box mt={1}>
          <Typography>UID: {inscription.inscription.uid}</Typography>
        </Box>
      </ElevatedBoxSm>

      {(category?.sidecar || category?.sidecarPassenger) && (
        <ElevatedBoxSm title={t().inscription.sidecar}>
          <EditSidecarPartner inscription={inscription} />
        </ElevatedBoxSm>
      )}

      <ElevatedBoxSm title={t().transponders.title}>
        <EditBorrowedTransponder inscription={inscription} />
      </ElevatedBoxSm>

      {openTasks && !openTasks.allDone && (
        <ElevatedBoxSm title={t().inscription.openTasks}>
          <List>
            {openTasks.tasks
              .filter((task) => !task.done)
              .map((task) => (
                <ListItem key={task.type}>
                  <ListItemIcon>
                    <AssignmentLate />
                  </ListItemIcon>
                  <ListItemText>{t().licenseTasks.names[task.type]}</ListItemText>
                </ListItem>
              ))}
          </List>
        </ElevatedBoxSm>
      )}

      {isInscribedInscription(inscription.inscription) && (
        <ElevatedBoxSm title={t().inscription.manualConfirmation}>
          <Box mb={1}>
            <Typography>
              Status:{' '}
              {inscription.inscription.manuallyVerified
                ? t().inscription.manuallyVerified
                : t().inscription.verifiedBySystem}
            </Typography>
          </Box>

          {status === 'inscription-pending' && (
            <Button
              variant="outlined"
              onClick={() => db.manuallyVerifyInscription(inscription.inscription)}
            >
              {t().inscription.confirmManually}
            </Button>
          )}

          {inscription.inscription.manuallyVerified && (
            <Button
              variant="outlined"
              onClick={() => db.disableManuallyVerifyInscription(inscription.inscription)}
            >
              {t().inscription.removeManualConfirmation}
            </Button>
          )}
        </ElevatedBoxSm>
      )}

      <ElevatedBoxSm title={t().inscription.addFineByAssociation}>
        <AddFineFormForInscription inscription={inscription.inscription} />
      </ElevatedBoxSm>

      {bikes.length > 0 && (
        <ElevatedBoxSm title={t().bikes}>
          <Box mt={1}>
            <ul>
              {bikes.map((bike) => (
                <li key={bike.id}>{bikeName(bike)}</li>
              ))}
            </ul>
          </Box>
          <Box mt={1}>
            <OverrideTeamAndBike bikes={bikes} inscription={inscription} />
          </Box>
        </ElevatedBoxSm>
      )}

      <ElevatedBoxSm title={t().actions}>
        {(inscription.inscription.type === 'enlistedDayInscriptionDayCategoryDraft' ||
          inscription.inscription.type === 'enlistedDayInscriptionYearCategoryDraft') && (
          <>
            <ConfirmDayInscriptionDayCategory inscription={inscription.inscription} />
            <Divider />
          </>
        )}

        {(inscription.inscription.type === 'enlistedDayInscriptionDayCategory' ||
          inscription.inscription.type === 'enlistedDayInscriptionYearCategory') && (
          <>
            <EditIssuedNumber inscription={inscription.inscription} />
            <Divider />
          </>
        )}

        <Box mt={2}>
          <Typography>
            <DeleteInscriptionButton
              personalData={inscription.documents.personalData}
              sportEvent={inscription.sportEvent}
              inscription={inscription.inscription}
            />
          </Typography>
        </Box>
      </ElevatedBoxSm>
    </>
  )
}

function EditSidecarPartner(props: { inscription: InscriptionWithContextAndSportEvent }) {
  const { inscription } = props
  const defaultValue = fallbackSidecarPartnerName(inscription)
  const [sidecarPartner, setSidecarPartner] = useState(defaultValue)
  const changed = sidecarPartner !== defaultValue
  const year = useLicenseYear()

  return (
    <Box mt={3} mb={3} style={{ minWidth: '400px' }}>
      <Typography>
        {t().sidecarPartner.label}:{' '}
        {inscriptionSidecarPartnerName(inscription) || t().inscription.noPartnerAssigned}
      </Typography>

      <TextField
        fullWidth
        required
        id="sidecarPartner"
        label={t().inscription.sideCarPartnerLabel}
        value={sidecarPartner}
        onChange={(e) => setSidecarPartner(e.target.value)}
      />

      <Button
        disabled={!changed}
        onClick={() => updateSidecarPartner(inscription.inscription, sidecarPartner, year)}
      >
        {t().inscription.assignPartner}
      </Button>
    </Box>
  )
}

function fallbackSidecarPartnerName(inscription: InscriptionWithContextAndSportEvent) {
  const sidecarPartner = licenseDraftSidecarPartner(inscription)
  return (
    inscriptionSidecarPartnerName(inscription) ||
    (sidecarPartner && `${t().inscription.fromRequestedLicense}: ${sidecarPartner}`) ||
    ''
  )
}

function licenseDraftSidecarPartner(inscription: InscriptionWithContextAndSportEvent) {
  return inscription.licenseWithContext?.license.draft.categoryDetails?.sidecarPartner
}

function inscriptionSidecarPartnerName(inscription: InscriptionWithContextAndSportEvent) {
  return (
    inscription.inscription.sidecarPartner ||
    inscription.licenseWithContext?.license.approved.currentSidecarPartner ||
    ''
  )
}

function EditBorrowedTransponder(props: { inscription: InscriptionWithContextAndSportEvent }) {
  const { inscription } = props
  const defaultValue = `${inscription.inscription.borrowedTransponder || ''}`
  const [borrowedTransponder, setBorrowedTransponder] = useState(defaultValue)
  const changed = borrowedTransponder !== defaultValue
  const privateTransponders = Object.values(
    inscription.documents.transponder?.transponders || {}
  ).filter((transponder) => transponder.type === inscription.sportEvent.transponderType)
  const privateTransponder1 = privateTransponders[0]
  const privateTransponder2 = inscription.inscription.borrowedTransponder
    ? undefined
    : privateTransponders[1]

  return (
    <Box mt={3} mb={3} style={{ minWidth: '400px' }}>
      <Typography>
        {t().transponders.privateTransponder}:{' '}
        {privateTransponder1
          ? [privateTransponder1, privateTransponder2]
              .filter(truthy)
              .map((t) => t.transponderNumber)
              .join(', ')
          : t().inscription.noTransponderRegistered(t().transponders.privateTransponder)}
      </Typography>

      <Typography>
        {t().transponders.borrowedTransponder}:{' '}
        {inscription.inscription.borrowedTransponder
          ? inscription.inscription.borrowedTransponder
          : t().inscription.noTransponderAssigned(t().transponders.borrowedTransponder)}
      </Typography>

      <TextField
        fullWidth
        id="borrowedTransponder"
        label={t().transponders.borrowedTransponder}
        value={borrowedTransponder}
        onChange={(e) => setBorrowedTransponder(e.target.value)}
      />

      <Button
        disabled={!changed}
        onClick={() => updateBorrowedTransponder(inscription.inscription, borrowedTransponder.trim())}
      >
        {t().transponders.borrowedTransponder}
        {borrowedTransponder ? `${borrowedTransponder} ${t().assign}` : t().delete}{' '}
      </Button>
    </Box>
  )
}

function EditIssuedNumber(props: {
  inscription: EnlistedDayInscriptionDayCategory | EnlistedDayInscriptionYearCategory
}) {
  const { inscription } = props
  const defaultValue = `${inscription.issuedNumber}`
  const [issuedNumberString, setIssuedNumberString] = useState(defaultValue)
  const issuedNumber = parseInt10(issuedNumberString)
  const changed = issuedNumberString !== defaultValue

  return (
    <Box mt={3} mb={3} style={{ minWidth: '400px' }}>
      <Typography>
        {t().issuedNumber.label}: {inscription.issuedNumber}
      </Typography>

      <TextField
        fullWidth
        type="number"
        id="issuedNumber"
        label={t().issuedNumber.label}
        value={issuedNumberString}
        onChange={(e) => setIssuedNumberString(e.target.value)}
      />

      <Button
        disabled={!changed || !issuedNumber}
        onClick={() => updateIssuedNumber(inscription, issuedNumber)}
      >
        {t().issuedNumber.label} {issuedNumber} {t().assign}
      </Button>
    </Box>
  )
}

interface ConfirmDayInscriptionDayCategoryProps {
  inscription: EnlistedDayInscriptionDayCategoryDraft | EnlistedDayInscriptionYearCategoryDraft
}

function ConfirmDayInscriptionDayCategory({ inscription }: ConfirmDayInscriptionDayCategoryProps) {
  const [issuedNumberString, setIssuedNumberString] = useState(inscription.preferredNumber)
  const { error, setError } = useError()
  const [loading, setLoading] = useState(false)
  const issuedNumber = parseInt10(issuedNumberString)
  const admin = useAdmin()

  return (
    <Box my={4}>
      <Typography variant="h6" gutterBottom>
        {t().inscription.confirmRegistration}
      </Typography>
      <Typography gutterBottom>
        {t().preferredNumber.label}: {inscription.preferredNumber}
      </Typography>

      <Box mb={1}>
        <TextField
          fullWidth
          required
          id="issuedNumber"
          label={t().issuedNumber.label}
          value={issuedNumberString}
          onChange={(e) => setIssuedNumberString(e.target.value)}
        />
      </Box>

      <FriendlyError error={error} />
      <Loading loading={loading} />

      <Button
        fullWidth
        variant="outlined"
        color="primary"
        disabled={!issuedNumber || loading}
        onClick={() =>
          catchAndLog(
            setError,
            () =>
              inscription.type === 'enlistedDayInscriptionDayCategoryDraft'
                ? approveDayInscriptionDayCategoryDraft({ db, inscription, admin, issuedNumber })
                : inscription.type === 'enlistedDayInscriptionYearCategoryDraft'
                ? approveDayInscriptionYearCategoryDraft({ db, inscription, admin, issuedNumber })
                : assertNever(inscription),
            setLoading
          )
        }
      >
        {t().inscription.confirmInscription(issuedNumber)}
      </Button>
    </Box>
  )
}

function OverrideTeamAndBike(props: {
  inscription: InscriptionWithContextAndSportEvent
  bikes: Bike[]
}) {
  // TODO: laster: add migration to remove `documents.teamOverrides`
  const { inscription, bikes } = props

  const license = inscription.licenseWithContext?.license.approved
  if (license) return <OverrideTeamAndBikeOfLicense license={license} />

  const rawInscription = inscription.inscription
  if (!isDayInscription(rawInscription)) return null

  return (
    <OverrideTeamAndBikeForm
      teamName={rawInscription.teamName || ''}
      setTeamName={(name) => updateInscriptionTeamName(rawInscription, name)}
      bikeMake={rawInscription.bikeMake || bikes[0]?.bikeMake || ''}
      setBikeMake={(name) => updateInscriptionBikeMake(rawInscription, name)}
    />
  )
}

function OverrideTeamAndBikeOfLicense({ license }: { license: ApprovedLicense }) {
  return (
    <OverrideTeamAndBikeForm
      teamName={license.teamName || ''}
      setTeamName={(name) => updateLicenseTeamName(license, name)}
      bikeMake={license.bikeMake || ''}
      setBikeMake={(name) => updateLicenseBikeMake(license, name)}
    />
  )
}

function OverrideTeamAndBikeForm(props: {
  bikeMake: string
  setBikeMake: (name: string) => Promise<void>
  teamName: string
  setTeamName: (name: string) => Promise<void>
}) {
  const { bikeMake, setBikeMake, teamName, setTeamName } = props
  const showFeedback = useCatchAndDisplayErrorOrSuccess('')

  return (
    <Box my={4}>
      <Typography variant="h6">{t().licensesBoxes.overwrite}</Typography>

      <Box mb={1}>
        <TextField
          fullWidth
          label={t().teamName.labelShort}
          value={teamName}
          onChange={(e) => showFeedback(setTeamName(e.target.value))}
        />
      </Box>

      <Box mb={1}>
        <TextField
          fullWidth
          required
          label={t().inscription.bikeMakeLabel}
          value={bikeMake}
          onChange={(e) => showFeedback(setBikeMake(e.target.value))}
        />
      </Box>
    </Box>
  )
}

async function updateLicenseTeamName(license: ApprovedLicense, teamName: string) {
  await updateLicense({ ...license, teamName: teamName || nullAsUndefined })
}

async function updateLicenseBikeMake(license: ApprovedLicense, bikeMake: string) {
  await updateLicense({ ...license, bikeMake: bikeMake || nullAsUndefined })
}

async function updateLicense(license: ApprovedLicense) {
  const category = categoryByIdRequired(license.categoryId)
  await db.setApprovedLicense(license, category.year)
  await updateInscriptionListByUser(db, license)
}

async function updateInscriptionTeamName(inscription: EnlistedInscription, teamName: string) {
  const updatedInscription = { ...inscription, teamName: teamName || nullAsUndefined }
  await updateInscription(updatedInscription)
}

async function updateInscriptionBikeMake(inscription: EnlistedInscription, bikeMake: string) {
  const updatedInscription = { ...inscription, bikeMake: bikeMake || nullAsUndefined }
  await updateInscription(updatedInscription)
}

async function updateInscription(inscription: EnlistedInscription) {
  await db.setInscription(inscription)
  await updateInscriptionListByInscription(db, inscription)
}
