import {
  categoryCommonName,
  categoryOfAssociationByCategoryDetails,
  fixedLicenseType,
  requiresInsuranceOptionByCategory,
  selectedCategories,
} from 'shared/data/categories-service'
import { sectionNameWithIdById } from 'shared/data/sections'
import { LicenseDrafts, LicenseFormData } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { CategoryDetails } from 'shared/models/category-details'
import { insuranceOptionById, InsuranceOptionId } from 'shared/models/insurance-options'
import { LastYear } from 'shared/models/last-year'
import { PersonalData } from 'shared/models/personal-data'
import { Summary } from 'shared/models/summary'
import {
  hasMyLapsTransponder,
  myLapsTransponderMakes,
  myLapsTransponderNumbers,
  Transponder,
  transponderOption,
} from 'shared/models/transponder'
import { TableLine } from 'shared/table-interfaces'
import { truthyTuples } from 'shared/utils/array'
import { parseDate, formatDateDe, pFormatDateWithSecondsSpaceDe } from 'shared/utils/date'

export function licenseTableContents(data: LicenseFormData, user: UserContext): TableLine[] {
  return [
    ...createTable(t().personalDataBox.title, mapPersonalData(data.documents?.personalData, user)),
    ...createTable(t().lastYear, mapLastYear(data.documents?.lastYear)),
    ...renderTransponder(data.documents?.transponder),
    ...renderLicenseDraft(data.licenseDrafts),
    ...renderSummary(data.licenseDrafts?.summary),
  ]
}

export function mapPersonalData(data: PersonalData | undefined, user: UserContext): [string, string][] {
  if (!data) return []
  return truthyTuples([
    user.view === 'admin' ? [t().uid, user.uid] : undefined,
    [t().firstName, data.firstName],
    [t().lastName, data.lastName],
    [t().street, data.street],
    [t().zip, data.zip.toString()],
    [t().place, data.place],
    [t().country, data.country],
    [t().email, data.email],
    [t().phone, data.phone],
    [t().birthdate, formatDateDe(parseDate(data.birthdate))],
    ...((user.view === 'user' && data.samSection) ||
    (user.view === 'admin' && user.associationOfAdmin === 'sam')
      ? truthyTuples([
          [t().samMemberNumber.label, data.samMemberNumber?.toString() || t().newMember],
          [t().samSection.label, sectionNameWithIdById(data.samSection) || '-'],
        ])
      : []),
    ...((user.view === 'user' && data.fmsSection) ||
    (user.view === 'admin' && user.associationOfAdmin === 'fms')
      ? truthyTuples([[t().fmsSection.labelOptional, sectionNameWithIdById(data.fmsSection) || '-']])
      : []),
    ...((user.view === 'user' && data.afmSection) ||
    (user.view === 'admin' && user.associationOfAdmin === 'afm')
      ? truthyTuples([[t().afmSection.labelOptional, sectionNameWithIdById(data.afmSection) || '-']])
      : []),
  ])
}

export function mapLastYear(data: LastYear | undefined): [string, string][] {
  if (!data) return []
  if (data.noPreviousLicense) return [[t().previousLicenses, t().none]]
  return [
    [t().lastYearCategoriesShort, data.lastYearCategories],
    [t().lastYearLicensesShort, data.lastYearLicenses],
    [t().lastYearRanks, data.lastYearRanks],
    [t().lastYearStartNumbers, data.lastYearStartNumbers],
  ]
}

function renderTransponder(transponder: Transponder | undefined) {
  try {
    const ordered = transponder?.orderedTransponders || []
    return transponder
      ? createTable(
          t().transponders.transponder,
          truthyTuples(
            ordered.map((transponder) => [
              t().transponders.transponder,
              transponderOption(transponder)?.name?.() || '',
            ])
          )
        )
      : []
    // TODO: later: check later
  } catch (error) {
    return []
  }
  // return transponder ? createTable(t().transponders.transponder, mapTransponder(transponder)) : []
}

export function mapTransponder(data: Transponder | undefined): [string, string][] {
  if (!data) return []

  return truthyTuples([
    [
      t().hasTransponder.validation,
      hasMyLapsTransponder(data)
        ? t().transponders.hasTransponder
        : t().hasTransponder.hintForNewTransponder,
    ],
    hasMyLapsTransponder(data) && [t().transponderMake.label, myLapsTransponderMakes(data)],
    hasMyLapsTransponder(data) && [t().transponderNumber.label, myLapsTransponderNumbers(data)],
  ])
}

function renderLicenseDraft(data: LicenseDrafts | undefined) {
  return selectedCategories(data?.categories).flatMap((category) =>
    mapCategory(fixedLicenseType(data?.categoryDetails?.[category.id]))
  )
}

export function mapCategory(details: CategoryDetails | undefined): TableLine[] {
  if (!details) return []

  const category = categoryOfAssociationByCategoryDetails(details)
  return createTable(categoryCommonName(category), mapCategoryDetails(details))
}

export function mapCategoryDetails(details: CategoryDetails | undefined): [string, string][] {
  const category = details && categoryOfAssociationByCategoryDetails(details)
  if (!details || !category) return []

  const insuranceOptionRequired = requiresInsuranceOptionByCategory(
    category.association,
    category.year,
    category
  )

  return truthyTuples([
    category.numberChoice ? [t().licensesBoxes.preferredNumber, details.preferredNumber] : undefined,
    details.sidecarPartner ? [t().licensesBoxes.sidecarPartner, details.sidecarPartner] : undefined,
    category.needsComment ? [category.needsComment, details.comment] : undefined,
    details.teamName ? [t().sponsor, details.teamName] : undefined,
    details.bikeMake ? [t().bikeMake.labelShort, details.bikeMake] : undefined,
    [t().licenseType, t().licenseTypes[details.licenseType]],
    insuranceOptionRequired
      ? [t().insuranceOption.label, insuranceOptionDetails(details.insuranceOption) || '-']
      : undefined,
  ])
}

function insuranceOptionDetails(id: InsuranceOptionId | undefined) {
  const insuranceOption = insuranceOptionById(id)
  return insuranceOption
    ? `${insuranceOption.benefitDurationDays}/${insuranceOption.waitingPeriodDays}`
    : ''
}

export function renderSummary(summary: Summary | undefined) {
  return summary ? createTable(t().otherSummary, mapSummary(summary)) : []
}

export function mapSummary(summary: Summary | undefined): [string, string][] {
  return truthyTuples([
    summary?.remarks && [t().remarks, summary.remarks || '-'],
    summary?.processedAt && [t().licenseRequestSent, pFormatDateWithSecondsSpaceDe(summary.processedAt)],
  ])
}

export function createTable(
  title: string,
  contents: readonly (readonly [string, string])[]
): TableLine[] {
  return [
    { title, type: 'title' },
    ...contents.map(([key, value]) => ({ key, value, type: 'row' as const })),
  ]
}

export type UserContext = UserContextUser | UserContextAdmin

export interface UserContextUser {
  view: 'user'
  uid: string
}

export interface UserContextAdmin {
  view: 'admin'
  uid: string
  associationOfAdmin: AssociationID
}
