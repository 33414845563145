import { Paper, Box, Typography, Button, Collapse, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useState } from 'react'
import {
  useLicenseTasksOverview,
  useOnlineSportEvents,
  useSportEventDayCategoriesList,
} from 'app/db/db-hooks/main-db-hooks'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import { routes } from 'shared/config/routes'
import {
  categoryCommonNameEndUserWithAssociation,
  categoryByIdRequired,
  categoryOfAssociationRequired,
} from 'shared/data/categories-service'
import { nextLicenseYear } from 'shared/data/license-config'
import { LicenseTasksOverview } from 'shared/data/license-tasks-overview'
import { ApprovedLicense, Documents, UserQuery } from 'shared/db/db'
import { searchSportEventsWithCategories } from 'shared/db/search'
import { t, todoT } from 'shared/i18n/current'
import { inscriptionStatusLong } from 'shared/inscription/inscription-status-service'
import { generalInscriptionTasks, openLicenseTasks } from 'shared/license/license-tasks'
import { Category } from 'shared/models/category'
import { CategoryDetailsWithCategory, DraftLicense } from 'shared/models/category-details'
import { Inscription, SportEvent } from 'shared/sport-events/sport-events'
import {
  dayCategoryDates,
  hasAlternativeDates,
  licenseCategoryDates,
  sportEventDescription,
} from 'shared/sport-events/sport-events-service'
import { pFormatDateDe } from 'shared/utils/date'
import { strictEntries } from 'shared/utils/object'
import { RoutedButton } from 'utils/buttons/routed-button'
import { useBoolean } from 'utils/use-boolean'

interface InscriptionBoxProps {
  user: UserQuery
  documents: Documents
  licenseTasks: LicenseTasksOverview
  approvedLicenses: ApprovedLicense[]
  draftLicenses: DraftLicense[]
  inscriptions: Inscription[]
}

export function InscriptionBox(props: InscriptionBoxProps) {
  const { user, licenseTasks, approvedLicenses, draftLicenses, documents, inscriptions } = props
  const showAllCategoriesFlag = useBoolean(false)
  const open = useBoolean(false)
  const { data: sportEvents } = useOnlineSportEvents(nextLicenseYear)
  const showAllCategories = showAllCategoriesFlag.value

  const allLicenseTasksDone = licenseTasks?.allDone

  return (
    <Paper elevation={3}>
      <Box p={2} mb={2}>
        <Typography component="h3" variant="h3">
          {t().inscribe}
        </Typography>

        {!allLicenseTasksDone && (
          <Box mb={2}>
            <Alert severity="error" variant="outlined">
              {t().inscriptionBox.tasksNotDone}
            </Alert>
          </Box>
        )}

        {open.value ? (
          <>
            <AllSportEventInscriptions
              sportEvents={sportEvents}
              approvedLicenses={approvedLicenses}
              draftLicenses={draftLicenses}
              documents={documents}
              inscriptions={inscriptions}
              showAllCategories={showAllCategories}
              user={user}
            />

            {!showAllCategories && (
              <Button onClick={() => showAllCategoriesFlag.setTrue()}>
                {t().inscriptionBox.showAllCategories}
              </Button>
            )}
            {showAllCategories && (
              <Button onClick={() => showAllCategoriesFlag.setFalse()}>
                {t().showOnlyCategoriesWithLicense}
              </Button>
            )}
          </>
        ) : (
          <Box mt={1}>
            <Button color="primary" variant="contained" onClick={() => open.setTrue()}>
              {t().inscriptionBox.showInscriptions}
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

interface AllSportEventInscriptionsProps {
  sportEvents: SportEvent[]
  approvedLicenses: ApprovedLicense[]
  draftLicenses: CategoryDetailsWithCategory[]
  documents: Documents
  inscriptions: Inscription[]
  showAllCategories: boolean
  user: UserQuery
}

function AllSportEventInscriptions(props: AllSportEventInscriptionsProps) {
  const { sportEvents, ...rest } = props
  const [search, setSearch] = useState('')

  return (
    <>
      <Box my={2}>
        <TextField
          label={t().inscriptionBox.search}
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
      </Box>

      {searchSportEventsWithCategories(search, sportEvents).map(({ found, sportEvent }) => (
        <Collapse key={sportEvent.id} in={found}>
          <SportEventInscriptions key={sportEvent.id} sportEvent={sportEvent} {...rest} />
        </Collapse>
      ))}
    </>
  )
}

interface SportEventInscriptionsProps {
  sportEvent: SportEvent
  approvedLicenses: ApprovedLicense[]
  draftLicenses: CategoryDetailsWithCategory[]
  documents: Documents
  inscriptions: Inscription[]
  showAllCategories: boolean
  user: UserQuery
}

function SportEventInscriptions(props: SportEventInscriptionsProps) {
  const {
    sportEvent,
    approvedLicenses,
    draftLicenses,
    documents,
    inscriptions,
    showAllCategories: globalShowAllCategories,
    user,
  } = props

  const showAllCategoriesForSportEvent = useBoolean(false)
  const showAllCategories = globalShowAllCategories || showAllCategoriesForSportEvent.value

  return (
    <Box key={sportEvent.id} my={2}>
      <Paper variant="outlined" elevation={3}>
        <Box p={2} pb={1}>
          <Typography component="h4" variant="h6">
            {sportEventDescription(sportEvent)}{' '}
            {hasAlternativeDates(sportEvent) && (
              <>
                ({todoT('Verschiebedatum')} {pFormatDateDe(sportEvent.alternativeStartsAt)}-
                {pFormatDateDe(sportEvent.alternativeEndsAt)})
              </>
            )}
          </Typography>
        </Box>

        <Box p={2} py={1}>
          <Button
            color="primary"
            variant={showAllCategoriesForSportEvent.value ? 'outlined' : 'outlined'}
            onClick={() => showAllCategoriesForSportEvent.toggle()}
          >
            {showAllCategoriesForSportEvent.value
              ? t().inscriptionBox.hideAllCategoriesPerSportEvent
              : t().inscriptionBox.showAllCategoriesPerSportEvent}
          </Button>
        </Box>

        <Box p={2} py={1}>
          {strictEntries(licenseCategoryDates(sportEvent)).map(([categoryID, dates]) => {
            const category = categoryByIdRequired(categoryID)
            const approvedLicense = approvedLicenses.find((lic) => lic.categoryId === categoryID)
            const draftLicense = draftLicenses.find((lic) => lic.categoryId === categoryID)
            const canInscribe = calcCanInscribe({ approvedLicense, documents, draftLicense })
            const hasOpenLicenseTasks = approvedLicense
              ? !openLicenseTasks({ license: approvedLicense, documents }).allDone
              : false
            const hasOnlyDraftLicense = !approvedLicense && !!draftLicense
            const hasInscription = dates.some((date) =>
              inscriptions.find(
                (inscription) => inscription.category === categoryID && inscription.date === date
              )
            )

            return (
              <Collapse
                key={[categoryID, ...dates].join('_')}
                in={!!(approvedLicense || draftLicense || showAllCategories || hasInscription)}
                mountOnEnter
              >
                <Box mt={1} mb={2}>
                  <Box mb={1}>
                    <Typography>
                      {categoryCommonNameEndUserWithAssociation(category)}
                      {!approvedLicense && !draftLicense && todoT(' (Tageslizenz)')}
                    </Typography>
                  </Box>
                  {hasOpenLicenseTasks && (
                    <Box mb={1}>
                      <Alert severity="error" variant="outlined">
                        {t().inscriptionBox.tasksNotDone}
                      </Alert>
                    </Box>
                  )}
                  {hasOnlyDraftLicense && (
                    <Box mb={1}>
                      <Alert severity="error" variant="outlined">
                        {t().inscriptionBox.licenseNotApproved}
                      </Alert>
                    </Box>
                  )}
                  <Box display="flex">
                    {dates.map((date) => {
                      const inscription = inscriptions.find(
                        (inscription) =>
                          inscription.date === date &&
                          inscription.sportEvent === sportEvent.id &&
                          inscription.category === category.id
                      )
                      return (
                        <Box key={date} mr={1}>
                          {approvedLicense?.invalidated ? (
                            <Button disabled>{t().licensesBoxes.invalidatedLicense.title}</Button>
                          ) : approvedLicense || draftLicense || inscription ? (
                            <ManageInscriptionButton
                              key={date}
                              sportEvent={sportEvent}
                              category={category}
                              canInscribe={canInscribe}
                              user={user}
                              date={date}
                              inscription={inscription}
                              documents={documents}
                            />
                          ) : hasOnlyDraftLicense ? null : showAllCategories ? (
                            <RoutedButton
                              variant="outlined"
                              to={routes.newInscription.generateTo(sportEvent, category, date)}
                              key={date}
                            >
                              {routes.newInscription.generateShort(date)}
                            </RoutedButton>
                          ) : null}{' '}
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              </Collapse>
            )
          })}

          <SportEventInscriptionsDayCategories
            sportEvent={sportEvent}
            inscriptions={inscriptions}
            showAllCategories={showAllCategories}
            documents={documents}
          />
        </Box>

        <Box p={2} pt={0}>
          <RoutedButton to={routes.publicInscriptions.generateTo(sportEvent.id)}>
            {routes.publicInscriptions.text()}
          </RoutedButton>
        </Box>
      </Paper>
    </Box>
  )
}

function SportEventInscriptionsDayCategories(props: {
  sportEvent: SportEvent
  inscriptions: Inscription[]
  showAllCategories: boolean
  documents: Documents
}) {
  const { sportEvent, inscriptions, showAllCategories, documents } = props
  const dayCategories = useSportEventDayCategoriesList(sportEvent.id)

  return (
    <>
      <LoadingOrErrorBox error={dayCategories.error} loading={dayCategories.loading} />
      {strictEntries(dayCategoryDates(sportEvent)).map(([categoryID, dates]) => {
        const dayCategory = dayCategories.data.find((dayCategory) => dayCategory.id === categoryID)
        const dayCategoryHasInscription = dates.some((date) =>
          inscriptions.find(
            (inscription) => inscription.category === categoryID && inscription.date === date
          )
        )

        return (dayCategoryHasInscription || showAllCategories) && dayCategory ? (
          <Box key={[categoryID, ...dates].join('_')} mb={2}>
            <Typography>{dayCategory.name}</Typography>
            {dates.map((date) => {
              const inscription = inscriptions.find(
                (inscription) => inscription.category === categoryID && inscription.date === date
              )
              const tasks = generalInscriptionTasks({
                inscription,
                documents,
                category: dayCategory,
              })

              if (inscription?.type === 'enlistedDayInscriptionDayCategory' && tasks.allDone)
                return (
                  <RoutedButton
                    to={routes.newInscription.generateTo(sportEvent, dayCategory, date)}
                    key={date}
                    variant="outlined"
                    color="primary"
                  >
                    {routes.newInscription.generateShort(date)} ({t().dayInscription.approved})
                  </RoutedButton>
                )
              if (
                inscription?.type === 'enlistedDayInscriptionDayCategoryDraft' ||
                inscription?.type === 'enlistedDayInscriptionDayCategory'
              )
                return (
                  <RoutedButton
                    to={routes.newInscription.generateTo(sportEvent, dayCategory, date)}
                    key={date}
                    variant="contained"
                    color="primary"
                  >
                    {routes.newInscription.generateShort(date)} (
                    {inscriptionStatusLong(inscription, tasks)})
                  </RoutedButton>
                )
              return (
                <RoutedButton
                  to={routes.newInscription.generateTo(sportEvent, dayCategory, date)}
                  key={date}
                >
                  {routes.newInscription.generateShort(date)}
                </RoutedButton>
              )
            })}
          </Box>
        ) : null
      })}
    </>
  )
}

interface ManageInscriptionButtonProps {
  sportEvent: SportEvent
  category: Category
  canInscribe: boolean
  user: UserQuery
  date: string
  inscription: Inscription | undefined
}

function calcCanInscribe(props: {
  approvedLicense?: ApprovedLicense
  documents: Documents
  draftLicense?: CategoryDetailsWithCategory
}) {
  const { approvedLicense, documents, draftLicense } = props

  if (approvedLicense) return openLicenseTasks({ license: approvedLicense, documents }).allDone

  return !draftLicense
}

function ManageInscriptionButton(props: ManageInscriptionButtonProps & { documents: Documents }) {
  const { sportEvent, category, canInscribe, user, date, inscription, documents } = props
  const { data: licenseTasks, ...restLicenseTasks } = useLicenseTasksOverview(user, nextLicenseYear)

  const tasks =
    inscription &&
    (inscription.type === 'enlistedDayInscriptionYearCategory' ||
      inscription.type === 'enlistedDayInscriptionYearCategoryDraft' ||
      inscription.type === 'enlistedLicenseInscription')
      ? generalInscriptionTasks({
          inscription,
          documents,
          category: categoryOfAssociationRequired(inscription.category, inscription.association),
        })
      : undefined

  return (
    <>
      <LoadingOrErrorBox error={restLicenseTasks.error} loading={restLicenseTasks.loading} />

      {licenseTasks && (
        <RoutedButton
          variant={
            inscription?.type === 'unlistedLicenseInscription'
              ? 'contained'
              : tasks?.allDone
              ? 'outlined'
              : 'contained'
          }
          color={
            inscription?.type === 'unlistedLicenseInscription'
              ? 'default'
              : tasks?.allDone
              ? 'primary'
              : 'primary'
          }
          to={routes.newInscription.generateTo(sportEvent, category, date)}
          disabled={!canInscribe}
        >
          {routes.newInscription.generateShort(date)} (
          {tasks
            ? inscriptionStatusLong(inscription, tasks)
            : inscriptionStatusLong(inscription, licenseTasks)}
          )
        </RoutedButton>
      )}
    </>
  )
}
