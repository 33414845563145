import {
  init,
  setUser,
  captureEvent as sentryCaptureEvent,
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
} from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import type { CaptureContext, Event, Severity } from '@sentry/types'
import { User } from 'app/users/user'
import { isLocalDevelopment } from 'utils/debug'
import { env } from 'utils/env'

export function initSentry() {
  if (enableSentry())
    init({
      dsn: isLocalDevelopment()
        ? 'https://e010059f33cf436ab861532890042409@o514841.ingest.sentry.io/6096380'
        : 'https://f53b6a26fc8b418d8f3fc6e3c3f0c57d@o514841.ingest.sentry.io/5618702',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: isLocalDevelopment() ? 0.001 : 0.1,
      release: env().REACT_APP_VERSION || 'unknown',
    })
}

export function setSentryUser(user: User | undefined) {
  if (enableSentry())
    setUser({
      email: user?.email || undefined,
      id: user?.uid || undefined,
      displayName: user?.displayName || undefined,
    })
}

/**
 * Captures a manually created event and sends it to Sentry.
 *
 * @param event The event to send to Sentry.
 * @returns The generated eventId.
 */
export function captureEvent(event: Event): string {
  return enableSentry() ? sentryCaptureEvent(event) : ''
}

/**
 * Captures an exception event and sends it to Sentry.
 *
 * @param exception An exception-like object.
 * @returns The generated eventId.
 */
export function captureException(exception: any, captureContext?: CaptureContext): string {
  return enableSentry() ? sentryCaptureException(exception, captureContext) : ''
}

/**
 * Captures a message event and sends it to Sentry.
 *
 * @param message The message to send to Sentry.
 * @param level Define the level of the message.
 * @returns The generated eventId.
 */
export function captureMessage(message: string, captureContext?: CaptureContext | Severity): string {
  return enableSentry() ? sentryCaptureMessage(message, captureContext) : ''
}

function enableSentry() {
  return true
}
