import { Box, LinearProgress, Paper } from '@material-ui/core'
import { FriendlyError } from 'utils/errors'

export function LoadingOrErrorBoxMulti({
  objects,
}: {
  objects: { loading: boolean; error: unknown }[]
}) {
  const loading = objects.some((obj) => obj.loading)
  const error = objects.find((obj) => obj.error)?.error

  return <LoadingOrErrorBox loading={loading} error={error} />
}

export function LoadingOrErrorBox({ loading, error }: { loading: boolean; error: unknown }) {
  return loading || error ? (
    <Paper elevation={3}>
      <Box mb={2} p={2} pb={1}>
        <FriendlyError error={error} />
        {loading && <LinearProgress />}
      </Box>
    </Paper>
  ) : null
}
