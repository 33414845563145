import {
  Box,
  FormControlLabel,
  FormGroup,
  Typography,
  Checkbox,
  Collapse,
  Button,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useState } from 'react'
import { useAllDocuments } from 'app/db/db-contexts/documents-context'
import { MaybeFBError } from 'app/db/db-hooks/db-hook-helpers'
import { actions, tableHeaders } from 'app/export/table'
import { useRacemanagerTitle } from 'app/layouts/route-with-error-boundary-and-title'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { transactionTableContents } from 'app/pages/admin/financials/flat-ransactions'
import { useSportEventFinancials } from 'app/sport-events/sport-events-financials-hooks'
import { useUserContext } from 'app/themes/user-context'
import { useYearContext } from 'app/themes/year-context'
import { routes } from 'shared/config/routes'
import { categoryById } from 'shared/data/categories-service'
import { UserQuery } from 'shared/db/db'
import { sortedTransactionsWithTotal } from 'shared/db/transactions-service'
import { t } from 'shared/i18n/current'
import { associationByID, AssociationID, associations } from 'shared/models/associations'
import { nameWithPlace } from 'shared/models/personal-data'
import { deserializeLicenseCategoryIDs } from 'shared/sport-events/sport-event-categories-serialization'
import {
  SportEventFinancialRowByInscription,
  sportEventTotals,
  groupByCategoryType,
  groupByCategory,
  AmountBySource,
  SportEventFinancialRowByCategory,
  SportEventFinancialRowByCategoryType,
  SportEventFinancialRowSummary,
} from 'shared/sport-events/sport-event-financials-service'
import { sportEventDescription } from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'
import { toChf } from 'shared/utils/number'
import { RawCellData, TableHeader } from 'shared/utils/table-data'
import { BreadcrumbsList } from 'utils/breadcrumbs'
import { RoutedButton } from 'utils/buttons/routed-button'
import { useSearchQuery } from 'utils/router'
import { useBoolean } from 'utils/use-boolean'

export function SportEventFinancials({ admin }: { admin: UserQuery }) {
  const query = useSearchQuery()
  const sportEventIds = query.q.split(',').filter(truthy)
  const userContext = useUserContext()
  const [rawSelectedAssociation, setSelectedAssociation] = useState<AssociationID | 'all' | 'default'>(
    'default'
  )
  const { data: documents, loading: documentsLoading, error: documentsError } = useAllDocuments()
  const allAssociation = { id: 'all', name: 'Alle' } as const
  const selectedAssociation =
    [allAssociation, ...associations].find((association) =>
      rawSelectedAssociation === allAssociation.id
        ? association.id === 'all'
        : rawSelectedAssociation === 'default' && userContext.associationAdmin
        ? association.id === userContext.associationAdmin
        : rawSelectedAssociation === 'default' && !userContext.associationAdmin
        ? association.id === 'all'
        : association.id === rawSelectedAssociation
    ) || allAssociation
  const association = userContext.admin ? 'all' : userContext.associationAdmin || 'none'
  const showSportEventsSelection = useBoolean(false)

  const year = useYearContext().year
  const { loading, error, loadingOrError, inscriptionsWithFinanacials, sportEvents, bookings } =
    useSportEventFinancials(year, sportEventIds, association, selectedAssociation.id)

  const selectedSportEvents = sportEvents.filter((event) => sportEventIds.includes(event.id))

  const canAccess = sportEventIds.every((sportEventId) => {
    const sportEvent = sportEvents.find((event) => event.id === sportEventId)
    return (
      sportEvent &&
      (userContext.canViewAssociation(sportEvent.association) ||
        deserializeLicenseCategoryIDs(sportEvent.licenseCategoryIds).some((categoryId) =>
          categoryById(categoryId)?.associations.some((associationId) =>
            userContext.canViewAssociation(associationId)
          )
        ))
    )
  })

  const title = `${t().financials.financialsFor} ${
    selectedSportEvents.length === 1 ? sportEventDescription(selectedSportEvents[0]) : t().multipleEvents
  }`
  useRacemanagerTitle(title)

  return (
    <>
      <BreadcrumbsList
        base="adminSportEvents"
        links={[
          [
            routes.sportEventFinancials.generateTo(query.q),
            routes.sportEventFinancials.textAlt(selectedSportEvents),
          ],
        ]}
      />
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {title}
        </Typography>
      </Box>

      <Box mb={1}>
        <Autocomplete<{ id: AssociationID | 'all'; name: string }>
          id="association"
          options={[allAssociation, ...associations]}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label={t().association} variant="outlined" />}
          value={selectedAssociation}
          onChange={(_event, value) => setSelectedAssociation(value?.id || 'all')}
        />
      </Box>

      <Box pb={3}>
        <Button onClick={() => showSportEventsSelection.toggle()}>{t().financials.eventFilter}</Button>
        {selectedSportEvents.length === 1 && (
          <RoutedButton
            to={routes.manualPayments.generateToFromSportEvent(selectedSportEvents[0])}
            onClick={() => showSportEventsSelection.toggle()}
          >
            {t().financials.cashOnEvent}
          </RoutedButton>
        )}
        <Collapse in={showSportEventsSelection.value}>
          <FormGroup row>
            {sportEvents.map((sportEvent) => (
              <FormControlLabel
                key={sportEvent.id}
                control={
                  <Checkbox
                    checked={sportEventIds.includes(sportEvent.id)}
                    onChange={() => query.toggle(sportEvent.id)}
                    name={sportEvent.id}
                  />
                }
                label={sportEventDescription(sportEvent)}
              />
            ))}
          </FormGroup>
        </Collapse>
      </Box>

      {selectedSportEvents.length === 0 ? (
        <>{t().financials.selectEvent}</>
      ) : !canAccess ? (
        <>{t().financials.infoExternalEvent}</>
      ) : (
        <>
          <FinancialsTotals
            loading={loading}
            error={error}
            loadingOrError={loadingOrError}
            data={sportEventTotals(inscriptionsWithFinanacials)}
          />

          <FinancialsByCategoryType
            loading={loading}
            error={error}
            loadingOrError={loadingOrError}
            data={groupByCategoryType(inscriptionsWithFinanacials)}
          />

          <FinancialsByCategory
            loading={loading}
            error={error}
            loadingOrError={loadingOrError}
            data={groupByCategory(inscriptionsWithFinanacials)}
          />

          <FinancialsByInscription
            admin={admin}
            loading={loading}
            error={error}
            loadingOrError={loadingOrError}
            data={inscriptionsWithFinanacials}
          />

          {userContext.user && (
            <TableBox
              title={t().routes.allTransactions}
              loading={loading || documentsLoading}
              error={error || documentsError}
              data={
                !loadingOrError &&
                transactionTableContents(
                  sortedTransactionsWithTotal(bookings).map((transaction) => ({
                    transaction,
                    uidName: nameWithPlace(documents[transaction.uid]?.personalData),
                    byUidName: nameWithPlace(documents[transaction.byUid]?.personalData),
                  })),
                  userContext.user,
                  userContext,
                  sportEvents
                )
              }
            />
          )}
        </>
      )}
    </>
  )
}

function FinancialsTotals(props: {
  loading: boolean
  error: MaybeFBError
  loadingOrError: boolean
  data: SportEventFinancialRowSummary
}) {
  const { loading, error, loadingOrError, data } = props
  return (
    <TableBox
      title="Total"
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: amountHeaders(),
          contents: [amountContents(data)],
          selected: [data.amount.open ? 'warning' : ''],
          rawData: [JSON.stringify(data)],
        }
      }
    />
  )
}

function FinancialsByCategoryType(props: {
  loading: boolean
  error: MaybeFBError
  loadingOrError: boolean
  data: SportEventFinancialRowByCategoryType[]
}) {
  const { loading, error, loadingOrError, data } = props
  return (
    <TableBox
      title={t().financials.byCategoryType}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: tableHeaders([t().categoryType, ...amountHeaders()]),
          contents: data.map((row) => [row.categoryTypeName, ...amountContents(row)]),
          selected: data.map((row) => (row.amount.open ? 'warning' : '')),
          ids: data.map(({ categoryTypeName }) => categoryTypeName),
          rawData: data.map((row) => JSON.stringify(row)),
        }
      }
    />
  )
}

function FinancialsByCategory(props: {
  loading: boolean
  error: MaybeFBError
  loadingOrError: boolean
  data: SportEventFinancialRowByCategory[]
}) {
  const { loading, error, loadingOrError, data } = props
  return (
    <TableBox
      title={t().financials.byCategory}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: tableHeaders([t().category, t().categoryType, ...amountHeaders()]),
          contents: data.map((row) => [
            row.category.name,
            row.category.typeName,
            ...amountContents(row),
          ]),
          selected: data.map((row) => (row.amount.open ? 'warning' : '')),
          ids: data.map(({ category }) => category.id),
          rawData: data.map((row) => JSON.stringify(row)),
        }
      }
    />
  )
}

function FinancialsByInscription(props: {
  loading: boolean
  error: MaybeFBError
  loadingOrError: boolean
  data: SportEventFinancialRowByInscription[]
  admin: UserQuery
}) {
  const { loading, error, loadingOrError, data, admin } = props

  return (
    <TableBox
      title={t().financials.byInscription}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: tableHeaders([
            t().sportEvent,
            t().user,
            t().category,
            t().categoryType,
            ...amountHeaders(),
            t().association,
            actions(),
          ]),
          // eslint-disable-next-line react/display-name
          contents: data.map((row) => () => [
            row.sportEvent === 'unknown' ? row.sportEvent : sportEventDescription(row.sportEvent),
            row.riderName,
            row.category.name,
            row.category.typeName,
            ...amountContents(row),
            row.association === 'none'
              ? '-'
              : row.association === 'multiple'
              ? 'Mehrere'
              : associationByID(row.association).name,
            <FinancialDialog key="dialog" admin={admin} user={row} />,
          ]),
          selected: data.map((row) => (row.amount.open ? 'warning' : '')),
          ids: data.map(({ uid, category }, index) => [uid, category.id, index].join(' | ')),
          rawData: data.map((row) => JSON.stringify(row)),
        }
      }
    />
  )
}

function amountHeaders(): TableHeader[] {
  const format = (el: RawCellData) => (typeof el === 'number' ? toChf(el) : el.toString())
  return [
    { align: 'right', value: 'Anzahl Buchungsgruppen' },
    { align: 'right', format, value: t().sportEventFinancialsAmounts.total },
    { align: 'right', format, value: t().sportEventFinancialsAmounts.inscription },
    { align: 'right', format, value: t().sportEventFinancialsAmounts.inscriptionDiscount },
    { align: 'right', format, value: t().sportEventFinancialsAmounts.power },
    { align: 'right', format, value: t().sportEventFinancialsAmounts.dayLicense },
    { align: 'right', format, value: t().sportEventFinancialsAmounts.donation },
    { align: 'right', format, value: t().sportEventFinancialsAmounts.open },
  ]
}

function amountContents(row: { amount: AmountBySource }) {
  return [
    row.amount.count,
    row.amount.total,
    row.amount.inscription,
    row.amount.inscriptionDiscount,
    row.amount.power,
    row.amount.dayLicense,
    row.amount.donation,
    row.amount.open,
  ]
}

// export const mockData: SportEventFinancialRowByInscription[] = [
//   {
//     uid: 'eDCmMUaqbzeSmjAX0lS24mGYymS2',
//     riderName: 'Marion Schleifer',
//     category: categoryByIdRequired('motocross-swiss-mx-women-cup'),
//     amount: { total: 80, cash: 40, bank: 20, manual: 15, open: 5 },
//   },
//   {
//     uid: 'HPuir13jHkSPxxujCcGnSyZIGKm1',
//     riderName: 'Lukas Elmer',
//     category: categoryByIdRequired('motocross-masters-sam-open'),
//     amount: { total: 80, cash: 40, bank: 20, manual: 15, open: 0 },
//   },
//   {
//     uid: 'HPuir13jHkSPxxujCcGnSyZIGKm1',
//     riderName: 'Lukas Elmer',
//     category: categoryByIdRequired('motocross-pro-race-serie-einzellizenz'),
//     amount: { total: 80, cash: 40, bank: 20, manual: 15, open: 0 },
//   },
//   {
//     uid: '3',
//     riderName: 'Sumo Suter',
//     category: categoryByIdRequired('supermoto-challenge'),
//     amount: { total: 80, cash: 40, bank: 20, manual: 15, open: 0 },
//   },
//   {
//     uid: '4',
//     riderName: 'Melanie Mofacup',
//     category: categoryByIdRequired('mofacup-open-100'),
//     amount: { total: 80, cash: 40, bank: 20, manual: 15, open: 0 },
//   },
//   {
//     uid: '5',
//     riderName: 'Max Mofacup',
//     category: categoryByIdRequired('mofacup-open-100'),
//     amount: { total: 80, cash: 40, bank: 20, manual: 15, open: 0 },
//   },
// ]
