import { Box, TextField } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import {
  useActiveSportEvent,
  useSearchDocumentsWithLicenses,
  useSportEventInscriptions,
} from 'app/db/db-hooks/main-db-hooks'
import { actions } from 'app/export/table'
import { InscribeButton } from 'app/inscription/inscribe-button'
import { useIsSmallerThanSm } from 'app/layout/use-small-screen'
import { TableBox } from 'app/layouts/table-box'
import { EditLicenseButton, NewLicenseButton } from 'app/license/edit-license-button'
import { ScanQRLicenseButton } from 'app/license/qr-code/scan-qr-license-button'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { EmergencyDetailsButton } from 'app/pages/admin/licenses/emergency-button'
import { LicenseAttachmentsButton } from 'app/pages/admin/licenses/license-attachments-button'
import { EditEmailButton, ImpersonateButton } from 'app/pages/admin/users/user-management'
import { FinancialBox } from 'app/pages/dashboard/financial-box'
import { useUserContext } from 'app/themes/user-context'
import { routes } from 'shared/config/routes'
import { categoryByIdRequired, categoryCommonName } from 'shared/data/categories-service'
import { displayIssuedNumber, hasIssuedNumber } from 'shared/data/licenses-service'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { sportEventDatesForLicense } from 'shared/sport-events/sport-events-service'
import { formatDateDe, parseDate } from 'shared/utils/date'
import { RoutedButton } from 'utils/buttons/routed-button'
import { useSearchQuery } from 'utils/router'

interface AssignLicenseProps {
  admin: UserQuery
}

export function AssignLicense({ admin }: AssignLicenseProps) {
  const search = useSearchQuery()
  const { data, loading, error, loadingOrError } = useSearchDocumentsWithLicenses(search.q)
  const { data: sportEvent } = useActiveSportEvent(admin)
  const { data: inscriptions } = useSportEventInscriptions({ sportEventId: sportEvent?.id })
  const smallerThanSm = useIsSmallerThanSm()
  const userContext = useUserContext()
  const tooManyEntries = data.length > 30 && !userContext.admin

  return (
    <>
      <TableBox
        title={t().assignLicense.title}
        loading={loading}
        error={error}
        disableDownloadButtons={!userContext.admin}
        more={
          <>
            {tooManyEntries && (
              <Alert severity="warning" variant="outlined">
                <AlertTitle>{t().assignLicense.tooManyResults.title}</AlertTitle>
                {t().assignLicense.tooManyResults.text}
              </Alert>
            )}
            <Box mt={2}>
              <Alert severity="info" variant="outlined">
                {t().assignLicense.onlyRegisteredRiders}
              </Alert>
            </Box>
          </>
        }
        data={
          !loadingOrError && {
            headers: [
              { value: t().lastName },
              { value: t().firstName },
              { value: t().street, exportOnly: true },
              { value: t().zip },
              { value: t().place },
              { value: t().country },
              { value: t().email },
              { value: t().phone, exportOnly: true },
              { value: t().birthdate },
              { value: t().birthdateDe, exportOnly: true },
              { value: t().licensesAndDrafts },
              actions(),
            ],
            contents: tooManyEntries
              ? []
              : data.map((documentsWithContext) => {
                  const documents = documentsWithContext.documents
                  const licenses = documentsWithContext.licensesWithContext
                  const birthdate = documents.personalData?.birthdate
                  return [
                    documents.personalData?.lastName || '',
                    documents.personalData?.firstName || '',
                    documents.personalData?.street || '',
                    documents.personalData?.zip || '',
                    documents.personalData?.place || '',
                    documents.personalData?.country || '',
                    documents.personalData?.email || '',
                    documents.personalData?.phone || '',
                    birthdate || '',
                    formatDateDe(parseDate(birthdate)),
                    <>
                      {licenses
                        .filter((licenseWithContext) =>
                          userContext.canViewLicense(licenseWithContext.license)
                        )
                        .map((licenseWithContext, index) => {
                          const license = licenseWithContext.license
                          return (
                            <div key={index}>
                              {userContext.canEditLicense(license) && (
                                <EditLicenseButton
                                  licenseWithContext={licenseWithContext}
                                  admin={admin}
                                />
                              )}
                              {sportEvent &&
                                license.type === 'approved' &&
                                sportEventDatesForLicense(sportEvent, license.approved).map((date) => (
                                  <InscribeButton
                                    key={date}
                                    personalData={documents.personalData}
                                    inscriptions={inscriptions}
                                    sportEvent={sportEvent}
                                    license={license.approved}
                                    admin={admin}
                                    date={date}
                                  />
                                ))}
                              {license.type === 'draft'
                                ? `${categoryCommonName(license.draft.category)} (${
                                    license.draft.categoryDetails?.preferredNumber
                                  })`
                                : `${categoryCommonName(
                                    categoryByIdRequired(license.approved.categoryId)
                                  )}${
                                    hasIssuedNumber(license.approved)
                                      ? ` (${displayIssuedNumber(license.approved)})`
                                      : ''
                                  }`}
                            </div>
                          )
                        })}
                    </>,
                    <>
                      <FinancialDialog admin={admin} user={documents} />
                      <LicenseAttachmentsButton
                        user={{ uid: documents.uid }}
                        documents={documents}
                        admin={admin}
                      />
                      <NewLicenseButton
                        user={{ uid: documents.uid }}
                        documents={documents}
                        admin={admin}
                        licenses={licenses}
                      />
                      <EmergencyDetailsButton user={{ uid: documents.uid }} admin={admin} />
                      <ImpersonateButton user={{ uid: documents.uid }} />
                      <EditEmailButton
                        user={{ uid: documents.uid, email: documents.personalData?.email || '?' }}
                      />
                    </>,
                  ]
                }),
            ids: tooManyEntries ? [] : data.map(({ uid }) => uid),
            rawData: tooManyEntries
              ? []
              : data.map((row) =>
                  JSON.stringify({
                    ...row,
                    sportEvent: sportEvent?.id,
                    inscriptions: inscriptions?.length,
                    smallerThanSm,
                  })
                ),
          }
        }
      >
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <TextField
              label={t().licenses.searchLicense}
              variant="outlined"
              size="small"
              fullWidth
              value={search.q}
              onChange={(event) => search.set(event.currentTarget.value)}
            />
          </Box>
          <Box px={1}>
            <RoutedButton to={routes.licenseDrafts.generateTo(search.q)}>
              {routes.licenseDrafts.text()}
            </RoutedButton>
            <RoutedButton to={routes.approvedLicenses.generateTo(search.q)}>
              {routes.approvedLicenses.text()}
            </RoutedButton>
            {sportEvent?.id && (
              <RoutedButton to={routes.inscriptions.generateTo(search.q, sportEvent.id)}>
                {routes.inscriptions.text()}
              </RoutedButton>
            )}
            <ScanQRLicenseButton onSuccess={(approvedLicense) => search.set(approvedLicense.uid)} />
          </Box>
        </Box>
      </TableBox>

      {data.length === 1 && <FinancialBox user={{ uid: data[0].uid }} admin={admin} />}
    </>
  )
}
