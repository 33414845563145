import assertNever from 'assert-never'
import { setLocale as setYupLocale } from 'yup'
import { de } from 'shared/i18n/de'
import { en } from 'shared/i18n/en'
import { fr } from 'shared/i18n/fr'
import { I18nLocales, I18nType, isLocale } from 'shared/i18n/i18n-types'
import { yupLocales } from 'shared/i18n/i18n-yup/yup'
import { setDateLocale } from 'shared/utils/date'

let internalCurrentLocale = loadDefaultLocale()
let internalCurrentT = loadT(internalCurrentLocale)

export function todoT(text: string) {
  return text
}

export function t(): I18nType {
  return internalCurrentT
}

export function tYup() {
  return yupLocales[internalCurrentLocale]
}

export function currentLocale(): I18nLocales {
  return internalCurrentLocale
}

export function setLocale(locale: I18nLocales) {
  setYupLocale(yupLocales[locale])
  setDateLocale(locale)
  internalCurrentLocale = locale
  internalCurrentT = loadT(locale)
  globalThis.localStorage?.setItem('locale', locale)
}

export async function temporarilyChangeLocale(locale: I18nLocales, fn: () => void | Promise<any>) {
  const before = currentLocale()
  setLocale(locale)
  await fn()
  setLocale(before)
}

export function loadT(locale: I18nLocales) {
  return locale === 'de' ? de : locale === 'en' ? en : locale === 'fr' ? fr : assertNever(locale)
}

export function loadDefaultLocale(): I18nLocales {
  const language = globalThis.navigator?.language || ''

  const localStorageLocale = globalThis?.localStorage?.getItem('locale')
  if (isLocale(localStorageLocale)) return localStorageLocale

  if (language.startsWith('de')) return 'de'
  if (language.startsWith('en')) return 'en'
  if (language.startsWith('fr')) return 'fr'

  return 'de'
}
