import { differenceInSeconds } from 'date-fns'
import { CanvasRef } from 'app/license/qr-code/canvas-ref'
import {
  canScanQRCodeNatively,
  scanQRCodeFromImageNatively,
} from 'app/license/qr-code/strategies/scan-qr-code-natively'
import { scanQRCodeFromImageWithJSQRLibrary } from 'app/license/qr-code/strategies/scan-qr-code-with-jsqr-library'
import { scanQRCodeFromImageWithQRScannerLibrary } from 'app/license/qr-code/strategies/scan-qr-code-with-qr-scanner-library'
import { scanQRCodeFromImageWithZXingLibrary } from 'app/license/qr-code/strategies/scan-qr-code-with-zxing-library'
import { t } from 'shared/i18n/current'
import { truthy } from 'shared/utils/array'
import { sleep } from 'shared/utils/time'

const ImageCapture = loadImageCapture()
const bitmapCaptureType = 'frame'

type BitmapUpdated = (bitmap: ImageBitmap) => void
export type MatchQRCode<T> = (url: string) => T | undefined

export async function continuouslyScanQRCode<T>(
  stopScanning: { current: boolean },
  match: MatchQRCode<T>,
  canvasRef: CanvasRef,
  bitmapUpdated: BitmapUpdated
): Promise<T | undefined> {
  const maxSecondsToScan = 15
  const msBetweenScans = 50

  const stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: { facingMode: 'environment' },
  })
  const tracks = stream.getVideoTracks()

  const start = new Date()
  try {
    while (Math.abs(differenceInSeconds(start, new Date())) < maxSecondsToScan) {
      if (stopScanning.current) return
      const codes = await Promise.all(
        tracks.map((track) => captureQRScan(track, bitmapUpdated, canvasRef))
      )
      const result = codes
        .filter(truthy)
        .map((code) => match(code))
        .find(truthy)
      if (result) return result
      if (stopScanning.current) return
      await sleep(msBetweenScans)
    }
  } finally {
    tracks.forEach((s) => s.stop())
  }
}

// isValidCode, parseCode

async function captureQRScan(
  track: MediaStreamTrack,
  bitmapUpdated: BitmapUpdated,
  canvasRef: CanvasRef
) {
  if (!ImageCapture) throw new Error(t().qrCodeScanNotAvailable)

  const imageCapture = new ImageCapture(track)
  const bitmap = await toBitmap(imageCapture)
  bitmapUpdated(bitmap)
  return await scanQRCodeFromImage(bitmap, canvasRef)
}

async function toBitmap(imageCapture: ImageCaptureInstance) {
  if (bitmapCaptureType === 'frame' && imageCapture.grabFrame) return await imageCapture.grabFrame()

  const photo = await imageCapture.takePhoto()
  return await createImageBitmap(photo)
}

async function scanQRCodeFromImage(image: ImageBitmap, canvasRef: CanvasRef) {
  if (await canScanQRCodeNatively()) return await scanQRCodeFromImageNatively(image)

  return (
    (await scanQRCodeFromImageWithQRScannerLibrary(image)) ||
    (await scanQRCodeFromImageWithZXingLibrary(canvasRef)) ||
    (await scanQRCodeFromImageWithJSQRLibrary(canvasRef))
  )
}

export function canScanQRCode() {
  return !!ImageCapture
}

function loadImageCapture(): ImageCaptureType | undefined {
  return (window as any).ImageCapture
}

interface ImageCaptureType {
  new (track: MediaStreamTrack): ImageCaptureInstance
}

interface ImageCaptureInstance {
  takePhoto(): Promise<ImageBitmapSource>
  grabFrame(): Promise<ImageBitmap>
}
