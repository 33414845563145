import { Box, Button, InputAdornment, TextField } from '@material-ui/core'
import { Add, Clear } from '@material-ui/icons'
import { UseWithObj } from 'app/db/db-hooks/db-hook-helpers'
import { useSearchDocuments } from 'app/db/db-hooks/main-db-hooks'
import { actions } from 'app/export/table'
import { ButtonWithTooltip } from 'app/layout/button-with-tooltip'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { AddFineButton } from 'app/pages/admin/inscriptions/AddFineButton'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { NewInscriptionForm, NewInscriptionFormProps } from 'app/pages/inscription/new-inscription-page'
import { useAdmin } from 'app/themes/user-context'
import {
  categoryByIdRequired,
  categoryCommonNameEndUserWithAssociation,
} from 'shared/data/categories-service'
import { DayCategory } from 'shared/db/day-category'
import { Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { SportEvent } from 'shared/sport-events/sport-events'
import { dayCategoryDates, licenseCategoryDates } from 'shared/sport-events/sport-events-service'
import { pDateWeekdayName } from 'shared/utils/date'
import { strictEntries } from 'shared/utils/object'
import { SearchQuery, useSearchQuery } from 'utils/router'

interface AddInscriptionButtonProps {
  sportEvent: SportEvent
  dayCategories: Record<string, DayCategory>
}

export function AddInscriptionButton(props: AddInscriptionButtonProps) {
  const { sportEvent, dayCategories } = props
  const dialog = useDialog()

  return (
    <>
      <IconButtonWithTooltip onClick={() => dialog.open()} tooltip={t().inscription.addNewInscription}>
        <Add />
      </IconButtonWithTooltip>
      <ConfirmDialog fullWidth title={t().inscription.addInscription} dialog={dialog}>
        <AddInscriptionForm sportEvent={sportEvent} dayCategories={dayCategories} />
      </ConfirmDialog>
    </>
  )
}

export function AddInscriptionForm(props: AddInscriptionButtonProps) {
  const { sportEvent, dayCategories } = props
  const search = useSearchQuery()
  const documents = useSearchDocuments(search.q)
  const { data } = documents

  return (
    <>
      {data.length === 1 && (
        <>
          <AddInscriptionFormInscriptionButtons
            documents={data[0]}
            sportEvent={sportEvent}
            dayCategories={dayCategories}
          />
          <AddFineButton user={{ uid: data[0].uid }} />
        </>
      )}
      <AddInscriptionFormUserSearch search={search} documents={documents} />
    </>
  )
}

interface AddInscriptionFormInscriptionButtonsProps {
  documents: Documents & { uid: string }
  sportEvent: SportEvent
  dayCategories: Record<string, DayCategory>
}

function AddInscriptionFormInscriptionButtons(props: AddInscriptionFormInscriptionButtonsProps) {
  const { documents, sportEvent, dayCategories } = props
  return (
    <ElevatedBox title={t().chooseCategoryAndDay}>
      {strictEntries(licenseCategoryDates(sportEvent)).map(([categoryId, dates]) => {
        return (
          <Box key={categoryId}>
            {dates.map((date) => (
              <NewInscriptionFormButton
                key={`${categoryId}-${date}`}
                user={{ uid: documents.uid }}
                documents={documents}
                categoryId={categoryId}
                sportEventId={sportEvent.id}
                date={date}
                categoryName={categoryCommonNameEndUserWithAssociation(categoryByIdRequired(categoryId))}
              />
            ))}
          </Box>
        )
      })}

      {strictEntries(dayCategoryDates(sportEvent)).map(([categoryId, dates]) => {
        return (
          <Box key={categoryId}>
            {dates.map((date) => (
              <NewInscriptionFormButton
                key={`${categoryId}-${date}`}
                user={{ uid: documents.uid }}
                documents={documents}
                categoryId={categoryId}
                sportEventId={sportEvent.id}
                date={date}
                categoryName={categoryCommonNameEndUserWithAssociation(dayCategories[categoryId])}
              />
            ))}
          </Box>
        )
      })}
    </ElevatedBox>
  )
}

function NewInscriptionFormButton(props: NewInscriptionFormProps & { categoryName: string }) {
  const dialog = useDialog()
  return (
    <>
      <ButtonWithTooltip onClick={() => dialog.open()} tooltip={t().detailsAndEdit}>
        {pDateWeekdayName(props.date)}: {props.categoryName}
      </ButtonWithTooltip>
      <ConfirmDialog fullWidth title={t().inscription.editInscription} dialog={dialog}>
        <NewInscriptionForm admin {...props} />
      </ConfirmDialog>
    </>
  )
}

interface AddInscriptionFormUserSearchProps {
  search: SearchQuery
  documents: UseWithObj<
    (Documents & {
      uid: string
    })[]
  >
}

function AddInscriptionFormUserSearch(props: AddInscriptionFormUserSearchProps) {
  const admin = useAdmin()
  const { search, documents } = props
  const { loading, error, loadingOrError, data } = documents

  return (
    <TableBox
      maxEntries={5}
      title={t().searchRider}
      loading={loading}
      error={error}
      disableDownloadButtons
      data={
        !loadingOrError && {
          headers: [
            { value: t().lastName },
            { value: t().firstName },
            { value: t().zip },
            { value: t().place },
            { value: t().email },
            { value: t().birthdate },
            actions(),
          ],
          // eslint-disable-next-line react/display-name
          contents: data.map((docs) => () => {
            return [
              docs.personalData?.lastName || '',
              docs.personalData?.firstName || '',
              docs.personalData?.zip || '',
              docs.personalData?.place || '',
              docs.personalData?.email || '',
              docs.personalData?.birthdate || '',
              <>
                <FinancialDialog user={docs} admin={admin} />
                {data.length === 1 ? (
                  <ButtonWithTooltip
                    disabled
                    tooltip={t().selectedRiderTooltip}
                    variant="outlined"
                    size="small"
                  >
                    Bereits Ausgewählt
                  </ButtonWithTooltip>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => search.set(docs.uid)}
                  >
                    Auswählen
                  </Button>
                )}
              </>,
            ]
          }),
          ids: data.map(({ uid }) => uid),
          rawData: data.map((row) => JSON.stringify({ row, q: search.q })),
        }
      }
    >
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButtonWithTooltip
                    tooltip={t().deleteEntry}
                    size="small"
                    onClick={() => search.set('')}
                  >
                    <Clear />
                  </IconButtonWithTooltip>
                </InputAdornment>
              ),
            }}
            autoFocus
            label={t().searchRiderLabel}
            variant="outlined"
            size="small"
            fullWidth
            value={search.q}
            onChange={(event) => search.set(event.currentTarget.value)}
          />
        </Box>
      </Box>
    </TableBox>
  )
}
