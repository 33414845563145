import { tableHeaders } from 'app/export/table'
import { downloadXlsx } from 'app/export/xlsx'
import { categoryById, categoryCommonName } from 'shared/data/categories-service'
import { LicenseTasksOverview, licenseTaskTypes } from 'shared/data/license-tasks-overview'
import { displayIssuedNumber, fullLicenseId } from 'shared/data/licenses-service'
import { ApprovedLicenseWithContext } from 'shared/db/db'
import { licenseTypeName } from 'shared/db/license-type'
import { t } from 'shared/i18n/current'
import { taskShortName } from 'shared/license/license-tasks'
import { associationNameWithDefault } from 'shared/models/associations'
import { Transponder } from 'shared/models/transponder'
import { TransponderType } from 'shared/models/transponder-type'
import { truthy } from 'shared/utils/array'
import { formatDateDe, parseDate } from 'shared/utils/date'
import { TableData } from 'shared/utils/table-data'

export async function downloadEventOrganizerList(licenses: ApprovedLicenseWithContext[]) {
  const data = csvData(licenses)
  await downloadXlsx(data, 'veranstalterliste')
}

function csvData(data: ApprovedLicenseWithContext[]): TableData {
  const headers = [
    t().id,
    t().open,
    t().category,
    t().issuedNumber.labelAlternative,
    t().lastName,
    t().firstName,
    t().association,
    t().teamName.labelShort,
    t().bike,
    t().street,
    t().zip,
    t().place,
    t().country,
    t().email,
    t().phone,
    t().birthdate,
    t().transponderNumbers.label,
    t().transponderNumbersRf.label,
    t().licenseType,
  ]
  const contents = data.map((licenseWithContext) => {
    const license = licenseWithContext.license
    const birthdate = license.documents.personalData?.birthdate
    const category = categoryById(license.approved.categoryId)
    return [
      fullLicenseId(license.approved),
      openLicenseTasksSummary(licenseWithContext.tasks),
      categoryCommonName(category),
      displayIssuedNumber(license.approved),
      license.documents.personalData?.lastName || '',
      license.documents.personalData?.firstName || '',
      associationNameWithDefault(license.approved.licenseAssociation),
      license.approved.teamName || '',
      license.approved.bikeMake || '',
      license.documents.personalData?.street || '',
      license.documents.personalData?.zip || '',
      license.documents.personalData?.place || '',
      license.documents.personalData?.country || '',
      license.documents.personalData?.email || '',
      license.documents.personalData?.phone || '',
      formatDateDe(parseDate(birthdate)),
      allTransponderNumbers(license.documents.transponder?.transponders, 'MyLaps').join(', '),
      allTransponderNumbers(license.documents.transponder?.transponders, 'RF').join(', '),
      licenseTypeName(license.approved.licenseType),
    ]
  })
  return { contents, headers: tableHeaders(headers), rawData: [] }
}

export function firstTransponderNumber(
  transponders: Transponder['transponders'] | undefined,
  type: TransponderType
) {
  return allTransponderNumbers(transponders, type)[0] || ''
}

export function allTransponderNumbers(
  transponders: Transponder['transponders'] | undefined,
  type: TransponderType
) {
  return Object.values(transponders || {})
    .filter((t) => t.type === type)
    .map((t) => t.transponderNumber)
    .filter(truthy)
}

export function openLicenseTasksSummary(tasks: LicenseTasksOverview) {
  return [
    ...licenseTaskTypes().map((taskName) => {
      const task = tasks.tasks.find((task) => task.type === taskName)
      return task && !task.done ? taskShortName(taskName) : undefined
    }),
  ]
    .filter(truthy)
    .join(', ')
}
