import {
  UploadMetadata,
  UploadStatus,
  UploadStatusDetails,
  uploadStatusOk,
} from 'shared/models/upload-status'

export function insuranceOk(model: Insurance | undefined) {
  return uploadStatusOk(model?.status)
}

export interface Insurance {
  status: UploadStatus
  statusDetails: UploadStatusDetails | undefined
  page1: UploadMetadata
}
