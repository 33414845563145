import { useAsync } from 'react-async-hook'
import { Redirect, useParams } from 'react-router-dom'
import { db } from 'app/db/frontend-db'
import { routes } from 'shared/config/routes'
import { categoryByShortId } from 'shared/data/categories-service'
import { parseInt10 } from 'shared/utils/number'
import { Loading } from 'utils/loading'

export function QRLicenseV1() {
  const { qrUid, shortCategoryId } = useParams<{ qrUid: string; shortCategoryId: string }>()
  const to = useAsync(
    async () =>
      routes.approvedLicenses.generateTo(
        `${await extractUid(qrUid)} ${extractCategory(shortCategoryId).id}`
      ),
    [qrUid, shortCategoryId]
  )
  return to.result ? (
    <Redirect to={to.result} />
  ) : to.loading ? (
    <Loading loading={true} />
  ) : (
    <>
      QR Code nicht erkannt: qrUid: {qrUid}, shortCategoryId: {shortCategoryId}
    </>
  )
}

async function extractUid(qrUid: string) {
  return (await db.uidForShortUid(qrUid)) || qrUid
}

function extractCategory(shortCategoryId: string) {
  const category = categoryByShortId(parseInt10(shortCategoryId))
  if (!category) throw new Error(`Category ${shortCategoryId} not found`)
  return category
}
