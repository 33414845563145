import { IconButton, Tooltip } from '@material-ui/core'
import type { IconButtonProps, IconButtonTypeMap } from '@material-ui/core'

type IconButtonWithTooltipProps<
  D extends React.ElementType = IconButtonTypeMap['defaultComponent'],
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {}
> = IconButtonProps<D, P> & {
  tooltip: string
}

export function IconButtonWithTooltip({ tooltip, ...rest }: IconButtonWithTooltipProps) {
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton {...rest} />
      </span>
    </Tooltip>
  )
}
