import { useAllInscriptionBookingsByRider } from 'app/db/db-hooks/financial-db-hooks'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { UserName } from 'app/pages/profile/user-name'
import { useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { routes } from 'shared/config/routes'
import { categoryByIdFromString, categoryCommonName } from 'shared/data/categories-service'
import { InscriptionBooking, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { associationNameWithDefault } from 'shared/models/associations'
import { groupByString, truthy } from 'shared/utils/array'
import { formatDateWithSecondsSpace, matchesYear, parseISO } from 'shared/utils/date'
import { toChf } from 'shared/utils/number'
import { filterValues, mapValues } from 'shared/utils/object'

export function LonelyTwoDayDiscounts({ admin }: { admin: UserQuery }) {
  const { loading, error, data, loadingOrError } = useAllInscriptionBookingsByRider()
  const userContext = useUserContext()
  const year = useLicenseYear()
  const lonelyBookings = filterLonelyBookings(data || {}).filter((booking) =>
    matchesYear(booking.date, year)
  )

  return (
    <TableBox
      title={routes.lonelyTwoDayDiscounts.text()}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: [
            { value: t().datetime, maxWidth: 100 },
            { value: t().bookingText, maxWidth: 150 },
            { value: t().user, export: false, maxWidth: 100 },
            { value: t().userID, exportOnly: true },
            { value: t().association, maxWidth: 80, exportOnly: !!userContext.associationAdmin },
            { value: t().category, maxWidth: 100 },
            { value: t().internalRemarks, maxWidth: 100 },
            { value: t().admin, maxWidth: 100, export: false },
            { value: t().adminID, exportOnly: true },
            { value: t().amount.label, align: 'right' },
            actions(),
          ],
          // eslint-disable-next-line react/display-name
          contents: lonelyBookings.map((booking) => () => [
            formatDateWithSecondsSpace(parseISO(booking.date)),
            booking.item.name,
            <UserName key="uid" uid={booking.uid} />,
            booking.uid,
            associationNameWithDefault(booking.item.association),
            categoryCommonName(
              (booking.item.categoryId && categoryByIdFromString(booking.item.categoryId)) || undefined
            ),
            booking.internalRemarks || '',
            <UserName key="byUid" uid={booking.byUid} />,
            booking.byUid,
            toChf(booking.item.price),
            <>
              <FinancialDialog admin={admin} user={{ uid: booking.uid }} />
            </>,
          ]),
          ids: lonelyBookings.map(({ id }) => id || ''),
          rawData: lonelyBookings.map((row) => JSON.stringify(row)),
        }
      }
    />
  )
}

function filterLonelyBookings(byRider: Record<string, InscriptionBooking[]>) {
  const filtered = filterValues(
    mapValues(byRider, (bookings) => filterLonelyTwoWayDiscountBookings(bookings)),
    (bookings) => Object.values(bookings).every((b) => !!b.length)
  )
  return Object.values(filtered)
    .map((bookings) => Object.values(bookings).flat())
    .flat()
    .filter(truthy)
}

function filterLonelyTwoWayDiscountBookings(bookings: InscriptionBooking[]) {
  const bySportEvent = groupByString(bookings, (booking) => booking.item.sportEventId)

  return filterValues(
    mapValues(bySportEvent, (bookings) => filterLonelyTwoWayDiscountBookingsBySportEvent(bookings)),
    (bookings) => !!bookings.length
  )
}

function filterLonelyTwoWayDiscountBookingsBySportEvent(bookings: InscriptionBooking[]) {
  const discountItems = bookings.filter((booking) => booking.item.type === 'inscriptionDiscountLineItem')
  const normalBookings = bookings
    .filter(
      (booking) =>
        booking.item.type === 'inscriptionLineItem' ||
        booking.item.type === 'inscriptionDayCategoryLineItem'
    )
    .filter(truthy)
  if (!discountItems.length) return []

  return discountItems.filter((discountBooking) =>
    isLonelyInscriptionBooking({ discountBooking, normalBookings })
  )
}

function isLonelyInscriptionBooking(props: {
  discountBooking: InscriptionBooking
  normalBookings: InscriptionBooking[]
}) {
  const { discountBooking, normalBookings } = props
  return (
    normalBookings.filter((booking) => booking.item.sportEventId === discountBooking.item.sportEventId)
      .length <= 1
  )
}
