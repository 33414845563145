import { needsAdditionalInfo } from 'app/license/steps/additional-info'
import {
  categoryByIdRequired,
  categoryOfAssociationRequired,
  offersMultipleLicenseTypes,
} from 'shared/data/categories-service'
import { LicenseFormData } from 'shared/db/db'
import { licenseTypeNameLong } from 'shared/db/license-type'
import { t } from 'shared/i18n/current'
import { LicenseCategoryContext, licenseCategoryContexts } from 'shared/license/license-draft-categories'
import { associationByID } from 'shared/models/associations'
import { Category } from 'shared/models/category'
import { truthy } from 'shared/utils/array'

export const personalDataStep = fixedStep('personalData', () => stepsT().personalData.title)
export const lastYearStep = fixedStep('lastSeason', () => stepsT().lastSeason)
export const categoriesStep = fixedStep('categories', () => stepsT().categories.title)
export const transponderStep = fixedStep('transponder', () => stepsT().transponder)
export const additionalInfoStep = fixedStep('additionalInfo', () => stepsT().additionalInfo)
export const summaryStep: FixedLicenseStep = fixedStep('summary', () => stepsT().summary.title)

function stepsT() {
  return t().licenseForm.steps
}

function fixedStep(type: FixedStepName, name: () => string): FixedLicenseStep {
  return { id: type, type, displayName: name }
}

export function firstStep(): LicenseStep {
  return personalDataStep
}

export function nextStep(currentStep: LicenseStep, data: LicenseFormData): LicenseStep {
  const steps = allSteps(data)
  return steps[Math.min(steps.length - 1, findStepIndex(steps, currentStep) + 1)]
}

export function previousStep(currentStep: LicenseStep, data: LicenseFormData): LicenseStep {
  const steps = allSteps(data)
  return steps[Math.max(0, findStepIndex(steps, currentStep) - 1)]
}

export function isPreviousStep(data: LicenseFormData, selectedStepId: string, stepId: string): boolean {
  return findStepIndexByName(data, stepId) < findStepIndexByName(data, selectedStepId)
}

function findStepIndexByName(data: LicenseFormData, stepId: string) {
  const steps = allSteps(data)
  return steps.findIndex((step) => step.id === stepId)
}

export function allSteps(data: LicenseFormData): LicenseStep[] {
  return [
    personalDataStep,
    lastYearStep,
    categoriesStep,
    transponderStep,
    ...additionalSteps(data),
    summaryStep,
  ]
}

function findStepIndex(steps: LicenseStep[], currentStep: LicenseStep) {
  return steps.findIndex((step) => step.id === currentStep.id)
}

export function additionalSteps(data: LicenseFormData): LicenseStep[] {
  if (!data.licenseDrafts?.categories) return []
  const contexts = licenseCategoryContexts(data.licenseDrafts, data.approvedLicenses)

  return [...categorySpecificForms(contexts), ...additionalInfoForm(contexts)]
}

function additionalInfoForm(contexts: LicenseCategoryContext[]) {
  return needsAdditionalInfo(contexts) ? [additionalInfoStep] : []
}

function categorySpecificForms(contexts: LicenseCategoryContext[]): CategoryLicenseStep[] {
  return contexts.map((licenseCategoryContext) => {
    const category = categoryByIdRequired(licenseCategoryContext.category)
    const associationCategory = categoryOfAssociationRequired(
      category.id,
      licenseCategoryContext.association
    )
    return {
      id: `category-${category.id}`,
      type: 'category',
      displayName: () =>
        [
          associationByID(licenseCategoryContext.association).name,
          associationCategory.name,
          offersMultipleLicenseTypes(associationCategory) &&
            licenseTypeNameLong(licenseCategoryContext.licenseType),
        ]
          .filter(truthy)
          .join(' - '),
      category,
      licenseCategoryContext,
    }
  })
}

export type LicenseStep = FixedLicenseStep | CategoryLicenseStep

export interface FixedLicenseStep {
  id: string
  type: FixedStepName
  displayName: () => string
}

export interface CategoryLicenseStep {
  id: string
  type: CategoryStepName
  displayName: () => string
  category: Category
  licenseCategoryContext: LicenseCategoryContext
}

export type StepName = FixedStepName | CategoryStepName
export type CategoryStepName = 'category'
export type FixedStepName =
  | 'personalData'
  | 'lastSeason'
  | 'categories'
  | 'transponder'
  | 'summary'
  | 'additionalInfo'
