import {
  Box,
  Button,
  createStyles,
  darken,
  lighten,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { ReactNode, memo, useState } from 'react'
import { downloadCsv } from 'app/export/csv'
import { downloadXlsx } from 'app/export/xlsx'
import { useIsSmallerThanXs } from 'app/layout/use-small-screen'
import { t } from 'shared/i18n/current'
import { fastInsecureHashCode } from 'shared/utils/string'
import { TableData, TableHeader, RawCellData, TableContentsRow } from 'shared/utils/table-data'
import { ArrayElement } from 'shared/utils/tsc'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fmsBlueTableRow: {
      backgroundColor: theme.palette.fmsBlue.light,
      color: theme.palette.fmsBlue.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.fmsBlue.main,
      },
    },
    fmsBlueTableCell: {
      color: theme.palette.fmsBlue.contrastText,
    },
    errorLightTableRow: {
      backgroundColor:
        theme.palette.type === 'light'
          ? lighten(theme.palette.error.light, 0.5)
          : darken(theme.palette.error.light, 0.5),
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor:
          theme.palette.type === 'light'
            ? lighten(theme.palette.error.main, 0.5)
            : darken(theme.palette.error.main, 0.5),
      },
    },
    errorLightTableCell: {},
    errorTableRow: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    errorTableCell: {
      color: theme.palette.error.contrastText,
    },
    successTableRow: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
      },
    },
    successTableCell: {
      color: theme.palette.success.contrastText,
    },

    successLightTableRow: {
      backgroundColor:
        theme.palette.type === 'light'
          ? lighten(theme.palette.success.light, 0.5)
          : darken(theme.palette.success.light, 0.5),
      color: theme.palette.success.contrastText,
      '&:hover': {
        backgroundColor:
          theme.palette.type === 'light'
            ? lighten(theme.palette.success.main, 0.5)
            : darken(theme.palette.success.main, 0.5),
      },
    },
    successLightTableCell: {},

    deemphasizedTableRow: {
      backgroundColor: theme.palette.deemphasized.main,
      color: theme.palette.deemphasized.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.deemphasized.light,
      },
    },
    deemphasizedTableCell: {
      color: theme.palette.deemphasized.contrastText,
    },
    warningTableRow: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.warning.light,
      },
    },
    warningTableCell: {
      color: theme.palette.warning.contrastText,
    },
  })
)

type StyleClasses = ReturnType<typeof useStyles>

interface DownloadableTableProps {
  data: TableData
  density?: 'small' | 'medium' | 'high'
  more?: ReactNode
  exports?: ReactNode
  disableDownloadButtons?: boolean
  disableCountButton?: boolean
  maxEntries?: number
}

export function DownloadableTable(props: DownloadableTableProps) {
  const {
    data,
    more,
    density,
    exports,
    disableDownloadButtons,
    disableCountButton,
    maxEntries = 50,
  } = props
  const classes = useStyles()
  const smallScreen = useIsSmallerThanXs()
  const [showAllCount, setShowAllCount] = useState(0)

  const showAll = showAllCount === data.contents.length
  const canShowMore = !showAll && maxEntries < data.contents.length

  return (
    <>
      <TableContainer>
        <Table
          size={density === 'small' ? 'small' : undefined}
          padding={(!density && smallScreen) || density === 'high' ? 'none' : 'normal'}
        >
          {data.headers && (
            <TableHead>
              <TableRow>{data.headers.map((header, index) => renderHeader(header, index))}</TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.contents.map((row, index) => {
              if (!disableCountButton && maxEntries !== 0 && !showAll && maxEntries <= index) return null

              const singleRawData = data.rawData?.[index]
              let rawData: string | number | undefined =
                singleRawData && typeof singleRawData === 'object'
                  ? JSON.stringify(singleRawData)
                  : singleRawData

              if (typeof rawData === 'string' && rawData.length > 100)
                rawData = fastInsecureHashCode(rawData)

              const params = {
                row,
                id: data.ids?.[index] || index,
                selected: data.selected?.[index],
                headers: data.headers,
                rawData,
              }
              return data.rawData ? (
                <DownloadableTableRow key={params.id} {...params} />
              ) : (
                renderRow({ ...params, classes })
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {more}

      {data.contents.length > 0 && (
        <Box display="flex" mt={1} alignItems="center">
          <Box flexGrow={1}>
            {!disableCountButton && (
              <Button disabled={!canShowMore} onClick={() => setShowAllCount(data.contents.length)}>
                {canShowMore
                  ? `${t().table.showAllEntries(data.contents.length)}${
                      data.contents.length > 1500 ? ` ${t().table.manyEntriesWarning}` : ''
                    }`
                  : data.contents.length === 1
                  ? t().table.oneEntryIsDisplayed
                  : t().table.showingNEntries(data.contents.length)}
              </Button>
            )}
          </Box>
          {exports}
          {!disableDownloadButtons && (
            <>
              <Button startIcon={<CloudDownload />} onClick={() => downloadXlsx(data, 'export')}>
                XLSX
              </Button>
              <Button startIcon={<CloudDownload />} onClick={() => downloadCsv(data, 'export')}>
                CSV
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  )
}

interface DownloadableTableRowProps extends BaseRowProps {
  rawData: string | number | undefined
}

// eslint-disable-next-line react/display-name
const DownloadableTableRow = memo(
  // eslint-disable-next-line react/prop-types
  ({ id, row, selected, headers }: DownloadableTableRowProps) => {
    const classes = useStyles()
    return renderRow({ classes, selected, row, headers, id })
  },
  (prev, next) =>
    !!prev.rawData && !!next.rawData && prev.rawData === next.rawData && prev.selected === next.selected
)

interface RenderRowProps extends BaseRowProps {
  classes: StyleClasses
}

function renderRow({ classes, selected, row, headers, id }: RenderRowProps): JSX.Element {
  const className = selected ? (`${selected}TableRow` as const) : undefined
  const evaluatedRow = typeof row === 'function' ? row() : row
  return (
    <TableRow key={id} className={className ? classes[className] : ''} hover={!selected}>
      {evaluatedRow.map((value, index) => renderCell(value, index, headers?.[index], selected, classes))}
    </TableRow>
  )
}

interface BaseRowProps {
  row: TableContentsRow
  selected: ArrayElement<TableData['selected']>
  headers: TableData['headers']
  id: ArrayElement<TableData['ids']>
}

export function renderHeader(header: TableHeader, index: number) {
  if (typeof header === 'object' && header.exportOnly) return null

  const value = typeof header === 'object' ? header.value : header
  const align = typeof header === 'object' ? header.align : undefined
  const maxWidth = typeof header === 'object' ? header.maxWidth : undefined
  return (
    <TableCell key={index} align={align}>
      {maxWidth ? <div style={{ maxWidth }}>{value}</div> : value}
    </TableCell>
  )
}

export function renderCell(
  cell: RawCellData,
  index: number,
  header: TableHeader | undefined,
  selected?: ArrayElement<TableData['selected']>,
  classes?: StyleClasses
) {
  if (typeof header === 'object' && header.exportOnly) return null

  const align = typeof header === 'object' ? header.align : undefined
  const maxWidth = typeof header === 'object' ? header.maxWidth : undefined
  const className = selected ? (`${selected}TableCell` as const) : undefined
  const content = header?.format ? header.format(cell) : cell

  return (
    <TableCell key={index} align={align} className={className ? classes?.[className] : ''}>
      {maxWidth ? <div style={{ maxWidth }}>{content}</div> : content}
    </TableCell>
  )
}
