import { cloneDeep, times } from 'lodash'
import { Championships, sortAssignmentsByChampionship } from './sortAssignmentsByChampionship'
import { db } from 'app/db/frontend-db'
import { InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { updateInscriptionListByInscription } from 'shared/inscription/public-inscription-list'
import { startingListNameAndDateLookupForInscription } from 'shared/sport-events/sport-event-groups'
import { Inscription, isEnlistedInscription, SportEvent } from 'shared/sport-events/sport-events'
import { mapValues } from 'shared/utils/object'

export async function assignInscriptionGroups(
  originalInscriptions: InscriptionWithContextAndSportEvent[],
  sportEvent: SportEvent,
  championships: Championships
) {
  const inscriptions = cloneDeep(originalInscriptions)
  const currentCounts = loadCurrentGroupCounts(sportEvent, inscriptions)
  const newAssignments = loadNewAssignments(inscriptions)
  const sortedNewAssignments = sortAssignmentsByChampionship(newAssignments, championships)

  const assigned = sortedNewAssignments.map((inscription) =>
    assignToSmallestGroup(currentCounts, inscription)
  )
  await Promise.all(assigned.map(({ inscription }) => storeInscription(inscription)))
}

export function loadNewAssignments(inscriptions: InscriptionWithContextAndSportEvent[]) {
  return inscriptions.filter(
    (inscription) => isEnlistedInscription(inscription.inscription) && !inscription.inscription.group
  )
}

function loadCurrentGroupCounts(
  sportEvent: SportEvent,
  inscriptions: InscriptionWithContextAndSportEvent[]
): GroupCounts {
  const groupCounts = mapValues(sportEvent.categoryGroupCounts || {}, buildGroups)
  inscriptions.forEach((inscription) => {
    const group = inscription.inscription.group
    if (!group) return

    const lookup = startingListNameAndDateLookupForInscription(inscription)
    groupCounts[lookup][group - 1] += 1
  })
  return groupCounts
}

function buildGroups(count: number) {
  return times(count).map(() => 0)
}

function assignToSmallestGroup(
  groupCounts: GroupCounts,
  inscription: InscriptionWithContextAndSportEvent
) {
  const relevantGroupCounts = groupCounts[startingListNameAndDateLookupForInscription(inscription)]
  const minimum = Math.min(...relevantGroupCounts)
  const minimumIndex = relevantGroupCounts.findIndex((count) => count === minimum)
  const newGroup = minimumIndex + 1

  relevantGroupCounts[newGroup - 1] += 1
  inscription.inscription.group = newGroup

  return inscription
}

export function deleteInscriptionGroup(inscription: Inscription) {
  inscription.group = null
  return storeInscription(inscription)
}

export function increaseInscriptionGroup(inscription: Inscription) {
  if (!inscription.group) return

  inscription.group += 1
  return storeInscription(inscription)
}

export function decreaseInscriptionGroup(inscription: Inscription) {
  if (!inscription.group) return

  inscription.group -= 1
  return storeInscription(inscription)
}

async function storeInscription(inscription: Inscription) {
  await db.setInscriptionGroup(inscription, inscription.group || null)
  await updateInscriptionListByInscription(db, inscription)
}

type GroupCounts = Record<string, number[]>
