import { useCallback } from 'react'
import { db } from 'app/db/frontend-db'
import { LoadCache, useLoadWithCache } from 'app/pages/profile/use-load-with-cache'
import { fullName, searchName } from 'shared/models/personal-data'

const userNameAndSearchNameCache: LoadCache<{ name: string; details: string }> = new Map()
const emailCache: LoadCache<string> = new Map()
const errorData = { name: '', details: '' }

export function useUserNameAndSearchName(uid: string) {
  const load = useCallback(() => loadUserNameAndSearchName(uid), [uid])
  return useLoadWithCache(uid, errorData, load, userNameAndSearchNameCache)
}

async function loadUserNameAndSearchName(uid: string) {
  const personalData = await db.loadPersonalData({ uid })
  return { name: fullName(personalData), details: searchName(uid, personalData) }
}

export function useCachedEmailFromUserData(uid: string) {
  const load = useCallback(() => loadCachedEmailFromUserData(uid), [uid])
  return useLoadWithCache(uid, '', load, emailCache)
}

async function loadCachedEmailFromUserData(uid: string) {
  const userData = await db.loadUserData({ uid })
  return userData?.email || ''
}
