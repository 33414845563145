import {
  Box,
  Tooltip,
  Button,
  Menu,
  MenuItem,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Badge,
} from '@material-ui/core'
import { DateRange, ExpandMore } from '@material-ui/icons'
import { useYearContext } from 'app/themes/year-context'
import { years } from 'shared/data/license-year'
import { t } from 'shared/i18n/current'
import { useMenu, useMenuWithAnchor } from 'utils/use-menu'

export function ChangeYearMenuItem({ closeMenu }: { closeMenu: () => void }) {
  const context = useYearContext()
  const innerMenu = useMenu()

  return (
    <>
      <ListItem button onClick={innerMenu.toggle}>
        <ListItemIcon>
          <DateRange />
        </ListItemIcon>
        <ListItemText primary={t().routes.changeYear} />
      </ListItem>
      <Collapse in={innerMenu.isOpen}>
        <List disablePadding>
          {years.map((year) => (
            <ListItem
              style={{ marginLeft: 16 }}
              disabled={year === context.year}
              key={year}
              button
              onClick={() => {
                context.setYear(year)
                innerMenu.close()
                closeMenu()
              }}
            >
              <ListItemIcon>
                <Badge
                  badgeContent={year - 2000}
                  color="primary"
                  style={{ wordBreak: 'initial' }}
                  max={99}
                >
                  <DateRange />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={year} secondary={year === context.year ? 'Aktiv' : undefined} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export function ChangeYearDropdownMenu() {
  const yearContext = useYearContext()
  const menu = useMenuWithAnchor()

  return (
    <Box mr={2} display="flex">
      <Tooltip title={t().routes.changeYear} enterDelay={300}>
        <Button color="inherit" onClick={menu.open}>
          <DateRange />
          <span>{yearContext.year}</span>
          <ExpandMore fontSize="small" />
        </Button>
      </Tooltip>
      <Menu anchorEl={menu.anchorEl} open={menu.isOpen} onClose={menu.close}>
        {years.map((year) => (
          <MenuItem
            component="a"
            data-no-link="true"
            key={year}
            selected={yearContext.year === year}
            onClick={() => {
              yearContext.setYear(year)
              menu.close()
            }}
          >
            {year}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
