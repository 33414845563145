import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { Lock } from '@material-ui/icons'
import { partition, sortBy } from 'lodash'
import { createElement } from 'react'
import { useSportEventsWithDayCategoriesByYear } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { actions } from 'app/export/table'
import { useIsSmallerThanSm } from 'app/layout/use-small-screen'
import { useRacemanagerTitle } from 'app/layouts/route-with-error-boundary-and-title'
import { TableBox } from 'app/layouts/table-box'
import { EditSportEventButton } from 'app/sport-events/edit-sport-event-button'
import { NewSportEventButton } from 'app/sport-events/new-sport-event-button'
import { UserState, useUserContext } from 'app/themes/user-context'
import { useYearContext } from 'app/themes/year-context'
import { routes } from 'shared/config/routes'
import { fullCategoryCommonName } from 'shared/data/categories-service'
import { DayCategory } from 'shared/db/day-category'
import { UserQuery } from 'shared/db/db'
import { searchSportEventsWithDayCategories } from 'shared/db/search'
import { t } from 'shared/i18n/current'
import { associationNameWithDefault } from 'shared/models/associations'
import { SportEvent } from 'shared/sport-events/sport-events'
import {
  isFutureSportEvent,
  sportEventCancelledPrefix,
  sportEventCategories,
  sportEventDescription,
} from 'shared/sport-events/sport-events-service'
import { isLength, truthy } from 'shared/utils/array'
import { TableHeader } from 'shared/utils/table-data'
import { BreadcrumbsList } from 'utils/breadcrumbs'
import { DeleteButtonIcon } from 'utils/buttons/delete-button-icon'
import { RoutedButton, RoutedIconButton } from 'utils/buttons/routed-button'
import { useSearchQuery } from 'utils/router'
import { useBoolean } from 'utils/use-boolean'

export function SportEvents(props: { user: UserQuery | undefined; showAdminView: boolean }) {
  const { showAdminView, user } = props
  const search = useSearchQuery()
  const { year } = useYearContext()
  // TODO: sportEvents: load only relevant day categories / by year
  const { data, loading, error, loadingOrError } = useSportEventsWithDayCategoriesByYear(year)
  const smScreen = useIsSmallerThanSm()
  const userContext = useUserContext()
  const showDeleted = useBoolean(false)

  const filtered = searchSportEventsWithDayCategories(search.q, data)
    .filter(({ sportEvent }) => showDeleted.value || sportEvent.status !== 'deleted')
    .filter(
      ({ sportEvent }) =>
        (showAdminView && canViewAsAdmin(userContext, sportEvent)) ||
        (isFutureSportEvent(sportEvent) && sportEvent.status === 'online')
    )
  const sportEvents = sortSportEvents(filtered)

  const sportEventName = isLength(sportEvents, 1) ? sportEventDescription(sportEvents[0].sportEvent) : ''
  const title = `${sportEventName ? `${sportEventName} - ` : ''}Veranstaltungen${
    showAdminView ? ' Admin' : ''
  }`
  useRacemanagerTitle(title)

  return (
    <>
      {showAdminView && <BreadcrumbsList base="adminSportEvents" links={[]} />}
      <TableBox
        title={showAdminView ? `${t().sportEvents} Admin` : t().sportEvents}
        loading={loading}
        error={error}
        data={
          !loadingOrError && {
            headers: headers(showAdminView, smScreen),
            contents: sportEvents.map(({ sportEvent, dayCategories }) => [
              sportEvent.id,
              ...(showAdminView
                ? [
                    <>
                      {sportEvent.finalized && (
                        <div>
                          <Lock />
                        </div>
                      )}
                      {sportEvent.status}
                    </>,
                  ]
                : []),
              ...(smScreen
                ? [
                    <>
                      {sportEventCancelledPrefix(sportEvent)}
                      {sportEvent.name}
                      <br />
                      <br />
                      {sportEvent.place}
                      <br />
                      <br />
                      {sportEvent.startsAt} - {sportEvent.endsAt}
                    </>,
                  ]
                : [
                    [sportEventCancelledPrefix(sportEvent), sportEvent.name].join(''),
                    sportEvent.place,
                    sportEvent.startsAt,
                    sportEvent.endsAt,
                  ]),
              ...(showAdminView
                ? [
                    associationNameWithDefault(sportEvent.association),
                    sportEvent.transponderType,
                    sportEvent.alternativeStartsAt,
                    sportEvent.alternativeEndsAt,
                    sportEvent.finalized ? t().finalized : t().open,
                  ]
                : []),
              <>
                {sportEventCategories(sportEvent).map((category) => (
                  <div key={category.id}>{fullCategoryCommonName(category)}</div>
                ))}
                {dayCategories.map(({ id, name }) => (
                  <div key={id}>{name}</div>
                ))}
              </>,
              <>
                <Box alignSelf="right">
                  {!showAdminView && (
                    <RoutedButton to={routes.publicInscriptions.generateTo(sportEvent.id)}>
                      {routes.publicInscriptions.text()}
                    </RoutedButton>
                  )}
                  {showAdminView && (
                    <RoutedIconButton
                      to={routes.publicInscriptions.generateTo(sportEvent.id)}
                      tooltip={routes.publicInscriptions.text()}
                      onClick={() => user && db.setActiveSportEvent(user, sportEvent.id)}
                    >
                      {createElement(routes.publicInscriptions.icon)}
                    </RoutedIconButton>
                  )}
                  {showAdminView && (
                    <RoutedIconButton
                      tooltip={routes.inscriptions.text()}
                      to={routes.inscriptions.generateTo('', sportEvent.id)}
                      onClick={() => user && db.setActiveSportEvent(user, sportEvent.id)}
                    >
                      {createElement(routes.inscriptions.icon)}
                    </RoutedIconButton>
                  )}
                  {showAdminView && (
                    <RoutedIconButton
                      tooltip={routes.sportEventFinancials.text()}
                      to={routes.sportEventFinancials.generateTo(sportEvent.id)}
                    >
                      {createElement(routes.sportEventFinancials.icon)}
                    </RoutedIconButton>
                  )}
                  {showAdminView && user && canEdit(userContext, sportEvent) && (
                    <EditSportEventButton
                      sportEvent={sportEvent}
                      dayCategories={dayCategories}
                      admin={user}
                    />
                  )}
                  {showAdminView && canEdit(userContext, sportEvent) && (
                    <DeleteButtonIcon
                      title={`${sportEventDescription(sportEvent)} löschen`}
                      onConfirm={async () => {
                        await db.deleteSportEvent(sportEvent)
                        await db.pushUserEvent({
                          id: '',
                          type: 'editSportEvent',
                          uid: user?.uid || '',
                          byUid: user?.uid || '',
                          date: new Date().toISOString(),
                          editType: 'delete',
                          details: sportEvent,
                        })
                      }}
                    />
                  )}
                </Box>
              </>,
            ]),
            selected: sportEvents.map(({ sportEvent }) =>
              sportEvent.status === 'deleted'
                ? 'error'
                : sportEvent.finalized
                ? 'successLight'
                : !isFutureSportEvent(sportEvent)
                ? 'deemphasized'
                : ''
            ),
            ids: sportEvents.map(({ sportEvent: { id } }) => id),
            rawData: sportEvents.map((event) => JSON.stringify([smScreen, event, userContext])),
          }
        }
      >
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <TextField
              label={`Suchen (Name, Ort, ${
                showAdminView ? 'Vergangenheit/Zukunft, ' : ''
              }Kategorien, Datum (yyyy-mm-dd))`}
              variant="outlined"
              size="small"
              fullWidth
              value={search.q}
              onChange={(event) => search.set(event.currentTarget.value)}
            />
          </Box>
          {!showAdminView && userContext.adminOrAssociationAdmin && (
            <Box px={1}>
              <RoutedButton to={routes.adminSportEvents.to}>Veranstaltungen Admin</RoutedButton>
            </Box>
          )}
          {showAdminView && userContext.adminOrAssociationAdmin && (
            <Box px={1}>
              <RoutedButton to={routes.sportEvents.to}>{routes.sportEvents.text()}</RoutedButton>
            </Box>
          )}
          {showAdminView && user && (
            <Box px={1}>
              <NewSportEventButton admin={user} />
            </Box>
          )}
          {showAdminView && (
            <FormControlLabel
              control={<Checkbox checked={showDeleted.value} onChange={showDeleted.toggle} />}
              label={t().showDeleted}
            />
          )}
        </Box>
      </TableBox>
    </>
  )
}

function sortSportEvents(filtered: { sportEvent: SportEvent; dayCategories: DayCategory[] }[]) {
  const [futureSportEvents, pastSportEvents] = partition(filtered, ({ sportEvent }) =>
    isFutureSportEvent(sportEvent)
  )
  return [
    ...sortBy(futureSportEvents, ({ sportEvent }) => sportEvent.startsAt),
    ...sortBy(pastSportEvents, ({ sportEvent }) => sportEvent.startsAt).reverse(),
  ]
}

function canViewAsAdmin(userContext: UserState, sportEvent: SportEvent): boolean {
  return (
    userContext.canEditAssociation(sportEvent.association) ||
    sportEventCategories(sportEvent).some((category) =>
      category.associations.some((association) => userContext.canEditAssociation(association))
    )
  )
}

function headers(admin: boolean, smScreen: boolean): TableHeader[] {
  return [
    { value: t().id, exportOnly: true },
    admin ? { value: t().status } : undefined,
    ...(smScreen
      ? [{ value: t().sportEvent }]
      : [{ value: t().name }, { value: t().place }, { value: t().startsAt }, { value: t().endsAt }]),
    ...(admin
      ? [
          { value: t().association },
          { value: t().transponders.transponderSystem },
          { value: t().alternativeStartsAt },
          { value: t().alternativeEndsAt },
          { value: t().finalized, exportOnly: true },
        ]
      : []),
    { value: t().categoryIds },
    actions(),
  ].filter(truthy)
}

function canEdit(userContext: UserState, sportEvent: SportEvent) {
  return userContext.canEditAssociation(sportEvent.association)
}
