import { Button } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { db } from 'app/db/frontend-db'
import { tableHeaders } from 'app/export/table'
import { downloadXlsx } from 'app/export/xlsx'
import { openInscriptionTasksShortName } from 'app/pages/admin/inscriptions/openInscriptionTasks'
import { categoryOfAssociationRequired } from 'shared/data/categories-service'
import { fullLicenseId } from 'shared/data/licenses-service'
import { InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { EnduroBike } from 'shared/models/bike'
import { CategoryId } from 'shared/models/category'
import { exportSectionName } from 'shared/my-laps/mylaps-export'
import { Inscription, SportEvent, isInscribedInscription } from 'shared/sport-events/sport-events'
import { sportEventDescription } from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'
import { RawCellData, TableData } from 'shared/utils/table-data'

interface DownloadInscriptionsForEnduroButtonProps {
  inscriptions: InscriptionWithContextAndSportEvent[]
  disabled: boolean
  sportEvent: SportEvent
}

export function DownloadInscriptionsForEnduroButton(props: DownloadInscriptionsForEnduroButtonProps) {
  return (
    <Button
      disabled={props.disabled}
      startIcon={<CloudDownload />}
      onClick={() => downloadInscriptionsForEnduro(props)}
    >
      {t().sportEventTypes.enduro}
    </Button>
  )
}

async function downloadInscriptionsForEnduro(props: DownloadInscriptionsForEnduroButtonProps) {
  const { inscriptions, sportEvent } = props
  await downloadXlsx(await enduroCsv(inscriptions), sportEventDescription(sportEvent))
}

async function enduroCsv(inscriptions: InscriptionWithContextAndSportEvent[]): Promise<TableData> {
  const exportObjects = await enduroExportObjects(inscriptions)
  const keys = Object.keys(
    exportObjects[0] || { 'No entries found': '' }
  ) as (keyof (typeof exportObjects)[0])[]
  const headers = tableHeaders(keys)
  const contents: RawCellData[][] = exportObjects.map((exportObject) =>
    keys.map((key) => exportObject[key])
  )

  return { headers, contents, rawData: undefined }
}

async function enduroExportObjects(inscriptions: InscriptionWithContextAndSportEvent[]) {
  const exported = await Promise.all(inscriptions.map(enduroExportObject))
  return exported.filter(truthy)
}

async function enduroExportObject(inscriptionWithContext: InscriptionWithContextAndSportEvent) {
  const inscription = inscriptionWithContext.inscription
  const firstBike = await loadFirstBike(inscription)

  if (!isInscribedInscription(inscription)) return undefined

  const inscriptionCategory =
    inscriptionWithContext.dayCategory ||
    categoryOfAssociationRequired(inscription.category as CategoryId, inscription.association)

  if (!inscriptionWithContext.documents.personalData) return undefined

  return {
    Incorrect: openInscriptionTasksShortName({
      inscription,
      documents: inscriptionWithContext.documents,
      dayCategory: inscriptionWithContext.dayCategory,
    }),
    'Cat.': inscriptionCategory.name,
    'No.':
      inscription.type === 'enlistedDayInscriptionDayCategory'
        ? inscription.issuedNumber || inscription.preferredNumber
        : inscription.type === 'enlistedDayInscriptionYearCategory'
        ? inscription.issuedNumber || inscription.preferredNumber
        : inscriptionWithContext.licenseWithContext?.license.approved.issuedNumber || '',
    NOM: (inscriptionWithContext.documents.personalData?.lastName || '').toUpperCase(),
    PRENOM: (inscriptionWithContext.documents.personalData?.firstName || '').toUpperCase(),
    CLUB: exportSectionName(
      inscriptionCategory,
      inscriptionWithContext.documents.personalData,
      inscriptionWithContext.sportEvent.association
    ),
    'NAT.': inscriptionWithContext.documents.personalData?.country || '',
    'Nr. Licence': fullLicenseId(inscriptionWithContext.licenseWithContext?.license.approved),
    'Nr. Permis': inscriptionWithContext.documents.driversLicense?.id || '',
    MOTO: firstBike?.bikeMake || '?',
    'Cyl.': firstBike?.bikeModel || '?',
    Type: firstBike?.bikeModel || '?',
    'Nr.Chassis': firstBike?.frameNumber || '?',
    'Nr. Plaque': firstBike?.numberPlate || '?',
    'Assurance moto': firstBike?.insurance || '?',
    Email: inscriptionWithContext.documents.personalData?.email,
    'Assurance accident': inscriptionWithContext.documents.emergency?.accidentInsuranceName || '',
    "Nr. Tel. d'urgence": inscriptionWithContext.documents.emergency?.contactOnAccident1Phone || '',
  }
}

async function loadFirstBike(inscription: Inscription): Promise<EnduroBike | undefined> {
  const firstBikeId = inscription.bikeIds?.[0]
  if (!firstBikeId) return undefined

  const firstBike = await db.loadBike(inscription, firstBikeId)
  return firstBike?.categoryType === 'enduro' ? firstBike : undefined
}
