import { combineFire2, useFire, le } from 'app/db/db-hooks/db-hook-helpers'
import { emptyArray } from 'app/db/db-hooks/empty-hook-helpers'
import { AdminPath, UserQuery as User } from 'shared/db/db'
import { AssociationID, associations } from 'shared/models/associations'
import { StoredUser } from 'shared/models/stored-user'

export function useIsAdmin(user: User | undefined) {
  const isNormalAdmin = useIsNormalAdmin(user)
  const isReadonlyAdmin = useIsReadonlyAdmin(user)

  return isNormalAdmin || isReadonlyAdmin
}

export function useIsNormalAdmin(user: User | undefined) {
  const { data } = useFire<boolean | null | undefined>(`admins/${user?.uid || 'anonymous'}`)
  return data !== null && data !== undefined
}

export function useIsReadonlyAdmin(user: User | undefined) {
  const { data } = useFire<boolean | null | undefined>(`readonlyAdmins/${user?.uid || 'anonymous'}`)
  return data !== null && data !== undefined
}

export function useIsAssociationAdminFirebase(user: User | undefined): AssociationID | false {
  const { data } = useFire<boolean | null | undefined>(`associationAdmins/${user?.uid || 'anonymous'}`)
  if (!data) return false
  const keys = Object.keys(data)
  return associations.find((association) => keys[0] === association.id)?.id || false
}

export function useAdmins(path: AdminPath) {
  return combineFire2(useAdminIds(path), useUsers(), (adminIds, users) => {
    const ids = new Set(adminIds)
    return {
      admins: users.filter((user) => ids.has(user.uid)),
      nonAdmins: users.filter((user) => !ids.has(user.uid)),
    }
  })
}

function useAdminIds(path: AdminPath) {
  const { data, ...rest } = useFire<Record<string, boolean>>(path)

  const adminsIds = Object.entries(rest.loadingOrError || !data ? {} : data)
    .filter(([_k, v]) => v)
    .map(([k]) => k)

  return le({ data: adminsIds, ...rest })
}

export function useAssociationAdmins() {
  return combineFire2(useAssociationAdminIds(), useUsers(), (admins, users) => {
    if (users.length === 0) return { admins: [], nonAdmins: [] }

    const ids = new Set(admins.map((admin) => admin.uid))
    return {
      admins: admins.map((admin) => ({ ...admin, user: findUser(users, admin) })),
      nonAdmins: users.filter((user) => !ids.has(user.uid)),
    }
  })
}

function findUser(users: StoredUser[], { uid }: { uid: string }) {
  const user = users.find((user) => user.uid === uid)
  if (!user) throw new Error(`Could not find user ${uid}`)
  return user
}

function useAssociationAdminIds() {
  const { data, ...rest } = useFire<Record<string, Record<AssociationID, boolean>>>('associationAdmins')

  const associationAdmins = Object.entries(rest.loadingOrError || !data ? {} : data).map(
    ([uid, associationMap]) => ({ uid, association: Object.keys(associationMap)[0] as AssociationID })
  )

  return le({ data: associationAdmins, ...rest })
}

function useUsers() {
  const { data: users, ...rest } = useFire<Record<string, StoredUser>>('users')
  return le({
    data: rest.loadingOrError || !users ? emptyArray<StoredUser>() : Object.values(users),
    ...rest,
  })
}
