import firebase from 'firebase/app'
import { useAuthState as useAuthStateHook } from 'react-firebase-hooks/auth'
import { setSentryUser } from 'app/config/sentry'
import { fixClosureLibraryIssue as fix } from 'app/users/auth-wrapper'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { base64Encode } from 'shared/utils/base64'
import { friendlyError } from 'shared/utils/friendly-error'
import { Disp } from 'utils/react'

export async function signInWithEmailAndPassword(
  email: string,
  password: string,
  setSignInError: Disp<string>
) {
  try {
    const user = await fix(firebase.auth().signInWithEmailAndPassword(email, password))
    return user.user
  } catch (error) {
    setSignInError(friendlyError(error))
  }
}

export async function sendEmailSignInLink(
  email: string,
  setSignInError: Disp<string>,
  setLinkSent: Disp<boolean>
) {
  try {
    await fix(firebase.auth().sendSignInLinkToEmail(email, emailSignInSettings()))
    window.localStorage.setItem('emailForSignIn', email)
    setLinkSent(true)
  } catch (error) {
    setSignInError(friendlyError(error))
  }
}

export function currentUser() {
  const user = firebase.auth().currentUser
  if (!user) return { uid: 'anonymous' }
  return user
}

export function isSignInUsingUrl() {
  return firebase.auth().isSignInWithEmailLink(window.location.href)
}

export async function tryToSignInUsingUrl(setError: Disp<string>) {
  const email = loadEmailFromLocalStorageOrPrompt()
  try {
    return await fix(firebase.auth().signInWithEmailLink(email || '-', window.location.href))
  } catch (error) {
    setError(friendlyError(error))
  }
  window.localStorage.removeItem('emailForSignIn')
}

export async function reauthenticateWithEmail(user: User, newEmail: string, setError: Disp<string>) {
  try {
    await fix(
      firebase.auth().sendSignInLinkToEmail(userEmail(user), {
        url: `${window.location.origin}${routes.changeEmailAfterLogin.to}?change-email-to=${base64Encode(
          newEmail
        )}`,
        handleCodeInApp: true,
      })
    )
    window.localStorage.setItem('emailForSignIn', userEmail(user))
  } catch (error) {
    setError(friendlyError(error))
  }
}

export function hasEmailProvider(user: User) {
  return hasProvider(user, firebase.auth.EmailAuthProvider)
}

export async function signInWithGoogle(setError: Disp<string>) {
  const provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('email')
  provider.addScope('profile')
  try {
    return await fix(firebase.auth().signInWithPopup(provider))
  } catch (error) {
    setError(friendlyError(error))
  }
}

export async function reauthenticateWithGoogle(user: User, setError: Disp<string>) {
  const provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('email')
  provider.addScope('profile')
  try {
    await user.reauthenticateWithPopup(provider)
  } catch (error) {
    setError(friendlyError(error))
  }
}

export function hasGoogleProvider(user: User) {
  return hasProvider(user, firebase.auth.GoogleAuthProvider)
}

export async function signInWithFacebook(setError: Disp<string>) {
  const provider = new firebase.auth.FacebookAuthProvider()
  provider.addScope('email')
  try {
    return await fix(firebase.auth().signInWithPopup(provider))
  } catch (error) {
    setError(friendlyError(error))
  }
}

export async function reauthenticateWithFacebook(user: User, setError: Disp<string>) {
  const provider = new firebase.auth.FacebookAuthProvider()
  provider.addScope('email')
  try {
    await user.reauthenticateWithPopup(provider)
  } catch (error) {
    setError(friendlyError(error))
  }
}

export function hasFacebookProvider(user: User) {
  return hasProvider(user, firebase.auth.FacebookAuthProvider)
}

export async function signInWithGithub(setError: Disp<string>) {
  const provider = new firebase.auth.GithubAuthProvider()
  provider.addScope('read:user')
  provider.addScope('user:email')
  try {
    await fix(firebase.auth().signInWithPopup(provider))
  } catch (error) {
    setError(friendlyError(error))
  }
}

export function hasProvider(user: User, { PROVIDER_ID }: { PROVIDER_ID: string }) {
  return user.providerData.some((data) => data?.providerId === PROVIDER_ID)
}

export async function verifyEmail(
  user: User,
  setVerifyEmailError: Disp<string>,
  setVerifyLinkSent: Disp<boolean>
) {
  try {
    await user.sendEmailVerification(emailSignInSettings())
    setVerifyLinkSent(true)
  } catch (error) {
    setVerifyEmailError(friendlyError(error))
  }
}

export async function changeEmail(user: User, newEmail: string) {
  try {
    await user.verifyBeforeUpdateEmail(newEmail, emailSignInSettings())
    return 'success'
  } catch (error: any) {
    if (error?.code === 'auth/requires-recent-login') return 'requires-recent-login'
    else throw error
  }
}

function emailSignInSettings() {
  return { url: window.location.origin, handleCodeInApp: true }
}

function loadEmailFromLocalStorageOrPrompt() {
  const localStorageEmail = window.localStorage.getItem('emailForSignIn')
  if (localStorageEmail) return localStorageEmail
  const promptEmail = window.prompt('Bitte E-Mail bestätigen')
  if (promptEmail) window.localStorage.setItem('emailForSignIn', promptEmail || '')
  return promptEmail || ''
}

export async function signOut() {
  try {
    await fix(firebase.auth().signOut())
  } catch (error) {
    console.error('Error signing out', error)
  }
  window.history.pushState({}, '', '/')
}

export function useUser() {
  const [user, loading, error] = useAuthState()
  setSentryUser(user || undefined)
  return { user, loading, error } || undefined
}

export function useAuthState() {
  return useAuthStateHook(firebase.auth())
}

export function userEmail(user: User) {
  if (!user.email) throw new Error('Unexpected empty user email')
  return user.email
}
