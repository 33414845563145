import { Box, Button, Tooltip, Typography } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import assertNever from 'assert-never'
import { groupBy, sortBy, uniq } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { MaybeFBError } from 'app/db/db-hooks/db-hook-helpers'
import {
  usePublicSportEventInscriptions,
  useSportEventWithDayCategories,
} from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { useIsSmallerThanXs, useIsSmallerThanSm, useIsSmallerThanMd } from 'app/layout/use-small-screen'
import { useRacemanagerTitle } from 'app/layouts/route-with-error-boundary-and-title'
import { TableBox } from 'app/layouts/table-box'
import { useIsAdminOrAssociationAdmin } from 'app/themes/user-context'
import { routes } from 'shared/config/routes'
import { DayCategory } from 'shared/db/day-category'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { migrateInscribed } from 'shared/inscription/inscription-status'
import {
  publicInscriptionTitle,
  startingNumber,
  groupNumberToLetter,
} from 'shared/inscription/public-inscriptions-names'
import { Category } from 'shared/models/category'
import { UnknownDate } from 'shared/models/unknown-date'
import { PublicInscription, publicInscriptionID, SportEvent } from 'shared/sport-events/sport-events'
import {
  categoryDates,
  dayCategoryDates,
  sportEventCategories,
  sportEventDescription,
} from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'
import { DateString } from 'shared/utils/date'
import { BreadcrumbsList } from 'utils/breadcrumbs'
import { RoutedButton } from 'utils/buttons/routed-button'
import { Loading } from 'utils/loading'

interface PublicInscriptionsPageProps {
  user: UserQuery | undefined
}

export function PublicInscriptionsPage({ user }: PublicInscriptionsPageProps) {
  const { sportEventId } = useParams<{ sportEventId: string }>()
  const { data } = useSportEventWithDayCategories(sportEventId)
  const admin = useIsAdminOrAssociationAdmin()

  const { sportEvent, dayCategories } = data || {}
  const title = `${t().startLists} ${sportEventDescription(sportEvent)}`
  useRacemanagerTitle(title)

  const [startListFilter, setStartListFilter] = useState<string>('')

  if (!sportEvent || !dayCategories) return <Loading loading />

  const unsortedCategories = sportEventCategories(sportEvent)
  const categories = sortBy(unsortedCategories, ({ startListName }) => startListName)
  const flatCategories = Object.values(
    groupBy(categories, (category) => category.startListName)
  ).flatMap((categories) => ({ type: 'category' as const, categories }))

  const groupedDayCategories = Object.entries(dayCategoryDates(sportEvent))
    .flatMap(([dayCategoryID, dates]) => {
      const dayCategory = dayCategories[dayCategoryID]
      return dayCategory
        ? { startListName: dayCategory.startListName, dayCategory, dayCategoryID, dates }
        : undefined
    })
    .filter(truthy)
  const flatDayCategories = Object.values(
    groupBy(groupedDayCategories, (category) => category.dayCategory.startListName)
  ).flatMap((categories) => ({ type: 'dayCategory' as const, categories }))

  const combinedCategories = sortBy(
    [...flatCategories, ...flatDayCategories],
    (details) => details.categories[0]?.startListName
  )

  const startListNames = uniq([
    ...categories.map((category) => category.startListName),
    ...combinedCategories.map((category) => category.categories[0]?.startListName).filter(truthy),
  ]).sort()

  return (
    <>
      {admin && (
        <>
          <BreadcrumbsList
            base="adminSportEvents"
            links={[
              [
                routes.publicInscriptions.generateTo(sportEventId),
                routes.publicInscriptions.textAlt(sportEvent),
              ],
            ]}
          />
          {sportEvent && (
            <RoutedButton
              color="primary"
              variant="contained"
              to={routes.inscriptions.generateTo('', sportEvent.id)}
              onClick={() => user && db.setActiveSportEvent(user, sportEvent.id)}
            >
              {routes.inscriptions.textAlt(sportEvent)}
            </RoutedButton>
          )}
        </>
      )}

      <Typography variant="h2">{title}</Typography>

      {startListNames.map((name) => (
        <Button
          variant={startListFilter === name ? 'contained' : 'text'}
          color="primary"
          onClick={() => setStartListFilter((current) => (current === name ? '' : name))}
          key={name}
        >
          {name}
        </Button>
      ))}

      {combinedCategories
        .filter(
          (category) => !startListFilter || startListFilter === category.categories[0].startListName
        )
        .map((details) => {
          if (details.type === 'category') {
            const categories = details.categories
            return uniq(categories.flatMap((category) => categoryDates(sportEvent, category)))
              .sort()
              .map((date) => (
                <ListPublicInscriptions
                  key={[date, ...categories.map((category) => category.id)].join('-')}
                  user={user || { uid: 'anonymous' }}
                  sportEvent={sportEvent}
                  date={date}
                  categories={categories}
                />
              ))
          } else if (details.type === 'dayCategory') {
            const dates = uniq(details.categories.flatMap(({ dates }) => dates)).sort()
            return dates.map((date) => {
              const categoriesOnThisDate = details.categories.filter((category) =>
                category.dates.includes(date)
              )
              const dayCategories = categoriesOnThisDate.map(({ dayCategory }) => dayCategory)
              const key = [date, ...categoriesOnThisDate.map(({ dayCategoryID }) => dayCategoryID)]

              return (
                <ListPublicInscriptions
                  key={key.join('-')}
                  user={user || { uid: 'anonymous' }}
                  sportEvent={sportEvent}
                  date={date}
                  categories={dayCategories}
                />
              )
            })
          }
          return assertNever(details)
        })}
    </>
  )
}

interface ListPublicInscriptionsAllDatesProps {
  sportEvent: SportEvent
  category: Category | DayCategory
  user: UserQuery
}

export function ListPublicInscriptionsAllDates(props: ListPublicInscriptionsAllDatesProps) {
  return (
    <>
      {categoryDates(props.sportEvent, props.category).map((date) => (
        <ListPublicInscriptions
          key={date}
          {...props}
          categories={[props.category]}
          date={date}
          showStartingListTitle
        />
      ))}
    </>
  )
}

interface ListPublicInscriptionsProps {
  sportEvent: SportEvent
  date: DateString | UnknownDate
  categories: (Category | DayCategory)[]
  user: UserQuery
  showStartingListTitle?: boolean
}

export function ListPublicInscriptions(props: ListPublicInscriptionsProps) {
  const { categories, date, sportEvent } = props
  const {
    error,
    loading,
    data: allInscriptions,
  } = usePublicSportEventInscriptions(sportEvent, date, categories)
  const groups = uniq(allInscriptions.map((row) => row.group))

  return (
    <>
      {groups.sort().map((group) => {
        const inscriptions = allInscriptions.filter((inscription) => inscription.group === group)
        return inscriptions.length === 0 ? null : (
          <ListPublicInscriptionsRaw
            key={group || '-'}
            error={error}
            loading={loading}
            inscriptions={inscriptions}
            hasGroups={groups.length > 1}
            {...props}
          />
        )
      })}
    </>
  )
}

interface ListPublicInscriptionsRawProps {
  sportEvent: SportEvent
  date: DateString | UnknownDate
  categories: (Category | DayCategory)[]
  user: UserQuery
  showStartingListTitle?: boolean
  error: MaybeFBError
  loading: boolean
  inscriptions: PublicInscription[]
  hasGroups: boolean
}

function ListPublicInscriptionsRaw(props: ListPublicInscriptionsRawProps) {
  const { categories, date, sportEvent, user, showStartingListTitle } = props
  const { hasGroups, inscriptions, loading, error } = props
  const group = inscriptions[0]?.group
  const xsScreen = useIsSmallerThanXs()
  const smScreen = useIsSmallerThanSm()
  const mdScreen = useIsSmallerThanMd()
  const singleCategory = uniq(inscriptions.map((row) => row.category)).length < 2
  const title = publicInscriptionTitle({
    showStartingListTitle,
    sportEvent,
    categories,
    date,
    group,
    hasGroups,
  })

  return (
    <Box mt={2}>
      <TableBox
        title={title}
        error={error}
        loading={loading}
        maxEntries={1000}
        data={{
          headers: [
            { value: t().issuedNumber.labelAlternative },
            { value: t().inscription.group, exportOnly: !group },
            { value: t().name },
            { value: t().category, export: false as const, exportOnly: xsScreen || singleCategory },
            { value: t().category, exportOnly: true },
            { value: t().details, exportOnly: true },
            { value: t().sponsor },
            { value: t().bike, exportOnly: smScreen },
            { value: t().transponders.transponders },
            { value: t().domicile, exportOnly: mdScreen },
            { value: t().nationality, exportOnly: mdScreen },
          ].filter(truthy),
          contents: inscriptions.map((inscription) => {
            const details = detailedCategoryName(categories, inscription)
            return [
              migrateInscribed(inscription.inscribed)
                ? startingNumber(inscription)
                : `* ${startingNumber(inscription)}`,
              inscription.group ? groupNumberToLetter(inscription.group) : '-',
              inscription.name,
              <>
                {details ? (
                  <Tooltip title={details}>
                    <span>
                      {inscription.categoryName} <Info fontSize="small" />
                    </span>
                  </Tooltip>
                ) : (
                  inscription.categoryName
                )}
              </>,
              inscription.categoryName,
              details,
              inscription.sponsor,
              inscription.bike,
              [inscription.transponder1, inscription.transponder2].filter(truthy).join(' / '),
              inscription.domicile,
              inscription.nationality,
            ]
          }),
          selected: inscriptions.map((row) =>
            !migrateInscribed(row.inscribed) ? 'errorLight' : row.uid === user.uid ? 'success' : ''
          ),
          rawData: inscriptions.map((row) => JSON.stringify({ row, xsScreen, smScreen, mdScreen })),
          ids: inscriptions.map(publicInscriptionID),
        }}
        more={
          inscriptions.some((inscription) => !migrateInscribed(inscription.inscribed)) && (
            <Box py={1}>{t().inscriptionBox.inscriptionPending}</Box>
          )
        }
      />
    </Box>
  )
}

function detailedCategoryName(
  categories: (Category | DayCategory)[],
  inscription: PublicInscription
): string {
  const category = categories.find((cat) => cat.id === inscription.category)
  return !category || category.categoryType === 'licenseCategory'
    ? ''
    : category.categoryType === 'dayCategory'
    ? detailedCategoryNameIfNotEqual(inscription.categoryName, category.name)
    : assertNever(category)
}

function detailedCategoryNameIfNotEqual(categoryName: string, detail: string): string {
  return categoryName.includes(detail) ? '' : detail
}
