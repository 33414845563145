import { t } from 'shared/i18n/current'
import { ArrayElement } from 'shared/utils/tsc'

export type LicenseType = ArrayElement<typeof licenseTypes>

export const licenseTypes = ['national', 'international'] as const

export function licenseTypeName(licenseType: LicenseType | undefined) {
  return licenseType ? t().licenseTypes[licenseType] : '-'
}

export function licenseTypeNameLong(licenseType: LicenseType) {
  return t().licenseTypesLong[licenseType]
}
