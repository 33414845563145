/* eslint-disable react/display-name */
import { MenuItem, TextField as MUTextField } from '@material-ui/core'
import { addYears, isAfter, startOfYear, subYears } from 'date-fns'
import { Field } from 'formik'
import type { FormikErrors, FormikTouched } from 'formik'
import { TextField, CheckboxWithLabel } from 'formik-material-ui'
import { Autocomplete } from 'formik-material-ui-lab'
import type { AutocompleteRenderInputParams } from 'formik-material-ui-lab'
import { KeyboardDatePicker, KeyboardDateTimePicker } from 'formik-material-ui-pickers'
import * as Yup from 'yup'
import { CategoriesCheckboxGroup } from 'app/forms/categories-checkbox-group'
import { CategoriesCheckboxes } from 'app/forms/categories-checkboxes'
import { CheckboxGroup } from 'app/forms/checkbox-group'
import {
  categoriesByAssociation,
  categoriesByYear,
  categoryByIdRequired,
  categoryOfAssociationRequired,
} from 'shared/data/categories-service'
import { civilStatusOptions } from 'shared/data/civil-status'
import { countryNames } from 'shared/data/countries'
import { insuranceModels } from 'shared/data/insurance-models'
import { nextLicenseYear } from 'shared/data/license-config'
import { Year } from 'shared/data/license-year'
import { recepientOptions } from 'shared/data/recipients'
import { allSectionsFiltered } from 'shared/data/sections'
import { ApprovedLicense } from 'shared/db/db'
import { LicenseType, licenseTypes } from 'shared/db/license-type'
import { currentLocale, t, tYup } from 'shared/i18n/current'
import { messageTypes } from 'shared/messages/messages'
import { associationByID, AssociationID, associations } from 'shared/models/associations'
import { Category, CategoryOfAssociation, CategoryType } from 'shared/models/category'
import { selectableInsuranceOptions } from 'shared/models/insurance-options'
import {
  enableTransponderOption,
  TransponderOptionId,
  transponderOptions,
  validTransponderInput,
} from 'shared/models/transponder'
import { transponderTypes, transponderTypesIncludingEmpty } from 'shared/models/transponder-type'
import { sportEventStatus } from 'shared/sport-events/sport-event-status'
import { sportEventTypes } from 'shared/sport-events/sportEventType'
import { memoizeBasedOnLocale } from 'shared/utils/memoize'

export const samFields = memoizeBasedOnLocale(() => ({
  dayCategory: {
    name: {
      default: '',
      field: (index: number) => textField(`dayCategories.${index}.name`, t().dayCategory.name),
    },
    myLapsName: {
      default: '',
      field: (index: number) =>
        textField(`dayCategories.${index}.myLapsName`, t().dayCategory.myLapsName),
    },
    hint: {
      default: '',
      field: (index: number) => textField(`dayCategories.${index}.hint`, t().dayCategory.hint),
    },
    transponder: {
      default: '',
      field: (index: number) =>
        selectField(
          `dayCategories.${index}.transponder`,
          t().dayCategory.transponder,
          transponderTypesIncludingEmpty.map((value) => ({ id: value, name: value || '-' }))
        ),
    },
    additionalTermsText: {
      default: '',
      wide: true,
      field: (index: number) =>
        textField(`dayCategories.${index}.additionalTermsText`, t().dayCategory.additionalTermsText, {
          multiline: true,
        }),
    },
    additionalTermsURL: {
      default: '',
      field: (index: number) =>
        textField(`dayCategories.${index}.additionalTermsURL`, t().dayCategory.additionalTermsURL),
      wide: true,
    },

    price: {
      default: 80,
      field: (index: number) => numberField(`dayCategories.${index}.price`, t().dayCategory.price),
      wide: true,
    },

    pricePower: {
      default: 30,
      field: (index: number) =>
        numberField(`dayCategories.${index}.pricePower`, t().dayCategory.pricePower),
    },
    priceTransponderRental: {
      default: 0,
      field: (index: number) =>
        numberField(
          `dayCategories.${index}.priceTransponderRental`,
          t().dayCategory.priceTransponderRental
        ),
    },

    surcharge: {
      default: 0,
      field: (index: number) =>
        numberField(`dayCategories.${index}.surcharge`, t().dayCategory.surcharge),
    },
    surchargeAfter: {
      default: '',
      field: (index: number) =>
        textField(`dayCategories.${index}.surchargeAfter`, t().dayCategory.surchargeAfter),
    },

    discount: {
      default: 0,
      field: (index: number) => numberField(`dayCategories.${index}.discount`, t().dayCategory.discount),
    },
    discountUntil: {
      default: '',
      field: (index: number) =>
        textField(`dayCategories.${index}.discountUntil`, t().dayCategory.discountUntil),
    },

    inscriptionsLimit: {
      default: 45,
      field: (index: number) =>
        numberField(`dayCategories.${index}.inscriptionsLimit`, t().dayCategory.inscriptionsLimit),
    },
    waitlistLimit: {
      default: 30,
      field: (index: number) =>
        numberField(`dayCategories.${index}.waitlistLimit`, t().dayCategory.waitlistLimit),
    },

    sortPrimaryBy: {
      wide: true,
      default: 'paymentDate',
      field: (index: number) =>
        selectField(`dayCategories.${index}.sortPrimaryBy`, t().dayCategory.sortPrimaryBy, [
          { id: 'paymentDate', name: t().dayCategory.sortPrimaryByNames['paymentDate'] },
          { id: 'enlistingDate', name: t().dayCategory.sortPrimaryByNames['enlistingDate'] },
        ]),
    },

    needsInsurance: {
      default: true,
      field: (index: number) =>
        checkboxField(`dayCategories.${index}.needsInsurance`, t().dayCategory.needsInsurance),
    },
    needsEmergency: {
      default: true,
      field: (index: number) =>
        checkboxField(`dayCategories.${index}.needsEmergency`, t().dayCategory.needsEmergency),
    },

    needsBikeInfo: {
      default: true,
      field: (index: number) =>
        checkboxField(`dayCategories.${index}.needsBikeInfo`, t().dayCategory.needsBikeInfo),
    },
    needsHealthCheck: {
      default: false,
      field: (index: number) =>
        checkboxField(`dayCategories.${index}.needsHealthCheck`, t().dayCategory.needsHealthCheck),
    },

    advertiseSamInsurance: {
      default: false,
      field: (index: number) =>
        checkboxField(
          `dayCategories.${index}.advertiseSamInsurance`,
          t().dayCategory.advertiseSamInsurance
        ),
    },

    sidecar: {
      default: false,
      field: (index: number) => checkboxField(`dayCategories.${index}.sidecar`, t().dayCategory.sidecar),
    },
    sidecarPassenger: {
      default: false,
      field: (index: number) =>
        checkboxField(`dayCategories.${index}.sidecarPassenger`, t().dayCategory.sidecarPassenger),
    },
  },
  sportEvent: {
    status: {
      default: 'draft',
      field: () =>
        selectField(
          'status',
          t().status,
          sportEventStatus.map((value) => ({ id: value, name: t().sportEventStatus[value] }))
        ),
      validation: requiredText(t().status),
    },
    sportEventType: {
      default: '',
      field: () =>
        selectField(
          'sportEventType',
          t().sportEventType,
          sportEventTypes.map((value) => ({ id: value, name: t().sportEventTypes[value] }))
        ),
      hint: () => t().sportEventTypeHint,
      validation: optionalText(t().sportEventType),
    },
    transponderType: {
      default: 'MyLaps',
      field: () =>
        selectField(
          'transponderType',
          t().transponders.transponderSystem,
          transponderTypes.map((value) => ({ id: value, name: value }))
        ),
      validation: requiredText(t().transponders.transponderSystem),
    },
    name: {
      default: '',
      field: () => textField('name', t().name),
      validation: requiredText(t().name),
    },
    place: {
      default: '',
      field: () => textField('place', t().place),
      validation: optionalText(t().place),
    },
    categories: {
      default: [],
      field: (year: Year) =>
        categoriesWithoutAssociationMultiSelectField(
          'categories',
          t().categoriesAlt,
          categoriesByYear(currentLocale(), year),
          undefined
        ),
      validation: Yup.array().when('dayCategories', {
        is: isEmptyArray,
        then: Yup.array().of(Yup.string().required()).min(1).label(t().categoriesAlt),
        otherwise: Yup.array().of(Yup.string().required()).label(t().categoriesAlt),
      }),
    },
    dayCategories: {
      default: [],
      // field: () =>
      //   categoriesWithoutAssociationMultiSelectField(
      //     'categories',
      //     t().categoriesAlt,
      //     categories(currentLocale()),
      //     undefined
      //   ),
      validation: Yup.array()
        .of(
          Yup.object().shape({
            // id: DayCategoryID,
            // sportEvent: SportEventId,

            transponder: optionalText(t().dayCategory.transponder).oneOf(
              transponderTypesIncludingEmpty.map((x) => x)
            ),
            name: requiredText(t().dayCategory.name),
            hint: optionalText(t().dayCategory.hint),
            additionalTermsText: optionalText(t().dayCategory.additionalTermsText),
            additionalTermsURL: optionalText(t().dayCategory.additionalTermsURL),
            price: requiredNumber(t().dayCategory.price),
            needsInsurance: requiredBoolean(t().dayCategory.needsInsurance),
            needsEmergency: requiredBoolean(t().dayCategory.needsEmergency),
            needsHealthCheck: requiredBoolean(t().dayCategory.needsHealthCheck),
            needsBikeInfo: requiredBoolean(t().dayCategory.needsBikeInfo),
            advertiseSamInsurance: requiredBoolean(t().dayCategory.advertiseSamInsurance),
            myLapsName: optionalText(t().dayCategory.myLapsName),
            sidecar: requiredBoolean(t().dayCategory.sidecar),
            sidecarPassenger: requiredBoolean(t().dayCategory.sidecarPassenger),
            surcharge: requiredNumber(t().dayCategory.surcharge),
            surchargeAfter: optionalText(t().dayCategory.surchargeAfter),
            discount: requiredNumber(t().dayCategory.discount),
            discountUntil: optionalText(t().dayCategory.discountUntil),
            inscriptionsLimit: requiredNumber(t().dayCategory.inscriptionsLimit),
            waitlistLimit: requiredNumber(t().dayCategory.waitlistLimit),
            pricePower: requiredNumber(t().dayCategory.pricePower),
            priceTransponderRental: requiredNumber(t().dayCategory.priceTransponderRental),
            sortPrimaryBy: requiredText(t().dayCategory.sortPrimaryBy).oneOf([
              'paymentDate',
              'enlistingDate',
            ]),
          })
        )
        .label(t().dayCategories),
    },
    categoryDates: {
      default: {},
      field: (categoryId: string, date: string) =>
        checkboxField(`categoryDates.${categoryId}.${date}`, ''),
      validation: Yup.object().label(t().categoryDate).required(),
    },
    categoryGroupCounts: {
      default: {},
      field: (startingListNameAndDateLookup: string) =>
        numberField(
          `categoryGroupCounts.${startingListNameAndDateLookup}`,
          t().sportsEvent.sportEventsAdminForm.categoryGroupCounts
        ),
      validation: Yup.object().nullable(),
    },
    categoryGroupSizes: {
      default: {},
      field: (startingListNameAndDateLookup: string) =>
        numberField(
          `categoryGroupSizes.${startingListNameAndDateLookup}`,
          t().sportsEvent.sportEventsAdminForm.categoryGroupSizes
        ),
      validation: Yup.object().nullable(),
    },
    disabledInscriptions: {
      default: {},
      field: (categoryId: string, label: string) =>
        checkboxField(`disabledInscriptions.${categoryId}`, label),
      validation: Yup.object().label(t().inscriptionsDisabled.short).required(),
    },
    startsAt: {
      default: new Date(),
      field: () => dateField('startsAt', t().startsAt),
      validation: requiredDate(t().startsAt),
    },
    endsAt: {
      default: new Date(),
      field: () => dateField('endsAt', t().endsAt),
      validation: requiredDate(t().endsAt),
    },
    alternativeStartsAt: {
      default: null,
      field: () => dateField('alternativeStartsAt', t().alternativeStartsAt),
      validation: optionalDate(t().alternativeStartsAt),
    },
    alternativeEndsAt: {
      default: null,
      field: () => dateField('alternativeEndsAt', t().alternativeEndsAt),
      validation: optionalDate(t().alternativeEndsAt),
    },
    offersPower: {
      default: false,
      field: () => checkboxField('offersPower', t().offersPower),
      validation: Yup.boolean().label(t().offersPower).required(),
    },
    cancelled: {
      default: false,
      field: () => checkboxField('cancelled', t().cancelled),
      validation: Yup.boolean().label(t().cancelled).required(),
    },
    finalized: {
      default: false,
      field: () => checkboxField('finalized', t().finalized),
      validation: Yup.boolean().label(t().finalized).required(),
      hint: () => t().finalizedHint,
    },
    suggestedDonationAmount: {
      default: 0,
      field: () => numberField('suggestedDonationAmount', t().suggestedDonationAmount),
      validation: requiredNumber(t().suggestedDonationAmount),
    },
    association: associationSelectField(),
    // links: {
    //   default: '',
    //   field: () => textField('links', t.links),
    //   validation: requiredText(t.links),
    // },
  },
  changeEmail: {
    email: {
      field: () => (
        <Field variant="outlined" component={TextField} fullWidth name={`email`} label={t().email} />
      ),
      validation: Yup.string().label(t().email).email().required(),
    },
  },
  personalData: {
    email: {
      default: '',
      field: ({ prefix }: { prefix: PDPrefix }) => (
        <Field
          variant="outlined"
          component={TextField}
          fullWidth
          name={`${prefix}email`}
          label={t().email}
        />
      ),
      validation: Yup.string().label(t().email).email().required(),
    },
    firstName: {
      default: '',
      field: ({ disabled = false, prefix }: { prefix: PDPrefix; disabled?: boolean }) =>
        textField(`${prefix}firstName`, t().firstName, { disabled }),
      validation: requiredText(t().firstName),
      hintForName: t().hintForName,
    },
    lastName: {
      default: '',
      field: ({ prefix, disabled = false }: { prefix: PDPrefix; disabled?: boolean }) =>
        textField(`${prefix}lastName`, t().lastName, { disabled }),
      validation: requiredText(t().lastName),
    },
    street: {
      default: '',
      field: ({ prefix }: { prefix: PDPrefix }) => textField(`${prefix}street`, t().street),
      validation: requiredText(t().street),
    },
    zip: {
      default: '',
      field: ({ prefix }: { prefix: PDPrefix }) => textField(`${prefix}zip`, t().zip),
      validation: requiredText(t().zip),
    },
    place: {
      default: '',
      field: ({ prefix }: { prefix: PDPrefix }) => textField(`${prefix}place`, t().place),
      validation: requiredText(t().place),
    },
    country: {
      default: 'Schweiz',
      field: ({ prefix }: { prefix: PDPrefix }) =>
        selectField(
          `${prefix}country`,
          t().country,
          countryNames().map(({ id, name }) => ({ id, name }))
        ),
      validation: requiredText(t().country),
    },
    birthdate: {
      default: new Date(),
      field: ({ prefix }: { prefix: PDPrefix }) => dateField(`${prefix}birthdate`, t().birthdate),
      validation: requiredDate(t().birthdate).max(
        startOfYear(addYears(new Date(), -1)),
        t().birthdateTooYoung
      ),
    },
    phone: {
      default: '',
      field: ({ prefix }: { prefix: PDPrefix }) => textField(`${prefix}phone`, t().phone),
      validation: requiredText(t().phone),
    },
    newSamMember: {
      default: false,
      field: ({ prefix }: { prefix: PDPrefix }) =>
        checkboxField(`${prefix}newSamMember`, t().newSamMember.label),
      validation: Yup.boolean().label(t().newSamMember.validation).nullable(),
      validationRequired: Yup.boolean().label(t().newSamMember.validation).required(),
      hintForNewMembers: t().newSamMember.hintForNewMembers,
      hintForFine: t().newSamMember.hintForFine,
    },
    samMemberNumber: {
      default: null,
      field: ({ prefix, disabled }: { disabled: boolean; prefix: PDPrefix }) =>
        numberField(`${prefix}samMemberNumber`, t().samMemberNumber.label, {
          helperText: t().samMemberNumber.helperText,
          disabled,
        }),
      validation: optionalNumber(t().samMemberNumber.label).nullable(),
      validationRequired: Yup.number().when('newSamMember', {
        is: true,
        then: Yup.number().nullable(),
        otherwise: Yup.number()
          .label(t().samMemberNumber.label)
          .min(10000, t().samMemberNumber.helperText)
          .max(999999, t().samMemberNumber.helperText)
          .required(t().samMemberNumber.requiredHelperText),
      }),
      hintForSubmittedLicense: t().samMemberNumber.hintForSubmittedLicense,
    },
    samSection: {
      default: '',
      field: (fieldOptions: ExternalFieldOptions & { disabled: boolean; prefix: PDPrefix }) =>
        selectField(
          `${fieldOptions.prefix}samSection`,
          t().samSection.label,
          [{ id: 0, name: '' }, ...allSectionsFiltered('sam', !!fieldOptions?.values?.newSamMember)],
          {
            helperText: fieldOptions?.values?.newSamMember ? t().samSection.helperText : undefined,
            disabled: fieldOptions?.disabled,
          }
        ),
      validation: optionalNumber(t().samSection.label).nullable(),
      validationRequired: requiredNumber(t().samSection.label).positive(tYup().mixed.required),
      hintForSubmittedLicense: t().samSection.hintForSubmittedLicense,
    },
    afmSection: {
      default: 0,
      field: (fieldOptions: { prefix: PDPrefix; required: boolean }) =>
        selectField(
          `${fieldOptions.prefix}afmSection`,
          fieldOptions.required ? t().afmSection.label : t().afmSection.labelOptional,
          [{ id: 0, name: '' }, ...allSectionsFiltered('afm', false)]
        ),
      validation: optionalNumber(t().afmSection.label).nullable(),
      validationRequired: requiredNumber(t().afmSection.label).positive(tYup().mixed.required),
    },
    fmsSection: {
      default: 0,
      field: (fieldOptions: { prefix: PDPrefix; required: boolean }) =>
        selectField(
          `${fieldOptions.prefix}fmsSection`,
          fieldOptions.required ? t().fmsSection.label : t().fmsSection.labelOptional,
          [{ id: 0, name: '' }, ...allSectionsFiltered('fms', false)]
        ),
      validation: optionalNumber(t().fmsSection.label).nullable(),
      validationRequired: requiredNumber(t().fmsSection.label).positive(tYup().mixed.required),
    },
    parentsInfo: {
      default: '',
      field: ({ prefix }: { prefix: PDPrefix }) => textField(`${prefix}parentsInfo`, t().parentsInfo),
      validation: Yup.string().when('birthdate', {
        is: isUnder18,
        then: requiredText(t().parentsInfo),
        otherwise: Yup.string(),
      }),
    },
    parentsAgree: {
      default: true,
      field: ({ prefix }: { prefix: PDPrefix }) =>
        checkboxField(`${prefix}parentsAgree`, t().parentsAgree.label),
      validation: Yup.boolean().when('birthdate', {
        is: isUnder18,
        then: Yup.boolean().label(t().parentsAgree.validation).required().equals([true]),
        otherwise: Yup.boolean(),
      }),
    },
  },
  enlistDayInscription: {
    preferredNumber: {
      default: '',
      field: () =>
        textField('preferredNumber', t().preferredNumber.label, {
          helperText: t().preferredNumber.helperText,
        }),
      validation: requiredText(t().preferredNumber.label),
    },
    sidecarPartner: {
      default: '',
      field: () => textField('sidecarPartner', t().sidecarPartner.label),
      validation: optionalText(t().sidecarPartner.label),
      hint: t().sidecarPartner.hint,
    },
    remarksRider: {
      default: '',
      field: () => textField('remarksRider', t().comment, { multiline: true }),
      validation: optionalText(t().comment),
    },
    acceptTerms: {
      default: false,
      field: (terms: string) => checkboxField('acceptTerms', terms || t().acceptTerms.label),
      validation: Yup.boolean()
        .label(t().acceptTerms.validation)
        .required()
        .equals([true], t().acceptTerms.validation),
      hintForTerms: t().acceptTerms.hintForTerms,
    },
  },
  lastYear: {
    noPreviousLicense: {
      default: false,
      field: () => checkboxField('noPreviousLicense', t().noPreviousLicense),
      validation: Yup.boolean().label(t().noPreviousLicense).required(),
    },
    lastYearLicenses: {
      default: '',
      field: () => textField('lastYearLicenses', t().lastYearLicenses),
      validation: Yup.string().when('noPreviousLicense', {
        is: true,
        then: optionalText(t().lastYearLicenses),
        otherwise: requiredText(t().lastYearLicenses),
      }),
    },
    lastYearCategories: {
      default: '',
      field: () => textField('lastYearCategories', t().lastYearCategories),
      validation: Yup.string().when('noPreviousLicense', {
        is: true,
        then: optionalText(t().lastYearCategories),
        otherwise: requiredText(t().lastYearCategories),
      }),
    },
    lastYearStartNumbers: {
      default: '',
      field: () => textField('lastYearStartNumbers', t().lastYearStartNumbers),
      validation: Yup.string().when('noPreviousLicense', {
        is: true,
        then: optionalText(t().lastYearStartNumbers),
        otherwise: requiredText(t().lastYearStartNumbers),
      }),
    },
    lastYearRanks: {
      default: '',
      field: () => textField('lastYearRanks', t().lastYearRanks),
      validation: Yup.string().when('noPreviousLicense', {
        is: true,
        then: optionalText(t().lastYearRanks),
        otherwise: requiredText(t().lastYearRanks),
      }),
    },
  },
  categories: {
    categories: {
      default: [],
      field: (approvedLicenses: ApprovedLicense[], year: Year) =>
        categoriesWithAssociationMultiSelectField(
          'categories',
          t().categoriesWithoutYear,
          categoriesByYear(currentLocale(), year).filter((category) => category.active),
          approvedLicenses.filter((license) => categoryByIdRequired(license.categoryId).year === year)
        ),
      validation: Yup.array()
        .of(
          Yup.object().required().shape({
            association: Yup.string(),
            categoryType: Yup.string(),
            category: Yup.string(),
          })
        )
        .min(0)
        .label(t().categoriesWithoutYear),
    },
  },
  categoriesDropdown: {
    categoryId: {
      default: '',
      field: (
        prefix: 'drafts' | 'approved',
        index: number,
        association: AssociationID | undefined,
        currentYear: Year,
        options: { disabled?: boolean } = {}
      ) =>
        selectField(
          `categoryDetails.${prefix}[${index}].categoryId`,
          t().category,
          categoriesByYear(currentLocale(), currentYear)
            .filter((category) => !association || category.associations.includes(association))
            .map((category) => ({
              id: category.id,
              name: `${category.associations
                .map(associationByID)
                .map((it) => it.name)
                .join(' / ')} ${category.commonName}`,
            })),
          options
        ),
      validation: requiredText(t().category),
    },
    categoryIdByAssociation: {
      default: '',
      field: (
        prefix: 'drafts' | 'approved',
        index: number,
        association: AssociationID | undefined,
        year: Year,
        options: { disabled?: boolean } = {}
      ) =>
        selectField(
          `categoryDetails.${prefix}[${index}].categoryId`,
          t().category,
          categoriesByYear(currentLocale(), year)
            .flatMap((category) =>
              category.associations.map((association) =>
                categoryOfAssociationRequired(category.id, association)
              )
            )
            .filter((category) => !association || category.association === association)
            .map((category) => ({
              id: category.id,
              name: `${associationByID(category.association).name} - ${category.name}`,
            })),
          options
        ),
      validation: requiredText(t().category),
    },
    licenseType: {
      default: '',
      field: (prefix: 'drafts' | 'approved', index: number, options: { disabled?: boolean } = {}) =>
        selectField(
          `categoryDetails.${prefix}[${index}].licenseType`,
          t().licenseType,
          licenseTypes.map((licenseType) => ({ name: t().licenseTypes[licenseType], id: licenseType })),
          options
        ),
      validation: requiredText(t().licenseType),
    },
    association: {
      default: '',
      field: (prefix: 'drafts' | 'approved', index: number, options: { disabled?: boolean } = {}) =>
        associationSelectField().field({
          name: `categoryDetails.${prefix}[${index}].licenseAssociation`,
          disabled: !!options.disabled,
        }),
      validation: associationSelectField().validation,
    },
    insuranceOption: {
      default: '',
      field: (prefix: 'drafts' | 'approved', index: number, options: { disabled?: boolean } = {}) =>
        selectField(
          `categoryDetails.${prefix}[${index}].insuranceOption`,
          t().insuranceOption.adjustLabel,
          selectableInsuranceOptions({ showAll: true }),
          options
        ),
      validation: requiredText(t().insuranceOption.validation),
    },
    preferredNumber: {
      default: '',
      field: (index: number) =>
        textField(`categoryDetails.drafts[${index}].preferredNumber`, t().preferredNumber.label),
      validation: requiredText(t().preferredNumber.label),
      optionalValidation: optionalText(t().preferredNumber.label),
    },
    issuedNumber: {
      default: 0,
      field: (index: number) =>
        numberField(`categoryDetails.approved[${index}].issuedNumber`, t().issuedNumber.label),
      validation: requiredNumber(t().issuedNumber.label),
      optionalValidation: optionalNumber(t().issuedNumber.label),
    },
    sidecarPartner: {
      default: '',
      field: (prefix: 'drafts' | 'approved', index: number) =>
        textField(`categoryDetails.${prefix}[${index}].sidecarPartner`, t().sidecarPartner.label),
      validation: optionalText(t().sidecarPartner.label),
      hint: t().sidecarPartner.hint,
    },
    teamName: {
      default: '',
      field: (prefix: 'drafts' | 'approved', index: number, options: { disabled?: boolean } = {}) =>
        textField(`categoryDetails.${prefix}[${index}].teamName`, t().teamName.label, {
          disabled: !!options.disabled,
        }),
      validation: optionalText(t().teamName.label),
    },
    bikeMake: {
      default: '',
      field: (prefix: 'drafts' | 'approved', index: number, options: { disabled?: boolean } = {}) =>
        textField(`categoryDetails.${prefix}[${index}].bikeMake`, t().bikeMake.label, {
          disabled: !!options.disabled,
        }),
      validation: optionalText(t().bikeMake.label),
    },
  },
  transponder: {
    hasTransponder: {
      default: false,
      field: (prefix: string) => checkboxField(`${prefix}hasTransponder`, t().hasTransponder.label),
      validation: () => Yup.boolean().label(t().hasTransponder.validation).required(),
    },
    orderedTransponders: {
      default: [],
      field: (prefix: string, categories: CategoryOfAssociation[], values: TransponderOptionId[]) =>
        checkboxesField(
          `${prefix}orderedTransponders`,
          values.length <= 1
            ? t().orderedMyLapsTransponder.labelSingular
            : t().orderedMyLapsTransponder.labelPlural,
          transponderOptions
            .filter((option) => enableTransponderOption(option, categories, values))
            .map((option) => ({ name: option.name(), id: option.id }))
        ),
      validation: () => Yup.array().of(Yup.string()).label(t().orderedMyLapsTransponder.validation),
    },
    transponderMake: {
      default: '',
      field: (prefix: string) => textField(`${prefix}transponderMake`, t().transponderMake.label),
      validation: (admin: boolean) =>
        admin
          ? Yup.string()
          : Yup.string().when('hasTransponder', {
              is: true,
              then: requiredText(t().transponderMake.label),
              otherwise: Yup.string(),
            }),
    },
    transponderNumbers: {
      default: undefined,
      field: (prefix: string) =>
        textField(`${prefix}transponderNumbers`, t().transponderNumbersInput.label),
      validation: (admin: boolean) =>
        admin
          ? validTransponderString(false)
          : Yup.string().when('hasTransponder', {
              is: true,
              then: validTransponderString(true),
              otherwise: validTransponderString(false),
            }),
    },
    hasTransponderRf: {
      default: false,
      field: (prefix: string) => checkboxField(`${prefix}hasTransponderRf`, t().hasTransponderRf.label),
      validation: () => Yup.boolean().label(t().hasTransponderRf.validation).required(),
    },
    transponderNumbersRf: {
      default: undefined,
      field: (prefix: string) =>
        textField(`${prefix}transponderNumbersRf`, t().transponderNumbersRfInput.label),
      validation: (admin: boolean) =>
        admin
          ? validTransponderRfString(false)
          : Yup.string().when('hasTransponderRf', {
              is: true,
              then: validTransponderRfString(true),
              otherwise: validTransponderRfString(false),
            }),
    },
  },
  associationPayment: {
    date: {
      default: '',
      field: () => dateField('date', t().datetime),
      validation: () => requiredDate(t().datetime),
    },
    amount: {
      default: 0,
      field: () => numberField('amount', t().amount.label),
      validation: () => requiredNumber(t().amount.label).min(0.1),
    },
    association: {
      ...associationSelectField(),
      validation: () => requiredText(t().association),
    },
    internalRemarks: {
      default: '',
      field: () => textField('internalRemarks', t().internalRemarks),
      validation: () => optionalText(t().internalRemarks),
    },
  },
  motocrossBike: {
    name: {
      default: '',
      field: () => textField('name', t().bikeName.label, { helperText: t().bikeName.hint }),
      validation: optionalText(t().bikeName.label),
    },
    teamName: {
      default: '',
      field: () => textField('teamName', t().teamName.label),
      validation: optionalText(t().teamName.label),
    },
    acceptBikeTerms: {
      default: false,
      field: () => checkboxField('acceptBikeTerms', t().acceptBikeTerms.label(nextLicenseYear)),
      validation: Yup.boolean()
        .label(t().acceptBikeTerms.label(nextLicenseYear))
        .required()
        .equals([true], t().acceptBikeTerms.validation),
    },
    bikeMake: {
      default: '',
      field: () => textField('bikeMake', t().bikeMake.label),
      validation: requiredText(t().bikeMake.label),
    },
    bikeModel: {
      onlyFor: ['motocross', 'supermoto'] as CategoryType[],
      default: '',
      field: () => textField('bikeModel', t().bikeModel.label, { helperText: t().bikeModel.hint }),
      validation: requiredText(t().bikeModel.label),
    },
    frameNumber: {
      onlyFor: ['motocross', 'supermoto'] as CategoryType[],
      default: '',
      field: () => textField('frameNumber', t().frameNumber),
      validation: requiredText(t().frameNumber),
    },
  },
  enduroBike: {
    name: {
      default: '',
      field: () => textField('name', t().bikeName.label, { helperText: t().bikeName.hint }),
      validation: optionalText(t().bikeName.label),
    },
    teamName: {
      default: '',
      field: () => textField('teamName', t().teamName.label),
      validation: optionalText(t().teamName.label),
    },
    acceptBikeTerms: {
      default: false,
      field: () => checkboxField('acceptBikeTerms', t().acceptBikeTerms.label(nextLicenseYear)),
      validation: Yup.boolean()
        .label(t().acceptBikeTerms.label(nextLicenseYear))
        .required()
        .equals([true], t().acceptBikeTerms.validation),
    },
    bikeMake: {
      default: '',
      field: () => textField('bikeMake', t().bikeMake.label),
      validation: requiredText(t().bikeMake.label),
    },
    bikeModel: {
      default: '',
      field: () => textField('bikeModel', t().bikeModel.label, { helperText: t().bikeModel.hint }),
      validation: requiredText(t().bikeModel.label),
    },
    numberPlate: {
      default: '',
      field: () => textField('numberPlate', t().numberPlate.label, { helperText: t().numberPlate.hint }),
      validation: requiredText(t().numberPlate.label),
    },
    insurance: {
      default: '',
      field: () =>
        textField('insurance', t().bikeInsurance.label, { helperText: t().bikeInsurance.hint }),
      validation: requiredText(t().bikeInsurance.label),
    },
    frameNumber: {
      default: '',
      field: () => textField('frameNumber', t().frameNumber),
      validation: requiredText(t().frameNumber),
    },
  },

  // bikeCylinderDisplacement: {
  //   onlyFor: ['mofacross', 'mofacup', 'pitbikecross'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeCylinderDisplacement', t.bikeCylinderDisplacement),
  //   validation: requiredText(t.bikeCylinderDisplacement),
  // },
  // bikeFrame: {
  //   onlyFor: ['mofacross', 'mofacup', 'pitbikecross'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeFrame', t.bikeFrame),
  //   validation: requiredText(t.bikeFrame),
  // },
  // bikeMotorName: {
  //   onlyFor: ['mofacross', 'mofacup'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeMotorName', t.bikeMotorName),
  //   validation: requiredText(t.bikeMotorName),
  // },
  // bikeMotorNumber: {
  //   onlyFor: ['pitbikecross'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeMotorNumber', t.bikeMotorNumber),
  //   validation: requiredText(t.bikeMotorNumber),
  // },
  // bikeIntake: {
  //   onlyFor: ['mofacross', 'mofacup'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeIntake', t.bikeIntake),
  //   validation: requiredText(t.bikeIntake),
  // },
  // bikeCylinderCooling: {
  //   onlyFor: ['mofacross', 'mofacup'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeCylinderCooling', t.bikeCylinderCooling),
  //   validation: requiredText(t.bikeCylinderCooling),
  // },
  // bikeCylinderIsOriginal: {
  //   onlyFor: ['pitbikecross'] as CategoryType[],
  //   default: false,
  //   field: () => textField('bikeCylinderIsOriginal', t.bikeCylinderIsOriginal),
  //   validation: requiredText(t.bikeCylinderIsOriginal),
  // },
  // bikeIgnition: {
  //   onlyFor: ['mofacross', 'mofacup'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeIgnition', t.bikeIgnition),
  //   validation: requiredText(t.bikeIgnition),
  // },
  // bikePistonSize: {
  //   onlyFor: ['mofacross', 'mofacup', 'pitbikecross'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikePistonSize', t.bikePistonSize),
  //   validation: requiredText(t.bikePistonSize),
  // },
  // bikeCarburetorSize: {
  //   onlyFor: ['mofacross', 'mofacup', 'pitbikecross'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeCarburetorSize', t.bikeCarburetorSize),
  //   validation: requiredText(t.bikeCarburetorSize),
  // },
  // bikeCrankshaftIsOriginal: {
  //   onlyFor: ['mofacross', 'mofacup', 'pitbikecross'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeCrankshaftIsOriginal', t.bikeCrankshaftIsOriginal),
  //   validation: requiredText(t.bikeCrankshaftIsOriginal),
  // },
  // bikeCrankshaftModified: {
  //   onlyFor: ['mofacross', 'mofacup', 'pitbikecross'] as CategoryType[],
  //   default: '',
  //   field: () => textField('bikeCrankshaftModified', t.bikeCrankshaftModified),
  //   validation: requiredText(t.bikeCrankshaftModified),
  // },
  // riderWeight: {
  //   onlyFor: ['minibike'] as CategoryType[],
  //   default: 75,
  //   field: () => numberField('riderWeight', t.riderWeight),
  //   validation: requiredNumber(t.riderWeight),
  // },

  categoryDetails: {
    preferredNumber: {
      default: '',
      field: () =>
        textField('preferredNumber', t().preferredNumber.label, {
          helperText: t().preferredNumber.helperText,
        }),
      validation: requiredText(t().preferredNumber.label),
    },
    sidecarPartner: {
      default: '',
      field: () => textField('sidecarPartner', t().sidecarPartner.label),
      validation: requiredText(t().sidecarPartner.label),
      hint: t().sidecarPartner.hint,
    },
    comment: {
      default: '',
      field: () => textField('comment', t().comment),
      validation: requiredText(t().comment),
    },
    licenseType: {
      default: '',
      field: (options: { name: string; id: LicenseType }[]) =>
        selectField('licenseType', t().licenseType, options, { disabled: options.length === 1 }),
      validation: requiredText(t().licenseType),
    },
    insuranceOption: {
      default: '',
      field: () =>
        selectField('insuranceOption', t().insuranceOption.adjustLabel, selectableInsuranceOptions()),
      validation: requiredText(t().insuranceOption.validation),
    },
    teamName: {
      default: '',
      field: () => textField('teamName', t().teamName.label),
      validation: optionalText(t().teamName.label),
    },
    bikeMake: {
      default: '',
      field: () => textField('bikeMake', t().bikeMake.label),
      validation: requiredText(t().bikeMake.label),
    },
  },
  // photoPath: {
  //   default: '',
  //   field: () => textField('photoPath', t.photoPath),
  //   validation: requiredText(t.photoPath),
  // },
  driversLicense: {
    id: {
      default: '',
      field: () => textField('id', t().driversLicense.driversLicenseId),
      validation: optionalText(t().driversLicense.driversLicenseId),
    },
  },
  emergency: {
    ahvNumber: {
      default: '',
      field: () => textField('ahvNumber', t().ahvNumber),
      validation: requiredText(t().ahvNumber),
    },
    civilStatus: {
      default: '',
      field: () => selectField('civilStatus', t().civilStatus.label, civilStatusOptions),
      validation: requiredText(t().civilStatus.label),
    },
    confession: {
      default: '',
      field: () => textField('confession', t().confession),
      validation: optionalText(t().confession),
    },
    profession: {
      default: '',
      field: () => textField('profession', t().profession),
      validation: requiredText(t().profession),
    },
    doctorName: {
      default: '',
      field: () => textField('doctorName', t().doctorName),
      validation: requiredText(t().doctorName),
    },
    doctorPhone: {
      default: '',
      field: () => textField('doctorPhone', t().doctorPhone),
      validation: requiredText(t().doctorPhone),
    },
    employerName: {
      default: '',
      field: () => textField('employerName', t().employerName),
      validation: requiredText(t().employerName),
    },
    employerPhone: {
      default: '',
      field: () => textField('employerPhone', t().employerPhone),
      validation: requiredText(t().employerPhone),
    },
    accidentInsuranceName: {
      default: '',
      field: () => textField('accidentInsuranceName', t().accidentInsuranceName),
      validation: requiredText(t().accidentInsuranceName),
    },
    accidentInsurancePolicyNumber: {
      default: '',
      field: () => textField('accidentInsurancePolicyNumber', t().accidentInsurancePolicyNumber),
      validation: optionalText(t().accidentInsurancePolicyNumber),
    },
    accidentInsurancePlan: {
      default: '',
      field: () =>
        selectField('accidentInsurancePlan', t().accidentInsurancePlan.label, insuranceModels),
      validation: requiredText(t().accidentInsurancePlan.label),
    },
    healthInsuranceName: {
      default: '',
      field: () => textField('healthInsuranceName', t().healthInsuranceName),
      validation: requiredText(t().healthInsuranceName),
    },
    healthInsurancePolicyNumber: {
      default: '',
      field: () => textField('healthInsurancePolicyNumber', t().healthInsurancePolicyNumber),
      validation: optionalText(t().healthInsurancePolicyNumber),
    },
    healthInsurancePlan: {
      default: '',
      field: () => selectField('healthInsurancePlan', t().healthInsurancePlan.label, insuranceModels),
      validation: requiredText(t().healthInsurancePlan.label),
    },
    hasSupplementaryInsurance: {
      default: false,
      field: () => checkboxField('hasSupplementaryInsurance', t().hasSupplementaryInsurance.label),
      validation: Yup.boolean().label(t().hasSupplementaryInsurance.validation).required(),
      hintForSupplementaryInsurance: t().hasSupplementaryInsurance.hintForSupplementaryInsurance,
    },
    hasReturnTransportInsurance: {
      default: false,
      field: () => checkboxField('hasReturnTransportInsurance', t().hasReturnTransportInsurance.label),
      validation: Yup.boolean().label(t().hasReturnTransportInsurance.validation).required(),
      hintForNewReturnTransportInsurance:
        t().hasReturnTransportInsurance.hintForNewReturnTransportInsurance,
    },
    returnTransportInsuranceName: {
      default: '',
      field: () => textField('returnTransportInsuranceName', t().returnTransportInsuranceName),
      validation: Yup.string().when('hasReturnTransportInsurance', {
        is: true,
        then: requiredText(t().returnTransportInsuranceName),
        otherwise: Yup.string(),
      }),
    },
    contactOnAccident1Name: {
      default: '',
      field: () => textField('contactOnAccident1Name', t().contactOnAccident1Name),
      validation: requiredText(t().contactOnAccident1Name),
    },
    contactOnAccident1Phone: {
      default: '',
      field: () => textField('contactOnAccident1Phone', t().contactOnAccident1Phone),
      validation: requiredText(t().contactOnAccident1Phone),
    },
    contactOnAccident2Name: {
      default: '',
      field: () => textField('contactOnAccident2Name', t().contactOnAccident2Name),
      validation: optionalText(t().contactOnAccident2Name),
    },
    contactOnAccident2Phone: {
      default: '',
      field: () => textField('contactOnAccident2Phone', t().contactOnAccident2Phone),
      validation: optionalText(t().contactOnAccident2Phone),
    },
    medication: {
      default: '',
      field: () => textField('medication', t().medication),
      validation: optionalText(t().medication),
    },
    allergies: {
      default: '',
      field: () => textField('allergies', t().allergies),
      validation: optionalText(t().allergies),
    },
    infoForDoctor: {
      default: '',
      field: () => textField('infoForDoctor', t().infoForDoctor, { multiline: true }),
      validation: optionalText(t().infoForDoctor),
    },
    bloodGroup: {
      default: '',
      field: () => textField('bloodGroup', t().bloodGroup),
      validation: optionalText(t().bloodGroup),
    },
    bloodRhesusFactor: {
      default: '',
      field: () => textField('bloodRhesusFactor', t().bloodRhesusFactor),
      validation: optionalText(t().bloodRhesusFactor),
    },
    specialInformation: {
      default: '',
      field: () => textField('specialInformation', t().specialInformation, { multiline: true }),
      validation: optionalText(t().specialInformation),
    },
    confirmRevokeConfidentiality: {
      default: false,
      field: () => checkboxField('confirmRevokeConfidentiality', t().confirmRevokeConfidentiality.label),
      validation: Yup.boolean()
        .label(t().confirmRevokeConfidentiality.validation)
        .required()
        .equals([true], t().confirmRevokeConfidentiality.validation),
    },
    emergencyConfirmation: {
      default: false,
      field: () =>
        checkboxField('emergencyConfirmation', t().emergencyDetails.emergencyConfirmation.label),
      validation: Yup.boolean()
        .label(t().emergencyDetails.emergencyConfirmation.label)
        .required()
        .equals([true], t().emergencyDetails.emergencyConfirmation.validation),
    },
  },
  summary: {
    remarks: {
      default: '',
      field: () => textField('remarks', t().remarks, { multiline: true }),
      validation: optionalText(t().remarks),
    },
    acceptTerms: {
      default: false,
      field: () => checkboxField('acceptTerms', t().acceptTerms.label),
      validation: Yup.boolean()
        .label(t().acceptTerms.validation)
        .required()
        .equals([true], t().acceptTerms.hintForTermsError),
      hintForTerms: t().acceptTerms.hintForTerms,
    },
  },
  adminMessages: {
    recipient: {
      default: null,
      field<T extends Record<any, any>>({
        touched,
        errors,
      }: {
        touched: FormikTouched<T>
        errors: FormikErrors<T>
      }) {
        return autocompleteSelectField<T>(
          'recipient',
          t().messages.recipient,
          recepientOptions(),
          touched,
          errors
        )
      },
      validation: Yup.object().nullable().label(t().messages.recipient).required(),
    },
    type: {
      default: '',
      field: () => selectField('type', t().messages.type, messageTypes),
      validation: requiredText(t().messages.type),
    },
    simulate: {
      default: '',
      field: () => checkboxField('simulate', t().messages.simulate),
      validation: Yup.boolean().label(t().messages.simulate).required(),
    },
    location: {
      default: '',
      field: () =>
        textField('location', t().messages.location.label, { helperText: t().messages.location.hint }),
      validation: requiredText(t().messages.location.label),
    },
    date: {
      field: () => textField('date', t().messages.date.label, { helperText: t().messages.date.hint }),
      validation: optionalText(t().messages.date.label),
    },
    sportEvent: {
      field: (sportEvents: AutocompleteOption[]) =>
        selectField('sportEvent', t().messages.sportEvent, sportEvents),
    },
  },
  inscriptionBooking: {
    date: {
      default: '',
      field: () => dateTimeField('date', t().datetime),
      validation: requiredDate(t().datetime),
    },
    amount: {
      default: 0,
      field: () => numberField('amount', t().amount.label, { helperText: t().amount.hint }),
      validation: requiredNumber(t().amount.label),
    },
    internalRemarks: {
      default: '',
      field: () => textField('internalRemarks', t().internalRemarks),
      validation: optionalText(t().internalRemarks),
    },
  },
  licenseBooking: {
    date: {
      default: '',
      field: () => dateTimeField('date', t().datetime),
      validation: requiredDate(t().datetime),
    },
    internalRemarks: {
      default: '',
      field: () => textField('internalRemarks', t().internalRemarks),
      validation: optionalText(t().internalRemarks),
    },
  },
  manualBooking: {
    uid: {
      default: null,
      field<T extends Record<any, any>>(props: {
        touched: FormikTouched<T>
        errors: FormikErrors<T>
        options: AutocompleteOption[]
      }) {
        const { touched, errors, options } = props
        return autocompleteSelectField<T>('uid', t().user, options, touched, errors)
      },
      validation: Yup.object().nullable().label(t().user).required(),
    },
    date: {
      default: '',
      field: () => dateTimeField('date', t().datetime),
      validation: requiredDate(t().datetime),
    },
    bookingText: {
      default: '',
      field: () => textField('bookingText', t().bookingText),
      validation: requiredText(t().bookingText),
    },
    amount: {
      default: 0,
      field: () => numberField('amount', t().amount.label, { helperText: t().amount.hint }),
      validation: requiredNumber(t().amount.label),
    },
    internalRemarks: {
      default: '',
      field: () => textField('internalRemarks', t().internalRemarks),
      validation: optionalText(t().internalRemarks),
    },
    sportEvent: {
      default: '',
      field<T extends Record<any, any>>(props: {
        touched: FormikTouched<T>
        errors: FormikErrors<T>
        options: AutocompleteOption[]
      }) {
        const { touched, errors, options } = props
        return autocompleteSelectField<T>('sportEvent', t().sportEvent, options, touched, errors)
      },
      validation: Yup.object().nullable().label(t().sportEvent).optional(),
    },
    category: {
      default: null,
      field<T extends Record<any, any>>(props: {
        touched: FormikTouched<T>
        errors: FormikErrors<T>
        association: AssociationID | undefined
      }) {
        const { touched, errors, association } = props
        return autocompleteSelectField<T>(
          'category',
          t().category,
          categoriesByAssociation(currentLocale(), association).map((category) =>
            categoryOfAssociationRequired(category.id, association || category.associations[0])
          ),
          touched,
          errors
        )
      },
      validation: Yup.object().nullable().label(t().category).optional(),
    },
    tag: {
      default: null,
      field<T extends Record<any, any>>(props: {
        touched: FormikTouched<T>
        errors: FormikErrors<T>
        tags: string[]
      }) {
        const { touched, errors, tags } = props
        return mutableAutocompleteSelectField<T>('tag', t().manualBookingTag, tags, touched, errors)
      },
      validation: Yup.string().nullable().label(t().manualBookingTag).required(),
    },
    association: associationSelectField(),
  },
  manualPayment: {
    date: {
      field: () => dateTimeField('date', t().datetime),
      validation: requiredDate(t().datetime),
    },
    amount: {
      field: () => numberField('amount', t().amount.label, { helperText: t().amount.hint }),
      validation: requiredNumber(t().amount.label),
    },
    tag: {
      default: null,
      field<T extends Record<any, any>>(props: {
        touched: FormikTouched<T>
        errors: FormikErrors<T>
        tags: string[]
      }) {
        const { touched, errors, tags } = props
        return mutableAutocompleteSelectField<T>('tag', t().manualPaymentTag, tags, touched, errors)
      },
      validation: Yup.string().nullable().label(t().manualPaymentTag).required(),
    },
    association: associationSelectField(),
    internalRemarks: {
      default: '',
      field: () => textField('internalRemarks', t().internalRemarks),
      validation: optionalText(t().internalRemarks),
    },
  },
  editTag: {
    tag: {
      default: '',
      field: () => textField('tag', t().manualPaymentTag),
      validation: requiredText(t().manualPaymentTag),
    },
  },
  invalidPayment: {
    bill: {
      field<T extends Record<any, any>>(props: {
        touched: FormikTouched<T>
        errors: FormikErrors<T>
        billOptions: AutocompleteOption[]
      }) {
        const { touched, errors, billOptions: bills } = props
        return autocompleteSelectField<T>('bill', t().bill, bills, touched, errors)
      },
      validation: Yup.object().nullable().label(t().bill).required(),
    },
  },
}))

export type PDPrefix = '' | 'personalData.'

export function isUnder18(birthdate: Date | undefined) {
  if (!birthdate) return false
  return isAfter(birthdate, subYears(new Date(), 18))
}

function isEmptyArray<T>(array: T[] | undefined) {
  return !array || array.length === 0
}

interface ExternalFieldOptions {
  values?: Record<string, unknown>
}

function associationSelectField() {
  return {
    default: '',
    field: ({ disabled, name = 'association' }: { disabled: boolean; name?: string }) =>
      selectField(name, t().association, associations, { disabled }),
    validation: requiredText(t().association),
  }
}

function requiredText(label: string) {
  return optionalText(label).required()
}

function optionalText(label: string) {
  return Yup.string().label(label).trim()
}

function requiredNumber(label: string) {
  return optionalNumber(label).required()
}

function optionalNumber(label: string) {
  return Yup.number().label(label)
}

function requiredBoolean(label: string) {
  return Yup.boolean().label(label).required()
}

function requiredDate(label: string) {
  return optionalDate(label).required()
}

function optionalDate(label: string) {
  return Yup.date().nullable().label(label)
}

export function textField(
  name: string,
  label: string,
  options: { helperText?: string; multiline?: true; disabled?: boolean; type?: string } = {}
) {
  return (
    <Field
      variant="outlined"
      component={TextField}
      fullWidth
      name={name}
      label={label}
      disabled={options.disabled}
      helperText={options.helperText}
      multiline={options.multiline}
      type={options.type}
    />
  )
}

function numberField(
  name: string,
  label: string,
  options: { helperText?: string; disabled?: boolean } = {}
) {
  return (
    <Field
      variant="outlined"
      component={TextField}
      fullWidth
      name={name}
      label={label}
      disabled={options.disabled}
      helperText={options.helperText}
      type="number"
    />
  )
}

function selectField<T extends string | number>(
  name: string,
  label: string,
  items: readonly AutocompleteOption<T>[],
  options: { helperText?: string; disabled?: boolean } = {}
) {
  return (
    <Field
      variant="outlined"
      component={TextField}
      fullWidth
      select={true}
      name={name}
      label={label}
      helperText={options.helperText}
      disabled={options.disabled}
    >
      {items.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Field>
  )
}

export interface AutocompleteOption<T extends string | number = string> {
  readonly id: T
  readonly name: string
}

function autocompleteSelectField<T extends Record<any, any>>(
  name: string,
  label: string,
  items: ReadonlyArray<AutocompleteOption>,
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  options: { disabled?: boolean } = {}
) {
  return (
    <Field
      variant="outlined"
      component={Autocomplete}
      fullWidth
      name={name}
      label={label}
      disabled={options.disabled}
      options={items}
      autoHighlight
      autoSelect
      getOptionLabel={(option: AutocompleteOption) =>
        items.find((item) => item.id === option.id)?.name || option.name || ''
      }
      getOptionSelected={(option: AutocompleteOption | null, value: AutocompleteOption | null) =>
        option?.id === value?.id
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MUTextField
          {...params}
          name={name}
          error={touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          label={label}
          variant="outlined"
        />
      )}
    />
  )
}

function mutableAutocompleteSelectField<T extends Record<any, any>>(
  name: string,
  label: string,
  items: string[],
  touched: FormikTouched<T>,
  errors: FormikErrors<T>,
  options: { disabled?: boolean; freeSolo?: boolean } = {}
) {
  return (
    <Field
      variant="outlined"
      component={Autocomplete}
      fullWidth
      name={name}
      label={label}
      disabled={options.disabled}
      options={items}
      autoHighlight
      autoSelect
      freeSolo
      getOptionLabel={(option: string) => option || ''}
      getOptionSelected={(option: string | null, value: string | null) => option === value}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MUTextField
          {...params}
          name={name}
          error={touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          label={label}
          variant="outlined"
        />
      )}
    />
  )
}

function categoriesWithAssociationMultiSelectField(
  name: string,
  label: string,
  categories: Category[],
  approvedLicenses: ApprovedLicense[] | undefined
) {
  return (
    <Field
      variant="outlined"
      component={CategoriesCheckboxGroup}
      fullWidth
      name={name}
      label={label}
      options={{ categories, approvedLicenses }}
    >
      {categories}
    </Field>
  )
}

function categoriesWithoutAssociationMultiSelectField(
  name: string,
  label: string,
  categories: Category[],
  approvedLicenses: ApprovedLicense[] | undefined
) {
  return (
    <Field
      variant="outlined"
      component={CategoriesCheckboxes}
      fullWidth
      name={name}
      label={label}
      options={{ categories, approvedLicenses }}
    >
      {categories}
    </Field>
  )
}

function dateField(name: string, label: string) {
  return (
    <Field inputVariant="outlined" component={KeyboardDatePicker} fullWidth name={name} label={label} />
  )
}

function dateTimeField(name: string, label: string) {
  return (
    <Field
      inputVariant="outlined"
      component={KeyboardDateTimePicker}
      fullWidth
      name={name}
      label={label}
      ampm={false}
    />
  )
}

function checkboxField(name: string, label: string) {
  return (
    <Field
      variant="outlined"
      component={CheckboxWithLabel}
      indeterminate={false}
      type="checkbox"
      name={name}
      Label={{ label }}
    />
  )
}

function checkboxesField(name: string, label: string, options: AutocompleteOption[]) {
  return (
    <Field
      variant="outlined"
      component={CheckboxGroup}
      fullWidth
      name={name}
      label={label}
      options={options}
    />
  )
}

function validTransponderString(required: boolean) {
  return Yup.string()
    .test('is-valid-transponders', t().transponderNumbersInput.label, (value) =>
      validTransponderInput(value, required)
    )
    .label(t().transponderNumbersInput.label)
}

function validTransponderRfString(required: boolean) {
  return Yup.string()
    .test('is-valid-transponders', t().transponderNumbersRfInput.label, (value) =>
      validTransponderInput(value, required)
    )
    .label(t().transponderNumbersRfInput.label)
}
