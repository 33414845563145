import { createStyles, FormHelperText, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { PricesByAgeHelperTexts } from 'app/forms/categories-checkbox-group'
import { samFields } from 'app/forms/fields'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import {
  requiresInsuranceOptionByCategory,
  selectableLicenseTypesOfCategory,
} from 'shared/data/categories-service'
import { LicenseCategoryContext } from 'shared/license/license-draft-categories'
import { AssociationCategory, associationSpecificDetails, Category } from 'shared/models/category'
import { CategoryDetails } from 'shared/models/category-details'
import { standardInsuranceOption } from 'shared/models/insurance-options'

interface CategoryDetailsProps {
  initialValues?: CategoryDetails
  category: Category
  licenseCategoryContext: LicenseCategoryContext
  onSubmit: (data: CategoryDetails) => Promise<void>
  onBack: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function CategoryDetailsForm(props: CategoryDetailsProps) {
  const classes = useStyles()
  const { category, initialValues = loadInitialValues(), onBack, onSubmit } = props
  const categoryDetails = associationSpecificDetails(category, props.licenseCategoryContext.association)
  const fields = samFields().categoryDetails

  if (!initialValues.teamName) initialValues.teamName = ''
  if (!initialValues.bikeMake) initialValues.bikeMake = ''

  const showInsuranceOptions = requiresInsuranceOptionByCategory(
    categoryDetails.association,
    category.year,
    categoryDetails
  )

  if (showInsuranceOptions && !initialValues.insuranceOption) {
    initialValues.insuranceOption = standardInsuranceOption().id
  }

  return (
    <>
      <Formik
        key={category.id}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema(category, categoryDetails)}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit({ ...values, categoryId: props.category.id })
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={1} className={classes.gridParent}>
              {category.numberChoice && (
                <Grid item xs={12}>
                  {fields.preferredNumber.field()}
                </Grid>
              )}

              {category.sidecar && (
                <Grid item xs={12}>
                  {fields.sidecarPartner.field()}
                  <FormHelperText>{fields.sidecarPartner.hint}</FormHelperText>
                </Grid>
              )}

              {categoryDetails.needsComment && (
                <Grid item xs={12}>
                  {fields.comment.field()}
                  <FormHelperText>{categoryDetails.needsComment}</FormHelperText>
                </Grid>
              )}

              {categoryDetails.needsBikeInfo && (
                <>
                  <Grid item md={6} xs={12}>
                    {fields.teamName.field()}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {fields.bikeMake.field()}
                  </Grid>
                </>
              )}

              {categoryDetails.offersNationalLicense && categoryDetails.offersInternationalLicense && (
                <Grid item xs={12}>
                  {fields.licenseType.field(selectableLicenseTypesOfCategory(categoryDetails))}
                  <PricesByAgeHelperTexts categoryDetails={categoryDetails} />
                </Grid>
              )}

              {showInsuranceOptions && (
                <Grid item xs={12}>
                  {fields.insuranceOption.field()}
                </Grid>
              )}

              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <AccordionNavigationButtons
                isSubmitting={isSubmitting}
                onBack={onBack}
                submitForm={submitForm}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

function loadInitialValues(): CategoryDetails {
  return Object.fromEntries(
    Object.entries(samFields().categoryDetails).map(([k, v]) => [k, v.default])
  ) as unknown as CategoryDetails
}

function schema(category: Category, associationCategory: AssociationCategory) {
  return Yup.object()
    .defined()
    .shape({
      ...(category.sidecar
        ? { sidecarPartner: samFields().categoryDetails.sidecarPartner.validation }
        : undefined),
      ...(category.numberChoice
        ? { preferredNumber: samFields().categoryDetails.preferredNumber.validation }
        : undefined),
      ...(associationCategory.needsBikeInfo
        ? { teamName: samFields().categoryDetails.teamName.validation }
        : undefined),
      ...(associationCategory.needsBikeInfo
        ? { bikeMake: samFields().categoryDetails.bikeMake.validation }
        : undefined),
      ...(associationCategory.needsComment
        ? { comment: samFields().categoryDetails.comment.validation }
        : undefined),
    })
}
