import assertNever from 'assert-never'
import { db } from 'app/db/frontend-db'
import { storeNewBill } from 'shared/billing/billing-service'
import { pushLicenseBookings } from 'shared/billing/bookings-service'
import { reverseBooking } from 'shared/category-pricing'
import { AdminRider, LicenseBooking, LicenseLineItem, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { BookingContext, equalItem } from 'shared/licenses-bookings'
import { AssociationID } from 'shared/models/associations'
import { removeFirst } from 'shared/utils/array'

export function handleBookings(context: BookingContext, year: number): Promise<void> {
  if (context.state === 'same-items') return Promise.resolve()
  if (context.state === 'same-price') return makeBookingsMatch(context, year)
  if (context.state === 'no-booked-items') return addBookingsAndBill(context, year)
  if (context.state === 'conflicting-items') return redoBookingsAndBill(context, year)
  assertNever(context.state)
}

async function redoBookingsAndBill(context: BookingContext, year: number) {
  await makeBookingsMatch(context, year)
  await storeNewBill(db, context, undefined)
}

async function makeBookingsMatch(context: BookingContext, year: number) {
  const newItems = await unbookBookings(context, year)
  await addBookings({ ...context, bookings: newItems }, year)
}

async function unbookBookings(
  { admin, rider, bookedItems, potentialItems }: BookingContext,
  year: number
) {
  const index = findLastZeroBalanceIndex(bookedItems)
  const bookedItemsToUndo = bookedItems.slice(index).filter(({ price }) => price !== 0)
  const newItems = potentialItems.filter(({ price }) => price !== 0)

  if (bookedItemsToUndo.some((item) => item.name.startsWith('Storniert: '))) {
    const errorText = t().licenses.cancelledBookingsError
    alert(errorText)
    throw new Error(errorText)
  }

  removeMatchingItems(bookedItemsToUndo, newItems)

  await pushLicenseBookings(
    db,
    bookedItemsToUndo.reverse().map((item) => reversedLicenseItemBooking(admin, rider, item, year))
  )
  return newItems
}

export async function reverseLicenseItemBooking(
  {
    admin,
    rider,
    item,
  }: {
    admin: UserQuery
    rider: UserQuery
    item: LicenseLineItem
  },
  year: number
) {
  await pushLicenseBookings(db, [reversedLicenseItemBooking(admin, rider, item, year)])
}

function reversedLicenseItemBooking(
  admin: UserQuery,
  rider: UserQuery,
  item: LicenseLineItem,
  year: number
): LicenseBooking {
  return {
    type: 'reverseLicenseBooking',
    id: '',
    year,
    byUid: admin.uid,
    uid: rider.uid,
    date: new Date().toISOString(),
    item: reverseBooking(item),
    remainingBalance: item.price,
  }
}

function findLastZeroBalanceIndex(items: LicenseLineItem[]) {
  let balance = 0
  let lastZeroBalanceIndex = 0
  items.forEach((item, index) => {
    balance += item.price
    if (balance === 0) lastZeroBalanceIndex = index + 1
  })
  return lastZeroBalanceIndex
}

function removeMatchingItems(bookedItemsToUndo: LicenseLineItem[], newItems: LicenseLineItem[]) {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const found = newItems.find((outer) => bookedItemsToUndo.find((inner) => equalItem(outer, inner)))
    if (!found) return
    removeFirst(newItems, (item) => equalItem(found, item))
    removeFirst(bookedItemsToUndo, (item) => equalItem(found, item))
  }
}

async function addBookingsAndBill(context: BookingContext, year: number) {
  await addBookings({ ...context, bookings: context.potentialItems }, year)
  await storeNewBill(db, context, undefined)
}

async function addBookings(
  { admin, rider, bookings }: { bookings: LicenseLineItem[] } & AdminRider,
  year: number
) {
  await pushLicenseBookings(
    db,
    [...bookings].reverse().map((item) => newLicenseBooking(admin, rider, item, year))
  )
}

export async function addBookingAndBill(
  props: { item: LicenseLineItem; association: AssociationID | undefined } & AdminRider,
  year: number
) {
  await addBooking(props, year)
  await storeNewBill(db, props, props.association)
}

export async function addBooking(
  { admin, rider, item }: { item: LicenseLineItem } & AdminRider,
  year: number
) {
  await pushLicenseBookings(db, [newLicenseBooking(admin, rider, item, year)])
}

function newLicenseBooking(
  admin: UserQuery,
  rider: UserQuery,
  item: LicenseLineItem,
  year: number
): LicenseBooking {
  return {
    type: 'licenseBooking',
    id: '',
    year,
    byUid: admin.uid,
    uid: rider.uid,
    date: new Date().toISOString(),
    item,
    remainingBalance: item.price,
  }
}
