import { StrikethroughS } from '@material-ui/icons'
import { db } from 'app/db/frontend-db'
import { IconButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { useAdmin } from 'app/themes/user-context'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { deleteInscription } from 'shared/inscription/inscription-service'
import { fullName, PersonalData } from 'shared/models/personal-data'
import { SportEvent, Inscription } from 'shared/sport-events/sport-events'
import { sportEventDescription } from 'shared/sport-events/sport-events-service'

export function DeleteInscriptionButton(data: {
  personalData: PersonalData | undefined
  sportEvent: SportEvent
  inscription: Inscription
}) {
  const { personalData, sportEvent, inscription } = data
  const admin = useAdmin()

  return (
    <IconButtonWithConfirmation
      title={t().inscription.deleteInscriptionTitle(fullName(personalData))}
      info={t().inscription.deleteInscriptionInfo(
        fullName(personalData),
        sportEventDescription(sportEvent)
      )}
      confirmation={t().inscription.deleteInscriptionConfirmation}
      tooltip={t().inscription.deleteInscriptionTooltip(sportEventDescription(sportEvent))}
      onConfirm={async () => {
        await deleteInscription(db, inscription, admin)
        const url = routes.assignLicense.generateTo(inscription.uid)
        window.open(url, '_blank')?.focus()
      }}
    >
      <StrikethroughS />
    </IconButtonWithConfirmation>
  )
}
