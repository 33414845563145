import { ReactNode } from 'react'

export function PreWithScroll({ values }: { values: unknown }) {
  return (
    <pre style={{ whiteSpace: 'pre-wrap', maxHeight: 400, overflow: 'auto' }}>{prettyJSON(values)}</pre>
  )
}

export function Debug({ values }: { values: unknown }) {
  return <DebugString>{prettyJSON(values)}</DebugString>
}

export function DebugString({ children }: { children: ReactNode }) {
  return <pre style={{ whiteSpace: 'pre-wrap' }}>{children}</pre>
}

export function isLocalDevelopment() {
  return window.location.hostname.endsWith('localhost')
}

export function prettyJSON(values: unknown) {
  return JSON.stringify(values, null, 2)
}
