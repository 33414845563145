import assertNever from 'assert-never'
import { sortBy } from 'lodash'
import {
  categoryByIdFromString,
  categoryOfAssociationRequired,
  isCategoryId,
} from 'shared/data/categories-service'
import { nextLicenseYear } from 'shared/data/license-config'
import { DayCategory } from 'shared/db/day-category'
import { DB, InscriptionBooking } from 'shared/db/db'
import { SportEventId } from 'shared/db/sport-event-id'
import { t } from 'shared/i18n/current'
import { AssociationCategory } from 'shared/models/category'
import {
  EnlistedInscription,
  Inscription,
  isEnlistedInscription,
  isUnlistedInscription,
  SportEvent,
} from 'shared/sport-events/sport-events'
import { sportEventDescription } from 'shared/sport-events/sport-events-service'
import { groupByString, isAtLeastLength, isLength, truthy } from 'shared/utils/array'
import { isSameOrBeforeISO } from 'shared/utils/date'

export async function simulateAddMissingTwoDayDiscounts(db: DB) {
  const added: InscriptionBooking[] = []
  const deleted: InscriptionBooking[] = []
  await addMissingTwoDayDiscountsInner(
    db,
    (inscription) => {
      added.push(inscription)
      return Promise.resolve(inscription)
    },
    (inscription) => {
      deleted.push(inscription)
      return Promise.resolve()
    }
  )
  console.table(sortBy(added, (x) => x.uid))
  console.table(sortBy(deleted, (x) => x.uid))
}

export async function addMissingTwoDayDiscounts(db: DB) {
  await addMissingTwoDayDiscountsInner(
    db,
    (inscription) => db.pushInscriptionBooking(inscription),
    (inscription) => db.deleteInscriptionBooking(inscription)
  )
}

async function addMissingTwoDayDiscountsInner(
  db: DB,
  pushInscriptionBooking: DB['pushInscriptionBooking'],
  deleteInscriptionBooking: DB['deleteInscriptionBooking']
) {
  const allInscriptions = (await db.loadAllInscriptions()).filter(
    (inscription) => inscription.year === nextLicenseYear
  )
  const inscriptionsByUser = groupByString(allInscriptions, (inscription) => inscription.uid)
  const sportEventsList = await db.loadAllSportEvents()
  const sportEvents = Object.fromEntries(
    sportEventsList.map((sportEvent) => [sportEvent.id, sportEvent])
  )
  await Promise.all(
    Object.values(inscriptionsByUser).map((inscriptions) =>
      addMissingTwoDayDiscountsByUserInscriptions(
        db,
        sportEvents,
        inscriptions,
        pushInscriptionBooking,
        deleteInscriptionBooking
      )
    )
  )
}

async function addMissingTwoDayDiscountsByUserInscriptions(
  db: DB,
  sportEvents: Record<SportEventId, SportEvent>,
  inscriptions: Inscription[],
  pushInscriptionBooking: DB['pushInscriptionBooking'],
  deleteInscriptionBooking: DB['deleteInscriptionBooking']
) {
  for await (const inscription of inscriptions) {
    const sportEvent = sportEvents[inscription.sportEvent]
    await handleTwoDayDiscountInner({
      db,
      inscription,
      sportEvent,
      pushInscriptionBooking,
      deleteInscriptionBooking,
    })
  }
}

export async function handleTwoDayDiscount(
  props: Omit<InscriptionDiscountProps, 'pushInscriptionBooking' | 'deleteInscriptionBooking'>
) {
  await handleTwoDayDiscountInner({
    ...props,
    pushInscriptionBooking: (inscription) => props.db.pushInscriptionBooking(inscription),
    deleteInscriptionBooking: (inscription) => props.db.deleteInscriptionBooking(inscription),
  })
}

async function handleTwoDayDiscountInner(props: InscriptionDiscountProps) {
  const { inscription } = props

  if (isUnlistedInscription(inscription)) await removeTwoDayDiscountIfRequired({ ...props, inscription })
  else if (isEnlistedInscription(inscription)) {
    await removeTwoDayDiscountIfRequired({ ...props, inscription })
    await addTwoDayDiscountIfEligible({ ...props, inscription })
  } else assertNever(inscription)
}

async function removeTwoDayDiscountIfRequired(props: InscriptionDiscountProps) {
  if ((await hasTwoDayDiscount(props)) && !(await eligibleForTwoDayDiscount(props)))
    await removeTwoDayDiscount(props)
}

async function addTwoDayDiscountIfEligible(props: EnlistedInscriptionDiscountProps) {
  if (!(await hasTwoDayDiscount(props)) && (await eligibleForTwoDayDiscount(props)))
    await addTwoDayDiscount(props)
}

async function hasTwoDayDiscount(props: { db: DB; inscription: Inscription; sportEvent: SportEvent }) {
  const { inscription, db, sportEvent } = props

  const uid = inscription.uid

  return (await db.loadInscriptionBookingsAndSportEvent({ uid }, sportEvent)).some(
    (booking) =>
      booking.item.type === 'inscriptionDiscountLineItem' &&
      booking.item.categoryId === inscription.category
  )
}

async function eligibleForTwoDayDiscount(props: InscriptionDiscountProps) {
  const { inscription } = props

  if (isSameOrBeforeISO(inscription.date, '2022-08-25T00:00:00.000Z')) return false

  const yearCategory = categoryByIdFromString(inscription.category)
  if (!yearCategory) return false

  const categoryBookings = await relevantCategoryBookings(props)

  if (!isAtLeastLength(categoryBookings, 2)) return false

  const category = findCategoryOfAssociation(props)

  return (category?.discountInscriptionWithLicenseTwoDays || 0) > 0
}

function findCategoryOfAssociation(props: InscriptionDiscountProps) {
  const { inscription } = props

  const yearCategory = categoryByIdFromString(inscription.category)
  if (!yearCategory) return undefined

  const category = categoryOfAssociationRequired(
    yearCategory.id,
    yearCategory.associationSpecificDetails.length === 1
      ? yearCategory.associationSpecificDetails[0].association
      : (inscription as EnlistedInscription).association
  )

  return category
}

async function removeTwoDayDiscount(props: InscriptionDiscountProps) {
  const { inscription, db, sportEvent, deleteInscriptionBooking } = props
  const { uid } = inscription
  const discountBookings = (await db.loadInscriptionBookingsAndSportEvent({ uid }, sportEvent))
    .map((booking) =>
      booking.item.type === 'inscriptionDiscountLineItem' &&
      booking.item.categoryId === inscription.category
        ? { ...booking, item: booking.item }
        : undefined
    )
    .filter(truthy)

  if (isLength(discountBookings, 0)) return

  if (!isLength(discountBookings, 1))
    throw new Error(`Too many discounted bookings ${JSON.stringify(discountBookings)}`)

  const [booking] = discountBookings

  await deleteInscriptionBooking(booking)
}

async function addTwoDayDiscount(props: EnlistedInscriptionDiscountProps) {
  const { inscription, pushInscriptionBooking, sportEvent } = props
  const { uid, byUid } = inscription

  if (!isCategoryId(inscription.category)) return

  const bookings = await relevantCategoryBookings(props)
  const association = bookings[0].item.association

  if (!association) return

  const category = findCategoryOfAssociation(props)

  if (!category || !category.discountInscriptionWithLicenseTwoDays) return
  const price = -category.discountInscriptionWithLicenseTwoDays

  const booking: InscriptionBooking = {
    type: 'inscriptionBooking',
    id: '',
    byUid,
    uid,
    date: new Date().toISOString(),
    remainingBalance: price,
    item: {
      association,
      categoryId: category.id,
      categoryName: category.commonName,
      name: inscriptionDescriptionTwoDayDiscount(sportEvent, category),
      price,
      sportEventDate: '',
      sportEventId: sportEvent.id,
      type: 'inscriptionDiscountLineItem',
    },
  }
  await pushInscriptionBooking(booking)
}

async function relevantCategoryBookings(props: InscriptionDiscountProps) {
  const { db, inscription, sportEvent } = props
  const { uid } = inscription
  return (await db.loadInscriptionBookingsAndSportEvent({ uid }, sportEvent))
    .filter((booking) => booking.item.categoryId === inscription.category)
    .map((booking) =>
      booking.item.type === 'inscriptionLineItem' ? { ...booking, item: booking.item } : undefined
    )
    .filter(truthy)
}

function inscriptionDescriptionTwoDayDiscount(
  sportEvent: SportEvent,
  category: AssociationCategory | DayCategory
) {
  return `${inscriptionDescription(sportEvent, category)}, ${t().twoDayDiscount}`
}

export function inscriptionDescription(
  sportEvent: SportEvent,
  category: AssociationCategory | DayCategory
) {
  return `${sportEventDescription(sportEvent)}, ${category.name}`
}

interface InscriptionDiscountProps {
  db: DB
  inscription: Inscription
  sportEvent: SportEvent
  pushInscriptionBooking: DB['pushInscriptionBooking']
  deleteInscriptionBooking: DB['deleteInscriptionBooking']
}

interface EnlistedInscriptionDiscountProps {
  db: DB
  inscription: EnlistedInscription
  sportEvent: SportEvent
  pushInscriptionBooking: DB['pushInscriptionBooking']
  deleteInscriptionBooking: DB['deleteInscriptionBooking']
}
