import { Recipient } from 'shared/api/emails'

export type SendCategoryEmailsQuery =
  | EmailPropsFirstRace
  | EmailPropsMissingLicenseInfo
  | EmailPropsNextRace
  | EmailPropsPostponedNextRace

export interface EmailPropsFirstRace extends SendCategoryEmailsQueryBase {
  type: 'first-race'
  date: string
  location: string
}

export interface EmailPropsMissingLicenseInfo extends SendCategoryEmailsQueryBase {
  type: 'missing-license-info'
}

export interface EmailPropsNextRace extends SendCategoryEmailsQueryBase {
  type: 'next-race'
  sportEventId: string
}

export interface EmailPropsPostponedNextRace extends SendCategoryEmailsQueryBase {
  type: 'postponed-next-race'
  sportEventId: string
}

export interface SendCategoryEmailsQueryBase {
  simulate: boolean
  recipient: Recipient
}

export const messageTypes = [
  { id: 'first-race', name: 'Erstes Rennen' },
  { id: 'missing-license-info', name: 'Fehlende Lizenzinformation' },
  { id: 'next-race', name: 'Nächstes Rennen' },
  { id: 'postponed-next-race', name: 'Nächstes Rennen (verschoben)' },
] as const
