import { TableBox } from 'app/layouts/table-box'
import { groupByString } from 'shared/utils/array'
import { RawCellData, TableHeader } from 'shared/utils/table-data'

interface HierarchyTableBoxProps<T, V extends RawCellData> {
  title: string
  loading: boolean
  error: Error | undefined
  data: {
    hierarchy: Hierarchy<T, V>
    headers: TableHeader[]
  }
}

export function HierarchyTableBox<T, V extends RawCellData>({
  title,
  loading,
  error,
  data,
}: HierarchyTableBoxProps<T, V>) {
  return (
    <TableBox
      title={title}
      loading={loading}
      error={error}
      data={
        !loading &&
        !error && {
          headers: data.headers,
          contents: tableContents(data.hierarchy),
          rawData: undefined,
        }
      }
    />
  )
}

function tableContents<T, V extends RawCellData>(data: Hierarchy<T, V>): RawCellData[][] {
  return [
    ['\u00A0'.repeat(data.depth * 8) + data.name, data.action || '', ...data.value],
    ...data.children.map((hierarchy) => tableContents(hierarchy)).flat(),
  ]
}

export function createHierarchy<T, V extends RawCellData>(
  key: string,
  name: string,
  data: T[],
  hierarchies: { group: (t: T) => string; name: (t: T) => string; action?: (t: T) => JSX.Element }[],
  combine: (t: T[], depth: number) => V[],
  depth: number,
  action: JSX.Element | undefined
): Hierarchy<T, V> {
  const [hierarchy, ...rest] = [...hierarchies]

  const children = hierarchy
    ? Object.entries(groupByString(data, hierarchy.group)).map(([childKey, childData]) =>
        createHierarchy(
          `${key}|${childKey}`,
          `${hierarchy.name(childData[0])}`,
          childData,
          rest,
          combine,
          depth + 1,
          hierarchy.action?.(childData[0])
        )
      )
    : []
  return { key, depth, name, data, value: combine(data, depth), children, action }
}

interface Hierarchy<T, V extends RawCellData> {
  name: string
  key: string
  depth: number
  value: V[]
  data: T[]
  children: Hierarchy<T, V>[]
  action: JSX.Element | undefined
}
