const BarcodeDetector = loadBarcodeDetector()

function loadBarcodeDetector(): BarcodeDetectorType | undefined {
  return (window as any).BarcodeDetector
}

export async function canScanQRCodeNatively() {
  return BarcodeDetector && (await BarcodeDetector.getSupportedFormats()).includes('qr_code')
}

export async function scanQRCodeFromImageNatively(image: ImageBitmap) {
  if (!BarcodeDetector) throw new Error('BarcodeDetector not available in your browser/OS at the moment')

  const barcodeDetector = new BarcodeDetector({ formats: ['qr_code'] })
  try {
    const barcodes = await barcodeDetector.detect(image)
    if (barcodes?.length === 1) return barcodes[0].rawValue
  } catch {
    return undefined
  }
}

interface BarcodeDetectorType {
  new (options: { formats: 'qr_code'[] }): BarcodeDetectorInstance
  getSupportedFormats(): Promise<'qr_code'[]>
}

interface BarcodeDetectorInstance {
  detect(image: ImageBitmapSource): Promise<{ rawValue: string }[] | undefined>
}
