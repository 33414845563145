import { Box, LinearProgress, Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useEmergencyData } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { EmergencyForm } from 'app/emergency/emergency'
import { MayBeOptionalMessage } from 'app/pages/dashboard/may-be-optional-message'
import { nextLicenseYear } from 'shared/data/license-config'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { FriendlyError } from 'utils/errors'

export function EditEmergency({ user, adminView }: { user: UserQuery; adminView?: boolean }) {
  const history = useHistory()
  const { data, loading, error } = useEmergencyData(user, nextLicenseYear)
  const [onSubmitError, setError] = useState<Error | undefined>()
  const { emergency, needed } = data

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().emergencyDetails.title}
        </Typography>
      </Box>

      <MayBeOptionalMessage needed={needed} />

      <Paper elevation={3}>
        <Box p={2}>
          <FriendlyError error={error} />
          <FriendlyError error={onSubmitError} />
          {loading && <LinearProgress />}
          {!loading && (
            <>
              <EmergencyForm
                adminView
                onSubmit={async (data) => {
                  try {
                    setError(undefined)
                    await db.setEmergency(user, { ...data, lastChecked: new Date().toISOString() })
                    if (!adminView) history.goBack()
                  } catch (error: any) {
                    setError(error)
                  }
                }}
                onBack={() => history.goBack()}
                onCancel={() => history.goBack()}
                initialValues={emergency || undefined}
              />
            </>
          )}
        </Box>
      </Paper>
    </>
  )
}
