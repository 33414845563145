import { Alert, AlertTitle } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { base64Decode } from 'shared/utils/base64'
import { useQuery } from 'utils/router'

export function AlertBox() {
  return (
    <>
      <LicenseRequestReceivedAlert />
      <EmailChangedAlert />
    </>
  )
}

function EmailChangedAlert() {
  const history = useHistory()
  const { query } = useQuery()

  return query.has('email-changed-to') ? (
    <Alert
      severity="success"
      variant="outlined"
      onClose={() => history.replace(routes.userDashboard.to)}
    >
      <AlertTitle>{t().personalDataBox.changeEmail.emailChangedTitle}</AlertTitle>
      {t().personalDataBox.changeEmail.emailChangedBefore}{' '}
      <strong>{base64Decode(query.get('email-changed-to') || '')}</strong>{' '}
      {t().personalDataBox.changeEmail.emailChangedAfter}
    </Alert>
  ) : null
}

function LicenseRequestReceivedAlert() {
  const history = useHistory()
  const { query } = useQuery()

  return query.has('license-request-received') ? (
    <Alert
      severity="success"
      variant="outlined"
      onClose={() => history.replace(routes.userDashboard.to)}
    >
      <AlertTitle>{t().licenseRequest.licenseRequestSent}</AlertTitle>
      {t().licenseRequest.licenseRequestSentHint}
    </Alert>
  ) : null
}
