import { Box, Button, Chip, Collapse, Divider, Typography } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { Dictionary, groupBy } from 'lodash'
import { ButtonWithTooltip } from 'app/layout/button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import {
  downloadInscriptinsForMylapsCsv,
  downloadInscriptinsForMylapsXlsx,
} from 'app/mylaps/mylaps-download'
import { InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { ExportOptions } from 'shared/models/transponder-type'
import { unknownDate } from 'shared/models/unknown-date'
import { sportEventExportOptions } from 'shared/my-laps/mylaps-export'
import { SportEvent } from 'shared/sport-events/sport-events'
import { pDateWeekdayName } from 'shared/utils/date'
import { useBoolean } from 'utils/use-boolean'

interface DownloadInscriptionsForChronoButtonProps {
  sportEvent: SportEvent
  inscriptions: InscriptionWithContextAndSportEvent[]
}

export function DownloadInscriptionsForChronoButton(props: DownloadInscriptionsForChronoButtonProps) {
  const { inscriptions, sportEvent } = props
  const dialog = useDialog()
  const inscriptionsByDate = groupBy(inscriptions, (inscription) => inscription.inscription.date)
  const exportOptions = sportEventExportOptions(sportEvent)
  const showExpanded = useBoolean(false)

  return (
    <>
      <ButtonWithTooltip
        tooltip={t().exportForMyLaps}
        startIcon={<CloudDownload />}
        onClick={dialog.open}
      >
        {t().myLaps}
      </ButtonWithTooltip>

      <ConfirmDialog title={t().exportMyLaps} dialog={dialog}>
        <Typography>{t().exportMyLaps}</Typography>
        <DownloadButtons
          inscriptions={inscriptions}
          inscriptionsByDate={inscriptionsByDate}
          exportOptions={exportOptions}
        />

        <Box mt={2}>
          <Button fullWidth onClick={() => showExpanded.toggle()}>
            {t().extended}
          </Button>
          <Collapse in={showExpanded.value}>
            <Box mt={2}>
              <DownloadButtons
                inscriptions={inscriptions}
                inscriptionsByDate={inscriptionsByDate}
                exportOptions={{ transponderType: 'MyLaps', format: 'normal' }}
              />
              <Divider />
              <DownloadButtons
                inscriptions={inscriptions}
                inscriptionsByDate={inscriptionsByDate}
                exportOptions={{ transponderType: 'MyLaps', format: 'special' }}
              />
              <Divider />
              <DownloadButtons
                inscriptions={inscriptions}
                inscriptionsByDate={inscriptionsByDate}
                exportOptions={{ transponderType: 'RF', format: 'normal' }}
              />
              <Divider />
              <DownloadButtons
                inscriptions={inscriptions}
                inscriptionsByDate={inscriptionsByDate}
                exportOptions={{ transponderType: 'RF', format: 'special' }}
              />
            </Box>
          </Collapse>
        </Box>
      </ConfirmDialog>
    </>
  )
}

interface DownloadButtonsProps {
  inscriptions: InscriptionWithContextAndSportEvent[]
  inscriptionsByDate: Dictionary<InscriptionWithContextAndSportEvent[]>
  exportOptions: ExportOptions
}

function DownloadButtons(props: DownloadButtonsProps) {
  const { inscriptions, inscriptionsByDate, exportOptions } = props
  return (
    <>
      <ExportFormatDescription exportOptions={exportOptions} />
      <Box mb={1}>
        <Typography>CSV (MyLaps / Orbits)</Typography>
        {Object.entries(inscriptionsByDate).map(([date, innerInscriptions]) => (
          <Button
            key={date}
            startIcon={<CloudDownload />}
            onClick={() => downloadInscriptinsForMylapsCsv(innerInscriptions, exportOptions)}
          >
            {date === unknownDate ? t().assignNoRaceDay : pDateWeekdayName(date) || date}
          </Button>
        ))}
        <ButtonWithTooltip
          tooltip={t().exportAlertTooltip}
          startIcon={<CloudDownload />}
          onClick={() => downloadInscriptinsForMylapsCsv(inscriptions, exportOptions)}
        >
          {t().all}
        </ButtonWithTooltip>
      </Box>

      <Box mt={2} mb={1}>
        <Typography>XLSX (Chronolec / RF, oder Excel)</Typography>
        {Object.entries(inscriptionsByDate).map(([date, inscriptions]) => (
          <Button
            key={date}
            startIcon={<CloudDownload />}
            onClick={() => downloadInscriptinsForMylapsXlsx(inscriptions, exportOptions)}
          >
            {date === unknownDate ? t().assignNoRaceDay : pDateWeekdayName(date) || date}
          </Button>
        ))}
        <ButtonWithTooltip
          tooltip={t().exportAlertTooltip}
          startIcon={<CloudDownload />}
          onClick={() => downloadInscriptinsForMylapsXlsx(inscriptions, exportOptions)}
        >
          {t().all}
        </ButtonWithTooltip>
      </Box>
    </>
  )
}

function ExportFormatDescription({ exportOptions }: { exportOptions: ExportOptions }) {
  return (
    <Box>
      <Box py={2}>
        <Chip
          size="small"
          label={
            exportOptions.transponderType === 'RF' ? 'Transponder: Chronolec' : 'Transponder: MyLaps'
          }
        />
        <Chip
          size="small"
          label={exportOptions.format === 'special' ? t().formatOptionSpecial : t().formatOptionNormal}
        />
      </Box>
    </Box>
  )
}
