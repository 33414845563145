import { de } from 'shared/i18n/de'
import { ArrayElement } from 'shared/utils/tsc'

export type I18nType = typeof de

export const changeLocaleText: Record<I18nLocales, LocaleDescription> = {
  de: {
    changeToLocale: 'Sprache nach Deutsch wechseln',
    buttonText: 'Deutsch',
    shortText: 'DE',
    locale: 'de',
  },
  en: {
    changeToLocale: 'Change language to English',
    buttonText: 'English',
    shortText: 'EN',
    locale: 'en',
  },
  fr: {
    changeToLocale: 'Changer la langue en Français',
    buttonText: 'Français',
    shortText: 'FR',
    locale: 'fr',
  },
}

export function isLocale(locale: string | null): locale is I18nLocales {
  return availableLocales.includes(locale as I18nLocales)
}

export const availableLocales = ['de', 'en', 'fr'] as const

export interface LocaleDescription {
  changeToLocale: string
  buttonText: string
  shortText: string
  locale: I18nLocales
}

export type I18nLocales = ArrayElement<typeof availableLocales>
