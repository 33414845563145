import assertNever from 'assert-never'
import { generateLicense2021 } from 'app/pages/licenses/pdf-license-service-2021'
import { generateLicense2022, useLicense2022Preview } from 'app/pages/licenses/pdf-license-service-2022'
import { LicenseExportType } from 'app/pages/licenses/pdf-license-service-common'
import { generatePitLane2022 } from 'app/pages/licenses/pdf-pit-lane-service-2022'
import { categoryByIdRequired } from 'shared/data/categories-service'
import { ApprovedLicense } from 'shared/db/db'
import { Disp } from 'utils/react'

export function useLicensePreview(setError: Disp<Error | null>, props: DownloadLicenseProps) {
  return useLicense2022Preview(setError, props)
}

export interface DownloadLicenseProps {
  license: ApprovedLicense
  type: LicenseExportType
}

export async function generateLicense(license: ApprovedLicense, type: LicenseExportType) {
  const category = categoryByIdRequired(license.categoryId)
  if (category.year === 2021) return await generateLicense2021(license)
  if (category.year === 2022 || category.year === 2023 || category.year === 2024) {
    if (type === 'license') return await generateLicense2022(license)
    if (type === 'pitLanePass') return await generatePitLane2022(license)
    assertNever(type)
  }
  assertNever(category.year)
}
