// Generated - edit here: https://docs.google.com/spreadsheets/d/13FODLia-TwZoE1XRSr7YiPgT4wt1SEIczgjKPcDNtA4
import { I18nType } from 'shared/i18n/i18n-types'

/* eslint-disable no-useless-escape */

export const fr: I18nType = {
  language: 'fr',
  finalized: 'Finalisé',
  finalizedHint:
    "Pour les manifestations terminées, il n'est plus possible de s'inscrire ou d'annuler l'inscription (décompte effectué)",
  errorCodes: {
    approvedLicenseNotFound: 'Licence approuvée non trouvée',
    cannotDeleteLicenseWithExistingInscriptions:
      "Les licences avec des inscriptions existantes ne peuvent pas être supprimées. Veuillez d'abord supprimer les inscriptions individuelles",
  },
  successful: 'Succès',
  anUnknownErrorOccurred: 'Une erreur inconnue est survenue',
  lastChange: 'Dernière modification',
  inscriptionsDisabled: {
    title: 'Les inscriptions / désinscriptions pour cette catégorie sont désactivées',
    hintEnlist: "Le champ est plein - peut-être qu'une autre catégorie a encore de la place",
    hintUnlist:
      "Le champ est complet et de nouvelles inscriptions n'étaient plus possibles. Pour cette raison, les désinscriptions sont également désactivées",
    short: 'Inscriptions désactivées',
  },
  showDeleted: 'Afficher les éléments supprimés',
  combine: 'Combiner',
  showOnlyDuplicates: 'Afficher uniquement les doublons',
  newVersion: {
    available: 'Une nouvelle version est disponible',
    reload: 'Actualise',
  },
  dashboard: {
    title: 'Tableau de bord',
    adminTitle: 'Tableau de bord administrateur',
    licenses: 'Licences',
    users: 'Utilisateurs',
    other: 'Autre',
  },
  extended: 'Étendu',
  adminArea: "Zone d'administration",
  toAdminArea: "A la zone d'administration",
  done: 'fini',
  email: 'E-Mail',
  emailFirebase: 'E-Mail Firebase',
  emailRacemanager: 'E-Mail Racemanager',
  active: 'Actif',
  name: 'Nom',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  hintForName:
    'Le nom ne peut pas être changé après avoir soumis la demande de licence. Si vous souhaitez soumettre une demande de licence pour une autre personne, vous devez vous inscrire avec une autre adresse électronique.',
  street: 'Rue',
  zip: 'Code postal',
  place: 'Lieu',
  startsAt: 'Démarrer',
  endsAt: 'Fin',
  alternativeStartsAt: 'Commencer (alt.)',
  alternativeEndsAt: 'Fin (alt.)',
  licensesAndDrafts: 'Licences / demandes de licence',
  categoryIds: 'Catégories',
  categoryDate: "Date de l'inscription",
  categoryDateUnknown: 'Selon le programme du jour',
  offersPower: 'Électricité à disposition',
  cancelled: 'Annulé',
  lastFiveScansOnThisDevice: 'Derniers scannages le',
  suggestedDonationAmount: "Montant du don suggéré lors de l'annulation",
  unlist: {
    buttonText: 'Désinscrire / Je ne prend pas le départ',
    dialogTitle: 'Vraiment désinscrire',
    inProgress: 'Désinscription en cours...',
    dialogConfirmation: 'Se désinscrire de la course et renoncer à sa place de départ',
    dialogConfirmWithoutDonation: 'Vraiment désinscrire',
    donationAmount: 'Montant du don',
    dialogConfirmDonation: (amount: string) => `Vraiment désinscrire et faire un don de CHF ${amount}`,
    donation: (amount: string) =>
      `Les frais d'inscription et la licence journalière te seront reversés sur le compte de Racemanager. Nous serions toutefois heureux de recevoir un don de ${amount} CHF pour couvrir une partie des frais de l'organisateur. Le don sera automatiquement déduit de ton compte Racemanager. Tu peux ajuster le montant volontaire à la hausse ou à la baisse à ta convenance. Si tu ne veux rien donner, tu peux mettre le montant à 0. Un grand merci pour ta solidarité!`,
  },
  country: 'Pays',
  birthdate: 'Date de naissance',
  birthdateDe: 'Date de naissance (DE)',
  birthdateTooYoung:
    "Vous êtes trop jeune pour soumettre une licence pour une demande de licence  (ou la date de naissance n'est pas juste)",
  phone: 'Téléphone',
  comment: 'Commenter',
  licenseType: 'Type de licence',
  licenseTypes: {
    national: 'National',
    international: 'International',
  },
  licenseTypesLong: {
    national: 'Licence nationale',
    international: 'Licence internationale',
  },
  newSamMember: {
    label: 'Je ne suis pas encore membre SAM',
    labelAlternative: 'Nouveau membre SAM?',
    validation: 'Adhésion à SAM',
    hintForNewMembers: 'Cette requête est considérée comme une demande de membre SAM',
    hintForFine:
      "Si vous êtes déjà membre et que le numéro d'adhésion n'est pas entré, une taxe de CHF 20 sera facturée. Le numéro de membre SAM de 5 à 6 chiffres (pas le numéro de licence) peut être trouvé sur votre identifiant SAM.",
  },
  samMemberNumber: {
    label: 'Numéro de membres SAM',
    helperText:
      'Le numéro de membre à 5 ou 6 chiffres (non numéro de licence) peut être trouvé sur votre identifiant SAM.',
    requiredHelperText:
      'Le numéro de membre doit être spécifié. Vous trouverez le numéro de membre à 5 ou 6 chiffres (pas le numéro de licence) sur votre identifiant SAM.',
    hintForSubmittedLicense:
      'Le numéro de membre ne peut pas être changé après avoir soumis la demande de licence. Si vous souhaitez déposer une demande de licence pour une autre personne, vous devez vous inscrire avec une autre adresse électronique.',
  },
  password: 'Mot de passe',
  setPassword: 'Définir un nouveau mot de passe',
  confirmSetPassword: 'Définir le mot de passe',
  licenseNumber: 'Numéro de licence',
  afmSection: {
    label: 'Club AFM',
    labelOptional: 'Club AFM (facultatif)',
  },
  fmsSection: {
    label: 'Section Swiss Moto',
    labelOptional: 'Section Swiss Moto (facultatif)',
  },
  samSection: {
    label: 'Section SAM',
    helperText: 'Veuillez sélectionner la section que vous souhaitez rejoindre.',
    hintForSubmittedLicense:
      'La section SAM ne peut pas être modifiée sur cette plate-forme. Veuillez postuler pour le changement de section ici:',
  },
  sidecarPartner: {
    label: 'Partenaire side-car',
    hint: "Nom, prénom, date de naissance du partenaire (pour l'identification de l'équipe)",
    validation: 'Partenaire de side-car',
  },
  assignLicense: {
    title: 'Attribuer une licence',
    onlyRegisteredRiders:
      "Les licences ne peuvent être saisies que pour les coureurs déjà enregistrés. Si tu veux créer un nouveau pilote, tu dois te déconnecter en tant qu'admin, et te connecter avec l'email du pilote",
    tooManyResults: {
      title: 'Trop de résultats',
      text: "Veuillez restreindre davantage la recherche afin d'afficher une sélection de pilotes (par ex. via email, prénom, nom, rue, code postal, ville, date de naissance, ...)",
    },
  },
  licenses: {
    drafts: 'Demandes de licence en attente',
    approved: 'licences validées',
    approvedLicenses: 'Licences confirmées',
    unapprovedLicenses: 'Licences non confirmées',
    confirm: 'confirmer',
    edit: 'éditer',
    editLicenseBooking: 'Modifier la réservation de licences',
    searchLicense: "Recherche (nom, lieu, code postal, numéro d'adhésion)",
    licenseRequests: 'Demandes de licence',
    licenseRequestsByCategoryType: 'Demandes de licence de type de catégorie',
    licenseRequestsByCategory: 'Demandes de licence de catégorie',
    confirmed: 'Confirmé',
    unconfirmed: 'Non confirmé',
    totalLicenseRequests: 'Demandes totales de licences',
    assignTransponder: 'Affecter le transpondeur',
    confirmLicense: 'Confirmer la licence',
    cancelledBookingsError:
      "Erreur: les réservations annulées ne peuvent pas être annulées plus d'une fois. Cette affaire doit être traitée / pleinement mise en œuvre. Pardon :-(",
    importLicenses: 'Importer des licences',
    licenseNotFound: 'Licence introuvable',
    licenseIdOrCodeMissing: 'ID de licence ou code manquant',
  },
  routes: {
    termsAndDataProtection: 'Mentions légales et protection des données',
    licenseTerms: 'Conditions de la licence',
    insuranceTerms: "Conditions d'assurance",
    homepage: 'Webstructure',
    homepages: 'Apparences Web',
    dashboard: 'Tableau de bord',
    userDashboard: "Au tableau de bord de l'utilisateur",
    adminDashboard: 'Admin',
    allTransactions: 'Toutes transactions',
    dashboardAllTransactions: 'KPIs',
    manualPayments: 'Payements manuels',
    approvedLicenses: 'Licences confirmées',
    assignLicense: 'Attribuer une licence',
    userFinancialsRiders: 'Solde du compte du pilote',
    userFinancials: 'Etat des comptes',
    licenseDrafts: 'Demandes de licence',
    newTransponders: 'Commande de transpondeur',
    licenseBookingsByRider: 'Réservation de licences par pilote',
    allLicenseBookings: 'Réservation de licences KPIs',
    insurances: 'Assurances',
    allLicenses: 'KPIs du licences',
    payments: 'Payements',
    memberFees: 'Cotisations',
    manualBookingsAndCharges: 'Crédits et charges manuels',
    manualBookings: 'Comptabilisations manuelles',
    categoriesKeyFigures: 'Statistique des licences',
    associationFinancials: "Finances de l'association",
    importLicenses: 'Importation des licences',
    userEvents: "Activité de l'utilisateur",
    newInscription: "À l'inscription pour",
    publicInscriptions: 'Listes de départ',
    inscriptionGroups: "Groupes d'inscription",
    lonelyTwoDayDiscounts: 'Invalide réductions deux jours',
    userAccounts: 'Comptes utilisateur',
    emailConflicts: "Conflit d'e-mails",
    transactionsWithoutUser: 'Transactions sans utilisateur',
    duplicatedUsers: 'Comptes dupliqués',
    dbMigrations: 'DB Migrations',
    administrators: 'Administrateur',
    messages: 'Messages',
    changeYear: "Changer l'année",
    qrPitLane: 'Scanner le pass pour les boxs',
    previewLicense: 'Aperçu de la licence',
    qrLicense: 'Scanner la licence',
    editProfile: 'Modifier le profil',
    editEmergency: "Modifier les informations d'urgence",
    editDriversLicense: 'Modifier le permis de conduire',
    editHealthCheck: 'Editer le bilan de santé',
    editInsurance: "Modifier l'assurance",
    editPhoto: 'Modifier la photo',
    editTransponder: 'Modifier le transpondeur',
    changeEmailAfterLogin: "Modifier l'e-mail",
    newLicense: 'Demander une nouvelle licence',
  },
  lastYear: "L'année dernière",
  noPreviousLicense: "Je n'avais aucune licence l'année dernière",
  addNewLicense: 'Ajouter une nouvelle licence',
  editLicenseRequest: (categoryNameWithNumber: string) =>
    `Modifier la demande de licence ${categoryNameWithNumber}`,
  editLicense: (categoryNameWithNumber: string) => `Modifier la licence ${categoryNameWithNumber}`,
  acceptLicenseConditions: 'Accepter les conditions de licence',
  conditionsAcceptedByRider:
    'Les conditions et les déclarations de protection des données sont acceptées par le pilote',
  noLicenseRequestAlert:
    "Le pilote n'a pas encore soumis de demande de licence et n'a donc pas encore accepté les conditions de licence. Le pilote doit envoyer soi-même la première demande de licence, afin qu'il puisse lire et accepter les conditions de licence.",
  licenseRequestAlternative:
    "Si cela n'est pas possible / nécessaire, assurez-vous de l'un des points suivants:",
  licenseRequestAdminConfirmation: 'Vous avez informé le pilote des conditions de licence',
  licenseRequestRiderConfirmation:
    'Il/elle a confirmé les conditions de licence par écrit ou par e-mail',
  licenseRequestOtherConfirmation:
    'Le pilote a déjà accepté les conditions de licence autrement (par exemple en achetant une licence avec un autre compte)',
  licenseRequestInternalNote:
    'Remarque interne: Quand et de quelle manière le pilote a-t-il accepté les conditions et les déclarations de protection des données?',
  editLicenseHelperText: 'Exemple 26.1.2021, 20:30, par e-mail à lizenzen@s-a-m.ch',
  license: 'Licence',
  addLicense: 'Ajouter une licence',
  saveLicenseRequest: 'Enregistrer la demande de licence',
  confirmLicenseRequest: (categoryNameWithNumber: string) =>
    `Confirmer l'application de licence ${categoryNameWithNumber}`,
  licenseConfirmed: 'Licence confirmée',
  licenseRequestSummary: 'Résumé de la demande de licence',
  licensesAndLicenseRequests: 'Licences / demandes de licence',
  confirmedLicenseForRider: 'Licences confirmées de cette personne',
  otherLicensesWithSameNumber: 'Autres licences avec le même numéro de départ',
  otherLicenseRequestsForRider: 'Autres demandes de licence de cette personne',
  or: '-- ou --',
  lastYearLicenses: 'Licences des dernières années (SAM, Swiss Moto, MXRS, AFM, …)',
  lastYearLicensesShort: "Licences l'année dernière",
  addCategory: 'Ajouter une catégorie',
  lastYearCategories: "Catégories l'année dernière (SAM National B, MXRS Race,...)",
  lastYearCategoriesShort: "Catégories l'année dernière",
  lastYearStartNumbers: "Numéros de départ l'année dernière",
  lastYearRanks: "Classement final l'année passée",
  category: 'Catégorie',
  categoryType: 'Type de catégorie',
  sponsor: 'Équipe / sponsor',
  bike: 'Moto',
  bikes: 'Motocyclettes',
  maxTwoBikes: 'Un maximum de 2 motos peut être sélectionné',
  chooseBikes: 'Choisir les motos',
  section: 'Section / Fédération',
  domicile: 'Domicile',
  nationality: 'Nationalité',
  association: 'Fédération',
  changeAssociation: "Changement d'association",
  categoriesAlt: 'Catégories',
  dayCategories: 'Catégories du jour',
  dayCategory: {
    title: 'Catégorie journalière',
    transponder: 'Transpondeur',
    name: 'Nom',
    hint: 'Remarque',
    additionalTermsText: 'Conditions supplémentaires',
    additionalTermsURL: 'URL des conditions supplémentaires',
    price: "Frais d'inscription",
    needsInsurance: "Attestation d'assurance requise",
    needsEmergency: 'Formulaire de premiers secours requis',
    needsHealthCheck: 'Bilan de santé requis',
    needsBikeInfo: 'Informations de la moto requises',
    advertiseSamInsurance: 'Assurance indiquée via SAM',
    myLapsName: 'Nom du chronométrage',
    myLapsNameIfDifferent: 'Nom du chronométrage (si différent du nom)',
    sidecar: 'Sidecar',
    sidecarPassenger: 'Sidecar Passager',
    surcharge: 'Surtaxe',
    surchargeAfter: 'Surtaxe à partir de ...',
    discount: 'Rabais',
    discountUntil: "Rabais jusqu'à ...",
    inscriptionsLimit: "Délai d'inscription",
    waitlistLimit: "Limite de liste d'attente",
    pricePower: "Prix de l'électricité",
    priceTransponderRental: 'Prix location transpondeur',
    sortPrimaryBy: 'Tri primaire par',
    sortPrimaryByNames: {
      paymentDate: 'Date de payement',
      enlistingDate: "Date d'inscription",
    },
    createDayCategory: 'Ajouter une catégorie de jour',
    deleteDayCategory: 'Supprimer la catégorie de la journée',
    deleteDayCategoryConfirmation:
      'Vraiment supprimer? Les inscriptions existantes pour cette catégorie seront automatiquement supprimées.',
  },
  categories: (licenseYear) => `Catégories voulues pour ${licenseYear}`,
  categoriesWithoutYear: 'Catégories souhaitées',
  categoryAlreadyChosenHint: 'La catégorie a déjà été choisie via une autre association',
  categoryNotOnlineYet: "La catégorie n'est pas encore en ligne. Veuillez réessayer plus tard.",
  licenseAlreadyConfirmed: 'La licence est déjà confirmée',
  insuranceOption: {
    label: 'Insurance cover',
    adjustLabel: 'Adjust insurance cover',
    optionDetails: (
      benefitDurationDays: number,
      waitingPeriodDays: number,
      priceDifferenceWithSign: string
    ) =>
      `Benefit duration ${benefitDurationDays} days / waiting period ${waitingPeriodDays} days = CHF ${priceDifferenceWithSign}`,
    validation: 'Insurance cover',
  },
  sportEvent: 'Manifestation',
  sportEvents: 'Manifestations',
  sportEventType: 'Type de manifestation',
  sportEventTypes: {
    '': 'Tous',
    enduro: 'Enduro',
  },
  sportEventTypeHint:
    'Pour les manifestations d enduro, la plaque d immatriculation et le permis de conduire sont obligatoires',
  sportEventStatus: {
    online: 'En ligne',
    draft: 'Provisoire',
    deleted: 'Annulé',
  },
  sportEventFormErrors: {
    invalidStartOrEndDate: "Date invalide: veuillez vérifier le début et la fin de l'événement",
    setDateOrAnnounceLater:
      'Date des catégories invalides: soit la date sera annoncée plus tard, soit la date est fixée, pas les deux ensemble',
    noDateForCategory:
      'Date des catégories invalides: au moins une date par catégorie doit être sélectionnée',
    dateOutsideOfEventDates:
      "Date des catégories invalides: seules les dates pendant l'événement peuvent être choisies",
  },
  hasTransponder: {
    label: "J'ai déjà un transpondeur MyLaps",
    labelAlternative: 'A transpondeur MyLaps',
    validation: 'Transpondeur',
    hintForNewTransponder: 'Je commande un transpondeur MyLaps',
  },
  orderedMyLapsTransponder: {
    labelSingular: 'Je commande le transpondeur suivant',
    labelPlural: 'Je commande les transpondeurs suivants',
    labelAlternative: 'Transpondeurs commandés',
    validation: 'Transpondeur',
  },
  transponders: {
    title: 'Transpondeur',
    transponderSystem: 'Système de transpondeur',
    privateTransponder: 'Transpondeur personnel',
    borrowedTransponder: 'Transpondeur de location',
    transponder: 'Transpondeur',
    transponders: 'Transpondeur',
    hasTransponder: 'Transpondeur disponible',
    transpondersForm: {
      yourTranspondersTitle: 'Votre transpondeur',
      buyTransponderTitle: 'Acheter des transpondeurs',
      usedTransponders: 'Transpondeurs utilisés',
      perCategory: 'En fonction de la catégorie et des règlements',
      needsToBeBought: 'Un transpondeur séparé doit être acheté',
      needsToBeRentedPerEvent: 'Un transpondeur par événement est fourni moyennant des frais',
      isGivenForFreePerEvent: 'Un transpondeur par événement est fourni gratuitement',
      pleaseInformYourself:
        "Veuillez vous informer dans la réglementation correspondante concernant la manipulation si vous n'avez pas de transpondeur et que vous ne voulez pas en acheter un.",
      buyAdvertisment:
        "Le plus confortable dans tous les cas est de commander et d'utiliser votre propre transpondeur. Vous pouvez le faire ici:",
      batteryReminder: 'La batterie du transpondeur RF Chronolec doit être remplacée tous les 3 ans',
      canBeEditedLater: 'Vous pouvez modifier vos transpondeurs plus tard à tout moment',
    },
    transponderNames: {
      subscriptionOneYear:
        'MyLaps X2 / TR2 MX, abonnement de 1 an, motocross SAM/MXRS/SJMCC et supermoto SAM/Swiss Moto',
      subscription:
        'MyLaps X2 / TR2 MX, abonnement à 5 ans, motocross SAM/MXRS/SJMCC et supermoto SAM/Swiss Moto',
      purchase: "MyLaps tr2, variante d'achat, motocross SAM/MXRS/SJMCC et supermoto SAM/Swiss Moto",
      rfPurchase: "Chronolec RF (via Swiss Moto), variante d'achat, motocross AFM/Swiss Moto",
      rfPurchaseAfm: "Chronolec RF (via AFM), variante d'achat, motocross AFM/Swiss Moto",
    },
    editTransponder: 'Modifier le transpondeur',
    orderOptionalTransponder: 'Je veux ajouter ou commander un transpondeur',
    transponderOrders: 'Ordres de transpondeurs',
    orderedTransponderIds: 'ID de transpondeur ordonné',
    orderedTransponders: 'Transpondeurs ordonnés',
  },
  transponderMake: {
    label: 'Transpondeur. Marque & Type (ex. MyLaps X1)',
    labelAlternative: 'Transpondeur. Marque & Type',
  },
  transponderNumber: {
    label: 'Numéro de transpondeur myLaps',
    labelInscription: 'Numéro de transpondeur MyLaps (transpondeur personnel)',
  },
  transponderNumbers: {
    label: 'Numéros de transpondeur myLaps',
  },
  transponderNumbersInput: {
    label: 'Numéros de transpondeur myLaps (séparés par une virgule, par ex. 123, 456)',
  },
  hasTransponderRf: {
    label: "J'ai déjà un transpondeur Chronolec RF",
    labelAlternative: 'A RF transpondeur',
    validation: 'Transpondeur',
  },
  transponderNumberRf: {
    label: 'Numéro de transpondeur Chronolec RF',
  },
  transponderNumbersRf: {
    label: 'Numéros de transpondeur Chronolec RF',
  },
  transponderNumbersRfInput: {
    label: 'Numéros de transpondeur Chronolec RF (séparés par une virgule, par ex. 123, 456)',
  },
  bikeMake: {
    label: 'Marque de machine (par exemple Kawasaki)',
    labelShort: 'Marque de machine',
  },
  numberPlate: {
    label: 'Plaque d immatriculation',
    hint: 'par ex. VD 12345',
  },
  bikeInsurance: {
    label: 'Assurance moto',
    hint: 'par ex. Allianz',
  },
  bikeModel: {
    label: 'Modèle de machine (par exemple KX450)',
    labelShort: 'Modèle de machine',
    hint: "Si ce n'est pas évident du modèle ou si pas d'origine: Capacité du cylindre [CCM]",
  },
  frameNumber: 'Numéro de cadre',
  bikeCylinderDisplacement: 'Capacité du cylindre [CCM]',
  preferredNumber: {
    label: 'Numéro souhaité',
    labelAlternative: 'Numéro de départ',
    helperText: 'par exemple "7 ou nombre à deux chiffres 7"',
  },
  issuedNumber: {
    label: 'Numéro de départ définitif',
    labelAlternative: 'Numéro de départ',
  },
  ahvNumber: "Numéro d'AHV / Numéro d'assurance sociale",
  civilStatus: {
    label: 'État civil',
    single: 'Célibataire',
    married: 'Marié',
    divorced: 'Divorcé',
    widowed: 'Veuf',
    registeredPartnership: 'Partenariat enregistré',
    other: 'Autre',
  },
  confession: 'Confession',
  profession: 'Profession',
  doctorName: 'Médecin de famille',
  doctorPhone: 'Numéro de téléphone du médecin de famille',
  employerName: 'Employeur',
  employerPhone: "Numéro de téléphone de l'employeur",
  accidentInsurance: "Assurance d'accident",
  accidentInsuranceName: "Assurance d'accident",
  accidentInsurancePolicyNumber: "Numéro de police de l'assurance accident",
  accidentInsurancePlan: {
    label: "Division de l'hôpital pour l'assurance accident",
    general: 'Division commune',
    halfPrivate: 'Demi-privé',
    private: 'Privé',
    validation: "Veuillez choisir la division hospitalière pour l'assurance accident",
  },
  healthInsurance: 'Assurance santé',
  healthInsuranceName: 'Assurance santé',
  healthInsurancePolicyNumber: "Numéro de police de l'assurance-maladie",
  healthInsurancePlan: {
    label: "Division de l'hôpital pour l'assurance-maladie",
    general: 'Division commune',
    halfPrivate: 'Demi-privé',
    private: 'Privé',
    validation: "Veuillez choisir un département hospitalier pour l'assurance maladie",
  },
  additionalInsurances: 'Assurance complémentaire',
  hasSupplementaryInsurance: {
    label: "J'ai une assurance complémentaire",
    validation: 'Assurance complémentaire',
    hintForSupplementaryInsurance:
      "À partir de cette saison, une assurance complémentaire est incluse dans la licence SAM ainsi elle n'est plus nécessaire",
  },
  hasReturnTransportInsurance: {
    label: "J'ai une assurance de rapatriement",
    validation: 'Assurance de rapatriement',
    hintForNewReturnTransportInsurance: "Je n'ai aucune assurance de rapatriement",
  },
  returnTransportInsuranceName: "Nom de l'assurance de rapatriement (par ex. Rega)",
  contactOnAccident: "Contact en cas d'accident",
  contactOnAccident1Name: 'Personne 1: Nom',
  contactOnAccident1Phone: 'Personne 1: numéro de téléphone',
  contactOnAccident2Name: 'Personne 2: Nom',
  contactOnAccident2Phone: 'Personne 2: Numéro de téléphone',
  medicalInformation: 'Information médicale',
  medication: 'Médicament permanent actuel',
  allergies: 'Allergies connues contre les médicaments',
  infoForDoctor: 'Autres informations pour Service de secours / Docteur',
  additionalInformation:
    'Les informations suivantes peuvent être données volontairement et sont traitées strictement confidentielles:',
  bloodGroup: 'Groupe sanguin',
  bloodRhesusFactor: 'Facteur rhésus',
  specialInformation: "D'autres informations supplémentaires",
  confirmRevokeConfidentiality: {
    label:
      "En cas d'accident, je libère le service de secours et l'hôpital du secret médical envers les membres de l'équipe de secours, de la commission sportive, du président d'organisation, le directeur de course et le contact de secours.",
    validation: 'Les conditions doivent être acceptées pour participer aux courses.',
  },
  remarks: 'Remarques',
  remarksRider: 'Remarques pilote',
  remarksAdmin: 'Remarques admin',
  licenseRequestSent: 'Application de licence envoyée sur',
  internalRemarks: 'Remarques internes',
  parentsInfo: 'Prénom, nom, téléphone du tuteur légal.',
  parentsAgree: {
    label: 'Ce formulaire a été rempli par les parents ou le tuteur légal.',
    validation: 'Ce formulaire doit être rempli par les parents ou le tuteur légal.',
  },
  acceptTerms: {
    label: "J'ai lu les conditions et la déclaration de protection des donées et l'accepte.",
    validation: 'Conditions et protection des données',
    additionalTerms: 'Conditions supplémentaires',
    hintForTerms: 'Lisez les documents suivants:',
    hintForTermsTerms: 'Conditions & déclaration de protection des données',
    hintForTermsDataProtection: 'Déclaration de protection des données',
    hintForTermsError:
      'Les conditions et la déclaration de protection des données doivent être acceptées pour recevoir une licence.',
  },
  photoPath: "Photo (seulement pour ceux sans la licence de l'année précédente)",
  riderWeight: 'Poids du pilote avec équipement',
  bikeName: {
    label: 'Nom de la moto (facultatif)',
    hint: 'Pour vous identifier, par ex. "moto de course", "Moto de remplacement" ou "Moto 2020"',
  },
  teamName: {
    label: 'Équipe (facultatif)',
    labelShort: 'Équipe',
  },
  bikeFrame: 'Marque du cadre-châssis / Type',
  bikeMotorName: 'Marque du moteur / nom du moteur',
  bikeMotorNumber: 'Numéro du moteur ( les 6 premiers chiffres)',
  bikeIntake:
    "Système d'entrée (chaîne contrôlée, ZME Ex Factory [Entrée de la membrane cylindrique], conversion en ZME, GME [entrée de la membrane de boîtier])",
  bikeCylinderCooling: "Marque de cylindre (refroidi à l'air, refroidi à l'eau)",
  bikeCylinderIsOriginal: "Cylindre d'origine? (oui, non)",
  bikeIgnition: "Système d'allumage (disjoncteur, électronique)",
  bikePistonSize: 'Diamètre de piston [mm]',
  bikeCarburetorSize: 'Taille du carburateur [mm]',
  bikeCrankshaftIsOriginal: "Course du vilebrequin (comme moteur d'origine) (mm)",
  bikeCrankshaftModified: "Course du vilebrequin (pas identique au moteur d'origine) (mm)",
  acceptBikeTerms: {
    label: (licenseYear: number) =>
      `Je confirme que mon équipement (par exemple, des vêtements de protection, un casque) et ma moto sont en accord avec la réglementation correspondante ${licenseYear} de ma catégorie (pour les courses de clubs, les règlements respectifs s'appliquent). Je confirme que je porterai les vêtements de protection complètes sans exception dans une séance d'entraînement et de course d'un événement. Je confirme que je suis responsable de la sécurité de ma moto.`,
    validation:
      "Afin d'accroître ta sécurité et la sécurité de tes collègues pilotes, tu dois observer les points respectifs pour participer aux courses.",
  },
  table: {
    oneEntryIsDisplayed: 'Une entrée est affichée',
    showingNEntries: (amount: number) => `${amount} entrées sont affichées`,
    showAllEntries: (amount: number) => `Afficher toutes les ${amount} entrées`,
    manyEntriesWarning:
      "(beaucoup d'entrées => l'exportation Excel est peut-être mieux adaptée à ce type de données)",
  },
  associationPayments: {
    title: "Paiements de l'association",
    add: "Ajouter un paiement à l'association",
    delete: "Supprimer le paiement à l'association",
    downloadSelectedPayments: (amount: number) => `Télécharger les ${amount} paiements sélectionnés`,
    requestPayment: "Demander un paiement à l'association",
    states: {
      requested: 'Peyement requis',
      paid: 'Payé',
    },
  },
  accountBalance: 'Solde bancaire CHF',
  accountBalanceAssociation: "Solde de l'association (selon les filtres)",
  accountBalanceChangeAssociation: "Modification du solde de l'association",
  noAccountBalanceBookings: 'Pas de réservations',
  addManualPaymentButtonText: (amount: string) => `Encaisser ${amount}`,
  transactionsKPIs: 'KPIs des transactions',
  accountBalanceCHF: 'Solde bancaire CHF',
  processedAt: 'Traité à',
  createdAt: 'Créé le',
  updatedAt: 'Mis à jour le',
  actions: 'Actions',
  amount: {
    label: 'Quantité',
    hint: 'Négatif: crédit, positif: débit',
  },
  amountOpen: 'Dont encore ouvert',
  amountOpenPosition: 'Ouvert',
  amountTable: 'Montant',
  amountTableCHF: 'Montant en CHF',
  sportEventFinancialsAmounts: {
    total: 'Total revenus',
    inscription: "Dont les frais d'inscription",
    power: "Dont l'électricité (Supermoto)",
    dayLicense: 'Dont licences journalières',
    donation: 'Dont donation',
    inscriptionDiscount: 'Dont remise pour 2 jours',
    open: 'Revenus encore ouvert',
  },
  createBooking: 'Comptabiliser',
  createBookingAndBill: 'Comptabiliser et établir une facture',
  reverseBooking: 'Faire un retour',
  transactionTypeNames: {
    licenseBooking: 'Comptablisation licences',
    reverseLicenseBooking: 'Annulation licences',
    inscriptionBooking: 'Inscription',
    manualBooking: 'Comptabilisation manuelle',
    payment: 'Paiement automatique',
    manualPayment: 'Paiement manuel',
    bill: 'Facture',
    associationPayment: "Paiement à l'association",
  },
  lineItemNames: {
    inscriptionLineItem: "Prime d'inscription",
    inscriptionDayCategoryLineItem: "Frais d'inscription (manifestation individuelle)",
    inscriptionDayLicenseLineItem: "Frais d'inscription (licence de jour)",
    powerLineItem: 'Electricité',
    inscriptionDayCategoryPowerLineItem: 'Electricité',
    categoryLineItem: 'Catégorie',
    transponderLineItem: 'Transpondeur',
    donationLineItem: 'Donation',
    inscriptionDiscountLineItem: 'Remise pour 2 jours',
  },
  signIn: {
    popupOpened: "Popup pour l'inscription a été ouverte",
    signInWithGoogle: 'Connectez-vous avec Google',
    signInWithFacebook: 'Connectez-vous avec Facebook',
    emailSentLong:
      "L'email a été envoyé. Veuillez vérifier votre email et cliquez sur le lien pour vous inscrire.",
    emailSentShort: 'Email envoyé',
    signInWithPassword: 'Connectez-vous avec mot de passe',
    signInWithEmail: 'Connectez-vous avec email',
    backToSignIn: 'Retour à la connexion',
    emailNotSent: "E-mail n'a pas été envoyé. S'il vous plaît choisi une autre option d'enregistrement.",
    verifyEmail: (email: string) => `Vérifiez le courrier électronique \"${email}\"`,
    verificationEmailSent: (email: string) =>
      `E-mail a été envoyé. Veuillez vérifier vos mails et cliquez sur le lien pour confirmer \"${email}\".`,
    creatingProfile: 'Une seule seconde, ton profil est créé',
    loading: "Chargement de l'app...",
    loadingUser: "Chargement de l'utilisateur...",
    loadingDocuments: 'Chargement des documents...',
    loadingUserData: (email: string) => `Chargement des données d'utilisateur de ${email}...`,
  },
  signInText: 'Identifier',
  signOutText: 'Déconnecter',
  inscribe: 'Inscription',
  inscribeFor: 'Inscrire pour',
  inscribeIn: "S'inscrire en",
  inscribeAtDateUnknown: 'Inscrire (selon le programme du jour)',
  user: 'Pilote',
  userID: 'ID du pilote',
  admin: 'Admin',
  adminID: "ID d'administration",
  id: 'ID',
  datetime: 'Date / heure',
  datetimeISO: 'Date ISO',
  bookingType: 'Type',
  bookingSubtype: 'Sous-type',
  bookingItemName: 'Nom de comptabilisation',
  bookingText: 'Texte de comptabilisation',
  manualPaymentTag: 'Tag (filtre interne)',
  manualBookingTag: 'Tag (filtre interne)',
  licenseStatus: 'Statut',
  uid: 'IDE',
  photo: 'Photo',
  displayName: "Nom d'affichage",
  creationTime: 'Enregistré le',
  lastSignInTime: 'Dernière connection',
  customClaims: 'Fonctions',
  date: 'Date',
  position: 'Position',
  reference: 'Référence',
  referenceParts: 'YYMMDD - HHMMSS - Numéro de membre - Random - CheckSum (éventuellement déplacé)',
  status: 'Statut',
  open: 'Ouvert',
  paid: 'Payé',
  importStatus: 'Importer',
  parsingStatus: 'Analyse',
  sheetStatus: 'Modification',
  details: 'Détails',
  debitor: 'Payé par / pour',
  bill: 'Facture',
  iban: 'IBAN',
  healthCheck: {
    title: 'Bilan de santé',
    status: {
      notUploaded: 'Non téléchargé (comlètement)',
      uploaded: 'Téléchargé',
      verified: 'Confirmé',
      invalid: 'Objecté par admin, veuillez télécharger à nouveau',
    },
    downloadAndPrintOptionA:
      'Option A: License international (DE): Télécharger et imprimer le contrôle de santé de licence',
    downloadAndPrintOptionB:
      'Option B: License international (FR): Télécharger et imprimer le contrôle de santé de licence',
    downloadAndPrintOptionC:
      'Option C: Licence nationale: Déclaration personnelle télécharger et imprimer',
    instructions: {
      fillInByDoctor: 'Laissez le médecin remplir et laissez-le signer',
      fillInPersonalDataAndSign: 'Remplir les données personnelles et les signer',
    },
    takePictureOrUpload: 'Photographie / Téléchargement',
    page: 'Page',
    deletePage: (page) => `Supprimer la page ${page}`,
  },
  provideDocuments: {
    status: 'Statut:',
    optionATakePictureWithPhone: 'Option A: Prendre la photo avec le portable (voir ci-dessous)',
    optionBScanAndUploadOnComputer: "Option B: Scanner et télécharger de l'ordinateur (voir ci-dessous)",
    checkReadabilityAndSignature: 'Vérifiez la lisibilité et les signatures',
    qualityHint:
      'Assurez-vous que les formulaires soient bien lisibles. Dans le cas où nous ne pouvons pas lire correctement les formulaires, vous devrez les télécharger une nouvelle fois plus tard',
    errorTooManyFilesSelected: 'Trop de fichiers sélectionnés par page',
  },
  insurance: {
    title: "Confirmation d'assurance",
    shortTitle: 'Assurance',
    status: {
      notUploaded: 'Non téléchargé',
      uploaded: 'Téléchargé',
      verified: 'Confirmé',
      invalid: 'Objecté par admin, veuillez télécharger à nouveau',
    },
    chooseInsuranceConfirmationType: "Sélectionnez confirmation d'assurance:",
    optionAForEmployees: 'Pour les employés selon la LAA Suisse',
    optionBForSelfEmployed: 'Pour les pilotes indépendants selon la LAA Suisse',
    optionCForOthers: 'Pour tous les autres pilotes',
    downloadInsuranceConfirmation:
      "Téléchargez et imprimez la confirmation d'assurance ou envoyez-la électroniquement",
    fillInByEmployer: "Confirmation d'assurance doit être remplie et signée par l'employeur",
    uploadInsuranceConfirmation: "Confirmation d'assurance: la photographier / la télécharger",
    deleteInsuranceConfirmation: "Supprimer la confirmation d'assurance",
  },
  pdfInsurance: {
    generatedOn: 'Généré sur',
    insuranceCompanyAndPoliceNumber:
      "Compagnie d'assurance, numéro de police ..................................................................................",
    fillInByEmployer: {
      title: "Doit être rempli par l'employeur",
      companyAddress:
        'Enterprise / adresse .....................................................................................................................',
      confirmAccidentInsurance: (riderName: string, formattedBirthdate: string) =>
        `Nous confirmons par la présente que ${riderName} ${formattedBirthdate} est assuré contre les accidents non professionnels selon la LAA`,
      datePlaceCompanySignature:
        "Date, lieu, signature de l'entreprise ..............................................................................................",
    },
    fillInByRider: {
      title: 'Doit être rempli par le pilote',
      confirmAccidentInsurance: (riderName: string, formattedBirthdate: string) =>
        `Je confirme que moi, ${riderName} ${formattedBirthdate} suis assuré(e) contre les accidents non professionnels selon la LAA`,
      datePlaceRiderSignature:
        'Date, lieu, signature par pilote ....................................................................................................',
      confirmAccidentInsuranceOther: (riderName: string, formattedBirthdate: string) =>
        `Je confirme que moi, ${riderName} ${formattedBirthdate} suis assuré(e) contre les accidents non professionnels équivalent à la LAA Suisse. Je confirme notamment que les frais de guérison après un accident sont couverts par mon assurance.`,
    },
    selfDeclaration: {
      title: 'Demande de licence 2024 | Déclaration personnelle pour la licence nationale de pilote',
      confirmTitle: (riderName: string, formattedBirthdate: string) =>
        `Je, ${riderName}, né(e) le ${formattedBirthdate}, erkläre hiermit déclare par la présente, conformément à la législation en vigueur:`,
      bulletPoint1:
        'Je demande une licence de course nationale conformément aux règlements de Swiss Moto.',
      bulletPoint2:
        "Je certifie que je suis en pleine possession de mes facultés mentales et que je ne souffre d'aucun problème de santé ou d'aucune infirmité susceptible d'altérer ma capacité à agir en toute sécurité en tant que pilote de course.",
      bulletPoint3:
        "Je suis conscient(e) que toute déclaration fausse ou incomplète concernant ma santé mentale ou physique peut entraîner des conséquences pénales et civiles ainsi que des conséquences en matière d'assurance.",
      bulletPoint4:
        "• J'accepte de me soumettre à tous les examens médicaux nécessaires qui peuvent être exigés par les autorités compétentes ou conformément aux dispositions de Swiss Moto.",
      bulletPoint5:
        "• Je m'engage à informer immédiatement Swiss Moto de toute modification de mon état de santé qui pourrait influencer mon aptitude à piloter lors d'une course, d'un entraînement ou d'autres engagements en rapport avec ma licence.",
      endParagraph:
        "Cette auto-déclaration est considérée comme faisant partie intégrante de ma demande de licence et peut être utilisée par Swiss Moto à des fins de vérification. Une auto-déclaration incomplète ou fausse entraîne dans tous les cas le retrait immédiat de la licence, ainsi que l'exclusion des championnats, y compris la suppression des points accumulés.",
      datePlaceRiderSignature:
        'Date, lieu, signature par pilote ....................................................................................................',
    },
  },
  licensePhoto: {
    title: 'Photo de profil',
    status: {
      notUploaded: 'Non téléchargé',
      uploaded: 'Téléchargé',
      verified: 'Vérifié',
      invalid: 'Objecté par admin, veuillez télécharger à nouveau',
    },
    options: {
      optionAMakeASelfie: 'Option A: prendre un selfie avec votre natel (voir ci-dessous)',
      optionBUploadFromComputer: "Option B: télécharger la photo de l'ordinateur (voir ci-dessous)",
      checkQuality: 'Vérifiez la qualité de la photo',
    },
    qualityHint:
      "Assurez-vous de la bonne qualité de la photo faite / téléchargée. Si ce n'est pas le cas, tu devras la télécharger ultérieurement.",
    uploadPhoto: 'Photo: photogragier / télécharger',
    deletePhoto: 'Supprimer la photo',
    invalidPhoto: "Le fichier téléchargé a été jugé invalide par l'administrateur",
    uploadAgain: "Télécharge s'il te plaît le fichier une nouvelle fois.",
    saveLicensePhoto: 'Sauvegarder la photo',
  },
  categoryTypeNames: {
    other: 'Autres',
    motocross: 'Motocross',
    supercross: 'Supercross',
    mofacup: 'Mofacup',
    mofacross: 'Mofacross',
    pitbikecross: 'Pitbikecross',
    minibike: 'Minibike',
    trial: 'Trial',
    supermoto: 'Supermoto',
    roadRacing: 'Road Racing',
    iceRace: 'Ice-Race',
    snx: 'SNX',
    dragster: 'Dragster',
    vintage: 'Vintage',
    track: 'Track',
    enduro: 'Enduro',
    enduroCrossCountry: 'Enduro / Crosscountry',
    bergrennen: 'Course de côte',
    eMoto: 'eMoto',
    otherFms: 'Autres',
    omc: 'OMC',
  },
  categoryTypeNamesLong: {
    otherFms: 'One Event / Fun Sport alle Disziplinen',
  },
  importStatusOptions: {
    done: 'Apparié',
    open: 'Import ouvert',
    outdatedPersonalData: 'Les données personnelles ne coïncident pas',
    locked: 'Aucune action possible',
  },
  none: 'Rien',
  parsingStatusOptions: {
    success: "d'accord",
    error: 'Erreur',
  },
  sheetStatusOptions: {
    associate: 'Créer',
    delete: 'Effacer',
    missingOrUnpaid: "Manque d'info ou non payé",
    unknown: 'Inconnu / faux',
  },
  messages: {
    recipient: 'Récepteur (tout, division, catégorie)',
    type: 'Type de messagerie / Modèle',
    simulate: 'Aperçu (e-mails ne sont pas envoyés)',
    location: {
      label: 'Lieu de la manifestation',
      hint: 'par exemple. Armeno (Piémont I)',
    },
    date: {
      label: 'Date',
      hint: 'par exemple. Samedi 24 avril 2021 - Dimanche 25 avril 2021',
    },
    sportEvent: 'Manifestation',
    sendMessages: 'Envoyer des messages',
    generatingPreview: 'Le preview est généré ...',
    generatePreview: 'Générer un preview',
    sendingEmail: 'Envoi de messages ...',
    confirmSendEmail: 'Envoyez vraiment le courrier',
    emailPreview: 'Email preview / e-mails envoyés',
    sent: 'Envoyé',
    preview: 'Preview',
  },
  internalError: 'Une erreur inattendue est survenue. Veuillez contacter le support.',
  inscription: {
    group: 'Groupe',
    title: 'Inscrire',
    inscriptionType: 'Type',
    inscriptionTypes: {
      enlistedLicenseInscription: 'Inscription validée par le système pour la catégorie annuelle',
      enlistedDayInscriptionYearCategoryDraft:
        "Inscription non confirmée pour la catégorie d'année / licence de jour",
      enlistedDayInscriptionYearCategory:
        'Inscription confirmée pour la catégorie annuelle / licence de jour',
      enlistedDayInscriptionDayCategoryDraft:
        'Inscription non confirmée pour la catégorie de jour / catégorie du club',
      enlistedDayInscriptionDayCategory:
        'Inscription confirmée pour la catégorie de jour / catégorie du club',
      unlistedLicenseInscription: 'Annulation licence annuelle pour la catégorie annuelle',
      unlistedDayLicenseInscription: 'Annulation licence journalière',
    },
    pendingTasksDescription: 'mais ce qui suit est toujours manquant',
    yearLicenseRequired: 'Une licence annuelle est requise pour cette catégorie',
    dayLicenseInThisCategoryTemporarilyDisabled:
      'Les licences journalières sont temporairement désactivées pour cette catégorie. Veuillez réessayer 2 semaines avant la manifestation',
    enlistedOnWaitlist: "inscrit, mais pas encore confirmé / sur la liste d'attente",
    payAndInscribe: 'Inscription payante',
    inscribing: 'Inscription en cours...',
    inscribeFor: 'Inscription pour',
    editInscriptionBooking: "Modifier l'entrée d'inscription",
    editInscriptionBookingTooltip: "Modifier l'entrée d'inscription",
    saveInscriptionBooking: "Enregistrer l'entrée d'inscription",
    deleteInscriptionConfirmation: 'Vraiment supprimer?',
    deleteInscriptionTitle: (fullName: string) => `Effacer l'inscription de ${fullName}`,
    deleteInscriptionInfo: (fullName: string, sportEvent: string) =>
      `Effacer l'inscription de ${fullName} pour ${sportEvent}. Les entrées d'inscription seront également supprimées, mais les factures générées ne sont pas (encore) supprimées. Toutes les amendes doivent être saisies manuellement.`,
    deleteInscriptionTooltip: (sportEvent: string) => `Effacer l'inscription pour ${sportEvent}`,
    migrateInscriptionBookings:
      "Migrer les réservations d'inscription. Cela ne doit être fait que 1x, le bouton sera supprimé. Cela prend quelques secondes.",
    inscriptionDetails: "Détails d'inscription",
    inscriptionStatistics: "Statistiques d'inscription",
    addNewInscription: 'Ajouter une nouvelle inscription',
    addInscription: 'Ajouter une inscription',
    editInscription: "Modifier l'inscription",
    fineForLastInscription: 'Ajouter une amende pour la dernière inscription',
    fineForInscription: (categoryName: string, sportEvent: string) =>
      `Ajouter une amende pour l'inscription dans ${categoryName} / ${sportEvent}`,
    inscriptionCurrentlyDeactivated:
      'Cette fonction est actuellement désactivée (inscription pour admin)',
    categoryNotStartingIn: (category: string, sportEvent: string) =>
      `Catégorie ${category} ne roule pas à ${sportEvent}`,
    modifySportsEventInscription: (inscriptionType: string, sportEvent: string) =>
      `Confirmer / modifier / rejeter ${inscriptionType} de ${sportEvent}`,
    confirmManually:
      'Confirmer manuellement (toutes les autres vérifications du système seront désactivées)',
    removeManualConfirmation:
      'Supprimer la confirmation manuelle (réactive les autres vérifications du système)',
    noTransponderRegistered: (transponderType: string) => `Aucun ${transponderType} enregistré`,
    noTransponderAssigned: (transponderType: string) => `Aucun ${transponderType} attitré`,
    confirmRegistration: "Confirmer l'inscription",
    enlistInscriptionInfo: (numberOfRiders: number, numberOfPayments: number, riderLimit: number) =>
      `${numberOfRiders} pilotes se sont inscrits. Les paiements ont été reçus de ${numberOfPayments} pilotes. Un maximum de ${riderLimit} peuvent courir dans cette catégorie.`,
    enlistInscriptionOrderInfo:
      "Les inscriptions sont principalement prioritaires en fonction de la réception du paiement. Si deux paiements arrivent en même temps, la date d'enregistrement est la deuxième priorité. Tant que votre paiement n'a pas été reçu sur notre compte, votre place de départ peut être attribuée à d'autres coureurs qui paient avant vous.",
    enlistPaymentInfo:
      'Si vous payez et ne pouvez pas courir parce que la catégorie est pleine, votre paiement sera crédité au Racemanager. Avec ce crédit, vous pouvez vous inscrire à la prochaine course.',
    enlistWaitlistInfo:
      "Si le champ est déjà plein à la réception de votre paiement, vous serez sur la liste d'attente. Si une place est alors disponible spontanément, vous êtes automatiquement inscrit à la course. Dans ce cas, vous ne pouvez plus récupérer votre argent.",
    enlistPaidRidersInfo: (numberOfPaidRiders: number, inscriptionLimit: number) =>
      `${numberOfPaidRiders} pilotes ont payé, mais un maximum de ${inscriptionLimit} pilotes peuvent courir.`,
    enlistOnWaitlistInfo:
      "C'est pourquoi votre inscription va sur la liste d'attente. Vous pouvez seulement espérer que les coureurs qui ont déjà payé se désinscriront. Sinon, vous ne pourrez pas participer.",
    enlistRiderLimitInfo: (numberEnlisted: number, inscriptionLimit: number) =>
      `Il y a ${numberEnlisted} pilotes inscrits, mais un maximum de ${inscriptionLimit} pilotes peuvent courir.`,
    enlistWaitlistPaymentInfo:
      "C'est pourquoi votre inscription va sur la liste d'attente. Si les autres pilotes paient avant vous ou en même temps, vous ne pourrez pas participer. Si vous payez et qu'un autre pilote enregistré ne le fait pas, vous obtiendrez sa place.",
    enlistInscriptionOrderRules: (numberOfRiders: number, numberOfPaidRiders: number) =>
      `${numberOfRiders} pilotes sont enregistrés. Nous avons reçu des paiements de ${numberOfPaidRiders} pilotes. Les lieux de course sont limités en fonction de l'événement / association / pistes, et les pilotes licenciés ont la priorité en fonction des réglementations de la catégorie respective. Veuillez lire ces règlements avant de contacter l'association correspondante. Merci.`,
    enlistDayLicenseInfo:
      "Si des places libres sont disponibles après l'enregistrement des pilotes licenciés, les inscriptions supplémentaires sont principalement priorisées en fonction de la réception du paiement. Si deux paiements arrivent en même temps, la date d'enregistrement est la deuxième priorité. Tant que votre paiement n'a pas été reçu dans notre compte, votre place de départ peut être attribuée à d'autres pilotes qui paient avant vous.",
    updatingInscriptions: 'Mise à jour des inscriptions...',
    moveInscriptionsError: 'Impossible de déplacer (toutes) les inscriptions',
    moveOrDeleteInscriptionNotice: 'Les inscriptions suivantes sont reportées ou supprimées',
    categoryId: 'ID de la catégorie',
    from: 'De',
    to: 'À',
    collect: 'Collecter',
    raceDate: 'Date de course',
    riderRemarks: 'Remarque de pilote',
    adminRemarks: "Remarque de l'administrateur",
    unlistedInscriptionCreatedAt: 'Approuver à',
    enlistedInscriptionCreatedAt: 'Inscription à',
    sidecar: 'Side car',
    noPartnerAssigned: 'Aucun partenaire encore affecté',
    sideCarPartnerLabel: 'Partenaire de side car (<nom de famille> <itame>)',
    assignPartner: 'Ajouter partenaire',
    openTasks: 'Tâches ouvertes',
    manualConfirmation: 'Confirmation manuelle',
    manuallyVerified: 'Vérifié manuellement',
    verifiedBySystem: 'Vérifié par le système',
    addFineByAssociation: "Ajouter de l'amende (association)",
    fromRequestedLicense: 'De la licence demandée',
    confirmInscription: (issuedNumber: number) =>
      `Confirmer l'enregistrement avec le numéro de démarrage ${issuedNumber}`,
    bikeMakeLabel: 'Marque de moto',
    inscriptionDeactivatedFor: 'Inscription désactivée pour',
    unsubscribeNotPossibleAnymoreTitle: (sportEventName: string) =>
      `Désinscription plus possible pour ${sportEventName}`,
    unsubscribeNotPossibleAnymoreText: (sportEventName: string) =>
      `Les désinscriptions pour ${sportEventName} ne sont plus possibles via le Racemanager. Veuillez contacter l'organisateur ou le secrétariat si vous souhaitez vous désinscrire`,
    filterByStatus: 'Filtrer par statut:',
    filterByType: 'Filtrer par type',
  },
  search: 'Recherche (nom, catégorie, #7, IDE, association,',
  chooseCategoryAndDay: 'Choisissez la catégorie / jour',
  detailsAndEdit: 'Détails / Modifier',
  searchRider: 'Rechercher un pilote',
  selectedRiderTooltip: 'Ce pilote est déjà sélectionné => Faites défiler vers le haut',
  deleteEntry: "Supprimer l'entrée",
  searchRiderLabel: 'Recherche (nom, date de naissance, IDE, code postal, lieu, MGNR)',
  myLaps: 'Chronométrage',
  exportForMyLaps: 'Export pour chronométrage',
  exportMyLaps: 'Export chronométrage',
  exportAlertTooltip:
    "Attention: Si une catégorie se déroule les deux jours, il n'est pas clair dans cet export quel jour le pilote roule. Les coureurs qui courent les deux jours apparaissent deux fois dans cette exportation.",
  formatByEventAndCategories: "Format selon l'événement et les catégories",
  assignNoRaceDay: 'Aucune journée de course attribuée',
  all: 'Tout',
  formatOptionSpecial: 'Format: NOM DE FAMILLE, prénom, lieu (sans code postal)',
  formatOptionNormal: 'Format: prénom, nom de famille, code postal, lieu',
  personalData: 'Données personnelles',
  editPersonalData: 'Modifier les données personnelles',
  unknown: 'inconnu',
  qrCodeScanNotAvailable: 'ImageCapture non disponible dans votre browser/OS pour le moment',
  scanning: 'Numérisation en cours...',
  scanCancelled: 'Scan QR annulé',
  scanNotCompleted: "Le code QR n'a pas pu être scanné",
  qrScan: 'Scan QR',
  qrScanLong: 'Scanner la licence ou le pass pour les boxs',
  tryAgain: 'Réessayer',
  showOnlyCategoriesWithLicense: 'Afficher uniquement les catégories de mes licences / inscriptions',
  startList: 'Liste de départ',
  startLists: 'Listes de départ',
  updatePublicStartLists: 'Mettre à jour / égaliser la liste de départ en public',
  for: 'pour',
  dayLicense: 'Licence journalière',
  issue: 'publier',
  deleted: 'supprimé',
  unpaid: 'non payé',
  incomplete: 'incomplet',
  twoDayDiscount: 'Remise pour 2 jours',
  dayUnconfirmedDate: 'selon le programme du jour',
  inscriptionStatusLong: {
    'not-inscribed': 'pas inscrit',
    unlisted: 'Désinscrit',
    inscribed: 'Inscrit',
    'inscription-pending': 'Inscrit',
  },
  inscriptionTypeSearch: {
    dayLicense: 'LJ',
    dayCategory: 'Club',
    license: 'Licence',
    unlisting: 'Désinscription',
  },
  inscriptionStatusShort: {
    'not-inscribed': 'Pas inscrit',
    unlisted: 'Désinscrit',
    inscribed: 'Inscrit',
    'inscription-pending': 'Incomplet',
  },
  kpiTitles: {
    totalBalances:
      "Total soldes des comptes, sans paiements QR reçus (positif = dette du coureur envers l'association)",
    totalBalanceWithouLicenseBookings:
      "Total soldes des comptes, sans paiements QR reçus (positif = dette du coureur envers l'association), moins les licences ouvertes",
    totalAutomaticPayments: 'Total des paiements automatiquement enregistrés',
    totalManualPayments: 'Total des paiements enregistrés manuellement',
    totalInscriptionBookings: 'Inscriptions totales',
    openInscriptionBookings: 'Inscriptions ouvertes',
    paidInscriptionBookings: 'Inscriptions payées',
    totalLicenseBookings: 'Licences totales',
    openLicenseBookings: 'Licences ouvertes',
    paidLicenseBookings: 'Licences payées',
    totalManualBookings: 'Total Comptabilisations manuelles',
    totalTransponderBookings: 'Comptabilisations licence: total transpondeurs',
    openTransponderBookings: 'Comptabilisations licence: transpondeurs ouverts',
    paidTransponderBookings: 'Comptablisations licence: transpondeurs payés',
    totalCategoryBookings: 'Comptablisaitons licence: total frais licences',
    openCategoryBookings: 'Comptabilisation licence: frais licences ouverts',
    paidCategoryBookings: 'Comptablisation licence: frais licences payés',
    totalCategoryBookingsWithInsurance: 'Comptabilisations licence enitère: total frais licence',
    openCategoryBookingsWithInsurance: 'Compabilisation licence entière: frais licence ouverts',
    paidCategoryBookingsWithInsurance: 'Comptablilisation licence entière: frais licence payés',
    totalAmountCategoryBookingsWithInsurance:
      'Comptabilisation licence entière: nombre total licence (pas CHF)',
    openAmountCategoryBookingsWithInsurance:
      'Comptablisation licene enitère: nombre ouvert licence (pas CHF)',
    paidAmountCategoryBookingsWithInsurance:
      'Comptabilisation licence entière: nombre payé licence (pas CHF)',
    totalInsuranceCategoryBookingsWithInsurance:
      "Comptabilisation licence entière: montants d'assurance total",
    openInsuranceCategoryBookingsWithInsurance:
      "Comptabilisation licence entière: montants d'assurance ouverts",
    paidInsuranceCategoryBookingsWithInsurance:
      "Comptabilisation licence entière: montants d'assurance payés",
    totalAmountCategoryBookingsSecondary:
      "Comptabilisation licence sans part d'assurance: nombre total licence (pas CHF)",
    openAmountCategoryBookingsSecondary:
      "Comptabilisation licence sans part d'assurance: nombre ouvert licences (pas CHF)",
    paidAmountCategoryBookingsSecondary:
      "Comptabilisation licence sans part d'assurance: nombre payé licences (pas CHF)",
    licenseBookingKPIs: 'Réservations de licence KPI',
    licenceBookingsByCategoryType: 'Réservations de licences selon le type de catégorie',
  },
  accountOverview: "Vue d'ensemble",
  financials: {
    financials: 'Finance',
    financialsFor: 'Finance pour',
    amountReceived: 'Montant reçu',
    amountPaid: 'Montant payé',
    debit: 'Débit',
    credit: 'Crédit',
    open: 'Ouvert',
    paidOn: 'Payé le',
    payment: 'Paiement',
    overpayment: 'Trop payé',
    underpayment: 'Paiement partiel',
    replaced: 'Remplacée par une nouvelle facture',
    inscriptionDiscount: 'Remise créditée',
    priceRangeTextBetween: (from: string, to: string) => `Date de naissance entre ${from} et ${to}:`,
    priceRangeTextOlderThan: (from: string) => `Plus ancien que ${from}:`,
    priceRangeTextYoungerThan: (to: string) => `Plus jeune que ${to}:`,
    manualBookingsAndCharges: 'Crédits et frais manuels',
    newManualBookingTitle: 'Nouvelle inscription manuelle',
    newManualBooking: 'Nouvelle entrée manuelle',
    saveNewManualBooking: (bookingKind: string) => `Enregistrer ${bookingKind}`,
    deleteManualBooking: (bookingItemName: string) =>
      `Supprimer l'inscription manuelle ${bookingItemName}`,
    editManualBookingButton: "Modifier l'inscription manuelle",
    editManualBooking: (bookingKind: string) => `Modifier la ${bookingKind} manuellement`,
    saveManualBooking: (bookingKind: string) => `Enregistrer ${bookingKind}`,
    copyBooking: "Copier l'inscription",
    bookingsOnRiderChanged:
      "Attention: Si le pilote est modifié, une nouvelle inscription sera créée et l'ancienne inscription doit être supprimée manuellement.",
    riderChanged: 'Pilote modifié',
    generateNewBill: 'Générer une nouvelle facture',
    eventFilter: 'Afficher / masquer les événements',
    cashOnEvent: "Paiements en espèces d'événements",
    selectEvent: 'Veuillez choisir un événement',
    infoExternalEvent: "Les finances d'un événement externe ne sont pas affichées",
    byCategoryType: 'Par type de catégorie',
    byCategory: 'Par catégorie',
    byInscription: 'Par inscription',
    accountBalances: 'Soldes de compte',
    accountBalancesPerRider: 'Soldes de compte par pilote',
    totalForRider: 'Total (du point de vue du pilote: positif: crédit, négatif: paiements ouverts)',
    filter: 'Filtre',
    riderHasCredit: 'Le pilote a un crédit',
    riderOwsMoney: "Le pilote doit de l'argent",
    balancingAccounts: 'Comptes équilibrés',
    licenseBookingsPerRider: 'Commandes de licences par pilote',
    necessaryBookings: 'Réservations nécessaires',
    createdBookings: 'Réservations créées',
    recalculatePaymentStatus: "Recalculer l'état de paiement",
    deleteLicenseBooking: (bookingName: string) => `Supprimer la commande de licence ${bookingName}`,
    totalCreditOrganization: "Total (crédit de l'organisation)",
    totalOpenPayments: 'Ouvert (paiements en suspens)',
    overwritePaymentDate: (date: string) => `La date de paiement sera écrasée avec ${date}`,
    paymentDateAlert:
      "La date de paiement des réservations n'est pas modifiée, la date de réservation dans le XML est fixée au jour ouvrable suivant",
    summarizedPositionsForBilling: 'Positions résumées pour la facturation',
    positionsForBilling: 'Positions pour la facturation',
    recalculatePaymentStatusAlert:
      "Recalculer l'état de paiement si l'état de paiement ne correspond pas au solde du compte. Cela sera calculé automatiquement à l'avenir. Cela prend quelques secondes.",
    addFineButtonText: 'Ajouter une amende',
    addFineTitle: 'Ajouter une amende',
    fine: 'Amende',
    bookingsOtherAssociations: "Inscriptions d'autres associations",
    deleteBill: (transactionTitle: string) => `Supprimer la facture ${transactionTitle}`,
    reopenBill: 'Rouvrir la facture',
    deleteBooking: (transactionName: string) => `Supprimer l'incription ${transactionName}`,
    removeQrPayment: (transactionReference: string) =>
      `Supprimer le paiement QR ${transactionReference} de l'utilisateur`,
    deleteQrPayment: (transactionReference: string) =>
      `Supprimer le paiement QR ${transactionReference}`,
    qrPaymentNotice: "Le paiement QR a été attribué manuellement => annuler l'attribution",
    deletePaymentNotice:
      "Le paiement sera complètement supprimé comme si l'importation n'avait pas eu lieu",
    deleteAssignment: 'Supprimer / annulation',
    deletePayment: (paymentTitle: string) => `Supprimer ${paymentTitle}`,
    desiredDate: 'Date souhaitée',
    requestedByUid: 'Demandé par IDE',
    paidByUid: 'Payé par IDE',
  },
  accountingGroups: {
    sumNames: {
      open: 'Ouvert',
      inscription: "Frais d'inscription",
      donation: 'Dons',
      yearLicense: 'Frais de licence annuelle',
      dayLicense: 'Frais de licence journalière',
      transponder: 'Transpondeurs vendus',
      automaticPayment: 'Paiements automatiques',
      manualBooking: 'Comptabilisations manuelles',
      manualPayment: 'Paiements manuels',
      associationPayment: "Paiements à l'association",
    },
  },
  multipleEvents: 'Plusieurs manifestations',
  showOldTransactions: 'Afficher les anciennes transactions',
  overviewBox: {
    personalDataCompleted: 'Données personnelles complétées',
    requestLicense: (year: number) => `Remplir la demande de licence ${year}`,
    licenseRequestCompleted: 'Demande de licence remplie',
    payMemberFees:
      "Payer la cotisation SAM (tu as reçu une facture par poste). Le virement par facture dure quelques jours jusqu'à ce qu'il soit débité sur ton compte.",
    samMembershipPaid: 'Adhésion au SAM est payée',
    payBills:
      'Payer les factures ouvertes (voir sous "finance"). Le virement par facture prend quelques jours avant d\'être crédité sur ton compte.',
    noOpenLicenseFees: 'Pas de facture de licence ouverte',
    addHealthCheck: 'Remplir le bilan de santé',
    healthCheckCompleted: 'Bilan de santé terminé',
    addInsuranceConfirmation: "Remplir la confirmation d'assurance",
    insuranceConfirmationUploaded: 'Télécharger la confirmation de licence',
    uploadLicensePhoto: 'Saisir la photo',
    licensePhotoUploaded: 'Télécharger la photo',
    bikeAdded: 'Moto ajoutée',
    adminConfirmationPending: "Confirmation administrative en attente / sur la liste d'attente",
    adminConfirmationDone: "Confirmé par l'administrateur",
    inscriptionSubmitted: 'Inscription soumise',
    inscriptionNotSubmitted: 'Inscription pas encore soumise',
    inscriptionPaid: 'Inscription payée',
  },
  inscriptionBox: {
    tasksNotDone: "Tâches pas encore terminées (vue d'ensemble)",
    licenseNotApproved: 'Licence pas encore approuvée',
    showAllCategories: 'Afficher toutes les catégories et disciplines',
    showAllCategoriesPerSportEvent: "Demande d'une licence journalière / inscription en catégorie club",
    hideAllCategoriesPerSportEvent: 'Masquer les licences journalières / inscriptions en catégorie club',
    showInscriptions: 'Afficher les inscriptions',
    search: 'Rechercher (événement, association, catégorie, ...)',
    inscriptionPending:
      "* INSCRIT PROVISOIREMENT: merci de vous manifester lors de l'inscription: pas encore confirmé, ou conditions pas encore remplies (par exemple, paiement pas encore reçu)",
  },
  otherSummary: 'Autres',
  licensesBoxes: {
    invalidatedLicense: {
      title: 'Licence invalide',
      explanation:
        "La licence a été déclarée invalide par l'association. Pour toute question, veuillez contacter l'association.",
    },
    showLicensesBefore: (year: number) => `Afficher les licences avant ${year}`,
    hideLicensesBefore: (year: number) => `Masquer les licences avant ${year}`,
    approvedLicensesTitle: 'Demandes de licence confirmées',
    draftLicensesTitle: 'Licences demandées',
    preferredNumber: 'Numéro souhaité',
    definitiveIssuedNumber: 'Numéro de départ définitif',
    provisionallyIssuedNumber: 'Numéro de départ provisoire',
    sidecarPartner: 'Partenaire Sidecar',
    unknownCategory: 'Catégorie inconnue',
    requestNewLicense: (licenseYear: number) => `Demander une licence(s) pour ${licenseYear}`,
    requestAdditionalLicense: (licenseYear: number) => `Demander plus de licence(s) pour ${licenseYear}`,
    requestLicenseOfDifferentYear: (licenseYear: number) =>
      `Mauvaise année? Demander une licence pour ${licenseYear}`,
    downloadLicense: 'Télécharger la licence',
    licenseConfirmation: 'Confirmation de licence',
    download: {
      license: 'Télécharger la licence',
      pitLanePass: 'Télécharger le pass pour les boxs',
    },
    emergencyDetails: "Détails d'urgence",
    myLaps: 'Chronométrage',
    organizerList: "Liste d'organisateurs",
    search: "Rechercher (nom, catégorie, #7, numéro d'adhésion, confirmé / nouveau,",
    setSearchInfo: 'Les affectations sont en cours, peut prendre quelques minutes ...',
    assignmentInProgress: "L'affectation est en cours d'exécution...",
    assignLicenseId: 'Affecter les identifiants de licence',
    importLicenses: 'Importation des licences',
    licensesToImport: 'Licences à importer',
    notFound: 'Pas trouvé:',
    overwrite: 'Écraser',
  },
  personalDataBox: {
    title: 'Données personnelles',
    samMembershipNumber: 'Numéro de membre SAM',
    createPersonalData: "Établir les données d'une personne",
    changeEmail: {
      title: "Changer l'e-mail",
      tryAgain: 'Essayer une nouvelle fois',
      saving: 'Mémoriser',
      startEmailChangeTitle: 'Lien de confirmation envoyé',
      startEmailChangeBefore: 'Nous avons envoyé un e-mail à',
      startEmailChangeAfter:
        '. Veuillez confirmer ce mail pour lancer le changement de courrier électronique.',
      emailChangedTitle: 'Changer le lien de courrier électronique envoyé',
      emailChangedBefore: 'Nous avons envoyé un e-mail à',
      emailChangedAfter: ". Veuillez confirmer l'e-mail pour compléter le changement.",
      securityHint:
        "Pour une raison de sécurité, vous devez confirmer l'action afin de modifier votre email.",
      confirmationEmailSent: 'Lien de confirmation envoyé',
      confirmWithGoogle: 'Confirmer avec Google',
      confirmWithFacebook: 'Confirmer avec Facebook',
    },
  },
  documentBox: {
    title: 'Documents',
    licensePhoto: 'Photo',
    contactOnAccident: "Données d'urgence: contact en cas d'accident",
    emergencyDataMissing: 'Formulaire de premiers secours: pas encore rempli',
    healthCheck: 'Bilan de santé',
    insuranceConfirmation: "Confirmation d'assurance",
    driversLicense: 'Permis de conduire',
  },
  driversLicense: {
    driversLicenseId: 'Numéro de permis de conduire',
  },
  optionalMessage: {
    title: 'Information facultatives',
    messagePart1: 'Sur la base de votre licence(s), ces informations sont',
    messagePart2Highlighted: 'facultatives',
    messagePart3:
      '. Vous pouvez toujours entrer les informations ici si vous souhaitez participer à un événement pour lequel ces informations sont nécessaires',
  },
  transactions: {
    name: "Transactions (crédits, contraintes, paiements, réservations de licences, réservations d'inscription)",
    transactionsByType: 'Transactions par type de réservation',
    downloadBill: 'Télécharger la facture',
    license: 'Demande de licence',
    inscription: 'Inscription',
    inscriptionDayLicense: "Frais d'inscription (licence journalière)",
    inscriptionDayCategory: "Frais d'inscription (manifestation individuelle)",
    power: 'Électricité',
    donation: 'Donation',
    inscriptionDiscount: 'Remise pour 2 jours',
    mainLicenseName: 'Licence',
    additionalLicenseName: 'Licence supplémentaire',
    discountName: "Rabais jusqu'à",
    surchargeName: 'Surtaxe de',
    reverse: 'Annulé:',
  },
  emergencyDetails: {
    title: 'Formulaire de premiers secours',
    titlePlural: "Fiches d'urgence",
    editEmergency: 'Modifier le formulaire de premiers secours',
    addEmergencyDetails: 'Remplir le formulaire de premiers secours',
    checkEmergencyDetails: 'Vérifier le formulaire de premiers secours',
    emergencyDetailsChecked: 'Formulaire de premiers secours vérifé',
    emergencyDetailsCompleted: 'Compléter des détails du formulaire de premiers secours',
    emergencyConfirmation: {
      label:
        "Je confirme que je suis suffisamment assuré en cas d'accident, que j'ai lu le Règlement des courses SAM et que je les accepte. Je renonce expressément, en cas d'accident et /ou dommage, de rendre responsable la fédération SAM, respectivement ses commissions et fonctionnaires, l'organisateur ainsi que les autres pilotes resp. leurs accompagnants ou proches pour toute prestation. Je reconnais que c'est ma propre responsabilité de m'assurer de manière privée contre tous les événements dommageables possibles ou de prendre sur moi la totalité du risque.",
      validation: 'Les conditions doivent être acceptées afin de participer à des courses',
    },
    seeEmergencyDetails: "Afficher les données d'urgence",
    dataProtectionHint:
      'Cet accès est enregistré pour des raisons de protection des données. Vraiment ouvrir?',
  },
  bikesBox: {
    frameNumber: 'Numéro de châssis',
    bikeMissing:
      "Aucune moto n'est enregistrée. Pour une course, tu dois enregistrer ta moto. Pour l'instant, seuls les motos pour Motocross et Supermoto peuvent être enregistrées - le reste suivra.",
    deleteBike: 'Supprimer la moto',
    addNewBike: 'Enregistrer une nouvelle moto',
  },
  delete: 'Effacer',
  assign: 'attribuer',
  newMember: 'Nouveau membre',
  previousLicenses: "Licences de l'année dernière",
  buttons: {
    edit: 'Éditer',
    add: 'Ajouter',
    editEmail: "Changer l'e-mail",
    cancel: 'Annuler',
    close: 'Terminer',
    back: 'Retour',
    continue: 'Continuer',
    save: 'Sauvegarder',
    saving: 'Est mémorisé',
    saveLicenseBooking: 'Enregistrer la commande de licences',
    changeEmail: "Changer l'e-mail",
    addObject: (object: string) => `Créer ${object}`,
    addObjectOptional: (object: string) => `Créer ${object} (facultatif)`,
    editObject: (object: string) => `Modifier ${object}`,
    editObjectOptional: (object: string) => `Modifier ${object} (facultatif)`,
    deleteButtonConfirmation: 'Vraiment supprimer?',
  },
  alerts: {
    dataSaved: 'Données sauvegardées',
    errorSaving: 'Erreur lors de la mémorisation',
    unknownError: 'erreur inconnue',
  },
  after: 'après',
  licenseForm: {
    tooEarly: {
      title: 'Vous êtes trop tôt',
      message:
        "La demande de licence n’est pas encore ouverte. Veuillez réeessayer plus tard. Suivez-nous sur les réseaux sociaux pour être informé de l'ouverture.",
    },
    mxrsSamFusionInfoP1:
      'Le MXRS et le SAM Sport ont fusionnés à la fin de 2022. Pour la saison 2023, un nouveau championnat National Amateur Motocross Series (NAM) sera lancé, où les catégories MXRS et SAM seront fusionnées en nouvelles catégories.',
    mxrsSamFusionInfoP2:
      "Si vous n'êtes pas encore membre d'une section SAM, vous pouvez vous joindre à une section existante ou vous inscrire à la nouvelle section MXRS (ce qui suivra dans une prochaine étape dans la demande de licence).",
    mxrsSamFusionInfoP3:
      "Les organisateurs dans l'arrière-plan restent les mêmes. De plus, lors de la commande d'une licence SAM, vous profitez d'une assurance additionnelle - il se peut que vous n'ayez plus besoin de votre assurance complémentaire existante.",
    mxrsSamFusionInfoP4:
      "Nous nous réjouissons d'une saison 2023 réussie, conjointement avec les organisateurs de l'ancien MXRS et de la nouvelle série NAM du SAM.",
    mxrsSamFusionInfoP5: 'Pour ce faire, demandez une licence SAM.',
    requestTransponders: 'Acheter des transpondeurs',
    requestLicenses: 'Demande de licences',
    requestLicense: 'Demande de licence',
    total: 'Le total',
    insuranceTerms: "Détails sur l'assurance-accidents de course",
    searchCategories: 'Catégorie de recherche (par exemple MX2, "Youngster 85", Vintage 50+, ...)',
    searchCategoriesMin3Letters:
      'Catégorie de recherche, min. 3 lettres (par exemple MX2, "Youngster 85", Vintage 50+, ...)',
    typeAtLeast3Letters: "Tapez au moins 3 lettres pour ajouter d'autres catégories",
    advertiseSamInsurance: "Couverture d'assurance chez Sam",
    title: 'Demande de licence',
    price: 'Prix',
    steps: {
      personalData: {
        title: 'Données personnelles',
        samSectionChange: 'Changement de section SAM',
      },
      lastSeason: 'La saison dernière',
      categories: {
        title: 'Catégories',
        includingInsurance: "Inclus la couverture d'assurance complémentaire",
      },
      transponder: 'Transpondeur',
      additionalInfo: "Plus d'informations",
      summary: {
        title: 'Résumé',
        noBirthdateFound:
          'Date de naissance non entrée, veuillez compléter vos informations personnelles',
        openCreditsAndDebits:
          'Tous les crédits ou amendes encore ouvertes ne sont pas encore pris en considération',
        category: 'Catégorie',
        preferredNumber: 'Numéro de départ souhaité',
        priceChf: 'Prix ​​CHF',
        conclusion: 'Conclusion',
        alertTitleLicenseYear: (previousYear: number) =>
          `Attention: Demande de licence saison ${previousYear}`,
        alertBodyLicenseYear: (nextYear: number, previousYear: number) =>
          `Les licences pour la saison ${nextYear} ne sont pas encore en ligne. Cette demande de licence n'est valable que pour la saison ${previousYear}.`,
      },
      bikes: {
        title: 'Ajouter la moto pour Motocross/Supermoto/Minibike',
        alertTitleOtherCategoryTypes:
          'Les autres disciplines, outre Motocross, Supermoto et Minibike, seront ajoutées ultérieurement, avec des champs différents correspondant aux disciplines',
        alertBodyOtherCategoryTypes:
          "Ce formulaire n'est actuellement disponible que pour les types de catégorie Motocross, Supermoto et Minibiike, car les premières courses sont prévues pour ces types de catégories. Le formulaire d'autres types de catégories sera en ligne avant leur première course respective.",
        bikeAdded: 'Ajouter la moto',
        bikeNotSaved: "La moto n'a pas pu être mémorisée",
        saveBike: 'Mémoriser la moto',
      },
    },
  },
  licenseRequest: {
    title: 'Demande de licence',
    licenseRequestSent: 'Demande de licence envoyée',
    licenseRequestSentHint:
      "Nous avons reçu votre demande de licence et nous la traiterons bientôt. Tu peux payer ici la licence, dès que nous l'avons examinée et le paiement est autorisé. Tu reçois encuite un mail de notre part comme rappel.",
  },
  emails: {
    greeting: 'Bonjour',
    bestWishes: 'Meilleurs vœux',
    signature: 'Votre équipe de Racemanager',
    licenseRequestEmail: {
      title: 'Demande de licence reçue',
      text: "Merci pour votre demande de licence. Nous traiterons votre demande et dès qu'elle aura été acceptée, votre demande sera confirmée et la facture correspondante sera émise. Veuillez consulter votre compte de temps en temps pour vérifier l'état de votre licence.",
    },
    paymentReadyEmail: {
      oneLicenseConfirmedTitle:
        'Nous avons approuvé votre demande de licence et avons confirmé votre numéro',
      severalLicensesConfirmedTitle: 'On a',
      oneLicenseConfirmedNextFewDaysBefore: 'La licence',
      oneLicenseConfirmedNextFewDaysMiddle: 'non confirmé',
      oneLicenseConfirmedNextFewDaysAfter: 'devrait être confirmée dans les prochains jours.',
      multipleLicensesConfirmedNextFewDaysBefore: 'Tes licences',
      multipleLicensesConfirmedNextFewDaysMiddle: 'non confirmé',
      multipleLicensesConfirmedNextFewDaysAfter:
        'sont censées être confirmées dans les prochains jours.',
      insuranceInformation:
        "Le début de la couverture d'assurance sera la date de paiement. À partir de ce jour, la couverture d'assurance durera 1 an, mais au maximum jusqu'à la fin février de l'année suivante. Plus d'information:",
      payLicenseBefore:
        'Pour obtenir ta licence, tu dois la payer. Tu peux le faire sur cette plate-forme',
      payLicenseAfter: '.',
      numberInformation:
        "Dans la saison 2022, les chiffres sur les motos doivent être conformes au Règlement sur Racemanager. Vous pouvez commander une conception qui est conforme aux règles de Racing de Backyard. Pour plus d'informations et pour commander votre décor, cliquez ici:",
      checkCurrentStatus:
        "Vous pouvez accéder à l'état actuel de vos données et l'état de votre demande de licence sur la plate-forme à tout moment.",
      fillInEmergencyDetails:
        "En prochaine étape, vous pouvez remplir le formulaire de détail d'urgence. Vous le trouverez sur la plate-forme également.",
      number: 'Numéro de départ',
      paymentLicenseRequestDueEndSingular: 'Demande de licence de paiement dû',
      paymentLicenseRequestDueDateEndPlural: 'Demandes de licence de paiement dus',
    },
  },
  licenseTasks: {
    names: {
      personalData: 'Données personnelles manquantes',
      licenseSubmitted: 'Demande de licence non envoyée',
      memberFees: 'Adhésion SAM non payée',
      licenseFees: 'Licence non payée',
      checkEmergency: "Données d'urgence non vérifées",
      emergency: "Données d'urgence non entrées",
      healthCheck: 'Bilan de santé non téléchargée',
      insurance: "Confirmation d'assurance non téléchargée",
      photo: 'Photo de profil non téléchargée',
      inscriptionPayment: "Frais d'inscription non payé",
      adminConfirmationPending:
        "Pas encore confirmé par l'administrateur / organisateur, et possiblement en liste d'attente",
      bike: 'Aucune moto enregistrée',
      inscriptionSubmitted: 'Inscription pas envoyée',
    },
    shortNames: {
      personalData: 'Pers.',
      licenseSubmitted: 'Lic.env.',
      memberFees: 'Cot.memb.',
      licenseFees: 'LicUnp.',
      emergency: 'Urg.info.',
      checkEmergency: 'Urg.val.',
      healthCheck: 'Sant.chk.',
      insurance: 'Assu.',
      photo: 'Photo',
      inscriptionPayment: 'NonPay.',
      adminConfirmationPending: 'AdmUnc.',
      bike: 'Moto',
      inscriptionSubmitted: 'Insc.',
    },
    pdfTasks: {
      emergency: "Données d'urgence manquantes",
      checkEmergency: "Données d'urgence non vérifiées",
      healthCheck: 'Bilan de santé manquant',
      insurance: "Confirmation d'assurance manquante",
      licenseFees: 'Licence non payée',
      licenseSubmitted: 'Demande de licence non envoyée',
      personalData: 'Données personnelles manquantes',
      memberFees: 'Adhésion SAM non payée',
      photo: 'La photo de profil est manquante',
      inscriptionPayment: "Frais d'inscriptions non payés",
      adminConfirmationPending:
        "Pas encore confirmé par l'administrateur / organisateur, et possibilité d'être sur la liste d'attente",
      bike: 'Moto manquante',
      inscriptionSubmitted: 'Inscription non envoyée',
    },
  },
  licensePdf: {
    valid: 'Valide',
    invalid: 'Invalide',
    validUntil: "Date d'expiration",
    notValidYet: 'Pas encore valide',
    license: 'Licence',
    togetherWithIdOrPassport: "(avec carte d'identité ou passeport)",
    pitLane: 'Pit Lane',
    pitLaneDownload: 'Pass pour les boxs',
    notValidForEntry: "Gilt nicht als Eintritt. N'est pas une entrée valid. Not valid for entry.",
    rider: 'Pilote',
    transponder: 'Transpondeur',
    trainingOnly: 'Valid: entrainements uniquement',
    validNationalOnly: 'Valide: national uniquement',
    validInternational: 'Valide: national / international',
    samDisclaimer:
      "ASSURANCE MEDICALE: Le détenteur d'une licence SAM est assuré pour les traitements médicaux selon le réglement SAM",
    fmsInternationalDisclaimerTitle: 'STARTING PERMISSION / AUTORISATION DE DÉPART',
    fmsInternationalDisclaimer:
      "Une AUTORISATION DE DÉPART est accordée au détenteur d'une licence de pilote/passager FIM pour manifestations internationales pour les disciplines mentionnées au recto de la licence lors de toute manifestation approuvée par une fédération membre de la FIM/FIM Europe. Il/elle est assuré(e) en vue de traitement médical et rapatriement conformément aux règlements de la FIM/FIM Europe/Swiss Moto.\nCette AUTORISATION DE DÉPART n'est pas valable pour les championnats et prix FIM/FIM Europe.\nLa licence n’est valable qu'avec la signature du pilote/passager.",
    scanLicense: 'Scanner la licence',
  },
  dayInscription: {
    approved: 'inscrit et confirmé',
  },
  description: 'Description',
  amountInChf: 'Montant en CHF',
  sportsEvent: {
    eventOrCategoryNotFound: 'Événement ou catégorie introuvable',
    editSportEvent: (sportEvent: string) => `Modifier ${sportEvent}`,
    sportEventsAdminForm: {
      categoryGroups: 'Nombre de groupes (1 si vide) / max. pilotes par groupe (optional)',
      categoryGroupCounts: 'Nombre de groupes',
      categoryGroupSizes: 'Pilotes par groupe',
    },
    errorsOccured: 'Les erreurs suivantes sont survenues',
    newEvent: 'Nouvelle manifestation',
    removeCategoryWarning:
      "Attention: les inscriptions existantes seront automatiquement supprimées lorsqu'une catégorie sera supprimée.",
    changeCategoryDateWarning:
      'Attention: Avec dans certains cas, seul le Superadmin a le droit de modifier certaines inscriptions. Dans ce cas, seul le Superadmin peut modifier la date des catégories.',
    inscriptionWithoutDateNotice:
      "Si la date n'est pas encore connue, les inscriptions sont déjà possibles.",
    changeEventDateNotice:
      "La date des inscriptions sera modifiée automatiquement lorsque la date de l'événement ou de la catégorie sera modifiée",
    referToDeletedOrMovedInscriptionTable:
      'Voir le tableau "Les enregistrements suivants sont déplacés ou supprimés", qui apparaît dès qu\'il y a un changement',
  },
  field: 'Champ',
  import: 'Import',
  system: 'Système',
  action: 'Action',
  documents: 'Documents',
  payments: {
    title: 'Paiements',
    addManualPaymentTitle: 'Ajouter un paiement manuel',
    refund: 'Remboursement',
    addNewManualPayment: (paymentName: string) => `Ajouter un nouveau manuel ${paymentName}`,
    addManualPayment: (paymentName: string) => `Ajouter ${paymentName}`,
    editManualPaymentTitle: 'Modifier le paiement manuel',
    editManualPayment: (paymentName: string) => `Modifier le manuel ${paymentName}`,
    saveManualPayment: (paymentName: string) => `Enregistrer ${paymentName}`,
    renameTag: (tag: string) => `Renommer tag ${tag}`,
    renameTagTo: (tag: string, newTag: string) => `Renommer tag ${tag} à ${newTag}`,
    renameTagWarning: (tag: string, association: string) =>
      `Attention: Tous les paiements de chaque année avec le tag ${tag} de l'association ${association} seront renommés.`,
    sum: 'Total',
    invalidPayments: 'Paiements non valides',
    noInvalidPaymentsFound: 'Aucun paiement non valide trouvé',
    deleteQrPayment: 'Supprimer le paiement QR',
    invalidPayment: 'Paiement non valide',
    searchPayments:
      "Recherche (nom, numéro d'adhésion, code postal, emplacement, mg payé / mg-off / QR payé / QR-Open)",
    bankPaymentUpload: 'Upload des paiements bancaires',
  },
  bills: {
    openBills: 'Factures en attente',
    searchBillsLabel:
      'Recherche ("" amount "": 80, / country "": "" de "/ à l\'étranger / numéro de référence / nom / rue / code postal / emplacement)',
    invalidEmptyZipCode: 'Code postal vide non valide',
    invalidSwissZipCode: 'Code postal suisse non valide',
    invalidTooLongZip: 'Code postal trop long non valide',
  },
  admins: {
    title: 'Administrateurs',
    readOnlyAdmins: 'Administrateur en lecture seule',
    adminEmail: 'E-mail (doit être enregistré)',
    addAdmin: "Ajouter l'administrateur",
    addAsAdmin: 'Ajouter comme administrateur',
    confirmAddAdmin: (adminEmail: string) => `Ajouter ${adminEmail} en tant qu'administrateur?`,
    removeAdmin: "Supprimer l'administrateur",
    removeAsAdmin: 'Supprimer comme administrateur',
    confirmDeleteAdmin: (adminEmail: string) => `Supprimer ${adminEmail} en tant qu'administrateur?`,
    associationAdmins: "Administrateurs d'association",
    addAssociationAdmin: (association: string) => `Ajouter admin ${association}`,
    addAsAssociationAdmin: (association: string) => `Ajouter comme ${association} admin`,
    confirmAddAssociationAdmin: (adminEmail: string, association: string) =>
      `Ajouter ${adminEmail} comme ${association} administrateur?`,
    remove: 'Supprimer',
    removeAssociationAdmin: "Supprimer l'administrateur de l'association",
    removeAsAssociationAdmin: "Supprimer comme administrateur d'association",
    confirmRemoveAsAssociationAdmin: (adminEmail: string) =>
      `Supprimer ${adminEmail} en tant qu'administrateur d'association?`,
    disabledForAssociationAdmin:
      "Cette fonctionnalité n'est pas (encore) disponible pour le rôle d'administration d'association.",
    functionalityDeactivated: 'Cette fonctionnalité est désactivée.',
  },
  users: {
    title: 'Utilisateur',
    loadMore: 'Charger plus',
    allUsersLoaded: 'Tous les utilisateurs chargés',
    editEmail: "Modifier l'e-mail",
    newEmailSaved: 'Nouvel e-mail enregistré',
    errorOnSaving: 'Erreur lors de la sauvegarde',
    updateShortId: 'Mettre à jour les UID courts (pour le code QR)',
    duplicatedUsersNotice:
      "Attention: cette liste n'est actuellement pas mise à jour automatiquement, mais est rechargée toutes les 2 minutes (détection active / inactive / inactive de l'utilisateur)",
    searchDuplicatedUsers: "Recherche (nom, lieu, code postal, numéro d'adhésion)",
    updatePersonalData: 'Modifier les données personnelles',
    editUserEmail: (email: string) => `Modifier l'e-mail ${email}`,
    loginAsUser: (user: string) => `Se connecter en tant qu'utilisateur ${user}`,
  },
  uploads: {
    newlyUploaded: 'Nouvellement téléchargé',
    verified: 'Vérifié',
    invalidated: 'Invalidé',
    notUploadedDeleted: 'Non téléchargé / supprimé',
    noteForRider: 'Note pour les pilotes',
    internalNote: 'Note interne',
    uploadedOn: 'Téléchargé sur',
    size: 'Taille',
    type: 'Taper',
    resetToNewlyUploaded: 'Réinitialiser avec un nouvel téléchargement',
    verify: 'Vérifier',
    invalidate: 'Invalide',
  },
  count: 'Comte',
  chf: 'CHF',
  groupAssignments: {
    assignGroups: 'Attribuer des groupes',
    downloadChampionshipStandings:
      'Le score du championnat sera téléchargé, puis les groupes pourront être attribués',
    startGroupAssignment: "Commencer l'attribution du groupe",
    groupSizeAndStatistics: 'Définir la taille du groupe et les statistiques',
    totalInscribed: 'Total (Inscrits)',
    numberOfGroups: 'x pilotes par groupe : nombre de groupes',
    configuredGroupSize: 'Taille de groupe configurée',
    largestGroupInTheory: 'Groupe théoriquement le plus important',
    withConfiguredGroupSize: 'avec taille de groupe configurée',
    deleteGroupAssignments: 'Supprimer toutes les affectations de groupe',
    deleteGroupAssignmentOf: (name: string) => `Supprimer les devoirs de groupe de  ${name}`,
    groupSize: 'Taille de groupe',
    listOfInscriptionsAndGroups: 'Liste des inscrits et leurs groupes',
    moveToHigherGroup: 'Passer au groupe supérieur',
    confirmMoveToHigherGroup:
      "Vraiment passer au groupe supérieur ? Si le groupe n'existe pas déjà, il sera créé",
    moveToLowerGroup: 'Déplacer vers le groupe inférieur',
    confirmMoveToLowerGroup: 'Vraiment passer au groupe inférieur ?',
    ranking: (myLapsName: string) => `Classement ${myLapsName}`,
    noRankingFound:
      'Aucun championnat trouvé - sera attribué au hasard. Alternativement, un classement des numéros de départ (par exemple "23 91 7") peut être spécifié.',
    assignAllGroups: 'Affecter tous les groupes',
    validInscriptionNoticeLong:
      'Seuls les groupes de courriers recommandés valides seront attribués. Les groupes déjà affectés ne seront pas modifiés. Diviser vraiment?',
    validInscriptionNoticeShort:
      'Seuls les groupes de courriers recommandés valides seront attribués. Les groupes déjà affectés ne seront pas modifiés.',
  },
}
