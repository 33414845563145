import { Typography } from '@material-ui/core'
import { t } from 'shared/i18n/current'
import { Photo } from 'shared/models/photo'

interface ShowPhotoProps {
  photo: Photo | undefined
}

export function ShowPhoto({ photo }: ShowPhotoProps) {
  const status = photo?.status || 'notUploaded'
  return (
    <Typography>
      {t().documentBox.licensePhoto}: {t().licensePhoto.status[status]}
    </Typography>
  )
}
