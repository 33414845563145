import { Year } from 'shared/data/license-year'
import { DayCategoryID, InscriptionCategoryID } from 'shared/db/day-category'
import { SportEventId } from 'shared/db/sport-event-id'
import { UserId } from 'shared/db/user-id'
import { AssociationID } from 'shared/models/associations'
import { CategoryId } from 'shared/models/category'
import { TransponderType } from 'shared/models/transponder-type'
import { SportEventStatus } from 'shared/sport-events/sport-event-status'
import { SportEventType } from 'shared/sport-events/sportEventType'

export interface SportEvent {
  id: SportEventId
  startsAt: string
  endsAt: string
  alternativeStartsAt: string
  alternativeEndsAt: string
  name: string
  place: string
  licenseCategoryIds: string | undefined
  dayCategoryIds: string | undefined
  dayCategoryNamesForSearch: string
  licenseCategoryDates: Partial<Record<CategoryId, string[]>> | undefined
  dayCategoryDates: Record<DayCategoryID, string[]> | undefined
  categoryGroupCounts: Record<StartingListNameAndDateLookup, number> | undefined
  categoryGroupSizes: Record<StartingListNameAndDateLookup, number> | undefined
  disabledInscriptions: Record<DayCategoryID | CategoryId, boolean> | undefined
  status: SportEventStatus
  createdBy: string
  createdAt: string
  links: Record<string, SportEventLink> | undefined
  offersPower: boolean
  association: AssociationID
  year: Year
  transponderType: TransponderType
  cancelled: boolean
  finalized: boolean
  suggestedDonationAmount: number
  sportEventType?: SportEventType
}

export type StartingListNameAndDateLookup = string

export interface SportEventLink {
  name: string
  url: string
  type: 'live-timing' | 'sam-website' | 'organizer-website' | 'sam-tv' | 'other'
}

export interface InscriptionV1 {
  uid: UserId
  sidecarPartner?: string
  category: CategoryId
  sportEvent: string
  createdAt: string
  bikeIds: string[] | undefined
  type: 'enlisted' | 'unlisted'
  paid: boolean
  association: AssociationID
  manuallyVerified: boolean
}

// should be this, but leads to strange typing issues (typescript 4.4)...? export type Inscription = UnlistedLicenseInscription | EnlistedInscription
export type Inscription =
  | UnlistedLicenseInscription
  | UnlistedDayLicenseInscription
  | EnlistedLicenseInscription
  | EnlistedDayInscriptionYearCategoryDraft
  | EnlistedDayInscriptionYearCategory
  | EnlistedDayInscriptionDayCategoryDraft
  | EnlistedDayInscriptionDayCategory
// should be this, but leads to strange typing issues (typescript 4.4)...? export type InscriptionType = Inscription['type']
export type InscriptionType =
  | UnlistedLicenseInscription['type']
  | UnlistedDayLicenseInscription['type']
  | EnlistedLicenseInscription['type']
  | EnlistedDayInscriptionYearCategoryDraft['type']
  | EnlistedDayInscriptionYearCategory['type']
  | EnlistedDayInscriptionDayCategoryDraft['type']
  | EnlistedDayInscriptionDayCategory['type']

export function isEnlistedInscription(inscription: Inscription): inscription is EnlistedInscription {
  return (
    isInscribedInscription(inscription) ||
    inscription.type === 'enlistedDayInscriptionDayCategoryDraft' ||
    inscription.type === 'enlistedDayInscriptionYearCategoryDraft'
  )
}

export type UnlistedInscription = UnlistedLicenseInscription | UnlistedDayLicenseInscription

export type EnlistedInscription =
  | EnlistedLicenseInscription
  | EnlistedDayInscriptionYearCategoryDraft
  | EnlistedDayInscriptionYearCategory
  | EnlistedDayInscriptionDayCategoryDraft
  | EnlistedDayInscriptionDayCategory

export function isInscribedInscription(inscription: Inscription): inscription is InscribedInscription {
  return (
    inscription.type === 'enlistedDayInscriptionDayCategory' ||
    inscription.type === 'enlistedDayInscriptionYearCategory' ||
    inscription.type === 'enlistedLicenseInscription'
  )
}

export type InscribedInscription =
  | EnlistedLicenseInscription
  | EnlistedDayInscriptionYearCategory
  | EnlistedDayInscriptionDayCategory

export function isDayInscription(inscription: Inscription): inscription is DayInscription {
  return (
    isDayInscriptionYearCategoryInscriptionOrDraft(inscription) ||
    isDayInscriptionDayCategoryInscriptionOrDraft(inscription)
  )
}

export function isDayInscriptionYearCategoryInscriptionOrDraft(
  inscription: Inscription | undefined
): inscription is EnlistedDayInscriptionYearCategory | EnlistedDayInscriptionYearCategoryDraft {
  return (
    inscription?.type === 'enlistedDayInscriptionYearCategory' ||
    inscription?.type === 'enlistedDayInscriptionYearCategoryDraft'
  )
}

export function isDayInscriptionDayCategoryInscriptionOrDraft(
  inscription: Inscription | undefined
): inscription is EnlistedDayInscriptionDayCategory | EnlistedDayInscriptionDayCategoryDraft {
  return (
    inscription?.type === 'enlistedDayInscriptionDayCategory' ||
    inscription?.type === 'enlistedDayInscriptionDayCategoryDraft'
  )
}

export function isUnlistedInscription(
  inscription: Inscription | undefined
): inscription is UnlistedDayLicenseInscription | UnlistedLicenseInscription {
  return (
    inscription?.type === 'unlistedLicenseInscription' ||
    inscription?.type === 'unlistedDayLicenseInscription'
  )
}

export function isLicenseInscription(
  inscription: Inscription | undefined
): inscription is EnlistedLicenseInscription {
  return inscription?.type === 'enlistedLicenseInscription'
}

export type YearCategoryInscription =
  | UnlistedLicenseInscription
  | EnlistedLicenseInscription
  | EnlistedDayInscriptionYearCategoryDraft
  | EnlistedDayInscriptionYearCategory

export function isYearCategoryInscription(
  inscription: Inscription
): inscription is YearCategoryInscription {
  return (
    inscription.type === 'enlistedLicenseInscription' ||
    inscription.type === 'unlistedLicenseInscription' ||
    inscription.type === 'enlistedDayInscriptionYearCategory' ||
    inscription.type === 'enlistedDayInscriptionYearCategoryDraft'
  )
}

export type DayCategoryInscription =
  | EnlistedDayInscriptionDayCategoryDraft
  | EnlistedDayInscriptionDayCategory

export function isDayCategoryInscription(
  inscription: Inscription
): inscription is DayCategoryInscription {
  return (
    inscription.type === 'enlistedDayInscriptionDayCategory' ||
    inscription.type === 'enlistedDayInscriptionDayCategoryDraft'
  )
}

export type DayInscription =
  | EnlistedDayInscriptionYearCategory
  | EnlistedDayInscriptionYearCategoryDraft
  | EnlistedDayInscriptionDayCategory
  | EnlistedDayInscriptionDayCategoryDraft

export interface UnlistedLicenseInscription {
  // TODO: later: migrate 'unlisted' to 'unlistedLicenseInscription'
  type: 'unlistedLicenseInscription'
  uid: UserId
  byUid: UserId
  date: string
  year: Year
  category: CategoryId
  sportEvent: string
  createdAt: string
  updatedAt: string
  paid: boolean
  bikeIds?: undefined
  sidecarPartner?: undefined
  remarksRider?: string
  remarksAdmin?: string
  borrowedTransponder?: string | number
  group?: undefined | null
}

export interface UnlistedDayLicenseInscription {
  type: 'unlistedDayLicenseInscription'
  uid: UserId
  byUid: UserId
  date: string
  year: Year
  category: CategoryId | DayCategoryID
  sportEvent: string
  createdAt: string
  updatedAt: string
  paid: boolean
  bikeIds?: undefined
  sidecarPartner?: undefined
  remarksRider?: string
  remarksAdmin?: string
  borrowedTransponder?: string | number
  group?: undefined | null
}

export interface EnlistedLicenseInscription {
  // TODO: later: migrate 'enlisted' to 'enlistedLicenseInscription'
  type: 'enlistedLicenseInscription'
  uid: UserId
  byUid: UserId
  date: string
  year: Year
  category: CategoryId
  sportEvent: string
  remarksRider: string
  remarksAdmin: string
  createdAt: string
  updatedAt: string
  bikeIds: string[] | undefined
  paid: boolean
  association: AssociationID
  manuallyVerified: boolean
  sidecarPartner?: string
  borrowedTransponder?: string | number
  group?: InscriptionGroup
  bikeMake?: string
  teamName?: string
}

export interface EnlistedDayInscriptionYearCategoryDraft {
  type: 'enlistedDayInscriptionYearCategoryDraft'
  uid: UserId
  byUid: UserId
  date: string
  year: Year
  category: CategoryId
  sportEvent: SportEventId
  remarksRider: string
  remarksAdmin: string
  createdAt: string
  updatedAt: string
  bikeIds: string[] | undefined
  sidecarPartner?: string
  borrowedTransponder?: string | number
  association: AssociationID

  preferredNumber: string
  paid: boolean
  group?: undefined | null
  bikeMake?: string
  teamName?: string
}

export interface EnlistedDayInscriptionYearCategory {
  type: 'enlistedDayInscriptionYearCategory'
  uid: UserId
  byUid: UserId
  date: string
  year: Year
  category: CategoryId
  sportEvent: SportEventId
  remarksRider: string
  remarksAdmin: string
  createdAt: string
  updatedAt: string
  bikeIds: string[] | undefined
  sidecarPartner?: string
  borrowedTransponder?: string | number
  association: AssociationID

  preferredNumber: string
  issuedNumber: number
  approvedAt: string
  approvedBy: string
  manuallyVerified: boolean
  paid: boolean
  group?: InscriptionGroup
  bikeMake?: string
  teamName?: string
}

export interface EnlistedDayInscriptionDayCategoryDraft {
  type: 'enlistedDayInscriptionDayCategoryDraft'
  uid: UserId
  byUid: UserId
  date: string
  year: Year
  category: DayCategoryID
  sportEvent: SportEventId
  remarksRider: string
  remarksAdmin: string
  createdAt: string
  updatedAt: string
  bikeIds: string[] | undefined
  sidecarPartner?: string
  borrowedTransponder?: string | number
  association: AssociationID
  // association: IndependentAssociationID

  preferredNumber: string
  paid: boolean
  group?: undefined | null
  bikeMake?: string
  teamName?: string
}

export interface EnlistedDayInscriptionDayCategory {
  type: 'enlistedDayInscriptionDayCategory'
  uid: UserId
  byUid: UserId
  date: string
  year: Year
  category: DayCategoryID
  sportEvent: SportEventId
  remarksRider: string
  remarksAdmin: string
  createdAt: string
  updatedAt: string
  bikeIds: string[] | undefined
  sidecarPartner?: string
  borrowedTransponder?: string | number
  association: AssociationID
  // association: IndependentAssociationID

  preferredNumber: string
  issuedNumber: number
  approvedAt: string
  approvedBy: UserId
  manuallyVerified: boolean
  paid: boolean
  group?: InscriptionGroup
  bikeMake?: string
  teamName?: string
}

export interface PublicInscription {
  uid: UserId
  category: InscriptionCategoryID
  categoryName: string
  sportEvent: SportEventId
  date: string
  year: Year
  name: string
  bike: string
  transponder1: number | string
  transponder2: number | string
  sponsor: string
  section: string
  association: AssociationID
  // association: AssociationID | IndependentAssociationID
  domicile: string
  startingNumber: number | string
  nationality: string
  tires: string
  inscribed: boolean | undefined
  group?: InscriptionGroup
}

export type InscriptionGroup = number | undefined | null

export function publicInscriptionID(inscription: PublicInscription) {
  return `${inscription.sportEvent}|${inscription.category}|${inscription.uid}|${inscription.date}`
}

export interface PublicInscriptionV1 {
  uid: UserId
  categoryId: CategoryId
  categoryName: string
  sportEventId: string
  name: string
  bike: string
  transponder1: number
  transponder2: number | string
  sponsor: string
  section: string
  association: AssociationID
  domicile: string
  startingNumber: number
  nationality: string
  tires: string
}
