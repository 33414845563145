import { createStyles, FormHelperText, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import { MotocrossBike } from 'shared/models/bike'
import { Dig } from 'shared/utils/tsc'

interface MotocrossBikeFormProps {
  initialValues?: MotocrossBike
  nextLabel: string
  onSubmit: (data: MotocrossBike) => Promise<void>
  onBack: () => void
  onCancel: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function MotocrossBikeForm(props: MotocrossBikeFormProps) {
  const classes = useStyles()
  const { initialValues = loadInitialValues(), onBack, onSubmit, nextLabel } = props
  const fields = samFields().motocrossBike

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema()}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, touched, errors }) => (
          <Form>
            <Grid container spacing={1} className={classes.gridParent}>
              <Grid item sm={6} xs={12}>
                {fields.bikeMake.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.bikeModel.field()}
              </Grid>

              <Grid item xs={12}>
                {fields.frameNumber.field()}
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.name.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.teamName.field()}
              </Grid>

              <Grid item xs={12}>
                {fields.acceptBikeTerms.field()}
                {touched.acceptBikeTerms && errors.acceptBikeTerms && (
                  <FormHelperText error>{errors.acceptBikeTerms}</FormHelperText>
                )}
              </Grid>

              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <AccordionNavigationButtons
                nextLabel={nextLabel}
                isSubmitting={isSubmitting}
                onBack={onBack}
                submitForm={submitForm}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

function loadInitialValues(): MotocrossBike {
  const values = Object.fromEntries(
    Object.entries(samFields().motocrossBike).map(([k, v]) => [k, v.default])
  ) as unknown as any
  return { categoryType: 'motocross', ...values } as MotocrossBike
}

function schema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().motocrossBike).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['motocrossBike']>
  return Yup.object().defined().shape(validations)
}
