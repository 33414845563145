import { saveAs } from 'file-saver'
import { uniq } from 'lodash'
import type ExtendedPDF from 'swissqrbill/lib/extended-pdf'
import { PDF } from 'swissqrbill/lib/swissqrbill'
import { addLogo } from 'app/pages/billing/add-association-logo'
import { itemsTotal } from 'shared/billing/billing-service'
import { Bill } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { truthy } from 'shared/utils/array'
import { formatDateDe, parseISO, pFormatDateDe } from 'shared/utils/date'
import { toChf } from 'shared/utils/number'
import { toFilename } from 'shared/utils/string'

export async function generatePDFBill({
  paymentInfo,
  filename,
  items: itemsOrUndefined,
  title,
  date: rawDate,
  paidAt,
}: Bill): Promise<void> {
  const items = itemsOrUndefined || []
  const swissqrbill = (await import('swissqrbill/lib/browser')).default

  const date = parseISO(rawDate)
  const stream = swissqrbill.BlobStream()
  const pdf = new swissqrbill.PDF(paymentInfo, stream, { autoGenerate: false, size: 'A4' })

  const associations = uniq(items.map((item) => item.association).filter(truthy)).sort()
  await addAssociationLogos(pdf, associations)

  pdf.fontSize(14)
  pdf.font('Helvetica-Bold')
  pdf.text(title, swissqrbill.utils.mmToPoints(10), swissqrbill.utils.mmToPoints(45), {
    width: swissqrbill.utils.mmToPoints(190),
    align: 'left',
    paragraphGap: 15,
  })

  pdf.font('Helvetica')
  pdf.fontSize(11)
  pdf.text(`Feusisberg, ${formatDateDe(date)}`, {
    width: swissqrbill.utils.mmToPoints(190),
    align: 'left',
    paragraphGap: 5,
  })

  if (paidAt)
    pdf.text(`${t().financials.paidOn} ${pFormatDateDe(paidAt)}`, {
      width: swissqrbill.utils.mmToPoints(190),
      align: 'left',
      paragraphGap: 25,
    })

  const space: ExtendedPDF.PDFColumn = { text: '', padding: [10, 0, 0, 0], width: 10 }
  const nameOptions = { padding: [10, 0, 0, 0] as [number, number, number, number] }
  const amountOptions = {
    textOptions: { align: 'right' },
    padding: [10, 0, 0, 0] as [number, number, number, number],
    width: 110,
  }
  const table: ExtendedPDF.PDFTable = {
    width: swissqrbill.utils.mmToPoints(190),
    x: swissqrbill.utils.mmToPoints(10),
    rows: [
      {
        font: 'Helvetica-Bold',
        header: true,
        fillColor: '#ecf0f1',
        fontSize: 12,
        height: 30,
        padding: 0,
        columns: [
          space,
          { ...nameOptions, text: t().description },
          { ...amountOptions, text: t().amountInChf },
          space,
        ],
      },
      ...items.map<ExtendedPDF.PDFRow>((item) => ({
        columns: [
          space,
          { ...nameOptions, text: item.name },
          { ...amountOptions, text: toChf(item.price) },
          space,
        ],
      })),
      {
        font: 'Helvetica-Bold',
        header: true,
        fillColor: '#ecf0f1',
        height: 30,
        padding: 0,
        columns: [
          space,
          { ...nameOptions, text: 'Total' },
          { ...amountOptions, text: toChf(itemsTotal(items)) },
          space,
        ],
      },
    ],
  }
  pdf.addTable(table)
  pdf.addQRBill()

  return new Promise<void>((resolve, reject) => {
    try {
      pdf.end()
      pdf.on('finish', () => {
        const blob = stream.toBlob('application/pdf')
        saveAs(blob, toFilename(filename), { autoBom: true })
        resolve()
      })
    } catch (error) {
      reject(error)
    }
  })
}

async function addAssociationLogos(pdf: PDF, associations: (AssociationID | 'racemanager')[]) {
  if (!associations.length) associations.push('racemanager')
  await Promise.all(associations.map((association, index) => addLogo(association, pdf, 25 + 82 * index)))
}
