import { addDays } from 'date-fns'
import { db } from 'app/db/frontend-db'
import { addLogo } from 'app/pages/billing/add-association-logo'
import { Year } from 'shared/data/license-year'
import { associationByID } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { nameWithPlace } from 'shared/models/personal-data'
import { formatDate, formatDateDe, formatDateIt, parseDate } from 'shared/utils/date'
import { downloadPdf, PDFDoc, pdfTextFontSize } from 'utils/pdf/pdf'

export function generateLicenseConfirmation(props: DownloadLicenseConfirmationProps) {
  return downloadPdf({ name: 'bestaetigung' }, (pdf) => generateLicenseConfirmationPdf(props, pdf))
}

async function generateLicenseConfirmationPdf(props: DownloadLicenseConfirmationProps, pdf: PDFDoc) {
  const { category, uid } = props
  const personalData = (await db.loadDocuments({ uid }))?.personalData
  if (!personalData) return

  const today = new Date()
  const validUntil = addDays(today, 30)
  const riderName = nameWithPlace(personalData)
  const birthdate = parseDate(personalData.birthdate)
  const categoryName = category.shortName
  const association = associationByID(category.association)
  const associationName = association.name
  const year = category.year
  const common = { riderName, birthdate, categoryName, validUntil, pdf, associationName, year }

  await addLogo(association.id, pdf)

  pdf.font('Helvetica')
  pdf.fontSize(pdfTextFontSize)
  pdf.moveDown(7)

  pdf.text(`${association.place}, ${formatDateDe(today)}`)
  pdf.moveDown(1)

  confirmation({ ...common, formatDate: formatDateDe, template: dataDe() })
  confirmation({ ...common, formatDate, template: dataEn() })
  confirmation({ ...common, formatDate: formatDateIt, template: dataIt() })
}

function confirmation(props: ConfirmationContents) {
  const {
    pdf,
    riderName,
    birthdate,
    categoryName,
    validUntil,
    formatDate,
    template,
    associationName,
    year,
  } = props
  const formattedBirthdate = formatDate(birthdate)
  const formattedValidUntil = formatDate(validUntil)

  pdf.text('\n')
  pdf.fontSize(17).text(template.title).fontSize(pdfTextFontSize)
  pdf.text('\n')
  const justify = { align: 'justify' }
  pdf.text(
    template.body({
      riderName,
      formattedBirthdate,
      categoryName,
      formattedValidUntil,
      associationName,
      year,
    }),
    justify
  )
  pdf.text('\n')
  pdf.text(template.footer({ associationName }))
  pdf.moveDown(1)
}

function dataDe() {
  return {
    title: 'Lizenzbestätigung',
    body: ({
      riderName,
      formattedBirthdate,
      categoryName,
      formattedValidUntil,
      associationName,
      year,
    }: FormattedProps) =>
      `Hiermit bestätigen wir, dass ${riderName} (${formattedBirthdate}) eine ${associationName}-Lizenz in der Kategorie ${categoryName} für das Jahr ${year} bestellt hat. Diese Bestätigung ist gültig bis am ${formattedValidUntil}.`,
    footer: ({ associationName }: { associationName: string }) =>
      `Die ${associationName}-Sportkommission`,
  }
}

function dataEn() {
  return {
    title: 'License confirmation',
    body: ({
      riderName,
      formattedBirthdate,
      categoryName,
      formattedValidUntil,
      associationName,
      year,
    }: FormattedProps) =>
      `We hereby confirm that ${riderName} (${formattedBirthdate}) has ordered a ${associationName} license for the category ${categoryName} for the year ${year}. This confirmation is valid until ${formattedValidUntil}.`,
    footer: ({ associationName }: { associationName: string }) =>
      `The ${associationName} Sports Commission`,
  }
}

function dataIt() {
  return {
    title: 'Conferma della licenza',
    body: ({
      riderName,
      formattedBirthdate,
      categoryName,
      formattedValidUntil,
      associationName,
      year,
    }: FormattedProps) =>
      `Con la presente certifichiamo che ${riderName} (${formattedBirthdate}) ha ordinato una licenza ${associationName} nella categoria ${categoryName} per l’anno ${year}. Questa conferma è valida fino al ${formattedValidUntil}.`,
    footer: ({ associationName }: { associationName: string }) =>
      `La Commissione Sportiva ${associationName}`,
  }
}

export interface DownloadLicenseConfirmationProps {
  category: CategoryOfAssociation
  uid: string
}

interface FormattedProps {
  riderName: string
  formattedBirthdate: string
  categoryName: string
  associationName: string
  formattedValidUntil: string
  year: Year
}

interface ConfirmationContents {
  riderName: string
  birthdate: Date | undefined
  categoryName: string
  associationName: string
  validUntil: Date
  formatDate: (date: Date | undefined) => string
  pdf: PDFDoc
  template: ReturnType<typeof dataDe>
  year: Year
}
