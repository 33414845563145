import { CanvasRef } from 'app/license/qr-code/canvas-ref'

export async function scanQRCodeFromImageWithZXingLibrary(canvasRef: CanvasRef) {
  try {
    const canvas = canvasRef.current
    if (!canvas) return undefined

    const { BrowserQRCodeReader } = await import('@zxing/browser')
    return new BrowserQRCodeReader().decodeFromCanvas(canvas).getText()
  } catch {
    return undefined
  }
}
