import * as YupLocale from 'yup/lib/locale'

export const yupEN = {
  mixed: YupLocale.mixed,
  string: YupLocale.string,
  number: YupLocale.number,
  date: YupLocale.date,
  object: YupLocale.object,
  array: YupLocale.array,
  boolean: YupLocale.boolean,
}
