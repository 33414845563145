import { AdminDashboard } from 'app/pages/admin/admin-dashboard'
import { UserDashboard } from 'app/pages/dashboard/user-dashboard'
import { useUserContext } from 'app/themes/user-context'
import { User } from 'app/users/user'

export function UserOrAdminDashboard({ user }: { user: User }) {
  const userContext = useUserContext()

  return userContext.adminOrAssociationAdmin ? (
    <AdminDashboard user={user} />
  ) : (
    <UserDashboard user={user} />
  )
}
