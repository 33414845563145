import { createStyles, FormHelperText, Grid, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { FormikErrors } from 'formik'
import * as Yup from 'yup'
import { PersonalDataFieldsSam, isSamMembershipField } from './personal-data-fields-sam'
import { isUnder18, samFields } from 'app/forms/fields'
import { PersonalDataFieldsAfm } from 'app/personal-data/personal-data-fields-afm'
import { PersonalDataFieldsFms } from 'app/personal-data/personal-data-fields-fms'
import { PersonalDataFormData } from 'shared/models/personal-data'

interface PersonalDataFormFieldsProps {
  lockMemberFields: boolean
  disableSamFields?: boolean
  values: PersonalDataFormData
  parentsAgree: string | false | undefined
  prefix: '' | 'personalData.'
  administration?: true
  errors: FormikErrors<PersonalDataFormData> | undefined
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function PersonalDataFormFields(props: PersonalDataFormFieldsProps) {
  const fields = samFields().personalData
  const classes = useStyles()
  const { lockMemberFields, values, parentsAgree, prefix, errors } = props

  return (
    <Grid container spacing={1} className={classes.gridParent}>
      <Grid item sm={6} xs={12}>
        {fields.firstName.field({ prefix, disabled: lockMemberFields })}
      </Grid>
      <Grid item sm={6} xs={12}>
        {fields.lastName.field({ prefix, disabled: lockMemberFields })}
      </Grid>
      {lockMemberFields && (
        <Grid item xs={12}>
          <FormHelperText>{fields.firstName.hintForName}</FormHelperText>
        </Grid>
      )}
      <Grid item md={6} sm={12} xs={12}>
        {fields.street.field({ prefix })}
      </Grid>
      <Grid item md={2} sm={6} xs={12}>
        {fields.zip.field({ prefix })}
      </Grid>
      <Grid item md={4} sm={6} xs={12}>
        {fields.place.field({ prefix })}
      </Grid>
      <Grid item xs={12}>
        {fields.country.field({ prefix })}
      </Grid>
      <Grid item sm={6} xs={12}>
        {fields.phone.field({ prefix })}
      </Grid>
      <Grid item sm={6} xs={12}>
        {fields.birthdate.field({ prefix })}
      </Grid>

      <PersonalDataFieldsAfm prefix={prefix} />

      <PersonalDataFieldsFms prefix={prefix} />

      {!props.disableSamFields && (
        <PersonalDataFieldsSam
          errors={errors}
          lockMemberFields={lockMemberFields}
          values={values}
          prefix={prefix}
        />
      )}

      {isUnder18(values.birthdate) && (
        <>
          <Grid item xs={12}>
            {fields.parentsInfo.field({ prefix })}
          </Grid>

          <Grid item xs={12}>
            {fields.parentsAgree.field({ prefix })}

            {parentsAgree && <FormHelperText error>{parentsAgree}</FormHelperText>}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export function loadInitialPersonalDataValues(): PersonalDataFormData {
  return Object.fromEntries(
    Object.entries(samFields().personalData).map(([k, v]) => [k, v.default])
  ) as unknown as PersonalDataFormData
}

export function personalDataSchema(disableSamFields: boolean) {
  const validations = Object.fromEntries(
    Object.entries(samFields().personalData)
      .filter(([k]) => !disableSamFields || !isSamMembershipField(k))
      .map(([k, v]) => [k, v.validation])
  )
  return Yup.object().defined().shape(validations)
}
