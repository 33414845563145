import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { MutableRefObject, useRef, useState } from 'react'
import { UseDialog, useDialog } from 'app/layouts/confirm-dialog'
import { canScanQRCode, continuouslyScanQRCode, MatchQRCode } from 'app/license/qr-code/scan-qr-code'
import { t } from 'shared/i18n/current'
import { Loading } from 'utils/loading'
import { Disp } from 'utils/react'
import { useErrorSnackbarForError } from 'utils/snackbar'

interface ScanQRButtonProps<T> {
  match: MatchQRCode<T>
  onSuccess: (result: T) => void
  text: string
  primary?: boolean
  fullWidth?: boolean
}

export function ScanQRButton<T>(props: ScanQRButtonProps<T>) {
  const { onSuccess, match } = props
  const showError = useErrorSnackbarForError()
  const dialog = useDialog()
  const [scanning, setScanning] = useState(false)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const stopScanning = useRef<boolean>(false)

  return canScanQRCode() ? (
    <>
      <Button
        variant={props.primary ? 'contained' : 'outlined'}
        color={props.primary ? 'primary' : 'default'}
        fullWidth={props.fullWidth}
        disabled={scanning}
        onClick={async () => {
          stopScanning.current = false
          const scan = await startScanning(
            dialog,
            setScanning,
            canvasRef,
            showError,
            match,
            stopScanning
          )
          if (scan) {
            onSuccess(scan)
            dialog.close()
          }
        }}
      >
        {props.text}
      </Button>

      <Dialog
        maxWidth={'xl'}
        open={dialog.isOpen}
        onClose={() => {
          stopScanning.current = true
          dialog.close()
        }}
      >
        <DialogTitle>{scanning ? t().scanning : t().scanCancelled}</DialogTitle>
        <DialogContent>
          {scanning && <canvas ref={canvasRef} style={{ height: '100%' }} />}
          <Loading loading={scanning} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dialog.close()}>
            {scanning ? t().buttons.cancel : t().buttons.close}
          </Button>
          {!scanning && (
            <Button
              onClick={async () => {
                const scan = await startScanning(
                  dialog,
                  setScanning,
                  canvasRef,
                  showError,
                  match,
                  stopScanning
                )
                if (scan) {
                  onSuccess(scan)
                  dialog.close()
                }
              }}
            >
              {t().tryAgain}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  ) : null
}

async function startScanning<T>(
  dialog: UseDialog,
  setScanning: Disp<boolean>,
  canvasRef: MutableRefObject<HTMLCanvasElement | null>,
  showError: ReturnType<typeof useErrorSnackbarForError>,
  match: MatchQRCode<T>,
  stopScanning: { current: boolean }
): Promise<T | undefined> {
  dialog.open()
  setScanning(true)
  try {
    const result = await continuouslyScanQRCode(stopScanning, match, canvasRef, (bitmap) => {
      const canvas = canvasRef.current
      const context = canvas?.getContext('2d')
      if (!canvas || !context) return
      canvas.width = previewWidth(bitmap)
      canvas.height = previewHeight(bitmap)
      context.drawImage(bitmap, 0, 0, previewWidth(bitmap), previewHeight(bitmap))
    })
    if (!result) throw new Error(t().scanNotCompleted)
    else return result
  } catch (error) {
    showError(error)
    return undefined
  } finally {
    setScanning(false)
  }
}

export function previewHeight(bitmap: ImageBitmap) {
  if (!bitmap.width || !bitmap.height) return 0

  return (previewWidth(bitmap) / bitmap.width) * bitmap.height
}
function previewWidth(bitmap: ImageBitmap) {
  if (!bitmap.width || !bitmap.height) return 0

  return 500
}
