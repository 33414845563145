import { Box, LinearProgress, Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTransponderData } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { TransponderForm } from 'app/license/steps/transponder'
import { toTransponderForm } from 'app/license/steps/transponder-form-fields'
import { User } from 'app/users/user'
import { t } from 'shared/i18n/current'
import { FriendlyError } from 'utils/errors'

export function EditTransponder({ user }: { user: User }) {
  const history = useHistory()
  const { data: transponderData, loading, error } = useTransponderData(user)
  const [onSubmitError, setError] = useState<Error | undefined>()

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().transponders.editTransponder}
        </Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <FriendlyError error={error} />
          <FriendlyError error={onSubmitError} />
          {loading && <LinearProgress />}
          {!loading && (
            <>
              <TransponderForm
                user={user}
                onSubmit={async (data) => {
                  try {
                    setError(undefined)
                    await db.setTransponder(user, data)
                    history.goBack()
                  } catch (error: any) {
                    setError(error)
                  }
                }}
                onBack={() => history.goBack()}
                initialValues={toTransponderForm(transponderData)}
              />
            </>
          )}
        </Box>
      </Paper>
    </>
  )
}
