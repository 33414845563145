import { Link as MaterialLink, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ExitToApp as SignOutIcon, AssignmentInd as SignInIcon } from '@material-ui/icons'
import { forwardRef, useMemo } from 'react'
import { Switch, Link as RouterLink } from 'react-router-dom'
import type { LinkProps as RouterLinkProps } from 'react-router-dom'
import { ChangeAssociationMenuItem } from 'app/layouts/change-association'
import { ChangeYearMenuItem } from 'app/layouts/change-year'
import { combinedAssociationsAndLocales } from 'app/layouts/combined-associations-and-locales'
import {
  RouteWithErrorBoundary,
  RouteWithErrorBoundaryAndTitle,
} from 'app/layouts/route-with-error-boundary-and-title'
import { SetAssociationAndLocale } from 'app/layouts/set-association-and-locale'
import { AdminDashboard } from 'app/pages/admin/admin-dashboard'
import { LicenseBookingsByRider } from 'app/pages/admin/bookings/license-bookings-by-rider'
import { ManualBookings } from 'app/pages/admin/bookings/manual-bookings'
import { AllLicenseBookings } from 'app/pages/admin/financials/all-license-bookings'
import { AllTransactions } from 'app/pages/admin/financials/all-transactions'
import { AssociationFinancials } from 'app/pages/admin/financials/association-financials'
import { TransactionsWithoutDocuments } from 'app/pages/admin/financials/transactions-without-documents'
import { UserFinancials } from 'app/pages/admin/financials/user-financials'
import { InscriptionGroups } from 'app/pages/admin/inscriptions/inscriptionGroups'
import { Inscriptions } from 'app/pages/admin/inscriptions/inscriptions'
import { LonelyTwoDayDiscounts } from 'app/pages/admin/inscriptions/lonely-two-day-discounts'
import { ApprovedLicenses } from 'app/pages/admin/licenses/approved-licenses'
import { AssignLicense } from 'app/pages/admin/licenses/assign-license'
import { CategoriesKeyFigures } from 'app/pages/admin/licenses/categories-key-figures'
import { LicenseDrafts } from 'app/pages/admin/licenses/license-drafts'
import { NewTransponders } from 'app/pages/admin/licenses/new-transponders'
import { ManualPayments } from 'app/pages/admin/manual-payments/manual-payments'
import { AdminMessages } from 'app/pages/admin/messages/admin-messages'
import { DBMigrations } from 'app/pages/admin/migrations/db-migrations'
import { ManuallyProcessLicenseRequest } from 'app/pages/admin/migrations/ManuallyProcessLicenseRequest'
import { InvalidPayments } from 'app/pages/admin/payments/invalid-payments'
import { MemberFees } from 'app/pages/admin/payments/member-fees'
import { Payments } from 'app/pages/admin/payments/payments'
import { SportEventFinancials } from 'app/pages/admin/sport-events/sport-event-financials'
import { SportEvents } from 'app/pages/admin/sport-events/sport-events'
import { AdminManagementContainer } from 'app/pages/admin/users/admin-management-container'
import { DuplicatedUsers } from 'app/pages/admin/users/duplicated-users'
import { EmailConflicts } from 'app/pages/admin/users/email-conflicts'
import { AllUserEvents } from 'app/pages/admin/users/user-events'
import { UserManagement } from 'app/pages/admin/users/user-management'
import { AddBike } from 'app/pages/bike/add-bike'
import { UserDashboard } from 'app/pages/dashboard/user-dashboard'
import { UserOrAdminDashboard } from 'app/pages/dashboard/user-or-admin-dashboard'
import { EditDriversLicense } from 'app/pages/driversLicense/EditDriversLicense'
import { EditEmergency } from 'app/pages/emergency/edit-emergency'
import { EditHealthCheck } from 'app/pages/health-check/edit-health-check'
import { NewInscriptionPage } from 'app/pages/inscription/new-inscription-page'
import { PublicInscriptionsPage } from 'app/pages/inscription/public-inscriptions-page'
import { EditInsurance } from 'app/pages/insurance/edit-insurance'
import { ListInsurances } from 'app/pages/insurance/list-insurances'
import { TermsAndDataProtection } from 'app/pages/legal/terms-and-data-protection'
import { NewLicense } from 'app/pages/licenses/new-license'
import { PreviewLicensePage } from 'app/pages/licenses/preview-license-page'
import { QRLicenseV1 } from 'app/pages/licenses/qr-license-v1'
import { QRLicenseV2 } from 'app/pages/licenses/qr-license-v2'
import { QRPitLanePass } from 'app/pages/licenses/qr-pit-lane-pass'
import { EditPhoto } from 'app/pages/photo/edit-photo'
import { ChangeEmail } from 'app/pages/profile/change-email'
import { ChangeEmailAfterLogin } from 'app/pages/profile/change-email-after-login'
import { EditPersonalData } from 'app/pages/profile/edit-personal-data'
import { EditTransponder } from 'app/pages/transponder/edit-transponder'
import { useAssociationContext } from 'app/themes/association-context'
import { associationsWithLogosPreview } from 'app/themes/associationsWithLogos'
import { useUserContext } from 'app/themes/user-context'
import { signOut } from 'app/users/auth'
import { DisabledForAdmin } from 'app/users/disabled-for-association-admin'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { nextLicenseYear } from 'shared/data/license-config'
import { t } from 'shared/i18n/current'
import { associationPath, associationPathWithoutLocale } from 'shared/models/associations'

interface SamEventsMenuProps {
  closeMenu: () => void
  user: User | undefined
}

export function SamEventsMenu({ closeMenu, user }: SamEventsMenuProps) {
  const userContext = useUserContext()
  return (
    <>
      <List>
        {user && <MenuItem closeMenu={closeMenu} {...routes.userDashboard} />}
        {user && (
          <MenuItem
            closeMenu={closeMenu}
            to={routes.newLicense.generateTo(nextLicenseYear)}
            icon={routes.newLicense.icon}
            text={() => routes.newLicense.textForYear(nextLicenseYear)}
          />
        )}
        {userContext.adminOrAssociationAdmin && adminMenuItems(closeMenu)}
        <MenuItem closeMenu={closeMenu} {...routes.sportEvents} />
        <ChangeAssociationMenuItem closeMenu={closeMenu} />
        {userContext.adminOrAssociationAdmin && <ChangeYearMenuItem closeMenu={closeMenu} />}
        {user && (
          <MenuItem
            to="/"
            closeMenu={closeMenu}
            action={signOut}
            icon={SignOutIcon}
            text={() => t().signOutText}
          />
        )}
        {!user && (
          <MenuItem closeMenu={closeMenu} to="/" icon={SignInIcon} text={() => t().signOutText} />
        )}
      </List>
    </>
  )
}

function adminMenuItems(closeMenu: () => void) {
  return (
    <>
      <MenuItem closeMenu={closeMenu} {...routes.adminDashboard} />
      <MenuItem closeMenu={closeMenu} {...routes.approvedLicenses} />
      <MenuItem closeMenu={closeMenu} {...routes.licenseDrafts} />
      <MenuItem closeMenu={closeMenu} {...routes.assignLicense} />
      <MenuItem closeMenu={closeMenu} {...routes.userFinancials} />
      <MenuItem closeMenu={closeMenu} {...routes.newTransponders} />
      <MenuItem closeMenu={closeMenu} {...routes.categoriesKeyFigures} />
      <MenuItem closeMenu={closeMenu} {...routes.associationFinancials} />
      <MenuItem closeMenu={closeMenu} {...routes.allTransactions} />
      <MenuItem closeMenu={closeMenu} {...routes.allLicenseBookings} />
      <MenuItem closeMenu={closeMenu} {...routes.manualBookings} />
      <MenuItem closeMenu={closeMenu} {...routes.payments} />
      <MenuItem closeMenu={closeMenu} {...routes.licenseBookingsByRider} />
      <MenuItem closeMenu={closeMenu} {...routes.editUsers} />
      <MenuItem closeMenu={closeMenu} {...routes.importLicenses} />
      <MenuItem closeMenu={closeMenu} {...routes.messages} />
      <MenuItem closeMenu={closeMenu} {...routes.adminSportEvents} />
    </>
  )
}

interface MenuItemProps {
  to: string
  action?: () => void
  icon: (props: any) => JSX.Element
  text: () => string
  closeMenu: () => void
}

export function MenuItem({ to, action, icon, text, closeMenu }: MenuItemProps) {
  const renderLink = useMemo(() => {
    const RouterLinkComponent = forwardRef<unknown, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
      <RouterLink to={to} ref={ref as any} {...itemProps} />
    ))
    RouterLinkComponent.displayName = `RouterLinkTo/${to}`
    return RouterLinkComponent
  }, [to])

  const Icon = icon || undefined

  const onClick = () => {
    closeMenu()
    action?.()
  }

  return (
    <ListItem button onClick={onClick} component={renderLink}>
      {Icon ? (
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
      ) : null}
      <ListItemText primary={typeof text === 'string' ? text : text()} />
    </ListItem>
  )
}

export function SamEventsRoutes({ user }: { user: User | undefined }) {
  const context = useAssociationContext()

  return (
    <Switch>
      {combinedAssociationsAndLocales().map(({ association, locale }) => (
        <RouteWithErrorBoundary
          text={() => ''}
          key={`${association}-${locale}`}
          exact
          user={user}
          path={associationPath({ association, locale })}
        >
          <SetAssociationAndLocale association={association} locale={locale} />
        </RouteWithErrorBoundary>
      ))}
      {associationsWithLogosPreview(context.previewing).map((association) => (
        <RouteWithErrorBoundary
          text={() => ''}
          key={`${association.id}`}
          exact
          user={user}
          path={associationPathWithoutLocale({ association: association.id })}
        >
          <SetAssociationAndLocale association={association.id} />
        </RouteWithErrorBoundary>
      ))}
      <RouteWithErrorBoundaryAndTitle
        user={user}
        text={routes.sportEvents.text}
        path={routes.sportEvents.to}
      >
        <SportEvents user={user} showAdminView={false} />
      </RouteWithErrorBoundaryAndTitle>
      <RouteWithErrorBoundaryAndTitle
        user={user}
        text={routes.publicInscriptions.text}
        path={routes.publicInscriptions.to}
      >
        <PublicInscriptionsPage user={user} />
      </RouteWithErrorBoundaryAndTitle>
      <RouteWithErrorBoundaryAndTitle
        user={user}
        text={routes.termsAndDataProtection.text}
        path={routes.termsAndDataProtection.to}
      >
        <TermsAndDataProtection />
      </RouteWithErrorBoundaryAndTitle>
      <RouteWithErrorBoundaryAndTitle
        user={user}
        text={routes.qrPitLane.text}
        path={routes.qrPitLane.to}
      >
        <QRPitLanePass />
      </RouteWithErrorBoundaryAndTitle>
      <RouteWithErrorBoundaryAndTitle
        user={user}
        text={routes.qrLicenseV2.text}
        path={routes.qrLicenseV2.to}
      >
        <QRLicenseV2 />
      </RouteWithErrorBoundaryAndTitle>
      {user && (
        <>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.migrations.text}
            path={routes.migrations.to}
          >
            <DBMigrations />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.manuallyProcessLicenseRequest.text}
            path={routes.manuallyProcessLicenseRequest.to}
          >
            <ManuallyProcessLicenseRequest />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.userEvents.text}
            path={routes.userEvents.to}
          >
            <AllUserEvents />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editAdmins.text}
            path={routes.editAdmins.to}
          >
            <AdminManagementContainer user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editUsers.text}
            path={routes.editUsers.to}
          >
            <UserManagement admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.emailConflicts.text}
            path={routes.emailConflicts.to}
          >
            <EmailConflicts admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.transactionsWithoutUser.text}
            path={routes.transactionsWithoutUser.to}
          >
            <TransactionsWithoutDocuments />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.duplicatedUsers.text}
            path={routes.duplicatedUsers.to}
          >
            <DuplicatedUsers admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.payments.text}
            path={routes.payments.to}
          >
            <Payments admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.memberFees.text}
            path={routes.memberFees.to}
          >
            <MemberFees admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.adminSportEvents.text}
            path={routes.adminSportEvents.to}
          >
            <SportEvents user={user} showAdminView />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.invalidPayments.text}
            path={routes.invalidPayments.to}
          >
            <InvalidPayments admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.allLicenseBookings.text}
            path={routes.allLicenseBookings.to}
          >
            <AllLicenseBookings admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.licenseBookingsByRider.text}
            path={routes.licenseBookingsByRider.to}
          >
            <LicenseBookingsByRider admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.manualBookings.text}
            path={routes.manualBookings.to}
          >
            <ManualBookings user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.approvedLicenses.text}
            path={routes.approvedLicenses.to}
          >
            <ApprovedLicenses admin={user} />
          </RouteWithErrorBoundaryAndTitle>{' '}
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.assignLicense.text}
            path={routes.assignLicense.to}
          >
            <AssignLicense admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.insurances.text}
            path={routes.insurances.to}
          >
            <ListInsurances admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.userFinancials.text}
            path={routes.userFinancials.to}
          >
            <UserFinancials admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.associationFinancials.text}
            path={routes.associationFinancials.to}
          >
            <AssociationFinancials admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.allTransactions.text}
            path={routes.allTransactions.to}
          >
            <AllTransactions admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.manualPayments.text}
            path={routes.manualPayments.to}
          >
            <ManualPayments admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.licenseDrafts.text}
            path={routes.licenseDrafts.to}
          >
            <LicenseDrafts admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.previewLicense.text}
            path={routes.previewLicense.to}
          >
            <PreviewLicensePage />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.importLicenses.text}
            path={routes.importLicenses.to}
          >
            <DisabledForAdmin title={t().licenses.importLicenses} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.messages.text}
            path={routes.messages.to}
          >
            <AdminMessages admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.qrLicenseV1.text}
            path={routes.qrLicenseV1.to}
          >
            <QRLicenseV1 />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.categoriesKeyFigures.text}
            path={routes.categoriesKeyFigures.to}
          >
            <CategoriesKeyFigures user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.newTransponders.text}
            path={routes.newTransponders.to}
          >
            <NewTransponders admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.inscriptions.text}
            path={routes.inscriptions.to}
          >
            <Inscriptions admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.inscriptionGroups.text}
            path={routes.inscriptionGroups.to}
          >
            <InscriptionGroups admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.sportEventFinancials.text}
            path={routes.sportEventFinancials.to}
          >
            <SportEventFinancials admin={user} />
          </RouteWithErrorBoundaryAndTitle>{' '}
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.lonelyTwoDayDiscounts.text}
            path={routes.lonelyTwoDayDiscounts.to}
          >
            <LonelyTwoDayDiscounts admin={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.adminDashboard.text}
            path={routes.adminDashboard.to}
            exact
          >
            <AdminDashboard user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editProfile.text}
            path={routes.editProfile.to}
          >
            <EditPersonalData user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editEmergency.text}
            path={routes.editEmergency.to}
          >
            <EditEmergency user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editDriversLicense.text}
            path={routes.editDriversLicense.to}
          >
            <EditDriversLicense user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editHealthCheck.text}
            path={routes.editHealthCheck.to}
          >
            <EditHealthCheck user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editInsurance.text}
            path={routes.editInsurance.to}
          >
            <EditInsurance user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editPhoto.text}
            path={routes.editPhoto.to}
          >
            <EditPhoto user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.editTransponder.text}
            path={routes.editTransponder.to}
          >
            <EditTransponder user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.addBike.text}
            path={routes.addBike.to}
          >
            <AddBike user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.newLicense.text}
            path={routes.newLicense.to}
          >
            <NewLicense user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.newInscription.text}
            path={routes.newInscription.to}
          >
            <NewInscriptionPage user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.changeEmail.text}
            path={routes.changeEmail.to}
          >
            <ChangeEmail user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.changeEmailAfterLogin.text}
            path={routes.changeEmailAfterLogin.to}
          >
            <ChangeEmailAfterLogin user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.userDashboard.text}
            path={routes.userDashboard.to}
            exact
          >
            <UserDashboard user={user} />
          </RouteWithErrorBoundaryAndTitle>
          <RouteWithErrorBoundaryAndTitle
            user={user}
            text={routes.userOrAdminDashboard.text}
            path={routes.userOrAdminDashboard.to}
            exact
          >
            <UserOrAdminDashboard user={user} />
          </RouteWithErrorBoundaryAndTitle>
        </>
      )}
    </Switch>
  )
}

export function Link({ to }: { to: string }) {
  return (
    <MaterialLink component={RouterLink} to={to}>
      With prop forwarding
    </MaterialLink>
  )
}
