import { Box } from '@material-ui/core'
import { buttonHighlightProps, editOrCreateText } from 'app/pages/dashboard/documents-box'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { ShowPhoto } from 'app/pages/photo/show-photo'
import { ShowPersonalData } from 'app/pages/profile/show-personal-data'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { RoutedButton } from 'utils/buttons/routed-button'

interface PersonalDataBoxProps {
  documents: Documents | undefined
  user: User
}

export function PersonalDataBox({ user, documents }: PersonalDataBoxProps) {
  const photo = documents?.photo
  const personalData = documents?.personalData

  return (
    <ElevatedBox title={t().personalDataBox.title}>
      <Box my={2}>
        <ShowPersonalData personalData={personalData} user={user} />
        <ShowPhoto photo={photo} />
      </Box>
      <Box mt={2}>
        <RoutedButton to={routes.editProfile.to}>
          {personalData ? t().buttons.edit : t().buttons.add}
        </RoutedButton>
        <RoutedButton to={routes.changeEmail.to}>{routes.changeEmail.text()}</RoutedButton>
        <RoutedButton {...buttonHighlightProps(photo, true)} to={routes.editPhoto.to}>
          {editOrCreateText(t().licensePhoto.title, photo, true)}
        </RoutedButton>
      </Box>
    </ElevatedBox>
  )
}
