import type {
  LicenseBooking,
  LicenseBookingWithCategoryLineItem,
  LicenseBookingWithTransponderLineItem,
} from 'shared/db/db'

export function isLicenseBookingWithCategoryLineItem(
  booking: LicenseBooking
): booking is LicenseBookingWithCategoryLineItem {
  return booking.item.type === 'categoryLineItem'
}

export function isLicenseBookingWithTransponderLineItem(
  booking: LicenseBooking
): booking is LicenseBookingWithTransponderLineItem {
  return booking.item.type === 'transponderLineItem'
}
