import { Box, Button } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { db } from 'app/db/frontend-db'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { AdministratePersonalData } from 'app/license/forms/administrate-personal-data'
import { formDataToPersonalData, personalDataToFormData } from 'app/personal-data/personal-data-form'
import {
  loadInitialPersonalDataValues,
  personalDataSchema,
} from 'app/personal-data/personal-data-form-fields'
import { Documents, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { PersonalDataFormData } from 'shared/models/personal-data'
import { FriendlyError } from 'utils/errors'
import { FormErrors } from 'utils/form-errors'
import { Loading } from 'utils/loading'
import { useErrorSnackbar, useErrorSnackbarForError, useSuccessSnackbar } from 'utils/snackbar'

interface EditPersonalDataButtonProps {
  type?: 'button' | 'icon'
  user: UserQuery
  email: string
  documents: Documents | undefined
}

export function EditPersonalDataButton(props: EditPersonalDataButtonProps) {
  const { user, documents, email, type = 'icon' } = props
  const [onSubmitError, setError] = useState<Error>()
  const confirmLicenseDialog = useDialog()
  const showSuccessMessage = useSuccessSnackbar()
  const showErrorMessage = useErrorSnackbar()
  const showErrorMessageForError = useErrorSnackbarForError()

  return (
    <>
      {type === 'icon' ? (
        <IconButtonWithTooltip
          tooltip={t().routes.editProfile}
          onClick={() => confirmLicenseDialog.open()}
        >
          <Edit />
        </IconButtonWithTooltip>
      ) : (
        <Button variant="contained" onClick={() => confirmLicenseDialog.open()}>
          {t().routes.editProfile}
        </Button>
      )}
      {confirmLicenseDialog.isOpen && (
        <Formik
          initialValues={loadInitialValues(documents, email)}
          enableReinitialize
          validateOnMount
          validationSchema={schema()}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setError(undefined)
              await db.setPersonalData(
                user,
                formDataToPersonalData(values.personalData, values.personalData.email)
              )
              showSuccessMessage(t().alerts.dataSaved)
            } catch (error: any) {
              setError(error)
              showErrorMessage(t().alerts.errorSaving)
              throw error
            }
            setSubmitting(false)
          }}
        >
          {({ submitForm, isSubmitting, dirty, values, errors, touched, isValid }) => (
            <Form>
              <ConfirmDialog
                maxWidth="xl"
                title={t().users.updatePersonalData}
                buttonText={t().users.updatePersonalData}
                dialog={confirmLicenseDialog}
                onConfirm={async () => {
                  try {
                    await submitForm()
                  } catch (error: any) {
                    showErrorMessageForError(error)
                    return false
                  }
                }}
                disabled={!isValid || !dirty || isSubmitting}
              >
                <Box>
                  <AdministratePersonalData
                    errors={errors.personalData}
                    values={values.personalData}
                    parentsAgree={
                      touched.personalData?.parentsAgree && errors.personalData?.parentsAgree
                    }
                  />
                </Box>

                <Loading loading={isSubmitting} />

                {!isValid && <FormErrors errors={errors} />}
                <FriendlyError error={onSubmitError} />
              </ConfirmDialog>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

function loadInitialValues(
  documents: Documents | undefined,
  email: string
): { personalData: PersonalDataFormData } {
  const personalDataOrUndefined = documents?.personalData
  const personalData = personalDataOrUndefined
    ? personalDataToFormData(personalDataOrUndefined)
    : loadInitialPersonalDataValues()
  personalData.email = documents?.personalData?.email || email || ''
  return { personalData }
}

function schema() {
  return Yup.object()
    .defined()
    .shape({ personalData: personalDataSchema(true) })
}
