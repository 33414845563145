import { useCallback, useEffect, useState } from 'react'
import type { CategoriesCheckboxProps } from 'app/forms/categories-checkbox-group'
import { useActiveAssociationID } from 'app/themes/use-assocation'
import { categoryByIdRequired, categoryOfAssociationRequired } from 'shared/data/categories-service'
import { queryParts } from 'shared/db/search'
import { AssociationID, FullAssociation } from 'shared/models/associations'
import { CategoryType } from 'shared/models/category'
import { SelectedCategory } from 'shared/models/selected-category'
import { includesObject, toggleArrayObject } from 'shared/utils/array'

export function useCategoriesContext(props: CategoriesCheckboxProps) {
  const { helperText, label } = props
  const { setFieldValue, errors } = props.form
  const { name: fieldName, value: fieldValue } = props.field
  const { categories, approvedLicenses = [] } = props.options
  const errorText = errors[fieldName]
  const approvedLicenseCategories = approvedLicenses.map<SelectedCategory>((license) => ({
    association: license.licenseAssociation,
    categoryType: categoryByIdRequired(license.categoryId).type,
    category: categoryByIdRequired(license.categoryId).id,
  }))

  const [search, setSearch] = useState('')
  const themeAssociationID = useActiveAssociationID()
  const [selectedAssociations, setSelectedAssociations] = useState<FullAssociation[]>([])
  const [selectedCategoryTypes, setSelectedCategoryTypes] = useState<SelectedCategoryType[]>([])
  const [selectedCategories, setSelectedCategoriesInternal] = useState<SelectedCategory[]>([])

  const setSelectedCategories = useCallback(
    (categories: SelectedCategory[]) => {
      setSelectedCategoriesInternal(categories)
      setFieldValue(fieldName, categories)
    },
    [fieldName, setSelectedCategoriesInternal, setFieldValue]
  )

  useEffect(() => setSelectedCategoriesInternal(fieldValue), [fieldValue])

  return {
    approvedLicenseCategories,
    themeAssociationID,
    selectedAssociations,
    setSelectedAssociations,
    selectedCategoryTypes,
    setSelectedCategoryTypes,
    selectedCategories,
    setSelectedCategories,
    fieldValue,
    errorText,
    categories,
    approvedLicenses,
    helperText,
    label,
    search,
    setSearch,
  }
}

export type CategoriesCheckboxGroupContext = ReturnType<typeof useCategoriesContext>

export function toggleAssociation(
  context: CategoriesCheckboxGroupContext,
  association: FullAssociation
) {
  context.setSelectedAssociations(toggleArrayObject(context.selectedAssociations, association))
}

export function toggleCategoryType(context: CategoriesCheckboxGroupContext, type: SelectedCategoryType) {
  context.setSelectedCategoryTypes(toggleArrayObject(context.selectedCategoryTypes, type))
}

export function toggleCategory(context: CategoriesCheckboxGroupContext, category: SelectedCategory) {
  context.setSelectedCategories(toggleArrayObject(context.selectedCategories, category))
}

export function associationExpanded(
  context: CategoriesCheckboxGroupContext,
  association: FullAssociation
) {
  return associationChecked(context, association) || context.search
}

export function categoryTypeExpanded(
  context: CategoriesCheckboxGroupContext,
  categoryType: SelectedCategoryType
) {
  return categoryTypeChecked(context, categoryType) || context.search
}

export function categoryTypeVisible(
  context: CategoriesCheckboxGroupContext,
  categoryType: SelectedCategoryType
) {
  return (
    !context.search ||
    context.categories.some(
      (category) =>
        category.associations.includes(categoryType.association) &&
        category.type === categoryType.categoryType &&
        categoryMatchesSearch(context, {
          association: categoryType.association,
          categoryType: categoryType.categoryType,
          category: category.id,
        })
    )
  )
}

export function associationChecked(
  context: CategoriesCheckboxGroupContext,
  association: FullAssociation
) {
  return (
    associationDisabled(context, association) ||
    includesObject(context.selectedAssociations, association)
  )
}

export function categoryTypeChecked(
  context: CategoriesCheckboxGroupContext,
  categoryType: SelectedCategoryType
) {
  return (
    categoryTypeDisabled(context, categoryType) ||
    includesObject(context.selectedCategoryTypes, categoryType)
  )
}

export function categoryChecked(context: CategoriesCheckboxGroupContext, category: SelectedCategory) {
  return (
    categoryHasApprovedLicense(context, category) || includesObject(context.selectedCategories, category)
  )
}

export function associationDisabled(
  context: CategoriesCheckboxGroupContext,
  association: FullAssociation
) {
  return (
    context.selectedCategoryTypes.some((type) => type.association === association.id) ||
    context.selectedCategories.some((category) => category.association === association.id) ||
    context.approvedLicenseCategories.some((license) => license.association === association.id)
  )
}

export function categoryTypeDisabled(
  context: CategoriesCheckboxGroupContext,
  type: SelectedCategoryType
) {
  return [...context.selectedCategories, ...context.approvedLicenseCategories].some(
    (category) =>
      category.association === type.association && category.categoryType === type.categoryType
  )
}

export function categoryDisabled(context: CategoriesCheckboxGroupContext, category: SelectedCategory) {
  return (
    categoryHasApprovedLicense(context, category) ||
    categorySelectedWithOtherAssociation(context, category) ||
    !categoryByIdRequired(category.category).active
  )
}

export function categorySelectedWithOtherAssociation(
  context: CategoriesCheckboxGroupContext,
  category: SelectedCategory
): boolean {
  return context.selectedCategories.some(
    (licenseCategory) =>
      licenseCategory.association !== category.association &&
      licenseCategory.categoryType === category.categoryType &&
      licenseCategory.category === category.category
  )
}

export function categoryHasApprovedLicense(
  context: CategoriesCheckboxGroupContext,
  category: SelectedCategory
) {
  return context.approvedLicenseCategories.some(
    (licenseCategory) =>
      licenseCategory.association === category.association &&
      licenseCategory.categoryType === category.categoryType &&
      licenseCategory.category === category.category
  )
}

export function categoryMatchesSearch(context: { search: string }, category: SelectedCategory) {
  if (!context.search) return true

  const parts = queryParts(context.search)
  const categoryString = categoryForSearch(category).toLocaleLowerCase()

  return parts.every((part) => categoryString.includes(part.lower))
}

function categoryForSearch(selectedCategory: SelectedCategory) {
  const category = categoryByIdRequired(selectedCategory.category)

  return [
    selectedCategory.association,
    category.typeName,
    categoryOfAssociationRequired(category.id, selectedCategory.association).name,
  ].join(' ')
}

export interface SelectedCategoryType {
  association: AssociationID
  categoryType: CategoryType
}
