import {
  createStyles,
  FormHelperText,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import { externalLinks } from 'shared/config/externalLinks'
import { t } from 'shared/i18n/current'
import { Emergency } from 'shared/models/emergency'
import { Dig } from 'shared/utils/tsc'
import { ExternalLink } from 'utils/external-link'

interface EmergencyProps {
  adminView?: boolean
  initialValues?: Emergency
  onSubmit: (data: Emergency) => Promise<void>
  onBack: () => void
  onCancel: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function EmergencyForm(props: EmergencyProps) {
  const classes = useStyles()
  const { initialValues = loadInitialValues(), onBack, onSubmit } = props
  const fields = samFields().emergency

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema()}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, values, touched, errors }) => (
          <Form>
            <Grid container spacing={1} className={classes.gridParent}>
              <Grid item sm={6} xs={12}>
                {fields.ahvNumber.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.civilStatus.field()}
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.confession.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.profession.field()}
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.doctorName.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.doctorPhone.field()}
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.employerName.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.employerPhone.field()}
              </Grid>

              <Grid item xs={12}>
                <Typography>{t().accidentInsurance}</Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.accidentInsuranceName.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.accidentInsurancePolicyNumber.field()}
              </Grid>

              <Grid item xs={12}>
                {fields.accidentInsurancePlan.field()}
              </Grid>

              <Grid item xs={12}>
                <Typography>{t().healthInsurance}</Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.healthInsuranceName.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.healthInsurancePolicyNumber.field()}
              </Grid>

              <Grid item xs={12}>
                {fields.healthInsurancePlan.field()}
              </Grid>

              <Grid item xs={12}>
                <Typography>{t().additionalInsurances}</Typography>
              </Grid>

              <Grid item xs={12}>
                {fields.hasSupplementaryInsurance.field()}
                {values.hasSupplementaryInsurance && (
                  <FormHelperText>
                    <ExternalLink href={externalLinks.samInsuranceTerms}>
                      {fields.hasSupplementaryInsurance.hintForSupplementaryInsurance}
                    </ExternalLink>
                  </FormHelperText>
                )}
              </Grid>

              <Grid item md={values.hasReturnTransportInsurance ? 4 : 12} xs={12}>
                {fields.hasReturnTransportInsurance.field()}
                {!values.hasReturnTransportInsurance && (
                  <FormHelperText>
                    {fields.hasReturnTransportInsurance.hintForNewReturnTransportInsurance}
                  </FormHelperText>
                )}
              </Grid>
              {values.hasReturnTransportInsurance && (
                <>
                  <Grid item md={8} xs={12}>
                    {fields.returnTransportInsuranceName.field()}
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Typography>{t().contactOnAccident}</Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.contactOnAccident1Name.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.contactOnAccident1Phone.field()}
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.contactOnAccident2Name.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.contactOnAccident2Phone.field()}
              </Grid>

              <Grid item xs={12}>
                <Typography>{t().medicalInformation}</Typography>
              </Grid>

              <Grid item xs={12}>
                {fields.medication.field()}
              </Grid>

              <Grid item xs={12}>
                {fields.allergies.field()}
              </Grid>

              <Grid item xs={12}>
                {fields.infoForDoctor.field()}
              </Grid>

              <Grid item xs={12}>
                <Typography>{t().additionalInformation}</Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                {fields.bloodGroup.field()}
              </Grid>
              <Grid item sm={6} xs={12}>
                {fields.bloodRhesusFactor.field()}
              </Grid>

              <Grid item xs={12}>
                {fields.specialInformation.field()}
              </Grid>

              <Grid item md={values.emergencyConfirmation} xs={12}>
                {fields.confirmRevokeConfidentiality.field()}
                {touched.confirmRevokeConfidentiality && errors.confirmRevokeConfidentiality && (
                  <FormHelperText error>{errors.confirmRevokeConfidentiality}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                {fields.emergencyConfirmation.field()}
                {touched.emergencyConfirmation && errors.emergencyConfirmation && (
                  <FormHelperText error>{errors.emergencyConfirmation}</FormHelperText>
                )}
              </Grid>

              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <AccordionNavigationButtons
                isSubmitting={isSubmitting}
                onBack={props.adminView ? undefined : onBack}
                submitForm={submitForm}
                nextLabel={props.adminView ? t().buttons.save : undefined}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

function loadInitialValues(): Emergency {
  return Object.fromEntries(
    Object.entries(samFields().emergency).map(([k, v]) => [k, v.default])
  ) as unknown as Emergency
}

function schema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().emergency).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['emergency']>
  return Yup.object().defined().shape(validations)
}
