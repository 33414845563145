import { currentLocale } from 'shared/i18n/current'
import { I18nLocales } from 'shared/i18n/i18n-types'
import { AssociationID } from 'shared/models/associations'
import { truthy } from 'shared/utils/array'

export function memoize<T>(fn: () => T): () => T {
  let cache: T | undefined
  return () => {
    if (!cache) cache = fn()
    return cache
  }
}

export function memoizeBasedOnLocale<T>(fn: () => T): () => T {
  const cache: Record<I18nLocales, T | undefined> = {} as any
  return () => {
    const locale = currentLocale()
    if (!cache[locale]) cache[locale] = fn()
    return cache[locale] as T
  }
}

export function memoizeBasedOnLocaleWithParam<T>(
  fn: (locale: I18nLocales) => T
): (locale: I18nLocales) => T {
  const cache: Record<I18nLocales, T | undefined> = {} as any
  return (locale: I18nLocales) => {
    if (!cache[locale]) cache[locale] = fn(locale)
    return cache[locale] as T
  }
}

export function memoizeBasedOnLocaleAndAssocationWithParams<T>(
  fn: (locale: I18nLocales, association: AssociationID | undefined) => T
): (locale: I18nLocales, association: AssociationID | undefined) => T {
  const cache: Record<string, T | undefined> = {} as any
  return (locale: I18nLocales, association: AssociationID | undefined) => {
    const key = [locale, association].filter(truthy).join('-')
    if (!cache[key]) cache[key] = fn(locale, association)
    return cache[key] as T
  }
}
