import blobStream from 'blob-stream'
import { saveAs } from 'file-saver'
import { toFilename } from 'shared/utils/string'

export function downloadPdf(options: PDFOptions, addContents: (pdf: PDFDoc) => Promise<void> | void) {
  return generatePdf(options, addContents, (stream) =>
    saveAs(stream.toBlob('application/pdf'), toFilename(options.name), { autoBom: true })
  )
}

export function previewPdf(options: PDFOptions, addContents: (pdf: PDFDoc) => Promise<void>) {
  return generatePdf(options, addContents, (stream) => {
    const iframe = document.getElementById('pdf_preview')
    if (!iframe || !(iframe instanceof HTMLIFrameElement)) return
    iframe.src =
      stream.toBlobURL('application/pdf') +
      '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0'
  })
}

async function generatePdf(
  options: PDFOptions,
  addContents: (pdf: PDFDoc) => Promise<void> | void,
  onFinish: (stream: blobStream.IBlobStream) => void
) {
  const pdfkit = await import('pdfkit/js/pdfkit.standalone')

  const pdf = new pdfkit.default({
    ...options,
    size: options.size || 'A4',
    margin: options.margin || options.size ? undefined : 28,
  })
  const stream = pdf.pipe(blobStream())
  await addContents(pdf)

  await new Promise<void>((resolve, reject) => {
    pdf.end()
    try {
      stream.on('finish', () => {
        onFinish(stream)
        resolve()
      })
    } catch (error) {
      reject(error)
    }
  })
}

export type PDFDoc = PDFKit.PDFDocument
export const pdfTextFontSize = 12

export interface PDFOptions extends PDFKit.PDFDocumentOptions {
  name: string
}

export async function drawSVG(pdf: PDFDoc, svg: string, x: number, y: number) {
  const SVGtoPDF = (await import('svg-to-pdfkit')).default
  SVGtoPDF(pdf, svg, x, y)
}
