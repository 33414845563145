import { Box } from '@material-ui/core'
import { consoleLog } from 'shared/utils/console'
import { catchAndLog } from 'utils/error-boundary'
import { PDFDoc, PDFOptions, previewPdf } from 'utils/pdf/pdf'
import { Disp } from 'utils/react'

export function usePdfPreview(
  setError: Disp<Error | null>,
  options: PDFOptions,
  addContents: (pdf: PDFDoc) => Promise<void>
) {
  catchAndLog(setError, () => previewPdf(options, addContents)).finally(() => consoleLog('Done'))
}

export function PDFPreview() {
  return (
    <Box>
      <iframe
        title="PDF Preview"
        id="pdf_preview"
        style={{
          zIndex: 10000000000,
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </Box>
  )
}
