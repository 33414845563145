import { createContext, useCallback, useContext, useState } from 'react'
import { db } from 'app/db/frontend-db'
import { setAuthLocale } from 'app/i18n/auth-locale'
import { UserQuery } from 'shared/db/db'
import { loadDefaultLocale, setLocale, t } from 'shared/i18n/current'
import { I18nLocales, I18nType } from 'shared/i18n/i18n-types'
import { Disp } from 'utils/react'

export function useI18nState(): I18nState {
  const [locale, setCurrentLocale] = useState<I18nLocales>(loadDefaultLocale())
  const setLocale = useCallback(
    (locale: I18nLocales) => setLocaleFn(locale, setCurrentLocale),
    [setCurrentLocale]
  )
  const setLocaleAndUserLocale = useCallback(
    (user: UserQuery | undefined, locale: I18nLocales) =>
      setLocaleAndUserLocaleFn(user, locale, setCurrentLocale),
    [setCurrentLocale]
  )
  return { locale, setLocale, setLocaleAndUserLocale, t: t() }
}

async function setLocaleAndUserLocaleFn(
  user: UserQuery | undefined,
  locale: I18nLocales,
  setCurrentLocale: Disp<I18nLocales>
) {
  setAuthLocale(locale)
  setLocaleFn(locale, setCurrentLocale)
  if (user?.uid) await db.setUserLocale(user, locale)
}

function setLocaleFn(locale: I18nLocales, setCurrentLocale: Disp<I18nLocales>) {
  setLocale(locale)
  setCurrentLocale(locale)
}

export function useI18nContext() {
  return useContext(I18nContext)
}

export const I18nContext = createContext(initialState())

function initialState(): I18nState {
  return {
    locale: loadDefaultLocale(),
    setLocaleAndUserLocale: () => null,
    setLocale: () => null,
    t: t(),
  }
}

export interface I18nState {
  locale: I18nLocales
  setLocale: (locale: I18nLocales) => void
  setLocaleAndUserLocale: (user: UserQuery | undefined, locale: I18nLocales) => void
  t: I18nType
}
