import { Box, Button, createStyles, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { sortBy } from 'lodash'
import { Fragment, useEffect } from 'react'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { LicenseDraftConfirmValues } from 'app/license/forms/admin-form-types'
import { useAdminContext } from 'app/themes/admin-context'
import { useAssociationContext } from 'app/themes/association-context'
import { useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import {
  categoriesByAssociation,
  categoryCommonName,
  categoryOfAssociation,
  categoryOfAssociationByCategoryDetails,
  firstValidLicenseType,
  fixedCategoryAssociation,
  fixedLicenseType,
  requiresInsuranceOptionByCategory,
} from 'shared/data/categories-service'
import { LicenseWithContext } from 'shared/db/db'
import { currentLocale, t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { CategoryDetails } from 'shared/models/category-details'
import { truthy } from 'shared/utils/array'
import { DeleteButtonIcon } from 'utils/buttons/delete-button-icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function AdministrateDraftCategories() {
  const currentYear = useLicenseYear()
  const fields = samFields().categoriesDropdown
  const classes = useStyles()
  const adminContext = useAdminContext()
  const userContext = useUserContext()
  const associationContext = useAssociationContext()
  const association = adminContext.association || 'sam'
  const categories = categoriesByAssociation(currentLocale(), association)
    .map((category) =>
      categoryOfAssociation(category.id, adminContext.association || category.associations[0])
    )
    .filter(truthy)

  const { values, setValues, isSubmitting } = useFormikContext<LicenseDraftConfirmValues>()

  const categoryDetailsHash = values.categoryDetails.drafts
    .map((detail) => [detail.categoryId, detail.licenseAssociation, detail.licenseType].join(','))
    .join(';')
  useEffect(() => {
    const newDraftCategoryDetails = values.categoryDetails.drafts
      .map((it) => fixedCategoryAssociation(it, userContext.associationAdmin))
      .map((it) => fixedLicenseType(it))

    const categoryDetailsNeedToBeFixed = newDraftCategoryDetails.some((newDetail, index) => {
      const detail = values.categoryDetails.drafts[index]
      return (
        detail.licenseAssociation !== newDetail.licenseAssociation ||
        detail.licenseType !== newDetail.licenseType
      )
    })

    if (categoryDetailsNeedToBeFixed)
      setValues({
        ...values,
        categoryDetails: { ...values.categoryDetails, drafts: newDraftCategoryDetails },
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryDetailsHash, association, currentYear])

  return (
    <Box mb={2}>
      <Typography variant="h4" component="h3" gutterBottom>
        {t().licenses.unapprovedLicenses}
      </Typography>

      {values.categoryDetails.drafts
        .map((it) => fixedCategoryAssociation(it, userContext.associationAdmin))
        .map((it) => fixedLicenseType(it))
        .map((categoryDetails, index) => {
          const category = categoryOfAssociationByCategoryDetails(categoryDetails)
          if (!category) return null

          const showInsuranceOptions = requiresInsuranceOptionByCategory(
            category.association,
            category.year,
            category
          )

          const canEdit = adminContext.matchesAssociation(categoryDetails.licenseAssociation)

          return category.associations.some((current) => adminContext.matchesAssociation(current)) ? (
            <Fragment
              key={`${index}-${categoryDetails.licenseAssociation}-${categoryDetails.categoryId}-draft`}
            >
              <Box display="flex" my={4}>
                <Box flexGrow={1}>
                  <Grid container spacing={1} className={classes.gridParent}>
                    <Grid item md={6} xs={12}>
                      {fields.categoryId.field(
                        'drafts',
                        index,
                        userContext.associationAdmin || undefined,
                        currentYear,
                        { disabled: !canEdit }
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {fields.association.field('drafts', index, {
                        disabled: !canEdit || !!userContext.associationAdmin,
                      })}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      {fields.licenseType.field('drafts', index, { disabled: !canEdit })}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box display="flex" style={{ gap: '0px 5px' }}>
                        {category?.numberChoice && (
                          <Box flexGrow={1} flexBasis={100}>
                            {fields.preferredNumber.field(index)}
                          </Box>
                        )}
                        {category?.sidecar && (
                          <Box flexGrow={1} flexBasis={100}>
                            {fields.sidecarPartner.field('drafts', index)}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    {showInsuranceOptions && (
                      <Grid item xs={12}>
                        {fields.insuranceOption.field('drafts', index, { disabled: !canEdit })}
                      </Grid>
                    )}
                    {category?.needsBikeInfo && (
                      <>
                        <Grid item md={6} xs={12}>
                          {fields.teamName.field('drafts', index, { disabled: !canEdit })}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          {fields.bikeMake.field('drafts', index, { disabled: !canEdit })}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
                <Box alignSelf="center">
                  <DeleteButtonIcon
                    title={`Lizenz ${categoryCommonName(category)} entfernen`}
                    onConfirm={() =>
                      setValues({
                        ...values,
                        categoryDetails: {
                          approved: [...values.categoryDetails.approved].map((it) =>
                            fixedLicenseType(it)
                          ),
                          drafts: values.categoryDetails.drafts
                            .filter((_, innerIndex) => index !== innerIndex)
                            .map((details) => fixedLicenseType(details)),
                        },
                      })
                    }
                  />
                </Box>
              </Box>
              <Divider />
            </Fragment>
          ) : null
        })}
      <Box mt={4}>
        <Button
          fullWidth
          variant={'outlined'}
          disabled={isSubmitting}
          onClick={() =>
            setValues({
              ...values,
              categoryDetails: {
                approved: [...values.categoryDetails.approved].map((it) => fixedLicenseType(it)),
                drafts: [
                  ...values.categoryDetails.drafts,
                  categoryDetailsForNewCategory(categories, associationContext.associationID),
                ].map((it) => fixedLicenseType(it)),
              },
            })
          }
        >
          {t().addCategory}
        </Button>
      </Box>
    </Box>
  )
}

function categoryDetailsForNewCategory(
  categories: CategoryOfAssociation[],
  association: AssociationID
): CategoryDetails {
  const category = categories.find((category) => category.association === association)
  return {
    categoryId: category?.id || categories[0].id,
    preferredNumber: '',
    sidecarPartner: '',
    licenseAssociation: association,
    licenseType: firstValidLicenseType(category || categories[0]),
    comment: '',
  }
}

export function loadInitialValuesCategoryDetails(
  licenseWithContext: LicenseWithContext | undefined
): LicenseDraftConfirmValues['categoryDetails'] {
  return {
    drafts: licenseWithContext
      ? sortBy(
          [licenseWithContext.license, ...licenseWithContext.others]
            .filter(({ type }) => type === 'draft')
            .map((license) => fixedLicenseType(license.draft.categoryDetails))
            .filter(truthy),
          (license) => license.categoryId
        )
      : [],
    approved: licenseWithContext
      ? sortBy(
          [licenseWithContext.license, ...licenseWithContext.others]
            .map((license) => (license.type === 'approved' ? license.approved : undefined))
            .filter(truthy),
          (license) => license.categoryId
        )
      : [],
  }
}

export function categoryDetailsSchema() {
  return Yup.object()
    .defined()
    .shape({
      drafts: Yup.array().defined().of(draftLineSchema()),
      approved: Yup.array().defined().of(approvedLineSchema()),
    })
}

function draftLineSchema() {
  return Yup.object().defined().shape({
    preferredNumber: samFields().categoriesDropdown.preferredNumber.optionalValidation,
    sidecarPartner: samFields().categoriesDropdown.sidecarPartner.validation,
    categoryId: samFields().categoriesDropdown.categoryId.validation,
  })
}

function approvedLineSchema() {
  return Yup.object().defined().shape({
    issuedNumber: samFields().categoriesDropdown.issuedNumber.optionalValidation,
    sidecarPartner: samFields().categoriesDropdown.sidecarPartner.validation,
    categoryId: samFields().categoriesDropdown.categoryId.validation,
  })
}
