import { sortBy } from 'lodash'
import { useAllDocuments } from 'app/db/db-contexts/documents-context'
import { UseWithObj } from 'app/db/db-hooks/db-hook-helpers'
import { emptyArray } from 'app/db/db-hooks/empty-hook-helpers'
import { useSportEvents } from 'app/db/db-hooks/main-db-hooks'
import { AutocompleteOption } from 'app/forms/fields'
import { searchName } from 'shared/models/personal-data'
import { sportEventDescription } from 'shared/sport-events/sport-events-service'

export function useUserOptions(): UseWithObj<AutocompleteOption[]> {
  const documents = useAllDocuments()

  const userOptions: AutocompleteOption[] = documents.loadingOrError
    ? emptyArray()
    : Object.values(documents.data).map(({ uid, personalData }) => ({
        id: uid,
        name: searchName(uid, personalData),
      }))
  return { ...documents, data: sortBy(userOptions, (row) => row.name) }
}

export function useSportEventOptions(): UseWithObj<AutocompleteOption[]> {
  const { data, ...rest } = useSportEvents()
  return {
    ...rest,
    data: data.map((event) => ({ id: event.id, name: sportEventDescription(event) })).reverse(),
  }
}
