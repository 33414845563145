import { DB } from 'shared/db/db'
import { todoMigrateAssociation } from 'shared/models/associations'

export const id = '2022-11-10T02-24-20-049Z'

export const description = 'Migrate payment associations'

export async function up(db: DB) {
  const payments = await db.loadAllManualPayments()
  await Promise.all(
    payments.map(async (payment) => {
      if (!payment.association) {
        payment.association = todoMigrateAssociation(payment.association)
        await db.updateManualPayment(payment)
      }
    })
  )
}
