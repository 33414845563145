import { db } from 'app/db/frontend-db'
import { Inscription } from 'shared/sport-events/sport-events'
import { truthy } from 'shared/utils/array'

export async function updateSidecarPartner(
  inscription: Inscription,
  sidecarPartner: string,
  year: number
) {
  await db.setInscriptionSidecarPartner(inscription, sanitizeSidecarPartner(sidecarPartner))
  if (inscription.type === 'enlistedLicenseInscription') {
    const license = await db.loadApprovedLicense(inscription, inscription.category, year)
    if (license) await db.setApprovedLicenseCurrentSidecarPartner(license, sidecarPartner, year)
  }
}

function sanitizeSidecarPartner(partner: string) {
  return partner
    .trim()
    .split('\t')
    .map((s) => s.trim())
    .filter(truthy)
    .join(' ')
    .split(' ')
    .map((s) => s.trim())
    .filter(truthy)
    .join(' ')
}

export async function updateBorrowedTransponder(inscription: Inscription, borrowedTransponder: string) {
  await db.setInscriptionBorrowedTransponder(inscription, borrowedTransponder || null)
}

export async function updateIssuedNumber(inscription: Inscription, issuedNumber: number) {
  await db.setInscriptionIssuedNumber(inscription, issuedNumber)
}
