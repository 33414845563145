import { uniq } from 'lodash'
import { updateBillStatesAfterManualBooking } from 'shared/billing/billing-service'
import { updateTransactionAndLicenseStates } from 'shared/billing/payment-processor'
import {
  AutomaticPaymentWithoutUid,
  DB,
  InscriptionBooking,
  LicenseBooking,
  ManualBooking,
  UserQuery,
} from 'shared/db/db'
import { ensureSportEventIsNotFinalized } from 'shared/sport-events/sport-events-service'

export async function deleteLicenseBooking(db: DB, booking: LicenseBooking) {
  await db.deleteLicenseBooking(booking)
  await updateStates(db, booking.uid)
}

export async function deleteInscriptionBooking(db: DB, booking: InscriptionBooking) {
  await ensureSportEventIsNotFinalized(db, booking.item.sportEventId)
  await db.deleteInscriptionBooking(booking)
  await updateStates(db, booking.uid)
}

export async function unassignInvalidPayment(
  db: DB,
  user: UserQuery,
  booking: AutomaticPaymentWithoutUid
) {
  await db.unassignInvalidPayment(booking)
  await updateStates(db, user.uid)
}

export async function deletePayment(db: DB, user: UserQuery, booking: AutomaticPaymentWithoutUid) {
  await db.deletePayment(booking)
  await updateStates(db, user.uid)
}

export async function deleteManualBooking(db: DB, booking: ManualBooking, admin: UserQuery) {
  await db.deleteManualBooking(booking)
  await db.pushUserEvent({
    id: '',
    type: 'editManualBooking',
    uid: booking.uid,
    byUid: admin.uid,
    date: new Date().toISOString(),
    editType: 'delete',
    details: booking,
  })
  await updateStates(db, booking.uid)
}

export async function pushManualBooking(db: DB, booking: ManualBooking) {
  await db.pushManualBooking(booking)
  await db.pushUserEvent({
    id: '',
    type: 'editManualBooking',
    uid: booking.uid,
    byUid: booking.byUid,
    date: new Date().toISOString(),
    editType: 'add',
    details: booking,
  })
  await updateStates(db, booking.uid)
}

export async function updateManualBooking(db: DB, booking: ManualBooking) {
  await db.updateManualBooking(booking)
  await db.pushUserEvent({
    id: '',
    type: 'editManualBooking',
    uid: booking.uid,
    byUid: booking.byUid,
    date: new Date().toISOString(),
    editType: 'update',
    details: booking,
  })
  await updateStates(db, booking.uid)
}

export async function updateInscriptionBooking(db: DB, booking: InscriptionBooking) {
  await ensureSportEventIsNotFinalized(db, booking.item.sportEventId)
  await db.updateInscriptionBooking(booking)
  await db.pushUserEvent({
    id: '',
    type: 'editInscriptionBooking',
    uid: booking.uid,
    byUid: booking.byUid,
    date: new Date().toISOString(),
    editType: 'update',
    details: booking,
  })
  await updateStates(db, booking.uid)
}

export async function updateLicenseBooking(db: DB, booking: LicenseBooking) {
  await db.updateLicenseBooking(booking)
  await db.pushUserEvent({
    id: '',
    type: 'editLicenseBooking',
    uid: booking.uid,
    byUid: booking.byUid,
    date: new Date().toISOString(),
    editType: 'update',
    details: booking,
  })
  await updateStates(db, booking.uid)
}

export async function pushLicenseBookings(db: DB, bookings: LicenseBooking[]) {
  await Promise.all(bookings.map((booking) => db.pushLicenseBooking(booking)))
  const uids = uniq(bookings.map((booking) => booking.uid))
  await Promise.all(uids.map((uid) => updateStates(db, uid)))
}

export async function updateStates(db: DB, uid: string) {
  await updateTransactionAndLicenseStates(db, uid)
  await updateBillStatesAfterManualBooking(db, { uid })
}
