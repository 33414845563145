import { Fragment } from 'react'
import { SearchLink } from 'app/pages/admin/payments/search-link'
import { Debitor } from 'shared/billing/payment-interfaces'
import { truthy } from 'shared/utils/array'
import { SearchQuery } from 'utils/router'

export function DebitorDetails({ debitor, search }: { debitor: Debitor; search: SearchQuery }) {
  const values = [
    debitor.name,
    ...(debitor.address.addressLines || []),
    [debitor.address.street, debitor.address.streetNumber].filter(truthy).join(' '),
    debitor.address.zip,
    debitor.address.place,
    debitor.address.country,
  ].filter(truthy)

  return (
    <>
      {values.map((line, index) => (
        <Fragment key={index}>
          {index === 0 ? '' : ', '}
          <SearchLink search={search}>{line}</SearchLink>
        </Fragment>
      ))}
    </>
  )
}
