import { addLogo } from 'app/pages/billing/add-association-logo'
import { t } from 'shared/i18n/current'
import { nameWithPlace, PersonalData } from 'shared/models/personal-data'
import { formatDateDe, parseDate } from 'shared/utils/date'
import { PDFDoc, pdfTextFontSize } from 'utils/pdf/pdf'

export async function withGeneratedPdfHeader(
  pdf: PDFDoc,
  personalData: PersonalData,
  fn: (context: RiderPdfContext) => void
) {
  const riderName = nameWithPlace(personalData)
  const birthdateDate = parseDate(personalData.birthdate)
  const birthdate = formatDateDe(birthdateDate)
  const context = { pdf, riderName, birthdate }

  await addLogo('racemanager', pdf)

  pdf.font('Helvetica')
  pdf.fontSize(pdfTextFontSize)
  pdf.moveDown(7)

  pdf.text(`${t().pdfInsurance.generatedOn} ${formatDateDe(new Date())}`)
  pdf.moveDown(5)

  fn(context)

  pdf.text('\n')
}

export interface RiderPdfContext {
  pdf: PDFKit.PDFDocument
  riderName: string
  birthdate: string
}
