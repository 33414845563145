import assertNever from 'assert-never'
import { withGeneratedPdfHeader } from 'app/pages/insurance/withGeneratedPdfHeader'
import { t } from 'shared/i18n/current'
import { PersonalData } from 'shared/models/personal-data'
import { downloadPdf, PDFDoc } from 'utils/pdf/pdf'

export function generateInsuranceForm(personalData: PersonalData, type: InsuranceType) {
  return downloadPdf({ name: 'versicherung' }, (pdf) => generateInsurancePdf(personalData, type, pdf))
}

async function generateInsurancePdf(personalData: PersonalData, type: InsuranceType, pdf: PDFDoc) {
  await withGeneratedPdfHeader(pdf, personalData, (context) => {
    if (type === 'employee') employee(context)
    else if (type === 'independent') independent(context)
    else if (type === 'other') other(context)
    else assertNever(type)
  })
}

function employee({ pdf, riderName, birthdate }: InsuranceContext) {
  pdf.text(`${t().pdfInsurance.fillInByEmployer.title}`, { align: 'center' })
  pdf.moveDown(3)
  pdf.text(`${t().pdfInsurance.fillInByEmployer.companyAddress}`, justify())
  pdf.moveDown(3)
  pdf.text(t().pdfInsurance.fillInByEmployer.confirmAccidentInsurance(riderName, birthdate), justify())
  pdf.moveDown(3)
  pdf.text(`${t().pdfInsurance.insuranceCompanyAndPoliceNumber}`, justify())
  pdf.moveDown(3)
  pdf.text(`${t().pdfInsurance.fillInByEmployer.datePlaceCompanySignature}`, justify())
}

function independent({ pdf, riderName, birthdate }: InsuranceContext) {
  pdf.text(`${t().pdfInsurance.fillInByRider.title}`, { align: 'center' })
  pdf.moveDown(3)
  pdf.text(t().pdfInsurance.fillInByRider.confirmAccidentInsurance(riderName, birthdate), justify())
  pdf.moveDown(3)
  pdf.text(`${t().pdfInsurance.insuranceCompanyAndPoliceNumber}`, justify())
  pdf.moveDown(3)
  pdf.text(`${t().pdfInsurance.fillInByRider.datePlaceRiderSignature}`, justify())
}

function other({ pdf, riderName, birthdate }: InsuranceContext) {
  pdf.text(`${t().pdfInsurance.fillInByRider.title}`, { align: 'center' })
  pdf.moveDown(3)
  pdf.text(t().pdfInsurance.fillInByRider.confirmAccidentInsuranceOther(riderName, birthdate), justify())
  pdf.moveDown(3)
  pdf.text(`${t().pdfInsurance.insuranceCompanyAndPoliceNumber}`, justify())
  pdf.moveDown(3)
  pdf.text(`${t().pdfInsurance.fillInByRider.datePlaceRiderSignature}`, justify())
}

function justify() {
  return { align: 'justify' }
}

interface InsuranceContext {
  pdf: PDFKit.PDFDocument
  riderName: string
  birthdate: string
}

type InsuranceType = 'employee' | 'independent' | 'other'
