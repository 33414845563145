import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'

export function useDebouncedValue<T>(value: T, delayInMs = 200): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    if (isEqual(value, debouncedValue)) return
    const timer = setTimeout(() => setDebouncedValue(value), delayInMs)
    return () => clearTimeout(timer)
  }, [value, delayInMs, debouncedValue])

  return debouncedValue
}
