import { useAllTransactions, useSportEvents } from 'app/db/db-hooks/main-db-hooks'
import { AssociationBalance } from 'app/pages/admin/financials/association-balance'
import { AssociationAndDateFilter, useFilterState } from 'app/pages/admin/financials/date-filter'
import { FinancialKPIs } from 'app/pages/admin/financials/financial-kpis'
import { FlatTransactions } from 'app/pages/admin/financials/flat-ransactions'
import { TransactionSumsForAccounting } from 'app/pages/admin/financials/transaction-sums-for-accounting'
import { TransactionsByTransactionType } from 'app/pages/admin/financials/transactions-by-transaction-type'
import { filterTransactions } from 'app/pages/admin/financials/transactions-filter'
import { TransactionsForAccounting } from 'app/pages/admin/financials/transactions-for-accounting'
import { useUserContext } from 'app/themes/user-context'
import { User } from 'app/users/user'
import { allTransactionKPIs } from 'shared/financials/financials-service'
import { t } from 'shared/i18n/current'

interface TransactionsOverviewProps {
  admin: User
}

export function AllTransactions({ admin }: TransactionsOverviewProps) {
  const userContext = useUserContext()
  const { filter, setFilter } = useFilterState()

  const { data: rawData, loading: transactionsLoading, error: transactionsError } = useAllTransactions()
  const data = filterTransactions(rawData, userContext, filter)

  const { data: sportEvents, loading: sportEventsLoading, error: sportEventsError } = useSportEvents()
  const transactions = data.map(({ transaction }) => transaction)

  const loading = transactionsLoading || sportEventsLoading
  const error = transactionsError || sportEventsError

  return (
    <>
      <AssociationAndDateFilter filter={filter} onChange={(filter) => setFilter(filter)} />

      <AssociationBalance
        title={t().accountBalanceChangeAssociation}
        data={data}
        loading={loading}
        error={error}
      />

      <TransactionSumsForAccounting loading={loading} error={error} transactions={transactions} />

      <TransactionsForAccounting loading={loading} error={error} transactions={transactions} />

      <FinancialKPIs
        title={t().transactionsKPIs}
        kpis={allTransactionKPIs(transactions)}
        loading={loading}
        error={error}
      />

      <TransactionsByTransactionType
        loading={loading}
        error={error}
        sportEvents={sportEvents}
        data={data}
        admin={admin}
      />

      <FlatTransactions
        loading={loading}
        error={error}
        sportEvents={sportEvents}
        data={data}
        admin={admin}
      />
    </>
  )
}
