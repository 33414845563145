import { DB, ShortUids } from 'shared/db/db'
import { parseInt10 } from 'shared/utils/number'

export async function uidOrShortUid(db: DB, uid: string) {
  return (await db.shortUid(uid)) || uid
}

export async function updateShortUids(db: DB, uids: string[]) {
  const allShortUids = await db.allShortUids()
  const unassignedUids = extractUnassignedUids(uids, allShortUids)
  if (unassignedUids.length === 0) return

  const newShortUids = assignNewShortUids(unassignedUids, lastUsedShortUid(allShortUids))
  await db.writeShortUids({
    uidToShortUid: { ...newShortUids.uidToShortUid, ...allShortUids.uidToShortUid },
    shortUidToUid: {
      avoid: 'automatic firebase array conversion',
      ...newShortUids.shortUidToUid,
      ...allShortUids.shortUidToUid,
    },
  })
}

function extractUnassignedUids(uids: string[], allShortUids: ShortUids) {
  const processedUids = extractProcessedUids(allShortUids)
  return uids.filter((uid) => !processedUids.has(uid))
}

function extractProcessedUids(allShortUids: ShortUids) {
  return new Set(Object.values(allShortUids.shortUidToUid))
}

function lastUsedShortUid(allShortUids: ShortUids) {
  const usedShortUids = Object.keys(allShortUids.shortUidToUid)
    .map(parseInt10)
    .filter((num) => !Number.isNaN(num))
  return Math.max(0, ...usedShortUids)
}

function assignNewShortUids(newUids: string[], lastUsedShortUid: number): ShortUids {
  let nextFreeShortUid = lastUsedShortUid + 1
  const uidToShortUid = Object.fromEntries(newUids.map((uid) => [uid, (nextFreeShortUid++).toString()]))
  const shortUidToUid = Object.fromEntries(newUids.map((uid) => [uidToShortUid[uid].toString(), uid]))
  return { uidToShortUid, shortUidToUid }
}
