import { add, format, isAfter, isBefore, isPast, isValid, parse, parseISO } from 'date-fns'
import localeDE from 'date-fns/locale/de'
import localeEN from 'date-fns/locale/en-US'
import localeFR from 'date-fns/locale/fr-CH'
import { Year } from 'shared/data/license-year'

export { parseISO } from 'date-fns'

export function parseISOOrUndefined(date: string | undefined) {
  return date ? parseISO(date) : undefined
}

export function olderThan(date: string | undefined, duration: Duration) {
  return date ? isPast(add(parseISO(date), duration)) : true
}

export function isBetweenISO(date: string, from: string, to: string) {
  return isSameOrAfterISO(date, from) && isSameOrBeforeISO(date, to)
}

export function isSameOrAfterISO(date: string, dateToCompare: string) {
  return date === dateToCompare || isAfter(parseISO(date), parseISO(dateToCompare))
}

export function isSameOrBeforeISO(date: string, dateToCompare: string) {
  return date === dateToCompare || isBefore(parseISO(date), parseISO(dateToCompare))
}

export function isSameOrAfter(date: string, dateToCompare: string) {
  return date === dateToCompare || isAfter(parseDateRequired(date), parseDateRequired(dateToCompare))
}

export function isSameOrBefore(date: string, dateToCompare: string) {
  return date === dateToCompare || isBefore(parseDateRequired(date), parseDateRequired(dateToCompare))
}

export function pFormatDateDe(rawDate: string | undefined) {
  return rawDate ? formatDateDe(parseISO(rawDate)) : ''
}

export function pFormatDateWithSecondsSpaceDe(rawDate: string | undefined) {
  return rawDate ? formatDateWithSecondsSpaceDe(parseISO(rawDate)) : ''
}

export function pFormatTimeWithSeconds(rawDate: string | undefined) {
  return rawDate ? formatTimeWithSeconds(parseISO(rawDate)) : ''
}

export function formatDateDe(rawDate: Date | undefined) {
  return rawDate ? format(rawDate, 'dd.MM.yyyy') : 'unbekanntes Datum'
}

export function formatDateIt(rawDate: Date | undefined) {
  return rawDate ? format(rawDate, 'dd/MM/yyyy') : 'unbekanntes Datum'
}

export function uFormatDate(rawDate: string | undefined) {
  return rawDate ? formatDate(new Date(rawDate)) : ''
}

export function pFormatDate(rawDate: string | undefined) {
  return rawDate ? formatDate(parseISO(rawDate)) : ''
}

export function pDateWeekdayName(rawDate: string | undefined) {
  const date = parseDate(rawDate)
  return date ? locale().localize?.day(date.getDay()) : ''
}

export function formatDate(rawDate: Date | undefined) {
  return rawDate ? format(rawDate, 'yyyy-MM-dd') : ''
}

export function tryParseDateString(rawDate: string): Date | undefined {
  const dateDe = parseDateDe(rawDate)
  if (isValid(dateDe)) return dateDe

  const dateEn = parseDate(rawDate)
  if (isValid(dateEn)) return dateEn

  return undefined
}

export function parseDateDe(rawDate: string): Date | undefined {
  return parse(rawDate, 'dd.MM.yyyy', new Date())
}

export function parseDateRequired(rawDate: string | undefined): Date {
  const date = parseDate(rawDate)
  if (!date) throw new Error(`Date required, ${rawDate} given`)
  return date
}

export function parseDate(rawDate: undefined): undefined
export function parseDate(rawDate: string): Date
export function parseDate(rawDate: string | undefined): Date | undefined
export function parseDate(rawDate: string | undefined): Date | undefined {
  if (!rawDate) return undefined
  return parse(rawDate, 'yyyy-MM-dd', new Date())
}

export function formatDateWithSeconds(date: Date) {
  return format(date, 'yyyy-MM-dd_HH-mm-ss')
}

export function formatDateWithSecondsSpace(date: Date | undefined) {
  if (!date) return ''
  return format(date, 'yyyy-MM-dd HH:mm:ss')
}

export function formatDateWithSecondsSpaceDe(date: Date | undefined) {
  if (!date) return ''
  return format(date, 'dd.MM.yyyy HH:mm:ss')
}

export function formatTimeWithSeconds(date: Date | undefined) {
  if (!date) return ''
  return format(date, 'HH:mm:ss')
}

export function formatForQRReference(date: Date) {
  return format(date, 'yyMMddHHmmss')
}

export function matchesYear(date: string, year: Year) {
  return date.split('-')[0] === `${year}`
}

function locale() {
  return _locale
}

export function setDateLocale(locale: 'de' | 'en' | 'fr') {
  _locale =
    locale === 'de' ? localeDE : locale === 'en' ? localeEN : locale === 'fr' ? localeFR : localeDE
}

let _locale = localeDE

export type DateString = string
