import { Box } from '@material-ui/core'
import { truthy } from 'shared/utils/array'

interface FelxButtonsProps {
  children: (React.ReactChild | boolean)[] | React.ReactChild
  justifyContent?: string
}

export function FlexButtons({ children, justifyContent }: FelxButtonsProps) {
  const mr = justifyContent === 'flex-end' ? 0 : 1
  const ml = mr ? 0 : 1
  const buttons = Array.isArray(children) ? children : [children]
  return (
    <Box display="flex" flexWrap="wrap" justifyContent={justifyContent}>
      {buttons.filter(truthy).map((child, index) => (
        <Box key={index} mb={1} mr={mr} ml={ml}>
          {child}
        </Box>
      ))}
    </Box>
  )
}
