import { SportEventId } from 'shared/db/sport-event-id'
import { CategoryId } from 'shared/models/category'
import { TransponderTypeIncludingEmpty } from 'shared/models/transponder-type'

export function generateDayCategorySearchString(dayCategories: DayCategory[] | { name: string }[]) {
  return dayCategories.map((dayCategory) => dayCategory.name.toLowerCase()).join(' ')
}

export interface DayCategory {
  id: DayCategoryID
  sportEvent: SportEventId
  transponder: TransponderTypeIncludingEmpty

  name: string
  hint: string
  additionalTermsText: string
  additionalTermsURL: string

  price: number

  needsInsurance: boolean
  needsEmergency: boolean
  needsHealthCheck: boolean
  needsBikeInfo: boolean

  advertiseSamInsurance: boolean

  myLapsName: string
  startListName: string

  sidecar: boolean
  sidecarPassenger: boolean

  surcharge: number
  surchargeAfter: string

  discount: number
  discountUntil: string

  inscriptionsLimit: number
  waitlistLimit: number

  pricePower: number
  priceTransponderRental: number
  sortPrimaryBy: 'paymentDate' | 'enlistingDate'

  categoryType: 'dayCategory'
}

export type InscriptionCategoryID = CategoryId | DayCategoryID
export type DayCategoryID = string
