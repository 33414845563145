import { useEffect, createContext, useContext, useState } from 'react'
import { associationsWithLogosPreview } from 'app/themes/associationsWithLogos'
import { useUserContext } from 'app/themes/user-context'
import { AssociationID, FullAssociation } from 'shared/models/associations'
import { useQuery } from 'utils/router'

export function useAssociationState(): AssociationState {
  const enableAssociationPreviewFromQuery = usePreviewAssociationFromQuery()
  const [preview, setPreview] = useState<boolean>(previewing() || enableAssociationPreviewFromQuery)

  const associationFromQuery = useAssociationFromQuery(preview)
  const userContext = useUserContext()
  const [currentAssociationID, setCurrentAssociationID] = useState<AssociationID>(
    userContext.associationAdmin ||
      associationFromQuery ||
      loadAssociationFromLocalStorage(preview) ||
      defaultAssociation().id
  )
  const associationFromAssociationAdmin = userContext.associationAdmin

  const associationID = userContext.associationAdmin || currentAssociationID
  useEffect(() => {
    if (associationFromAssociationAdmin) {
      globalThis.localStorage.setItem('theme', associationFromAssociationAdmin)
    } else if (associationFromQuery) {
      globalThis.localStorage.setItem('theme', associationFromQuery)
    } else if (!loadAssociationFromLocalStorage(preview)) {
      globalThis.localStorage.setItem('theme', currentAssociationID)
    }
  }, [associationFromAssociationAdmin, associationFromQuery, currentAssociationID, preview])

  useEffect(() => {
    if (associationFromAssociationAdmin) {
      setCurrentAssociationID(associationFromAssociationAdmin)
    }
  }, [associationFromAssociationAdmin])

  useEffect(() => {
    if (enableAssociationPreviewFromQuery) {
      activatePreview()
      setPreview(true)
    }
  }, [enableAssociationPreviewFromQuery])

  const association = findAssociationOrDefault(associationID, preview)

  return {
    association,
    associationID: association.id,
    setAssociation(association: AssociationID) {
      globalThis.localStorage.setItem('theme', association)
      setCurrentAssociationID(association)
    },
    previewing: preview,
    activatePreview() {
      activatePreview()
      setPreview(true)
    },
    deactivatePreview() {
      deactivatePreview()
      setPreview(false)
    },
  }
}

function useAssociationFromQuery(previewing: boolean): AssociationID | undefined {
  const query = useQuery()
  const queryTheme = query.query.get('theme')

  const association = associationsWithLogosPreview(previewing).find(
    (association) => association.id === queryTheme
  )

  return association?.id
}

function usePreviewAssociationFromQuery() {
  const query = useQuery()
  return query.query.get('preview') === 'true'
}

export function useAssociationContext() {
  return useContext(AssociationContext)
}

export const AssociationContext = createContext(initialState())

function initialState(): AssociationState {
  const associationID = loadAssociationFromLocalStorage(true) || defaultAssociation().id
  const association =
    associationsWithLogosPreview(true).find((association) => association.id === associationID) ||
    defaultAssociation()
  return {
    association,
    associationID,
    previewing: false,
    setAssociation: () => undefined,
    activatePreview: () => undefined,
    deactivatePreview: () => undefined,
  }
}

export interface AssociationState {
  association: FullAssociation
  associationID: AssociationID
  setAssociation: (association: AssociationID) => void
  previewing: boolean
  activatePreview: () => void
  deactivatePreview: () => void
}

export function loadAssociationFromLocalStorage(previewing: boolean): AssociationID | undefined {
  const themeName = globalThis.localStorage.getItem('theme')
  const association = associationsWithLogosPreview(previewing).find(
    (association) => association.id === themeName
  )
  return association?.id
}

function findAssociationOrDefault(id: AssociationID, previewing: boolean) {
  return (
    associationsWithLogosPreview(previewing).find((association) => association.id === id) ||
    defaultAssociation()
  )
}

function defaultAssociation() {
  const associations = associationsWithLogosPreview(true)
  return associations.find((association) => association.id === 'sam') || associations[0]
}

function previewing() {
  return globalThis.localStorage.getItem('preview') === 'true'
}

function activatePreview() {
  globalThis.localStorage.setItem('preview', 'true')
}

function deactivatePreview() {
  globalThis.localStorage.removeItem('preview')
}
