export const externalLinks = {
  homepages: {
    sam: 'https://www.s-a-m.ch/',
    fms: 'https://www.swissmoto.org/',
    mxrs: 'https://www.mxrs.ch/',
    sjmcc: 'https://www.sjmcc.ch/',
    afm: 'https://afm-moto.ch/',
  },
  changeSection: 'https://www.s-a-m.ch/Dienste/Mitglieder-Dienste/Sektionswechsel',
  healthCheckForm: {
    de: 'https://www.s-a-m.ch/Portals/0/Daten/Lizenzen/Gesundheits-Check_d.pdf',
    fr: 'https://www.s-a-m.ch/Portals/0/Daten/Lizenzen/Gesundheits-Check_f.pdf',
  },
  websiteEvents: 'https://www.s-a-m.ch/Termine/Termine-anzeigen',
  backyardRacingDecor:
    'https://www.backyard-racing.ch/backyard-racing-dekorkit,-personalisiert/p-22113.html?shop_ID=1#utm_source=sam&utm_medium=newsletter&utm_campaign=lizenzen&utm_content=SAM_DEKOR',
  samLicenseTerms: 'https://www.s-a-m.ch/Portals/0/Daten/Lizenzen/Lizenzgesuch_Bedingungen.pdf',
  samInsuranceTerms: 'https://www.s-a-m.ch/Sport/Lizenzen-Reglemente/Rennunfallversicherung',
  sjmccLicenseTerms: 'https://sjmcc.ch/der-club/downloads',
  mxrsLicenseTerms: 'https://www.mxrs.ch/de/Motocross/Veranstaltungen/Reglement.html',
  fmsLicenseTerms: {
    de: 'https://www.swissmoto.org/pub/uploads/editor/files/Lizenzgesuche/Lizenzbedingungen%20d_f.pdf',
    fr: 'https://www.swissmoto.org/pub/uploads/editor/files/Lizenzgesuche/Lizenzbedingungen%20d_f.pdf',
  },
  afmLicenseTerms: {
    fr: 'https://afm-moto.ch/conditions/',
  },
  fmsInsuranceTerms:
    'https://www.swissmoto.org/pub/uploads/editor/files/Formulare%20-%20Protokolle/Lizenzanforderungen_Versicherungen_d(1).pdf',
}
