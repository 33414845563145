import { useAssociationContext } from 'app/themes/association-context'
import { useUserContext } from 'app/themes/user-context'
import { useYearContext } from 'app/themes/year-context'
import { Year } from 'shared/data/license-year'
import { AssociationID } from 'shared/models/associations'

export function useAdminContext(): AdminContext {
  const yearContext = useYearContext()
  const associationContext = useAssociationContext()
  const userContext = useUserContext()

  return {
    admin: userContext.admin,
    year: yearContext.year,
    association:
      userContext.associationAdmin === false
        ? false
        : userContext.associationAdmin || associationContext.associationID,
    matchesAssociation(objectAssociation: AssociationID | undefined) {
      const association = objectAssociation || 'sam'
      return userContext.admin || association === userContext.associationAdmin
    },
    matchesCurrentAssociation(objectAssociation: AssociationID | undefined) {
      const association = objectAssociation || 'sam'
      return userContext.admin
        ? association === associationContext.associationID
        : association === userContext.associationAdmin
    },
  }
}

export interface AdminContext {
  admin: boolean
  year: Year | undefined
  association: AssociationID | undefined | false
  matchesAssociation: (association: AssociationID | undefined) => boolean
  matchesCurrentAssociation: (association: AssociationID | undefined) => boolean
}
