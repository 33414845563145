import { Box, Typography } from '@material-ui/core'
import {
  TransponderFormContents,
  TransponderFormFields,
} from 'app/license/steps/transponder-form-fields'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'

export function AdministrateTransponder({
  user,
  values,
}: {
  user: UserQuery
  values: TransponderFormContents
}) {
  return (
    <Box mb={2} mt={4}>
      <Typography variant="h4" component="h3" gutterBottom>
        {t().transponders.title}
      </Typography>
      <TransponderFormFields user={user} values={values} prefix="transponder." adminView />
    </Box>
  )
}
