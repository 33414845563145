import { useNewTransponders } from 'app/db/db-hooks/main-db-hooks'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { myLapsTransponderNumbers, rfTransponderNumbers } from 'shared/models/transponder'

interface LicensesProps {
  admin: UserQuery
}

export function NewTransponders({ admin }: LicensesProps) {
  const { data, loading, error, loadingOrError } = useNewTransponders()

  return (
    <TableBox
      title={t().transponders.transponderOrders}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: [
            { value: t().firstName },
            { value: t().lastName },
            { value: t().street, exportOnly: true },
            { value: t().zip, exportOnly: true },
            { value: t().place, exportOnly: true },
            { value: t().country, exportOnly: true },
            { value: t().email, exportOnly: true },
            { value: t().phone, exportOnly: true },
            { value: 'MyLaps' },
            { value: 'RF' },
            { value: t().transponders.orderedTransponderIds, exportOnly: true },
            { value: t().transponders.orderedTransponders },
            actions(),
          ],
          // eslint-disable-next-line react/display-name
          contents: data.map(({ documents, transponders }) => () => [
            documents.personalData?.lastName || '',
            documents.personalData?.firstName || '',
            documents.personalData?.street || '',
            documents.personalData?.zip || '',
            documents.personalData?.place || '',
            documents.personalData?.country || '',
            documents.personalData?.email || '',
            documents.personalData?.phone || '',
            myLapsTransponderNumbers(documents.transponder) || '-',
            rfTransponderNumbers(documents.transponder) || '-',
            transponders.map(({ id }) => id).join(', '),
            <>
              {transponders.map((option) => (
                <div key={option.id}>{option.name()}</div>
              ))}
            </>,
            <>
              <FinancialDialog admin={admin} user={{ uid: documents.uid }} />
            </>,
          ]),
          ids: data.map(({ documents: { uid } }) => uid),
          rawData: data.map((row) => JSON.stringify(row)),
        }
      }
    />
  )
}
