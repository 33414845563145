import { Grid } from '@material-ui/core'
import type { FormikProps } from 'formik'
import * as Yup from 'yup'
import { useAllManualPaymentTags } from 'app/db/db-hooks/financial-db-hooks'
import { samFields } from 'app/forms/fields'
import { useUserContext } from 'app/themes/user-context'
import { t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { Dig } from 'shared/utils/tsc'
import { Loading } from 'utils/loading'

export function ManualPaymentFields(props: { form: FormikProps<ManualPaymentFormData> }) {
  const { form } = props
  const { touched, errors } = form
  const manualPaymentTags = useAllManualPaymentTags()
  const userContext = useUserContext()

  const fields = samFields().manualPayment

  return manualPaymentTags.loadingOrError ? (
    <Loading />
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {fields.association.field({ disabled: !!userContext.associationAdmin })}
      </Grid>
      <Grid item xs={12}>
        {fields.tag.field({
          touched,
          errors,
          tags: manualPaymentTags.data[form.values.association] || [],
        })}
      </Grid>
      <Grid item xs={12}>
        {fields.amount.field()}
      </Grid>
      <Grid item xs={12}>
        {fields.date.field()}
      </Grid>
      <Grid item xs={12}>
        {fields.internalRemarks.field()}
      </Grid>
    </Grid>
  )
}

export function manualPaymentSchema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().manualPayment).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['manualPayment']>
  return Yup.object().defined().shape(validations)
}

export function bookingKindText(amount: number) {
  return amount <= 0 ? t().financials.credit : t().financials.debit
}

export interface ManualPaymentFormData {
  amount: number
  date: Date
  tag: string
  association: AssociationID
  internalRemarks: string
}
