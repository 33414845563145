import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import type { History } from 'history'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FacebookBlueBackgroundButton, GoogleRedBackgroundButton } from 'app/layouts/sign-in-form'
import { EmailForm as ChangeEmailForm } from 'app/personal-data/email-form'
import {
  changeEmail,
  hasEmailProvider,
  hasFacebookProvider,
  hasGoogleProvider,
  reauthenticateWithEmail,
  reauthenticateWithFacebook,
  reauthenticateWithGoogle,
} from 'app/users/auth'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { base64Encode } from 'shared/utils/base64'
import { FriendlyError } from 'utils/errors'
import { Disp } from 'utils/react'

export function ChangeEmail({ user }: { user: User }) {
  const history = useHistory()
  const [reauthenticateEmail, setReauthenticateEmail] = useState('')
  const [error, setError] = useState<any>(null)
  const [popupError, setPopupError] = useState('')
  const [popupOpened, setPopupOpened] = useState(false)
  const [linkSent, setLinkSent] = useState(false)

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().personalDataBox.changeEmail.title}
        </Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <FriendlyError pb={2} error={error} />
          <FriendlyError pb={2} error={popupError} />

          {!reauthenticateEmail && (
            <ChangeEmailForm
              user={user}
              onSubmit={(newEmail) =>
                tryToChangeEmail({ newEmail, user, setError, setReauthenticateEmail, history })
              }
              onCancel={() => history.goBack()}
            />
          )}

          {reauthenticateEmail && (
            <Grid container spacing={1}>
              {!linkSent && (
                <Grid item xs={12}>
                  <Typography>{t().personalDataBox.changeEmail.securityHint}</Typography>
                </Grid>
              )}

              {linkSent && (
                <Grid item xs={12}>
                  <Alert
                    severity="success"
                    variant="outlined"
                    onClose={() => history.replace(routes.userDashboard.to)}
                  >
                    <AlertTitle>{t().personalDataBox.changeEmail.startEmailChangeTitle}</AlertTitle>
                    {t().personalDataBox.changeEmail.startEmailChangeBefore}{' '}
                    <strong>{user.email}</strong> {t().personalDataBox.changeEmail.startEmailChangeAfter}
                  </Alert>
                </Grid>
              )}

              {hasEmailProvider(user) && (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={popupOpened || linkSent}
                    onClick={async () => {
                      setPopupOpened(true)
                      await reauthenticateWithEmail(user, reauthenticateEmail, setPopupError)
                      setPopupOpened(false)
                      setLinkSent(true)
                    }}
                  >
                    Bestätigen mit {user.email}
                  </Button>
                </Grid>
              )}

              {hasGoogleProvider(user) && (
                <Grid item xs={12}>
                  <GoogleRedBackgroundButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={popupOpened || linkSent}
                    onClick={async () => {
                      setPopupOpened(true)
                      await reauthenticateWithGoogle(user, setPopupError)
                      setLinkSent(false)
                      await tryToChangeEmail({
                        newEmail: reauthenticateEmail,
                        user,
                        setError,
                        setReauthenticateEmail,
                        history,
                      })
                    }}
                  >
                    {t().personalDataBox.changeEmail.confirmWithGoogle}
                  </GoogleRedBackgroundButton>
                </Grid>
              )}

              {hasFacebookProvider(user) && (
                <Grid item xs={12}>
                  <FacebookBlueBackgroundButton
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={popupOpened || linkSent}
                    onClick={async () => {
                      setPopupOpened(true)
                      await reauthenticateWithFacebook(user, setPopupError)
                      setPopupOpened(false)
                      await tryToChangeEmail({
                        newEmail: reauthenticateEmail,
                        user,
                        setError,
                        setReauthenticateEmail,
                        history,
                      })
                    }}
                  >
                    {t().personalDataBox.changeEmail.confirmWithFacebook}
                  </FacebookBlueBackgroundButton>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Paper>
    </>
  )
}

export async function tryToChangeEmail({
  newEmail,
  user,
  setError,
  setReauthenticateEmail,
  history,
}: {
  newEmail: string
  user: User
  setError: Disp<any>
  setReauthenticateEmail: Disp<string>
  history: History<unknown>
}): Promise<void> {
  try {
    await user.reload()
    const status = await changeEmail(user, newEmail)
    setError(null)
    if (status === 'requires-recent-login') setReauthenticateEmail(newEmail)
    else history.push(`${routes.userDashboard.to}?email-changed-to=${base64Encode(newEmail)}`)
  } catch (error) {
    setError(error)
  }
}
