import { sortBy } from 'lodash'
import { MaybeFBError } from 'app/db/db-hooks/db-hook-helpers'
import { tableHeaders } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { AccountingGroup } from 'app/pages/admin/financials/accounting-group'
import { accountingGroupSumName } from 'app/pages/admin/financials/accounting-group-name'
import { accountingStatement } from 'app/pages/admin/financials/accounting-statement'
import { BookingRelevantTransaction } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { groupByLiteral } from 'shared/utils/array'
import { toChf } from 'shared/utils/number'

interface TransactionsForAccountingProps {
  loading: boolean
  error: MaybeFBError
  transactions: BookingRelevantTransaction[]
}

export function TransactionSumsForAccounting(props: TransactionsForAccountingProps) {
  const { loading, error, transactions } = props
  const loadingOrError = loading || error

  const groups = accountingStatement(transactions)
  const groupedByType = Object.values(groupByLiteral(groups, (group) => group.type))
  const combined = groupedByType.map((groups) =>
    groups.reduce<AccountingGroupSum>(
      (previous, current) => ({ ...previous, value: previous.value + current.value }),
      { type: groups[0].type, value: 0 }
    )
  )
  const sorted = sortBy(combined, (group) => accountingGroupSumName(group.type))

  return (
    <TableBox
      title={t().financials.summarizedPositionsForBilling}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: tableHeaders([t().position, { value: t().amountTableCHF, align: 'right' }]),
          contents: sorted.map((row) => [accountingGroupSumName(row.type), toChf(row.value)]),
          ids: sorted.map((row) => row.type),
          rawData: sorted.map((row) =>
            JSON.stringify({ id: row.type, value: row.value, name: accountingGroupSumName(row.type) })
          ),
        }
      }
    />
  )
}

interface AccountingGroupSum {
  type: AccountingGroup['type']
  value: number
}
