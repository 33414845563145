import { useEffect, useState } from 'react'

export function useTime(intervalInMs: number) {
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), intervalInMs)
    return () => clearInterval(interval)
  }, [intervalInMs])

  return time
}
