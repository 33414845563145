import { uniq } from 'lodash'
import { t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { TransponderType } from 'shared/models/transponder-type'
import { truthy } from 'shared/utils/array'
import { toNumberIfPossibleOrUndefined, validNumber } from 'shared/utils/number'
import { ArrayElement } from 'shared/utils/tsc'

export const transponderOptions: TransponderOption[] = [
  {
    id: 'subscriptionOneYear',
    name: () => `${t().transponders.transponderNames.subscriptionOneYear}, CHF 119.00`,
    shortName: () => t().transponders.transponderNames.subscriptionOneYear,
    price: 119,
    type: 'MyLaps',
    association: 'sam',
  },
  {
    id: 'subscription',
    name: () => `${t().transponders.transponderNames.subscription}, CHF 299.00`,
    shortName: () => t().transponders.transponderNames.subscription,
    price: 299,
    type: 'MyLaps',
    association: 'sam',
  },
  {
    id: 'purchase',
    name: () => `${t().transponders.transponderNames.purchase}, CHF 485.00`,
    shortName: () => t().transponders.transponderNames.purchase,
    price: 485,
    type: 'MyLaps',
    association: 'sam',
  },
  {
    id: 'rf-purchase',
    name: () => `${t().transponders.transponderNames.rfPurchase}, CHF 80.00`,
    shortName: () => t().transponders.transponderNames.rfPurchase,
    price: 80,
    type: 'RF',
    association: 'fms',
  },
  {
    id: 'rfPurchaseAfm',
    name: () => `${t().transponders.transponderNames.rfPurchaseAfm}, CHF 75.00`,
    shortName: () => t().transponders.transponderNames.rfPurchaseAfm,
    price: 75,
    type: 'RF',
    association: 'afm',
  },
]

export function enableTransponderOption(
  option: TransponderOption,
  categories: CategoryOfAssociation[],
  alreadySelected: TransponderOptionId[]
): boolean {
  if (alreadySelected.includes(option.id)) return true

  const neededTransponders = uniq(categories.flatMap(({ transponders }) => transponders))
  const noTransponderNeeded = neededTransponders.length === 0
  const needsMyLaps = neededTransponders.includes('MyLaps')
  const needsRF = neededTransponders.includes('RF')
  const afmLicense = categories.some((category) => category.association === 'afm')
  const fmsLicense = categories.some((category) => category.association === 'fms')

  return noTransponderNeeded
    ? option.id !== 'rfPurchaseAfm'
    : needsMyLaps && option.type === 'MyLaps'
    ? true
    : !needsRF
    ? false
    : afmLicense && !fmsLicense
    ? option.id === 'rfPurchaseAfm'
    : option.id === 'rf-purchase'
}

export function originalTransponderOption() {
  return transponderOptions[0]
}

export function legacyTransponderOption(id: LegacyTransponderOptionId | undefined) {
  return transponderOptions.find((option) => option.id === id) || transponderOptions[0]
}

export function transponderOptionRequired(id: TransponderOptionId | undefined) {
  const option = transponderOptions.find((option) => option.id === id)
  if (!option) throw new Error(`Transponder option ${id} not found`)
  return option
}

export function transponderOption(id: TransponderOptionId | undefined) {
  return transponderOptions.find((option) => option.id === id)
}

export function validTransponderInput(numbersAsString: string | undefined, required: boolean) {
  if (!required && !numbersAsString) return true

  const numbers = splitTransponderNumbers(numbersAsString)
  if (required && numbers.length === 0) return false

  const validNumbers = (numbersAsString || '')
    .split(',')
    .map((number) => number.trim())
    .every((number) => validNumber(number))
  if (!validNumbers) return false

  return true
}

export function hasAtLeastOneTransponder(numbers: string | undefined) {
  return splitTransponderNumbers(numbers).length > 0
}

export function splitTransponderNumbers(numbers: string | undefined) {
  return (numbers || '')
    .split(',')
    .map((number) => number.trim())
    .map(toNumberIfPossibleOrUndefined)
    .filter(truthy)
    .filter((number) => number > 0)
}

export function hasMyLapsTransponder(transponder: Transponder | undefined) {
  return !!transponder && myLapsTransponders(transponder).length > 0
}

export function myLapsTransponderMakes(transponder: Transponder | undefined) {
  return myLapsTransponders(transponder)
    .map((t) => t.make)
    .join(', ')
}

export function myLapsTransponderNumbers(transponder: Transponder | undefined) {
  return myLapsTransponders(transponder)
    .map((t) => t.transponderNumber)
    .join(', ')
}

export function myLapsTransponders(transponder: Transponder | undefined) {
  return Object.values(transponder?.transponders || {}).filter((t) => t.type === 'MyLaps')
}

export function hasRfTransponder(transponder: Transponder | undefined) {
  return !!transponder && rfTransponders(transponder).length > 0
}

export function rfTransponderNumbers(transponder: Transponder | undefined) {
  return rfTransponders(transponder)
    .map((t) => t.transponderNumber)
    .join(', ')
}

export function rfTransponders(transponder: Transponder | undefined) {
  return Object.values(transponder?.transponders || {}).filter((t) => t.type === 'RF')
}

export interface Transponder {
  orderedTransponders: TransponderOptionId[] | undefined
  transponders: Record<string, NewTransponder> | undefined
}

export interface NewTransponder {
  type: TransponderType
  make?: string | undefined
  transponderNumber: number | undefined
}

export interface TransponderOption {
  id: TransponderOptionId
  name: () => string
  shortName: () => string
  price: number
  type: TransponderType
  association: AssociationID
}

export type LegacyTransponderOptionId = 'subscription' | 'purchase' | ''
export type TransponderOptionId = ArrayElement<typeof transponderOptionIds>
export const transponderOptionIds = [
  'subscriptionOneYear',
  'subscription',
  'purchase',
  'rf-purchase',
  'rfPurchaseAfm',
] as const
