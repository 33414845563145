import { DayCategory, DayCategoryID } from 'shared/db/day-category'
import { InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { associationName } from 'shared/models/associations'
import { Bike } from 'shared/models/bike'
import {
  bikesForInscription,
  exportEnlistedDayInscriptionDayCategoryToPublicInscription,
  exportEnlistedDayInscriptionYearCategoryToPublicInscription,
  exportEntryToStartingListCategoryWithLicense,
  exportListToMylaps,
  sportEventExportOptions,
} from 'shared/my-laps/mylaps-export'
import { isEnlistedInscription } from 'shared/sport-events/sport-events'
import { truthy } from 'shared/utils/array'

export function exportBikesToCsv(
  inscriptions: InscriptionWithContextAndSportEvent[],
  dayCategories: Record<DayCategoryID, DayCategory>
) {
  const data = inscriptions
    .map((inscription) =>
      exportBikes({
        inscription,
        bikes: bikesForInscription(inscription.inscription, inscription.documents),
        dayCategories,
      })
    )
    .filter(truthy)
  return exportListToMylaps(data)
}

interface ExportBikesProps {
  inscription: InscriptionWithContextAndSportEvent
  bikes: Bike[]
  dayCategories: Record<DayCategoryID, DayCategory>
}

function exportBikes(props: ExportBikesProps): Record<string, string | number> | undefined {
  const { inscription, bikes, dayCategories } = props

  const approved = inscription.licenseWithContext?.license.approved
  const documents = inscription.documents

  const exportOptions = sportEventExportOptions(inscription.sportEvent)

  const data = approved
    ? exportEntryToStartingListCategoryWithLicense(
        approved,
        documents,
        isEnlistedInscription(inscription.inscription) ? inscription.inscription : undefined,
        exportOptions
      )
    : inscription.inscription.type === 'enlistedDayInscriptionYearCategory'
    ? exportEnlistedDayInscriptionYearCategoryToPublicInscription({
        inscription: inscription.inscription,
        documents,
        exportOptions,
        inscribed: true,
      })
    : inscription.inscription.type === 'enlistedDayInscriptionDayCategory'
    ? exportEnlistedDayInscriptionDayCategoryToPublicInscription({
        inscription: inscription.inscription,
        documents,
        dayCategory: dayCategories[inscription.inscription.category],
        exportOptions,
        inscribed: true,
      })
    : undefined

  if (!data) return undefined

  const bikesFields = bikes.map((bike, index) => ({
    [`${t().bikeMake.labelShort}-${index + 1}`]: bike.bikeMake,
    [`${t().bikeModel.labelShort}-${index + 1}`]: bike.bikeModel,
    [`${t().frameNumber}-${index + 1}`]: bike.frameNumber,
    ...(inscription.sportEvent.sportEventType === 'enduro'
      ? {
          [`${t().numberPlate.label}-${index + 1}`]:
            bike.categoryType === 'enduro' ? bike.numberPlate : '-',
          [`${t().bikeInsurance.label}-${index + 1}`]:
            bike.categoryType === 'enduro' ? bike.insurance : '-',
        }
      : {}),
  }))

  return {
    Status: inscription.status,
    Typ: inscription.inscription.type,
    Bezahlt: inscription.inscription.paid ? 'x' : '',
    ManuellVerifiziert:
      'manuallyVerified' in inscription.inscription && inscription.inscription.manuallyVerified
        ? 'x'
        : '',
    Startnummer: data.startingNumber,
    Klasse: data.categoryName,
    Vorname: documents.personalData?.firstName,
    Nachname: documents.personalData?.lastName,
    Email: documents.personalData?.email,
    ...Object.assign({}, ...bikesFields),
    Sektion: data.section,
    Club: associationName({ association: data.association }),
  }
}
