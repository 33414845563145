import { Box, Typography } from '@material-ui/core'
import { useFullDashboardData, useLicenseTasksOverview } from 'app/db/db-hooks/main-db-hooks'
import { InscriptionBox } from 'app/inscription/inscription-box'
import { AdminBox } from 'app/pages/dashboard/admin-box'
import { AlertBox } from 'app/pages/dashboard/alert-box'
import { ApprovedLicensesBox } from 'app/pages/dashboard/approved-licenses-box'
import { BikesBox } from 'app/pages/dashboard/bikes-box'
import { DocumentsBox } from 'app/pages/dashboard/documents-box'
import { DraftLicensesBox } from 'app/pages/dashboard/draft-licenses-box'
import { FinancialBox } from 'app/pages/dashboard/financial-box'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import { OverviewBox } from 'app/pages/dashboard/overview-box'
import { PersonalDataBox } from 'app/pages/dashboard/personal-data-box'
import { TransponderBox } from 'app/pages/dashboard/transponder-box'
import { useUserContext } from 'app/themes/user-context'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { categoryById } from 'shared/data/categories-service'
import { nextLicenseYear } from 'shared/data/license-config'
import { t } from 'shared/i18n/current'
import { CategoryDetailsWithCategory } from 'shared/models/category-details'
import { RoutedButton } from 'utils/buttons/routed-button'

export function UserDashboard({ user }: { user: User }) {
  const { data, loading, error, loadingOrError } = useFullDashboardData(user)
  const {
    licenseData,
    categoryDetails,
    needsHealthCheck,
    needsInsurance,
    needsEmergency,
    inscriptions,
  } = data
  const { data: licenseTasksData } = useLicenseTasksOverview(user, nextLicenseYear)

  const documents = licenseData.documents
  const personalData = documents?.personalData
  const processedLicenses = licenseData.licenseDrafts?.summary?.processed
  const approvedLicenses = licenseData.approvedLicenses || []
  const approvedLicensesOfNextYear = approvedLicenses.filter(
    (license) => categoryById(license.categoryId)?.year === nextLicenseYear
  )
  const userContext = useUserContext()

  const draftLicenses: CategoryDetailsWithCategory[] =
    categoryDetails &&
    (approvedLicensesOfNextYear.length === 0 || categoryDetails.length > 0) &&
    processedLicenses &&
    personalData
      ? categoryDetails
      : []

  return (
    <>
      <AlertBox />

      <Box mt={3} />

      {userContext.adminOrAssociationAdmin && (
        <Box pb={1}>
          <RoutedButton to={routes.adminDashboard.to}>{routes.adminDashboard.textAlt}</RoutedButton>
        </Box>
      )}

      <Box>
        <Typography component="h2" variant="h2">
          {t().dashboard.title}
        </Typography>
      </Box>

      {loadingOrError ? (
        <LoadingOrErrorBox error={error} loading={loading} />
      ) : (
        <>
          <AdminBox />

          {licenseTasksData?.allDone && documents && (
            <InscriptionBox
              user={user}
              documents={documents}
              licenseTasks={licenseTasksData}
              approvedLicenses={approvedLicenses}
              draftLicenses={draftLicenses}
              inscriptions={inscriptions}
            />
          )}

          <OverviewBox user={user} />

          {(approvedLicensesOfNextYear.length === 0 || draftLicenses.length > 0) && documents && (
            <DraftLicensesBox
              user={user}
              documents={documents}
              personalData={personalData}
              processedLicenses={processedLicenses}
              draftLicenses={draftLicenses}
            />
          )}

          {approvedLicenses.length > 0 && documents && (
            <ApprovedLicensesBox approvedLicenses={approvedLicenses} documents={documents} />
          )}

          <PersonalDataBox documents={documents} user={user} />
          <DocumentsBox
            documents={documents}
            needsHealthCheck={needsHealthCheck}
            needsInsurance={needsInsurance}
            needsEmergency={needsEmergency}
          />
          <FinancialBox user={user} admin={user} />
          <TransponderBox transponder={documents?.transponder} />
          {licenseTasksData?.allDone && <BikesBox bikes={Object.values(documents?.bikes || {})} />}
          {licenseTasksData && !licenseTasksData.allDone && (
            <BikesBox
              bikes={Object.values(documents?.bikes || {}).filter((bike) => bike.status !== 'deleted')}
            />
          )}

          {licenseTasksData && !licenseTasksData.allDone && documents && (
            <InscriptionBox
              user={user}
              documents={documents}
              licenseTasks={licenseTasksData}
              approvedLicenses={approvedLicenses}
              draftLicenses={draftLicenses}
              inscriptions={inscriptions}
            />
          )}
        </>
      )}
    </>
  )
}
