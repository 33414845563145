import { Box, Button, TextField } from '@material-ui/core'
import { CloudDownload, Sync, Update } from '@material-ui/icons'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { downloadBikes } from 'app/bike/bikes-download'
import { useSportEventWithInscriptions } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { exportEmergencyPDFs } from 'app/emergency/export-emergency'
import { actions } from 'app/export/table'
import { AccountBalance } from 'app/financials/account-balance'
import { InscriptionDetailsButton, InscriptionDetails } from 'app/inscription/inscription-details-button'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { Multiline } from 'app/layout/multiline'
import { useRacemanagerTitle } from 'app/layouts/route-with-error-boundary-and-title'
import { TableBox } from 'app/layouts/table-box'
import { EditLicenseButton } from 'app/license/edit-license-button'
import { ScanQRLicenseButton } from 'app/license/qr-code/scan-qr-license-button'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { AddInscriptionButton } from 'app/pages/admin/inscriptions/add-inscription-button'
import { DownloadInscriptionsForChronoButton } from 'app/pages/admin/inscriptions/DownloadInscriptionsForChronoButton'
import { DownloadInscriptionsForEnduroButton } from 'app/pages/admin/inscriptions/DownloadInscriptionsForEnduroButton'
import { openInscriptionTasksShortName } from 'app/pages/admin/inscriptions/openInscriptionTasks'
import { EmergencyDetailsButton } from 'app/pages/admin/licenses/emergency-button'
import { LicenseAttachmentsButton } from 'app/pages/admin/licenses/license-attachments-button'
import { ImpersonateButton } from 'app/pages/admin/users/user-management'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { FinancialBox } from 'app/pages/dashboard/financial-box'
import { AdminContext, useAdminContext } from 'app/themes/admin-context'
import { useAssociationContext } from 'app/themes/association-context'
import { useIsAdminOrAssociationAdmin } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { updateAllInscriptions, updateAllTransactionStates } from 'shared/billing/payment-processor'
import { routes } from 'shared/config/routes'
import { categoryByIdRequired, isCategoryId } from 'shared/data/categories-service'
import { sectionNameWithIdById } from 'shared/data/sections'
import { InscriptionWithContextAndSportEvent, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import {
  inscriptionCategoryName,
  inscriptionIssuedOrPreferredNumber,
} from 'shared/inscription/inscription-categories-service'
import { transponderDescription } from 'shared/inscription/inscription-helpers'
import { migrateInscriptions } from 'shared/inscription/inscription-service'
import { recalculateInscriptionStatistics } from 'shared/inscription/inscription-statistics-service'
import { inscriptionStates } from 'shared/inscription/inscription-status'
import { updateInscriptionListsOfSportEvent } from 'shared/inscription/public-inscription-list'
import { associationNameWithDefault } from 'shared/models/associations'
import { Inscription, isEnlistedInscription, SportEvent } from 'shared/sport-events/sport-events'
import { sportEventCategories, sportEventDescription } from 'shared/sport-events/sport-events-service'
import { groupByLiteral, truthy } from 'shared/utils/array'
import {
  formatDateDe,
  formatDateWithSecondsSpace,
  parseDate,
  parseISOOrUndefined,
} from 'shared/utils/date'
import { BreadcrumbsList } from 'utils/breadcrumbs'
import { RoutedButton } from 'utils/buttons/routed-button'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'
import { useSearchQuery } from 'utils/router'

export function Inscriptions({ admin }: { admin: UserQuery }) {
  const search = useSearchQuery()
  const isAdmin = useIsAdminOrAssociationAdmin()
  const { sportEventId } = useParams<{ sportEventId: string }>()
  const query = { q: search.q, sportEventId }
  const { data, loading, error, loadingOrError } = useSportEventWithInscriptions(query)
  const { sportEvent, inscriptions, dayCategories = {} } = data
  const refreshError = useError()
  const [refreshLoading, setRefreshLoading] = useState(false)
  const year = useLicenseYear()
  const canDisplaySidecarCategory = false
  const displaySidecarCategories =
    canDisplaySidecarCategory &&
    sportEvent &&
    [...sportEventCategories(sportEvent), ...Object.values(dayCategories)].some(
      (category) => category.sidecar || category.sidecarPassenger
    )
  const association = useAssociationContext()
  const adminContext = useAdminContext()
  const title = `${t().inscription.title} ${sportEventDescription(sportEvent)}`
  useRacemanagerTitle(title)

  const inscribedInscriptions = inscriptions.filter((inscription) => inscription.status === 'inscribed')
  const inscribedAndPendingInscriptions = inscriptions.filter(
    ({ status }) => status === 'inscribed' || status === 'inscription-pending'
  )

  return (
    <>
      <BreadcrumbsList
        base="adminSportEvents"
        links={[
          [
            routes.inscriptions.generateTo(query.q, query.sportEventId),
            routes.inscriptions.textAlt(sportEvent),
          ],
        ]}
      />
      <FriendlyError error={refreshError.error} />
      <TableBox
        title={title}
        loading={loading || refreshLoading}
        error={error}
        exports={
          <>
            {isAdmin && (
              <Button
                startIcon={<CloudDownload />}
                onClick={() =>
                  exportEmergencyPDFs(
                    inscriptions
                      .map((row) =>
                        isEnlistedInscription(row.inscription)
                          ? {
                              documents: row.documents,
                              license: row.licenseWithContext?.license.approved,
                              inscription: row.inscription,
                              categoryName: inscriptionCategoryName(
                                row.inscription,
                                dayCategories[row.inscription.category]
                              ),
                              associationEmail: association.association.email,
                            }
                          : undefined
                      )
                      .filter(truthy)
                  )
                }
              >
                {t().emergencyDetails.titlePlural}
              </Button>
            )}
            {sportEvent && (
              <DownloadInscriptionsForChronoButton
                sportEvent={sportEvent}
                inscriptions={inscribedInscriptions}
              />
            )}
            {sportEvent?.sportEventType === 'enduro' && (
              <DownloadInscriptionsForEnduroButton
                disabled={inscribedAndPendingInscriptions.length === 0}
                inscriptions={inscribedAndPendingInscriptions}
                sportEvent={sportEvent}
              />
            )}
            <Button
              startIcon={<CloudDownload />}
              onClick={() => downloadBikes(inscriptions, dayCategories)}
            >
              {t().bikes}
            </Button>
          </>
        }
        data={
          sportEvent &&
          !loadingOrError && {
            headers: [
              { value: t().inscription.inscriptionType, maxWidth: 100 },
              { value: t().status, maxWidth: 100 },
              { value: t().open, maxWidth: 100 },
              { value: t().date, maxWidth: 100 },
              { value: t().issuedNumber.labelAlternative, align: 'right' },
              { value: t().lastName },
              { value: t().firstName },
              { value: t().association, exportOnly: true },
              { value: t().street, exportOnly: true },
              { value: t().zip, exportOnly: true },
              { value: t().place, exportOnly: true },
              { value: t().country, exportOnly: true },
              { value: t().email, exportOnly: true },
              { value: t().phone, exportOnly: true },
              { value: t().birthdate, exportOnly: true },
              { value: t().birthdateDe, exportOnly: true },
              { value: t().afmSection.label, exportOnly: true },
              { value: t().fmsSection.label, exportOnly: true },
              { value: t().newSamMember.labelAlternative, exportOnly: true },
              { value: t().samMemberNumber.label, exportOnly: true },
              { value: t().samSection.label, exportOnly: true },
              { value: t().parentsInfo, exportOnly: true },
              { value: t().lastYearCategories, exportOnly: true },
              { value: t().lastYearLicenses, exportOnly: true },
              { value: t().lastYearRanks, exportOnly: true },
              { value: t().lastYearStartNumbers, exportOnly: true },
              {
                value: t().transponders.transponder,
                exportOnly: sportEvent.sportEventType === 'enduro',
              },
              { value: t().category, maxWidth: 300 },
              { value: t().remarksRider, exportOnly: true },
              { value: t().remarksAdmin, exportOnly: true },
              { value: t().sidecarPartner.label, exportOnly: !displaySidecarCategories },
              ...(sportEvent.sportEventType === 'enduro'
                ? [{ value: t().driversLicense.driversLicenseId, exportOnly: true }]
                : []),
              { value: t().createdAt, exportOnly: true },
              { value: t().updatedAt, exportOnly: true },
              { value: t().accountBalance, maxWidth: 300, align: 'right' },
              actions(),
            ],
            // eslint-disable-next-line react/display-name
            contents: inscriptions.map((inscriptionWithContext) => () => {
              const { licenseWithContext, inscription, status, documents } = inscriptionWithContext
              const license = licenseWithContext?.license
              const birthdate = documents.personalData?.birthdate
              const parentsInfo = documents.personalData?.parentsInfo
              const dayCategory = dayCategories[inscription.category]

              return [
                t().inscription.inscriptionTypes[inscription.type],
                t().inscriptionStatusShort[status],
                openInscriptionTasksShortName({
                  inscription,
                  documents,
                  dayCategory,
                }),
                inscription.date,
                inscriptionIssuedOrPreferredNumber(inscription, license?.approved),
                documents.personalData?.lastName || '',
                documents.personalData?.firstName || '',
                associationNameWithDefault(license?.approved.licenseAssociation || undefined),
                documents.personalData?.street || '',
                documents.personalData?.zip || '',
                documents.personalData?.place || '',
                documents.personalData?.country || '',
                documents.personalData?.email || '',
                documents.personalData?.phone || '',
                birthdate || '',
                formatDateDe(parseDate(birthdate)),
                sectionNameWithIdById(documents.personalData?.afmSection),
                sectionNameWithIdById(documents.personalData?.fmsSection),
                documents.personalData?.newSamMember ? 'x' : '',
                documents.personalData?.samMemberNumber || '-',
                sectionNameWithIdById(documents.personalData?.samSection),
                typeof parentsInfo === 'string' ? parentsInfo : '',
                documents.lastYear?.lastYearCategories || '',
                documents.lastYear?.lastYearLicenses || '',
                documents.lastYear?.lastYearRanks || '',
                documents.lastYear?.lastYearStartNumbers || '',
                transponderDescription(sportEvent, documents, inscription),
                inscriptionCategoryName(inscription, dayCategory),
                <Multiline key="remarksRider">{inscription.remarksRider || ''}</Multiline>,
                <Multiline key="remarksAdmin">{inscription.remarksAdmin || ''}</Multiline>,
                inscription.sidecarPartner || '',
                ...(sportEvent.sportEventType === 'enduro' ? [documents.driversLicense?.id || ''] : []),
                formatDateWithSecondsSpace(parseISOOrUndefined(inscription.createdAt)),
                formatDateWithSecondsSpace(parseISOOrUndefined(inscription.updatedAt)),
                <AccountBalance key="balance" user={{ uid: inscription.uid }} />,
                <>
                  <FinancialDialog admin={admin} user={{ uid: inscription.uid }} />
                  {licenseWithContext && (
                    <EditLicenseButton licenseWithContext={licenseWithContext} admin={admin} />
                  )}
                  <LicenseAttachmentsButton
                    user={{ uid: inscription.uid }}
                    documents={documents}
                    admin={admin}
                  />
                  <EmergencyDetailsButton user={{ uid: inscription.uid }} admin={admin} />
                  <ImpersonateButton user={{ uid: inscription.uid }} />
                  <InscriptionDetailsButton
                    disabled={!canEditCategory(sportEvent, inscription, adminContext)}
                    sportEvent={sportEvent}
                    inscription={inscriptionWithContext}
                    status={status}
                  />
                </>,
              ]
            }),
            selected: inscriptions.map(({ inscription, status }) =>
              inscription.type === 'enlistedDayInscriptionDayCategoryDraft' ||
              inscription.type === 'enlistedDayInscriptionYearCategoryDraft'
                ? 'warning'
                : status === 'inscribed'
                ? 'success'
                : inscription.type === 'unlistedLicenseInscription' ||
                  inscription.type === 'unlistedDayLicenseInscription'
                ? 'deemphasized'
                : 'error'
            ),
            ids: inscriptions.map(
              (row) =>
                `${row.inscription.sportEvent}|${row.inscription.category}|${row.inscription.uid}|${row.inscription.date}`
            ),
            rawData: inscriptions.map((row) =>
              JSON.stringify({
                ...row,
                hasSidecarCategories: displaySidecarCategories,
                sportEvent,
                dayCategories,
              })
            ),
          }
        }
      >
        <Box>
          <Box display="flex" alignItems="center">
            <Box>
              {t().inscription.filterByStatus}{' '}
              {[
                t().inscriptionStatusLong['inscription-pending'],
                t().inscriptionStatusLong.inscribed,
              ].map((v) => (
                <Button onClick={() => search.set(v)} disabled={search.q === v} key={v}>
                  {v}
                </Button>
              ))}
            </Box>
            <Box>
              {t().inscription.filterByType}{' '}
              {Object.values(t().inscriptionTypeSearch).map((v) => (
                <Button onClick={() => search.set(v)} disabled={search.q === v} key={v}>
                  {v}
                </Button>
              ))}
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <TextField
                label={`${t().search} ${[
                  t().inscriptionStatusLong.inscribed,
                  t().inscriptionStatusLong['inscription-pending'],
                ]
                  .map((v) => `${v}`)
                  .join('/')}, ${Object.values(t().inscriptionTypeSearch)
                  .map((v) => `${v}`)
                  .join('/')}, "${
                  t().inscription.inscriptionTypes.unlistedDayLicenseInscription
                }", ...)`}
                variant="outlined"
                size="small"
                fullWidth
                value={search.q}
                onChange={(event) => search.set(event.currentTarget.value)}
              />
            </Box>
            <Box px={1}>
              <RoutedButton to={routes.approvedLicenses.generateTo(search.q)}>
                {routes.approvedLicenses.text()}
              </RoutedButton>
              {sportEvent && (
                <>
                  <RoutedButton to={routes.inscriptionGroups.generateTo(sportEvent.id)}>
                    {routes.inscriptionGroups.text()}
                  </RoutedButton>
                  <RoutedButton to={routes.publicInscriptions.generateTo(sportEvent.id)}>
                    {routes.publicInscriptions.text()}
                  </RoutedButton>
                </>
              )}
              <ScanQRLicenseButton onSuccess={(approvedLicense) => search.set(approvedLicense.uid)} />
              {sportEvent && (
                <AddInscriptionButton sportEvent={sportEvent} dayCategories={dayCategories} />
              )}
              {sportEvent && (
                <IconButtonWithTooltip
                  tooltip={t().updatePublicStartLists}
                  onClick={() =>
                    catchAndLog(
                      refreshError.setError,
                      () =>
                        Promise.all([
                          updateInscriptionListsOfSportEvent(db, sportEvent),
                          recalculateInscriptionStatistics(db, sportEvent),
                        ]),
                      setRefreshLoading
                    )
                  }
                >
                  <Sync />
                </IconButtonWithTooltip>
              )}
              {adminContext.admin && (
                <>
                  <IconButtonWithTooltip
                    tooltip={t().financials.recalculatePaymentStatus}
                    onClick={() =>
                      catchAndLog(
                        refreshError.setError,
                        () => updateAllInscriptions(db, year),
                        setRefreshLoading
                      )
                    }
                  >
                    <Sync />
                  </IconButtonWithTooltip>
                  <IconButtonWithTooltip
                    tooltip={t().inscription.migrateInscriptionBookings}
                    onClick={() =>
                      catchAndLog(
                        refreshError.setError,
                        async () => {
                          await migrateInscriptions(db, year)
                          await updateAllTransactionStates(db)
                        },
                        setRefreshLoading
                      )
                    }
                  >
                    <Update />
                  </IconButtonWithTooltip>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </TableBox>
      {inscriptions.length === 1 &&
        sportEvent &&
        canEditCategory(sportEvent, inscriptions[0].inscription, adminContext) && (
          <>
            <ElevatedBox title={t().inscription.inscriptionDetails}>
              <InscriptionDetails
                sportEvent={sportEvent}
                inscription={inscriptions[0]}
                status={inscriptions[0].status}
              />
            </ElevatedBox>
            <FinancialBox user={{ uid: inscriptions[0].inscription.uid }} admin={admin} />
          </>
        )}
      <ElevatedBox title={t().inscription.inscriptionStatistics}>
        <InscriptionStatistics inscriptions={inscriptions} />
      </ElevatedBox>
    </>
  )
}

function canEditCategory(sportEvent: SportEvent, inscription: Inscription, adminContext: AdminContext) {
  return (
    adminContext.matchesAssociation(sportEvent.association) ||
    (isCategoryId(inscription.category) &&
      categoryByIdRequired(inscription.category).associations.some((categoryAssociation) =>
        adminContext.matchesAssociation(categoryAssociation)
      ))
  )
}

function InscriptionStatistics({
  inscriptions,
}: {
  inscriptions: InscriptionWithContextAndSportEvent[]
}) {
  const byStatus = groupByLiteral(inscriptions, (context) => context.status)
  return (
    <>
      {inscriptionStates()
        .filter((status) => status !== 'not-inscribed')
        .map((status) => [status, byStatus[status]?.length || 0] as const)
        .map(([status, count]) => (
          <div key={status}>
            {t().inscriptionStatusLong[status]}: {count}
          </div>
        ))}
    </>
  )
}
