import { Box, Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  isAfter,
  isValid,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'
import { useState } from 'react'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { useUserContext } from 'app/themes/user-context'
import { t } from 'shared/i18n/current'
import { AssociationID, associations, isAssociationByID } from 'shared/models/associations'
import { useSearchQuery } from 'utils/router'
import { useBoolean } from 'utils/use-boolean'

interface OptionalDateFilterProps {
  filter: OptionalDateFilterState
  onChange: (filter: OptionalDateFilterState) => void
}

export function AssociationAndOptionalDateFilter(props: OptionalDateFilterProps) {
  return <InternalAssociationAndDateFilter requireDates={false} {...props} />
}

interface DateFilterProps {
  filter: DateFilterState
  onChange: (filter: DateFilterState) => void
}

export function AssociationAndDateFilter(props: DateFilterProps) {
  return (
    <InternalAssociationAndDateFilter
      requireDates
      filter={props.filter}
      onChange={(data) =>
        data.startsAt &&
        data.endsAt &&
        props.onChange({ ...data, startsAt: data.startsAt, endsAt: data.endsAt })
      }
    />
  )
}

type InternalAssociationAndDateFilterProps = RequiredDateProps | OptionalDateProps

interface RequiredDateProps {
  requireDates: true
  filter: OptionalDateFilterState
  onChange: (filter: OptionalDateFilterState) => void
}

interface OptionalDateProps {
  requireDates: false
  filter: OptionalDateFilterState
  onChange: (filter: OptionalDateFilterState) => void
}

function InternalAssociationAndDateFilter(props: InternalAssociationAndDateFilterProps) {
  const enableStartsAtState = useBoolean(!!props.filter.startsAt)
  const enableEndsAtState = useBoolean(!!props.filter.startsAt)
  const enableStartsAt = enableStartsAtState.value || props.requireDates
  const enableEndsAt = enableEndsAtState.value || props.requireDates
  const startsAt = props.filter.startsAt
  const endsAt = props.filter.endsAt
  const userContext = useUserContext()
  const selectedAssociation =
    associations.find((association) =>
      userContext.associationAdmin
        ? association.id === userContext.associationAdmin
        : association.id === props.filter.association
    ) || null

  return (
    <ElevatedBox title="Filter">
      <Box mb={1}>
        <Autocomplete
          id="association"
          options={associations.filter((association) =>
            userContext.associationAdmin ? association.id === userContext.associationAdmin : true
          )}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label={t().association} variant="outlined" />}
          value={selectedAssociation}
          onChange={(_event, value) => props.onChange({ ...props.filter, association: value?.id })}
        />
      </Box>
      <Box display="flex">
        {!props.requireDates && (
          <Box mb={1} display="flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableStartsAtState.value}
                  name="startsAt"
                  onChange={() => {
                    if (enableStartsAtState.value && startsAt) updateStartsAt(props, 'delete')
                    else if (!enableStartsAtState.value && !startsAt)
                      updateStartsAt(props, defaultStartsAt())

                    enableStartsAtState.toggle()
                  }}
                />
              }
              label={t().startsAt}
            />
          </Box>
        )}
        {enableStartsAt && startsAt && (
          <Box mb={1} display="flex" flexGrow={1}>
            <Box flexGrow={1} alignSelf="center">
              <KeyboardDateTimePicker
                value={startsAt}
                onChange={(date) => updateStartsAt(props, date)}
                inputVariant="outlined"
                fullWidth
                label={t().startsAt}
                ampm={false}
              />
            </Box>
            <Box flexShrink={1} alignSelf="center">
              <Box>
                <DateButtonStartsAt {...props} label="-1y" newDate={subYears(startsAt, 1)} />
                <DateButtonStartsAt {...props} label="-1m" newDate={subMonths(startsAt, 1)} />
                <DateButtonStartsAt {...props} label="-1d" newDate={subDays(startsAt, 1)} />
                <DateButtonStartsAt {...props} label="+1d" newDate={addDays(startsAt, 1)} />
                <DateButtonStartsAt {...props} label="+1m" newDate={addMonths(startsAt, 1)} />
                <DateButtonStartsAt {...props} label="+1y" newDate={addYears(startsAt, 1)} />
              </Box>
              <Box>
                <DateButtonStartsAt {...props} label="SoY" newDate={startOfYear(startsAt)} />
                <DateButtonStartsAt {...props} label="SoM" newDate={startOfMonth(startsAt)} />
                <DateButtonStartsAt {...props} label="SoD" newDate={startOfDay(startsAt)} />
                <DateButtonStartsAt {...props} label="EoD" newDate={endOfDay(startsAt)} />
                <DateButtonStartsAt {...props} label="EoM" newDate={endOfMonth(startsAt)} />
                <DateButtonStartsAt {...props} label="EoY" newDate={endOfYear(startsAt)} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex">
        {!props.requireDates && (
          <Box mb={1} display="flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableEndsAtState.value}
                  name="endsAt"
                  onChange={() => {
                    if (enableEndsAtState.value && endsAt) updateEndsAt(props, 'delete')
                    else if (!enableEndsAtState.value && !endsAt) updateEndsAt(props, defaultEndsAt())

                    enableEndsAtState.toggle()
                  }}
                />
              }
              label={t().endsAt}
            />
          </Box>
        )}
        {enableEndsAt && endsAt && (
          <Box display="flex" flexGrow={1}>
            <Box flexGrow={1} alignSelf="center">
              <KeyboardDateTimePicker
                value={endsAt}
                onChange={(date) => updateEndsAt(props, date)}
                inputVariant="outlined"
                fullWidth
                label={t().endsAt}
                ampm={false}
              />
            </Box>
            <Box flexShrink={1} alignSelf="center">
              <Box>
                <DateButtonEndAt {...props} label="-1y" newDate={subYears(endsAt, 1)} />
                <DateButtonEndAt {...props} label="-1m" newDate={subMonths(endsAt, 1)} />
                <DateButtonEndAt {...props} label="-1d" newDate={subDays(endsAt, 1)} />
                <DateButtonEndAt {...props} label="+1d" newDate={addDays(endsAt, 1)} />
                <DateButtonEndAt {...props} label="+1m" newDate={addMonths(endsAt, 1)} />
                <DateButtonEndAt {...props} label="+1y" newDate={addYears(endsAt, 1)} />
              </Box>
              <Box>
                <DateButtonEndAt {...props} label="SoY" newDate={startOfYear(endsAt)} />
                <DateButtonEndAt {...props} label="SoM" newDate={startOfMonth(endsAt)} />
                <DateButtonEndAt {...props} label="SoD" newDate={startOfDay(endsAt)} />
                <DateButtonEndAt {...props} label="EoD" newDate={endOfDay(endsAt)} />
                <DateButtonEndAt {...props} label="EoM" newDate={endOfMonth(endsAt)} />
                <DateButtonEndAt {...props} label="EoY" newDate={endOfYear(endsAt)} />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </ElevatedBox>
  )
}

function DateButtonStartsAt(props: OptionalDateFilterProps & { label: string; newDate: Date }) {
  return <Button onClick={() => updateStartsAt(props, props.newDate)}>{props.label}</Button>
}

function updateStartsAt(props: OptionalDateFilterProps, date: Date | null | 'delete') {
  const { filter, onChange } = props
  if (date === 'delete')
    return onChange({
      association: props.filter.association,
      startsAt: undefined,
      endsAt: filter.endsAt,
    })

  const startsAt = date && isValid(date) ? date : defaultStartsAt()
  const endsAt = !filter.endsAt ? undefined : isAfter(startsAt, filter.endsAt) ? startsAt : filter.endsAt
  onChange({ association: props.filter.association, startsAt, endsAt })
}

function DateButtonEndAt(props: OptionalDateFilterProps & { label: string; newDate: Date }) {
  return <Button onClick={() => updateEndsAt(props, props.newDate)}>{props.label}</Button>
}

function updateEndsAt(props: OptionalDateFilterProps, date: Date | null | 'delete') {
  const { filter, onChange } = props
  if (date === 'delete')
    return onChange({
      association: props.filter.association,
      startsAt: filter.startsAt,
      endsAt: undefined,
    })

  const endsAt = date && isValid(date) ? date : defaultEndsAt()
  const startsAt = !filter.startsAt
    ? undefined
    : isAfter(endsAt, filter.startsAt)
    ? filter.startsAt
    : endsAt
  onChange({ association: props.filter.association, startsAt, endsAt })
}

export function useFilterState(): {
  filter: DateFilterState
  setFilter(newFilter: DateFilterState): void
} {
  const filter = useInternalFilterState('required-dates')
  return filter as { filter: DateFilterState; setFilter(newFilter: DateFilterState): void }
}

export function useOptionalFilterState() {
  return useInternalFilterState('optional-dates')
}

function useInternalFilterState(options: 'required-dates' | 'optional-dates') {
  const fromQuery = useSearchQuery('from')
  const toQuery = useSearchQuery('to')
  const associationQuery = useSearchQuery('association')
  const [filter, setFilter] = useState<OptionalDateFilterState>(
    options === 'optional-dates'
      ? defaultOptionalDatesFilter(fromQuery.q, toQuery.q, associationQuery.q)
      : defaultRequiredDatesFilter(fromQuery.q, toQuery.q, associationQuery.q)
  )
  return {
    filter,
    setFilter(newFilter: OptionalDateFilterState) {
      fromQuery.set(newFilter.startsAt?.toISOString() || '')
      toQuery.set(newFilter.endsAt?.toISOString() || '')
      associationQuery.set(newFilter.association || '')
      setFilter(newFilter)
    },
  }
}

function defaultRequiredDatesFilter(startsAt: string, endsAt: string, association: string) {
  const filter = defaultOptionalDatesFilter(startsAt, endsAt, association)
  return {
    startsAt: filter.startsAt || defaultStartsAt(),
    endsAt: filter.endsAt || defaultEndsAt(),
    association: filter.association,
  }
}

function defaultOptionalDatesFilter(startsAt: string, endsAt: string, association: string) {
  return {
    startsAt: startsAt ? parseISO(startsAt) || defaultStartsAt() : undefined,
    endsAt: endsAt ? parseISO(endsAt) || defaultEndsAt() : undefined,
    association: isAssociationByID(association) ? association : undefined,
  }
}

function defaultEndsAt() {
  return endOfYear(new Date())
}

function defaultStartsAt() {
  return startOfYear(new Date())
}

export interface OptionalDateFilterState {
  startsAt?: Date | undefined
  endsAt?: Date | undefined
  association: AssociationID | undefined
}

export interface DateFilterState {
  startsAt: Date
  endsAt: Date
  association: AssociationID | undefined
}

export interface AssociationFilterState {
  association: AssociationID | undefined
}
