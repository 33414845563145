import { Button } from '@material-ui/core'
import { db } from 'app/db/frontend-db'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { calculateCategoryGroups } from 'app/sport-events/edit-sport-event-button'
import { SportEventForm } from 'app/sport-events/sport-event-form'
import { generateDayCategorySearchString } from 'shared/db/day-category'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { serializeCategoryIDs } from 'shared/sport-events/sport-event-categories-serialization'
import {
  createOrUpdateDayCategories,
  extractLicenseCategoryDates,
} from 'shared/sport-events/sport-event-categories-service'
import { useErrorSnackbarForError } from 'utils/snackbar'

export function NewSportEventButton({ admin }: { admin: UserQuery }) {
  const dialog = useDialog()
  const showError = useErrorSnackbarForError()

  return (
    <>
      <Button onClick={() => dialog.open()}>{t().sportsEvent.newEvent}</Button>
      {dialog.isOpen && (
        <ConfirmDialog maxWidth="xl" fullWidth title={t().sportsEvent.newEvent} dialog={dialog}>
          <SportEventForm
            admin={admin}
            initialSportEvent={undefined}
            initialDayCategories={[]}
            onSubmit={async (originalProps) => {
              const { sportEvent: newSportEvent } = originalProps
              try {
                const sportEvent = await db.pushSportEvent({
                  licenseCategoryIds: '',
                  dayCategoryIds: '',
                  licenseCategoryDates: {},
                  dayCategoryDates: {},
                  ...newSportEvent,
                  status: 'draft',
                })
                const props = { ...originalProps, sportEvent }

                const dayCategories = await createOrUpdateDayCategories({ db, ...props })
                const dayCategoryDates = dayCategories.dates
                const licenseCategoryDates = extractLicenseCategoryDates(props)
                const licenseCategoryIds = Object.keys(licenseCategoryDates)
                const dayCategoryIds = Object.keys(dayCategoryDates)

                const { categoryGroupCounts, categoryGroupSizes } = calculateCategoryGroups(
                  dayCategories,
                  sportEvent,
                  licenseCategoryIds
                )

                const sportEventWithCategories = {
                  ...sportEvent,
                  status: newSportEvent.status,
                  licenseCategoryIds: serializeCategoryIDs(licenseCategoryIds),
                  dayCategoryIds: serializeCategoryIDs(dayCategoryIds),
                  dayCategoryNamesForSearch: generateDayCategorySearchString(dayCategories.categories),
                  licenseCategoryDates,
                  dayCategoryDates,
                  categoryGroupCounts,
                  categoryGroupSizes,
                }
                await db.updateSportEvent(sportEventWithCategories)
                await db.pushUserEvent({
                  id: '',
                  type: 'editSportEvent',
                  uid: admin.uid,
                  byUid: admin.uid,
                  date: new Date().toISOString(),
                  editType: 'add',
                  details: sportEventWithCategories,
                })
                dialog.close()
              } catch (error) {
                showError(error)
              }
            }}
          />
        </ConfirmDialog>
      )}
    </>
  )
}
