import { Box, Breadcrumbs, Link } from '@material-ui/core'
import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { routes } from 'shared/config/routes'

export function BreadcrumbsList(props: { links: [string, string | ReactNode][]; base: BreadcrumbBase }) {
  const baseLinks = breadcrumbBases[props.base].map((route) => [route.to, route.text()])
  const links = [...baseLinks, ...props.links]
  return (
    <Box mt={2} mb={1}>
      <Breadcrumbs>
        {links.map(([to, text], index) => (
          <BreadcrumbLink
            key={`${to}-${index}`}
            to={to}
            color={index === links.length - 1 ? 'textPrimary' : 'inherit'}
          >
            {text}
          </BreadcrumbLink>
        ))}
      </Breadcrumbs>
    </Box>
  )
}

export type BreadcrumbBase = keyof typeof breadcrumbBases

const breadcrumbBases = {
  admin: [routes.adminDashboard],
  adminSportEvents: [routes.adminDashboard, routes.adminSportEvents],
}

export function BreadcrumbLink(props: {
  to: string
  children: ReactNode
  color?: 'inherit' | 'textPrimary'
}) {
  return (
    <Link color={props.color || 'inherit'} to={props.to} component={RouterLink}>
      {props.children}
    </Link>
  )
}
