import { Button, createStyles, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { userEmail } from 'app/users/auth'
import { User } from 'app/users/user'
import { t } from 'shared/i18n/current'

interface EmailFormProps {
  user: User
  onSubmit: (email: string) => Promise<void>
  onCancel: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function EmailForm(props: EmailFormProps) {
  const classes = useStyles()
  const { user, onSubmit, onCancel } = props

  return (
    <>
      <Formik
        initialValues={{ email: userEmail(user) }}
        enableReinitialize={true}
        validationSchema={schema()}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values.email)
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <Grid container spacing={1} className={classes.gridParent}>
              <Grid item xs={12}>
                {samFields().personalData.email.field({ prefix: '' })}
              </Grid>

              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <Grid item xs={6}>
                <Button fullWidth variant="contained" disabled={isSubmitting} onClick={onCancel}>
                  {t().buttons.cancel}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || user.email === values.email}
                  onClick={submitForm}
                >
                  {isSubmitting
                    ? t().personalDataBox.changeEmail.saving
                    : t().personalDataBox.changeEmail.title}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

function schema() {
  const validations = { email: samFields().personalData.email.validation }
  return Yup.object().defined().shape(validations)
}
