import { Box, Button, Grid } from '@material-ui/core'
import { t } from 'shared/i18n/current'

interface AccordionNavigationButtonsProps {
  isSubmitting: boolean
  onBack?: () => void
  submitForm: (() => Promise<void>) & (() => Promise<any>)
  nextLabel?: string
  autoHighlightSubmit?: boolean
  dirty?: boolean
  spacingTop?: boolean
  disabled?: boolean
}

export function AccordionNavigationButtons(props: AccordionNavigationButtonsProps) {
  const {
    disabled,
    autoHighlightSubmit,
    isSubmitting,
    onBack,
    submitForm,
    nextLabel,
    dirty,
    spacingTop,
  } = props
  return (
    <>
      {onBack && (
        <Grid item xs={6}>
          <Box mt={spacingTop ? 1 : 0}>
            <Button fullWidth disabled={isSubmitting} onClick={onBack}>
              {t().buttons.back}
            </Button>
          </Box>
        </Grid>
      )}
      <Grid item xs={onBack ? 6 : 12}>
        <Box mt={spacingTop ? 1 : 0}>
          <Button
            fullWidth
            variant={autoHighlightSubmit && !dirty ? 'outlined' : 'contained'}
            color={autoHighlightSubmit && !dirty ? 'default' : 'primary'}
            disabled={(autoHighlightSubmit && !dirty) || isSubmitting || disabled}
            onClick={submitForm}
          >
            {isSubmitting ? t().buttons.saving : nextLabel || t().buttons.continue}
          </Button>
        </Box>
      </Grid>
    </>
  )
}
