import { t } from 'shared/i18n/current'

export function friendlyError(error: unknown) {
  if (typeof error === 'string') return error
  if (typeof error === 'object' && error) {
    const errorObject: { message?: unknown } = error
    if (typeof errorObject.message === 'string' && errorObject.message.toLowerCase() === 'internal')
      return t().internalError
    if (typeof errorObject.message === 'string') return errorObject.message
    else return JSON.stringify(error)
  }
  return 'Unknown error'
}
