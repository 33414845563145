import { Box, Button, TextField } from '@material-ui/core'
import { Autorenew, SpeakerNotes, VerifiedUser, Error, Undo, Add, Receipt } from '@material-ui/icons'
import assertNever from 'assert-never'
import { useState } from 'react'
import { discrepancies, useLicenseBookingsByUsers } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { actions } from 'app/export/table'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { DownloadableTable } from 'app/layouts/downloadable-table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import {
  addBooking,
  addBookingAndBill,
  handleBookings,
  reverseLicenseItemBooking,
} from 'app/pages/billing/license-booking-service'
import { useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { User } from 'app/users/user'
import { deleteLicenseBooking } from 'shared/billing/bookings-service'
import { updateAllTransactionStates } from 'shared/billing/payment-processor'
import { totalLineItem } from 'shared/category-pricing'
import { t } from 'shared/i18n/current'
import { licenseLineItemName } from 'shared/licenses-booking-names'
import { BookingContext, BookingContextWithDocuments } from 'shared/licenses-bookings'
import { AssociationID } from 'shared/models/associations'
import { nameWithPlace } from 'shared/models/personal-data'
import { toChf } from 'shared/utils/number'
import { DeleteButtonIcon } from 'utils/buttons/delete-button-icon'
import { Loading } from 'utils/loading'
import { useSearchQuery } from 'utils/router'

interface LicenseBookingsProps {
  admin: User
}

export function LicenseBookingsByRider({ admin }: LicenseBookingsProps) {
  const search = useSearchQuery()
  const userContext = useUserContext()
  const association = userContext.associationAdmin
  const {
    data: filteredData,
    loading,
    error,
    loadingOrError,
  } = useLicenseBookingsByUsers(search.q, admin, association)
  const year = useLicenseYear()

  return association === false ? null : (
    <>
      <TableBox
        title={t().financials.licenseBookingsPerRider}
        loading={loading}
        error={error}
        data={
          !loadingOrError && {
            headers: [
              { value: t().user, maxWidth: 300 },
              { value: t().financials.necessaryBookings, maxWidth: 800 },
              { value: t().financials.createdBookings, maxWidth: 800 },
              actions(),
            ],
            // eslint-disable-next-line react/display-name
            contents: filteredData.map(({ documents, bookingContext }) => () => {
              return [
                nameWithPlace(documents.personalData),
                <>{renderPotentialItems(bookingContext, association, year)}</>,
                <>{renderItems(bookingContext, year)}</>,
                <>
                  <FinancialDialog admin={bookingContext.admin} user={bookingContext.rider} />
                  <IconButtonWithTooltip
                    tooltip="Sync"
                    size="small"
                    onClick={() => handleBookings(bookingContext, year)}
                    disabled={!!association}
                  >
                    {renderSyncIcon(bookingContext)}
                  </IconButtonWithTooltip>
                </>,
              ]
            }),
            selected: filteredData.map((data) =>
              data.bookingContext.state === 'no-booked-items'
                ? 'errorLight'
                : data.bookingContext.state === 'conflicting-items'
                ? 'errorLight'
                : ''
            ),
            ids: filteredData.map(({ uid }) => uid),
            rawData: filteredData.map((row) => JSON.stringify({ row, associationAdmin: association })),
          }
        }
      >
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <TextField
              label={`${t().licensesBoxes.search} ${discrepancies})`}
              variant="outlined"
              size="small"
              fullWidth
              value={search.q}
              onChange={(event) => search.set(event.currentTarget.value)}
            />
          </Box>
          <Box px={1}>
            <Button onClick={() => search.toggle(discrepancies, ' ')}>{discrepancies}</Button>
            {!association && <UpdateTransactionStatesButton />}
            {!association && <SynchronizeLicenseBookingsButton bookingsWithDocuments={filteredData} />}
          </Box>
        </Box>
      </TableBox>
    </>
  )
}

interface BookChangedButtonProps {
  bookingsWithDocuments: BookingContextWithDocuments[]
}

function renderSyncIcon({ state }: BookingContext) {
  if (state === 'same-items') return <VerifiedUser />
  if (state === 'same-price') return <SpeakerNotes />
  if (state === 'no-booked-items') return <Autorenew />
  if (state === 'conflicting-items') return <Error />
  assertNever(state)
}

function UpdateTransactionStatesButton() {
  const [processing, setProcessing] = useState(false)
  return (
    <>
      <Loading loading={processing} />
      <Button
        disabled={processing}
        onClick={async () => {
          setProcessing(true)
          await updateAllTransactionStates(db)
          setProcessing(false)
        }}
      >
        {t().financials.recalculatePaymentStatus}
      </Button>
    </>
  )
}

function SynchronizeLicenseBookingsButton({ bookingsWithDocuments }: BookChangedButtonProps) {
  const userContext = useUserContext()
  const year = useLicenseYear()

  return userContext.associationAdmin === false ? null : (
    <Button
      disabled={!!userContext.associationAdmin}
      onClick={() =>
        bookingsWithDocuments
          // TODO: later: implement this for all kinds of bookings (issue with cascading cancellations)
          .filter((bookings) => bookings.bookingContext.state === 'no-booked-items')
          .map((bookings) => bookNewItems(bookings, year))
      }
    >
      Sync
    </Button>
  )
}

function bookNewItems(booking: BookingContextWithDocuments, year: number) {
  return handleBookings(booking.bookingContext, year)
}

function renderPotentialItems(
  bookingContext: BookingContext,
  association: AssociationID | undefined,
  year: number
) {
  const items = [...bookingContext.potentialItems].reverse()
  return items.length === 0 ? null : (
    <>
      <DownloadableTable
        disableDownloadButtons
        data={{
          headers: [{ value: t().amount.label }, { value: t().bookingText }, { value: t().actions }],
          contents: [
            // eslint-disable-next-line react/display-name
            ...items.map((item) => () => [
              toChf(item.price),
              licenseLineItemName(item),
              <>
                <IconButtonWithTooltip
                  key="create"
                  tooltip={t().createBooking}
                  size="small"
                  onClick={() =>
                    addBooking({ admin: bookingContext.admin, item, rider: bookingContext.rider }, year)
                  }
                >
                  <Add />
                </IconButtonWithTooltip>
                <IconButtonWithTooltip
                  key="create"
                  tooltip={t().createBookingAndBill}
                  size="small"
                  onClick={() =>
                    addBookingAndBill(
                      {
                        admin: bookingContext.admin,
                        item,
                        rider: bookingContext.rider,
                        association,
                      },
                      year
                    )
                  }
                >
                  <Receipt />
                </IconButtonWithTooltip>
              </>,
            ]),
            () => [toChf(totalLineItem(items).price), totalLineItem(items).name, ''],
          ],
          rawData: items.map((item) => JSON.stringify(item)),
        }}
      />
    </>
  )
}

function renderItems(bookingContext: BookingContext, year: number) {
  const bookings = [...bookingContext.bookedLicenseBookings]
  const items = bookings.map((booking) => booking.item)
  return bookings.length === 0 ? null : (
    <>
      <DownloadableTable
        disableDownloadButtons
        data={{
          headers: [{ value: t().amount.label }, { value: t().bookingText }, { value: t().actions }],
          contents: [
            // eslint-disable-next-line react/display-name
            ...bookings.map((booking) => () => [
              toChf(booking.item.price),
              licenseLineItemName(booking.item),
              <>
                <DeleteButtonIcon
                  key="delete"
                  size="small"
                  title={t().financials.deleteLicenseBooking(booking.item.name)}
                  onConfirm={() => deleteLicenseBooking(db, booking)}
                />
                <IconButtonWithTooltip
                  tooltip={t().reverseBooking}
                  size="small"
                  onClick={() =>
                    reverseLicenseItemBooking(
                      {
                        admin: bookingContext.admin,
                        item: booking.item,
                        rider: bookingContext.rider,
                      },
                      year
                    )
                  }
                >
                  <Undo />
                </IconButtonWithTooltip>
              </>,
            ]),
            () => [toChf(totalLineItem(items).price), totalLineItem(items).name, ''],
          ],
          rawData: bookings.map((item) => JSON.stringify(item)),
        }}
      />
    </>
  )
}
