import { constInsuranceOptions } from 'shared/data/insurance-options'
import { Year } from 'shared/data/license-year'
import { t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { ArrayElement } from 'shared/utils/tsc'

export interface InsuranceOption {
  readonly id: InsuranceOptionId
  readonly name: string
  readonly benefitDurationDays: number
  readonly waitingPeriodDays: number
  readonly price: number
  readonly standard: boolean
  readonly archived: boolean
}

export const InsuranceOptionsSamStartYear = 2025

export function supportsInsuranceOptions(association: AssociationID, year: Year) {
  return association === 'sam' && year >= InsuranceOptionsSamStartYear
}

export const insuranceOptionIds = constInsuranceOptions.map(({ id }) => id)

export type InsuranceOptionId = ArrayElement<typeof insuranceOptionIds>

export const insuranceOptions: readonly InsuranceOption[] = constInsuranceOptions

export const insuranceOptionsById = new Map(insuranceOptions.map((option) => [option.id, option]))

export function insuranceOptionById(id: InsuranceOptionId | undefined): InsuranceOption | undefined {
  if (!id) return undefined
  return insuranceOptionsById.get(id)
}

export function activeInsuranceOptions(): InsuranceOption[] {
  return insuranceOptions.filter(({ archived }) => !archived)
}

export function standardInsuranceOption(): InsuranceOption {
  const standardOption = insuranceOptions.find(({ standard, archived }) => standard && !archived)
  if (!standardOption) throw new Error('Standard insurance option not found')
  return standardOption
}

export function standardInsuranceOptionPrice(): number {
  return standardInsuranceOption().price
}

export function insuranceOptionPrice(id: InsuranceOptionId | undefined): number {
  return (insuranceOptionById(id) || standardInsuranceOption()).price
}

export function selectableInsuranceOptions(options: { showAll?: boolean } = {}) {
  const selectableOptions = options.showAll ? insuranceOptions : activeInsuranceOptions()
  const standardPrice = standardInsuranceOptionPrice()

  return selectableOptions.map((option) => {
    const prefix = option.archived ? '(archived) ' : ''
    const name = t().insuranceOption.optionDetails(
      option.benefitDurationDays,
      option.waitingPeriodDays,
      priceWithSign(option.price - standardPrice)
    )
    return { id: option.id, name: `${prefix}${name}` }
  })
}

function priceWithSign(price: number) {
  return `${price >= 0 ? '+' : ''}${price}`
}
