export function orEmptyArray<T>(arr: T[] | undefined): T[] {
  return arr || _emptyArray
}

export function emptyArray<T>(): T[] {
  return _emptyArray
}

export const _emptyArray: any[] = []

// eslint-disable-next-line @typescript-eslint/ban-types
export function orEmptyObject<T>(obj: T | undefined): T | {} {
  return obj || emptyObject
}

export const emptyObject = {}
