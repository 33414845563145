import { useAsync } from 'react-use'
import { callRacemanagerApi } from 'app/config/firebase'
import { db } from 'app/db/frontend-db'
import { actions, tableHeaders } from 'app/export/table'
import { ButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { TableBox } from 'app/layouts/table-box'
import { useUserContext } from 'app/themes/user-context'
import { ManuallyProcessLicenseRequestEndpoint } from 'shared/api/interfaces'
import { nextLicenseYear } from 'shared/data/license-config'
import { UserId } from 'shared/db/user-id'
import { useCatchAndDisplayErrorOrSuccess } from 'utils/snackbar'

export function ManuallyProcessLicenseRequest() {
  const userContext = useUserContext()
  if (!userContext.normalAdmin) return null

  return <InnerDBMigrations />
}

function InnerDBMigrations() {
  const handlePromise = useCatchAndDisplayErrorOrSuccess()
  const { loading, error, value } = useAsync(async () => {
    const drafts = await db.loadAllLicenseDrafts(nextLicenseYear)
    if (!drafts) throw new Error(`No drafts found for ${nextLicenseYear}`)

    const unprocessed = Object.entries(drafts)
      .filter(([_, draft]) => draft.summary?.processed === false)
      .map(([userId, draft]) => ({ userId, draft }))
    console.log(
      `User ids array`,
      unprocessed.map((u) => u.userId)
    )
    console.log(`User ids`, unprocessed.map((u) => u.userId).join(', '))
    console.log(unprocessed)
    return unprocessed
  })

  return (
    <>
      <TableBox
        title="Reprocess license requests"
        error={error}
        loading={loading}
        data={{
          headers: tableHeaders(['User ID', 'Content', actions()]),
          contents: (value || []).map(({ userId, draft }) => [
            userId,
            JSON.stringify(draft),
            <>
              <ButtonWithConfirmation
                onConfirm={() => handlePromise(reprocessLicenseRequest({ uid: userId }))}
                title="Reprocess license request"
                confirmation={`Reprocess "${userId}"`}
                fullWidth={false}
                variant="text"
              >
                Execute
              </ButtonWithConfirmation>
            </>,
          ]),
          selected: [],
          rawData: undefined,
        }}
      />
    </>
  )
}

export async function reprocessLicenseRequest({ uid }: { uid: UserId }) {
  const props = { uid, year: nextLicenseYear } as const
  await callRacemanagerApi<ManuallyProcessLicenseRequestEndpoint>('manuallyProcessLicenseRequest', props)
}
