import { parseISO } from 'date-fns'
import { isRacemanagerYear } from 'shared/data/license-year'
import { DB } from 'shared/db/db'
import { consoleLog } from 'shared/utils/console'

export const id = '2024-04-11T10-13-37-761Z'

export const description = 'Add sport events by year'

export async function up(db: DB) {
  await Promise.all(
    (
      await db.loadAllSportEvents()
    ).map(async (sportEvent) => {
      if (!sportEvent.year || (sportEvent.year as any) === 'undefined') {
        const year = parseISO(sportEvent.startsAt).getFullYear()
        if (!isRacemanagerYear(year))
          throw new Error(`Invalid year ${year} for sport event ${sportEvent.id}`)
        consoleLog('Year not found for sport event', sportEvent.id, 'setting to', year)
        sportEvent.year = year
      }

      if (!sportEvent.association) {
        consoleLog('Association not found for sport event', sportEvent.id, 'setting to sam')
        sportEvent.association = 'sam'
      }

      await db.updateSportEvent(sportEvent)
    })
  )
}
