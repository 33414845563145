import { Grid, Typography } from '@material-ui/core'
import { samFields } from 'app/forms/fields'

export function isFmsMembershipField(fieldName: string): fieldName is 'fmsSection' {
  return fieldName === 'fmsSection'
}

interface PersonalDataFmsFieldsProps {
  prefix: '' | 'personalData.'
  withTitle?: boolean
  required?: boolean
}

export function PersonalDataFieldsFms(props: PersonalDataFmsFieldsProps) {
  const { prefix, withTitle, required } = props
  const fields = samFields().personalData

  return (
    <>
      {withTitle && (
        <Grid item xs={12}>
          <Typography variant="h6">FMS</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        {fields.fmsSection.field({ prefix, required: !!required })}
      </Grid>
    </>
  )
}
