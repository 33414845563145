import { DB } from 'shared/db/db'
import { todoMigrateAssociation } from 'shared/models/associations'

export const id = '2022-11-10T02-32-18-993Z'

export const description = 'Migrate manual booking associations'

export async function up(db: DB) {
  const bookings = await db.loadAllManualBookings()
  await Promise.all(
    bookings.map(async (booking) => {
      if (!booking.item.association) {
        booking.item.association = todoMigrateAssociation(booking.item.association)
        await db.updateManualBooking(booking)
      }
    })
  )
}
