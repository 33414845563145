import assertNever from 'assert-never'
import {
  InscriptionTaskType,
  LicenseTasksOverview,
  LicenseTaskType,
} from 'shared/data/license-tasks-overview'
import { t } from 'shared/i18n/current'
import { InscriptionStatus } from 'shared/inscription/inscription-status'
import { taskName } from 'shared/license/license-tasks'
import {
  EnlistedDayInscriptionDayCategory,
  EnlistedDayInscriptionYearCategory,
  EnlistedLicenseInscription,
  Inscription,
} from 'shared/sport-events/sport-events'
import { truthy } from 'shared/utils/array'

export function inscriptionStatusShort(
  inscription: Inscription | undefined,
  tasks: LicenseTasksOverview
) {
  const status = inscriptionStatus(inscription, tasks)
  return t().inscriptionStatusShort[status]
}

export function inscriptionStatusLong(
  inscription: Inscription | undefined,
  tasks: LicenseTasksOverview
) {
  const status = inscriptionStatus(inscription, tasks)
  return status === 'inscription-pending'
    ? `${t().inscriptionStatusLong[status]}, ${t().inscription.pendingTasksDescription}: ${
        inscription && openInscriptionTaskNames(tasks)
      }`
    : t().inscriptionStatusLong[status]
}

export function inscriptionStatus(
  inscription: Inscription | undefined,
  tasks: LicenseTasksOverview | undefined
): InscriptionStatus {
  return !inscription
    ? 'not-inscribed'
    : inscription.type === 'unlistedLicenseInscription' ||
      inscription.type === 'unlistedDayLicenseInscription'
    ? 'unlisted'
    : inscription.type === 'enlistedDayInscriptionDayCategory' ||
      inscription.type === 'enlistedDayInscriptionYearCategory'
    ? enlistedDayInscriptionStatus(inscription)
    : inscription.type === 'enlistedDayInscriptionDayCategoryDraft' ||
      inscription.type === 'enlistedDayInscriptionYearCategoryDraft'
    ? 'inscription-pending'
    : inscription.type === 'enlistedLicenseInscription'
    ? enlistedLicenseInscriptionStatus(inscription, tasks)
    : assertNever(inscription)
}

function enlistedDayInscriptionStatus(
  inscription: EnlistedDayInscriptionYearCategory | EnlistedDayInscriptionDayCategory
): InscriptionStatus {
  const systematicallyVerified = inscription.paid
  return inscription.manuallyVerified || systematicallyVerified ? 'inscribed' : 'inscription-pending'
}

function enlistedLicenseInscriptionStatus(
  inscription: EnlistedLicenseInscription,
  tasks: LicenseTasksOverview | undefined
): InscriptionStatus {
  const systematicallyVerified = tasks?.allDone && inscription.paid
  return inscription.manuallyVerified || systematicallyVerified ? 'inscribed' : 'inscription-pending'
}

function openInscriptionTaskNames(tasks: LicenseTasksOverview) {
  return openInscriptionTasks(tasks)
    .map((task) => taskName(task))
    .join(', ')
}

function openInscriptionTasks(tasks: LicenseTasksOverview): (LicenseTaskType | InscriptionTaskType)[] {
  return tasks.tasks.map((task) => (task && !task.done ? task.type : undefined)).filter(truthy)
}
