import { Box, Button } from '@material-ui/core'
import { useLatestInscriptionOfRider } from 'app/db/db-hooks/inscription-db-hooks'
import { useSportEvent } from 'app/db/db-hooks/main-db-hooks'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { CopyOrNewManualBookingButton } from 'app/pages/admin/bookings/new-manual-booking'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import { useAssociationContext } from 'app/themes/association-context'
import { useAdmin } from 'app/themes/user-context'
import { categoryByIdRequired, commonCategoryName, isCategoryId } from 'shared/data/categories-service'
import { UserQuery } from 'shared/db/db'
import { SportEventId } from 'shared/db/sport-event-id'
import { t } from 'shared/i18n/current'
import { CategoryId } from 'shared/models/category'
import { Inscription } from 'shared/sport-events/sport-events'
import { sportEventDescription } from 'shared/sport-events/sport-events-service'

export function AddFineButton({ user }: { user: UserQuery }) {
  const dialog = useDialog()
  return (
    <>
      <ElevatedBox title={t().financials.addFineButtonText}>
        <Button onClick={dialog.open}>{t().inscription.fineForLastInscription}</Button>
      </ElevatedBox>
      {dialog.isOpen && (
        <ConfirmDialog fullWidth title={t().financials.addFineTitle} dialog={dialog}>
          <AddFineFormForLatestInscription user={user} />
        </ConfirmDialog>
      )}
    </>
  )
}

function AddFineFormForLatestInscription({ user }: { user: UserQuery }) {
  const { data, ...rest } = useLatestInscriptionOfRider(user)

  return rest.loadingOrError ? (
    <LoadingOrErrorBox {...rest} />
  ) : (
    <AddFineFormForCategory
      user={user}
      mayBeCategoryId={data?.category}
      sportEventId={data?.sportEvent}
    />
  )
}

export function AddFineFormForInscription({ inscription }: { inscription: Inscription }) {
  return (
    <AddFineFormForCategory
      user={inscription}
      mayBeCategoryId={inscription.category}
      sportEventId={inscription.sportEvent}
    />
  )
}

interface AddFineFormForCategoryProps {
  user: UserQuery
  mayBeCategoryId: string | undefined
  sportEventId: SportEventId | undefined
}

function AddFineFormForCategory(props: AddFineFormForCategoryProps) {
  const { user, mayBeCategoryId, sportEventId } = props
  const categoryId = isCategoryId(mayBeCategoryId) ? mayBeCategoryId : undefined

  return (
    <Box display="flex">
      <AddFineWithPriceButton
        user={user}
        categoryId={categoryId}
        sportEventId={sportEventId}
        price={30}
      />
      <AddFineWithPriceButton
        user={user}
        categoryId={categoryId}
        sportEventId={sportEventId}
        price={50}
      />
    </Box>
  )
}

interface AddFineWithPriceButtonProps {
  user: UserQuery
  price: number
  categoryId: CategoryId | undefined
  sportEventId: SportEventId | undefined
}

function AddFineWithPriceButton(props: AddFineWithPriceButtonProps) {
  const { user, price, categoryId, sportEventId } = props
  const admin = useAdmin()
  const association = useAssociationContext()
  const categoryName = categoryId ? commonCategoryName(categoryByIdRequired(categoryId)) : 'Clubklasse'
  const { data: sportEvent, ...rest } = useSportEvent(sportEventId)

  if (rest.loadingOrError) return <LoadingOrErrorBox {...rest} />

  return (
    <Box mr={1}>
      <CopyOrNewManualBookingButton
        variant="outlined"
        color="default"
        buttonName={`${price}`}
        admin={admin}
        booking={{
          byUid: admin.uid,
          sportEventId,
          categoryId: categoryId || 'common-2022-motocross-u-20',
          createdAt: new Date().toISOString(),
          date: new Date().toISOString(),
          id: '',
          internalRemarks: '',
          tag: t().financials.fine,
          type: 'manualBooking',
          uid: user.uid,
          updatedAt: new Date().toISOString(),
          remainingBalance: 0,
          item: {
            name: t().inscription.fineForInscription(categoryName, sportEventDescription(sportEvent)),
            price,
            type: 'billLineItem',
            association: association.associationID,
          },
        }}
      />
    </Box>
  )
}
