import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { BrowserRouter } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { initializeFirebaseApp } from 'app/config/firebase'
import { AdminDBProvider } from 'app/db/db-contexts/admin-db-provider'
import { I18nContext, useI18nState } from 'app/i18n/i18n-context'
import { AppContent } from 'app/layouts/app-content'
import { AppUpdateNotification } from 'app/layouts/app-update-notification'
import { AssociationContext, useAssociationState } from 'app/themes/association-context'
import { NotistackProvider } from 'app/themes/notistack-provider'
import { useDaylightTheme } from 'app/themes/theme'
import { UserContext, useUserContext, useUserState } from 'app/themes/user-context'
import { useYearState, YearContext } from 'app/themes/year-context'
import { useUser } from 'app/users/auth'
import { t } from 'shared/i18n/current'
import { currentDateFnsLocale, currentDateFnsUtils } from 'shared/i18n/i18n-yup/i18n'
import { ErrorBoundary } from 'utils/error-boundary'
import { CenterLoading } from 'utils/loading'
import { useBoolean } from 'utils/use-boolean'

initializeFirebaseApp()

export function App() {
  const i18n = useI18nState()

  return (
    <I18nContext.Provider value={i18n}>
      <AppWithRouter />
    </I18nContext.Provider>
  )
}

function AppWithRouter() {
  const user = useUser()
  return (
    <BrowserRouter>
      <AppWithUser user={user} />
    </BrowserRouter>
  )
}

function AppWithUser({ user }: { user: ReturnType<typeof useUser> }) {
  const userState = useUserState(user)
  return (
    <UserContext.Provider value={userState}>
      <AppWithAssociationContext />
    </UserContext.Provider>
  )
}

function AppWithAssociationContext() {
  const association = useAssociationState()
  const yearState = useYearState()
  return (
    <AssociationContext.Provider value={association}>
      <YearContext.Provider value={yearState}>
        <AppWithAdminDBContext />
      </YearContext.Provider>
    </AssociationContext.Provider>
  )
}

function AppWithAdminDBContext() {
  const user = useUserContext()
  return user.preloadDocuments ? (
    <AdminDBProvider>
      <AppUsingRouter />
    </AdminDBProvider>
  ) : (
    <AppUsingRouter />
  )
}

function AppUsingRouter() {
  const theme = useDaylightTheme()

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={currentDateFnsUtils()} locale={currentDateFnsLocale()}>
        <NotistackProvider>
          <CssBaseline />
          <AppUsingErrorBoundary />
        </NotistackProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

function AppUsingErrorBoundary() {
  return (
    <ErrorBoundary>
      <AppUsingUpdateNotification />
    </ErrorBoundary>
  )
}

function AppUsingUpdateNotification() {
  return (
    <AppUpdateNotification>
      <AppUsingAppContent />
    </AppUpdateNotification>
  )
}

function AppUsingAppContent() {
  const loaded = useBoolean(false)
  useDebounce(loaded.setTrue, 500)
  return loaded.value ? <AppContent /> : <CenterLoading message={t().signIn.loading} />
}
