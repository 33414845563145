import * as m1 from './2022-01-07T23-28-10-000Z-migrate-user-events'
import * as m2 from './2022-01-18T22-39-40-305Z-migrate-sport-event-categories'
import * as m3 from './2022-01-19T20-14-51-513Z-migrate-inscriptions-v-1-to-v-2'
import * as m4 from './2022-02-20T17-39-08-129Z-add-transponder-type-to-sport-events'
import * as m5 from './2022-05-15T17-18-40-206Z-migrates-inscription-bookings-to-sport-event-associations'
import * as m6 from './2022-07-26T16-29-51-179Z-add-2-day-discounts-to-inscriptions'
import * as m7 from './2022-11-09T19-16-49-028Z-convert-manual-payment-payment-method-to-tag'
import * as m8 from './2022-11-10T00-20-22-751Z-init-tag-caches'
import * as m9 from './2022-11-10T02-24-20-049Z-migrate-payment-associations'
import * as m10 from './2022-11-10T02-32-18-993Z-migrate-manual-booking-associations'
import * as m11 from './2023-01-20T08-15-38-192Z-migrates-category-draft-bike-make-team-name-to-approved-license'
import * as m12 from './2023-02-08T20-46-31-329Z-move-payment-from-deleted-user'
import * as m13 from './2023-02-18T14-49-05-193Z-change-the-fms-transponder-price-bookings-from-2022-12-until-now-from-75-to-80'
import * as m14 from './2023-07-31T12-34-24-049Z-migrate-old-transponder-format-to-new-transponder-format'
import * as m15 from './2023-10-30T20-43-12-050Z-create-short-id-mapping'
import * as m16 from './2024-01-11T02-45-46-464Z-process-unprocessed-license-drafts'
import * as m17 from './2024-04-11T10-13-37-761Z-add-sport-events-by-year'
import * as m18 from './2024-04-11T11-49-24-387Z-add-day-category-names-for-search-to-sport-event'
import { Migration } from 'shared/db/db-migrations'

export const migrations: Migration[] = [
  m18,
  m17,
  m16,
  m15,
  m14,
  m13,
  m12,
  m11,
  m10,
  m9,
  m8,
  m7,
  m6,
  m5,
  m4,
  m3,
  m2,
  m1,
].sort((a, b) => a.id.localeCompare(b.id))

if (new Set(migrations.map((m) => m.id)).size !== migrations.length)
  throw new Error('Invalid migration ids')
