import { db } from 'app/db/frontend-db'
import { ScanQRButton } from 'app/license/qr-code/ScanQRButton'
import { routes } from 'shared/config/routes'
import { nextLicenseYear } from 'shared/data/license-config'
import { ApprovedLicense } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { parseInt10 } from 'shared/utils/number'

export function ScanQRLicenseButton(props: { onSuccess: (approvedLicense: ApprovedLicense) => void }) {
  return (
    <ScanQRButton
      text={t().qrScan}
      match={(url) => {
        if (isValidCode(url)) return parseCode(url)
      }}
      onSuccess={async (scan: { shortId: number; licenseCode: string }) => {
        const license = await loadApprovedLicense(scan)
        if (license) props.onSuccess(license)
      }}
    />
  )
}

function isValidCode(code: string) {
  return routes.qrLicenseV2.validStarts.some((validStart) => code.startsWith(validStart))
}

function parseCode(code: string) {
  const codeWithoutStart = routes.qrLicenseV2.validStarts.reduce(
    (previousCode, validStart) => previousCode.replace(validStart, ''),
    code
  )
  const [shortId, licenseCode] = codeWithoutStart.split('/')
  return { shortId: parseInt10(shortId), licenseCode }
}

async function loadApprovedLicense(
  scan: { shortId: number; licenseCode: string } | undefined
): Promise<ApprovedLicense | undefined> {
  if (!scan) return undefined
  const license = await db.loadApprovedLicenseByShortId(nextLicenseYear, scan.shortId)
  return license && license.licenseCode === scan.licenseCode ? license : undefined
}
