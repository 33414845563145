import { useState } from 'react'

export function useBoolean(initialState: boolean): UseBoolean {
  const [value, setValue] = useState(initialState)

  return {
    setTrue: () => setValue(true),
    setFalse: () => setValue(false),
    toggle: () => setValue(!value),
    value: !!value,
  }
}

export interface UseBoolean {
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
  value: boolean
}
