import firebase from 'firebase/app'
import { firebaseFunctions } from 'app/config/firebase'
import { isLocalDevelopment } from 'utils/debug'
import { env } from 'utils/env'

const _enableEmulators = isLocalDevelopment() && !disableEmulatorsForLocalDevelopment()

function disableEmulatorsForLocalDevelopment() {
  return env().REACT_APP_DISABLE_EMULATORS === 'yes_i_want_to_edit_production_data'
}

export function enableEmulators() {
  return isLocalDevelopment() && _enableEmulators
}

export function connectEmulators() {
  if (!isLocalDevelopment()) return

  firebase.auth().useEmulator('http://127.0.0.1:5004/')
  firebaseFunctions().useEmulator('127.0.0.1', 5001)
  firebase.database().useEmulator('127.0.0.1', 5003)
  firebase.storage().useEmulator('127.0.0.1', 5005)
}
