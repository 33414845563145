import {
  uploadStatusOk,
  UploadStatus,
  UploadMetadata,
  UploadStatusDetails,
} from 'shared/models/upload-status'

export function healthCheckOk(model: HealthCheck | undefined) {
  return uploadStatusOk(model?.status)
}

export interface HealthCheck {
  status: UploadStatus
  statusDetails: UploadStatusDetails | undefined
  page1: UploadMetadata
  page2: UploadMetadata
  page3: UploadMetadata
}
