import {
  Box,
  Button,
  createStyles,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { callRacemanagerApi } from 'app/config/firebase'
import { useSportEvents } from 'app/db/db-hooks/main-db-hooks'
import { samFields } from 'app/forms/fields'
import { useIsAssociationAdmin } from 'app/themes/user-context'
import { DisabledForAssociationAdmin } from 'app/users/disabled-for-association-admin'
import { Recipient, SentEmail } from 'shared/api/emails'
import { SendCategoryEmailsEndpoint } from 'shared/api/interfaces'
import { categoryByIdRequired } from 'shared/data/categories-service'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { SendCategoryEmailsQuery } from 'shared/messages/messages'
import { categoryIds } from 'shared/models/category'
import { sportEventDescription } from 'shared/sport-events/sport-events-service'
import { FriendlyError, useError } from 'utils/errors'
import { Loading } from 'utils/loading'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function AdminMessages(_props: { admin: UserQuery }) {
  const classes = useStyles()
  const { data: sportEvents, error: sportEventsError, loading } = useSportEvents()
  const { error, setError } = useError()
  const [previewEmails, setPreviewEmails] = useState<SentEmail[] | undefined>()

  const sportEventOptions = sportEvents.map((event) => ({
    id: event.id,
    name: sportEventDescription(event),
  }))

  const fields = samFields().adminMessages
  const initialValues: FormProps = {
    recipient: categoryByIdRequired(categoryIds[0]),
    date: 'Samstag, 24. April 2021 - Sonntag, 25. April 2021',
    location: 'Armeno (Piemont I)',
    type: 'missing-license-info',
    simulate: true,
    sportEvent: '',
  }

  const associationAdmin = useIsAssociationAdmin()
  if (associationAdmin) return <DisabledForAssociationAdmin title={t().messages.sendMessages} />

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().messages.sendMessages}
        </Typography>
      </Box>
      <Paper elevation={3}>
        <Box p={2}>
          <Loading loading={loading} />
          <FriendlyError error={error} pb={2} />
          <FriendlyError error={sportEventsError} pb={2} />

          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={schema()}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                setPreviewEmails(await onSubmit(values))
              } catch (error: any) {
                setError(error)
              }
              setSubmitting(false)
            }}
          >
            {({ submitForm, isSubmitting, touched, errors, values }) => (
              <Form>
                <Grid container spacing={1} className={classes.gridParent}>
                  <Grid item xs={12}>
                    {fields.recipient.field({ touched, errors })}
                  </Grid>
                  <Grid item xs={12}>
                    {fields.type.field()}
                  </Grid>
                  <Grid item xs={12}>
                    {fields.simulate.field()}
                  </Grid>
                  {values.type === 'first-race' && (
                    <Grid item xs={12}>
                      {fields.location.field()}
                    </Grid>
                  )}
                  {values.type === 'first-race' && (
                    <Grid item xs={12}>
                      {fields.date.field()}
                    </Grid>
                  )}
                  {values.type === 'next-race' && (
                    <Grid item xs={12}>
                      {fields.sportEvent.field(sportEventOptions)}
                    </Grid>
                  )}
                  {values.type === 'postponed-next-race' && (
                    <Grid item xs={12}>
                      {fields.sportEvent.field(sportEventOptions)}
                    </Grid>
                  )}

                  {isSubmitting && (
                    <Grid item xs={12}>
                      <LinearProgress />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      {values.simulate
                        ? isSubmitting
                          ? t().messages.generatingPreview
                          : t().messages.generatePreview
                        : isSubmitting
                        ? t().messages.sendingEmail
                        : t().messages.confirmSendEmail}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
      <EmailPreview emails={previewEmails} />
    </>
  )
}

function onSubmit(values: FormProps) {
  const query: SendCategoryEmailsQuery = {
    recipient: values.recipient.id,
    date: values.date,
    location: values.location,
    type: values.type,
    simulate: values.simulate,
    sportEventId: values.sportEvent,
  }
  return callRacemanagerApi<SendCategoryEmailsEndpoint>('sendCategoryEmails', query)
}

function schema() {
  return Yup.object().defined()
}

interface FormProps {
  recipient: { id: Recipient }
  type: SendCategoryEmailsQuery['type']
  simulate: boolean
  location: string
  date: string
  sportEvent: string
}

const useStylesPreview = makeStyles((theme: Theme) =>
  createStyles({ preview: { '& a': { color: theme.palette.primary.main } } })
)

function EmailPreview({ emails }: { emails: SentEmail[] | undefined }) {
  const classes = useStylesPreview()
  const theme = useTheme()

  if (!emails) return null

  return (
    <Paper elevation={3}>
      <Box my={2} p={2} pb={2}>
        <Box mb={2}>
          <Typography component="h3" variant="h3">
            {t().messages.emailPreview}
          </Typography>
        </Box>
        {emails.map((email, index) => (
          <Paper key={index} elevation={3} style={{ background: theme.palette.background.default }}>
            <Box p={2} mt={2}>
              <Box>Status: {email.sent ? t().messages.sent : t().messages.preview}</Box>
              <Box>Empfänger: {email.email.to}</Box>
              <Box mt={1}>{email.email.subject}</Box>
              <Box mt={1} className={classes.preview}>
                <div dangerouslySetInnerHTML={{ __html: email.email.html }} />
              </Box>
            </Box>
          </Paper>
        ))}
      </Box>
    </Paper>
  )
}
