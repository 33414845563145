import { Box, Button } from '@material-ui/core'
import { sortBy } from 'lodash'
import { useApprovedLicenses } from 'app/db/db-hooks/main-db-hooks'
import { useI18nContext } from 'app/i18n/i18n-context'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { generateLicense, useLicensePreview } from 'app/pages/licenses/pdf-license-service'
import { usePitLane2022Preview } from 'app/pages/licenses/pdf-pit-lane-service-2022'
import { UserState, useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { categories } from 'shared/data/categories-service'
import { ApprovedLicense } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { AssociationCategory, Category } from 'shared/models/category'
import { truthy } from 'shared/utils/array'
import { FriendlyError, useError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { PDFPreview } from 'utils/pdf/pdf-preview'
import { useQuery } from 'utils/router'

export function PreviewLicensePage() {
  const query = useQuery().query
  const userContext = useUserContext()
  const year = useLicenseYear()
  const { loading, data, error } = useApprovedLicenses(
    { uid: query.get('uid') || userContext.user?.uid || '' },
    year
  )
  const license = Object.values(data || {}).find((license) =>
    license.categoryId.toLowerCase().includes((query.get('category') || '').toLowerCase())
  )
  const locale = useI18nContext().locale
  const pitLanePreview = query.get('pit-lane')

  const allCombinations = sortBy(categories(locale), (category) => [
    category.type,
    category.associations[0],
    category.commonName,
  ])
    .filter((category) => category.year === year)
    .flatMap((category) =>
      category.associationSpecificDetails.flatMap((details) =>
        [
          details.offersNationalLicense ? ('national' as const) : undefined,
          details.offersInternationalLicense ? ('international' as const) : undefined,
        ]
          .filter(truthy)
          .map((licenseType) => ({ licenseType, details, category }))
      )
    )

  return (
    <ElevatedBox title="Preview Licenses">
      <FriendlyError error={error} />
      <Loading loading={loading} />
      {license && <PDFPreview />}

      <Box>
        <Button
          onClick={async () => {
            for await (const { licenseType, details, category } of allCombinations) {
              await download(details, category, licenseType, query, userContext)
            }
          }}
        >
          Download all
        </Button>
      </Box>
      <Box>
        {allCombinations.map(({ licenseType, details, category }) => (
          <Button
            size="small"
            key={[licenseType, details.association, category.id].join('-')}
            onClick={() => download(details, category, licenseType, query, userContext)}
          >
            {category.id}, {details.association}, {licenseType}
          </Button>
        ))}
      </Box>
      <Box>
        {license
          ? !pitLanePreview && <PreviewLicense license={license} />
          : loading
          ? ''
          : t().licenses.licenseNotFound}
      </Box>
      <Box>
        {license
          ? pitLanePreview && <PreviewPitLanePass license={license} />
          : loading
          ? ''
          : t().licenses.licenseNotFound}
      </Box>
    </ElevatedBox>
  )
}

async function download(
  details: AssociationCategory,
  category: Category,
  licenseType: 'national' | 'international',
  query: URLSearchParams,
  userContext: UserState
) {
  const now = new Date(1999, 10, 10).toISOString()
  const license: ApprovedLicense = {
    approvedAt: now,
    approvedBy: '',
    licenseAssociation: details.association,
    categoryId: category.id,
    draftProcessedAt: now,
    association: details.association.toUpperCase() as any,
    issuedNumber: Math.round(Math.random() * 1000),
    licenseType,
    paid: true,
    remarksAdmin: '',
    remarksRider: '',
    uid: query.get('uid') || userContext.user?.uid || '',
    currentSidecarPartner: '',
    shortId: '123456 (example license only)' as any,
    sidecarPartner: '',
    licenseCode: 'abc',
    pitLanePassCode: 'def',
  }
  await generateLicense(license, 'license')
}

export function PreviewLicense({ license }: { license: ApprovedLicense }) {
  const { error, setError } = useError()
  useLicensePreview(setError, { license, type: 'license' })
  return (
    <>
      <FriendlyError error={error} />
    </>
  )
}

export function PreviewPitLanePass({ license }: { license: ApprovedLicense }) {
  const { error, setError } = useError()
  usePitLane2022Preview(setError, { license })
  return (
    <>
      <FriendlyError error={error} />
    </>
  )
}
