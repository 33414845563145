import assertNever from 'assert-never'
import { AccountingGroup } from 'app/pages/admin/financials/accounting-group'
import { t } from 'shared/i18n/current'
import { translateCategoryType } from 'shared/i18n/i18n-helpers'

export function accountingGroupName(row: AccountingGroup) {
  const groupName = accountingGroupSumName(row.type)
  return row.type === 'open'
    ? groupName
    : row.type === 'inscription'
    ? `${groupName} - ${translateCategoryType(row.categoryType)}`
    : row.type === 'donation'
    ? t().transactions.donation
    : row.type === 'yearLicense'
    ? `${groupName} - ${translateCategoryType(row.categoryType)}`
    : row.type === 'dayLicense'
    ? `${groupName} - ${translateCategoryType(row.categoryType)}`
    : row.type === 'transponder'
    ? groupName
    : row.type === 'automaticPayment'
    ? groupName
    : row.type === 'manualBooking'
    ? `${groupName} - ${row.tag || '-'}`
    : row.type === 'manualPayment'
    ? `${groupName} - ${row.tag || '-'}`
    : row.type === 'associationPayment'
    ? groupName
    : assertNever(row)
}

export function accountingGroupSumName(type: AccountingGroup['type']): string {
  return t().accountingGroups.sumNames[type]
}
