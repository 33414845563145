import { Grid, LinearProgress } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import {
  samFieldsPersonalDataAssociationsSchema,
  AdditionalInfoFormFields,
} from 'app/license/steps/additional-info-form-fields'
import { formDataToPersonalData, personalDataToFormData } from 'app/personal-data/personal-data-form'
import { categoryOfAssociationByLicenseCategory } from 'shared/data/categories-service'
import { LicenseFormData } from 'shared/db/db'
import { LicenseCategoryContext, licenseCategoryContexts } from 'shared/license/license-draft-categories'
import { PersonalData } from 'shared/models/personal-data'

export function needsAdditionalInfo(contexts: LicenseCategoryContext[]) {
  return contexts.some(
    ({ association }) => association === 'sam' || association === 'afm' || association === 'fms'
  )
}

interface AdditionalInfoFormProps {
  lockMemberFields: boolean
  initialValues: PersonalData | undefined
  onSubmit: (data: PersonalData) => Promise<void>
  submitLabel?: string
  onBack?: () => void
  autoHighlightSubmit?: true
  licenseData: LicenseFormData
}

export function AdditionalInfoForm(props: AdditionalInfoFormProps) {
  const {
    autoHighlightSubmit,
    submitLabel,
    initialValues,
    onBack,
    onSubmit,
    lockMemberFields,
    licenseData,
  } = props

  if (!initialValues) return null

  const loadedInitialValues = personalDataToFormData(initialValues)
  const licenseCategories = licenseCategoryContexts(
    licenseData.licenseDrafts,
    licenseData.approvedLicenses
  )
  const associations = new Set(licenseCategories.map(({ association }) => association))
  const categories = licenseCategories.map((c) => categoryOfAssociationByLicenseCategory(c))

  return (
    <Formik
      initialValues={loadedInitialValues}
      enableReinitialize={true}
      validationSchema={samFieldsPersonalDataAssociationsSchema(categories)}
      onSubmit={async (values, { setSubmitting }) => {
        if (values.newSamMember) delete values.samMemberNumber
        await onSubmit(formDataToPersonalData(values, values.email))
        setSubmitting(false)
      }}
    >
      {({ submitForm, isSubmitting, values, dirty, errors }) => (
        <Form>
          <AdditionalInfoFormFields
            associations={associations}
            errors={errors}
            values={values}
            lockMemberFields={lockMemberFields}
          />
          <Grid container spacing={1}>
            {isSubmitting && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            <AccordionNavigationButtons
              isSubmitting={isSubmitting}
              onBack={onBack}
              submitForm={submitForm}
              nextLabel={submitLabel}
              autoHighlightSubmit={autoHighlightSubmit}
              dirty={dirty}
              spacingTop
            />
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
