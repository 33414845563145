import { CallMerge } from '@material-ui/icons'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { UserRecord } from 'shared/api/interfaces'
import { Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'

interface MergeUsersButtonProps {
  users: {
    user: UserRecord
    documents: Documents
  }[]
  admin: UserRecord
}
export function MergeUsersButton({ users, admin }: MergeUsersButtonProps) {
  const [userA, userB] = users
  const dialog = useDialog()

  if (users.length !== 2) return null

  return (
    <>
      <IconButtonWithTooltip size="small" tooltip={t().combine} onClick={() => dialog.open()}>
        <CallMerge />
      </IconButtonWithTooltip>
      {dialog.isOpen && (
        <ConfirmDialog maxWidth="xl" fullWidth title={t().combine} dialog={dialog}>
          <div>TODO: finish this</div>

          <div style={{ display: 'none' }}>{JSON.stringify(userA)}</div>
          <div style={{ display: 'none' }}>{JSON.stringify(userB)}</div>
          <div style={{ display: 'none' }}>{JSON.stringify(admin)}</div>
        </ConfirmDialog>
      )}
    </>
  )
}
