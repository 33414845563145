import firebase from 'firebase/app'
import { DB } from 'shared/db/db'

class FrontendDB extends DB {
  async push<T extends { id?: string | undefined }>(path: string, data: T) {
    const ref = firebase.database().ref(path).push()
    data.id = ref.key || undefined
    await ref.set(data)
    return data
  }

  async moveRef(from: string, to: string) {
    const snapshot = await firebase.database().ref(from).once('value')
    if (!snapshot.exists()) return

    const data = snapshot.val()
    await this.set(to, data)
    await this.ref(from).remove()
  }

  ref(path: string) {
    return firebase.database().ref(path)
  }
}

export const db = new FrontendDB()
