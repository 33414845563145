import { saveAs } from 'file-saver'
import { csvStringify } from 'app/export/load-csv-stringify'
import { convertData } from 'app/export/table'
import { toFilename } from 'shared/utils/string'
import { TableData } from 'shared/utils/table-data'

export function downloadCsv(data: TableData, fileNamePrefix: string) {
  return downloadTextAsCsv(csvStringify(convertData(data)), `${toFilename(fileNamePrefix)}.csv`)
}

function downloadTextAsCsv(text: string, fileName: string) {
  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, fileName, { autoBom: true })
}
