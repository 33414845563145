export const constInsuranceOptions = [
  {
    id: '360-30',
    name: 'V1',
    benefitDurationDays: 360,
    waitingPeriodDays: 30,
    price: 175,
    standard: true,
    archived: false,
  },
  {
    id: '360-60',
    name: 'V2',
    benefitDurationDays: 360,
    waitingPeriodDays: 60,
    price: 125,
    standard: false,
    archived: false,
  },
  {
    id: '720-30',
    name: 'V3',
    benefitDurationDays: 720,
    waitingPeriodDays: 30,
    price: 350,
    standard: false,
    archived: false,
  },
  {
    id: '720-60',
    name: 'V4',
    benefitDurationDays: 720,
    waitingPeriodDays: 60,
    price: 250,
    standard: false,
    archived: false,
  },
] as const
