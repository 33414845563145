import 'react-app-polyfill/stable'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/300-italic.css'
import '@fontsource/roboto/400-italic.css'
import '@fontsource/roboto/500-italic.css'
import '@fontsource/roboto/700-italic.css'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions'
import 'firebase/performance'
import 'firebase/app'
import './index.scss'
import { App } from 'app/app'
import { initSentry } from 'app/config/sentry'
import * as serviceWorker from 'app/serviceWorker'
import { domain, domainFMS, domainSAM } from 'shared/config/domain'
import { initI18n } from 'shared/i18n/i18n-yup/i18n'
import { isLocalDevelopment } from 'utils/debug'
import { connectEmulators, enableEmulators } from 'utils/emulators'

const hostname = window.location.hostname
if (hostname === 'sam-events.web.app' || hostname === 'sam-events.firebaseapp.com') {
  window.location.replace(domain)
}

if (hostname === 'racemanager.swissmoto.org' || hostname === 'www.racemanager.swissmoto.org') {
  window.location.replace(domainFMS)
}

if (hostname === 'racemanager.s-a-m.ch' || hostname === 'www.racemanager.s-a-m.ch') {
  window.location.replace(domainSAM)
}

if (enableEmulators()) connectEmulators()

disableWheelScrollingOnNumberInputs()
initSentry()
initI18n()

if (isLocalDevelopment()) {
  const originalConsoleError = console.error
  console.error = function filterWarnings(msg, ...args) {
    const surpressedWarnings = [
      'is using incorrect casing. Use PascalCase for React components, or lowercase for HTML elements.',
      'prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase',
      'findDOMNode is deprecated in StrictMode',
    ]
    try {
      if (surpressedWarnings.some((warning) => msg && 'includes' in msg && msg.includes(warning))) return
    } catch {
      // ignore
    }
    originalConsoleError(msg, ...args)
  }
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

function disableWheelScrollingOnNumberInputs() {
  document.addEventListener('wheel', () => {
    const activeElement = globalThis.document.activeElement
    if (activeElement instanceof HTMLInputElement && activeElement.type === 'number')
      activeElement.blur()
  })
}
