export const constCategories2021 = [
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-u-20',
    shortId: 0,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'U20 (Nachwuchs) [2021] ',
    nameFr: 'U20 (Nachwuchs)',
    nameEn: 'U20 (Nachwuchs)',
    myLapsName: 'U20',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'U20',
    shortNameFr: 'U20',
    shortNameEn: 'U20',
    importName: 'MX U20',
    type: 'motocross',
    prices: [{ association: 'sam', price: 250 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 60,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: 'MyLaps',
    hintDe: '80-150 ccm, 2 Takt, bis 20 Jahre (Jahrgang 2001)',
    hintFr: '80-150 ccm, 2 Takt, bis 20 Jahre (Jahrgang 2001)',
    hintEn: '80-150 ccm, 2 Takt, bis 20 Jahre (Jahrgang 2001)',
    colorLicense: '#f5b912',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-imba-youth',
    shortId: 1,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'IMBA Youth [2021] ',
    nameFr: 'IMBA Youth',
    nameEn: 'IMBA Youth',
    myLapsName: 'IMBA Youth',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'IMBA Youth',
    shortNameFr: 'IMBA Youth',
    shortNameEn: 'IMBA Youth',
    importName: 'MX IMBA Youth',
    type: 'motocross',
    prices: [{ association: 'sam', price: 0 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 0,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 0,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: '',
    hintDe: 'Zusätzlich zu U20 (Nachwuchs)',
    hintFr: 'Zusätzlich zu U20 (Nachwuchs)',
    hintEn: 'Zusätzlich zu U20 (Nachwuchs)',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-junioren-open-a',
    shortId: 2,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Junioren Open A [2021] ',
    nameFr: 'Junioren Open A',
    nameEn: 'Junioren Open A',
    myLapsName: 'Junioren Open A',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Junioren Open A',
    shortNameFr: 'Junioren Open A',
    shortNameEn: 'Junioren Open A',
    importName: 'MX Junioren Open A',
    type: 'motocross',
    prices: [{ association: 'sam', price: 350 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#cc0000',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe: 'Die ersten 10 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    hintFr: 'Die ersten 10 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    hintEn: 'Die ersten 10 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-junioren-open-b',
    shortId: 3,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Junioren Open B [2021] ',
    nameFr: 'Junioren Open B',
    nameEn: 'Junioren Open B',
    myLapsName: 'Junioren Open B',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Junioren Open B',
    shortNameFr: 'Junioren Open B',
    shortNameEn: 'Junioren Open B',
    importName: 'MX Junioren Open B',
    type: 'motocross',
    prices: [{ association: 'sam', price: 250 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffffff',
    colorForeground: '#cc0000',
    transponder: 'MyLaps',
    hintDe:
      'Einsteigerkategorie, eigene Meisterschaft. \nDie ersten 10 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    hintFr:
      'Einsteigerkategorie, eigene Meisterschaft. \nDie ersten 10 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    hintEn:
      'Einsteigerkategorie, eigene Meisterschaft. \nDie ersten 10 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-national-open-a',
    shortId: 4,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'National Open A [2021] ',
    nameFr: 'National Open A',
    nameEn: 'National Open A',
    myLapsName: 'National Open A',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'National Open A',
    shortNameFr: 'National Open A',
    shortNameEn: 'National Open A',
    importName: 'MX National Open A',
    type: 'motocross',
    prices: [{ association: 'sam', price: 400 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffffff',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: 'Die ersten 5 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    hintFr: 'Die ersten 5 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    hintEn: 'Die ersten 5 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-national-open-b',
    shortId: 5,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'National Open B [2021] ',
    nameFr: 'National Open B',
    nameEn: 'National Open B',
    myLapsName: 'National Open B',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'National Open B',
    shortNameFr: 'National Open B',
    shortNameEn: 'National Open B',
    importName: 'MX National Open B',
    type: 'motocross',
    prices: [{ association: 'sam', price: 400 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#000000',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe: 'Die ersten 5 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    hintFr: 'Die ersten 5 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    hintEn: 'Die ersten 5 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-senioren-open',
    shortId: 6,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Senioren Open [2021] ',
    nameFr: 'Senioren Open',
    nameEn: 'Senioren Open',
    myLapsName: 'Senioren Open',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Senioren Open',
    shortNameFr: 'Senioren Open',
    shortNameEn: 'Senioren Open',
    importName: 'MX Senioren Open',
    type: 'motocross',
    prices: [{ association: 'sam', price: 400 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#3300cc',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe: 'Ab 35 Jahre (Jahrgang 1986)',
    hintFr: 'Ab 35 Jahre (Jahrgang 1986)',
    hintEn: 'Ab 35 Jahre (Jahrgang 1986)',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-senioren-open-u-50',
    shortId: 7,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Senioren Open Ü50 [2021] ',
    nameFr: 'Senioren Open Ü50',
    nameEn: 'Senioren Open Ü50',
    myLapsName: 'Senioren Open Ü50',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Senioren Open Ü50',
    shortNameFr: 'Senioren Open Ü50',
    shortNameEn: 'Senioren Open Ü50',
    importName: 'MX Senioren Open Ü50',
    type: 'motocross',
    prices: [{ association: 'sam', price: 400 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffffff',
    colorForeground: '#3300cc',
    transponder: 'MyLaps',
    hintDe: 'Ab 50 Jahre (Jahrgang 1971)',
    hintFr: 'Ab 50 Jahre (Jahrgang 1971)',
    hintEn: 'Ab 50 Jahre (Jahrgang 1971)',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-masters-sam-open',
    shortId: 8,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Masters Open [2021] ',
    nameFr: 'Masters Open',
    nameEn: 'Masters Open',
    myLapsName: 'Masters Open',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Masters Open',
    shortNameFr: 'Masters Open',
    shortNameEn: 'Masters Open',
    importName: 'MX Masters Open',
    type: 'motocross',
    prices: [{ association: 'sam', price: 400 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: 'Nicht mit Inter-Lizenz eines anderen Verbandes',
    hintFr: 'Nicht mit Inter-Lizenz eines anderen Verbandes',
    hintEn: 'Nicht mit Inter-Lizenz eines anderen Verbandes',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-pro-race-serie-einzellizenz',
    shortId: 9,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'ProRace Serie Einzellizenz [2021] ',
    nameFr: 'ProRace Serie Einzellizenz',
    nameEn: 'ProRace Serie Einzellizenz',
    myLapsName: 'ProRace Serie',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'ProRace Serie',
    shortNameFr: 'ProRace Serie',
    shortNameEn: 'ProRace Serie',
    importName: 'MX ProRace Serie Einzellizenz',
    type: 'motocross',
    prices: [{ association: 'sam', price: 400 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 0,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: 'MyLaps',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-pro-race-serie-zusatz',
    shortId: 10,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'ProRace Serie Zusatz [2021] ',
    nameFr: 'ProRace Serie Zusatz',
    nameEn: 'ProRace Serie Zusatz',
    myLapsName: 'ProRace Serie',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'ProRace Serie Zusatz',
    shortNameFr: 'ProRace Serie Zusatz',
    shortNameEn: 'ProRace Serie Zusatz',
    importName: 'MX ProRace Serie Zusatz',
    type: 'motocross',
    prices: [{ association: 'sam', price: 100 }],
    priceHintDe: 'ohne erweiterte Versicherungsdeckung',
    priceHintFr: 'ohne erweiterte Versicherungsdeckung',
    priceHintEn: 'ohne erweiterte Versicherungsdeckung',
    priceAdditionalLicense: 100,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 0,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: 'MyLaps',
    hintDe: 'Für Besitzer einer Lizenz FMS Inter, FMS Lites, FMS Junioren, SAM Masters, MXRS Super Race',
    hintFr: 'Für Besitzer einer Lizenz FMS Inter, FMS Lites, FMS Junioren, SAM Masters, MXRS Super Race',
    hintEn: 'Für Besitzer einer Lizenz FMS Inter, FMS Lites, FMS Junioren, SAM Masters, MXRS Super Race',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-imba-mx-2',
    shortId: 11,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'IMBA MX2 [2021] ',
    nameFr: 'IMBA MX2',
    nameEn: 'IMBA MX2',
    myLapsName: 'IMBA MX2',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'IMBA MX2',
    shortNameFr: 'IMBA MX2',
    shortNameEn: 'IMBA MX2',
    importName: 'MX IMBA MX2',
    type: 'motocross',
    prices: [{ association: 'sam', price: 0 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 0,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 0,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: '',
    hintDe: 'Zusätzlich zu Pro Race, Masters oder National',
    hintFr: 'Zusätzlich zu Pro Race, Masters oder National',
    hintEn: 'Zusätzlich zu Pro Race, Masters oder National',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-imba-open-class',
    shortId: 12,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'IMBA Open Class [2021] ',
    nameFr: 'IMBA Open Class',
    nameEn: 'IMBA Open Class',
    myLapsName: 'IMBA Open Class',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'IMBA Open Class',
    shortNameFr: 'IMBA Open Class',
    shortNameEn: 'IMBA Open Class',
    importName: 'MX IMBA Open Class',
    type: 'motocross',
    prices: [{ association: 'sam', price: 0 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 0,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 0,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: '',
    hintDe: 'Zusätzlich zu Pro Race, Masters oder National',
    hintFr: 'Zusätzlich zu Pro Race, Masters oder National',
    hintEn: 'Zusätzlich zu Pro Race, Masters oder National',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-swiss-mx-women-cup',
    shortId: 13,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Swiss MX Women Cup [2021] ',
    nameFr: 'Swiss MX Women Cup',
    nameEn: 'Swiss MX Women Cup',
    myLapsName: 'Swiss MX Women Cup',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Swiss MX Women Cup',
    shortNameFr: 'Swiss MX Women Cup',
    shortNameEn: 'Swiss MX Women Cup',
    importName: 'MX Women',
    type: 'motocross',
    prices: [{ association: 'sam', price: 350 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: 'MyLaps',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#f5b912',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-imba-ladies',
    shortId: 14,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'IMBA Ladies [2021] ',
    nameFr: 'IMBA Ladies',
    nameEn: 'IMBA Ladies',
    myLapsName: 'IMBA Ladies',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'IMBA Ladies',
    shortNameFr: 'IMBA Ladies',
    shortNameEn: 'IMBA Ladies',
    importName: 'MX IMBA Ladies',
    type: 'motocross',
    prices: [{ association: 'sam', price: 0 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 0,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: '',
    hintDe: 'Zusätzlich zu MX Women Cup',
    hintFr: 'Zusätzlich zu MX Women Cup',
    hintEn: 'Zusätzlich zu MX Women Cup',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-seitenwagen-fahrer',
    shortId: 15,
    sidecar: true,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Seitenwagen Fahrer [2021] ',
    nameFr: 'Seitenwagen Fahrer',
    nameEn: 'Seitenwagen Fahrer',
    myLapsName: 'Seitenwagen',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Seitenwagen Fahrer',
    shortNameFr: 'Seitenwagen Fahrer',
    shortNameEn: 'Seitenwagen Fahrer',
    importName: 'MX Sidecar Pilot',
    type: 'motocross',
    prices: [{ association: 'sam', price: 350 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 40,
    priceDayLicenseWithoutMember: 50,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#f5b912',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-seitenwagen-passagier',
    shortId: 16,
    sidecar: true,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Seitenwagen Passagier [2021] ',
    nameFr: 'Seitenwagen Passagier',
    nameEn: 'Seitenwagen Passagier',
    myLapsName: 'Nur zur Kontrolle, NICHT importieren - Seitenwagen Passagier',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Seitenwagen Passagier',
    shortNameFr: 'Seitenwagen Passagier',
    shortNameEn: 'Seitenwagen Passagier',
    importName: 'MX Sidecar Passager',
    type: 'motocross',
    prices: [{ association: 'sam', price: 250 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 40,
    priceDayLicenseWithoutMember: 50,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#f5b912',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-imba-side-car',
    shortId: 17,
    sidecar: true,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'IMBA Side Car [2021] ',
    nameFr: 'IMBA Side Car',
    nameEn: 'IMBA Side Car',
    myLapsName: 'IMBA Side Car',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'IMBA Side Car',
    shortNameFr: 'IMBA Side Car',
    shortNameEn: 'IMBA Side Car',
    importName: 'MX IMBA Side Car',
    type: 'motocross',
    prices: [{ association: 'sam', price: 0 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 0,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: '',
    hintDe: 'Zusätzlich zu Seitenwagen',
    hintFr: 'Zusätzlich zu Seitenwagen',
    hintEn: 'Zusätzlich zu Seitenwagen',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-e-klasse',
    shortId: 18,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'E-Klasse (Elektro) [2021] ',
    nameFr: 'E-Klasse (Elektro)',
    nameEn: 'E-Klasse (Elektro)',
    myLapsName: 'E-Klasse',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'E-Klasse',
    shortNameFr: 'E-Klasse',
    shortNameEn: 'E-Klasse',
    importName: 'MX E-Klasse',
    type: 'motocross',
    prices: [{ association: 'sam', price: 250 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#000000',
    colorForeground: '#ffff00',
    transponder: 'MyLaps',
    hintDe: 'Meisterschaft ab 5 Lizenzen',
    hintFr: 'Meisterschaft ab 5 Lizenzen',
    hintEn: 'Meisterschaft ab 5 Lizenzen',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'motocross-quad',
    shortId: 19,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Quad [2021] ',
    nameFr: 'Quad',
    nameEn: 'Quad',
    myLapsName: 'Quad',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Quad',
    shortNameFr: 'Quad',
    shortNameEn: 'Quad',
    importName: 'MX Quad',
    type: 'motocross',
    prices: [{ association: 'sam', price: 350 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 50,
    priceDayLicenseWithoutMember: 70,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#f5b912',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'mofacross-m-0',
    shortId: 20,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Mofacross M0 [2021] ',
    nameFr: 'Mofacross M0',
    nameEn: 'Mofacross M0',
    myLapsName: 'M0',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Mofacross M0',
    shortNameFr: 'Mofacross M0',
    shortNameEn: 'Mofacross M0',
    importName: '',
    type: 'mofacross',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 65,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 40,
    priceDayLicenseWithoutMember: 40,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffffff',
    colorForeground: '#000000',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#449eda',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'mofacross-m-1',
    shortId: 21,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Mofacross M1 [2021] ',
    nameFr: 'Mofacross M1',
    nameEn: 'Mofacross M1',
    myLapsName: 'M1',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Mofacross M1',
    shortNameFr: 'Mofacross M1',
    shortNameEn: 'Mofacross M1',
    importName: '',
    type: 'mofacross',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 65,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 40,
    priceDayLicenseWithoutMember: 40,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#449eda',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'mofacross-m-2',
    shortId: 22,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Mofacross M2 [2021] ',
    nameFr: 'Mofacross M2',
    nameEn: 'Mofacross M2',
    myLapsName: 'M2',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Mofacross M2',
    shortNameFr: 'Mofacross M2',
    shortNameEn: 'Mofacross M2',
    importName: '',
    type: 'mofacross',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 65,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 40,
    priceDayLicenseWithoutMember: 40,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ff9900',
    colorForeground: '#000000',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#449eda',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'pitbikecross-pitbike-light-125',
    shortId: 23,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Pitbike Light 125 [2021] ',
    nameFr: 'Pitbike Light 125',
    nameEn: 'Pitbike Light 125',
    myLapsName: 'Pitbike Light 125',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Pitbike Light 125',
    shortNameFr: 'Pitbike Light 125',
    shortNameEn: 'Pitbike Light 125',
    importName: '',
    type: 'pitbikecross',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 65,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 40,
    priceDayLicenseWithoutMember: 40,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#3300cc',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#801434',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'pitbikecross-pitbike-open-200',
    shortId: 24,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Pitbike Open 200 [2021] ',
    nameFr: 'Pitbike Open 200',
    nameEn: 'Pitbike Open 200',
    myLapsName: 'Pitbike Open 200',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Pitbike Open 200',
    shortNameFr: 'Pitbike Open 200',
    shortNameEn: 'Pitbike Open 200',
    importName: '',
    type: 'pitbikecross',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 65,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 40,
    priceDayLicenseWithoutMember: 40,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#66ff00',
    colorForeground: '#000000',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#801434',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-kid-65',
    shortId: 25,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Kid 65 [2021] ',
    nameFr: 'Kid 65',
    nameEn: 'Kid 65',
    myLapsName: 'KID 65',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Kid 65',
    shortNameFr: 'Kid 65',
    shortNameEn: 'Kid 65',
    importName: 'SM Kid 65',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 115 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 50,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 20,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#cc0000',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe: 'JG 2007 und jünger',
    hintFr: 'JG 2007 und jünger',
    hintEn: 'JG 2007 und jünger',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-youngster-85',
    shortId: 26,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Youngster 85 [2021] ',
    nameFr: 'Youngster 85',
    nameEn: 'Youngster 85',
    myLapsName: 'YOUNGSTER 85',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Youngster 85',
    shortNameFr: 'Youngster 85',
    shortNameEn: 'Youngster 85',
    importName: 'SM Youngster 85',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 210 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#3300cc',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe: 'JG 2009 bis 2006',
    hintFr: 'JG 2009 bis 2006',
    hintEn: 'JG 2009 bis 2006',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-a-1-125-cup',
    shortId: 27,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'A1 125 Cup [2021] ',
    nameFr: 'A1 125 Cup',
    nameEn: 'A1 125 Cup',
    myLapsName: 'A1 125 CUP',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'A1 125 Cup',
    shortNameFr: 'A1 125 Cup',
    shortNameEn: 'A1 125 Cup',
    importName: 'SM A1 125 Cup',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 210 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffffff',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: 'JG 2009 bis 2003',
    hintFr: 'JG 2009 bis 2003',
    hintEn: 'JG 2009 bis 2003',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-zusatz-internationale-lizenz-youngster-a-1-125-cup',
    shortId: 28,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Internat. Lizenz gewünscht (zusätzlich zu Youngster, A1 125 Cup) [2021] ',
    nameFr: 'Internat. Lizenz gewünscht (zusätzlich zu Youngster, A1 125 Cup)',
    nameEn: 'Internat. Lizenz gewünscht (zusätzlich zu Youngster, A1 125 Cup)',
    myLapsName:
      'Nur zur Kontrolle, NICHT importieren - Zusatz Internationale Lizenz (Youngster, A1 125 Cup)',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Internat. Lizenz gewünscht (zusätzlich zu Youngster, A1 125 Cup)',
    shortNameFr: 'Internat. Lizenz gewünscht (zusätzlich zu Youngster, A1 125 Cup)',
    shortNameEn: 'Internat. Lizenz gewünscht (zusätzlich zu Youngster, A1 125 Cup)',
    importName: '',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 0 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 290,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-women',
    shortId: 29,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Women [2021] ',
    nameFr: 'Women',
    nameEn: 'Women',
    myLapsName: 'WOMEN',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Women',
    shortNameFr: 'Women',
    shortNameEn: 'Women',
    importName: 'SM Women',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 320 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 100,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 50,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#FF66FF',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: 'JG 2006 und älter',
    hintFr: 'JG 2006 und älter',
    hintEn: 'JG 2006 und älter',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-elektro',
    shortId: 30,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Elektro [2021] ',
    nameFr: 'Elektro',
    nameEn: 'Elektro',
    myLapsName: 'ELEKTRO',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Elektro',
    shortNameFr: 'Elektro',
    shortNameEn: 'Elektro',
    importName: 'SM Elektro',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 320 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 100,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 50,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#cc0000',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe:
      'JG 2006 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
    hintFr:
      'JG 2006 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
    hintEn:
      'JG 2006 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-zusatz-internationale-lizenz-women-elektro',
    shortId: 31,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Internat. Lizenz gewünscht (zusätzlich zu Women, Elektro) [2021] ',
    nameFr: 'Internat. Lizenz gewünscht (zusätzlich zu Women, Elektro)',
    nameEn: 'Internat. Lizenz gewünscht (zusätzlich zu Women, Elektro)',
    myLapsName: 'Nur zur Kontrolle, NICHT importieren - Zusatz Internationale Lizenz (Women, Elektro)',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Internat. Lizenz gewünscht (zusätzlich zu Women, Elektro)',
    shortNameFr: 'Internat. Lizenz gewünscht (zusätzlich zu Women, Elektro)',
    shortNameEn: 'Internat. Lizenz gewünscht (zusätzlich zu Women, Elektro)',
    importName: '',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 0 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 330,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-einsteiger',
    shortId: 32,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Einsteiger [2021] ',
    nameFr: 'Einsteiger',
    nameEn: 'Einsteiger',
    myLapsName: 'EINSTEIGER',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Einsteiger',
    shortNameFr: 'Einsteiger',
    shortNameEn: 'Einsteiger',
    importName: 'SM Einsteiger',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 320 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 100,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 50,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#009900',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe: 'JG 2006 und älter',
    hintFr: 'JG 2006 und älter',
    hintEn: 'JG 2006 und älter',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-senior',
    shortId: 33,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Senior [2021] ',
    nameFr: 'Senior',
    nameEn: 'Senior',
    myLapsName: 'SENIOR',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Senior',
    shortNameFr: 'Senior',
    shortNameEn: 'Senior',
    importName: 'SM Senior',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 550 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 50,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#3300cc',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe: 'JG 1986 und älter',
    hintFr: 'JG 1986 und älter',
    hintEn: 'JG 1986 und älter',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-challenge',
    shortId: 34,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Challenge [2021] ',
    nameFr: 'Challenge',
    nameEn: 'Challenge',
    myLapsName: 'CHALLENGE',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Challenge',
    shortNameFr: 'Challenge',
    shortNameEn: 'Challenge',
    importName: 'SM Challenge',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 550 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 50,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#000000',
    colorForeground: '#ffffff',
    transponder: 'MyLaps',
    hintDe: 'JG 2006 und älter',
    hintFr: 'JG 2006 und älter',
    hintEn: 'JG 2006 und älter',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-zusatz-internationale-lizenz-senior-challenge',
    shortId: 35,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Internat. Lizenz gewünscht (zusätzlich zu Senior, Challenge) [2021] ',
    nameFr: 'Internat. Lizenz gewünscht (zusätzlich zu Senior, Challenge)',
    nameEn: 'Internat. Lizenz gewünscht (zusätzlich zu Senior, Challenge)',
    myLapsName:
      'Nur zur Kontrolle, NICHT importieren - Zusatz Internationale Lizenz (Senior, Challenge)',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Internat. Lizenz gewünscht (zusätzlich zu Senior, Challenge)',
    shortNameFr: 'Internat. Lizenz gewünscht (zusätzlich zu Senior, Challenge)',
    shortNameEn: 'Internat. Lizenz gewünscht (zusätzlich zu Senior, Challenge)',
    importName: '',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 0 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 100,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-prestige',
    shortId: 36,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Prestige [2021] ',
    nameFr: 'Prestige',
    nameEn: 'Prestige',
    myLapsName: 'PRESTIGE',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Prestige',
    shortNameFr: 'Prestige',
    shortNameEn: 'Prestige',
    importName: 'SM Prestige',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 650 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 50,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: 'JG 2006 und älter',
    hintFr: 'JG 2006 und älter',
    hintEn: 'JG 2006 und älter',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'supermoto-quad',
    shortId: 37,
    sidecar: false,
    active: false,
    needsHealthCheck: true,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Quad [2021] ',
    nameFr: 'Quad',
    nameEn: 'Quad',
    myLapsName: 'QUAD INTER',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Quad',
    shortNameFr: 'Quad',
    shortNameEn: 'Quad',
    importName: 'SM Quad',
    type: 'supermoto',
    prices: [{ association: 'sam', price: 650 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 70,
    priceDayLicenseWithoutMember: 90,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 50,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffff00',
    colorForeground: '#000000',
    transponder: 'MyLaps',
    hintDe: 'JG 2006 und älter',
    hintFr: 'JG 2006 und älter',
    hintEn: 'JG 2006 und älter',
    colorLicense: '#2b57a4',
    numberFixed: false,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'minibike-pitbike-160-ccm-series-light',
    shortId: 38,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Pitbike 160 ccm Series Light [2021] ',
    nameFr: 'Pitbike 160 ccm Series Light',
    nameEn: 'Pitbike 160 ccm Series Light',
    myLapsName: 'PITBIKE 160 LIGHT',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Pitbike 160 ccm Series Light',
    shortNameFr: 'Pitbike 160 ccm Series Light',
    shortNameEn: 'Pitbike 160 ccm Series Light',
    importName: '',
    type: 'minibike',
    prices: [{ association: 'sam', price: 115 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 20,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#e40716',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'minibike-pitbike-160-ccm-series-heavy',
    shortId: 39,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Pitbike 160 ccm Series Heavy [2021] ',
    nameFr: 'Pitbike 160 ccm Series Heavy',
    nameEn: 'Pitbike 160 ccm Series Heavy',
    myLapsName: 'PITBIKE 160 HEAVY',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Pitbike 160 ccm Series Heavy',
    shortNameFr: 'Pitbike 160 ccm Series Heavy',
    shortNameEn: 'Pitbike 160 ccm Series Heavy',
    importName: '',
    type: 'minibike',
    prices: [{ association: 'sam', price: 115 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 20,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#e40716',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'minibike-pitbike-190-ccm-series',
    shortId: 40,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Pitbike 190 ccm Series [2021] ',
    nameFr: 'Pitbike 190 ccm Series',
    nameEn: 'Pitbike 190 ccm Series',
    myLapsName: 'PITBIKE 190',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Pitbike 190 ccm Series',
    shortNameFr: 'Pitbike 190 ccm Series',
    shortNameEn: 'Pitbike 190 ccm Series',
    importName: '',
    type: 'minibike',
    prices: [{ association: 'sam', price: 115 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 20,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#e40716',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'minibike-mini-gp-160-ccm-series',
    shortId: 41,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'MiniGP 160 ccm Series [2021] ',
    nameFr: 'MiniGP 160 ccm Series',
    nameEn: 'MiniGP 160 ccm Series',
    myLapsName: 'MINIGP 160',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'MiniGP 160 ccm Series',
    shortNameFr: 'MiniGP 160 ccm Series',
    shortNameEn: 'MiniGP 160 ccm Series',
    importName: '',
    type: 'minibike',
    prices: [{ association: 'sam', price: 115 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 20,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#e40716',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'minibike-mini-gp-190-ccm-series',
    shortId: 42,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'MiniGP 190 ccm Series [2021] ',
    nameFr: 'MiniGP 190 ccm Series',
    nameEn: 'MiniGP 190 ccm Series',
    myLapsName: 'MINIGP 190',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'MiniGP 190 ccm Series',
    shortNameFr: 'MiniGP 190 ccm Series',
    shortNameEn: 'MiniGP 190 ccm Series',
    importName: '',
    type: 'minibike',
    prices: [{ association: 'sam', price: 115 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 20,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#e40716',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'minibike-pitbike-open-mini-gp-open',
    shortId: 43,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Pitbike Open / MiniGP Open [2021] ',
    nameFr: 'Pitbike Open / MiniGP Open',
    nameEn: 'Pitbike Open / MiniGP Open',
    myLapsName: 'PITBIKE / MINIGP OPEN',
    myLapsSpecialExportFormatting: true,
    shortNameDe: 'Pitbike / MiniGP Open',
    shortNameFr: 'Pitbike / MiniGP Open',
    shortNameEn: 'Pitbike / MiniGP Open',
    importName: '',
    type: 'minibike',
    prices: [{ association: 'sam', price: 115 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 140,
    priceInscriptionPower: 30,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 20,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 30,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#e40716',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'mofacup-rookies-e-50-cup',
    shortId: 44,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Rookies E-50 Cup [2021] ',
    nameFr: 'Rookies E-50 Cup',
    nameEn: 'Rookies E-50 Cup',
    myLapsName: 'Rookies E-50 Cup',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Rookies E-50 Cup',
    shortNameFr: 'Rookies E-50 Cup',
    shortNameEn: 'Rookies E-50 Cup',
    importName: '',
    type: 'mofacup',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#963d8f',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'mofacup-superstock-70',
    shortId: 45,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Superstock 70 [2021] ',
    nameFr: 'Superstock 70',
    nameEn: 'Superstock 70',
    myLapsName: 'Superstock 70',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Superstock 70',
    shortNameFr: 'Superstock 70',
    shortNameEn: 'Superstock 70',
    importName: '',
    type: 'mofacup',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#963d8f',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'mofacup-superstock-80',
    shortId: 46,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Superstock 80 [2021] ',
    nameFr: 'Superstock 80',
    nameEn: 'Superstock 80',
    myLapsName: 'Superstock 80',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Superstock 80',
    shortNameFr: 'Superstock 80',
    shortNameEn: 'Superstock 80',
    importName: '',
    type: 'mofacup',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#963d8f',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'mofacup-open-100',
    shortId: 47,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Open 100 [2021] ',
    nameFr: 'Open 100',
    nameEn: 'Open 100',
    myLapsName: 'Open 100',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Open 100',
    shortNameFr: 'Open 100',
    shortNameEn: 'Open 100',
    importName: '',
    type: 'mofacup',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#963d8f',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'mofacup-puch-cup',
    shortId: 48,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: true,
    needsEmergency: true,
    showAdditionalInsuranceForm: false,
    nameDe: 'Puch Cup [2021] ',
    nameFr: 'Puch Cup',
    nameEn: 'Puch Cup',
    myLapsName: 'Puch Cup',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Puch Cup',
    shortNameFr: 'Puch Cup',
    shortNameEn: 'Puch Cup',
    importName: '',
    type: 'mofacup',
    prices: [{ association: 'sam', price: 105 }],
    priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintFr: 'Inklusive erweiterter Versicherungsdeckung',
    priceHintEn: 'Inklusive erweiterter Versicherungsdeckung',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 60,
    priceInscriptionWithLicense: 80,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#963d8f',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-3',
    shortId: 49,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur [2021] ',
    nameFr: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    nameEn: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    myLapsName: 'Klasse 3',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 3',
    shortNameFr: 'Klasse 3',
    shortNameEn: 'Klasse 3',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 30,
    priceDayLicenseWithoutMember: 30,
    priceInscriptionSurcharge: 30,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#ffffff',
    colorForeground: '#000000',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-4',
    shortId: 50,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur [2021] ',
    nameFr: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur',
    nameEn: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur',
    myLapsName: 'Klasse 4',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 4',
    shortNameFr: 'Klasse 4',
    shortNameEn: 'Klasse 4',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#3300cc',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-5',
    shortId: 51,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur [2021] ',
    nameFr: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur',
    nameEn: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur',
    myLapsName: 'Klasse 5',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 5',
    shortNameFr: 'Klasse 5',
    shortNameEn: 'Klasse 5',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#cc0000',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-6',
    shortId: 52,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur [2021] ',
    nameFr: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur',
    nameEn: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur',
    myLapsName: 'Klasse 6',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 6',
    shortNameFr: 'Klasse 6',
    shortNameEn: 'Klasse 6',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#009900',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-7',
    shortId: 53,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur [2021] ',
    nameFr: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur',
    nameEn: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur',
    myLapsName: 'Klasse 7',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 7',
    shortNameFr: 'Klasse 7',
    shortNameEn: 'Klasse 7',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#3300cc',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-8',
    shortId: 54,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur [2021] ',
    nameFr: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur',
    nameEn: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur',
    myLapsName: 'Klasse 8',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 8',
    shortNameFr: 'Klasse 8',
    shortNameEn: 'Klasse 8',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#cc0000',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-8-t',
    shortId: 55,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur [2021] ',
    nameFr: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    nameEn: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    myLapsName: 'Klasse 8T',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 8T',
    shortNameFr: 'Klasse 8T',
    shortNameEn: 'Klasse 8T',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#cc0000',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-9',
    shortId: 56,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur [2021] ',
    nameFr: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur',
    nameEn: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur',
    myLapsName: 'Klasse 9',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 9',
    shortNameFr: 'Klasse 9',
    shortNameEn: 'Klasse 9',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#009900',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-klasse-9-t',
    shortId: 57,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur [2021] ',
    nameFr: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    nameEn: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    myLapsName: 'Klasse 9T',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Klasse 9T',
    shortNameFr: 'Klasse 9T',
    shortNameEn: 'Klasse 9T',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#009900',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-elektro-trial',
    shortId: 58,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur [2021] ',
    nameFr: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    nameEn: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    myLapsName: 'Elektro Trial',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Elektro Trial',
    shortNameFr: 'Elektro Trial',
    shortNameEn: 'Elektro Trial',
    importName: '',
    type: 'trial',
    prices: [
      { association: 'sam', to: '2003-01-01', price: 50 },
      { association: 'sam', from: '2002-12-31', price: 80 },
    ],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '#cc0000',
    colorForeground: '#ffffff',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-kids-klasse-1',
    shortId: 59,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht [2021] ',
    nameFr: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht',
    nameEn: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht',
    myLapsName: 'Kids Klasse 1',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Kids Klasse 1',
    shortNameFr: 'Kids Klasse 1',
    shortNameEn: 'Kids Klasse 1',
    importName: '',
    type: 'trial',
    prices: [{ association: 'sam', price: 40 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-kids-klasse-2',
    shortId: 60,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht [2021] ',
    nameFr: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht',
    nameEn: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht',
    myLapsName: 'Kids Klasse 2',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Kids Klasse 2',
    shortNameFr: 'Kids Klasse 2',
    shortNameEn: 'Kids Klasse 2',
    importName: '',
    type: 'trial',
    prices: [{ association: 'sam', price: 40 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-kids-klasse-3',
    shortId: 61,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel [2021] ',
    nameFr: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel',
    nameEn: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel',
    myLapsName: 'Kids Klasse 3',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Kids Klasse 3',
    shortNameFr: 'Kids Klasse 3',
    shortNameEn: 'Kids Klasse 3',
    importName: '',
    type: 'trial',
    prices: [{ association: 'sam', price: 40 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-kids-klasse-4',
    shortId: 62,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel [2021] ',
    nameFr: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel',
    nameEn: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel',
    myLapsName: 'Kids Klasse 4',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Kids Klasse 4',
    shortNameFr: 'Kids Klasse 4',
    shortNameEn: 'Kids Klasse 4',
    importName: '',
    type: 'trial',
    prices: [{ association: 'sam', price: 40 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-kids-klasse-5',
    shortId: 63,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer [2021] ',
    nameFr: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer',
    nameEn: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer',
    myLapsName: 'Kids Klasse 5',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Kids Klasse 5',
    shortNameFr: 'Kids Klasse 5',
    shortNameEn: 'Kids Klasse 5',
    importName: '',
    type: 'trial',
    prices: [{ association: 'sam', price: 40 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: true,
    year: 2021,
    id: 'trial-kids-klasse-6',
    shortId: 64,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer [2021] ',
    nameFr: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer',
    nameEn: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer',
    myLapsName: 'Kids Klasse 6',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Kids Klasse 6',
    shortNameFr: 'Kids Klasse 6',
    shortNameEn: 'Kids Klasse 6',
    importName: '',
    type: 'trial',
    prices: [{ association: 'sam', price: 40 }],
    priceHintDe: '',
    priceHintFr: '',
    priceHintEn: '',
    priceAdditionalLicense: 20,
    priceInsuranceBookkeeping: 0,
    priceInscriptionWithLicense: 40,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 10,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#56a933',
    numberFixed: true,
  },
  {
    associations: ['sam'],
    archived: false,
    year: 2021,
    id: 'other-trainingslizenz-funlizenz',
    shortId: 65,
    sidecar: false,
    active: false,
    needsHealthCheck: false,
    needsInsurance: false,
    needsEmergency: false,
    showAdditionalInsuranceForm: false,
    nameDe: 'Trainingslizenz / Funlizenz [2021] ',
    nameFr: 'Trainingslizenz / Funlizenz',
    nameEn: 'Trainingslizenz / Funlizenz',
    myLapsName:
      'Hier kommt die Kategorie für ein Hobbyrennen / Tageslizenz rein - Trainingslizenz / Funlizenz',
    myLapsSpecialExportFormatting: false,
    shortNameDe: 'Fun',
    shortNameFr: 'Fun',
    shortNameEn: 'Fun',
    importName: '',
    type: 'other',
    prices: [{ association: 'sam', price: 200 }],
    priceHintDe:
      'Inklusive erweiterter Versicherungsdeckung. Achtung: läuft schon bald ab: spätestens 1 Jahr nach Lösen der Lizenz, oder Ende des Versicherungsjahr 2021.',
    priceHintFr:
      'Inklusive erweiterter Versicherungsdeckung. Achtung: läuft schon bald ab: spätestens 1 Jahr nach Lösen der Lizenz, oder Ende des Versicherungsjahr 2021.',
    priceHintEn:
      'Inklusive erweiterter Versicherungsdeckung. Achtung: läuft schon bald ab: spätestens 1 Jahr nach Lösen der Lizenz, oder Ende des Versicherungsjahr 2021.',
    priceAdditionalLicense: 80,
    priceInsuranceBookkeeping: 150,
    priceInscriptionWithLicense: 0,
    priceInscriptionPower: 0,
    priceDayLicenseForMember: 0,
    priceDayLicenseWithoutMember: 0,
    priceInscriptionSurcharge: 0,
    priceInscriptionHoursBeforeEvent: 144,
    surcharge: 0,
    surchargeAfter: '2021-01-31T23:00:00.000Z',
    discount: 0,
    discountUntil: '2021-01-31T22:59:59.999Z',
    colorBackground: '',
    colorForeground: '',
    transponder: '',
    hintDe: '',
    hintFr: '',
    hintEn: '',
    colorLicense: '#afa88b',
    numberFixed: true,
  },
] as const
