import { Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import type { FormikProps } from 'formik'
import * as Yup from 'yup'
import { useSportEventOptions, useUserOptions } from 'app/db/db-hooks/autocomplete-hooks'
import { combineFire3 } from 'app/db/db-hooks/db-hook-helpers'
import { useAllManualBookingTags } from 'app/db/db-hooks/financial-db-hooks'
import { AutocompleteOption, samFields } from 'app/forms/fields'
import { useUserContext } from 'app/themes/user-context'
import { SportEventId } from 'shared/db/sport-event-id'
import { t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { Dig } from 'shared/utils/tsc'

interface ManualBookingFieldsProps {
  formik: FormikProps<ManualBookingFormData>
  autocompleteOptions: ManualBookingAutocompleteOptions
  action: 'new' | 'edit'
}

export function ManualBookingFields({ formik, autocompleteOptions, action }: ManualBookingFieldsProps) {
  const fields = samFields().manualBooking
  const { touched, errors } = formik
  const userContext = useUserContext()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {fields.association.field({ disabled: !!userContext.associationAdmin })}
      </Grid>
      <Grid item xs={12}>
        {fields.tag.field({
          touched,
          errors,
          tags: autocompleteOptions.manualBookingTags[formik.values.association] || [],
        })}
      </Grid>
      <Grid item xs={12}>
        {fields.uid.field({ touched, errors, options: autocompleteOptions.userOptions })}
      </Grid>
      <Grid item xs={12}>
        {action === 'edit' && formik.initialValues.uid?.id !== formik.values.uid?.id && (
          <Alert severity="warning" variant="outlined" closeText={t().financials.riderChanged}>
            {t().financials.bookingsOnRiderChanged}
          </Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        {fields.amount.field()}
      </Grid>
      <Grid item xs={12}>
        {fields.bookingText.field()}
      </Grid>
      <Grid item xs={12}>
        {fields.internalRemarks.field()}
      </Grid>
      <Grid item xs={12}>
        {fields.category.field({
          touched,
          errors,
          association: userContext.associationAdmin || undefined,
        })}
      </Grid>
      <Grid item xs={12}>
        {fields.sportEvent.field({
          touched,
          errors,
          options: autocompleteOptions.sportEventOptions,
        })}
      </Grid>
      <Grid item xs={12}>
        {fields.date.field()}
      </Grid>
    </Grid>
  )
}

export function manualBookingSchema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().manualBooking).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['manualBooking']>
  return Yup.object().defined().shape(validations)
}

export function bookingKindText(amount: number) {
  return amount <= 0 ? t().financials.credit : t().financials.debit
}

export function useManualBookingAutocompleteOptions() {
  const userOptions = useUserOptions()
  const manualBookingTags = useAllManualBookingTags()
  const sportEventOptions = useSportEventOptions()
  return combineFire3(
    userOptions,
    manualBookingTags,
    sportEventOptions,
    (userOptions, manualBookingTags, sportEventOptions) => ({
      userOptions,
      manualBookingTags,
      sportEventOptions,
    })
  )
}

export interface ManualBookingAutocompleteOptions {
  userOptions: AutocompleteOption<string>[]
  manualBookingTags: Record<AssociationID, string[]>
  sportEventOptions: AutocompleteOption<string>[]
}

export interface ManualBookingFormData {
  uid: AutocompleteOption | undefined
  date: Date
  bookingText: string
  amount: number
  internalRemarks: string
  sportEvent: AutocompleteOption<SportEventId> | undefined
  category: CategoryOfAssociation | undefined
  tag: string
  association: AssociationID
}
