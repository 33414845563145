import * as YupLocale from 'yup/lib/locale'

/*eslint no-template-curly-in-string: */

const mixed: Required<YupLocale.MixedLocale> = {
  default: '${path} ist ungültig',
  required: '${label} ist ein Pflichtfeld',
  oneOf: '${path} muss einem der folgenden Werte entsprechen: ${values}',
  notOneOf: '${path} darf keinem der folgenden Werte entsprechen: ${values}',
  notType({ path, type, value, originalValue }) {
    const isCast = originalValue !== null && originalValue !== value
    let msg =
      `Für den Programmierer: ${path} muss von Typ \`${type}\` sein, ` +
      `aber der Wert war von Typ: \`${typeof value}\`` +
      (isCast ? ` (gecastet vom Wert \`${typeof originalValue}\`).` : '.')

    if (value === null) {
      msg += `\n Wenn "null" ein gültiger Wert sein soll, so muss das Schema als \`.nullable()\` gekennzeichnet werden.`
    }

    return msg
  },
  defined: '${path} muss definiert sein',
}

const string: Required<YupLocale.StringLocale> = {
  length: '${path} muss genau ${length} Zeichen lang sein',
  min: '${path} muss mindestens ${min} Zeichen lang sein',
  max: '${path} darf höchstens ${max} Zeichen lang sein',
  matches: '${path} muss wie folgt aussehen: "${regex}"',
  email: '${path} muss eine gültige E-Mail-Adresse enthalten',
  url: '${path} muss eine gültige URL sein',
  uuid: '${path} muss eine gültige UUID sein',
  trim: '${path} darf keine Leerzeichen am Anfang oder Ende enthalten',
  lowercase: '${path} darf nur Kleinschreibung enthalten',
  uppercase: '${path} darf nur Großschreibung enthalten',
}

const number: Required<YupLocale.NumberLocale> = {
  min: '${path} muss größer oder gleich ${min} sein',
  max: '${path} muss kleiner oder gleich ${max} sein',
  lessThan: '${path} muss kleiner sein als ${less}',
  moreThan: '${path} muss größer sein als ${more}',
  positive: '${path} muss eine positive Zahl sein',
  negative: '${path} muss eine negative Zahl sein',
  integer: '${path} muss eine ganze Zahl sein',
}

const date: Required<YupLocale.DateLocale> = {
  min: '${path} muss später sein als ${min}',
  max: '${path} muss früher sein als ${max}',
}

const object: Required<YupLocale.ObjectLocale> = {
  noUnknown: '${path}-Feld darf keine Schlüssel verwenden, die nicht im "Objekt-Shape" definiert wurden',
}

const array: Required<YupLocale.ArrayLocale> = {
  min: ({ path, min }) =>
    `${path}-Feld muss mindesten ${min} ${min === 1 ? 'Eintrag' : 'Einträge'} haben`,
  max: ({ path, max }) =>
    `${path}-Feld darf höchstens ${max} ${max === 1 ? 'Eintrag' : 'Einträge'} haben`,
  length: ({ path, length }) =>
    `${path}-Feld muss ${length} ${length === 1 ? 'Eintrag' : 'Einträge'} haben`,
}

const boolean: Required<YupLocale.BooleanLocale> = {
  isValue: ({ path, value }) => `${path}-Feld muss ${value ? 'wahr' : 'falsch'} sein`,
}

export const yupDE = { mixed, string, number, date, object, array, boolean }
