import assertNever from 'assert-never'
import { remove, sortBy, sumBy } from 'lodash'
import { UseWithObj } from 'app/db/db-hooks/db-hook-helpers'
import { useAllInscriptionBookingsByRider } from 'app/db/db-hooks/financial-db-hooks'
import { useSportEventsWithInscriptions } from 'app/db/db-hooks/main-db-hooks'
import { categoryByIdRequired, categoryCommonName, isCategoryId } from 'shared/data/categories-service'
import { Year } from 'shared/data/license-year'
import { InscriptionBooking, InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { SportEventId } from 'shared/db/sport-event-id'
import { totalOpenRemainingBalance } from 'shared/db/transactions-service'
import { AssociationID } from 'shared/models/associations'
import { nameWithPlace } from 'shared/models/personal-data'
import {
  AmountBySource,
  FinancialCategory,
  SportEventFinancialRowByInscription,
} from 'shared/sport-events/sport-event-financials-service'
import {
  isDayCategoryInscription,
  isYearCategoryInscription,
  SportEvent,
} from 'shared/sport-events/sport-events'
import { associationParticipatesInSportEvent } from 'shared/sport-events/sport-events-service'
import { groupByLiteral, isLength, truthy } from 'shared/utils/array'

export function useSportEventFinancials(
  year: Year,
  sportEventIds: SportEventId[],
  association: AssociationID | 'all' | 'none',
  selectedAssociation: AssociationID | 'all' | 'none'
) {
  const {
    data: { sportEvents, inscriptions },
    ...rest
  } = useSportEventsWithInscriptions(year)
  const allInscriptionBookings = useAllInscriptionBookingsByRider()
  const inscriptionsWithContexts = combineInscriptionsWithBookings(
    inscriptions,
    allInscriptionBookings,
    sportEventIds,
    association,
    selectedAssociation
  )
  const inscriptionsWithFinanacials =
    sportEventIds.length === 0
      ? inscriptionsWithContexts
      : inscriptionsWithContexts.filter(
          ({ sportEvent }) => sportEvent === 'unknown' || sportEventIds.includes(sportEvent.id)
        )
  return {
    ...rest,
    inscriptionsWithFinanacials,
    sportEvents: sportEvents
      .map((event) => event.sportEvent)
      .filter((event) => associationCanAccessSportEvent(event, association)),
    bookings: inscriptionsWithFinanacials.flatMap((row) => row.bookings),
  }
}

function combineInscriptionsWithBookings(
  inscriptions: InscriptionWithContextAndSportEvent[],
  allInscriptionBookings: UseWithObj<Record<string, InscriptionBooking[]>>,
  sportEventIds: SportEventId[],
  association: AssociationID | 'all' | 'none',
  selectedAssociation: AssociationID | 'all' | 'none'
) {
  const sportEventInscriptionBookings = Object.fromEntries(
    Object.entries(allInscriptionBookings.data).map(([uid, bookings]) => [
      uid,
      bookings.filter(
        (booking) =>
          sportEventIds.includes(booking.item.sportEventId) &&
          selectedAssociation !== 'none' &&
          (selectedAssociation === 'all' || booking.item.association === selectedAssociation)
      ),
    ])
  )
  const inscriptionsWithContextsPart1: SportEventFinancialRowByInscription[] = inscriptions
    .filter((inscription) => associationCanAccessInscription(inscription, association))
    .map((inscription) => {
      const bookings = remove(
        sportEventInscriptionBookings[inscription.inscription.uid] || [],
        (booking) =>
          booking.item.sportEventId === inscription.sportEvent.id &&
          booking.item.sportEventDate === inscription.inscription.date &&
          booking.item.categoryId === inscription.inscription.category
      )
      return {
        uid: inscription.inscription.uid,
        riderName: nameWithPlace(inscription.documents.personalData),
        category: inscriptionFinancialCategory(inscription),
        sportEvent: inscription.sportEvent,
        amount: calculateInscriptionFinancials(bookings),
        association: extractAssociation(bookings),
        bookings,
      }
    })
  const inscriptionsWithContextsPart2: SportEventFinancialRowByInscription[] = inscriptions
    .filter((inscription) => associationCanAccessInscription(inscription, association))
    .map((inscription) => {
      const bookings = remove(
        sportEventInscriptionBookings[inscription.inscription.uid] || [],
        (booking) =>
          booking.item.sportEventId === inscription.sportEvent.id &&
          booking.item.categoryId === inscription.inscription.category
      )
      if (bookings.length === 0) return undefined
      return {
        uid: inscription.inscription.uid,
        riderName: nameWithPlace(inscription.documents.personalData),
        category: inscriptionFinancialCategory(inscription),
        sportEvent: inscription.sportEvent,
        amount: calculateInscriptionFinancials(bookings),
        association: extractAssociation(bookings),
        bookings,
      }
    })
    .filter(truthy)
  const inscriptionsWithContextsPart3: SportEventFinancialRowByInscription[] = inscriptions
    .filter((inscription) => associationCanAccessInscription(inscription, association))
    .map((inscription) => {
      const bookings = remove(
        sportEventInscriptionBookings[inscription.inscription.uid] || [],
        (booking) => booking.item.sportEventId === inscription.sportEvent.id
      )
      if (bookings.length === 0) return undefined
      return {
        uid: inscription.inscription.uid,
        riderName: nameWithPlace(inscription.documents.personalData),
        category: inscriptionFinancialCategory(inscription),
        sportEvent: inscription.sportEvent,
        amount: calculateInscriptionFinancials(bookings),
        association: extractAssociation(bookings),
        bookings,
      }
    })
    .filter(truthy)
  const inscriptionsWithContextsPart4: SportEventFinancialRowByInscription[] = inscriptions
    .filter((inscription) => associationCanAccessInscription(inscription, association))
    .map((inscription) => {
      const bookings = remove(
        sportEventInscriptionBookings[inscription.inscription.uid] || [],
        () => true
      )
      if (bookings.length === 0) return undefined
      return {
        uid: inscription.inscription.uid,
        riderName: nameWithPlace(inscription.documents.personalData),
        category: inscriptionFinancialCategory(inscription),
        sportEvent: inscription.sportEvent,
        amount: calculateInscriptionFinancials(bookings),
        association: extractAssociation(bookings),
        bookings,
      }
    })
    .filter(truthy)
  const remaining: SportEventFinancialRowByInscription[] = Object.values(sportEventInscriptionBookings)
    .flat()
    .map((booking) => ({
      uid: booking.uid,
      riderName: booking.uid,
      category: { id: '?', name: '?', typeName: '?' },
      sportEvent: 'unknown',
      amount: calculateInscriptionFinancials([booking]),
      association: extractAssociation([booking]),
      bookings: [booking],
    }))
  const inscriptionsWithContexts = [
    ...inscriptionsWithContextsPart1,
    ...inscriptionsWithContextsPart2,
    ...inscriptionsWithContextsPart3,
    ...inscriptionsWithContextsPart4,
    ...remaining,
  ]
  return sortBy(inscriptionsWithContexts, (row) => [row.category.name, row.riderName])
}

function associationCanAccessInscription(
  inscriptionWithContext: InscriptionWithContextAndSportEvent,
  association: AssociationID | 'all' | 'none'
): unknown {
  if (association === 'all') return true
  if (association === 'none') return false
  if (inscriptionWithContext.sportEvent.association === association) return true
  if (!associationCanAccessSportEvent(inscriptionWithContext.sportEvent, association)) return false

  const inscription = inscriptionWithContext.inscription

  if (isDayCategoryInscription(inscription))
    return inscriptionWithContext.sportEvent.association === association

  if (isYearCategoryInscription(inscription))
    return categoryByIdRequired(inscription.category).associations.includes(association)

  if (inscription.type === 'unlistedDayLicenseInscription')
    return isCategoryId(inscription.category)
      ? categoryByIdRequired(inscription.category).associations.includes(association)
      : inscriptionWithContext.sportEvent.association === association

  assertNever(inscription)
}

function associationCanAccessSportEvent(
  sportEvent: SportEvent,
  association: AssociationID | 'all' | 'none'
): unknown {
  if (association === 'all') return true
  if (association === 'none') return false
  return (
    sportEvent.association === association ||
    associationParticipatesInSportEvent(sportEvent, association)
  )
}

function inscriptionFinancialCategory(
  inscription: InscriptionWithContextAndSportEvent
): FinancialCategory {
  const categoryId = inscription.inscription.category
  if (isCategoryId(categoryId)) {
    const category = categoryByIdRequired(categoryId)
    return { id: category.id, name: categoryCommonName(category), typeName: category.typeName }
  }

  const category = inscription.dayCategory

  if (!category) return { id: categoryId, name: categoryId, typeName: 'Tageskategorie' }

  return { id: category.id, name: categoryCommonName(category), typeName: 'Tageskategorie' }
}

function calculateInscriptionFinancials(inscriptionBookings: InscriptionBooking[]): AmountBySource {
  const bookings = inscriptionBookings

  const bookingsByGroup = groupByLiteral(bookings, (booking) =>
    booking.item.type === 'inscriptionDayCategoryLineItem' || booking.item.type === 'inscriptionLineItem'
      ? 'inscription'
      : booking.item.type === 'powerLineItem' ||
        booking.item.type === 'inscriptionDayCategoryPowerLineItem'
      ? 'power'
      : booking.item.type === 'inscriptionDayLicenseLineItem'
      ? 'dayLicense'
      : booking.item.type === 'donationLineItem'
      ? 'donation'
      : booking.item.type === 'inscriptionDiscountLineItem'
      ? 'inscriptionDiscount'
      : assertNever(booking.item)
  )

  return {
    count: bookings.length > 0 ? 1 : 0,
    total: sumBy(bookings, (booking) => booking.item.price),
    inscription: sumBy(bookingsByGroup.inscription || [], (booking) => booking.item.price),
    power: sumBy(bookingsByGroup.power || [], (booking) => booking.item.price),
    dayLicense: sumBy(bookingsByGroup.dayLicense || [], (booking) => booking.item.price),
    donation: sumBy(bookingsByGroup.donation || [], (booking) => booking.item.price),
    inscriptionDiscount: sumBy(
      bookingsByGroup.inscriptionDiscount || [],
      (booking) => booking.item.price
    ),
    open: totalOpenRemainingBalance(bookings),
  }
}

function extractAssociation(bookings: InscriptionBooking[]): AssociationID | 'multiple' | 'none' {
  if (isLength(bookings, 0)) return 'none'
  const [first, ...rest] = bookings
  const association = first.item.association
  return rest.every((booking) => booking.item.association === association) ? association : 'multiple'
}
