import { DB } from 'shared/db/db'

export async function executeMigration(db: DB, migration: Migration) {
  try {
    await migration.up(db)
    await db.setMigrationExecuted(migration.id)
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function deleteMigrationExecution(db: DB, migration: Migration) {
  await db.deleteMigrationExecution(migration.id)
}

export interface Migration {
  id: string
  description: string
  up: (db: DB) => Promise<void>
  down?: (db: DB) => Promise<void>
}

export interface MigrationState {
  id: string
  runAt: string
}
