import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { useUserContext } from 'app/themes/user-context'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { RoutedButton } from 'utils/buttons/routed-button'

export function AdminBox() {
  const userContext = useUserContext()

  return userContext.adminOrAssociationAdmin ? (
    <ElevatedBox title={t().adminArea}>
      <RoutedButton to={routes.adminDashboard.to} color="primary" variant="contained">
        {t().toAdminArea}
      </RoutedButton>
    </ElevatedBox>
  ) : null
}
