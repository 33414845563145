import { categoryOfAssociationByCategoryDetails } from 'shared/data/categories-service'
import { nextLicenseYear } from 'shared/data/license-config'
import { DB, UserQuery } from 'shared/db/db'
import { UserEventUpload } from 'shared/db/user-event'
import {
  approvedLicenseNeedsHealthCheck,
  draftLicenseNeedsHealthCheck,
} from 'shared/license/needs-health-check'
import { UploadStatusDetails } from 'shared/models/upload-status'

export async function updateHealthCheckUploadStatus(db: DB, adminAndUser: UserAndAdmin) {
  const { user, admin } = adminAndUser
  const healthCheck = ((await db.loadDocuments(user)) || {}).healthCheck

  const uploadComplete = (await needsHealthCheckByDoctor(db, user))
    ? !!(healthCheck?.page1 && healthCheck?.page2 && healthCheck?.page3)
    : !!healthCheck?.page1
  const statusDetails: UploadStatusDetails = newUploadStatusDetails(uploadComplete, admin)
  await setHealthCheckStatus({ db, user, admin, statusDetails })
}

async function needsHealthCheckByDoctor(db: DB, user: UserQuery): Promise<boolean> {
  const personalData = await db.loadPersonalData(user)
  if (!personalData) return false

  const approved = await db.loadApprovedLicenses(user, nextLicenseYear)
  if (
    approved.some(
      (license) =>
        license.licenseType === 'international' &&
        approvedLicenseNeedsHealthCheck(license, personalData.birthdate)
    )
  )
    return true

  const drafts = await db.loadLicenseDrafts(user, nextLicenseYear)
  if (!drafts?.categoryDetails || !drafts.summary) return false

  const allDetails = Object.values(drafts.categoryDetails)

  if (
    allDetails.some((categoryDetails) => {
      if (categoryDetails.licenseType !== 'international') return false

      const category = categoryOfAssociationByCategoryDetails(categoryDetails)
      if (!category) return false

      return draftLicenseNeedsHealthCheck(
        { category, categoryId: categoryDetails.categoryId, categoryDetails, summary: drafts.summary },
        personalData.birthdate
      )
    })
  )
    return true

  return false
}

export async function updateInsuranceUploadStatus(db: DB, adminAndUser: UserAndAdmin) {
  const { user, admin } = adminAndUser
  const insurance = ((await db.loadDocuments(user)) || {}).insurance
  const uploadComplete = !!insurance?.page1
  const statusDetails: UploadStatusDetails = newUploadStatusDetails(uploadComplete, admin)
  await setInsuranceStatus({ db, user, admin, statusDetails })
}

export async function updatePhotoUploadStatus(db: DB, adminAndUser: UserAndAdmin) {
  const { user, admin } = adminAndUser
  const photo = ((await db.loadDocuments(user)) || {}).photo
  const uploadComplete = !!photo?.upload
  const statusDetails: UploadStatusDetails = newUploadStatusDetails(uploadComplete, admin)
  await setPhotoStatus({ db, user, admin, statusDetails })
}

function newUploadStatusDetails(uploadComplete: boolean, admin: UserQuery): UploadStatusDetails {
  const status = uploadComplete ? 'uploaded' : 'notUploaded'
  return { date: new Date().toISOString(), status, noteInternal: '', noteUser: '', byUid: admin.uid }
}

export async function setHealthCheckStatus({ db, user, admin, statusDetails }: UploadStatusUpdate) {
  await db.setHealthCheckStatus(user, statusDetails)
  await pushUserEvent({ db, user, admin, statusDetails, model: 'healthCheck' })
}

export async function setInsuranceStatus({ db, user, admin, statusDetails }: UploadStatusUpdate) {
  await db.setInsuranceStatus(user, statusDetails)
  await pushUserEvent({ db, user, admin, statusDetails, model: 'insurance' })
}

export async function setPhotoStatus({ db, user, admin, statusDetails }: UploadStatusUpdate) {
  await db.setPhotoStatus(user, statusDetails)
  await pushUserEvent({ db, user, admin, statusDetails, model: 'photo' })
}

interface UserAndAdmin {
  user: UserQuery
  admin: UserQuery
}

async function pushUserEvent(props: {
  db: DB
  user: UserQuery
  admin: UserQuery
  model: UserEventUpload['model']
  statusDetails: UploadStatusDetails
}) {
  const { db, user, admin, model, statusDetails: details } = props
  const date = new Date().toISOString()
  const uid = user.uid
  const byUid = admin.uid
  await db.pushUserEvent({ id: '', type: 'upload', uid, byUid, date, model, details })
}

interface UploadStatusUpdate {
  db: DB
  user: UserQuery
  admin: UserQuery
  statusDetails: UploadStatusDetails
}
