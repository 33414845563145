import { createStyles, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import { t } from 'shared/i18n/current'
import { DriversLicense } from 'shared/models/DriversLicense'
import { Dig } from 'shared/utils/tsc'

interface DriversLicenseProps {
  adminView?: boolean
  initialValues?: DriversLicense
  onSubmit: (data: DriversLicense) => Promise<void>
  onBack: () => void
  onCancel: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function DriversLicenseForm(props: DriversLicenseProps) {
  const classes = useStyles()
  const { initialValues = loadInitialValues(), onBack, onSubmit } = props
  const fields = samFields().driversLicense

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema()}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={1} className={classes.gridParent}>
              <Grid item xs={12}>
                {fields.id.field()}
              </Grid>

              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <AccordionNavigationButtons
                isSubmitting={isSubmitting}
                onBack={props.adminView ? undefined : onBack}
                submitForm={submitForm}
                nextLabel={props.adminView ? t().buttons.save : undefined}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

function loadInitialValues(): DriversLicense {
  return Object.fromEntries(
    Object.entries(samFields().driversLicense).map(([k, v]) => [k, v.default])
  ) as unknown as DriversLicense
}

function schema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().driversLicense).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['driversLicense']>
  return Yup.object().defined().shape(validations)
}
