export const constCategories2022 = [
  {
    id: 'common-2022-motocross-u-20',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'U20',
    commonNameEn: 'U20',
    commonNameFr: 'U20',
    importName: 'MX U20',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'U20',
    shortId: 284,
    associations: ['sam', 'sjmcc'],
    associationSpecificDetails: [
      {
        nameDe: 'U20',
        nameEn: 'U20',
        nameFr: 'U20',
        shortNameDe: 'U20',
        shortNameEn: 'U20',
        shortNameFr: 'U20',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'U20',
        startListName: 'U20',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 250, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '80-150 ccm, 2 Takt, bis 20 Jahre (Jahrgang 2002)',
        hintEn: '80-150 ccm, 2 bars, up to 20 years (born 2002)',
        hintFr: "80-150 ccm, 2 bars, jusqu'à 20 ans (né en 2002)",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'SJMCC/SAM U20 Cup',
        nameEn: 'SJMCC/SAM U20 Cup',
        nameFr: 'SJMCC/SAM U20 Cup',
        shortNameDe: 'U20',
        shortNameEn: 'U20',
        shortNameFr: 'U20',
        needsHealthCheck: [{ association: 'sjmcc', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'U20',
        startListName: 'U20',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#ffffff',
        numberFixed: true,
        association: 'sjmcc',
        prices: [{ association: 'sjmcc', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-22T22:59:59.999Z',
        hintDe: '125-150ccm 2-Takt, Getriebe (analog U20 Zusazreglement)',
        hintEn: '125-150cc 2-stroke, gear (analogous U20 coverage)',
        hintFr: '125-150cc 2 temps, équipement (couverture analogue U20)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-imba-youth',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Youth',
    commonNameEn: 'IMBA Youth',
    commonNameFr: 'IMBA Youth',
    importName: 'MX IMBA Youth',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'IMBA Youth',
    shortId: 285,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Youth',
        nameEn: 'IMBA Youth',
        nameFr: 'IMBA Youth',
        shortNameDe: 'IMBA Youth',
        shortNameEn: 'IMBA Youth',
        shortNameFr: 'IMBA Youth',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Youth',
        startListName: 'IMBA Youth',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu U20 (Nachwuchs)',
        hintEn: 'In addition to the U20 (youngsters)',
        hintFr: 'En plus du U20 (jeunes)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-junioren-open-a',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Junioren Open A',
    commonNameEn: 'Junioren Open A',
    commonNameFr: 'Junioren Open A',
    importName: 'MX Junioren Open A',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Junioren Open A',
    shortId: 286,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Junioren Open A',
        nameEn: 'Junioren Open A',
        nameFr: 'Junioren Open A',
        shortNameDe: 'Junioren Open A',
        shortNameEn: 'Junioren Open A',
        shortNameFr: 'Junioren Open A',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Junioren Open A',
        startListName: 'Junioren Open A',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Die ersten 10 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
        hintEn: 'The first 10 of the 2020 season have to move into a higher category',
        hintFr: 'Les 10 premières de la saison 2020 doivent passer dans une catégorie supérieure',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-junioren-open-b',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Junioren Open B',
    commonNameEn: 'Junioren Open B',
    commonNameFr: 'Junioren Open B',
    importName: 'MX Junioren Open B',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Junioren Open B',
    shortId: 287,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Junioren Open B',
        nameEn: 'Junioren Open B',
        nameFr: 'Junioren Open B',
        shortNameDe: 'Junioren Open B',
        shortNameEn: 'Junioren Open B',
        shortNameFr: 'Junioren Open B',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Junioren Open B',
        startListName: 'Junioren Open B',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#cc0000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 250, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe:
          'Einsteigerkategorie, eigene Meisterschaft. \nDie ersten 10 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
        hintEn:
          'Introductory category, own championship.\nThe first 10 of the 2020 season have to move into a higher category',
        hintFr:
          "Catégorie d'introduction, propre championnat.\nLes 10 premières de la saison 2020 doivent passer dans une catégorie supérieure",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-national-open-a',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'National Open A',
    commonNameEn: 'National Open A',
    commonNameFr: 'National Open A',
    importName: 'MX National Open A',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'National Open A',
    shortId: 288,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'National Open A',
        nameEn: 'National Open A',
        nameFr: 'National Open A',
        shortNameDe: 'National Open A',
        shortNameEn: 'National Open A',
        shortNameFr: 'National Open A',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'National Open A',
        startListName: 'National Open A',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 400, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Die ersten 5 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
        hintEn: 'The first 5 of the 2020 season have to move into a higher category',
        hintFr: 'Les 5 premières de la saison 2020 doivent passer dans une catégorie supérieure',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-national-open-b',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'National Open B',
    commonNameEn: 'National Open B',
    commonNameFr: 'National Open B',
    importName: 'MX National Open B',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'National Open B',
    shortId: 289,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'National Open B',
        nameEn: 'National Open B',
        nameFr: 'National Open B',
        shortNameDe: 'National Open B',
        shortNameEn: 'National Open B',
        shortNameFr: 'National Open B',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'National Open B',
        startListName: 'National Open B',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#000000',
        colorForeground: '#ffffff',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 400, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Die ersten 5 der Saison 2020 müssen in eine höhere Kategorie aufsteigen',
        hintEn: 'The first 5 of the 2020 season have to move into a higher category',
        hintFr: 'Les 5 premières de la saison 2020 doivent passer dans une catégorie supérieure',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-senioren-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren Open',
    commonNameEn: 'Senioren Open',
    commonNameFr: 'Senioren Open',
    importName: 'MX Senioren Open',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Senioren Open',
    shortId: 290,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren Open',
        nameEn: 'Senioren Open',
        nameFr: 'Senioren Open',
        shortNameDe: 'Senioren Open',
        shortNameEn: 'Senioren Open',
        shortNameFr: 'Senioren Open',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren Open',
        startListName: 'Senioren Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 400, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Ab 35 Jahre (Jahrgang 1987)',
        hintEn: 'From 35 years (born 1987)',
        hintFr: 'À partir de 35 ans (né en 1987)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-senioren-open-u-50',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren Open Ü50',
    commonNameEn: 'Senioren Open Ü50',
    commonNameFr: 'Senioren Open Ü50',
    importName: 'MX Senioren Open Ü50',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Senioren Open Ü50',
    shortId: 291,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren Open Ü50',
        nameEn: 'Senioren Open Ü50',
        nameFr: 'Senioren Open Ü50',
        shortNameDe: 'Senioren Open Ü50',
        shortNameEn: 'Senioren Open Ü50',
        shortNameFr: 'Senioren Open Ü50',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren Open Ü50',
        startListName: 'Senioren Open Ü50',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#3300cc',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 400, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Ab 50 Jahre (Jahrgang 1972)',
        hintEn: 'From 50 years (born 1972)',
        hintFr: 'À partir de 50 ans (né en 1972)',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-masters-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Masters Open',
    commonNameEn: 'Masters Open',
    commonNameFr: 'Masters Open',
    importName: 'MX Masters Open',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Masters Open',
    shortId: 498,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Masters Open',
        nameEn: 'Masters Open',
        nameFr: 'Masters Open',
        shortNameDe: 'Masters Open',
        shortNameEn: 'Masters Open',
        shortNameFr: 'Masters Open',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Masters Open',
        startListName: 'Masters Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 400, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Nicht mit Inter-Lizenz eines anderen Verbandes',
        hintEn: 'Not with an inter-license of another association',
        hintFr: "Pas avec une intervention d'une autre association",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-pro-race-serie-einzellizenz',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'ProRace Serie Einzellizenz',
    commonNameEn: 'ProRace Serie Einzellizenz',
    commonNameFr: 'ProRace Serie Einzellizenz',
    importName: 'MX ProRace Serie Einzellizenz',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: 'ProRace Serie',
    shortId: 293,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'ProRace Serie Einzellizenz',
        nameEn: 'ProRace Serie Einzellizenz',
        nameFr: 'ProRace Serie Einzellizenz',
        shortNameDe: 'ProRace Serie',
        shortNameEn: 'ProRace Serie',
        shortNameFr: 'ProRace Serie',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ProRace Serie',
        startListName: 'ProRace Serie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 400, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-motocross-pro-race-serie-zusatz',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'ProRace Serie Zusatz',
    commonNameEn: 'ProRace Serie Zusatz',
    commonNameFr: 'ProRace Serie Zusatz',
    importName: 'MX ProRace Serie Zusatz',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: 'ProRace Serie',
    shortId: 499,
    associations: ['mxrs', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'ProRace Serie Zusatz',
        nameEn: 'ProRace Serie Zusatz',
        nameFr: 'ProRace Serie Zusatz',
        shortNameDe: 'ProRace Serie Zusatz',
        shortNameEn: 'ProRace Serie Zusatz',
        shortNameFr: 'ProRace Serie Zusatz',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ProRace Serie',
        startListName: 'ProRace Serie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 100, licenseType: 'national' }],
        priceHintDe: 'ohne erweiterte Versicherungsdeckung',
        priceHintEn: 'Without extended insurance coverage',
        priceHintFr: "Sans couverture d'assurance prolongée",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe:
          'Für Besitzer einer Lizenz FMS Inter, FMS Lites, FMS Junioren, SAM Masters, MXRS Super Race',
        hintEn:
          'For owners of a license FMS Inter, FMS Lites, FMS Juniors, Sam Masters, MXRS Super Race',
        hintFr:
          "Pour les propriétaires d'une licence FMS Inter, FMS Lites, FMS Juniors, Sam Masters, MXRS Super Race",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
      {
        nameDe: 'ProRace Serie Zusatz',
        nameEn: 'ProRace Serie Zusatz',
        nameFr: 'ProRace Serie Zusatz',
        shortNameDe: 'ProRace Serie Zusatz',
        shortNameEn: 'ProRace Serie Zusatz',
        shortNameFr: 'ProRace Serie Zusatz',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ProRace Serie',
        startListName: 'ProRace Serie',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 100, licenseType: 'national' }],
        priceHintDe: 'ohne erweiterte Versicherungsdeckung',
        priceHintEn: 'ohne erweiterte Versicherungsdeckung',
        priceHintFr: 'ohne erweiterte Versicherungsdeckung',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 40,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe:
          'Für Besitzer einer Lizenz FMS Inter, FMS Lites, FMS Junioren, SAM Masters, MXRS Super Race',
        hintEn:
          'Für Besitzer einer Lizenz FMS Inter, FMS Lites, FMS Junioren, SAM Masters, MXRS Super Race',
        hintFr:
          'Für Besitzer einer Lizenz FMS Inter, FMS Lites, FMS Junioren, SAM Masters, MXRS Super Race',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-imba-mx-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA MX2',
    commonNameEn: 'IMBA MX2',
    commonNameFr: 'IMBA MX2',
    importName: 'MX IMBA MX2',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'IMBA MX2',
    shortId: 295,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA MX2',
        nameEn: 'IMBA MX2',
        nameFr: 'IMBA MX2',
        shortNameDe: 'IMBA MX2',
        shortNameEn: 'IMBA MX2',
        shortNameFr: 'IMBA MX2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA MX2',
        startListName: 'IMBA MX2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Pro Race, Masters oder National',
        hintEn: 'In addition to Pro Race, Masters or National',
        hintFr: 'En plus de la race professionnelle, des maîtres ou du National',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-imba-open-class',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Open Class',
    commonNameEn: 'IMBA Open Class',
    commonNameFr: 'IMBA Open Class',
    importName: 'MX IMBA Open Class',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'IMBA Open',
    shortId: 296,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Open Class',
        nameEn: 'IMBA Open Class',
        nameFr: 'IMBA Open Class',
        shortNameDe: 'IMBA Open Class',
        shortNameEn: 'IMBA Open Class',
        shortNameFr: 'IMBA Open Class',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Open',
        startListName: 'IMBA Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Pro Race, Masters oder National',
        hintEn: 'In addition to Pro Race, Masters or National',
        hintFr: 'En plus de la race professionnelle, des maîtres ou du National',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-motocross-swiss-mx-women-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Swiss MX Women Cup',
    commonNameEn: 'Swiss MX Women Cup',
    commonNameFr: 'Swiss MX Women Cup',
    importName: 'MX Women',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Swiss MX Women Cup',
    shortId: 500,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Women Cup',
        nameEn: 'Swiss MX Women Cup',
        nameFr: 'Swiss MX Women Cup',
        shortNameDe: 'Swiss MX Women Cup',
        shortNameEn: 'Swiss MX Women Cup',
        shortNameFr: 'Swiss MX Women Cup',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Women Cup',
        startListName: 'Swiss MX Women Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Swiss MX Women Cup',
        nameEn: 'Swiss MX Women Cup',
        nameFr: 'Swiss MX Women Cup',
        shortNameDe: 'Swiss MX Women Cup',
        shortNameEn: 'Swiss MX Women Cup',
        shortNameFr: 'Swiss MX Women Cup',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Women Cup',
        startListName: 'Swiss MX Women Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, to: '2004-01-01', licenseType: 'national' },
          { association: 'fms', value: 350, from: '2003-12-31', licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-imba-ladies',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Ladies',
    commonNameEn: 'IMBA Ladies',
    commonNameFr: 'IMBA Ladies',
    importName: 'MX IMBA Ladies',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'IMBA Ladies',
    shortId: 298,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Ladies',
        nameEn: 'IMBA Ladies',
        nameFr: 'IMBA Ladies',
        shortNameDe: 'IMBA Ladies',
        shortNameEn: 'IMBA Ladies',
        shortNameFr: 'IMBA Ladies',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Ladies',
        startListName: 'IMBA Ladies',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu MX Women Cup',
        hintEn: 'In addition to MX Women Cup',
        hintFr: 'En plus de la MX Women Cup',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-motocross-sidecar-pilot',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Sidecar Pilot',
    commonNameEn: 'Sidecar Pilot',
    commonNameFr: 'Sidecar Pilot',
    importName: 'MX Sidecar Pilot',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Sidecar',
    shortId: 501,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Seitenwagen Fahrer',
        nameEn: 'Seitenwagen Fahrer',
        nameFr: 'Seitenwagen Fahrer',
        shortNameDe: 'Seitenwagen Fahrer',
        shortNameEn: 'Seitenwagen Fahrer',
        shortNameFr: 'Seitenwagen Fahrer',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Sidecar',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Sidecar Pilot',
        nameEn: 'Sidecar Pilot',
        nameFr: 'Sidecar Pilot',
        shortNameDe: 'Sidecar Pilot',
        shortNameEn: 'Sidecar Pilot',
        shortNameFr: 'Sidecar Pilot',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Sidecar',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2006 und älter',
        hintEn: 'Born in 2006 and older',
        hintFr: 'Année de naissance 2006 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-motocross-sidecar-passenger',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Sidecar Passenger',
    commonNameEn: 'Sidecar Passenger',
    commonNameFr: 'Sidecar Passenger',
    importName: 'MX Sidecar Passager',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Sidecar',
    shortId: 502,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Seitenwagen Passagier',
        nameEn: 'Seitenwagen Passagier',
        nameFr: 'Seitenwagen Passagier',
        shortNameDe: 'Seitenwagen Passagier',
        shortNameEn: 'Seitenwagen Passagier',
        shortNameFr: 'Seitenwagen Passagier',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Nur zur Kontrolle, NICHT importieren - Seitenwagen Passagier',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 250, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Sidecar Passager',
        nameEn: 'Sidecar Passager',
        nameFr: 'Sidecar Passager',
        shortNameDe: 'Sidecar Passenger',
        shortNameEn: 'Sidecar Passenger',
        shortNameFr: 'Sidecar Passenger',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Nur zur Kontrolle, NICHT importieren - Seitenwagen Passagier',
        startListName: 'Sidecar',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2006 und älter',
        hintEn: 'Born in 2006 and older',
        hintFr: 'Année de naissance 2006 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-imba-side-car',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'IMBA Side Car',
    commonNameEn: 'IMBA Side Car',
    commonNameFr: 'IMBA Side Car',
    importName: 'MX IMBA Side Car',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'IMBA Sidecar',
    shortId: 301,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'IMBA Side Car',
        nameEn: 'IMBA Side Car',
        nameFr: 'IMBA Side Car',
        shortNameDe: 'IMBA Side Car',
        shortNameEn: 'IMBA Side Car',
        shortNameFr: 'IMBA Side Car',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'IMBA Sidecar',
        startListName: 'IMBA Sidecar',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Zusätzlich zu Seitenwagen',
        hintEn: 'In addition to sidecar',
        hintFr: 'En plus de la side-car',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-e-klasse',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'E-Klasse (Elektro)',
    commonNameEn: 'E-Klasse (Elektro)',
    commonNameFr: 'E-Klasse (Elektro)',
    importName: 'MX E-Klasse',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'E-Klasse',
    shortId: 302,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'E-Klasse (Elektro)',
        nameEn: 'E-Klasse (Elektro)',
        nameFr: 'E-Klasse (Elektro)',
        shortNameDe: 'E-Klasse',
        shortNameEn: 'E-Klasse',
        shortNameFr: 'E-Klasse',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'E-Klasse',
        startListName: 'E-Klasse',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#000000',
        colorForeground: '#ffff00',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 250, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Meisterschaft ab 5 Lizenzen',
        hintEn: 'Championship from 5 licenses',
        hintFr: 'Championnat de 5 licences',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-motocross-quad',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Quad',
    commonNameEn: 'Quad',
    commonNameFr: 'Quad',
    importName: 'MX Quad',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Quad',
    shortId: 303,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Quad',
        nameEn: 'Quad',
        nameFr: 'Quad',
        shortNameDe: 'Quad',
        shortNameEn: 'Quad',
        shortNameFr: 'Quad',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Quad',
        startListName: 'Quad',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 350, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacross-m-0',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M0 (bis 50 ccm)',
    commonNameEn: 'Mofacross M0 (bis 50 ccm)',
    commonNameFr: 'Mofacross M0 (bis 50 ccm)',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M0',
    shortId: 304,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M0 (bis 50 ccm)',
        nameEn: 'Mofacross M0 (bis 50 ccm)',
        nameFr: 'Mofacross M0 (bis 50 ccm)',
        shortNameDe: 'Mofacross M0',
        shortNameEn: 'Mofacross M0',
        shortNameFr: 'Mofacross M0',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M0',
        startListName: 'Mofacross M0',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacross-m-1',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M1 (bis 75 ccm)',
    commonNameEn: 'Mofacross M1 (bis 75 ccm)',
    commonNameFr: 'Mofacross M1 (bis 75 ccm)',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M1',
    shortId: 305,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M1 (bis 75 ccm)',
        nameEn: 'Mofacross M1 (bis 75 ccm)',
        nameFr: 'Mofacross M1 (bis 75 ccm)',
        shortNameDe: 'Mofacross M1',
        shortNameEn: 'Mofacross M1',
        shortNameFr: 'Mofacross M1',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M1',
        startListName: 'Mofacross M1',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacross-m-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M2 (bis 80 ccm)',
    commonNameEn: 'Mofacross M2 (bis 80 ccm)',
    commonNameFr: 'Mofacross M2 (bis 80 ccm)',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M2',
    shortId: 306,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M2 (bis 80 ccm)',
        nameEn: 'Mofacross M2 (bis 80 ccm)',
        nameFr: 'Mofacross M2 (bis 80 ccm)',
        shortNameDe: 'Mofacross M2',
        shortNameEn: 'Mofacross M2',
        shortNameFr: 'Mofacross M2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M2',
        startListName: 'Mofacross M2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ff9900',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacross-m-3',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Mofacross M3 (bis 100 ccm)',
    commonNameEn: 'Mofacross M3 (bis 100 ccm)',
    commonNameFr: 'Mofacross M3 (bis 100 ccm)',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Mofacross M3',
    shortId: 529,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Mofacross M3 (bis 100 ccm)',
        nameEn: 'Mofacross M3 (bis 100 ccm)',
        nameFr: 'Mofacross M3 (bis 100 ccm)',
        shortNameDe: 'Mofacross M3',
        shortNameEn: 'Mofacross M3',
        shortNameFr: 'Mofacross M3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mofacross M3',
        startListName: 'Mofacross M3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ff9900',
        colorForeground: '#000000',
        colorLicense: '#449eda',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-pitbikecross-pitbike-light-125',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'pitbikecross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Light 125',
    commonNameEn: 'Pitbike Light 125',
    commonNameFr: 'Pitbike Light 125',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Pitbike Light 125',
    shortId: 307,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Light 125',
        nameEn: 'Pitbike Light 125',
        nameFr: 'Pitbike Light 125',
        shortNameDe: 'Pitbike Light 125',
        shortNameEn: 'Pitbike Light 125',
        shortNameFr: 'Pitbike Light 125',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Pitbike Light 125',
        startListName: 'Pitbike Light 125',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#801434',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-pitbikecross-pitbike-open-200',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'pitbikecross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Open 200',
    commonNameEn: 'Pitbike Open 200',
    commonNameFr: 'Pitbike Open 200',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Pitbike Open 200',
    shortId: 308,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Open 200',
        nameEn: 'Pitbike Open 200',
        nameFr: 'Pitbike Open 200',
        shortNameDe: 'Pitbike Open 200',
        shortNameEn: 'Pitbike Open 200',
        shortNameFr: 'Pitbike Open 200',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Pitbike Open 200',
        startListName: 'Pitbike Open 200',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#66ff00',
        colorForeground: '#000000',
        colorLicense: '#801434',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-02-28T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-02-28T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 40,
        priceDayLicenseForMemberTwoDays: 80,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-kid-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Kid 65',
    commonNameEn: 'Kid 65',
    commonNameFr: 'Kid 65',
    importName: 'SM Kid 65',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'KID 65',
    shortId: 309,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kid 65',
        nameEn: 'Kid 65',
        nameFr: 'Kid 65',
        shortNameDe: 'Kid 65',
        shortNameEn: 'Kid 65',
        shortNameFr: 'Kid 65',
        needsHealthCheck: [{ association: 'sam', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'KID 65',
        startListName: 'KID 65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 115, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und jünger',
        hintEn: 'Born in 2008 and younger',
        hintFr: 'Année de naissance 2008 et moins vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 20,
        priceDayLicenseForMemberTwoDays: 40,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 40,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Kid 65',
        nameEn: 'Kid 65',
        nameFr: 'Kid 65',
        shortNameDe: 'Kid 65',
        shortNameEn: 'Kid 65',
        shortNameFr: 'Kid 65',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'KID 65',
        startListName: 'KID 65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 90, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und jünger',
        hintEn: 'Born in 2008 and younger',
        hintFr: 'Année de naissance 2008 et moins vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 20,
        priceDayLicenseForMemberTwoDays: 20,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-youngster-85',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Youngster 85',
    commonNameEn: 'Youngster 85',
    commonNameFr: 'Youngster 85',
    importName: 'SM Youngster 85',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'YOUNGSTER 85',
    shortId: 310,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Youngster 85',
        nameEn: 'Youngster 85',
        nameFr: 'Youngster 85',
        shortNameDe: 'Youngster 85',
        shortNameEn: 'Youngster 85',
        shortNameFr: 'Youngster 85',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'YOUNGSTER 85',
        startListName: 'YOUNGSTER 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 210, licenseType: 'national' },
          { association: 'sam', value: 500, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 bis 2007',
        hintEn: 'Born between 2010 and 2007',
        hintFr: 'Né entre 2010 et 2007',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Youngster 85',
        nameEn: 'Youngster 85',
        nameFr: 'Youngster 85',
        shortNameDe: 'Youngster 85',
        shortNameEn: 'Youngster 85',
        shortNameFr: 'Youngster 85',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'YOUNGSTER 85',
        startListName: 'YOUNGSTER 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 110, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 bis 2007',
        hintEn: 'Born between 2010 and 2007',
        hintFr: 'Né entre 2010 et 2007',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-a-1-125-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'A1 125 Cup',
    commonNameEn: 'A1 125 Cup',
    commonNameFr: 'A1 125 Cup',
    importName: 'SM A1 125 Cup',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'A1 125 CUP',
    shortId: 311,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'A1 125 Cup',
        nameEn: 'A1 125 Cup',
        nameFr: 'A1 125 Cup',
        shortNameDe: 'A1 125 Cup',
        shortNameEn: 'A1 125 Cup',
        shortNameFr: 'A1 125 Cup',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'A1 125 CUP',
        startListName: 'A1 125 CUP',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 210, licenseType: 'national' },
          { association: 'sam', value: 500, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 bis 2004',
        hintEn: 'Born between 2010 and 2004',
        hintFr: 'Né entre 2010 et 2004',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 100,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'A1 125 Cup',
        nameEn: 'A1 125 Cup',
        nameFr: 'A1 125 Cup',
        shortNameDe: 'A1 125 Cup',
        shortNameEn: 'A1 125 Cup',
        shortNameFr: 'A1 125 Cup',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'A1 125 CUP',
        startListName: 'A1 125 CUP',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 110, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2010 bis 2004',
        hintEn: 'Born between 2010 and 2004',
        hintFr: 'Né entre 2010 et 2004',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 50,
        priceDayLicenseWithoutMemberTwoDays: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-women',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Women',
    commonNameEn: 'Women',
    commonNameFr: 'Women',
    importName: 'SM Women',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'WOMEN',
    shortId: 312,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Women',
        nameEn: 'Women',
        nameFr: 'Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'WOMEN',
        startListName: 'WOMEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#FF66FF',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 320, licenseType: 'national' },
          { association: 'sam', value: 650, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2007 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
        hintEn:
          'Born in 2007 and older. If there are not at least 8 licenses for the electro and the women, these categories start with the beginners and are evaluated separately.',
        hintFr:
          "Année de naissance 2007 et plus vieux. S'il n'y a pas d'au moins 8 licences pour l'électro et les femmes, ces catégories commencent avec les débutants et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Women',
        nameEn: 'Women',
        nameFr: 'Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'WOMEN',
        startListName: 'WOMEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#FF66FF',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-elektro',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Elektro',
    commonNameEn: 'Elektro',
    commonNameFr: 'Elektro',
    importName: 'SM Elektro',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'ELEKTRO',
    shortId: 313,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Elektro',
        nameEn: 'Elektro',
        nameFr: 'Elektro',
        shortNameDe: 'Elektro',
        shortNameEn: 'Elektro',
        shortNameFr: 'Elektro',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELEKTRO',
        startListName: 'ELEKTRO',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 320, licenseType: 'national' },
          { association: 'sam', value: 650, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2007 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
        hintEn:
          'Born in 2007 and older. If there are not at least 8 licenses for the electro and the women, these categories start with the beginners and are evaluated separately.',
        hintFr:
          "Année de naissance 2007 et plus vieux. S'il n'y a pas d'au moins 8 licences pour l'électro et les femmes, ces catégories commencent avec les débutants et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Elektro',
        nameEn: 'Elektro',
        nameFr: 'Elektro',
        shortNameDe: 'Elektro',
        shortNameEn: 'Elektro',
        shortNameFr: 'Elektro',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELEKTRO',
        startListName: 'ELEKTRO',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 220, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe:
          'Jahrgang 2007 und älter. Werden bei den Elektro und bei den Women nicht mindestens 8 Lizenzen verkauft, starten diese Kategorien mit den Einsteigern und werden separat gewertet.',
        hintEn:
          'Born in 2006 and older. If there are not at least 8 licenses for the electro and the women, these categories start with the beginners and are ranked separately.',
        hintFr:
          "Année de naissance 2006 et plus vieux. S'il n'y a pas d'au moins 8 licences pour l'électro et les femmes, ces catégories commencent avec les débutants et sont évaluées séparément.",
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-fun',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Fun S5',
    commonNameEn: 'Fun S5',
    commonNameFr: 'Fun S5',
    importName: 'SM Fun',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'FUN S5',
    shortId: 493,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Fun S5 (ex. Einsteiger)',
        nameEn: 'Fun S5 (ex. Einsteiger)',
        nameFr: 'Fun S5 (anct. Débutants)',
        shortNameDe: 'Fun S5',
        shortNameEn: 'Fun S5',
        shortNameFr: 'Fun S5 (anct. Débutants)',
        needsHealthCheck: [{ association: 'sam', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'FUN S5',
        startListName: 'FUN S5',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 320, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Fun S5 (ex. Einstieger)',
        nameEn: 'Fun S5 (ex. Einstieger)',
        nameFr: 'Fun S5 (anct. Débutants)',
        shortNameDe: 'Fun S5 (ex. Einstieger)',
        shortNameEn: 'Fun S5 (ex. Einstieger)',
        shortNameFr: 'Fun S5 (anct. Débutants)',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'FUN S5',
        startListName: 'FUN S5',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 220, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 100,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-senior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senior',
    commonNameEn: 'Senior',
    commonNameFr: 'Senior',
    importName: 'SM Senior',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'SENIOR',
    shortId: 314,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Senior',
        nameEn: 'Senior',
        nameFr: 'Senior',
        shortNameDe: 'Senior',
        shortNameEn: 'Senior',
        shortNameFr: 'Senior',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'SENIOR',
        startListName: 'SENIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1987 und älter',
        hintEn: 'Born in 1987 and older',
        hintFr: 'Année de naissance 1987 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Senior',
        nameEn: 'Senior',
        nameFr: 'Senior',
        shortNameDe: 'Senior',
        shortNameEn: 'Senior',
        shortNameFr: 'Senior',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'SENIOR',
        startListName: 'SENIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 1987 und älter',
        hintEn: 'Born in 1987 and older',
        hintFr: 'Année de naissance 1987 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-promo',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Promo S4',
    commonNameEn: 'Promo S4',
    commonNameFr: 'Promo S4',
    importName: 'SM Promo',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'PROMO S4',
    shortId: 371,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Promo S4',
        nameEn: 'Promo S4',
        nameFr: 'Promo S4',
        shortNameDe: 'Promo S4',
        shortNameEn: 'Promo S4',
        shortNameFr: 'Promo S4',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PROMO S4',
        startListName: 'PROMO S4',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Promo S4',
        nameEn: 'Promo S4',
        nameFr: 'Promo S4',
        shortNameDe: 'Promo S4',
        shortNameEn: 'Promo S4',
        shortNameFr: 'Promo S4',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PROMO S4',
        startListName: 'PROMO S4',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-challenge',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Challenge S2',
    commonNameEn: 'Challenge S2',
    commonNameFr: 'Challenge S2',
    importName: 'SM Challenge',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'CHALLENGE S2',
    shortId: 315,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Challenge S2',
        nameEn: 'Challenge S2',
        nameFr: 'Challenge S2',
        shortNameDe: 'Challenge S2',
        shortNameEn: 'Challenge S2',
        shortNameFr: 'Challenge S2',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'CHALLENGE S2',
        startListName: 'CHALLENGE S2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#000000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Challenge S2',
        nameEn: 'Challenge S2',
        nameFr: 'Challenge S2',
        shortNameDe: 'Challenge S2',
        shortNameEn: 'Challenge S2',
        shortNameFr: 'Challenge S2',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'CHALLENGE S2',
        startListName: 'CHALLENGE S2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#000000',
        colorForeground: '#ffffff',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-prestige',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Prestige S1',
    commonNameEn: 'Prestige S1',
    commonNameFr: 'Prestige S1',
    importName: 'SM Prestige',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'PRESTIGE S1',
    shortId: 316,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Prestige S1',
        nameEn: 'Prestige S1',
        nameFr: 'Prestige S1',
        shortNameDe: 'Prestige S1',
        shortNameEn: 'Prestige S1',
        shortNameFr: 'Prestige S1',
        needsHealthCheck: [{ association: 'sam', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PRESTIGE S1',
        startListName: 'PRESTIGE S1',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 650, licenseType: 'international' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Prestige S1',
        nameEn: 'Prestige S1',
        nameFr: 'Prestige S1',
        shortNameDe: 'Prestige S1',
        shortNameEn: 'Prestige S1',
        shortNameFr: 'Prestige S1',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PRESTIGE S1',
        startListName: 'PRESTIGE S1',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'common-2022-supermoto-quad',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Quad',
    commonNameEn: 'Quad',
    commonNameFr: 'Quad',
    importName: 'SM Quad',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'QUAD INTER',
    shortId: 317,
    associations: ['fms', 'sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Quad',
        nameEn: 'Quad',
        nameFr: 'Quad',
        shortNameDe: 'Quad',
        shortNameEn: 'Quad',
        shortNameFr: 'Quad',
        needsHealthCheck: [
          { association: 'sam', value: true, licenseType: 'national' },
          { association: 'sam', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'QUAD INTER',
        startListName: 'QUAD INTER',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 450, licenseType: 'national' },
          { association: 'sam', value: 550, licenseType: 'international' },
        ],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 100,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 140,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
      {
        nameDe: 'Quad',
        nameEn: 'Quad',
        nameFr: 'Quad',
        shortNameDe: 'Quad',
        shortNameEn: 'Quad',
        shortNameFr: 'Quad',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'QUAD INTER',
        startListName: 'QUAD INTER',
        myLapsSpecialExportFormatting: true,
        colorBackground: '#ffff00',
        colorForeground: '#000000',
        colorLicense: '#2b57a4',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 50,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-minibike-pitbike-160-ccm-series-light',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike 160 ccm Series Light',
    commonNameEn: 'Pitbike 160 ccm Series Light',
    commonNameFr: 'Pitbike 160 ccm Series Light',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE 160 LIGHT',
    shortId: 318,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike 160 ccm Series Light',
        nameEn: 'Pitbike 160 ccm Series Light',
        nameFr: 'Pitbike 160 ccm Series Light',
        shortNameDe: 'Pitbike 160 ccm Series Light',
        shortNameEn: 'Pitbike 160 ccm Series Light',
        shortNameFr: 'Pitbike 160 ccm Series Light',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE 160 LIGHT',
        startListName: 'PITBIKE 160 LIGHT',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 115, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-minibike-pitbike-160-ccm-series-heavy',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike 160 ccm Series Heavy',
    commonNameEn: 'Pitbike 160 ccm Series Heavy',
    commonNameFr: 'Pitbike 160 ccm Series Heavy',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE 160 HEAVY',
    shortId: 319,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike 160 ccm Series Heavy',
        nameEn: 'Pitbike 160 ccm Series Heavy',
        nameFr: 'Pitbike 160 ccm Series Heavy',
        shortNameDe: 'Pitbike 160 ccm Series Heavy',
        shortNameEn: 'Pitbike 160 ccm Series Heavy',
        shortNameFr: 'Pitbike 160 ccm Series Heavy',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE 160 HEAVY',
        startListName: 'PITBIKE 160 HEAVY',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 115, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-minibike-pitbike-190-ccm-series',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike 190 ccm Series',
    commonNameEn: 'Pitbike 190 ccm Series',
    commonNameFr: 'Pitbike 190 ccm Series',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE 190',
    shortId: 320,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike 190 ccm Series',
        nameEn: 'Pitbike 190 ccm Series',
        nameFr: 'Pitbike 190 ccm Series',
        shortNameDe: 'Pitbike 190 ccm Series',
        shortNameEn: 'Pitbike 190 ccm Series',
        shortNameFr: 'Pitbike 190 ccm Series',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE 190',
        startListName: 'PITBIKE 190',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 115, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-minibike-mini-gp-160-ccm-series',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'MiniGP 160 ccm Series',
    commonNameEn: 'MiniGP 160 ccm Series',
    commonNameFr: 'MiniGP 160 ccm Series',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'MINIGP 160',
    shortId: 321,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MiniGP 160 ccm Series',
        nameEn: 'MiniGP 160 ccm Series',
        nameFr: 'MiniGP 160 ccm Series',
        shortNameDe: 'MiniGP 160 ccm Series',
        shortNameEn: 'MiniGP 160 ccm Series',
        shortNameFr: 'MiniGP 160 ccm Series',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MINIGP 160',
        startListName: 'MINIGP 160',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 115, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-minibike-mini-gp-190-ccm-series',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'MiniGP 190 ccm Series',
    commonNameEn: 'MiniGP 190 ccm Series',
    commonNameFr: 'MiniGP 190 ccm Series',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'MINIGP 190',
    shortId: 322,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'MiniGP 190 ccm Series',
        nameEn: 'MiniGP 190 ccm Series',
        nameFr: 'MiniGP 190 ccm Series',
        shortNameDe: 'MiniGP 190 ccm Series',
        shortNameEn: 'MiniGP 190 ccm Series',
        shortNameFr: 'MiniGP 190 ccm Series',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MINIGP 190',
        startListName: 'MINIGP 190',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 115, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-minibike-pitbike-open-mini-gp-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'minibike',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Pitbike Open / MiniGP Open',
    commonNameEn: 'Pitbike Open / MiniGP Open',
    commonNameFr: 'Pitbike Open / MiniGP Open',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'PITBIKE / MINIGP OPEN',
    shortId: 323,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Pitbike Open / MiniGP Open',
        nameEn: 'Pitbike Open / MiniGP Open',
        nameFr: 'Pitbike Open / MiniGP Open',
        shortNameDe: 'Pitbike / MiniGP Open',
        shortNameEn: 'Pitbike / MiniGP Open',
        shortNameFr: 'Pitbike / MiniGP Open',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'PITBIKE / MINIGP OPEN',
        startListName: 'PITBIKE / MINIGP OPEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#e40716',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 115, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 140,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 30,
        priceInscriptionSurcharge: 20,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 30,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacup-rookies-e-50-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Rookies E-50 Cup',
    commonNameEn: 'Rookies E-50 Cup',
    commonNameFr: 'Rookies E-50 Cup',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Rookies E-50 Cup',
    shortId: 324,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Rookies E-50 Cup',
        nameEn: 'Rookies E-50 Cup',
        nameFr: 'Rookies E-50 Cup',
        shortNameDe: 'Rookies E-50 Cup',
        shortNameEn: 'Rookies E-50 Cup',
        shortNameFr: 'Rookies E-50 Cup',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Rookies E-50 Cup',
        startListName: 'Rookies E-50 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 110,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacup-superstock-70',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Superstock 70',
    commonNameEn: 'Superstock 70',
    commonNameFr: 'Superstock 70',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Superstock 70',
    shortId: 325,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Superstock 70',
        nameEn: 'Superstock 70',
        nameFr: 'Superstock 70',
        shortNameDe: 'Superstock 70',
        shortNameEn: 'Superstock 70',
        shortNameFr: 'Superstock 70',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Superstock 70',
        startListName: 'Superstock 70',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 130,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacup-superstock-80',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Superstock 80',
    commonNameEn: 'Superstock 80',
    commonNameFr: 'Superstock 80',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Superstock 80',
    shortId: 326,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Superstock 80',
        nameEn: 'Superstock 80',
        nameFr: 'Superstock 80',
        shortNameDe: 'Superstock 80',
        shortNameEn: 'Superstock 80',
        shortNameFr: 'Superstock 80',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Superstock 80',
        startListName: 'Superstock 80',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 130,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacup-open-100',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Open 100',
    commonNameEn: 'Open 100',
    commonNameFr: 'Open 100',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Open 100',
    shortId: 327,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Open 100',
        nameEn: 'Open 100',
        nameFr: 'Open 100',
        shortNameDe: 'Open 100',
        shortNameEn: 'Open 100',
        shortNameFr: 'Open 100',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Open 100',
        startListName: 'Open 100',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 130,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-mofacup-puch-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'mofacup',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Puch Cup',
    commonNameEn: 'Puch Cup',
    commonNameFr: 'Puch Cup',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Puch Cup',
    shortId: 328,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Puch Cup',
        nameEn: 'Puch Cup',
        nameFr: 'Puch Cup',
        shortNameDe: 'Puch Cup',
        shortNameEn: 'Puch Cup',
        shortNameFr: 'Puch Cup',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Puch Cup',
        startListName: 'Puch Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#963d8f',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 105, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 130,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-3',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    commonNameEn: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    commonNameFr: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 3',
    shortId: 329,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        nameEn: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        nameFr: 'Klasse 3, Spezialisten, Jg. alle, weisse Spur',
        shortNameDe: 'Klasse 3',
        shortNameEn: 'Klasse 3',
        shortNameFr: 'Klasse 3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 3',
        startListName: 'Klasse 3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#ffffff',
        colorForeground: '#000000',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 60,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 20,
        priceDayLicenseWithOtherLicenseTwoDays: 20,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-4',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur',
    commonNameEn: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur',
    commonNameFr: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 4',
    shortId: 330,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur',
        nameEn: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur',
        nameFr: 'Klasse 4, Fortgeschrittene, Jg. 1991 und jünger, blaue Spur',
        shortNameDe: 'Klasse 4',
        shortNameEn: 'Klasse 4',
        shortNameFr: 'Klasse 4',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 4',
        startListName: 'Klasse 4',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-5',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur',
    commonNameEn: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur',
    commonNameFr: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 5',
    shortId: 331,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur',
        nameEn: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur',
        nameFr: 'Klasse 5, Einsteiger, Jg. 1976 und jünger, rote Spur',
        shortNameDe: 'Klasse 5',
        shortNameEn: 'Klasse 5',
        shortNameFr: 'Klasse 5',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 5',
        startListName: 'Klasse 5',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-6',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur',
    commonNameEn: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur',
    commonNameFr: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 6',
    shortId: 332,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur',
        nameEn: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur',
        nameFr: 'Klasse 6, Nachwuchs, Jg. 2003 und jünger, grüne Spur',
        shortNameDe: 'Klasse 6',
        shortNameEn: 'Klasse 6',
        shortNameFr: 'Klasse 6',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 6',
        startListName: 'Klasse 6',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-7',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur',
    commonNameEn: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur',
    commonNameFr: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 7',
    shortId: 333,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur',
        nameEn: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur',
        nameFr: 'Klasse 7, Senioren, Jg. 1990 und älter, blaue Spur',
        shortNameDe: 'Klasse 7',
        shortNameEn: 'Klasse 7',
        shortNameFr: 'Klasse 7',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 7',
        startListName: 'Klasse 7',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#3300cc',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-8',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur',
    commonNameEn: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur',
    commonNameFr: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 8',
    shortId: 334,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur',
        nameEn: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur',
        nameFr: 'Klasse 8, Veteranen, Jg. 1975 und älter, rote Spur',
        shortNameDe: 'Klasse 8',
        shortNameEn: 'Klasse 8',
        shortNameFr: 'Klasse 8',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 8',
        startListName: 'Klasse 8',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-8-t',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    commonNameEn: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    commonNameFr: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 8T',
    shortId: 335,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        nameEn: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        nameFr: 'Klasse 8T, Twinshock Expert, Jg. alle, rote Spur',
        shortNameDe: 'Klasse 8T',
        shortNameEn: 'Klasse 8T',
        shortNameFr: 'Klasse 8T',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 8T',
        startListName: 'Klasse 8T',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-9',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur',
    commonNameEn: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur',
    commonNameFr: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 9',
    shortId: 336,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur',
        nameEn: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur',
        nameFr: 'Klasse 9, Hobby, Jg. 2002 und älter, grüne Spur',
        shortNameDe: 'Klasse 9',
        shortNameEn: 'Klasse 9',
        shortNameFr: 'Klasse 9',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 9',
        startListName: 'Klasse 9',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-klasse-9-t',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    commonNameEn: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    commonNameFr: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Klasse 9T',
    shortId: 337,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        nameEn: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        nameFr: 'Klasse 9T, Twinshock Clubman, Jg. alle, grüne Spur',
        shortNameDe: 'Klasse 9T',
        shortNameEn: 'Klasse 9T',
        shortNameFr: 'Klasse 9T',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Klasse 9T',
        startListName: 'Klasse 9T',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#009900',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-elektro-trial',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    commonNameEn: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    commonNameFr: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Elektro Trial',
    shortId: 338,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        nameEn: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        nameFr: 'Elektro Trial, Elektro Trial, Jg. alle, rote Spur',
        shortNameDe: 'Elektro Trial',
        shortNameEn: 'Elektro Trial',
        shortNameFr: 'Elektro Trial',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Elektro Trial',
        startListName: 'Elektro Trial',
        myLapsSpecialExportFormatting: false,
        colorBackground: '#cc0000',
        colorForeground: '#ffffff',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [
          { association: 'sam', value: 50, to: '2004-01-01', licenseType: 'national' },
          { association: 'sam', value: 80, from: '2003-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-kids-klasse-1',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht',
    commonNameEn: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht',
    commonNameFr: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 1',
    shortId: 339,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht',
        nameEn: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht',
        nameFr: 'Kids Klasse 1, E Kids Cup 12.5", Jg. 2014 und jünger, weisse Spur leicht',
        shortNameDe: 'Kids Klasse 1',
        shortNameEn: 'Kids Klasse 1',
        shortNameFr: 'Kids Klasse 1',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 1',
        startListName: 'Kids Klasse 1',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-kids-klasse-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht',
    commonNameEn: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht',
    commonNameFr: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 2',
    shortId: 340,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht',
        nameEn: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht',
        nameFr: 'Kids Klasse 2, E Kids Cup 12.5", Jg. 2012 und 2013, weisse Spur leicht',
        shortNameDe: 'Kids Klasse 2',
        shortNameEn: 'Kids Klasse 2',
        shortNameFr: 'Kids Klasse 2',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 2',
        startListName: 'Kids Klasse 2',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-kids-klasse-3',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel',
    commonNameEn: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel',
    commonNameFr: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 3',
    shortId: 341,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel',
        nameEn: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel',
        nameFr: 'Kids Klasse 3, E Kids Cup 16" und 20", Jg. 2011 und jünger, grüne Spur mittel',
        shortNameDe: 'Kids Klasse 3',
        shortNameEn: 'Kids Klasse 3',
        shortNameFr: 'Kids Klasse 3',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 3',
        startListName: 'Kids Klasse 3',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-kids-klasse-4',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel',
    commonNameEn: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel',
    commonNameFr: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 4',
    shortId: 342,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel',
        nameEn: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel',
        nameFr: 'Kids Klasse 4, E Kids Cup 16" und 20", Jg. 2005 bis 2010, grüne Spur mittel',
        shortNameDe: 'Kids Klasse 4',
        shortNameEn: 'Kids Klasse 4',
        shortNameFr: 'Kids Klasse 4',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 4',
        startListName: 'Kids Klasse 4',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-kids-klasse-5',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer',
    commonNameEn: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer',
    commonNameFr: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 5',
    shortId: 343,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer',
        nameEn: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer',
        nameFr: 'Kids Klasse 5, E Kids Cup 20", Jg. 2005 und jünger, gelbe Spur schwer',
        shortNameDe: 'Kids Klasse 5',
        shortNameEn: 'Kids Klasse 5',
        shortNameFr: 'Kids Klasse 5',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 5',
        startListName: 'Kids Klasse 5',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-trial-kids-klasse-6',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer',
    commonNameEn: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer',
    commonNameFr: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Kids Klasse 6',
    shortId: 344,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer',
        nameEn: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer',
        nameFr: 'Kids Klasse 6, E Kids Cup 24", Jg. 2005 und jünger, gelbe Spur schwer',
        shortNameDe: 'Kids Klasse 6',
        shortNameEn: 'Kids Klasse 6',
        shortNameFr: 'Kids Klasse 6',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids Klasse 6',
        startListName: 'Kids Klasse 6',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#56a933',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 40, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 20,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 40,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 10,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sam-2022-other-trainingslizenz-funlizenz',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'other',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Trainingslizenz / Funlizenz',
    commonNameEn: 'Trainingslizenz / Funlizenz',
    commonNameFr: 'Trainingslizenz / Funlizenz',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName:
      'Hier kommt die Kategorie für ein Hobbyrennen / Tageslizenz rein - Trainingslizenz / Funlizenz',
    shortId: 345,
    associations: ['sam'],
    associationSpecificDetails: [
      {
        nameDe: 'Trainingslizenz / Funlizenz',
        nameEn: 'Trainingslizenz / Funlizenz',
        nameFr: 'Trainingslizenz / Funlizenz',
        shortNameDe: 'Fun',
        shortNameEn: 'Fun',
        shortNameFr: 'Fun',
        needsHealthCheck: [{ association: 'sam', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName:
          'Hier kommt die Kategorie für ein Hobbyrennen / Tageslizenz rein - Trainingslizenz / Funlizenz',
        startListName:
          'Hier kommt die Kategorie für ein Hobbyrennen / Tageslizenz rein - Trainingslizenz / Funlizenz',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#afa88b',
        numberFixed: true,
        association: 'sam',
        prices: [{ association: 'sam', value: 200, licenseType: 'national' }],
        priceHintDe: 'Inklusive erweiterter Versicherungsdeckung',
        priceHintEn: 'Including extended insurance coverage',
        priceHintFr: "Y compris une couverture d'assurance étendue",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: false,
        priceAdditionalLicense: 80,
        priceInsuranceBookkeeping: 150,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-swiss-mx-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss MX Open',
    commonNameEn: 'Swiss MX Open',
    commonNameFr: 'Swiss MX Open',
    importName: 'MX Swiss MX Open',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Swiss MX Open',
    shortId: 346,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Open',
        nameEn: 'Swiss MX Open',
        nameFr: 'Swiss MX Open',
        shortNameDe: 'Swiss MX Open',
        shortNameEn: 'Swiss MX Open',
        shortNameFr: 'Swiss MX Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Open',
        startListName: 'Swiss MX Open',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2006 und älter',
        hintEn: 'Born in 2006 and older',
        hintFr: 'Année de naissance 2006 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-swiss-mx-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss MX2',
    commonNameEn: 'Swiss MX2',
    commonNameFr: 'Swiss MX2',
    importName: 'MX Swiss MX2',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Swiss MX2',
    shortId: 347,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX2',
        nameEn: 'Swiss MX2',
        nameFr: 'Swiss MX2',
        shortNameDe: 'Swiss MX2',
        shortNameEn: 'Swiss MX2',
        shortNameFr: 'Swiss MX2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX2',
        startListName: 'Swiss MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-inter-erweiterung',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Inter Erweiterung',
    commonNameEn: 'Extension Inter',
    commonNameFr: 'Inter Extension',
    importName: 'MX Erweiterung / extension Inter',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 348,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Erweiterung',
        nameEn: 'Inter Extension',
        nameFr: 'Extension Inter',
        shortNameDe: 'Erweiterung Inter',
        shortNameEn: 'Inter Extension',
        shortNameFr: 'Extension Inter',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'national' }],
        priceHintDe: 'Zusätzlich zu Lites 250, Junior 125 2T, oder Women Cup',
        priceHintEn: 'In addition to Lites 250, Junior 125 2T, or Women Cup',
        priceHintFr: 'En plus des lites 250, Junior 125 2T ou Women Cup',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-inter-erweiterung-swiss-mx-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Inter Erweiterung Swiss MX Open',
    commonNameEn: 'Extension Inter Swiss MX Open',
    commonNameFr: 'Inter Extension Swiss MX Open',
    importName: 'MX Erweiterung / extension Inter Swiss MX Open',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX Open',
    shortId: 534,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Erweiterung Swiss MX Open',
        nameEn: 'Inter Extension Swiss MX Open',
        nameFr: 'Extension Inter Swiss MX Open',
        shortNameDe: 'Erweiterung Inter Swiss MX Open',
        shortNameEn: 'Inter Extension Swiss MX Open',
        shortNameFr: 'Extension Inter Swiss MX Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Open',
        startListName: 'Swiss MX Open',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'national' }],
        priceHintDe: 'Zusätzlich zu Lites 250, Junior 125 2T, oder Women Cup',
        priceHintEn: 'In addition to Lites 250, Junior 125 2T, or Women Cup',
        priceHintFr: 'En plus des lites 250, Junior 125 2T ou Women Cup',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2006 und älter',
        hintEn: 'Born in 2006 and older',
        hintFr: 'Année de naissance 2006 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-inter-erweiterung-swiss-mx-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Inter Erweiterung Swiss MX2',
    commonNameEn: 'Extension Inter Swiss MX2',
    commonNameFr: 'Inter Extension Swiss MX2',
    importName: 'MX Erweiterung / extension Inter Swiss MX2',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX2',
    shortId: 535,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Erweiterung Swiss MX2',
        nameEn: 'Inter Extension Swiss MX2',
        nameFr: 'Extension Inter Swiss MX2',
        shortNameDe: 'Erweiterung Inter Swiss MX2',
        shortNameEn: 'Inter Extension Swiss MX2',
        shortNameFr: 'Extension Inter Swiss MX2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX2',
        startListName: 'Swiss MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'national' }],
        priceHintDe: 'Zusätzlich zu Lites 250, Junior 125 2T, oder Women Cup',
        priceHintEn: 'In addition to Lites 250, Junior 125 2T, or Women Cup',
        priceHintFr: 'En plus des lites 250, Junior 125 2T ou Women Cup',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-swiss-mx-open-auslandische-lizenz-licence-etrangere',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss MX Open ausländische Lizenz',
    commonNameEn: 'Swiss MX Open licence étrangère',
    commonNameFr: 'Swiss MX Open foreign license',
    importName: 'Swiss MX Open ausländische Lizenz / licence étrangère',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX Open',
    shortId: 537,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Open ausländische Lizenz',
        nameEn: 'Swiss MX Open foreign license',
        nameFr: 'Swiss MX Open licence étrangère',
        shortNameDe: 'Swiss MX Open ausländische Lizenz',
        shortNameEn: 'Swiss MX Open foreign license',
        shortNameFr: 'Swiss MX Open licence étrangère',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Open',
        startListName: 'Swiss MX Open',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2006 und älter',
        hintEn: 'Born in 2006 and older',
        hintFr: 'Année de naissance 2006 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-swiss-mx-2-auslandische-lizenz-licence-etrangere',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Swiss MX2 ausländische Lizenz',
    commonNameEn: 'Swiss MX2 licence étrangère',
    commonNameFr: 'Swiss MX2 foreign license',
    importName: 'Swiss MX2 ausländische Lizenz / licence étrangère',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX2',
    shortId: 538,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX2 ausländische Lizenz',
        nameEn: 'Swiss MX2 foreign license',
        nameFr: 'Swiss MX2 licence étrangère',
        shortNameDe: 'Swiss MX2 ausländische Lizenz',
        shortNameEn: 'Swiss MX2 foreign license',
        shortNameFr: 'Swiss MX2 licence étrangère',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX2',
        startListName: 'Swiss MX2',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2007 und älter',
        hintEn: 'Born in 2007 and older',
        hintFr: 'Année de naissance 2007 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-lites-250',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Lites 250',
    commonNameEn: 'Lites 250',
    commonNameFr: 'Lites 250',
    importName: 'MX Lites 250',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Lites 250',
    shortId: 349,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Lites 250',
        nameEn: 'Lites 250',
        nameFr: 'Lites 250',
        shortNameDe: 'Lites 250',
        shortNameEn: 'Lites 250',
        shortNameFr: 'Lites 250',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Lites 250',
        startListName: 'Lites 250',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, to: '2004-01-01', licenseType: 'national' },
          { association: 'fms', value: 400, from: '2003-12-31', licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and younger',
        hintFr: 'Année de naissance 2008 et moins vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-junior-125-2-t',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Junior 125 2T',
    commonNameEn: 'Junior 125 2T',
    commonNameFr: 'Junior 125 2T',
    importName: 'MX Junior 125 2T',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Junior 125 2T',
    shortId: 350,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Junior 125 2T',
        nameEn: 'Junior 125 2T',
        nameFr: 'Junior 125 2T',
        shortNameDe: 'Junior 125 2T',
        shortNameEn: 'Junior 125 2T',
        shortNameFr: 'Junior 125 2T',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Junior 125 2T',
        startListName: 'Junior 125 2T',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 bis 1997',
        hintEn: 'Born between 2009 and 1997',
        hintFr: 'Né entre 2009 et 1997',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-mini-85',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Mini 85',
    commonNameEn: 'Mini 85',
    commonNameFr: 'Mini 85',
    importName: 'MX Mini 85',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Mini 85',
    shortId: 351,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Mini 85',
        nameEn: 'Mini 85',
        nameFr: 'Mini 85',
        shortNameDe: 'Mini 85',
        shortNameEn: 'Mini 85',
        shortNameFr: 'Mini 85',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mini 85',
        startListName: 'Mini 85',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2011 bis 2007',
        hintEn: 'Born between 2011 and 2007',
        hintFr: 'Né entre 2011 et 2007',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 30,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 40,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-scott-kids-cup-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Scott Kids Cup 65',
    commonNameEn: 'Scott Kids Cup 65',
    commonNameFr: 'Scott Kids Cup 65',
    importName: 'MX Scott Kids Cup 65',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Scott Kids Cup 65',
    shortId: 352,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Scott Kids Cup 65',
        nameEn: 'Scott Kids Cup 65',
        nameFr: 'Scott Kids Cup 65',
        shortNameDe: 'Scott Kids Cup 65',
        shortNameEn: 'Scott Kids Cup 65',
        shortNameFr: 'Scott Kids Cup 65',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Scott Kids Cup 65',
        startListName: 'Scott Kids Cup 65',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 50, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2014 bis 2010',
        hintEn: 'Born between 2014 and 2010',
        hintFr: 'Né entre 2014 et 2010',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 30,
        priceDayLicenseForMemberTwoDays: 30,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 40,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-yamaha-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF'],
    numberChoice: true,
    commonNameDe: 'Yamaha Cup',
    commonNameEn: 'Yamaha Cup',
    commonNameFr: 'Yamaha Cup',
    importName: 'MX Yamaha Cup',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Yamaha Cup',
    shortId: 353,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Yamaha Cup',
        nameEn: 'Yamaha Cup',
        nameFr: 'Yamaha Cup',
        shortNameDe: 'Yamaha Cup',
        shortNameEn: 'Yamaha Cup',
        shortNameFr: 'Yamaha Cup',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Yamaha Cup',
        startListName: 'Yamaha Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 150, to: '2004-01-01', licenseType: 'national' },
          { association: 'fms', value: 400, from: '2003-12-31', licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2008 und älter',
        hintEn: 'Born in 2008 and older',
        hintFr: 'Année de naissance 2008 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-swiss-mx-women-cup-angora-afm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['RF', 'MyLaps'],
    numberChoice: true,
    commonNameDe: 'Swiss MX Women Cup Angora/AFM',
    commonNameEn: 'Swiss MX Women Cup Angora/AFM',
    commonNameFr: 'Swiss MX Women Cup Angora/AFM',
    importName: '',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: 'Swiss MX Women Cup',
    shortId: 536,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss MX Women Cup Angora/AFM',
        nameEn: 'Swiss MX Women Cup Angora/AFM',
        nameFr: 'Swiss MX Women Cup Angora/AFM',
        shortNameDe: 'Swiss MX Women Cup Angora/AFM',
        shortNameEn: 'Swiss MX Women Cup Angora/AFM',
        shortNameFr: 'Swiss MX Women Cup Angora/AFM',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Swiss MX Women Cup',
        startListName: 'Swiss MX Women Cup',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f5b912',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 30,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Jahrgang 2009 und älter',
        hintEn: 'Born in 2009 and older',
        hintFr: 'Année de naissance 2009 et plus vieux',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 70,
        priceDayLicenseForMemberTwoDays: 70,
        priceDayLicenseWithoutMember: 90,
        priceDayLicenseWithoutMemberTwoDays: 90,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-mxgp-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM MXGP WM',
    commonNameEn: 'FIM MXGP WM',
    commonNameFr: 'FIM MXGP WM',
    importName: 'MX FIM MXGP WM',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 357,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MXGP WM',
        nameEn: 'FIM MXGP WM',
        nameFr: 'FIM MXGP WM',
        shortNameDe: 'FIM MXGP WM',
        shortNameEn: 'FIM MXGP WM',
        shortNameFr: 'FIM MXGP WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-mx-2-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM MX2 WM',
    commonNameEn: 'FIM MX2 WM',
    commonNameFr: 'FIM MX2 WM',
    importName: 'MX FIM MX2 WM',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 358,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX2 WM',
        nameEn: 'FIM MX2 WM',
        nameFr: 'FIM MX2 WM',
        shortNameDe: 'FIM MX2 WM',
        shortNameEn: 'FIM MX2 WM',
        shortNameFr: 'FIM MX2 WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-sidecar-pilot-wm',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Sidecar Pilot WM',
    commonNameEn: 'FIM Sidecar Pilot WM',
    commonNameFr: 'FIM Sidecar Pilot WM',
    importName: 'MX FIM Sidecar Pilot WM',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 359,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Pilot WM',
        nameEn: 'FIM Sidecar Pilot WM',
        nameFr: 'FIM Sidecar Pilot WM',
        shortNameDe: 'FIM Sidecar Pilot WM',
        shortNameEn: 'FIM Sidecar Pilot WM',
        shortNameFr: 'FIM Sidecar Pilot WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 850, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 500,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-sidecar-passenger-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Sidecar Passager WM',
    commonNameEn: 'FIM Sidecar Passager WM',
    commonNameFr: 'FIM Sidecar Passager WM',
    importName: 'MX FIM Sidecar Passenger WM',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 503,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Passager WM',
        nameEn: 'FIM Sidecar Passager WM',
        nameFr: 'FIM Sidecar Passager WM',
        shortNameDe: 'FIM Sidecar Passenger WM',
        shortNameEn: 'FIM Sidecar Passenger WM',
        shortNameFr: 'FIM Sidecar Passenger WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-wmx',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM WMX',
    commonNameEn: 'FIM WMX',
    commonNameFr: 'FIM WMX',
    importName: 'MX FIM WMX',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 361,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM WMX',
        nameEn: 'FIM WMX',
        nameFr: 'FIM WMX',
        shortNameDe: 'FIM WMX',
        shortNameEn: 'FIM WMX',
        shortNameFr: 'FIM WMX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 750, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 250,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fime-emx-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX65',
    commonNameEn: 'FIME EMX65',
    commonNameFr: 'FIME EMX65',
    importName: 'MX FIME EMX65',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 362,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX65',
        nameEn: 'FIME EMX65',
        nameFr: 'FIME EMX65',
        shortNameDe: 'FIME EMX65',
        shortNameEn: 'FIME EMX65',
        shortNameFr: 'FIME EMX65',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fime-emx-85',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX85',
    commonNameEn: 'FIME EMX85',
    commonNameFr: 'FIME EMX85',
    importName: 'MX FIME EMX85',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 363,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX85',
        nameEn: 'FIME EMX85',
        nameFr: 'FIME EMX85',
        shortNameDe: 'FIME EMX85',
        shortNameEn: 'FIME EMX85',
        shortNameFr: 'FIME EMX85',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fime-emx-125',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX125',
    commonNameEn: 'FIME EMX125',
    commonNameFr: 'FIME EMX125',
    importName: 'MX FIME EMX125',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 364,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX125',
        nameEn: 'FIME EMX125',
        nameFr: 'FIME EMX125',
        shortNameDe: 'FIME EMX125',
        shortNameEn: 'FIME EMX125',
        shortNameFr: 'FIME EMX125',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fime-emx-250',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX250',
    commonNameEn: 'FIME EMX250',
    commonNameFr: 'FIME EMX250',
    importName: 'MX FIME EMX250',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 365,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX250',
        nameEn: 'FIME EMX250',
        nameFr: 'FIME EMX250',
        shortNameDe: 'FIME EMX250',
        shortNameEn: 'FIME EMX250',
        shortNameFr: 'FIME EMX250',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-emx-2-t',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX2T',
    commonNameEn: 'FIME EMX2T',
    commonNameFr: 'FIME EMX2T',
    importName: 'MX FIME EMX2T',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 366,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX2T',
        nameEn: 'FIME EMX2T',
        nameFr: 'FIME EMX2T',
        shortNameDe: 'FIME EMX2T',
        shortNameEn: 'FIME EMX2T',
        shortNameFr: 'FIME EMX2T',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fime-emx-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME EMX Open',
    commonNameEn: 'FIME EMX Open',
    commonNameFr: 'FIME EMX Open',
    importName: 'MX FIME EMX Open',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 530,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME EMX Open',
        nameEn: 'FIME EMX Open',
        nameFr: 'FIME EMX Open',
        shortNameDe: 'FIME EMX Open',
        shortNameEn: 'FIME EMX Open',
        shortNameFr: 'FIME EMX Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fime-wemx',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME WEMX',
    commonNameEn: 'FIME WEMX',
    commonNameFr: 'FIME WEMX',
    importName: 'MX FIME WEMX',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 367,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME WEMX',
        nameEn: 'FIME WEMX',
        nameFr: 'FIME WEMX',
        shortNameDe: 'FIME WEMX',
        shortNameEn: 'FIME WEMX',
        shortNameFr: 'FIME WEMX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 250, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 50,
        priceDayLicenseForMemberTwoDays: 50,
        priceDayLicenseWithoutMember: 70,
        priceDayLicenseWithoutMemberTwoDays: 70,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-supermoto-fim-supermoto-s-1-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM Supermoto S1 WM',
    commonNameEn: 'FIM Supermoto S1 WM',
    commonNameFr: 'FIM Supermoto S1 WM',
    importName: 'SM FIM Supermoto S1 WM',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 504,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supermoto S1 WM',
        nameEn: 'FIM Supermoto S1 WM',
        nameFr: 'FIM Supermoto S1 WM',
        shortNameDe: 'FIM Supermoto S1 WM',
        shortNameEn: 'FIM Supermoto S1 WM',
        shortNameFr: 'FIM Supermoto S1 WM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 600,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-supermoto-fime-supermoto-s-2-em',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME Supermoto S2 EM',
    commonNameEn: 'FIME Supermoto S2 EM',
    commonNameFr: 'FIME Supermoto S2 EM',
    importName: 'SM FIME Supermoto S2 EM',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 505,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Supermoto S2 EM',
        nameEn: 'FIME Supermoto S2 EM',
        nameFr: 'FIME Supermoto S2 EM',
        shortNameDe: 'FIME Supermoto S2 EM',
        shortNameEn: 'FIME Supermoto S2 EM',
        shortNameFr: 'FIME Supermoto S2 EM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-elite',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Elite',
    commonNameEn: 'Elite',
    commonNameFr: 'Elite',
    importName: 'Trial Elite',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'ELITE',
    shortId: 375,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Elite',
        nameEn: 'Elite',
        nameFr: 'Elite',
        shortNameDe: 'Elite',
        shortNameEn: 'Elite',
        shortNameFr: 'Elite',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ELITE',
        startListName: 'ELITE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-expert',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Expert',
    commonNameEn: 'Expert',
    commonNameFr: 'Expert',
    importName: 'Trial Expert',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'EXPERT',
    shortId: 376,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Expert',
        nameEn: 'Expert',
        nameFr: 'Expert',
        shortNameDe: 'Expert',
        shortNameEn: 'Expert',
        shortNameFr: 'Expert',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'EXPERT',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 350, licenseType: 'national' },
          { association: 'fms', value: 500, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Open',
    commonNameEn: 'Open',
    commonNameFr: 'Open',
    importName: 'Trial Open',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'OPEN',
    shortId: 377,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Open',
        nameEn: 'Open',
        nameFr: 'Open',
        shortNameDe: 'Open',
        shortNameEn: 'Open',
        shortNameFr: 'Open',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'OPEN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 100, to: '2004-01-01', licenseType: 'national' },
          {
            association: 'fms',
            value: 200,
            from: '2003-12-31',
            to: '2001-01-01',
            licenseType: 'national',
          },
          { association: 'fms', value: 350, from: '2000-12-31', licenseType: 'national' },
          { association: 'fms', value: 350, to: '2001-01-01', licenseType: 'international' },
          { association: 'fms', value: 500, from: '2000-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-challenge',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Challenge',
    commonNameEn: 'Challenge',
    commonNameFr: 'Challenge',
    importName: 'Trial Challenge',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'CHALLENGE',
    shortId: 378,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Challenge',
        nameEn: 'Challenge',
        nameFr: 'Challenge',
        shortNameDe: 'Challenge',
        shortNameEn: 'Challenge',
        shortNameFr: 'Challenge',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'CHALLENGE',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 100, to: '2004-01-01', licenseType: 'national' },
          {
            association: 'fms',
            value: 200,
            from: '2003-12-31',
            to: '2001-01-01',
            licenseType: 'national',
          },
          { association: 'fms', value: 350, from: '2000-12-31', licenseType: 'national' },
          { association: 'fms', value: 350, to: '2001-01-01', licenseType: 'international' },
          { association: 'fms', value: 500, from: '2000-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-junior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Junior',
    commonNameEn: 'Junior',
    commonNameFr: 'Junior',
    importName: 'Trial Junior',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'JUNIOR',
    shortId: 379,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Junior',
        nameEn: 'Junior',
        nameFr: 'Junior',
        shortNameDe: 'Junior',
        shortNameEn: 'Junior',
        shortNameFr: 'Junior',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'JUNIOR',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 100, to: '2004-01-01', licenseType: 'national' },
          { association: 'fms', value: 200, from: '2003-12-31', licenseType: 'national' },
          { association: 'fms', value: 350, to: '2001-01-01', licenseType: 'international' },
          { association: 'fms', value: 500, from: '2000-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fun',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Fun',
    commonNameEn: 'Fun',
    commonNameFr: 'Fun',
    importName: 'Trial Fun',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'FUN',
    shortId: 380,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Fun',
        nameEn: 'Fun',
        nameFr: 'Fun',
        shortNameDe: 'Fun',
        shortNameEn: 'Fun',
        shortNameFr: 'Fun',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'FUN',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-assistent',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Assistent',
    commonNameEn: 'Assistent',
    commonNameFr: 'Assistent',
    importName: 'Trial Assistent',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'ASSISTENT',
    shortId: 381,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Assistent',
        nameEn: 'Assistent',
        nameFr: 'Assistent',
        shortNameDe: 'Assistent',
        shortNameEn: 'Assistent',
        shortNameFr: 'Assistent',
        needsHealthCheck: [
          { association: 'fms', value: false, licenseType: 'national' },
          { association: 'fms', value: false, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: 'ASSISTENT',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 50, licenseType: 'national' },
          { association: 'fms', value: 100, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fime-trial',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial EM / CE',
    commonNameEn: 'FIME Trial EM / CE',
    commonNameFr: 'FIME Trial EM / CE',
    importName: 'Trial FIME Trial',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 382,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial EM / CE',
        nameEn: 'FIME Trial EM / CE',
        nameFr: 'FIME Trial EM / CE',
        shortNameDe: 'FIME Trial EM / CE',
        shortNameEn: 'FIME Trial EM / CE',
        shortNameFr: 'FIME Trial EM / CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fim-trial-x-trial',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial / X Trial',
    commonNameEn: 'FIM Trial / X Trial',
    commonNameFr: 'FIM Trial / X Trial',
    importName: 'Trial FIM Trial / X Trial',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 383,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial / X Trial',
        nameEn: 'FIM Trial / X Trial',
        nameFr: 'FIM Trial / X Trial',
        shortNameDe: 'FIM Trial / X Trial',
        shortNameEn: 'FIM Trial / X Trial',
        shortNameFr: 'FIM Trial / X Trial',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1050, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 600,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fim-trial-women',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Women',
    commonNameEn: 'FIM Trial Women',
    commonNameFr: 'FIM Trial Women',
    importName: 'Trial FIM Trial Women',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 384,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Women',
        nameEn: 'FIM Trial Women',
        nameFr: 'FIM Trial Women',
        shortNameDe: 'FIM Trial Women',
        shortNameEn: 'FIM Trial Women',
        shortNameFr: 'FIM Trial Women',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 750, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fim-trial-assistant',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Assistant',
    commonNameEn: 'FIM Trial Assistant',
    commonNameFr: 'FIM Trial Assistant',
    importName: 'Trial FIM Trial Assistant',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 385,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Assistant',
        nameEn: 'FIM Trial Assistant',
        nameFr: 'FIM Trial Assistant',
        shortNameDe: 'FIM Trial Assistant',
        shortNameEn: 'FIM Trial Assistant',
        shortNameFr: 'FIM Trial Assistant',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 250, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fime-trial-assistant',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial Assistant',
    commonNameEn: 'FIME Trial Assistant',
    commonNameFr: 'FIME Trial Assistant',
    importName: 'Trial FIME Trial Assistant',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 386,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial Assistant',
        nameEn: 'FIME Trial Assistant',
        nameFr: 'FIME Trial Assistant',
        shortNameDe: 'FIME Trial Assistant',
        shortNameEn: 'FIME Trial Assistant',
        shortNameFr: 'FIME Trial Assistant',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 150, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-inter-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter Open',
    commonNameEn: 'Inter Open',
    commonNameFr: 'Inter Open',
    importName: 'Enduro Inter Open',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 387,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Open',
        nameEn: 'Inter Open',
        nameFr: 'Inter Open',
        shortNameDe: 'Inter Open',
        shortNameEn: 'Inter Open',
        shortNameFr: 'Inter Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, to: '1996-01-01', licenseType: 'international' },
          { association: 'fms', value: 550, from: '1995-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-inter-junior-25',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter Junior -25',
    commonNameEn: 'Inter Junior -25',
    commonNameFr: 'Inter Junior -25',
    importName: 'Enduro Inter Junior -25',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 388,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Junior -25',
        nameEn: 'Inter Junior -25',
        nameFr: 'Inter Junior -25',
        shortNameDe: 'Inter Junior -25',
        shortNameEn: 'Inter Junior -25',
        shortNameFr: 'Inter Junior -25',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-national-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'National Open',
    commonNameEn: 'National Open',
    commonNameFr: 'National Open',
    importName: 'Enduro National Open',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 389,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Open',
        nameEn: 'National Open',
        nameFr: 'National Open',
        shortNameDe: 'National Open',
        shortNameEn: 'National Open',
        shortNameFr: 'National Open',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 250, to: '1996-01-01', licenseType: 'national' },
          { association: 'fms', value: 400, from: '1995-12-31', licenseType: 'national' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-national-junior-open-25',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'National Junior Open -25',
    commonNameEn: 'National Junior Open -25',
    commonNameFr: 'National Junior Open -25',
    importName: 'Enduro National Junior Open -25',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 390,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Junior Open -25',
        nameEn: 'National Junior Open -25',
        nameFr: 'National Junior Open -25',
        shortNameDe: 'National Junior Open -25',
        shortNameEn: 'National Junior Open -25',
        shortNameFr: 'National Junior Open -25',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 250, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-national-veteran',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'National Veteran',
    commonNameEn: 'National Veteran',
    commonNameFr: 'National Veteran',
    importName: 'Enduro National Veteran',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 391,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Veteran',
        nameEn: 'National Veteran',
        nameFr: 'National Veteran',
        shortNameDe: 'National Veteran',
        shortNameEn: 'National Veteran',
        shortNameFr: 'National Veteran',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-national-senior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'National Senior',
    commonNameEn: 'National Senior',
    commonNameFr: 'National Senior',
    importName: 'Enduro National Senior',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 392,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'National Senior',
        nameEn: 'National Senior',
        nameFr: 'National Senior',
        shortNameDe: 'National Senior',
        shortNameEn: 'National Senior',
        shortNameFr: 'National Senior',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-kadetten-cadets',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Kadetten / Cadets',
    commonNameEn: 'Kadetten / Cadets',
    commonNameFr: 'Kadetten / Cadets',
    importName: 'Enduro Kadetten / Cadets',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 393,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Kadetten / Cadets',
        nameEn: 'Kadetten / Cadets',
        nameFr: 'Kadetten / Cadets',
        shortNameDe: 'Kadetten / Cadets',
        shortNameEn: 'Kadetten / Cadets',
        shortNameFr: 'Kadetten / Cadets',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-enduro-rally',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Enduro Rally',
    commonNameEn: 'Enduro Rally',
    commonNameFr: 'Enduro Rally',
    importName: 'Enduro Rally',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 543,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Enduro Rally',
        nameEn: 'Enduro Rally',
        nameFr: 'Enduro Rally',
        shortNameDe: 'Enduro Rally',
        shortNameEn: 'Enduro Rally',
        shortNameFr: 'Enduro Rally',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-vintage-enduro-european-championship',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Vintage Enduro European Championship',
    commonNameEn: 'Vintage Enduro European Championship',
    commonNameFr: 'Vintage Enduro European Championship',
    importName: 'Enduro Vintage Enduro European Championship',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 531,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Vintage Enduro European Championship',
        nameEn: 'Vintage Enduro European Championship',
        nameFr: 'Vintage Enduro European Championship',
        shortNameDe: 'Vintage Enduro European Championship',
        shortNameEn: 'Vintage Enduro European Championship',
        shortNameFr: 'Vintage Enduro European Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fime-enduro',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Enduro',
    commonNameEn: 'FIME Enduro',
    commonNameFr: 'FIME Enduro',
    importName: 'Enduro FIME Enduro',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 394,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Enduro',
        nameEn: 'FIME Enduro',
        nameFr: 'FIME Enduro',
        shortNameDe: 'FIME Enduro',
        shortNameEn: 'FIME Enduro',
        shortNameFr: 'FIME Enduro',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fime-enduro-junior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Enduro Junior',
    commonNameEn: 'FIME Enduro Junior',
    commonNameFr: 'FIME Enduro Junior',
    importName: 'Enduro FIME Enduro Junior',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 395,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Enduro Junior',
        nameEn: 'FIME Enduro Junior',
        nameFr: 'FIME Enduro Junior',
        shortNameDe: 'FIME Enduro Junior',
        shortNameEn: 'FIME Enduro Junior',
        shortNameFr: 'FIME Enduro Junior',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fim-enduro-isde',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro / ISDE',
    commonNameEn: 'FIM Enduro / ISDE',
    commonNameFr: 'FIM Enduro / ISDE',
    importName: 'Enduro FIM Enduro / ISDE',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 396,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro / ISDE',
        nameEn: 'FIM Enduro / ISDE',
        nameFr: 'FIM Enduro / ISDE',
        shortNameDe: 'FIM Enduro / ISDE',
        shortNameEn: 'FIM Enduro / ISDE',
        shortNameFr: 'FIM Enduro / ISDE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1050, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 550,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fim-enduro-junior',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro Junior',
    commonNameEn: 'FIM Enduro Junior',
    commonNameFr: 'FIM Enduro Junior',
    importName: 'Enduro FIM Enduro Junior',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 397,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro Junior',
        nameEn: 'FIM Enduro Junior',
        nameFr: 'FIM Enduro Junior',
        shortNameDe: 'FIM Enduro Junior',
        shortNameEn: 'FIM Enduro Junior',
        shortNameFr: 'FIM Enduro Junior',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fim-womens-world-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: "FIM Women's World Cup",
    commonNameEn: "FIM Women's World Cup",
    commonNameFr: "FIM Women's World Cup",
    importName: "Enduro FIM Women's World Cup",
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 398,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: "FIM Women's World Cup",
        nameEn: "FIM Women's World Cup",
        nameFr: "FIM Women's World Cup",
        shortNameDe: "FIM Women's World Cup",
        shortNameEn: "FIM Women's World Cup",
        shortNameFr: "FIM Women's World Cup",
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fim-super-enduro',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SuperEnduro',
    commonNameEn: 'FIM SuperEnduro',
    commonNameFr: 'FIM SuperEnduro',
    importName: 'Enduro FIM SuperEnduro',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 399,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SuperEnduro',
        nameEn: 'FIM SuperEnduro',
        nameFr: 'FIM SuperEnduro',
        shortNameDe: 'FIM SuperEnduro',
        shortNameEn: 'FIM SuperEnduro',
        shortNameFr: 'FIM SuperEnduro',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-vintage-solo',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Solo',
    commonNameEn: 'Solo',
    commonNameFr: 'Solo',
    importName: 'Vintage Solo',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 518,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Solo',
        nameEn: 'Solo',
        nameFr: 'Solo',
        shortNameDe: 'Solo',
        shortNameEn: 'Solo',
        shortNameFr: 'Solo',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1972-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1971-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-vintage-sidecar-pilot',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Pilot',
    commonNameEn: 'Sidecar Pilot',
    commonNameFr: 'Sidecar Pilot',
    importName: 'Vintage Sidecar Pilot',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 519,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Pilot',
        nameEn: 'Sidecar Pilot',
        nameFr: 'Sidecar Pilot',
        shortNameDe: 'Sidecar Pilot',
        shortNameEn: 'Sidecar Pilot',
        shortNameFr: 'Sidecar Pilot',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1972-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1971-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-vintage-sidecar-passager',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Passager',
    commonNameEn: 'Sidecar Passager',
    commonNameFr: 'Sidecar Passager',
    importName: 'Vintage Sidecar Passager',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 520,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Passager',
        nameEn: 'Sidecar Passager',
        nameFr: 'Sidecar Passager',
        shortNameDe: 'Sidecar Passager',
        shortNameEn: 'Sidecar Passager',
        shortNameFr: 'Sidecar Passager',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1972-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1971-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 300, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-vintage-uem-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'vintage',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'UEM Cup',
    commonNameEn: 'UEM Cup',
    commonNameFr: 'UEM Cup',
    importName: 'Vintage UEM Cup',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 406,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'UEM Cup',
        nameEn: 'UEM Cup',
        nameFr: 'UEM Cup',
        shortNameDe: 'UEM Cup',
        shortNameEn: 'UEM Cup',
        shortNameFr: 'UEM Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-bergrennen-fime-bergrennen-course-de-cote',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Bergrennen / Course de Côte',
    commonNameEn: 'FIME Bergrennen / Course de Côte',
    commonNameFr: 'FIME Bergrennen / Course de Côte',
    importName: 'Bergrennen FIME Bergrennen / Course de Côte',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 407,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Bergrennen / Course de Côte',
        nameEn: 'FIME Bergrennen / Course de Côte',
        nameFr: 'FIME Bergrennen / Course de Côte',
        shortNameDe: 'FIME Bergrennen / Course de Côte',
        shortNameEn: 'FIME Bergrennen / Course de Côte',
        shortNameFr: 'FIME Bergrennen / Course de Côte',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 700, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 200,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-bergrennen-swiss-moto-legend-trophy',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Swiss Moto Legend Trophy',
    commonNameEn: 'Swiss Moto Legend Trophy',
    commonNameFr: 'Swiss Moto Legend Trophy',
    importName: 'Bergrennen Swiss Moto Legend Trophy -50',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 521,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Swiss Moto Legend Trophy',
        nameEn: 'Swiss Moto Legend Trophy',
        nameFr: 'Swiss Moto Legend Trophy',
        shortNameDe: 'Swiss Moto Legend Trophy',
        shortNameEn: 'Swiss Moto Legend Trophy',
        shortNameFr: 'Swiss Moto Legend Trophy',
        needsHealthCheck: [
          { association: 'fms', value: false, to: '1972-01-01', licenseType: 'national' },
          { association: 'fms', value: true, from: '1971-12-31', licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 400, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-bergrennen-solo',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Solo',
    commonNameEn: 'Solo',
    commonNameFr: 'Solo',
    importName: 'Bergrennen Solo',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 410,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Solo',
        nameEn: 'Solo',
        nameFr: 'Solo',
        shortNameDe: 'Solo',
        shortNameEn: 'Solo',
        shortNameFr: 'Solo',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-bergrennen-sidecar-pilot',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Pilot',
    commonNameEn: 'Sidecar Pilot',
    commonNameFr: 'Sidecar Pilot',
    importName: 'Bergrennen Sidecar Pilot',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 411,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Pilot',
        nameEn: 'Sidecar Pilot',
        nameFr: 'Sidecar Pilot',
        shortNameDe: 'Sidecar Pilot',
        shortNameEn: 'Sidecar Pilot',
        shortNameFr: 'Sidecar Pilot',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, licenseType: 'national' },
          { association: 'fms', value: 550, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-bergrennen-sidecar-passager',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'bergrennen',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Sidecar Passager',
    commonNameEn: 'Sidecar Passager',
    commonNameFr: 'Sidecar Passager',
    importName: 'Bergrennen Sidecar Passager',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 412,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Sidecar Passager',
        nameEn: 'Sidecar Passager',
        nameFr: 'Sidecar Passager',
        shortNameDe: 'Sidecar Passager',
        shortNameEn: 'Sidecar Passager',
        shortNameFr: 'Sidecar Passager',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 300, licenseType: 'national' },
          { association: 'fms', value: 450, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-moto-gp',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto GP',
    commonNameEn: 'FIM Moto GP',
    commonNameFr: 'FIM Moto GP',
    importName: 'Road Racing FIM Moto GP',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 413,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto GP',
        nameEn: 'FIM Moto GP',
        nameFr: 'FIM Moto GP',
        shortNameDe: 'FIM Moto GP',
        shortNameEn: 'FIM Moto GP',
        shortNameFr: 'FIM Moto GP',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Outrage by IRTA',
        hintFr: 'Indignation par IRTA',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-moto-2',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto2',
    commonNameEn: 'FIM Moto2',
    commonNameFr: 'FIM Moto2',
    importName: 'Road Racing FIM Moto2',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 414,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto2',
        nameEn: 'FIM Moto2',
        nameFr: 'FIM Moto2',
        shortNameDe: 'FIM Moto2',
        shortNameEn: 'FIM Moto2',
        shortNameFr: 'FIM Moto2',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Outrage by IRTA',
        hintFr: 'Indignation par IRTA',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-moto-3',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Moto3',
    commonNameEn: 'FIM Moto3',
    commonNameFr: 'FIM Moto3',
    importName: 'Road Racing FIM Moto3',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 415,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Moto3',
        nameEn: 'FIM Moto3',
        nameFr: 'FIM Moto3',
        shortNameDe: 'FIM Moto3',
        shortNameEn: 'FIM Moto3',
        shortNameFr: 'FIM Moto3',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Outrage by IRTA',
        hintFr: 'Indignation par IRTA',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-moto-e',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MotoE',
    commonNameEn: 'FIM MotoE',
    commonNameFr: 'FIM MotoE',
    importName: 'Road Racing FIM MotoE',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 416,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MotoE',
        nameEn: 'FIM MotoE',
        nameFr: 'FIM MotoE',
        shortNameDe: 'FIM MotoE',
        shortNameEn: 'FIM MotoE',
        shortNameFr: 'FIM MotoE',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 0, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Verrechnung durch IRTA',
        hintEn: 'Outrage by IRTA',
        hintFr: 'Indignation par IRTA',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-superbike-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Superbike WM/CM',
    commonNameEn: 'FIM Superbike WM/CM',
    commonNameFr: 'FIM Superbike WM/CM',
    importName: 'Road Racing FIM Superbike WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 417,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Superbike WM/CM',
        nameEn: 'FIM Superbike WM/CM',
        nameFr: 'FIM Superbike WM/CM',
        shortNameDe: 'FIM Superbike WM/CM',
        shortNameEn: 'FIM Superbike WM/CM',
        shortNameFr: 'FIM Superbike WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 2400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 900,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-supersport-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Supersport  WM/CM',
    commonNameEn: 'FIM Supersport  WM/CM',
    commonNameFr: 'FIM Supersport  WM/CM',
    importName: 'Road Racing FIM Supersport  WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 418,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supersport  WM/CM',
        nameEn: 'FIM Supersport  WM/CM',
        nameFr: 'FIM Supersport  WM/CM',
        shortNameDe: 'FIM Supersport  WM/CM',
        shortNameEn: 'FIM Supersport  WM/CM',
        shortNameFr: 'FIM Supersport  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 2400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 900,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-endurance-team-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Team  WM/CM',
    commonNameEn: 'FIM Endurance Team  WM/CM',
    commonNameFr: 'FIM Endurance Team  WM/CM',
    importName: 'Road Racing FIM Endurance Team  WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 419,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Team  WM/CM',
        nameEn: 'FIM Endurance Team  WM/CM',
        nameFr: 'FIM Endurance Team  WM/CM',
        shortNameDe: 'FIM Endurance Team  WM/CM',
        shortNameEn: 'FIM Endurance Team  WM/CM',
        shortNameFr: 'FIM Endurance Team  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-endurance-rider-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Rider  WM/CM',
    commonNameEn: 'FIM Endurance Rider  WM/CM',
    commonNameFr: 'FIM Endurance Rider  WM/CM',
    importName: 'Road Racing FIM Endurance Rider  WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 420,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Rider  WM/CM',
        nameEn: 'FIM Endurance Rider  WM/CM',
        nameFr: 'FIM Endurance Rider  WM/CM',
        shortNameDe: 'FIM Endurance Rider  WM/CM',
        shortNameEn: 'FIM Endurance Rider  WM/CM',
        shortNameFr: 'FIM Endurance Rider  WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1050, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 550,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-sidecar-pilote-wm-cm',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Sidecar Pilote WM/CM',
    commonNameEn: 'FIM Sidecar Pilote WM/CM',
    commonNameFr: 'FIM Sidecar Pilote WM/CM',
    importName: 'Road Racing FIM Sidecar Pilote WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 421,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Pilote WM/CM',
        nameEn: 'FIM Sidecar Pilote WM/CM',
        nameFr: 'FIM Sidecar Pilote WM/CM',
        shortNameDe: 'FIM Sidecar Pilote WM/CM',
        shortNameEn: 'FIM Sidecar Pilote WM/CM',
        shortNameFr: 'FIM Sidecar Pilote WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 850, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 350,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-sidecar-passager-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Sidecar Passager WM/CM',
    commonNameEn: 'FIM Sidecar Passager WM/CM',
    commonNameFr: 'FIM Sidecar Passager WM/CM',
    importName: 'Road Racing FIM Sidecar Passager WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 422,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Sidecar Passager WM/CM',
        nameEn: 'FIM Sidecar Passager WM/CM',
        nameFr: 'FIM Sidecar Passager WM/CM',
        shortNameDe: 'FIM Sidecar Passager WM/CM',
        shortNameEn: 'FIM Sidecar Passager WM/CM',
        shortNameFr: 'FIM Sidecar Passager WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-moto-gp-rookies-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MotoGP Rookies Cup',
    commonNameEn: 'FIM MotoGP Rookies Cup',
    commonNameFr: 'FIM MotoGP Rookies Cup',
    importName: 'Road Racing FIM MotoGP Rookies Cup',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 423,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MotoGP Rookies Cup',
        nameEn: 'FIM MotoGP Rookies Cup',
        nameFr: 'FIM MotoGP Rookies Cup',
        shortNameDe: 'FIM MotoGP Rookies Cup',
        shortNameEn: 'FIM MotoGP Rookies Cup',
        shortNameFr: 'FIM MotoGP Rookies Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-cev-moto-3-junior-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM CEV Moto3 Junior WM / CM',
    commonNameEn: 'FIM CEV Moto3 Junior WM / CM',
    commonNameFr: 'FIM CEV Moto3 Junior WM / CM',
    importName: 'Road Racing FIM CEV Moto3 Junior WM / CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 526,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM CEV Moto3 Junior WM / CM',
        nameEn: 'FIM CEV Moto3 Junior WM / CM',
        nameFr: 'FIM CEV Moto3 Junior WM / CM',
        shortNameDe: 'FIM CEV Moto3 Junior WM / CM',
        shortNameEn: 'FIM CEV Moto3 Junior WM / CM',
        shortNameFr: 'FIM CEV Moto3 Junior WM / CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-junior-gp-moto-2-european-championship',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM JuniorGP Moto2 European Championship',
    commonNameEn: 'FIM JuniorGP Moto2 European Championship',
    commonNameFr: 'FIM JuniorGP Moto2 European Championship',
    importName: 'Road Racing FIM JuniorGP Moto2 European Championship',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 528,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM JuniorGP Moto2 European Championship',
        nameEn: 'FIM JuniorGP Moto2 European Championship',
        nameFr: 'FIM JuniorGP Moto2 European Championship',
        shortNameDe: 'FIM JuniorGP Moto2 European Championship',
        shortNameEn: 'FIM JuniorGP Moto2 European Championship',
        shortNameFr: 'FIM JuniorGP Moto2 European Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: false,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fime-road-racing',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Road Racing EM / CE',
    commonNameEn: 'FIME Road Racing EM / CE',
    commonNameFr: 'FIME Road Racing EM / CE',
    importName: 'Road Racing FIME Road Racing',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 424,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Road Racing EM / CE',
        nameEn: 'FIME Road Racing EM / CE',
        nameFr: 'FIME Road Racing EM / CE',
        shortNameDe: 'FIME Road Racing EM / CE',
        shortNameEn: 'FIME Road Racing EM / CE',
        shortNameFr: 'FIME Road Racing EM / CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 300,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Genaue Kategorie bekanntgeben',
        hintEn: 'Appearable category',
        hintFr: "Catégorie d'appareil",
        advertiseSamInsurance: false,
        needsCommentDe: 'genaue Kategorie bekanntgeben',
        needsCommentFr: 'State exact category',
        needsCommentEn: "Catégorie d'appareil",
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fime-promosport-mini-rr-pocketbike',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Promosport (Mini RR, Pocketbike)',
    commonNameEn: 'FIME Promosport (Mini RR, Pocketbike)',
    commonNameFr: 'FIME Promosport (Mini RR, Pocketbike)',
    importName: 'Road Racing FIME Promosport (Mini RR, Pocketbike)',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 425,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Promosport (Mini RR, Pocketbike)',
        nameEn: 'FIME Promosport (Mini RR, Pocketbike)',
        nameFr: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameDe: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameEn: 'FIME Promosport (Mini RR, Pocketbike)',
        shortNameFr: 'FIME Promosport (Mini RR, Pocketbike)',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fime-northern-talent-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Northern Talent Cup',
    commonNameEn: 'FIME Northern Talent Cup',
    commonNameFr: 'FIME Northern Talent Cup',
    importName: 'Road Racing FIME Northern Talent Cup',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 426,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Northern Talent Cup',
        nameEn: 'FIME Northern Talent Cup',
        nameFr: 'FIME Northern Talent Cup',
        shortNameDe: 'FIME Northern Talent Cup',
        shortNameEn: 'FIME Northern Talent Cup',
        shortNameFr: 'FIME Northern Talent Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 150,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-supersport-600',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Supersport 600',
    commonNameEn: 'Inter Supersport 600',
    commonNameFr: 'Inter Supersport 600',
    importName: 'Road Racing Inter Supersport 600',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 427,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Supersport 600',
        nameEn: 'Inter Supersport 600',
        nameFr: 'Inter Supersport 600',
        shortNameDe: 'Inter Supersport 600',
        shortNameEn: 'Inter Supersport 600',
        shortNameFr: 'Inter Supersport 600',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-supersport-300',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Supersport 300',
    commonNameEn: 'Inter Supersport 300',
    commonNameFr: 'Inter Supersport 300',
    importName: 'Road Racing Inter Supersport 300',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 527,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Supersport 300',
        nameEn: 'Inter Supersport 300',
        nameFr: 'Inter Supersport 300',
        shortNameDe: 'Inter Supersport 300',
        shortNameEn: 'Inter Supersport 300',
        shortNameFr: 'Inter Supersport 300',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-superbike',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Superbike',
    commonNameEn: 'Inter Superbike',
    commonNameFr: 'Inter Superbike',
    importName: 'Road Racing Inter Superbike',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 428,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Superbike',
        nameEn: 'Inter Superbike',
        nameFr: 'Inter Superbike',
        shortNameDe: 'Inter Superbike',
        shortNameEn: 'Inter Superbike',
        shortNameFr: 'Inter Superbike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-yamaha-r-3-b-lu-c-ru-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Yamaha R3 bLU cRU Cup',
    commonNameEn: 'Inter Yamaha R3 bLU cRU Cup',
    commonNameFr: 'Inter Yamaha R3 bLU cRU Cup',
    importName: 'Road Racing Inter Yamaha R3 bLU cRU Cup',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 429,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Yamaha R3 bLU cRU Cup',
        nameEn: 'Inter Yamaha R3 bLU cRU Cup',
        nameFr: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameDe: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameEn: 'Inter Yamaha R3 bLU cRU Cup',
        shortNameFr: 'Inter Yamaha R3 bLU cRU Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-250-cc',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter 250cc',
    commonNameEn: 'Inter 250cc',
    commonNameFr: 'Inter 250cc',
    importName: 'Road Racing Inter 250cc',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 430,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter 250cc',
        nameEn: 'Inter 250cc',
        nameFr: 'Inter 250cc',
        shortNameDe: 'Inter 250cc',
        shortNameEn: 'Inter 250cc',
        shortNameFr: 'Inter 250cc',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-125-cc',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter 125cc',
    commonNameEn: 'Inter 125cc',
    commonNameFr: 'Inter 125cc',
    importName: 'Road Racing Inter 125cc',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 431,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter 125cc',
        nameEn: 'Inter 125cc',
        nameFr: 'Inter 125cc',
        shortNameDe: 'Inter 125cc',
        shortNameEn: 'Inter 125cc',
        shortNameFr: 'Inter 125cc',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 400, to: '2004-01-01', licenseType: 'international' },
          { association: 'fms', value: 550, from: '2003-12-31', licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-sidecar-pilot',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Sidecar Pilot',
    commonNameEn: 'Inter Sidecar Pilot',
    commonNameFr: 'Inter Sidecar Pilot',
    importName: 'Road Racing Inter Sidecar Pilot',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 432,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Sidecar Pilot',
        nameEn: 'Inter Sidecar Pilot',
        nameFr: 'Inter Sidecar Pilot',
        shortNameDe: 'Inter Sidecar Pilot',
        shortNameEn: 'Inter Sidecar Pilot',
        shortNameFr: 'Inter Sidecar Pilot',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-sidecar-passager',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Sidecar Passager',
    commonNameEn: 'Inter Sidecar Passager',
    commonNameFr: 'Inter Sidecar Passager',
    importName: 'Road Racing Inter Sidecar Passager',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 433,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Sidecar Passager',
        nameEn: 'Inter Sidecar Passager',
        nameFr: 'Inter Sidecar Passager',
        shortNameDe: 'Inter Sidecar Passager',
        shortNameEn: 'Inter Sidecar Passager',
        shortNameFr: 'Inter Sidecar Passager',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 450, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-mini-road-racing',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Mini Road Racing',
    commonNameEn: 'Inter Mini Road Racing',
    commonNameFr: 'Inter Mini Road Racing',
    importName: 'Road Racing Inter Mini Road Racing',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 434,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Mini Road Racing',
        nameEn: 'Inter Mini Road Racing',
        nameFr: 'Inter Mini Road Racing',
        shortNameDe: 'Inter Mini Road Racing',
        shortNameEn: 'Inter Mini Road Racing',
        shortNameFr: 'Inter Mini Road Racing',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-endurance',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Endurance',
    commonNameEn: 'Inter Endurance',
    commonNameFr: 'Inter Endurance',
    importName: 'Road Racing Inter Endurance',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 539,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Endurance',
        nameEn: 'Inter Endurance',
        nameFr: 'Inter Endurance',
        shortNameDe: 'Inter Endurance',
        shortNameEn: 'Inter Endurance',
        shortNameFr: 'Inter Endurance',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-european-talent-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'European Talent Cup',
    commonNameEn: 'European Talent Cup',
    commonNameFr: 'European Talent Cup',
    importName: 'Road Racing European Talent Cup',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 435,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'European Talent Cup',
        nameEn: 'European Talent Cup',
        nameFr: 'European Talent Cup',
        shortNameDe: 'European Talent Cup',
        shortNameEn: 'European Talent Cup',
        shortNameFr: 'European Talent Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-scooter',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Scooter',
    commonNameEn: 'Inter Scooter',
    commonNameFr: 'Inter Scooter',
    importName: 'Road Racing Scooter',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 540,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Scooter',
        nameEn: 'Inter Scooter',
        nameFr: 'Inter Scooter',
        shortNameDe: 'Inter Scooter',
        shortNameEn: 'Inter Scooter',
        shortNameFr: 'Inter Scooter',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-inter-pitbike',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Pitbike',
    commonNameEn: 'Inter Pitbike',
    commonNameFr: 'Inter Pitbike',
    importName: 'Road Racing Pitbike',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 544,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Pitbike',
        nameEn: 'Inter Pitbike',
        nameFr: 'Inter Pitbike',
        shortNameDe: 'Inter Pitbike',
        shortNameEn: 'Inter Pitbike',
        shortNameFr: 'Inter Pitbike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-dragster-fim-drag-bike-wold-cup',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'dragster',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Drag Bike Wold Cup',
    commonNameEn: 'FIM Drag Bike Wold Cup',
    commonNameFr: 'FIM Drag Bike Wold Cup',
    importName: 'Dragster/Sprinter FIM Drag Bike Wold Cup',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 436,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Drag Bike Wold Cup',
        nameEn: 'FIM Drag Bike Wold Cup',
        nameFr: 'FIM Drag Bike Wold Cup',
        shortNameDe: 'FIM Drag Bike Wold Cup',
        shortNameEn: 'FIM Drag Bike Wold Cup',
        shortNameFr: 'FIM Drag Bike Wold Cup',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 2400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 550,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-dragster-inter-drag-bike',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'dragster',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Drag Bike',
    commonNameEn: 'Inter Drag Bike',
    commonNameFr: 'Inter Drag Bike',
    importName: 'Dragster/Sprinter Inter Drag Bike',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 437,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Drag Bike',
        nameEn: 'Inter Drag Bike',
        nameFr: 'Inter Drag Bike',
        shortNameDe: 'Inter Drag Bike',
        shortNameEn: 'Inter Drag Bike',
        shortNameFr: 'Inter Drag Bike',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-ice-race-fim-ice-race-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Ice-Race WM/CM',
    commonNameEn: 'FIM Ice-Race WM/CM',
    commonNameFr: 'FIM Ice-Race WM/CM',
    importName: 'Ice-Race FIM Ice-Race WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 438,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Ice-Race WM/CM',
        nameEn: 'FIM Ice-Race WM/CM',
        nameFr: 'FIM Ice-Race WM/CM',
        shortNameDe: 'FIM Ice-Race WM/CM',
        shortNameEn: 'FIM Ice-Race WM/CM',
        shortNameFr: 'FIM Ice-Race WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 450,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-ice-race-fim-eu-ice-race-em-ce',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM EU Ice-Race EM/CE',
    commonNameEn: 'FIM EU Ice-Race EM/CE',
    commonNameFr: 'FIM EU Ice-Race EM/CE',
    importName: 'Ice-Race FIM EU Ice-Race EM/CE',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 439,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM EU Ice-Race EM/CE',
        nameEn: 'FIM EU Ice-Race EM/CE',
        nameFr: 'FIM EU Ice-Race EM/CE',
        shortNameDe: 'FIM EU Ice-Race EM/CE',
        shortNameEn: 'FIM EU Ice-Race EM/CE',
        shortNameFr: 'FIM EU Ice-Race EM/CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1050, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 200,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-ice-race-inter-ice-race',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'iceRace',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Ice-Race',
    commonNameEn: 'Inter Ice-Race',
    commonNameFr: 'Inter Ice-Race',
    importName: 'Ice-Race Inter Ice-Race',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 440,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Ice-Race',
        nameEn: 'Inter Ice-Race',
        nameFr: 'Inter Ice-Race',
        shortNameDe: 'Inter Ice-Race',
        shortNameEn: 'Inter Ice-Race',
        shortNameFr: 'Inter Ice-Race',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 850, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-fim-snx-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIM SNX WM/CM',
    commonNameEn: 'FIM SNX WM/CM',
    commonNameFr: 'FIM SNX WM/CM',
    importName: 'Ice-Race FIM SNX WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 441,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX WM/CM',
        nameEn: 'FIM SNX WM/CM',
        nameFr: 'FIM SNX WM/CM',
        shortNameDe: 'FIM SNX WM/CM',
        shortNameEn: 'FIM SNX WM/CM',
        shortNameFr: 'FIM SNX WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 450,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-fim-snx-wm-cm-women',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX WM/CM Women',
    commonNameEn: 'FIM SNX WM/CM Women',
    commonNameFr: 'FIM SNX WM/CM Women',
    importName: 'Ice-Race FIM SNX WM/CM Women',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 494,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX WM/CM Women',
        nameEn: 'FIM SNX WM/CM Women',
        nameFr: 'FIM SNX WM/CM Women',
        shortNameDe: 'FIM SNX WM/CM Women',
        shortNameEn: 'FIM SNX WM/CM Women',
        shortNameFr: 'FIM SNX WM/CM Women',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 650, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-fime-snx-em-ce',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'FIME SNX EM/CE',
    commonNameEn: 'FIME SNX EM/CE',
    commonNameFr: 'FIME SNX EM/CE',
    importName: 'SNX FIME SNX EM/CE',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 442,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME SNX EM/CE',
        nameEn: 'FIME SNX EM/CE',
        nameFr: 'FIME SNX EM/CE',
        shortNameDe: 'FIME SNX EM/CE',
        shortNameEn: 'FIME SNX EM/CE',
        shortNameFr: 'FIME SNX EM/CE',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-inter-snx',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Inter SNX',
    commonNameEn: 'Inter SNX',
    commonNameFr: 'Inter SNX',
    importName: 'SNX Inter SNX',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 443,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter SNX',
        nameEn: 'Inter SNX',
        nameFr: 'Inter SNX',
        shortNameDe: 'Inter SNX',
        shortNameEn: 'Inter SNX',
        shortNameFr: 'Inter SNX',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 350, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-elite-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Elite Open',
    commonNameEn: 'Elite Open',
    commonNameFr: 'Elite Open',
    importName: 'SNX Elite Open',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 444,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Elite Open',
        nameEn: 'Elite Open',
        nameFr: 'Elite Open',
        shortNameDe: 'Elite Open',
        shortNameEn: 'Elite Open',
        shortNameFr: 'Elite Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-amateur-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Amateur Open',
    commonNameEn: 'Amateur Open',
    commonNameFr: 'Amateur Open',
    importName: 'SNX Amateur Open',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 445,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Amateur Open',
        nameEn: 'Amateur Open',
        nameFr: 'Amateur Open',
        shortNameDe: 'Amateur Open',
        shortNameEn: 'Amateur Open',
        shortNameFr: 'Amateur Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-senior-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Senior Open',
    commonNameEn: 'Senior Open',
    commonNameFr: 'Senior Open',
    importName: 'SNX Senior Open',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 446,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Senior Open',
        nameEn: 'Senior Open',
        nameFr: 'Senior Open',
        shortNameDe: 'Senior Open',
        shortNameEn: 'Senior Open',
        shortNameFr: 'Senior Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-damen-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: true,
    commonNameDe: 'Damen Open',
    commonNameEn: 'Damen Open',
    commonNameFr: 'Damen Open',
    importName: 'SNX Damen Open',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 447,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Damen Open',
        nameEn: 'Damen Open',
        nameFr: 'Damen Open',
        shortNameDe: 'Damen Open',
        shortNameEn: 'Damen Open',
        shortNameFr: 'Damen Open',
        needsHealthCheck: [
          { association: 'fms', value: true, licenseType: 'national' },
          { association: 'fms', value: true, licenseType: 'international' },
        ],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [
          { association: 'fms', value: 200, licenseType: 'national' },
          { association: 'fms', value: 350, licenseType: 'international' },
        ],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-track-fim-track-racing-world-championship',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Track Racing World Championship',
    commonNameEn: 'FIM Track Racing World Championship',
    commonNameFr: 'FIM Track Racing World Championship',
    importName: 'Track FIM Track Racing World Championship',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 448,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Track Racing World Championship',
        nameEn: 'FIM Track Racing World Championship',
        nameFr: 'FIM Track Racing World Championship',
        shortNameDe: 'FIM Track Racing World Championship',
        shortNameEn: 'FIM Track Racing World Championship',
        shortNameFr: 'FIM Track Racing World Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 800,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-track-fim-flat-track-world-championship',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Flat Track World Championship',
    commonNameEn: 'FIM Flat Track World Championship',
    commonNameFr: 'FIM Flat Track World Championship',
    importName: 'Track FIM Flat Track World Championship',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 449,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Flat Track World Championship',
        nameEn: 'FIM Flat Track World Championship',
        nameFr: 'FIM Flat Track World Championship',
        shortNameDe: 'FIM Flat Track World Championship',
        shortNameEn: 'FIM Flat Track World Championship',
        shortNameFr: 'FIM Flat Track World Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 700, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 200,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-track-fime-track-racing',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Track Racing',
    commonNameEn: 'FIME Track Racing',
    commonNameFr: 'FIME Track Racing',
    importName: 'Track FIME Track Racing',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 450,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Track Racing',
        nameEn: 'FIME Track Racing',
        nameFr: 'FIME Track Racing',
        shortNameDe: 'FIME Track Racing',
        shortNameEn: 'FIME Track Racing',
        shortNameFr: 'FIME Track Racing',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-track-fime-speedway',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Speedway',
    commonNameEn: 'FIME Speedway',
    commonNameFr: 'FIME Speedway',
    importName: 'Track FIME Speedway',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 451,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Speedway',
        nameEn: 'FIME Speedway',
        nameFr: 'FIME Speedway',
        shortNameDe: 'FIME Speedway',
        shortNameEn: 'FIME Speedway',
        shortNameFr: 'FIME Speedway',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-track-inter-speedway-track',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter Speedway/Track',
    commonNameEn: 'Inter Speedway/Track',
    commonNameFr: 'Inter Speedway/Track',
    importName: 'Track Inter Speedway/Track',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 452,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter Speedway/Track',
        nameEn: 'Inter Speedway/Track',
        nameFr: 'Inter Speedway/Track',
        shortNameDe: 'Inter Speedway/Track',
        shortNameEn: 'Inter Speedway/Track',
        shortNameFr: 'Inter Speedway/Track',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-other-fms-fun-sport',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Fun Sport',
    commonNameEn: 'Fun Sport',
    commonNameFr: 'Fun Sport',
    importName: 'Fun Sport',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 522,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Fun Sport',
        nameEn: 'Fun Sport',
        nameFr: 'Fun Sport',
        shortNameDe: 'Fun Sport',
        shortNameEn: 'Fun Sport',
        shortNameFr: 'Fun Sport',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-other-fms-inter-one-event-all-disciplines',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'Inter One Event all disciplines',
    commonNameEn: 'Inter One Event all disciplines',
    commonNameFr: 'Inter One Event all disciplines',
    importName: 'Inter One Event all disciplines',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 523,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'Inter One Event all disciplines',
        nameEn: 'Inter One Event all disciplines',
        nameFr: 'Inter One Event all disciplines',
        shortNameDe: 'Inter One Event all disciplines',
        shortNameEn: 'Inter One Event all disciplines',
        shortNameFr: 'Inter One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-other-fms-fime-one-event-all-disciplines',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME One Event all disciplines',
    commonNameEn: 'FIME One Event all disciplines',
    commonNameFr: 'FIME One Event all disciplines',
    importName: 'FIME One Event all disciplines',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 524,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME One Event all disciplines',
        nameEn: 'FIME One Event all disciplines',
        nameFr: 'FIME One Event all disciplines',
        shortNameDe: 'FIME One Event all disciplines',
        shortNameEn: 'FIME One Event all disciplines',
        shortNameFr: 'FIME One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 185, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 95,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-other-fms-fime-promosport-one-event-all-disciplines',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'otherFms',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Promosport One Event all disciplines',
    commonNameEn: 'FIME Promosport One Event all disciplines',
    commonNameFr: 'FIME Promosport One Event all disciplines',
    importName: 'FIME Promosport One Event all disciplines',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 525,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Promosport One Event all disciplines',
        nameEn: 'FIME Promosport One Event all disciplines',
        nameFr: 'FIME Promosport One Event all disciplines',
        shortNameDe: 'FIME Promosport One Event all disciplines',
        shortNameEn: 'FIME Promosport One Event all disciplines',
        shortNameFr: 'FIME Promosport One Event all disciplines',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 150, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 60,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-superbike-supersport-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Superbike/Supersport One Event WM/CM',
    commonNameEn: 'FIM Superbike/Supersport One Event WM/CM',
    commonNameFr: 'FIM Superbike/Supersport One Event WM/CM',
    importName: 'FIM Superbike/Supersport One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 457,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Superbike/Supersport One Event WM/CM',
        nameEn: 'FIM Superbike/Supersport One Event WM/CM',
        nameFr: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameDe: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameEn: 'FIM Superbike/Supersport One Event WM/CM',
        shortNameFr: 'FIM Superbike/Supersport One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 720, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Kategorie, Datum und Ort bekanntgeben',
        hintEn: 'Racing, category, date and location announced',
        hintFr: 'Nom de course, catégorie, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Kategorie, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, catégorie, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 630,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-road-racing-fim-endurance-rider-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'roadRacing',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Endurance Rider One Event WM/CM',
    commonNameEn: 'FIM Endurance Rider One Event WM/CM',
    commonNameFr: 'FIM Endurance Rider One Event WM/CM',
    importName: 'FIM Endurance Rider One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 458,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Endurance Rider One Event WM/CM',
        nameEn: 'FIM Endurance Rider One Event WM/CM',
        nameFr: 'FIM Endurance Rider One Event WM/CM',
        shortNameDe: 'FIM Endurance Rider One Event WM/CM',
        shortNameEn: 'FIM Endurance Rider One Event WM/CM',
        shortNameFr: 'FIM Endurance Rider One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 210,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-mxgp-mx-2-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MXGP/ MX2 One Event WM/CM',
    commonNameEn: 'FIM MXGP/ MX2 One Event WM/CM',
    commonNameFr: 'FIM MXGP/ MX2 One Event WM/CM',
    importName: 'FIM MXGP/ MX2 One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 459,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MXGP/ MX2 One Event WM/CM',
        nameEn: 'FIM MXGP/ MX2 One Event WM/CM',
        nameFr: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameDe: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameEn: 'FIM MXGP/ MX2 One Event WM/CM',
        shortNameFr: 'FIM MXGP/ MX2 One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 500, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 410,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-mx-junior-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Junior One Event WM/CM',
    commonNameEn: 'FIM MX Junior One Event WM/CM',
    commonNameFr: 'FIM MX Junior One Event WM/CM',
    importName: 'FIM MX Junior One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 460,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Junior One Event WM/CM',
        nameEn: 'FIM MX Junior One Event WM/CM',
        nameFr: 'FIM MX Junior One Event WM/CM',
        shortNameDe: 'FIM MX Junior One Event WM/CM',
        shortNameEn: 'FIM MX Junior One Event WM/CM',
        shortNameFr: 'FIM MX Junior One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 210, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 120,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-wmx-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM WMX One Event WM/CM',
    commonNameEn: 'FIM WMX One Event WM/CM',
    commonNameFr: 'FIM WMX One Event WM/CM',
    importName: 'FIM WMX One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 461,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM WMX One Event WM/CM',
        nameEn: 'FIM WMX One Event WM/CM',
        nameFr: 'FIM WMX One Event WM/CM',
        shortNameDe: 'FIM WMX One Event WM/CM',
        shortNameEn: 'FIM WMX One Event WM/CM',
        shortNameFr: 'FIM WMX One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 140, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-mx-sidecar-pilot-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: true,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
    commonNameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
    commonNameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
    importName: 'FIM MX Sidecar Pilot One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 541,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
        nameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
        nameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameDe: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameEn: 'FIM MX Sidecar Pilot One Event WM/CM',
        shortNameFr: 'FIM MX Sidecar Pilot One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 100, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 100,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-motocross-fim-mx-sidecar-passenger-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: true,
    type: 'motocross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
    commonNameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
    commonNameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
    importName: 'FIM MX Sidecar Passenger One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 542,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
        nameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
        nameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameDe: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameEn: 'FIM MX Sidecar Passenger One Event WM/CM',
        shortNameFr: 'FIM MX Sidecar Passenger One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 50, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 50,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-supermoto-fim-supermoto-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supermoto',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Supermoto One Event WM/CM',
    commonNameEn: 'FIM Supermoto One Event WM/CM',
    commonNameFr: 'FIM Supermoto One Event WM/CM',
    importName: 'FIM Supermoto One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 462,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supermoto One Event WM/CM',
        nameEn: 'FIM Supermoto One Event WM/CM',
        nameFr: 'FIM Supermoto One Event WM/CM',
        shortNameDe: 'FIM Supermoto One Event WM/CM',
        shortNameEn: 'FIM Supermoto One Event WM/CM',
        shortNameFr: 'FIM Supermoto One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 210,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-fim-snx-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX One Event WM/CM',
    commonNameEn: 'FIM SNX One Event WM/CM',
    commonNameFr: 'FIM SNX One Event WM/CM',
    importName: 'FIM SNX One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 463,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX One Event WM/CM',
        nameEn: 'FIM SNX One Event WM/CM',
        nameFr: 'FIM SNX One Event WM/CM',
        shortNameDe: 'FIM SNX One Event WM/CM',
        shortNameEn: 'FIM SNX One Event WM/CM',
        shortNameFr: 'FIM SNX One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 400, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 310,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-snx-fim-snx-women-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'snx',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM SNX Women One Event WM/CM',
    commonNameEn: 'FIM SNX Women One Event WM/CM',
    commonNameFr: 'FIM SNX Women One Event WM/CM',
    importName: 'FIM SNX Women One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 464,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM SNX Women One Event WM/CM',
        nameEn: 'FIM SNX Women One Event WM/CM',
        nameFr: 'FIM SNX Women One Event WM/CM',
        shortNameDe: 'FIM SNX Women One Event WM/CM',
        shortNameEn: 'FIM SNX Women One Event WM/CM',
        shortNameFr: 'FIM SNX Women One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 190, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 100,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fim-trial-trial-de-nation-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
    commonNameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
    commonNameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
    importName: 'FIM Trial, Trial de Nation One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 465,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
        nameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
        nameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameDe: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameEn: 'FIM Trial, Trial de Nation One Event WM/CM',
        shortNameFr: 'FIM Trial, Trial de Nation One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 210,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fime-trial-assistant-one-event',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIME Trial Assistant One Event',
    commonNameEn: 'FIME Trial Assistant One Event',
    commonNameFr: 'FIME Trial Assistant One Event',
    importName: 'Trial FIME Trial Assistant One Event',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 466,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIME Trial Assistant One Event',
        nameEn: 'FIME Trial Assistant One Event',
        nameFr: 'FIME Trial Assistant One Event',
        shortNameDe: 'FIME Trial Assistant One Event',
        shortNameEn: 'FIME Trial Assistant One Event',
        shortNameFr: 'FIME Trial Assistant One Event',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 50, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-trial-fim-trial-assistent-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'trial',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Trial Assistent One Event WM/CM',
    commonNameEn: 'FIM Trial Assistent One Event WM/CM',
    commonNameFr: 'FIM Trial Assistent One Event WM/CM',
    importName: 'FIM Trial Assistent One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 467,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Trial Assistent One Event WM/CM',
        nameEn: 'FIM Trial Assistent One Event WM/CM',
        nameFr: 'FIM Trial Assistent One Event WM/CM',
        shortNameDe: 'FIM Trial Assistent One Event WM/CM',
        shortNameEn: 'FIM Trial Assistent One Event WM/CM',
        shortNameFr: 'FIM Trial Assistent One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: false, licenseType: 'international' }],
        needsInsurance: false,
        needsEmergency: false,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 140, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 50,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fim-enduro-isde-world-trophy-one-event-wm-cm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    commonNameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    commonNameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    importName: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 468,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        nameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        nameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameDe: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameEn: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        shortNameFr: 'FIM Enduro & ISDE World Trophy One Event WM/CM',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 210,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fim-isde-womens-clubs-team-one-event',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM ISDE Womens, Clubs Team One Event',
    commonNameEn: 'FIM ISDE Womens, Clubs Team One Event',
    commonNameFr: 'FIM ISDE Womens, Clubs Team One Event',
    importName: 'FIM ISDE Womens, Clubs Team One Event',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 469,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM ISDE Womens, Clubs Team One Event',
        nameEn: 'FIM ISDE Womens, Clubs Team One Event',
        nameFr: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameDe: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameEn: 'FIM ISDE Womens, Clubs Team One Event',
        shortNameFr: 'FIM ISDE Womens, Clubs Team One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 250, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 160,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-enduro-fim-cross-country-rally-one-event',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'enduro',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Cross Country Rally One Event',
    commonNameEn: 'FIM Cross Country Rally One Event',
    commonNameFr: 'FIM Cross Country Rally One Event',
    importName: 'FIM Cross Country Rally One Event',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 470,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Cross Country Rally One Event',
        nameEn: 'FIM Cross Country Rally One Event',
        nameFr: 'FIM Cross Country Rally One Event',
        shortNameDe: 'FIM Cross Country Rally One Event',
        shortNameEn: 'FIM Cross Country Rally One Event',
        shortNameFr: 'FIM Cross Country Rally One Event',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 740, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 650,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-track-fim-track-ice-speedway',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'track',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Track, Ice Speedway',
    commonNameEn: 'FIM Track, Ice Speedway',
    commonNameFr: 'FIM Track, Ice Speedway',
    importName: 'FIM Track, Ice Speedway',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 471,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Track, Ice Speedway',
        nameEn: 'FIM Track, Ice Speedway',
        nameFr: 'FIM Track, Ice Speedway',
        shortNameDe: 'FIM Track, Ice Speedway',
        shortNameEn: 'FIM Track, Ice Speedway',
        shortNameFr: 'FIM Track, Ice Speedway',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 300, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 0,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        hintEn: 'Racing name, date and location announced',
        hintFr: 'Nom de course, date et emplacement annoncés',
        advertiseSamInsurance: false,
        needsCommentDe: 'Rennbezeichnung, Datum und Ort bekanntgeben',
        needsCommentFr: 'State race, date and place',
        needsCommentEn: 'Nom de course, date et emplacement annoncés',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 210,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-supercross-fim-international-supercross',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supercross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM International Supercross',
    commonNameEn: 'FIM International Supercross',
    commonNameFr: 'FIM International Supercross',
    importName: 'FIM International Supercross',
    year: 2022,
    enlistWithoutLicense: false,
    startListName: '',
    shortId: 532,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM International Supercross',
        nameEn: 'FIM International Supercross',
        nameFr: 'FIM International Supercross',
        shortNameDe: 'FIM International Supercross',
        shortNameEn: 'FIM International Supercross',
        shortNameFr: 'FIM International Supercross',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 550, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 100,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'fms-2022-supercross-fim-supercross-wm',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'supercross',
    transponders: [],
    numberChoice: false,
    commonNameDe: 'FIM Supercross World Championship',
    commonNameEn: 'FIM Supercross World Championship',
    commonNameFr: 'FIM Supercross World Championship',
    importName: 'FIM Supercross WM',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: '',
    shortId: 533,
    associations: ['fms'],
    associationSpecificDetails: [
      {
        nameDe: 'FIM Supercross World Championship',
        nameEn: 'FIM Supercross World Championship',
        nameFr: 'FIM Supercross World Championship',
        shortNameDe: 'FIM Supercross World Championship',
        shortNameEn: 'FIM Supercross World Championship',
        shortNameFr: 'FIM Supercross World Championship',
        needsHealthCheck: [{ association: 'fms', value: true, licenseType: 'international' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: true,
        myLapsName: '',
        startListName: '',
        myLapsSpecialExportFormatting: true,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#000000',
        numberFixed: true,
        association: 'fms',
        prices: [{ association: 'fms', value: 1800, licenseType: 'international' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: false,
        offersInternationalLicense: true,
        needsBikeInfo: true,
        priceAdditionalLicense: 1250,
        priceInsuranceBookkeeping: 60,
        priceInscriptionWithLicense: 0,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-31T22:59:59.999Z',
        hintDe: '',
        hintEn: '',
        hintFr: '',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-open',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MXRS Open',
    commonNameEn: 'Open',
    commonNameFr: 'Open',
    importName: 'Open',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Open',
    shortId: 506,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'MXRS Open',
        nameEn: 'Open',
        nameFr: 'Open',
        shortNameDe: 'Open',
        shortNameEn: 'Open',
        shortNameFr: 'Open',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Open',
        startListName: 'Open',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 180, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 40,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe:
          'Als Tagesfahrer bei der SuperRace Klasse möglich (ohne Meisterschaftspunkte nur Tagesklassement für Podium)',
        hintEn:
          'Possible as daily driver in SuperRace class (without championship points only daily classification for podium)',
        hintFr:
          'Possible en tant que pilote journalier dans la classe SuperRace (sans points de championnat, uniquement classement journalier pour le podium)',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-mx-2-125',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'MX2 125',
    commonNameEn: 'MX2 125',
    commonNameFr: 'MX2 125',
    importName: 'MX2 125',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'MX2 125/250 4T',
    shortId: 507,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'MX2 125',
        nameEn: 'MX2 125',
        nameFr: 'MX2 125',
        shortNameDe: 'MX2 125',
        shortNameEn: 'MX2 125',
        shortNameFr: 'MX2 125',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'MX2 125/250 4T',
        startListName: 'MX2 125/250 4T',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 180, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 40,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe:
          'Als Tagesfahrer bei der Open oder SuperRace Klasse möglich (ohne Meisterschaftspunkte nur Tagesklassement für Podium)',
        hintEn:
          'Possible as daily driver in Open or SuperRace class (without championship points only daily classification for podium)',
        hintFr:
          "Possibilité d'être pilote d'un jour dans la classe Open ou SuperRace (sans points de championnat, seulement classement journalier pour le podium)",
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-super-race',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Super Race',
    commonNameEn: 'Super Race',
    commonNameFr: 'Super Race',
    importName: 'SuperRace',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Super Race',
    shortId: 508,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Super Race',
        nameEn: 'Super Race',
        nameFr: 'Super Race',
        shortNameDe: 'Super Race',
        shortNameEn: 'Super Race',
        shortNameFr: 'Super Race',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Super Race',
        startListName: 'Super Race',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 180, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 40,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe: 'Mit dieser Lizenz kann eine Zusatzlizenz ProRace gelöst werden',
        hintEn: 'With this license an additional license ProRace can be solved',
        hintFr: "Cette licence permet d'acheter une licence supplémentaire ProRace.",
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-senioren-u-40',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren ü40',
    commonNameEn: 'Senioren ü40',
    commonNameFr: 'Senioren ü40',
    importName: 'Senioren ü40',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Senioren ü40',
    shortId: 509,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren ü40',
        nameEn: 'Senioren ü40',
        nameFr: 'Senioren ü40',
        shortNameDe: 'Senioren ü40',
        shortNameEn: 'Senioren ü40',
        shortNameFr: 'Senioren ü40',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren ü40',
        startListName: 'Senioren ü40',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 180, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 40,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe: 'Wird mit den MXRS Senioren ü50 gefahren / separate Punktewertung und Meisterschaft',
        hintEn:
          'Will be raced with the MXRS Seniors ü50 / separate points classification and championship.',
        hintFr: 'Couru avec les seniors MXRS ü50 / classement par points et championnat séparés',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-senioren-u-50',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Senioren ü50',
    commonNameEn: 'Senioren ü50',
    commonNameFr: 'Senioren ü50',
    importName: 'Senioren ü50',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Senioren ü50',
    shortId: 510,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Senioren ü50',
        nameEn: 'Senioren ü50',
        nameFr: 'Senioren ü50',
        shortNameDe: 'Senioren ü50',
        shortNameEn: 'Senioren ü50',
        shortNameFr: 'Senioren ü50',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Senioren ü50',
        startListName: 'Senioren ü50',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 180, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 40,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe: 'Wird mit den MXRS Senioren ü40 gefahren / separate Punktewertung und Meisterschaft',
        hintEn:
          'Will be raced with the MXRS Seniors ü40 / separate points classification and championship.',
        hintFr: 'Couru avec les seniors MXRS ü40 / classement par points et championnat séparés',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-oldtimer-68',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Oldtimer 68',
    commonNameEn: 'Oldtimer 68',
    commonNameFr: 'Oldtimer 68',
    importName: 'Oldtimer 68',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Oldtimer 68',
    shortId: 511,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Oldtimer 68',
        nameEn: 'Oldtimer 68',
        nameFr: 'Oldtimer 68',
        shortNameDe: 'Oldtimer 68',
        shortNameEn: 'Oldtimer 68',
        shortNameFr: 'Oldtimer 68',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Oldtimer 68',
        startListName: 'Oldtimer 68',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 0, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe: 'Tagespodium keine MXRS Meisterschaft / OMC hat eine eigene Meisterschaft',
        hintEn: 'Day podium no MXRS championship / OMC has its own championship',
        hintFr: 'Podium du jour pas de championnat MXRS / OMC a son propre championnat',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-oldtimer-75',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Oldtimer 75',
    commonNameEn: 'Oldtimer 75',
    commonNameFr: 'Oldtimer 75',
    importName: 'Oldtimer 75',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Oldtimer 75',
    shortId: 512,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Oldtimer 75',
        nameEn: 'Oldtimer 75',
        nameFr: 'Oldtimer 75',
        shortNameDe: 'Oldtimer 75',
        shortNameEn: 'Oldtimer 75',
        shortNameFr: 'Oldtimer 75',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Oldtimer 75',
        startListName: 'Oldtimer 75',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 0, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe: 'Tagespodium keine MXRS Meisterschaft / OMC hat eine eigene Meisterschaft',
        hintEn: 'Day podium no MXRS championship / OMC has its own championship',
        hintFr: 'Podium du jour pas de championnat MXRS / OMC a son propre championnat',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-oldtimer-ts',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Oldtimer TS',
    commonNameEn: 'Oldtimer TS',
    commonNameFr: 'Oldtimer TS',
    importName: 'Oldtimer TS',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Oldtimer TS',
    shortId: 513,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Oldtimer TS',
        nameEn: 'Oldtimer TS',
        nameFr: 'Oldtimer TS',
        shortNameDe: 'Oldtimer TS',
        shortNameEn: 'Oldtimer TS',
        shortNameFr: 'Oldtimer TS',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Oldtimer TS',
        startListName: 'Oldtimer TS',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 0, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 70,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 0,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe: 'Tagespodium keine MXRS Meisterschaft / OMC hat eine eigene Meisterschaft',
        hintEn: 'Day podium no MXRS championship / OMC has its own championship',
        hintFr: 'Podium du jour pas de championnat MXRS / OMC a son propre championnat',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 0,
        priceDayLicenseWithoutMemberTwoDays: 0,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-kids-85',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Kids 85',
    commonNameEn: 'Kids 85',
    commonNameFr: 'Kids 85',
    importName: 'Kids 85',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Kids 85',
    shortId: 514,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Kids 85',
        nameEn: 'Kids 85',
        nameFr: 'Kids 85',
        shortNameDe: 'Kids 85',
        shortNameEn: 'Kids 85',
        shortNameFr: 'Kids 85',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Kids 85',
        startListName: 'Kids 85',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 90, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 20,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe:
          'Teils als separate Klasse am Start / Teils mit den Mini 65 am Start jedoch separate Punktewertung',
        hintEn:
          'Partly as a separate class at the start / Partly with the Mini 65 at the start but separate points classification',
        hintFr:
          'En partie comme classe séparée au départ / En partie avec les Mini 65 au départ, mais classement par points séparé',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-mini-65',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Mini 65',
    commonNameEn: 'Mini 65',
    commonNameFr: 'Mini 65',
    importName: 'Mini 65',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Mini 65',
    shortId: 515,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Mini 65',
        nameEn: 'Mini 65',
        nameFr: 'Mini 65',
        shortNameDe: 'Mini 65',
        shortNameEn: 'Mini 65',
        shortNameFr: 'Mini 65',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Mini 65',
        startListName: 'Mini 65',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 90, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 60,
        discountInscriptionWithLicenseTwoDays: 30,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe:
          'Teils als separate Klasse am Start / Teils mit den Mini 85 am Start jedoch separate Punktewertung',
        hintEn:
          'Partly as a separate class at the start / Partly with the Mini 85 at the start but separate points classification',
        hintFr:
          'En partie comme classe séparée au départ / En partie avec les Mini 85 au départ, mais classement par points séparé',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 30,
        priceDayLicenseWithoutMemberTwoDays: 60,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-piccolo-50',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Piccolo 50',
    commonNameEn: 'Piccolo 50',
    commonNameFr: 'Piccolo 50',
    importName: 'Piccolo 50',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Piccolo 50',
    shortId: 516,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Piccolo 50',
        nameEn: 'Piccolo 50',
        nameFr: 'Piccolo 50',
        shortNameDe: 'Piccolo 50',
        shortNameEn: 'Piccolo 50',
        shortNameFr: 'Piccolo 50',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Piccolo 50',
        startListName: 'Piccolo 50',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 90, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 65,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe: 'Möglichkeit bei den Mini 65 als Tagesfahrer zu starten',
        hintEn: 'Possibility to compete in the Mini 65 as a daily driver',
        hintFr: "Possibilité de participer aux Mini 65 en tant que coureur d'un jour",
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'mxrs-2022-motocross-mxrs-women',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'Women',
    commonNameEn: 'Women',
    commonNameFr: 'Women',
    importName: 'Women',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Women',
    shortId: 517,
    associations: ['mxrs'],
    associationSpecificDetails: [
      {
        nameDe: 'Women',
        nameEn: 'Women',
        nameFr: 'Women',
        shortNameDe: 'Women',
        shortNameEn: 'Women',
        shortNameFr: 'Women',
        needsHealthCheck: [{ association: 'mxrs', value: false, licenseType: 'national' }],
        needsInsurance: true,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Women',
        startListName: 'Women',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#f18231',
        numberFixed: true,
        association: 'mxrs',
        prices: [{ association: 'mxrs', value: 180, licenseType: 'national' }],
        priceHintDe: 'vielen Dank für Deine Lizenzbestellung bei uns MXRS',
        priceHintEn: 'Thank you for your license order with us MXRS',
        priceHintFr: "Merci d'avoir commandé une licence chez MXRS.",
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 80,
        discountInscriptionWithLicenseTwoDays: 40,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 0,
        surcharge: 20,
        surchargeAfter: '2022-01-31T22:59:59.999Z',
        discount: 0,
        discountUntil: '2021-01-31T22:59:59.999Z',
        hintDe: 'Startklasse bei der MXRS Open / kann am Renntag auch varieren je nach Anzahl Fahrer',
        hintEn:
          'Starting class at the MXRS Open / can also vary on race day depending on the number of riders',
        hintFr:
          'Classe de départ pour le MXRS Open / peut varier le jour de la course en fonction du nombre de pilotes.',
        advertiseSamInsurance: true,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sjmcc-2022-motocross-u-9',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'U9',
    commonNameEn: 'U9',
    commonNameFr: 'U9',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'ablauf-service.ch U9 Cup',
    shortId: 495,
    associations: ['sjmcc'],
    associationSpecificDetails: [
      {
        nameDe: 'ablauf-service.ch U9 Cup',
        nameEn: 'ablauf-service.ch U9 Cup',
        nameFr: 'ablauf-service.ch U9 Cup',
        shortNameDe: 'U9',
        shortNameEn: 'U9',
        shortNameFr: 'U9',
        needsHealthCheck: [{ association: 'sjmcc', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'ablauf-service.ch U9 Cup',
        startListName: 'ablauf-service.ch U9 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#ffffff',
        numberFixed: true,
        association: 'sjmcc',
        prices: [{ association: 'sjmcc', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-22T22:59:59.999Z',
        hintDe: '50ccm 2-Takt, Automaten / 110ccm 4-Takt, Elektro, 10/12 Zoll',
        hintEn: '50cc 2-stroke, machine / 110cc 4-stroke, electrical, 10/12 inches',
        hintFr: '50cc 2 coups, machine / 110cc à 4 coups, électrique, 10/12 pouces',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 40,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sjmcc-2022-motocross-u-12',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'U12',
    commonNameEn: 'U12',
    commonNameFr: 'U12',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Smart Vision U12 Cup',
    shortId: 496,
    associations: ['sjmcc'],
    associationSpecificDetails: [
      {
        nameDe: 'Smart Vision U12 Cup',
        nameEn: 'Smart Vision U12 Cup',
        nameFr: 'Smart Vision U12 Cup',
        shortNameDe: 'U12',
        shortNameEn: 'U12',
        shortNameFr: 'U12',
        needsHealthCheck: [{ association: 'sjmcc', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Smart Vision U12 Cup',
        startListName: 'Smart Vision U12 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#ffffff',
        numberFixed: true,
        association: 'sjmcc',
        prices: [{ association: 'sjmcc', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-22T22:59:59.999Z',
        hintDe: '65ccm, Getriebe, Elektromotoren, 12/14 Zoll Räde',
        hintEn: '65ccm, gearbox, electric motors, 12/14 inches',
        hintFr: '65ccm, boîte de vitesses, moteurs électriques, 12/14 pouces',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 20,
        priceDayLicenseWithoutMemberTwoDays: 40,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
  {
    id: 'sjmcc-2022-motocross-u-16',
    active: false,
    archived: false,
    sidecar: false,
    sidecarPassenger: false,
    type: 'motocross',
    transponders: ['MyLaps'],
    numberChoice: true,
    commonNameDe: 'U16',
    commonNameEn: 'U16',
    commonNameFr: 'U16',
    importName: '',
    year: 2022,
    enlistWithoutLicense: true,
    startListName: 'Backyard Racing U16 Cup',
    shortId: 497,
    associations: ['sjmcc'],
    associationSpecificDetails: [
      {
        nameDe: 'Backyard Racing U16 Cup',
        nameEn: 'Backyard Racing U16 Cup',
        nameFr: 'Backyard Racing U16 Cup',
        shortNameDe: 'U16',
        shortNameEn: 'U16',
        shortNameFr: 'U16',
        needsHealthCheck: [{ association: 'sjmcc', value: false, licenseType: 'national' }],
        needsInsurance: false,
        needsEmergency: true,
        showAdditionalInsuranceForm: false,
        oneEventLicense: false,
        myLapsName: 'Backyard Racing U16 Cup',
        startListName: 'Backyard Racing U16 Cup',
        myLapsSpecialExportFormatting: false,
        colorBackground: '',
        colorForeground: '',
        colorLicense: '#ffffff',
        numberFixed: true,
        association: 'sjmcc',
        prices: [{ association: 'sjmcc', value: 150, licenseType: 'national' }],
        priceHintDe: '',
        priceHintEn: '',
        priceHintFr: '',
        offersNationalLicense: true,
        offersInternationalLicense: false,
        needsBikeInfo: true,
        priceAdditionalLicense: 0,
        priceInsuranceBookkeeping: 0,
        priceInscriptionWithLicense: 50,
        discountInscriptionWithLicenseTwoDays: 0,
        priceInscriptionPower: 0,
        priceInscriptionSurcharge: 0,
        priceInscriptionHoursBeforeEvent: 144,
        surcharge: 0,
        surchargeAfter: '2022-01-31T23:00:00.000Z',
        discount: 50,
        discountUntil: '2022-01-22T22:59:59.999Z',
        hintDe: '85ccm 2-Takt / 150ccm 4-Takt, Getriebe',
        hintEn: '85cc 2-stroke / 150cc 4-stroke, gearbox',
        hintFr: '85cc 2-temps / 150cc 4-temps, boîte de vitesses',
        advertiseSamInsurance: false,
        needsCommentDe: '',
        needsCommentFr: '',
        needsCommentEn: '',
        priceDayLicenseForMember: 0,
        priceDayLicenseForMemberTwoDays: 0,
        priceDayLicenseWithoutMember: 40,
        priceDayLicenseWithoutMemberTwoDays: 80,
        priceDayLicenseWithOtherLicense: 0,
        priceDayLicenseWithOtherLicenseTwoDays: 0,
      },
    ],
    categoryType: 'licenseCategory',
  },
] as const
