import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  TextField,
} from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import type { FieldProps } from 'formik'
import { sortBy } from 'lodash'
import { useState } from 'react'
import { categoryCommonName } from 'shared/data/categories-service'
import { queryParts } from 'shared/db/search'
import { t } from 'shared/i18n/current'
import { associationByID } from 'shared/models/associations'
import { Category } from 'shared/models/category'
import { toggleArrayElement } from 'shared/utils/array'

export interface CategoriesCheckboxesProps extends FieldProps<string[]> {
  options: {
    categories: Category[]
  }
  label: string
  helperText?: string
}

export function CategoriesCheckboxes(props: CategoriesCheckboxesProps) {
  const [search, setSearch] = useState('')
  const errorText = props.form.errors[props.field.name]
  const categories = sortBy(props.options.categories, categoryName)

  return (
    <>
      <FormLabel component="legend">{props.label}</FormLabel>
      <Box mt={2} mb={1}>
        <TextField
          label={t().licenseForm.searchCategoriesMin3Letters}
          variant="outlined"
          size="small"
          fullWidth
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
      </Box>
      <FormControl error={!!errorText} component="fieldset" variant="outlined">
        <FormGroup>
          {search.length < 3 && (
            <FormHelperText error>{t().licenseForm.typeAtLeast3Letters}</FormHelperText>
          )}
          {categories.map((category) => (
            <CategoryCheckbox key={category.id} {...props} category={category} search={search} />
          ))}
        </FormGroup>
      </FormControl>
    </>
  )
}

interface CategoryCheckboxesProps extends CategoriesCheckboxesProps {
  category: Category
  search: string
}

function CategoryCheckbox(props: CategoryCheckboxesProps) {
  const { category, field, search } = props
  const checked = field.value.includes(category.id)
  const searchMatches = categoryMatchesSearch(search, category)
  const searchTermLongEnough = search.length >= 3

  if (!checked && !(searchTermLongEnough && searchMatches)) return null

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() =>
              props.form.setFieldValue(field.name, toggleArrayElement(field.value, category.id))
            }
            name={category.id}
          />
        }
        label={categoryName(category)}
      />
    </Box>
  )
}

function categoryMatchesSearch(search: string, category: Category) {
  if (!search) return true

  const parts = queryParts(search)
  const categoryString = categoryName(category).toLocaleLowerCase()

  return parts.every((part) => categoryString.includes(part.lower))
}

function categoryName(category: Category): string {
  const associationNames = category.associations.map(associationByID).map(({ name }) => name)
  return `${associationNames.join('/')} - ${categoryCommonName(category)}`
}
