import rawCountries from 'i18n-iso-countries'
import countriesLocaleDe from 'i18n-iso-countries/langs/de.json'
import countriesLocaleEn from 'i18n-iso-countries/langs/en.json'
import countriesLocaleFr from 'i18n-iso-countries/langs/fr.json'
import { currentLocale } from 'shared/i18n/current'

rawCountries.registerLocale(countriesLocaleDe)
rawCountries.registerLocale(countriesLocaleEn)
rawCountries.registerLocale(countriesLocaleFr)

export function countryNames() {
  return Object.values(rawCountries.getNames('de'))
    .map((de) => ({
      id: de,
      code: countryCodeFor(de),
      de,
      en: rawCountries.getName(countryCodeFor(de), 'en'),
      fr: rawCountries.getName(countryCodeFor(de), 'fr'),
    }))
    .map((name) => ({ ...name, name: name[currentLocale()] }))
}

export function countryCodeFor(countryDe: string) {
  return rawCountries.getAlpha2Code(countryDe, 'de') || 'CH'
}
