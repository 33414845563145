import { Box, Button, Grid, LinearProgress, Paper, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useHealthCheck, usePersonalData } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { DeleteButtonWithConfirmation } from 'app/layout/delete-button-with-confirmation'
import { OrderedList } from 'app/layout/ordered-list'
import { MayBeOptionalMessage } from 'app/pages/dashboard/may-be-optional-message'
import { ConfirmUploadButton } from 'app/pages/insurance/confirm-upload-button'
import { generateHealthCheckForm } from 'app/pages/insurance/pdf-health-check-form'
import { DisplayUploadedFile } from 'app/storage/display-uploaded-file'
import { deleteHealthCheck, uploadHealthCheck, useHealthCheckFilePath } from 'app/storage/health-check'
import { UploadPage } from 'app/storage/storage'
import { InvalidUploadMessage } from 'app/upload/invalid-upload-message'
import { UploadButton } from 'app/upload/upload-button'
import { externalLinks } from 'shared/config/externalLinks'
import { nextLicenseYear } from 'shared/data/license-config'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { HealthCheck } from 'shared/models/health-check'
import { migrateUploadStatus, UploadMetadata } from 'shared/models/upload-status'
import { setHealthCheckStatus } from 'shared/upload/upload-service'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'
import { ExternalLink } from 'utils/external-link'
import { PreventDefaultLink } from 'utils/prevent-default-link'

export function EditHealthCheck({ user }: { user: UserQuery }) {
  const history = useHistory()
  const { data, loading, error } = useHealthCheck(user, nextLicenseYear)
  const { needed, healthCheck } = data
  const { data: personalData, loading: pdLoading, error: pdError } = usePersonalData(user)
  const { error: generatePdfError, setError } = useError()

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().healthCheck.title}
        </Typography>
      </Box>

      <MayBeOptionalMessage needed={needed} />

      <Paper elevation={3}>
        <Box p={2}>
          <FriendlyError error={error} />
          <FriendlyError error={generatePdfError} />
          <FriendlyError error={pdError} />
          {(loading || pdLoading) && <LinearProgress />}
          {!loading && !pdLoading && personalData && (
            <>
              <Box my={2}>
                <Typography component="h3" variant="h3">
                  {t().status}: {t().healthCheck.status[healthCheck?.status || 'notUploaded']}
                </Typography>
              </Box>
              <Box my={2}>
                <OrderedList
                  items={[
                    {
                      name: '1a',
                      text: (
                        <ExternalLink href={externalLinks.healthCheckForm.de}>
                          {t().healthCheck.downloadAndPrintOptionA}
                        </ExternalLink>
                      ),
                    },
                    {
                      name: '1b',
                      text: (
                        <ExternalLink href={externalLinks.healthCheckForm.fr}>
                          {t().healthCheck.downloadAndPrintOptionB}
                        </ExternalLink>
                      ),
                    },
                    {
                      name: '1c',
                      text: (
                        <PreventDefaultLink
                          onClick={() =>
                            catchAndLog(setError, () => generateHealthCheckForm(personalData))
                          }
                        >
                          {t().healthCheck.downloadAndPrintOptionC}
                        </PreventDefaultLink>
                      ),
                    },
                    { name: 2, text: t().healthCheck.instructions.fillInByDoctor },
                    { name: 3, text: t().healthCheck.instructions.fillInPersonalDataAndSign },
                    { name: '4a', text: t().provideDocuments.optionATakePictureWithPhone },
                    { name: '4b', text: t().provideDocuments.optionBScanAndUploadOnComputer },
                    { name: 5, text: t().provideDocuments.checkReadabilityAndSignature },
                  ]}
                />
              </Box>
              <Box mb={4}>
                <Typography>{t().provideDocuments.qualityHint}</Typography>
              </Box>
              <UploadHealthCheckPage
                healthCheck={healthCheck}
                metadata={healthCheck?.page1}
                page={1}
                user={user}
                admin={user}
              />
              <UploadHealthCheckPage
                healthCheck={healthCheck}
                metadata={healthCheck?.page2}
                page={2}
                user={user}
                admin={user}
              />
              <UploadHealthCheckPage
                healthCheck={healthCheck}
                metadata={healthCheck?.page3}
                page={3}
                user={user}
                admin={user}
              />
            </>
          )}

          <Grid item xs={12}>
            <Box mt={1}>
              <Button fullWidth onClick={() => history.goBack()}>
                {t().buttons.back}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

interface UploadHealthCheckPageProps {
  healthCheck: HealthCheck | undefined
  metadata: UploadMetadata
  page: number
  user: UserQuery
  admin: UserQuery
}

export function UploadHealthCheckPage(props: UploadHealthCheckPageProps) {
  const { healthCheck, metadata, user, admin, page } = props
  const path = useHealthCheckFilePath({ user, admin, page, metadata })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InvalidUploadMessage upload={migrateUploadStatus(healthCheck)} />
        <UploadButton
          fullWidth
          onUpload={async (files) => {
            const ua = { user, admin }
            if (files.length === 3)
              await Promise.all([
                uploadHealthCheck({ oldMetadata: healthCheck?.page1, file: files[0], page: 1, ...ua }),
                uploadHealthCheck({ oldMetadata: healthCheck?.page2, file: files[1], page: 2, ...ua }),
                uploadHealthCheck({ oldMetadata: healthCheck?.page3, file: files[2], page: 3, ...ua }),
              ])
            else if (files.length === 1)
              await uploadHealthCheck({ oldMetadata: metadata, file: files[0], page, user, admin })
            else if (files.length !== 0)
              throw new Error(`${t().provideDocuments.errorTooManyFilesSelected} ${page}`)
          }}
        >
          {t().healthCheck.page} {page}: {t().healthCheck.takePictureOrUpload}
        </UploadButton>
        <Box py={1}>
          <DeleteHealthCheckUploadButton {...props} />
        </Box>

        <ConfirmUploadButton
          upload={migrateUploadStatus(props.healthCheck)}
          admin={props.admin}
          metadata={props.metadata}
          onUpdateStatus={(statusDetails) => setHealthCheckStatus({ db, user, admin, statusDetails })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DisplayUploadedFile path={path} metadata={metadata} />
      </Grid>
    </Grid>
  )
}

function DeleteHealthCheckUploadButton(props: UploadPage) {
  const { page, metadata } = props
  return (
    <DeleteButtonWithConfirmation
      title={t().healthCheck.deletePage(page)}
      onConfirm={() => deleteHealthCheck(props)}
      disabled={!metadata}
    />
  )
}
