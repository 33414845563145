import { t } from 'shared/i18n/current'
import { CivilStatus } from 'shared/models/civil-status'

export const civilStatusOptions: CivilStatus[] = [
  { id: 'single', name: t().civilStatus.single },
  { id: 'married', name: t().civilStatus.married },
  { id: 'registeredPartnership', name: t().civilStatus.registeredPartnership },
  { id: 'divorced', name: t().civilStatus.divorced },
  { id: 'widowed', name: t().civilStatus.widowed },
  { id: 'other', name: t().civilStatus.other },
]
