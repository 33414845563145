import { Box, TextField } from '@material-ui/core'
import { uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { callRacemanagerApi } from 'app/config/firebase'
import { ButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import {
  assignInscriptionGroups,
  loadNewAssignments,
} from 'app/pages/admin/inscriptions/assignInscriptionGroups'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import {
  DownloadChampionshipEndpoint,
  DownloadChampionshipResult,
  MyLapsName,
} from 'shared/api/interfaces'
import { InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { startListName } from 'shared/sport-events/sport-event-groups'
import { SportEvent } from 'shared/sport-events/sport-events'
import { truthy } from 'shared/utils/array'
import { toNumberIfPossible } from 'shared/utils/number'
import { mapValues } from 'shared/utils/object'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'

interface AssignInscriptionGroupsProps {
  inscriptions: InscriptionWithContextAndSportEvent[]
  sportEvent: SportEvent
}

export function AssignInscriptionGroupsDialog(props: AssignInscriptionGroupsProps) {
  const [assignGroupsLoading, setAssignGroupsLoading] = useState(false)
  const refreshError = useError()
  const { inscriptions, sportEvent } = props
  const association = sportEvent.association
  const myLapsNames = uniq(loadNewAssignments(inscriptions).map(startListName)).sort()
  const joinedMyLapsNames = myLapsNames.join('- split -')
  const championship = useAsync(() => {
    if (!joinedMyLapsNames) return Promise.resolve<DownloadChampionshipResult>({ rankings: {} })
    const myLapsNames = joinedMyLapsNames.split('- split -')
    const params = { association, myLapsNames }
    return callRacemanagerApi<DownloadChampionshipEndpoint>('downloadChampionship', params)
  }, [association, joinedMyLapsNames])

  const [overriddenChampionship, setOverriddenChampionship] = useState<
    Record<MyLapsName, IssuedNumbersString>
  >({})

  const championshipResult = championship.result

  useEffect(() => {
    if (!championshipResult) return
    const newOverriddenChampionship: Record<MyLapsName, IssuedNumbersString> = Object.fromEntries(
      Object.values(championshipResult.rankings).map((ranking) => [
        ranking.myLapsName,
        ranking.ranking.join(' '),
      ])
    )
    setOverriddenChampionship(newOverriddenChampionship)
  }, [championshipResult])

  const parsedChampionships = parseOverriddenChampionships(overriddenChampionship)

  return (
    <>
      {championshipResult &&
        Object.entries(overriddenChampionship).map(([myLapsName, ranking]) => (
          <Box py={2} key={myLapsName}>
            <TextField
              error={!ranking}
              helperText={!ranking && t().groupAssignments.noRankingFound}
              autoFocus
              label={t().groupAssignments.ranking(myLapsName)}
              variant="outlined"
              size="small"
              fullWidth
              value={ranking}
              onChange={(event) =>
                setOverriddenChampionship({
                  ...overriddenChampionship,
                  [myLapsName]: event.target.value.replaceAll('\n', ' '),
                })
              }
              multiline
            />
          </Box>
        ))}
      <LoadingOrErrorBox error={championship.error} loading={championship.loading} />
      <FriendlyError error={refreshError.error} />
      <ButtonWithConfirmation
        title={t().groupAssignments.assignAllGroups}
        fullWidth={false}
        color="primary"
        variant="contained"
        confirmation={t().groupAssignments.validInscriptionNoticeLong}
        tooltip={t().groupAssignments.validInscriptionNoticeShort}
        disabled={
          !parsedChampionships.valid ||
          assignGroupsLoading ||
          championship.loading ||
          !!championship.error
        }
        onConfirm={() =>
          catchAndLog(
            refreshError.setError,
            () => assignInscriptionGroups(inscriptions, sportEvent, parsedChampionships.results),
            setAssignGroupsLoading
          )
        }
      >
        {t().groupAssignments.assignGroups}
      </ButtonWithConfirmation>
    </>
  )
}

function parseOverriddenChampionships(championship: Record<MyLapsName, IssuedNumbersString>) {
  const results = mapValues(championship, (ranking) =>
    ranking
      .split(' ')
      .filter(truthy)
      .map(toNumberIfPossible)
      .map((x) => (typeof x === 'number' ? x : undefined))
  )
  return {
    valid: Object.values(results).every((list) => list.every(truthy)),
    results: mapValues(results, (ranking) => ranking.filter(truthy)),
  }
}

type IssuedNumbersString = string
