import { Box } from '@material-ui/core'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { ShowTransponderData } from 'app/pages/transponder/show-transponder-data'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { Transponder } from 'shared/models/transponder'
import { RoutedButton } from 'utils/buttons/routed-button'

export function TransponderBox({ transponder }: { transponder: Transponder | undefined }) {
  return (
    <ElevatedBox title={t().transponders.title}>
      <Box my={2}>{transponder && <ShowTransponderData transponder={transponder} />}</Box>
      <Box mt={2}>
        <RoutedButton to={routes.editTransponder.to}>
          {transponder ? t().buttons.edit : t().buttons.add}
        </RoutedButton>
      </Box>
    </ElevatedBox>
  )
}
