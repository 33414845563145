import * as YupLocale from 'yup/lib/locale'

/*eslint-disable no-template-curly-in-string*/

const mixed: Required<YupLocale.MixedLocale> = {
  default: '${path} est invalide.',
  required: '${path} est un champ obligatoire',
  oneOf: "${path} doit être l'une des valeurs suivantes: ${values}",
  notOneOf: "${path} ne doit pas être l'une des valeurs suivantes: ${values}",
  notType({ path, type, value, originalValue }) {
    const isCast = originalValue !== null && originalValue !== value
    let msg =
      `${path} doit être un type \`${type}\`, ` +
      `mais la valeur finale était: \`${typeof value}\`` +
      (isCast ? ` (coulée de la valeur \`${typeof originalValue}\`).` : '.')

    if (value === null) {
      msg +=
        `\n Si « null » est conçue comme une valeur vide assurez-vous de marquer le schéma comme` +
        ' `.nullable()`'
    }

    return msg
  },
  defined: '${path} doit être défini',
}

const string: Required<YupLocale.StringLocale> = {
  length: '${path} doit être exactement ${length} caractères',
  min: '${path} doit être au moins ${min} caractères',
  max: '${path} doit être au plus ${max} caractères',
  matches: '${path} doit correspondre à ce qui suit: "${regex}"',
  email: '${path} doit être un email valide',
  url: '${path} doit être une URL valide',
  trim: '${path} doit être une chaîne garnie',
  lowercase: '${path} doit être une chaîne en minuscule',
  uppercase: '${path} doit être une chaîne de majuscules',
  uuid: '${path} doit être une UUID',
}

const number: Required<YupLocale.NumberLocale> = {
  min: '${path} doit être supérieure ou égale à ${min}',
  max: '${path} doit être inférieur ou égal à ${max}',
  lessThan: '${path} doit être inférieure à ${less}',
  moreThan: '${path} doit être supérieure à ${more}',
  positive: '${path} doit être un nombre positif',
  negative: '${path} doit être un nombre négatif',
  integer: '${path} doit être un entier',
}

const date: Required<YupLocale.DateLocale> = {
  min: '${path} champ doit être au plus tard ${min}',
  max: 'champ ${path} doit être au plus tôt ${max}',
}

const object: Required<YupLocale.ObjectLocale> = {
  noUnknown: "champ ${path} ne peut pas avoir des clés non spécifiées dans la forme de l'objet",
}

const array: Required<YupLocale.ArrayLocale> = {
  min: 'champ ${path} doit avoir au moins ${min} articles',
  max: '${path} champ doit avoir inférieur ou égal à ${max} articles',
  length: '${path}-Feld champ doit avoir ${length} article(s)',
}

const boolean: Required<YupLocale.BooleanLocale> = {
  isValue: ({ path, value }) => `${path} doit être ${value ? 'vrai' : 'faux'} sein`,
}

export const yupFR = { mixed, string, number, date, object, array, boolean }
