import { Box } from '@material-ui/core'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { ShowDriversLicense } from 'app/pages/driversLicense/ShowDriversLicense'
import { ShowEmergency } from 'app/pages/emergency/show-emergency'
import { ShowHealthCheck } from 'app/pages/health-check/show-health-check'
import { ShowInsurance } from 'app/pages/insurance/show-insurance'
import { routes } from 'shared/config/routes'
import { Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { RoutedButton } from 'utils/buttons/routed-button'
import { FlexButtons } from 'utils/flex-buttons'

interface DocumentsBoxProps {
  documents: Documents | undefined
  needsHealthCheck: boolean
  needsInsurance: boolean
  needsEmergency: boolean
}

export function DocumentsBox(props: DocumentsBoxProps) {
  const { documents, needsHealthCheck, needsInsurance, needsEmergency } = props
  const emergency = documents?.emergency
  const healthCheck = documents?.healthCheck
  const insurance = documents?.insurance
  const driversLicense = documents?.driversLicense
  const needsDriversLicense = false

  return (
    <ElevatedBox title={t().documentBox.title}>
      <Box my={2}>
        <ShowEmergency emergency={emergency} needed={needsEmergency} />
        <ShowHealthCheck healthCheck={healthCheck} needed={needsHealthCheck} />
        <ShowInsurance insurance={insurance} needed={needsInsurance} />
        <ShowDriversLicense driversLicense={driversLicense} />
      </Box>
      <Box mt={2}>
        <FlexButtons>
          <RoutedButton
            {...buttonHighlightProps(emergency, needsEmergency)}
            to={routes.editEmergency.to}
          >
            {editOrCreateText(t().emergencyDetails.title, emergency, true)}
          </RoutedButton>
          <RoutedButton
            {...buttonHighlightProps(healthCheck, needsHealthCheck)}
            to={routes.editHealthCheck.to}
          >
            {editOrCreateText(t().healthCheck.title, healthCheck, needsHealthCheck)}
          </RoutedButton>
          <RoutedButton
            {...buttonHighlightProps(insurance, needsInsurance)}
            to={routes.editInsurance.to}
          >
            {editOrCreateText(t().documentBox.insuranceConfirmation, insurance, needsInsurance)}
          </RoutedButton>
          <RoutedButton
            {...buttonHighlightProps(driversLicense, needsDriversLicense)}
            to={routes.editDriversLicense.to}
          >
            {editOrCreateText(t().documentBox.driversLicense, driversLicense, needsDriversLicense)}
          </RoutedButton>
        </FlexButtons>
      </Box>
    </ElevatedBox>
  )
}

export function buttonHighlightProps(value: any | undefined, needed: boolean) {
  return value || !needed ? {} : ({ variant: 'contained', color: 'primary' } as const)
}

export function editOrCreateText(objectName: string, object: any | undefined, needed: boolean) {
  return object && needed
    ? t().buttons.editObject(objectName)
    : object && !needed
    ? t().buttons.editObjectOptional(objectName)
    : !object && needed
    ? t().buttons.addObject(objectName)
    : !object && !needed
    ? t().buttons.addObjectOptional(objectName)
    : ''
}
