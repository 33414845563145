import type { I18nType } from 'shared/i18n/i18n-types'

export function serializeError(error: Error) {
  return { ...error, name: error.name, message: error.message, stack: error.stack || '' }
}

export class ErrorWithDetails extends Error {
  public readonly details: any

  constructor(message?: string, options?: ErrorOptions & { details: any }) {
    // @ts-ignore
    super(message, options)
    this.details = Object.freeze(options?.details)
  }
}

interface ErrorOptions {
  cause?: Error
}

export class I18nError extends Error {
  public readonly code: keyof I18nType['errorCodes']

  constructor(message: keyof I18nType['errorCodes'], options?: ErrorOptions) {
    // @ts-ignore
    super(message, options)
    this.code = message
  }
}
