import { Box, TextField } from '@material-ui/core'
import { sortBy } from 'lodash'
import { useAllPaymentsWithDocuments } from 'app/db/db-hooks/main-db-hooks'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { InvalidPaymentsButton } from 'app/pages/admin/payments/invalid-payments-button'
import { UploadBankStatementButton } from 'app/pages/admin/payments/upload-bank-statement-button'
import { useIsAssociationAdmin } from 'app/themes/user-context'
import { DisabledForAssociationAdmin } from 'app/users/disabled-for-association-admin'
import { routes } from 'shared/config/routes'
import { UserQuery } from 'shared/db/db'
import { searchDocuments } from 'shared/db/search'
import { t } from 'shared/i18n/current'
import { nameWithPlace } from 'shared/models/personal-data'
import { pFormatDate } from 'shared/utils/date'
import { toChf } from 'shared/utils/number'
import { TableHeader } from 'shared/utils/table-data'
import { RoutedButton } from 'utils/buttons/routed-button'
import { useSearchQuery } from 'utils/router'

export function Payments({ admin }: { admin: UserQuery }) {
  const search = useSearchQuery()
  const { data, loading, error, loadingOrError } = useAllPaymentsWithDocuments()
  const filtered = searchDocuments(search.q, data)
  const users = sortBy(filtered, (documents) => nameWithPlace(documents.personalData))

  const associationAdmin = useIsAssociationAdmin()
  if (associationAdmin) return <DisabledForAssociationAdmin title="Zahlungen" />

  return (
    <TableBox
      title={t().payments.title}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers,
          // eslint-disable-next-line react/display-name
          contents: users.map((documents) => () => [
            nameWithPlace(documents.personalData),
            documents.personalData?.email || '',
            documents.memberFeesPaidAt || '',
            <>
              {documents.payments.map((payment, index) => (
                <div key={payment.id}>
                  {index > 0 && '\n'}
                  {pFormatDate(payment.date)}: {toChf(payment.amount)}
                </div>
              ))}
            </>,
            <FinancialDialog key="f" admin={admin} user={{ uid: documents.uid }} />,
          ]),
          selected: users.map((documents) =>
            documents.memberFeesPaidAt && documents.payments.length > 0
              ? 'success'
              : documents.memberFeesPaidAt || documents.payments.length > 0
              ? 'warning'
              : ''
          ),
          ids: users.map(({ uid }) => uid),
          rawData: users.map((user) => JSON.stringify(user)),
        }
      }
    >
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <TextField
            label={t().payments.searchPayments}
            variant="outlined"
            size="small"
            fullWidth
            value={search.q}
            onChange={(event) => search.set(event.currentTarget.value)}
          />
        </Box>
        <Box px={1}>
          <UploadBankStatementButton />{' '}
          <RoutedButton to={routes.memberFees.to} variant="outlined">
            {routes.memberFees.text()}
          </RoutedButton>{' '}
          <InvalidPaymentsButton />
        </Box>
      </Box>
    </TableBox>
  )
}

const headers: TableHeader[] = [
  { value: t().user, maxWidth: 400 },
  { value: t().email, maxWidth: 200 },
  { value: 'Mitgliederbeitrag bezahlt', align: 'right' },
  { value: 'Bezahlte QR-Rechnungen', align: 'right' },
  actions(),
]
