import { Typography } from '@material-ui/core'
import { t } from 'shared/i18n/current'
import { HealthCheck, healthCheckOk } from 'shared/models/health-check'

interface ShowHealthCheckProps {
  healthCheck: HealthCheck | undefined
  needed: boolean
}

export function ShowHealthCheck({ healthCheck, needed }: ShowHealthCheckProps) {
  if (!needed && !healthCheckOk(healthCheck)) return null

  return (
    <Typography>
      {t().healthCheck.title}: {t().healthCheck.status[healthCheck?.status || 'notUploaded']}
    </Typography>
  )
}
