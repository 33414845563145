import { ApprovedLicense, Documents } from 'shared/db/db'
import { inscriptionIssuedOrPreferredNumber } from 'shared/inscription/inscription-categories-service'
import { fullName } from 'shared/models/personal-data'
import { EnlistedInscription } from 'shared/sport-events/sport-events'
import { pFormatDateDe } from 'shared/utils/date'
import { downloadPdf } from 'utils/pdf/pdf'

interface EmergencyExportProps {
  documents: Documents
  license: ApprovedLicense | undefined
  inscription?: EnlistedInscription
  categoryName: string
  associationEmail: string
}

export async function exportEmergencyPDFs(contexts: EmergencyExportProps[]) {
  await downloadPdf({ name: 'notfall_emergency' }, (pdf) => {
    contexts.forEach((context, index) => {
      if (index > 0) pdf.addPage()

      const emergency = context.documents.emergency
      const personalData = context.documents.personalData
      if (!personalData) throw new Error('Personal data cannot be empty')
      const issuedNumber = context.inscription
        ? inscriptionIssuedOrPreferredNumber(context.inscription, context.license)
        : context.license?.issuedNumber || '?'
      const categoryName = context.categoryName
      const associationEmail = context.associationEmail

      pdf.fontSize(14)
      pdf.font('Helvetica-Bold')
      pdf.text(`Notfallblatt / Patient Info: ${fullName(personalData)}`)

      pdf.font('Helvetica')
      pdf.fontSize(9)
      pdf.text(
        `Das Notfallblatt enthält besonders schützenswerte Personendaten gemäss Schweizerischem Bundesgesetz über den Datenschutz DSG, 235.1 bzw. dem GDPR. Die Daten sind entsprechend besonders geschützt zu handhaben. Für Fragen kontaktieren Sie ${associationEmail}.`
      )
      pdf.text(
        `This emergency form contains personal data which is exceptionally worth protecting according to the Swiss "Bundesgesetz über den Datenschutz DSG, 235.1", and GDPR respectively. The data needs to be handled accordingly. Contact ${associationEmail} for further questions.`
      )
      pdf.fontSize(11)
      pdf.moveDown(1)

      pdf.text(`Sport Kategorie / Sport category: ${categoryName}`)
      pdf.text(`Sport Startnummer / Sport issued Number: #${issuedNumber}`)
      pdf.moveDown(1)

      pdf.text(`Vorname / First name: ${personalData.firstName}`)
      pdf.text(`Nachname / Last name: ${personalData.lastName}`)
      pdf.text(
        `Geburtsdatum (dd.mm.yyyy) / Birthdate (yyyy-mm-dd): ${pFormatDateDe(
          personalData.birthdate
        )} / ${personalData.birthdate}`
      )
      pdf.text(`Kontakt Patient / Contact patient: ${personalData.email}, ${personalData.phone}`)

      if (personalData.parentsInfo) pdf.text(`Eltern / Parents: ${personalData.parentsInfo}`)

      pdf.moveDown(1)

      pdf.text(`Anschrift / Address:`)
      pdf.text(fullName(personalData))
      pdf.text(personalData.street)
      pdf.text(`${personalData.zip} ${personalData.place}`)
      pdf.moveDown(1)

      pdf.moveDown(1)

      if (emergency) {
        pdf.fontSize(14)
        pdf.font('Helvetica-Bold')
        pdf.text('Information für den Arzt / Information for the doctor')
        pdf.font('Helvetica')
        pdf.fontSize(11)

        if (emergency.infoForDoctor) pdf.text(emergency.infoForDoctor)

        pdf.text(`Allergien / Allergies: ${emergency.allergies || '-'}`)

        if (emergency.bloodGroup && emergency.rhesusFactor)
          pdf.text(`Blutgruppe / Blood group: ${emergency.bloodGroup} ${emergency.rhesusFactor}`)

        pdf.text(`Medikamente / Medication: ${emergency.medication || '-'}`)

        if (emergency.specialInformation)
          pdf.text(`Special Information: ${emergency.specialInformation}`)

        pdf.moveDown(1)

        pdf.fontSize(14)
        pdf.font('Helvetica-Bold')
        pdf.text('Sonstiges / Other')
        pdf.font('Helvetica')
        pdf.fontSize(11)

        pdf.text(
          `Notfallkontakt 1 / Emergency contact 1: ${emergency.contactOnAccident1Name}, ${emergency.contactOnAccident1Phone}`
        )

        pdf.text(
          `Notfallkontakt 2 / Emergency contact 2: ${emergency.contactOnAccident2Name}, ${emergency.contactOnAccident2Phone}`
        )

        pdf.text(`AHV-Nummer / Social security number: ${emergency.ahvNumber}`)

        pdf.text(`Doktor / Doctor: ${emergency.doctorName}, ${emergency.doctorPhone}`)

        pdf.text(`Arbeitgeber / Employer: ${emergency.employerName}, ${emergency.employerPhone}`)

        pdf.text(
          `Unfallversicherung / Accident insurance: ${emergency.accidentInsuranceName}, ${emergency.accidentInsurancePlan}, ${emergency.accidentInsurancePolicyNumber}`
        )

        pdf.text(
          `Krankenkasse / Health insurance: ${emergency.healthInsuranceName}, ${emergency.healthInsurancePlan}, ${emergency.healthInsurancePolicyNumber}`
        )

        if (emergency.hasReturnTransportInsurance)
          pdf.text(
            `Rücktransportversicherung / Transport insurance: ${emergency.returnTransportInsuranceName}`
          )

        pdf.text(`Beruf / Profession: ${emergency.profession}`)
        pdf.text(`Zivilstatus / Civil status: ${emergency.civilStatus}`)
        pdf.text(`Konfession / Confession: ${emergency.confession}`)
      } else {
        pdf.text('Notfallblatt nicht erfasst / No emergency info found')
      }
    })
  })
}
