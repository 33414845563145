import {
  AssociationFilterState,
  DateFilterState,
  OptionalDateFilterState,
} from 'app/pages/admin/financials/date-filter'
import { UserState } from 'app/themes/user-context'
import { BookingRelevantTransactionPure } from 'shared/db/db'
import { AssociationID } from 'shared/models/associations'
import { DateString, isBetweenISO, isSameOrAfterISO, isSameOrBeforeISO } from 'shared/utils/date'

export function filterTransactions<T extends { transaction: BookingRelevantTransactionPure }>(
  data: T[],
  userContext: UserState,
  filter: DateFilterState | OptionalDateFilterState | AssociationFilterState | undefined
) {
  const associationFilter = extractAssociationFilter(userContext, filter)

  return data
    .reverse()
    .filter((value) => (filter ? filterByDate(filter, value) : true))
    .filter((value) => filterTransactionByAssociation(associationFilter, value))
    .reverse()
}

export function extractAssociationFilter(
  userContext: UserState,
  filter: AssociationFilterState | undefined
) {
  return userContext.normalAdmin ? filter?.association || 'all' : userContext.associationAdmin || 'none'
}

export function filterTransactionByAssociation(
  associationFilter: AssociationID | 'all' | 'none',
  value: { transaction: BookingRelevantTransactionPure }
): unknown {
  return associationFilter === 'none'
    ? false
    : associationFilter === 'all'
    ? true
    : transactionAssociation(value.transaction) === associationFilter
}

export function filterByDate(
  filter: OptionalDateFilterState,
  value: { transaction: { date: DateString } }
) {
  if (filter.startsAt && filter.endsAt)
    return isBetweenISO(
      value.transaction.date,
      filter.startsAt.toISOString(),
      filter.endsAt.toISOString()
    )
  if (filter.startsAt) return isSameOrAfterISO(value.transaction.date, filter.startsAt.toISOString())
  if (filter.endsAt) return isSameOrBeforeISO(value.transaction.date, filter.endsAt.toISOString())
  return true
}

function transactionAssociation(t: BookingRelevantTransactionPure) {
  // think about mapping payments to SAM
  // => maybe this should be mapped to racemanager instead?
  // => this would allow to use the same mechanism to transfer money out of racemanager as the other associations
  return t.type === 'manualPayment' || t.type === 'associationPayment'
    ? t.association
    : t.type === 'payment'
    ? 'sam'
    : t.item.association
}
