import { Box } from '@material-ui/core'
import { ShowBikes } from 'app/pages/bike/show-bikes'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { Bike } from 'shared/models/bike'
import { RoutedButton } from 'utils/buttons/routed-button'

export function BikesBox({ bikes }: { bikes: Bike[] }) {
  return (
    <ElevatedBox title={t().bikes}>
      <Box my={2}>
        <ShowBikes bikes={bikes.filter((bike) => bike.status !== 'deleted')} />
      </Box>
      <Box mt={2}>
        <RoutedButton
          to={routes.addBike.to}
          variant={bikes.length === 0 ? 'contained' : 'text'}
          color={bikes.length === 0 ? 'primary' : 'default'}
        >
          {routes.addBike.text()}
        </RoutedButton>
      </Box>
    </ElevatedBox>
  )
}
