import { categoryByIdRequired } from 'shared/data/categories-service'
import { InscriptionWithContextAndSportEvent } from 'shared/db/db'
import {
  isYearCategoryInscription,
  StartingListNameAndDateLookup,
} from 'shared/sport-events/sport-events'

export function startingListNameAndDateLookupForInscription(
  inscription: InscriptionWithContextAndSportEvent
): StartingListNameAndDateLookup {
  return startingListNameAndDateLookup(startListName(inscription), inscription.inscription.date)
}

export function startingListNameAndDateLookup(
  startListName: string,
  date: string | undefined
): StartingListNameAndDateLookup {
  return `${startListName}_${date}`
}

export function startListName(inscription: InscriptionWithContextAndSportEvent) {
  return isYearCategoryInscription(inscription.inscription)
    ? categoryByIdRequired(inscription.inscription.category).startListName
    : inscription.dayCategory?.startListName || ''
}
