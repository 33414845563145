import { ReactNode } from 'react'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'

interface IconButtonWithDetailsProps {
  onConfirm?: () => Promise<void | boolean | undefined> | void
  title: string
  tooltip: string
  disabled?: boolean
  icon: ReactNode
  children: ReactNode
  fullWidth?: boolean
}

export function IconButtonWithDetails(props: IconButtonWithDetailsProps) {
  const { onConfirm, title, tooltip, disabled, icon, children, fullWidth } = props
  const dialog = useDialog()
  return (
    <>
      <IconButtonWithTooltip tooltip={tooltip} disabled={disabled} onClick={() => dialog.open()}>
        {icon}
      </IconButtonWithTooltip>
      <ConfirmDialog title={title} dialog={dialog} onConfirm={onConfirm} fullWidth={!!fullWidth}>
        {children}
      </ConfirmDialog>
    </>
  )
}
