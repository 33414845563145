import { Box, Link, Typography } from '@material-ui/core'
import { CloudDownload, LockOpen, ZoomIn } from '@material-ui/icons'
import { assertNever } from 'assert-never'
import { isAfter, parseISO, startOfYear, subMonths } from 'date-fns'
import { uniq } from 'lodash'
import { db } from 'app/db/frontend-db'
import { IconButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { EditInscriptionBookingButton } from 'app/pages/admin/bookings/edit-inscription-booking'
import { EditLicenseBookingButton } from 'app/pages/admin/bookings/edit-license-booking'
import { EditManualBookingButton } from 'app/pages/admin/bookings/edit-manual-booking'
import { CopyOrNewManualBookingButton } from 'app/pages/admin/bookings/new-manual-booking'
import { EditAssociationPaymentButton } from 'app/pages/admin/financials/association-payment-form'
import { AddManualPaymentForBillButton } from 'app/pages/admin/manual-payments/add-manual-payment-button'
import { EditManualPaymentButton } from 'app/pages/admin/manual-payments/edit-manual-payment-button'
import { generatePDFBill } from 'app/pages/billing/pdf-bill-service'
import { UserState } from 'app/themes/user-context'
import { reopenBill } from 'shared/billing/billing-service'
import {
  deleteInscriptionBooking,
  deleteLicenseBooking,
  deleteManualBooking,
  deletePayment,
  unassignInvalidPayment,
} from 'shared/billing/bookings-service'
import { deleteManualPayment } from 'shared/billing/payment-service'
import { routes } from 'shared/config/routes'
import {
  categoryByIdFromString,
  categoryByIdRequired,
  categoryCommonName,
} from 'shared/data/categories-service'
import {
  AssociationPayment,
  AutomaticPaymentWithoutUid,
  Bill,
  InscriptionBooking,
  ManualBooking,
  ManualPayment,
  NormalLicenseBooking,
  ReverseLicenseBooking,
  Transaction,
  UserQuery,
  WithTotal,
} from 'shared/db/db'
import {
  openRemainingBalance,
  transactionReference,
  transactionStatus,
} from 'shared/db/transactions-service'
import { t } from 'shared/i18n/current'
import { AssociationID, associationName, todoMigrateAssociation } from 'shared/models/associations'
import { Category } from 'shared/models/category'
import { truthy } from 'shared/utils/array'
import { olderThan, pFormatDate, pFormatDateWithSecondsSpaceDe } from 'shared/utils/date'
import { toChf } from 'shared/utils/number'
import { DeleteButtonIcon } from 'utils/buttons/delete-button-icon'
import { CopyToClipboardAbbreviationAutoShort } from 'utils/copy-to-clipboard'
import { catchAndLog } from 'utils/error-boundary'
import { PreventDefaultLink } from 'utils/prevent-default-link'
import { Disp } from 'utils/react'

export function mapTransactionToView(params: MapTransactionParams): TransactionView {
  const transaction = params.transaction

  if (transaction.type === 'bill') return mapBill(transaction, params)
  if (transaction.type === 'manualBooking') return mapManualBooking(transaction, params)
  if (transaction.type === 'licenseBooking' || transaction.type === 'reverseLicenseBooking')
    return mapLicenseBooking(transaction, params)
  if (transaction.type === 'inscriptionBooking') return mapInscriptionBooking(transaction, params)
  if (transaction.type === 'payment') return mapPayment(transaction, params)
  if (transaction.type === 'manualPayment') return mapManualPayment(transaction, params)
  if (transaction.type === 'associationPayment') return mapAssociationPayment(transaction, params)

  assertNever(transaction)
}

function mapBill(transaction: WithTotal<Bill>, params: MapTransactionParams): TransactionView {
  const { userContext, setError, transactions } = params
  const associations = uniq(
    (transaction.items || []).map((item) => todoMigrateAssociation(item.association))
  )
  const canEditBillBasedOnTransactions = associations.some(userContext.canEditAssociation)
  const canViewBill =
    canEditBillBasedOnTransactions ||
    params.userContext.user?.uid === transaction.uid ||
    params.userContext.adminOrAssociationAdmin
  const canEditBillBecauseItsTheLatest =
    isLatestBill(transaction, transactions) && userContext.adminOrAssociationAdmin
  const canEditBill = canEditBillBasedOnTransactions || canEditBillBecauseItsTheLatest

  return {
    ...transactionDates(transaction),
    association: '',
    censored: !canViewBill && !canEditBill,
    id: transaction.reference,
    typeName: t().transactionTypeNames[transaction.type],
    subtypeName: '',
    description: '',
    itemName: '',
    category: null,
    categoryCommonName: null,
    sportEventId: null,
    reference: (
      <PreventDefaultLink
        key="download"
        onClick={() => catchAndLog(setError, () => generatePDFBill(transaction))}
      >
        {transactionReferenceView(transaction)}
      </PreventDefaultLink>
    ),
    rawReference: transactionReference(transaction),
    status: transactionStatus(transaction),
    amount: 0, // this would be wrong, because the bills don't have any transaction relevant value: transaction.paymentInfo.amount
    amountOpen: 0, // this would be wrong, because the bills don't have any transaction relevant value: openRemainingBalance(transaction.remainingBalance),
    remainingBalance: 0, // this would be wrong, because the bills don't have any transaction relevant value: transaction.remainingBalance || 0,
    total: transaction.currentTotal,
    internalRemarks: '',
    actions: (
      <>
        <IconButtonWithTooltip
          tooltip={t().transactions.downloadBill}
          onClick={() => generatePDFBill(transaction)}
        >
          <CloudDownload />
        </IconButtonWithTooltip>
        {canEditBill && youngEnoughToEdit(transaction) && (
          <>
            <AddManualPaymentForBillButton bill={transaction} />
            {canDeleteBill(transaction, params) && (
              <DeleteButtonIcon
                title={t().financials.deleteBill(transaction.title)}
                onConfirm={() => db.deleteBill(transaction)}
              />
            )}
            {canReopenBill(transaction) && <ReopenBillButton bill={transaction} />}
          </>
        )}
      </>
    ),
  }
}

function isLatestBill(transaction: Bill, transactions: WithTotal<Transaction>[]) {
  return transaction === transactions.find((inner) => inner.type === 'bill')
}

function canDeleteBill(transaction: Bill, params: MapTransactionParams) {
  const { admin, userContext } = params
  const canDeleteBillAccordingToStatus =
    transaction.status === 'open' || transaction.status === 'replaced'
  const isFirstBill =
    transaction === params.transactions.find((transaction) => transaction.type === 'bill')
  const replacedByFirstBill = !isFirstBill && canDeleteBillAccordingToStatus
  const recent = !olderThan(transaction.createdAt, { days: 10 })

  return (
    canDeleteBillAccordingToStatus &&
    (userContext.normalAdmin || transaction.byUid === admin.uid || (replacedByFirstBill && recent))
  )
}

function canReopenBill(transaction: Bill) {
  return transaction.status !== 'open'
}

function ReopenBillButton({ bill }: { bill: Bill }) {
  return (
    <IconButtonWithTooltip tooltip={`${t().financials.reopenBill}`} onClick={() => reopenBill(db, bill)}>
      <LockOpen />
    </IconButtonWithTooltip>
  )
}

function mapManualBooking(
  transaction: WithTotal<ManualBooking>,
  params: MapTransactionParams
): TransactionView {
  const { userContext } = params
  const association = transaction.item.association
  const isAdmin = userContext.canEditAssociation(association)
  const canView =
    isAdmin ||
    params.userContext.adminOrAssociationAdmin ||
    params.userContext.user?.uid === transaction.uid
  const category = transaction.categoryId ? categoryByIdRequired(transaction.categoryId) : null
  return {
    ...transactionDates(transaction),
    association,
    censored: !canView,
    id: transaction.id,
    typeName: t().transactionTypeNames[transaction.type],
    subtypeName: transaction.tag || '',
    description: transaction.internalRemarks || '',
    itemName: transaction.item.name,
    category,
    categoryCommonName: categoryCommonName(category),
    sportEventId: transaction.sportEventId || null,
    reference: transactionReferenceView(transaction),
    rawReference: transactionReference(transaction),
    status: transactionStatus(transaction),
    amount: transaction.item.price,
    amountOpen: openRemainingBalance(transaction.remainingBalance),
    remainingBalance: transaction.remainingBalance || 0,
    total: transaction.currentTotal,
    internalRemarks: transaction.internalRemarks || '',
    actions: isAdmin ? (
      <>
        <CopyOrNewManualBookingButton booking={transaction} admin={params.admin} buttonType="copy" />
        {youngEnoughToEdit(transaction) && (
          <>
            <EditManualBookingButton booking={transaction} admin={params.admin} />
            <DeleteButtonIcon
              title={t().financials.deleteManualBooking(transaction.item.name)}
              onConfirm={() => deleteManualBooking(db, transaction, params.admin)}
            />
          </>
        )}
      </>
    ) : (
      ''
    ),
  }
}

function mapLicenseBooking(
  transaction: WithTotal<NormalLicenseBooking> | WithTotal<ReverseLicenseBooking>,
  params: MapTransactionParams
): TransactionView {
  const { userContext } = params
  const association = todoMigrateAssociation(transaction.item.association)
  const isAdmin = userContext.canEditAssociation(association)
  const canView =
    isAdmin ||
    params.userContext.adminOrAssociationAdmin ||
    params.userContext.user?.uid === transaction.uid

  const category =
    transaction.item.type === 'categoryLineItem'
      ? categoryByIdRequired(transaction.item.categoryId)
      : null

  return {
    ...transactionDates(transaction),
    association,
    censored: !canView,
    id: transaction.id || '',
    typeName: t().transactionTypeNames[transaction.type],
    subtypeName: t().lineItemNames[transaction.item.type],
    description: `${transaction.year}`,
    itemName: transaction.item.name,
    category,
    categoryCommonName: categoryCommonName(category),
    sportEventId: null,
    reference: isAdmin ? (
      <Link href={routes.approvedLicenses.generateTo(transaction.uid)}>
        {transactionReferenceView(transaction)}
      </Link>
    ) : (
      transactionReferenceView(transaction)
    ),
    rawReference: transactionReference(transaction),
    status: transactionStatus(transaction),
    amount: transaction.item.price,
    amountOpen: openRemainingBalance(transaction.remainingBalance),
    remainingBalance: transaction.remainingBalance || 0,
    total: transaction.currentTotal,
    internalRemarks: transaction.internalRemarks || '',
    actions:
      transaction && isAdmin && youngEnoughToEdit(transaction) ? (
        <>
          <DeleteButtonIcon
            title={t().financials.deleteLicenseBooking(transaction.item.name)}
            onConfirm={() => deleteLicenseBooking(db, transaction)}
          />
          {userContext.admin && <EditLicenseBookingButton booking={transaction} />}
        </>
      ) : (
        ''
      ),
  }
}

function mapInscriptionBooking(
  transaction: WithTotal<InscriptionBooking>,
  params: MapTransactionParams
): TransactionView {
  const { userContext } = params
  const category = categoryByIdFromString(transaction.item.categoryId)
  const association = todoMigrateAssociation(transaction.item.association)
  const canEditAssociation = userContext.canEditAssociation(association)
  const canViewCategory = category?.associations.some((association) =>
    userContext.canViewAssociation(association)
  )
  const canEditCategory = category?.associations.some((association) =>
    userContext.canEditAssociation(association)
  )
  const canView =
    canEditAssociation ||
    canViewCategory ||
    params.userContext.adminOrAssociationAdmin ||
    params.userContext.user?.uid === transaction.uid
  const canEditInscriptionBooking = canEditAssociation || canEditCategory
  const canDeleteInscriptionBooking = canEditAssociation

  return {
    ...transactionDates(transaction),
    association,
    censored: !canView,
    id: transaction.id || '',
    typeName: t().transactionTypeNames[transaction.type],
    subtypeName: t().lineItemNames[transaction.item.type],
    description: '',
    itemName: transaction.item.name,
    category:
      transaction.item.type === 'inscriptionLineItem' ||
      transaction.item.type === 'powerLineItem' ||
      transaction.item.type === 'inscriptionDiscountLineItem' ||
      transaction.item.type === 'inscriptionDayLicenseLineItem'
        ? categoryByIdRequired(transaction.item.categoryId)
        : null,
    categoryCommonName:
      transaction.item.type === 'inscriptionLineItem' ||
      transaction.item.type === 'powerLineItem' ||
      transaction.item.type === 'inscriptionDiscountLineItem' ||
      transaction.item.type === 'inscriptionDayLicenseLineItem'
        ? categoryCommonName(categoryByIdRequired(transaction.item.categoryId))
        : transaction.item.type === 'inscriptionDayCategoryLineItem'
        ? transaction.item.dayCategoryName
        : null,
    sportEventId: transaction.item.sportEventId,
    reference: canEditAssociation ? (
      <Link href={routes.inscriptions.generateTo(transaction.uid, transaction.item.sportEventId)}>
        {transactionReferenceView(transaction)}
      </Link>
    ) : (
      transactionReferenceView(transaction)
    ),
    rawReference: transactionReference(transaction),
    status: transactionStatus(transaction),
    amount: transaction.item.price,
    amountOpen: openRemainingBalance(transaction.remainingBalance),
    remainingBalance: transaction.remainingBalance || 0,
    total: transaction.currentTotal,
    internalRemarks: transaction.internalRemarks || '',
    actions: youngEnoughToEdit(transaction) ? (
      <>
        {canEditInscriptionBooking && <EditInscriptionBookingButton booking={transaction} />}
        {canDeleteInscriptionBooking && youngEnoughToEdit(transaction) && (
          <DeleteButtonIcon
            title={t().financials.deleteBooking(transaction.item.name)}
            onConfirm={() => deleteInscriptionBooking(db, transaction)}
          />
        )}
      </>
    ) : (
      ''
    ),
  }
}

function mapPayment(
  transaction: WithTotal<AutomaticPaymentWithoutUid>,
  params: MapTransactionParams
): TransactionView {
  const { user, userContext } = params
  const isAdmin = userContext.normalAdmin
  return {
    ...transactionDates(transaction),
    association: '',
    censored: false,
    id: transaction.id || '',
    typeName: t().transactionTypeNames[transaction.type],
    subtypeName: '',
    description: transaction.debitorIban,
    itemName: transaction.reference,
    category: null,
    categoryCommonName: null,
    sportEventId: null,
    reference: transactionReferenceView(transaction),
    rawReference: transactionReference(transaction),
    status: transactionStatus(transaction),
    amount: -transaction.amount,
    amountOpen: 0,
    remainingBalance: transaction.remainingBalance || 0,
    total: transaction.currentTotal,
    internalRemarks: '',
    actions:
      isAdmin && youngEnoughToEdit(transaction) ? (
        <>
          {transaction.originalReference && (
            <>
              <DeleteButtonIcon
                title={t().financials.removeQrPayment(transaction.reference)}
                info={t().financials.qrPaymentNotice}
                onConfirm={() => unassignInvalidPayment(db, user, transaction)}
                tooltip={t().financials.deleteAssignment}
              />
            </>
          )}
          <DeleteButtonIcon
            title={t().financials.deleteQrPayment(transaction.reference)}
            info={t().financials.deletePaymentNotice}
            onConfirm={() => deletePayment(db, user, transaction)}
          />
        </>
      ) : (
        ''
      ),
  }
}

function mapManualPayment(
  transaction: WithTotal<ManualPayment>,
  params: MapTransactionParams
): TransactionView {
  const { userContext } = params
  const association = transaction.association
  const isAdmin = userContext.canEditAssociation(association)
  const paymentTitle = `${t().financials.payment} ${transaction.reference}/${transaction.id}`
  return {
    ...transactionDates(transaction),
    association,
    censored: false,
    id: transaction.id || '',
    typeName: t().transactionTypeNames[transaction.type],
    subtypeName: transaction.tag || '',
    description: transaction.tag || '',
    itemName: `${transaction.reference}/${transaction.id}`,
    category: null,
    categoryCommonName: null,
    sportEventId: null,
    reference: transactionReferenceView(transaction),
    rawReference: transactionReference(transaction),
    status: transactionStatus(transaction),
    amount: -transaction.amount,
    amountOpen: 0,
    remainingBalance: transaction.remainingBalance || 0,
    total: transaction.currentTotal,
    internalRemarks: transaction.internalRemarks || '',
    actions: isAdmin ? (
      <>
        <IconButtonWithConfirmation
          title={paymentTitle}
          tooltip={[t().details, transaction.internalRemarks].filter(truthy).join(': ')}
          confirmation=""
          info={
            <Box>
              <Typography>
                {t().datetime}: {pFormatDateWithSecondsSpaceDe(transaction.date)}
              </Typography>
              <Typography>
                {t().createdAt}: {pFormatDateWithSecondsSpaceDe(transaction.createdAt)}
              </Typography>
              <Typography>
                {t().internalRemarks}: {transaction.internalRemarks}
              </Typography>
              <Typography>
                {t().amount.label}: {toChf(transaction.amount)}
              </Typography>
              <Typography>
                {t().association}: {associationName({ association })}
              </Typography>
              <Typography>
                {t().manualPaymentTag}: {transaction.tag}
              </Typography>
            </Box>
          }
        >
          <ZoomIn />
        </IconButtonWithConfirmation>

        {youngEnoughToEdit(transaction) && (
          <>
            <EditManualPaymentButton payment={transaction} />
            <DeleteButtonIcon
              title={t().financials.deletePayment(paymentTitle)}
              onConfirm={() => deleteManualPayment(db, transaction, userContext.uid || '')}
            />
          </>
        )}
      </>
    ) : (
      ''
    ),
  }
}

function mapAssociationPayment(
  transaction: WithTotal<AssociationPayment>,
  params: MapTransactionParams
): TransactionView {
  const { userContext } = params
  const association = transaction.association
  const isAdmin = userContext.canEditAssociation(association)
  const paymentTitle = `${t().financials.payment} ${transaction.paymentReference}/${transaction.id}`
  return {
    ...transactionDates(transaction),
    association,
    censored: false,
    id: transaction.id || '',
    typeName: t().transactionTypeNames[transaction.type],
    subtypeName: t().associationPayments.states[transaction.status],
    description: [transaction.desiredDate, transaction.paidByUid, transaction.requestedByUid]
      .filter(truthy)
      .join(', '),
    itemName: `${transaction.id}`,
    category: null,
    categoryCommonName: null,
    sportEventId: null,
    reference: transactionReferenceView(transaction),
    rawReference: transactionReference(transaction),
    status: transactionStatus(transaction),
    amount: -transaction.amount,
    amountOpen: 0,
    remainingBalance: transaction.remainingBalance || 0,
    total: transaction.currentTotal,
    internalRemarks: transaction.internalRemarks || '',
    actions: isAdmin ? (
      <>
        <IconButtonWithConfirmation
          title={paymentTitle}
          tooltip={[t().details, transaction.internalRemarks].filter(truthy).join(': ')}
          confirmation=""
          info={
            <Box>
              <Typography>
                {t().datetime}: {pFormatDateWithSecondsSpaceDe(transaction.date)}
              </Typography>
              <Typography>
                {t().createdAt}: {pFormatDateWithSecondsSpaceDe(transaction.createdAt)}
              </Typography>
              <Typography>
                {t().internalRemarks}: {transaction.internalRemarks}
              </Typography>
              <Typography>
                {t().amount.label}: {toChf(transaction.amount)}
              </Typography>
              <Typography>
                {t().association}: {associationName({ association })}
              </Typography>
              {transaction.desiredDate && (
                <Typography>
                  {t().financials.desiredDate}: {transaction.desiredDate}
                </Typography>
              )}
              {transaction.requestedByUid && (
                <Typography>
                  {t().financials.requestedByUid}: {transaction.requestedByUid}
                </Typography>
              )}
              {transaction.paidByUid && (
                <Typography>
                  {t().financials.paidByUid}: {transaction.paidByUid}
                </Typography>
              )}
            </Box>
          }
        >
          <ZoomIn />
        </IconButtonWithConfirmation>
        {youngEnoughToEdit(transaction) && (
          <EditAssociationPaymentButton
            associationPayment={transaction}
            maxAmount={Number.MAX_VALUE}
            buttonText={t().buttons.edit}
            disabled={!userContext.normalAdmin}
          />
        )}
      </>
    ) : (
      ''
    ),
  }
}

function transactionDates(transaction: WithTotal<Transaction>) {
  return {
    date: pFormatDate(transaction.date),
    datetime: pFormatDateWithSecondsSpaceDe(transaction.date),
    datetimeISO: transaction.date,
  }
}

function youngEnoughToEdit(props: { date: string; createdAt?: string; updatedAt?: string }) {
  const { date, createdAt, updatedAt } = props
  return (
    isAfterLastNovember(date) ||
    (createdAt && isAfterLastNovember(createdAt)) ||
    (updatedAt && isAfterLastNovember(updatedAt))
  )
}

function isAfterLastNovember(date: string) {
  const startOfCurrentYear = startOfYear(new Date())
  return isAfter(parseISO(date), subMonths(startOfCurrentYear, 2))
}

function transactionReferenceView(transaction: Transaction) {
  const reference = transactionReference(transaction)
  return <CopyToClipboardAbbreviationAutoShort text={reference} maxTextLength={55} />
}

export interface MapTransactionParams {
  user: UserQuery
  admin: UserQuery
  userContext: UserState
  setError: Disp<Error | null>
  transaction: WithTotal<Transaction>
  transactions: WithTotal<Transaction>[]
}

export interface TransactionView {
  id: string
  date: string
  datetime: string
  datetimeISO: string
  reference: string | JSX.Element
  rawReference: string
  status: string
  amount: number
  amountOpen: number
  remainingBalance: number
  total: number
  actions: string | JSX.Element
  typeName: string
  subtypeName: string
  itemName: string
  description: string
  category: Category | null
  categoryCommonName: string | null
  sportEventId: string | null
  censored: boolean
  association: AssociationID | ''
  internalRemarks: string
}
