import {
  Box,
  CircularProgress,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { ExposureNeg1, ExposurePlus1 } from '@material-ui/icons'
import { sortBy, uniq } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { MaybeFBError } from 'app/db/db-hooks/db-hook-helpers'
import { useSportEventWithInscriptions } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { actions } from 'app/export/table'
import { InscriptionDetailsButton } from 'app/inscription/inscription-details-button'
import { IconButtonWithConfirmationAndErrorHandling } from 'app/layout/button-with-confirmation'
import { ButtonWithTooltip } from 'app/layout/button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { useRacemanagerTitle } from 'app/layouts/route-with-error-boundary-and-title'
import { TableBox } from 'app/layouts/table-box'
import { EditLicenseButton } from 'app/license/edit-license-button'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import {
  deleteInscriptionGroup,
  increaseInscriptionGroup,
  decreaseInscriptionGroup,
  loadNewAssignments,
} from 'app/pages/admin/inscriptions/assignInscriptionGroups'
import { AssignInscriptionGroupsDialog } from 'app/pages/admin/inscriptions/AssignInscriptionGroupsDialog'
import { EmergencyDetailsButton } from 'app/pages/admin/licenses/emergency-button'
import { LicenseAttachmentsButton } from 'app/pages/admin/licenses/license-attachments-button'
import { ImpersonateButton } from 'app/pages/admin/users/user-management'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { AdminContext, useAdminContext } from 'app/themes/admin-context'
import { useIsAdminOrAssociationAdmin } from 'app/themes/user-context'
import { routes } from 'shared/config/routes'
import { categoryByIdRequired, isCategoryId } from 'shared/data/categories-service'
import { DayCategory } from 'shared/db/day-category'
import { InscriptionWithContextAndSportEvent, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import {
  inscriptionCategoryName,
  inscriptionIssuedOrPreferredNumber,
} from 'shared/inscription/inscription-categories-service'
import { unknownDate } from 'shared/models/unknown-date'
import {
  startingListNameAndDateLookup,
  startingListNameAndDateLookupForInscription,
  startListName,
} from 'shared/sport-events/sport-event-groups'
import {
  EnlistedInscription,
  Inscription,
  isEnlistedInscription,
  isInscribedInscription,
  SportEvent,
} from 'shared/sport-events/sport-events'
import { sportEventDates, sportEventDescription } from 'shared/sport-events/sport-events-service'
import { toNumberIfPossible } from 'shared/utils/number'
import { sleep } from 'shared/utils/time'
import { BreadcrumbsList } from 'utils/breadcrumbs'
import { DeleteButtonIcon } from 'utils/buttons/delete-button-icon'
import { RoutedButton } from 'utils/buttons/routed-button'
import { FriendlyError, useError } from 'utils/errors'
import { useErrorSnackbarForError } from 'utils/snackbar'
import { useBoolean } from 'utils/use-boolean'

export function InscriptionGroups({ admin }: { admin: UserQuery }) {
  const isAdmin = useIsAdminOrAssociationAdmin()
  const { sportEventId } = useParams<{ sportEventId: string }>()
  const query = { sportEventId }
  const { data, loading, error, loadingOrError } = useSportEventWithInscriptions({ q: '', ...query })
  const { sportEvent, inscriptions, dayCategories = {} } = data
  const refreshError = useError()
  const title = `${t().routes.inscriptionGroups} ${sportEventDescription(sportEvent)}`
  useRacemanagerTitle(title)
  const assignGroupsDialog = useDialog()

  const startListNamesWithGroups = Object.entries(sportEvent?.categoryGroupCounts || {})
    .filter(([, count]) => count > 1)
    .map(([startListName]) => startListName)

  const inscribedInscriptions = inscriptions
    .filter((inscription) => inscription.status === 'inscribed')
    .filter(
      (inscription) =>
        startListNamesWithGroups.includes(startingListNameAndDateLookupForInscription(inscription)) ||
        inscription.inscription.group
    )

  return (
    <>
      <BreadcrumbsList
        base="adminSportEvents"
        links={[
          [
            routes.inscriptionGroups.generateTo(query.sportEventId),
            routes.inscriptionGroups.textAlt(sportEvent),
          ],
        ]}
      />
      <FriendlyError error={refreshError.error} />

      <ElevatedBox title={title}>
        <Box display="flex" alignItems="center">
          <Box px={1}>
            {sportEvent && (
              <>
                <RoutedButton
                  to={routes.inscriptions.generateTo('', sportEvent.id)}
                  onClick={() => db.setActiveSportEvent(admin, sportEvent.id)}
                >
                  {routes.inscriptions.text()}
                </RoutedButton>
                <RoutedButton
                  to={routes.publicInscriptions.generateTo(sportEvent.id)}
                  onClick={() => db.setActiveSportEvent(admin, sportEvent.id)}
                >
                  {routes.publicInscriptions.text()}
                </RoutedButton>
              </>
            )}
            {isAdmin && sportEvent && (
              <>
                {assignGroupsDialog.isOpen && (
                  <ConfirmDialog
                    fullWidth
                    title={t().groupAssignments.assignGroups}
                    dialog={assignGroupsDialog}
                  >
                    <AssignInscriptionGroupsDialog
                      inscriptions={inscribedInscriptions}
                      sportEvent={sportEvent}
                    />
                  </ConfirmDialog>
                )}
                <ButtonWithTooltip
                  disabled={loadNewAssignments(inscribedInscriptions).length === 0}
                  color="primary"
                  variant="contained"
                  onClick={assignGroupsDialog.open}
                  tooltip={t().groupAssignments.downloadChampionshipStandings}
                >
                  {t().groupAssignments.startGroupAssignment}
                </ButtonWithTooltip>
              </>
            )}
          </Box>
        </Box>
      </ElevatedBox>

      <ElevatedBox title={t().groupAssignments.groupSizeAndStatistics}>
        {sportEvent && (
          <InscriptionGroupStatistics
            sportEvent={sportEvent}
            startListNamesWithGroups={startListNamesWithGroups}
            inscriptions={inscriptions.filter(({ inscription }) => isEnlistedInscription(inscription))}
          />
        )}
      </ElevatedBox>

      {sportEvent && (
        <InscriptionList
          dayCategories={dayCategories}
          loading={loading}
          loadingOrError={loadingOrError}
          error={error}
          sportEvent={sportEvent}
          admin={admin}
          inscribedInscriptions={inscribedInscriptions}
        />
      )}
    </>
  )
}

function canEditCategory(sportEvent: SportEvent, inscription: Inscription, adminContext: AdminContext) {
  return (
    adminContext.matchesAssociation(sportEvent.association) ||
    (isCategoryId(inscription.category) &&
      categoryByIdRequired(inscription.category).associations.some((categoryAssociation) =>
        adminContext.matchesAssociation(categoryAssociation)
      ))
  )
}

function InscriptionGroupStatistics(props: {
  inscriptions: InscriptionWithContextAndSportEvent[]
  sportEvent: SportEvent
  startListNamesWithGroups: string[]
}) {
  const classes = useStyles()
  const { inscriptions, sportEvent } = props
  const sorted = sortBy(inscriptions, startListName)
  const inscribed = sorted.filter((inscription) => inscription.status === 'inscribed')
  const pending = sorted.filter((inscription) => inscription.status === 'inscription-pending')
  const inscribedOrPending = sorted.filter(
    (inscription) => inscription.status === 'inscribed' || inscription.status === 'inscription-pending'
  )
  const inscriptionsWithGroup = inscribedOrPending
    .map(({ inscription }) => inscription)
    .filter((inscription) => inscription.group)

  const startListNames = uniq(inscriptions.map(startListName))

  return (
    <>
      {[...sportEventDates(sportEvent), unknownDate].map((date) => {
        if (startListNames.every((name) => countInscriptions(inscribedOrPending, name, date) === 0))
          return null

        return (
          <Box key={date} mt={2}>
            <Typography variant="h6">{date === unknownDate ? t().categoryDateUnknown : date}</Typography>

            <Grid container spacing={1} className={classes.gridParent}>
              <Grid item xs />
              <Grid item xs>
                <Typography>{t().groupAssignments.totalInscribed}</Typography>
              </Grid>
              <Grid item xs>
                <Typography>{t().groupAssignments.numberOfGroups}</Typography>
              </Grid>
              <Grid item xs>
                <Typography>{t().groupAssignments.configuredGroupSize}</Typography>
              </Grid>
              <Grid item xs>
                <Typography>
                  {t().groupAssignments.largestGroupInTheory}
                  <br />
                  {t().groupAssignments.withConfiguredGroupSize}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography>
                  <DeleteButtonIcon
                    title={t().groupAssignments.deleteGroupAssignments}
                    tooltip={t().groupAssignments.deleteGroupAssignments}
                    onConfirm={() => Promise.all(inscriptionsWithGroup.map(deleteInscriptionGroup))}
                  />
                </Typography>
              </Grid>
            </Grid>

            {startListNames.map((name) => {
              const combinedCount = countInscriptions(inscribedOrPending, name, date)
              const inscribedCount = countInscriptions(inscribed, name, date)
              const pendingCount = countInscriptions(pending, name, date)
              const groupSizeLookup = startingListNameAndDateLookup(name, date || unknownDate)
              const configuredGroupSize = sportEvent.categoryGroupCounts?.[groupSizeLookup] || 1
              const currentWithGroup = filterInscriptions(inscribedOrPending, name, date)
                .map(({ inscription }) => inscription)
                .filter((inscription) => inscription.group)

              return combinedCount === 0 ? null : (
                <Grid key={name} container spacing={1} className={classes.gridParent}>
                  <Grid item xs>
                    <Typography>{name}</Typography>
                  </Grid>
                  <Grid item xs>
                    {combinedCount} ({inscribedCount}/{pendingCount})
                  </Grid>
                  <Grid item xs>
                    <SuggestedGroupAmount count={combinedCount} groupSize={30} />,{' '}
                    <SuggestedGroupAmount count={combinedCount} groupSize={35} />,{' '}
                    <SuggestedGroupAmount count={combinedCount} groupSize={40} />,{' '}
                    <SuggestedGroupAmount count={combinedCount} groupSize={45} />,{' '}
                    <SuggestedGroupAmount count={combinedCount} groupSize={50} />
                  </Grid>
                  <Grid item xs>
                    <EditConfiguredGroupSize
                      lookup={groupSizeLookup}
                      currentSize={configuredGroupSize}
                      sportEvent={sportEvent}
                    />
                  </Grid>
                  <Grid item xs>
                    {Math.ceil(combinedCount / configuredGroupSize)}
                  </Grid>
                  <Grid item xs>
                    <DeleteButtonIcon
                      title={t().groupAssignments.deleteGroupAssignmentOf(name)}
                      tooltip={t().groupAssignments.deleteGroupAssignmentOf(name)}
                      onConfirm={() => Promise.all(currentWithGroup.map(deleteInscriptionGroup))}
                    />
                  </Grid>
                </Grid>
              )
            })}
          </Box>
        )
      })}
    </>
  )
}

function EditConfiguredGroupSize(props: {
  lookup: string
  currentSize: number
  sportEvent: SportEvent
}) {
  const { lookup, currentSize, sportEvent } = props
  const [groupSize, setGroupSize] = useState(`${currentSize}`)
  const saving = useBoolean(false)
  const showError = useErrorSnackbarForError()

  return (
    <TextField
      label={t().groupAssignments.groupSize}
      variant="outlined"
      size="small"
      fullWidth
      value={groupSize}
      onChange={async (e) => {
        const newGroupSizeString = e.target.value
        setGroupSize(newGroupSizeString)
        const newGroupSizeNumber = toNumberIfPossible(newGroupSizeString)
        if (typeof newGroupSizeNumber === 'number') {
          saving.setTrue()
          try {
            await updateGroupSizeInDB(sportEvent, lookup, newGroupSizeNumber)
            await sleep(500)
          } catch (error) {
            showError(error)
          }
          saving.setFalse()
        }
      }}
      InputProps={
        saving.value
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CircularProgress size={'1rem'} />
                </InputAdornment>
              ),
            }
          : undefined
      }
    />
  )
}

async function updateGroupSizeInDB(sportEvent: SportEvent, lookup: string, newGroupSizeNumber: number) {
  await db.updateSportEventGroupCount(sportEvent, lookup, newGroupSizeNumber)
}

function SuggestedGroupAmount({ count, groupSize }: { count: number; groupSize: number }) {
  return (
    <>
      {groupSize}: {Math.ceil(count / groupSize)}
    </>
  )
}

function countInscriptions(
  inscriptions: InscriptionWithContextAndSportEvent[],
  name: string,
  date: string | undefined
) {
  return filterInscriptions(inscriptions, name, date).length
}

function filterInscriptions(
  inscriptions: InscriptionWithContextAndSportEvent[],
  name: string,
  date: string | undefined
) {
  return inscriptions.filter(
    (inscription) => startListName(inscription) === name && inscription.inscription.date === date
  )
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

function InscriptionList(props: {
  loading: boolean
  loadingOrError: boolean
  error: MaybeFBError
  sportEvent: SportEvent
  admin: UserQuery
  dayCategories: Record<string, DayCategory>
  inscribedInscriptions: InscriptionWithContextAndSportEvent[]
}) {
  const adminContext = useAdminContext()
  const { loading, error, inscribedInscriptions, admin, sportEvent, loadingOrError, dayCategories } =
    props
  return (
    <TableBox
      title={t().groupAssignments.listOfInscriptionsAndGroups}
      loading={loading}
      error={error}
      data={
        sportEvent &&
        !loadingOrError && {
          headers: [
            { value: t().issuedNumber.labelAlternative, align: 'right' },
            { value: t().lastName },
            { value: t().firstName },
            { value: t().category, maxWidth: 300 },
            { value: t().inscription.group, maxWidth: 300 },
            actions(),
          ],
          // eslint-disable-next-line react/display-name
          contents: inscribedInscriptions.map((inscriptionWithContext) => () => {
            const { licenseWithContext, inscription, status, documents } = inscriptionWithContext
            const license = licenseWithContext?.license
            const dayCategory = dayCategories[inscription.category]

            return [
              inscriptionIssuedOrPreferredNumber(inscription, license?.approved),
              documents.personalData?.lastName || '',
              documents.personalData?.firstName || '',
              inscriptionCategoryName(inscription, dayCategory),
              (isInscribedInscription(inscription) && (
                <DisplayInscriptionGroup inscription={inscription} />
              )) ||
                '-',
              <>
                <FinancialDialog admin={admin} user={{ uid: inscription.uid }} />
                {licenseWithContext && (
                  <EditLicenseButton licenseWithContext={licenseWithContext} admin={admin} />
                )}
                <LicenseAttachmentsButton
                  user={{ uid: inscription.uid }}
                  documents={documents}
                  admin={admin}
                />
                <EmergencyDetailsButton user={{ uid: inscription.uid }} admin={admin} />
                <ImpersonateButton user={{ uid: inscription.uid }} />
                <InscriptionDetailsButton
                  disabled={!canEditCategory(sportEvent, inscription, adminContext)}
                  sportEvent={sportEvent}
                  inscription={inscriptionWithContext}
                  status={status}
                />
              </>,
            ]
          }),
          selected: inscribedInscriptions.map(({ inscription }) =>
            inscription.group ? 'success' : 'warning'
          ),
          ids: inscribedInscriptions.map(
            (row) =>
              `${row.inscription.sportEvent}|${row.inscription.category}|${row.inscription.uid}|${row.inscription.date}`
          ),
          rawData: inscribedInscriptions.map((row) =>
            JSON.stringify({ ...row, sportEvent, dayCategories })
          ),
        }
      }
    />
  )
}

function DisplayInscriptionGroup({ inscription }: { inscription: EnlistedInscription }) {
  return inscription.group ? (
    <>
      {inscription.group}{' '}
      <DeleteButtonIcon title={t().delete} onConfirm={() => deleteInscriptionGroup(inscription)} />
      <IconButtonWithConfirmationAndErrorHandling
        tooltip={t().groupAssignments.moveToHigherGroup}
        confirmation={t().groupAssignments.confirmMoveToHigherGroup}
        title={t().groupAssignments.moveToHigherGroup}
        onConfirm={() => increaseInscriptionGroup(inscription)}
      >
        <ExposurePlus1 />
      </IconButtonWithConfirmationAndErrorHandling>
      <IconButtonWithConfirmationAndErrorHandling
        tooltip={t().groupAssignments.moveToLowerGroup}
        confirmation={t().groupAssignments.confirmMoveToLowerGroup}
        title={t().groupAssignments.moveToLowerGroup}
        onConfirm={() => decreaseInscriptionGroup(inscription)}
        disabled={inscription.group === 1}
      >
        <ExposureNeg1 />
      </IconButtonWithConfirmationAndErrorHandling>
    </>
  ) : (
    <>-</>
  )
}

t()
