import { Recipient } from 'shared/api/emails'
import { categories, categoryCommonName } from 'shared/data/categories-service'
import { currentLocale } from 'shared/i18n/current'
import { translateCategoryType } from 'shared/i18n/i18n-helpers'
import { categoryTypes } from 'shared/models/category'

export function recepientOptions(): { id: Recipient; name: string }[] {
  return [
    { id: 'all', name: 'Alle' },
    ...categoryTypes.map((id) => ({ id, name: translateCategoryType(id) })),
    ...categories(currentLocale()).map((category) => ({
      id: category.id,
      name: categoryCommonName(category),
    })),
  ]
}
