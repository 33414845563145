import { Box, MenuItem, Paper, Select, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import assertNever from 'assert-never'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EnduroBikeForm } from 'app/bike/EnduroBikeForm'
import { MotocrossBikeForm } from 'app/bike/MotocrossBikeForm'
import { db } from 'app/db/frontend-db'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { Bike } from 'shared/models/bike'
import { FriendlyError, useError } from 'utils/errors'
import { useSuccessSnackbar, useErrorSnackbar } from 'utils/snackbar'

export function AddBike({ user }: { user: User }) {
  const history = useHistory()
  const { error, setError } = useError()
  const showSuccessMessage = useSuccessSnackbar()
  const showErrorMessage = useErrorSnackbar()
  const [bikeType, setBikeType] = useState<'' | 'motocross' | 'enduro'>('')

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().licenseForm.steps.bikes.title}
        </Typography>
      </Box>

      {showAlert && (
        <Box pb={3}>
          <Alert severity="info" variant="outlined">
            <AlertTitle>{t().licenseForm.steps.bikes.alertTitleOtherCategoryTypes}</AlertTitle>
            {t().licenseForm.steps.bikes.alertBodyOtherCategoryTypes}
          </Alert>
        </Box>
      )}

      <Box pb={2}>
        <Paper elevation={3}>
          <Box p={2}>
            <Select
              fullWidth
              value={bikeType}
              variant="outlined"
              onChange={(e) => setBikeType(e.target.value as any)}
            >
              <MenuItem value="motocross">Motocross</MenuItem>
              <MenuItem value="enduro">Enduro</MenuItem>
            </Select>
          </Box>
        </Paper>
      </Box>

      {bikeType && (
        <Paper elevation={3}>
          <Box p={2}>
            <FriendlyError error={error} />
            {bikeType === 'motocross' ? (
              <MotocrossBikeForm
                onSubmit={(bike) =>
                  submitBike({ setError, user, bike, history, showSuccessMessage, showErrorMessage })
                }
                onBack={() => history.goBack()}
                onCancel={() => history.goBack()}
                nextLabel={t().licenseForm.steps.bikes.saveBike}
              />
            ) : bikeType === 'enduro' ? (
              <EnduroBikeForm
                onSubmit={(bike) =>
                  submitBike({ setError, user, bike, history, showSuccessMessage, showErrorMessage })
                }
                onBack={() => history.goBack()}
                onCancel={() => history.goBack()}
                nextLabel={t().licenseForm.steps.bikes.saveBike}
              />
            ) : (
              assertNever(bikeType)
            )}
          </Box>
        </Paper>
      )}
    </>
  )
}

async function submitBike(props: {
  setError: (error: Error | null) => void
  user: User
  bike: Bike
  showSuccessMessage: (message: string) => void
  history: ReturnType<typeof useHistory>
  showErrorMessage: (message: string) => void
}) {
  const { setError, user, bike, showSuccessMessage, history, showErrorMessage } = props
  try {
    setError(null)
    await db.pushBike(user, bike)
    showSuccessMessage(t().licenseForm.steps.bikes.bikeAdded)
    history.push(routes.userDashboard.to)
  } catch (error: any) {
    setError(error)
    showErrorMessage(t().licenseForm.steps.bikes.bikeNotSaved)
  }
}

const showAlert = false
