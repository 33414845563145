import { uniq } from 'lodash'
import { DayCategory } from 'shared/db/day-category'
import { t } from 'shared/i18n/current'
import { translateCategoryType } from 'shared/i18n/i18n-helpers'
import { associationNameWithDefault } from 'shared/models/associations'
import { Category } from 'shared/models/category'
import { InscriptionGroup, PublicInscription, SportEvent } from 'shared/sport-events/sport-events'
import { sportEventInscriptionDayOrEventDateShort } from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'

export function startingNumber(inscription: PublicInscription): string | number {
  return inscription.startingNumber === -1 ? '-' : inscription.startingNumber
}

export function publicInscriptionTitle(props: {
  showStartingListTitle?: boolean
  sportEvent: SportEvent
  categories: (Category | DayCategory)[]
  date: string
  group: InscriptionGroup
  hasGroups: boolean
}) {
  const { showStartingListTitle, sportEvent, categories, date, group, hasGroups } = props
  const startListTitle = showStartingListTitle ? `${t().startList} ` : ''
  const sportEventName = ''

  return `${startListTitle}${categoryName(
    categories
  )} ${sportEventName}(${sportEventInscriptionDayOrEventDateShort(
    sportEvent,
    date
  )})${inscriptionGroupNameWithDash({ group, hasGroups })}`
}

function categoryName(categories: (Category | DayCategory)[]) {
  const category = categories[0]
  const associations = uniq(
    categories.flatMap((category) =>
      category.categoryType === 'licenseCategory' ? category.associations : []
    )
  )
    .map(associationNameWithDefault)
    .sort()
    .join('/')

  return [
    associations,
    category.categoryType === 'licenseCategory' ? translateCategoryType(category.type) : '',
    category.categoryType === 'licenseCategory'
      ? category.startListName
      : category.startListName || category.myLapsName || category.name,
  ]
    .filter(truthy)
    .join(' - ')
}

export function inscriptionGroupNameWithDash(props: { group: InscriptionGroup; hasGroups: boolean }) {
  return props.hasGroups ? ` - ${inscriptionGroupName(props)}` : ''
}

export function inscriptionGroupName(props: { group: InscriptionGroup; hasGroups: boolean }) {
  return props.hasGroups ? `${t().inscription.group} ${inscriptionGroupLetter(props)}` : ''
}

function inscriptionGroupLetter(props: { group: InscriptionGroup; hasGroups: boolean }) {
  const { group, hasGroups } = props
  return hasGroups ? `${group ? groupNumberToLetter(group) : t().unknown}` : ''
}

export function groupNumberToLetter(group: number): string {
  return String.fromCharCode(64 + group)
}
