import { Box, Button } from '@material-ui/core'
import type { ButtonProps } from '@material-ui/core'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FriendlyError, useError } from 'utils/errors'
import { Loading } from 'utils/loading'

interface TextUploadButtonProps extends ButtonProps {
  onUpload: (
    fileContents: {
      text: string
      filename: string
    }[]
  ) => Promise<void>
}

export function TextUploadButton(props: TextUploadButtonProps) {
  return (
    <UploadButton
      {...props}
      onUpload={async (files) => {
        const fileContents = await Promise.all(
          files.map(async (file) => ({ text: await file.text(), filename: file.name }))
        )
        await props.onUpload(fileContents)
      }}
    />
  )
}

interface ArrayBufferUploadButtonProps extends ButtonProps {
  onUpload: (
    fileContents: {
      buffer: ArrayBuffer
      filename: string
    }[]
  ) => Promise<void>
}

export function ArrayBufferUploadButton(props: ArrayBufferUploadButtonProps) {
  return (
    <UploadButton
      {...props}
      onUpload={async (files) => {
        const fileContents = await Promise.all(
          files.map(async (file) => ({ buffer: await file.arrayBuffer(), filename: file.name }))
        )
        await props.onUpload(fileContents)
      }}
    />
  )
}

interface UploadButtonProps extends ButtonProps {
  onUpload: (files: File[]) => Promise<void>
}

export function UploadButton(props: UploadButtonProps) {
  const [uploading, setUploading] = useState(false)
  const [id] = useState(uuidv4())
  const { error, setError } = useError()
  const { onUpload, ...buttonProps } = props

  return (
    <>
      <Loading loading={uploading} />
      {error && (
        <Box my={1}>
          <FriendlyError error={error} />
        </Box>
      )}
      <input
        style={{ display: 'none' }}
        id={id}
        type="file"
        name="file"
        multiple
        onChange={async (e) => {
          try {
            setError(null)
            setUploading(true)

            const files = extractFiles(e.target.files)
            if (files.length === 0) return

            const filesArray = await Promise.all([...files])
            await onUpload(filesArray)
          } catch (error: any) {
            setError(error)
          }
          setUploading(false)
        }}
      />
      <label htmlFor={id}>
        <Button variant="outlined" component="span" disabled={uploading} {...buttonProps} />
      </label>
    </>
  )
}

function extractFiles(fileList: FileList | null) {
  return fileList ? [...fileList] : []
}
