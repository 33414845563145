import { Box, Button } from '@material-ui/core'
import {
  DownloadLicenseConfirmationProps,
  generateLicenseConfirmation,
} from 'app/pages/licenses/pdf-license-confirmation-service'
import { t } from 'shared/i18n/current'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'

export function DownloadLicenseConfirmation(props: DownloadLicenseConfirmationProps) {
  const { error, setError } = useError()

  return (
    <Box>
      <FriendlyError error={error} />
      <Button onClick={() => catchAndLog(setError, () => generateLicenseConfirmation(props))}>
        {t().licensesBoxes.licenseConfirmation}
      </Button>
    </Box>
  )
}
