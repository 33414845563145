import { useSnackbar } from 'notistack'
import type { OptionsObject, SnackbarMessage } from 'notistack'
import { t } from 'shared/i18n/current'
import { I18nError } from 'shared/utils/errors'

export function useDismissingSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  return (message: SnackbarMessage, options?: OptionsObject) => {
    closeSnackbar()
    return enqueueSnackbar(message, options)
  }
}

export function useCatchAndDisplayErrorOrSuccess(successMessage = t().successful) {
  const showErrorOrSuccess = useCatchAndDisplayErrorOrSuccessFn(successMessage)
  return (promise: Promise<any> | void) => showErrorOrSuccess(() => promise)
}

export function useCatchAndDisplayErrorOrSuccessFn(successMessage = t().successful) {
  const showError = useErrorSnackbarForError()
  const showSuccess = useSuccessSnackbar()
  return async (fn: () => Promise<any> | void) => {
    try {
      await fn()
      if (successMessage) showSuccess(successMessage)
    } catch (error) {
      showError(error)
    }
  }
}

export function useSuccessSnackbar() {
  const { enqueueSnackbar } = useSnackbar()
  return (message: SnackbarMessage, options: OptionsObject = {}) => {
    return enqueueSnackbar(message, { ...options, variant: 'success' })
  }
}

export function useWarningSnackbar() {
  const { enqueueSnackbar } = useSnackbar()
  return (message: SnackbarMessage, options: OptionsObject = {}) => {
    return enqueueSnackbar(message, { ...options, variant: 'warning' })
  }
}

export function useErrorSnackbarForError() {
  const showError = useErrorSnackbar()
  return (error: unknown, options: OptionsObject = {}) => {
    if (error instanceof I18nError) return showError(t().errorCodes[error.code], options)
    if (error instanceof Error) return showError(error.message, options)
    else {
      console.error(error)
      return showError(t().anUnknownErrorOccurred, options)
    }
  }
}

export function useErrorSnackbar() {
  const { enqueueSnackbar } = useSnackbar()
  return (message: SnackbarMessage, options: OptionsObject = {}) =>
    enqueueSnackbar(message, { ...options, variant: 'error' })
}

export type SnackbarMessageFn = ReturnType<typeof useErrorSnackbar>
