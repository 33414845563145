import { Box, Paper, Typography } from '@material-ui/core'
import { CircularProgressWithLabel, LicenseTaskList } from 'app/pages/dashboard/overview-box'
import { DownloadLicenseConfirmation } from 'app/pages/licenses/download-license-confirmation'
import { routes } from 'shared/config/routes'
import { categoryOfAssociationRequired, fullCategoryShortName } from 'shared/data/categories-service'
import { nextLicenseYear, oldLicenseYear } from 'shared/data/license-config'
import { Documents, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { openDraftLicenseInscriptionTasks } from 'shared/license/license-tasks'
import { CategoryDetailsWithCategory, DraftLicense } from 'shared/models/category-details'
import { PersonalData } from 'shared/models/personal-data'
import { RoutedButton } from 'utils/buttons/routed-button'

interface DraftLicensesBoxProps {
  user: UserQuery
  personalData: PersonalData | undefined
  processedLicenses: boolean | undefined
  draftLicenses: CategoryDetailsWithCategory[]
  documents: Documents
}

export function DraftLicensesBox(props: DraftLicensesBoxProps) {
  const { user, processedLicenses, draftLicenses, documents } = props
  return (
    <Paper elevation={3}>
      <Box p={2} mb={2}>
        <Typography component="h3" variant="h3">
          {t().licensesBoxes.draftLicensesTitle}
        </Typography>

        {draftLicenses.map((draftLicense) => {
          return (
            <Box key={draftLicense.category.id} my={2}>
              <Paper variant="outlined" elevation={3}>
                <DraftLicenseBox draftLicense={draftLicense} user={user} documents={documents} />
              </Paper>
            </Box>
          )
        })}

        <Box mt={2}>
          {processedLicenses ? (
            <>
              <RoutedButton to={routes.newLicense.generateTo(nextLicenseYear)}>
                {t().licensesBoxes.requestAdditionalLicense(nextLicenseYear)}
              </RoutedButton>
              {new Date().getFullYear() === oldLicenseYear && (
                <RoutedButton to={routes.newLicense.generateTo(oldLicenseYear)}>
                  {t().licensesBoxes.requestAdditionalLicense(oldLicenseYear)}
                </RoutedButton>
              )}
            </>
          ) : (
            <>
              <RoutedButton
                variant="contained"
                color="primary"
                to={routes.newLicense.generateTo(nextLicenseYear)}
              >
                {routes.newLicense.textForYear(nextLicenseYear)}
              </RoutedButton>{' '}
              {new Date().getFullYear() === oldLicenseYear && (
                <RoutedButton to={routes.newLicense.generateTo(oldLicenseYear)}>
                  {routes.newLicense.textForYear(oldLicenseYear)}
                </RoutedButton>
              )}
            </>
          )}
        </Box>
      </Box>
    </Paper>
  )
}

interface DraftLicenseBoxProps {
  draftLicense: DraftLicense
  user: UserQuery
  documents: Documents
}

function DraftLicenseBox({ user, draftLicense, documents }: DraftLicenseBoxProps) {
  const { category, preferredNumber, licenseAssociation } = draftLicense
  const categoryOfAssociation = categoryOfAssociationRequired(category.id, licenseAssociation)
  const licenseTasks = openDraftLicenseInscriptionTasks({
    category: categoryOfAssociation,
    documents,
    categoryDetails: draftLicense,
  })

  return (
    <Box p={2}>
      <Box display="flex">
        <Box mr={4}>
          <CircularProgressWithLabel
            invalidated={false}
            size="sm"
            done={licenseTasks.tasksDone}
            total={licenseTasks.tasksTotal}
          />
        </Box>
        <Box flexGrow={1} alignSelf="center">
          <Typography component="h4" variant="h6">
            {fullCategoryShortName(categoryOfAssociation)}
          </Typography>
          {categoryOfAssociation.numberChoice && (
            <Typography>
              {t().licensesBoxes.preferredNumber}: {preferredNumber}
            </Typography>
          )}
          <DownloadLicenseConfirmation category={categoryOfAssociation} uid={user.uid} />
        </Box>
      </Box>
      {!licenseTasks.allDone && <LicenseTaskList size="sm" tasks={licenseTasks} />}
    </Box>
  )
}
