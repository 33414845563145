import {
  Policy,
  ListAlt,
  SettingsRemote,
  LocalAtm,
  AttachMoney,
  PieChart,
  AssignmentInd,
  Event,
  People,
  CloudUpload,
  PlaylistAddCheck,
  Email,
  ImportContacts,
  Dashboard,
  MenuBook,
  ViewList,
  FiberNew,
  AccountBalanceWallet,
  FormatListNumbered,
} from '@material-ui/icons'
import { addDays, parseISO, subDays } from 'date-fns'
import { allowedQRDomains } from 'shared/config/domain'
import { categoryByIdRequired, categoryHack } from 'shared/data/categories-service'
import { Year } from 'shared/data/license-year'
import { DayCategory } from 'shared/db/day-category'
import { ApprovedLicense, DB } from 'shared/db/db'
import { SportEventId } from 'shared/db/sport-event-id'
import { t, todoT } from 'shared/i18n/current'
import { Category, CategoryId } from 'shared/models/category'
import { unknownDate } from 'shared/models/unknown-date'
import { SportEvent } from 'shared/sport-events/sport-events'
import { uidOrShortUid } from 'shared/users/users-service'
import { pDateWeekdayName } from 'shared/utils/date'

export const routes = {
  userOrAdminDashboard: { to: '/', text: () => 'Dashboard', icon: Dashboard },
  userDashboard: {
    to: '/dashboard/user',
    text: () => t().routes.dashboard,
    textAlt: () => t().routes.userDashboard,
    icon: Dashboard,
  },
  adminDashboard: {
    to: '/dashboard/admin',
    text: () => t().routes.adminDashboard,
    textAlt: () => t().routes.userDashboard,
    icon: Policy,
  },
  allTransactions: {
    to: '/admin/all-transactions',
    text: () => t().routes.allTransactions,
    dashboard: () => t().routes.dashboardAllTransactions,
    icon: ViewList,
  },
  manualPayments: {
    generateToFromSportEvent: (sportEvent: SportEvent) =>
      `/admin/manual-payments?from=${subDays(
        parseISO(sportEvent.startsAt),
        1
      ).toISOString()}&to=${addDays(parseISO(sportEvent.endsAt), 1).toISOString()}`,
    to: '/admin/manual-payments',
    text: () => t().routes.manualPayments,
    icon: ViewList,
  },
  approvedLicenses: {
    generateTo: (query: string) => `${routes.approvedLicenses.to}?q=${encodeURIComponent(query)}`,
    to: '/admin/approved-licenses',
    text: () => t().routes.approvedLicenses,
    icon: PlaylistAddCheck,
  },
  assignLicense: {
    generateTo: (query: string) => `${routes.assignLicense.to}?q=${encodeURIComponent(query)}`,
    to: '/admin/assign-license',
    text: () => t().routes.assignLicense,
    icon: FiberNew,
  },
  userFinancials: {
    generateTo: (query: string) => `${routes.userFinancials.to}?q=${encodeURIComponent(query)}`,
    to: '/admin/user-financials',
    text: () => t().routes.userFinancialsRiders,
    dashboard: () => t().routes.userFinancials,
    icon: AccountBalanceWallet,
  },
  licenseDrafts: {
    generateTo: (query: string) => `${routes.licenseDrafts.to}?q=${encodeURIComponent(query)}`,
    to: '/admin/license-drafts',
    text: () => t().routes.licenseDrafts,
    icon: ListAlt,
  },
  newTransponders: {
    to: '/admin/new-transponders',
    text: () => t().routes.newTransponders,
    icon: SettingsRemote,
  },
  licenseBookingsByRider: {
    generateTo: (query: string) => `${routes.licenseBookingsByRider.to}?q=${encodeURIComponent(query)}`,
    to: '/admin/license-bookings-by-rider',
    text: () => t().routes.licenseBookingsByRider,
    icon: LocalAtm,
  },
  allLicenseBookings: {
    generateTo: (query: string) => `${routes.allLicenseBookings.to}?q=${encodeURIComponent(query)}`,
    to: '/admin/all-license-bookings',
    text: () => t().routes.allLicenseBookings,
    dashboard: () => t().routes.allLicenses,
    icon: LocalAtm,
  },
  insurances: {
    to: '/admin/insurances',
    text: () => t().routes.insurances,
    icon: ViewList,
  },
  payments: {
    generateTo: (query: string) => `${routes.payments.to}?q=${encodeURIComponent(query)}`,
    to: '/admin/payments',
    text: () => t().routes.payments,
    icon: AttachMoney,
  },
  memberFees: {
    to: '/admin/member-fees',
    text: () => t().routes.memberFees,
    icon: AttachMoney,
  },
  adminSportEvents: { to: '/admin/events', text: () => `${t().sportEvents} Admin`, icon: Event },
  invalidPayments: { to: '/admin/invalid-payments', text: () => 'Fehlerhafte Zahlungen' },
  manualBookings: {
    to: '/admin/manual-bookings',
    text: () => t().routes.manualBookingsAndCharges,
    dashboard: () => t().routes.manualBookings,
    icon: AttachMoney,
  },
  categoriesKeyFigures: {
    to: '/admin/categories-key-figures',
    text: () => t().routes.categoriesKeyFigures,
    icon: PieChart,
  },
  associationFinancials: {
    to: '/admin/association-financials',
    text: () => t().routes.associationFinancials,
    icon: AccountBalanceWallet,
  },
  editUsers: { to: '/admin/edit-users', text: () => t().routes.userAccounts, icon: People },
  emailConflicts: { to: '/admin/email-conflicts', text: () => t().routes.emailConflicts, icon: People },
  transactionsWithoutUser: {
    to: '/admin/transactions-without-user',
    text: () => t().routes.transactionsWithoutUser,
    icon: People,
  },
  duplicatedUsers: {
    to: '/admin/duplicate-users',
    text: () => t().routes.duplicatedUsers,
    icon: People,
  },
  migrations: { to: '/admin/migrations', text: () => t().routes.dbMigrations },
  manuallyProcessLicenseRequest: {
    to: '/admin/reprocess-license-requests',
    text: () => todoT('Reprocess license requests'),
  },
  editAdmins: { to: '/admin/edit-admins', text: () => t().routes.administrators },
  importLicenses: {
    to: '/admin/import-licenses',
    text: () => t().routes.importLicenses,
    icon: CloudUpload,
  },
  messages: { to: '/admin/messages', text: () => t().routes.messages, icon: Email },
  userEvents: {
    to: '/admin/user-events',
    generateTo: (q: string) => `/admin/user-events?q-user-event=${encodeURIComponent(q)}`,
    text: () => t().routes.userEvents,
    icon: People,
  },
  newLicense: {
    to: '/licenses/new/:year',
    generateTo: (year: Year) => `/licenses/new/${year}`,
    textForYear: (year: Year) => t().licensesBoxes.requestNewLicense(year),
    text: () => t().routes.newLicense,
    icon: AssignmentInd,
  },
  previewLicense: {
    to: '/licenses/preview',
    icon: AssignmentInd,
    text: () => t().routes.previewLicense,
  },
  sportEvents: {
    to: '/events',
    anonymousMatch: '/events',
    icon: Event,
    text: () => t().sportEvents,
  },
  newInscription: {
    to: '/inscriptions/new/:sportEventId/:categoryId/:date',
    generateTo: (event: SportEvent, category: Category | DayCategory, date: string) =>
      `/inscriptions/new/${event.id}/${category.id}/${date}`,
    generateText: (category: Category, date: string) =>
      `${t().routes.newInscription} ${categoryHack(category).shortName} (${date})`,
    generateShort: (date: string) =>
      date === unknownDate
        ? t().inscribeAtDateUnknown
        : `${t().inscribeFor} ${date === unknownDate ? '' : date} (${pDateWeekdayName(date)})`,
    icon: ImportContacts,
    text: () => t().routes.newInscription,
  },
  inscriptions: {
    to: '/admin/inscriptions/:sportEventId',
    generateTo: (q: string, id: SportEventId) => `/admin/inscriptions/${id}?q=${encodeURIComponent(q)}`,
    icon: ImportContacts,
    text: () => t().inscribe,
    textAlt: (sportEvent: SportEvent | undefined) => `${t().inscribe} ${sportEvent?.name || ''}`,
  },
  inscriptionGroups: {
    to: '/admin/inscription-groups/:sportEventId',
    generateTo: (id: SportEventId) => `/admin/inscription-groups/${id}`,
    icon: FormatListNumbered,
    text: () => t().routes.inscriptionGroups,
    textAlt: (sportEvent: SportEvent | undefined) =>
      `${t().routes.inscriptionGroups} ${sportEvent?.name || ''}`,
  },
  lonelyTwoDayDiscounts: {
    to: '/admin/lonely-two-day-discounts',
    icon: AttachMoney,
    text: () => t().routes.lonelyTwoDayDiscounts,
  },
  sportEventFinancials: {
    to: '/admin/sport-event-financials',
    generateTo: (id: SportEventId) => `/admin/sport-event-financials/?q=${encodeURIComponent(id)}`,
    icon: AttachMoney,
    text: () => t().financials.financials,
    textAlt: (sportEvents: SportEvent[]) =>
      `${t().financials.financials} ${sportEvents.length === 1 ? sportEvents[0].name : ''}`,
  },
  publicInscriptions: {
    to: '/public-inscriptions/:sportEventId',
    anonymousMatch: '/public-inscriptions',
    generateTo: (id: SportEventId) => `/public-inscriptions/${id}`,
    icon: MenuBook,
    text: () => t().routes.publicInscriptions,
    textAlt: (sportEvent: SportEvent) => `${t().routes.publicInscriptions} ${sportEvent.name}`,
  },
  editProfile: { to: '/profile/edit', text: () => t().routes.editProfile },
  editEmergency: { to: '/emergency/edit', text: () => t().routes.editEmergency },
  editDriversLicense: { to: '/drivers-license/edit', text: () => t().routes.editDriversLicense },
  editHealthCheck: { to: '/health-check/edit', text: () => t().routes.editHealthCheck },
  editInsurance: { to: '/insurance/edit', text: () => t().routes.editInsurance },
  editPhoto: { to: '/photo/edit', text: () => t().routes.editPhoto },
  changeEmailAfterLogin: {
    to: '/profile/change-email-after-login',
    text: () => t().routes.changeEmailAfterLogin,
  },
  changeEmail: { to: '/profile/change-email', text: () => t().buttons.changeEmail },
  editTransponder: { to: '/transponder/edit', text: () => t().routes.editTransponder },
  addBike: { to: '/bikes/new', text: () => t().bikesBox.addNewBike },
  qrLicenseV1: {
    to: '/q1/:qrUid/:shortCategoryId',
    async generateTo(db: DB, { uid, categoryId }: { uid: string; categoryId: CategoryId }) {
      const category = categoryByIdRequired(categoryId)
      const qrUid = encodeURIComponent(await uidOrShortUid(db, uid))
      return `/q1/${qrUid}/${category.shortId}`
    },
    generateToRaw({ shortUid, shortCategoryId }: { shortUid: number; shortCategoryId: number }) {
      return `/q1/${shortUid}/${shortCategoryId}`
    },
    validStarts: allowedQRDomains.map((domain) => `${domain}/q1/`),
    text: () => t().routes.qrLicense,
  },
  qrLicenseV2: {
    to: '/q2/l/:licenseShortId/:code',
    anonymousMatch: '/q2/l',
    generateTo: (license: ApprovedLicense) =>
      !license.shortId || !license.licenseCode ? '' : `/q2/l/${license.shortId}/${license.licenseCode}`,
    generateToRaw({ shortId, licenseCode }: { shortId: number; licenseCode: string }) {
      return `/q2/l/${shortId}/${licenseCode}`
    },
    validStarts: allowedQRDomains.map((domain) => `${domain}/q2/l/`),
    text: () => t().routes.qrLicense,
  },
  qrPitLane: {
    to: '/q2/p/:licenseShortId/:pitLanePassCode',
    anonymousMatch: '/q2/p',
    text: () => t().routes.qrPitLane,
    generateTo: (license: ApprovedLicense) =>
      !license.shortId || !license.pitLanePassCode
        ? ''
        : `/q2/p/${license.shortId}/${license.pitLanePassCode}`,
    validStarts: allowedQRDomains.map((domain) => `${domain}/q2/p/`),
  },
  termsAndDataProtection: {
    to: '/terms-and-data-protection',
    anonymousMatch: '/terms-and-data-protection',
    text: () => t().routes.termsAndDataProtection,
    icon: Policy,
  },
}
