import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useI18nContext } from 'app/i18n/i18n-context'
import { useAssociationContext } from 'app/themes/association-context'
import { I18nLocales } from 'shared/i18n/i18n-types'
import { AssociationID } from 'shared/models/associations'

export function SetAssociationAndLocale(props: { locale?: I18nLocales; association: AssociationID }) {
  const { locale, association } = props
  const history = useHistory()
  const i18nContext = useI18nContext()
  const associationContext = useAssociationContext()

  useEffect(() => {
    associationContext.setAssociation(association)
    if (locale) i18nContext.setLocale(locale)
    history.push('/')
  }, [i18nContext, locale, associationContext, association, history])

  return null
}
