import { DB } from 'shared/db/db'
import { UserEvent } from 'shared/db/user-event'

export const id = '2022-01-07T23-28-10-000Z'

export const description = 'Migrates the user events from the V1 format to the V2 format'

export async function up(db: DB) {
  const userEvents = await db.load<{
    all: Record<string, UserEvent>
    byUser: Record<string, UserEvent>
    details: Record<string, UserEvent['details']>
    doneByUser: Record<string, UserEvent>
  }>('userEvents')
  const userEvents2 = await db.load<{
    all: Record<string, UserEvent>
    byUser: Record<string, UserEvent>
    details: Record<string, UserEvent['details']>
    doneByUser: Record<string, UserEvent>
  }>('userEvents2')

  if (!userEvents || !userEvents2) throw new Error('Invalid DB data')

  await Promise.all(
    Object.values(userEvents.all)
      .map((event) => ({ ...event, details: userEvents.details[event.id || ''] }))
      .map((event) => db.pushUserEvent(event))
  )
}
