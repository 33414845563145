import { AdminManagement } from 'app/pages/admin/users/admin-management'
import { AssociationAdminManagement } from 'app/pages/admin/users/association-admin-management'
import { useUserContext } from 'app/themes/user-context'
import { User } from 'app/users/user'

export function AdminManagementContainer({ user }: { user: User }) {
  const userContext = useUserContext()
  return (
    <>
      {userContext.admin && <AdminManagement path="admins" user={user} />}
      {userContext.admin && <AdminManagement path="readonlyAdmins" user={user} />}
      <AssociationAdminManagement user={user} />
    </>
  )
}
