import base64 from 'base-64'
import base64Url from 'base64url'

export function base64Encode(data: string) {
  return base64.encode(data)
}

export function base64Decode(data: string) {
  return base64.decode(data)
}

export function base64UrlEncode(data: string) {
  return base64Url.encode(data, 'utf8')
}

export function base64UrlDecode(data: string) {
  return base64Url.decode(data, 'utf8')
}
