import { remove } from 'diacritics'
import { sample, times } from 'lodash'
import { formatDateWithSeconds } from 'shared/utils/date'

export function wordsUpToTotalLength(text: string, maxTextLength: number) {
  if (maxTextLength >= text.length) return text

  let lastSpaceIndex = Math.min(maxTextLength, text.length - 1)
  while (lastSpaceIndex >= 0 && text[lastSpaceIndex] !== ' ') lastSpaceIndex--
  return text.slice(0, lastSpaceIndex <= 0 ? maxTextLength : lastSpaceIndex)
}

export function exactLen(length: number, original: string) {
  return maxLen(length, original).padStart(length, '0')
}

export function maxLen(length: number, original: string) {
  return original.slice(0, length)
}

export function toFilename(text: string) {
  const sanatized = remove(text.toLocaleLowerCase()).replace(/[^a-z0-9-_]/gi, '_')
  return `${sanatized}_${formatDateWithSeconds(new Date())}`
}

export function replaceAllMultiple(
  text: string,
  replacements: [searchValue: string, replaceValue: string][]
) {
  return replacements.reduce((text, replacement) => replaceAll(text, ...replacement), text)
}

export function replaceAll(text: string, searchValue: string, replaceValue: string): string {
  return (text as any).replaceAll
    ? (text as any).replaceAll(searchValue, replaceValue)
    : text.split(searchValue).join(replaceValue)
}

// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export function fastInsecureHashCode(s: string): number {
  let hash = 0
  let chr = 0

  if (s.length === 0) return hash
  for (let i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export function randomString(length: number) {
  return times(length, () => sample(allowedRandomCharacters)).join('')
}

const allowedRandomCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
