import assertNever from 'assert-never'
import { db } from 'app/db/frontend-db'
import {
  boldFontName,
  DownloadLicenseProps,
  drawAddress,
  drawAssociationAndCategory,
  drawHeader,
  drawImages,
  drawIssuedNumber,
  drawLicenseDetailsTitle,
  drawLicenseID,
  drawNumberPlate,
  drawQRCodeOnPDF,
  drawTopBackground,
  ensureFontsAreLoaded,
  filename,
  fontCache,
  fontSizeFooter,
  fontSizeNormal,
  fontSizeOpenTasks,
  LicenseExportType,
  licensePdfOptions,
  PDFProps,
  regularFontName,
  titleBoldFontName,
  titleRegularFontName,
  topSectionHeight,
} from 'app/pages/licenses/pdf-license-service-common'
import { categoryOfAssociationRequired } from 'shared/data/categories-service'
import { InscriptionTask, LicenseTask } from 'shared/data/license-tasks-overview'
import { ApprovedLicense, Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { openLicenseTasks } from 'shared/license/license-tasks'
import { todoMigrateAssociation } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { fullName, zipPlace } from 'shared/models/personal-data'
import { truthy } from 'shared/utils/array'
import { formatDateDe, parseDate } from 'shared/utils/date'
import { downloadPdf, PDFDoc } from 'utils/pdf/pdf'
import { usePdfPreview } from 'utils/pdf/pdf-preview'
import { Disp } from 'utils/react'

export function useLicense2022Preview(setError: Disp<Error | null>, { license }: DownloadLicenseProps) {
  usePdfPreview(setError, licensePdfOptions('preview'), async (pdf) => {
    const documents = await db.loadDocuments(license)
    if (!documents) throw new Error('No documents provided')
    await ensureFontsAreLoaded()
    Object.entries(fontCache).forEach(([key, value]) => pdf.registerFont(key, value))
    await generateLicensePdf(license, documents, pdf)
  })
}

export async function generateLicense2022(license: ApprovedLicense) {
  await ensureFontsAreLoaded()

  const documents = await db.loadDocuments(license)
  if (!documents) throw new Error('No documents provided')
  await downloadPdf(licensePdfOptions(filename(license, documents, 'license')), (pdf) => {
    Object.entries(fontCache).forEach(([key, value]) => pdf.registerFont(key, value))
    return generateLicensePdf(license, documents, pdf)
  })
}

async function generateLicensePdf(license: ApprovedLicense, documents: Documents, pdf: PDFDoc) {
  const association = todoMigrateAssociation(license.licenseAssociation)
  const category = categoryOfAssociationRequired(license.categoryId, association)

  pdf.font(titleRegularFontName)

  const tasks = openLicenseTasks({ license, documents })
  const type: LicenseExportType = 'license'
  const pdfProps = { pdf, type, category, license, documents, tasks }
  drawTopBackground(pdf, category)
  await drawImages(pdfProps)
  drawHeader(pdfProps)
  drawNumberPlate(pdfProps)

  pdf.fontSize(fontSizeNormal)
  drawRiderDetails(pdfProps)
  drawMemberNumber(pdfProps)

  drawLicenseDetailsTitle(pdfProps)
  drawValidity(pdfProps)
  drawLicenseID(pdfProps)
  drawNationalOrInternationalValidity(pdfProps)
  drawAssociationAndCategory(pdfProps)
  drawIssuedNumber(pdfProps)
  drawTransponderNumber(pdfProps)

  pdf.font(regularFontName)
  if (tasks.allDone) drawInsuranceDisclaimer(pdfProps)
  drawAddress(pdfProps)

  pdf.addPage()

  await drawQRCodeOnPDF(pdfProps, 0, 0)
}

function drawTransponderNumber({ pdf, category, documents }: PDFProps) {
  const allTransponders = Object.values(documents.transponder?.transponders || {})
  const rfTransponder = allTransponders.find((transponder) => transponder.type === 'RF')
  const myLapsTransponder = allTransponders.find((transponder) => transponder.type === 'MyLaps')

  const transponders = [rfTransponder, myLapsTransponder]
    .filter(truthy)
    .filter((t) => category.transponders.includes(t.type))
  const transpondersText =
    transponders.length === 0
      ? undefined
      : `${transponders
          .map((transponder) => `${transponder.type}: ${transponder.transponderNumber}`)
          .join(' / ')}`

  if (transpondersText)
    pdf
      .text('\n')
      .font(titleBoldFontName)
      .text(t().licensePdf.transponder)
      .font(titleRegularFontName)
      .text(transpondersText)
}

function drawMemberNumber({ pdf, category, documents }: PDFProps) {
  const text = category.association === 'sam' ? memberNumberText(documents) : undefined
  if (text) pdf.font(titleRegularFontName).fontSize(fontSizeNormal).text(text)
}

function memberNumberText(documents: Documents) {
  const memberNumber = (documents.personalData?.samMemberNumber || '').toString()
  return memberNumber ? `SAM MNR: ${memberNumber}` : ''
}

function drawNationalOrInternationalValidity({ category, pdf, license }: PDFProps) {
  if (category.association === 'fms') {
    if (category.id === 'fms-2022-other-fms-fun-sport') pdf.text(`${t().licensePdf.trainingOnly}`)
    else if (license.licenseType === 'national') pdf.text(`${t().licensePdf.validNationalOnly}`)
    else if (license.licenseType === 'international') pdf.text(`${t().licensePdf.validInternational}`)
    else assertNever(license.licenseType)
  }
}

function drawRiderDetails({ pdf, documents, license }: PDFProps) {
  pdf.font(titleBoldFontName).text(t().licensePdf.rider, undefined, topSectionHeight + 28)

  pdf.font(titleRegularFontName).text(fullName(documents.personalData))

  if (license.licenseAssociation !== 'fms')
    pdf.font(titleRegularFontName).text(zipPlace(documents.personalData))

  pdf.font(titleRegularFontName).text(formatDateDe(parseDate(documents.personalData?.birthdate)))
}

function drawValidity({ pdf, category, license, documents }: PDFProps) {
  const tasks = openLicenseTasks({ documents, license })
  if (tasks.allDone) pdf.text(`${t().licensePdf.validUntil}: 31.12.${category.year}`)
  else
    pdf
      .fillColor('red')
      .fontSize(fontSizeOpenTasks)
      .text(
        `${t().licensePdf.notValidYet}: ${tasks.tasks
          .filter((task) => !task.done)
          .map((task) => shortTaskDescription(task))
          .join(', ')}`,
        { align: 'justify' }
      )
      .fillColor('black')
      .fontSize(fontSizeNormal)
}

function shortTaskDescription(task: LicenseTask | InscriptionTask) {
  return t().licenseTasks.pdfTasks[task.type]
}

function drawInsuranceDisclaimer({ pdf, category, license }: PDFProps) {
  if (!category.needsInsurance) return

  if (showInternationalStartingPermission(category, license))
    return pdf
      .moveTo(0, topSectionHeight + 194)
      .lineTo(pdf.page.width, topSectionHeight + 194)
      .fillAndStroke('black')
      .font(boldFontName)
      .fontSize(fontSizeFooter)
      .text(t().licensePdf.fmsInternationalDisclaimerTitle, undefined, topSectionHeight + 200)
      .font(regularFontName)
      .text(t().licensePdf.fmsInternationalDisclaimer)

  if (showSamInsurance(category))
    return pdf
      .moveTo(0, topSectionHeight + 213)
      .lineTo(pdf.page.width, topSectionHeight + 213)
      .fillAndStroke('black')
      .font(regularFontName)
      .fontSize(fontSizeFooter)
      .text(t().licensePdf.samDisclaimer, undefined, topSectionHeight + 219)
}

function showSamInsurance(category: CategoryOfAssociation) {
  return category.association === 'sam'
}

function showInternationalStartingPermission(category: CategoryOfAssociation, license: ApprovedLicense) {
  return (
    (category.association === 'fms' && license.licenseType === 'international') ||
    (category.association === 'sam' &&
      license.licenseType === 'international' &&
      category.type === 'supermoto')
  )
}
