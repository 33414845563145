import assertNever from 'assert-never'
import {
  categoryByIdRequired,
  fullCategoryCommonName,
  isCategoryId,
} from 'shared/data/categories-service'
import { displayIssuedNumber } from 'shared/data/licenses-service'
import { DayCategory } from 'shared/db/day-category'
import { ApprovedLicense, InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { Inscription } from 'shared/sport-events/sport-events'

export function inscriptionIssuedOrPreferredNumber(
  inscription: Inscription,
  license: ApprovedLicense | undefined
) {
  return inscription.type === 'unlistedDayLicenseInscription' ||
    inscription.type === 'unlistedLicenseInscription'
    ? '-'
    : inscription.type === 'enlistedDayInscriptionDayCategory' ||
      inscription.type === 'enlistedDayInscriptionYearCategory'
    ? inscription.issuedNumber
    : inscription.type === 'enlistedDayInscriptionDayCategoryDraft' ||
      inscription.type === 'enlistedDayInscriptionYearCategoryDraft'
    ? inscription.preferredNumber
    : inscription.type === 'enlistedLicenseInscription'
    ? displayIssuedNumber(license) || '?'
    : assertNever(inscription)
}

export function inscriptionIssuedNumber(withContext: InscriptionWithContextAndSportEvent) {
  if (withContext.status !== 'inscribed') throw new Error('Inscription is not inscribed')

  const { inscription } = withContext
  const license = withContext.licenseWithContext?.license
  if (
    inscription.type === 'enlistedDayInscriptionDayCategory' ||
    inscription.type === 'enlistedDayInscriptionYearCategory'
  )
    return inscription.issuedNumber
  if (inscription.type === 'enlistedLicenseInscription' && license?.approved.issuedNumber)
    return license?.approved.issuedNumber
  throw new Error('Inscription is not inscribed')
}

export function inscriptionCategoryName(
  inscription: Inscription,
  dayCategory: DayCategory | undefined
): string {
  const category = inscriptionCategory(inscription, dayCategory)

  return !category
    ? '?'
    : category.categoryType === 'licenseCategory'
    ? fullCategoryCommonName(category)
    : category.categoryType === 'dayCategory'
    ? category.name
    : assertNever(category)
}

export function inscriptionCategory(inscription: Inscription, dayCategory: DayCategory | undefined) {
  return isCategoryId(inscription.category) ? categoryByIdRequired(inscription.category) : dayCategory
}

export function findDayCategory(inscription: Inscription, dayCategories: DayCategory[]) {
  return dayCategories.find((category) => inscription.category === category.id)
}
