import { Grid } from '@material-ui/core'
import { t } from 'shared/i18n/current'
import {
  hasMyLapsTransponder,
  hasRfTransponder,
  myLapsTransponderMakes,
  myLapsTransponderNumbers,
  rfTransponderNumbers,
  Transponder,
  transponderOptionRequired,
} from 'shared/models/transponder'

export function ShowTransponderData({ transponder }: { transponder: Transponder }) {
  return (
    <Grid container spacing={1}>
      {hasMyLapsTransponder(transponder) && (
        <Grid item xs={12}>
          {t().transponderNumbers.label}: {myLapsTransponderMakes(transponder)},{' '}
          {myLapsTransponderNumbers(transponder)}
        </Grid>
      )}

      {hasRfTransponder(transponder) && (
        <Grid item xs={12}>
          {t().transponderNumbersRf.label}: {rfTransponderNumbers(transponder)}
        </Grid>
      )}

      {transponder.orderedTransponders?.map((transponder) => (
        <Grid key={transponder} item xs={12}>
          {transponderOptionRequired(transponder).name()}
        </Grid>
      ))}
    </Grid>
  )
}
