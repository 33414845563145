import { Button } from '@material-ui/core'
import { AlternateEmail, TransferWithinAStation } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { useAllDocuments } from 'app/db/db-contexts/documents-context'
import { actions } from 'app/export/table'
import { samFields } from 'app/forms/fields'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { EditPersonalDataButton } from 'app/pages/admin/users/EditPersonalDataButton'
import { SetPasswordButton } from 'app/pages/admin/users/set-password-button'
import { useIsAssociationAdmin, useUserContext } from 'app/themes/user-context'
import {
  changeEmail as changeFirebaseAuthEmail,
  impersonateUser,
  useUsers,
} from 'app/users/auth-admin-service'
import { DisabledForAssociationAdmin } from 'app/users/disabled-for-association-admin'
import { updateShortUids } from 'app/users/users-service'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { fullName } from 'shared/models/personal-data'
import { uFormatDate } from 'shared/utils/date'
import { sleep } from 'shared/utils/time'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { useSuccessSnackbar, useErrorSnackbar } from 'utils/snackbar'

export function UserManagement({ admin }: { admin: UserQuery }) {
  const users = useUsers(1000)
  const { data: allDocuments, error, loading } = useAllDocuments()

  const associationAdmin = useIsAssociationAdmin()
  if (associationAdmin) return <DisabledForAssociationAdmin title={t().users.title} />

  return (
    <TableBox
      title={t().users.title}
      loading={loading}
      error={users.error || error}
      data={
        users.allUsers && {
          rawData: users.allUsers.map((user) => JSON.stringify(user)),
          headers: [
            { value: t().photo },
            { value: t().uid },
            { value: t().email },
            { value: '✅' },
            { value: t().displayName },
            { value: t().name },
            { value: t().creationTime },
            { value: t().lastSignInTime },
            { value: t().customClaims, exportOnly: true },
            actions(),
          ],
          // eslint-disable-next-line react/display-name
          contents: users.allUsers.map((user) => () => {
            const documents = allDocuments[user.uid]
            return [
              user.photoURL ? <img src={user.photoURL} alt="User" /> : '',
              user.uid,
              user.email,
              user.emailVerified ? 'x' : '',
              user.displayName,
              fullName(documents?.personalData),
              uFormatDate(user.metadata.creationTime),
              uFormatDate(user.metadata.lastSignInTime),
              JSON.stringify(user.customClaims || undefined) || '',
              <>
                <FinancialDialog user={user} admin={admin} />
                <EditPersonalDataButton user={user} documents={documents} email={user.email} />
                <EditEmailButton
                  user={user}
                  afterSuccessfulChange={async () => {
                    await sleep(2000)
                    globalThis.location.reload()
                  }}
                />{' '}
                <ImpersonateButton user={user} />
                <SetPasswordButton user={user} />
              </>,
            ]
          }),
        }
      }
      more={
        <>
          <Button
            fullWidth
            disabled={users.loading || !users.loadMore}
            onClick={() => users.loadMore?.()}
          >
            {users.loadMore || users.loading ? t().users.loadMore : t().users.allUsersLoaded}
          </Button>
          <Loading loading={users.loading} />
        </>
      }
    >
      <UpdateShortUidsButton />
    </TableBox>
  )
}

interface EditEmailButtonProps {
  user: {
    uid: string
    email: string
  }
  afterSuccessfulChange?: () => Promise<void>
}

export function EditEmailButton({ user, afterSuccessfulChange }: EditEmailButtonProps) {
  const [onSubmitError, setError] = useState<Error>()
  const dialog = useDialog()
  const showSuccessMessage = useSuccessSnackbar()
  const showErrorMessage = useErrorSnackbar()
  const fields = samFields().changeEmail
  const userContext = useUserContext()
  if (!userContext.normalAdmin) return null

  return (
    <>
      <IconButtonWithTooltip tooltip={t().users.editEmail} onClick={() => dialog.open()}>
        <AlternateEmail />
      </IconButtonWithTooltip>
      {dialog.isOpen && (
        <Formik
          initialValues={{ email: user.email }}
          enableReinitialize={true}
          validationSchema={schema()}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setError(undefined)
              await changeFirebaseAuthEmail({
                uid: user.uid,
                oldEmail: user.email,
                newEmail: values.email,
              })
              showSuccessMessage(t().users.newEmailSaved)
              await afterSuccessfulChange?.()
            } catch (error: any) {
              setError(error)
              showErrorMessage(t().users.errorOnSaving)
              throw error
            }
            setSubmitting(false)
          }}
        >
          {({ submitForm, isSubmitting, dirty, isValid }) => (
            <Form>
              <ConfirmDialog
                maxWidth="xl"
                title={t().users.editUserEmail(user.email)}
                buttonText={t().users.editUserEmail(user.email)}
                dialog={dialog}
                onConfirm={async () => {
                  try {
                    await submitForm()
                  } catch {
                    return false
                  }
                }}
                disabled={!isValid || !dirty}
              >
                {fields.email.field()}
                <Loading loading={isSubmitting} />
                <FriendlyError error={onSubmitError} />
              </ConfirmDialog>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

function schema() {
  return Yup.object().shape({
    email: Yup.string().label(t().email).trim().required(),
  })
}

export function ImpersonateButton({ user }: { user: UserQuery }) {
  const userContext = useUserContext()
  if (!userContext.normalAdmin) return null

  return (
    <IconButtonWithTooltip
      tooltip={t().users.loginAsUser(user.uid)}
      onClick={() => impersonateUser({ uid: user.uid })}
    >
      <TransferWithinAStation />
    </IconButtonWithTooltip>
  )
}

function UpdateShortUidsButton() {
  const { error, setError } = useError()
  const [loading, setLoading] = useState(false)
  return (
    <>
      <Button
        disabled={loading}
        onClick={() => catchAndLog(setError, () => updateShortUids(), setLoading)}
      >
        {t().users.updateShortId}
      </Button>
      <Loading loading={loading} />
      <FriendlyError error={error} />
    </>
  )
}
