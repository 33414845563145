import assertNever from 'assert-never'
import { sortBy } from 'lodash'
import {
  AccountingGroup,
  AccountingGroupInscription,
  AccountingGroupDonation,
  AccountingGroupOpen,
  AccountingGroupYearLicense,
  AccountingGroupTransponder,
  AccountingGroupManualBooking,
  AccountingGroupManualPayment,
  AccountingGroupAutomaticPayment,
  AccountingGroupAssociationPayment,
  AccountingGroupDayLicense,
} from 'app/pages/admin/financials/accounting-group'
import { categoryByIdFromString, categoryByIdRequired } from 'shared/data/categories-service'
import {
  AssociationPayment,
  AutomaticPayment,
  BookingRelevantTransaction,
  InscriptionBooking,
  ManualBooking,
  ManualPayment,
  NormalLicenseBooking,
  ReverseLicenseBooking,
} from 'shared/db/db'
import { openRemainingBalance } from 'shared/db/transactions-service'
import { CategoryType } from 'shared/models/category'

export function accountingStatement(transactions: BookingRelevantTransaction[]) {
  const groupsById = new Map<string, AccountingGroup>()
  transactions.forEach((transaction) => {
    const groups = accountingGroup(transaction).filter((group) => group.value !== 0)
    groups.forEach((group) => {
      const existingGroup = groupsById.get(group.id)
      if (existingGroup) existingGroup.value += group.value
      else groupsById.set(group.id, group)
    })
  })
  return sortBy([...groupsById.values()], (group) => group.id).filter(
    (group) => group.type !== 'open' && group.type !== 'automaticPayment'
  )
}

function accountingGroup(transaction: BookingRelevantTransaction): AccountingGroup[] {
  return transaction.type === 'inscriptionBooking'
    ? accountingGroupForInscriptionBooking(transaction)
    : transaction.type === 'licenseBooking' || transaction.type === 'reverseLicenseBooking'
    ? accountingGroupForLicenseBooking(transaction)
    : transaction.type === 'manualBooking'
    ? accountingGroupForManualBooking(transaction)
    : transaction.type === 'manualPayment'
    ? accountingGroupForManualPayment(transaction)
    : transaction.type === 'payment'
    ? accountingGroupForAutomaticPayment(transaction)
    : transaction.type === 'associationPayment'
    ? accountingGroupForAssociationPayment(transaction)
    : assertNever(transaction)
}

function accountingGroupForInscriptionBooking(
  transaction: InscriptionBooking
): (
  | AccountingGroupInscription
  | AccountingGroupDonation
  | AccountingGroupOpen
  | AccountingGroupDayLicense
)[] {
  if (
    transaction.item.type === 'inscriptionLineItem' ||
    transaction.item.type === 'inscriptionDayCategoryLineItem' ||
    transaction.item.type === 'inscriptionDayCategoryPowerLineItem' ||
    transaction.item.type === 'powerLineItem' ||
    transaction.item.type === 'inscriptionDiscountLineItem'
  ) {
    const category = categoryByIdFromString(transaction.item.categoryId)
    const categoryType: CategoryType | 'none' = category?.type || 'none'
    const open = openRemainingBalance(transaction.remainingBalance)
    const value = transaction.item.price - open
    return sep<AccountingGroupInscription>(open, {
      id: `inscription-${categoryType}`,
      type: 'inscription',
      categoryType,
      value,
    })
  } else if (transaction.item.type === 'donationLineItem') {
    const open = openRemainingBalance(transaction.remainingBalance)
    const value = transaction.item.price - open
    return sep(open, { id: 'donation', type: 'donation', value })
  } else if (transaction.item.type === 'inscriptionDayLicenseLineItem') {
    const category = categoryByIdRequired(transaction.item.categoryId)
    const categoryType: CategoryType = category.type
    const open = openRemainingBalance(transaction.remainingBalance)
    const value = transaction.item.price - open
    return sep(open, { id: `day-license-${categoryType}`, type: 'dayLicense', categoryType, value })
  } else return assertNever(transaction.item)
}

function accountingGroupForLicenseBooking(
  transaction: NormalLicenseBooking | ReverseLicenseBooking
): (AccountingGroupOpen | AccountingGroupYearLicense | AccountingGroupTransponder)[] {
  if (transaction.item.type === 'categoryLineItem') {
    const category = categoryByIdRequired(transaction.item.categoryId)
    const categoryType: CategoryType = category.type
    const open = openRemainingBalance(transaction.remainingBalance)
    const value = transaction.item.price - open
    return sep(open, { id: `year-license-${categoryType}`, type: 'yearLicense', categoryType, value })
  } else if (transaction.item.type === 'transponderLineItem') {
    const open = openRemainingBalance(transaction.remainingBalance)
    const value = transaction.item.price - open
    return sep(open, { id: 'transponder', type: 'transponder', value })
  } else assertNever(transaction.item)
}

function accountingGroupForManualBooking(
  transaction: ManualBooking
): (AccountingGroupOpen | AccountingGroupManualBooking)[] {
  const open = openRemainingBalance(transaction.remainingBalance)
  const value = transaction.item.price - open
  const tag = transaction.tag || '-'
  const id = `manualBooking-${tag}`
  return sep(open, { id, type: 'manualBooking', tag, value })
}

function accountingGroupForManualPayment(
  transaction: ManualPayment
): (AccountingGroupOpen | AccountingGroupManualPayment)[] {
  const tag = transaction.tag || '-'
  return [{ id: `manualPayment-${tag}`, type: 'manualPayment', tag, value: -transaction.amount }]
}

function accountingGroupForAutomaticPayment(
  transaction: AutomaticPayment
): AccountingGroupAutomaticPayment[] {
  return [{ id: 'automaticPayment', type: 'automaticPayment', value: -transaction.amount }]
}

function accountingGroupForAssociationPayment(
  transaction: AssociationPayment
): AccountingGroupAssociationPayment[] {
  return [{ id: 'associationPayment', type: 'associationPayment', value: -transaction.amount }]
}

function sep<T extends AccountingGroup>(open: number, group: T): [T] | [T, AccountingGroupOpen] {
  return open === 0 ? [group] : [group, accountingGroupOpen(open)]
}

function accountingGroupOpen(open: number): AccountingGroupOpen {
  return { id: 'open', type: 'open', value: open }
}
