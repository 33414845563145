import * as YupLocale from 'yup/lib/locale'
import { yupDE } from 'shared/i18n/i18n-yup/yup-de'
import { yupEN } from 'shared/i18n/i18n-yup/yup-en'
import { yupFR } from 'shared/i18n/i18n-yup/yup-fr'

export const yupLocales: Record<
  string,
  {
    mixed: Required<YupLocale.MixedLocale>
    string: Required<YupLocale.StringLocale>
    number: Required<YupLocale.NumberLocale>
    date: Required<YupLocale.DateLocale>
    boolean: Required<YupLocale.BooleanLocale>
    object: Required<YupLocale.ObjectLocale>
    array: Required<YupLocale.ArrayLocale>
  }
> = {
  de: yupDE,
  fr: yupFR,
  en: yupEN as any,
}
