import { updateStates } from 'shared/billing/bookings-service'
import { DB, ManualPayment, NewManualPayment, WithoutTimestamps } from 'shared/db/db'
import { UserId } from 'shared/db/user-id'
import { AssociationID } from 'shared/models/associations'

export async function pushManualPayment(db: DB, props: NewManualPayment, admin: UserId) {
  const { bill, tag, uid, reference, date, amount, byUid, association, internalRemarks } = props
  const payment: WithoutTimestamps<ManualPayment> = {
    id: '',
    type: 'manualPayment',
    byUid,
    uid,
    reference,
    date,
    amount,
    tag,
    association,
    internalRemarks,
  }
  const createdPayment = await db.pushManualPayment(payment)
  await db.pushUserEvent({
    id: '',
    type: 'editManualPayment',
    uid: createdPayment.uid,
    byUid: admin,
    date: new Date().toISOString(),
    editType: 'add',
    details: createdPayment,
  })
  if (bill) await db.updateBillFromPayment(createdPayment, bill)
  await updateStates(db, createdPayment.uid)
}

export async function updateManualPayment(db: DB, payment: ManualPayment, admin: UserId) {
  await db.updateManualPayment(payment)
  await db.pushUserEvent({
    id: '',
    type: 'editManualPayment',
    uid: payment.uid,
    byUid: admin,
    date: new Date().toISOString(),
    editType: 'update',
    details: payment,
  })
  await updateStates(db, payment.uid)
}

export async function deleteManualPayment(db: DB, payment: ManualPayment, admin: UserId) {
  await db.deleteManualPayment(payment)
  await db.pushUserEvent({
    id: '',
    type: 'editManualPayment',
    uid: payment.uid,
    byUid: admin,
    date: new Date().toISOString(),
    editType: 'delete',
    details: payment,
  })
  await updateStates(db, payment.uid)
}

export async function renameManualPaymentTag(props: {
  db: DB
  uid: UserId
  oldTag: string
  newTag: string
  association: AssociationID
}) {
  const { db, uid, oldTag, newTag, association } = props
  const payments = await db.loadAllManualPayments()

  const filtered = payments.filter((p) => p.tag === oldTag && p.association === association)
  await db.pushUserEvent({
    id: '',
    type: 'renameTag',
    uid,
    byUid: uid,
    date: new Date().toISOString(),
    editType: 'manualPayment',
    details: { ids: filtered.map((p) => ({ uid: p.uid, elementId: p.id })), oldTag, newTag },
  })
  await Promise.all(filtered.map((p) => db.setManualPaymentTag(p, newTag)))
}
