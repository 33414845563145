import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { t } from 'shared/i18n/current'

export function DisabledForAssociationAdmin({ title }: { title: string }) {
  return <ElevatedBox title={title}>{t().admins.disabledForAssociationAdmin}</ElevatedBox>
}

export function DisabledForAdmin({ title }: { title: string }) {
  return <ElevatedBox title={title}>{t().admins.functionalityDeactivated}</ElevatedBox>
}
