import { lighten, colors } from '@material-ui/core'
import type { ThemeOptions } from '@material-ui/core'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import assertNever from 'assert-never'
import {
  samGreen,
  samBlue,
  samYellow,
  samRed,
  samGreenContrast,
  samGreenLight,
  samGreenDark,
  samBlueDark,
  samYellowDark,
  samYellowLight,
  samBlueLight,
  samLightGreen,
  samLightGreenContrast,
  samLightGreenDark,
  samLightGreenLight,
  samRedLight,
  samRedDark,
  fmsBlue,
  fmsBlueContrast,
  fmsBlueDark,
  fmsBlueLight,
  fmsRedLight,
  fmsRed,
  fmsRedDark,
  mxrsOrangeLight,
  mxrsOrange,
  mxrsOrangeDark,
  mxrsOrangeContrast,
  mxrsLightOrangeLight,
  mxrsLightOrange,
  mxrsLightOrangeDark,
  mxrsLightOrangeContrast,
  mxrsRedLight,
  mxrsRed,
  mxrsRedDark,
  sjmccRedLight,
  sjmccRed,
  sjmccLightBlackContrast,
  sjmccLightBlackDark,
  sjmccLightBlack,
  sjmccLightBlackLight,
  sjmccBlackContrast,
  sjmccBlackDark,
  sjmccBlack,
  sjmccBlackLight,
  sjmccRedDark,
  sjmccWhiteLight,
  sjmccWhite,
  sjmccWhiteDark,
  sjmccWhiteContrast,
  afmRedLight,
  afmErrorOrange,
  afmErrorOrangeDark,
  afmLightRed,
  afmLightRedDark,
  afmLightRedLight,
  afmRed,
  afmRedContrast,
  afmRedDark,
  afmLightRedContrast,
  afmErrorOrangeLight,
  fmsLightBlue,
  fmsLightBlueDark,
  fmsLightBlueContrast,
  fmsLightBlueLight,
} from 'shared/utils/colors'

export function themeOptions(type: ThemeName): ThemeOptions {
  return {
    overrides: {
      MuiOutlinedInput: {
        input: { '&:-webkit-autofill': { '-webkit-box-shadow': `0 0 0 100px rgb(0,0,0,0) inset` } },
      },
      ...(type === 'sam-light'
        ? themeSamLightOverrides()
        : type === 'fms-light'
        ? themeFmsLightOverrides()
        : type === 'fms-dark'
        ? themeFmsDarkOverrides()
        : type === 'sjmcc-dark'
        ? themeSjmccDarkOverrides()
        : type === 'afm-dark'
        ? themeAfmDarkOverrides()
        : {}),
    },
    palette: themePalette(type),
  }
}

function themeSamLightOverrides(): ThemeOptions['overrides'] {
  return {
    MuiAppBar: {
      colorPrimary: { backgroundColor: samLightGreen, color: samLightGreenContrast },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: samLightGreen,
        color: samLightGreenContrast,
        '&:hover': { backgroundColor: samLightGreenDark },
      },
    },
    MuiLinearProgress: {
      barColorPrimary: { backgroundColor: samLightGreen },
      colorPrimary: { backgroundColor: lighten(samLightGreen, 0.62) },
    },
  }
}

function themeFmsLightOverrides(): ThemeOptions['overrides'] {
  return {}
}

function themeFmsDarkOverrides(): ThemeOptions['overrides'] {
  return {
    MuiAppBar: {
      colorPrimary: { backgroundColor: fmsBlue, color: fmsBlueContrast },
    },
    MuiLinearProgress: {
      barColorPrimary: { backgroundColor: fmsLightBlue },
      colorPrimary: { backgroundColor: fmsBlue },
    },
  }
}

function themePalette(type: ThemeName): PaletteOptions {
  return type === 'sam-light'
    ? themePaletteSamLight()
    : type === 'sam-dark'
    ? themePaletteSamDark()
    : type === 'fms-light'
    ? themePaletteFmsLight()
    : type === 'fms-dark'
    ? themePaletteFmsDark()
    : type === 'mxrs-light'
    ? themePaletteMxrsLight()
    : type === 'mxrs-dark'
    ? themePaletteMxrsDark()
    : type === 'afm-light'
    ? themePaletteAfmLight()
    : type === 'afm-dark'
    ? themePaletteAfmDark()
    : type === 'sjmcc-light'
    ? themePaletteSjmccLight()
    : type === 'sjmcc-dark'
    ? themePaletteSjmccDark()
    : assertNever(type)
}

function themePaletteSamLight(): PaletteOptions {
  return {
    ...themePaletteLightBase(),
    primary: {
      light: samGreenLight,
      main: samGreen,
      dark: samGreenDark,
      contrastText: samGreenContrast,
    },
    secondary: {
      light: samBlueLight,
      main: samBlue,
      dark: samBlueDark,
      contrastText: '#fff',
    },
    error: {
      main: samRed,
      dark: samRedDark,
      contrastText: '#fff',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteFmsLight(): PaletteOptions {
  return {
    ...themePaletteLightBase(),
    primary: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
    secondary: {
      light: fmsRedLight,
      main: fmsRed,
      dark: fmsRedDark,
      contrastText: '#fff',
    },
    error: {
      main: fmsRed,
      dark: fmsRedDark,
      contrastText: '#fff',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteMxrsLight(): PaletteOptions {
  return {
    ...themePaletteLightBase(),
    primary: {
      light: mxrsOrangeLight,
      main: mxrsOrange,
      dark: mxrsOrangeDark,
      contrastText: mxrsOrangeContrast,
    },
    secondary: {
      light: mxrsLightOrangeLight,
      main: mxrsLightOrange,
      dark: mxrsLightOrangeDark,
      contrastText: '#fff',
    },
    error: {
      main: mxrsRed,
      dark: mxrsRedDark,
      contrastText: '#fff',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteAfmLight(): PaletteOptions {
  return {
    ...themePaletteLightBase(),
    primary: {
      light: afmRedLight,
      main: afmRed,
      dark: afmRedDark,
      contrastText: afmRedContrast,
    },
    secondary: {
      light: afmLightRedLight,
      main: afmLightRed,
      dark: afmLightRedDark,
      contrastText: afmLightRedContrast,
    },
    error: {
      main: afmErrorOrange,
      dark: afmErrorOrangeDark,
      contrastText: '#000',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteSjmccLight(): PaletteOptions {
  return {
    ...themePaletteLightBase(),
    primary: {
      light: sjmccBlackLight,
      main: sjmccBlack,
      dark: sjmccBlackDark,
      contrastText: sjmccBlackContrast,
    },
    secondary: {
      light: sjmccLightBlackLight,
      main: sjmccLightBlack,
      dark: sjmccLightBlackDark,
      contrastText: '#fff',
    },
    error: {
      main: sjmccRed,
      dark: sjmccRedDark,
      contrastText: '#fff',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteLightBase() {
  return {
    type: 'light',
    deemphasized: {
      light: colors.grey[300],
      main: colors.grey[200],
      dark: colors.grey[100],
      contrastText: colors.grey[600],
    },
  } as const
}

function themePaletteSamDark(): PaletteOptions {
  return {
    ...themePaletteDarkBase(),
    primary: {
      light: samLightGreenLight,
      main: samLightGreen,
      dark: samLightGreenDark,
      contrastText: samLightGreenContrast,
    },
    secondary: {
      light: samYellowLight,
      main: samYellow,
      dark: samYellowDark,
      contrastText: '#000',
    },
    error: {
      main: samRedLight,
      dark: samRed,
      contrastText: '#000',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteFmsDark(): PaletteOptions {
  return {
    ...themePaletteDarkBase(),
    primary: {
      light: fmsLightBlueLight,
      main: fmsLightBlue,
      dark: fmsLightBlueDark,
      contrastText: fmsLightBlueContrast,
    },
    secondary: {
      light: fmsRedLight,
      main: fmsRed,
      dark: fmsRedDark,
      contrastText: '#000',
    },
    error: {
      main: fmsRedLight,
      dark: fmsRed,
      contrastText: '#000',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteMxrsDark(): PaletteOptions {
  return {
    ...themePaletteDarkBase(),
    primary: {
      light: mxrsOrangeLight,
      main: mxrsOrange,
      dark: mxrsOrangeDark,
      contrastText: mxrsOrangeContrast,
    },
    secondary: {
      light: mxrsLightOrangeLight,
      main: mxrsLightOrange,
      dark: mxrsLightOrangeDark,
      contrastText: mxrsLightOrangeContrast,
    },
    error: {
      main: mxrsRedLight,
      dark: mxrsRed,
      contrastText: '#000',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteAfmDark(): PaletteOptions {
  return {
    ...themePaletteDarkBase(),
    primary: {
      light: afmRedLight,
      main: afmRed,
      dark: afmRedDark,
      contrastText: afmRedContrast,
    },
    secondary: {
      light: afmLightRedLight,
      main: afmLightRed,
      dark: afmLightRedDark,
      contrastText: afmLightRedContrast,
    },
    error: {
      main: afmErrorOrangeLight,
      dark: afmErrorOrange,
      contrastText: '#000',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themePaletteSjmccDark(): PaletteOptions {
  return {
    ...themePaletteDarkBase(),
    text: {
      primary: '#cbcbcb',
    },
    background: {
      default: '#000',
      paper: '#151515',
    },
    primary: {
      light: sjmccWhiteLight,
      main: sjmccWhite,
      dark: sjmccWhiteDark,
      contrastText: sjmccWhiteContrast,
    },
    secondary: {
      light: sjmccLightBlackLight,
      main: sjmccLightBlack,
      dark: sjmccLightBlackDark,
      contrastText: sjmccLightBlackContrast,
    },
    error: {
      main: sjmccRedLight,
      dark: sjmccRed,
      contrastText: '#000',
    },
    fmsBlue: {
      light: fmsBlueLight,
      main: fmsBlue,
      dark: fmsBlueDark,
      contrastText: fmsBlueContrast,
    },
  }
}

function themeSjmccDarkOverrides(): ThemeOptions['overrides'] {
  return {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: sjmccRed,
        },
      },
    },
  }
}

function themeAfmDarkOverrides(): ThemeOptions['overrides'] {
  return {
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: afmRed,
        },
      },
    },
  }
}

function themePaletteDarkBase() {
  return {
    type: 'dark',
    deemphasized: {
      light: colors.grey[900],
      main: colors.grey[800],
      dark: colors.grey[700],
      contrastText: colors.grey[400],
    },
  } as const
}
export type ThemeName =
  | 'afm-light'
  | 'afm-dark'
  | 'sam-light'
  | 'sam-dark'
  | 'fms-light'
  | 'fms-dark'
  | 'mxrs-light'
  | 'mxrs-dark'
  | 'sjmcc-light'
  | 'sjmcc-dark'
