import { useAssociationContext } from 'app/themes/association-context'

export function PreviewBanner() {
  const context = useAssociationContext()

  return context.previewing ? (
    <div
      style={{
        position: 'fixed',
        overflow: 'hidden',
        zIndex: 10000,
        top: 0,
        right: 0,
        height: 80,
        width: 80,
        pointerEvents: 'none',
      }}
    >
      <div
        onClick={context.deactivatePreview}
        style={{
          backgroundColor: '#a00',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          marginLeft: '-10px',
          marginRight: '-50px',
          marginTop: '10px',
          transform: 'rotate(45deg)',
          boxShadow: '0 0 10px #888',
          cursor: 'pointer',
          pointerEvents: 'auto',
        }}
      >
        <div
          style={{
            border: '1px solid #faa',
            color: '#fff',
            display: 'block',
            fontWeight: 'bold',
            fontSize: '0.7em',
            margin: '1px 0',
            padding: '10px 50px',
            textAlign: 'center',
            textDecoration: 'none',
            textShadow: '0 0 5px #444',
          }}
        >
          Preview
        </div>
      </div>
    </div>
  ) : null
}
