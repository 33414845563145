import { Typography } from '@material-ui/core'
import { useAsync } from 'react-async-hook'
import { useParams } from 'react-router-dom'
import { callRacemanagerApi } from 'app/config/firebase'
import { ScanQRRedirectButton } from 'app/license/qr-code/ScanQRRedirectButton'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import { QRAdminButtons } from 'app/pages/licenses/qr-admin-buttons'
import { QRScannedTable } from 'app/pages/licenses/qr-scanned-table'
import { useAssociationContext } from 'app/themes/association-context'
import { QRScanEndpoint, QRScanResultWithHistory } from 'shared/api/interfaces'
import { t } from 'shared/i18n/current'
import { parseInt10 } from 'shared/utils/number'

export function QRLicenseV2() {
  const { licenseShortId, code } = useParams<{ licenseShortId: string; code: string }>()
  const { loading, error, result } = useLicenseContents({
    licenseShortId: parseInt10(licenseShortId),
    code,
  })

  return (
    <ElevatedBox>
      <ScanQRRedirectButton />
      <LoadingOrErrorBox loading={loading} error={error} />
      {result && <DisplayQRLicense scan={result} />}
    </ElevatedBox>
  )
}

function DisplayQRLicense({ scan: { result } }: { scan: QRScanResultWithHistory }) {
  return (
    <>
      <Typography variant="h4">{t().licensePdf.license}</Typography>
      <QRScannedTable result={result} type="license" />
      <QRAdminButtons result={result} />
    </>
  )
}

function useLicenseContents(props: { licenseShortId: number; code: string }) {
  const { licenseShortId, code } = props
  const context = useAssociationContext()

  return useAsync<QRScanResultWithHistory>(async () => {
    if (!licenseShortId || !code) throw new Error(t().licenses.licenseIdOrCodeMissing)
    const query = { licenseShortId, code }
    try {
      const result = await callRacemanagerApi<QRScanEndpoint>('qrScan', query)
      if (!result) throw new Error(t().licenses.licenseNotFound)
      if (result.association && context.association.id !== result.association)
        context.setAssociation(result.association)
      return { result }
    } catch (error) {
      throw error instanceof Error && error.message === t().licenses.licenseNotFound
        ? new Error(t().licenses.licenseNotFound)
        : error
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseShortId, code])
}
