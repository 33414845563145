import { Box, CircularProgress, Typography } from '@material-ui/core'
import { AssignmentLate, AssignmentTurnedIn } from '@material-ui/icons'
import assertNever from 'assert-never'
import { useLicenseTasksOverview } from 'app/db/db-hooks/main-db-hooks'
import { OrderedList } from 'app/layout/ordered-list'
import { useIsLargerThanSm } from 'app/layout/window-size-hooks'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { nextLicenseYear } from 'shared/data/license-config'
import { InscriptionTask, LicenseTask, LicenseTasksOverview } from 'shared/data/license-tasks-overview'
import { t } from 'shared/i18n/current'
import { RoutedButton } from 'utils/buttons/routed-button'

export function OverviewBox({ user }: { user: User }) {
  const { data, loadingOrError } = useLicenseTasksOverview(user, nextLicenseYear)

  if (loadingOrError || !data) return null

  return (
    <ElevatedBox title={t().accountOverview}>
      <VisualLicenseTasks tasks={data} />
    </ElevatedBox>
  )
}

interface VisualLicenseTasksProps {
  tasks: LicenseTasksOverview
  size?: 'sm' | 'lg'
  hideIfAllDone?: boolean
  admin?: boolean
}

export function VisualLicenseTasks(props: VisualLicenseTasksProps) {
  const { tasks, size = 'lg', hideIfAllDone = false, admin } = props
  const isLargerThanSm = useIsLargerThanSm()

  if (tasks.allDone && hideIfAllDone) return null

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexWrap={isLargerThanSm ? 'nowrap' : 'wrap'}
    >
      <Box p={1}>
        <CircularProgressWithLabel
          invalidated={false}
          done={tasks.tasksDone}
          total={tasks.tasksTotal}
          size={size}
        />
      </Box>

      <LicenseTaskList tasks={tasks} size={size} admin={!!admin} />
    </Box>
  )
}

interface LicenseTaskListProps {
  tasks: LicenseTasksOverview
  size?: 'sm' | 'lg'
  admin?: boolean
}

export function LicenseTaskList({ tasks, size, admin }: LicenseTaskListProps) {
  return (
    <Box flexGrow={1}>
      <OrderedList
        items={tasks.tasks
          .filter((task) => size === 'lg' || !task.done)
          .map((task) => ({
            key: task.type,
            icon: task.done ? (
              <AssignmentTurnedIn fontSize="large" color="primary" />
            ) : (
              <AssignmentLate fontSize="large" color="error" />
            ),
            text: taskDescription({ task, licensesOnline: true, admin: !!admin }),
          }))}
      />
    </Box>
  )
}

interface CircularProgressWithLabelProps {
  done: number
  total: number
  invalidated: boolean
  size: 'sm' | 'lg'
}

export function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  const { done, total, size, invalidated } = props
  const isLargerThanSm = useIsLargerThanSm()
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        color={invalidated ? 'secondary' : 'primary'}
        variant="determinate"
        size={circularProgressWithLabelSize(size, isLargerThanSm)}
        thickness={circularProgressWithLabelThickness(size, isLargerThanSm)}
        value={(100 / total) * done}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          align="center"
          variant={size === 'lg' || isLargerThanSm ? 'h5' : 'body1'}
          component="div"
          color="textSecondary"
        >
          {done}/{total}
          <br />
          {t().done}
        </Typography>
      </Box>
    </Box>
  )
}

function circularProgressWithLabelSize(
  size: 'sm' | 'lg',
  isLargerThanSm: boolean
): string | number | undefined {
  if (size === 'lg') return isLargerThanSm ? 250 : 150
  if (size === 'sm') return isLargerThanSm ? 150 : 100
  assertNever(size)
}

function circularProgressWithLabelThickness(
  size: 'sm' | 'lg',
  isLargerThanSm: boolean
): number | undefined {
  if (size === 'lg') return isLargerThanSm ? 10 : 7
  if (size === 'sm') return isLargerThanSm ? 7 : 4
  assertNever(size)
}

interface TaskDescriptionProps {
  task: LicenseTask | InscriptionTask
  licensesOnline: boolean
  admin: boolean
}

function taskDescription(props: TaskDescriptionProps) {
  const { task, licensesOnline, admin } = props
  const descriptionsDone = {
    personalData: t().overviewBox.personalDataCompleted,
    licenseSubmitted: t().overviewBox.licenseRequestCompleted,
    memberFees: t().overviewBox.samMembershipPaid,
    licenseFees: t().overviewBox.noOpenLicenseFees,
    emergency: t().emergencyDetails.emergencyDetailsCompleted,
    checkEmergency: t().emergencyDetails.emergencyDetailsChecked,
    healthCheck: t().overviewBox.healthCheckCompleted,
    insurance: t().overviewBox.insuranceConfirmationUploaded,
    photo: t().overviewBox.licensePhotoUploaded,
    bike: t().overviewBox.bikeAdded,
    inscriptionPayment: t().overviewBox.inscriptionPaid,
    adminConfirmationPending: t().overviewBox.adminConfirmationDone,
    inscriptionSubmitted: t().overviewBox.inscriptionSubmitted,
  }

  const descriptionsTodo = {
    personalData: (
      <RoutedButton disabled={admin} variant="outlined" to={routes.editProfile.to}>
        {t().personalDataBox.createPersonalData}
      </RoutedButton>
    ),
    licenseSubmitted: (
      <RoutedButton
        variant="outlined"
        to={routes.newLicense.generateTo(nextLicenseYear)}
        disabled={!licensesOnline || admin}
      >
        {t().overviewBox.requestLicense(nextLicenseYear)}
      </RoutedButton>
    ),
    memberFees: t().overviewBox.payMemberFees,
    licenseFees: t().overviewBox.payBills,
    emergency: (
      <RoutedButton disabled={admin} variant="outlined" to={routes.editEmergency.to}>
        {t().emergencyDetails.addEmergencyDetails}
      </RoutedButton>
    ),
    checkEmergency: (
      <RoutedButton disabled={admin} variant="outlined" to={routes.editEmergency.to}>
        {t().emergencyDetails.checkEmergencyDetails}
      </RoutedButton>
    ),
    healthCheck: (
      <RoutedButton disabled={admin} variant="outlined" to={routes.editHealthCheck.to}>
        {t().overviewBox.addHealthCheck}
      </RoutedButton>
    ),
    insurance: (
      <RoutedButton disabled={admin} variant="outlined" to={routes.editInsurance.to}>
        {t().overviewBox.addInsuranceConfirmation}
      </RoutedButton>
    ),
    photo: (
      <RoutedButton disabled={admin} variant="outlined" to={routes.editPhoto.to}>
        {t().overviewBox.uploadLicensePhoto}
      </RoutedButton>
    ),
    bike: (
      <RoutedButton disabled={admin} variant="outlined" to={routes.addBike.to}>
        {routes.addBike.text()}
      </RoutedButton>
    ),
    inscriptionPayment: t().overviewBox.payBills,
    adminConfirmationPending: t().overviewBox.adminConfirmationPending,
    inscriptionSubmitted: t().overviewBox.inscriptionNotSubmitted,
  }

  return task.done ? descriptionsDone[task.type] : descriptionsTodo[task.type]
}
