import { startCase, uniq } from 'lodash'
import { useLicenseCategoryIdsByAssociation } from 'app/db/db-hooks/main-db-hooks'
import { tableHeaders } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { User } from 'app/users/user'
import { categories, categoryById, categoryCommonName } from 'shared/data/categories-service'
import { Year } from 'shared/data/license-year'
import { currentLocale, t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { CategoryId } from 'shared/models/category'
import { countStrings, truthy } from 'shared/utils/array'
import { TableData } from 'shared/utils/table-data'

interface CategoriesKeyFiguresProps {
  user: User
}

export function CategoriesKeyFigures(_props: CategoriesKeyFiguresProps) {
  const year = useLicenseYear()
  const rawAssociation = useUserContext().associationAdmin
  const association = rawAssociation === false ? '-' : rawAssociation
  const { data, loading, error } = useLicenseCategoryIdsByAssociation(association)

  return (
    <>
      <TableBox
        title={t().licenses.licenseRequests}
        loading={loading}
        error={error}
        data={data && renderTotalFigures(data)}
      />
      <TableBox
        title={t().licenses.licenseRequestsByCategoryType}
        loading={loading}
        error={error}
        data={data && renderCategoryTypeFigures(data, association, year)}
      />
      <TableBox
        title={t().licenses.licenseRequestsByCategory}
        loading={loading}
        error={error}
        data={data && renderCategoryFigures(data, association, year)}
      />
    </>
  )
}

function renderTotalFigures(data: Data): TableData {
  return {
    ids: ['total'],
    rawData: undefined,
    headers: tableHeaders([
      '',
      { align: 'right', value: t().licenses.confirmed },
      { align: 'right', value: t().licenses.unconfirmed },
      { align: 'right', value: t().licenses.totalLicenseRequests },
    ]),
    contents: [
      ['Total', data.approved.length, data.drafts.length, data.approved.length + data.drafts.length],
    ],
  }
}

function renderCategoryTypeFigures(data: Data, association: LocalAssociation, year: Year): TableData {
  const approvedCount = categoryTypeCounts(data.approved)
  const draftsCount = categoryTypeCounts(data.drafts)
  const allTypes = uniq(
    categoriesOfAssociation(association, year).map((category) => category.type)
  ).sort()
  return {
    headers: tableHeaders([
      'Sparte',
      { align: 'right', value: t().licenses.confirmed },
      { align: 'right', value: t().licenses.unconfirmed },
      { align: 'right', value: t().licenses.totalLicenseRequests },
    ]),
    contents: allTypes.map((type) => [
      startCase(type),
      approvedCount.get(type) || 0,
      draftsCount.get(type) || 0,
      (approvedCount.get(type) || 0) + (draftsCount.get(type) || 0),
    ]),
    ids: allTypes,
    rawData: undefined,
  }
}

function categoryTypeCounts(categoryIds: CategoryId[]) {
  const categoryTypes = categoryIds
    .map((id) => categoryById(id))
    .filter(truthy)
    .map((category) => category.type)
  return countStrings(categoryTypes)
}

function renderCategoryFigures(data: Data, association: LocalAssociation, year: Year): TableData {
  const approvedCount = countStrings(data.approved)
  const draftsCount = countStrings(data.drafts)
  return {
    ids: categoriesOfAssociation(association, year).map(({ id }) => id),
    headers: tableHeaders([
      'Sparte',
      'Kategorie',
      { align: 'right', value: t().licenses.confirmed },
      { align: 'right', value: t().licenses.unconfirmed },
      { align: 'right', value: t().licenses.totalLicenseRequests },
    ]),
    contents: categoriesOfAssociation(association, year).map((category) => [
      startCase(category.type),
      categoryCommonName(category),
      approvedCount.get(category.id) || 0,
      draftsCount.get(category.id) || 0,
      (approvedCount.get(category.id) || 0) + (draftsCount.get(category.id) || 0),
    ]),
    rawData: undefined,
  }
}

function categoriesOfAssociation(association: undefined | AssociationID | '-', year: Year) {
  return categories(currentLocale())
    .filter((category) => category.year === year)
    .filter(
      (category) => association !== '-' && (!association || category.associations.includes(association))
    )
}

type LocalAssociation = AssociationID | undefined | '-'

interface Data {
  drafts: CategoryId[]
  approved: CategoryId[]
}
