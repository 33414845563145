import type { FormikErrors } from 'formik'

interface FormErrorsProps<T> {
  errors: FormikErrors<T>
}

export function FormErrors<T>({ errors }: FormErrorsProps<T>) {
  return (
    <ul>
      {mapErrorsToStrings(errors).map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  )
}

function mapErrorsToStrings<T>(value: FormikErrors<T>): string[] {
  if (!value) return []
  if (typeof value === 'string') return [value]
  if (value instanceof Array) return value.flatMap(mapErrorsToStrings)
  if (typeof value === 'object')
    return Object.values(value).flatMap((value) => mapErrorsToStrings(value as FormikErrors<T>))
  return []
}
