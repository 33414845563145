import { Typography } from '@material-ui/core'
import { User } from 'app/users/user'
import { sectionName } from 'shared/data/sections'
import { t } from 'shared/i18n/current'
import { PersonalData } from 'shared/models/personal-data'
import { parseDate, formatDateDe } from 'shared/utils/date'

export function ShowPersonalData({
  personalData,
  user,
}: {
  personalData: PersonalData | undefined
  user: User
}) {
  return personalData ? (
    <>
      <Typography>
        {personalData.firstName} {personalData.lastName} (
        {formatDateDe(parseDate(personalData?.birthdate))}), {personalData.street}, {personalData.zip}{' '}
        {personalData.place}, {personalData.country}
      </Typography>

      <Typography>
        {user.email}, {personalData.phone}
      </Typography>

      {personalData.afmSection && (
        <Typography>
          {t().afmSection.label}: {sectionName(personalData.afmSection)}
        </Typography>
      )}

      {personalData.fmsSection && (
        <Typography>
          {t().fmsSection.label}: {sectionName(personalData.fmsSection)}
        </Typography>
      )}

      {personalData.samSection && personalData.samMemberNumber && (
        <Typography>
          {t().samSection.label}: {sectionName(personalData.samSection)},{' '}
          {t().personalDataBox.samMembershipNumber}:{' '}
          {personalData.newSamMember ? '-' : personalData.samMemberNumber}
        </Typography>
      )}
    </>
  ) : (
    <Typography>
      {t().email}: {user.email}
    </Typography>
  )
}
