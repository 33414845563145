import { combineFire3, useRecords } from 'app/db/db-hooks/db-hook-helpers'
import {
  useLicenseTasksOverview,
  useSportEventWithInscription,
  useApprovedLicense,
  useInscriptionsByRider,
} from 'app/db/db-hooks/main-db-hooks'
import { nextLicenseYear } from 'shared/data/license-config'
import { DayCategory, DayCategoryID } from 'shared/db/day-category'
import { PublicInscriptionStatus, UserQuery } from 'shared/db/db'
import { SportEventId } from 'shared/db/sport-event-id'
import { Category, CategoryId } from 'shared/models/category'
import { strictMinBy } from 'shared/utils/array'
import { DateString } from 'shared/utils/date'

export function useLatestInscriptionOfRider(user: UserQuery) {
  const { data, ...rest } = useInscriptionsByRider(user)
  return { data: strictMinBy(data, (inscription) => inscription.date), ...rest }
}

export function useNewInscriptionFormData({
  user,
  sportEventId,
  category,
  date,
}: {
  sportEventId: SportEventId
  category: Category | DayCategory
  date: DateString
  user: UserQuery
}) {
  const categoryId = category.id
  return combineFire3(
    useSportEventWithInscription({ user, sportEventId, categoryId, date }),
    useLicenseTasksOverview(user, nextLicenseYear),
    useApprovedLicense(
      user,
      nextLicenseYear,
      category.categoryType === 'licenseCategory' ? category.id : undefined
    ),
    (data, licenseTasks, license) => ({
      sportEvent: data.sportEvent,
      inscription: data.inscription,
      bikes: data.bikes,
      licenseTasks,
      license,
    })
  )
}

export function usePublicInscriptionStatistics(props: {
  sportEvent: SportEventId
  date: DateString
  category: CategoryId | DayCategoryID
}) {
  const { sportEvent, date, category } = props
  const { data } = useRecords<PublicInscriptionStatus>(
    `sportEventInscriptionsV2/publicStatistics/${sportEvent}/${date}/${category}`
  )
  return Object.values(data || {}).reduce(
    (acc, status) => ({
      paid: acc.paid + (status.paid ? 1 : 0),
      enlisted: acc.enlisted + (status.enlisted ? 1 : 0),
    }),
    { paid: 0, enlisted: 0 }
  )
}
