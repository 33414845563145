import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ScanQRButton } from 'app/license/qr-code/ScanQRButton'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'

export function ScanQRRedirectButton() {
  const history = useHistory()
  return (
    <Box mb={2}>
      <ScanQRButton
        fullWidth
        primary
        text={t().qrScanLong}
        match={(url) => (isValidRacemanagerUrl(url) ? url : undefined)}
        onSuccess={(url: string) => url && history.push(new URL(url).pathname)}
      />
    </Box>
  )
}
function isValidRacemanagerUrl(url: string) {
  return [...routes.qrLicenseV2.validStarts, ...routes.qrPitLane.validStarts].some((validStart) =>
    url.startsWith(validStart)
  )
}
