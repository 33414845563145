import { categoryOfAssociationRequired } from 'shared/data/categories-service'
import { DayCategory } from 'shared/db/day-category'
import { Documents } from 'shared/db/db'
import {
  dayCategoryInscriptionTasks,
  dayLicenseYearCategoryInscriptionTasks,
  licenseInscriptionTasks,
  taskShortName,
} from 'shared/license/license-tasks'
import {
  Inscription,
  isDayInscriptionDayCategoryInscriptionOrDraft,
  isDayInscriptionYearCategoryInscriptionOrDraft,
  isEnlistedInscription,
} from 'shared/sport-events/sport-events'

export interface OpenInscriptionTasksShortNameProps {
  documents: Documents | undefined
  inscription: Inscription
  dayCategory: DayCategory | undefined
}

export function openInscriptionTasksShortName(props: OpenInscriptionTasksShortNameProps) {
  const tasks = openInscriptionTasks(props)
  return tasks
    ? tasks.tasks
        .filter((task) => !task.done)
        .map((task) => taskShortName(task.type))
        .join(', ')
    : ''
}

export function openInscriptionTasks(props: OpenInscriptionTasksShortNameProps) {
  const { inscription, documents, dayCategory } = props
  if (!isEnlistedInscription(inscription) || !documents) return undefined

  return isDayInscriptionDayCategoryInscriptionOrDraft(inscription) && dayCategory
    ? dayCategoryInscriptionTasks({
        inscription,
        documents,
        category: dayCategory,
      })
    : isDayInscriptionYearCategoryInscriptionOrDraft(inscription)
    ? dayLicenseYearCategoryInscriptionTasks({
        inscription,
        documents,
        category: categoryOfAssociationRequired(inscription.category, inscription.association),
      })
    : inscription.type === 'enlistedLicenseInscription'
    ? licenseInscriptionTasks({
        inscription,
        documents,
        category: categoryOfAssociationRequired(inscription.category, inscription.association),
      })
    : undefined
}
