export function withTimeout<T>(ms: number, fn: () => Promise<T>): Promise<T> {
  return Promise.race([timeOutAfter<T>(ms), fn()])
}

export async function timeOutAfter<T>(ms: number): Promise<T> {
  await sleep(ms)
  throw new Error(`Request timed out after ${ms}ms`)
}

export function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms))
}
