import { useAllTransactionsMissingUser } from 'app/db/db-hooks/main-db-hooks'
import { tableHeaders } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { useUserContext } from 'app/themes/user-context'
import { DisabledForAssociationAdmin } from 'app/users/disabled-for-association-admin'
import { t } from 'shared/i18n/current'

export function TransactionsWithoutDocuments() {
  const userContext = useUserContext()
  const { loading, error, loadingOrError, data } = useAllTransactionsMissingUser()

  const title = t().routes.transactionsWithoutUser
  if (userContext.associationAdmin) return <DisabledForAssociationAdmin title={title} />

  return (
    <TableBox
      title={title}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: tableHeaders([t().adminID, t().uid, t().details]),
          contents: data.map((t) => [t.byUidName, t.uidName, JSON.stringify(t.transaction)]),
          rawData: data.map((row) => JSON.stringify(row)),
        }
      }
    />
  )
}
