import { DB } from 'shared/db/db'
import { Inscription } from 'shared/sport-events/sport-events'

export async function updateDayInscriptionPublicStatistics(db: DB, inscription: Inscription) {
  if (
    inscription.type === 'enlistedDayInscriptionDayCategory' ||
    inscription.type === 'enlistedDayInscriptionDayCategoryDraft' ||
    inscription.type === 'unlistedDayLicenseInscription'
  )
    await db.updateInscriptionPublicStatistics(inscription)
}
