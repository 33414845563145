import { db } from 'app/db/frontend-db'
import {
  UploadPage,
  useDownloadFilePath,
  NewUpload,
  uploadFile,
  toUploadMetadata,
  ensureOldDataIsDeleted,
  extension,
} from 'app/storage/storage'
import { UserQuery } from 'shared/db/db'
import { updateHealthCheckUploadStatus } from 'shared/upload/upload-service'

export function useHealthCheckFilePath(page: UploadPage) {
  return useDownloadFilePath(healthCheckNameRef(page), page.metadata)
}

export async function uploadHealthCheck(page: NewUpload) {
  const metadata = await uploadFile(page, healthCheckPageRef(page))
  await db.setHealthCheckUpload(page.user, page.page, toUploadMetadata(metadata))
  await updateHealthCheckUploadStatus(db, page)
}

export async function deleteHealthCheck({ user, admin, page, metadata }: UploadPage) {
  await ensureOldDataIsDeleted(metadata?.fullPath)
  await db.deleteHealthCheckUpload(user, page)
  await updateHealthCheckUploadStatus(db, { user, admin })
}

function healthCheckPageRef({ user, page, file }: NewUpload) {
  return rawHealthCheckPageRef({ user, page, ext: extension(file) })
}

function rawHealthCheckPageRef({ user, page, ext }: { user: UserQuery; page: number; ext: string }) {
  return `healthChecks/${user.uid}/page-${page}${ext ? '.' : ''}${ext}`
}

function healthCheckNameRef({ user, metadata }: UploadPage) {
  return `healthChecks/${user.uid}/${metadata?.name}`
}
