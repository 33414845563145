import { Box, Button, Grid, LinearProgress } from '@material-ui/core'
import { Formik, Form } from 'formik'
import {
  loadInitialPersonalDataValues,
  PersonalDataFormFields,
  personalDataSchema,
} from 'app/personal-data/personal-data-form-fields'
import { t } from 'shared/i18n/current'
import { PersonalData, PersonalDataFormData } from 'shared/models/personal-data'
import { formatDate, parseDate } from 'shared/utils/date'

interface PersonalDataFormProps {
  email: string
  lockMemberFields: boolean
  disableSamFields?: boolean
  initialValues: PersonalData | undefined
  onSubmit: (data: PersonalData) => Promise<void>
  onCancel?: () => void
  submitLabel?: string
  autoHighlightSubmit?: true
}

export function PersonalDataForm(props: PersonalDataFormProps) {
  const { submitLabel, email, initialValues, onSubmit, onCancel, lockMemberFields, disableSamFields } =
    props
  const loadedInitialValues: PersonalDataFormData = initialValues
    ? personalDataToFormData(initialValues)
    : loadInitialPersonalDataValues()
  loadedInitialValues.email = email

  return (
    <>
      <Formik
        initialValues={loadedInitialValues}
        enableReinitialize={true}
        validationSchema={personalDataSchema(!!disableSamFields)}
        onSubmit={async (values, { setSubmitting }) => {
          if (values.newSamMember) delete values.samMemberNumber
          await onSubmit(formDataToPersonalData(values, email))
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, values, errors, touched, dirty }) => (
          <Form>
            <PersonalDataFormFields
              errors={errors}
              disableSamFields={disableSamFields}
              prefix=""
              lockMemberFields={lockMemberFields}
              values={values}
              parentsAgree={touched.parentsAgree && errors.parentsAgree}
            />

            <Box mt={2}>
              <Grid container spacing={1}>
                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                )}

                {onCancel && (
                  <Grid item xs={6}>
                    <Button fullWidth disabled={isSubmitting} onClick={onCancel}>
                      {t().buttons.cancel}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={onCancel ? 6 : 12}>
                  <Button
                    fullWidth
                    variant={props.autoHighlightSubmit && !dirty ? 'outlined' : 'contained'}
                    color={props.autoHighlightSubmit && !dirty ? 'default' : 'primary'}
                    disabled={(props.autoHighlightSubmit && !dirty) || isSubmitting}
                    onClick={submitForm}
                  >
                    {isSubmitting ? t().buttons.saving : submitLabel || t().buttons.continue}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

export function personalDataToFormData(initialValues: PersonalData): PersonalDataFormData {
  return {
    ...initialValues,
    birthdate: parseDate(initialValues.birthdate),
    newSamMember: !!initialValues.newSamMember,
    samMemberNumber: initialValues.samMemberNumber || 0,
    samSection: initialValues.samSection || 0,
    afmSection: initialValues.afmSection || 0,
    fmsSection: initialValues.fmsSection || 0,
  }
}

export function formDataToPersonalData(values: PersonalDataFormData, email: string): PersonalData {
  return {
    ...values,
    email,
    birthdate: formatDate(values.birthdate),
    samMemberNumber: values.samMemberNumber || (null as any as undefined),
    samSection: values.samSection || (null as any as undefined),
    afmSection: values.afmSection || (null as any as undefined),
    fmsSection: values.fmsSection || (null as any as undefined),
  }
}
