import { db } from 'app/db/frontend-db'
import {
  UploadPage,
  useDownloadFilePath,
  NewUpload,
  uploadFile,
  toUploadMetadata,
  ensureOldDataIsDeleted,
  extension,
} from 'app/storage/storage'
import { UserQuery } from 'shared/db/db'
import { updatePhotoUploadStatus } from 'shared/upload/upload-service'

export function usePhotoPath(page: UploadPage) {
  return useDownloadFilePath(photoNameRef(page), page.metadata)
}

export async function uploadPhoto(newUpload: NewUpload) {
  const metadata = await uploadFile(newUpload, photoRef(newUpload))
  await db.setPhotoUpload(newUpload.user, toUploadMetadata(metadata))
  await updatePhotoUploadStatus(db, newUpload)
}

export async function deletePhoto({ user, admin, metadata }: UploadPage) {
  await ensureOldDataIsDeleted(metadata?.fullPath)
  await db.deletePhotoUpload(user)
  await updatePhotoUploadStatus(db, { user, admin })
}

function photoRef({ user, page, file }: NewUpload) {
  return rawPhotoRef({ user, page, ext: extension(file) })
}

function rawPhotoRef({ user, ext }: { user: UserQuery; page: number; ext: string }) {
  return `licensePhotos/${user.uid}/upload${ext ? '.' : ''}${ext}`
}

function photoNameRef({ user, metadata }: UploadPage) {
  return `licensePhotos/${user.uid}/${metadata?.name}`
}
