import { Button } from '@material-ui/core'
import type { ButtonProps } from '@material-ui/core'
import { ReactNode } from 'react'
import { ButtonWithTooltip } from 'app/layout/button-with-tooltip'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { useCatchAndDisplayErrorOrSuccess } from 'utils/snackbar'

interface ButtonWithConfirmationProps extends ButtonProps {
  onConfirm: () => Promise<void | boolean | undefined> | void
  title: string
  tooltip?: string
  children?: ReactNode
  confirmation: string | ReactNode
  fullWidth?: boolean
  fullWidthDialog?: boolean
  disableConfirmButton?: boolean
}

export function ButtonWithConfirmation(props: ButtonWithConfirmationProps) {
  const {
    onConfirm,
    children,
    title,
    confirmation,
    tooltip,
    fullWidth = true,
    fullWidthDialog = false,
    disableConfirmButton = false,
    variant = 'outlined',
    ...rest
  } = props
  const dialog = useDialog()
  return (
    <>
      {tooltip ? (
        <ButtonWithTooltip
          tooltip={tooltip}
          variant={variant}
          fullWidth={fullWidth}
          {...rest}
          onClick={() => dialog.open()}
        >
          {children || title}
        </ButtonWithTooltip>
      ) : (
        <Button variant={variant} fullWidth={fullWidth} {...rest} onClick={() => dialog.open()}>
          {children || title}
        </Button>
      )}
      <ConfirmDialog
        disabled={disableConfirmButton}
        title={title}
        dialog={dialog}
        onConfirm={onConfirm}
        buttonText={title}
        fullWidth={fullWidthDialog}
      >
        {confirmation}
      </ConfirmDialog>
    </>
  )
}

interface IconButtonWithConfirmationAndErrorHandlingProps {
  onConfirm: () => Promise<void | boolean | undefined> | void
  title: string
  info?: string | ReactNode
  disabled?: boolean
  tooltip: string
  size?: 'small'
  confirmation: string
  children: ReactNode
}

export function IconButtonWithConfirmationAndErrorHandling(
  props: IconButtonWithConfirmationAndErrorHandlingProps
) {
  const { onConfirm, tooltip, confirmation, children, ...rest } = props
  const showError = useCatchAndDisplayErrorOrSuccess()
  const onConfirmWithErrorHandling = () => showError(onConfirm())

  return (
    <IconButtonWithConfirmation
      confirmation={confirmation}
      tooltip={tooltip}
      onConfirm={onConfirmWithErrorHandling}
      {...rest}
    >
      {children}
    </IconButtonWithConfirmation>
  )
}

interface IconButtonWithConfirmationProps {
  onConfirm?: () => Promise<void | boolean | undefined> | void
  title: string
  tooltip: string
  confirmation: string
  disabled?: boolean
  children: ReactNode
  size?: 'small' | 'medium'
  info?: string | ReactNode
}

export function IconButtonWithConfirmation(props: IconButtonWithConfirmationProps) {
  const { onConfirm, title, tooltip, disabled, confirmation, info, children, size } = props
  const dialog = useDialog()
  return (
    <>
      <IconButtonWithTooltip
        size={size}
        tooltip={tooltip}
        disabled={disabled}
        onClick={() => dialog.open()}
      >
        {children}
      </IconButtonWithTooltip>
      <ConfirmDialog
        title={title}
        dialog={dialog}
        onConfirm={onConfirm}
        buttonText={onConfirm ? title : ''}
      >
        {confirmation}
        {info && typeof info === 'string' && (
          <>
            <br />
            <br />
            {info}
          </>
        )}
        {!!info && typeof info !== 'string' && info}
      </ConfirmDialog>
    </>
  )
}
