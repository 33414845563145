import { Box, Typography } from '@material-ui/core'
import type { FormikErrors } from 'formik'
import { PersonalDataFormFields } from 'app/personal-data/personal-data-form-fields'
import { useAdminContext } from 'app/themes/admin-context'
import { t } from 'shared/i18n/current'
import { PersonalDataFormData } from 'shared/models/personal-data'

interface AdministratePersonalDataProps {
  values: PersonalDataFormData
  parentsAgree: string | false | undefined
  errors: FormikErrors<PersonalDataFormData> | undefined
}

export function AdministratePersonalData(props: AdministratePersonalDataProps) {
  const { values, parentsAgree, errors } = props
  const adminContext = useAdminContext()
  return (
    <Box mb={2} mt={4}>
      <Typography variant="h4" component="h3" gutterBottom>
        {t().personalData}
      </Typography>
      <PersonalDataFormFields
        errors={errors}
        prefix="personalData."
        parentsAgree={parentsAgree}
        lockMemberFields={false}
        values={values}
        administration
        disableSamFields={!adminContext.matchesAssociation('sam')}
      />
    </Box>
  )
}
