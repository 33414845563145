import { Grid } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { parseISO } from 'date-fns'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { db } from 'app/db/frontend-db'
import { samFields } from 'app/forms/fields'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, UseDialog, useDialog } from 'app/layouts/confirm-dialog'
import { updateLicenseBooking } from 'shared/billing/bookings-service'
import { LicenseBooking } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { Dig } from 'shared/utils/tsc'
import { Loading } from 'utils/loading'
import { useSuccessSnackbar } from 'utils/snackbar'

interface EditLicenseBookingButtonProps {
  booking: LicenseBooking
}

export function EditLicenseBookingButton({ booking }: EditLicenseBookingButtonProps) {
  const dialog = useDialog()
  return (
    <>
      <IconButtonWithTooltip tooltip={t().licenses.editLicenseBooking} onClick={() => dialog.open()}>
        <Edit />
      </IconButtonWithTooltip>
      {dialog.isOpen && <EditLicenseBookingForm dialog={dialog} booking={booking} />}
    </>
  )
}

interface EditLicenseBookingFormProps {
  booking: LicenseBooking
  dialog: UseDialog
}

function EditLicenseBookingForm({ dialog, booking }: EditLicenseBookingFormProps) {
  const showSuccessMessage = useSuccessSnackbar()

  const initialValues: LicenseBookingFormData = {
    date: parseISO(booking.date),
    internalRemarks: booking.internalRemarks || '',
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={licenseBookingSchema()}
        validateOnMount
        onSubmit={async (values, { setSubmitting }) => {
          const licenseBooking = {
            ...booking,
            date: values.date.toISOString(),
            internalRemarks: values.internalRemarks || null,
            updatedAt: new Date().toISOString(),
          }
          await updateLicenseBooking(db, licenseBooking)
          showSuccessMessage(t().alerts.dataSaved)
          setSubmitting(false)
        }}
      >
        {(form) => (
          <ConfirmDialog
            maxWidth="xl"
            fullWidth
            title={t().licenses.editLicenseBooking}
            buttonText={form.isSubmitting ? t().buttons.saving : t().buttons.saveLicenseBooking}
            dialog={dialog}
            onConfirm={() => form.submitForm()}
            disabled={form.isSubmitting || !form.isValid}
          >
            <Form>
              <LicenseBookingFields />
            </Form>
            <Loading loading={form.isSubmitting} />
          </ConfirmDialog>
        )}
      </Formik>
    </>
  )
}

export function LicenseBookingFields() {
  const fields = samFields().licenseBooking

  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={12}>
        {fields.date.field()}
      </Grid>
      <Grid item md={6} xs={12}>
        {fields.internalRemarks.field()}
      </Grid>
    </Grid>
  )
}

export function licenseBookingSchema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().licenseBooking).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['licenseBooking']>
  return Yup.object().defined().shape(validations)
}

interface LicenseBookingFormData {
  date: Date
  internalRemarks: string
}
