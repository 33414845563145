import { createContext, useContext } from 'react'
import { UseWithObj } from 'app/db/db-hooks/db-hook-helpers'
import { useUserContext } from 'app/themes/user-context'
import { DocumentsWithUid } from 'shared/db/db'
import { UserId } from 'shared/db/user-id'

export const DocumentsContext = createContext(initialState())

export function useAllDocuments() {
  return useContext(DocumentsContext).documents
}

export function useDocumentsLoading() {
  const user = useUserContext()
  const context = useContext(DocumentsContext)
  return user.preloadDocuments && !context.initiallyLoaded
}

function initialState(): DocumentsState {
  return {
    documents: { data: {}, error: undefined, loading: true, loadingOrError: false },
    initiallyLoaded: false,
  }
}

export interface DocumentsState {
  documents: UseWithObj<Record<UserId, DocumentsWithUid>>
  initiallyLoaded: boolean
}
