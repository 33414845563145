import { Edit } from '@material-ui/icons'
import { parseISO } from 'date-fns'
import { Form, Formik } from 'formik'
import { useAllManualPaymentTags } from 'app/db/db-hooks/financial-db-hooks'
import { db } from 'app/db/frontend-db'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, UseDialog, useDialog } from 'app/layouts/confirm-dialog'
import {
  ManualPaymentFields,
  ManualPaymentFormData,
  manualPaymentSchema,
} from 'app/pages/admin/manual-payments/manual-payment-form'
import { useUserContext } from 'app/themes/user-context'
import { updateManualPayment } from 'shared/billing/payment-service'
import { ManualPayment } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { FriendlyError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { useSuccessSnackbar } from 'utils/snackbar'

interface EditManualPaymentButtonProps {
  payment: ManualPayment
}

export function EditManualPaymentButton({ payment }: EditManualPaymentButtonProps) {
  const dialog = useDialog()
  return (
    <>
      <IconButtonWithTooltip tooltip={t().payments.editManualPaymentTitle} onClick={() => dialog.open()}>
        <Edit />
      </IconButtonWithTooltip>
      {dialog.isOpen && <EditManualPaymentForm dialog={dialog} payment={payment} />}
    </>
  )
}

type EditManualPaymentFormProps = EditManualPaymentButtonProps & {
  dialog: UseDialog
}

function EditManualPaymentForm({ dialog, payment }: EditManualPaymentFormProps) {
  const userContext = useUserContext()
  const showSuccessMessage = useSuccessSnackbar()

  const manualPaymentTags = useAllManualPaymentTags()

  const initialValues: ManualPaymentFormData = {
    amount: payment.amount,
    date: parseISO(payment.date),
    tag: payment.tag,
    association: payment.association,
    internalRemarks: payment.internalRemarks || '',
  }

  return (
    <>
      <Loading loading={manualPaymentTags.loading} />
      <FriendlyError error={manualPaymentTags.error} />
      {!manualPaymentTags.loadingOrError && (
        <Formik
          initialValues={initialValues}
          validationSchema={manualPaymentSchema()}
          validateOnMount
          onSubmit={async (values, { setSubmitting }) => {
            const manualPayment: ManualPayment = {
              ...payment,
              date: values.date.toISOString(),
              tag: values.tag || '',
              amount: values.amount,
              association: values.association,
              internalRemarks: values.internalRemarks || null,
              updatedAt: new Date().toISOString(),
            }
            await updateManualPayment(db, manualPayment, userContext.uid || '')
            showSuccessMessage(t().alerts.dataSaved)
            setSubmitting(false)
          }}
        >
          {(form) => {
            const paymentName =
              (form.values.amount || 0) < 0 ? t().payments.refund : t().financials.payment
            return (
              <ConfirmDialog
                maxWidth="xl"
                fullWidth
                title={t().payments.editManualPayment(paymentName)}
                buttonText={
                  form.isSubmitting ? t().buttons.saving : t().payments.saveManualPayment(paymentName)
                }
                dialog={dialog}
                onConfirm={() => form.submitForm()}
                disabled={form.isSubmitting || !form.isValid}
              >
                <Form>
                  <ManualPaymentFields form={form} />
                </Form>
                <Loading loading={form.isSubmitting} />
              </ConfirmDialog>
            )
          }}
        </Formik>
      )}
    </>
  )
}
