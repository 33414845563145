import { Typography } from '@material-ui/core'
import { ReactChild } from 'react'

export function SectionList({ contents }: { contents: [ReactChild, ReactChild][] }) {
  return (
    <>
      {contents.map(([title, value], index) => (
        <section key={index}>
          <Typography component="h4" variant="subtitle2">
            {title}
          </Typography>
          {value}
        </section>
      ))}
    </>
  )
}
