import { categoryByIdRequired, commonCategoryName, isCategoryId } from 'shared/data/categories-service'
import { DayCategory, DayCategoryID } from 'shared/db/day-category'
import { CategoryId } from 'shared/models/category'
import { DayCategoryFormData } from 'shared/sport-events/sport-event-categories-service'
import { SportEvent } from 'shared/sport-events/sport-events'
import { truthy, tryRemoveFirst } from 'shared/utils/array'
import { DateString } from 'shared/utils/date'

export function calculateCategoryMovements(props: InscriptionMovementsProps): CategoryMovement[] {
  const { before, after, initialDayCategories } = props
  const beforeLicenseCategories = [
    ...Object.entries(before.licenseCategoryDates || {}),
    ...Object.entries(before.dayCategoryDates || {}),
  ].map(([category, dates]) => ({ category, dates }))
  const afterClone = [...after.dates]

  return beforeLicenseCategories.flatMap(({ category, dates }) =>
    dates
      .map((date) => ({ date, category }))
      .map(({ category, date: beforeDate }) => {
        if (isCategoryId(category) && !after.licenseCategoryIds.includes(category))
          return createCategoryDeletionMovement(beforeDate, category, initialDayCategories)

        if (
          !isCategoryId(category) &&
          !after.dayCategories.some((dayCategory) => dayCategory.id === category)
        )
          return createCategoryDeletionMovement(beforeDate, category, initialDayCategories)

        const foundAfterCategory = tryRemoveFirst(
          afterClone,
          (afterEntry) => afterEntry.categoryId === category
        )

        if (!foundAfterCategory)
          return createCategoryDeletionMovement(beforeDate, category, initialDayCategories)

        const afterDate = foundAfterCategory.date

        return beforeDate === afterDate
          ? undefined
          : beforeDate && afterDate
          ? {
              categoryId: category,
              categoryName: categoryName(category, initialDayCategories),
              from: beforeDate,
              to: afterDate,
            }
          : beforeDate
          ? {
              categoryId: category,
              categoryName: categoryName(category, initialDayCategories),
              from: beforeDate,
              to: deleteInscriptionMovement,
            }
          : undefined
      })
      .filter(truthy)
  )
}

interface InscriptionMovementsProps {
  before: SportEvent
  initialDayCategories: DayCategory[]
  after: {
    dates: { categoryId: CategoryId | DayCategoryID; date: string }[]
    licenseCategoryIds: CategoryId[]
    dayCategories: DayCategoryFormData[]
  }
}

function createCategoryDeletionMovement(
  date: string,
  category: string,
  initialDayCategories: DayCategory[]
): CategoryMovement {
  return {
    categoryId: category,
    categoryName: categoryName(category, initialDayCategories),
    from: date,
    to: deleteInscriptionMovement,
  }
}

function categoryName(categoryId: DayCategoryID | CategoryId | 'unknown', dayCategories: DayCategory[]) {
  if (isCategoryId(categoryId)) return commonCategoryName(categoryByIdRequired(categoryId))

  const category = dayCategories.find((category) => category.id === categoryId)
  return category?.name || categoryId
}

export interface CategoryMovement {
  categoryId: CategoryId | DayCategoryID
  categoryName: string
  from: DateString
  to: DateString | typeof deleteInscriptionMovement
}

export const deleteInscriptionMovement = 'deleteInscriptions'
