import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useCallback, useState } from 'react'
import { MaybeFBError } from 'app/db/db-hooks/db-hook-helpers'
import { ErrorWithDetails } from 'shared/utils/errors'
import { friendlyError } from 'shared/utils/friendly-error'
import { prettyJSON } from 'utils/debug'

interface FriendlyErrorProps {
  error: string | Error | MaybeFBError | firebase.default.auth.Error | null | undefined | unknown
  onClose?: () => void
  pb?: number
}

export function FriendlyError({ onClose, error, pb }: FriendlyErrorProps) {
  if (!error) return null

  const errorEl = <FriendlyErrorAlways error={error} onClose={onClose} />
  return pb ? <Box pb={pb}>{errorEl}</Box> : errorEl
}

export function FriendlyErrorAlways({ error, onClose }: { error: unknown; onClose?: () => void }) {
  if (!error) return null
  return <FriendlyErrorForCollapse error={error} onClose={onClose} />
}

export function FriendlyErrorForCollapse({ error, onClose }: { error: unknown; onClose?: () => void }) {
  const errorWithDetails = error instanceof ErrorWithDetails ? error : undefined
  return (
    <Alert severity="error" variant="outlined" onClose={onClose}>
      {friendlyError(error)}
      {errorWithDetails?.details && <pre>{prettyJSON(errorWithDetails.details)}</pre>}
    </Alert>
  )
}

// see https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
export function useAsyncError() {
  const [, setError] = useState()
  return useCallback(
    (e: Error) => {
      setError(() => {
        throw e
      })
    },
    [setError]
  )
}

export function useError() {
  const [error, setError] = useState<Error | null>(null)
  return { error, setError }
}
