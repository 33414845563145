import assertNever from 'assert-never'
import { addDays, addMonths, isBefore, isFuture, isPast } from 'date-fns'
import { uniq } from 'lodash'
import { categoryByIdRequired, isCategoryId } from 'shared/data/categories-service'
import { DayCategory, DayCategoryID } from 'shared/db/day-category'
import { ApprovedLicense, DB } from 'shared/db/db'
import { SportEventId } from 'shared/db/sport-event-id'
import { t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { Category, CategoryId } from 'shared/models/category'
import { DraftLicense } from 'shared/models/category-details'
import { unknownDate, UnknownDate } from 'shared/models/unknown-date'
import { deserializeLicenseCategoryIDs } from 'shared/sport-events/sport-event-categories-serialization'
import { Inscription, SportEvent } from 'shared/sport-events/sport-events'
import { DateString, formatDate, parseISO, pDateWeekdayName, pFormatDateDe } from 'shared/utils/date'
import { mapValues } from 'shared/utils/object'

export async function ensureSportEventIsNotFinalized(db: DB, sportEventId: SportEventId) {
  const sportEvent = await db.loadSportEvent(sportEventId)
  if (sportEvent?.finalized) throw new Error('Sport event is already finalized')
}

export function ensureSportEventObjectIsNotFinalized(sportEvent: SportEvent | undefined) {
  if (sportEvent?.finalized) throw new Error('Sport event is already finalized')
}

export function filterSportEventsByLicensesAndInscriptions(
  events: {
    sportEvent: SportEvent
    dayCategories: DayCategory[]
  }[],
  approvedLicenses: ApprovedLicense[],
  draftLicenses: DraftLicense[],
  inscriptions: Inscription[]
) {
  return events.filter(
    ({ sportEvent }) =>
      sportEventLicenseCategoryIds(sportEvent).some((id) =>
        [...approvedLicenses, ...draftLicenses].some((license) => license.categoryId === id)
      ) ||
      sportEventAllCategoryIds(sportEvent).some((id) =>
        inscriptions.some((inscription) => inscription.category === id)
      )
  )
}

export function sportEventCategories(sportEvent: SportEvent) {
  return sportEventLicenseCategoryIds(sportEvent).map((id) => categoryByIdRequired(id))
}

export function sportEventDatesForLicense(event: SportEvent, _license: ApprovedLicense) {
  // TODO: later: inscription admin: only allow inscription on days where the `_license` category starts
  return sportEventDates(event)
}

export function categoryDates(event: SportEvent, category: Category | DayCategory) {
  const dates: Record<string | CategoryId, string[]> =
    category.categoryType === 'dayCategory'
      ? dayCategoryDates(event)
      : category.categoryType === 'licenseCategory'
      ? licenseCategoryDates(event)
      : assertNever(category)
  return dates[category.id] || []
}

export function licenseCategoryDates(event: SportEvent): Partial<Record<CategoryId, string[]>> {
  return sortCategoryDates(event.licenseCategoryDates)
}

export function dayCategoryDates(sportEvent: SportEvent): Record<DayCategoryID, string[]> {
  return sortCategoryDates(sportEvent.dayCategoryDates)
}

function sortCategoryDates<T extends string>(dates: Partial<Record<T, string[]>> | undefined) {
  return mapValues(dates || {}, (dates) => [...dates].sort()) as Record<T, string[]>
}

export function sportEventDates(event: {
  id?: SportEvent['id']
  startsAt: SportEvent['startsAt']
  endsAt: SportEvent['endsAt']
}) {
  const [parsedFrom, parsedTo] = [parseISO(event.startsAt), parseISO(event.endsAt)]

  if (isBefore(parsedTo, parsedFrom))
    throw new Error(`Event ${event.id} has invalid dates: ${event.startsAt} - ${event.endsAt}`)

  let current = parsedFrom
  const ret = [current]
  while (formatDate(current) !== formatDate(parsedTo)) {
    current = addDays(current, 1)
    ret.push(current)
  }
  return ret.map(formatDate)
}

export function sportEventAllCategoryIds(props: {
  licenseCategoryIds: string | undefined
}): (CategoryId | DayCategoryID)[] {
  return deserializeLicenseCategoryIDs(props.licenseCategoryIds)
}

export function sportEventLicenseCategoryIds(props: {
  licenseCategoryIds: string | undefined
}): CategoryId[] {
  // TODO: later: add day categories? // replace calling functions with generic function?
  return deserializeLicenseCategoryIDs(props.licenseCategoryIds)
    .map((id) => (isCategoryId(id) ? id : undefined))
    .map((id) => {
      if (!id) throw new Error(`Invalid category id ${id}`)
      return id
    })
}

export function sportEventInscriptionDayOrEventDate(event: SportEvent, date: DateString | UnknownDate) {
  return date === unknownDate
    ? `${t().dayUnconfirmedDate}, ${sportEventDescriptionDates(event)}`
    : `${pDateWeekdayName(date)}, ${pFormatDateDe(date)}`
}

export function sportEventInscriptionDayOrEventDateShort(
  event: SportEvent,
  date: DateString | UnknownDate
) {
  return date === unknownDate
    ? `${t().dayUnconfirmedDate}, ${sportEventDescriptionDates(event)}`
    : `${pDateWeekdayName(date)}`
}

export function sportEventDescriptionWithoutDate(event: SportEvent | undefined) {
  return sportEventDescriptionOrEmptyWithoutDate(event) || t().unknown
}

function sportEventDescriptionOrEmptyWithoutDate(event: SportEvent | undefined) {
  if (!event) return ''

  const addition = event.status === 'deleted' ? ` (${t().sportEventStatus.deleted})` : ''
  return `${event.name}${addition}`
}

export function sportEventDescription(event: SportEvent | undefined) {
  return sportEventDescriptionOrEmpty(event) || t().unknown
}

export function sportEventDescriptionOrEmpty(event: SportEvent | undefined) {
  if (!event) return ''

  const dates = sportEventDescriptionDates(event)
  const addition = event.status === 'deleted' ? ` (${t().sportEventStatus.deleted})` : ''
  const cancelled = sportEventCancelledPrefix(event)
  return `${cancelled}${event.name}, ${dates}${addition}`
}

export function sportEventCancelledPrefix(event: SportEvent) {
  return event.cancelled ? `[${t().cancelled}] ` : ''
}

export function sportEventDescriptionDates(event: SportEvent | undefined) {
  if (!event) return ''

  const [from, to] = [pFormatDateDe(event.startsAt), pFormatDateDe(event.endsAt)]
  return from === to ? from : `${from} - ${to}`
}

export function isOnlineSportEvent(event: SportEvent): boolean {
  return isFutureSportEvent(event) || isCancelledAndWithinUnsubscriptionTimeSportEvent(event)
}

export function isFutureSportEvent(event: SportEvent): boolean {
  return isFutureEventDate(event.endsAt)
}

export function isCancelledAndWithinUnsubscriptionTimeSportEvent(event: SportEvent): boolean {
  return event.cancelled && isLessThan3DaysAfterSportEvent(event)
}

function isFutureEventDate(date: string) {
  return isFuture(addDays(parseISO(date), 1))
}

export function isLessThanSixMonthsAfterSportEvent(event: SportEvent): boolean {
  const dateAfterSportEvent = addMonths(parseISO(event.endsAt), 6).toISOString()
  return !isPastEventDate(dateAfterSportEvent)
}

export function isMoreThan3DaysAfterSportEvent(event: SportEvent): boolean {
  return !isLessThan3DaysAfterSportEvent(event)
}

export function isMoreThan1MonthAfterSportEvent(event: SportEvent): boolean {
  return !isLessThan1MonthAfterSportEvent(event)
}

export function isLessThan3DaysAfterSportEvent(event: SportEvent): boolean {
  const dateAfterSportEvent = addDays(parseISO(event.endsAt), 3)
  return !isPastEventDate(dateAfterSportEvent.toISOString())
}

export function isLessThan1MonthAfterSportEvent(event: SportEvent): boolean {
  const dateAfterSportEvent = addMonths(parseISO(event.endsAt), 1).toISOString()
  return !isPastEventDate(dateAfterSportEvent)
}
export function isPastSportEvent(event: SportEvent): boolean {
  return isPastEventDate(event.startsAt)
}

function isPastEventDate(date: string) {
  return isPast(parseISO(date))
}

export function hasAlternativeDates(sportEvent: SportEvent) {
  return sportEvent.alternativeStartsAt && sportEvent.alternativeEndsAt
}

export function formatSportEventDates(sportEvent: SportEvent) {
  const dates = [sportEvent.startsAt, sportEvent.endsAt]
  return uniq(dates.map(pFormatDateDe)).join('-')
}

export function associationParticipatesInSportEvent(sportEvent: SportEvent, association: AssociationID) {
  return deserializeLicenseCategoryIDs(sportEvent.licenseCategoryIds)
    .map((id) => categoryByIdRequired(id))
    .some((category) => category.associations.includes(association))
}

export async function deletePublicInscriptions(db: DB, sportEvent: SportEvent) {
  await db.setAllPublicInscriptions(sportEvent.id, null)
}
