import { updateLicenseBooking } from 'shared/billing/bookings-service'
import { DB, LicenseBooking } from 'shared/db/db'
import { consoleLog } from 'shared/utils/console'
import { isSameOrAfterISO, isSameOrBeforeISO } from 'shared/utils/date'

export const id = '2023-02-18T14-49-05-193Z'

export const description =
  'Change the fms transponder price / bookings from 2022-12 until now from 75 to 80'

export async function up(db: DB) {
  const allBookings = await db.loadAllLicenseBookings()
  const bookings = allBookings.filter(
    (booking) =>
      booking.item.association === 'fms' &&
      booking.item.type === 'transponderLineItem' &&
      booking.item.price === 75 &&
      booking.item.transponderId === 'rf-purchase' &&
      isSameOrAfterISO(booking.date, '2022-11-01T00:00:00.000Z') &&
      isSameOrBeforeISO(booking.date, '2023-02-20T00:00:00.000Z')
  )
  consoleLog(bookings.length, bookings)
  await Promise.all(bookings.map((booking) => adjustPrice(db, booking)))
}

async function adjustPrice(db: DB, booking: LicenseBooking): Promise<void> {
  const documents = await db.loadDocuments(booking)
  consoleLog(
    `${documents?.personalData?.email} (${documents?.personalData?.firstName} ${documents?.personalData?.lastName}, ${booking.uid})`
  )
  await updateLicenseBooking(db, { ...booking, item: { ...booking.item, price: 80 } })
}
