import { DB } from 'shared/db/db'
import { todoMigrateAssociation } from 'shared/models/associations'
import { truthy } from 'shared/utils/array'
import { consoleLog } from 'shared/utils/console'

export const id = '2022-05-15T17-18-40-206Z'

export const description = 'Migrates inscriptionBookings to sport event associations'

export async function up(db: DB) {
  const allBookings = await db.loadInscriptionBookingsByRider()
  // @ts-ignore
  const sportEvents = await db.loadAllSportEventsById()
  const bookings = Object.values(allBookings)
    .flat()
    .map((booking) =>
      booking.item.type === 'inscriptionDayCategoryLineItem' ||
      booking.item.type === 'inscriptionDayCategoryPowerLineItem' ||
      booking.item.type === 'inscriptionDiscountLineItem' ||
      booking.item.type === 'inscriptionLineItem' ||
      booking.item.type === 'powerLineItem' ||
      booking.item.type === 'donationLineItem'
        ? booking
        : undefined
    )
    .filter(truthy)
    .map((booking) => ({ sportEvent: sportEvents[booking.item.sportEventId], booking }))

  consoleLog(`Found ${bookings.length} bookings in total`)
  const bookingsToBeUpdated = bookings.filter(
    ({ sportEvent, booking }) =>
      todoMigrateAssociation(booking.item.association) !== todoMigrateAssociation(sportEvent.association)
  )
  consoleLog(`Found ${bookingsToBeUpdated.length} bookings to be updated`, bookingsToBeUpdated)
  await Promise.all(
    bookingsToBeUpdated.map(({ sportEvent, booking }) => {
      booking.item.association = todoMigrateAssociation(sportEvent.association)
      return db.updateInscriptionBooking(booking)
    })
  )
  consoleLog('Bookings updated')
}
