import assertNever from 'assert-never'
import { sortBy, sum } from 'lodash'
import { categoryByIdRequired, categoryCommonName } from 'shared/data/categories-service'
import type {
  Transaction,
  WithTotal,
  Bill,
  TransactionsByType,
  BookingRelevantTransactionPure,
  LicenseBookingsByLineItemType,
} from 'shared/db/db'
import {
  isLicenseBookingWithCategoryLineItem,
  isLicenseBookingWithTransponderLineItem,
} from 'shared/db/db-typechecks'
import { t as i18nT } from 'shared/i18n/current'
import { licenseBookingName } from 'shared/licenses-booking-names'
import { pFormatDateDe } from 'shared/utils/date'

export function sortedTransactionsWithTotal<T extends Transaction>(
  unsortedTransactions: T[]
): WithTotal<T>[] {
  let currentTotal = 0
  return sortTransactions(unsortedTransactions)
    .map((item) => {
      currentTotal -= transactionTotal(item)
      return { ...item, currentTotal }
    })
    .reverse()
}

export function sortTransactions<T extends Transaction>(transactions: T[]): T[] {
  return sortBy(transactions, (item) => item.date)
}

export function transactionsTotal<T extends Transaction>(transactions: T[]) {
  const prices = transactions.map((t) => transactionTotal(t))
  return sum(prices)
}

export function transactionTotal(t: Transaction) {
  return t.type === 'bill'
    ? 0
    : t.type === 'manualBooking' ||
      t.type === 'licenseBooking' ||
      t.type === 'reverseLicenseBooking' ||
      t.type === 'inscriptionBooking'
    ? t.item.price
    : t.type === 'payment' || t.type === 'manualPayment' || t.type === 'associationPayment'
    ? -t.amount
    : assertNever(t)
}

export function transactionReference(transaction: Transaction) {
  return transaction.type === 'licenseBooking'
    ? licenseBookingName(transaction)
    : transaction.type === 'reverseLicenseBooking'
    ? `Storniert: ${licenseBookingName(transaction)}`
    : transaction.type === 'manualBooking' || transaction.type === 'inscriptionBooking'
    ? transaction.item.name
    : transaction.type === 'bill'
    ? `Rechnung ${transaction.reference}`
    : transaction.type === 'payment'
    ? `QR-Zahlung ${transaction.reference}`
    : transaction.type === 'manualPayment'
    ? `Zahlung (${transaction.tag}) ${transaction.reference}/${transaction.id}`
    : transaction.type === 'associationPayment'
    ? `Verbandsauszahlung ${[transaction.paymentReference, transaction.id].join('/')}`
    : assertNever(transaction)
}

export function transactionStatus(t: Transaction) {
  return t.type === 'licenseBooking' || t.type === 'reverseLicenseBooking'
    ? `${i18nT().transactions.license} ${t.year}`
    : t.type === 'inscriptionBooking'
    ? t.item.type === 'inscriptionLineItem'
      ? `${i18nT().transactions.inscription} ${categoryCommonName(
          categoryByIdRequired(t.item.categoryId)
        )}`
      : t.item.type === 'inscriptionDayLicenseLineItem'
      ? `${i18nT().transactions.inscriptionDayLicense} ${categoryCommonName(
          categoryByIdRequired(t.item.categoryId)
        )}`
      : t.item.type === 'inscriptionDayCategoryLineItem'
      ? `${i18nT().transactions.inscriptionDayCategory} ${t.item.dayCategoryName}`
      : t.item.type === 'powerLineItem'
      ? `${i18nT().transactions.power} ${categoryCommonName(categoryByIdRequired(t.item.categoryId))}`
      : t.item.type === 'inscriptionDayCategoryPowerLineItem'
      ? `${i18nT().transactions.power} ${t.item.dayCategoryName}`
      : t.item.type === 'donationLineItem'
      ? `${i18nT().transactions.donation} ${t.item.categoryName}`
      : t.item.type === 'inscriptionDiscountLineItem'
      ? `${i18nT().financials.inscriptionDiscount}`
      : assertNever(t.item)
    : t.type === 'manualBooking'
    ? t.item.price > 0
      ? i18nT().financials.debit
      : i18nT().financials.credit
    : t.type === 'bill'
    ? billStatus(t)
    : t.type === 'payment' || t.type === 'manualPayment'
    ? t.amount > 0
      ? i18nT().financials.amountReceived
      : i18nT().financials.amountPaid
    : t.type === 'associationPayment'
    ? i18nT().associationPayments.states[t.status]
    : assertNever(t)
}

function billStatus(bill: Bill): string {
  if (bill.status === 'open') return i18nT().financials.open
  if (bill.status === 'paid') return `${i18nT().financials.paidOn} ${pFormatDateDe(bill.paidAt)}`
  if (bill.status === 'overpaid') return i18nT().financials.overpayment
  if (bill.status === 'underpaid') return i18nT().financials.underpayment
  if (bill.status === 'replaced') return i18nT().financials.replaced
  assertNever(bill.status)
}

export function transactionsByLineItemType(
  transactions: BookingRelevantTransactionPure[]
): LicenseBookingsByLineItemType {
  return transactionsByType(transactions).licenseBookings.reduce(
    (combined, transaction) => {
      if (isLicenseBookingWithCategoryLineItem(transaction)) combined.categoryLineItems.push(transaction)
      if (isLicenseBookingWithTransponderLineItem(transaction))
        combined.transponderLineItems.push(transaction)
      return combined
    },
    {
      categoryLineItems: [],
      transponderLineItems: [],
    } as LicenseBookingsByLineItemType
  )
}

export function transactionsByType(transactions: BookingRelevantTransactionPure[]): TransactionsByType {
  return transactions.reduce(
    (combined, transaction) => {
      if (transaction.type === 'licenseBooking' || transaction.type === 'reverseLicenseBooking')
        combined.licenseBookings.push(transaction)
      if (transaction.type === 'manualPayment') combined.manualPayments.push(transaction)
      if (transaction.type === 'payment') combined.automaticPayments.push(transaction)
      if (transaction.type === 'manualBooking') combined.manualBookings.push(transaction)
      if (transaction.type === 'inscriptionBooking') combined.inscriptionBookings.push(transaction)
      return combined
    },
    {
      licenseBookings: [],
      manualPayments: [],
      automaticPayments: [],
      manualBookings: [],
      inscriptionBookings: [],
    } as TransactionsByType
  )
}

export function totalOpenRemainingBalance(transactions: { remainingBalance?: number }[]) {
  return sum(transactions.map(({ remainingBalance }) => openRemainingBalance(remainingBalance)))
}

export function openRemainingBalance(remainingBalance: number | undefined) {
  return Math.max(0, remainingBalance || 0)
}
