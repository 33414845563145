import { List } from '@material-ui/core'
import { Lock, LockOpen } from '@material-ui/icons'
import { generate } from 'generate-password-ts'
import { callRacemanagerApi } from 'app/config/firebase'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { UserRecord, SetPasswordEndpoint, DeletePasswordEndpoint } from 'shared/api/interfaces'
import { domain } from 'shared/config/domain'
import { t } from 'shared/i18n/current'
import { sleep } from 'shared/utils/time'
import { copyTextToClipboard } from 'utils/copy-to-clipboard'
import { ExternalLink, ExternalLinkListItem } from 'utils/external-link'
import { useErrorSnackbarForError, useSuccessSnackbar } from 'utils/snackbar'

export function SetPasswordButton({ user }: { user: UserRecord }) {
  const showSuccess = useSuccessSnackbar()
  const showError = useErrorSnackbarForError()
  const dialog = useDialog()
  const links = [
    'https://firebase.google.com/docs/auth/android/email-link-auth',
    'https://swoopnow.com/password-alternatives/',
  ]
  return (
    <>
      {!user.hasPassword && (
        <IconButtonWithTooltip
          tooltip={`Passwort für ${user.email} (${user.displayName}) setzen`}
          onClick={dialog.open}
        >
          <LockOpen />
        </IconButtonWithTooltip>
      )}
      {user.hasPassword && (
        <IconButtonWithTooltip
          tooltip={`Passwort für ${user.email} (${user.displayName}) löschen`}
          onClick={() => deletePassword({ user, showSuccess, showError })}
        >
          <Lock />
        </IconButtonWithTooltip>
      )}
      <ConfirmDialog
        title={t().setPassword}
        dialog={dialog}
        buttonText={t().confirmSetPassword}
        onConfirm={async () => {
          await setPassword({ user, showSuccess, showError })
        }}
      >
        <p>
          Passwörter sind ein grundsätzliches Sicherheitsrisiko. Mit einer Authentisierung via Google
          oder Facebook werden viele Angriffe verhindert, und es entsteht eine Protokollierung, wann sich
          wer von welcher IP Adresse angemeldet hat.
        </p>
        <p>
          Mit einer Authentisierung via E-Mail wird sichergestellt, dass der Fahrer Zugriff auf das
          entsprechende Mailkonto hat, und dass die E-Mails des Systems beim Benutzer ankommen.
        </p>
        <p>Weitere Informationen:</p>
        <List>
          {links.map((link) => (
            <ExternalLinkListItem key={link} href={link}>
              {link}
            </ExternalLinkListItem>
          ))}
        </List>
        <p>
          Willst du trotzdem ein Passwort für {user.email}, {user.displayName}, {user.uid},{' '}
          {user.emailVerified ? 'E-Mail verifiziert' : 'E-Mail nicht verifiziert'}
          setzen?
        </p>
        <p>
          Das neue Passwort wird automatisch in deine Zwischenablage kopiert (Ctrl+V, um das Passwort
          einzufügen).
        </p>
        <p>
          Der Benutzer kann sich unter{' '}
          <ExternalLink href={`${domain}/password`}>{domain}/password</ExternalLink> oder{' '}
          <ExternalLink href={`${domain}/passwort`}>{domain}/passwort</ExternalLink> anmelden.
        </p>
      </ConfirmDialog>
    </>
  )
}

interface SetPasswordProps {
  user: UserRecord
  showSuccess: ReturnType<typeof useSuccessSnackbar>
  showError: ReturnType<typeof useErrorSnackbarForError>
}

async function setPassword({ user, showSuccess, showError }: SetPasswordProps) {
  const password = generate({ uppercase: false, length: 16, excludeSimilarCharacters: true })
  try {
    await callRacemanagerApi<SetPasswordEndpoint>('setPassword', { uid: user.uid, password })
    await copyTextToClipboard(password)
    showSuccess('Passwort wurde gesetzt und in Zwischenablage kopiert')
    await sleep(2000)
    globalThis.location.reload()
  } catch (error) {
    showError(error)
  }
}

async function deletePassword({ user, showSuccess, showError }: SetPasswordProps) {
  try {
    await callRacemanagerApi<DeletePasswordEndpoint>('deletePassword', { uid: user.uid })
    showSuccess('Passwort wurde gelöscht')
    await sleep(2000)
    globalThis.location.reload()
  } catch (error) {
    showError(error)
  }
}
