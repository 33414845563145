import { Typography } from '@material-ui/core'
import { t } from 'shared/i18n/current'
import { Insurance, insuranceOk } from 'shared/models/insurance'

interface ShowInsuranceProps {
  insurance: Insurance | undefined
  needed: boolean
}

export function ShowInsurance({ insurance, needed }: ShowInsuranceProps) {
  if (!needed && !insuranceOk(insurance)) return null

  return (
    <Typography>
      {t().documentBox.insuranceConfirmation}: {t().insurance.status[insurance?.status || 'notUploaded']}
    </Typography>
  )
}
