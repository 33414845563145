import assertNever from 'assert-never'
import { categoryCommonNameEndUser, categoryOfAssociationRequired } from 'shared/data/categories-service'
import type { LicenseBooking, LicenseLineItem } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { associationName } from 'shared/models/associations'
import { AssociationCategory, CategoryOfAssociation } from 'shared/models/category'
import { pFormatDateDe } from 'shared/utils/date'

export function mainLicenseName(mostExpensiveCategory: CategoryOfAssociation): string {
  return `${associationName(mostExpensiveCategory)} - Lizenz ${categoryCommonNameEndUser(
    mostExpensiveCategory
  )}`
}

export function additionalLicenseName(category: CategoryOfAssociation): string {
  return `${associationName(category)} - Zusatzlizenz ${categoryCommonNameEndUser(category)}`
}

export function discountName(category: AssociationCategory) {
  return `${associationName(category)} - Rabatt bis ${pFormatDateDe(category.discountUntil)}`
}

export function surchargeName(category: AssociationCategory) {
  return `${associationName(category)} - Zuschlag ab dem ${pFormatDateDe(category.surchargeAfter)}`
}

export function licenseBookingName(licenseBooking: LicenseBooking) {
  return licenseLineItemName(licenseBooking.item)
}

export function licenseLineItemName(item: LicenseLineItem) {
  if (item.type === 'transponderLineItem') return item.name

  const category = categoryOfAssociationRequired(item.categoryId, item.association)
  const reverse = item.name.startsWith('Storniert:')

  return item.subtype === 'mainLicense'
    ? mainLicenseNameT(reverse, category)
    : item.subtype === 'additionalLicense'
    ? additionalLicenseNameT(reverse, category)
    : item.subtype === 'licenseDiscount'
    ? discountNameT(reverse, category)
    : item.subtype === 'licenseSurcharge'
    ? surchargeNameT(reverse, category)
    : assertNever(item.subtype)
}

export function mainLicenseNameT(
  reverse: boolean,
  mostExpensiveCategory: CategoryOfAssociation
): string {
  return `${reverseName(reverse)}${associationName(mostExpensiveCategory)} - ${
    t().transactions.mainLicenseName
  } ${categoryCommonNameEndUser(mostExpensiveCategory)}`
}

export function additionalLicenseNameT(reverse: boolean, category: CategoryOfAssociation): string {
  return `${reverseName(reverse)}${associationName(category)} - ${
    t().transactions.additionalLicenseName
  } ${categoryCommonNameEndUser(category)}`
}

export function discountNameT(reverse: boolean, category: AssociationCategory) {
  return `${reverseName(reverse)}${associationName(category)} - ${
    t().transactions.discountName
  } ${pFormatDateDe(category.discountUntil)}`
}

export function surchargeNameT(reverse: boolean, category: AssociationCategory) {
  return `${reverseName(reverse)}${associationName(category)} - ${
    t().transactions.surchargeName
  } ${pFormatDateDe(category.surchargeAfter)}`
}

function reverseName(reverse: boolean) {
  return reverse ? `${t().transactions.reverse} ` : ''
}
