import { Box } from '@material-ui/core'
import { t } from 'shared/i18n/current'
import { UploadStatusDetails } from 'shared/models/upload-status'
import { FriendlyError } from 'utils/errors'

interface InvalidUploadMessageProps {
  upload: UploadStatusDetails | undefined
}

export function InvalidUploadMessage({ upload }: InvalidUploadMessageProps) {
  return upload?.status === 'invalid' ? (
    <Box py={1}>
      <FriendlyError
        error={`${t().licensePhoto.invalidPhoto}${noteUser(upload)}. ${t().licensePhoto.uploadAgain}`}
      />
    </Box>
  ) : null
}

function noteUser(upload: UploadStatusDetails) {
  return upload.noteUser ? `: ${upload.noteUser}` : ''
}
