import { Container, Grid, Button, Box, LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import firebase from 'firebase/app'
import { useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { callRacemanagerApi } from 'app/config/firebase'
import { useDocumentsLoading } from 'app/db/db-contexts/documents-context'
import { useUserData } from 'app/db/db-hooks/main-db-hooks'
import { useI18nContext } from 'app/i18n/i18n-context'
import { Center } from 'app/layout/center'
import { AppNormalContent } from 'app/layouts/app-normal-content'
import { combinedAssociationsAndLocales } from 'app/layouts/combined-associations-and-locales'
import { PreviewBanner } from 'app/layouts/preview-banner'
import { SignInForm, afterSignIn } from 'app/layouts/sign-in-form'
import { useAssociationContext } from 'app/themes/association-context'
import { useUserContext } from 'app/themes/user-context'
import { isSignInUsingUrl, signOut, tryToSignInUsingUrl, verifyEmail } from 'app/users/auth'
import { User } from 'app/users/user'
import { ImportUserDataEndpoint } from 'shared/api/interfaces'
import { routes } from 'shared/config/routes'
import { UserData } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { associationPath, associationPathWithoutLocale } from 'shared/models/associations'
import { FriendlyError } from 'utils/errors'
import { CenterLoading } from 'utils/loading'
import { Disp } from 'utils/react'

export function AppContent() {
  const userContext = useUserContext()
  const user = userContext.user
  const [verifyEmailError, setVerifyEmailError] = useState('')
  const [verifyEmailLinkSent, setVerifyEmailLinkSent] = useState(false)
  const [signInLinkError, setSignInLinkError] = useState('')
  const { data: userData, loading: userDataLoading, error: userDataError } = useUserData(user)
  const [importStarted, setImportStarted] = useState(false)
  const location = useLocation()
  const associationContext = useAssociationContext()
  const lastHref = useRef('')
  const history = useHistory()
  const i18nContext = useI18nContext()
  const documentsLoading = useDocumentsLoading()

  if (documentsLoading) return <CenterLoading message={t().signIn.loadingDocuments} />

  if (
    anonymousAccess(location) ||
    isAssociationUrl(location) ||
    (user && user.emailVerified && userData?.imported)
  ) {
    if (isSignInUsingUrl()) history.replace(routes.userDashboard.to)

    return <AppNormalContent user={user} />
  }

  if (userContext.userError) return <FriendlyError error={userContext.userError} />
  if (userContext.userLoading) return <CenterLoading message={t().signIn.loadingUser} />

  if (user && user.emailVerified)
    return renderUserImporting(
      user,
      userData,
      userDataLoading,
      userDataError,
      importStarted,
      setImportStarted
    )

  if (user && !user.emailVerified)
    return renderVerifyEmail(
      user,
      verifyEmailError,
      setVerifyEmailError,
      verifyEmailLinkSent,
      setVerifyEmailLinkSent
    )

  if (isSignInUsingUrl()) {
    if (lastHref.current !== window.location.href) {
      lastHref.current = window.location.href
      tryToSignInUsingUrl(setSignInLinkError)
        .then((credentials) => afterSignIn(credentials?.user, associationContext, i18nContext, 'email'))
        .catch(console.error)
    }
    return renderAfterUrlSignIn(signInLinkError)
  }

  return renderSignInForm()
}

function anonymousAccess(location: Location) {
  return Object.values(routes).some(
    (route) =>
      'anonymousMatch' in route &&
      route.anonymousMatch &&
      location.pathname.startsWith(route.anonymousMatch)
  )
}

function isAssociationUrl(location: Location) {
  return (
    combinedAssociationsAndLocales()
      .map(associationPath)
      .some((path) => path === location.pathname) ||
    combinedAssociationsAndLocales()
      .map(associationPathWithoutLocale)
      .some((path) => path === location.pathname)
  )
}

type Location = ReturnType<typeof useLocation>

function renderUserImporting(
  user: User,
  userData: UserData | undefined,
  userDataLoading: boolean,
  userDataError: firebase.FirebaseError | undefined,
  importStarted: boolean,
  setImportStarted: Disp<boolean>
) {
  if (userDataError) return <FriendlyError error={userDataError} />
  if (userDataLoading) return <CenterLoading message={t().signIn.loadingUserData(user.email || '')} />
  if (!importStarted && user.emailVerified && !userData?.imported) {
    setImportStarted(true)
    importUserData(user).catch(console.error)
  }
  return renderImportingUserData()
}

async function importUserData(user: firebase.User) {
  await user.reload()
  await callRacemanagerApi<ImportUserDataEndpoint>('importUserData', null)
}

function renderImportingUserData() {
  return <CenterLoading message={t().signIn.creatingProfile} />
}

function renderVerifyEmail(
  user: User,
  verifyError: string,
  setVerifyError: Disp<string>,
  verifyLinkSent: boolean,
  setVerifyLinkSent: Disp<boolean>
) {
  const email = user.email
  return (
    <Container maxWidth="sm">
      <Center minHeight="100vh">
        {verifyLinkSent && (
          <Box mb={1}>
            <Alert severity="success" variant="outlined">
              {t().signIn.verificationEmailSent}
            </Alert>
          </Box>
        )}{' '}
        {verifyError && (
          <Box mb={1}>
            <Alert severity="error" variant="outlined">
              {verifyError}
            </Alert>
          </Box>
        )}
        {email ? (
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => verifyEmail(user, setVerifyError, setVerifyLinkSent)}
            disabled={verifyLinkSent}
          >
            {t().signIn.verifyEmail(email)}
          </Button>
        ) : (
          t().signIn.emailNotSent
        )}
        <Box mt={1}>
          <Button
            fullWidth
            color="secondary"
            href="/"
            variant="contained"
            onClick={signOut}
            disabled={verifyLinkSent}
          >
            {t().signOutText}
          </Button>
        </Box>
      </Center>
    </Container>
  )
}

function renderAfterUrlSignIn(signInLinkError: string) {
  return (
    <Container maxWidth="sm">
      <Center minHeight="100vh">
        {!signInLinkError ? (
          <LinearProgress />
        ) : (
          <>
            <Box mb={1}>
              <Alert severity="error" variant="outlined">
                {signInLinkError}
              </Alert>
            </Box>
            <Box mb={1}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => window.location.reload()}
              >
                {t().personalDataBox.changeEmail.tryAgain}
              </Button>
            </Box>
            <Button fullWidth href="/" variant="outlined">
              {t().signIn.backToSignIn}
            </Button>
          </>
        )}
      </Center>
    </Container>
  )
}

function renderSignInForm() {
  return (
    <>
      <Container maxWidth="md">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={12}>
            <SignInForm />
          </Grid>
        </Grid>
      </Container>
      <PreviewBanner />
    </>
  )
}
