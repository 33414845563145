import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { fullCategoryCommonName } from 'shared/data/categories-service'
import { LicenseDraftWithDocuments } from 'shared/db/db'
import { t } from 'shared/i18n/current'

export function ListOtherLicenses({ others }: { others: LicenseDraftWithDocuments[] }) {
  return others.length <= 0 ? null : (
    <Box mb={2}>
      <Typography variant="subtitle1" gutterBottom>
        {t().otherLicenseRequestsForRider}
      </Typography>
      <List disablePadding dense>
        {others.map((draft) => (
          <ListItem key={draft.id}>
            <ListItemText
              primary={`${fullCategoryCommonName(draft.draft.category)} (${
                draft.draft.categoryDetails?.preferredNumber
              })`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
