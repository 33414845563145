import { isEqual, uniq } from 'lodash'
import { DB } from 'shared/db/db'
import {
  LegacyTransponderOptionId,
  NewTransponder,
  Transponder,
  TransponderOptionId,
} from 'shared/models/transponder'
import { truthy } from 'shared/utils/array'

export const id = '2023-07-31T12-34-24-049Z'

export const description = 'Migrate old transponder format to new transponder format'

export async function up(db: DB) {
  const allDocuments = await db.loadAllDocuments()
  await Promise.all(
    Object.entries(allDocuments).map(async ([uid, documents]) => {
      if (!documents.transponder) return
      const migrated = migrateTransponders(documents.transponder)
      if (isEqual(migrated, documents.transponder)) return
      await db.setTransponder({ uid }, migrated)
    })
  )
}

function migrateTransponders(t: OldTransponderFormat | Transponder): Transponder {
  return { orderedTransponders: migrateOrderedTransponder(t), transponders: migrateSavedTransponders(t) }
}

function migrateOrderedTransponder(t: OldTransponderFormat | Transponder) {
  const orderedTranspondersRaw: TransponderOptionId[] = t.orderedTransponders || []
  if ('orderedTransponder' in t && t.orderedTransponder)
    orderedTranspondersRaw.push(t.orderedTransponder)
  return orderedTranspondersRaw.length > 0 ? uniq(orderedTranspondersRaw) : undefined
}

function migrateSavedTransponders(t: OldTransponderFormat | Transponder): Transponder['transponders'] {
  const transpondersArray = migrateSavedTranspondersInner(t)
  return transpondersArray.length > 0
    ? Object.fromEntries(transpondersArray.map((t, i) => [`t${i}`, t]))
    : undefined
}

function migrateSavedTranspondersInner(t: OldTransponderFormat | Transponder): NewTransponder[] {
  const existingTransponders = Object.values(t.transponders || {})
  const rfTransponder = extractRfTransponder(t, existingTransponders)
  const myLapsTransponder = extractMyLapsTransponder(t, existingTransponders)

  return [...existingTransponders, rfTransponder, myLapsTransponder].filter(truthy)
}

function extractRfTransponder(
  t: OldTransponderFormat | Transponder,
  transponders: NewTransponder[]
): NewTransponder | undefined {
  return 'hasTransponderRf' in t &&
    t.hasTransponderRf &&
    'transponderNumberRf' in t &&
    t.transponderNumberRf &&
    !transponders.find(
      (newTransponder) =>
        newTransponder.type === 'RF' && newTransponder.transponderNumber === t.transponderNumberRf
    )
    ? { transponderNumber: t.transponderNumberRf, type: 'RF' }
    : undefined
}

function extractMyLapsTransponder(
  t: OldTransponderFormat | Transponder,
  transponders: NewTransponder[]
): NewTransponder | undefined {
  return 'hasTransponder' in t &&
    t.hasTransponder &&
    'transponderNumber' in t &&
    t.transponderNumber &&
    !transponders.find(
      (newTransponder) =>
        newTransponder.type === 'MyLaps' && newTransponder.transponderNumber === t.transponderNumber
    )
    ? {
        transponderNumber: t.transponderNumber,
        type: 'MyLaps',
        ...(t.transponderMake ? { make: t.transponderMake } : {}),
      }
    : undefined
}

export interface OldTransponderFormat {
  hasTransponder: boolean
  transponderMake: string
  transponderNumber: number | undefined

  hasTransponderRf: boolean | undefined
  transponderNumberRf: number | undefined

  orderedTransponder: LegacyTransponderOptionId | ''
  orderedTransponders: TransponderOptionId[] | undefined

  transponders: Record<string, NewTransponder> | undefined
}
