import { Box, Typography } from '@material-ui/core'
import { addMinutes, isFuture, parseISO } from 'date-fns'
import { useEffect } from 'react'
import { useAsync } from 'react-async-hook'
import { useParams } from 'react-router-dom'
import { callRacemanagerApi } from 'app/config/firebase'
import { ScanQRRedirectButton } from 'app/license/qr-code/ScanQRRedirectButton'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { LoadingOrErrorBox } from 'app/pages/dashboard/loading-or-error-box'
import { QRAdminButtons } from 'app/pages/licenses/qr-admin-buttons'
import { QRScannedTable } from 'app/pages/licenses/qr-scanned-table'
import { useAssociationContext } from 'app/themes/association-context'
import { useActiveAssociationID } from 'app/themes/use-assocation'
import { QRScanEndpoint, QRScanResult, QRScanResultWithHistory } from 'shared/api/interfaces'
import { t } from 'shared/i18n/current'
import { DateString, pFormatTimeWithSeconds } from 'shared/utils/date'
import { parseInt10 } from 'shared/utils/number'

export function QRPitLanePass() {
  const { licenseShortId, pitLanePassCode } = useParams<{
    licenseShortId: string
    pitLanePassCode: string
  }>()
  const { loading, error, result } = usePitLanePassContents({
    licenseShortId: parseInt10(licenseShortId),
    code: pitLanePassCode,
  })

  return (
    <ElevatedBox>
      <ScanQRRedirectButton />
      <LoadingOrErrorBox loading={loading} error={error} />
      {result && <DisplayQRPitLanePass scan={result} />}
    </ElevatedBox>
  )
}

function DisplayQRPitLanePass({ scan: { result, lastScansAt } }: { scan: QRScanResultWithHistory }) {
  const current = useActiveAssociationID()
  const context = useAssociationContext()

  useEffect(() => {
    if (current !== result.association) {
      context.setAssociation(result.association)
    }
  }, [result.association, context, current])

  return (
    <>
      <Typography variant="h4">{t().licensePdf.pitLaneDownload}</Typography>

      {lastScansAt && lastScansAt.length > 0 && (
        <Box mb={2}>
          <Typography>{t().lastFiveScansOnThisDevice}</Typography>
          <Box display="flex" flexDirection="right" flexWrap="wrap" style={{ gap: '8px' }}>
            {lastScansAt.map((date, index) => (
              <Box key={`${date}-${index}`}>{pFormatTimeWithSeconds(date)}</Box>
            ))}
          </Box>
        </Box>
      )}

      <QRScannedTable result={result} type="pit-lane-pass" />
      <QRAdminButtons result={result} />
    </>
  )
}

function usePitLanePassContents(props: { licenseShortId: number; code: string }) {
  const { licenseShortId, code } = props
  const context = useAssociationContext()

  return useAsync<QRScanResultWithHistory>(async () => {
    if (!licenseShortId || !code) throw new Error(t().licenses.licenseIdOrCodeMissing)
    const query = { licenseShortId, code }
    const result = await callRacemanagerApi<QRScanEndpoint>('qrScan', query)
    if (!result) throw new Error(t().licenses.licenseNotFound)
    if (result.association && context.association.id !== result.association)
      context.setAssociation(result.association)
    const lastScansAt = addScanResultToHistory(result)
    return { result, lastScansAt }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseShortId, code])
}

function addScanResultToHistory(result: QRScanResult): DateString[] {
  const shortId = result.shortId || 0
  const current = loadHistoryFromLocalStorage()
  current.unshift({ licenseShortId: shortId, date: new Date().toISOString() })
  saveHistoryToLocalStorage(current)
  return loadHistoryFromLocalStorage()
    .filter(({ licenseShortId }) => licenseShortId === shortId)
    .map(({ date }) => date)
    .slice(0, pitLanePassHistoryConfig.historySizePerLicense)
}

function loadHistoryFromLocalStorage() {
  const serialized = globalThis.localStorage.getItem(pitLanePassHistoryConfig.localStorageKey)
  if (!serialized) return []
  try {
    const parsed: QRHistoryEntry[] = JSON.parse(serialized)
    if (!Array.isArray(parsed)) return []
    return parsed.map((entry) => ({ licenseShortId: entry.licenseShortId || 0, date: entry.date || '' }))
  } catch {
    return []
  }
}

function saveHistoryToLocalStorage(entries: QRHistoryEntry[]) {
  const capped = entries
    .filter(({ date }) => isFuture(addMinutes(parseISO(date), 30)))
    .slice(0, pitLanePassHistoryConfig.historySizeTotal)
  globalThis.localStorage.setItem(pitLanePassHistoryConfig.localStorageKey, JSON.stringify(capped))
}

const pitLanePassHistoryConfig = {
  localStorageKey: 'qrPitLanePassHistory',
  historySizePerLicense: 5,
  historySizeTotal: 200,
}

interface QRHistoryEntry {
  licenseShortId: number
  date: string
}
