import assertNever from 'assert-never'
import { random, sum } from 'lodash'
import { platformCreditor } from 'shared/config/creditor'
import { countryCodeFor } from 'shared/data/countries'
import { AdminRider, Bill, BillLineItem, DB, PaymentInfo, Transaction, UserQuery } from 'shared/db/db'
import {
  transactionsTotal,
  transactionTotal,
  transactionReference,
} from 'shared/db/transactions-service'
import { t } from 'shared/i18n/current'
import { AssociationID, todoMigrateAssociation } from 'shared/models/associations'
import { fullName, PersonalData } from 'shared/models/personal-data'
import { truthy } from 'shared/utils/array'
import { formatDateWithSeconds, formatForQRReference } from 'shared/utils/date'
import { parseInt10 } from 'shared/utils/number'
import { exactLen, maxLen } from 'shared/utils/string'

export async function updateBillStatesAfterManualBooking(db: DB, rider: UserQuery) {
  const total = transactionsTotal(await db.loadTransactions(rider))
  const bills = await db.loadBills(rider)

  if (total <= 0)
    await Promise.all(
      bills.filter((bill) => bill.status === 'open').map((bill) => db.setBillStatus(bill, 'paid'))
    )
}

export async function storeNewBill(
  db: DB,
  { admin, rider }: AdminRider,
  association: AssociationID | undefined
): Promise<Bill> {
  const transactions = await db.loadTransactions(rider)
  const items = billItemsFromTransactions(transactions)

  const personalData = await db.loadPersonalData(rider)
  if (!personalData) throw new Error('Unable to load personal data')

  const billDate = new Date()
  const reference = await generateNumericReference(billDate, personalData)

  if (await referenceExistsAlready(db, reference)) return storeNewBill(db, { admin, rider }, association)

  const openBills = (await db.loadBills(rider)).filter((bill) => bill.status === 'open')
  const markBillsAsReplacedPromises = openBills.map((bill) => db.setBillStatus(bill, 'replaced'))

  const amount = itemsTotal(items)
  const bill: Bill = {
    type: 'bill',
    createdAt: billDate.toISOString(),
    updatedAt: billDate.toISOString(),
    items,
    reference,
    uid: rider.uid,
    byUid: admin.uid,
    title: generateTitle(reference),
    filename: generateFilename(reference, personalData, billDate),
    paymentInfo: generatePaymentInfo(amount <= 0 ? 0 : amount, reference, personalData),
    date: billDate.toISOString(),
    status: amount <= 0 ? 'paid' : 'open',
    paidAt: amount <= 0 ? billDate.toISOString() : '',
  }
  await Promise.all([...markBillsAsReplacedPromises, db.storeBill(bill)])
  return bill
}

function billItemsFromTransactions(transactions: Transaction[]): BillLineItem[] {
  const firstPayment = transactions.find((t) => t.type === 'payment')
  const groups = extract(transactions, firstPayment)

  const price = transactionsTotal(groups.combined)
  const carryover =
    price !== 0 ? [{ name: 'Übertrag seit letzter Zahlung', price, type: 'billLineItem' as const }] : []

  return [...groups.detailed.map(transactionToBillLineItem).filter(truthy), ...carryover]
}

function extract(transactions: Transaction[], firstPayment: Transaction | undefined) {
  if (!firstPayment) return { detailed: transactions, combined: [] }

  return {
    detailed: transactions.slice(0, transactions.indexOf(firstPayment)),
    combined: transactions.slice(transactions.indexOf(firstPayment)),
  }
}

function transactionToBillLineItem(t: Transaction): BillLineItem | undefined {
  return t.type === 'manualPayment'
    ? {
        name: transactionReference(t),
        price: transactionTotal(t),
        type: 'billLineItem',
        association: t.association,
      }
    : t.type === 'licenseBooking' ||
      t.type === 'reverseLicenseBooking' ||
      t.type === 'inscriptionBooking' ||
      t.type === 'manualBooking'
    ? {
        name: transactionReference(t),
        price: transactionTotal(t),
        type: 'billLineItem',
        association: todoMigrateAssociation(t.item.association),
      }
    : t.type === 'payment'
    ? { name: transactionReference(t), price: transactionTotal(t), type: 'billLineItem' }
    : t.type === 'associationPayment'
    ? undefined
    : t.type === 'bill'
    ? undefined
    : assertNever(t)
}

export function itemsTotal(items: { price: number }[]) {
  return sum(items.map(({ price }) => price))
}

function referenceExistsAlready(db: DB, reference: string) {
  return db.loadBill(reference)
}

async function generateNumericReference(date: Date, personalData: PersonalData): Promise<string> {
  const swissqrbillUtils = await import('swissqrbill/lib/utils')
  const referenceWithoutChecksum = generateRandomReferenceNumber(date, personalData?.samMemberNumber)
  const checksum = swissqrbillUtils.calculateQRReferenceChecksum(referenceWithoutChecksum)
  const reference = `${referenceWithoutChecksum}${checksum}`
  return swissqrbillUtils.isQRReferenceValid(reference)
    ? reference
    : await generateNumericReference(date, personalData)
}

export function generateRandomReferenceNumber(date: Date, optionalIdentifier: number | undefined) {
  const memberNumberStr = optionalIdentifier
    ? exactLen(6, optionalIdentifier.toFixed(0))
    : exactLen(6, randomMemberNumber())
  // length: 12 + 6 = 18 => 18 + 8 = 26
  const randomNumber = random(0, 10 ** 8 - 1).toFixed(0)
  return `${formatForQRReference(date)}${memberNumberStr}${exactLen(8, randomNumber)}`
}

function randomMemberNumber() {
  return `9${random(0, 10 ** 5 - 1).toFixed(0)}`
}

function generateFilename(reference: string, personalData: PersonalData, billDate: Date) {
  return `${generateTitle(reference)} ${fullName(personalData)} ${formatDateWithSeconds(billDate)}.pdf`
}

function generateTitle(reference: string) {
  return `Racemanager - ${t().bill} ${reference}`
}

function generatePaymentInfo(
  amount: number,
  reference: string,
  personalData: PersonalData
): PaymentInfo {
  const zip = extractZip(personalData)
  return {
    amount,
    reference,
    currency: 'CHF',
    creditor: platformCreditor,
    debitor: {
      name: maxLen(70, fullName(personalData)),
      address: maxLen(70, personalData.street),
      zip,
      city: maxLen(35, personalData.place),
      country: countryCodeFor(personalData.country),
    },
  } as const
}

function extractZip(personalData: PersonalData): PaymentInfo['debitor']['zip'] {
  const zip = personalData.zip
  if (!zip) throw new Error(t().bills.invalidEmptyZipCode)
  const numZip = parseInt10(zip)
  if (numZip) return numZip
  if (personalData.country === 'Schweiz') throw new Error(t().bills.invalidSwissZipCode)
  if (zip.length > 16) throw new Error(t().bills.invalidTooLongZip)
  return zip
}

export async function reopenBill(db: DB, bill: Bill) {
  await db.setBillStatus(bill, 'open')
  await db.setBillPaidAt(bill, null)
}
