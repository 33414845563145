import { Box, Paper, Typography, Chip } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { db } from 'app/db/frontend-db'
import { DeleteButtonWithConfirmation } from 'app/layout/delete-button-with-confirmation'
import { t } from 'shared/i18n/current'
import { Bike } from 'shared/models/bike'

export function ShowBikes({ bikes }: { bikes: Bike[] }) {
  return (
    <>
      {bikes.length ? (
        bikes.map((bike) => (
          <Box key={bike.id} my={2}>
            <Paper variant="outlined" elevation={3}>
              <Box p={2}>
                <Typography component="h4" variant="h6">
                  {bike.bikeMake} {bike.bikeModel} - {bike.name || ''}
                  <Chip
                    label={chipLabel(bike)}
                    size="small"
                    style={{ marginLeft: '8px', marginBottom: '5px' }}
                  />
                </Typography>
                <Typography>
                  {t().bikesBox.frameNumber}: {bike.frameNumber}
                </Typography>
                {bike.teamName && (
                  <Typography>
                    {bike.teamName} ({t().sponsor} {t().dayLicense})
                  </Typography>
                )}
                {bike.categoryType === 'enduro' && (
                  <Typography>
                    {bike.numberPlate} / {bike.insurance}
                  </Typography>
                )}
                <DeleteButtonWithConfirmation
                  title={t().bikesBox.deleteBike}
                  onConfirm={() => db.deleteBike(bike)}
                  fullWidth={false}
                />
              </Box>
            </Paper>
          </Box>
        ))
      ) : (
        <Alert severity="error" variant="outlined">
          {t().bikesBox.bikeMissing}
        </Alert>
      )}
    </>
  )
}

function chipLabel(bike: Bike) {
  return bike.categoryType === 'enduro' ? 'Enduro' : 'MX'
}
