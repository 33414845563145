import { Link } from '@material-ui/core'
import type { LinkProps } from '@material-ui/core'
import { ReactNode, useCallback } from 'react'

interface PreventDefaultLinkProps extends LinkProps {
  children: ReactNode
  color?: 'inherit'
  onClick: () => void | Promise<void>
}

export function PreventDefaultLink({ children, onClick, ...rest }: PreventDefaultLinkProps) {
  const fn = useCallback(
    async (e) => {
      e.preventDefault()
      await onClick()
    },
    [onClick]
  )

  return (
    <Link href="#" onClick={fn} {...rest}>
      {children}
    </Link>
  )
}
