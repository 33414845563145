import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { categoryCommonName } from 'shared/data/categories-service'
import { LicenseDraftWithDocuments } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { fullName } from 'shared/models/personal-data'

export function ListConflictingLicenses({ conflicts }: { conflicts: LicenseDraftWithDocuments[] }) {
  return conflicts.length <= 0 ? null : (
    <Box mb={2}>
      <Typography variant="subtitle1" gutterBottom>
        {t().otherLicensesWithSameNumber}
      </Typography>
      <List disablePadding dense>
        {conflicts.map((draft) => (
          <ListItem key={draft.id}>
            <ListItemText
              primary={`${fullName(draft.documents.personalData)}: ${categoryCommonName(
                draft.draft.category
              )} (${draft.draft.categoryDetails?.preferredNumber})`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
