import { todoT } from 'shared/i18n/current'
import { wordsUpToTotalLength } from 'shared/utils/string'
import { useErrorSnackbarForError, useSuccessSnackbar } from 'utils/snackbar'

interface CopyToClipboardAbbreviationAutoShortProps {
  text: string
  maxTextLength: number
}

export function CopyToClipboardAbbreviationAutoShort(props: CopyToClipboardAbbreviationAutoShortProps) {
  const { text, maxTextLength } = props
  const short = wordsUpToTotalLength(text, maxTextLength)
  return short === text ? <>{short}</> : <CopyToClipboardAbbreviation text={short} details={text} />
}

export function CopyToClipboardAbbreviation({ details, text }: { details?: string; text: string }) {
  const showError = useErrorSnackbarForError()
  const showSuccess = useSuccessSnackbar()

  if (!details) return <>{text}</>

  return (
    <abbr
      style={{ cursor: 'copy' }}
      title={details || text}
      onClick={async () => {
        try {
          await copyTextToClipboard(details || text)
          showSuccess(todoT('Text in Zwischenablage kopiert'))
        } catch (error) {
          showError(error)
        }
      }}
    >
      {text}
    </abbr>
  )
}

export async function copyTextToClipboard(text: string) {
  try {
    if ('clipboard' in globalThis.navigator) {
      await globalThis.navigator.clipboard.writeText(text)
    } else {
      const result = document.execCommand('copy', true, text)
      if (!result) throw new Error(todoT('Text konnte nicht kopiert werden'))
    }
  } catch {
    throw new Error(todoT('Text konnte nicht kopiert werden'))
  }
}
