import { Button, IconButton, Tooltip } from '@material-ui/core'
import type { ButtonProps, IconButtonProps } from '@material-ui/core'
import { Link as RouterLinkInner } from 'react-router-dom'

export function RoutedButton(props: ButtonProps<RouterLinkInner>) {
  return <Button {...props} component={RouterLinkInner} />
}

export function RoutedIconButton({
  tooltip,
  ...props
}: IconButtonProps<RouterLinkInner> & { tooltip: string }) {
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton {...props} component={RouterLinkInner} />
      </span>
    </Tooltip>
  )
}

export function RouterLink({ ...props }: IconButtonProps<RouterLinkInner>) {
  return <RouterLinkInner {...props} />
}

export function RouterLinkWithTooltip({
  tooltip,
  ...props
}: IconButtonProps<RouterLinkInner> & { tooltip: string }) {
  return (
    <Tooltip title={tooltip}>
      <span>
        <RouterLinkInner {...props} />
      </span>
    </Tooltip>
  )
}
