import { categoryByIdRequired } from 'shared/data/categories-service'
import { ApprovedLicense } from 'shared/db/db'
import { AssociationID } from 'shared/models/associations'

export function registeredViaTrustedAssociation(props: ApprovedLicense) {
  const category = categoryByIdRequired(props.categoryId)
  if (category.year !== 2021) return false

  const { association } = props
  return !!association && association !== 'SAM'
}

export type Association = 'FMS' | 'SAM' | 'AFM' | 'FIM' | 'MXRS' | 'Angora'

export function convertAssociation(association: Association | undefined): AssociationID {
  return association === 'SAM'
    ? 'sam'
    : association === 'FMS'
    ? 'fms'
    : association === 'MXRS'
    ? 'mxrs'
    : 'sam'
}
