import { unstable_createMuiStrictModeTheme, createTheme as createMuiTheme } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { deDE, enUS, frFR } from '@material-ui/core/locale'
import assertNever from 'assert-never'
import { isAfter, isBefore } from 'date-fns'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import { useI18nContext } from 'app/i18n/i18n-context'
import { usePosition } from 'app/themes/geolocation'
import { ThemeName, themeOptions } from 'app/themes/theme-options'
import { useTime } from 'app/themes/time'
import { useActiveAssociationID } from 'app/themes/use-assocation'
import { I18nLocales } from 'shared/i18n/i18n-types'
import { isLocalDevelopment } from 'utils/debug'

const deCH = cloneDeep(deDE)
deCH.props?.MuiAlert?.closeText && (deCH.props.MuiAlert.closeText = 'Schliessen')
deCH.props?.MuiAutocomplete?.closeText && (deCH.props.MuiAutocomplete.closeText = 'Schliessen')

const createTheme = isLocalDevelopment() ? unstable_createMuiStrictModeTheme : createMuiTheme

export function useDaylightTheme() {
  const i18n = useI18nContext()
  const [prevThemeName, setPrevThemeName] = useState<ThemeName | null>(null)
  const [theme, setTheme] = useState<Theme>(
    createTheme(themeOptions('sam-dark'), themeLocale(i18n.locale))
  )
  const themeName = useThemeName()

  if (prevThemeName !== themeName) {
    setPrevThemeName(themeName)
    setTheme(createMuiTheme(themeOptions(themeName)))
  }
  return theme
}

function themeLocale(locale: I18nLocales) {
  return locale === 'de' ? deCH : locale === 'en' ? enUS : locale === 'fr' ? frFR : assertNever(locale)
}

function useThemeName(): ThemeName {
  const position = usePosition()
  const association = useActiveAssociationID()
  useTime(tenMinutesInMilliseconds())

  const sunrise = getSunrise(...position)
  const sunset = getSunset(...position)

  const now = new Date()
  const isNight = isAfter(sunrise, now) || isBefore(sunset, now)
  return isNight ? `${association}-dark` : `${association}-light`
}

function tenMinutesInMilliseconds() {
  return 10 * 60 * 1000
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    fmsBlue: PaletteColor
    deemphasized: PaletteColor
  }
  interface PaletteOptions {
    fmsBlue: PaletteColorOptions
    deemphasized: PaletteColorOptions
    // TODO: later: add paper alt background
  }
}
