import { Button } from '@material-ui/core'
import type { ButtonProps } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { sortBy } from 'lodash'
import { useApprovedLicenses } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { samFields } from 'app/forms/fields'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, UseDialog, useDialog } from 'app/layouts/confirm-dialog'
import {
  ManualBookingFormData,
  manualBookingSchema,
  ManualBookingFields,
  bookingKindText,
  useManualBookingAutocompleteOptions,
} from 'app/pages/admin/bookings/manual-booking-form'
import { useUserContext } from 'app/themes/user-context'
import { useLicenseYear } from 'app/themes/year-context'
import { pushManualBooking } from 'shared/billing/bookings-service'
import {
  categoryById,
  categoryByIdRequired,
  categoryOfAssociation,
  categoryOfAssociationRequired,
} from 'shared/data/categories-service'
import { ManualBooking, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { todoMigrateAssociation } from 'shared/models/associations'
import { parseISO } from 'shared/utils/date'
import { FriendlyError } from 'utils/errors'
import { Loading } from 'utils/loading'
import { useErrorSnackbar, useSuccessSnackbar } from 'utils/snackbar'

interface NewManualBookingButtonPropsInt extends NewManualBookingButtonProps {
  initialValues: ManualBookingFormData
  dialog: UseDialog
}

interface CopyManualBookingButtonProps extends NewManualBookingButtonProps {
  booking: ManualBooking
  buttonType?: 'copy'
}

interface NewManualBookingButtonProps {
  admin: UserQuery
  buttonType?: 'copy'
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
  defaultValues?: Partial<ManualBooking>
  buttonName?: string
}

export function CopyOrNewManualBookingButton({ booking, ...rest }: CopyManualBookingButtonProps) {
  const category = booking.categoryId && categoryByIdRequired(booking.categoryId)
  const association = booking.item.association

  const newBooking: ManualBookingFormData = {
    uid: { id: booking.uid, name: booking.uid },
    date: parseISO(booking.date),
    bookingText: booking.item.name,
    association,
    amount: booking.item.price,
    sportEvent: booking.sportEventId
      ? { id: booking.sportEventId, name: booking.sportEventId }
      : undefined,
    internalRemarks: booking.internalRemarks || '',
    category: category
      ? categoryOfAssociation(category.id, association || category.associations[0])
      : undefined,
    tag: booking.tag,
  }

  return <NewManualBookingButtonInt initialValues={newBooking} {...rest} />
}

export function NewManualBookingButton(options: NewManualBookingButtonProps) {
  const userContext = useUserContext()
  const initialValues = loadInitialValues()
  const uid = options.defaultValues?.uid || initialValues.uid?.id || ''
  const association = userContext.associationAdmin || 'sam'
  return (
    <NewManualBookingButtonInt
      {...options}
      initialValues={{
        ...initialValues,
        uid: { id: uid, name: uid },
        association,
        date: new Date(),
      }}
    />
  )
}

function NewManualBookingButtonInt(props: Omit<NewManualBookingButtonPropsInt, 'dialog'>) {
  const { buttonType, buttonName, color = 'primary', variant = 'contained' } = props

  const dialog = useDialog()
  return (
    <>
      {buttonType === 'copy' ? (
        <IconButtonWithTooltip tooltip={t().financials.copyBooking} onClick={() => dialog.open()}>
          <FileCopy />
        </IconButtonWithTooltip>
      ) : (
        <Button variant={variant} color={color} onClick={() => dialog.open()}>
          {buttonName || t().financials.newManualBookingTitle}
        </Button>
      )}
      {dialog.isOpen && <NewManualBookingButtonIntFormLoader dialog={dialog} {...props} />}
    </>
  )
}

function NewManualBookingButtonIntFormLoader(props: NewManualBookingButtonPropsInt) {
  const uid = props.initialValues.uid?.id
  const categoryId = props.initialValues.category?.id

  return uid && !categoryId ? (
    <NewManualBookingButtonLoadUserCategory {...props} uid={uid} />
  ) : (
    <NewManualBookingButtonIntForm {...props} />
  )
}

function NewManualBookingButtonLoadUserCategory(
  props: NewManualBookingButtonPropsInt & { uid: string }
) {
  const year = useLicenseYear()
  const { uid, ...rest } = props
  const { data: approvedLicenses, loading, error } = useApprovedLicenses({ uid }, year)
  const categoryId = sortBy(
    (approvedLicenses ? Object.values(approvedLicenses) : []).filter(
      (license) => todoMigrateAssociation(license.licenseAssociation) === props.initialValues.association
    ),
    (license) => license.approvedAt
  ).reverse()[0]?.categoryId

  const rawCategory = categoryById(categoryId)
  const association = rest.initialValues.association
  const category =
    rawCategory &&
    categoryOfAssociationRequired(rawCategory.id, association || rawCategory.associations[0])

  const newRest = {
    ...rest,
    initialValues: {
      ...rest.initialValues,
      category: category ? category : rest.initialValues.category,
    },
  }
  if (error) return <FriendlyError error={error} />
  if (loading) return <Loading />
  return <NewManualBookingButtonIntForm {...newRest} />
}

function NewManualBookingButtonIntForm(props: NewManualBookingButtonPropsInt) {
  const { dialog, admin, initialValues } = props
  const showSuccessMessage = useSuccessSnackbar()
  const showErrorMessage = useErrorSnackbar()
  const autocompleteOptions = useManualBookingAutocompleteOptions()

  return (
    <>
      <Loading loading={autocompleteOptions.loading} />
      <FriendlyError error={autocompleteOptions.error} />
      {!autocompleteOptions.loadingOrError && (
        <Formik
          initialValues={initialValues}
          validationSchema={manualBookingSchema()}
          validateOnMount
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const manualBooking = {
                type: 'manualBooking' as const,
                id: '',
                sportEventId: values.sportEvent?.id || null,
                internalRemarks: values.internalRemarks || null,
                categoryId: values.category?.id || null,
                uid: values.uid?.id || '',
                byUid: admin.uid,
                date: values.date.toISOString(),
                item: {
                  name: values.bookingText,
                  price: values.amount,
                  type: 'billLineItem' as const,
                  association: values.association,
                },
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                tag: values.tag || '',
              }
              await pushManualBooking(db, manualBooking)
              showSuccessMessage(t().alerts.dataSaved)
              setSubmitting(false)
            } catch (error) {
              showErrorMessage(
                `${t().alerts.errorSaving} ${
                  error instanceof Error ? error.message : t().alerts.unknownError
                }`
              )
              setSubmitting(false)
              return false
            }
          }}
        >
          {(form) => (
            <ConfirmDialog
              maxWidth="xl"
              fullWidth
              title={t().financials.newManualBooking}
              buttonText={
                form.isSubmitting
                  ? t().buttons.saving
                  : t().financials.saveNewManualBooking(bookingKindText(form.values.amount))
              }
              dialog={dialog}
              onConfirm={() => form.submitForm()}
              disabled={form.isSubmitting || !form.isValid}
            >
              <Form>
                <ManualBookingFields
                  formik={form}
                  action="new"
                  autocompleteOptions={autocompleteOptions.data}
                />
              </Form>
              <Loading loading={form.isSubmitting} />
            </ConfirmDialog>
          )}
        </Formik>
      )}
    </>
  )
}

function loadInitialValues(): ManualBookingFormData {
  const data = Object.fromEntries(
    Object.entries(samFields().manualBooking).map(([k, v]) => [k, v.default])
  ) as unknown as ManualBookingFormData
  return { ...data, date: new Date() }
}
