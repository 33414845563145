import assertNever from 'assert-never'
import QRCode from 'qrcode'
import { addLogo } from 'app/pages/billing/add-association-logo'
import { cropImageSize, downloadFileOrUndefined } from 'app/storage/storage'
import { domain } from 'shared/config/domain'
import { routes } from 'shared/config/routes'
import {
  categoryByIdRequired,
  categoryOfAssociationRequired,
  fullCategoryShortName,
} from 'shared/data/categories-service'
import { fullLicenseId } from 'shared/data/licenses-service'
import { ApprovedLicense, Documents } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { associationByID, todoMigrateAssociation } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { uploadStatusOk } from 'shared/models/upload-status'
import { truthy } from 'shared/utils/array'
import { withTimeout } from 'shared/utils/time'
import { drawSVG, PDFOptions } from 'utils/pdf/pdf'

export async function ensureFontsAreLoaded() {
  const fonts = ['regular', 'bold'] as const

  await Promise.all(
    fonts.map(async (fontName) => {
      if (fontCache[fontName]) return

      const response = await fetch(`/fonts/${fontName}.ttf`)
      const buffer = await response.arrayBuffer()
      fontCache[fontName] = buffer
    })
  )
}

export const fontCache = {
  regular: undefined as undefined | ArrayBuffer,
  bold: undefined as undefined | ArrayBuffer,
}

export function filename(license: ApprovedLicense, documents: Documents, type: LicenseExportType) {
  const { firstName, lastName } = documents.personalData || {}
  const category = categoryOfAssociationRequired(license.categoryId, license.licenseAssociation)
  return [
    licenseExportTypeName(type),
    license.licenseAssociation,
    category.name,
    fullLicenseId(license),
    firstName,
    lastName,
  ]
    .filter(truthy)
    .join('-')
}

export function licensePdfOptions(filename: string): PDFOptions {
  return {
    name: filename,
    layout: 'portrait',
    /*
     The ratio of the internal value of A4 as described in
     https://github.com/foliojs/pdfkit/blob/master/lib/page.js#L20 and A4 as described in https://en.wikipedia.org/wiki/ISO_216#A_series
     and https://en.wikipedia.org/wiki/ISO/IEC_7810
     */
    // size: [(595.28 / 210) * 53.98, (841.89 / 297) * 85.6],
    size: 'A7',
    margins: { top: 75.1, left: marginLeftRight, right: marginLeftRight, bottom: 0 },
  }
}

export function drawTopBackground(pdf: PDFKit.PDFDocument, category: CategoryOfAssociation) {
  pdf.rect(0, 0, pdf.page.width, topSectionHeight).fill(category.colorLicense).fill('black')
}

export async function drawImages({ pdf, documents, license }: PDFProps) {
  await drawLogo(license, pdf)
  await drawPhoto(documents, pdf)
}

async function drawLogo(license: ApprovedLicense, pdf: PDFKit.PDFDocument) {
  if (categoryByIdRequired(license.categoryId).type === 'supermoto') {
    await drawSamLogoSmall(pdf)
    await drawFmsLogoSmall(pdf)
  } else {
    await drawLogoInner(pdf, license)
  }
}

async function drawLogoInner(pdf: PDFKit.PDFDocument, license: ApprovedLicense) {
  pdf.save().scale(0.5)
  await addLogo(todoMigrateAssociation(license.licenseAssociation), pdf, 10, 8, 'dark')
  pdf.restore()
}

async function drawSamLogoSmall(pdf: PDFKit.PDFDocument) {
  pdf.save().scale(0.28)
  await addLogo('sam', pdf, 10, 82)
  pdf.restore()
}

async function drawFmsLogoSmall(pdf: PDFKit.PDFDocument) {
  pdf.save().scale(0.28)
  await addLogo('fms', pdf, 10, 7, 'dark')
  pdf.restore()
}

async function drawPhoto(documents: Documents, pdf: PDFKit.PDFDocument) {
  if (!uploadStatusOk(documents.photo?.status)) return

  const photo = await withTimeout(120_000, () =>
    downloadFileOrUndefined(documents.photo?.upload?.fullPath || '')
  )
  if (!photo) return

  const photoScale = 0.065
  const photoWidth = cropImageSize.width * photoScale
  const photoHeight = cropImageSize.height * photoScale
  const circleRadius = photoWidth / 2
  const left = 166
  const top = -3
  pdf
    .save()
    .circle(left + photoWidth / 2, top + photoHeight / 2, circleRadius)
    .clip()
    .image(photo, left, top, scaleImage(cropImageSize, photoScale))
    .restore()
}

export async function drawQRCodeOnPDF(props: PDFProps, x: number, y: number) {
  const { pdf } = props
  const url = urlQRCodeWithDomain(props)
  const data = await QRCode.toString(url, { errorCorrectionLevel: 'H', type: 'svg' })
  await drawSVG(pdf, data, x, y)
}

export function qrCodeDataURL(props: LicenseAndType) {
  const url = urlQRCodeWithDomain(props)
  return QRCode.toDataURL(url, { errorCorrectionLevel: 'H', scale: 15 })
}

export function urlQRCodeWithDomain(props: LicenseAndType) {
  return `${domain}${urlQRCode(props)}`
}

function urlQRCode({ license, type }: LicenseAndType): string {
  if (type === 'license') return routes.qrLicenseV2.generateTo(license)
  if (type === 'pitLanePass') return routes.qrPitLane.generateTo(license)
  assertNever(type)
}

interface LicenseAndType {
  license: PDFProps['license']
  type: PDFProps['type']
}

export function drawHeader({ pdf, license, category, type }: PDFProps) {
  const categoryType = category.type === 'other' ? 'FUN' : category.typeName.toUpperCase()
  const licenseType =
    type === 'pitLanePass'
      ? t().licensePdf.pitLane
      : license.licenseAssociation === 'fms'
      ? t().licenseTypesLong[license.licenseType]
      : category.associations.includes('fms') && license.licenseType === 'international'
      ? t().licenseTypesLong[license.licenseType]
      : t().licensePdf.license

  const debug = false
  pdf
    .save()
    .font(titleBoldFontName)
    .fontSize(fontSizeTitleCategoryType)
    .lineWidth(2.5)
    .strokeColor('white')
    .fill('white')
    .text(categoryType, marginLeftRight, 5, { align: 'center', fill: true, stroke: true })
    .rect(45, 20, pdf.page.width - 90, 10.54)
    .fill(debug ? 'red' : category.colorLicense)
    .fontSize(fontSizeTitleLicenseAndYear)
    .lineWidth(1.55)
    .text(`${licenseType} ${category.year}`, { align: 'center', fill: true, stroke: true })
    .stroke()
    .fill('black')
    .fontSize(fontSizeTitleCategoryType)
    .text(categoryType, marginLeftRight, 5, { align: 'center' })
    .fontSize(fontSizeTitleLicenseAndYear)
    .text(`${licenseType} ${category.year}`, { align: 'center', fill: true })
    .rect(45, 0, pdf.page.width - 90, 5.9)
    .fill(debug ? 'blue' : category.colorLicense)
    .rect(45, 32.05, pdf.page.width - 90, 5.54)
    .fill(debug ? 'green' : category.colorLicense)
    .rect(45, 20, pdf.page.width - 90, 2.84)
    .fill(debug ? 'purple' : category.colorLicense)
    .restore()
}

export function drawNumberPlate({ pdf, category, license }: PDFProps) {
  if (!displayIssuedNumber(category, license)) return

  const plateSize = 20
  pdf
    .save()
    .rect(pdf.page.width / 2 - plateSize / 2, topSectionHeight - plateSize / 2, plateSize, plateSize)
    .lineJoin('bevel')
    .fillAndStroke(category.colorBackground || 'white', 'black')
    .fill(category.colorForeground || 'black')
    .fontSize(fontSizeNumberPlate)
    .font(boldFontName)
    .text(`${license.issuedNumber}`, marginLeftRight, topSectionHeight - 4, { align: 'center' })
    .restore()
}

export function drawLicenseID({ pdf, license }: PDFProps) {
  if (!license.shortId) return

  pdf
    .font(titleBoldFontName)
    .font(titleRegularFontName)
    .text(`${t().licenseNumber}: ${fullLicenseId(license)}`)
}

export function drawLicenseDetailsTitle({ pdf, type }: PDFProps) {
  pdf.text('\n').font(titleBoldFontName).text(licenseExportTypeName(type)).font(titleRegularFontName)
}

export function drawAssociationAndCategory({ category, pdf }: PDFProps) {
  if (category.type !== 'other') {
    pdf.text(`${fullCategoryShortName(category)}`)
  }
}

export function drawIssuedNumber(props: PDFProps) {
  if (!shouldDrawIssuedNumber(props)) return

  const { pdf, license } = props
  pdf.text(`${t().emails.paymentReadyEmail.number}: #${license.issuedNumber}`)
}

export function shouldDrawIssuedNumber({ category, license }: PDFProps) {
  return category.type !== 'other' && displayIssuedNumber(category, license)
}

function displayIssuedNumber(category: CategoryOfAssociation, license: ApprovedLicense) {
  return category.numberChoice && license.issuedNumber && license.issuedNumber > 0
}

function scaleImage({ width, height }: { width: number; height: number }, ratio: number) {
  return { width: width * ratio, height: height * ratio }
}

function licenseExportTypeName(type: LicenseExportType) {
  return type === 'license'
    ? t().licensePdf.license
    : type === 'pitLanePass'
    ? t().licensePdf.pitLane
    : assertNever(type)
}

export function drawAddress({ pdf, category }: PDFProps) {
  ;[...category.associations].reverse().forEach((association, index) => {
    pdf
      .fontSize(fontSizeFooter)
      .text(
        `${associationByID(association).address} `,
        marginLeftRight,
        topSectionHeight + 240 - index * 8
      )
  })
}

export interface DownloadLicenseProps {
  license: ApprovedLicense
}

export const topSectionHeight = 45
export const marginLeftRight = 7
export const titleBoldFontName = 'bold'
export const titleRegularFontName = 'regular'
export const boldFontName = 'Helvetica-Bold'
export const regularFontName = 'Helvetica'

export const fontSizeTitleCategoryType = 15
export const fontSizeTitleLicenseAndYear = 11
export const fontSizeNumberPlate = 10
export const fontSizeNormal = 10
export const fontSizeOpenTasks = 5
export const fontSizeFooter = 3.6

export interface PDFProps {
  pdf: PDFKit.PDFDocument
  category: CategoryOfAssociation
  license: ApprovedLicense
  documents: Documents
  type: LicenseExportType
}

export type LicenseExportType = 'license' | 'pitLanePass'
