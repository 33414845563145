import { Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ReactNode, useEffect, useState } from 'react'
import { useIdle, useInterval } from 'react-use'
import { t } from 'shared/i18n/current'

export function AppUpdateNotification({ children }: { children?: ReactNode }) {
  const oneHour = 60 * 60 * 1000
  const isIdle = useIdle(oneHour)
  const newVersionAvailable = useNewAppVersionAvailable()
  const snackbar = useSnackbar()
  const [snackbarKey, setSnackbarKey] = useState<string | number>('')

  useEffect(() => {
    if (isIdle && newVersionAvailable) window.location.reload()
  }, [isIdle, newVersionAvailable])

  useEffect(() => {
    if (newVersionAvailable && !snackbarKey) {
      const key = snackbar.enqueueSnackbar(t().newVersion.available, {
        variant: 'info',
        persist: true,
        preventDuplicate: true,
        action: <Button onClick={() => window.location.reload()}>{t().newVersion.reload}</Button>,
      })
      setSnackbarKey(key)
    }
    if (!newVersionAvailable && snackbarKey) {
      snackbar.closeSnackbar(snackbarKey)
      setSnackbarKey('')
    }
  }, [newVersionAvailable, snackbar, snackbarKey])

  return <>{children}</>
}

function useNewAppVersionAvailable() {
  const currentVersion = useAppVersion() || ''
  const [initialVersion, setInitialVersion] = useState('')
  useEffect(() => {
    if (currentVersion && !initialVersion) {
      setInitialVersion(currentVersion)
    }
  }, [currentVersion, initialVersion])
  return currentVersion && initialVersion && currentVersion !== initialVersion
}

function useAppVersion() {
  const [version, setVersion] = useState('')
  const twoMinutes = 2 * 60 * 1000
  useInterval(async () => {
    const serverVersion = await loadAppVersion()
    if (serverVersion && version !== serverVersion) setVersion(serverVersion)
  }, twoMinutes)
  return version
}

async function loadAppVersion() {
  try {
    const response = await fetch('/')
    const result = await response.text()
    return (result || '').trim()
  } catch {
    return ''
  }
}
