import { isValidElement } from 'react'
import reactInnerText from 'react-innertext'
import { t } from 'shared/i18n/current'
import { TableData, TableHeader } from 'shared/utils/table-data'

export function convertData(data: TableData) {
  const headers = data.headers
    ?.filter(shouldExport)
    .map((header) => (typeof header === 'object' ? header.value : header))
  return [headers || [], ...contentsToCSV(data)]
}

function contentsToCSV(data: TableData): (string | number)[][] {
  return data.contents
    .map((rawRow) => (typeof rawRow === 'function' ? rawRow() : rawRow))
    .map((row) =>
      row
        .filter((_cell, index) => shouldExport(data.headers?.[index]))
        .map((cell) =>
          cellToString(isValidElement(cell) ? reactInnerText(cell) : (cell as string | number))
        )
    )
}

function cellToString(cellValue: string | number) {
  return typeof cellValue === 'string' ? cellValue.split('\n').join(';') : cellValue
}

function shouldExport(header: TableHeader | undefined) {
  if (!header) return true
  return typeof header === 'object' ? header.export !== false : true
}

export function tableHeaders(headers: (TableHeader | string)[]) {
  return headers.map<TableHeader>((header) => (typeof header === 'string' ? { value: header } : header))
}

export function actions(): TableHeader {
  return { value: t().actions, align: 'right', export: false }
}

export function headerChf(): TableHeader {
  return { value: t().amountTableCHF, align: 'right' }
}
