import { Box, Divider, createStyles, Grid, makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import type { FormikErrors } from 'formik'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { isAfmMembershipField, PersonalDataFieldsAfm } from 'app/personal-data/personal-data-fields-afm'
import { isFmsMembershipField, PersonalDataFieldsFms } from 'app/personal-data/personal-data-fields-fms'
import { isSamMembershipField, PersonalDataFieldsSam } from 'app/personal-data/personal-data-fields-sam'
import { AssociationID } from 'shared/models/associations'
import { CategoryOfAssociation } from 'shared/models/category'
import { PersonalDataFormData } from 'shared/models/personal-data'

interface AdditionalInfoFormFieldsProps {
  associations: Set<AssociationID>
  lockMemberFields: boolean
  values: PersonalDataFormData
  errors: FormikErrors<PersonalDataFormData> | undefined
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function AdditionalInfoFormFields(props: AdditionalInfoFormFieldsProps) {
  const classes = useStyles()
  const { associations, lockMemberFields, values, errors } = props
  const prefix = ''

  return (
    <Grid container spacing={1} className={classes.gridParent}>
      {associations.has('afm') && (
        <PersonalDataFieldsAfm required withTitle={associations.size > 1} prefix={prefix} />
      )}

      {associations.has('afm') && associations.has('fms') && <GridDivider />}

      {associations.has('fms') && (
        <PersonalDataFieldsFms required withTitle={associations.size > 1} prefix={prefix} />
      )}

      {((associations.has('afm') && associations.has('sam')) ||
        (associations.has('fms') && associations.has('sam'))) && <GridDivider />}

      {associations.has('sam') && (
        <PersonalDataFieldsSam
          withTitle={associations.size > 1}
          errors={errors}
          lockMemberFields={lockMemberFields}
          values={values}
          prefix={prefix}
        />
      )}
    </Grid>
  )
}

function GridDivider() {
  return (
    <Grid item xs={12}>
      <Box mt={2} mb={1}>
        <Divider />
      </Box>
    </Grid>
  )
}

export function samFieldsPersonalDataAssociationsSchema(categories: CategoryOfAssociation[]) {
  const associationsWhichRequireMembership = new Set<AssociationID>(
    categories.filter(({ needsMembership }) => needsMembership).map(({ association }) => association)
  )
  const validations = Object.fromEntries(
    Object.entries(samFields().personalData)
      .filter(
        ([k]) =>
          (associationsWhichRequireMembership.has('sam') && isSamMembershipField(k)) ||
          (associationsWhichRequireMembership.has('afm') && isAfmMembershipField(k)) ||
          (associationsWhichRequireMembership.has('fms') && isFmsMembershipField(k))
      )
      .map(([k, v]) => [
        k,
        isSamMembershipField(k) || isAfmMembershipField(k) || isFmsMembershipField(k)
          ? ((v as any).validationRequired as typeof v.validation)
          : v.validation,
      ])
  )
  return Yup.object().defined().shape(validations)
}
