import { mapKeys } from 'lodash'
import { DB, ManualBooking, ManualPayment } from 'shared/db/db'
import { AssociationID, associations } from 'shared/models/associations'
import { base64UrlDecode, base64UrlEncode } from 'shared/utils/base64'
import { mapValues, strictFromEntries } from 'shared/utils/object'

export async function recalculateTagCaches(db: DB) {
  await recalculateManualPaymentTagsCache(db)
  await recalculateManualBookingTagsCache(db)
}

async function recalculateManualPaymentTagsCache(db: DB) {
  const manualPayments = await db.loadAllManualPayments()
  const counts = calculateTagCountsByAssociation(manualPayments)
  await db.setManualPaymentTagsCache(counts)
}

async function recalculateManualBookingTagsCache(db: DB) {
  const manualBookings = await db.loadAllManualBookings()
  const counts = calculateTagCountsByAssociation(manualBookings)
  await db.setManualBookingTagsCache(counts)
}

function calculateTagCountsByAssociation(manualPayments: ManualPayment[] | ManualBooking[]) {
  const tagCountsByAssociation = strictFromEntries(
    associations.map(({ id }) => [id, {} as Record<string, number>])
  ) as Record<AssociationID, Record<string, number>>

  manualPayments.forEach((p) => {
    if (!p.tag) return
    const encoded = base64UrlEncode(p.tag)

    const association = p.type === 'manualBooking' ? p.item.association : p.association
    const map = tagCountsByAssociation[association] || {}
    const count = map[encoded] || 0
    map[encoded] = count + 1
  })
  return tagCountsByAssociation
}

export function decodeTags(tags: Record<AssociationID, Record<string, number>>) {
  return mapValues(tags, (map) => mapKeys(map, (_v, k) => base64UrlDecode(k)))
}
