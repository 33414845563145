import { categoryOfAssociationByLicense } from 'shared/data/categories-service'
import { maxBalanceDiscrepancyInChf } from 'shared/data/financials'
import { ApprovedLicense, InscriptionBooking, LicenseBooking } from 'shared/db/db'
import { Inscription } from 'shared/sport-events/sport-events'

/**
 * This function must only be used in the payment processor. Once a license is paid, it should stay that way,
 * not matter if the account balance is insufficient afterwards.
 */
export function isLicensePaid(license: ApprovedLicense, allBookings: LicenseBooking[]) {
  const bookings = allBookings.filter(
    (booking) =>
      booking.type === 'licenseBooking' &&
      booking.item.type === 'categoryLineItem' &&
      booking.item.categoryId === license.categoryId
  )

  return noPriceAndNoBooking(license, bookings) || bookings.every(remainingBalanceSufficient)
}

function noPriceAndNoBooking(license: ApprovedLicense, bookings: LicenseBooking[]) {
  const hasBooking = bookings.length > 0
  const category = categoryOfAssociationByLicense(license)
  return category.prices.some((price) => price.value === 0) && !hasBooking
}

/**
 * This function must only be used in the payment processor. Once an inscription is paid, it should stay that way,
 * not matter if the account balance is insufficient afterwards.
 */
export function isInscriptionPaid(inscription: Inscription, bookings: InscriptionBooking[]) {
  const relevantBookings = bookings.filter(
    (booking) =>
      booking.type === 'inscriptionBooking' &&
      booking.uid === inscription.uid &&
      booking.item.sportEventId === inscription.sportEvent &&
      booking.item.categoryId === inscription.category
  )
  return relevantBookings.every(remainingBalanceSufficient)
}

function remainingBalanceSufficient({ remainingBalance }: { remainingBalance?: number }): boolean {
  return typeof remainingBalance === 'number' && (remainingBalance || 0) < maxBalanceDiscrepancyInChf
}
