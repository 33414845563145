import { callRacemanagerApi } from 'app/config/firebase'
import { db } from 'app/db/frontend-db'
import { LoadAllUsersEndpoint, LoadAllUidsEndpoint } from 'shared/api/interfaces'
import { updateShortUids as doUpdateShortUids } from 'shared/users/users-service'

export async function updateShortUids() {
  await doUpdateShortUids(db, await allUids())
}

export function allUids() {
  return callRacemanagerApi<LoadAllUidsEndpoint>('loadAllUids', null)
}

export function allUsers() {
  return callRacemanagerApi<LoadAllUsersEndpoint>('loadAllUsers', null)
}
