import { Box, Button, TextField } from '@material-ui/core'
import assertNever from 'assert-never'
import { useState } from 'react'
import { tableHeaders } from 'app/export/table'
import { ConfirmDialog, UseDialog, useDialog } from 'app/layouts/confirm-dialog'
import { DownloadableTable } from 'app/layouts/downloadable-table'
import { UserName } from 'app/pages/profile/user-name'
import { useIsAdminOrAssociationAdmin } from 'app/themes/user-context'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { UploadMetadata, UploadStatus, UploadStatusDetails } from 'shared/models/upload-status'
import { truthy } from 'shared/utils/array'
import { pFormatDateWithSecondsSpaceDe } from 'shared/utils/date'

interface ConfirmUploadButtonProps {
  upload: UploadStatusDetails | undefined
  metadata: UploadMetadata
  admin: UserQuery
  onUpdateStatus: (status: UploadStatusDetails) => void
}

export function ConfirmUploadButton(props: ConfirmUploadButtonProps) {
  const { admin, metadata, upload, onUpdateStatus } = props
  const isAdmin = useIsAdminOrAssociationAdmin()
  const dialog = useDialog()

  if (!isAdmin || !upload) return null

  const uploaded = upload.status === 'uploaded'
  const verified = upload.status === 'verified'
  const invalid = upload.status === 'invalid'
  const notUploaded = upload.status === 'notUploaded'

  const statusName =
    upload.status === 'uploaded'
      ? t().uploads.newlyUploaded
      : upload.status === 'verified'
      ? t().uploads.verified
      : upload.status === 'invalid'
      ? t().uploads.invalidated
      : upload.status === 'notUploaded'
      ? t().uploads.notUploadedDeleted
      : assertNever(upload.status)

  return (
    <Box py={1}>
      {upload && (
        <Box>
          <DownloadableTable
            disableDownloadButtons
            disableCountButton
            data={{
              headers: tableHeaders(['Feld', 'Wert']),
              contents: [
                [t().status, statusName],
                [t().date, pFormatDateWithSecondsSpaceDe(upload.date)],
                [t().user, upload.byUid ? <UserName uid={upload.byUid} /> : '-'],
                [t().uploads.noteForRider, upload.noteUser],
                [t().uploads.internalNote, upload.noteInternal],
                metadata
                  ? [t().uploads.uploadedOn, pFormatDateWithSecondsSpaceDe(metadata.updatedAt)]
                  : undefined,
                metadata ? [t().uploads.size, metadata.size || ''] : undefined,
                metadata ? [t().uploads.type, metadata.contentType || ''] : undefined,
              ].filter(truthy),
              rawData: undefined,
            }}
          />
        </Box>
      )}
      <Box>
        <Button
          disabled={notUploaded || uploaded}
          onClick={() => onUpdateStatus(statusDetails(admin, 'uploaded'))}
        >
          {!uploaded ? t().uploads.resetToNewlyUploaded : statusName}
        </Button>
        <Button
          disabled={notUploaded || verified}
          onClick={() => onUpdateStatus(statusDetails(admin, 'verified'))}
        >
          {!verified ? t().uploads.verify : statusName}
        </Button>
        <Button disabled={notUploaded || invalid} onClick={dialog.open}>
          {!invalid ? t().uploads.invalidate : statusName}
        </Button>
        <InvalidateDialog dialog={dialog} admin={admin} onUpdateStatus={onUpdateStatus} />
      </Box>
    </Box>
  )
}

interface InvalidateDialogProps {
  dialog: UseDialog
  admin: UserQuery
  onUpdateStatus: (status: UploadStatusDetails) => void
}

function InvalidateDialog(props: InvalidateDialogProps) {
  const { dialog, admin, onUpdateStatus } = props
  const [noteUser, setNoteUser] = useState('')
  const [noteInternal, setNoteInternal] = useState('')

  return (
    <ConfirmDialog
      title={t().uploads.invalidate}
      dialog={dialog}
      onConfirm={() =>
        onUpdateStatus({
          date: new Date().toISOString(),
          status: 'invalid',
          noteUser,
          noteInternal,
          byUid: admin.uid,
        })
      }
      buttonText={t().uploads.invalidate}
      fullWidth
      disabled={!noteUser}
    >
      <Box>
        <TextField
          fullWidth
          required
          variant="outlined"
          id="noteUser"
          label={t().uploads.noteForRider}
          value={noteUser}
          onChange={(e) => setNoteUser(e.target.value)}
        />
      </Box>
      <Box mt={1}>
        <TextField
          fullWidth
          variant="outlined"
          id="noteInternal"
          label={t().uploads.internalNote}
          value={noteInternal}
          onChange={(e) => setNoteInternal(e.target.value)}
        />
      </Box>
    </ConfirmDialog>
  )
}

function statusDetails(admin: UserQuery, status: UploadStatus): UploadStatusDetails {
  return {
    date: new Date().toISOString(),
    status,
    noteInternal: '',
    noteUser: '',
    byUid: admin.uid,
  }
}
