import assertNever from 'assert-never'
import { categoryOfAssociationRequired, isCategoryId } from 'shared/data/categories-service'
import { Year } from 'shared/data/license-year'
import { DayCategory, InscriptionCategoryID } from 'shared/db/day-category'
import { ApprovedLicense, DB, Documents, UserQuery } from 'shared/db/db'
import { SportEventId } from 'shared/db/sport-event-id'
import { UserId } from 'shared/db/user-id'
import { inscriptionStatus } from 'shared/inscription/inscription-status-service'
import {
  openDayLicenseTasks,
  dayLicenseYearCategoryInscriptionTasks,
  licenseInscriptionTasks,
} from 'shared/license/license-tasks'
import { CategoryId } from 'shared/models/category'
import { ExportOptions } from 'shared/models/transponder-type'
import {
  exportEnlistedInscriptionToPublicInscription,
  sportEventExportOptions,
} from 'shared/my-laps/mylaps-export'
import {
  InscribedInscription,
  Inscription,
  isDayInscriptionYearCategoryInscriptionOrDraft,
  isDayInscriptionDayCategoryInscriptionOrDraft,
  PublicInscription,
  SportEvent,
  isUnlistedInscription,
  isEnlistedInscription,
  EnlistedInscription,
} from 'shared/sport-events/sport-events'
import {
  deletePublicInscriptions,
  isLessThan3DaysAfterSportEvent,
  isMoreThan1MonthAfterSportEvent,
  isMoreThan3DaysAfterSportEvent,
} from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'
import { DateString, matchesYear } from 'shared/utils/date'

export async function updateInscriptionListByUser(db: DB, user: UserQuery) {
  const inscriptions = await db.loadInscriptionsByUser(user)
  await Promise.all(
    inscriptions.map((inscription) => updateInscriptionListByInscription(db, inscription))
  )
}

export async function updateInscriptionListByInscription(db: DB, inscriptionQuery: Inscription) {
  const inscription = await db.loadInscription(inscriptionQuery)
  if (!inscription) return await db.deletePublicInscription(inscriptionQuery)

  const dayCategory = await db.loadDayCategory({
    sportEvent: inscription.sportEvent,
    category: inscription.category,
  })

  const documents = await db.loadDocuments(inscription)
  const license = isCategoryId(inscription.category)
    ? await db.loadApprovedLicense(inscription, inscription.category, inscription.year)
    : undefined

  const sportEvent = await db.loadSportEvent(inscription.sportEvent)

  if (!sportEvent) return
  if (!documents || isMoreThan1MonthAfterSportEvent(sportEvent))
    return await db.deletePublicInscription(inscriptionQuery)

  if (isUnlistedInscription(inscription)) return await db.deletePublicInscription(inscriptionQuery)

  if (isEnlistedInscription(inscription)) {
    const inscribed = isInscribed(dayCategory, inscription, documents, license)
    const exportOptions = sportEventExportOptions(sportEvent)
    const publicInscription = generatePublicInscription({
      inscription,
      dayCategory,
      documents,
      license,
      exportOptions,
      inscribed,
    })
    return await db.setPublicInscription(publicInscription)
  }

  assertNever(inscription)
}

interface P1 {
  inscription: EnlistedInscription
  dayCategory: DayCategory | undefined
  documents: Documents
  license: ApprovedLicense | undefined
  exportOptions: ExportOptions
  inscribed: boolean
}

export async function updateInscriptionListsOfSportEvent(db: DB, sportEvent: SportEvent) {
  if (!sportEvent) return
  if (isMoreThan1MonthAfterSportEvent(sportEvent)) return deletePublicInscriptions(db, sportEvent)
  if (isMoreThan3DaysAfterSportEvent(sportEvent)) return

  const year = sportEvent.year
  const licenses = await db.loadAllApprovedLicensesByUserByCategory(year)
  const allDocuments = await db.loadAllDocuments()
  const inscriptions = await db.loadInscriptionsBySportEvent(sportEvent)
  const dayCategories = await db.loadAllDayCategoriesById(year)

  const publicInscriptions = inscriptions
    .map((inscription) => {
      if (sportEvent.id !== inscription.sportEvent)
        throw new Error(`Inscription ${inscription.uid} has wrong sport event`)

      const license: ApprovedLicense | undefined =
        licenses[inscription.uid]?.[inscription.category as CategoryId]
      const documents: Documents = allDocuments[inscription.uid]
      const exportOptions = sportEventExportOptions(sportEvent)

      const dayCategory = dayCategories[inscription.category]
      const inscribed = isInscribed(dayCategory, inscription, documents, license)
      return documents && isEnlistedInscription(inscription)
        ? generatePublicInscription({
            inscription,
            license,
            documents,
            exportOptions,
            dayCategory,
            inscribed,
          })
        : undefined
    })
    .filter(truthy)

  const groupedByEvent = groupInscriptions(publicInscriptions)[sportEvent.id]
  await db.setAllPublicInscriptions(sportEvent.id, groupedByEvent)
}

export async function updateAllInscriptionLists(db: DB, year: Year) {
  const licenses = await db.loadAllApprovedLicensesByUserByCategory(year)
  const allDocuments = await db.loadAllDocuments()
  const inscriptions = await db.loadAllInscriptions()
  const sportEvents = await db.loadAllSportEventsOfYearById(year)
  const dayCategories = await db.loadAllDayCategoriesById(year)

  const publicInscriptions = inscriptions
    .filter((inscription) => matchesYear(inscription.date, year))
    .map((inscription) => {
      const license: ApprovedLicense | undefined =
        licenses[inscription.uid]?.[inscription.category as CategoryId]
      const documents: Documents = allDocuments[inscription.uid]
      const sportEvent = sportEvents[inscription.sportEvent]
      const exportOptions: ExportOptions = {
        transponderType: sportEvent?.transponderType || 'MyLaps',
        format: sportEvent?.transponderType === 'RF' ? 'special' : 'normal',
      }

      const dayCategory: DayCategory | undefined = dayCategories[inscription.category]
      const inscribed = isInscribed(dayCategory, inscription, documents, license)
      return documents && isEnlistedInscription(inscription)
        ? generatePublicInscription({
            inscription,
            license,
            documents,
            exportOptions,
            dayCategory,
            inscribed,
          })
        : undefined
    })
    .filter(truthy)

  const groupedAll = groupInscriptions(publicInscriptions)
  await Promise.all(
    Object.entries(groupedAll).map(async ([sportEventId, groupedByEvent]) => {
      const sportEvent = sportEvents[sportEventId]
      if (sportEvent && isLessThan3DaysAfterSportEvent(sportEvent))
        await db.setAllPublicInscriptions(sportEventId, groupedByEvent)
    })
  )
  await Promise.all(
    Object.values(sportEvents).map(async (sportEvent) => {
      if (isMoreThan1MonthAfterSportEvent(sportEvent)) await deletePublicInscriptions(db, sportEvent)
    })
  )
}

function generatePublicInscription(props: P1) {
  const { inscription, dayCategory, documents, license, exportOptions, inscribed } = props

  if (inscription.type === 'enlistedLicenseInscription') {
    if (!license) throw new Error(`No license fround for inscription ${JSON.stringify(inscription)}`)
    return exportEnlistedInscriptionToPublicInscription({
      inscription,
      license,
      documents,
      exportOptions,
      inscribed,
    })
  } else if (
    inscription.type === 'enlistedDayInscriptionYearCategory' ||
    inscription.type === 'enlistedDayInscriptionYearCategoryDraft'
  ) {
    return exportEnlistedInscriptionToPublicInscription({
      inscription,
      documents,
      exportOptions,
      inscribed,
    })
  } else if (
    inscription.type === 'enlistedDayInscriptionDayCategory' ||
    inscription.type === 'enlistedDayInscriptionDayCategoryDraft'
  ) {
    return exportEnlistedInscriptionToPublicInscription({
      dayCategory,
      inscription,
      documents,
      exportOptions,
      inscribed,
    })
  }

  assertNever(inscription)
}

function isInscribed(
  dayCategory: DayCategory | undefined,
  inscription: Inscription,
  documents: Documents | undefined,
  license: ApprovedLicense | undefined
): inscription is InscribedInscription {
  if (!documents) return false

  if (
    inscription.type === 'unlistedDayLicenseInscription' ||
    inscription.type === 'unlistedLicenseInscription'
  )
    return false

  if (isDayInscriptionDayCategoryInscriptionOrDraft(inscription)) {
    const category = dayCategory
    if (!category)
      throw new Error(`No day category ${inscription.category} found ${JSON.stringify(inscription)}`)

    const tasks = openDayLicenseTasks({ type: 'day', documents, inscription, category })
    const status = inscriptionStatus(inscription, tasks)
    return status === 'inscribed'
  }

  if (isDayInscriptionYearCategoryInscriptionOrDraft(inscription)) {
    const category = categoryOfAssociationRequired(inscription.category, inscription.association)
    const tasks = dayLicenseYearCategoryInscriptionTasks({ documents, category, inscription })
    const status = inscriptionStatus(inscription, tasks)
    return status === 'inscribed'
  }

  if (!license) throw new Error(`No license found for inscription ${JSON.stringify(inscription)}`)
  const tasks = licenseInscriptionTasks({
    documents,
    inscription,
    category: categoryOfAssociationRequired(inscription.category, inscription.association),
  })
  const status = inscriptionStatus(inscription, tasks)
  return status === 'inscribed'
}

function groupInscriptions(publicInscriptions: PublicInscription[]) {
  const inscriptions: Record<
    SportEventId,
    Record<DateString, Record<InscriptionCategoryID, Record<UserId, PublicInscription>>>
  > = {}
  publicInscriptions.forEach((pi) => {
    inscriptions[pi.sportEvent] = inscriptions[pi.sportEvent] || {}
    inscriptions[pi.sportEvent][pi.date] = inscriptions[pi.sportEvent][pi.date] || {}
    inscriptions[pi.sportEvent][pi.date][pi.category] =
      inscriptions[pi.sportEvent][pi.date][pi.category] || {}
    inscriptions[pi.sportEvent][pi.date][pi.category][pi.uid] = pi
  })
  return inscriptions
}
