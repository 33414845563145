import { last } from 'lodash'
import { createContext, useContext, useEffect, useState } from 'react'
import { nextLicenseYear } from 'shared/data/license-config'
import { Year, years } from 'shared/data/license-year'
import { parseInt10 } from 'shared/utils/number'

export function useYearState(): YearState {
  const [year, setYear] = useState<Year>(loadYearFromLocalStorage() || initialState().year)
  useEffect(() => globalThis.localStorage.setItem('year', year.toString()), [year])
  return { year, setYear }
}

function loadYearFromLocalStorage(): Year | undefined {
  const yearString = globalThis.localStorage.getItem('year')
  if (!yearString) return undefined
  const year = parseInt10(yearString)
  return years.find((providedYear) => providedYear === year)
}

export function useLicenseYear() {
  return useYearContext().year
}

export function useYearContext() {
  return useContext(YearContext)
}

export const YearContext = createContext(initialState())

function initialState(): YearState {
  return { year: last(years) || nextLicenseYear, setYear: () => null }
}

export interface YearState {
  year: Year
  setYear: (year: Year) => void
}
