import type { Theme } from '@material-ui/core'
import { createStyles, makeStyles, Box, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { useIsSmallerThanMd, useIsSmallerThanXs } from 'app/layout/use-small-screen'
import { afmIsOnline } from 'app/license/config'
import { useAssociationContext } from 'app/themes/association-context'
import { useAssociations } from 'app/themes/use-assocation'

export function SignInLogos() {
  const classes = useStyles()
  const context = useAssociationContext()
  const allLogos = useAssociations()
  const xs = useIsSmallerThanXs()
  const md = useIsSmallerThanMd()
  const isDark = useTheme().palette.type === 'dark'

  const logos = allLogos.filter(({ id }) => (context.previewing ? true : afmIsOnline || id !== 'afm'))

  const logoSizes = xs
    ? { active: 100, passive: 65 }
    : md
    ? { active: 140, passive: 100 }
    : { active: 180, passive: 110 }

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="center"
      mb={2}
      style={{ minHeight: logoSizes.active }}
    >
      {logos.map(({ id, darkBackgroundLogo, whiteBackgroundLogo, active }) => (
        <img
          className={clsx(classes.logo, active ? '' : classes.logoInactive)}
          onClick={() => context.setAssociation(id)}
          key={id}
          style={logoSize(active, logoSizes)}
          src={isDark && id !== 'afm' ? darkBackgroundLogo : whiteBackgroundLogo}
          alt={id}
        />
      ))}
    </Box>
  )
}

function logoSize(active: boolean, logoSize: { active: number; passive: number }) {
  const size = active ? logoSize.active : logoSize.passive
  return { width: size, height: size }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      cursor: 'pointer',
      marginLeft: 5,
      marginRight: 5,
      transition: theme.transitions.create(['width', 'height', 'filter'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.complex,
      }),
    },
    logoInactive: {
      filter: 'grayscale(100%)',
      '&:hover': {
        filter: 'grayscale(0%)',
      },
    },
  })
)
