import { Grid } from '@material-ui/core'
import { useAllManualBookingsWithTotalByAssociation } from 'app/db/db-hooks/association-admin-hooks'
import { db } from 'app/db/frontend-db'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { EditManualBookingButton } from 'app/pages/admin/bookings/edit-manual-booking'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { bookingKindText } from 'app/pages/admin/bookings/manual-booking-form'
import {
  CopyOrNewManualBookingButton,
  NewManualBookingButton,
} from 'app/pages/admin/bookings/new-manual-booking'
import { UserName } from 'app/pages/profile/user-name'
import { useUserContext } from 'app/themes/user-context'
import { User } from 'app/users/user'
import { deleteManualBooking } from 'shared/billing/bookings-service'
import { categoryById, categoryCommonName } from 'shared/data/categories-service'
import { t } from 'shared/i18n/current'
import { associationNameWithDefault } from 'shared/models/associations'
import { formatDateWithSecondsSpace, parseISO } from 'shared/utils/date'
import { toChf } from 'shared/utils/number'
import { DeleteButtonIcon } from 'utils/buttons/delete-button-icon'

interface ManualBookingsProps {
  user: User
}

export function ManualBookings({ user }: ManualBookingsProps) {
  const { data, loading, error, loadingOrError } = useAllManualBookingsWithTotalByAssociation()
  const userContext = useUserContext()
  const reversed = [...data].reverse()

  return (
    <TableBox
      title={t().financials.manualBookingsAndCharges}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: [
            { value: t().datetime, maxWidth: 100 },
            { value: t().bookingText, maxWidth: 150 },
            { value: t().user, export: false, maxWidth: 100 },
            { value: t().userID, exportOnly: true },
            { value: t().association, maxWidth: 80, exportOnly: !!userContext.associationAdmin },
            { value: t().category, maxWidth: 100 },
            { value: t().internalRemarks, maxWidth: 100 },
            { value: t().admin, maxWidth: 100, export: false },
            { value: t().adminID, exportOnly: true },
            { value: t().bookingType },
            { value: t().manualBookingTag },
            { value: t().amount.label, align: 'right' },
            actions(),
          ],
          // eslint-disable-next-line react/display-name
          contents: reversed.map((booking) => () => [
            formatDateWithSecondsSpace(parseISO(booking.date)),
            booking.item.name,
            <UserName key="uid" uid={booking.uid} />,
            booking.uid,
            associationNameWithDefault(booking.item.association),
            categoryCommonName(booking.categoryId && categoryById(booking.categoryId)),
            booking.internalRemarks || '',
            <UserName key="byUid" uid={booking.byUid} />,
            booking.byUid,
            bookingKindText(booking.item.price),
            booking.tag || '-',
            toChf(booking.item.price),
            <>
              <FinancialDialog admin={user} user={{ uid: booking.uid }} />
              <CopyOrNewManualBookingButton admin={user} booking={booking} buttonType="copy" />
              <EditManualBookingButton admin={user} booking={booking} />
              <DeleteButtonIcon
                title={t().financials.deleteManualBooking(booking.item.name)}
                onConfirm={() => deleteManualBooking(db, booking, user)}
              />
            </>,
          ]),
          ids: reversed.map(({ id }) => id),
          rawData: reversed.map((row) => JSON.stringify(row)),
        }
      }
    >
      <Grid container justifyContent="flex-end">
        <NewManualBookingButton admin={user} />
      </Grid>
    </TableBox>
  )
}
