import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { ReactNode, useCallback, useState } from 'react'
import { t } from 'shared/i18n/current'

export function useDialog(): UseDialog {
  const [open, setOpen] = useState(false)
  return {
    isOpen: open,
    open: useCallback(() => setOpen(true), []),
    close: useCallback(() => setOpen(false), []),
  }
}

export interface UseDialog {
  isOpen: boolean
  open: () => void
  close: () => void
}

interface ConfirmDialogProps {
  title: string
  children: ReactNode
  buttonText?: string
  onConfirm?: () => Promise<void | boolean | undefined> | void
  onClose?: () => void
  hideCloseButton?: boolean
  disabled?: boolean
  dialog: {
    isOpen: boolean
    close: () => void
  }
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  fullWidth?: boolean
}

export function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    maxWidth,
    disabled,
    dialog,
    onClose,
    onConfirm,
    title,
    children,
    buttonText,
    hideCloseButton,
    fullWidth,
  } = props
  const onConfirmFn = onConfirm || (() => Promise.resolve())
  const [confirming, setConfirming] = useState(false)

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth === undefined ? 'lg' : maxWidth}
      open={dialog.isOpen}
      onClose={dialog.close}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof children === 'string' ? <DialogContentText>{children}</DialogContentText> : children}
      </DialogContent>
      <DialogActions>
        {!hideCloseButton && (
          <Button onClick={dialog.close}>{buttonText ? t().buttons.cancel : t().buttons.close}</Button>
        )}
        {buttonText && (
          <Button
            onClick={async () => {
              setConfirming(true)
              const success = await onConfirmFn()
              setConfirming(false)
              if (success === false) return
              dialog.close()
              onClose?.()
            }}
            color="primary"
            autoFocus
            disabled={disabled || confirming}
          >
            {buttonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

interface SimpleDialogProps {
  children: ReactNode
  dialog: {
    isOpen: boolean
    close: () => void
  }
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  fullWidth?: true
}

export function SimpleDialog(props: SimpleDialogProps) {
  const { maxWidth, dialog, children, fullWidth } = props

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth === undefined ? 'lg' : maxWidth}
      open={dialog.isOpen}
      onClose={dialog.close}
    >
      {children}
    </Dialog>
  )
}
