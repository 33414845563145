import { Delete } from '@material-ui/icons'
import { IconButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { t } from 'shared/i18n/current'
import { useCatchAndDisplayErrorOrSuccess } from 'utils/snackbar'

interface DeleteButtonIconProps {
  onConfirm: () => Promise<void | boolean | undefined | void[]> | void
  title: string
  info?: string
  disabled?: boolean
  tooltip?: string
  size?: 'small'
  confirmation?: string
}

export function DeleteButtonIcon(props: DeleteButtonIconProps) {
  const {
    onConfirm,
    tooltip = t().delete,
    confirmation = t().buttons.deleteButtonConfirmation,
    ...rest
  } = props
  const showError = useCatchAndDisplayErrorOrSuccess()
  const onConfirmWithErrorHandling = () => showError(onConfirm())

  return (
    <IconButtonWithConfirmation
      confirmation={confirmation}
      tooltip={tooltip}
      onConfirm={onConfirmWithErrorHandling}
      {...rest}
    >
      <Delete />
    </IconButtonWithConfirmation>
  )
}

export function DeleteButtonIconWithoutConfirmation({ onConfirm }: { onConfirm: () => void }) {
  const showError = useCatchAndDisplayErrorOrSuccess()
  const onConfirmWithErrorHandling = () => showError(onConfirm())
  return (
    <IconButtonWithTooltip tooltip={t().delete} size="small" onClick={onConfirmWithErrorHandling}>
      <Delete />
    </IconButtonWithTooltip>
  )
}
