import { Grid } from '@material-ui/core'
import { CSSProperties, ReactNode } from 'react'

interface CenterProps {
  children: ReactNode
  minHeight?: CSSProperties['minHeight']
  minWidth?: CSSProperties['minWidth']
}

export function Center({ children, minHeight, minWidth }: CenterProps) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight, minWidth }}
    >
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}
