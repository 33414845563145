export const platformCreditor: PlatformCreditor = {
  name: 'Racemanager',
  address: 'Firststrasse 15',
  zip: 8835,
  city: 'Feusisberg',
  account: 'CH6730058305807805090',
  country: 'CH',
}

export interface PlatformCreditor {
  name: 'Racemanager'
  address: 'Firststrasse 15'
  zip: 8835
  city: 'Feusisberg'
  account: 'CH6730058305807805090'
  country: 'CH'
}
