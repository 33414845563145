import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { sum } from 'lodash'
import { useAllBalances } from 'app/db/db-hooks/main-db-hooks'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { useIsAssociationAdmin } from 'app/themes/user-context'
import { DisabledForAssociationAdmin } from 'app/users/disabled-for-association-admin'
import { DocumentsWithUid, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { reverseFullName, zipPlace } from 'shared/models/personal-data'
import { toChf } from 'shared/utils/number'
import { useSearchQuery } from 'utils/router'

interface UserFinancialsProps {
  admin: UserQuery
}

export function UserFinancials({ admin }: UserFinancialsProps) {
  const userFinancialsFilter = useUserFinancialsFilter()
  const { data: rawData, loading, error, loadingOrError } = useAllBalances(userFinancialsFilter.sortBy)
  const associationAdmin = useIsAssociationAdmin()
  const data = filterUserFinancials(rawData, userFinancialsFilter)

  if (associationAdmin)
    return <DisabledForAssociationAdmin title={t().financials.accountBalancesPerRider} />

  return (
    <>
      <UserFinancialsFilter {...userFinancialsFilter} />

      <ElevatedBox title={t().financials.totalForRider}>
        <Typography component="h4" variant="h5">
          {t().chf} {toChf(sum(data.map(({ balance }) => -balance)))}
        </Typography>
        <Typography component="h4" variant="h5">
          {t().count} {data.length}
        </Typography>
      </ElevatedBox>

      <TableBox
        title={t().financials.accountBalances}
        loading={loading}
        error={error}
        data={
          !loadingOrError && {
            headers: [
              { value: t().userID, exportOnly: true },
              { value: t().user },
              { value: t().place },
              { value: t().accountBalance, align: 'right' },
              actions(),
            ],
            // eslint-disable-next-line react/display-name
            contents: data.map(({ uid, documents, balance }) => () => [
              uid,
              reverseFullName(documents?.personalData),
              zipPlace(documents?.personalData),
              toChf(-balance),
              <FinancialDialog key={uid} admin={admin} user={{ uid }} />,
            ]),
            ids: data.map(({ uid }) => uid),
            rawData: data.map((row) => JSON.stringify(row)),
          }
        }
      />
    </>
  )
}

function UserFinancialsFilter(props: UseUserFinancialsFilter) {
  const { positive, toggle, neutral, negative } = props

  return (
    <ElevatedBox title={t().financials.filter}>
      <FormControlLabel
        control={<Checkbox checked={positive} onChange={() => toggle('positive')} />}
        label={t().financials.riderHasCredit}
      />
      <FormControlLabel
        control={<Checkbox checked={neutral} onChange={() => toggle('neutral')} />}
        label={t().financials.balancingAccounts}
      />
      <FormControlLabel
        control={<Checkbox checked={negative} onChange={() => toggle('negative')} />}
        label={t().financials.riderOwsMoney}
      />
    </ElevatedBox>
  )
}

function useUserFinancialsFilter(): UseUserFinancialsFilter {
  const search = useSearchQuery()
  const q = search.q
  const positive = !q.includes('hide-positive')
  const neutral = !q.includes('hide-neutral')
  const negative = !q.includes('hide-negative')
  const sortBy = !positive || !negative ? 'balance' : 'name'
  return { positive, neutral, negative, sortBy, toggle: (part) => search.toggle(`hide-${part}`) }
}

interface UseUserFinancialsFilter {
  positive: boolean
  neutral: boolean
  negative: boolean
  sortBy: 'balance' | 'name'
  toggle: (part: 'positive' | 'negative' | 'neutral') => void
}

function filterUserFinancials(
  rawData: { uid: string; documents: DocumentsWithUid | undefined; balance: number }[],
  userFinancialsFilter: UseUserFinancialsFilter
) {
  return rawData.filter(({ balance }) => {
    if (balance < 0.00001) return userFinancialsFilter.positive
    if (balance > -0.00001) return userFinancialsFilter.negative
    return userFinancialsFilter.neutral
  })
}
