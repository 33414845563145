import firebase from 'firebase/app'
import { useEffect, useRef, useState } from 'react'
import { useAsync } from 'react-async-hook'
import { callRacemanagerApi } from 'app/config/firebase'
import { fixClosureLibraryIssue } from 'app/users/auth-wrapper'
import {
  ChangeEmailEndpoint,
  ChangeEmailQuery,
  CreateUserEndpoint,
  CreateUserQuery,
  ImpersonateUserEndpoint,
  ImpersonateUserQuery,
  ListUsersEndpoint,
  ListUsersQuery,
  ListUsersResult,
  UserRecord,
} from 'shared/api/interfaces'
import { olderThan } from 'shared/utils/date'

export async function impersonateUser(query: ImpersonateUserQuery) {
  const { token } = await callRacemanagerApi<ImpersonateUserEndpoint>('impersonateUser', query)
  await fixClosureLibraryIssue(firebase.auth().signInWithCustomToken(token))
}

export async function changeEmail(query: ChangeEmailQuery) {
  await callRacemanagerApi<ChangeEmailEndpoint>('changeEmail', query)
}

export function createUser(query: CreateUserQuery) {
  return callRacemanagerApi<CreateUserEndpoint>('createUser', query)
}

export function useAllUsersWithReload(reloadDuration: Duration) {
  const [users, setUsers] = useState<UserRecord[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()
  const lastLoadingDone = useRef('')
  const currentlyLoading = useRef(false)
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const intervalHandle = setInterval(async () => {
      if (
        document.hidden ||
        currentlyLoading.current ||
        (lastLoadingDone.current && !olderThan(lastLoadingDone.current, reloadDuration))
      )
        return
      currentlyLoading.current = true
      setError(undefined)
      try {
        setUsers(await listAllUsers())
        lastLoadingDone.current = new Date().toISOString()
      } catch (error: any) {
        setError(error)
      }
      currentlyLoading.current = false
      setLoading(false)
    }, 1000)
    return () => clearInterval(intervalHandle)
  }, [setUsers, setError, setLoading, reloadDuration])
  return { users, loading, error }
}

export function useAllUsers() {
  const [users, setUsers] = useState<UserRecord[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()
  useAsync(async () => {
    setError(undefined)
    setLoading(true)
    try {
      setUsers(await listAllUsers())
    } catch (error: any) {
      setError(error)
    }
    setLoading(false)
  }, [setUsers])
  return { users, loading, error }
}

export function useUsers(maxResults: number | undefined) {
  const [userResult, setUserResult] = useState<ListUsersResult>()
  const [allUsers, setAllUsers] = useState<UserRecord[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()
  useEffect(() => {
    listUsers({ maxResults })
      .then((result) => {
        setLoading(false)
        setAllUsers(result.users)
        setUserResult(result)
      })
      .catch(setError)
  }, [maxResults])

  const loadMore = userResult?.pageToken
    ? () => {
        setLoading(true)
        listUsers({ maxResults, pageToken: userResult.pageToken })
          .then((result) => {
            setLoading(false)
            setAllUsers([...allUsers, ...result.users])
            setUserResult(result)
          })
          .catch(setError)
      }
    : undefined
  return { allUsers, loading, error, loadMore, loadingOrError: loading || error }
}

async function listAllUsers() {
  const loadedUsers: UserRecord[] = []
  let nextPageToken: string | undefined = undefined
  while (loadedUsers.length === 0 || nextPageToken) {
    const query: ListUsersQuery = { maxResults: 1000, pageToken: nextPageToken }
    const { users, pageToken } = await listUsers(query)
    nextPageToken = pageToken
    loadedUsers.push(...users)
  }
  return loadedUsers
}

function listUsers(query: ListUsersQuery): Promise<ListUsersResult> {
  return callRacemanagerApi<ListUsersEndpoint>('listUsers', query)
}
