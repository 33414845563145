import { DB } from 'shared/db/db'
import {
  addMissingTwoDayDiscounts,
  simulateAddMissingTwoDayDiscounts,
} from 'shared/inscription/inscription-discount-service'

export const id = '2022-07-26T16-29-51-179Z'

export const description = 'Add 2-day-discounts to inscriptions'

const simulate = false

export async function up(db: DB) {
  if (simulate) await simulateAddMissingTwoDayDiscounts(db)
  else await addMissingTwoDayDiscounts(db)
}
