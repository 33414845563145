import type { PopoverProps } from '@material-ui/core'
import { SyntheticEvent, useState } from 'react'
import { useBoolean } from 'utils/use-boolean'

export function useMenuWithAnchor() {
  const [menu, setMenu] = useState<PopoverProps['anchorEl']>()

  return {
    open: (e: SyntheticEvent) => setMenu(e.currentTarget),
    close: () => setMenu(undefined),
    anchorEl: menu,
    isOpen: !!menu,
  }
}

export function useMenu() {
  const open = useBoolean(false)

  return {
    open: () => open.setTrue(),
    close: () => open.setFalse(),
    toggle: () => open.toggle(),
    isOpen: open.value,
  }
}
