import { categoryByIdRequired, categoryCommonName, isCategoryId } from 'shared/data/categories-service'
import { fullLicenseId } from 'shared/data/licenses-service'
import { DayCategory } from 'shared/db/day-category'
import {
  ApprovedLicense,
  Bill,
  Documents,
  InscriptionWithContextAndSportEvent,
  LicenseDraftWithDocuments,
  PaymentWithoutUid,
  SingleLicenseDraft,
} from 'shared/db/db'
import { UserId } from 'shared/db/user-id'
import { t, todoT } from 'shared/i18n/current'
import {
  inscriptionCategoryName,
  inscriptionIssuedOrPreferredNumber,
} from 'shared/inscription/inscription-categories-service'
import { CategoryId } from 'shared/models/category'
import { PersonalData } from 'shared/models/personal-data'
import { deserializeLicenseCategoryIDs } from 'shared/sport-events/sport-event-categories-serialization'
import {
  isDayInscriptionDayCategoryInscriptionOrDraft,
  isDayInscriptionYearCategoryInscriptionOrDraft,
  isLicenseInscription,
  isUnlistedInscription,
  SportEvent,
} from 'shared/sport-events/sport-events'
import { isFutureSportEvent, isPastSportEvent } from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'
import { formatDate, tryParseDateString } from 'shared/utils/date'
import { parseInt10, parseInt10OrUndefined } from 'shared/utils/number'
import { strictEntries } from 'shared/utils/object'

export function searchBills<T extends Bill>(query: string, bills: T[]) {
  const parts = queryParts(query)
  return bills.filter((bill) => billFoundByQueryParts(bill, parts))
}

function billFoundByQueryParts(bill: Bill, parts: QueryPart[]) {
  return parts.length === 0 || parts.every((part) => billFoundByQueryPart(bill, part))
}

function billFoundByQueryPart(bill: Bill, query: QueryPart): boolean {
  const zip = bill.paymentInfo.debitor.zip
  return (
    (query.lower === todoT('ausland') && bill.paymentInfo.debitor.country !== 'CH') ||
    searchString(bill.reference, query) ||
    searchString(bill.date, query) ||
    bill.paymentInfo.amount === query.number ||
    searchString(bill.uid, query) ||
    searchString(bill.title, query) ||
    searchString(bill.paymentInfo.debitor.address, query) ||
    searchString(bill.paymentInfo.debitor.city, query) ||
    searchZip(zip, query) ||
    searchString(bill.paymentInfo.debitor.name, query) ||
    searchString(bill.paymentInfo.debitor.country, query) ||
    searchString(JSON.stringify(bill.paymentInfo), query)
  )
}

export function searchInscriptions(allInscriptions: InscriptionWithContextAndSportEvent[], q: string) {
  const {
    qInscribed,
    qInscriptionPending,
    qUnlisted,
    qDayCategory,
    qDayLicense,
    qLicense,
    qUnlisting,
    query,
  } = extractInscriptionQueryParts(q)
  const parts = queryParts(query)
  const additionalQueryParts = { qDayCategory, qDayLicense, qLicense, qUnlisting }
  const filteredInscriptions = allInscriptions
    .filter((inscription) => inscriptionFoundByQueryParts(inscription, parts, additionalQueryParts))
    .filter((inscription) =>
      qInscribed
        ? inscription.status === 'inscribed'
        : qInscriptionPending
        ? inscription.status === 'inscription-pending'
        : qUnlisted
        ? inscription.status === 'unlisted'
        : true
    )
  return filteredInscriptions
}

function extractInscriptionQueryParts(q: string) {
  const { newQuery: q1, includedPart: qInscribed } = extractQueryPart(
    q,
    t().inscriptionStatusLong.inscribed
  )
  const { newQuery: q2, includedPart: qInscriptionPending } = extractQueryPart(
    q1,
    t().inscriptionStatusLong['inscription-pending']
  )
  const { newQuery: q3, includedPart: qUnlisted } = extractQueryPart(
    q2,
    t().inscriptionStatusLong.unlisted
  )
  const { newQuery: q4, includedPart: qDayCategory } = extractQueryPart(
    q3,
    t().inscriptionTypeSearch.dayCategory
  )
  const { newQuery: q5, includedPart: qDayLicense } = extractQueryPart(
    q4,
    t().inscriptionTypeSearch.dayLicense
  )
  const { newQuery: q6, includedPart: qLicense } = extractQueryPart(
    q5,
    t().inscriptionTypeSearch.license
  )
  const { newQuery: q7, includedPart: qUnlisting } = extractQueryPart(
    q6,
    t().inscriptionTypeSearch.unlisting
  )
  return {
    qInscribed,
    qInscriptionPending,
    qUnlisted,
    qDayCategory,
    qDayLicense,
    qLicense,
    qUnlisting,
    query: q7,
  }
}

export function searchDocumentsMap<T extends SearchableDocumentsWithoutUid>(
  query: string,
  data: Record<string, T>
): Record<string, T & { uid: UserId }> {
  const parts = queryParts(query)
  return Object.fromEntries(
    Object.entries(data)
      .map(([uid, documents]) => [uid, { ...documents, uid }] as const)
      .filter(([_, documents]) => searchableDocumentsFoundByQueryParts(documents, parts))
  )
}

export function searchDocuments<T extends SearchableDocuments>(query: string, data: T[]) {
  const parts = queryParts(query)
  return data.filter((documents) => searchableDocumentsFoundByQueryParts(documents, parts))
}

export function searchableDocumentsFoundByQueryParts(
  documents: SearchableDocuments,
  parts: QueryPart[]
) {
  return (
    parts.length === 0 || parts.every((part) => searchableDocumentsFoundByQueryPart(documents, part))
  )
}

export function searchableDocumentsFoundByQueryPart(
  docs: SearchableDocuments,
  query: QueryPart
): boolean {
  const { uid, personalData, memberFeesPaidAt } = docs

  if (query.date) return personalData?.birthdate === formatDate(query.date)
  if (query.lower === todoT('mg-bezahlt')) return !!memberFeesPaidAt
  if (query.lower === todoT('mg-offen')) return !memberFeesPaidAt
  if (docs.payments && query.lower === todoT('qr-bezahlt')) return docs.payments.length > 0
  if (docs.payments && query.lower === todoT('qr-offen')) return docs.payments.length === 0

  const zip = personalData?.zip
  return (
    personalData?.samMemberNumber === query.number ||
    uid.toLowerCase() === query.lower ||
    searchString(personalData?.email, query) ||
    searchString(personalData?.place, query) ||
    searchZip(zip, query) ||
    searchString(personalData?.firstName, query) ||
    searchString(personalData?.lastName, query)
  )
}

export function inscriptionFoundByQueryParts(
  inscription: InscriptionWithContextAndSportEvent,
  parts: QueryPart[],
  additionalQueryParts: {
    qDayCategory: boolean
    qDayLicense: boolean
    qLicense: boolean
    qUnlisting: boolean
  }
) {
  const { qDayCategory, qDayLicense, qLicense, qUnlisting } = additionalQueryParts
  if (qDayCategory && !isDayInscriptionDayCategoryInscriptionOrDraft(inscription.inscription))
    return false
  if (qDayLicense && !isDayInscriptionYearCategoryInscriptionOrDraft(inscription.inscription))
    return false
  if (qLicense && !isLicenseInscription(inscription.inscription)) return false
  if (qUnlisting && !isUnlistedInscription(inscription.inscription)) return false

  return (
    parts.length === 0 ||
    parts.every((part) => {
      if (isAssociationQueryPart(part)) return inscriptionFoundByQueryPart(inscription, part)

      return (
        documentsFoundByQueryPart(
          { documents: inscription.documents, uid: inscription.inscription.uid },
          part
        ) ||
        inscriptionFoundByQueryPart(inscription, part) ||
        (inscription.licenseWithContext?.license.approved
          ? draftFoundByQueryPart(
              inscription.licenseWithContext.license,
              inscription.licenseWithContext.approved,
              part
            )
          : false)
      )
    })
  )
}

export function documentsFoundByQueryPart(
  { documents, uid }: { documents: Documents; uid: UserId },
  query: QueryPart
): boolean {
  return (
    documents.personalData?.samMemberNumber === query.number ||
    uid.toLowerCase() === query.lower ||
    searchString(documents.personalData?.place, query) ||
    searchZip(documents.personalData?.zip, query) ||
    searchString(documents.personalData?.firstName, query) ||
    searchString(documents.personalData?.lastName, query)
  )
}

function searchZip(zip: string | number | undefined, query: QueryPart) {
  return (
    zip &&
    ((query.number && typeof zip === 'number' && zip === query.number) ||
      (typeof zip === 'string' && searchString(zip, query)))
  )
}

export function inscriptionFoundByQueryPart(
  inscription: InscriptionWithContextAndSportEvent,
  query: QueryPart
): boolean {
  const issuedOrPreferredNumber = inscriptionIssuedOrPreferredNumber(
    inscription.inscription,
    inscription.licenseWithContext?.license.approved
  )

  if (query.issuedNumber) {
    if (typeof issuedOrPreferredNumber === 'number')
      return query.issuedNumber === issuedOrPreferredNumber
    else return query.issuedNumber === parseInt10OrUndefined(issuedOrPreferredNumber)
  }

  if (isAssociationQueryPart(query)) {
    if (!isCategoryId(inscription.inscription.category)) return false

    const category = categoryByIdRequired(inscription.inscription.category)
    return category.associations.some((association) => queryMatchesAssociation(query, association))
  }

  const types = Object.values(t().inscription.inscriptionTypes).map((s) => s.toLowerCase())
  if (types.includes(query.lower)) {
    const entry = strictEntries(t().inscription.inscriptionTypes).find(
      ([, value]) => value.toLowerCase() === query.lower
    )
    if (!entry) throw new Error('Not possible')
    return entry[0] === inscription.inscription.type
  }

  return (
    inscription.inscription.category === query.lower ||
    (issuedOrPreferredNumber && issuedOrPreferredNumber === query.lower) ||
    (issuedOrPreferredNumber && issuedOrPreferredNumber === query.number) ||
    inscription.inscription.date === query.lower ||
    inscription.inscription.borrowedTransponder?.toString() === query.lower ||
    searchString(inscription.inscription.sidecarPartner, query) ||
    searchString(inscription.inscription.type, query) ||
    searchString(inscriptionCategoryName(inscription.inscription, inscription.dayCategory), query)
  )
}

export function licenseDraftFoundByQueryParts(
  draft: LicenseDraftWithDocuments,
  approvedLicenses: ApprovedLicense[],
  parts: QueryPart[]
) {
  return (
    parts.length === 0 || parts.every((part) => draftFoundByQueryPart(draft, approvedLicenses, part))
  )
}

export function draftFoundByQueryPart(
  { type, draft, documents, userId }: LicenseDraftWithDocuments,
  approvedLicenses: ApprovedLicense[],
  query: QueryPart
): boolean {
  // TODO: i18n?
  if (query.lower === 'neu') return type === 'draft'
  // TODO: i18n?
  if (query.lower === 'bestätigt') return type === 'approved'
  if (query.issuedNumber) {
    const approvedLicense = findApprovedLicense(approvedLicenses, draft)
    if (approvedLicense) return query.issuedNumber === approvedLicense.issuedNumber
    else return query.issuedNumber === parseInt10OrUndefined(draft.categoryDetails?.preferredNumber)
  }

  if (isAssociationQueryPart(query))
    return approvedLicenses.some((license) => queryMatchesAssociation(query, license.licenseAssociation))

  const approvedLicense = findApprovedLicense(approvedLicenses, draft)

  return (
    draft.categoryId === query.lower ||
    draft.categoryDetails?.preferredNumber === query.lower ||
    (approvedLicense && fullLicenseId(approvedLicense).toLowerCase() === query.lower) ||
    searchString(categoryCommonName(draft.category), query) ||
    documentsFoundByQueryPart({ documents, uid: userId }, query)
  )
}

function findApprovedLicense(approvedLicenses: ApprovedLicense[], draft: SingleLicenseDraft) {
  return approvedLicenses.find((license) => license.categoryId === draft.categoryId)
}

export function isAssociationQueryPart(query: QueryPart) {
  return (
    query.original === 'SAM' ||
    query.original === 'sam' ||
    query.lower === 'swissmoto' ||
    query.lower === 'fms' ||
    query.lower === 'mxrs' ||
    query.lower === 'afm' ||
    query.lower === 'sjmcc'
  )
}

export function queryMatchesAssociation(query: QueryPart, association: string): boolean {
  return query.lower === 'sam'
    ? !association || association === 'sam'
    : query.lower === 'fms' || query.lower === 'swissmoto'
    ? association === 'fms'
    : query.lower === 'mxrs'
    ? association === 'mxrs'
    : query.lower === 'afm'
    ? association === 'afm'
    : query.lower === 'sjmcc'
    ? association === 'sjmcc'
    : false
}

export function searchSportEventsWithDayCategories(query: string, data: SportEventWithCategories[]) {
  const parts = queryParts(query)
  return data.filter(({ sportEvent }) => sportEventFoundByQueryParts(sportEvent, parts))
}

export function searchSportEvents(query: string, data: SportEvent[]) {
  const parts = queryParts(query)
  return data.filter((event) => sportEventFoundByQueryParts(event, parts))
}

function sportEventFoundByQueryParts(event: SportEvent, parts: QueryPart[]) {
  return parts.length === 0 || parts.every((part) => sportEventFoundByQueryPart(event, part))
}

export function searchSportEventsWithCategories(query: string, sportEvents: SportEvent[]) {
  const parts = queryParts(query)
  return sportEvents.map((sportEvent) => ({
    found: sportEventWithCategoriesFoundByQueryParts(sportEvent, parts),
    sportEvent,
  }))
}

function sportEventWithCategoriesFoundByQueryParts(sportEvent: SportEvent, parts: QueryPart[]) {
  return (
    parts.length === 0 ||
    parts.every(
      (part) =>
        searchString(sportEvent.name, part) || sportEventCategoryFoundByQueryPart(sportEvent, part)
    )
  )
}

function sportEventFoundByQueryPart(event: SportEvent, query: QueryPart): boolean {
  if (query.lower === todoT('vergangenheit')) return isPastSportEvent(event)
  if (query.lower === todoT('zukunft')) return isFutureSportEvent(event)

  return (
    searchString(event.name, query) ||
    searchString(event.place, query) ||
    searchString(event.licenseCategoryIds, query) ||
    searchString(event.startsAt, query) ||
    searchString(event.endsAt, query) ||
    searchString(event.alternativeStartsAt, query) ||
    searchString(event.alternativeEndsAt, query) ||
    searchString(
      Object.values(event.links || {})
        .map((link) => link.url)
        .join(' '),
      query
    )
  )
}

function sportEventCategoryFoundByQueryPart(sportEvent: SportEvent, query: QueryPart): boolean {
  return (
    searchString(sportEvent.dayCategoryNamesForSearch || '', query) ||
    deserializeLicenseCategoryIDs(sportEvent.licenseCategoryIds).some((categoryId) =>
      licenseCategoryFoundByQueryPart(categoryId, query)
    )
  )
}

function licenseCategoryFoundByQueryPart(categoryId: CategoryId, query: QueryPart): boolean {
  const category = categoryByIdRequired(categoryId)

  if (isAssociationQueryPart(query))
    return category.associations.some((association) => queryMatchesAssociation(query, association))

  return searchString(categoryCommonName(category), query)
}

function searchString(text: string | undefined | number, queryPart: QueryPart): boolean {
  return typeof text === 'number'
    ? text === queryPart.number
    : !!text && text.toLowerCase().includes(queryPart.lower)
}

interface SearchableDocumentsWithoutUid {
  personalData?: PersonalData | undefined
  memberFeesPaidAt?: string | undefined
  payments?: PaymentWithoutUid[]
}

interface SearchableDocuments {
  personalData?: PersonalData | undefined
  uid: string
  memberFeesPaidAt?: string | undefined
  payments?: PaymentWithoutUid[]
}

export function extractQueryPart(query: string, part: string) {
  const parts = queryParts(query)
  const includedPart = parts.map(({ lower }) => lower).includes(part.toLowerCase())
  const newQuery = query.toLowerCase().split(part.toLowerCase()).join('')
  return { newQuery, includedPart }
}

export function queryParts(query: string): QueryPart[] {
  const quote = '%doublequote%'
  return query
    .trim()
    .split('""')
    .join(quote)
    .split(' ')
    .map((part) => part.trim())
    .filter(truthy)
    .join(' ')
    .split('"')
    .flatMap((subpart, index) => (index % 2 === 0 ? subpart.split(' ') : [subpart.trim()]))
    .filter(truthy)
    .map((part) => part.split(quote).join('"'))
    .map((part) => ({
      original: part,
      lower: part.toLowerCase(),
      number: parseNumber(part),
      date: tryParseDateString(part),
      issuedNumber: tryParseIssuedNumber(part),
    }))
}

function parseNumber(part: string) {
  const number = parseInt10(part)
  return number.toString() === part ? number : NaN
}

function tryParseIssuedNumber(part: string) {
  if (part.startsWith('#')) {
    const rest = part.slice(1)
    if (!rest) return NaN
    const number = parseInt10(rest)
    if (number && number.toString() === rest) return number
  }
  return NaN
}

export interface QueryPart {
  original: string
  lower: string
  number: number
  date: Date | undefined
  issuedNumber: number
}

export interface SportEventWithCategories {
  sportEvent: SportEvent
  dayCategories: DayCategory[]
}
