import { DB } from 'shared/db/db'

export const id = '2023-01-20T08-15-38-192Z'

export const description = 'Migrates category draft bike make / team name to approved license for 2023'

export async function up(db: DB) {
  const licenses = await db.loadAllApprovedLicenses(2023)
  const unmigratedLicenses = licenses.filter((license) => !license.bikeMake && !license.teamName)
  await Promise.all(
    unmigratedLicenses.map(async (license) => {
      const draftDetails = await db.loadApprovedDraftCategoryDetails(license, license.categoryId, 2023)

      if (!draftDetails) return

      const { bikeMake, teamName } = draftDetails
      if (!bikeMake && !teamName) return

      await db.setApprovedLicense(
        { ...license, bikeMake: bikeMake || null, teamName: teamName || null },
        2023
      )
    })
  )
}
