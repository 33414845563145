import { Box } from '@material-ui/core'
import { AccountBox } from '@material-ui/icons'
import { ButtonWithTooltip } from 'app/layout/button-with-tooltip'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { DownloadLicenseProps, generateLicense } from 'app/pages/licenses/pdf-license-service'
import { ApprovedLicense } from 'shared/db/db'
import { t, temporarilyChangeLocale } from 'shared/i18n/current'
import { changeLocaleText } from 'shared/i18n/i18n-types'
import { catchAndLog } from 'utils/error-boundary'
import { FriendlyError, useError } from 'utils/errors'

export interface DownloadLicenseButtonsProps {
  license: ApprovedLicense
}

export function DownloadLicenseButtons({ license }: DownloadLicenseButtonsProps) {
  const { error, setError } = useError()

  return (
    <>
      <FriendlyError error={error} />
      {Object.values(changeLocaleText).map((locale) => (
        <ButtonWithTooltip
          tooltip={`${t().licensesBoxes.download.license} (${locale.buttonText})`}
          key={locale.locale}
          onClick={() =>
            catchAndLog(setError, () =>
              temporarilyChangeLocale(locale.locale, () => generateLicense(license, 'license'))
            )
          }
        >
          {t().licensePdf.license} {locale.buttonText}
        </ButtonWithTooltip>
      ))}

      <Box>
        <FriendlyError error={error} />
        <ButtonWithTooltip
          disabled={!license.pitLanePassCode}
          tooltip={`${t().licensesBoxes.download.pitLanePass}`}
          onClick={() => catchAndLog(setError, () => generateLicense(license, 'pitLanePass'))}
        >
          {t().licensePdf.pitLaneDownload}
        </ButtonWithTooltip>
      </Box>
    </>
  )
}

export function DownloadLicenseButton({ license, type }: DownloadLicenseProps) {
  const { error, setError } = useError()

  return (
    <>
      <FriendlyError error={error} />
      <IconButtonWithTooltip
        tooltip={t().licensesBoxes.download[type]}
        onClick={() => catchAndLog(setError, () => generateLicense(license, type))}
      >
        <AccountBox />
      </IconButtonWithTooltip>
    </>
  )
}
