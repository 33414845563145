import { ButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { t } from 'shared/i18n/current'

interface DeleteButtonWithConfirmationProps {
  onConfirm: () => Promise<void | boolean | undefined> | void
  title: string
  disabled?: boolean
  fullWidth?: boolean
  confirmation?: string
}

export function DeleteButtonWithConfirmation(props: DeleteButtonWithConfirmationProps) {
  const { confirmation = t().buttons.deleteButtonConfirmation, ...rest } = props
  return <ButtonWithConfirmation confirmation={confirmation} {...rest} />
}
