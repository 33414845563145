import assertNever from 'assert-never'
import { MaybeFBError } from 'app/db/db-hooks/db-hook-helpers'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { refineTransaction } from 'app/pages/admin/financials/refine-transactions'
import { UserState, useUserContext } from 'app/themes/user-context'
import { FilteredBookingRelevantTransaction, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { SportEvent } from 'shared/sport-events/sport-events'
import { toChf } from 'shared/utils/number'
import { TableData } from 'shared/utils/table-data'

interface TransactionsByTypeProps {
  loading: boolean
  error: MaybeFBError
  sportEvents: SportEvent[]
  admin: UserQuery
  data: FilteredBookingRelevantTransaction[]
}

export function TransactionsByTransactionType(props: TransactionsByTypeProps) {
  const userContext = useUserContext()
  const { loading, error, sportEvents, admin, data } = props
  const loadingOrError = loading || error

  return (
    <TableBox
      title={t().transactions.transactionsByType}
      loading={loading}
      error={error}
      data={!loadingOrError && transactionTableContents(data, admin, userContext, sportEvents)}
    />
  )
}

export function transactionTableContents(
  data: FilteredBookingRelevantTransaction[],
  admin: UserQuery,
  userContext: UserState,
  sportEvents: SportEvent[]
): TableData | false | undefined {
  const transactions = data.map((row) => row.transaction)
  return {
    headers: [
      { value: t().id, maxWidth: 100 },
      { value: t().date, maxWidth: 100, exportOnly: true },
      { value: t().datetime, maxWidth: 100, exportOnly: true },
      { value: t().datetimeISO, maxWidth: 100, exportOnly: true },
      { value: t().bookingType, maxWidth: 150 },
      { value: t().bookingSubtype, maxWidth: 150, exportOnly: true },
      { value: t().bookingItemName, maxWidth: 150, exportOnly: true },
      { value: t().bookingText, maxWidth: 150, exportOnly: true },
      { value: t().reference, maxWidth: 150, exportOnly: true },
      { value: t().status, maxWidth: 100, exportOnly: true },
      { value: t().categoryType, maxWidth: 100 },
      { value: t().category, maxWidth: 100 },
      { value: t().sportEvent, maxWidth: 100 },
      { value: t().user, maxWidth: 100, exportOnly: true },
      { value: t().userID, maxWidth: 100, exportOnly: true },
      { value: t().admin, maxWidth: 100, exportOnly: true },
      { value: t().adminID, maxWidth: 100, exportOnly: true },
      { value: t().association, maxWidth: 100, exportOnly: true },

      { value: t().amount.label, align: 'right', exportOnly: true },
      { value: t().amountOpen, align: 'right', exportOnly: true },
      { value: t().financials.amountPaid, align: 'right' },

      ...(
        [
          'manualBooking',
          'inscriptionBooking',
          'licenseBooking',
          'reverseLicenseBooking',
          'payment',
          'manualPayment',
        ] as const
      ).map((transactionType) => ({
        value: t().transactionTypeNames[transactionType],
        maxWidth: 100,
        align: 'right' as const,
      })),

      actions(),
    ],
    // eslint-disable-next-line react/display-name
    contents: data.map((row) => () => {
      const mapped = refineTransaction(row, transactions, admin, userContext, sportEvents)
      const byTransactionType: Partial<Record<typeof mapped.type, string>> = {
        [mapped.type]: mapped.amountNetFormatted,
      }

      return [
        mapped.id,
        mapped.date,
        mapped.datetime,
        mapped.datetimeISO,
        mapped.typeName,
        mapped.subtypeName,
        mapped.itemName,
        mapped.description,
        mapped.reference,
        mapped.status,
        mapped.categoryTypeName,
        mapped.categoryName,
        mapped.sportEventName,
        mapped.uidName,
        mapped.uid,
        mapped.byUidName,
        mapped.byUid,
        mapped.association,

        mapped.amountFormatted,
        mapped.amountOpenFormatted,
        mapped.amountNetFormatted,

        byTransactionType.manualBooking || toChf(0),
        byTransactionType.inscriptionBooking || toChf(0),
        byTransactionType.licenseBooking || toChf(0),
        byTransactionType.reverseLicenseBooking || toChf(0),
        byTransactionType.payment || toChf(0),
        byTransactionType.manualPayment || toChf(0),

        <FinancialDialog key="financial-dialog" admin={admin} user={{ uid: mapped.uid }} />,
      ]
    }),
    ids: data.map(({ transaction: { id } }) => id || ''),
    rawData: data.map(({ transaction }) =>
      JSON.stringify([
        transaction.id,
        transaction.date,
        transaction.remainingBalance,
        transaction.currentTotal,
        transaction.type === 'inscriptionBooking' ||
        transaction.type === 'licenseBooking' ||
        transaction.type === 'reverseLicenseBooking'
          ? [transaction.byUid]
          : transaction.type === 'manualBooking' || transaction.type === 'manualPayment'
          ? [transaction.byUid, transaction.updatedAt]
          : transaction.type === 'payment'
          ? [transaction.reference]
          : transaction.type === 'associationPayment'
          ? [transaction.id]
          : assertNever(transaction),
      ])
    ),
  }
}
