import { partition, shuffle, sortBy } from 'lodash'
import { IssuedNumber, MyLapsName } from 'shared/api/interfaces'
import { InscriptionWithContextAndSportEvent } from 'shared/db/db'
import { inscriptionIssuedNumber } from 'shared/inscription/inscription-categories-service'
import { startListName } from 'shared/sport-events/sport-event-groups'

export function sortAssignmentsByChampionship(
  assignments: InscriptionWithContextAndSportEvent[],
  championships: Championships
) {
  const shuffled = shuffle(assignments)
  const [partOfChampionship, notPartOfChampionship] = partition(shuffled, (inscription) =>
    isPartOfChampionship(inscription, championships)
  )
  return [
    ...sortBy(shuffle(partOfChampionship), (inscription) =>
      championshipPosition(inscription, championships)
    ),
    ...shuffle(notPartOfChampionship),
  ]
}

function isPartOfChampionship(
  inscription: InscriptionWithContextAndSportEvent,
  championships: Championships
) {
  const issuedNumbers = championships[startListName(inscription)]
  return !!issuedNumbers && issuedNumbers.includes(inscriptionIssuedNumber(inscription))
}

function championshipPosition(
  inscription: InscriptionWithContextAndSportEvent,
  championships: Championships
) {
  const issuedNumbers = championships[startListName(inscription)]
  if (!issuedNumbers) return 99999
  const index = issuedNumbers.indexOf(inscriptionIssuedNumber(inscription))
  return index === -1 ? 99999 : index
}

export type Championships = Record<MyLapsName, IssuedNumber[]>
