import { Typography } from '@material-ui/core'
import { Fragment, ReactNode } from 'react'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { useActiveAssociation } from 'app/themes/use-assocation'
import { t } from 'shared/i18n/current'
import { racemanagerAddress } from 'shared/models/associations'
import { replaceAllMultiple } from 'shared/utils/string'

export function TermsAndDataProtectionText() {
  const associaton = useActiveAssociation()
  return (
    <ElevatedBox title={t().routes.termsAndDataProtection}>
      {data.map(({ title, paragraphs }, index) => (
        <Fragment key={index}>
          <Title>{title}</Title>
          {paragraphs.map((paragraph, index) => (
            <Paragraph key={index}>
              {replaceAllMultiple(paragraph, [
                ['{associationAddress}', associaton.address],
                ['{associationHomepage}', associaton.homepage],
                ['{racemanagerAddress}', racemanagerAddress],
              ])}
            </Paragraph>
          ))}
        </Fragment>
      ))}
    </ElevatedBox>
  )
}

function Title({ children }: { children: ReactNode }) {
  return (
    <Typography gutterBottom variant="h4">
      {children}
    </Typography>
  )
}

function Paragraph({ children }: { children: ReactNode }) {
  return <Typography paragraph>{children}</Typography>
}

const data: {
  title: string
  paragraphs: string[]
}[] = [
  {
    title: 'Übersicht / Overview / Aperçu',
    paragraphs: [
      'Das Impressum und die Datenschutzbestimmungen sind nur auf Deutsch verfügbar.',
      'Verantwortlicher im Sinne des Datenschutzgesetzes (insbesondere der EU-Datenschutzgrundverordnung DSGVO) ist :',
      '{racemanagerAddress}',
      'Vertretungsberechtigte Person: Stephan Kessler',
    ],
  },
  {
    title: 'Deine Rechte',
    paragraphs: [
      'Komm jederzeit auf uns zu, wenn du Fragen zu folgenden Rechten hast:',
      'Auskunft über deine Daten und deren Verarbeitung,',
      'Berichtigung deiner persönlichen Daten',
      'Löschen deiner bei uns gespeicherten Daten,',
      'Einschränkung bei der Datenverarbeitung, oder falls wir diese aufgrund gesetzlicher Pflichten noch nicht löschen dürfen',
      'Widerspruch gegen die Verarbeitung deiner Daten',
      'Datenübertragbarkeit, sofern du einen Vertrag mit uns abgeschlossen hast oder eine Bewilligung zur Datenverarbeitung vorliegt.',
      'Eine einmal erteilte Einwilligung kann selbstverständlich jederzeit widerrufen werden.',
      'Bitte wende dich bei Beschwerden an die für dich zuständige Aufsichtsbehörde. Diese hat ihren Sitz am Ort deines Wohnsitzes, deiner Arbeit, oder dem Ort der mutmasslichen Verletzung.',
    ],
  },

  {
    title: 'Zweck der Datenverarbeitung',
    paragraphs: [
      'Diese Plattform ermöglicht es Verbänden / Unternehmen / Personen (z.B. AFM, MXRS, SAM, SJMCC, Swiss Moto), Lizenzen auszustellen und Veranstaltungen durchzuführen. Dazu werden personenbezogene Daten verarbeitet. Diese Daten werden wenn nötig an die entsprechenden Verbände, Veranstalter und Organisationen weitergegeben, damit sie z.B. eine Lizenz ausstellen können oder eine Veranstaltung durchführen können.',
      'Deine personenbezogenen Daten werden nur zu den in dieser Datenschutzvereinbarung genannten Zwecken benutzt. Wir verzichten darauf, deine persönlichen Daten zu anderen als den genannten Zwecken an Dritte zu übermitteln. Deine persönlichen Daten werden ausschliesslich in folgenden Fällen an Dritte weitergegeben:',
      'Wenn du deine ausdrückliche Einwilligung dazu erteilt hast',
      'Wenn die Verarbeitung zur Abwicklung eines Vertrags erforderlich ist',
      'Wenn die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist',
      'Wenn die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist',
      'Wenn kein Grund zur Annahme besteht, dass du ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe deiner Daten hast und die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist.',
    ],
  },

  {
    title: 'Löschen oder Sperrung deiner Daten',
    paragraphs: [
      'Datenvermeidung und Datensparsamkeit sind uns wichtig. Deine Daten werden daher nur so lange gespeichert, wie dies zur Erreichung der hier genannten Zwecke erforderlich oder vom Gesetzgeber gefordert ist. Deine personenbezogenen Daten werden nach Erfüllung des Zweckes, bzw. nach Ablauf der Fristen entsprechend der gesetzlichen Vorschriften routinemässig gesperrt oder gelöscht.',
      'Du hast die Möglichkeit, deine Daten zu löschen, indem du uns unter {racemanagerAddress} kontaktierst.',
    ],
  },
  {
    title: 'Cookies',
    paragraphs: [
      'Wir verwenden Cookies für unsere Plattform. Cookies sind kleine Textdateien, die beim Besuch unserer Plattform auf deinem Computer dauerhaft oder temporär gespeichert werden. Zweck der Cookies ist insbesondere, dass du dich bei deinem Konto anmelden kannst, und angemeldet bleibst. Weiter benötigen wir Cookies, um die Funktionalität unserer Plattform zu verbessern (z.B. via Tracking, wenn etwas technisch nicht funktioniert, wie es sollte).',
      'Cookies erfassen Nutzungsinformationen, wie Datum und Uhrzeit des Abrufs unserer Internet-Seite, Name der besuchten Internet-Seite, die IP-Adresse deines Endgeräts, sowie das verwendete Betriebssystem. Cookies geben beispielsweise auch Auskunft darüber, welche unserer Internet-Seiten du besuchst und von welcher Webseite aus du auf unsere Internet-Seite gekommen bist. Du kannst Cookies auf unserer Website in deinem Browser jederzeit ganz oder teilweise deaktivieren. Bei deaktivierten Cookies stehen dir allenfalls nicht mehr alle Funktionen unserer Website zur Verfügung.',
    ],
  },
  {
    title: 'Registrierung und Benutzerkonto',
    paragraphs: [
      'Auf unserer Plattform hast du die Möglichkeit, dich als Benutzer zu registrieren. Dadurch kannst du auf Inhalte und Leistungen zugreifen, auf welche nur registrierte Nutzer Zugriff haben. Dazu erheben wir verschiedene personenbezogene Daten, wie z.B. Name, Anschrift, Kontakt- und Kommunikationsdaten wie Telefonnummer und E-Mail-Adresse. Als angemeldeter Nutzer hast du zudem die Möglichkeit, die bei der Registrierung angegebenen Daten jederzeit zu ändern oder zu löschen. Du erhältst auch jederzeit Auskunft über die von uns über dich gespeicherten Daten. Wir berichtigen, bzw. löschen diese auch auf deinen Wunsch, soweit es die gesetzlichen Aufbewahrungspflichten erlauben. Bitte benutze bei allfälligen Fragen in diesem Zusammenhang die Kontaktdaten in dieser Datenschutzerklärung.',
    ],
  },
  {
    title: 'TLS Verschlüsselung',
    paragraphs: [
      'Wir benutzen dem aktuellen Stand der Technik entsprechende sichere kryptografische Verfahren für die Übermittlung und Verarbeitung deiner Daten. Speziell sind deine Daten während der übertragung via HTTPS verschlüsselt.',
    ],
  },
  {
    title: 'E-Mail Benachrichtigungen',
    paragraphs: [
      'Für administrative Zwecke senden wir Nachrichten and deine E-Mail-Adresse (z.B. bei Absage oder Verschiebung einer Veranstaltung, oder falls Daten fehlen, die für deinen Lizenzantrag nötig sind).',
    ],
  },
  {
    title: 'Haftungsausschluss',
    paragraphs: [
      'Der Racemanager (und die damit verbundenen Verbände) übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen. Haftungsansprüche gegen den Racemanager (und die damit verbundenen Verbände) wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen. Alle Angebote sind unverbindlich. Der Racemanager (und die damit verbundenen Verbände) behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.',
    ],
  },
  {
    title: 'Haftung für Links',
    paragraphs: [
      'Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.',
    ],
  },
  {
    title: 'Google Analytics',
    paragraphs: [
      'Wir verwenden für unsere Website Google Analytics, einen Dienst der amerikanischen Google Inc. (nachfolgend «Google»). Google Analytics verwendet unter anderem auch Cookies, die eine statistische Auswertung der Nutzung unserer Website ermöglichen. Durch Google Analytics werden normalerweise Cookies und sonstige Daten über deinen Besuch unserer Website auf Server von Google in den Vereinigten Staaten von Amerika (USA) übertragen und dort bearbeitet beziehungsweise gespeichert. Google nutzt diese Daten, um in unserem Auftrag die Nutzung unserer Website auszuwerten und weitere Dienstleistungen in diesem Zusammenhang zu erbringen. Die verwendeten IP-Adressen werden vor Bearbeitung beziehungsweise Speicherung durch Google gekürzt, um den Schutz deiner Privatsphäre zu verbessern.',
    ],
  },
  { title: 'Kontaktangaben Racemanager', paragraphs: ['{racemanagerAddress}, https://racemanager.io'] },
  {
    title: 'Kontaktangaben gewählter Verband',
    paragraphs: ['{associationAddress}, {associationHomepage}'],
  },
  {
    title: 'Statuten und Reglemente des Verbandes',
    paragraphs: [
      'Im Weiteren gelten die Bestimmungen in den Statuten und den geltenden Reglementen des jeweiligen Verbandes.',
    ],
  },
  {
    title: 'Änderung der Datenschutzbestimmungen',
    paragraphs: [
      'Um aktuellen rechtlichen Forderungen gerecht zu werden oder Änderungen unserer Leistungen umzusetzen (z.B. bei der Einführung neuer Services), behalten wir uns vor, die vorliegende Datenschutzerklärung anzupassen. Bei deinem nächsten Besuch auf dieser Plattform gilt dann die neue Datenschutzerklärung.',
    ],
  },
]
