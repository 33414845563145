import { LocalHospital } from '@material-ui/icons'
import { callRacemanagerApi } from 'app/config/firebase'
import { IconButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { EditEmergency } from 'app/pages/emergency/edit-emergency'
import { ProtocolEmergencyObservationEndpoint } from 'shared/api/interfaces'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'

interface EmergencyButtonProps {
  admin: UserQuery
  user: UserQuery
}

export function EmergencyDetailsButton(props: EmergencyButtonProps) {
  const dialog = useDialog()
  return (
    <>
      <IconButtonWithConfirmation
        tooltip={t().emergencyDetails.title}
        title={t().emergencyDetails.seeEmergencyDetails}
        confirmation={t().emergencyDetails.dataProtectionHint}
        onConfirm={async () => {
          await callRacemanagerApi<ProtocolEmergencyObservationEndpoint>(
            'protocolEmergencyObservation',
            { affectedUid: props.user.uid }
          )
          dialog.open()
        }}
      >
        <LocalHospital />
      </IconButtonWithConfirmation>
      {dialog.isOpen && (
        <ConfirmDialog maxWidth="xl" fullWidth dialog={dialog} title={t().emergencyDetails.title}>
          <EditEmergency user={props.user} adminView />
        </ConfirmDialog>
      )}
    </>
  )
}
