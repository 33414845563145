import { Grid } from '@material-ui/core'
import { AccountBalance, Edit } from '@material-ui/icons'
import { addBusinessDays, parseISO, startOfDay } from 'date-fns'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { db } from 'app/db/frontend-db'
import { samFields } from 'app/forms/fields'
import { ButtonWithTooltip } from 'app/layout/button-with-tooltip'
import { IconButtonWithTooltip } from 'app/layout/icon-button-with-tooltip'
import { ConfirmDialog, useDialog } from 'app/layouts/confirm-dialog'
import { useAssociationContext } from 'app/themes/association-context'
import { useAdmin, useUserContext } from 'app/themes/user-context'
import { AssociationPayment } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { AssociationID } from 'shared/models/associations'
import { Dig } from 'shared/utils/tsc'
import { Loading } from 'utils/loading'
import { useSuccessSnackbar } from 'utils/snackbar'

interface EditAssociationPaymentButtonProps {
  associationPayment: AssociationPayment
  maxAmount: number
  buttonText: string
  disabled: boolean
}

export function EditAssociationPaymentButton(props: EditAssociationPaymentButtonProps) {
  const { maxAmount, buttonText, associationPayment, disabled } = props

  const userContext = useUserContext()
  const dialog = useDialog()
  const initialValues = {
    amount: associationPayment.amount,
    internalRemarks: associationPayment.internalRemarks,
    date: parseISO(associationPayment.date),
    association: associationPayment.association,
  }
  const showSuccessMessage = useSuccessSnackbar()

  return (
    <>
      <IconButtonWithTooltip disabled={disabled} tooltip={buttonText} onClick={dialog.open}>
        <Edit />
      </IconButtonWithTooltip>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema(maxAmount, userContext.normalAdmin)}
        validateOnMount
        onSubmit={async (values, { setSubmitting }) => {
          const common = {
            date: values.date.toISOString(),
            amount: values.amount,
            association: values.association,
            internalRemarks: values.internalRemarks,
          }
          await db.updateAssociationPayment(
            associationPayment.status === 'paid'
              ? { ...associationPayment, ...common, status: 'paid' }
              : { ...associationPayment, ...common, status: 'requested' }
          )
          showSuccessMessage(t().alerts.dataSaved)
          setSubmitting(false)
        }}
      >
        {(form) => {
          return (
            <ConfirmDialog
              maxWidth="xl"
              fullWidth
              title={buttonText}
              buttonText={form.isSubmitting ? t().buttons.saving : buttonText}
              dialog={dialog}
              onConfirm={() => form.submitForm()}
              disabled={form.isSubmitting || !form.isValid}
            >
              <Form>
                <AssociationPaymentFields />
              </Form>
              <Loading loading={form.isSubmitting} />
            </ConfirmDialog>
          )
        }}
      </Formik>
    </>
  )
}

interface AddAssociationPaymentButtonProps {
  amount: number
  maxAmount: number
  buttonText: string
  association: AssociationID | undefined
}

export function AddAssociationPaymentButton(props: AddAssociationPaymentButtonProps) {
  const { amount, maxAmount, buttonText, association } = props

  const admin = useAdmin()
  const userContext = useUserContext()
  const dialog = useDialog()
  const associationContext = useAssociationContext()
  const initialValues = {
    amount,
    internalRemarks: '',
    date: addBusinessDays(new Date(), 1),
    association: association || associationContext.association.id,
  }
  const showSuccessMessage = useSuccessSnackbar()

  return (
    <>
      <ButtonWithTooltip
        color="primary"
        variant="contained"
        startIcon={<AccountBalance />}
        tooltip={buttonText}
        onClick={dialog.open}
      >
        {buttonText}
      </ButtonWithTooltip>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema(maxAmount, userContext.normalAdmin)}
        validateOnMount
        onSubmit={async (values, { setSubmitting }) => {
          await db.pushAssociationPayment({
            id: '',
            type: 'associationPayment',
            date: values.date.toISOString(),
            amount: values.amount,
            requestedByUid: admin.uid,
            association: values.association,
            internalRemarks: values.internalRemarks || '',
            status: 'requested',
          })
          showSuccessMessage(t().alerts.dataSaved)
          setSubmitting(false)
        }}
      >
        {(form) => {
          return (
            <ConfirmDialog
              maxWidth="xl"
              fullWidth
              title={buttonText}
              buttonText={form.isSubmitting ? t().buttons.saving : buttonText}
              dialog={dialog}
              onConfirm={() => form.submitForm()}
              disabled={form.isSubmitting || !form.isValid}
            >
              <Form>
                <AssociationPaymentFields />
              </Form>
              <Loading loading={form.isSubmitting} />
            </ConfirmDialog>
          )
        }}
      </Formik>
    </>
  )
}

function AssociationPaymentFields() {
  const fields = samFields().associationPayment
  const userContext = useUserContext()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {fields.association.field({ disabled: !!userContext.associationAdmin })}
      </Grid>
      <Grid item xs={12}>
        {fields.amount.field()}
      </Grid>
      <Grid item xs={12}>
        {fields.date.field()}
      </Grid>
      <Grid item xs={12}>
        {fields.internalRemarks.field()}
      </Grid>
    </Grid>
  )
}

function schema(maxAmount: number, admin: boolean) {
  const validations = Object.fromEntries(
    Object.entries(samFields().associationPayment).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['associationPayment']>
  return Yup.object()
    .defined()
    .shape({
      association: validations.association(),
      internalRemarks: validations.internalRemarks(),
      amount: validations.amount().max(maxAmount),
      date: admin
        ? validations.date()
        : validations.date().min(startOfDay(addBusinessDays(new Date(), 1))),
    })
}

export interface AssociationPaymentFormData {
  date: Date
  amount: number
  internalRemarks: string
}
