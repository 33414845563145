import { Box } from '@material-ui/core'
import { useActiveSportEvent } from 'app/db/db-hooks/main-db-hooks'
import { useUserContext } from 'app/themes/user-context'
import { QRScanResult } from 'shared/api/interfaces'
import { routes } from 'shared/config/routes'
import { UserQuery } from 'shared/db/db'
import { RoutedButton } from 'utils/buttons/routed-button'

export function QRAdminButtons({ result }: { result: QRScanResult }) {
  const userContext = useUserContext()
  if (!userContext.adminOrAssociationAdmin || !userContext.uid) return null

  return <QRAdminButtonsInner result={result} admin={{ uid: userContext.uid }} />
}

export function QRAdminButtonsInner({ admin, result }: { result: QRScanResult; admin: UserQuery }) {
  const userContext = useUserContext()
  const { data: sportEvent } = useActiveSportEvent(admin)

  return (
    <Box>
      <RoutedButton to={routes.licenseDrafts.generateTo(result.uid)}>
        {routes.licenseDrafts.text()}
      </RoutedButton>
      <RoutedButton to={routes.approvedLicenses.generateTo(uidAndCategoryId(result))}>
        {routes.approvedLicenses.text()}
      </RoutedButton>
      <RoutedButton to={routes.assignLicense.generateTo(result.uid)}>
        {routes.assignLicense.text()}
      </RoutedButton>
      {sportEvent?.id && (
        <RoutedButton to={routes.inscriptions.generateTo(result.uid, sportEvent.id)}>
          {routes.inscriptions.text()}
        </RoutedButton>
      )}
      {userContext.admin && (
        <>
          <RoutedButton to={routes.payments.generateTo(result.uid)}>
            {routes.payments.text()}
          </RoutedButton>
          <RoutedButton to={routes.userEvents.generateTo(result.uid)}>
            {routes.userEvents.text()}
          </RoutedButton>
        </>
      )}
    </Box>
  )
}

function uidAndCategoryId(result: QRScanResult) {
  return [result.uid, result.categoryId].join(' ')
}
