import { Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Component, ErrorInfo } from 'react'
import { captureException } from 'app/config/sentry'
import { db } from 'app/db/frontend-db'
import { currentUser } from 'app/users/auth'
import { UserQuery } from 'shared/db/db'
import { serializeError } from 'shared/utils/errors'
import { isLocalDevelopment } from 'utils/debug'
import { Disp } from 'utils/react'

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

export class ErrorBoundary extends Component<{ name?: string; user?: UserQuery }, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false, error: null, errorInfo: null }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo })
    logFrontendErrorFor(this.props.user, error, errorInfo)
    if (error?.name === 'ChunkLoadError') window.location.reload()
  }

  render() {
    return this.state.hasError ? (
      <ErrorDisplay
        title={`Es ist ein unerwarteter Fehler aufgetreten. Bitte versuche, die Seite neu zu laden`}
        error={this.state.error}
        errorInfo={this.state.errorInfo}
        onIgnore={() => this.setState({ hasError: false, error: null, errorInfo: null })}
      />
    ) : (
      this.props.children
    )
  }
}

export async function catchAndLog(
  setError: Disp<Error | null>,
  fn: () => Promise<void> | Promise<void[]>,
  setLoading: Disp<boolean> | null = null
) {
  try {
    setLoading?.(true)
    await fn()
  } catch (error: any) {
    setError(error)
    logFrontendErrorFor(currentUser(), error)
  }
  setLoading?.(false)
}

export function logFrontendError(error: Error) {
  logFrontendErrorFor(currentUser(), error)
}

function logFrontendErrorFor(
  user: UserQuery | undefined,
  error: Error,
  errorInfo: ErrorInfo | undefined = undefined
) {
  if (isLocalDevelopment()) logErrorToConsole(error, errorInfo)
  else {
    captureException(error, { extra: { componentStack: errorInfo?.componentStack } })
    logErrorToConsole(error, errorInfo)
    db.storeFrontendError(user, errorWithMetadata(error, errorInfo)).catch(console.error)
  }
}

interface ErrorDisplayProps {
  title: string
  error: Error | null
  errorInfo: ErrorInfo | null
  onIgnore?: () => void
}

function logErrorToConsole(error: Error, errorInfo: ErrorInfo | undefined) {
  console.error(errorWithMetadata(error, errorInfo))
}

function errorWithMetadata(error: Error, errorInfo: ErrorInfo | undefined) {
  return {
    error: serializeError(error),
    stack: errorInfo?.componentStack,
    userAgent: window.navigator.userAgent || undefined,
    platform: window.navigator.platform || undefined,
    vendor: window.navigator.vendor || undefined,
    appVersion: window.navigator.appVersion || undefined,
    appCodeName: window.navigator.appCodeName || undefined,
    language: window.navigator.language || undefined,
    product: window.navigator.product || undefined,
    productSub: window.navigator.productSub || undefined,
  }
}

function ErrorDisplay(props: ErrorDisplayProps) {
  const onIgnore = props.onIgnore
  return (
    <Alert
      severity="error"
      action={
        <>
          <Button color="inherit" size="small" onClick={() => window.location.reload()}>
            Neu laden
          </Button>
          {onIgnore && (
            <Button color="inherit" size="small" onClick={() => onIgnore()}>
              Fehler ignorieren
            </Button>
          )}
        </>
      }
    >
      <AlertTitle>{props.title}</AlertTitle>

      {<pre>{errorMessage(props.error)}</pre>}
      {props.error?.stack && <pre>{props.error.stack}</pre>}
      {props.errorInfo?.componentStack && (
        <pre>
          Comp stack:
          {props.errorInfo?.componentStack}
        </pre>
      )}
    </Alert>
  )
}

function errorMessage(error: Error | null) {
  return error ? `Fehlername: ${error.name}\nNachricht: ${error.message}` : 'Unbekannter Fehler'
}
