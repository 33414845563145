// if maxSize > 2897 it doesn't work on the iPhone :(
const maxSizeForCropping = 2500

export async function cropImageToSize(
  src: string,
  originalPixelCrop: CropOptions | null,
  rotation: number,
  target: Dimensions
) {
  if (!originalPixelCrop) throw new Error('Invalid pixelCrop')
  const { pixelCrop, scaledSrc } = await scaleIfNeeded(src, originalPixelCrop)
  const blob = await cropImage(scaledSrc, pixelCrop, rotation)
  return await scaleBlob(blob, target)
}

async function scaleIfNeeded(src: string, originalPixelCrop: CropOptions) {
  const image = await createImage(src)
  const maxSize = Math.max(image.width, image.height)
  if (maxSize <= maxSizeForCropping) return { pixelCrop: originalPixelCrop, scaledSrc: src }
  const ratio = maxSizeForCropping / maxSize

  return {
    pixelCrop: {
      width: originalPixelCrop.width * ratio,
      height: originalPixelCrop.height * ratio,
      x: originalPixelCrop.x * ratio,
      y: originalPixelCrop.y * ratio,
    },
    scaledSrc: URL.createObjectURL(
      await scaleBlob(await (await fetch(src)).blob(), {
        width: image.width * ratio,
        height: image.height * ratio,
      })
    ),
  }
}

async function scaleBlob(blob: Blob, target: Dimensions) {
  const image = await createImage(URL.createObjectURL(blob))
  const canvas = document.createElement('canvas')
  const ctx = createCanvasContext(canvas, target)
  ctx.drawImage(image, 0, 0, target.width, target.height)
  const data = await getImageData(ctx, target)
  ctx.putImageData(data, 0, 0)
  return toBlob(canvas)
}

async function cropImage(src: string, pixelCrop: CropOptions | null, rotation: number) {
  if (!pixelCrop) throw new Error('Invalid pixelCrop')
  const image = await createImage(src)
  const canvas = document.createElement('canvas')
  const { ctx, canvasSize } = createCanvasContextForRotation(canvas, image)
  rotateImageOnContext(ctx, canvasSize, rotation)
  const data = await drawRotatedImage(ctx, image, canvasSize)
  cropCanvas(canvas, pixelCrop)
  putImageData(ctx, data, canvasSize, image, pixelCrop)
  return toBlob(canvas)
}

function createCanvasContextForRotation(canvas: HTMLCanvasElement, image: HTMLImageElement) {
  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  const maxSize = Math.max(image.width, image.height)
  const canvasSize = 2 * ((maxSize / 2) * Math.sqrt(2))
  return { ctx: createCanvasContext(canvas, { width: canvasSize, height: canvasSize }), canvasSize }
}

function createCanvasContext(canvas: HTMLCanvasElement, { width, height }: Dimensions) {
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('Unable to get canvas context')
  canvas.width = width
  canvas.height = height
  return ctx
}

function rotateImageOnContext(ctx: CanvasRenderingContext2D, canvasSize: number, rotation: number) {
  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(canvasSize / 2, canvasSize / 2)
  ctx.rotate(radians(rotation))
  ctx.translate(-canvasSize / 2, -canvasSize / 2)
}

function drawRotatedImage(ctx: CanvasRenderingContext2D, image: HTMLImageElement, canvasSize: number) {
  // draw rotated image and store data.
  ctx.drawImage(image, canvasSize / 2 - image.width * 0.5, canvasSize / 2 - image.height * 0.5)
  return getImageData(ctx, { width: canvasSize, height: canvasSize })
}

async function getImageData(ctx: CanvasRenderingContext2D, dimensions: Dimensions) {
  ctx.getImageData(0, 0, dimensions.width, dimensions.height)
  await sleep(100)
  return ctx.getImageData(0, 0, dimensions.width, dimensions.height)
}

function cropCanvas(canvas: HTMLCanvasElement, pixelCrop: CropOptions) {
  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
}

function putImageData(
  ctx: CanvasRenderingContext2D,
  data: ImageData,
  canvasSize: number,
  image: HTMLImageElement,
  pixelCrop: CropOptions
) {
  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - canvasSize / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - canvasSize / 2 + image.height * 0.5 - pixelCrop.y)
  )
}

function toBlob(canvas: HTMLCanvasElement) {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((file) => (file ? resolve(file) : reject('Invalid blob')), 'image/jpeg')
  })
}

function radians(degrees: number) {
  return (degrees * Math.PI) / 180
}

export async function rotateImage(imageSrc: string, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) throw new Error('Unable to get canvas context')

  const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
  if (orientationChanged) {
    canvas.width = image.height
    canvas.height = image.width
  } else {
    canvas.width = image.width
    canvas.height = image.height
  }

  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.rotate((rotation * Math.PI) / 180)
  ctx.drawImage(image, -image.width / 2, -image.height / 2)

  return new Promise<string>((resolve, reject) => {
    canvas.toBlob((file) => {
      if (!file) reject(new Error('Invalid blob'))
      else resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
}

function createImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.src = url
  })
}

function sleep(ms: number) {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms))
}

export interface CropOptions {
  width: number
  height: number
  x: number
  y: number
}
export interface Dimensions {
  width: number
  height: number
}
