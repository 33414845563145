import { mapTransactionToView } from 'app/pages/billing/map-transactions'
import { UserState } from 'app/themes/user-context'
import { categoryCommonName, categoryTypeName } from 'shared/data/categories-service'
import { FilteredBookingRelevantTransaction, UserQuery } from 'shared/db/db'
import { SportEvent } from 'shared/sport-events/sport-events'
import { sportEventDescriptionOrEmpty } from 'shared/sport-events/sport-events-service'
import { toChfOrDash } from 'shared/utils/number'

export function refineTransactions(
  data: FilteredBookingRelevantTransaction[],
  admin: UserQuery,
  userContext: UserState,
  sportEvents: SportEvent[]
) {
  return data.map((row) =>
    refineTransaction(
      row,
      data.map((row) => row.transaction),
      admin,
      userContext,
      sportEvents
    )
  )
}

export function refineTransaction(
  row: FilteredBookingRelevantTransaction,
  transactions: FilteredBookingRelevantTransaction['transaction'][],
  admin: UserQuery,
  userContext: UserState,
  sportEvents: SportEvent[]
) {
  const { transaction, uidName, byUidName } = row
  const uid = transaction.type === 'associationPayment' ? '-' : transaction.uid
  const byUid =
    transaction.type === 'associationPayment' || transaction.type === 'payment' ? '-' : transaction.byUid

  const mapped = mapTransactionToView({
    user: { uid },
    admin,
    userContext,
    setError: (error) => console.error(error),
    transaction,
    transactions,
  })

  return {
    ...mapped,
    type: transaction.type,
    uid,
    amountFormatted: toChfOrDash(mapped.amount),
    amountOpenFormatted: toChfOrDash(mapped.amountOpen),
    amountNetFormatted: toChfOrDash(mapped.amount - mapped.amountOpen),
    categoryTypeName: categoryTypeName(mapped.category),
    categoryName: mapped.category
      ? categoryCommonName(mapped.category)
      : mapped.categoryCommonName || '',
    sportEventName: sportEventDescriptionOrEmpty(
      sportEvents.find((event) => event.id === mapped.sportEventId)
    ),
    uidName,
    byUidName,
    byUid,
  }
}

export type RefinedTransaction = ReturnType<typeof refineTransactions>
