import { useMediaQuery, useTheme } from '@material-ui/core'

export function useIsSmallerThanXs() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('xs'))
}

export function useIsSmallerThanSm() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('sm'))
}

export function useIsSmallerThanMd() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('md'))
}

export function useIsSmallerThanLg() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down('lg'))
}
