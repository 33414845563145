import DateFnsUtils from '@date-io/date-fns'
import format from 'date-fns/format'
import localeDE from 'date-fns/locale/de'
import { setLocale } from 'yup'
import { loadDefaultLocale } from 'shared/i18n/current'
import { I18nLocales } from 'shared/i18n/i18n-types'
import { yupLocales } from 'shared/i18n/i18n-yup/yup'
import { setDateLocale } from 'shared/utils/date'

export function initI18n() {
  const locale = loadDefaultLocale()
  initYupLocale(locale)
}

function initYupLocale(locale: I18nLocales) {
  setLocale(yupLocales[locale])
  setDateLocale(locale)
}

class DateFnsUtilsDE extends DateFnsUtils {
  dateFormat = 'dd.MM.yyyy'
  dateTime24hFormat = 'dd.MM.yyyy HH:mm'

  getDatePickerHeaderText(date: Date) {
    return format(date, 'dd. MMMM', { locale: this.locale })
  }
}

export function currentDateFnsUtils() {
  return DateFnsUtilsDE
}

export function currentDateFnsLocale() {
  return localeDE
}
