import { sortBy } from 'lodash'
import { MaybeFBError } from 'app/db/db-hooks/db-hook-helpers'
import { tableHeaders } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { accountingGroupName } from 'app/pages/admin/financials/accounting-group-name'
import { accountingStatement } from 'app/pages/admin/financials/accounting-statement'
import { BookingRelevantTransaction } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { toChf } from 'shared/utils/number'

interface TransactionsForAccountingProps {
  loading: boolean
  error: MaybeFBError
  transactions: BookingRelevantTransaction[]
}

export function TransactionsForAccounting(props: TransactionsForAccountingProps) {
  const { loading, error, transactions } = props
  const loadingOrError = loading || error

  const groups = accountingStatement(transactions)
  const sorted = sortBy(groups, (group) => accountingGroupName(group))

  return (
    <TableBox
      title={t().financials.positionsForBilling}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: tableHeaders([t().position, { value: t().amountTableCHF, align: 'right' }]),
          contents: sorted.map((row) => [accountingGroupName(row), toChf(row.value)]),
          ids: sorted.map((row) => row.id),
          rawData: sorted.map((row) =>
            JSON.stringify({ id: row.id, value: row.value, name: accountingGroupName(row) })
          ),
        }
      }
    />
  )
}
