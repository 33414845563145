import { MaybeFBError } from 'app/db/db-hooks/db-hook-helpers'
import { tableHeaders } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FilteredBookingRelevantTransaction, isNotPayment } from 'shared/db/db'
import { totalOpenRemainingBalance, transactionsTotal } from 'shared/db/transactions-service'
import { t } from 'shared/i18n/current'
import { toChf } from 'shared/utils/number'
import { TableData } from 'shared/utils/table-data'

interface AssociationBalanceProps {
  loading: boolean
  error: MaybeFBError
  data: FilteredBookingRelevantTransaction[]
  title: string
}

export function AssociationBalance(props: AssociationBalanceProps) {
  const { loading, error, data, title } = props
  const loadingOrError = loading || error

  return (
    <TableBox
      title={title}
      loading={loading}
      error={error}
      data={!loadingOrError && tableContents(data)}
    />
  )
}

function tableContents(filteredData: FilteredBookingRelevantTransaction[]): TableData {
  const { paid, open, total } = associationAccountBalance(filteredData)
  return {
    headers: tableHeaders([t().position, { value: t().amountTableCHF, align: 'right' }]),
    contents: [
      [t().financials.totalCreditOrganization, toChf(paid)],
      [t().financials.totalOpenPayments, toChf(open)],
    ],
    rawData: [total, open, paid],
    ids: ['paid', 'open'],
  }
}

export function associationAccountBalance(filteredData: FilteredBookingRelevantTransaction[]) {
  const data = filteredData.filter((row) => row.transaction.type !== 'payment')
  const total = transactionsTotal(data.map((row) => row.transaction))
  const open = totalOpenRemainingBalance(data.map((row) => row.transaction).filter(isNotPayment))
  const paid = total - open
  return { paid, open, total }
}
