import { Fragment } from 'react'

export function Multiline({ children }: { children: string }) {
  return (
    <>
      {children.split('\n').map((value, index) => (
        <Fragment key={index}>
          {value}
          <br />
        </Fragment>
      ))}
    </>
  )
}
