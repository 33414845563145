import { createStyles, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { samFields } from 'app/forms/fields'
import { AccordionNavigationButtons } from 'app/license/accordion-navigation-buttons'
import { routes } from 'shared/config/routes'
import { nextLicenseYear, oldLicenseYear } from 'shared/data/license-config'
import { Year } from 'shared/data/license-year'
import { ApprovedLicense } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { SelectedCategory } from 'shared/models/selected-category'
import { Dig } from 'shared/utils/tsc'
import { RoutedButton } from 'utils/buttons/routed-button'

interface CategoriesProps {
  approvedLicenses: ApprovedLicense[]
  year: Year
  initialValues: SelectedCategory[]
  onSubmit: (data: SelectedCategory[]) => Promise<void>
  onBack: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({ gridParent: { '& > *': { margin: theme.spacing(1, 0, 0, 0) } } })
)

export function CategoriesForm(props: CategoriesProps) {
  const classes = useStyles()
  const { initialValues, onBack, onSubmit, approvedLicenses, year } = props

  const otherYear = year === nextLicenseYear ? oldLicenseYear : nextLicenseYear
  return (
    <>
      <Formik
        initialValues={{ categories: initialValues }}
        enableReinitialize={true}
        validationSchema={schema()}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values.categories)
          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={1} className={classes.gridParent}>
              {year === oldLicenseYear && new Date().getFullYear() <= nextLicenseYear && (
                <Grid item xs={12}>
                  <Alert severity="warning" variant="outlined">
                    <AlertTitle>
                      {t().licenseForm.title} {year}
                    </AlertTitle>
                    <Typography>{t().licenseForm.steps.summary.alertTitleLicenseYear(year)}</Typography>
                    <RoutedButton variant="outlined" to={routes.newLicense.generateTo(otherYear)}>
                      {t().licensesBoxes.requestLicenseOfDifferentYear(otherYear)}
                    </RoutedButton>
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                {samFields().categories.categories.field(approvedLicenses, year)}
              </Grid>

              {isSubmitting && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <AccordionNavigationButtons
                disabled={
                  new Date().getFullYear() !== oldLicenseYear &&
                  new Date().getFullYear() !== nextLicenseYear
                }
                isSubmitting={isSubmitting}
                onBack={onBack}
                submitForm={submitForm}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

function schema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().categories).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['categories']>
  return Yup.object().defined().shape(validations)
}
