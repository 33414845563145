import { generate } from 'generate-password-ts'

export function generateCode() {
  return generate({
    uppercase: true,
    lowercase: true,
    numbers: true,
    symbols: false,
    excludeSimilarCharacters: false,
    length: 12,
  })
}
