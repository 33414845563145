import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import type { FieldProps } from 'formik'
import { useCallback } from 'react'
import { useIsSmallerThanXs } from 'app/layout/use-small-screen'

export interface CheckboxProps extends FieldProps {
  options: { id: string; name: string; hint: string }[]
  label: string
  helperText?: string
}

export function CheckboxGroup(props: CheckboxProps) {
  const xs = useIsSmallerThanXs()
  const {
    form: { setFieldValue },
    field: { name, value },
  } = props
  const onChange = useCallback(
    (fieldId, checked) => {
      const valueSet = new Set(value)

      if (checked) valueSet.add(fieldId)
      else valueSet.delete(fieldId)

      setFieldValue(name, [...valueSet])
    },
    [setFieldValue, name, value]
  )
  const errorText = props.form.errors[props.field.name]
  const error = Boolean(errorText)

  return (
    <FormControl error={error} component="fieldset" variant="outlined">
      <Box mb={1}>
        <FormLabel component="legend">{props.label}</FormLabel>
      </Box>
      <FormGroup>
        {props.options.map(({ id, name, hint }) => (
          <Box key={id} mt={xs ? 1 : 0}>
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={value.includes(id)}
                  onChange={(_event, value) => onChange(id, value)}
                  name={id}
                />
              }
              label={name}
            />
            {hint && value.includes(id) && (
              <Box mb={2}>
                <FormHelperText>{hint}</FormHelperText>
              </Box>
            )}
          </Box>
        ))}
      </FormGroup>

      {props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
      {error && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}
