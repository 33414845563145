import { uniq } from 'lodash'
import { constCategories } from 'shared/data/categories'
import { constCategories2021 } from 'shared/data/categories-2021'
import { constCategories2022 } from 'shared/data/categories-2022'
import { constCategories2023 } from 'shared/data/categories-2023'
import { constCategories2024 } from 'shared/data/categories-2024'
import {
  map2021FormatToCurrentFormat,
  map2022FormatToCurrentFormat,
  map2023FormatToCurrentFormat,
  map2024FormatToCurrentFormat,
} from 'shared/data/legacy-conversions'
import { nextLicenseYear } from 'shared/data/license-config'
import { Year } from 'shared/data/license-year'
import type { DayCategory } from 'shared/db/day-category'
import type { ApprovedLicense } from 'shared/db/db'
import { LicenseType } from 'shared/db/license-type'
import { currentLocale, t } from 'shared/i18n/current'
import { translateCategoryType } from 'shared/i18n/i18n-helpers'
import { I18nLocales } from 'shared/i18n/i18n-types'
import {
  AssociationID,
  associationNameWithDefault,
  todoMigrateAssociation,
} from 'shared/models/associations'
import {
  AssociationCategory,
  associationSpecificDetails,
  Category,
  CategoryId,
  CategoryOfAssociation,
  MergedUntranslatedCategory,
} from 'shared/models/category'
import { CategoryDetails } from 'shared/models/category-details'
import { supportsInsuranceOptions } from 'shared/models/insurance-options'
import { truthy } from 'shared/utils/array'
import {
  memoizeBasedOnLocale,
  memoizeBasedOnLocaleAndAssocationWithParams,
  memoizeBasedOnLocaleWithParam,
} from 'shared/utils/memoize'

const rawCategories: readonly MergedUntranslatedCategory[] = [
  ...map2021FormatToCurrentFormat(constCategories2021),
  ...map2022FormatToCurrentFormat(constCategories2022),
  ...map2023FormatToCurrentFormat(constCategories2023),
  ...map2024FormatToCurrentFormat(constCategories2024),
  // TODO: later: remove any?
  ...(constCategories as any),
]

export function categoryAssociations(categoryId: CategoryId) {
  return categoryByIdRequired(categoryId).associations
}

export function selectedCategories(ids: CategoryId[] | undefined) {
  return ids ? categories(currentLocale()).filter((category) => ids.includes(category.id)) : []
}

export function categoryByIdRequired(id: CategoryId) {
  const category = categoryById(id)
  if (!category) throw new Error(`Category ${id} not found`)
  return category
}

export function fullCategoryCommonName(category: Category | undefined | null): string {
  return category ? [associationsNames(category), categoryCommonName(category)].join(' - ') : '-'
}

export function commonCategoryName(category: DayCategory | Category) {
  return category.categoryType === 'licenseCategory'
    ? categoryCommonNameEndUser(category)
    : category.name
}

export function categoryCommonNameEndUserWithAssociation(category: Category | DayCategory) {
  return [associationsNames(category), categoryCommonNameEndUser(category)].filter(truthy).join(' - ')
}

export function categoryStartListNameEndUserWithAssociation(category: Category | DayCategory) {
  return [
    associationsNames(category),
    category.categoryType === 'licenseCategory' ? translateCategoryType(category.type) : '',
    category.startListName,
  ]
    .filter(truthy)
    .join(' - ')
}

// TODO: later: fix this
export function categoryCommonNameEndUser(category: Category | DayCategory | undefined | null): string {
  return categoryCommonName(category)
}

// TODO: later: maybe fix this?
export function categoryCommonName(category: Category | DayCategory | undefined | null): string {
  return category?.categoryType === 'dayCategory' ? category.name : category?.commonName || ''
}

export function categoryTypeName(category: Category | DayCategory | undefined | null): string {
  return category?.categoryType === 'dayCategory' ? 'Tageskategorie' : category?.typeName || ''
}

// TODO: later: maybe fix this?
export function categoryHack(category: Category): CategoryOfAssociation
export function categoryHack(category: undefined | null): undefined
export function categoryHack(category: Category | undefined | null): CategoryOfAssociation | undefined
export function categoryHack(category: Category | undefined | null): CategoryOfAssociation | undefined {
  if (!category) return undefined
  const association = category.associations[0]
  const combined = categoryOfAssociation(category.id, association)
  if (!combined) throw new Error(`Category ${category.id} not found`)
  return combined
}

export function categoryOfAssociationByCategoryDetails(license: {
  categoryId: CategoryId
  licenseAssociation: AssociationID
}) {
  return categoryOfAssociation(license.categoryId, license.licenseAssociation)
}

export function categoryOfAssociationByLicense(license: {
  categoryId: CategoryId
  licenseAssociation: AssociationID
}) {
  return categoryOfAssociationRequired(license.categoryId, license.licenseAssociation)
}

export function categoryOfAssociationByLicenseCategory(license: {
  category: CategoryId
  association: AssociationID
}) {
  return categoryOfAssociationRequired(license.category, license.association)
}

export function offersMultipleLicenseTypes(category: AssociationCategory) {
  return category.offersNationalLicense && category.offersInternationalLicense
}

export function categoryOfAssociationRequired(
  id: CategoryId,
  association: AssociationID
): CategoryOfAssociation {
  const category = categoryOfAssociation(id, todoMigrateAssociation(association))
  if (!category) throw new Error(`Category ${id}/${association} not found`)
  return category
}

export function categoryOfAssociation(
  id: CategoryId,
  association: AssociationID
): CategoryOfAssociation | undefined {
  const category = categoryById(id)
  if (!category || !category.associations.includes(association)) return undefined
  const details = associationSpecificDetails(category, association)
  return { ...category, ...details }
}

export function categoryByIdFromStringRequired(id: string): Category {
  const category = categoryByIdFromString(id)
  if (!category) throw new Error(`Category ${id} not found`)
  return category
}

export function categoryByIdFromString(id: string): Category | undefined {
  return isCategoryId(id) ? categoryByIdRequired(id) : undefined
}

export function categoryById(id: CategoryId | undefined): Category | undefined {
  if (!id) return undefined

  return categoriesById().get(migrateCategoryId(id))
}

export function categoryByShortId(shortId: number) {
  return categoriesByShortId().get(shortId)
}

export function isCategoryId(id: unknown): id is CategoryId {
  return typeof id === 'string' && categoriesById().has(migrateCategoryId(id as CategoryId))
}

export function isCategory(category: any): category is Category {
  return categoriesById().has(category)
}

function migrateCategoryId(id: CategoryId): CategoryId {
  return id?.startsWith('fms-2022-all-')
    ? (id.replace('fms-2022-all-', 'fms-2022-other-fms-') as CategoryId)
    : id
}

const categoriesById = memoizeBasedOnLocale(
  () => new Map(categories(currentLocale()).map((category) => [category.id, category] as const))
)

const categoriesByShortId = memoizeBasedOnLocale(
  () => new Map(categories(currentLocale()).map((category) => [category.shortId, category] as const))
)

export const categoriesByAssociation = memoizeBasedOnLocaleAndAssocationWithParams(
  (locale, association) =>
    categories(locale).filter((category) => !association || category.associations.includes(association))
)

export function categoriesByYear(locale: I18nLocales, year: Year) {
  return categories(locale).filter((category) => category.year === year)
}

export const categories = memoizeBasedOnLocaleWithParam((locale) =>
  rawCategories.map((category) => toCurrentLanguage(category, locale))
)

function toCurrentLanguage(category: MergedUntranslatedCategory, locale: I18nLocales): Category {
  const typeName = translateCategoryType(category.type)
  const commonName = [
    typeName,
    translateString(locale, category.commonNameDe, category.commonNameFr, category.commonNameEn),
  ].join(' - ')
  return {
    ...category,
    commonName,
    typeName,
    associationSpecificDetails: category.associationSpecificDetails.map((details) => ({
      ...details,
      name: [typeName, translateString(locale, details.nameDe, details.nameFr, details.nameEn)].join(
        ' - '
      ),
      shortName: translateString(locale, details.shortNameDe, details.shortNameFr, details.shortNameEn),
      priceHint: translateString(locale, details.priceHintDe, details.priceHintFr, details.priceHintEn),
      hint: translateString(locale, details.hintDe, details.hintFr, details.hintEn),
      needsComment: translateString(
        locale,
        details.needsCommentDe,
        details.needsCommentFr,
        details.needsCommentEn
      ),
    })),
  }
}

function translateString(locale: I18nLocales, de: string, fr: string, en: string) {
  return locale === 'de' ? de || fr || en : locale === 'fr' ? fr || de || en : en || de || fr
}

export function needsTransponder(categories: Category[]) {
  return categories.some(({ transponders }) => transponders.length > 0)
}

export function neededTransponders(categories: Category[]) {
  return uniq(categories.flatMap(({ transponders }) => transponders))
}

export function isFunLicense(categoryId: string) {
  return categoryId.includes('funlizenz')
}

export function includesInsuranceCover(category: AssociationCategory | CategoryOfAssociation) {
  return category.priceInsuranceBookkeeping > 0
}

export function requiresInsuranceOptionByCategory(
  association: AssociationID,
  year: Year,
  category: AssociationCategory | CategoryOfAssociation
) {
  return supportsInsuranceOptions(association, year) && includesInsuranceCover(category)
}

export function requiresInsuranceOptionByCategoryId(association: AssociationID, categoryId: CategoryId) {
  const category = categoryOfAssociation(categoryId, association)
  if (!category) return false
  return requiresInsuranceOptionByCategory(category.association, category.year, category)
}

export function fixedLicenseType(categoryDetails: ApprovedLicense): ApprovedLicense
export function fixedLicenseType(categoryDetails: CategoryDetails): CategoryDetails
export function fixedLicenseType(categoryDetails: undefined): undefined
export function fixedLicenseType(categoryDetails: ShortApprovedLicense): ShortApprovedLicense
export function fixedLicenseType(
  categoryDetails: CategoryDetails | undefined
): CategoryDetails | undefined
export function fixedLicenseType(
  categoryDetailsOrig: CategoryDetails | ApprovedLicense | ShortApprovedLicense | undefined
): CategoryDetails | ApprovedLicense | ShortApprovedLicense | undefined {
  if (!categoryDetailsOrig) return undefined

  const categoryDetails = {
    ...categoryDetailsOrig,
    licenseAssociation: todoMigrateAssociation(categoryDetailsOrig.licenseAssociation),
  }
  const { categoryId, licenseAssociation } = categoryDetails
  const associationCategory = categoryOfAssociationRequired(categoryId, licenseAssociation)
  if (!validLicenseType(associationCategory, categoryDetails.licenseType))
    return { ...categoryDetails, licenseType: firstValidLicenseType(associationCategory) }

  return categoryDetails
}

export function fixedCategoryAssociation(
  categoryDetails: CategoryDetails,
  association: AssociationID | false | undefined
): CategoryDetails {
  const { categoryId, licenseAssociation } = categoryDetails
  const category = categoryByIdRequired(categoryId)
  return association && category.associations.includes(association) && licenseAssociation === association
    ? categoryDetails
    : {
        ...categoryDetails,
        licenseAssociation: category.associations.includes(licenseAssociation)
          ? licenseAssociation
          : association && category.associations.includes(association)
          ? association
          : category.associations[0],
      }
}

export function validLicenseType(
  associationCategory: CategoryOfAssociation | undefined,
  licenseType: LicenseType
) {
  if (!associationCategory) return true
  return (
    (licenseType === 'national' && associationCategory.offersNationalLicense) ||
    (licenseType === 'international' && associationCategory.offersInternationalLicense)
  )
}

export function firstValidLicenseType(associationCategory: CategoryOfAssociation | undefined) {
  const licenseType = !associationCategory
    ? 'national'
    : associationCategory.offersInternationalLicense
    ? 'international'
    : associationCategory.offersNationalLicense
    ? 'national'
    : undefined
  if (!licenseType)
    throw new Error(`No valid license type found for category ${associationCategory?.id}`)
  return licenseType
}

export function selectableLicenseTypesOfCategory(associationCategory: AssociationCategory | undefined) {
  return licenseTypesOfCategory(associationCategory).map((licenseType) => ({
    id: licenseType,
    name: t().licenseTypes[licenseType],
  }))
}

export function licenseTypesOfCategory(
  associationCategory: AssociationCategory | undefined
): LicenseType[] {
  if (!associationCategory) return ['national']

  return [
    associationCategory.offersNationalLicense ? ('national' as const) : undefined,
    associationCategory.offersInternationalLicense ? ('international' as const) : undefined,
  ].filter(truthy)
}

export function fullCategoryShortName(category: CategoryOfAssociation) {
  return [
    associationNameWithDefault(category.association),
    '-',
    category.shortName,
    category.year === nextLicenseYear ? '' : `(${category.year})`,
  ]
    .filter(truthy)
    .join(' ')
}

export function fullCategoryName(category: CategoryOfAssociation) {
  return [
    associationNameWithDefault(category.association),
    '-',
    category.name,
    category.year === nextLicenseYear ? '' : `(${category.year})`,
  ]
    .filter(truthy)
    .join(' ')
}

export function associationsNames(category: Category | DayCategory) {
  return category.categoryType === 'dayCategory'
    ? ''
    : category.associations.map(associationNameWithDefault).join('/')
}

export interface ShortApprovedLicense {
  categoryId: CategoryId
  licenseAssociation: AssociationID
  licenseType: LicenseType
}
