import assertNever from 'assert-never'
import { endOfDay, isAfter, previousTuesday } from 'date-fns'
import {
  SubmitEnlistedDayInscriptionDayCategoryDraftQuery,
  SubmitEnlistedDayInscriptionYearCategoryDraftQuery,
  SubmitEnlistedLicenseInscriptionQuery,
  SubmitInscriptionQuery,
  SubmitUnlistedDayLicenseInscriptionQuery,
  SubmitUnlistedLicenseInscriptionQuery,
} from 'shared/api/interfaces'
import { storeNewBill } from 'shared/billing/billing-service'
import {
  forceUpdateInscriptionPaymentStatus,
  updateAllInscriptions,
  updateInscriptionPaymentStatus,
  updateTransactionAndLicenseStates,
  updateTransactionStates,
} from 'shared/billing/payment-processor'
import {
  categoryByIdRequired,
  categoryOfAssociationRequired,
  isCategoryId,
} from 'shared/data/categories-service'
import { Year } from 'shared/data/license-year'
import { DayCategory } from 'shared/db/day-category'
import { DB, UserQuery } from 'shared/db/db'
import { UserId } from 'shared/db/user-id'
import { t } from 'shared/i18n/current'
import {
  handleTwoDayDiscount,
  inscriptionDescription,
} from 'shared/inscription/inscription-discount-service'
import { updateDayInscriptionPublicStatistics } from 'shared/inscription/inscription-payment-status'
import { AssociationID } from 'shared/models/associations'
import { Bike } from 'shared/models/bike'
import {
  AssociationCategory,
  associationSpecificDetails,
  Category,
  CategoryOfAssociation,
} from 'shared/models/category'
import {
  EnlistedDayInscriptionDayCategory,
  EnlistedDayInscriptionDayCategoryDraft,
  EnlistedDayInscriptionYearCategory,
  EnlistedDayInscriptionYearCategoryDraft,
  EnlistedLicenseInscription,
  Inscription,
  SportEvent,
  UnlistedDayLicenseInscription,
  UnlistedLicenseInscription,
} from 'shared/sport-events/sport-events'
import {
  ensureSportEventIsNotFinalized,
  isCancelledAndWithinUnsubscriptionTimeSportEvent,
  isLessThanSixMonthsAfterSportEvent,
  isPastSportEvent,
} from 'shared/sport-events/sport-events-service'
import { truthy } from 'shared/utils/array'
import { parseISO } from 'shared/utils/date'
import { nullAsUndefined } from 'shared/utils/object'

export async function processSubmitSportsEventInscription(
  db: DB,
  query: SubmitInscriptionQuery,
  context: InscriptionContext
) {
  const sportEvent = await db.loadSportEvent(query.sportEventId)
  if (!sportEvent) throw new Error(`Sport event ${query} not found`)

  checkPermissions(sportEvent, context, query)

  const inscription = await generateInscription(db, query, context, sportEvent)
  if (!inscription) throw new Error(`Invalid inscription ${JSON.stringify(query)}`)

  if (context.association && !(await allowedByAssociationAdmin(context, sportEvent, inscription)))
    throw new Error("Association admin doesn't have permission to edit this event or category")

  await submitSportEventInscription(db, sportEvent, inscription)
  await handleDonation(db, query, sportEvent, inscription)
}

async function allowedByAssociationAdmin(
  _context: InscriptionContext,
  _sportEvent: SportEvent,
  _inscription: Inscription
) {
  // TODO: later: inscription admin: implement this
  await Promise.resolve()
  return true
}

async function generateInscription(
  db: DB,
  query: SubmitInscriptionQuery,
  context: InscriptionContext,
  sportEvent: SportEvent
): Promise<Inscription> {
  if (query.type === 'unlistedLicenseInscription')
    return await generateUnlistedLicenseInscription(db, query, context)

  if (query.type === 'unlistedDayLicenseInscription')
    return await generateUnlistedDayLicenseInscription(db, query, context)

  if (query.type === 'enlistedLicenseInscription')
    return await generateEnlistedLicenseInscription(db, query, context)

  if (query.type === 'enlistedDayInscriptionYearCategoryDraft')
    return await generateEnlistedDayInscriptionYearCategoryDraft(db, query, context)

  if (query.type === 'enlistedDayInscriptionDayCategoryDraft')
    return await generateEnlistedDayInscriptionDayCategoryDraft(db, query, context, sportEvent)

  assertNever(query)
}

async function generateUnlistedLicenseInscription(
  db: DB,
  query: SubmitUnlistedLicenseInscriptionQuery,
  context: InscriptionContext
): Promise<UnlistedLicenseInscription> {
  return {
    type: query.type,
    updatedAt: new Date().toISOString(),
    ...commonInscriptionProps(query, context),
    ...(await commonInscriptionWithLicenseProps(db, query)),
    paid: true,
  }
}

async function generateUnlistedDayLicenseInscription(
  db: DB,
  query: SubmitUnlistedDayLicenseInscriptionQuery,
  context: InscriptionContext
): Promise<UnlistedDayLicenseInscription> {
  const sportEvent = await db.loadSportEvent(query.sportEventId)
  if (!sportEvent) throw new Error(`Sport event ${query} not found`)

  return {
    type: query.type,
    updatedAt: new Date().toISOString(),
    ...commonInscriptionProps(query, context),
    category: query.categoryId,
    year: sportEvent.year,
    paid: true,
  }
}

async function generateEnlistedLicenseInscription(
  db: DB,
  query: SubmitEnlistedLicenseInscriptionQuery,
  context: InscriptionContext
): Promise<EnlistedLicenseInscription> {
  return {
    type: query.type,
    updatedAt: new Date().toISOString(),
    ...commonInscriptionProps(query, context),
    ...(await commonInscriptionWithLicenseProps(db, query)),
    ...commonEnlistedInscriptionProps(query),
    manuallyVerified: context.manuallyVerified,
  }
}

async function generateEnlistedDayInscriptionYearCategoryDraft(
  db: DB,
  query: SubmitEnlistedDayInscriptionYearCategoryDraftQuery,
  context: InscriptionContext
): Promise<EnlistedDayInscriptionYearCategoryDraft> {
  const category = categoryByIdRequired(query.categoryId)
  const bike = await firstBike(db, query.bikeIds, { uid: query.uid })

  return {
    type: query.type,
    ...commonInscriptionProps(query, context),
    ...commonEnlistedInscriptionProps(query),
    association: query.association,
    category: category.id,
    year: category.year,
    preferredNumber: query.preferredNumber,
    updatedAt: new Date().toISOString(),
    sidecarPartner: query.sidecarPartner,
    bikeMake: bike?.bikeMake || nullAsUndefined,
    teamName: bike?.teamName || nullAsUndefined,
  }
}

async function generateEnlistedDayInscriptionDayCategoryDraft(
  db: DB,
  query: SubmitEnlistedDayInscriptionDayCategoryDraftQuery,
  context: InscriptionContext,
  sportEvent: SportEvent
): Promise<EnlistedDayInscriptionDayCategoryDraft> {
  const category = await db.loadDayCategory({ sportEvent: sportEvent.id, category: query.categoryId })
  if (!category) throw new Error(`Category ${query.categoryId} not found`)

  const bike = await firstBike(db, query.bikeIds, { uid: query.uid })

  return {
    type: query.type,
    ...commonInscriptionProps(query, context),
    ...commonEnlistedInscriptionProps(query),
    association: sportEvent.association,
    category: query.categoryId,
    year: query.year,
    preferredNumber: query.preferredNumber,
    updatedAt: new Date().toISOString(),
    sidecarPartner: query.sidecarPartner,
    bikeMake: bike?.bikeMake || nullAsUndefined,
    teamName: bike?.teamName || nullAsUndefined,
  }
}

function firstBike(db: DB, bikeIds: string[], user: UserQuery): Promise<Bike | undefined> {
  if (!bikeIds?.[0]) return Promise.resolve(undefined)
  return db.loadBike(user, bikeIds[0])
}

function commonInscriptionProps(query: SubmitInscriptionQuery, context: InscriptionContext) {
  return {
    createdAt: new Date().toISOString(),
    uid: query.uid,
    byUid: context.byUid,
    sportEvent: query.sportEventId,
    date: query.date,
  }
}

async function commonInscriptionWithLicenseProps(
  db: DB,
  query: SubmitUnlistedLicenseInscriptionQuery | SubmitEnlistedLicenseInscriptionQuery
) {
  const category = categoryByIdRequired(query.categoryId)
  const license = await loadApprovedLicenseRequired(db, category, query.uid)

  return {
    category: category.id,
    year: category.year,
    association: license.licenseAssociation,
  }
}

function commonEnlistedInscriptionProps(
  query:
    | SubmitEnlistedLicenseInscriptionQuery
    | SubmitEnlistedDayInscriptionYearCategoryDraftQuery
    | SubmitEnlistedDayInscriptionDayCategoryDraftQuery
) {
  return {
    paid: false,
    bikeIds: query.bikeIds,
    remarksAdmin: '',
    remarksRider: query.remarksRider,
  }
}

async function loadApprovedLicenseRequired(db: DB, category: Category, uid: string) {
  const license = await db.loadApprovedLicense({ uid }, category.id, category.year)
  if (!license) throw new Error(`License for query ${JSON.stringify({ category, uid })} not found`)
  if (!license.licenseAssociation)
    throw new Error(`Invalid license association for license ${JSON.stringify(license)}`)
  return license
}

async function submitSportEventInscription(db: DB, sportEvent: SportEvent, inscription: Inscription) {
  // TODO: later: handle this
  // if (!isAdmin) {
  //   const existingInscription = await db.loadInscription(inscription)
  //   if (existingInscription?.type === 'enlistedLicenseInscription')
  //     throw new Error('Only admins can remove enlisted inscriptions.')
  // }

  const inDB = await db.loadInscription(inscription)
  if (inDB?.type === inscription.type) throw new Error(`Inscription ${inscription.type} already exists`)

  const uid = inscription.uid
  await handleInscriptionTypeSpecificParts({ inscription, db, sportEvent })
  await db.setInscription(inscription)
  await pushInscriptionUserEvent({ db, inscription, byUid: inscription.byUid, action: 'create' })
  await handleTwoDayDiscount({ inscription, db, sportEvent })
  await storeNewBill(db, { admin: { uid: inscription.byUid }, rider: { uid } }, undefined)
  await updateTransactionStates(db, uid)
  const updatedInscription = await db.loadInscription(inscription)
  if (updatedInscription) await forceUpdateInscriptionPaymentStatus(db, updatedInscription)
}

function checkPermissions(
  sportEvent: SportEvent,
  context: InscriptionContext,
  query: SubmitInscriptionQuery
) {
  const additionalInfo = { context, query }

  if (sportEvent.finalized)
    throw new Error(
      `Cannot edit inscriptions of finalized sport events ${JSON.stringify(additionalInfo)}`
    )

  if (!canSubmitInscription(sportEvent, context))
    throw new Error(`Cannot edit inscription for past sport event ${JSON.stringify(additionalInfo)}`)

  if (
    !unlistEnabled(sportEvent) &&
    (query.type === 'unlistedDayLicenseInscription' || query.type === 'unlistedLicenseInscription')
  ) {
    throw new Error('Unsubscription not allowed anymore')
  }
}

export function unlistEnabled(sportEvent: SportEvent) {
  return (
    !tooLateToUnsubscribe(sportEvent) || isCancelledAndWithinUnsubscriptionTimeSportEvent(sportEvent)
  )
}

function tooLateToUnsubscribe(sportEvent: SportEvent) {
  return sportEvent.association === 'sam' && afterTuesdayBeforeEvent(sportEvent)
}

function afterTuesdayBeforeEvent(sportEvent: SportEvent) {
  const tuesdayNight = endOfDay(previousTuesday(parseISO(sportEvent.startsAt)))
  return isAfter(new Date(), tuesdayNight)
}

function canSubmitInscription(
  sportEvent: SportEvent,
  context: { isAdmin: boolean; isAssociationAdmin: boolean }
) {
  const admin = context.isAdmin || context.isAssociationAdmin

  return (
    !isPastSportEvent(sportEvent) ||
    isCancelledAndWithinUnsubscriptionTimeSportEvent(sportEvent) ||
    (admin && isLessThanSixMonthsAfterSportEvent(sportEvent))
  )
}

async function handleInscriptionTypeSpecificParts(props: {
  db: DB
  inscription: Inscription
  sportEvent: SportEvent
}) {
  const { inscription, db, sportEvent } = props
  const uid = inscription.uid

  await ensureSportEventIsNotFinalized(db, inscription.sportEvent)

  // TODO: later: handle late payment / late inscriptions / late unlistings

  if (inscription.type === 'unlistedLicenseInscription') {
    await unlistLicenseInscriptionOfSameCategoryAndEventAndDate(db, uid, inscription)
    await deleteBookingsOfInscription(db, uid, inscription)
    return
  }
  if (inscription.type === 'unlistedDayLicenseInscription') {
    await deleteDayInscriptionOfSameCategoryAndEventAndDate(db, uid, inscription)
    await deleteBookingsOfInscription(db, uid, inscription)
    return
  }
  if (inscription.type === 'enlistedLicenseInscription') {
    await addLicenseInscriptionBookings(db, inscription, sportEvent)
    return
  }
  if (inscription.type === 'enlistedDayInscriptionDayCategoryDraft') {
    await addDayCategoryInscriptionBookings(db, inscription, sportEvent)
    return
  }
  if (inscription.type === 'enlistedDayInscriptionYearCategoryDraft') {
    await addDayLicenseBookings(db, inscription, sportEvent)
    await addLicenseInscriptionBookings(db, inscription, sportEvent)
    return
  }
  if (
    inscription.type === 'enlistedDayInscriptionDayCategory' ||
    inscription.type === 'enlistedDayInscriptionYearCategory'
  ) {
    throw new Error(`This case is not supported by this workflow: ${inscription.type}`)
  }

  console.error(`Unsupported inscription type ${(inscription as any).type}`)
  assertNever(inscription)
}

async function addDayCategoryInscriptionBookings(
  db: DB,
  inscription: EnlistedDayInscriptionDayCategoryDraft,
  sportEvent: SportEvent
) {
  const uid = inscription.uid
  const category = await db.loadDayCategory({
    sportEvent: sportEvent.id,
    category: inscription.category,
  })

  if (!category) throw new Error(`Category ${inscription.category} not found`)

  await db.pushInscriptionBooking({
    type: 'inscriptionBooking',
    id: '',
    byUid: inscription.byUid,
    uid,
    date: new Date().toISOString(),
    remainingBalance: category.price,
    item: {
      categoryId: category.id,
      sportEventId: inscription.sportEvent,
      sportEventDate: inscription.date,
      price: category.price,
      type: 'inscriptionDayCategoryLineItem',
      name: inscriptionDescription(sportEvent, category),
      dayCategoryName: category.name,
      association: sportEvent.association,
    },
  })

  if (sportEvent.offersPower && category.pricePower > 0) {
    await db.pushInscriptionBooking({
      type: 'inscriptionBooking',
      id: '',
      byUid: inscription.byUid,
      uid,
      date: new Date().toISOString(),
      remainingBalance: category.pricePower,
      item: {
        categoryId: inscription.category,
        sportEventId: inscription.sportEvent,
        sportEventDate: inscription.date,
        price: category.pricePower,
        dayCategoryName: category.name,
        type: 'inscriptionDayCategoryPowerLineItem',
        name: inscriptionDescription(sportEvent, category),
        association: sportEvent.association,
      },
    })
  }
}

async function addDayLicenseBookings(
  db: DB,
  inscription: EnlistedDayInscriptionYearCategoryDraft,
  sportEvent: SportEvent
) {
  const category = categoryOfAssociationRequired(inscription.category, inscription.association)

  const price = await dayLicensePrice(db, inscription, category)

  if (price)
    await db.pushInscriptionBooking({
      type: 'inscriptionBooking',
      id: '',
      byUid: inscription.byUid,
      uid: inscription.uid,
      date: new Date().toISOString(),
      remainingBalance: price,
      item: {
        categoryId: inscription.category,
        sportEventId: inscription.sportEvent,
        sportEventDate: inscription.date,
        price,
        type: 'inscriptionDayLicenseLineItem',
        name: inscriptionDescriptionDayLicense(sportEvent, category),
        association: category.association,
      },
    })
}

async function dayLicensePrice(
  db: DB,
  inscription: EnlistedDayInscriptionYearCategoryDraft,
  category: CategoryOfAssociation
) {
  return Math.min(...(await dayLicensePriceOptions(db, inscription, category)))
}

async function dayLicensePriceOptions(
  db: DB,
  inscription: EnlistedDayInscriptionYearCategoryDraft,
  category: CategoryOfAssociation
): Promise<number[]> {
  const options: number[] = []

  if (typeof category.priceDayLicenseWithOtherLicense === 'number') {
    const licenses = await db.loadApprovedLicenses({ uid: inscription.uid }, inscription.year)
    if (licenses.some((license) => license.licenseAssociation === category.association))
      options.push(category.priceDayLicenseWithOtherLicense)
  }

  if (category.association === 'sam') {
    // TODO: later: check membership in a better way? (add additional field?)
    const documents = await db.loadDocuments(inscription)
    if (!documents) throw new Error('No documents found')
    if (documents.personalData?.samMemberNumber) options.push(category.priceDayLicenseForMember)
  }

  // TODO: later: check membership for FMS

  options.push(category.priceDayLicenseWithoutMember)

  return options
}

async function addLicenseInscriptionBookings(
  db: DB,
  inscription: EnlistedLicenseInscription | EnlistedDayInscriptionYearCategoryDraft,
  sportEvent: SportEvent
) {
  const uid = inscription.uid
  const category = categoryByIdRequired(inscription.category)
  const categoryDetails = associationSpecificDetails(category, inscription.association)
  await db.pushInscriptionBooking({
    type: 'inscriptionBooking',
    id: '',
    byUid: inscription.byUid,
    uid,
    date: new Date().toISOString(),
    remainingBalance: categoryDetails.priceInscriptionWithLicense,
    item: {
      categoryId: inscription.category,
      sportEventId: inscription.sportEvent,
      sportEventDate: inscription.date,
      price: categoryDetails.priceInscriptionWithLicense,
      type: 'inscriptionLineItem',
      name: inscriptionDescription(sportEvent, categoryDetails),
      association: sportEvent.association,
    },
  })

  if (sportEvent.offersPower && categoryDetails.priceInscriptionPower > 0) {
    await db.pushInscriptionBooking({
      type: 'inscriptionBooking',
      id: '',
      byUid: inscription.byUid,
      uid,
      date: new Date().toISOString(),
      remainingBalance: categoryDetails.priceInscriptionPower,
      item: {
        categoryId: inscription.category,
        sportEventId: inscription.sportEvent,
        sportEventDate: inscription.date,
        price: categoryDetails.priceInscriptionPower,
        type: 'powerLineItem',
        name: inscriptionDescription(sportEvent, categoryDetails),
        association: sportEvent.association,
      },
    })
  }
}

async function unlistLicenseInscriptionOfSameCategoryAndEventAndDate(
  db: DB,
  uid: string,
  inscription: UnlistedLicenseInscription
) {
  const allInscriptions = await db.loadInscriptionsByUser({ uid })
  const relevantInscriptions = allInscriptions
    .filter(
      (insc) =>
        insc.sportEvent === inscription.sportEvent &&
        insc.category === inscription.category &&
        insc.date === inscription.date
    )
    .map<UnlistedLicenseInscription | undefined>((insc) =>
      insc.type === 'enlistedLicenseInscription'
        ? {
            type: 'unlistedLicenseInscription',
            byUid: inscription.byUid,
            category: insc.category,
            sportEvent: insc.sportEvent,
            createdAt: insc.createdAt,
            updatedAt: new Date().toISOString(),
            date: insc.date,
            paid: insc.paid,
            uid: insc.uid,
            year: insc.year,
          }
        : undefined
    )
    .filter(truthy)

  await Promise.all(
    relevantInscriptions.map(async (inscription) => {
      await db.setInscription(inscription)
    })
  )
  await updateTransactionStates(db, uid)
  await Promise.all(
    relevantInscriptions.map(async (inscription) => {
      await updateInscriptionPaymentStatus(db, inscription)
    })
  )
}

async function deleteDayInscriptionOfSameCategoryAndEventAndDate(
  db: DB,
  uid: string,
  inscription: UnlistedDayLicenseInscription
) {
  const allInscriptions = await db.loadInscriptionsByUser({ uid })
  const relevantInscriptions = allInscriptions
    .filter(
      (insc) =>
        insc.sportEvent === inscription.sportEvent &&
        insc.category === inscription.category &&
        insc.date === inscription.date
    )
    .map((insc) =>
      insc.type === 'enlistedDayInscriptionDayCategory' ||
      insc.type === 'enlistedDayInscriptionDayCategoryDraft' ||
      insc.type === 'enlistedDayInscriptionYearCategory' ||
      insc.type === 'enlistedDayInscriptionYearCategoryDraft'
        ? insc
        : undefined
    )
    .filter(truthy)

  await Promise.all(
    relevantInscriptions.map(async (inscription) => {
      await db.setInscriptionPaid(inscription, false)
      await updateDayInscriptionPublicStatistics(db, { ...inscription, paid: false })
      await db.deleteInscription(inscription)
    })
  )
}

function inscriptionDescriptionDayLicense(
  sportEvent: SportEvent,
  category: AssociationCategory | DayCategory
) {
  return `${inscriptionDescription(sportEvent, category)}, ${t().dayLicense}`
}

async function deleteBookingsOfInscription(
  db: DB,
  uid: string,
  inscription: UnlistedLicenseInscription | UnlistedDayLicenseInscription
) {
  const bookings = await db.loadInscriptionBookings({ uid })
  const relevantBookings = bookings.filter(
    (booking) =>
      booking.item.categoryId === inscription.category &&
      booking.item.sportEventId === inscription.sportEvent &&
      booking.item.sportEventDate === inscription.date &&
      booking.item.type !== 'donationLineItem'
  )

  await Promise.all(relevantBookings.map((booking) => db.deleteInscriptionBooking(booking)))
}

export async function migrateInscriptions(db: DB, year: Year) {
  const inscriptions = await db.loadAllInscriptions()
  await Promise.all(inscriptions.map((inscription) => db.setInscription(inscription)))
  await updateAllInscriptions(db, year)
}

export async function deleteInscription(db: DB, inscription: Inscription, admin: UserQuery) {
  await ensureSportEventIsNotFinalized(db, inscription.sportEvent)
  const uid = inscription.uid
  const allBookings = await db.loadInscriptionBookings({ uid })
  const bookings = allBookings.filter(
    (booking) =>
      booking.uid === uid &&
      booking.type === 'inscriptionBooking' &&
      booking.item.categoryId === inscription.category &&
      booking.item.sportEventId === inscription.sportEvent &&
      booking.item.sportEventDate === inscription.date &&
      booking.item.type !== 'donationLineItem'
  )

  await pushInscriptionUserEvent({ db, inscription, byUid: admin.uid, action: 'delete' })
  await db.setInscriptionPaid(inscription, false)
  await updateDayInscriptionPublicStatistics(db, { ...inscription, paid: false })
  await db.deleteInscription(inscription)
  await Promise.all(bookings.map((booking) => db.deleteInscriptionBooking(booking)))
  await updateTransactionAndLicenseStates(db, uid)
}

interface ApproveEnlistedDayInscriptionDayCategoryDraftProps {
  db: DB
  inscription: EnlistedDayInscriptionDayCategoryDraft
  admin: UserQuery
  issuedNumber: number
}

export async function approveDayInscriptionDayCategoryDraft(
  props: ApproveEnlistedDayInscriptionDayCategoryDraftProps
) {
  const { db, inscription, admin, issuedNumber } = props
  const newInscription: EnlistedDayInscriptionDayCategory = {
    ...inscription,
    type: 'enlistedDayInscriptionDayCategory',
    issuedNumber,
    approvedAt: new Date().toISOString(),
    approvedBy: admin.uid,
    manuallyVerified: false,
  }
  await db.setInscription(newInscription)
  await pushInscriptionUserEvent({ db, inscription, byUid: admin.uid, action: 'approve' })
}

interface ApproveEnlistedDayInscriptionYearCategoryDraftProps {
  db: DB
  inscription: EnlistedDayInscriptionYearCategoryDraft
  admin: UserQuery
  issuedNumber: number
}

export async function approveDayInscriptionYearCategoryDraft(
  props: ApproveEnlistedDayInscriptionYearCategoryDraftProps
) {
  const { db, inscription, admin, issuedNumber } = props
  const newInscription: EnlistedDayInscriptionYearCategory = {
    ...inscription,
    type: 'enlistedDayInscriptionYearCategory',
    issuedNumber,
    approvedAt: new Date().toISOString(),
    approvedBy: admin.uid,
    manuallyVerified: false,
  }
  await db.setInscription(newInscription)
  await pushInscriptionUserEvent({ db, inscription, byUid: admin.uid, action: 'approve' })
}

async function pushInscriptionUserEvent(props: {
  db: DB
  inscription: Inscription
  byUid: UserId
  action: 'delete' | 'approve' | 'create'
}) {
  const { db, inscription, byUid, action } = props
  const date = new Date().toISOString()
  const uid = inscription.uid
  await db.pushUserEvent({
    id: '',
    type: 'inscription',
    uid,
    byUid,
    date,
    action,
    inscriptionType: inscription.type,
    details: inscription,
  })
}

async function handleDonation(
  db: DB,
  query: SubmitInscriptionQuery,
  sportEvent: SportEvent,
  inscription: Inscription
) {
  if (
    (query.type === 'unlistedDayLicenseInscription' || query.type === 'unlistedLicenseInscription') &&
    (inscription.type === 'unlistedDayLicenseInscription' ||
      inscription.type === 'unlistedLicenseInscription') &&
    (query.donationAmount || 0) > 0
  ) {
    await createDonation(db, sportEvent, inscription, query.donationAmount)
    await updateTransactionStates(db, inscription.uid)
  }
}

async function createDonation(
  db: DB,
  sportEvent: SportEvent,
  inscription: UnlistedLicenseInscription | UnlistedDayLicenseInscription,
  donationAmount: number
) {
  const category = await loadCategory(db, inscription, sportEvent)

  await db.pushInscriptionBooking({
    type: 'inscriptionBooking',
    id: '',
    byUid: inscription.byUid,
    uid: inscription.uid,
    date: new Date().toISOString(),
    remainingBalance: donationAmount,
    item: {
      categoryId: inscription.category,
      sportEventId: inscription.sportEvent,
      sportEventDate: inscription.date,
      price: donationAmount,
      categoryName: category.name,
      type: 'donationLineItem',
      name: inscriptionDescription(sportEvent, category),
      association: sportEvent.association,
    },
  })
}

async function loadCategory(
  db: DB,
  inscription: UnlistedLicenseInscription | UnlistedDayLicenseInscription,
  sportEvent: SportEvent
): Promise<DayCategory | AssociationCategory> {
  if (isCategoryId(inscription.category)) {
    const category = categoryByIdRequired(inscription.category)
    return associationSpecificDetails(category, category.associations[0])
  }

  const category = await db.loadDayCategory({
    sportEvent: sportEvent.id,
    category: inscription.category,
  })
  if (!category) throw new Error(`Day category ${inscription.category} not found`)
  return category
}

type InscriptionContext =
  | AdminInscriptionContext
  | AssociationAdminInscriptionContext
  | RiderInscriptionContext

interface AdminInscriptionContext {
  isAdmin: true
  isAssociationAdmin: false
  association: undefined
  byUid: string
  manuallyVerified: boolean
}

interface AssociationAdminInscriptionContext {
  isAdmin: false
  isAssociationAdmin: true
  association: AssociationID
  byUid: string
  manuallyVerified: boolean
}

interface RiderInscriptionContext {
  isAdmin: false
  isAssociationAdmin: false
  association: undefined
  byUid: string
  manuallyVerified: boolean
}
