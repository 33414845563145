import { DB } from 'shared/db/db'

export const id = '2022-02-20T17-39-08-129Z'

export const description = 'Add transponderType to sportEvents'

export async function up(db: DB) {
  const sportEvents = await db.loadAllSportEvents()
  await Promise.all(
    sportEvents.map(async (sportEvent) => {
      if (sportEvent.transponderType) return

      await db.updateSportEvent({ ...sportEvent, transponderType: 'MyLaps' })
    })
  )
}
