import { Button, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useState } from 'react'
import { useAllDocuments } from 'app/db/db-contexts/documents-context'
import { useAdmins } from 'app/db/db-hooks/use-is-admin'
import { db } from 'app/db/frontend-db'
import { actions, tableHeaders } from 'app/export/table'
import { useDialog, ConfirmDialog } from 'app/layouts/confirm-dialog'
import { TableBox } from 'app/layouts/table-box'
import { EditPersonalDataButton } from 'app/pages/admin/users/EditPersonalDataButton'
import { User } from 'app/users/user'
import { AdminPath } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { fullName } from 'shared/models/personal-data'
import { StoredUser } from 'shared/models/stored-user'
import { useAsyncError } from 'utils/errors'

interface UserManagementProps {
  user: User
  path: AdminPath
}

export function AdminManagement({ user, path }: UserManagementProps) {
  const { data, loading, error, loadingOrError } = useAdmins(path)
  const allDocuments = useAllDocuments()
  const [selectedUser, setSelectedUser] = useState<StoredUser | null>(null)
  const addAdminDialog = useDialog()
  const throwError = useAsyncError()

  return (
    <TableBox
      title={path === 'readonlyAdmins' ? t().admins.readOnlyAdmins : t().admins.title}
      loading={loading}
      error={error}
      data={
        !loadingOrError && {
          headers: tableHeaders(['UID', 'E-Mail', 'Name', actions()]),
          contents: data.admins.map((admin) => {
            const documents = allDocuments.data[admin.uid]
            return [
              admin.uid,
              admin.email,
              fullName(documents?.personalData),
              <>
                <RemoveAdminButton key="remove-admin" path={path} admin={admin} user={user} />
                <EditPersonalDataButton
                  user={{ uid: admin.uid }}
                  documents={documents}
                  email={admin.email}
                />
              </>,
            ]
          }),
          ids: data.admins.map(({ uid }) => uid),
          rawData: data.admins.map((row) => JSON.stringify(row)),
        }
      }
    >
      {!loadingOrError && (
        <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
          <Grid item>
            <Autocomplete
              size="small"
              options={data.nonAdmins}
              getOptionLabel={(option) => option.email}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label={t().admins.adminEmail} variant="outlined" />
              )}
              value={selectedUser}
              onChange={(_el, value) => setSelectedUser(value)}
            />
          </Grid>
          <Grid item>
            <Button onClick={() => addAdminDialog.open()} disabled={!selectedUser}>
              {t().admins.addAdmin}
            </Button>
            <ConfirmDialog
              title={t().admins.addAdmin}
              buttonText={t().admins.addAsAdmin}
              dialog={addAdminDialog}
              onClose={() => setSelectedUser(null)}
              onConfirm={() =>
                selectedUser ? db.setAdmin(path, selectedUser).catch(throwError) : Promise.resolve()
              }
            >
              {t().admins.confirmAddAdmin(selectedUser?.email || '')}
            </ConfirmDialog>
          </Grid>
        </Grid>
      )}
    </TableBox>
  )
}

interface RemoveAdminButtonProps {
  admin: StoredUser
  user: User
  path: AdminPath
}

function RemoveAdminButton({ admin, user, path }: RemoveAdminButtonProps): JSX.Element {
  const removeAdminDialog = useDialog()
  const throwError = useAsyncError()

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        disabled={admin.uid === user.uid}
        onClick={() => removeAdminDialog.open()}
      >
        {t().admins.remove}
      </Button>
      <ConfirmDialog
        title={t().admins.removeAdmin}
        buttonText={t().admins.removeAsAdmin}
        dialog={removeAdminDialog}
        onConfirm={() => db.removeAdmin(path, admin).catch(throwError)}
      >
        {t().admins.confirmDeleteAdmin(admin.email)}
      </ConfirmDialog>
    </>
  )
}
