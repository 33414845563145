import { Avatar, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from '@material-ui/core'
import { ReactNode } from 'react'

interface OrderedListProps {
  items: {
    key?: string
    name?: string | number
    icon?: ReactNode
    text: ReactNode
  }[]
}

export function OrderedList({ items }: OrderedListProps) {
  return (
    <List>
      {items.map(({ name, text, icon }, index) => (
        <ListItem key={index}>
          {name && (
            <ListItemAvatar>
              <Avatar>{name}</Avatar>
            </ListItemAvatar>
          )}
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText>{text}</ListItemText>
        </ListItem>
      ))}
    </List>
  )
}
