import { Box, Typography } from '@material-ui/core'
import { LicensesOverview } from 'app/pages/admin/licenses/licenses-overview'
import { ElevatedBox } from 'app/pages/dashboard/elevated-box'
import { useIsAssociationAdmin, useUserContext } from 'app/themes/user-context'
import { User } from 'app/users/user'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { RoutedButton } from 'utils/buttons/routed-button'
import { FlexButtons } from 'utils/flex-buttons'

interface AdminDashboardProps {
  user: User
}

export function AdminDashboard({ user }: AdminDashboardProps) {
  const userContext = useUserContext()
  const isAssociationAdmin = useIsAssociationAdmin()

  return userContext.adminOrAssociationAdmin ? (
    <>
      <Box pt={3} pb={1}>
        <RoutedButton to={routes.userDashboard.to}>{routes.userDashboard.textAlt()}</RoutedButton>
      </Box>
      <Box py={1}>
        <Typography component="h2" variant="h2">
          {t().dashboard.adminTitle}
        </Typography>
      </Box>

      <ElevatedBox title={t().sportEvents}>
        <FlexButtons>
          <RoutedButton to={routes.adminSportEvents.to} color="primary" variant="outlined">
            {routes.adminSportEvents.text()}
          </RoutedButton>
          <RoutedButton to={routes.sportEvents.to}>{routes.sportEvents.text()}</RoutedButton>
          <RoutedButton to={routes.sportEventFinancials.to}>
            {routes.sportEventFinancials.text()}
          </RoutedButton>
          <RoutedButton to={routes.lonelyTwoDayDiscounts.to}>
            {routes.lonelyTwoDayDiscounts.text()}
          </RoutedButton>
        </FlexButtons>
      </ElevatedBox>

      <LicensesOverview user={user} />

      <ElevatedBox title={t().financials.financials}>
        <FlexButtons>
          <RoutedButton color="primary" variant="outlined" to={routes.allTransactions.to}>
            {routes.allTransactions.dashboard()}
          </RoutedButton>
          <RoutedButton to={routes.manualPayments.to}>{routes.manualPayments.text()}</RoutedButton>
          <RoutedButton to={routes.allLicenseBookings.to}>
            {routes.allLicenseBookings.dashboard()}
          </RoutedButton>
          <RoutedButton to={routes.licenseBookingsByRider.to}>
            {routes.licenseBookingsByRider.text()}
          </RoutedButton>
          <RoutedButton to={routes.manualBookings.to}>{routes.manualBookings.dashboard()}</RoutedButton>
          <RoutedButton to={routes.userFinancials.to}>{routes.userFinancials.dashboard()}</RoutedButton>
          {!isAssociationAdmin && (
            <>
              <RoutedButton to={routes.payments.to}>{routes.payments.text()}</RoutedButton>
              <RoutedButton to={routes.transactionsWithoutUser.to}>
                {routes.transactionsWithoutUser.text()}
              </RoutedButton>
            </>
          )}
          <RoutedButton to={routes.associationFinancials.to}>
            {routes.associationFinancials.text()}
          </RoutedButton>
        </FlexButtons>
      </ElevatedBox>

      <ElevatedBox title={t().dashboard.users}>
        <FlexButtons>
          <RoutedButton to={routes.editAdmins.to}>{routes.editAdmins.text()}</RoutedButton>
          <RoutedButton to={routes.messages.to}>{routes.messages.text()}</RoutedButton>
          {!isAssociationAdmin && (
            <>
              <RoutedButton to={routes.editUsers.to}>{routes.editUsers.text()}</RoutedButton>
              <RoutedButton to={routes.emailConflicts.to}>{routes.emailConflicts.text()}</RoutedButton>
              <RoutedButton to={routes.userEvents.to}>{routes.userEvents.text()}</RoutedButton>
              <RoutedButton to={routes.duplicatedUsers.to}>{routes.duplicatedUsers.text()}</RoutedButton>
            </>
          )}
        </FlexButtons>
      </ElevatedBox>

      <ElevatedBox title={t().dashboard.other}>
        <FlexButtons>
          <RoutedButton to={routes.newTransponders.to}>{routes.newTransponders.text()}</RoutedButton>
        </FlexButtons>
      </ElevatedBox>
    </>
  ) : null
}
