import { useState } from 'react'
import { UploadMetadata } from 'shared/models/upload-status'
import { ExternalLink, ExternalLinkButton } from 'utils/external-link'

interface DisplayUploadedFileProps {
  path: string
  metadata: UploadMetadata
  maxWidth?: number
}

export function DisplayUploadedFile({ path, metadata, maxWidth }: DisplayUploadedFileProps) {
  const [imageUnloadable, setImageUnloadable] = useState(false)

  if (!path) return null

  if (displayImage(metadata?.contentType || ''))
    return (
      <ExternalLink href={path}>
        {!imageUnloadable && (
          <img
            style={{ width: '100%', maxWidth }}
            src={path}
            alt="Datei "
            onError={() => setImageUnloadable(true)}
          />
        )}
        <br />
        Herunterladen
      </ExternalLink>
    )

  return (
    <ExternalLinkButton variant="outlined" color="primary" fullWidth href={path}>
      Herunterladen
    </ExternalLinkButton>
  )
}

function displayImage(contentType: string) {
  return contentType.startsWith('image/')
}
