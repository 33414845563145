import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  TextField,
} from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import { Alert } from '@material-ui/lab'
import type { FieldProps } from 'formik'
import { sortBy, uniq } from 'lodash'
import {
  associationChecked,
  associationDisabled,
  associationExpanded,
  CategoriesCheckboxGroupContext,
  categoryChecked,
  categoryDisabled,
  categoryHasApprovedLicense,
  categoryMatchesSearch,
  categorySelectedWithOtherAssociation,
  categoryTypeChecked,
  categoryTypeDisabled,
  categoryTypeExpanded,
  categoryTypeVisible,
  SelectedCategoryType,
  toggleAssociation,
  toggleCategory,
  toggleCategoryType,
  useCategoriesContext,
} from 'app/forms/categories-checkbox-group-context'
import { afmIsOnline } from 'app/license/config'
import { useAssociationState } from 'app/themes/association-context'
import { categoryPriceIncludingAdditionGivenBase, priceRangeText } from 'shared/category-pricing'
import { routes } from 'shared/config/routes'
import {
  categoryCommonNameEndUser,
  categoryOfAssociationRequired,
  offersMultipleLicenseTypes,
} from 'shared/data/categories-service'
import { ApprovedLicense } from 'shared/db/db'
import { licenseTypeNameLong } from 'shared/db/license-type'
import { t } from 'shared/i18n/current'
import { translateCategoryType, translateLongCategoryType } from 'shared/i18n/i18n-helpers'
import { AssociationID, associations, FullAssociation } from 'shared/models/associations'
import {
  AssociationCategory,
  associationSpecificDetails,
  Category,
  CategoryType,
} from 'shared/models/category'
import { SelectedCategory } from 'shared/models/selected-category'
import { pFormatDateWithSecondsSpaceDe } from 'shared/utils/date'
import { ExternalLink } from 'utils/external-link'

export interface CategoriesCheckboxProps extends FieldProps<SelectedCategory[]> {
  options: {
    categories: Category[]
    approvedLicenses: ApprovedLicense[] | undefined
  }
  label: string
  helperText?: string
}

export function CategoriesCheckboxGroup(props: CategoriesCheckboxProps) {
  const context = useCategoriesContext(props)
  const { previewing } = useAssociationState()

  return (
    <>
      <FormLabel component="legend">{props.label}</FormLabel>
      <Box mt={2} mb={1}>
        <TextField
          label={t().licenseForm.searchCategories}
          variant="outlined"
          size="small"
          fullWidth
          value={context.search}
          onChange={(event) => context.setSearch(event.currentTarget.value)}
        />
      </Box>
      <FormControl error={!!context.errorText} component="fieldset" variant="outlined">
        <FormGroup>
          {sortedAssociations(context.themeAssociationID)
            .filter((association) => previewing || afmIsOnline || association.id !== 'afm')
            .map((association) => (
              <AssociationCheckboxes key={association.id} context={context} association={association} />
            ))}
        </FormGroup>
      </FormControl>
    </>
  )
}

interface AssociationCheckboxesProps {
  association: FullAssociation
  context: CategoriesCheckboxGroupContext
}

function AssociationCheckboxes(props: AssociationCheckboxesProps) {
  const { association, context } = props
  return (
    <>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={associationChecked(context, association)}
              disabled={associationDisabled(context, association)}
              onChange={() => toggleAssociation(context, association)}
              name={association.id}
            />
          }
          label={association.name}
        />
      </Box>
      {associationExpanded(context, association) && <CategoryTypesCheckboxes {...props} />}
    </>
  )
}

function CategoryTypesCheckboxes({ context, association }: AssociationCheckboxesProps) {
  const associationCategories = context.categories.filter((category) =>
    category.associations.includes(association.id)
  )
  const associationCategoryTypes = sortBy(
    uniq(associationCategories.map((category) => category.type)),
    (type) => translateCategoryType(type)
  )

  return (
    <Box ml={2}>
      {association.id === 'mxrs' && <MxrsSamFusionInfo />}
      {associationCategoryTypes.map((categoryType) => (
        <CategoryTypeCheckboxes
          key={categoryType}
          context={context}
          association={association}
          categoryType={categoryType}
        />
      ))}
    </Box>
  )
}

function MxrsSamFusionInfo() {
  return (
    <Box my={2}>
      <Alert severity="success" variant="outlined">
        <p>{t().licenseForm.mxrsSamFusionInfoP1}</p>
        <p>{t().licenseForm.mxrsSamFusionInfoP2}</p>
        <p>{t().licenseForm.mxrsSamFusionInfoP3}</p>
        <p>{t().licenseForm.mxrsSamFusionInfoP4}</p>
        <p>
          <strong>{t().licenseForm.mxrsSamFusionInfoP5}</strong>
        </p>
      </Alert>
    </Box>
  )
}

interface CategoryTypeCheckboxesProps {
  context: CategoriesCheckboxGroupContext
  association: FullAssociation
  categoryType: CategoryType
}

function CategoryTypeCheckboxes(props: CategoryTypeCheckboxesProps) {
  const { context, association, categoryType } = props
  const selectedCategoryType: SelectedCategoryType = { association: association.id, categoryType }

  return (
    <>
      {categoryTypeVisible(context, selectedCategoryType) && (
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={categoryTypeChecked(context, selectedCategoryType)}
                disabled={categoryTypeDisabled(context, selectedCategoryType)}
                onChange={() => toggleCategoryType(context, selectedCategoryType)}
                name={categoryType}
              />
            }
            label={translateLongCategoryType(categoryType)}
          />
        </Box>
      )}
      {categoryTypeExpanded(context, selectedCategoryType) && <CategoriesCheckboxes {...props} />}
    </>
  )
}

function CategoriesCheckboxes(props: CategoryTypeCheckboxesProps) {
  const { context, association, categoryType } = props
  const categories = context.categories.filter(
    (category) =>
      category.associations.includes(association.id) &&
      category.type === categoryType &&
      !category.archived
  )

  return (
    <Box ml={2}>
      {categories.map((category) => (
        <CategoryCheckbox
          key={category.id}
          context={context}
          association={association}
          categoryType={categoryType}
          category={category}
        />
      ))}
    </Box>
  )
}

interface CategoryCheckboxProps {
  context: CategoriesCheckboxGroupContext
  association: FullAssociation
  categoryType: CategoryType
  category: Category
}

function CategoryCheckbox(props: CategoryCheckboxProps) {
  const { context, association, categoryType, category } = props
  const selectedCategory: SelectedCategory = {
    association: association.id,
    categoryType,
    category: category.id,
  }

  if (!categoryMatchesSearch(context, selectedCategory)) return null

  const categoryDetails = associationSpecificDetails(category, association.id)
  const { hint, priceHint } = categoryDetails

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            disabled={categoryDisabled(context, selectedCategory)}
            checked={categoryChecked(context, selectedCategory)}
            onChange={() => toggleCategory(context, selectedCategory)}
            name={[association.id, category.id].join('-')}
          />
        }
        label={`${association.name} - ${
          categoryOfAssociationRequired(category.id, association.id).name
        }`}
      />
      {categorySelectedWithOtherAssociation(context, selectedCategory) && (
        <Box mb={2}>
          <FormHelperText>{t().categoryAlreadyChosenHint}</FormHelperText>
        </Box>
      )}
      {!category.active && (
        <Box>
          <FormHelperText>{t().categoryNotOnlineYet}</FormHelperText>
        </Box>
      )}
      {categoryChecked(context, selectedCategory) && (
        <Box mb={2}>
          {!categorySelectedWithOtherAssociation(context, selectedCategory) && (
            <PricesByAgeHelperTexts categoryDetails={categoryDetails} />
          )}
          {categoryHasApprovedLicense(context, selectedCategory) && (
            <FormHelperText>{t().licenseAlreadyConfirmed}</FormHelperText>
          )}

          {priceHint && <FormHelperText>{priceHint}</FormHelperText>}
          {hint && <FormHelperText>{hint}</FormHelperText>}
          {categoryDetails.advertiseSamInsurance && <SAMInsuranceCheckbox context={context} />}
          {(category.id === 'sam-2022-other-trainingslizenz-funlizenz' ||
            category.id === 'other-trainingslizenz-funlizenz') && (
            <FormHelperText>
              <ExternalLink href={routes.termsAndDataProtection.to}>
                {t().licenseForm.insuranceTerms}
              </ExternalLink>
            </FormHelperText>
          )}
        </Box>
      )}
    </Box>
  )
}

function SAMInsuranceCheckbox({ context }: { context: CategoriesCheckboxGroupContext }) {
  const category = context.categories.find(({ id }) => id === 'sam-2022-other-trainingslizenz-funlizenz')
  if (!category) return null

  const selectedCategory: SelectedCategory = {
    association: category.associations[0],
    categoryType: category.type,
    category: category.id,
  }
  const categoryDetails = associationSpecificDetails(category, selectedCategory.association)
  const { hint, priceHint } = categoryDetails
  return (
    <Box ml={2}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={categoryDisabled(context, selectedCategory)}
            checked={categoryChecked(context, selectedCategory)}
            onChange={() => toggleCategory(context, selectedCategory)}
            name={'additional-sam-2022-other-trainingslizenz-funlizenz'}
          />
        }
        label={`${t().licenseForm.advertiseSamInsurance} ${categoryCommonNameEndUser(category)}`}
      />
      {categoryChecked(context, selectedCategory) && (
        <Box mb={2}>
          {categoryDisabled(context, selectedCategory) && (
            <FormHelperText>{t().licenseAlreadyConfirmed}</FormHelperText>
          )}
          {priceHint && <FormHelperText>{priceHint}</FormHelperText>}
          {hint && <FormHelperText>{hint}</FormHelperText>}
          <FormHelperText>
            <ExternalLink href={routes.termsAndDataProtection.to}>
              {t().licenseForm.insuranceTerms}
            </ExternalLink>
          </FormHelperText>
        </Box>
      )}
    </Box>
  )
}

export interface OldCategoriesCheckboxProps extends FieldProps<string[]> {
  options: {
    categories: Category[]
    approvedLicenses: ApprovedLicense[] | undefined
    showHints: boolean
  }
  label: string
  helperText?: string
}

export function PricesByAgeHelperTexts({ categoryDetails }: { categoryDetails: AssociationCategory }) {
  return (
    <>
      {categoryDetails.prices
        .filter(({ value: price }) => price > 0)
        .map(({ from, to, value: price, licenseType }) => {
          const priceDetails = categoryPriceIncludingAdditionGivenBase(categoryDetails, price)
          return (
            <FormHelperText key={[from, to, licenseType, price].join('-')}>
              {t().licenseForm.price}
              {offersMultipleLicenseTypes(categoryDetails)
                ? ` (${licenseTypeNameLong(licenseType)})`
                : ''}
              : {priceRangeText({ from, to })} CHF {priceDetails.total}{' '}
              {priceDetails.discount > 0
                ? `(CHF ${priceDetails.base} ${t().after} ${pFormatDateWithSecondsSpaceDe(
                    categoryDetails.discountUntil
                  )})`
                : ''}
            </FormHelperText>
          )
        })}
    </>
  )
}

function sortedAssociations(themeAssociation: AssociationID) {
  return sortBy(associations, (association) => [
    themeAssociation === association.id ? 0 : 1,
    association.name,
  ])
}
