import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  LinearProgress,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { usePublicInscriptionStatistics } from 'app/db/db-hooks/inscription-db-hooks'
import { useActiveBikes } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { samFields } from 'app/forms/fields'
import { submitSportEventInscriptionToServer } from 'app/inscription/inscription-frontend-service'
import { ButtonWithConfirmation } from 'app/layout/button-with-confirmation'
import { Multiline } from 'app/layout/multiline'
import { generatePDFBill } from 'app/pages/billing/pdf-bill-service'
import { VisualLicenseTasks } from 'app/pages/dashboard/overview-box'
import { bikeName } from 'app/pages/inscription/bike-name'
import { routes } from 'shared/config/routes'
import { categoryOfAssociationRequired } from 'shared/data/categories-service'
import { nextLicenseYear } from 'shared/data/license-config'
import { DayCategory } from 'shared/db/day-category'
import { Documents, UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import {
  dayCategoryInscriptionTasks,
  dayLicenseYearCategoryInscriptionTasks,
} from 'shared/license/license-tasks'
import { AssociationID } from 'shared/models/associations'
import { Bike } from 'shared/models/bike'
import { Category } from 'shared/models/category'
import { SportEvent } from 'shared/sport-events/sport-events'
import { SportEventType } from 'shared/sport-events/sportEventType'
import { isLength, truthy } from 'shared/utils/array'
import { toggleSetValue } from 'shared/utils/set'
import { Dig } from 'shared/utils/tsc'
import { RoutedButton } from 'utils/buttons/routed-button'
import { ExternalLink } from 'utils/external-link'
import { Disp } from 'utils/react'
import { useErrorSnackbarForError } from 'utils/snackbar'
import { UseBoolean, useBoolean } from 'utils/use-boolean'

// pending i18n

interface EnlistDayInscriptionDayCategoryButtonProps {
  user: UserQuery
  date: string
  category: DayCategory
  sportEvent: SportEvent
  documents: Documents
  availableBikes: Bike[]
  additionalInfo: boolean
  admin: boolean
}

export function EnlistDayInscriptionDayCategoryButton(
  props: EnlistDayInscriptionDayCategoryButtonProps
) {
  const { sportEvent, category, user, availableBikes, date, documents, additionalInfo, admin } = props

  const showError = useErrorSnackbarForError()
  const [selectedBikeIds, setSelectedBikeIds] = useState(new Set<string>())
  const stats = usePublicInscriptionStatistics({
    sportEvent: sportEvent.id,
    date,
    category: category.id,
  })

  const tasks = dayCategoryInscriptionTasks({ category, documents })
  const fields = samFields().enlistDayInscription

  return (
    <Box>
      <Formik
        initialValues={enlistDayInscriptionLoadInitialValues()}
        enableReinitialize={true}
        validateOnMount={true}
        validationSchema={enlistDayInscriptionSchema()}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await submitSportEventInscriptionToServer({
              bikeIds: [...selectedBikeIds],
              uid: user.uid,
              sportEventId: sportEvent.id,
              date,
              type: 'enlistedDayInscriptionDayCategoryDraft',
              categoryId: category.id,
              year: nextLicenseYear,
              preferredNumber: values.preferredNumber,
              sidecarPartner: values.sidecarPartner,
              remarksRider: values.remarksRider,
            })
            const bill = await db.loadLatestBill(user)
            if (bill) await generatePDFBill(bill)
          } catch (error) {
            showError(error)
          }

          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, touched, errors, isValid }) => (
          <Form>
            <Box mt={2}>
              <VisualLicenseTasks hideIfAllDone tasks={tasks} size="sm" admin={admin} />
            </Box>

            {additionalInfo && (
              <Box mt={2}>
                <Alert severity="info" variant="outlined">
                  {t().inscription.enlistInscriptionInfo(
                    stats.enlisted,
                    stats.paid,
                    category.inscriptionsLimit
                  )}
                  <br />
                  <br />
                  {t().inscription.enlistInscriptionOrderInfo}
                  <br />
                  <br />
                  {t().inscription.enlistPaymentInfo}
                  <br />
                  <br />
                  {t().inscription.enlistWaitlistInfo}
                </Alert>

                {stats.paid >= category.inscriptionsLimit ? (
                  <Box mt={1}>
                    <Alert severity="error" variant="outlined">
                      {t().inscription.enlistPaidRidersInfo(stats.paid, category.inscriptionsLimit)}
                      <br />
                      <br />
                      {t().inscription.enlistOnWaitlistInfo}
                    </Alert>
                  </Box>
                ) : (
                  stats.enlisted >= category.inscriptionsLimit && (
                    <Box mt={1}>
                      <Alert severity="warning" variant="outlined">
                        {t().inscription.enlistRiderLimitInfo(
                          stats.enlisted,
                          category.inscriptionsLimit
                        )}
                        <br />
                        <br />
                        {t().inscription.enlistWaitlistPaymentInfo}
                      </Alert>
                    </Box>
                  )
                )}
              </Box>
            )}

            <Box mt={2} mb={1}>
              <ButtonWithConfirmation
                variant="contained"
                color="primary"
                disabled={!admin && !tasks.allDone}
                onConfirm={() => submitForm()}
                title={isSubmitting ? t().inscription.inscribing : t().inscribe}
                fullWidthDialog
                disableConfirmButton={
                  !isValid ||
                  isSubmitting ||
                  (!admin &&
                    (!tasks.allDone ||
                      (category.needsBikeInfo
                        ? selectedBikeIds.size === 0 || selectedBikeIds.size > 2
                        : false)))
                }
                confirmation={
                  <Box>
                    {category.needsBikeInfo && (
                      <Box mt={1}>
                        <SelectedBikes
                          label={t().chooseBikes}
                          bikes={availableBikes}
                          selectedBikeIds={selectedBikeIds}
                          setSelectedBikeIds={setSelectedBikeIds}
                          sportsEventType={sportEvent.sportEventType}
                        />
                      </Box>
                    )}

                    <Box py={1}>{fields.preferredNumber.field()}</Box>
                    {(category.sidecar || category.sidecarPassenger) && (
                      <Box py={1}>{fields.sidecarPartner.field()}</Box>
                    )}
                    <Box py={1}>{fields.remarksRider.field()}</Box>
                    <Box py={1}>
                      <TransponderInfo documents={documents} sportEvent={sportEvent} />
                    </Box>

                    {category.additionalTermsText && (
                      <Box py={1}>
                        <Typography variant="h6">{t().acceptTerms.additionalTerms}</Typography>
                        <Typography>
                          <Multiline>{category.additionalTermsText}</Multiline>
                        </Typography>
                      </Box>
                    )}
                    <Box py={1}>
                      {fields.acceptTerms.field('')}

                      <FormHelperText>
                        {fields.acceptTerms.hintForTerms}{' '}
                        <ExternalLink href={routes.termsAndDataProtection.to}>
                          {t().acceptTerms.validation}
                        </ExternalLink>
                        {category.additionalTermsURL && (
                          <>
                            ,{' '}
                            <ExternalLink href={category.additionalTermsURL}>
                              {t().acceptTerms.additionalTerms}
                            </ExternalLink>
                          </>
                        )}
                      </FormHelperText>
                      {touched.acceptTerms && errors.acceptTerms && (
                        <FormHelperText error>{errors.acceptTerms}</FormHelperText>
                      )}
                    </Box>
                    {isSubmitting && (
                      <Box my={1}>
                        <LinearProgress />
                      </Box>
                    )}
                  </Box>
                }
              >
                {isSubmitting ? t().inscription.inscribing : t().inscription.payAndInscribe}
              </ButtonWithConfirmation>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

function enlistDayInscriptionSchema() {
  const validations = Object.fromEntries(
    Object.entries(samFields().enlistDayInscription).map(([k, v]) => [k, v.validation])
  ) as Dig<'validation', ReturnType<typeof samFields>['enlistDayInscription']>
  return Yup.object().defined().shape(validations)
}

function enlistDayInscriptionLoadInitialValues(): EnlistDayInscriptionDayCategoryForm {
  return Object.fromEntries(
    Object.entries(samFields().enlistDayInscription).map(([k, v]) => [k, v.default])
  ) as unknown as EnlistDayInscriptionDayCategoryForm
}

interface EnlistDayInscriptionDayCategoryForm {
  preferredNumber: string
  remarksRider: string
  sidecarPartner: string
  acceptTerms: boolean
}

interface EnlistDayInscriptionYearCategoryButtonProps {
  user: UserQuery
  date: string
  submitting: UseBoolean
  category: Category
  sportEvent: SportEvent
  availableBikes: Bike[]
  documents: Documents
  additionalInfo: boolean
  admin: boolean
}

export function EnlistDayInscriptionYearCategoryButton(
  props: EnlistDayInscriptionYearCategoryButtonProps
) {
  const {
    sportEvent,
    category: rawCategory,
    user,
    availableBikes,
    date,
    documents,
    additionalInfo,
    admin,
  } = props

  const showError = useErrorSnackbarForError()
  const [selectedBikeIds, setSelectedBikeIds] = useState(new Set<string>())
  const stats = usePublicInscriptionStatistics({
    sportEvent: sportEvent.id,
    date,
    category: rawCategory.id,
  })

  const association = inscriptionAssociation(rawCategory, sportEvent)

  if (!association)
    return (
      <Alert variant="outlined" severity="info">
        {t().inscription.dayLicenseInThisCategoryTemporarilyDisabled}
      </Alert>
    )

  const category = categoryOfAssociationRequired(rawCategory.id, association)
  const tasks = dayLicenseYearCategoryInscriptionTasks({ category, documents })

  if (!category.enlistWithoutLicense)
    return (
      <Alert variant="outlined" severity="error">
        {t().inscription.yearLicenseRequired}
      </Alert>
    )

  const fields = samFields().enlistDayInscription

  return (
    <Box>
      <Formik
        initialValues={enlistDayInscriptionLoadInitialValues()}
        enableReinitialize={true}
        validateOnMount={true}
        validationSchema={enlistDayInscriptionSchema()}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await submitSportEventInscriptionToServer({
              bikeIds: [...selectedBikeIds],
              uid: user.uid,
              sportEventId: sportEvent.id,
              date,
              type: 'enlistedDayInscriptionYearCategoryDraft',
              categoryId: category.id,
              association,
              preferredNumber: values.preferredNumber,
              sidecarPartner: values.sidecarPartner,
              remarksRider: values.remarksRider,
            })

            const bill = await db.loadLatestBill(user)
            if (bill) await generatePDFBill(bill)
          } catch (error) {
            showError(error)
          }

          setSubmitting(false)
        }}
      >
        {({ submitForm, isSubmitting, touched, errors, isValid }) => (
          <Form>
            <Box mt={2}>
              <VisualLicenseTasks hideIfAllDone tasks={tasks} size="sm" admin={admin} />
            </Box>

            {additionalInfo && (
              <Box mt={2}>
                <Alert severity="info" variant="outlined">
                  {t().inscription.enlistInscriptionOrderRules(stats.enlisted, stats.paid)}
                  <br />
                  <br />
                  {t().inscription.enlistDayLicenseInfo}
                  <br />
                  <br />
                  {t().inscription.enlistPaymentInfo}
                  <br />
                  <br />
                  {t().inscription.enlistWaitlistInfo}
                </Alert>
              </Box>
            )}

            <Box mt={2} mb={1}>
              <ButtonWithConfirmation
                variant="contained"
                color="primary"
                disabled={!admin && !tasks.allDone}
                onConfirm={() => submitForm()}
                title={isSubmitting ? t().inscription.inscribing : t().inscribe}
                fullWidthDialog
                disableConfirmButton={
                  !isValid ||
                  isSubmitting ||
                  (!admin &&
                    (!tasks.allDone ||
                      (category.needsBikeInfo
                        ? selectedBikeIds.size === 0 || selectedBikeIds.size > 2
                        : false)))
                }
                confirmation={
                  <Box>
                    {category.needsBikeInfo && (
                      <Box mt={1}>
                        <SelectedBikes
                          label={t().chooseBikes}
                          bikes={availableBikes}
                          selectedBikeIds={selectedBikeIds}
                          setSelectedBikeIds={setSelectedBikeIds}
                          sportsEventType={sportEvent.sportEventType}
                        />
                      </Box>
                    )}

                    <Box py={1}>{fields.preferredNumber.field()}</Box>
                    {(category.sidecar || category.sidecarPassenger) && (
                      <Box py={1}>{fields.sidecarPartner.field()}</Box>
                    )}
                    <Box py={1}>{fields.remarksRider.field()}</Box>
                    <Box py={1}>
                      <TransponderInfo documents={documents} sportEvent={sportEvent} />
                    </Box>

                    <Box py={1}>
                      {fields.acceptTerms.field('')}

                      <FormHelperText>
                        {fields.acceptTerms.hintForTerms}{' '}
                        <ExternalLink href={routes.termsAndDataProtection.to}>
                          {t().acceptTerms.validation}
                        </ExternalLink>
                      </FormHelperText>
                      {touched.acceptTerms && errors.acceptTerms && (
                        <FormHelperText error>{errors.acceptTerms}</FormHelperText>
                      )}
                    </Box>

                    {isSubmitting && (
                      <Box my={1}>
                        <LinearProgress />
                      </Box>
                    )}
                  </Box>
                }
              >
                {isSubmitting ? t().inscription.inscribing : t().inscription.payAndInscribe}
              </ButtonWithConfirmation>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

function TransponderInfo({ documents, sportEvent }: { documents: Documents; sportEvent: SportEvent }) {
  const relevantTransponders = Object.values(documents.transponder?.transponders || {}).filter(
    (transponder) => transponder.type === sportEvent.transponderType
  )
  const [first, second] = relevantTransponders.map((t) => t.transponderNumber).filter(truthy)

  return relevantTransponders.length === 0 ? null : (
    <>
      {t().transponders.privateTransponder}: {[first, second].filter(truthy).join(', ')}
    </>
  )
}

interface EnlistLicenseInscriptionButtonProps {
  user: UserQuery
  date: string
  submitting: UseBoolean
  category: Category
  sportEvent: SportEvent
  availableBikes: Bike[]
  admin: boolean
}

export function EnlistLicenseInscriptionButton(props: EnlistLicenseInscriptionButtonProps) {
  const { submitting, sportEvent, category, availableBikes, user, date, admin } = props

  const buttonSubmitting = useBoolean(false)
  const showError = useErrorSnackbarForError()
  const [selectedBikeIds, setSelectedBikeIds] = useState(new Set<string>())

  return (
    <Box>
      <Box mt={2}>
        <SelectedBikes
          label={t().chooseBikes}
          bikes={availableBikes}
          selectedBikeIds={selectedBikeIds}
          setSelectedBikeIds={setSelectedBikeIds}
          sportsEventType={sportEvent.sportEventType}
        />
      </Box>

      <Box mt={2} mb={1}>
        <Button
          variant="contained"
          color="primary"
          disabled={
            (!admin && (selectedBikeIds.size === 0 || selectedBikeIds.size > 2)) || submitting.value
          }
          onClick={async () => {
            try {
              submitting.setTrue()
              buttonSubmitting.setTrue()
              await submitSportEventInscriptionToServer({
                bikeIds: [...selectedBikeIds],
                uid: user.uid,
                sportEventId: sportEvent.id,
                remarksRider: '',
                date,
                type: 'enlistedLicenseInscription',
                categoryId: category.id,
              })
              const bill = await db.loadLatestBill(user)
              if (bill) await generatePDFBill(bill)
            } catch (error) {
              showError(error)
            }
            buttonSubmitting.setFalse()
            submitting.setFalse()
          }}
        >
          {buttonSubmitting.value ? t().inscription.inscribing : t().inscription.payAndInscribe}
        </Button>
      </Box>
    </Box>
  )
}

function inscriptionAssociation(category: Category, sportEvent: SportEvent): AssociationID | undefined {
  if (isLength(category.associations, 1)) return category.associations[0]

  if (category.associations.includes(sportEvent.association)) return sportEvent.association

  if (category.associations.includes('sam')) return 'sam'
  if (category.associations.includes('fms')) return 'fms'
  if (category.associations.includes('mxrs')) return 'mxrs'
  if (category.associations.includes('afm')) return 'afm'

  // TODO: later: day licenses: implement corner case:
  // association belongs to the category per event => needs to be defined in the sport event admin section
  // => usually, it's the association organizing the sport event
  // => special case (e.g. U20, organized by MXRS) => needs to be overridable in the sport event admin per category
  // throw new Error(
  //   `Einschreiben für Kategorie ${category.id} und Veranstaltung ${sportEvent.id} nicht möglich: Kategorie gehört zu mehrern Verbänden, wird aber von einem anderen Verband organisiert`
  // )
  return undefined
}

interface SelectBikesProps {
  label: string
  selectedBikeIds: Set<string>
  setSelectedBikeIds?: Disp<Set<string>>
  user: UserQuery
  sportsEventType: SportEventType
}

export function SelectBikes(props: SelectBikesProps) {
  const { data: bikes } = useActiveBikes(props.user)

  return <SelectedBikes {...props} bikes={bikes} />
}

interface SelectedBikesProps {
  label: string
  bikes: Bike[]
  selectedBikeIds: Set<string>
  setSelectedBikeIds?: Disp<Set<string>>
  sportsEventType: SportEventType
}

export function SelectedBikes(props: SelectedBikesProps) {
  const { bikes, selectedBikeIds, setSelectedBikeIds, label, sportsEventType } = props
  const canChange = !!setSelectedBikeIds
  const bikeTypeAddition = sportsEventType === 'enduro' ? ` (${t().sportEventTypes.enduro})` : ''

  return (
    <>
      {bikes.length > 0 && (
        <Box>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {label}
              {bikeTypeAddition}
            </FormLabel>
            <FormGroup>
              {bikes
                .filter((bike) => (sportsEventType === 'enduro' ? bike.categoryType === 'enduro' : true))
                .map((bike) => (
                  <FormControlLabel
                    key={bike.id}
                    control={
                      <Checkbox
                        disabled={!canChange}
                        checked={selectedBikeIds.has(bike.id)}
                        onChange={() => setSelectedBikeIds?.(toggleSetValue(selectedBikeIds, bike.id))}
                        name={bike.id}
                      />
                    }
                    label={bikeName(bike)}
                  />
                ))}
            </FormGroup>
            {canChange && <FormHelperText>{t().maxTwoBikes}</FormHelperText>}
          </FormControl>
        </Box>
      )}
      {canChange && (
        <RoutedButton
          to={routes.addBike.to}
          variant={bikes.length === 0 ? 'contained' : 'text'}
          color={bikes.length === 0 ? 'primary' : 'default'}
        >
          {routes.addBike.text()}
        </RoutedButton>
      )}
    </>
  )
}
