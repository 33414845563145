import assertNever from 'assert-never'
import { chunk, sortBy } from 'lodash'
import { Recipient } from 'shared/api/emails'
import { categoryAssociations, categoryById, categoryByIdRequired } from 'shared/data/categories-service'
import { generateCode } from 'shared/data/generateCode'
import { LicenseTasksOverview } from 'shared/data/license-tasks-overview'
import { Year } from 'shared/data/license-year'
import { ApprovedLicense, DB, Documents, LicenseDraftWithDocuments } from 'shared/db/db'
import { openLicenseTasks } from 'shared/license/license-tasks'
import { Category, CategoryId, CategoryType, categoryTypes } from 'shared/models/category'
import { Inscription, isLicenseInscription } from 'shared/sport-events/sport-events'
import { groupByString, truthy } from 'shared/utils/array'
import { I18nError } from 'shared/utils/errors'

export function displayIssuedNumber(license: ApprovedLicense | undefined) {
  return !license
    ? '?'
    : categoryById(license.categoryId)?.numberChoice
    ? license.issuedNumber || ''
    : '-'
}

export function hasIssuedNumber(license: ApprovedLicense | undefined) {
  return !!license && !!categoryById(license.categoryId)?.numberChoice && !!license.issuedNumber
}

export function licenseCategoryAssociations(license: LicenseDraftWithDocuments) {
  if (license.type === 'approved') return categoryAssociations(license.approved.categoryId)
  if (license.type === 'draft') return categoryAssociations(license.draft.categoryId)
  assertNever(license)
}

export function fullLicenseId(license?: ApprovedLicense) {
  if (!license?.shortId) return '-'

  return `${
    license.licenseType === 'national' ? 'N' : license.licenseType === 'international' ? 'I' : '?'
  }-${license.shortId}`
}

export async function updateLicenseIdsAndCodes(db: DB, year: Year) {
  await updateLicenseShortIds(db, year)
  await setMissingLicenseCodes(db, year)
}

async function updateLicenseShortIds(db: DB, year: Year) {
  await removeDuplicateLicenseIds(db, year)
  await setMissingLicenseIds(db, year)
}

async function removeDuplicateLicenseIds(db: DB, year: Year) {
  const licenses = await db.loadAllApprovedLicenses(year)
  const duplicates = licensesWithDuplicateShortIds(licenses)
  await Promise.all(duplicates.map((duplicate) => db.setApprovedLicenseShortID(duplicate, 0, year)))
}

function licensesWithDuplicateShortIds(licenses: ApprovedLicense[]) {
  const licensesWithShortId = licenses.filter((license) => license.shortId)

  const shortIdsArray = licensesWithShortId.map((license) => license.shortId).filter(truthy)

  const mapping = new Map(licensesWithShortId.map((license) => [license.shortId, license]))
  return duplicateNumbers(shortIdsArray)
    .map((shortId) => mapping.get(shortId))
    .filter(truthy)
}

function duplicateNumbers(numbersArray: number[]) {
  const counts = new Map<number, number>()
  numbersArray.forEach((shortId) => counts.set(shortId, (counts.get(shortId) || 0) + 1))

  const entries = [...counts.entries()]
  return entries.filter(([, count]) => count > 1).map(([shortId]) => shortId)
}

async function setMissingLicenseIds(db: DB, year: Year) {
  const licenses = await db.loadAllApprovedLicenses(year)
  const usedLicenseIds = new Set(licenses.map((license) => license.shortId))
  const licensesWithoutId = licenses.filter((license) => !license.shortId)
  const sortedLicensesWithoutId = sortBy(licensesWithoutId, (license) => license.approvedAt)

  let nextLicenseId = nextLicenseShortId(licenses)
  await Promise.all(
    sortedLicensesWithoutId.map((license) => {
      while (usedLicenseIds.has(nextLicenseId)) nextLicenseId++
      usedLicenseIds.add(nextLicenseId)
      return db.setApprovedLicenseShortID(license, nextLicenseId, year)
    })
  )
}

function nextLicenseShortId(allLicenses: ApprovedLicense[]) {
  const highest = allLicenses.reduce((acc, license) => Math.max(acc, license.shortId || 0), 0)
  return highest ? highest + 1 : startLicenseShortId
}

const startLicenseShortId = 100_001

async function setMissingLicenseCodes(db: DB, year: Year) {
  const licenses = await db.loadAllApprovedLicenses(year)
  const licensesWithoutSecrets = licenses.filter(
    (license) => !license.licenseCode || !license.pitLanePassCode
  )
  const chunks = chunk(licensesWithoutSecrets, 100)
  for await (const licenses of chunks) {
    await Promise.all(licenses.map((license) => setApprovedLicenseCodes(db, license, year)))
  }
}

async function setApprovedLicenseCodes(db: DB, license: ApprovedLicense, year: Year) {
  if (license.licenseCode && license.pitLanePassCode) return

  license.licenseCode = generateCode()
  license.pitLanePassCode = generateCode()
  await db.setApprovedLicenseCodes(license, year)
}

export async function loadApprovedLicensesByUid(
  db: DB,
  recipient: Recipient,
  year: Year
): Promise<Record<string, ApprovedLicenseWithContext[]>> {
  const licenses = filterByRecipient(await db.loadAllApprovedLicenses(year), recipient)

  const licensesWithContext = await Promise.all(
    licenses.map(async (license) => {
      const category = categoryByIdRequired(license.categoryId)
      const documents = await db.loadDocuments(license)
      if (!documents) throw new Error('No documents provided')
      const tasks = openLicenseTasks({ license, documents })
      return { tasks, documents, license, category }
    })
  )

  return groupByString(licensesWithContext, (license) => license.license.uid)
}

function filterByRecipient(licenses: ApprovedLicense[], recipient: Recipient): ApprovedLicense[] {
  return recipient === 'all'
    ? licenses
    : isCategoryType(recipient)
    ? filterByCategoryType(licenses, recipient)
    : filterByCategoryId(recipient, licenses)
}

function filterByCategoryType(licenses: ApprovedLicense[], recipient: CategoryType): ApprovedLicense[] {
  return licenses.filter((license) => categoryByIdRequired(license.categoryId).type === recipient)
}

function filterByCategoryId(recipient: CategoryId, licenses: ApprovedLicense[]) {
  const category = categoryByIdRequired(recipient)
  return licenses.filter((license) => license.categoryId === category.id)
}

function isCategoryType(recipient: Recipient): recipient is CategoryType {
  const types: Readonly<string[]> = categoryTypes
  return types.includes(recipient)
}

export async function deleteApprovedLicense(
  db: DB,
  approvedLicense: ApprovedLicense,
  category: Category
) {
  await throwIfRiderHasLicenseInscriptions(db, approvedLicense)
  await db.deleteApprovedLicense(approvedLicense, category.year)
}

export async function invalidateApprovedLicense(
  db: DB,
  approvedLicense: ApprovedLicense,
  category: Category
) {
  const existingLicense = await db.loadApprovedLicense(approvedLicense, category.id, category.year)
  if (!existingLicense) throw new I18nError('approvedLicenseNotFound')

  await db.invalidateApprovedLicense(approvedLicense, category.year)
}

export async function validateApprovedLicense(
  db: DB,
  approvedLicense: ApprovedLicense,
  category: Category
) {
  await db.validateApprovedLicense(approvedLicense, category.year)
}

async function throwIfRiderHasLicenseInscriptions(db: DB, approvedLicense: ApprovedLicense) {
  const inscriptions = await db.loadInscriptionsByUser(approvedLicense)
  if (hasLicenseInscriptions(inscriptions, approvedLicense))
    throw new I18nError('cannotDeleteLicenseWithExistingInscriptions')
}

function hasLicenseInscriptions(inscriptions: Inscription[], approvedLicense: ApprovedLicense) {
  return inscriptions.some(
    (inscription) =>
      isLicenseInscription(inscription) && inscription.category === approvedLicense.categoryId
  )
}

export interface ApprovedLicenseWithContext {
  tasks: LicenseTasksOverview
  documents: Documents
  license: ApprovedLicense
  category: Category
}
