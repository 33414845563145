import { Box, Button, Grid, LinearProgress, Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePhoto } from 'app/db/db-hooks/main-db-hooks'
import { db } from 'app/db/frontend-db'
import { DeleteButtonWithConfirmation } from 'app/layout/delete-button-with-confirmation'
import { OrderedList } from 'app/layout/ordered-list'
import { ConfirmUploadButton } from 'app/pages/insurance/confirm-upload-button'
import { CropPhoto } from 'app/pages/photo/crop-photo'
import { DisplayUploadedFile } from 'app/storage/display-uploaded-file'
import { deletePhoto, uploadPhoto, usePhotoPath } from 'app/storage/photo'
import { UploadPage } from 'app/storage/storage'
import { InvalidUploadMessage } from 'app/upload/invalid-upload-message'
import { UploadButton } from 'app/upload/upload-button'
import { UserQuery } from 'shared/db/db'
import { t } from 'shared/i18n/current'
import { Photo } from 'shared/models/photo'
import { migrateUploadStatus, UploadMetadata } from 'shared/models/upload-status'
import { setPhotoStatus } from 'shared/upload/upload-service'
import { FriendlyError } from 'utils/errors'

export function EditPhoto({ user }: { user: UserQuery }) {
  const history = useHistory()
  const { data, loading, error } = usePhoto(user)

  return (
    <>
      <Box py={3}>
        <Typography component="h2" variant="h2">
          {t().licensePhoto.title}
        </Typography>
      </Box>

      <Paper elevation={3}>
        <Box p={2}>
          <FriendlyError error={error} />
          {loading && <LinearProgress />}
          {!loading && (
            <>
              <Box my={2}>
                <Typography component="h3" variant="h3">
                  Status: {t().licensePhoto.status[data?.status || 'notUploaded']}
                </Typography>
              </Box>
              <Box my={2}>
                <OrderedList
                  items={[
                    { name: '1a', text: t().licensePhoto.options.optionAMakeASelfie },
                    { name: '1b', text: t().licensePhoto.options.optionBUploadFromComputer },
                    { name: 2, text: t().licensePhoto.options.checkQuality },
                  ]}
                />
              </Box>
              <Box mb={4}>
                <Typography>{t().licensePhoto.qualityHint}</Typography>
              </Box>
              <UploadPhoto photo={data} metadata={data?.upload} page={1} user={user} admin={user} />
            </>
          )}

          <Grid item xs={12}>
            <Box mt={1}>
              <Button fullWidth onClick={() => history.goBack()}>
                {t().buttons.back}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </>
  )
}

interface UploadPhotoProps {
  photo: Photo | undefined
  metadata: UploadMetadata
  page: number
  user: UserQuery
  admin: UserQuery
}

export function UploadPhoto(props: UploadPhotoProps) {
  const [file, setFile] = useState<File>()
  const { metadata, user, admin, page, photo } = props
  const path = usePhotoPath({ user, admin, page, metadata })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InvalidUploadMessage upload={migrateUploadStatus(photo)} />

        <UploadButton
          fullWidth
          onUpload={(files) => {
            setFile(files[0])
            return Promise.resolve()
          }}
        >
          {t().licensePhoto.uploadPhoto}
        </UploadButton>

        <Box py={1}>
          <DeletePhotoUploadButton {...props} />
        </Box>

        <ConfirmUploadButton
          upload={migrateUploadStatus(props.photo)}
          admin={props.admin}
          metadata={props.metadata}
          onUpdateStatus={(statusDetails) => setPhotoStatus({ db, user, admin, statusDetails })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {file && (
          <CropPhoto
            file={file}
            onSave={async (blob) => {
              await uploadBlob(photo, user, admin, blob)
              setFile(undefined)
            }}
          />
        )}
        <DisplayUploadedFile path={path} metadata={metadata} maxWidth={400} />
      </Grid>
    </Grid>
  )
}

export function uploadBlob(photo: Photo | undefined, user: UserQuery, admin: UserQuery, blob: Blob) {
  const file = new File([blob], 'photo.jpg', { lastModified: new Date().getTime(), type: 'image/jpeg' })
  return uploadPhoto({ oldMetadata: photo?.upload, file, page: 1, user, admin })
}

export function DeletePhotoUploadButton(props: UploadPage) {
  const { metadata } = props
  return (
    <DeleteButtonWithConfirmation
      title={t().licensePhoto.deletePhoto}
      onConfirm={() => deletePhoto(props)}
      disabled={!metadata}
    />
  )
}
