import { Box, TextField } from '@material-ui/core'
import { actions } from 'app/export/table'
import { TableBox } from 'app/layouts/table-box'
import { FinancialDialog } from 'app/pages/admin/bookings/financial-dialog'
import { ReferenceDetails } from 'app/pages/admin/payments/reference-details'
import { Bill, UserQuery } from 'shared/db/db'
import { searchBills } from 'shared/db/search'
import { t } from 'shared/i18n/current'
import { SearchQuery } from 'utils/router'

interface SearchOpenBillsProps {
  admin: UserQuery
  bills: Bill[]
  search: SearchQuery
}

export function SearchOpenBills({ admin, bills, search }: SearchOpenBillsProps) {
  const filteredBills = searchBills(search.q, bills)

  return (
    <TableBox
      title={t().bills.openBills}
      loading={false}
      error={null}
      data={{
        headers: [
          { value: t().reference, maxWidth: 220 },
          { value: t().amount.label, maxWidth: 100 },
          { value: t().name, maxWidth: 200 },
          { value: t().street, maxWidth: 200 },
          { value: t().place, maxWidth: 200 },
          { value: t().country, maxWidth: 100 },
          actions(),
        ],
        // eslint-disable-next-line react/display-name
        contents: filteredBills.map((bill) => () => [
          <ReferenceDetails key="reference" reference={bill.reference} />,
          bill.paymentInfo.amount,
          bill.paymentInfo.debitor.name,
          bill.paymentInfo.debitor.address,
          `${bill.paymentInfo.debitor.zip} ${bill.paymentInfo.debitor.city}`,
          bill.paymentInfo.debitor.country,
          <FinancialDialog key="" admin={admin} user={{ uid: bill.uid }} />,
        ]),
        ids: filteredBills.map(({ reference }) => reference),
        rawData: filteredBills.map((bill) => JSON.stringify(bill)),
      }}
    >
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <TextField
            label={t().bills.searchBillsLabel}
            variant="outlined"
            size="small"
            fullWidth
            value={search.q}
            onChange={(event) => search.set(event.currentTarget.value)}
          />
        </Box>
      </Box>
    </TableBox>
  )
}
