import { CanvasRef } from 'app/license/qr-code/canvas-ref'

export async function scanQRCodeFromImageWithJSQRLibrary(canvasRef: CanvasRef) {
  try {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    if (!canvas || !context) return undefined

    const jsQR = await import('jsqr')
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
    const result = jsQR.default(imageData.data, imageData.width, imageData.height)
    return result?.data
  } catch {
    return undefined
  }
}
