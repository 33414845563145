import assertNever from 'assert-never'
import samLogo from 'app/layouts/logo512.png'
import afmLogoDark from 'app/layouts/logos/afm-dark-background.svg'
import afmLogoLight from 'app/layouts/logos/afm-light-background.svg'
import fmsLogoDark from 'app/layouts/logos/fms-dark-background.svg'
import fmsLogoLight from 'app/layouts/logos/fms-light-background.svg'
import mxrsLogo from 'app/layouts/logos/mxrs.png'
import sjmccLogoDark from 'app/layouts/logos/sjmcc-dark-background.svg'
import sjmccLogoLight from 'app/layouts/logos/sjmcc-light-background.svg'
import { afmIsOnline } from 'app/license/config'
import { AssociationID, associations } from 'shared/models/associations'

export function associationsWithLogosPreview(preview: boolean) {
  return associationsWithLogos().filter(({ id }) => preview || afmIsOnline || id !== 'afm')
}

export function associationsWithLogos() {
  return internalAssociationsWithLogos
}

const internalAssociationsWithLogos = associations.map((association) => ({
  ...association,
  whiteBackgroundLogo: whiteBackgroundLogo(association.id),
  darkBackgroundLogo: darkBackgroundLogo(association.id),
}))

function whiteBackgroundLogo(id: AssociationID) {
  return id === 'fms'
    ? fmsLogoLight
    : id === 'sjmcc'
    ? sjmccLogoLight
    : id === 'afm'
    ? afmLogoLight
    : darkBackgroundLogo(id)
}

function darkBackgroundLogo(id: AssociationID) {
  return id === 'fms'
    ? fmsLogoDark
    : id === 'sam'
    ? samLogo
    : id === 'mxrs'
    ? mxrsLogo
    : id === 'sjmcc'
    ? sjmccLogoDark
    : id === 'afm'
    ? afmLogoDark
    : assertNever(id)
}
