import { ArrayElement } from 'shared/utils/tsc'

export interface ExportOptions {
  transponderType: TransponderType
  format: FormatType
}

export type FormatType = ArrayElement<typeof formatType>
export type TransponderType = ArrayElement<typeof transponderTypes>
export type TransponderTypeIncludingEmpty = ArrayElement<typeof transponderTypesIncludingEmpty>

// normal: First name, Last name, ZIP Place
// special: LAST NAME First name, Place (no ZIP)
export const formatType = ['normal', 'special'] as const
export const transponderTypes = ['MyLaps', 'RF'] as const
export const transponderTypesIncludingEmpty = [...transponderTypes, ''] as const
