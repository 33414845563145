// should match: export type PersonalFormData = Yup.InferType<ReturnType<typeof schema>>

import { Association } from 'shared/db/association'

export interface PersonalData {
  samMemberNumber?: number | undefined
  parentsInfo: string | undefined
  parentsAgree: boolean | undefined
  newSamMember: boolean
  email: string
  firstName: string
  lastName: string
  street: string
  zip: string
  place: string
  country: string
  birthdate: string
  phone: string
  afmSection: number | undefined
  fmsSection: number | undefined
  samSection: number | undefined
  association: undefined | Association
}

export interface PersonalDataFormData {
  samMemberNumber?: number | undefined
  parentsInfo: string | undefined
  parentsAgree: boolean | undefined
  newSamMember: boolean
  email: string
  firstName: string
  lastName: string
  street: string
  zip: string
  place: string
  country: string
  birthdate: Date | undefined
  phone: string
  afmSection: number | undefined
  fmsSection: number | undefined
  samSection: number | undefined
  association: undefined | Association
}

export function sortName(uid: string, personalData: PersonalData | undefined) {
  return `${reverseFullName(personalData)}, ${personalData?.zip} ${personalData?.place}, ${uid}`
}

export function searchName(uid: string, personalData: PersonalData | undefined) {
  return `${uniqueName(personalData)}, ${uid}`
}

export function uniqueName(personalData: PersonalData | undefined) {
  return personalData ? `${nameWithPlace(personalData)}, ${personalData.email}` : notFoundPlaceholder
}

export function nameWithPlace(personalData: PersonalData | undefined) {
  return personalData ? `${fullName(personalData)}, ${zipPlace(personalData)}` : notFoundPlaceholder
}

export function fullName(personalData: PersonalDataNames) {
  return personalData ? `${personalData.firstName} ${personalData.lastName}` : notFoundPlaceholder
}

export function reverseFullName(personalData: PersonalDataNames) {
  return `${personalData?.lastName} ${personalData?.firstName}`
}

export function reverseFullNameUppercase(personalData: PersonalDataNames) {
  const lastName = personalData?.lastName || ''
  return `${lastName.toUpperCase()} ${personalData?.firstName}`
}

export function zipPlace(personalData: PersonalData | undefined) {
  return `${personalData?.zip} ${personalData?.place}`
}

export const notFoundPlaceholder = '?'

export type PersonalDataNames =
  | {
      firstName: string
      lastName: string
    }
  | undefined
