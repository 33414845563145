import { Typography } from '@material-ui/core'
import { useTransactions } from 'app/db/db-hooks/financial-db-hooks'
import { AddManualPaymentButton } from 'app/pages/admin/manual-payments/add-manual-payment-button'
import { UserQuery } from 'shared/db/db'
import { sortedTransactionsWithTotal } from 'shared/db/transactions-service'
import { t } from 'shared/i18n/current'
import { toChf } from 'shared/utils/number'

export function AccountBalance({ user }: { user: UserQuery }) {
  const { balance, transactionsCount } = useCurrentAccountBalance(user)

  return (
    <Typography>{transactionsCount === 0 ? t().noAccountBalanceBookings : toChf(balance)}</Typography>
  )
}

export function AccountBalancePaymentButton({ user }: { user: UserQuery }) {
  const { balance } = useCurrentAccountBalance(user)

  return (
    <AddManualPaymentButton
      key={balance}
      amount={-balance}
      reference={''}
      internalRemarks={''}
      uid={user.uid}
      buttonText={t().addManualPaymentButtonText(toChf(-balance))}
    />
  )
}

function useCurrentAccountBalance(user: UserQuery) {
  const { data, loadingOrError } = useTransactions(user)
  if (loadingOrError) return { balance: 0, transactionsCount: 0 }

  const contents = sortedTransactionsWithTotal(data)
  if (contents.length === 0) return { balance: 0, transactionsCount: 0 }

  return { balance: contents[0].currentTotal, transactionsCount: contents.length }
}
