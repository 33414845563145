import { differenceInSeconds } from 'date-fns'
import { useEffect, useState } from 'react'
import { Disp } from 'utils/react'

export function useLoadWithCache<T>(
  uid: string,
  errorData: T,
  load: () => Promise<T>,
  cache: LoadCache<T>
) {
  const [data, setData] = useState<T>(errorData)
  useEffect(() => {
    loadData(load, cache, setData, uid, errorData).catch((error) => console.error(error))
  }, [uid, errorData, load, cache])
  return data
}

async function loadData<T>(
  loadFn: () => Promise<T>,
  cache: LoadCache<T>,
  setData: Disp<T>,
  uid: string,
  errorData: T
) {
  const entry = cache.get(uid)
  if (entry?.loaded) setData(entry.data)
  if (entry?.loaded && !needsReload(entry.lastLoaded)) return

  try {
    const useCurrentlyLoadingPromise = !!entry && !entry.loaded && !entry.error
    const promise = useCurrentlyLoadingPromise ? entry.promise : loadFn()
    cache.set(uid, { error: false, loaded: false, lastLoaded: new Date(), promise })
    const data = await promise
    cache.set(uid, { error: false, loaded: true, lastLoaded: new Date(), data })
    setData(data)
  } catch (errorObject: any) {
    cache.set(uid, { error: true, loaded: false, lastLoaded: new Date(), errorObject })
    setData(errorData)
  }
}

function needsReload(lastLoaded: Date) {
  const fiveMinutes = 5 * 60
  const difference = Math.abs(differenceInSeconds(lastLoaded, new Date()))
  return difference > fiveMinutes
}

export type LoadCache<T> = Map<
  string,
  | { error: false; loaded: false; lastLoaded: Date; promise: Promise<T> }
  | { error: true; loaded: false; lastLoaded: Date; errorObject: Error }
  | { error: false; loaded: true; lastLoaded: Date; data: T }
>
