export function toNumberIfPossible(maybeNumber: string | number) {
  const ret = toNumberIfPossibleOrUndefined(maybeNumber)
  return ret === undefined ? maybeNumber : ret
}

export function toNumberIfPossibleOrUndefined(maybeNumber: string | number) {
  return typeof maybeNumber === 'number'
    ? maybeNumber
    : validNumber(maybeNumber)
    ? parseInt10(maybeNumber)
    : undefined
}

export function validNumber(maybeNumber: number | string | undefined): boolean {
  return (
    !!maybeNumber &&
    maybeNumber !== 'NaN' &&
    parseInt10(maybeNumber.toString()).toString() === maybeNumber.toString()
  )
}

export function parseInt10OrUndefined(num: string | undefined) {
  return num === undefined ? undefined : parseInt10(num)
}

export function parseInt10(num: string) {
  return parseInt(num, 10)
}

export function toChfOrDash(num: number | undefined) {
  return typeof num === 'number' ? toChf(num) : '-'
}

export function roundChf(num: number) {
  return round(num, 2)
}

export function round(num: number, fractionDigits: number) {
  const factor = 10 ** fractionDigits
  return Math.round(num * factor) / factor
}

export function toChf(num: number) {
  return `${num.toFixed(2)}`
}
