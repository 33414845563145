import { saveAs } from 'file-saver'
import { toFilename } from 'shared/utils/string'

export function downloadXml(content: string, options: { name: string }) {
  downloadTextAsXml(content, toFilename(options.name))
}

function downloadTextAsXml(content: string, fileName: string) {
  const blob = new Blob([content], { type: 'text/xml;charset=utf-8' })
  saveAs(blob, fileName, { autoBom: true })
}
