import {
  AppBar,
  Avatar,
  Box,
  Slide,
  Container,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme, createStyles } from '@material-ui/core/styles'
import { AccountCircle, ChevronLeft, ChevronRight, Menu as MenuIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import { useIsSmallerThanSm, useIsSmallerThanXs } from 'app/layout/use-small-screen'
import { ChangeAssociationDropdownMenu } from 'app/layouts/change-association'
import { ChangeYearDropdownMenu } from 'app/layouts/change-year'
import {
  ChangeLocaleFooterLinks,
  ChangeLocaleDropdownMenu,
  ChangeLocaleIconButtons,
} from 'app/layouts/i18n-change-locale'
import { SamEventsMenu, SamEventsRoutes } from 'app/layouts/menu-and-router'
import { PreviewBanner } from 'app/layouts/preview-banner'
import { useAssociations } from 'app/themes/use-assocation'
import { useUserContext } from 'app/themes/user-context'
import { signOut } from 'app/users/auth'
import { User } from 'app/users/user'
import { externalLinks } from 'shared/config/externalLinks'
import { routes } from 'shared/config/routes'
import { t } from 'shared/i18n/current'
import { ExternalLink } from 'utils/external-link'
import { Disp } from 'utils/react'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex' },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    toolbarColorTransition: {
      transition: theme.transitions.create(['background-color', 'color'], {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.complex,
        delay: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    logoBox: { width: 70, height: 70, marginTop: 2, marginBottom: 2, marginRight: 10 },
    logo: { width: 65, height: 65 },
    menuButton: { marginRight: theme.spacing(1) },
    hide: { display: 'none' },
    drawer: { width: drawerWidth, flexShrink: 0 },
    drawerPaper: { width: drawerWidth },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0.5),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3, 2),
      },
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
)

type Classes = ReturnType<typeof useStyles>

export interface AppNormalContentProps {
  user: User | undefined
}

export function AppNormalContent({ user }: AppNormalContentProps) {
  const classes: Classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const xsScreen = useIsSmallerThanXs()
  const smScreen = useIsSmallerThanSm()
  const userContext = useUserContext()

  const handleDrawerOpen = useCallback(() => setOpen(true), [setOpen])
  const handleDrawerClose = useCallback(() => setOpen(false), [setOpen])

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null)
  const handleProfileClose = useCallback(() => setProfileAnchorEl(null), [setProfileAnchorEl])

  const navigateToHome = useCallback(() => history.push(routes.userOrAdminDashboard.to), [history])

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
          <Toolbar className={classes.toolbarColorTransition}>
            {renderMenuButton(handleDrawerOpen, classes, open)}
            <Logo navigateToHome={navigateToHome} classes={classes} />
            <Title navigateToHome={navigateToHome} />
            {((userContext.adminOrAssociationAdmin && !smScreen) ||
              (!userContext.adminOrAssociationAdmin && !xsScreen)) && <ChangeLocaleDropdownMenu />}
            {!xsScreen && <ChangeAssociationDropdownMenu />}
            {!xsScreen && userContext.adminOrAssociationAdmin && <ChangeYearDropdownMenu />}
            {user && renderProfile(user, setProfileAnchorEl, profileAnchorEl, handleProfileClose)}
          </Toolbar>
        </AppBar>
        {renderMenu(classes, open, handleDrawerClose, theme, user)}
        {renderContent(classes, user, open)}
      </div>
      <Copyright />
      <PreviewBanner />
    </>
  )
}

function renderMenuButton(handleDrawerOpen: () => void, classes: Classes, open: boolean) {
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={handleDrawerOpen}
      edge="start"
      className={clsx(classes.menuButton, open && classes.hide)}
    >
      <MenuIcon />
    </IconButton>
  )
}

function Logo(props: { navigateToHome: () => void; classes: Classes }) {
  const { navigateToHome, classes } = props
  const logos = useAssociations()
  const theme = useTheme()

  return (
    <Box
      onClick={navigateToHome}
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      className={classes.logoBox}
      style={{
        width: 70,
        height: 70,
        marginTop: 2,
        marginBottom: 2,
        marginRight: 10,
        cursor: 'pointer',
      }}
    >
      {logos.map(({ id, name, darkBackgroundLogo, whiteBackgroundLogo, active }) => (
        <Slide key={id} in={active} timeout={theme.transitions.duration.complex}>
          <img
            className={classes.logo}
            style={{ width: 65, height: 65, position: 'absolute' }}
            src={
              theme.palette.type === 'dark' && id === 'sjmcc' ? whiteBackgroundLogo : darkBackgroundLogo
            }
            alt={`Logo ${name}`}
          />
        </Slide>
      ))}
    </Box>
  )
}

function Title({ navigateToHome }: { navigateToHome: () => void }) {
  const { width } = useWindowSize()
  const displayTitle = isBetween(width, 470, 590) || isBetween(width, 700, 10e20)
  return (
    <Typography
      component="h1"
      variant="h5"
      style={{ flexGrow: 1, cursor: 'pointer' }}
      onClick={navigateToHome}
    >
      {displayTitle ? 'Racemanager' : ''}
    </Typography>
  )
}

function isBetween(num: number, lowerBound: number, upperBound: number) {
  return lowerBound < num && num < upperBound
}

function renderProfile(
  user: User,
  setProfileAnchorEl: Disp<HTMLElement | null>,
  profileAnchorEl: HTMLElement | null,
  handleProfileClose: () => void
) {
  return (
    <>
      <Typography
        onClick={(event) => setProfileAnchorEl(event.currentTarget)}
        style={{ cursor: 'pointer' }}
      >
        {user.displayName || user.email || ''}
      </Typography>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={(event) => setProfileAnchorEl(event.currentTarget)}
        color="inherit"
      >
        {user.photoURL ? (
          <Avatar alt={user.displayName || user.email || ''} src={user.photoURL} />
        ) : (
          <AccountCircle />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={profileAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(profileAnchorEl)}
        onClose={handleProfileClose}
      >
        <MenuItem
          onClick={async () => {
            await signOut()
            handleProfileClose()
          }}
        >
          {t().signOutText}
        </MenuItem>
      </Menu>
    </>
  )
}

function renderMenu(
  classes: Classes,
  open: boolean,
  handleDrawerClose: () => void,
  theme: Theme,
  user: User | undefined
) {
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <ChangeLocaleIconButtons />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <Divider />
      <SamEventsMenu user={user} closeMenu={handleDrawerClose} />
    </Drawer>
  )
}

function renderContent(classes: Classes, user: User | undefined, open: boolean) {
  return (
    <main className={clsx(classes.content, { [classes.contentShift]: open })}>
      <div className={classes.drawerHeader} />
      <Container maxWidth="xl" disableGutters={true}>
        <SamEventsRoutes user={user} />
      </Container>
    </main>
  )
}

function Copyright() {
  return (
    <>
      <Box mt={2}>
        <Divider />
      </Box>
      <Box p={1} pb={2}>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ marginBottom: 14, marginTop: 14 }}
        >
          <ExternalLink color="inherit" href={externalLinks.homepages.sam}>
            SAM - Schweizerischer Auto- und Motorradfahrer-Verband Sport
          </ExternalLink>{' '}
          {new Date().getFullYear()}
          <br />
          <ExternalLink color="inherit" href={routes.termsAndDataProtection.to}>
            {routes.termsAndDataProtection.text()}
          </ExternalLink>
          <br />
          <ChangeLocaleFooterLinks />
        </Typography>
      </Box>
    </>
  )
}
